import { useMemo } from "react";
import { createThemeBuilder } from "@theme/v2/theme.builders";
import { ThemeMode } from "@theme/v2/types";

const useCombineThemes = (mode: ThemeMode, isNewThemeOnly = false) => {
  const theme: any = useMemo(() => createThemeBuilder(mode), [mode]);

  return (outerTheme: any) => {
    if (isNewThemeOnly) return theme;

    const components = {
      ...outerTheme.components,
      ...theme.components,
      MuiButton: {
        ...outerTheme.components.MuiButton,
        variants: [
          ...outerTheme.components.MuiButton.variants,
          ...(theme?.components?.MuiButton?.variants || []),
        ],
      },
      MuiIconButton: {
        ...outerTheme.components.MuiIconButton,
        styleOverrides: {
          root: {
            variants: [
              ...outerTheme.components.MuiIconButton.styleOverrides.root
                .variants,
              ...(theme.components?.MuiIconButton?.styleOverrides?.root
                ?.variants || []),
            ],
          },
        },
      },
      MuiTextField: {
        ...outerTheme.components.MuiTextField,
        styleOverrides: {
          root: {
            variants: [
              ...outerTheme.components.MuiTextField.styleOverrides.root
                .variants,
              ...(theme.components?.MuiTextField?.styleOverrides?.root
                ?.variants || {}),
            ],
          },
        },
      },
      MuiCheckbox: {
        ...outerTheme.components.MuiCheckbox,
        styleOverrides: {
          root: {
            variants: [
              ...outerTheme.components.MuiCheckbox.styleOverrides.root.variants,
              ...(theme.components?.MuiCheckbox?.styleOverrides?.root
                ?.variants || {}),
            ],
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          ...outerTheme.components.MuiTypography.defaultProps,
          variantMapping: {
            ...outerTheme.components.MuiTypography.defaultProps.variantMapping,
            ...theme.components.MuiTypography.defaultProps.variantMapping,
          },
        },
        styleOverrides: theme.components.MuiTypography.styleOverrides,
      },
    };

    return {
      ...outerTheme,
      ...theme,
      components,
      breakpoints: { ...outerTheme.breakpoints, ...theme.breakpoints },
      palette: { ...outerTheme.palette, ...theme.palette },
    };
  };
};

export default useCombineThemes;
