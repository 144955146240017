import { format, sub } from "date-fns";
import { replaceValues, withAppendedKey } from "@services/filtering";
import moment from "moment";

type TModifier =
  | "is on or after"
  | "is on or before"
  | "is between"
  | "in the last";

export type TDate = {
  modifier: TModifier;
  count: number;
  dayMonthYear: string;
  startDate: number;
  endDate: number;
  date: number;
};

const createDateFilter = ({
  modifier,
  count,
  dayMonthYear,
  startDate,
  endDate,
  date,
}: TDate) => {
  switch (modifier) {
    case "is on or after":
      return getFromDate(modifier, date);
    case "is on or before":
      return getFromDate(modifier, date);
    case "is between":
      return getBetween(startDate, endDate);
    case "in the last":
    default:
      return getInTheLast(count, dayMonthYear);
  }
};

export default createDateFilter;

/**
 * Computes the date filter and the API query
 * @function
 * @param  {string} modifier - Modifier that determine the output of the filter
 * @param  {Date} date - The date that is the start/end of the filter
 */
const getFromDate = (
  modifier: Extract<TModifier, "is on or after" | "is on or before">,
  date: number,
) => {
  const name = [modifier, format(date, "dd/MM/yy")];

  const momentDate = moment(date);
  const queryDate =
    modifier === "is on or before"
      ? momentDate.endOf("day")
      : momentDate.startOf("day");

  const query = replaceValues(
    withAppendedKey.created[modifier],
    queryDate.unix(),
  );

  return {
    name,
    query,
  };
};

/**
 * Computes the date filter and the API query
 * @function
 * @param  {Date} startDate - The date that is the start of the filter
 * @param  {Date} endDate - The date that is the end of the filter
 */
const getBetween = (startDate: number, endDate: number) => {
  const name = [
    format(startDate, "dd/MM/yy"),
    "-",
    format(endDate, "dd/MM/yy"),
  ];

  const queryStartDate = moment(startDate).startOf("day").unix();
  const queryEndDate = moment(endDate).endOf("day").unix();
  const query = replaceValues(
    withAppendedKey.created["is between"],
    queryStartDate,
    queryEndDate,
  );

  return { name, query };
};

/**
 * Computes the date filter and the API query
 * @function
 * @param  {number} count - The subtraction amount in the specified unit of measure
 * @param  {string} dayMonthYear - The unit of measure of the subtraction (day, month, year, ...)
 */
const getInTheLast = (count: number, dayMonthYear: string) => {
  const name = ["last", count, dayMonthYear];

  const result = sub(new Date(), { [dayMonthYear]: count });
  const queryDate = moment(result).startOf("day").unix();
  const query = replaceValues(
    withAppendedKey.created["in the last"],
    queryDate,
  );
  return {
    name,
    query,
  };
};
