import { Text } from "@common/Text";
import { Box, Divider, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

type Section = {
  label: string;
  fields: string[];
};

const config = [
  {
    label: "Accounts",
    fields: [
      "Account ID",
      "Mask",
      "Name",
      "Official Name",
      "Persistent Account ID",
      "Subtype",
      "Type",
      "Balances",
    ],
  },

  {
    label: "Item",
    fields: [
      "Consent Expiration Time",
      "Error",
      "Institution ID",
      "Item ID",
      "Update Type",
      "Webhook",
      "Available Products",
      "Billed Products",
      "Consented Products",
      "Products",
    ],
  },

  {
    label: "Numbers",
    fields: ["ACH", "Bacs", "Eft", "International"],
  },
] as Section[];

const PlaidAccountInfo = ({ data }: { data: any }) => {
  const { isMobileView } = useCustomTheme();

  return (
    <Stack
      spacing="16px"
      padding={isMobileView ? "4px" : "24px"}
      overflow="auto"
    >
      {config.map((section, sectionInd) => {
        const sectionName = section.label.toLowerCase();
        const sectionData = data[sectionName];

        const isSectionArray = Array.isArray(sectionData);
        const sectionDataArr =
          isSectionArray && sectionData.length ? sectionData : [sectionData];

        return sectionDataArr.map((sectionDataItem, sectionItemInd) => (
          <Stack key={sectionInd}>
            {!sectionItemInd && (
              <Text
                color={palette.neutral[70]}
                fontSize={18}
                padding="8px 16px"
              >
                {section.label}
              </Text>
            )}
            {section.fields.map((field, fInd) => (
              <Row key={fInd} isEven={fInd % 2 === 0}>
                <Text color={palette.neutral[70]}>{field}</Text>

                <Stack>
                  {getSectionFieldData(sectionDataItem, field).map((val, i) => (
                    <Text
                      key={i}
                      color={palette.neutral[80]}
                      sx={{
                        wordBreak: "break-all",
                        textTransform: "capitalize",
                      }}
                    >
                      {val}
                    </Text>
                  ))}
                </Stack>
              </Row>
            ))}

            {sectionInd !== config.length - 1 && (
              <Divider color={palette.neutral[20]} sx={{ marginTop: "16px" }} />
            )}
          </Stack>
        ));
      })}
    </Stack>
  );
};
export default PlaidAccountInfo;

const Row = styled(Box)<{ isEven: boolean }>(({ theme, isEven }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  padding: "4px 16px",
  borderRadius: "4px",
  backgroundColor: isEven ? theme?.palette.neutral.white : "transparent",
}));

const getStringArrayFromObject = (obj: { [key: string]: string }) =>
  Object.entries(obj).map(
    ([key, value]) => `${key}: ${value ? value : "None"}`,
  );

const getFieldData = (fieldVal: any): string[] => {
  if (typeof fieldVal === "string") return [fieldVal];

  if (Array.isArray(fieldVal) && fieldVal.length) {
    if (typeof fieldVal[0] === "object" && fieldVal[0] !== null) {
      return fieldVal.flatMap((item) => getStringArrayFromObject(item));
    } else {
      return fieldVal;
    }
  }
  if (!fieldVal || (Array.isArray(fieldVal) && !fieldVal.length)) {
    return ["None"];
  }

  if (typeof fieldVal === "object") {
    return getStringArrayFromObject(fieldVal);
  }

  return ["None"];
};

const getSectionFieldData = (sectionDataItem: any, field: string): string[] => {
  const fieldName = field.toLowerCase().replaceAll(" ", "_");
  const fieldVal = sectionDataItem ? sectionDataItem[fieldName] : null;

  return getFieldData(fieldVal).map((s) => s.replaceAll("_", " "));
};
