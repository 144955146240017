import { Stack } from "@mui/material";
import { UserList } from "@phosphor-icons/react";
import SectionHeader from "./SectionHeader";
import { GiveInput } from "@shared/GiveInputs/GiveInput";
import { Controller, useFormContext } from "react-hook-form";
import { HFGiveTelephone } from "@shared/HFInputs/HFGiveTelephone/HFGiveTelephone";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import GiveText from "@shared/Text/GiveText";

const Contact = () => {
  const methods = useFormContext();
  const { methods: leftSidepanelMethods } = usePayBuilderForm();

  const {
    render: isPhoneNumberRendered,
    required: isPhoneNumberRequired,
    optionalMessage: phoneNumberMessage,
  } = leftSidepanelMethods.watch().Checkout.phoneNumber;

  return (
    <Stack spacing="20px">
      <SectionHeader title="Contact" icon={<UserList size={24} />} />
      <Stack spacing={2}>
        <Controller
          control={methods.control}
          name="email"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <GiveInput
                name="email"
                fullWidth
                label="Email"
                value={value}
                onChange={onChange}
                error={!!error}
              />
            </>
          )}
        />
        {isPhoneNumberRendered && (
          <Stack spacing={1}>
            <HFGiveTelephone
              label="Phone Number"
              name="phoneNumber"
              required={isPhoneNumberRequired}
              formatUSOnly={false}
            />
            <GiveText variant="bodyXS" color="secondary">
              {phoneNumberMessage}
            </GiveText>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default Contact;
