import { RHFInput } from "@common/Input";
import { Text } from "@common/Text";
import { MerchantImage } from "@components/AcquirerMerchants/MerchantList/MerchantMainInfo";
import { Box, Stack } from "@mui/material";
import { styled } from "@mui/system";
import { palette } from "@palette";
import { generateBillingDescriptor } from "@utils/helpers";
import { BILLING_DESCRIPTOR_MAX_LENGTH } from "@constants/constants";

type TStatementPreview = {
  billingDescriptor: string;
  avatarImage: string;
  issuer: React.ReactElement;
  date: string;
  amount: string;
  prefix: string;
  isMobileView: boolean;
};

export const BillingDescriptorInput = ({
  billingDescriptor,
  prefix,
  disabled = false,
}: {
  billingDescriptor: string;
  prefix: string;
  disabled?: boolean;
}) => {
  return (
    <Stack gap="24px">
      <Box>
        <Text
          sx={{
            mb: "8px",
            fontSize: "14px",
            color: palette.neutral["80"],
            lineHeight: "21.6px",
            fontWeight: "350",
          }}
        >
          Reduce your chargeback
        </Text>
        <Text
          sx={{
            fontSize: "14px",
            color: palette.neutral["70"],
            lineHeight: "16.8px",
            fontWeight: "350",
          }}
        >
          The billing descriptor is vital for transaction clarity and trust
          between merchants and customers. When purchases are easily
          identifiable on statements, it reduces unwarranted disputes,
          protecting businesses from revenue loss and operational disruptions.
        </Text>
      </Box>
      <RHFInput
        name="billingDescriptor"
        label={`Billing Descriptor`}
        inputPrefix={`${prefix}*`}
        fullWidth
        inputProps={{ maxLength: BILLING_DESCRIPTOR_MAX_LENGTH }}
        helperText={`Max ${billingDescriptor.length}/${BILLING_DESCRIPTOR_MAX_LENGTH} characters`}
        handleNormalizeInput={(v) => generateBillingDescriptor(v)}
        disabled={disabled}
      />
    </Stack>
  );
};

export const StatementPreview = ({
  billingDescriptor,
  avatarImage,
  issuer,
  date,
  amount,
  prefix,
  isMobileView,
}: TStatementPreview) => {
  const parseBillingDescriptor =
    generateBillingDescriptor(billingDescriptor).toUpperCase();
  return (
    <Stack gap="12px" marginBottom="24px ">
      <Text
        sx={{
          fontSize: "14px",
          color: palette.neutral["80"],
          lineHeight: "16.8px",
          fontWeight: "350",
        }}
      >
        Customers Credit Card Statement Preview
      </Text>
      <Stack sx={{ position: "relative" }}>
        <Box
          data-testid="statement-preview"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: palette.neutral.white,
            borderRadius: "8px",
            height: "55px",
            p: "12px 16px",
            lineHeight: "21.6px",
            fontWeight: "350",
            boxShadow: "0px 3px 8px 0px rgba(161, 175, 180, 0.10)",
            zIndex: 15,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <MerchantImage
              src={avatarImage}
              height={32}
              width={32}
            ></MerchantImage>
            <Text
              sx={{
                ml: "8px",
                wordWrap: isMobileView ? "normal" : "break-word",
                width: "150px",
              }}
            >
              <span style={{ color: palette.neutral["70"] }}>{prefix}*</span>
              {parseBillingDescriptor}
            </Text>
          </Stack>
          {!isMobileView ? issuer : null}
          <Wrapper isMobileView={isMobileView}>
            <Text>{date}</Text>
            <Text>{amount}</Text>
          </Wrapper>
        </Box>
        <StackLayer isFirst />
        <StackLayer />
      </Stack>
    </Stack>
  );
};

const StackLayer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isFirst",
})<{ isFirst?: boolean }>(({ isFirst }) => ({
  width: isFirst ? "96%" : "93%",
  height: "100%",
  position: "absolute",
  left: isFirst ? "2%" : "4%",
  zIndex: isFirst ? 10 : 5,
  top: isFirst ? 10 : 20,
  borderRadius: "8px",
  background: palette.neutral.white,
  boxShadow: "0px 3px 15px 0px rgba(161, 175, 180, 0.10)",
}));

const Wrapper = ({
  isMobileView,
  children,
}: {
  isMobileView: boolean;
  children: React.ReactElement[];
}) => {
  if (isMobileView)
    return (
      <Stack
        sx={{
          display: "flex",
          flexDirection: "column-reverse",
          alignItems: "flex-end",
          justifyContent: "center",
        }}
      >
        {children}
      </Stack>
    );
  return <>{children}</>;
};
