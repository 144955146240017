import { SidePanel } from "@containers/SidePanel";
import NiceModal from "@ebay/nice-modal-react";
import { useCreateMerchant } from "./hooks/useCreateMerchant";
import CreateMerchantHeader from "./CreateMerchantHeader";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import General from "./components/General";
import { FormProvider } from "react-hook-form";
import { Button } from "@common/Button";
import Placeholders from "./components/AddSections/AddSectionPlaceholders";
import PrimaryAccountHolder from "./components/CreatePrimaryAccountHolder";
import BusinessProfile from "./components/CreateBusinessProfile";
import MerchantBusinessOwners from "../MerchantPreview/components/BusinessOwners/MerchantBusinessOwners";
import MerchantBankAccounts from "../MerchantPreview/components/BankAccounts/MerchantBankAccounts";
import MerchantAgreement from "../MerchantPreview/sections/MerchantAgreement/MerchantAgreement";
import { CREATE_MERCHANT_AGREEMENT_MODAL } from "modals/modal_names";
import EnterprisePAHSection from "./components/EnterprisePAHSection";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";

const CreateMerchantPanel = NiceModal.create(() => {
  const {
    modal,
    handleClose,
    methods,
    onSubmit,
    handleCloseBusinessOwnerModal,
    handleCloseBankAccountModal,
    handleCloseMerchantAgreement,
    isLoading,
  } = useCreateMerchant();
  const { watch } = methods;
  const values = watch();

  const enterpriseConfiguration = useEnterprisePermissions();
  const { modify_merchant, b_profile_linking } = enterpriseConfiguration;
  const handleOpenMerchantAgreement = () => {
    NiceModal.show(CREATE_MERCHANT_AGREEMENT_MODAL, {
      onClose: handleCloseMerchantAgreement,
      data: values.merchantAgreement,
      merchantData: {
        ...values,
        businessAddress: values.businessProfile.address,
        businessOwnersList: values.businessOwners,
        bankAccountList: values.bankAccounts,
      },
    });
  };

  return (
    <SidePanel
      modal={modal}
      onCloseDrawer={handleClose}
      sx={{
        "& .MuiPaper-root": {
          boxShadow: "-8px 0px 50px 0px rgba(0, 0, 0, 0.15) !important",
        },
      }}
    >
      <CreateMerchantHeader handleClose={handleClose} />
      <FormProvider {...methods}>
        <Container component="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <General modify_merchant={modify_merchant} />

          {modify_merchant && (
            <Stack spacing={2} padding="0 16px">
              <PrimaryAccountHolder />
              <BusinessProfile />
              {values.businessOwners?.length !== 0 ? (
                <MerchantBusinessOwners
                  isMerchant
                  data={values.businessOwners}
                  businessType={values.businessProfile.businessType}
                  onCloseModal={handleCloseBusinessOwnerModal}
                  isIndividualSoleProprietorship={
                    values.businessProfile.businessType ===
                    "individual_sole_proprietorship"
                  }
                  isBPLinked={values?.businessProfile?.isLinkBusinessProfile}
                />
              ) : null}
              {values.bankAccounts?.length !== 0 ? (
                <MerchantBankAccounts
                  data={values.bankAccounts}
                  onCloseModal={handleCloseBankAccountModal}
                  parentAccID={values.merchantInfo.enterpriseID}
                  bankAccountSettings={{
                    linkedAccountID: values.linkedBankAccountID as number,
                    isLinkAccount: Boolean(values.linkedBankAccountID),
                    allowBankAccounts: true,
                    allowTransfers: true
                  }}
                />
              ) : null}
              {values.merchantAgreement.msaPCICompliant && (
                <MerchantAgreement
                  data={values.merchantAgreement}
                  handleOpenMerchantAgreement={handleOpenMerchantAgreement}
                />
              )}
            </Stack>
          )}
          {!modify_merchant && <EnterprisePAHSection />}
          <Placeholders enterpriseConfiguration={enterpriseConfiguration} />
          <Box flexGrow={1} />
          <CreateButton
            data-testid="create-merchant-button"
            size="medium"
            type="submit"
            disabled={isLoading}
          >
            {modify_merchant ? "Create" : "Invite"}
          </CreateButton>
        </Container>
      </FormProvider>
    </SidePanel>
  );
});

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  height: "100%",
  background: palette.primary.background,
  padding: "13px 12px 20px",
  overflowY: "auto",
  overflowX: "hidden",
  [theme.breakpoints.down("sm")]: {
    padding: "19px 6px",
  },
}));

const CreateButton = styled(Button)(() => ({
  margin: "0 auto",
  width: 200,
  position: "sticky",
  bottom: 0,
  zIndex: 99,
}));

export default CreateMerchantPanel;
