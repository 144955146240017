//mui
import { Box, InputAdornment, Stack, styled } from "@mui/material";
//hooks
import { useLogin } from "@hooks/auth-api";
//form
import { FormProvider } from "react-hook-form";
//components
import { WarningIcon } from "@assets/icons";
import { HideEye_V2, ShowEye_V2 } from "@assets/rebrandIcons";
import { Button } from "@common/Button";
import { RHFCheckbox } from "@common/Checkbox";
import { RHFInput } from "@common/Input";
import { Text } from "@common/Text";
import { LoginCustomLink } from "@components/Login";
import NiceModal from "@ebay/nice-modal-react";
import LoginContainer from "@pages/Login/LoginContainer";
import { palette } from "@palette";
import { useAppSelector } from "@redux/hooks";
import { selectAuth } from "@redux/slices/auth/auth";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import Cookies from "js-cookie";
import { TERMS_OF_SERVICE_MODAL } from "modals/modal_names";
import { useEffect } from "react";
import DifferentAccount from "./DifferentAccount";
import LoginOTP from "./LoginOTP";
import FlaggedWrapper from "FeatureFlags/FlaggedWrapper";
import { FeatureFlagKeys } from "FeatureFlags/featureFlagKeys";

const LoginNew = () => {
  const isAuthenticated = useAppSelector(selectAuth);

  const {
    isLoading,
    showAlert,
    location,
    methods,
    isPasswordVisible,
    handleVerify,
    tmpStep2,
    is2FAError,
    is2FALoading,
    onSubmit,
    togglePasswordVisible,
    isDifferentAccount,
    onSwitchAccount,
    onContinueAnyway,
    setTmpStep2,
  } = useLogin();
  const { isMobileView } = useCustomTheme();

  const {
    setValue,
    formState: { isValid },
  } = methods;

  const openTermsConditions = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.preventDefault();
    NiceModal.show(TERMS_OF_SERVICE_MODAL, {
      agree: () =>
        setValue("termsConditions", true, {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        }),
    });
  };

  useEffect(() => {
    if (isAuthenticated && !Cookies.get("user")) window.location.reload();
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return <></>;
  }

  if (isDifferentAccount) {
    return (
      <DifferentAccount
        onContinueAnyway={onContinueAnyway}
        onSwitchAccount={onSwitchAccount}
      />
    );
  }

  if (tmpStep2.email) {
    return (
      <LoginOTP
        onSubmit={onSubmit}
        setTmpStep2={setTmpStep2}
        handleVerify={handleVerify}
        isError={!!is2FAError}
        isLoading={!!is2FALoading || !!isLoading}
        email={tmpStep2.email}
        isMobileView={isMobileView}
      />
    );
  }

  return (
    <LoginContainer hideFooterLogo key={tmpStep2.email}>
      <Stack direction="column" gap={1}>
        <Text
          variant="h5"
          fontWeight="book"
          lineHeight={isMobileView ? "27px" : "46.3px"}
          fontSize={isMobileView ? 20 : 32}
          color={palette.gray[300]}
          letterSpacing={isMobileView ? "-0.2px" : "-0.32px"}
        >
          Login
        </Text>
        <Text
          variant="body"
          fontWeight={isMobileView ? "book" : "light"}
          fontSize={isMobileView ? 16 : 18}
          lineHeight={isMobileView ? "19.2px" : "21.6px"}
          color={palette.black[100]}
        >
          Welcome back! We&apos;re glad you&apos;re here.
        </Text>
      </Stack>
      {showAlert && (
        <WarningBox>
          <WarningIcon height={24} width={24} fill={palette.error.main} />
          <Text color={palette.error.main} fontSize={14} variant="headline">
            {showAlert}
          </Text>
        </WarningBox>
      )}
      <Box>
        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={methods.handleSubmit(onSubmit)}
            id="login"
          >
            <Stack spacing={2}>
              <RHFInput
                name="email"
                fullWidth
                type="text"
                placeholder="john.appleseed@mail.com"
                label="Email"
                error={
                  Boolean(showAlert) &&
                  showAlert !== (location.state as any)?.alert
                }
              />
              <RHFInput
                name="password"
                fullWidth
                type={isPasswordVisible ? "text" : "password"}
                placeholder="Type in your password"
                label="Password"
                error={
                  Boolean(showAlert) &&
                  showAlert !== (location.state as any)?.alert
                }
                autoFocus={Boolean((location.state as any)?.alert)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      onClick={togglePasswordVisible}
                      position="end"
                      sx={{ cursor: "pointer" }}
                    >
                      {isPasswordVisible ? <ShowEye_V2 /> : <HideEye_V2 />}
                    </InputAdornment>
                  ),
                }}
              />
              <StyledRowBox>
                <RHFCheckbox
                  label="Remember me"
                  name="remember"
                  sx={{
                    "& .MuiButtonBase-root.MuiCheckbox-root": {
                      height: 20,
                      "& > svg": {
                        height: 18,
                        width: 18,
                      },
                    },
                  }}
                />
                <LoginCustomLink
                  underlineColor={palette.neutral[70]}
                  to="/reset-password"
                  data-testid="forgot-password-link"
                >
                  <Text color={palette.neutral[70]} fontWeight="book" mr={3}>
                    Forgot Password?
                  </Text>
                </LoginCustomLink>
              </StyledRowBox>

              <RHFCheckbox
                name="termsConditions"
                dataTestId="termsConditions"
                sx={{
                  "& .MuiButtonBase-root.MuiCheckbox-root": {
                    height: 20,
                    "& > svg": {
                      height: 18,
                      width: 18,
                    },
                  },
                }}
                label={
                  <>
                    Accept{" "}
                    <Box
                      sx={{
                        display: "inline-block",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                      onClick={openTermsConditions}
                    >
                      <Text color={palette.neutral[80]}>
                        Terms & Conditions
                      </Text>
                    </Box>
                  </>
                }
              />
            </Stack>
          </Box>
        </FormProvider>
      </Box>
      <FlaggedWrapper
        ActiveComponent={
          <Text color={palette.neutral[70]} fontSize="12px" fontWeight="book">
            Protected by reCAPTCHA, Google{" "}
            <a
              rel="noreferrer"
              style={{ color: palette.givebox[600] }}
              target="_blank"
              href="https://policies.google.com/privacy"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              rel="noreferrer"
              style={{ color: palette.givebox[600] }}
              target="_blank"
              href="https://policies.google.com/terms"
            >
              Terms of Service
            </a>{" "}
            apply
          </Text>
        }
        name={FeatureFlagKeys.RECAPTCHA}
      />

      <Box display="flex" justifyContent="flex-end" mt={1}>
        <Button
          variant="secondary"
          disabled={!isValid || isLoading}
          type="submit"
          form="login"
        >
          Login
        </Button>
      </Box>
    </LoginContainer>
  );
};

const StyledRowBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const WarningBox = styled(Box)({
  display: "flex",
  gap: "16px",
  alignItems: "end",
  padding: "10px 16px",
  borderRadius: "8px",
  backgroundColor: palette.error.light,
});

export default LoginNew;
