import { showMessage } from "@common/Toast";
import {
  QKEY_LIST_ACQUIRER_MERCHANTS,
  QKEY_LIST_ENTERPRISE_MERCHANTS,
  QKEY_LIST_ENTERPRISE_STATS,
  QKEY_LIST_MERCHANTS,
  QKEY_LIST_TEAM_MEMBERS,
  QKEY_LIST_UNDERWRITERS,
} from "@constants/queryKeys";
import { useAppSelector } from "@redux/hooks";
import { selectSelectedAccount } from "@redux/slices/auth/accounts";
import { selectQueryString } from "@redux/slices/search";
import { customInstance } from "@services/api";
import {
  useGetAccUnderwriters,
  useGetAllTeamMembers,
} from "@services/api/manage-team";
import { checkPortals } from "@utils/routing";
import { UseQueryOptions, useMutation, useQueryClient } from "react-query";

interface GetTeamMembersProps {
  parentAccID?: number | null;
  memberStatus?: string;
  queryOptions?: Omit<
    UseQueryOptions<any, any, any, any>,
    "queryKey" | "queryFn"
  >;
}
const useGetTeamMembers = ({
  parentAccID,
  memberStatus,
  queryOptions = {},
}: GetTeamMembersProps) => {
  const { isAcquirerPortal } = checkPortals();
  const selectedUser = useAppSelector(selectSelectedAccount) as any;

  const id = isAcquirerPortal ? selectedUser?.id : parentAccID;
  const searchQuery = useAppSelector((state) =>
    selectQueryString(state, QKEY_LIST_TEAM_MEMBERS),
  );

  const { data, isLoading, error, isFetching } = useGetAllTeamMembers(
    { searchQuery, memberStatus },
    id,
    queryOptions,
  );
  return {
    data: data?.data || [],
    isLoading,
    error,
    isFetching,
    searchQuery,
  };
};

export type AssigneesType = "underwriting" | "risk" | "members";

interface IGetUnderwritersProps {
  merchantId: number;
  queryOptions?: Omit<
    UseQueryOptions<any, any, any, any>,
    "queryKey" | "queryFn"
  >;
  type?: AssigneesType;
}

export const useGetUnderwriters = ({
  merchantId,
  queryOptions = {},
  type = "underwriting",
}: IGetUnderwritersProps) => {
  const searchQuery = useAppSelector((state) =>
    selectQueryString(state, QKEY_LIST_UNDERWRITERS),
  );

  const { data, isLoading, error, isFetching } = useGetAccUnderwriters(
    { searchQuery },
    merchantId,
    {
      enabled: !!merchantId,
      ...queryOptions,
    },
    type,
  );
  return {
    data: data?.data || [],
    isLoading,
    error,
    isFetching,
    searchQuery,
  };
};

type TOptions = { updateMerchantPreview?: boolean };
type TKeyValuePairs = {
  [key: string]: any;
};

export const useUpdateMerchantsLists = (queryKey?: string) => {
  const queries = [
    QKEY_LIST_ACQUIRER_MERCHANTS,
    QKEY_LIST_ENTERPRISE_MERCHANTS,
    QKEY_LIST_MERCHANTS,
    QKEY_LIST_ENTERPRISE_STATS,
  ];
  const queryClient = useQueryClient();
  const previousData = queryClient.getQueriesData(queryKey as string);

  const updateData = (
    merchantID: number,
    keyValuePairs: TKeyValuePairs,
    options?: TOptions,
  ) => {
    const updateHandler = (oldData: any) => {
      if (!oldData?.data || !Array.isArray(oldData.data)) return oldData;

      const updatedData = oldData.data.map((merchant: any) => {
        if (merchant.accID !== merchantID) return merchant;

        // Iterate over each key-value pair and update the merchant
        const updatedMerchant = Object.entries(keyValuePairs).reduce(
          (acc, [key, value]) => {
            acc[key] = value;
            return acc;
          },
          { ...merchant },
        );

        return updatedMerchant;
      });

      return {
        ...oldData,
        data: updatedData,
      };
    };

    const updateMerchantPreview = (oldData: any) => {
      if (!oldData?.merchant) return oldData;

      const updatedMerchant = Object.entries(keyValuePairs).reduce(
        (acc, [key, value]) => {
          acc[key] = value;
          return acc;
        },
        { ...oldData.merchant },
      );

      return {
        ...oldData,
        merchant: updatedMerchant,
      };
    };

    queries.forEach((key) => queryClient.setQueriesData(key, updateHandler));

    if (options?.updateMerchantPreview) {
      queryClient.setQueriesData("get-merchant-preview", updateMerchantPreview);
    }
  };

  const revertChange = () => {
    previousData.forEach(([queryKey, queryData]) =>
      queryClient.setQueriesData(queryKey as string, queryData),
    );
  };

  return { updateData, revertChange };
};

export const useUpdateAssignee = (merchantID?: number, options?: TOptions) => {
  const { updateData } = useUpdateMerchantsLists();

  const updateAssignee = useMutation((data: any) => {
    return customInstance({
      url: `/merchants/${merchantID}/underwriting`,
      method: "PATCH",
      data,
    });
  });

  const handleChangeAssignee = (
    oldAssignee = 0,
    newAssignee: string | null,
    onSuccess?: () => void,
  ) => {
    const successCharMsg =
      oldAssignee && newAssignee
        ? "changed"
        : newAssignee
        ? "added"
        : "removed";
    updateAssignee.mutate(
      { underwriterID: newAssignee },
      {
        onError: (error: unknown) => {
          const axiosError = error as any;
          const errorMessage = axiosError?.response?.data?.message;
          showMessage("Error", errorMessage || "Unable to change assignee");
        },
        onSuccess: (data) => {
          if (onSuccess) onSuccess();
          showMessage("Success", `Assignee has been ${successCharMsg}`);

          merchantID &&
            updateData(
              merchantID,
              {
                underwriterID: data?.underwriterID,
                underwriterName:
                  data?.underwriterName || data?.underwriterEmail,
                underwriterImageURL: data?.underwriterImageURL,
              },
              {
                ...options,
              },
            );
        },
      },
    );
  };

  return { handleChangeAssignee };
};

export default useGetTeamMembers;
