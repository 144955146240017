import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import KotoLinearProgress from "./LinearProgress";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Step,
  Stepper,
  StepperProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Button } from "@common/Button";
import { ITextProps } from "@common/Text/Text";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

interface IStepperProps extends StepperProps {
  customSteps: Array<{ label: string; barValue: number; hidden?: boolean }>;
  isClickable?: boolean;
  defaultStep?: string;
  setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  actionOnClick?: React.Dispatch<React.SetStateAction<number>>;
  setStep?: React.Dispatch<React.SetStateAction<any>>;
  LinearProgressProps?: ITextProps;
  svgProps?: { width?: number; height?: number };
  automateLast?: boolean;
  disableLastClick?: boolean;
  overriderActiveStep?: number;
  disableWithoutStyle?: boolean;
  alwaysEnable?: boolean;
  kotoStepClickHandler?: (arg0: any, arg1: any) => void;
  completionStatus: Record<
    string,
    { isComplete: boolean; tabName: string }
  > | null;
}

const mapStepCompletionStatus = (
  steps: Array<{ label: string }>,
  completionStatus: Record<
    string,
    { isComplete: boolean; tabName: string }
  > | null,
) => {
  if (!completionStatus) return {};
  return steps.reduce((acc, step) => {
    const matchingStatus = Object.values(completionStatus).find((status) => {
      return status.tabName == step.label;
    });

    acc[step.label] = matchingStatus || {
      isComplete: true,
      tabName: step.label,
    };
    return acc;
  }, {} as Record<string, { isComplete: boolean; tabName: string }>);
};

const KotoStepper = forwardRef(
  (
    {
      customSteps: steps,
      isClickable = true,
      setIsLoaded,
      actionOnClick,
      setStep,
      defaultStep,
      LinearProgressProps,
      svgProps,
      automateLast,
      disableLastClick,
      overriderActiveStep,
      disableWithoutStyle,
      alwaysEnable = false,
      kotoStepClickHandler,
      completionStatus,
      ...rest
    }: IStepperProps,
    ref,
  ) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);

    const customSteps = steps.filter(({ hidden }) => !hidden);
    const [lastStepValue, setLastStepValue] = useState(0);

    const stepCompletionStatus = useMemo(
      () => mapStepCompletionStatus(customSteps, completionStatus),
      [customSteps, completionStatus],
    );

    useEffect(() => {
      setIsLoaded(true);
      setStep && setStep(defaultStep);
    }, []);

    useEffect(() => {
      if (
        overriderActiveStep &&
        overriderActiveStep > -1 &&
        overriderActiveStep !== activeStep
      ) {
        setActiveStep(overriderActiveStep);
      }
    }, [overriderActiveStep]);

    const handleNext = () => {
      setActiveStep((prev) => prev + 1);
    };

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step: number, isPrevCompleted: boolean) => () => {
      if (
        disableWithoutStyle ||
        !isClickable ||
        !isPrevCompleted ||
        (customSteps.length - 1 === step && disableLastClick)
      )
        return;

      // If there is kotoStepClickHandler step change action should be handled after api response
      if (kotoStepClickHandler) {
        kotoStepClickHandler(handleStepAction, step);
      } else {
        handleStepAction(step);
      }
    };

    const handleStepAction = (step: number) => {
      setActiveStep(step);
      actionOnClick && actionOnClick(step + 1);
      setStep && setStep(customSteps[step]?.label);
    };

    useEffect(() => {
      if (setStep) setStep(customSteps[activeStep]?.label);
    }, [activeStep]);

    useEffect(() => {
      if (automateLast)
        if (activeStep === customSteps.length - 1) {
          const interval = setInterval(() => {
            setLastStepValue((prevValue) =>
              prevValue < 100 ? prevValue + 1 : prevValue,
            );
          }, 50);

          return () => clearInterval(interval);
        }
    }, [activeStep, automateLast]);

    useImperativeHandle(
      ref,
      (): { handleNext: () => void; handleBack: () => void } => {
        return {
          handleNext,
          handleBack,
        };
      },
      [],
    );
    return (
      <Box sx={{ ...rest.sx }}>
        <Stepper nonLinear activeStep={activeStep} sx={{ ...KotoStepperStyle }}>
          {customSteps
            .filter(({ hidden }) => !hidden)
            .map((step, index) => {
              if (!isDesktop && index !== activeStep) return;
              const isPrevCompleted =
                index === 0 ||
                alwaysEnable ||
                customSteps
                  .slice(0, index)
                  .every((step) => step.barValue === 100);

              const isStepCompleted =
                stepCompletionStatus[step.label]?.isComplete;

              const value =
                index === customSteps.length - 1 && automateLast
                  ? lastStepValue
                  : customSteps[index].barValue;

              return (
                <Step
                  sx={{ width: "100%", height: "100%", alignItems: "start" }}
                  key={step?.label}
                >
                  <Button
                    background="tertiary"
                    data-testid={`stepper-${step?.label}-button`}
                    sx={{
                      padding: 0,
                      "&:hover, &:active": { textDecoration: "none" },
                      cursor:
                        isPrevCompleted &&
                        !(
                          customSteps.length - 1 === index && disableLastClick
                        ) &&
                        !disableWithoutStyle
                          ? "pointer"
                          : "default",
                      height: "fit-content",
                    }}
                    fullWidth
                    onClick={handleStep(index, isPrevCompleted)}
                    disabled={
                      !isClickable ||
                      !isPrevCompleted ||
                      (customSteps.length - 1 === index && disableLastClick)
                    }
                  >
                    <div className={classes.root}>
                      <KotoLinearProgress
                        hasDescription
                        value={value}
                        label={step?.label}
                        isActive={index === activeStep}
                        LinearProgressProps={LinearProgressProps}
                        svgProps={svgProps}
                        isIncomplete={!isStepCompleted}
                      />
                    </div>
                  </Button>
                </Step>
              );
            })}
        </Stepper>
      </Box>
    );
  },
);

const KotoStepperStyle = {
  justifyContent: "space-between",
  alignItems: "flex-start",
  ".MuiStepLabel-iconContainer, .MuiStepConnector-root": {
    display: "none",
  },
};

KotoStepper.displayName = "KotoStepper";

export default KotoStepper;
