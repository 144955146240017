export enum TAG_STATUSES_ENUM {
  APPROVED = "Approved",
  DECLINED = "Declined",
  INVITE = "Invite sent",
  SUSPENDED = "Suspended",
  PENDING = "Pending",
  UNDERWRITING = "Underwriting",
  READY_FOR_REVIEW = "Ready for Review",
  IN_PROGRESS = "In progress",
  DELIVERED = "Delivered",
  UNDELIVERABLE = "Undeliverable",
}
