import { EditMerchantSection } from "@components/Merchants/MerchantPreview/components/atoms";
import NiceModal from "@ebay/nice-modal-react";
import { CREATE_BUSINESS_PROFILE_MODAL } from "modals/modal_names";
import { useFormContext } from "react-hook-form";
import { TCreateBusinessProfile } from "../types";
import { businessTypes } from "@common/BusinessProfileInputs/BusinessTypeSelect";
import { capitalizeFirstLetter } from "@common/Table/helpers";
import {
  formatEIN,
  formatSSN,
  parsePhoneNumber,
} from "@components/Merchants/MerchantPreview/hooks/useGetSectionItems";
import { getCountryNameFromCode } from "@utils/country_dial_codes";
import { defaultValues } from "../schemas/CreateMerchantSchema";
import { TBusinessOwner } from "@components/Merchants/MerchantPreview/data.types";
import { useEffect } from "react";
import { FormType } from "../modals/CreateBusinessProfileModal";
import { EditBusinessHandler } from "../modals/CreateBusinessProfileModal";
import { computeAgeOfBusiness } from "@components/Merchants/MerchantPreview/helpers/parsers";

type Props = {
  isEnterprise?: boolean;
};

const CreateBusinessProfile = ({ isEnterprise }: Props) => {
  const { watch, setValue, resetField } = useFormContext();
  const values = watch();

  const taxIdToShow = values.businessProfile?.ssn
    ? formatSSN(values?.businessProfile?.ssn)
    : formatEIN(values.businessProfile?.taxID);

  useEffect(() => {
    resetField("taxID");
    resetField("ssn");
  }, [values.tinType]);

  const ageOfBusiness = computeAgeOfBusiness(
    values.businessProfile?.businessOpenedAt,
  );

  const businessProfileItems = [
    {
      title: "Federal Tax ID",
      value: taxIdToShow,
      size: 6,
    },
    {
      title: "Legal Name",
      value: values.businessProfile?.legalName,
      size: 6,
    },
    {
      title: "Business Type",
      value:
        businessTypes[
          values.businessProfile?.businessType as keyof typeof businessTypes
        ],
      size: 6,
    },
    {
      title: "Business Ownership Type",
      value: capitalizeFirstLetter(values.businessProfile?.ownershipType),
      size: 6,
    },
    {
      title: "Doing Business As",
      value: values.businessProfile?.DBA || values.businessProfile?.dba,
      size: 6,
    },
    {
      title: "Date business opened",
      value: values.businessProfile?.businessOpenedAt,
      size: 6,
    },
    {
      title: "Business Phone Number",
      value: parsePhoneNumber(values.businessProfile?.contactPhone),
      size: 6,
      isPhoneNumber: true,
    },
    {
      title: "Age of Business",
      value: ageOfBusiness,
      size: 6,
      isValue: true,
      suf: Number(ageOfBusiness) > 1 ? "years" : "year",
    },
  ];

  const businessAddressItems = [
    {
      title: "Country",
      value: getCountryNameFromCode(values.businessProfile?.address?.country),
      size: 6,
    },
    {
      title: "Street Address",
      value: values.businessProfile?.address?.address,
      size: 6,
    },
    {
      title: "City",
      value: values.businessProfile?.address?.city,
      size: 6,
    },
    {
      title: "State",
      value: values.businessProfile?.address?.state,
      size: 6,
    },
    {
      title: "Zip Code",
      value: values.businessProfile?.address?.zip,
      isValue: true,
      size: 6,
    },
  ];

  const handleCloseBusinessProfile = (data: {
    businessProfile: TCreateBusinessProfile;
    businessOwners?: TBusinessOwner[];
  }) => {
    setValue("businessProfile", {
      ...data.businessProfile,
      linkedOwnerId: data.businessOwners?.[0]?.id,
    });
    if (data.businessProfile.linkedBusinessProfile) {
      setValue("businessOwners", data.businessOwners || []);
    }
  };

  const editHandler: EditBusinessHandler = (section) => {
    let customData = values.businessProfile;
    if (customData.isLinkBusinessProfile && customData.linkedBusinessProfile) {
      customData = {
        ...defaultValues.businessProfile,
        isLinkBusinessProfile: true,
        linkedBusinessProfile: customData.linkedBusinessProfile,
      };
    } else {
      customData = {
        ...customData,
        isLinkBusinessProfile: false,
        linkedBusinessProfile: undefined,
      };
    }

    NiceModal.show(CREATE_BUSINESS_PROFILE_MODAL, {
      onClose: handleCloseBusinessProfile,
      section,
      data: customData,
      isEdit: true,
      isLegalEntityApproved: false,
      formType: isEnterprise
        ? FormType.CREATE_ENTERPRISE
        : FormType.CREATE_MERCHANT,
    });
  };

  if (!values.businessProfile?.taxID && !values.businessProfile?.ssn)
    return null;

  return (
    <>
      <EditMerchantSection
        sectionTitle="Business profile"
        editHandler={() => editHandler("business_profile")}
        items={businessProfileItems}
      />
      <EditMerchantSection
        sectionTitle="Business address"
        editHandler={() => editHandler("business_address")}
        items={businessAddressItems}
      />
    </>
  );
};

export default CreateBusinessProfile;
