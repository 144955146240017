/* eslint-disable @typescript-eslint/ban-ts-comment */
// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";

import { Button } from "@common/Button";

import { FormControlLabel } from "@common/FormControlLabel";
import { Input, RHFInput } from "@common/Input";
import { Switch } from "@common/Switch";
import { TabPanel, Tabs } from "@common/Tabs";
import { Text } from "@common/Text";
import { ColorValue, createColor } from "mui-color";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { CheckMarkIcon } from "@assets/icons";
import ColorPicker from "@common/ColorPicker/ColorPicker";
import FadeInWrapper from "@components/animation/FadeInWrapper";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { Color } from "mui-color";
import { useCallback, useMemo, useState } from "react";
import { campaignType, WidgetType } from "./share.types";

const boxStyle = {
  mt: 1,
  height: 72,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#F4F3F7",
  borderRadius: "4px",
  padding: "16px",
  boxShadow:
    "inset -4px -4px 9px rgba(255, 255, 255, 0.88), inset 0px 2px 14px rgba(193, 208, 238, 0.5)",
};

const inputBox = {
  marginTop: 2,
  position: "relative",

  "& > .MuiButton-root": {
    position: "absolute",
    borderRadius: "6px",
    bottom: "8px",
    right: "8px",

    "@media (max-width: 600px)": {
      left: 0,
      right: 0,
      width: "95%",
      margin: "0 auto",
    },
  },
};

const inputStyle = {
  "& .MuiInputBase-root": {
    padding: "4px 0px 4px 16px",

    "& textarea": {
      fontSize: "16px",

      "&::-webkit-scrollbar": {
        width: 3,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "inherit",
      },
      "&::-webkit-scrollbar-thumb": {
        border: "none",
      },
    },
  },
};

const btnCopied = {
  background: "#EFF4F8",
  boxShadow:
    "inset -4px -4px 9px rgba(255, 255, 255, 0.88), inset 4px 4px 14px #C1D5EE",
};

function getCurrentDomain() {
  const { protocol, hostname } = window.location;

  // Remove "www." prefix if present
  const domain = hostname.replace("www.", "");

  const fullDomain = `${protocol}//${domain}`;
  return fullDomain;
}
function minify_html(inputHTML: any) {
  const minifiedHTML = inputHTML
    .replace(/\s+/g, " ")
    .replace(/<!--[^-]*(?:-[^-]+)*-->/g, "")
    .replace(/> </g, "><");
  const formattedHTML = minifiedHTML.replace(/></g, ">\n<");
  return formattedHTML.trim();
}

const makeWidget = ({
  buttonName,
  domainName,
  productId,
  buttonColor,
  buttonBackground,
  autoPayment,
}: any) => {
  const iFrameText = `<iframe src='${domainName}/${productId}?public=true&noFocus=true' frameBorder='no' scrolling='auto' height='800px' width='100%'></iframe>`;
  const widgetText = `
  <button
    type="button"
    data-id="pay-now"
    class="givepayments-btn"
    data-productId="${productId}"
    data-domainName="${domainName}"
    style="
      color:${
        buttonColor === " " || typeof buttonColor === "object"
          ? "transparent"
          : buttonColor
      };
      background:${
        typeof buttonBackground === "object" ? "transparent" : buttonBackground
      };
      border-radius: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 20px;
      border: 0;
      cursor: pointer;
    "
  >
    ${buttonName}
  </button>
  <script type='text/javascript' src='https://cdn.givepayments.com/ui/static/js/script.minify.js'></script> 
  <script type='text/javascript'>
    if(${autoPayment}) {
      document.addEventListener('DOMContentLoaded',GIVEPAYMENTS.init([{auto:'${productId}',domainName:'${domainName}'}]));
    }

    const btn = document.querySelector('[data-id="pay-now"]');

    btn.addEventListener("click", () => {
      GIVEPAYMENTS.init([{ auto: "${productId}", domainName: "${domainName}" }]);
    });

    btn.addEventListener('touchstart', () => {
      GIVEPAYMENTS.load([{ auto: "${productId}", domainName: "${domainName}" }]);
    });
  </script> 
  `;

  return { iFrameText, widgetText };
};

export default function AddToYourWebsite({
  productId,
  type,
}: {
  productId: string;
  type: campaignType;
}) {
  const theme = useTheme();
  const { isMobileView } = useCustomTheme();

  const [tabValue, setTabValue] = useState(0);
  const [isCopied, setIsCopied] = useState(false);
  const [isIFrameCopied, setIsIFrameCopied] = useState(false);
  const [textColor, setTextColor] = useState<ColorValue>(
    createColor("#FFFFFF"),
  );

  const [autoPopUp, setAutoPopUp] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState<ColorValue>(
    createColor("#4385F5"),
  );

  const schema = Yup.object({
    text: Yup.string().required("This field is required"),
    // textColor: Yup.string()
    //   .required("This field is required")
    //   .test("is-hex-code", "", (value: any) => hex.test(value)),
    // backgroundColor: Yup.string()
    //   .required("This field is required")
    //   .test("is-hex-code", "", (value: any) => hex.test(value)),
  });

  const defaultValues = {
    text: "Donate Now",
    textColor: "#FFFFFF",
    backgroundColor: "#4385F5",
    autopop: false,
  };

  const methods = useForm<WidgetType>({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { watch } = methods;
  const values = watch();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onSubmit: SubmitHandler<WidgetType> = (formValues) => {
    console.log("Values: ", formValues);
  };

  const { iFrameText, widgetText } = makeWidget({
    buttonName: values.text,
    domainName: getCurrentDomain(),
    autoPayment: autoPopUp,
    productId,
    buttonColor: (textColor as Color).raw // hex is stripping trasparency
      ? `${(textColor as Color).raw}`
      : textColor,
    buttonBackground: (backgroundColor as Color).raw
      ? `${(backgroundColor as Color).raw}`
      : backgroundColor,
  });

  const changeTextColor = useCallback((color: string) => {
    if (!color) {
      setTextColor(" "); //this is a workaround, because the library does not allow the setting of undefined or empty string
    } else setTextColor(createColor(color));
  }, []);

  const changeBackgroundColor = useCallback((color: string) => {
    setBackgroundColor(createColor(color));
  }, []);

  const gridItem = useMemo(() => {
    const arr = [
      {
        component: <RHFInput name="text" type="text" fullWidth label="Text" />,
        xs: 12,
      },
      {
        xs: 6,
        component: (
          <ColorPicker
            fullWidth
            // value={textColor}
            label="Text color"
            defaultValue={defaultValues.textColor}
            onChange={changeTextColor}
          />
        ),
      },
      {
        component: (
          <ColorPicker
            fullWidth
            // value={backgroundColor}
            label="Background color"
            defaultValue={defaultValues.backgroundColor}
            onChange={changeBackgroundColor}
            // onChange={(color: ColorValue) => setBackgroundColor(color)}
          />
        ),
        xs: 6,
      },
      {
        component: (
          <>
            <Text fontWeight="medium">Button Preview</Text>
            <Box sx={boxStyle}>
              <Button
                onTouchStart={() => {
                  const scr = document.createElement("script");
                  scr.setAttribute("type", "text/javascript");
                  scr.setAttribute(
                    "src",
                    "https://cdn.givepayments.com/ui/static/js/script.minify.js",
                  );

                  document.body.prepend(scr);

                  scr.onload = function () {
                    try {
                      GIVEPAYMENTS.load([
                        {
                          auto: productId,
                          domainName: getCurrentDomain(),
                        },
                      ]);
                    } catch (err) {
                      console.log(err);
                    }
                  };
                }}
                onClick={() => {
                  const scr = document.createElement("script");
                  scr.setAttribute("type", "text/javascript");
                  scr.setAttribute(
                    "src",
                    "https://cdn.givepayments.com/ui/static/js/script.minify.js",
                  );

                  document.body.prepend(scr);

                  scr.onload = function () {
                    try {
                      GIVEPAYMENTS.init([
                        {
                          auto: productId,
                          domainName: getCurrentDomain(),
                        },
                      ]);
                    } catch (err) {
                      console.log(err);
                    }
                  };
                }}
                sx={{
                  borderRadius: "6px",
                  boxShadow: "none",
                  height: "40px",
                  padding: "8px 24px",
                  ...(textColor && {
                    // @ts-ignore
                    color: textColor.hex
                      ? // @ts-ignore
                        `#${textColor.hex}`
                      : textColor.toString(),
                  }),
                  ...(backgroundColor && {
                    // @ts-ignore
                    backgroundColor: backgroundColor.hex
                      ? // @ts-ignore
                        `#${backgroundColor.hex}`
                      : backgroundColor.toString(),
                  }),
                }}
              >
                {values.text}
              </Button>
            </Box>
          </>
        ),
        xs: 12,
        mt: 1,
      },
      {
        xs: 12,
        component: (
          <>
            <FormControlLabel
              control={<Switch name="autopop" />}
              value={autoPopUp}
              onChange={() => setAutoPopUp((current) => !current)}
              label={
                <Text ml={1.5} variant="headline">
                  Autopopup Form
                </Text>
              }
            />
            <Text ml="60px" color={theme.palette.neutral[600]}>
              {` When your website is visited, your ${type} will automatically
              pop above your website.`}
            </Text>
          </>
        ),
      },
    ];
    return arr;
  }, [backgroundColor, textColor, values.text, boxStyle, autoPopUp]);

  return (
    <>
      <Box mb={2}>
        <FadeUpWrapper delay={100}>
          <Tabs
            size="large"
            value={tabValue}
            variant="fullWidth"
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Embed Popup Widget" />
            <Tab label="Embed iFrame" />
          </Tabs>
        </FadeUpWrapper>
      </Box>
      <TabPanel index={0} value={tabValue} sx={{ pb: 0 }}>
        <FadeUpWrapper delay={150}>
          <Text variant="h5" fontWeight="semibold">
            Popup Widget Settings
          </Text>
        </FadeUpWrapper>
        <FadeUpWrapper delay={200}>
          <Text color={theme.palette.neutral[600]}>
            {`Customize the display of the button that will pop your ${type}.`}
          </Text>
        </FadeUpWrapper>

        <FormProvider {...methods}>
          <Grid
            mt={1}
            mb={2}
            container
            rowSpacing={2}
            columnSpacing={1}
            component="form"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            {gridItem.map((c, idx) => (
              <Grid item key={idx} mt={c.mt} xs={c.xs}>
                <FadeUpWrapper delay={200 + idx * 50}>
                  {c.component}
                </FadeUpWrapper>
              </Grid>
            ))}
          </Grid>
        </FormProvider>
        <Box>
          <FadeUpWrapper delay={300}>
            <Text variant="h5" fontWeight="semibold">
              Popup Widget Code
            </Text>
          </FadeUpWrapper>

          <FadeUpWrapper delay={350}>
            <Text color={theme.palette.neutral[600]}>
              Copy and paste this code anywhere in your website&apos;s HTML to
              pop the widget.
            </Text>
          </FadeUpWrapper>
          <FadeInWrapper delay={400}>
            <Box sx={inputBox}>
              <Input
                fullWidth
                type="text"
                value={minify_html(widgetText)}
                multiline
                rows={isMobileView ? 12 : 9}
                disabled
                sx={inputStyle}
              />
              <CopyToClipboard
                text={minify_html(widgetText)}
                onCopy={() => setIsCopied(true)}
              >
                <Button
                  startIcon={isCopied && <CheckMarkIcon />}
                  sx={{ ...(isCopied && { ...btnCopied }) }}
                >
                  {isCopied ? "Copied" : "Copy Code"}
                </Button>
              </CopyToClipboard>
            </Box>
          </FadeInWrapper>
        </Box>
      </TabPanel>
      {/** ====================== PANEL 2 ====================== */}
      <TabPanel index={1} value={tabValue} sx={{ pt: 1, pb: 0 }}>
        <FadeUpWrapper delay={100}>
          <Text variant="h5" fontWeight="semibold">
            Embed iFrame Code
          </Text>
        </FadeUpWrapper>

        <FadeUpWrapper delay={150}>
          <Text color={theme.palette.neutral[600]}>
            {`Copy and paste this code anywhere in your website's HTML where
            you want the ${type} to show.`}
          </Text>
        </FadeUpWrapper>

        <FadeInWrapper delay={250}>
          <Box
            sx={{
              ...inputBox,
              maxWidth: "100%",
              maxHeight: "100%",
              overflow: "hidden",
            }}
          >
            <Input
              fullWidth
              type="text"
              value={iFrameText}
              multiline
              rows={isMobileView ? 8 : 4}
              disabled
              sx={inputStyle}
            />
            <CopyToClipboard
              text={iFrameText}
              onCopy={() => setIsIFrameCopied(true)}
            >
              <Button
                startIcon={isIFrameCopied && <CheckMarkIcon />}
                sx={{ ...(isIFrameCopied && { ...btnCopied }) }}
              >
                {isIFrameCopied ? "Copied" : "Copy Code"}
              </Button>
            </CopyToClipboard>
          </Box>
        </FadeInWrapper>
      </TabPanel>
    </>
  );
}
