import { useRiskActivity } from "@components/Merchants/MerchantPreview/RiskProfile/hooks/useRiskActivity";
import { TRiskActivity } from "@components/Merchants/MerchantPreview/RiskProfile/types";
import { Box, Divider, Stack, SxProps } from "@mui/material";

import { Pulse } from "@phosphor-icons/react";
import GiveText from "@shared/Text/GiveText";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { styled, useAppTheme } from "@theme/v2/Provider";
import React from "react";

type Props = {
  profileId: number;
  merchantId: number;
  riskLabel: string;
  sx?: SxProps;
};

const RiskActivity = ({ profileId, merchantId, riskLabel, sx }: Props) => {
  const { data } = useRiskActivity(profileId, merchantId);
  const hasRiskActivity = data.length !== 0;

  const activityTitle = hasRiskActivity ? data[0]?.merchantFactorName : "None";
  const createdAt = hasRiskActivity ? data[0]?.createdAt : "";

  return (
    <GiveTooltip
      color="light"
      data-testid="tooltip-risk-level"
      title={<RiskActivityHoverPopover data={data[0]} riskLabel={riskLabel} />}
      disableFocusListener={!hasRiskActivity}
      disableHoverListener={!hasRiskActivity}
      disableTouchListener={!hasRiskActivity}
    >
      <RiskActivityContainer sx={sx}>
        <Pulse />
        <Stack flexGrow={1} gap={1}>
          <Stack direction="row" justifyContent="space-between">
            <GiveText color={"primary"} fontSize="14px">
              Risk Activity
            </GiveText>
            <GiveText color={"primary"} fontSize="12px">
              {createdAt}
            </GiveText>
          </Stack>
          <GiveText color={"secondary"} fontSize="14px">
            {activityTitle}
          </GiveText>
        </Stack>
      </RiskActivityContainer>
    </GiveTooltip>
  );
};

const RiskActivityContainer = styled(Stack)(({ theme }) => ({
  padding: "12px 16px",
  borderRadius: "16px",
  border: `1px solid ${theme.palette.primitive?.neutral[20]}`,
  backgroundColor: "#F7F7F7",
  flexDirection: "row",
  gap: "8px",
  width: "100%",
  cursor: "pointer",
}));

export default RiskActivity;

const RiskActivityHoverPopover = ({
  data,
  riskLabel,
}: {
  data: TRiskActivity;
  riskLabel: string;
}) => {
  const { palette } = useAppTheme();
  const items = [
    {
      label: data?.merchantFactorName,
      value: data?.merchantFactorDescription,
      showBorder: true,
      hidden: riskLabel === "low",
    },
    {
      label: "Last Escalation Date",
      value: data?.createdAt,
      showBorder: false,
      hidden: false,
    },
  ];
  return (
    <ItemsContainer data-testid="risk-activity-tooltip-popover">
      {items
        .filter((item) => !item.hidden)
        .map((item, index) => (
          <React.Fragment key={index}>
            <ItemBox>
              <Header>{item.label}</Header>
              <Header
                sx={{ color: `${palette.primitive?.neutral[70]} !important` }}
              >
                {item.value}
              </Header>
            </ItemBox>
            {item.showBorder && <Divider />}
          </React.Fragment>
        ))}
    </ItemsContainer>
  );
};

const Header = styled(GiveText)(({ theme }) => ({
  color: theme.palette.primitive?.neutral[100],
  fontSize: "12px",
  fontWeight: 350,
  lineHeight: "14.4px",
  textAlign: "start",
}));

const ItemBox = styled(Box)({
  width: "100%",
  gap: "4px",
  paddingBottom: "12px",
  paddingTop: "12px",
});

const ItemsContainer = styled(Box)({
  width: "100%",
  padding: "0px 16px",
  justifyContent: "space-between",
  display: "flex",
  flexDirection: "column",
});
