// Update the Typography's variant prop options
import { ThemeVersions } from "@theme/v2/types";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    bodyL: true;
    bodyM: true;
    bodyXXS: true;
    bodyS: true;
    bodyXS: true;
  }

  interface TypographyOwnProps {
    version?: ThemeVersions;
  }
}

const FONT_PRIMARY = "Give Whyte, sans-serif";

export const typography = {
  fontFamily: FONT_PRIMARY,
  h1: {
    fontSize: "44px",
    lineHeight: "48px",
    textDecoration: "none",
    fontWeight: 271,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: -1.32,
    "@media (max-width:1399px)": {
      fontSize: "36px",
      lineHeight: "40px",
      letterSpacing: -1.08,
    },
  },
  h2: {
    fontSize: "36px",
    lineHeight: "40px",
    textDecoration: "none",
    fontWeight: 271,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: -1.08,
    "@media (max-width:1399px)": {
      fontSize: "32px",
      lineHeight: "36px",
      letterSpacing: -0.96,
    },
  },
  h3: {
    fontSize: "28px",
    lineHeight: "34px",
    textDecoration: "none",
    fontWeight: 350,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: -0.84,
    textCase: "none",
  },
  h4: {
    fontSize: "24px",
    lineHeight: "28px",
    textDecoration: "none",
    fontWeight: 350,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: -0.24,
    textCase: "none",
    "@media (max-width:1399px)": {
      fontSize: "22px",
      lineHeight: "26px",
      letterSpacing: -0.22,
    },
  },
  h5: {
    fontSize: "20px",
    lineHeight: "24px",
    textDecoration: "none",
    fontWeight: 400,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: 0,
    textCase: "none",
  },
  h6: {
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 400,
    letterSpacing: 0,
  },
  bodyL: {
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 400,
    letterSpacing: 0,
  },
  bodyM: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  body: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  bodyS: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  bodyXS: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
  },
  bodyXXS: {
    fontWeight: "400",
    fontSize: "11px",
    lineHeight: "14px",
  },
  button: {
    fontSize: "14px",
    lineHeight: "24px",
    textTransform: "none",
  },
  buttonSmall: {
    fontSize: "12px",
    lineHeight: "20px",
    textTransform: "none",
  },
  caption: {
    fontSize: "12px",
    lineHeight: "16px",
    textTransform: "none",
  },
  captionSmall: {
    fontSize: "10px",
    lineHeight: "14px",
    textTransform: "none",
  },
  smallest: {
    fontSize: "14px",
    lineHeight: "18px",
    textTransform: "none",
  },
  headline: {
    fontSize: "16px",
    lineHeight: "22.4px",
  },
  link: {
    fontSize: "14px",
    lineHeight: "21px",
    justifyItems: "baseline",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
};
