import { showMessage } from "@common/Toast";
import { customInstance } from "@services/api";
import { useMutation, useQueryClient } from "react-query";
import { TRiskStatus } from "../types";
import {
  QKEY_LIST_ACQUIRER_MERCHANTS,
  QKEY_LIST_ENTERPRISE_MERCHANTS,
} from "@constants/queryKeys";
import { composePermission, useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";

const useUpdateRiskProfile = (profileId: number, merchantId: number) => {
  const queryClient = useQueryClient();

  const isAllowedUpdate = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.RISK_PROFILE,
    ),
    operation: OPERATIONS.UPDATE,
  });

  const updateEscalation = useMutation((data: any) => {
    return customInstance({
      url: `/merchants/${merchantId}/risk/merchant-profiles/${profileId}`,
      method: "PATCH",
      data,
    });
  });

  const handleChange = (status: TRiskStatus) => {
    if(!isAllowedUpdate) return;
    updateEscalation.mutate(
      { riskStatus: status },
      {
        onError: (err: any) => {
          showMessage("Error", err?.response?.data?.message);
        },
        onSuccess: () => {
          queryClient.refetchQueries(["get-merchant-risk-profile", profileId]);
          queryClient.invalidateQueries(QKEY_LIST_ACQUIRER_MERCHANTS);
          queryClient.invalidateQueries(QKEY_LIST_ENTERPRISE_MERCHANTS);
        },
      },
    );
  };

  return { handleChange, isAllowedUpdate };
};

export default useUpdateRiskProfile;
