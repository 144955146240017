import { useInfiniteEnterprises } from "@common/BusinessProfileInputs/EnterprisesSelect";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import ListItem from "@common/TableFilters/ListItem";
import Selector from "@common/TableFilters/Selector";
import {
  SelectorOption,
  customJoinList,
  mapRange,
} from "@common/TableFilters/utils";
import { useInfiniteMerchants } from "@components/Settlement/hooks/useSettlementInfiniteMerchants";
import {
  QKEY_LIST_ACQUIRER_MERCHANTS,
  QKEY_LIST_ENTERPRISES,
} from "@constants/queryKeys";
import { Stack } from "@mui/material";
import { palette } from "@palette";
import { useAppSelector } from "@redux/hooks";
import { selectQueryString } from "@redux/slices/search";
import { checkPortals } from "@utils/routing";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  TransferFilterFormFields,
  TransferFormValuesType,
  transferFilterDefaultValues,
} from "../utils";
import { FiltersContainer } from "@common/TableFilters/CommonFiltersContainer";
import { isEqual } from "lodash";
import {
  resetFilters,
  selectedQueries,
  selectedQueryString,
  setFilters,
} from "@redux/slices/tableFilters";
import { useDispatch } from "react-redux";

export const useTransferFilter = () => {
  const { onClose } = useNiceModal();
  const dispatch = useDispatch();
  const { isAcquirerPortal } = checkPortals();
  const contextQueryString = useAppSelector(selectedQueryString);
  const contextQueries = useAppSelector(selectedQueries);

  const { data: providers, handleScroll: handleScrollEnterprises } =
    useInfiniteEnterprises();
  const {
    data: merchants,
    handleScroll: handleScrollMerchants,
    isLoading: loadingMerchants,
  } = useInfiniteMerchants();

  const methods = useForm<TransferFormValuesType>({
    defaultValues: {
      ...transferFilterDefaultValues,
      ...contextQueries,
    },
  });
  const values = methods.watch();

  const areFiltersSelected =
    !isEqual(transferFilterDefaultValues, values) || contextQueryString;

  const clearAllEnabled =
    (areFiltersSelected && !isEqual(transferFilterDefaultValues, values)) ||
    false;

  const handleReset = () => {
    dispatch(resetFilters());
    methods.reset(transferFilterDefaultValues);
  };

  const actions = {
    primaryAction: {
      label: "Apply filters",
      disabled: !areFiltersSelected,
      sx: {
        fontSize: "18px",
        lineHeight: "18px",
        padding: "12px 24px",
      },
    },
    secondaryAction: {
      label: "Clear all filters",
      onClick: handleReset,
      sx: {
        fontSize: "18px",
        lineHeight: "18px",
        padding: "12px 24px",
        color: palette.filled.red,
      },
      hidden: !clearAllEnabled,
    },
  };

  const enterpriseOptions = useMemo(
    () =>
      providers.map((item) => ({
        label: item.name,
        id: item.accID,
        imageURL: item.imageURL,
        value: item.name,
      })),
    [providers],
  );

  const merchantOptions = useMemo(
    () =>
      merchants.map((item) => ({
        label: item.name,
        id: item.accID,
        imageURL: item.imageURL,
        value: item.name,
      })),
    [merchants],
  );

  const handleMultiSelect = (
    fieldName: TransferFilterFormFields,
    value: SelectorOption | SelectorOption[],
  ) => {
    const currentValues: SelectorOption[] =
      !!values[fieldName] && Array.isArray(values[fieldName])
        ? (values[fieldName] as SelectorOption[])
        : [];

    // replace form value if the value param is an array
    if (Array.isArray(value)) {
      methods.setValue(fieldName, value);
      return;
    }
    if (currentValues && currentValues.find((item) => item.id === value.id)) {
      methods.setValue(
        fieldName,
        currentValues?.filter((item) => item.id !== value.id),
      );
    } else {
      const vals = currentValues ? [...currentValues, value] : [value];
      methods.setValue(fieldName, vals);
    }
  };

  const transferSections = useMemo(() => {
    return [
      {
        title: "Provider name",
        description: "Filter by Provider Name",
        hidden: !isAcquirerPortal,
        endElement: (
          <Selector
            options={enterpriseOptions}
            onScroll={handleScrollEnterprises}
            loading={false}
            queryKey={QKEY_LIST_ENTERPRISES}
            value={values?.enterpriseName}
            handleChange={(val) =>
              handleMultiSelect("enterpriseName", val as SelectorOption)
            }
            handleDeselect={(val) => handleMultiSelect("enterpriseName", val)}
            multiSelect
          />
        ),
        children: (
          <Stack spacing="6px" paddingTop="8px">
            {Boolean(values.enterpriseName) &&
              values.enterpriseName?.map((item: SelectorOption) => (
                <ListItem
                  key={item.label}
                  item={item}
                  onDelete={(val) => handleMultiSelect("enterpriseName", val)}
                />
              ))}
          </Stack>
        ),
      },
      {
        title: "Merchant name",
        description: "Filter by Merchant Name",
        endElement: (
          <Selector
            options={merchantOptions}
            onScroll={handleScrollMerchants}
            loading={loadingMerchants}
            queryKey={QKEY_LIST_ACQUIRER_MERCHANTS}
            value={values?.merchantName}
            handleChange={(val) =>
              handleMultiSelect("merchantName", val as SelectorOption)
            }
            handleDeselect={(val) => handleMultiSelect("merchantName", val)}
            multiSelect
          />
        ),
        children: (
          <Stack spacing="6px" paddingTop="8px">
            {Boolean(values.merchantName) &&
              values.merchantName?.map((item: SelectorOption) => (
                <ListItem
                  key={item.label}
                  item={item}
                  onDelete={(val) => handleMultiSelect("merchantName", val)}
                />
              ))}
          </Stack>
        ),
      },
      {
        title: "Transfer Status",
        children: (
          <FiltersContainer
            filters={filters}
            fieldName="transferStatus"
            containerSx={{ padding: 0 }}
            disabledCustom
          />
        ),
      },
      {
        title: "Amount",
        description: "Amount of the transfer",
        children: (
          <FiltersContainer
            filters={amountFilters}
            fieldName="amount"
            limits={{ min: 10 }}
            containerSx={{ padding: 0 }}
          />
        ),
      },
    ];
  }, [values, enterpriseOptions, merchantOptions]);

  const onSubmit = (data: TransferFormValuesType) => {
    let queryString = "";
    if (data.enterpriseName?.length > 0) {
      queryString += "(";
      queryString += customJoinList(
        data.enterpriseName.map(
          (enterprise) => `parentAccName:"${enterprise.label}"`,
        ),
        ",",
      );
      queryString += ")%3B";
    }

    if (data.merchantName?.length > 0) {
      queryString += "(";
      queryString += customJoinList(
        data.merchantName.map((merchant) => `merchAccName:"${merchant.label}"`),
        ",",
      );
      queryString += ")%3B";
    }

    if (data.transferStatus?.length > 0) {
      queryString += "(";
      queryString += customJoinList(
        data.transferStatus.map((status) => `displayStatus:"${status}"`),
        ",",
      );
      queryString += ")%3B";
    }

    if (data.amount?.length > 0) {
      const amount = data?.amount?.[0] as string;
      let amountQs = "";
      switch (amount) {
        case "10 - 1000 USD":
          amountQs = "(cost:>=1000%3Bcost:<=100000)";
          break;
        case "1000 - 5000 USD":
          amountQs = "(cost:>=100000%3Bcost:<=500000)";
          break;
        case ">5000 USD":
          amountQs = "cost:>500000";
          break;
        case "Custom":
          amountQs = `(${data?.custom?.replace(/amount/g, "cost")})`;
          break;
      }
      queryString += amountQs;
    }

    dispatch(
      setFilters({
        queryParams: data,
        queryString,
      }),
    );

    onClose();
  };

  return {
    actions,
    methods,
    onSubmit,
    transferSections,
  };
};

const filters = [
  "Requested",
  "Ready to Process",
  "Sending",
  "Sent",
  "Error",
  "Declined",
  "Canceled",
  "Returned",
];

const amountFilters = [
  "10 - 1000 USD",
  "1000 - 5000 USD",
  ">5000 USD",
  "Custom",
];
