import { Button } from "@common/Button";
import { TDocument } from "@common/FilePreview/types";
import { Text } from "@common/Text";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import { useDocumentPreview } from "@hooks/common/documents";
import { downloadDocument } from "@hooks/common/documents/utils";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { Eye } from "@phosphor-icons/react";

type Props = {
  documentList: TMerchantDocument[];
};

const EvidenceDocumentList = ({ documentList }: Props) => {
  const { handlePreview } = useDocumentPreview({
    list: documentList,
    merchantID: 0,
    handlers: { handleDownload },
  });

  return (
    <Stack spacing={0.5}>
      {documentList.map((document, index) => (
        <DocumentContainer key={document?.id ?? index}>
          <Box display="flex" justifyContent="space-between">
            <Text
              fontWeight="book"
              color={palette.neutral[80]}
              lineHeight="16.8px"
            >
              {document.fileName}
            </Text>
            <StyledButton data-testid={`preview-icon-${index}`} onClick={() => handlePreview(document)}>
              <Eye height={18} width={18} fill={palette.neutral[70]} />
            </StyledButton>
          </Box>
          <Text
            variant="caption"
            fontWeight="book"
            color={palette.neutral[70]}
            lineHeight="14.4px"
          >
            {document?.fileSize}
          </Text>
          {document.notes && (
            <NoteContainer>
              <Text
                variant="caption"
                fontWeight="book"
                color={palette.neutral[80]}
                lineHeight="14.4px"
              >
                {document.notes}
              </Text>
            </NoteContainer>
          )}
        </DocumentContainer>
      ))}
    </Stack>
  );
};

const handleDownload = (document: TDocument | null) => {
  if (!document) return;

  downloadDocument({
    fileName: document?.name || "undefined",
    fileURL: document.URL,
  });
};

const DocumentContainer = styled(Box)(() => ({
  padding: "8px",
  borderRadius: "4px",
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  background: palette.neutral.white,
}));

const NoteContainer = styled(Box)(() => ({
  padding: "12px 16px",
  borderRadius: "8px",
  border: `1px solid ${palette.neutral[10]}`,
  background: palette.neutral[5],
  boxShadow: "box-shadow: 0px 0px 10px 0px #1E1E1E0D inset",
}));

const StyledButton = styled(Button)(() => ({
  padding: "4px 8px",
  borderRadius: "8px",
  background: palette.neutral.white,
  boxShadow: "0px 4px 8px 0px #00000014",
  height: "26px",
  width: "34px",
  minWidth: "unset",
  "&:hover": {
    background: palette.neutral[10],
    boxShadow: "0px 4px 8px 0px #00000014",
  },
}));

export default EvidenceDocumentList;
