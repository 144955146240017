import { Box, Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { ChatsCircle } from "@phosphor-icons/react";
import { styled } from "@theme/v2/Provider";
import GiveLink from "@shared/Link/GiveLink";
import GiveButton from "@shared/Button/GiveButton";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { useStatusReview } from "@components/Merchants/MerchantPreview/hooks/useStatusReview";
import { checkPortals } from "@utils/routing";
import useCheckSponsor, {
  useCheckSponsorRole,
} from "@hooks/common/useCheckSponsor";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import SponsorStatusChip from "./components/SponsorStatusChip";
import ChipMenu from "../ChipMenu";
import { useUpdateUnderwriting } from "@components/Merchants/MerchantPreview/hooks/useUnderwritingUpdate";
import {
  QKEY_LIST_ACQUIRER_MERCHANTS,
  QKEY_LIST_MERCHANT_STATS,
} from "@constants/queryKeys";
import { showMessage } from "@common/Toast";
import { ApprovalTypeInputs } from "@components/Merchants/MerchantPreview/modals/MerchantInitialApprovalModal";
import { useQueryClient } from "react-query";
import { capitalizeEachWord } from "@utils/index";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import { useAppSelector } from "@redux/hooks";
import { selectMerchantStatusName } from "@redux/slices/enterprise/merchants";
import { StatusNames } from "@components/Merchants/MerchantList/MerchantStatusFilters";

const SponsorApprovalSection = () => {
  const { data: rootData } = useMerchantSidePanelContext();
  const name = rootData?.merchantInfo?.merchantName;
  const data = rootData.status;
  const { isAcquirerPortal } = checkPortals();
  const { hasSponsorAccess } = useCheckSponsor();
  const { isSponsor } = useCheckSponsorRole();
  const { merchant_underwriting } = useEnterprisePermissions();
  const { isUpdateUnderwriterAllowed } = useUnderwriterPermissions();
  const { openConversationHandler } = useConversationsModal();
  const { updateUnderwriting } = useUpdateUnderwriting(data?.accID);
  const queryClient = useQueryClient();
  const { handleSponsorApprove, handleSponsorDecline, handleMarkAsPending } =
    useStatusReview({ data, name });
  const statusName = useAppSelector(selectMerchantStatusName);

  const sponsorStatusName = data?.sponsorStatusName;

  const handleOpenConversation = () =>
    openConversationHandler({
      id: 0,
      name: "Sponsor",
      paths: [],
    });

  const showActionButtons =
    ["pending", "ready_for_review"].includes(sponsorStatusName || "") &&
    statusName === StatusNames.aquirerSponsor;

  const canUpdateApprovalStatus =
    !isSponsor && isUpdateUnderwriterAllowed && sponsorStatusName !== "pending";

  const currentStatusName = data?.statusName?.startsWith("pre")
    ? "pre-approval"
    : "auto-approval";

  const handleChangeApprovalType = async (value: ApprovalTypeInputs) => {
    updateUnderwriting.mutate(value, {
      onSuccess: () => {
        queryClient.invalidateQueries("get-merchant-msp-status");
        queryClient.refetchQueries(QKEY_LIST_MERCHANT_STATS);
        queryClient.refetchQueries(QKEY_LIST_ACQUIRER_MERCHANTS);
        queryClient.refetchQueries(["get-merchant-preview", data?.accID]);
      },
      onError: () => {
        queryClient.refetchQueries("get-merchant-preview");
        showMessage(
          "Error",
          "Merchant account approval failed. Ensure all required information is accurate and approved",
        );
      },
    });
  };

  const approvalTypeOptions = [
    {
      text: "Pre Approval",
      onClick: () => handleChangeApprovalType({ status: "pre-approval" }),
    },
    {
      text: "Auto Approval",
      onClick: () => handleChangeApprovalType({ status: "auto-approval" }),
    },
  ];

  return (
    <Stack gap="21px">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" gap="10px">
          <GiveText>Sponsor Approval</GiveText>
          <SponsorStatusChip status={sponsorStatusName} />
        </Stack>
        {merchant_underwriting && isAcquirerPortal && (
          <GiveIconButton
            onClick={handleOpenConversation}
            Icon={ChatsCircle}
            variant="ghost"
          />
        )}
      </Stack>
      <ContentStack>
        <Stack direction="row" justifyContent="space-between">
          <GiveText color="secondary">Recommendation</GiveText>
          <ChipMenu
            options={approvalTypeOptions}
            chipProps={{
              color: "success",
              variant: "light",
            }}
            label={capitalizeEachWord(currentStatusName, "-")}
            canOpen={canUpdateApprovalStatus}
          />
        </Stack>

        {sponsorStatusName === "pending" && (
          <ReasonContainer>
            <GiveText color="secondary" variant="bodyS">
              Reason
            </GiveText>
            <GiveText variant="bodyS">{data?.sponsorStatusReason}</GiveText>
          </ReasonContainer>
        )}

        {hasSponsorAccess && isAcquirerPortal && showActionButtons && (
          <ActionButtonsStack>
            <Box>
              {sponsorStatusName !== "pending" && (
                <GiveLink component="button" onClick={handleMarkAsPending}>
                  Mark as Pending
                </GiveLink>
              )}
            </Box>
            <Stack direction="row" gap="12px">
              <GiveButton
                label="Decline"
                color="destructive"
                variant="ghost"
                size="small"
                onClick={handleSponsorDecline}
              />
              <GiveButton
                label="Approve"
                size="small"
                onClick={handleSponsorApprove}
              />
            </Stack>
          </ActionButtonsStack>
        )}
      </ContentStack>
    </Stack>
  );
};

const ContentStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2.5),
  borderRadius: theme.spacing(2.5),
  gap: "16px",
  backgroundColor: theme.palette?.surface?.primary,
}));

const ReasonContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette?.primitive?.transparent?.["darken-5"],
}));

const ActionButtonsStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  paddingTop: theme.spacing(2),
  borderTop: `1px solid ${theme.palette?.border?.primary}`,
}));

export default SponsorApprovalSection;
