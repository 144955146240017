import { SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  selectConversationTopic,
  setConversationTopic,
} from "@redux/slices/conversations";
import { customInstance } from "@services/api";
import { useMutation, useQueryClient } from "react-query";
import {
  getGlobalTopic,
  getGlobalTopicLabel,
} from "../hooks/useConversationsModal";
import { showMessage } from "@common/Toast";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useUpdateChallenge } from "@components/Merchants/MerchantPreview/hooks/useUpdateChallenge";

type IFormInputs = {
  subject: string;
  message: string;
  module: string;
  isInternalNote: boolean;
};

type Args = {
  isThreadSelected: boolean;
  refetch: any;
  merchantName?: string;
  isThreadClosed: boolean;
  isRiskMonitor: boolean;
  merchantID: number;
};

const useUnderwritingThreads = ({
  isThreadSelected,
  refetch,
  merchantName,
  isThreadClosed,
  isRiskMonitor,
  merchantID,
}: Args) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { merchantId: loggedMID } = useGetCurrentMerchantId();
  const isThreadClosedRiskMonitor = isThreadClosed && isRiskMonitor;

  const { queryObject } = useAppSelector(selectConversationTopic);
  const pathThread = queryObject?.paths?.find((item) => item.isConversation);

  const hideInputs = pathThread?.hideInputs || [];
  const isRejection = pathThread?.isRejection || false;
  const filteredInputs = isThreadSelected ? ["subject", "module"] : [];
  const id = pathThread?.pathID;
  const threadId = id && id !== "new" ? id : null;
  const topic2Name = pathThread?.topicName || "";

  const { handleUpdateChallengeStatus } = useUpdateChallenge({
    challengeId: queryObject?.challengeId || 0,
  });

  const methods = useForm<IFormInputs>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(generateSchema([...hideInputs, ...filteredInputs])),
    defaultValues: defaultValues,
  });

  const {
    formState: { isValid },
  } = methods;

  const rejectCustomChallenge = () =>
    handleUpdateChallengeStatus({
      merchantId: queryObject?.merchantId || 0,
      data: { status: "open", type: "enhanced_due_diligence" },
    });

  const { mutate, isLoading } = useMutation({
    mutationFn: async (payload: any) => {
      const { data: topics } = await getGlobalTopic({});

      const findTopic = (name: string, type: string) =>
        topics?.find(
          (item: any) =>
            [item?.name, item?.Name]?.includes(name) &&
            [item?.typeName, item?.Type]?.includes(type),
        );

      const internalTopic = findTopic("underwriting", "internal");
      const activityTopic = findTopic("underwriting", "activity");
      const riskMonitorInternal = findTopic("risk_activity", "internal");

      const customPayload = {
        ...payload,
        topicID: Number(
          payload?.module || activityTopic?.id || activityTopic?.ID,
        ),
      };

      const postActivity = await customInstance({
        url: threadId
          ? `/merchants/${loggedMID}/threads/${threadId}/messages`
          : `/merchants/${loggedMID}/threads`,
        method: "POST",
        data: { ...customPayload, title: payload?.subject },
      });

      const isRiskMonitor = postActivity?.topicName === "risk_monitor";

      if (payload?.isInternalNote) {
        const internalNoteTopicID = isRiskMonitor
          ? riskMonitorInternal?.ID
          : internalTopic?.ID;

        await customInstance({
          url: `/merchants/${loggedMID}/threads`,
          method: "POST",
          data: {
            ...customPayload,
            topicID: internalNoteTopicID,
            originalThreadID: threadId || postActivity?.id || postActivity?.ID,
          },
        });
      }

      return postActivity;
    },
    onError(error: any) {
      if (error?.response?.data?.message?.includes("already exist")) {
        return methods.setError("subject", {
          type: "manual",
          message: error?.response?.data?.message,
        });
      }
      showMessage(
        "Error",
        error?.response?.data?.message || "Something went wrong",
      );
    },
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("fetch-activity-list");
      queryClient.refetchQueries("get-challenges");

      if (isRejection) {
        rejectCustomChallenge();
      }

      if (!threadId) {
        const currentPath = queryObject?.paths?.filter(
          (item: any) => !item.isConversation,
        );

        const paths = [
          ...currentPath,
          {
            pathID: data.topicID,
            pathName: getGlobalTopicLabel(data?.topicName) || data?.topicName,
            avatars: data?.authorAvatarImageURL
              ? [data?.authorAvatarImageURL]
              : [],
          },
          {
            avatars: [],
            isConversation: true,
            pathName: data?.title,
            pathID: data?.id,
            hideInputs: ["module", "subject"],
            topicName: topic2Name,
          },
        ];

        dispatch(
          setConversationTopic({
            isOpen: true,
            isOpenedFromSidePanel: false,
            queryObject: {
              ...queryObject,
              id: data?.topicID,
              name: merchantName || queryObject?.name || "",
              paths: paths,
            },
          }),
        );
      } else {
        refetch();
      }

      methods.reset();
    },
  });

  const handleSubmit: SubmitHandler<IFormInputs> = async (data) => {
    const payload = {
      ...data,
      module: pathThread?.moduleID || data?.module,
      subject: data?.subject,
      subjectAccID: merchantID,
      topicID: Number(data?.module),
      body: data?.message,
      message: {
        body: data?.message,
        subjectAccID: merchantID,
      },
    };

    if (isThreadClosed) {
      showMessage(
        "Error",
        "Not allowed to reply to the same message more than once",
      );
      methods.reset();
    } else {
      mutate(payload);
    }
  };

  return {
    isRejection,
    methods,
    handleSubmit,
    disableSubmit: !isValid || isLoading,
    hideInput: {
      module:
        isThreadSelected ||
        hideInputs.includes("module") ||
        isRejection ||
        isThreadClosedRiskMonitor,
      subject:
        isThreadSelected ||
        hideInputs.includes("subject") ||
        isRejection ||
        isThreadClosedRiskMonitor,
      message: hideInputs.includes("message") || isThreadClosedRiskMonitor,
    },
  };
};

export default useUnderwritingThreads;

const defaultValues = {
  subject: "",
  message: "",
  module: "",
  isInternalNote: true,
};

const generateYup = ({
  key,
  optionalFields,
  max,
}: {
  key: string;
  optionalFields: string[];
  max: number;
}) =>
  optionalFields.includes(key)
    ? Yup.string()?.max(max)
    : Yup.string().required(`${key} is required`)?.max(max);

const generateSchema = (optionalFields: string[] = []) => {
  return Yup.object().shape({
    subject: generateYup({ key: "subject", optionalFields, max: 24 }),
    message: generateYup({ key: "message", optionalFields, max: 500 }),
    module: generateYup({ key: "module", optionalFields, max: 30 }),
    isInternalNote: Yup.boolean(),
  });
};
