import { IParsedData } from "@components/Merchants/MerchantPreview/data.types";
import { betterPhoneNumber, parseTaxID } from "./helpers";
import { businessTypes } from "@common/BusinessProfileInputs/BusinessTypeSelect";
import { capitalizeFirstLetter } from "@common/Table/helpers";
import { getCountryNameFromCode } from "@utils/country_dial_codes";
import { format } from "date-fns";
import { toCountryName } from "@hooks/helpers";
import { ReactNode } from "react";
// import GiveChip from "@shared/Chip/GiveChip";

export interface ISectionItem {
  key?: number;
  title: string;
  value: string | number | ReactNode;
  isPhoneNumber?: boolean;
  isLink?: boolean;
  canBeCopied?: boolean;
  hideOverflow?: boolean;
  split?: boolean;
  suf?: string;
  highlight?: boolean;
  isAmount?: boolean;
}

const useGetSectionItems = (data: IParsedData) => {
  const {
    businessProfile,
    businessAddress,
    primaryAccountHolder,
    merchantInfo,
  } = data;
  const merchantInfoItems: ISectionItem[] = [
    {
      title: "Merchant Name",
      value: merchantInfo.merchantName,
    },
    {
      title: "Provider",
      value: merchantInfo.enterprise,
    },
    {
      title: "Classification",
      value: merchantInfo.classification.displayName,
    },
    {
      title: "Signup Date",
      value: merchantInfo.createdAt,
    },
    {
      title: "Signup type",
      value: merchantInfo.signupTypeDisplayName,
    },
    {
      title: "Estimated Annual Revenue (USD)",
      value: +merchantInfo.estimatedAnnualRevenue,
      isAmount: true,
    },
    {
      title: `Billing Descriptor (${data.merchantInfo.billingDescriptorPrefix})`,
      value: merchantInfo.billingDescriptor,
    },
    {
      title: "Merchant Phone Number",
      value: betterPhoneNumber(merchantInfo.servicePhoneNumber),
      isPhoneNumber: true,
    },
    {
      title: "Website URL",
      value: `https://${merchantInfo.websiteURL}` || "",
      isLink: true,
      hideOverflow: true,
      canBeCopied: true,
    },
    {
      title: "Permalink",
      value: `https://${merchantInfo.parentSlug}.givepayments.com/${data.merchantInfo.merchantSlug}`,
      isLink: true,
      hideOverflow: true,
      canBeCopied: true,
    },
    {
      title: "Countries Serviced Outside the USA/Canada",
      value: merchantInfo?.serviceCountriesOutUSCanada
        ? merchantInfo?.countriesServicedOutside
        : "None",
    },
    {
      title: "Average Ticket Amount (USD)",
      value: merchantInfo.averageTicketAmount ?? 0,
      split: true,
      isAmount: true,
    },
    {
      title: "High Ticket Amount (USD)",
      value: merchantInfo.highTicketAmount ?? 0,
      isAmount: true,
    },
    {
      title: `Purpose or Mission of Merchant`,
      value: merchantInfo?.businessPurpose || merchantInfo?.description,
      split: true,
    },
  ];

  const primaryAccountHolderItems: ISectionItem[] = [
    {
      title: "Full name",
      value:
        primaryAccountHolder?.lastName || primaryAccountHolder?.firstName
          ? `${primaryAccountHolder?.firstName} ${primaryAccountHolder?.lastName}`
          : "",
    },
    // will come in a future US
    // {
    //   title: "PEP Status",
    //   value: <GiveChip title="status" />,
    //   size: 6,
    //   hidden: true,
    // },
    {
      title: "Email",
      value: primaryAccountHolder?.owner?.email || primaryAccountHolder?.email,
    },
    {
      title: "Date of Birth",
      value: primaryAccountHolder.dateOfBirth
        ? format(
            new Date(primaryAccountHolder.dateOfBirth * 1000),
            "MM/dd/yyyy",
          )
        : "",
    },

    {
      title: "Mobile Phone",
      value: betterPhoneNumber(primaryAccountHolder.phoneNumber),
      isPhoneNumber: true,
    },
    {
      title: "Country of Citizenship",
      value: toCountryName(primaryAccountHolder.citizenship),
    },
    {
      title: "Country of Residence",
      value: toCountryName(primaryAccountHolder.countryOfResidence),
    },
  ];

  const businessProfileItems: ISectionItem[] = [
    {
      title: "Legal Name",
      value: businessProfile.legalName,
    },
    {
      title: "Business Type",
      value:
        businessTypes[
          businessProfile.businessType as keyof typeof businessTypes
        ],
    },
    {
      title: "Federal Tax ID",
      value: parseTaxID(businessProfile.taxID),
    },

    {
      title: "Business Ownership Type",
      value: capitalizeFirstLetter(businessProfile.ownershipType),
    },
    {
      title: "Doing Business As (Optional)",
      value: businessProfile.dba,
    },
    {
      title: "Business Creation Date",
      value: businessProfile.businessOpenedAt,
    },
    {
      title: "Business Phone Number",
      value: betterPhoneNumber(businessProfile.contactPhone),
      isPhoneNumber: true,
    },
    {
      title: "Address",
      value: `${businessAddress.address}, ${businessAddress.city}, ${
        businessAddress.state
      }, ${getCountryNameFromCode(businessAddress.country)}, `,
    },
    {
      title: "Age of Business",
      value: businessProfile.ageOfBusiness,
      suf: Number(businessProfile.ageOfBusiness) > 1 ? "years" : "year",
    },
  ];

  return {
    businessProfileItems,
    primaryAccountHolderItems,
    merchantInfoItems,
  };
};

export default useGetSectionItems;
