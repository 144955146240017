import React, { useRef, FocusEvent } from "react";
import { styled, useAppTheme } from "@theme/v2/Provider";
import { Stack } from "@mui/material";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { GiveInput } from "@shared/GiveInputs/GiveInput";
import GiveDatePicker from "@shared/DatePicker/GiveDatePicker";
import useUpdateMerchant from "features/Merchants/MerchantSidePanel/hooks/useUpdateMerchant";
import moment from "moment";

// Define form values type
type FormValues = {
  processorName: string;
  terminationDate: Date | null | string;
  terminationReason: string;
};

interface Props {
  msaPreviousTerminationProcessorName: string | null | undefined;
  msaPreviousTerminationAt: number | Date | null | undefined;
  msaPreviousTerminationReason: string | null | undefined;
}
// Define validation schema with Yup
const validationSchema = Yup.object().shape({
  processorName: Yup.string().required("Processor Name is required"),
  terminationDate: Yup.date()
    .required("Termination Date is required")
    .nullable(),
  terminationReason: Yup.string().required("Termination Reason is required"),
});

function TerminationForm({
  msaPreviousTerminationProcessorName,
  msaPreviousTerminationAt,
  msaPreviousTerminationReason,
}: Props) {
  const { palette, customs } = useAppTheme();
  const {
    updateMerchantMutation: { mutate, isLoading },
  } = useUpdateMerchant();
  const parsedTerminationDate = (() => {
    if (typeof msaPreviousTerminationAt === "number") {
      // If it's a UNIX timestamp, convert it to a formatted string
      return moment.unix(msaPreviousTerminationAt).utc().format("MM/DD/YYYY");
    } else if (msaPreviousTerminationAt instanceof Date) {
      // If it's already a Date, format it as well
      return moment(msaPreviousTerminationAt).utc().format("MM/DD/YYYY");
    }
    // If it's null or undefined, return an empty string or a specific default value
    return ""; // Or you can use null if preferred
  })();

  const { control, handleSubmit, getValues, formState } = useForm<FormValues>({
    defaultValues: {
      processorName: msaPreviousTerminationProcessorName || "",
      terminationDate: parsedTerminationDate,
      terminationReason: msaPreviousTerminationReason || "",
    },
    resolver: yupResolver(validationSchema),
  });

  const { isValid } = formState;
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    mutate({
      msaPreviousTermination: true,
      msaPreviousTerminationAt: moment(
        data?.terminationDate,
        "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ",
      ).unix(),
      msaPreviousTerminationProcessorName: data?.processorName,
      msaPreviousTerminationReason: data?.terminationReason,
    });
  };

  // Handle blur to trigger submit if all fields are valid and filled
  const handleBlur = () => {
    clearTimeout(timeoutRef.current as NodeJS.Timeout);
    timeoutRef.current = setTimeout(() => {
      if (!document.activeElement || !document.activeElement.closest("form")) {
        const values = getValues();

        if (
          values.processorName &&
          values.terminationDate &&
          values.terminationReason
        ) {
          handleSubmit(onSubmit)();
        }
      }
    }, 100);
  };

  return (
    <form>
      <Stack flexDirection="column" mt="24px" gap="24px">
        <Controller
          name="processorName"
          control={control}
          disabled={isLoading}
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              label="Name of Previous Processor"
              onBlur={handleBlur}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          name="terminationDate"
          control={control}
          disabled={isLoading}
          render={({ field, fieldState: { error } }) => (
            <DatePicker
              {...field}
              label="Date of Termination"
              onSetDate={(e) => {
                field.onChange(e);
                handleBlur();
              }}
              textFieldSx={{
                border: `1px solid ${palette.border?.secondary}`,
                borderRadius: `${customs?.radius.small}px`,
              }}
              maxDate={new Date()}
              // error={!!error}
              //  helperText={error?.message}
            />
          )}
        />

        <Controller
          name="terminationReason"
          control={control}
          disabled={isLoading}
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              label="Reason for Termination"
              multiline
              rows={6}
              onBlur={handleBlur}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Stack>
    </form>
  );
}

export default TerminationForm;

// Styled input components
const Input = styled(GiveInput)(({ theme }) => ({
  border: `1px solid ${theme.palette.border?.secondary}`,
  borderRadius: `${theme.customs?.radius.small}px`,
}));

const DatePicker = styled(GiveDatePicker)(({ theme }) => ({
  border: `1px solid ${theme.palette.border?.secondary}`,
  borderRadius: `${theme.customs?.radius.small}px`,
  borderColor: "red",
}));
