export const getCustomPaperStyle = (palette: any, basePallete: any) => {
  return {
    styleOverrides: {
      root: {
        "& .MuiPaper-root-MuiPopover-paper-MuiMenu-paper": {
          background: palette.surface.primary,
          "& .MuiList-root-MuiMenu-list": {
            borderRadius: "16px",
            "& option": {
              padding: "6px",
              "&:hover": {
                background: basePallete.primitive.transparent["darken-5"],
              },
            },
          },
        },
      },
    },
  };
};
