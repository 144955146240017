import BaseCardComponent from "../BaseCardComponent";
import useSnapShot from "features/Merchants/MerchantSidePanel/hooks/useSnapShot";

function BusinessProfile() {
  const { businessProfile } = useSnapShot();
  return (
    <BaseCardComponent leftTitle="Business Profile" payload={businessProfile} />
  );
}

export default BusinessProfile;
