import { Grid, rgbToHex, styled } from "@mui/material";
import PayBuilderContext from "./provider/PayBuilderContext";
import { ResponsiveViewSwitcher } from "./provider/Switcher";
import PayBuilderFormProvider, {
  usePayBuilderForm,
} from "./provider/PayBuilderFormProvider";
import { ThemeWrapper } from "@theme/v2/Provider";
import useCombineThemes from "@theme/v2/hooks/useCombineThemes";
import { getTextColorsBasedOnBackground } from "./utils";
import { CartProvider } from "./provider/CartContext";
import { useLocation } from "react-router-dom";
import ProductPreviewView from "./components/ProductPreviewView";

function PayBuilder() {
  const location = useLocation();
  const regex = /^\/pay_product_builder\/[^/]+/;
  const hasId = regex.test(location.pathname);
  const displayStatus = hasId ? "published" : "preview";

  return (
    <ThemeWrapper isNewThemeOnly>
      <PayBuilderContext>
        <PayBuilderFormProvider>
          <>
            {displayStatus === "published" ? (
              <CartProvider>
                <ProductPreviewView />
              </CartProvider>
            ) : (
              <Container container>
                <ResponsiveViewSwitcher>
                  <ResponsiveViewSwitcher.BuilderForm />
                  <FormPreviewWithCustomTheme />
                </ResponsiveViewSwitcher>
              </Container>
            )}
          </>
        </PayBuilderFormProvider>
      </PayBuilderContext>
    </ThemeWrapper>
  );
}

const FormPreviewWithCustomTheme = () => {
  //The same has to be done for Cutomer view(public payment form)
  const { methods } = usePayBuilderForm();
  const { luminance } = getTextColorsBasedOnBackground(
    rgbToHex(methods.watch().Style.background).toUpperCase(),
  );

  const combineThemes = useCombineThemes(
    luminance < 0.5 ? "dark" : "light",
    false,
  );

  return (
    <ThemeWrapper combineThemesIOC={combineThemes}>
      <ResponsiveViewSwitcher.FormPreview />
    </ThemeWrapper>
  );
};

export default PayBuilder;

const Container = styled(Grid)(({ theme }) => ({
  width: "100%",
  margin: 0,
  padding: 0,
  overflow: "hidden",
  "& > div": {
    flex: 1,
  },
}));
