import React from "react";
import { Box, Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import GiveButton from "@shared/Button/GiveButton";
import { styled } from "@theme/v2/Provider";
import { GiveEmptyStateType } from "./types";

const GiveEmptyState = ({ title, action, sx, Icon }: GiveEmptyStateType) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.stopPropagation();
    action?.handleAction && action?.handleAction();
  };
  return (
    <Container sx={sx}>
      <IconContainer>{Icon}</IconContainer>
      <Stack
        direction="column"
        alignItems="center"
        width="100%"
        zIndex={2}
        className="empty_text"
      >
        <GiveText variant="h6">{title.main}</GiveText>
        <GiveText variant="bodyS" color="secondary">
          {title.secondary}
        </GiveText>
      </Stack>
      {action && (
        <GiveButton
          variant="outline"
          label={action?.label}
          disabled={action?.disabled}
          onClick={handleClick}
          size="large"
          sx={{
            borderRadius: "40px",
          }}
        />
      )}
    </Container>
  );
};

const Container = styled(Stack)(() => ({
  flexDirection: "column",
  gap: "24px",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  overflow: "hidden",
  userSelect: "none",
  width: "100%",
  height: "100%",
}));

const IconContainer = styled(Box)(({ theme }) => ({
  height: 68,
  width: 68,
  borderRadius: 64,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: theme.palette.primitive?.transparent["darken-5"],
}));

export default React.memo(GiveEmptyState);
