import { CampaignType } from "@common/Campaigns/hooks/useReportMenuActions";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { TagType } from "@common/Tag/TransactionTableTag";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { shouldBeHidden } from "@constants/constants";
import { SidePanel } from "@containers/SidePanel";
import NiceModal from "@ebay/nice-modal-react";
import { Stack } from "@mui/material";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { checkPortals } from "@utils/routing";
import React, { useEffect } from "react";
import { useUpdateTransactionReadStatus } from "../hooks";
import LoadingState from "./LoadingState";
import TransactionInfoBody from "./TransactionInfoBody";
import TransactionInfoHeader from "./TransactionInfoHeader";
import TransactionMobilePanel from "./TransactionMobilePanel";
import { useTransactionHistory } from "./hooks";
import {
  composePermission,
  WithAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";

type Props = {
  data: any;
  DMenu?: any;
  setSelectedRow?: React.Dispatch<any>;
  isLoading?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  compaignType?: CampaignType;
  noModal?: boolean;
  handleClose?: () => void;
};

export const BaseTransactionModal = ({
  data,
  DMenu,
  setSelectedRow,
  isLoading,
  isFirst,
  isLast,
  compaignType,
  noModal,
  handleClose,
}: Props) => {
  const { isMobileView } = useCustomTheme();
  const { modal, open: isModalVisible, onClose, SlideProps } = useNiceModal();

  const { isEnterprisePortal, isAcquirerPortal, isManageMoney } =
    checkPortals();
  const isProcessing = isAcquirerPortal || isEnterprisePortal || isManageMoney;
  const transactionID = isProcessing ? data?.id : data?.transactionID;
  const {
    tabs,
    currentTab,
    setCurrentTab,
    displayedData,
    tabsData,
    isLoading: isHistoryLoading,
  } = useTransactionHistory(transactionID, isProcessing, data, isModalVisible);

  const onCloseDrawer = () => {
    onClose();
    if (setSelectedRow) {
      setSelectedRow(-1);
    }
  };

  const { hideRefund, hideSendReceipt } = getRefundReceipt(
    displayedData?.displayType,
    displayedData?.displayStatus,
  );

  const modalData = displayedData;

  const isDataLoading = isLoading || isHistoryLoading;

  const contentProps = {
    data: modalData,
    onCloseDrawer,
    hideRefund: hideRefund,
    hideSendReceipt,
    isLoading: isDataLoading,
    setSelectedRow,
    DMenu,
    isFirst,
    isLast,
    noModal,
    handleClose,
    tabs,
    tabsData,
    currentTab,
    setCurrentTab,
  };

  const { updateReadStatus } = useUpdateTransactionReadStatus(
    "acquirer-processing-transactions",
  );
  useEffect(() => {
    if (
      displayedData?.isBlocked &&
      isAcquirerPortal &&
      !displayedData?.firstCheckedAt
    ) {
      updateReadStatus(displayedData);
    }
  }, [displayedData?.id]);

  if (noModal) return <TransactionPanelContent {...contentProps} />;

  const permission = composePermission(
    RESOURCE_BASE.MERCHANT,
    RESOURCE_BASE.TRANSACTION,
  );

  if (isMobileView)
    return (
      <TransactionMobilePanel
        onCloseDrawer={onCloseDrawer}
        isModalVisble={isModalVisible}
        SlideProps={SlideProps}
      >
        <WithAccessControl resource={permission} operation={OPERATIONS.READ}>
          {isDataLoading ? (
            <LoadingSpinner />
          ) : (
            modalData && (
              <TransactionInfoBody
                isMobile={isMobileView}
                compaignType={compaignType}
                {...contentProps}
              />
            )
          )}
        </WithAccessControl>
      </TransactionMobilePanel>
    );

  return (
    <SidePanel
      modal={modal}
      onCloseDrawer={onCloseDrawer}
      paperStyle={{ width: "650px" }}
    >
      <TransactionPanelContent {...contentProps} />
    </SidePanel>
  );
};

const TransactionPanelContent = (
  props: Props & {
    onCloseDrawer: () => void;
    hideRefund: boolean;
    hideSendReceipt: boolean;
    tabs: TagType[];
    tabsData?: any[];
    currentTab: number;
    setCurrentTab: React.Dispatch<React.SetStateAction<number>>;
  },
) => {
  const {
    noModal,
    data,
    onCloseDrawer,
    hideRefund,
    hideSendReceipt,
    handleClose,
    tabs,
    tabsData,
    currentTab,
    setCurrentTab,
    isFirst,
    isLast,
    setSelectedRow,
  } = props;

  const permission = composePermission(
    RESOURCE_BASE.MERCHANT,
    RESOURCE_BASE.TRANSACTION,
  );

  return (
    <WithAccessControl resource={permission} operation={OPERATIONS.READ}>
      {props.isLoading ? (
        <LoadingState />
      ) : (
        <Stack
          sx={{
            ...containerStyle,
            ...(noModal && {
              padding: "0",
            }),
          }}
        >
          {!noModal && <TransactionInfoHeader {...props} />}
          <TransactionInfoBody
            data={data}
            onCloseDrawer={noModal ? handleClose : onCloseDrawer}
            hideRefund={hideRefund}
            hideSendReceipt={hideSendReceipt}
            noModal={noModal}
            tabs={tabs}
            tabsData={tabsData}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            isFirst={isFirst}
            isLast={isLast}
            setSelectedRow={setSelectedRow}
          />
        </Stack>
      )}
    </WithAccessControl>
  );
};

const containerStyle = {
  padding: "8px 24px",
  height: "100vh",
  alignItems: "baseline",
};

export enum TransactionStatus {
  CHARGEBACK = "Chargeback",
  MONEY_TRANSFER = "Money Transfer",
  VOIDED = "Voided",
  CHARGEBACK_REVERSAL = "Chargeback Reversal",
  SETTLED = "Settled",
  REFUNDED = "Refunded",
  DECLINED = "Declined",
  SENT = "Sent",
}

const getRefundReceipt = (displayType: string, displayStatus: string) => {
  const isChargeback = displayStatus === TransactionStatus.CHARGEBACK;
  const isTransfer = displayType === TransactionStatus.MONEY_TRANSFER;
  const isVoided = displayStatus === TransactionStatus.VOIDED;
  const isChargebackReversal =
    displayStatus === TransactionStatus.CHARGEBACK_REVERSAL;
  const isDeclined = displayStatus === TransactionStatus.DECLINED;

  const hideRefund =
    isTransfer ||
    shouldBeHidden.refund ||
    displayStatus === TransactionStatus.REFUNDED ||
    isDeclined ||
    displayStatus === TransactionStatus.SENT ||
    isVoided ||
    isChargeback ||
    isChargebackReversal;
  const hideSendReceipt = isTransfer || isChargeback || isVoided || isDeclined;

  return { hideRefund, hideSendReceipt };
};

export const TransactionInfo = BaseTransactionModal;

export const TransactionModal = NiceModal.create(BaseTransactionModal);
