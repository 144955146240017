import { SidePanelHeaderBase } from "@shared/SidePanel/components/SidePanelHeader/SidePanelHeader";
import {
  SidePanelBodyWrapper,
  SidePanelContainerWrapper,
} from "@shared/SidePanel/SidePanelAtoms";
import { WithRepositoryProvider } from "../WithRepositoryContext";
import {
  useConfigureChallenges,
  useGetSpecificChallengeData,
} from "./useChallengesRepository";
import { ChallengeActions, ChallengeTitle } from "./ChallengeHeader";
import ChallengeBody from "./ChallangeBody";

interface IComponentProps {
  challengeIdentifier: string;
  challengeDisplayName: string;
  handleClose: () => void;
}

export const ChallengesSecondPanelWrapper = (props: IComponentProps) => {
  const { configs, repositories } = useConfigureChallenges();
  return (
    <SidePanelContainerWrapper>
      <WithRepositoryProvider configs={configs} repositories={repositories}>
        <ChallengesSecondPanel {...props} />
      </WithRepositoryProvider>
    </SidePanelContainerWrapper>
  );
};

const ChallengesSecondPanel = ({
  handleClose,
  challengeIdentifier,
  challengeDisplayName,
}: IComponentProps) => {
  const { data, isLoading, isLoadingList } =
    useGetSpecificChallengeData(challengeIdentifier);

  return (
    <>
      <SidePanelHeaderBase
        leftItems={
          <ChallengeTitle name={challengeDisplayName} onBack={handleClose} />
        }
        rightItems={<ChallengeActions onClose={handleClose} />}
      />
      <SidePanelBodyWrapper>
        {!isLoading && data?.categoryStats && data?.challengesList && (
          <ChallengeBody
            data={data}
            name={challengeDisplayName}
            identifier={challengeIdentifier}
          />
        )}
      </SidePanelBodyWrapper>
    </>
  );
};

export default ChallengesSecondPanel;
