import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  data: any;
  isLoading: boolean;
};

export const useChallengeScrollIntoView = ({ data, isLoading }: Props) => {
  // if user opens the edd challenge from the conversation modal then:
  // the selecteded edd challenge is to be scrolled into the view

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const focusedChallengeId = queryParams?.get("focused-challenge-id");

  useEffect(() => {
    if (focusedChallengeId && data && !isLoading) {
      const challenge = document.getElementById(
        `challenge-item-${focusedChallengeId}`,
      );
      challenge?.scrollIntoView({ behavior: "smooth" });
      //clear search params after scrolling is done
      navigate({
        pathname: location.pathname,
        search: "",
      });
    }
  }, [data, isLoading, focusedChallengeId]);
};
