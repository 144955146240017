import { ConversationsEmptyStateIcons } from "@assets/icons";
import { Button } from "@common/Button";
import { Text, TruncateText } from "@common/Text";
import { Box, Stack, styled } from "@mui/material";
import { TelegramLogo } from "@phosphor-icons/react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  selectConversationTopic,
  selectReplies,
  selectThreads,
  setConversationTopic,
} from "@redux/slices/conversations";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Comment from "../Chat/Comment";
import ComponentWithAnimation from "../Chat/ComponentWithAnimation";
import TextDivider from "../components/TextDivider";
import { getGlobalTopic } from "../hooks/useConversationsModal";
import ConversationsEmptyState from "../Modal/ConversationsEmptyState";

function RepliesChat({ isMobileView }: { isMobileView: boolean; data: any }) {
  const threads = useAppSelector(selectThreads);
  const [isRendered, setIsRendered] = useState<boolean>(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  const { commentId, index } = useAppSelector(selectReplies);
  const scrollRef = useRef<any>(null);

  if (typeof index !== "number") return <></>;

  const thread = threads[index];
  const { queryObject } = useAppSelector(selectConversationTopic);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [thread.messages.length]);
  const messages = thread?.messages?.filter(
    (message) => message?.body !== thread.originalThread?.messageBody,
  );
  const {
    createdAt,
    authorFirstName,
    authorLastName,
    messageBody,
    subjectAccName,
  } = thread.originalThread || {};
  const dispatch = useAppDispatch();

  const handleReadMore = async () => {
    const { data: topics } = await getGlobalTopic({});
    const isRiskMonitor = thread?.topicName === "risk_activity";
    const name = isRiskMonitor ? "risk_monitor" : "underwriting";
    const topic = topics?.find(
      (item: any) => item?.Type === "activity" && item?.Name === name,
    );

    dispatch(
      setConversationTopic({
        isOpen: true,
        isOpenedFromSidePanel: false,
        queryObject: {
          ...queryObject,
          id: topic?.ID,
          name: thread?.originalThread?.subjectAccName || "",
          tabs: "Activity",
          paths: [
            {
              pathID: topic?.ID,
              pathName: thread?.topicName || "",
              avatars: [],
            },
            {
              avatars: [],
              isConversation: true,
              pathName: thread?.originalThread?.title || "",
              pathID: thread?.originalThread?.id,
              hideInputs: ["module", "subject"],
            },
          ],
        },
      }),
    );
  };
  return (
    <Box sx={{ flex: 1 }}>
      {!thread ? (
        <ConversationsEmptyState
          icon={<ConversationsEmptyStateIcons />}
          message="No replies..."
        />
      ) : (
        <Box>
          {thread?.originalThread && (
            <Stack
              flexDirection="row"
              borderRadius="8px"
              bgcolor="#FFFFFF"
              mx="16px"
              p="12px 16px"
              alignItems="self-start"
              gap="8px"
            >
              <Stack
                height="24px"
                width="24px"
                justifyContent="center"
                alignItems="center"
                bgcolor="#6D9CF8"
                borderRadius="100%"
                flex={1}
              >
                <TelegramLogo color="#F8F8F6" weight="fill" size={16} />
              </Stack>
              <Box flex={15}>
                {createdAt && (
                  <Text fontWeight="book" fontSize="12px" color="#8F8F8F">
                    {moment.unix(createdAt).format("DD MMM YYYY, HH:mm")}
                  </Text>
                )}
                <Text
                  mb="12px"
                  fontWeight="regular"
                  color="#403D3D"
                  fontSize="14px"
                >
                  Conversation started with{" "}
                  <TextSpan>{subjectAccName}</TextSpan> underwriting by
                  <TextSpan> {`${authorFirstName} ${authorLastName}`}</TextSpan>
                </Text>
                <TruncateText
                  fontWeight="book"
                  fontSize="14px"
                  color="#575353"
                  lineClamp={2}
                >
                  {messageBody}
                </TruncateText>
                <Button
                  sx={{
                    margin: 0,
                    padding: 0,
                    color: "#6D9CF8",
                    fontSize: "14px",
                  }}
                  background="tertiary"
                  variant="text"
                  onClick={handleReadMore}
                >
                  Read All
                </Button>
              </Box>
            </Stack>
          )}
          {messages?.map((comment, index) => {
            const commentCreatedAt = Math.floor(
              new Date(comment?.createdAt).getTime(),
            );
            return (
              <ComponentWithAnimation
                id={comment.id}
                shouldAnimate={!isRendered}
                index={index}
                key={index}
              >
                <Comment
                  index={index}
                  isError={thread.isError}
                  isSending={thread.isSending}
                  isMobileView={isMobileView}
                  parentThreadAuthorID={thread.authorAccID}
                  {...comment}
                  parentThreadId={thread.id}
                  createdAt={commentCreatedAt}
                  isReplyComment
                />
                {index === 0 && (
                  <TextDivider
                    color="neutral.70"
                    wrapperProps={{ gap: 1, padding: 1 }}
                  >
                    {thread.messages.length - 1} replies
                  </TextDivider>
                )}
              </ComponentWithAnimation>
            );
          })}
          <Box ref={scrollRef} />
        </Box>
      )}
    </Box>
  );
}

export default RepliesChat;

const TextSpan = styled("span")(() => ({
  color: "#292727",
}));
