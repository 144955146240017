import { useState } from "react";
import { TrashBin } from "@assets/icons/RebrandedIcons";
import { DownloadIcon } from "@assets/icons";
import { ClickAwayListener, Stack } from "@mui/material";
import { styled } from "@mui/material";
import { palette } from "@palette";
import { Text, TruncateText } from "@common/Text";
import { StyledIconButton } from "@common/IconButton";
import { Eye } from "@phosphor-icons/react";
import { useDocumentHandlers } from "@hooks/common/documents";
import { checkPortals } from "@utils/routing";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import { useQueryClient } from "react-query";
import { DELETE_DENY_MESSAGE } from "@constants/permissions";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";

export const UploadFileDocumentPreviewItem = ({
  deleteDocument,
  id,
  ...props
}: {
  deleteDocument: (id: string | number) => void;
  fileName: string;
  id: string | number;
  fileURL: string;
  merchantId?: number;
  list?: any;
  isUploaded?: boolean;
}) => {
  const { fileName, merchantId, list, isUploaded } = props;
  const fileObject = list?.find((file: any) => file.fileName === fileName);
  const queryClient = useQueryClient();
  const { isEnterprisePortal } = checkPortals();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const refetcher = () => {
    queryClient.refetchQueries("get-bank-files");
  };
  const { previewHandler, isDeleteAllowed } = useDocumentHandlers(
    merchantId || 0,
    list as TMerchantDocument[],
    isEnterprisePortal,
    refetcher,
  );

  const download = async (file: any) => {
    const link = document.createElement("a");
    link.href = file.fileURL;
    link.download = file.fileName;
    link.target = "_blank";
    link.click();
  };

  const remove = (id: string | number) => {
    deleteDocument(id);
  };

  const actions = [
    {
      label: "view",
      children: <Eye width={18} height={18} color="#8F8F8F" />,
      onClick: () => previewHandler(fileObject as TMerchantDocument),
      hide: !isUploaded,
    },
    {
      label: "download",
      children: <DownloadIcon width={18} height={18} stroke="#8F8F8F" />,
      onClick: () => download(props),
    },
    {
      label: "delete",
      children: <TrashBin />,
      disabled: !isDeleteAllowed,
      onClick: () => remove(id),
      tooltipProps: {
        show: !isDeleteAllowed,
        message: DELETE_DENY_MESSAGE,
      },
    },
  ];

  return (
    <ClickAwayListener onClickAway={() => setIsFocused(false)}>
      <ItemContainer
        sx={{
          ...(isFocused && {
            backgroundColor: palette.neutral.white,
            "& .document-item-actions": {
              display: "flex",
            },
          }),
        }}
        data-testid="actions-item-container"
      >
        <Stack direction="row">
          <TruncateText
            lineClamp={1}
            color={palette.black[100]}
            sx={{ wordBreak: "break-all" }}
            variant="body"
            fontWeight="book"
            data-testid={fileName}
          >
            {fileName?.substring(0, fileName?.lastIndexOf("."))}
          </TruncateText>
          <Text color={palette.black[100]} fontWeight="book">
            .{fileName?.substring(fileName?.lastIndexOf(".") + 1)}
          </Text>
        </Stack>
        <ActionsContainer className="document-item-actions">
          {actions.map((action) => {
            const { label, hide, tooltipProps, ...rest } = action;
            if (hide) return;

            return (
              <CustomToolTip
                key={label}
                showToolTip={!!tooltipProps?.show}
                message={tooltipProps?.message}
              >
                <ActionButton key={label} {...rest} />
              </CustomToolTip>
            );
          })}
        </ActionsContainer>
      </ItemContainer>
    </ClickAwayListener>
  );
};

const ItemContainer = styled(Stack)(({ theme }) => ({
  padding: "12px 16px",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "8px",
  borderRadius: "8px",
  position: "relative",
  cursor: "pointer",
  minHeight: "50px",

  ":hover": {
    backgroundColor: palette.neutral.white,
    "& .document-item-actions": {
      display: "flex",
    },
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
}));

const ActionsContainer = styled(Stack)(() => ({
  display: "none",
  flexDirection: "row",
  alignItems: "center",
  padding: "4px 8px",
  gap: "12px",
  borderRadius: "4px",
  backgroundColor: palette.neutral.white,
  boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
}));

const ActionButton = styled(StyledIconButton)(() => ({
  maxWidth: "18px",
  maxHeight: "18px",
  backgroundColor: "inherit",
  borderRadius: "4px",
  padding: "0 !important",
}));
