import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import NiceModal from "@ebay/nice-modal-react";
import { isFunction } from "lodash";
import GivePopup from "@shared/Popup/GivePopup";
import {
  PopupIconTypeProps,
  PopupTypeProps,
} from "@shared/Popup/GivePopupTypes";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

type TModalType = "approved" | "declined" | "delete" | "suspend" | "change-mcc";

type THandler = {
  onClick: () => void;
  disable?: boolean;
};

type TValuesObject = {
  icon: PopupIconTypeProps;
  submitButtonText: string;
  submitButtonColor?: "primary" | "destructive";
  type: PopupTypeProps;
};
type Props = {
  modalType: TModalType;
  title: string;
  description: string | React.ReactNode;
  actions?: {
    handleSuccess?: THandler;
    handleCancel?: THandler;
  };
};

const GiveConfirmationPopUp = NiceModal.create(
  ({ modalType, title, description, actions }: Props) => {
    const { open, onClose } = useNiceModal();
    const { isMobileView } = useCustomThemeV2();

    const { handleSuccess, handleCancel } = actions || {};
    const onSuccess = () => {
      if (isFunction(handleSuccess?.onClick)) {
        handleSuccess?.onClick();
      }
      onClose();
    };

    const onCancel = () => {
      if (isFunction(handleCancel?.onClick)) handleCancel?.onClick();
      onClose();
    };
    const { icon, submitButtonText, submitButtonColor, type } =
      getModalType(modalType);
    return (
      <GivePopup
        type={type}
        iconType={icon}
        title={title}
        description={description}
        open={open}
        onClose={onCancel}
        isMobile={isMobileView}
        buttons={[
          {
            label: "Cancel",
            onClick: onCancel,
            variant: "ghost",
          },
          {
            label: `Yes, ${submitButtonText}`,
            onClick: onSuccess,
            variant: "filled",
            color: submitButtonColor,
            sxButton: { border: "none" },
          },
        ]}
        sx={{
          "& .MuiDialogTitle-root+.MuiDialogContent-root": {
            paddingTop: "0 !important",
          },
        }}
      />
    );
  },
);

export default GiveConfirmationPopUp;

const getModalType = (modalType: TModalType) => {
  const modalTypeObject: Record<TModalType, TValuesObject> = {
    approved: {
      icon: "success-regular",
      submitButtonText: "Approve",
      submitButtonColor: "primary",
      type: "success",
    },
    declined: {
      icon: "decline",
      submitButtonText: "Decline",
      submitButtonColor: "destructive",
      type: "destructive",
    },
    delete: {
      icon: "destructive",
      submitButtonText: "Delete",
      submitButtonColor: "destructive",
      type: "destructive",
    },
    suspend: {
      icon: "destructive-v2",
      submitButtonText: "Suspend",
      submitButtonColor: "primary",
      type: "success",
    },
    "change-mcc": {
      icon: "warning",
      submitButtonText: "Change",
      submitButtonColor: "primary",
      type: "success",
    },
  };

  return modalTypeObject[modalType];
};
