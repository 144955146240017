import { TComment, TThreadInfo } from "../types";
import { TConversationTopic } from "../hooks/useListTopics";
import { addSizeToImage } from "@components/UploadAvatar/UploadAvatar";
import AvatarPlaceholder from "@assets/images/avatar-placeholder.png";

export type TMentionedUser = {
  user: string;
  authorAccID: number | undefined;
};

export type ThreadInfos = {
  comment: TComment;
  userAvatars: string[];
  commentCreatedAt: number;
  threadUpdatedAt: number;
};

export const objectSlicer = <T extends TComment>(
  objects: T[],
  start: number,
  end: number,
  property?: keyof T,
): T[keyof T][] | T[] => {
  const sliced = objects?.slice(start, end);

  if (!property) return sliced;

  return sliced?.map((obj) => obj[property]);
};

export const getThreadInfos = (thread: TThreadInfo) => {
  if (!thread.messages || thread.messages.length === 0) {
    return {
      comment: undefined,
      userAvatars: [],
      commentCreatedAt: 0,
      threadUpdatedAt: 0,
    };
  }

  const comment = objectSlicer(thread.messages, 0, 1)[0] as TComment;
  if (!comment)
    return {
      comment: undefined,
      userAvatars: [],
      commentCreatedAt: 0,
      threadUpdatedAt: 0,
    };

  const userAvatars = thread.messages.reduce(
    (acc: string[], message: TComment, _, arr: TComment[]) => {
      if (acc.includes(message.authorAvatarImageURL)) {
        return acc;
      } else {
        return [...acc, message.authorAvatarImageURL];
      }
    },
    [],
  );

  const threadUpdatedAt = new Date(thread.lastMessageAt || 0).getTime();
  const commentCreatedAt = Math.floor(new Date(comment?.createdAt).getTime());

  return {
    comment,
    userAvatars,
    commentCreatedAt,
    threadUpdatedAt,
  };
};
export const getRepliesInfos = (thread: TThreadInfo) => {
  const comments = objectSlicer(
    thread.messages,
    0,
    thread.messages.length,
  ) as TComment[];
  if (comments.length === 0)
    return {
      comment: undefined,
      userAvatars: [],
      commentCreatedAt: 0,
      threadUpdatedAt: 0,
    };

  return { comments };
};

export function parseInput(
  input: string,
  { isUrl }: { isUrl?: boolean } = {},
): string {
  const hashtagPattern = /#([^\s#]+)/g;

  const result = input.replace(hashtagPattern, (match, hashtagName) => {
    return !isUrl
      ? `#[__${hashtagName}__](hashtag:__${hashtagName}__)`
      : `hashtag ${hashtagName}`;
  });

  return result;
}

export const calculateTotalUnreadSum = (
  arrayOfObjects: TConversationTopic[],
): { totalUnread: number; hasUnreadActivity: boolean } => {
  if (!arrayOfObjects) return { totalUnread: 0, hasUnreadActivity: false };
  return {
    totalUnread: arrayOfObjects.reduce((sum, obj) => sum + obj.totalUnread, 0),
    hasUnreadActivity: arrayOfObjects.some(
      (obj: any) => obj.typeName === "activity" && obj.totalUnread > 0,
    ),
  };
};

export function getComputedMentions(data: any) {
  return data?.data?.map((obj: any) => {
    const { firstName, lastName, accID, imageURL, email } = obj.user;
    const nameBuilder = `${firstName} ${lastName}`;
    return {
      id: `${accID}`,
      display: firstName ? nameBuilder : email,
      image: imageURL ? addSizeToImage(imageURL, "small") : AvatarPlaceholder,
    };
  });
}
