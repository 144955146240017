import { useAppSelector } from "@redux/hooks";
import { selectConversationTopic } from "@redux/slices/conversations";
import { customInstance } from "@services/api";
import { useQuery, useQueryClient } from "react-query";
import { Message } from "../Modal/types";
import { isEmpty } from "lodash";
import {
  QKEY_LIST_ACQUIRER_MERCHANTS,
  QKEY_LIST_ENTERPRISE_MERCHANTS,
  QKEY_LIST_ENTERPRISE_STATS,
  QKEY_LIST_ENTERPRISES,
} from "@constants/queryKeys";

const useGetThreadMessages = ({ merchantID }: { merchantID: number }) => {
  const queryClient = useQueryClient();
  const { queryObject } = useAppSelector(selectConversationTopic);
  const pathThread = queryObject?.paths?.find((item) => item.isConversation);

  const id = pathThread?.pathID;
  const threadId = id && id !== "new" ? id : null;

  const { data, isLoading, refetch } = useQuery(
    ["get-thread-messages", threadId],
    async () => {
      const data = await customInstance({
        url: `/merchants/${merchantID}/threads/${threadId}/messages?sort=-createdAt`,
      });

      await customInstance({
        url: `/merchants/${merchantID}/messages/read`,
        method: "POST",
        data: {
          threadID: threadId,
        },
      });

      return data;
    },
    {
      enabled: !!threadId,
      onSuccess: () => {
        queryClient.invalidateQueries(QKEY_LIST_ENTERPRISE_MERCHANTS);
        queryClient.invalidateQueries(QKEY_LIST_ACQUIRER_MERCHANTS);
        queryClient.invalidateQueries(QKEY_LIST_ENTERPRISES);
        queryClient.invalidateQueries(QKEY_LIST_ENTERPRISE_STATS);
      },
    },
  );

  const messages: Message[] | undefined = data?.data;

  return {
    messages,
    isMessageResponse: !isEmpty(messages) && !isLoading,
    isLoading,
    refetch,
  };
};

export default useGetThreadMessages;
