import { showMessage } from "@common/Toast";
import { customInstance } from "@services/api";
import { useMutation, useQueryClient } from "react-query";
import { DISPUTES_QUERY_KEY } from "./useDisputesTable";

export const useUpdateDisputeAssignee = (refetchDisputePreview?: boolean) => {
  const { updateData } = useUpdateDisputesList();

  const updateAssignee = useMutation(({ disputeID, userID, isDelete }: any) => {
    return customInstance({
      url: `/disputes/${disputeID}/assignee/${userID}`,
      method: isDelete ? "DELETE" : "PUT",
    });
  });

  const handleChangeAssignee = (
    oldAssignee = 0,
    newAssignee: string | null,
    disputeID: string,
    onSuccess?: () => void,
  ) => {
    const isDelete = newAssignee === null;

    const successCharMsg =
      oldAssignee && newAssignee
        ? "changed"
        : newAssignee
        ? "added"
        : "removed";
    updateAssignee.mutate(
      { userID: isDelete ? oldAssignee : newAssignee, disputeID, isDelete },
      {
        onError: (error: unknown) => {
          const axiosError = error as any;
          const errorMessage = axiosError?.response?.data?.message;
          showMessage("Error", errorMessage || "Unable to change assignee");
        },
        onSuccess: (data) => {
          if (onSuccess) onSuccess();
          showMessage("Success", `Assignee has been ${successCharMsg}`);

          updateData(
            {
              accID: data?.accID,
              avatarURL: data?.avatarURL,
              email: data?.email,
              firstName: data?.firstName,
              lastName: data?.lastName,
            },
            refetchDisputePreview,
          );
        },
      },
    );
  };

  return { handleChangeAssignee };
};

type TKeyValuePairs = {
  [key: string]: any;
};

const useUpdateDisputesList = (queryKey?: string) => {
  const queryClient = useQueryClient();
  const previousData = queryClient.getQueriesData(queryKey as string);

  const updateData = (
    keyValuePairs: TKeyValuePairs,
    refetchDisputePreview?: boolean,
  ) => {
    const updateHandler = (oldData: any) => {
      if (!oldData?.data || !Array.isArray(oldData.data)) return oldData;

      const updatedData = oldData.data.map((merchant: any) => {
        // Iterate over each key-value pair and update the merchant
        const updatedMerchant = Object.entries(keyValuePairs).reduce(
          (acc, [key, value]) => {
            acc[key] = value;
            return acc;
          },
          { ...merchant },
        );

        return updatedMerchant;
      });

      return {
        ...oldData,
        data: updatedData,
      };
    };

    const updateMerchantPreview = (oldData: any) => {
      if (!oldData?.merchant) return oldData;

      const updatedMerchant = Object.entries(keyValuePairs).reduce(
        (acc, [key, value]) => {
          acc[key] = value;
          return acc;
        },
        { ...oldData.merchant },
      );

      return {
        ...oldData,
        merchant: updatedMerchant,
      };
    };

    queryClient.setQueriesData(DISPUTES_QUERY_KEY, updateHandler);

    if (refetchDisputePreview) {
      queryClient.setQueriesData("get-merchant-preview", updateMerchantPreview);
    }
  };

  const revertChange = () => {
    previousData.forEach(([queryKey, queryData]) =>
      queryClient.setQueriesData(queryKey as string, queryData),
    );
  };

  return { updateData, revertChange };
};
