import { ChallengeBellRingingIcon } from "@assets/icons";
import { Button } from "@common/Button";
import { TooltipProps } from "@common/Button/Button";
import { BtnBGTypes } from "@common/Button/Button_V2";
import { Text } from "@common/Text";
import { Stack, SxProps } from "@mui/material";
import { ReactNode } from "react";
import RejectApprove from "./ActionsRejectApprove";
import ViewAction from "./ViewActions";
import { EDIT_DENY_MESSAGE } from "@constants/permissions";

type ActionButtonProps = {
  label: string;
  onClick: () => void;
  size?: "small" | "medium" | "large";
  background?: BtnBGTypes;
  sx?: SxProps;
  testId?: string;
  disabled?: boolean;
  tooltipProps?: TooltipProps;
};

type NotifyButtonProps = {
  isMerchantNotified?: boolean;
  disabled: boolean;
  isEnterprise?: boolean;
  notifyMerchant: () => void;
};

export const ActionButton = ({
  label,
  onClick,
  size = "small",
  background,
  disabled,
  sx,
  testId,
  ...rest
}: ActionButtonProps) => (
  <Button
    size={size}
    background={background}
    data-testid={testId}
    disabled={!!disabled}
    {...rest}
    sx={{
      padding: "8px 24px",
      ...(sx || {}),
    }}
    onClick={onClick}
  >
    {label}
  </Button>
);

export const NotifyButton = ({
  isMerchantNotified = false,
  disabled,
  isEnterprise,
  notifyMerchant,
}: NotifyButtonProps) => {
  return (
    <>
      {" "}
      {isMerchantNotified ? (
        <Stack direction="row" alignItems="center" gap="4px">
          <ChallengeBellRingingIcon width={16} height={16} />
          <Text
            fontStyle="italic"
            lineHeight="16.8px"
            sx={{ fontWeight: 400, color: "#575353" }}
          >
            {isEnterprise ? "Provider" : "Merchant"} Notified
          </Text>
        </Stack>
      ) : (
        <ActionButton
          testId="challenge-notify-button"
          label={`Notify ${isEnterprise ? "Provider" : "Merchant"}`}
          onClick={notifyMerchant}
          disabled={disabled}
          background="tertiary"
          sx={{
            textDecoration: "none",
            cursor: "pointer",
            ":disabled": {
              color: "#D1D1D0",
              pointerEvents: "auto",
              textDecoration: "none",
              cursor: "default",
            },
          }}
        />
      )}
    </>
  );
};

export const getTooltipProps = (
  needsVerification?: boolean,
  isEditChallengeAllowed?: boolean,
) => {
  if (!isEditChallengeAllowed)
    return {
      message: EDIT_DENY_MESSAGE,
      show: true,
    };
  if (needsVerification)
    return {
      message: "Review stalled: missing required documents/information",
      show: true,
    };

  return undefined;
};

const ChallengeActions = ({
  children,
  isReadyForConfirmation,
}: {
  children: ReactNode;
  isReadyForConfirmation: boolean;
}): JSX.Element => {
  return isReadyForConfirmation ? (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      {children}
    </Stack>
  ) : (
    <>{children}</>
  );
};

ChallengeActions.RejectApprove = RejectApprove;
ChallengeActions.NotifyButton = NotifyButton;
ChallengeActions.ViewActions = ViewAction;

export default ChallengeActions;
