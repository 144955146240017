import { useFormData } from "@components/Signup/Forms/SignupFormProvider";
// form
import * as Yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useUploadSingleImage as useUploadSingleImageNew } from "@hooks/upload-api/uploadHooks";
import { useUploadSingleImage as useUploadSingleImageOld } from "@hooks/merchant-api/image-gallery/useUploadSingleImage";

import { showMessage } from "@common/Toast";
import { formatBillingDescriptor } from "@components/Merchants/MerchantPreview/modals/EditMerchantInfoModal";
import {
  BILLING_DESCRIPTOR_MAX_LENGTH,
  MERCHANT_PROVIDER_MAX_CHARS,
  SLUG_MAX_CHARACTER_LENGTH,
} from "@constants/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMediaQuery, useTheme } from "@mui/material";
import { customInstance } from "@services/api";
import { encodeString } from "@utils/index";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { removeSpecialChars } from "@utils/slug";
import { refineLEOwnership, ssnSchema } from "@utils/validation.helpers";
import { isEnterpriseWebsite, SignupType } from "./utils";

type TinType = "ssn" | "ein";

type IFormInputs = {
  name: string;
  termsConditions: boolean;
  logo?: string | File;
  businessType: string;
  tinType: TinType;
  ssn: string;
  taxId: string;
  classification?: {
    name?: string;
    description?: string;
  };
  organizationType: string;
  businessPurpose: string;
};

type Props = {
  handleNext: () => void;
};

const useSignup = ({ handleNext }: Props) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const { formData, setFormValues } = useFormData();
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
  const { handleUpload: handleUploadNew, isLoading: isLoadingNew } =
    useUploadSingleImageNew();
  const { handleUpload: handleUploadOld, isLoading: isLoadingOld } =
    useUploadSingleImageOld();
  const handleUpload = isFileUploadRefactorEnabled
    ? handleUploadNew
    : handleUploadOld;
  const imageLoading = isFileUploadRefactorEnabled
    ? isLoadingNew
    : isLoadingOld;

  const getCategories = () => {
    return customInstance({
      method: "GET",
      url: `/merchants-public`,
    });
  };

  const createMerchantMutation = useMutation((data: any) => {
    return customInstance({
      url: "merchants",
      method: "POST",
      data,
    });
  });

  const schema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .matches(
        /^(?=(.*[a-zA-Z]){3}).*$/,
        "Please provide a valid name with at least 3 letters",
      )
      .max(
        MERCHANT_PROVIDER_MAX_CHARS,
        `Name can not contain more than ${MERCHANT_PROVIDER_MAX_CHARS} characters`,
      )
      .trim(),
    businessType: Yup.string().required("Business Type is required"),
    tinType: Yup.mixed<TinType>()
      .oneOf(["ssn", "ein"])
      .required("TIN type is required"),
    ssn: Yup.string().when("tinType", {
      is: "ssn",
      then: ssnSchema("ssn"),
    }),
    taxId: Yup.string().when("tinType", {
      is: "ein",
      then: ssnSchema("ein"),
    }),
    termsConditions: Yup.bool()
      .required("Agree to terms and conditions is required")
      .oneOf([true], "Agree to terms and conditions is required"),
    businessPurpose: Yup.string().required("Purpose & Mission"),
  });

  const defaultValues = formData.organizationDetails;

  const methods = useForm<IFormInputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { setValue } = methods;

  const handleChangeStatus = (file: File) => {
    if (file) {
      setValue("logo", file);
    }
  };

  const { data: enterpriseData } = useQuery(
    "get-categories-by-slug",
    async () => {
      const data = await getCategories();

      return {
        accID: data?.accID,
      };
    },
    { refetchOnWindowFocus: false },
  );

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    let url = null;
    if (typeof data.logo === "string") {
      url = data.logo;
    } else if (data.logo) {
      url = await handleUpload(
        isFileUploadRefactorEnabled ? { file: data.logo } : (data.logo as any),
      );
    }
    if (!url) url = null;
    const descriptor = formatBillingDescriptor(data.name);

    const slug = removeSpecialChars(data.name, SLUG_MAX_CHARACTER_LENGTH);
    const customData = {
      signupType: isEnterpriseWebsite()
        ? SignupType.ENTERPRISE_WEBSITE
        : SignupType.GIVEPAYMENTS_WEBSITE,
      type:
        formData?.organizationType === "manage_submerchants"
          ? "enterprise"
          : "submerchant",
      parentAccID: enterpriseData?.accID,
      slug,
      websiteURL: "",
      name: data.name,
      imageURL: url,
      legalEntity: {
        name: data.name, // will be removed when BE updates required fields
        type: data?.businessType, // will be removed when BE updates required fields
        ownershipType: refineLEOwnership(data?.businessType),
        hasAcceptedCreditCards: true,
        tinType: data.tinType,
        ...(data?.tinType === "ssn"
          ? {
              ssn: data?.ssn,
            }
          : {
              taxIDNumber: data?.taxId,
            }),
      },
      inviteOwner: true,
      owner: {
        email: formData.personalDetails.email,
        firstName: formData.personalDetails.firstName,
        lastName: formData.personalDetails.lastName,
        hasAcceptedTC: true,
      },
      allowAddingBankAccounts: true,
      submerchantAllowedToTransfer: true,
      class: formData.classification.name,
      classType: formData.organizationType,
      classDescription: formData.classification.description,
      billingDescriptor: descriptor
        .toUpperCase()
        .slice(0, BILLING_DESCRIPTOR_MAX_LENGTH),
      description: encodeString(data.businessPurpose),
    };

    createMerchantMutation.mutate(customData, {
      onError: (error: unknown) => {
        if ((error as any)?.response?.data.input) {
          showMessage(
            "Error",
            (error as any)?.response?.data.input[0].message ||
              `${getMerchantErrorParamName(
                (error as any)?.response?.data.input[0].param,
              )} is not valid`,
            isDesktop,
          );
          return;
        }
        if ((error as any)?.response?.data?.message) {
          showMessage(
            "Error",
            (error as any)?.response?.data?.message,
            isDesktop,
          );
        }
      },
      onSuccess: () => {
        setFormValues("organizationDetails", data);
        handleNext();
      },
    });
  };

  const { isLoading, isSuccess } = createMerchantMutation;

  return {
    methods,
    defaultValues,
    schema,
    onSubmit,
    handleChangeStatus,
    isLoading: imageLoading || isLoading,
    isSuccess,
  };
};

export const getMerchantErrorParamName = (param: string) => {
  switch (param) {
    case "owner.phoneNumber":
      return "Primary Account Holder Contact phone";
    case "slug":
      return "Permalink";
    case "imageURL":
      return "Avatar image";
    case "billingDescriptor":
      return "Billing Descriptor";
    case "legalEntity.taxIDNumber":
      return "Federal Tax ID";
    case "legalEntity.principals.taxIDNumber":
      return "Business Owner Tax ID";
    default:
      return "Input";
  }
};

export default useSignup;
