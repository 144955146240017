import { Stack } from "@mui/material";
import { CaretRight, WarningCircle } from "@phosphor-icons/react";
import SectionCardBase from "@shared/SidePanel/components/SectionCard/SectionCardBase";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme } from "@theme/v2/Provider";
import { StatusInfo } from "../../agreements.types";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import GiveIconButton from "@shared/IconButton/GiveIconButton";

interface Props {
  onClick: () => void;
  statusInfo?: StatusInfo;
}
function SnapshotCard({ onClick, statusInfo }: Props) {
  const { palette } = useAppTheme();

  const { status = "" } = statusInfo || {};
  if (!["signed", "newVersion"]?.includes(status)) return <></>;

  const secondary = palette.text.secondary;
  return (
    <SectionCardBase>
      <Stack
        justifyContent="space-between"
        alignItems="center"
        onClick={onClick}
        flexDirection="row"
        sx={{
          cursor: "pointer",
        }}
      >
        <Stack gap="8px" alignItems="center" flexDirection="row">
          <GiveText fontSize="14px" color="secondary">
            Snapshot
          </GiveText>
          <GiveTooltip
            color="default"
            title="The snapshot is taken at Underwriting Approval, serving as a permanent record of the original information provided by the Merchant during the approval process"
          >
            <WarningCircle fill={secondary} size="16px" />
          </GiveTooltip>
        </Stack>
        <GiveIconButton
          onClick={onClick}
          Icon={CaretRight}
          color={secondary}
          size="medium"
          sx={{
            backgroundColor: "transparent",
          }}
        />
      </Stack>
    </SectionCardBase>
  );
}

export default SnapshotCard;
