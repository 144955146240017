import { useState } from "react";
import { Box, Button, Collapse, Stack, styled, SxProps } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { IMerchantBankAccount } from "../../data.types";
import { BankAccountTag } from "@common/Tag/BankAccountTag";
import { MoreActionButton } from "@components/ManageMoney/TransactionTable/TransactionInfoModal/MoreActionButton";
import CheckIcon from "@assets/icons/RebrandedIcons/CheckIcon";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import BankAccountDetails from "./BankAccountDetails";
import SwipeableDrawerMobile from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";

type Props = {
  onOpenMenu?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    id: number,
  ) => void;
  handleCheckAccount?: (id: number) => void;
  isChecked?: boolean;
  isEnterprise?: boolean;
  modify_merchant?: boolean;
  canUpdateLinked?: boolean;
};

const MerchantBankAccountItem = (props: IMerchantBankAccount & Props) => {
  const {
    status,
    enterprise,
    id,
    onOpenMenu,
    handleCheckAccount,
    isChecked,
    modify_merchant,
    canUpdateLinked,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const { isMobileView } = useCustomTheme();

  const handleExpand = () => {
    if (handleCheckAccount) {
      handleCheckAccount(id);
      setIsExpanded(true);
    } else {
      setIsExpanded(!isExpanded);
    }
  };

  const headerProps = {
    ...props,
    enterprise: props.enterprise || Boolean(props.isEnterprise),
  };

  return (
    <Container data-testid="merchant-bank-account-item">
      <Stack spacing={1} onClick={handleExpand}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <BankItemHeader {...headerProps} />
          {onOpenMenu &&
            (!enterprise || (enterprise && canUpdateLinked)) &&
            !isMobileView &&
            modify_merchant && (
              <MoreActionButton
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  onOpenMenu(e, id);
                }}
                sx={{
                  height: "5px !important",
                  "&:hover": { background: "none !important" },
                }}
              />
            )}
          {handleCheckAccount && isChecked && (
            <Box sx={checkIconStyle}>
              <CheckIcon height={12} width={12} fill={palette.neutral.white} />
            </Box>
          )}
        </Box>
        <Box display="inline-flex">
          <BankAccountTag type={status} />
        </Box>
      </Stack>
      {isMobileView && onOpenMenu ? (
        <SwipeableDrawerMobile
          anchor="bottom"
          onOpen={() => undefined}
          open={isExpanded}
          onClose={handleExpand}
          sx={{
            display: "flex",
            "& .MuiPaper-root.MuiDrawer-paper": {
              background: palette.neutral[5],
              top: "45%",
              flexGrow: 1,
            },
          }}
        >
          <BankItemHeader
            sx={{ color: palette.neutral[80], mb: 3, fontSize: 18 }}
            {...headerProps}
          />
          <BankAccountDetails {...props} />
          <Box sx={{ flexGrow: 1 }} />
          {onOpenMenu && !enterprise && (
            <ActionsButton size="small" onClick={(e) => onOpenMenu(e, id)}>
              Actions
            </ActionsButton>
          )}
        </SwipeableDrawerMobile>
      ) : (
        <Collapse
          orientation="vertical"
          in={onOpenMenu ? isExpanded : isExpanded && isChecked}
          unmountOnExit
        >
          <BankAccountDetails {...props} status={null} />
        </Collapse>
      )}
    </Container>
  );
};

export const BankItemHeader = (
  props: IMerchantBankAccount & { sx?: SxProps },
) => (
  <Text
    color={palette.neutral[70]}
    sx={{ fontStyle: props.bankName ? "normal" : "italic", ...props.sx }}
  >
    {props.bankName || props.name}
    {!props.bankName && (
      <span style={{ fontStyle: "normal" }}>
        {" "}
        {`•••• ${props.accountNumber?.slice(-4)}`}
      </span>
    )}
    <span style={{ color: palette.gray[100], fontStyle: "normal" }}>
      {props.enterprise && " · Provider"}
    </span>
  </Text>
);

const Container = styled(Box)(() => ({
  padding: "8px 16px",
  borderRadius: "8px",
  background: palette.neutral.white,
  boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.10)",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
}));

const ActionsButton = styled(Button)(() => ({
  borderRadius: "32px",
  border: `1px solid ${palette.neutral[70]}`,
  background: palette.neutral[10],
  boxShadow: "none",
  width: "100px",
  margin: "0 auto",
  fontSize: "14px !important",
  "&:hover": {
    background: palette.neutral[10],
  },
}));

const checkIconStyle = {
  padding: "4px",
  borderRadius: "50%",
  background: palette.black[300],
  display: "inline-flex",
};

export default MerchantBankAccountItem;
