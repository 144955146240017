import * as Yup from "yup";
import { matchIsValidTel } from "mui-tel-input";
import { urlSchema } from "@utils/validation.helpers";
import { MERCHANT_PROVIDER_MAX_CHARS } from "@constants/constants";

export const getCreateMerchantInfoSchema = (isAcquirerPortal: boolean) => {
  return {
    averageTicketAmount: Yup.string(),
    highTicketAmount: Yup.string(),
    merchantName: Yup.string()
      .required("")
      .required("Name is required")
      .matches(
        /^(?=(.*[a-zA-Z]){3}).*$/,
        "Please provide a valid name with at least 3 letters",
      )
      .max(
        MERCHANT_PROVIDER_MAX_CHARS,
        `Merchant Name can not contain more than ${MERCHANT_PROVIDER_MAX_CHARS} characters`,
      )
      .trim(),
    merchantSlug: Yup.string(),
    businessPurpose: Yup.string(),
    websiteURL: urlSchema({
      isRequired: false,
    }),
    billingDescriptor: Yup.string(),
    servicePhoneNumber: Yup.string().when({
      is: (exists: string) => !!exists,
      then: (schema) =>
        schema.test(
          "is-valid-number",
          "Please enter a valid phone number",
          function (value) {
            const phoneNumber = value as string;
            if (phoneNumber === "+1") return true;
            return matchIsValidTel(phoneNumber);
          },
        ),
    }),
    estimatedAnnualRevenue: Yup.string(),
    serviceCountriesOutUSCanada: Yup.boolean(),
    countriesServicedOutside: Yup.string().when("serviceCountriesOutUSCanada", {
      is: true,
      then: Yup.string().required("At least one serviced country is required"),
    }),
    enterpriseID: isAcquirerPortal
      ? Yup.number()
          .typeError("Please specify the provider")
          .required("Please specify the provider")
      : Yup.number().nullable(),
    description: Yup.string(),
  };
};

export const createPrimaryAccountHolderSchema = {
  email: Yup.string(),
  invite: Yup.boolean(),
};

export const invitePrimaryAccountHolderSchema = {
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email is required"),
};

export const createBusinessProfileSchema = {
  isLinkBusinessProfile: Yup.boolean(),
  linkedBusinessProfile: Yup.number(),
  businessType: Yup.string(),
  ownershipType: Yup.string(),
  legalName: Yup.string(),
  DBA: Yup.string(),
  taxID: Yup.string(),
  contactPhone: Yup.string(),
  address: Yup.object({
    country: Yup.string(),
    city: Yup.string(),
    address: Yup.string(),
    state: Yup.string(),
    zip: Yup.string(),
  }),
};

export const createBusinessOwnersSchema = {
  files: Yup.array(),
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string(),
  ownership: Yup.string(),
  ssn: Yup.string(),
  dob: Yup.date(),
  phone: Yup.string(),
  isBusinessAddress: Yup.boolean(),
  address: Yup.object().shape({
    country: Yup.string(),
    line1: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zip: Yup.string(),
  }),
};

export const defaultValues = {
  merchantInfo: {
    averageTicketAmount: "",
    highTicketAmount: "",
    merchantName: "",
    category: 0,
    merchantSlug: "",
    websiteURL: "",
    billingDescriptor: "",
    servicePhoneNumber: "",
    enterpriseID: undefined,
    description: "",
    thumbnail: null,
    enterpriseSlug: "provider-slug",
    businessPurpose: "",
    estimatedAnnualRevenue: "",
    serviceCountriesOutUSCanada: false,
    countriesServicedOutside: "",
  },
  primaryAccountHolder: {
    email: "",
    invite: false,
  },
  businessProfile: {
    isLinkBusinessProfile: false,
    businessType: "corporation",
    ownershipType: "public",
    legalName: "",
    DBA: "",
    taxID: "",
    ssn: "",
    tinType: "ein",
    contactPhone: "",
    businessOpenedAt: "",
    address: {
      country: "US",
      city: "",
      address: "",
      state: "",
      zip: "",
    },
  },
  businessOwners: [],
  bankAccounts: [],
  merchantAgreement: {},
};
