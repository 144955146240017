import { customInstance } from "@services/api";
import { getTrustedDevices } from "@services/api/2FA/trustedDevices";
import { useQuery } from "react-query";

export const useListTrustedDevices = () => {


  const { data, error, isLoading } = useQuery(
    ["get-trusted-devices"],
    async () => {
      const data = await getTrustedDevices();
      return data;
    },
    {
      //   refetchOnWindowFocus: false,
      onError(err: any) {
        //error
      },
      onSuccess(_data) {
        //success
      },
    },
  );

  return {
    isLoading,
    data,
    error,
  };
};
