export enum ErrorType_bulk_merchant {
  EMAIL_INVALID = "email_invalid",
  EMAIL_REQUIRED = "email_required",
  MERCHANT_NAME_REQUIRED = "merchantName_required",
  MERCHANT_NAME_EXISTS = "merchantName_exists",
  SLUG_EXISTS = "slug_exists",
}

export type TImportedRow = {
  pahEmail: string;
  merchantName: string;
  errorType?: ErrorType_bulk_merchant;
};

export interface TInviteElement extends TImportedRow {
  checked: boolean;
}

export type TInvitesMap = Map<string, TInviteElement>;
