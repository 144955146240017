import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type SelectedProduct = {
  product_id?: number;
  imgSrc: string;
  title: string;
};

export interface Product {
  selectedProduct: SelectedProduct;
}
interface RootState {
  product: Product;
}

export const initialProductState: Product = {
  selectedProduct: {
    product_id: undefined,
    imgSrc: "",
    title: "",
  },
};

const productSlice = createSlice({
  name: "product",
  initialState: initialProductState,
  reducers: {
    setNewSelectedProduct: (
      state: Product,
      action: PayloadAction<SelectedProduct>,
    ) => {
      const payload = action.payload;
      const actionProduct = {
        product_id: payload?.product_id || undefined,
        imgSrc: payload?.imgSrc || "",
        title: payload?.title || "",
      };
      state.selectedProduct = actionProduct;
    },
  },
});

export const { setNewSelectedProduct } = productSlice.actions;

export const selectProduct = (state: RootState) => state.product;

export default productSlice.reducer;
