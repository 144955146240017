import { Text } from "@common/Text";
import NotificationCheckBox from "@components/ProfileMenu/components/NotificationCheckBox";
import { Box, Skeleton, Stack, styled } from "@mui/material";
import useNotificationTab from "../../hooks/useNotificationTab";
import { ProfileModalPageProps } from "../../types";
import { palette } from "@palette";
import { HiddenComponent } from "@containers/HiddenComponent";
import NotificationGridLayout, {
  TNotificationCheckbox,
} from "@components/ProfileMenu/components/NotificationGridLayout";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { FilterButtonBase } from "features/Notifications/NotificationsCenter/components/Header.atoms";
import { TabsArrayItem } from "../../hooks/useAlertsTabs";
import { TParsedAlert } from "../../types";
import { shouldBeHidden } from "@constants/constants";
import { PaperPlaneTilt, At } from "@phosphor-icons/react";

function NotificationTab({ setDisabled }: ProfileModalPageProps) {
  const { isMobileView } = useCustomTheme();
  const {
    activeTab,
    setActiveTab,
    tabs,
    customData,
    isLoading,
    handleChange,
    handleCheckAll,
    handleSubmit,
  } = useNotificationTab({ setIsDisabled: setDisabled });

  const renderTabs = (item: TabsArrayItem) => {
    const { name, value, hidden } = item;
    const isActive = value === activeTab?.value;

    const onClick = () => setActiveTab(item.value);

    return (
      <HiddenComponent key={value} hidden={hidden || false}>
        {isMobileView ? (
          <FilterButtonBase
            background="tertiary"
            selected={isActive}
            onClick={onClick}
          >
            {name}
          </FilterButtonBase>
        ) : (
          <CustomTabBtx isActive={isActive} onClick={onClick}>
            {name}
          </CustomTabBtx>
        )}
      </HiddenComponent>
    );
  };

  const {
    alerts,
    totalAssigned: { emailCount, pushCount },
  } = customData;
  const alertsTotal = alerts.length;

  const renderCheckbox = (item: TParsedAlert, idx: number) => (
    <NotificationCheckBox
      key={`${activeTab?.value} ${item?.name || idx}`}
      delay={100 + idx * 50}
      item={item}
      handleChange={handleChange}
      isLast={idx === alertsTotal - 1}
    />
  );

  const checkAllActions: TNotificationCheckbox[] = [
    {
      channel: "email",
      label: (
        <>
          <At weight="regular" size={16} color={palette.gray[300]} />
          Email
        </>
      ),
      labelAlign: "left",
      dataTestId: "email-check-all",
      value: alertsTotal === emailCount,
      indeterminate: alertsTotal === emailCount ? false : emailCount > 0,
      handleChange: () =>
        handleCheckAll("email", emailCount === 0 ? "add" : "remove"),
    },
    {
      channel: "push",
      hidden: shouldBeHidden.pushNotificationSettings,
      label: (
        <>
          <PaperPlaneTilt
            weight="regular"
            size={16}
            color={palette.gray[300]}
            data-testid="push-notifications-column-icon"
          />
          Push
        </>
      ),
      labelAlign: "left",
      dataTestId: "push-check-all",
      value: alertsTotal === pushCount,
      indeterminate: alertsTotal === pushCount ? false : pushCount > 0,
      handleChange: () =>
        handleCheckAll("push", pushCount === 0 ? "add" : "remove"),
    },
  ];

  return (
    <Box overflow="hidden" height="100%" width="100%">
      <form
        style={{
          overflow: "hidden",
          height: "100%",
          width: "100%",
        }}
        id="profile-form"
        data-testid="notification-tab"
        onSubmit={handleSubmit}
      >
        {isLoading ? (
          <TabBarSkeleton />
        ) : (
          <NotificationGridLayout
            showBorder={!isMobileView}
            containerSx={{
              ...(!isMobileView && {
                marginBottom: "24px",
              }),
            }}
            mainContent={
              <Stack direction="row" gap={2} alignItems="center">
                {tabs?.map(renderTabs)}
              </Stack>
            }
            checkboxes={
              alertsTotal === 0 || isMobileView ? [] : checkAllActions
            }
          />
        )}
        {isLoading ? (
          <CheckboxesSkeleton />
        ) : (
          <CheckboxesContainer>
            {alerts.map(renderCheckbox)}
          </CheckboxesContainer>
        )}
      </form>
    </Box>
  );
}

export default NotificationTab;

const TabBarSkeleton = () => {
  return (
    <FadeUpWrapper delay={50}>
      <Stack
        direction="row"
        justifyContent="space-between"
        padding="12px 0"
        borderBottom={`1px solid ${palette.liftedWhite[200]}`}
        marginBottom="24px"
      >
        <TabsSkeleton />
        <Stack direction="row" gap="12px" alignItems="center">
          {Array.from({ length: 2 }, (_, i) => (
            <TabBarEndSkeleton key={i} />
          ))}
        </Stack>
      </Stack>
    </FadeUpWrapper>
  );
};

const CheckboxesSkeleton = () => (
  <Box mt="24px" pr="5px">
    {Array.from({ length: 7 }, (c, idx) => (
      <Skeleton
        key={idx}
        sx={{
          borderRadius: "8px",
          marginTop: "8px",
        }}
        width="100%"
        variant="rounded"
        height="64px"
      />
    ))}
  </Box>
);

const TabBarEndSkeleton = () => (
  <Stack alignItems="center" gap="6px" flexDirection="row">
    <Skeleton
      sx={{
        borderRadius: "8px",
      }}
      width="50px"
      variant="rounded"
      height="16px"
    />
    <Skeleton
      sx={{
        borderRadius: "6px",
      }}
      width="20px"
      variant="rounded"
      height="20px"
    />
  </Stack>
);

const TabsSkeleton = () => (
  <Box gap="16px" display="flex" alignItems="center">
    {Array.from({ length: 3 }, (c, idx) => (
      <Skeleton
        key={idx}
        sx={{
          borderRadius: "8px",
        }}
        width="64px"
        variant="rounded"
        height="16px"
      />
    ))}
  </Box>
);

const CustomTabBtx = styled(Text, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{ isActive: boolean }>(({ isActive }) => ({
  all: "unset",
  cursor: isActive ? "default" : "pointer",
  fontSize: "14px",
  lineHeight: "16.8px",
  fontWeight: 350,
  color: isActive ? palette.neutral[100] : palette.neutral[70],
  userSelect: "none",
  transitions: "all 50ms ease",
}));

const CheckboxesContainer = styled(Box)(() => ({
  "&::-webkit-scrollbar": {
    display: "none",
  },
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  overflowY: "auto",
  maxHeight: "calc(100% - 96px)",
}));
