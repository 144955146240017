import { Grid, Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import GiveChip from "@shared/Chip/GiveChip";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { ChatsCircle, DotsThree } from "@phosphor-icons/react";
import { styled } from "@theme/v2/Provider";
import GiveDivider from "@shared/GiveDivider/GiveDivider";
import GiveButton from "@shared/Button/GiveButton";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import ContextualMenu from "@shared/ContextualMenu/ContextualMenu";
import ResendInviteButton from "./ResendInviteButton";
import usePAHInfoSection from "../hooks/usePAHInfoSection";
import { IParsedData } from "@components/Merchants/MerchantPreview/data.types";
import { ISectionItem } from "features/Merchants/MerchantSidePanel/GiveMerchantFile/hooks/useGetSectionItems";
import { GiveSectionItem } from "features/Merchants/MerchantSidePanel/GiveMerchantFile/GiveSectionItem";
import IdentityVerification from "features/Merchants/MerchantSidePanel/components/PrimaryAccountHolderSection/IdentityVerification";

import GiveTruncateText from "@shared/Text/GiveTruncateText";
import GiveBusinessProfileTag from "features/Merchants/MerchantSidePanel/GiveMerchantFile/businessProfile/GiveBusinessProfileTag";
import { checkPortals } from "@utils/routing";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";

interface IComponentProps {
  data: IParsedData;
  id: number;
  items?: ISectionItem[];
}

const PAHInfoSection = ({ data, id, items }: IComponentProps) => {
  const { isMobileView } = useCustomThemeV2();
  const status = data?.primaryAccountHolder?.owner?.statusName;
  const {
    menuAnchorEl,
    menuButtonRef,
    isPreviewDisabled,
    menuOptions,
    handleOpenPreview,
    handleOpenConversation,
    handleOpenMenu,
    handleCloseMenu,
  } = usePAHInfoSection(id, data);

  const { merchant_underwriting } = useEnterprisePermissions();
  const { isAcquirerPortal } = checkPortals();

  return (
    <Stack gap={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" gap="10px">
          <GiveText>Primary Account Holder</GiveText>
          <GiveBusinessProfileTag statusCode={status} />
        </Stack>
        <Stack direction="row" gap="4px">
          {merchant_underwriting && isAcquirerPortal && (
            <GiveIconButton
              onClick={handleOpenConversation}
              Icon={ChatsCircle}
              variant="ghost"
            />
          )}
          <GiveIconButton
            elementRef={menuButtonRef}
            onClick={handleOpenMenu}
            Icon={DotsThree}
            variant="ghost"
          />
        </Stack>
      </Stack>

      <StyledSectionStack>
        {data.invitation.inviteStatus != "invited" && items ? (
          <>
            <Grid container spacing={2}>
              {items.map((item) => (
                <>
                  {item.split && <GiveDivider />}
                  <GiveSectionItem key={item.title} item={item} />
                </>
              ))}
            </Grid>
            <GiveDivider />
            <GiveText color="secondary" paddingBottom="8px" variant="bodyS">
              Identity Verification
            </GiveText>
            <IdentityVerification />
          </>
        ) : (
          <>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <GiveText variant="bodyS" color="secondary">
                  Email
                </GiveText>
              </Grid>
              <Grid item xs={6}>
                <GiveTruncateText variant="bodyS" lineClamp={2}>
                  {data?.primaryAccountHolder?.email}
                </GiveTruncateText>
              </Grid>
            </Grid>
            <GiveDivider />
            <Grid container spacing={2}>
              <Grid item xs={isMobileView ? 12 : 6}>
                <Stack
                  direction="row"
                  gap={1}
                  alignItems="center"
                  height="100%"
                >
                  <GiveText width={isMobileView ? "50%" : "fit-content"}>
                    Invitation
                  </GiveText>
                  <GiveChip
                    variant="light"
                    label={data?.primaryAccountHolder?.inviteStatusDisplayName}
                  />
                </Stack>
              </Grid>
              <Grid item xs={isMobileView ? 12 : 6}>
                <Stack direction="row" gap="10px">
                  <ResendInviteButton data={data?.invitation} id={id} />
                  <GiveButton
                    onClick={handleOpenPreview}
                    fullWidth={isMobileView}
                    disabled={isPreviewDisabled}
                    size="small"
                    color="light"
                    label="Preview"
                    sx={{
                      border: "none",
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </>
        )}
      </StyledSectionStack>
      <ContextualMenu
        anchorEl={menuAnchorEl}
        color="primary"
        texture="solid"
        options={menuOptions}
        handleClose={handleCloseMenu}
      />
    </Stack>
  );
};

const StyledSectionStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette?.surface?.primary,
  padding: "20px",
  borderRadius: "20px",
}));

export default PAHInfoSection;
