import { CheckedFilledCircleIcon } from "@assets/icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Stack,
  styled,
  SxProps,
} from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import InfoFillIcon from "@assets/icons/InfoFillIcon";
import { CaretDown, CircleDashed } from "@phosphor-icons/react";
import { Button } from "@common/Button";
import { Tooltip } from "@common/Tooltip";
import { DisputeCaseType } from "../data.types";
import { format } from "date-fns";
import NiceModal from "@ebay/nice-modal-react";
import { EVIDENCE_PROVIDED_MODAL } from "modals/modal_names";
import { useState } from "react";

const DisputeCircleDashedIcon = () => {
  return (
    <Box
      width={24}
      height={24}
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="32px"
      bgcolor={palette.filled.orange}
    >
      <CircleDashed size={12} color={palette.neutral.white} />
    </Box>
  );
};

const ToolTipText = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <Text
      color={palette.neutral[40]}
      fontSize={12}
      fontWeight="book"
      lineHeight="14.4px"
    >
      {title}: <span style={{ color: palette.neutral[5] }}>{description}</span>
    </Text>
  );
};

interface IDisputeHistoryCardProps {
  data: DisputeCaseType;
  issuingBankName: string;
  isLast?: boolean;
  isFirst?: boolean;
}

const DisputeCaseHistoryCard = ({
  data,
  isLast,
  isFirst,
  issuingBankName,
}: IDisputeHistoryCardProps) => {
  const [expanded, setExpanded] = useState(isFirst);

  const statusIcons = {
    done: <CheckedFilledCircleIcon width={24} height={24} />,
    ongoing: <DisputeCircleDashedIcon />,
  };

  const handleViewEvidence = () => {
    NiceModal.show(EVIDENCE_PROVIDED_MODAL, {
      disputeId: data?.disputeID,
      caseId: data?.id,
      caseAction: data?.statusActionDisplayName,
      caseNotes: data?.notes,
    });
  };

  return (
    <>
      <StyledAccordion
        onChange={() => setExpanded((curr) => !curr)}
        disableGutters
        expanded={expanded}
      >
        <StyledAccordionSummary
          expandIcon={<CaretDown size={16} stroke={palette.neutral[80]} />}
        >
          <Text color={palette.neutral[80]}>
            Fulfillment (3 Evidence Submitted)
          </Text>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Stack gap={1} data-testid="dispute-history-card">
            <Text color={palette.neutral[70]} lineHeight="16.8px">
              {format(new Date(data?.createdAt * 1000), "MMM d, yyyy, HH:mm")}
            </Text>
            <StyledCardContainer>
              <Stack direction="row" gap={2}>
                {["pending", "open"]?.includes(data?.status)
                  ? statusIcons["ongoing"]
                  : statusIcons["done"]}
                <Stack spacing={0.5}>
                  <Text color={palette.neutral[70]} lineHeight="16.8px">
                    {data?.status === "submitted"
                      ? "Merchant (You)"
                      : issuingBankName || "Issuing Bank"}
                  </Text>
                  <Text
                    fontSize={16}
                    color={palette.neutral[80]}
                    lineHeight="16px"
                  >
                    {data?.notes}
                  </Text>
                  <Text color={palette.neutral[80]} lineHeight="16.8px">
                    {data?.reason}
                  </Text>
                </Stack>
              </Stack>
              {data?.fileEvidence && (
                <>
                  <Divider color={palette.neutral[30]} />
                  <StyledButton
                    size="medium"
                    background="tertiary"
                    onClick={handleViewEvidence}
                  >
                    View Evidence
                  </StyledButton>
                </>
              )}
            </StyledCardContainer>
          </Stack>
        </StyledAccordionDetails>
      </StyledAccordion>
      {!isLast && <StyledVerticalLine />}
    </>
  );
};

const StyledAccordion = styled(Accordion)(() => ({
  boxShadow: "none",
  borderRadius: "8px !important",
  backgroundColor: palette.background.newWhite,
  border: `1px solid ${palette.neutral[20]}`,
  marginBottom: "16px",
}));

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  padding: "16px",
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  borderRadius: "8px !important",
  backgroundColor: palette.neutral[10],
}));

const StyledCardContainer = styled(Stack)(() => ({
  borderRadius: "8px",
  padding: "16px",
  backgroundColor: palette.neutral[10],
  gap: "16px",
}));

const StyledButton = styled(Button)({
  width: "fit-content",
  height: "fit-content",
  padding: 0,
  color: palette.neutral[80],
  fontWeight: "350",
});

const StyledVerticalLine = styled(Box)(({ theme }) => ({
  height: 28,
  width: 1,
  backgroundColor: palette.gray[100],
  marginLeft: theme.spacing(2),
}));

const IconWrapper = styled(Box)({
  "&:hover ": {
    cursor: "pointer",
  },
});

const toolTipSx: SxProps = {
  right: "8px",
  top: "8px",
  borderRadius: "8px",
  alignItems: "flex-start",
  paddingTop: "4px",
  paddingBottom: "4px",
  backgroundColor: palette.neutral[80],
  maxWidth: "unset",
};

export default DisputeCaseHistoryCard;
