type IconProps = {
  height?: number;
  width?: number;
  stroke?: string;
};

const EmbedIcon = ({
  width = 49,
  height = 48,
  stroke = "#A9AFBD",
}: IconProps) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="56" height="56" rx="28" fill="black" fillOpacity="0.1" />
      <path
        d="M24.2058 21.6563L17.0626 28L24.2015 34.3438C24.2876 34.42 24.358 34.5125 24.4084 34.616C24.4588 34.7194 24.4884 34.8318 24.4954 34.9466C24.5024 35.0615 24.4867 35.1766 24.4492 35.2854C24.4117 35.3943 24.3532 35.4946 24.2769 35.5808C24.2007 35.667 24.1082 35.7373 24.0047 35.7877C23.9013 35.8382 23.7889 35.8677 23.674 35.8747C23.5592 35.8817 23.444 35.866 23.3352 35.8286C23.2264 35.7911 23.1261 35.7325 23.0399 35.6563L15.1649 28.6563C15.0718 28.5742 14.9972 28.4732 14.9461 28.36C14.8951 28.2469 14.8687 28.1242 14.8687 28C14.8687 27.8759 14.8951 27.7532 14.9461 27.64C14.9972 27.5269 15.0718 27.4259 15.1649 27.3438L23.0399 20.3438C23.1261 20.2672 23.2265 20.2084 23.3354 20.1706C23.4443 20.1329 23.5596 20.1169 23.6747 20.1238C23.7898 20.1306 23.9024 20.16 24.0061 20.2103C24.1098 20.2606 24.2026 20.3309 24.2791 20.417C24.3557 20.5032 24.4145 20.6036 24.4523 20.7126C24.49 20.8215 24.5059 20.9368 24.4991 21.0518C24.4923 21.1669 24.4629 21.2795 24.4126 21.3832C24.3623 21.4869 24.292 21.5797 24.2058 21.6563ZM40.8308 27.3438L32.9558 20.3438C32.7818 20.1897 32.5537 20.1111 32.3217 20.1253C32.0897 20.1395 31.8728 20.2452 31.7188 20.4192C31.5648 20.5933 31.4862 20.8214 31.5003 21.0534C31.5145 21.2854 31.6202 21.5022 31.7943 21.6563L38.9375 28L31.7986 34.3438C31.7125 34.42 31.6422 34.5125 31.5917 34.616C31.5413 34.7194 31.5117 34.8318 31.5047 34.9466C31.4977 35.0615 31.5134 35.1766 31.5509 35.2854C31.5884 35.3943 31.6469 35.4946 31.7232 35.5808C31.7994 35.667 31.8919 35.7373 31.9954 35.7877C32.0988 35.8382 32.2112 35.8677 32.3261 35.8747C32.4409 35.8817 32.5561 35.866 32.6649 35.8286C32.7737 35.7911 32.874 35.7325 32.9602 35.6563L40.8352 28.6563C40.9283 28.5742 41.0029 28.4732 41.054 28.36C41.105 28.2469 41.1314 28.1242 41.1314 28C41.1314 27.8759 41.105 27.7532 41.054 27.64C41.0029 27.5269 40.9283 27.4259 40.8352 27.3438H40.8308Z"
        fill="#292928"
      />
    </svg>
  );
};

export default EmbedIcon;
