import { Box, Stack } from "@mui/material";
import {
  ArrowLineDown,
  Check,
  UploadSimple,
  Warning,
  X,
} from "@phosphor-icons/react";
import { useAppTheme } from "@theme/v2/Provider";
import React from "react";
import SnackbarLinearProgress from "shared/Snackbar/SnackbarLinearProgress";
import GiveText from "../Text/GiveText";
import { SnackbarIconsType } from "./GiveSnackbarTypes";
import { DEFAULT_ICON_SIZE } from "../constants";
import GiveTruncateText from "../Text/GiveTruncateText";

interface Props {
  type: SnackbarIconsType;
  title: string;
  description: React.ReactNode;
  progress?: number;
  rightContent?: React.ReactNode;
  isMobile: boolean;
}
const GiveSnackbar = ({
  type = "success",
  title,
  description,
  progress,
  rightContent,
  isMobile,
}: Props) => {
  const { palette } = useAppTheme();

  return (
    <Stack
      direction="row"
      gap="12px"
      sx={{
        maxWidth: isMobile ? "100%" : "320px",
        width: isMobile ? "100%" : "fit-content",
        minWidth: type === "upload" ? "320px" : "163px",
        padding: "10px",
        borderRadius: "12px",
        backgroundColor: palette["surface-inverted"]?.primary,
        height: "fit-content",
        alignItems: "center",
      }}
    >
      <Stack direction="column" gap="8px" width="100%">
        <Stack
          direction="row"
          gap="12px"
          sx={{
            height: "fit-content",
            alignItems: "center",
          }}
        >
          <Icon type={type} />
          <Stack direction="column" gap="2px">
            <GiveTruncateText
              lineClamp={1}
              variant="bodyS"
              sx={{ color: palette["text-inverted"]?.primary }}
            >
              {title}
            </GiveTruncateText>
            <GiveText
              variant="bodyXS"
              sx={{ color: palette["text-inverted"]?.secondary }}
            >
              {description}
            </GiveText>
          </Stack>
        </Stack>
        {typeof progress === "number" && (
          <SnackbarLinearProgress progress={progress} />
        )}
      </Stack>
      {rightContent}
    </Stack>
  );
};

export default GiveSnackbar;
const ICON_SIZE = DEFAULT_ICON_SIZE;
const Icon = ({ type }: { type: SnackbarIconsType }): React.ReactElement => {
  const { palette } = useAppTheme();

  switch (type) {
    case "download":
      return (
        <IconBox backgroundColor={palette["surface-inverted"]?.tertiary}>
          <ArrowLineDown
            size={ICON_SIZE}
            color={palette["icon-inverted"]?.["icon-primary"]}
          />
        </IconBox>
      );
    case "upload":
      return (
        <IconBox backgroundColor={palette["surface-inverted"]?.tertiary}>
          <UploadSimple
            size={ICON_SIZE}
            color={palette["icon-inverted"]?.["icon-primary"]}
          />
        </IconBox>
      );
    case "success":
      return (
        <IconBox backgroundColor={palette.primitive?.["success-inverted"][25]}>
          <Check
            size={ICON_SIZE}
            color={palette.primitive?.["success-inverted"][100]}
          />
        </IconBox>
      );
    case "warning":
      return (
        <IconBox backgroundColor={palette.primitive?.["warning-inverted"][10]}>
          <Warning
            size={ICON_SIZE}
            color={palette.primitive?.["warning-inverted"][100]}
          />
        </IconBox>
      );
    case "error":
      return (
        <IconBox backgroundColor={palette.primitive?.["error-inverted"][25]}>
          <X
            size={ICON_SIZE}
            color={palette.primitive?.["error-inverted"][100]}
          />
        </IconBox>
      );
    default:
      return (
        <IconBox backgroundColor={palette.surface?.tertiary}>
          <Check size={ICON_SIZE} />
        </IconBox>
      );
  }
};

const IconBox = ({
  backgroundColor,
  children,
}: {
  backgroundColor?: string;
  children: React.ReactNode;
}): React.ReactElement => {
  return (
    <Box
      sx={{
        backgroundColor,
        borderRadius: "50%",
        height: "30px",
        width: "30px",
        flex: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  );
};
