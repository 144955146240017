import NiceModal, { useModal } from "@ebay/nice-modal-react";
// form
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useMemo, useRef } from "react";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useGetEnterpriseById } from "@hooks/acquirer-api/account/useGetEnterprises";
import { checkPortals } from "@utils/routing";
import { defaultValues } from "../defaultValues";

import { getEntrepriseCreateSchema } from "../Schemas";
import {
  IMerchantBankAccount,
  TBusinessOwner,
} from "@components/Merchants/MerchantPreview/data.types";
import { useUploadSingleImage as useUploadSingleImageNew } from "@hooks/upload-api/uploadHooks";
import { renameBEBankAccountStatus } from "@components/Merchants/MerchantPreview/helpers/bankAccounts";
import { findBankByRoutingNumber } from "@utils/bank_list";
import { toUnixDateFormat } from "@utils/date.helpers";
import { useMutation, useQueryClient } from "react-query";
import { showMessage } from "@common/Toast";
import {
  getMerchantErrorMessage,
  getMerchantErrorParam,
} from "@components/Merchants/CreateMerchantPanel/helpers";
import { customInstance } from "@services/api";
import {
  CREATE_BUSINESS_PROFILE_MODAL,
  CREATE_PRIMARY_ACCOUNT_HOLDER_MODAL,
  EDIT_BANK_ACCOUNT_MODAL,
  EDIT_BUSINESS_OWNER_MODAL,
  EDIT_ENTERPRISE_MCC_MODAL,
  ENTERPRISE_CREATED,
} from "modals/modal_names";
import { FormType } from "@components/Merchants/CreateMerchantPanel/modals/CreateBusinessProfileModal";
import { QKEY_LIST_ENTERPRISE_STATS } from "@constants/queryKeys";
import { useListEnterpriseCategoryCodes } from "@hooks/enterprise-api/merchants/useListEnterpriseCategoryCodes";
import { ICreateEnterpriseSchema } from "../types";
import { SignupType } from "@hooks/onboarding/utils";
import { EnterpriseConfigurationList } from "../constants/EnterpriseConfiguration.constants";
import { useAddSignature as useAddSignatureOld } from "@components/ProfilePage/MerchantAgreementSetup/hooks/useUpdateUser";

import { isEmpty } from "lodash";
import { useAddSignature as useAddSignatureNew } from "@hooks/upload-api/useAddSignature";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { useUploadSingleImage as useUploadSingleImageOld } from "@hooks/merchant-api/image-gallery/useUploadSingleImage";

export const useCreateEnterpise = () => {
  const modal = useModal();
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
  const queryClient = useQueryClient();
  const { isEnterprisePortal } = checkPortals();
  const { merchantId: accountId } = useGetCurrentMerchantId();
  const { data: provider } = useGetEnterpriseById(accountId.toString());
  const { handleUpload: handleUploadNew, isLoading: imageLoadingNew } =
    useUploadSingleImageNew();
  const { handleUpload: handleUploadOld, isLoading: imageLoadingOld } =
    useUploadSingleImageOld();
  //
  const handleUpload = isFileUploadRefactorEnabled
    ? handleUploadNew
    : handleUploadOld;
  const imageLoading = isFileUploadRefactorEnabled
    ? imageLoadingNew
    : imageLoadingOld;
  const {
    handleUploadSignature: handleUploadSignatureNew,
    isLoading: isFileUploadLoadingNew,
  } = useAddSignatureNew();
  const {
    handleUploadSignature: handleUploadSignatureOld,
    isLoading: isFileUploadLoadingOld,
  } = useAddSignatureOld();
  const handleUploadSignature = isFileUploadRefactorEnabled
    ? handleUploadSignatureNew
    : handleUploadSignatureOld;
  const isFileUploadLoading = isFileUploadRefactorEnabled
    ? isFileUploadLoadingNew
    : isFileUploadLoadingOld;
  const { categories: parentCategories } =
    useListEnterpriseCategoryCodes(accountId);
  const schema: any = getEntrepriseCreateSchema();

  const handleClose = () => modal.hide();
  const methods = useForm<ICreateEnterpriseSchema>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const { watch, setValue } = methods;
  const categoriesInitiated = useRef(false);
  useEffect(() => {
    if (parentCategories.length > 0 && !categoriesInitiated.current) {
      setValue("categories", parentCategories);
      categoriesInitiated.current = true;
    }
  }, [parentCategories]);
  const values = watch();

  useEffect(() => {
    if (modal.visible) {
      methods.reset(defaultValues);
      categoriesInitiated.current = false;
    }
  }, [modal.visible]);

  const handleCloseBusinessOwnerModal = (
    data?: TBusinessOwner,
    id?: string,
  ) => {
    if (id && isEmpty(data)) {
      // remove
      setValue(
        "businessOwners",
        values.businessOwners.filter(
          (owner) => owner?.id?.toString() === id?.toString(),
        ),
      );

      values?.businessProfile?.linkedOwnerId?.toString() === id?.toString() &&
        setValue("businessProfile", defaultValues?.businessProfile);

      return;
    }
    if (!data) return;

    const existedOwner = values.businessOwners.find(
      (owner) => owner.id?.toString() === data.id?.toString(),
    );
    if (existedOwner) {
      // create
      const updatedOwners = values.businessOwners.map((owner) => {
        if (owner.id?.toString() === data.id?.toString()) return data;
        return owner;
      });
      setValue("businessOwners", updatedOwners);
    } else {
      // edit
      setValue("businessOwners", [...values.businessOwners, data]);
    }
  };

  const handleCloseBankAccountModal = (
    data?: IMerchantBankAccount,
    id?: number,
  ) => {
    if (id) {
      // remove
      setValue(
        "bankAccounts",
        values.bankAccounts.filter((account: any) => account.id !== id),
      );
      return;
    }
    if (!data) return;

    const existedAccount = values.bankAccounts.find(
      (account: any) => account.id === data.id,
    );
    if (existedAccount) {
      // create
      const updatedAccounts = values.bankAccounts.map((account: any) => {
        if (account.id === data.id) return data;
        return account;
      });
      setValue("bankAccounts", updatedAccounts);
    } else {
      // edit
      setValue("bankAccounts", [...values.bankAccounts, data]);
    }
  };

  useEffect(() => {
    if (modal.visible) {
      methods.reset(defaultValues);
    }
  }, [modal.visible]);

  useEffect(() => {
    if (isEnterprisePortal && modal.visible && provider) {
      setValue("enterprise_info.enterprise_slug", provider.slug);
      if (provider?.allowedCategoryCodes?.length > 0) {
        setValue(
          "enterprise_info.category",
          provider.allowedCategoryCodes[0].categoryCodes.id,
        );
      }
    }
  }, [isEnterprisePortal, modal.visible, provider]);

  const { mutateAsync: CreateEnterpiseAsync, isLoading } = useMutation(
    (data: any) => {
      return customInstance({
        url: "merchants",
        method: "POST",
        data,
      });
    },
  );

  const handleClosePrimaryAccountHolder = (data: any) => {
    setValue("primary_account_holder", data);
  };
  const handleCloseBusinessOwner = (data: TBusinessOwner) => {
    setValue("businessOwners", [data]);
  };

  const handleCloseMCC = (data: ICreateEnterpriseSchema["categories"]) => {
    setValue("categories", data);
  };

  const handleCloseBusinessProfile = (data: {
    //TODO: add correct types
    businessProfile: any;
    businessOwners?: any[];
  }) => {
    setValue("businessProfile", data.businessProfile);
    if (data.businessProfile.linkedBusinessProfile) {
      setValue("businessOwners", data.businessOwners || []);
    }
  };

  const isCompletedEnterprise = useMemo(
    () =>
      Boolean(values.primary_account_holder.email) &&
      Boolean(values.businessProfile.taxID || values.businessProfile.ssn) &&
      ((values.businessOwners?.length !== 0 &&
        values.businessProfile.linkedBusinessProfile) ||
        !values.businessProfile.linkedBusinessProfile) &&
      values.bankAccounts?.length !== 0 &&
      values?.categories?.length !== 0,
    [values],
  );

  const onSubmit: SubmitHandler<ICreateEnterpriseSchema> = async (data) => {
    const isSoleProprietorship =
      "individual_sole_proprietorship" === data?.businessProfile?.businessType;
    if (!data.primary_account_holder.email) {
      NiceModal.show(CREATE_PRIMARY_ACCOUNT_HOLDER_MODAL, {
        data: data.primary_account_holder,
        onClose: handleClosePrimaryAccountHolder,
        isEnterprise: true,
      });

      return;
    }
    if (!data.businessProfile.taxID && !data.businessProfile.ssn) {
      NiceModal.show(CREATE_BUSINESS_PROFILE_MODAL, {
        data: data.businessProfile,
        onClose: handleCloseBusinessProfile,
        formType: FormType.CREATE_ENTERPRISE,
      });
      return;
    }
    if (
      data.businessOwners?.length === 0 &&
      !data.businessProfile.linkedBusinessProfile
    ) {
      NiceModal.show(EDIT_BUSINESS_OWNER_MODAL, {
        onClose: handleCloseBusinessOwner,
        isEnteprise: true,
        isIndividualSoleProprietorship: isSoleProprietorship,
      });
      return;
    }
    if (data.bankAccounts?.length === 0) {
      NiceModal.show(EDIT_BANK_ACCOUNT_MODAL, {
        onClose: handleCloseBankAccountModal,
        isEnterprise: true,
      });
      return;
    }
    if (values?.categories?.length === 0) {
      NiceModal.show(EDIT_ENTERPRISE_MCC_MODAL, {
        onClose: handleCloseMCC,
        merchantId: accountId,
        categories: [],
        parentCategories,
      });
      return;
    }

    let image = null;
    if (data.enterprise_info.avatar_url) {
      image = await handleUpload(
        isFileUploadRefactorEnabled
          ? ({ file: data.enterprise_info.avatar_url } as any)
          : (data.enterprise_info.avatar_url as any),
      );
    }
    if (!image) image = null;

    const customBusinessOwners = data.businessOwners.map((owner) => {
      const baseObj = {
        firstName: owner.firstName,
        lastName: owner.lastName,
        phoneNumber: owner.phone
          ? owner.phone.slice(1)
          : data.businessProfile?.contactPhone?.slice(1) ?? null,
        email: owner.email,
        ownershipPercentage: owner.ownership ? +owner.ownership : null,
        dateOfBirth: toUnixDateFormat(new Date(owner.dob)),
        ...(!owner.isBusinessAddress &&
          owner.address && {
            address: {
              line1: owner.address.line1,
              line2: "",
              city: owner.address.city,
              state: owner.address.state,
              zip: owner.address.zip,
              country: owner.address.country,
            },
          }),
        useEntityGeoAddress: owner.isBusinessAddress,
        identityProof: owner.identityProof || [],
      };

      if (owner.identityProof?.length) {
        baseObj.identityProof = owner.identityProof;
      }

      return baseObj;
    });

    const customBankAccounts = data.bankAccounts.map((bankAccount) => ({
      name: bankAccount.name,
      type: bankAccount.type,
      routingNumber: bankAccount.routingNumber,
      number: bankAccount.accountNumber,
      notes: bankAccount.notes,
      status: renameBEBankAccountStatus(bankAccount.status),
      statements: bankAccount.statements,
      bankName: findBankByRoutingNumber(bankAccount.routingNumber),
    }));

    const customPermissions = EnterpriseConfigurationList.map((item) => ({
      id: item.configurationID,
      name: item.name,
      isActive: data?.permissions?.[item.name] || false,
    }));
    const signatureFile = data?.agreement?.signatureFile;
    const customData = {
      signupType: SignupType.ACQUIRER_CREATED,
      type: "enterprise",
      inviteOwner: data.primary_account_holder.invite,
      parentAccID: +data.enterprise_info.enterpriseID || accountId,
      name: data.enterprise_info.enterprise_name,
      slug: data.enterprise_info.enterprise_slug,
      class: data.enterprise_info.enterprise_classification
        ? data.enterprise_info.enterprise_classification
        : undefined,
      classType: data.enterprise_info.enterprise_classification
        ? "manage_submerchants"
        : undefined,
      classDescription: data.enterprise_info.classification_description,
      serviceCountriesOutUSCanada: data.enterprise_info.hasServiceCountries,
      countriesServicedOutside: data.enterprise_info.servicedCountries
        ? data.enterprise_info.servicedCountries
        : null,
      description: btoa(
        unescape(encodeURIComponent(data?.enterprise_info?.description)),
      ),
      imageURL: image,
      websiteURL: data.enterprise_info.website_url
        ? "https://" + data.enterprise_info.website_url
        : null,
      billingDescriptor: data.enterprise_info.billingDescriptor.toUpperCase(),
      ...(data.businessProfile.isLinkBusinessProfile &&
        data.businessProfile.linkedBusinessProfile && {
          legalEntityID: data.businessProfile.linkedBusinessProfile,
        }),
      ...(!data.businessProfile.isLinkBusinessProfile &&
        (data.businessProfile.taxID || data.businessProfile.ssn) && {
          legalEntity: {
            hasAcceptedCreditCards: true,
            type: data.businessProfile.businessType,
            name: data.businessProfile.legalName,
            doingBusinessAs: data.businessProfile.DBA,
            ...(data.businessProfile.tinType === "ssn"
              ? {
                  ssn: data?.businessProfile.ssn,
                }
              : {
                  taxIDNumber: data?.businessProfile?.taxID,
                }),
            tinType: data.businessProfile.tinType,
            phoneNumber: data.businessProfile.contactPhone
              ? data.businessProfile.contactPhone.slice(1)
              : null,
            ...(data?.businessProfile?.businessOpenedAt && {
              businessOpenedAt: toUnixDateFormat(
                new Date(data.businessProfile.businessOpenedAt),
              ),
            }),
            estMonthlyVolume: 0,
            allowMultipleMerchants: true,
            ownershipType: data.businessProfile.ownershipType,
            address: {
              line1: data.businessProfile.address.address,
              line2: "",
              city: data.businessProfile.address.city,
              state: data.businessProfile.address.state,
              zip: data.businessProfile.address.zip,
              country: data.businessProfile.address.country,
            },
            principals: customBusinessOwners,
          },
        }),
      bankAccounts: customBankAccounts,
      ...(data.primary_account_holder.email && {
        owner: {
          email: data.primary_account_holder.email,
        },
      }),
      ...(!data.businessProfile.isLinkBusinessProfile &&
        data.businessProfile.taxID && {
          address: {
            line1: data.businessProfile.address.address,
            line2: "",
            city: data.businessProfile.address.city,
            state: data.businessProfile.address.state,
            zip: data.businessProfile.address.zip,
            country: data.businessProfile.address.country,
          },
        }),
      allowedCategoryCodes: data.categories?.map((category) => category.id),
      configurations: customPermissions,
      servicePhoneNumber: data.enterprise_info.servicedPhoneNumber || null,
      creditCardFees: 0,
      amexCreditCardFees: 0,
      debitCardFees: 0,
      allowAddingBankAccounts: true,
      submerchantAllowedToTransfer: true,
      msaHadAgreed: signatureFile ? true : data?.agreement?.msaHadAgreed,
    };

    try {
      const data = await CreateEnterpiseAsync(customData);
      queryClient.refetchQueries(QKEY_LIST_ENTERPRISE_STATS);
      if (signatureFile) {
        await handleUploadSignature(data?.accID, signatureFile, true, {
          msaHadAgreed: true,
        });
      }
      NiceModal.show(ENTERPRISE_CREATED, { handleClose: () => modal.hide() });
    } catch (error: any) {
      if (error?.response?.data.input) {
        const input = error?.response?.data.input[0];

        showMessage(
          "Error",
          getMerchantErrorMessage(input?.param, input?.code, input?.message) ||
            `${getMerchantErrorParam(input?.param)} is not valid`,
        );
        return;
      }
      if (error?.response?.data?.message) {
        showMessage("Error", error?.response?.data?.message);
      }
    }
  };

  return {
    modal,
    handleClose,
    methods,
    schema,
    onSubmit,
    handleCloseBusinessOwnerModal,
    handleCloseBankAccountModal,
    isLoading: isLoading || imageLoading || isFileUploadLoading,
    parentCategories,
    isCompletedEnterprise,
    handleCloseMCC,
  };
};
