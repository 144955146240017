import { MenuListItem } from "types/data.types";
import { useProductPermission } from "features/Permissions/AccessControl/hooks";

const useMenuItemPermission = (itemList: MenuListItem[]) => {
  const { isAddProductAllowed } = useProductPermission();

  const filteredMenu = itemList.filter((item) =>
    item.name === "Pre-Built Forms" ? isAddProductAllowed : true,
  );

  if (filteredMenu.at(-1)?.name === "__title__") filteredMenu.pop();

  return filteredMenu;
};

export default useMenuItemPermission;
