import { showMessage } from "@common/Toast";
import { toUnixDateFormat } from "@utils/date.helpers";

export const onPrincipalError = (error: any) => {
  const errorMessage = error.response.data.message;
  const errorInputs = error.response.data.input;
  const errorCode = error.response.data.code;

  if (errorCode === "invalid_input" && errorInputs && errorInputs.length > 0) {
    for (const err of errorInputs) {
      showMessage(
        "Error",
        `${err.message ? err.message : err.param}`,
        true,
        "",
        6000,
      );
    }
  } else {
    showMessage("Error", `${errorMessage}`);
  }
};

export const generatePrincipalPayload = (
  values: any,
  isMerchantPortal: boolean,
) => {
  const isNOTaxNumber = values?.ssn?.toLowerCase()?.startsWith("x");
  const isConfirmMatch = values?.pepStatusName === "confirmed_match";

  if (isConfirmMatch) {
    return {
      id: values?.id,
      ownershipPercentage: values.ownership ? +values.ownership : null,
    };
  }

  const addressPayload = isMerchantPortal
    ? {
        line1: values?.street || null,
        city: values?.city || null,
        state: values?.state || null,
        zip: values?.zip || null,
        country: values?.country || null,
      }
    : {
        line1: values?.street,
        city: values?.city,
        state: values?.state,
        zip: values?.zip,
        country: values?.country,
      };

  return {
    ...values,
    ...(!isNOTaxNumber && {
      taxIDNumber: values?.ssn,
    }),
    ...(!values?.useBusinessAddress && {
      address: { ...addressPayload },
    }),
    ownershipPercentage: +values?.ownership,
    dateOfBirth: toUnixDateFormat(new Date(values.DOB)),
    useEntityGeoAddress: values?.useBusinessAddress,
  };
};

export const businessOwnerDescriptions = {
  individual_sole_proprietorship:
    "As a sole proprietor, you are the 100% owner and the sole beneficial owner of the business. Please ensure all relevant personal and business information is accurately submitted to meet these requirements.",
  corporation:
    "A beneficial owner is any individual who directly or indirectly owns 10% or more of the company's shares.",
  limited_liability_company:
    "A beneficial owner is any individual who directly or indirectly owns 10% or more of the company's shares.",
  partnership:
    "A beneficial owner is any individual who directly or indirectly owns 10% or more of the company's shares.",
  general_partnership:
    "A beneficial owner is any individual who directly or indirectly owns 10% or more of the company's shares.",
  limited_partnership:
    "A beneficial owner is any individual who directly or indirectly owns 10% or more of the company's shares.",
  tax_exempt_organization:
    "As a non-profit entitiy, you are required to identify and provide information for one individual with significant responsibility for managing the non-profit, such as the executive director or a senior officer.",
  government_agency:
    "As a non-profit entitiy, you are required to identify and provide information for one individual with significant responsibility for managing the non-profit, such as the executive director or a senior officer.",
} as const;

export type IgnoredValueTypes = {
  [key: string]: {
    key: string;
    value: boolean | "both";
  };
};

//logic of usage check 'useCalculatePercentage.tsx'
export const businessOwnerIgnoredValues: IgnoredValueTypes = {
  isNotUSResident: {
    key: "isNotUSResident",
    value: false,
  },
  citizenship: {
    key: "isNotUSResident",
    value: false,
  },
  countryOfResidence: {
    key: "isNotResidentInCitizenshipCountry",
    value: false,
  },
  isNotResidentInCitizenshipCountry: {
    key: "isNotResidentInCitizenshipCountry",
    value: false,
  },
  useBusinessAddress: {
    key: "useBusinessAddress",
    value: "both",
  },
  country: {
    key: "useBusinessAddress",
    value: true,
  },
  state: {
    key: "useBusinessAddress",
    value: true,
  },
  city: {
    key: "useBusinessAddress",
    value: true,
  },
  street: {
    key: "useBusinessAddress",
    value: true,
  },
  zip: {
    key: "useBusinessAddress",
    value: true,
  },
};
