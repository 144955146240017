import { ReplyIcon } from "@assets/icons";
import AvatarPlaceholder from "@assets/images/avatar-placeholder.png";
import { Button } from "@common/Button";
import { triggerExternalWebsitePopUp } from "@common/LinkModalAlert/LinkModalAlert";
import { Image } from "@common/StyledImage/Image";
import { Text, TruncateText } from "@common/Text";
import { Box, Stack } from "@mui/material";
import { useAppDispatch } from "@redux/hooks";
import { setConversationTopic, setReplies } from "@redux/slices/conversations";
import { truncateString } from "@utils/helpers";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import { Interweave as Markup } from "interweave";
import { useClickableHashtags } from "../hooks/useClickableHashtags";
import { TComment } from "../types";
import { TMentionedUser } from "../utils/functions";
import ImagesStacker from "../utils/ImagesStacker";
import CommentLayout from "./CommentLayout";

function transform(node: HTMLElement, children: any): React.ReactNode {
  const href = node.getAttribute('href');
  if (node.tagName === 'A' && href) {
    return <a href={href} rel="noopener noreferrer"  onClick={(e) => {
      e.preventDefault();
      triggerExternalWebsitePopUp(href)
      }}>{children}</a>;
  }
}
type Props = {
  setMentionedUser?: (mentionedUser: TMentionedUser) => void;
  topicId?: number | undefined;
  parentThreadName?: string;
  userAvatars?: string[];
  numberOfReplies?: number;
  isReplyComment?: boolean;
  lastUpdatedAt?: number;
  parentThreadId: number;
  isSending?: boolean;
  index: number;
  isError?: boolean;
  isMobileView?: boolean;
  parentThreadAuthorID: number;
};

const Comment = ({
  id,
  index,
  authorAvatarImageURL,
  authorFirstName,
  authorLastName,
  parentThreadAuthorID,
  body,
  createdAt,
  lastUpdatedAt,
  topicId,
  parentThreadId,
  parentThreadName,
  setMentionedUser,
  userAvatars,
  numberOfReplies,
  isReplyComment,
  isError = false,
  isSending = false,
}: TComment & Props) => {
  const resizedImage = authorAvatarImageURL
    ? authorAvatarImageURL + "/small"
    : AvatarPlaceholder;

  const name = `${authorFirstName} ${authorLastName}`;
  const dispatch = useAppDispatch();

  const { computedBody } = useClickableHashtags(body);

  const handleReply = () => {
    dispatch(
      setConversationTopic({
        queryObject: {
          id: topicId || undefined,
          name: parentThreadName || "",
          paths: [
            {
              avatars: Array.from(new Set(userAvatars)) || [],
              pathName: name,
            },
          ],
        },
      }),
    );
    dispatch(
      setReplies({
        isRepliesOpen: true,
        threadId: parentThreadId,
        commentId: id,
        index: index,
      }),
    );
    const bar = document.querySelector(".conversationsBarActive");
    if (bar) {
      bar.classList.remove("conversationsBarActive");
    }
    setMentionedUser &&
      setMentionedUser({ user: name, authorAccID: parentThreadAuthorID });
  };

  return (
    <>
      <CommentLayout
        isSending={isSending}
        image={
          <Image
            sx={{
              width: "32px",
              height: "32px",
              borderRadius: "32px",
              opacity: isSending ? 0.5 : 1,
            }}
            src={resizedImage}
          />
        }
        name={
          <Text sx={{ opacity: isSending ? 0.5 : 1 }}>
            {truncateString(name, 15)}
          </Text>
        }
        time={
          <Text
            sx={{
              opacity: isSending ? 0.5 : 1,
              color: !isError ? "#8F8F8F" : "red",
            }}
            data-testid={`thread-${id}-timestamp`}
          >
            {!isSending
              ? formatDistanceToNow(fromUnixTime(createdAt), {
                  addSuffix: true,
                })
              : !isError
              ? "Sending..."
              : "Something went wrong"}
          </Text>
        }
        message={<Markup className="comment-markup" content={computedBody}
          transform={transform}
        />}
        action={
          !isReplyComment && !numberOfReplies ? (
            <Button
              onClick={handleReply}
              disabled={isSending}
              sx={{
                color: "#292727",
                fontSize: "14px",
                fontWeight: 400,
                padding: "4px 8px",
                height: "auto",
                opacity: isSending ? 0.5 : 1,
              }}
              background="tertiary"
              endIcon={<ReplyIcon />}
              data-testid={`thread-${id}-reply-button`}
            >
              Reply
            </Button>
          ) : (
            !isSending &&
            !isReplyComment && (
              <Stack direction="row" alignItems="center" spacing={2}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  onClick={handleReply}
                  sx={{
                    padding: "2px 8px",
                    borderRadius: "100px",
                    backgroundColor: "#ECECE9",
                    cursor: "pointer",
                  }}
                  data-testid={`conversation-${id}-number-of-replies`}
                >
                  <TruncateText lineClamp={1} fontSize="12px">
                    {numberOfReplies!}{" "}
                    {numberOfReplies! > 1 ? "Replies" : "Reply"}
                  </TruncateText>
                  <ImagesStacker
                    data={userAvatars?.slice(0, 4) || []}
                    hasBorderedImages
                    borderColor="transparent"
                    renderAdditional={() => (
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                          overflow: "hidden",
                          marginLeft: "-5px", // Adjust the overlap
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          background: "rgba(255, 255, 255, 0.94)",
                          color: "#403D3D",
                        }}
                      >
                        <Text fontSize={12}>
                          +{(userAvatars || [])?.length - 4}
                        </Text>
                      </Box>
                    )}
                    condition={(userAvatars || []).length > 4}
                  />
                </Stack>
                {lastUpdatedAt && (
                  <TruncateText
                    lineClamp={1}
                    color="#8F8F8F"
                    data-testid={`conversation-${id}-last-updated`}
                  >
                    Last updated{" "}
                    {formatDistanceToNow(fromUnixTime(lastUpdatedAt), {
                      addSuffix: true,
                    })}
                  </TruncateText>
                )}
              </Stack>
            )
          )
        }
      />
    </>
  );
};

export default Comment;
