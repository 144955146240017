import { useState } from "react";
import { styled } from "@theme/v2/Provider";
import { Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { useGetMerchantById } from "@hooks/enterprise-api/account/useGetMerchants";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useGetProfileStatus } from "@pages/Signup/useGetProfileStatus";
import {
  CaretDown,
  CaretUp,
  CheckCircle,
  WarningCircle,
  X,
} from "@phosphor-icons/react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Pills } from "./Pills";
import { setOnboardingScreen } from "@redux/slices/app";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import GiveChip from "@shared/Chip/GiveChip";
import useAccStatus from "./hooks/useAccStatus";
import GiveButton from "@shared/Button/GiveButton";

const STATUS = {
  INCOMPLETE: "INCOMPLETE",
  APPROVED: "APPROVED",
  UNDER_VERIFICATION: "UNDER_VERIFICATION",
};

type TVariant = "Incomplete" | "Verified" | "Update";

type TAccountSectionProps = {
  open: boolean;
  onClose: () => void;
};

const MerchantAccountSection = ({ open, onClose }: TAccountSectionProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { merchantId } = useGetCurrentMerchantId();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const toggleExpand = () => setIsExpanded((prev) => !prev);

  const { data: merchant, isLoading } = useGetMerchantById({
    merchantId,
    enabled: !!merchantId,
  });

  const {
    pahStatus,
    businessDetailsStatus,
    bankAccountStatus,
    agreementStatus,
    allLoaded,
  } = useGetProfileStatus(merchant);

  const { handleCheckUpdate } = useAccStatus({ merchant });
  if (merchant?.profileCompletedReadAt !== null || isLoading || !allLoaded)
    return null;

  const isReviewUpdate = false;
  const statuses = [
    pahStatus,
    businessDetailsStatus,
    bankAccountStatus,
    agreementStatus,
  ];

  const progress = statuses.filter(
    (status) => status === STATUS.APPROVED,
  ).length;

  const variant: TVariant = isReviewUpdate
    ? "Update"
    : merchant?.statusName === "approved"
    ? "Verified"
    : "Incomplete";

  const isSuccess = variant === "Verified";

  const config = getConfig(variant);

  const handleCardClick = () => {
    if (isReviewUpdate && !isExpanded) {
      toggleExpand();
    } else if (location.pathname !== "/merchant/welcome") {
      navigate("/merchant/welcome#onboarding");
    } else {
      dispatch(setOnboardingScreen({ screen: "profile" }));
    }
  };

  return (
    <Stack onClick={handleCardClick} padding="0 4px">
      {open ? (
        <Card>
          {/* header */}
          <Stack direction="row" justifyContent="space-between">
            <GiveChip
              variant="light"
              label={config.title}
              color={isSuccess ? "success" : "warning"}
            />
            <GiveIconButton
              size="extraSmall"
              variant="ghost"
              onClick={isSuccess ? onClose : toggleExpand}
              Icon={isSuccess ? X : isExpanded ? CaretDown : CaretUp}
            />
          </Stack>

          {/* description */}
          {(isExpanded || isSuccess) && (
            <Text variant="bodyXS" color="secondary">
              {config.description}
            </Text>
          )}

          {/* read agreement btn */}
          {isExpanded && isReviewUpdate && (
            <GiveButton
              size="small"
              label="Read Agreement"
              onClick={handleCheckUpdate}
            />
          )}

          {/* progress */}
          {!isReviewUpdate && (
            <Stack direction="row" alignItems="center" spacing="16px">
              <Pills
                statuses={{
                  pah: pahStatus === STATUS.APPROVED,
                  business_details: businessDetailsStatus === STATUS.APPROVED,
                  bank_account: bankAccountStatus === STATUS.APPROVED,
                  merchant_agreement: agreementStatus === STATUS.APPROVED,
                }}
              />
              <Text color="secondary" variant="bodyXS">
                {progress}/4
              </Text>
            </Stack>
          )}
        </Card>
      ) : (
        <IconButton
          Icon={isSuccess ? CheckCircle : WarningCircle}
          isSuccess={isSuccess}
        />
      )}
    </Stack>
  );
};

export default MerchantAccountSection;

const Card = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.border?.primary}`,
  borderRadius: "8px",
  padding: "12px",
  gap: "12px",

  "&:hover": {
    border: `1px solid ${theme.palette.border?.secondary}`,
    background: theme.palette.primitive?.transparent["darken-2"],
  },
}));

const getConfig = (variant: TVariant) => {
  return variant === "Verified"
    ? {
        title: "Account Verified",
        description: "Your identity has been successfully verified!",
      }
    : variant === "Incomplete"
    ? {
        title: "Account Incomplete",
        description: "Provide required information to complete your profile",
      }
    : {
        title: "Review Update",
        description: `Updates have been made to the merchant agreement. 
                      Please read through the revised terms for the latest information`,
      };
};

const Text = styled(GiveText)({
  whiteSpace: "normal",
});

const IconButton = styled(GiveIconButton)<{ isSuccess: boolean }>(
  ({ theme, isSuccess }) => ({
    height: 36,
    width: 36,
    borderRadius: "50%",
    color: isSuccess
      ? theme.palette.primitive?.success[50]
      : theme.palette.primitive?.warning[50],
    backgroundColor: isSuccess
      ? theme.palette.primitive?.success[25]
      : theme.palette.primitive?.warning[10],

    "&:hover": {
      color: isSuccess
        ? theme.palette.primitive?.success[25]
        : theme.palette.primitive?.warning[25],
      backgroundColor: isSuccess
        ? theme.palette.primitive?.success[50]
        : theme.palette.primitive?.warning[50],
    },
  }),
);
