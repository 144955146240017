import { useAppSelector } from "@redux/hooks";
import {
  selectConversationTopic,
  selectConversationsFilter,
  selectConversationsGeneralSearch,
} from "@redux/slices/conversations";
import { customInstance } from "@services/api";
import { useQuery } from "react-query";
import { useConversationsModal } from "./useConversationsModal";
import { Tabs_Types } from "../types";

export type TConversationTopic = {
  typeName?: string;
  createdAt: number;
  displayName: string;
  id: number;
  isGlobal: boolean;
  lastActivity: null | number; // Assuming lastActivity can be either null or a number
  name: string;
  subjectAccID: number;
  totalMessages: number;
  totalMentions: number;
  totalUnread: number;
  totalUsers: number;
  updatedAt: number;
  userAccID: number;
  userAvatars: string[];
  hasMentions: boolean;
  subject?: string;
};

const getTopics = ({
  merchantId,
  queryString,
  filterString,
  tabs,
  isConversationOpen,
}: {
  merchantId: number;
  queryString: string;
  filterString: string;
  tabs: Tabs_Types;
  isConversationOpen: boolean;
}) => {
  let builder = `/merchants/${merchantId}/topics`;
  let hasQueryParams = false;

  if (queryString) {
    builder += `?q="${queryString}"`;
    hasQueryParams = true;
  }
  if (filterString) {
    builder += `${hasQueryParams ? "&" : "?"}filter=totalMentions:>0`;
    hasQueryParams = true;
  }
  if (tabs && isConversationOpen) {
    builder += `${
      hasQueryParams ? "&" : "?"
    }filter=typeDisplayName:"${tabs}"&sort=-id,-userAccID`;
  } else {
    builder += `?sort=-id,-userAccID`;
  }

  return customInstance({
    url: builder,
    method: "GET",
  });
};

export const useListTopics = ({
  merchantId,
  enabled = true,
}: {
  merchantId: number;
  enabled?: boolean;
}) => {
  const { isOpen, queryObject } = useAppSelector(selectConversationTopic);
  const { queryKey, queryString } = useAppSelector(
    selectConversationsGeneralSearch,
  );
  const { isOpen: isConversationOpen } = useConversationsModal();

  const { filterString } = useAppSelector(selectConversationsFilter);
  const { tabs = "Internal" } = queryObject || {};
  const { data, isLoading, isFetching, error } = useQuery(
    [
      "get-conversation-topics",
      merchantId,
      isOpen,
      queryKey === "Topics" && queryString,
      filterString,
      tabs,
      isConversationOpen,
    ],
    async () => {
      const data = await getTopics({
        tabs,
        merchantId,
        queryString,
        filterString,
        isConversationOpen,
      });

      return {
        ...data,
        data: data?.data,
      };
    },
    { enabled: typeof merchantId === "number" && enabled },
  );

  return {
    data,
    isLoading,
    isFetching,
    error,
  };
};
