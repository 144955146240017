import { CAMPAIGN_PANEL_WIDTH } from "@common/CampaignCard/CampaignDetailsModal/useCampaignModal";
import { Box, Skeleton, Stack, SxProps, Theme, styled } from "@mui/material";
import Header from "./components/Header";
import useManagePepData from "./hooks/useManagePepData";
import PepCard from "./components/PepCard";
import PepDetailedCard from "./components/PepDetailedCard";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import { THistoryCheck, TPEPOwner } from "./types";
import {
  WithAccessControl,
  composePermission,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";

interface Props {
  onClose?: () => void;
  onBackPress?: () => void;
  selectedOwner: TPEPOwner;
  merchantId: number;
  legalEntityID: number;
  isAcquirer?: boolean;
  containerSx?: SxProps<Theme>;
  isEnterprise?: boolean;
}

function PoliticallyExposed({
  onClose,
  selectedOwner,
  merchantId,
  legalEntityID,
  containerSx = {},
  isEnterprise,
  onBackPress,
}: Props) {
  const {
    data,
    isLoading,
    handleClickCheck,
    selectedCheck,
    removeSelectedPEP,
    checkPEP,
    isRunningCheck,
  } = useManagePepData({
    selectedOwner,
    merchantId: merchantId,
    legalEntityID: legalEntityID,
  });

  const latestStatus = data
    ? data[0]?.manualPepStatus || data[0]?.status
    : selectedOwner?.pepStatusName;

  return (
    <Container
      sx={{
        ...containerSx,
        "&:hover": {
          "& .MuiButtonBase-root.MuiButton-root": {
            visibility: "visible",
          },
        },
      }}
    >
      <Header
        breadCrumbDate={selectedCheck?.date}
        isLoading={isLoading}
        removeSelectedPEP={removeSelectedPEP}
        onClose={onClose}
        checkPEP={checkPEP}
        isRunningCheck={isRunningCheck}
        status={latestStatus}
        goBack={onBackPress}
      />
      {selectedCheck ? (
        <ErrorCatcher errorID="pepDetailed">
          <PepDetailedCard
            check={selectedCheck}
            selectedOwner={selectedOwner}
            merchantId={merchantId}
            legalEntityID={legalEntityID}
            latestStatus={latestStatus}
          />
        </ErrorCatcher>
      ) : (
        <WithAccessControl
          resource={composePermission(
            isEnterprise ? RESOURCE_BASE.ENTERPRISE : RESOURCE_BASE.MERCHANT,
            RESOURCE_BASE.LEGAL_ENTITY,
            RESOURCE_BASE.PRINCIPAL,
            RESOURCE_BASE.PEP,
          )}
          operation={OPERATIONS.LIST}
        >
          {isLoading ? (
            <Skeletons />
          ) : (
            <ErrorCatcher errorID="pepList">
              <Box mt="13px">
                {data?.map((check: THistoryCheck, idx: number) => (
                  <FadeUpWrapper
                    key={idx + check?.checkID}
                    delay={150 + idx * 50}
                  >
                    <PepCard check={check} onClick={handleClickCheck} />
                  </FadeUpWrapper>
                ))}
              </Box>
            </ErrorCatcher>
          )}
        </WithAccessControl>
      )}
    </Container>
  );
}

export default PoliticallyExposed;

const Skeletons = () => {
  return (
    <>
      {Array(10)
        .fill(null)
        .map((i, idx) => (
          <Skeleton
            key={idx}
            data-testid="PEP_LOADING_SKELETON"
            sx={{
              borderRadius: "4px",
              marginBottom: "8px",
            }}
            width="100%"
            variant="rounded"
            height="38px"
          />
        ))}
    </>
  );
};

const Container = styled(Stack)(() => ({
  width: CAMPAIGN_PANEL_WIDTH - 30 + "px",
  height: "100%",
  borderRadius: "16px",
  gap: 2,
  padding: "8px 16px",
  boxShadow: "0px 4px 15px 0px #0000000D",
  overflowX: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  msOverflowStyle: "none",
  scrollbarWidth: "none",
}));
