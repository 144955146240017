import SectionCardBase from "@shared/SidePanel/components/SectionCard/SectionCardBase";
import { useMerchantSidePanelContext } from "features/Merchants/MerchantSidePanel/Provider/MerchantSidePanelProvider";

import { RenderTextsComponent } from "../BaseCardComponent";
import { IMerchantBankAccount } from "@components/Merchants/MerchantPreview/data.types";
import { isEmpty } from "lodash";
import useSnapShot from "features/Merchants/MerchantSidePanel/hooks/useSnapShot";

function BankAccount() {
  const { data } = useMerchantSidePanelContext();
  const { bankAccounts } = useSnapShot();
  const bankAccountList = data?.bankAccountList || [];
  if (isEmpty(bankAccountList)) return <></>;
  return (
    <>
      <SectionCardBase sx={{ marginTop: "40px" }} leftTitle="Bank Account" />
      {bankAccounts.map((bank, index) => (
        <RenderBankDetails key={index} bank={bank} />
      ))}
    </>
  );
}

export default BankAccount;

const RenderBankDetails = ({
  bank,
}: {
  bank: {
    label: string;
    value: string | number;
  }[];
}) => {
  return (
    <SectionCardBase>
      {bank.map((detail) => (
        <RenderTextsComponent
          key={`${detail.label}-${detail.value}`}
          {...detail}
        />
      ))}
    </SectionCardBase>
  );
};
