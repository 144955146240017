import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import PlaidAccountInfo from "./PlaidAccountInfo";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { showMessage } from "@common/Toast";

const PlaidAccountInfoModal = NiceModal.create(({ data }: { data: string }) => {
  const modal = useModal();
  const { isMobileView } = useCustomTheme();
  const handleCancel = () => modal.hide();

  const dataObj = parseJSON(data);
  if (!dataObj || !dataObj?.request_id) return null;
  const request_id = dataObj?.request_id;

  return (
    <ModalFactory
      variant="dialog"
      modalProps={{
        open: modal.visible,
        onClose: handleCancel,
        width: 600,
      }}
    >
      <ModalTitle
        title="Plaid Account Information"
        description={`Request ID: ${request_id}`}
        padding={isMobileView ? "0 4px" : "24px 24px 0"}
        onClose={handleCancel}
        textStyles={{
          title: {
            fontSize: 18,
          },
          description: {
            fontWeight: 350,
          },
        }}
      />
      <PlaidAccountInfo data={dataObj} />
      <ModalActions
        animationDelay={100}
        padding={isMobileView ? "16px 4px" : "20px 24px 16px"}
        secondaryAction={{
          sx: { display: "none" },
        }}
        primaryAction={{
          type: "button",
          label: "Close",
          onClick: handleCancel,
          background: "primary",
          isLoading: false,
          sx: { fontSize: 18, padding: "12px 24px" },
        }}
      />
    </ModalFactory>
  );
});
export default PlaidAccountInfoModal;

const parseJSON = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    console.error("authPlaidInfo error:", error);
    showMessage("Error", "Something went wrong");
    return null;
  }
};
