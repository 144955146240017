import React, { memo, useState } from "react";
import { styled } from "@theme/v2/Provider";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import GiveSideMenu from "@shared/SideMenu/GiveSideMenu";
import MobileNavBar from "./components/MobileNavBar";
import SideMenu from "./SideMenu";
import { ArrowLineLeft, ArrowLineRight, X } from "@phosphor-icons/react";
import { Stack } from "@mui/material";
import SideMenuItem from "./components/SideMenuItem";
import { useAvailableBalance } from "./hooks/useAvailableBalance";

const WIDTH = 264;
const WIDTH_CLOSED = 116;
const WIDTH_MOBILE = 320;
const WIDTH_MOBILE_CLOSED = 92;

function SideMenuDrawer() {
  const { isMobileView, isDesktopView } = useCustomThemeV2();
  const { availableBalance } = useAvailableBalance();

  const [open, setOpen] = useState(false);

  const toggleDrawer = () => setOpen((open) => !open);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const Icon = open ? (isDesktopView ? ArrowLineLeft : X) : ArrowLineRight;
  return (
    <>
      {isMobileView && (
        <MobileNavBar
          toggleDrawer={toggleDrawer}
          availableBalance={availableBalance}
        />
      )}
      <Drawer open={open} onClose={handleDrawerClose}>
        <Stack flex={1} spacing="20px" padding={open ? 0 : "4px"}>
          <SideMenuItem
            value=""
            label={isDesktopView ? "Collapse" : "Close"}
            open={open}
            onClick={toggleDrawer}
            Icon={<Icon size={20} />}
          />
          <SideMenu
            open={open}
            availableBalance={availableBalance}
            handleDrawerClose={handleDrawerClose}
          />
        </Stack>
      </Drawer>
    </>
  );
}

export default memo(SideMenuDrawer);

const Drawer = styled(GiveSideMenu)(({ open, theme }) => ({
  flexShrink: 0,
  overflowX: "hidden",
  whiteSpace: "nowrap",

  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.standard,
  }),
  "& .MuiDrawer-paper": {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
    [theme.breakpoints.between("v2_sm", "v2_md")]: { padding: "20px" },
  },

  // desktop
  [theme.breakpoints.up("v2_md")]: {
    boxSizing: "border-box",
    ...(open ? widthStyle(WIDTH) : widthStyle(WIDTH_CLOSED)),
  },
  // tablet
  [theme.breakpoints.between("v2_sm", "v2_md")]: {
    ...(open
      ? widthStyle(WIDTH_MOBILE_CLOSED, WIDTH_MOBILE)
      : widthStyle(WIDTH_MOBILE_CLOSED)),
  },
  //mobile
  [theme.breakpoints.down("v2_sm")]: {
    width: WIDTH_MOBILE,
    "& .MuiDrawer-paper": {
      width: `${WIDTH_MOBILE}px !important`,
    },
  },
}));

const widthStyle = (width: number, paperWidth?: number) => ({
  width: width,
  "& .MuiDrawer-paper": {
    width: paperWidth ? paperWidth : width,
  },
});
