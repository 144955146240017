import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import NiceModal from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import GiveButton from "@shared/Button/GiveButton";
import { HFGiveInput } from "@shared/HFInputs/HFGiveInput/HFGiveInput";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { EDDModalCheckbox } from "./components/EDDModalCheckbox";

const NOTIFY_MERCHANT_TOOLTIP_TEXT =
  "The merchant will receive a notification containing instructions on how to complete this challenge";
const INTERNAL_NOTE_TOOLTIP_TEXT =
  "This note will be shared in internal discussions with other acquirer users";

type InputType = {
  name: string;
  description: string;
  notifyMerchant: boolean;
  internalNote: boolean;
};

const schema = Yup.object().shape({
  name: Yup.string()
    .required("Challenge name is required")
    .min(1, "Challenge name cannot be empty"),
  description: Yup.string()
    .required("How to complete this challenge is required")
    .min(1, "How to complete this challenge cannot be empty"),
});

type EDDChallengeModalProps = {
  type: "create" | "edit";
  challengeName?: string;
  challengeDescription?: string;
};

const TypeMap = {
  create: {
    title: "Add Challenge",
    buttonLabel: "Create",
  },
  edit: {
    title: "Edit Challenge",
    buttonLabel: "Save",
  },
};

const EDDChallengeModal = NiceModal.create(
  ({
    type = "create",
    challengeName,
    challengeDescription,
  }: EDDChallengeModalProps) => {
    const { open, onClose } = useNiceModal();

    const methods = useForm<InputType>({
      mode: "onSubmit",
      reValidateMode: "onSubmit",
      defaultValues: {
        name: type === "edit" ? challengeName : "",
        description: type === "edit" ? challengeDescription : "",
        notifyMerchant: false,
        internalNote: false,
      },
      resolver: yupResolver(schema),
    });

    const handleClose = () => {
      methods.reset();
      onClose();
    };

    const handleSubmit = (data: InputType) => {
      //TODO: Implement create/edit challenge logic
    };

    return (
      <FormProvider {...methods}>
        <GiveBaseModal
          open={open}
          title={TypeMap[type].title}
          width="560px"
          height="67%"
          onClose={handleClose}
          buttons={
            <Stack gap="12px" flexDirection="row">
              <GiveButton
                onClick={handleClose}
                label="Cancel"
                variant="ghost"
                size="large"
              />
              <GiveButton
                onClick={methods.handleSubmit(handleSubmit)}
                label={TypeMap[type].buttonLabel}
                variant="filled"
                size="large"
                sx={{
                  border: "none",
                }}
              />
            </Stack>
          }
        >
          <Stack gap="24px">
            <HFGiveInput label="Challenge Name" name="name" maxLength={50} />
            <HFGiveInput
              label="How to complete this challenge?"
              name="description"
              placeholder="Provide clear and concise directions to complete this challenge"
              multiline
              rows={8}
            />
            {type === "create" && (
              <Stack gap="12px">
                <EDDModalCheckbox
                  name="notifyMerchant"
                  label="Notify Merchant"
                  tooltipText={NOTIFY_MERCHANT_TOOLTIP_TEXT}
                />
                <EDDModalCheckbox
                  name="internalNote"
                  label="Internal Note"
                  tooltipText={INTERNAL_NOTE_TOOLTIP_TEXT}
                />
              </Stack>
            )}
          </Stack>
        </GiveBaseModal>
      </FormProvider>
    );
  },
);

export default EDDChallengeModal;
