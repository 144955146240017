import React, { useEffect, useMemo, useState } from "react";
import { RHFInput } from "@common/Input";
import { useFormContext } from "react-hook-form";
import { Stack } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { sleep } from "@utils/index";
import { isEmpty } from "lodash";
import OwnershipTab from "./OwnershipTab";

type OwnershipInputProps = {
  name: string;
  label: string;
  placeholder: string;
  disabled?: boolean;
  labelProps?: any;
  isFullyDisabled?: boolean;
  disableWithoutReset?: boolean;
  leType?: string;
};

const OwnershipTabsInput = ({
  name,
  label,
  placeholder,
  disabled,
  labelProps,
  isFullyDisabled,
  disableWithoutReset,
  leType,
}: OwnershipInputProps) => {
  const { setValue, watch, formState } = useFormContext();
  const [tabs, setTabs] = useState(getInitialTabs(leType));

  const normalizeInput = (value: string) => {
    if (isEmpty(value)) return value;
    const currentValue = value?.replace(/[^\d.]/g, "");
    if (parseFloat(currentValue) > 100) return "100";

    return currentValue;
  };

  useEffect(() => {
    if (isFullyDisabled && !disableWithoutReset) {
      setValue(name, "100");
      handleSelectTab({
        value: "100",
      });
    }
  }, [isFullyDisabled]);

  const handleBlur = () => {
    const value = normalizeInput(watch(name));
    if (
      leType &&
      ["tax_exempt_organization", "government_agency"].includes(leType)
    )
      return;
    if (parseFloat(value) < 10) setValue(name, "10");
  };

  const selectTab = (value: string) => {
    const updatedTabs = tabs.map((tab) => {
      return {
        ...tab,
        selected: tab.value === value,
      };
    });
    setTabs(updatedTabs);
  };

  const handleSelectTab = async ({
    value,
    custom,
    shouldDirty = true,
  }: {
    value: string;
    custom?: string;
    shouldDirty?: boolean;
  }) => {
    const val =
      formState && formState.defaultValues
        ? formState?.defaultValues[name]
        : normalizeInput(watch(name));

    selectTab(value);
    if (value === "Custom") {
      await sleep(50);
      setValue(name, custom || val || "33", {
        shouldValidate: true,
        shouldDirty,
      });
    } else {
      setValue(name, value, { shouldValidate: true, shouldDirty });
    }
  };

  const getTooltip = (tab: string) => {
    if (leType === "individual_sole_proprietorship") {
      return "A sole proprietor owns 100% of the business";
    }
    if (
      leType &&
      tab === "0" &&
      ["tax_exempt_organization", "government_agency"].includes(leType)
    ) {
      return "Tax-exempt organizations and government agencies do not possess ownership percentages";
    }
    return "";
  };

  React.useEffect(() => {
    setValue(name, normalizeInput(watch(name)));
  }, [watch(name)]);

  const setInitialValue = (value: string, shouldDirty = false) => {
    switch (value.toString()) {
      case "0":
      case "10":
      case "25":
      case "100":
        handleSelectTab({
          value: value.toString(),
          shouldDirty,
        });
        break;
      default:
        handleSelectTab({
          value: "Custom",
          custom: value,
          shouldDirty,
        });
    }
  };

  React.useEffect(() => {
    const value = watch(name);
    if (leType) {
      switch (leType) {
        case "individual_sole_proprietorship":
          handleSelectTab({
            value: "100",
          });
          break;
        case "tax_exempt_organization":
        case "government_agency":
          handleSelectTab({
            value: "0",
          });
          break;
        default:
          if (value) setInitialValue(value);
          else setValue(name, "10");
      }
    }
  }, []);

  return (
    <Stack spacing={1}>
      <Text fontWeight="book" color={palette.neutral[80]} {...labelProps}>
        Ownership
      </Text>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          overflowX: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {tabs.map(({ value, flex, selected }) => (
          <OwnershipTab
            key={value}
            selected={selected}
            disabled={isFullyDisabled}
            onClick={() => handleSelectTab({ value })}
            flex={flex}
            value={value}
            tooltip={getTooltip(value)}
            dataTestId={`ownership-${value}`}
          >
            {value}
            {value !== "Custom" ? "%" : null}
          </OwnershipTab>
        ))}
      </Stack>
      {tabs?.slice(-1)?.[0]?.selected &&
        leType !== "individual_sole_proprietorship" && (
          <RHFInput
            label={label}
            name={name}
            placeholder={placeholder}
            onBlur={handleBlur}
            fullWidth
            endIcon="%"
            size="medium"
            disabled={disabled || isFullyDisabled}
          />
        )}
    </Stack>
  );
};

const getInitialTabs = (leType?: string) => {
  if (
    leType &&
    ["tax_exempt_organization", "government_agency"].includes(leType)
  ) {
    return [
      {
        flex: 1,
        value: "0",
        selected: true,
      },
      ...initTabs,
    ];
  }

  return initTabs;
};

const initTabs = [
  { value: "10", selected: true },
  { value: "25", selected: false, flex: 2 },
  { value: "100", selected: false, flex: 3 },
  { value: "Custom", selected: false },
];

export default OwnershipTabsInput;
