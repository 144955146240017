import React from "react";
import { useMerchantSidePanelContext } from "../Provider/MerchantSidePanelProvider";
import SidePanelTitle from "@shared/SidePanel/components/SidepanelTitle/SidePanelTitle";
import MerchantInformationSection from "./MerchantInformationSection";
import SponsorApprovalSection from "./SponsorApprovalSection/SponsorApprovalSection";
import AccountStatusOverviewSection from "./AccountStatusOverviewSection/AccountStatusOverviewSection";
import UnderwriterRiskAnalystAssignCard from "./UnderwriterRiskAnalystAssignCard/UnderwriterRiskAnalystAssignCard";
import RiskSection from "./GiveMerchantSections";
import UnderwritingCard from "features/Underwriting/UnderwritingCard/UnderwritingCard";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { useComponentVisibilityOnStatus } from "@components/Merchants/MerchantPreview/sections/StatusReview/hooks/useComponentVisibilityOnStatus";

function SidePanelBody() {
  const { data, isSponsorView } = useMerchantSidePanelContext();
  const enterpriseImageUrl = data?.merchantInfo?.enterpriseImageUrl;

  const { isViewUnderwritingAllowed } = useUnderwriterPermissions();
  const { isUnderwritingReportVisible } = useComponentVisibilityOnStatus({
    data: data.status,
    isRebranding: true,
  });
  const { merchant_underwriting } = useEnterprisePermissions();
  const hideUnderwritingCard =
    !isViewUnderwritingAllowed ||
    !merchant_underwriting ||
    !isUnderwritingReportVisible;

  const merchantsSidePanelComponents = [
    {
      component: (
        <SidePanelTitle
          panelType="merchantSidePanel"
          title={data?.merchantInfo?.merchantName}
          imageUrl={
            enterpriseImageUrl ? enterpriseImageUrl + "/thumb" : undefined
          }
        />
      ),
      hide: false,
    },
    {
      component: <MerchantInformationSection />,
      hide: isSponsorView,
    },
    {
      component: <SponsorApprovalSection />,
      hide: !isSponsorView,
    },
    {
      component: <UnderwritingCard />,
      hide:
        data?.status?.statusName !== "preapproved_no_tx" ||
        hideUnderwritingCard, //Todo - Include logic to only show summary card in portfolio view only
    },
    {
      component: <RiskSection />,
      hide: !["approved", "suspended"].includes(data?.status?.statusName),
    },
    {
      component: <AccountStatusOverviewSection />,
      hide: !["approved", "suspended", "declined"].includes(
        data?.status?.statusName,
      ),
    },
    {
      component: (
        <UnderwriterRiskAnalystAssignCard
          type={
            ["approved", "suspended"].includes(data?.status?.statusName)
              ? "Risk Analyst"
              : "Underwriter"
          }
        />
      ),
      hide: false,
    },
  ];
  return (
    <>
      {merchantsSidePanelComponents?.map(({ component, hide }) => {
        if (hide) return null;
        return component;
      })}
    </>
  );
}

export default SidePanelBody;
