import NiceModal from "@ebay/nice-modal-react";
import { palette } from "@palette";
import ExportViewHeader from "./components/ExportViewHeader";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { useExportTable } from "./hooks/useExportTable";
import { FormProvider } from "react-hook-form";
import { ModalContent, PanelContent } from "./components/styles";
import ExportTypeSection from "./components/ExportTypeSection";
import ExportColumnsSection from "./components/ExportColumnsSection";
import { ModalActions } from "@common/Modal/ModalFactory/atoms";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import AdvancedSettings, {
  TAdvancedSettingsData,
} from "./components/AdvancedSettings";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import {
  MERCHANT_COLUMNS,
  MERCHANT_COLUMN_TRANSLATION,
} from "./columns/merchant";
import {
  RISK_MONITOR_TRANSACTIONS,
  RISK_MONITOR_TRANSACTIONS_TRANSLATIONS,
} from "./columns/riskMonitorTransactions";
import { useAppSelector } from "@redux/hooks";
import { getSelectedFiltersAmount } from "@redux/slices/tableFilters";
import { selectQueryString } from "@redux/slices/search";
import { QKEY_LIST_ACQUIRER_MERCHANTS } from "@constants/queryKeys";
import { isArray } from "lodash";
import ExportDateRangeSection from "./components/ExportDateRangeSection";
import { Colum_types } from "./types";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import {
  SETTLEMENT_COLUMNS,
  SETTLEMENT_TRANSLATIONS,
} from "./columns/settlement";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { ensureArray } from "./utils";
import FadeDownWrapper from "@components/animation/FadeDownWrapper";
import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";

type Props = {
  totalRows: number;
  filteredRows?: number;
  tab: "underwriting" | "portfolio" | "risk" | "default";
  EXPORT_FORM_NAME?: string;
  column_type?: Colum_types;
  merchantId?: number;
  sortReduxKey?: string;
  searchKey?: string;
  formattedFilterParams?: string;
};

const columnData = {
  merchant: MERCHANT_COLUMNS,
  risk_monitor_transactions: RISK_MONITOR_TRANSACTIONS,
  settlement: SETTLEMENT_COLUMNS,
};

const translationData = {
  merchant: MERCHANT_COLUMN_TRANSLATION,
  risk_monitor_transactions: RISK_MONITOR_TRANSACTIONS_TRANSLATIONS,
  settlement: SETTLEMENT_TRANSLATIONS,
};

const ExportViewPanel = NiceModal.create(
  ({
    totalRows,
    filteredRows = 0,
    tab,
    EXPORT_FORM_NAME,
    column_type = "merchant",
    merchantId,
    sortReduxKey,
    searchKey,
    formattedFilterParams,
  }: Props) => {
    const { isMonthlyReportEnabled } = useGetFeatureFlagValues();
    const { onClose } = useNiceModal();
    const { isMobileView } = useCustomTheme();
    const filtersAmount = useAppSelector(getSelectedFiltersAmount);
    const searchQuery = useAppSelector((state) =>
      selectQueryString(state, searchKey || QKEY_LIST_ACQUIRER_MERCHANTS),
    );
    const isFilter = Boolean(
      filtersAmount || searchQuery || formattedFilterParams,
    );
    const columns = columnData?.[column_type];
    const translations = translationData?.[column_type] || {};

    const {
      titles: sectionTitles,
      visible,
      monthly,
      ofacBusiness,
      ofacPersons,
      ...checkboxSections
    } = columns;

    const visibleColumns = isArray(visible) ? visible : visible?.[tab] || [];
    // const monthlyColumns = ensureArray(monthly);
    const ofacBusinessColumns = ensureArray(ofacBusiness);
    const ofacPersonsColumns = ensureArray(ofacPersons);

    //montlyReport, ofacBusiness and ofacPersons is avalaible for all acquirer except member role
    const isExportAllowed: boolean = useAccessControl({
      resource: RESOURCE_BASE.MERCHANT,
      operation: OPERATIONS.EXPORT,
    });

    const monthlyColumns = isArray(monthly) ? monthly : [];

    const { isMasqueradeMode } = useMasqueradeReducer();
    const showOfacReport = isExportAllowed && !isMasqueradeMode;
    const showMonthlyReport = isMonthlyReportEnabled && showOfacReport;

    const {
      methods,
      onSubmit,
      isLoading,
      initialTimezone,
      showDateRangeSection,
    } = useExportTable({
      isFilter,
      allColumns: checkboxSections,
      visibleColumns,
      monthlyColumns,
      EXPORT_FORM_NAME,
      closeModal: onClose,
      column_type,
      merchantId,
      sortReduxKey,
      searchKey,
      formattedFilterParams,
      showMonthlyReport,
      ofacBusinessColumns,
    });

    const advancedSettingsData: TAdvancedSettingsData = {
      columns: checkboxSections,
      translations,
      sectionTitles,
      visibleColumns,
      monthlyColumns,
      showMonthlyReport,
      ofacBusinessColumns,
      ofacPersonsColumns,
      initialTimezone,
      column_type,
    };

    const {
      formState: { isValid },
    } = methods;
    const actions = {
      primaryAction: {
        label: isLoading ? "Exporting" : "Export",
        disabled: isLoading || !isValid,
        isLoading,
        sx: {
          fontSize: "18px",
          lineHeight: isMobileView ? "21.6px" : "18px",
          padding: "12px 24px",
        },
      },
      secondaryAction: {
        label: "Cancel",
        onClick: onClose,
        disabled: isLoading,
        sx: {
          fontSize: "18px",
          lineHeight: isMobileView ? "21.6px" : "18px",
          padding: "12px 24px",
        },
      },
    };

    return (
      <ModalWrapper isLoading={isLoading}>
        <FormProvider {...methods}>
          <PanelContent
            component="form"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <ExportViewHeader handleClose={onClose} />

            <ModalContent
              gap={isMobileView ? "16px" : "24px"}
              onTouchMove={(e) => e.stopPropagation()}
            >
              <ExportTypeSection
                totalRows={totalRows}
                filteredRows={filteredRows}
                isFilter={isFilter}
                column_type={column_type}
              />
              <ExportColumnsSection
                column_type={column_type}
                showMonthlyReport={showMonthlyReport}
                showOfacReport={showOfacReport}
                initialColumns={advancedSettingsData.columns}
                visibleColumns={visibleColumns}
                monthlyColumns={monthlyColumns}
                ofacBusinessColumns={ofacBusinessColumns}
                ofacPersonsColumns={ofacPersonsColumns}
                initialTimezone={initialTimezone}
                EXPORT_FORM_NAME={EXPORT_FORM_NAME}
              />
              {showMonthlyReport &&
                showDateRangeSection &&
                column_type !== "risk_monitor_transactions" && (
                  <FadeDownWrapper delay={400}>
                    <ExportDateRangeSection
                      column_type={column_type}
                      initialTimezone={initialTimezone}
                    />
                  </FadeDownWrapper>
                )}
              <AdvancedSettings {...advancedSettingsData} />
            </ModalContent>

            <ModalActions
              fullWidth={isMobileView}
              animationDelay={250}
              {...actions}
            />
          </PanelContent>
        </FormProvider>
      </ModalWrapper>
    );
  },
);

export default ExportViewPanel;

const ModalWrapper = ({
  isLoading,
  children,
}: {
  isLoading: boolean;
  children: React.ReactNode;
}) => {
  const { open, onClose, TransitionProps, SlideProps } = useNiceModal();
  return (
    <ModalFactory
      variant="sidepanel"
      modalProps={{
        open,
        onClose,
        width: "446px",
        DrawerProps: {
          PaperProps: {
            sx: {
              padding: "24px 16px 16px",
              borderRadius: "24px 24px 0 0",
            },
            ...(!isLoading && {
              TransitionProps,
            }),
          },
        },
        SidePanelProps: {
          PaperProps: {
            sx: {
              padding: "16px",
              background: `${palette.neutral[5]} !important`,
            },
            ...(!isLoading && {
              SlideProps,
            }),
          },
          slotProps: {
            backdrop: {
              sx: {
                background: "rgba(53, 53, 53, 0.15)",
                backdropFilter: "blur(3px)",
              },
            },
          },
        },
      }}
    >
      {children}
    </ModalFactory>
  );
};
