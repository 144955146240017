import React from "react";
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { buttonTheme } from "./newButtonTheme";

export type BtnBGTypes =
  | "primary"
  | "secondary"
  | "tertiary"
  | "text"
  | "red"
  | "success"
  | "outlined";

export type RebrandedButtonProps = MuiButtonProps & {
  children?: React.ReactNode;
  background?: BtnBGTypes;
  size?: "small" | "medium" | "large";
};

export const StyledButton_V2 = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== "size" && prop !== "background",
})(
  ({
    background = "primary",
    size = "large",
  }: {
    background?: BtnBGTypes;
    size?: "small" | "medium" | "large";
  }) => ({
    borderRadius: "90px",
    boxShadow: "none",
    backgroundColor: buttonTheme.colors[background].background,
    borderColor: buttonTheme.colors[background].borderColor,
    borderWidth: buttonTheme.colors[background].borderWeight,
    fontSize: buttonTheme.size[size].fontSize,
    height: buttonTheme.size[size].height,
    paddingTop: buttonTheme.size[size].vertical,
    paddingBottom: buttonTheme.size[size].vertical,
    paddingLeft: buttonTheme.size[size].horizontal,
    paddingRight: buttonTheme.size[size].horizontal,
    color: buttonTheme.colors[background].text,
    "&:disabled": {
      backgroundColor:
        buttonTheme.colors[background].disabled.background ?? "transparent",
      color: buttonTheme.colors[background].disabled.color,
      borderColor: buttonTheme.colors[background].disabled.borderColor,
      boxShadow: "none",
    },
    "&:hover": {
      backgroundColor: buttonTheme.colors[background].hover.background,
      textDecoration: buttonTheme.colors[background].active.decoration,
      boxShadow: "none",
    },
    "&:active": {
      backgroundColor: buttonTheme.colors[background].active.background,
      textDecoration: buttonTheme.colors[background].active.decoration,
    },
  }),
);
