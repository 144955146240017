import { useFormContext } from "react-hook-form";
import { ColumnExport, TExportTable } from "../types";
import { useCallback } from "react";
import { generateFileName, getDefaultSelectedColumns } from "./useExportTable";
import { getMonthRange } from "../utils";

export type TSelectedColumns = Record<string, string[]>;
export type TBulkSelectAction = "select" | "deselect";
type TBulkSelector = (action: TBulkSelectAction, section: string) => void;
const PERSONS_TYPE_COLUMN = "Persons Type";

type Props = {
  initialColumns: TSelectedColumns;
  visibleColumns: string[];
  monthlyColumns: string[];
  ofacBusinessColumns: string[];
  ofacPersonsColumns: string[];
  initialTimezone: string;
  EXPORT_FORM_NAME?: string;
};
const useColumnSelection = ({
  initialColumns,
  visibleColumns,
  monthlyColumns,
  ofacBusinessColumns,
  ofacPersonsColumns,
  initialTimezone,
  EXPORT_FORM_NAME,
}: Props) => {
  const { watch, setValue } = useFormContext<TExportTable>();
  const selectedColumns = watch("selectedColumns");
  const columnsFilter = watch("columnsIncluded");
  const { startDate, endDate } = getMonthRange("current");
  const isOfacPersonSelected = columnsFilter === "ofac_persons";
  //set the fileName and the custom checkboxs in columns included select
  const columnsActionHandlers = {
    all: () => {
      setValue("fileName", generateFileName(EXPORT_FORM_NAME));
      let newArray: string[] = [];
      Object.values(initialColumns).forEach(
        (values) => (newArray = [...newArray, ...values]),
      );
      setValue("selectedColumns", newArray);
    },
    visible: () => {
      setValue("fileName", generateFileName(EXPORT_FORM_NAME));
      setValue("selectedColumns", visibleColumns);
    },
    processing_monthly: () => {
      setValue(
        "fileName",
        generateFileName("GIVE_MONTHLY_MERCHANT_PROCESSING_"),
      );
      setValue("selectedColumns", monthlyColumns);
      setValue("dateRange", "current_month");
      setValue("customDateRange", {
        timezone: initialTimezone,
        startDate,
        endDate,
      });
    },
    ofac_business: () => {
      setValue("fileName", generateFileName("GIVE_OFAC_BUSINESS_"));
      setValue("selectedColumns", ofacBusinessColumns);
      setValue("dateRange", "current_month");
      setValue("customDateRange", {
        timezone: initialTimezone,
        startDate,
        endDate,
      });
    },
    ofac_persons: () => {
      setValue("fileName", generateFileName("GIVE_OFAC_PERSONS_"));
      setValue("selectedColumns", ofacPersonsColumns);
      setValue("dateRange", "current_month");
      setValue("customDateRange", {
        timezone: initialTimezone,
        startDate,
        endDate,
      });
    },
    custom: () => {
      setValue("fileName", generateFileName(EXPORT_FORM_NAME));
    },
  };
  //set  columns included if custom items match
  const columnConditions: { key: ColumnExport; columns: string[] }[] = [
    { key: "visible", columns: visibleColumns },
    { key: "processing_monthly", columns: monthlyColumns },
    { key: "ofac_business", columns: ofacBusinessColumns },
    { key: "ofac_persons", columns: ofacPersonsColumns },
    { key: "all", columns: getDefaultSelectedColumns(initialColumns) },
  ];
  const handleSetColumnsIncluded = (columns: string[]) => {
    for (const condition of columnConditions) {
      if (
        columns.every((col) => condition.columns.includes(col)) &&
        columns.length === condition.columns.length
      ) {
        setValue("columnsIncluded", condition.key);
        return;
      }
    }
    setValue("columnsIncluded", "custom");
  };

  const updateColumn = (name: string, checked: boolean) => {
    const index = selectedColumns?.findIndex((el) => el === name);
    let updatedArray = selectedColumns;

    if (checked && index === -1) {
      updatedArray = [...selectedColumns, name];
    } else if (!checked && index !== -1 && selectedColumns?.length > 1) {
      updatedArray?.splice(index, 1);
    } else {
      // invalid operation
      return;
    }
    setValue("selectedColumns", updatedArray);
    handleSetColumnsIncluded(updatedArray);
  };

  const bulkSelect: TBulkSelector = useCallback(
    (action, section) => {
      const initialColumnsLength = initialColumns[section].length;
      if (action === "deselect") {
        if (selectedColumns.length > initialColumnsLength + 1) {
          initialColumns[section].forEach((name) => {
            updateColumn(name, false);
          });
        } else {
          initialColumns[section].forEach((name, index) => {
            if (index !== initialColumnsLength - 1) updateColumn(name, false);
          });
        }
        return;
      }

      const columnsSet = new Set(selectedColumns);
      initialColumns[section].forEach((name) => columnsSet.add(name));
      setValue("selectedColumns", Array.from(columnsSet));
      handleSetColumnsIncluded(Array.from(columnsSet));
    },
    [selectedColumns],
  );

  const isSectionAllActive = useCallback(
    (section: string) => {
      return initialColumns?.[section]?.every(
        (entry) => selectedColumns?.findIndex((el) => el === entry) !== -1,
      );
    },
    [selectedColumns],
  );

  const getCheckboxAttributes = (name: string) => {
    const checked = selectedColumns?.some((entry) => entry == name);

    // this was stated for a previous bug
    //we are adding the "Persons Type checkbox only when OFAC persons report is selected" to the advance select
    //bc this column right now is not accepted by BE and for ofac_persons we only sendt the reportType without the columnsIncluded

    /* 
    disbaled if selected columns contain only one value, 
    or in case of ofac, contains 2 values where 'Persons Type' is one of the two 
    */
    const disabled =
      (selectedColumns.length == 1 && selectedColumns[0] === name) ||
      (selectedColumns.length == 2 &&
        [PERSONS_TYPE_COLUMN, name].every((el) =>
          selectedColumns.includes(el),
        ));

    return { checked, disabled };
  };

  return {
    selectedColumns,
    updateColumn,
    bulkSelect,
    isSectionAllActive,
    getCheckboxAttributes,
    columnsFilter,
    hasLastCheckbox: selectedColumns?.length === 1,
    columnsActionHandlers,
    isOfacPersonSelected,
  };
};

export default useColumnSelection;
