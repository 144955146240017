import GiveSelect from "@shared/GiveInputs/GiveSelect";
import React, { useMemo, useState } from "react";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import { useListEnterpriseCategoryCodes } from "@hooks/enterprise-api/merchants/useListEnterpriseCategoryCodes";
import useUpdateMerchant from "../../hooks/useUpdateMerchant";
import GivePopup from "@shared/Popup/GivePopup";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  ACTION_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { CustomToolTip as PermissionTooltip } from "componentsV2/Table/CustomTooltip";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

const defaultState = {
  open: false,
  newId: null,
};
function MCCInput() {
  const { data } = useMerchantSidePanelContext();
  const [searchValue, setSearchValue] = useState("");
  const [{ open, newId }, setOpen] = useState<{
    open: boolean;
    newId: null | number | string;
  }>(defaultState);
  const { updateMerchantMutation } = useUpdateMerchant();
  const { mutate, isLoading } = updateMerchantMutation;
  const { enterpriseID, categoryCodeID } = data?.merchantInfo || {};
  const { categories, isLoading: isLoadingGetCategories } =
    useListEnterpriseCategoryCodes(enterpriseID);
  const { isMobileView } = useCustomThemeV2();

  const handleClose = () => {
    setOpen(defaultState);
  };
  const { categoriesOptions, fullList } = useMemo(() => {
    const fullList = categories?.map((category) => ({
      label: `${category?.code} - ${category?.title}`,
      value: category.id,
      code: category.code,
      isSelected: Number(categoryCodeID) === Number(category.id),
      checkedIconType: "Check",
    }));

    if (!searchValue) return { categoriesOptions: fullList, fullList };

    const filtered = fullList.filter(
      (option) =>
        option.label.toLowerCase().includes(searchValue.toLowerCase()) ||
        option.code.toLowerCase().includes(searchValue.toLowerCase()) ||
        option.value.toString().includes(searchValue),
    );
    return { categoriesOptions: filtered, fullList };
  }, [categories, searchValue, categoryCodeID]);
  const contextualMenuProps = {
    searchBarProps: {
      handleChange: (val: string) => setSearchValue(val),
    },
    value: searchValue,
    ...(!isMobileView
      ? {
          color: "transparent",
          texture: "blurred",
          menuWidth: 512,
          anchorOrigin: {
            vertical: -20,
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        }
      : {}),
  };

  const oldMCC = fullList?.find(
    (item) => Number(item?.value) === Number(categoryCodeID),
  );
  const newMCC = fullList?.find(
    (item) => Number(item?.value) === Number(newId),
  );

  const allowedEditMerchant = useAccessControl({
    resource: RESOURCE_BASE.MERCHANT,
    operation: OPERATIONS.UPDATE,
  });
  return (
    <PermissionTooltip
      showToolTip={!allowedEditMerchant}
      message={ACTION_DENY_MESSAGE}
      placement="top"
      alignment="end"
    >
      <GiveSelect
        label="Merchant Category Code (MCC)"
        options={categoriesOptions}
        useContextualMenu
        disabled={isLoading || isLoadingGetCategories || !allowedEditMerchant}
        value={categoryCodeID}
        contextualMenuProps={contextualMenuProps as any}
        onChange={(e) => {
          e?.target?.value &&
            setOpen({
              open: true,
              newId: e?.target?.value,
            });
        }}
      />
      <GivePopup
        type="success"
        open={open}
        actionButtons={{
          cancel: {
            label: "Cancel",
            onClick: handleClose,
          },
          success: {
            label: "Yes, Change",
            onClick: () => {
              mutate({
                categoryCodeID: Number(newId),
              });
              handleClose();
            },
          },
        }}
        onClose={handleClose}
        description={
          <>
            {oldMCC && newMCC ? (
              <>
                Are you sure you want to change the MCC code from{" "}
                <strong>{oldMCC?.label}</strong> to{" "}
                <strong>{newMCC?.label}</strong>?{" "}
              </>
            ) : (
              <>Are you sure you want to select {newMCC?.label} as your MCC </>
            )}
          </>
        }
        title="Change MCC"
      />
    </PermissionTooltip>
  );
}

export default MCCInput;
