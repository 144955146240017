type IconProps = {
  height?: number;
  width?: number;
  stroke?: string;
};

const FacebookIcon = ({
  width = 48,
  height = 48,
  stroke = "#A9AFBD",
}: IconProps) => {
    return (
      
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={width} height={height} rx="24" fill="#355089" />
      <g clipPath="url(#clip0_2353_46778)">
        <path
          d="M21.101 35.6909V27.7109H18.627V24.0439H21.101V22.4639C21.101 18.3789 22.949 16.4859 26.959 16.4859C27.36 16.4859 27.914 16.5279 28.427 16.5889C28.8112 16.6285 29.1924 16.6936 29.568 16.7839V20.1089C29.3509 20.0887 29.133 20.0767 28.915 20.0729C28.6707 20.0666 28.4264 20.0636 28.182 20.0639C27.475 20.0639 26.923 20.1599 26.507 20.3729C26.2273 20.5133 25.9922 20.7286 25.828 20.9949C25.57 21.4149 25.454 21.9899 25.454 22.7469V24.0439H29.373L28.987 26.1469L28.7 27.7109H25.454V35.9559C31.396 35.2379 36 30.1789 36 24.0439C36 17.4169 30.627 12.0439 24 12.0439C17.373 12.0439 12 17.4169 12 24.0439C12 29.6719 15.874 34.3939 21.101 35.6909Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2353_46778">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(12 12)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FacebookIcon;
