import { useAppSelector } from "@redux/hooks";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import { createComponentRepository } from "../createComponentRepository";
import { IComponentRepository, RepositoryConfigs } from "../types";
import { useRepository } from "../WithRepositoryContext";
import { TChallengeCategory, TChallengeRepositories } from "./types";
import { selectQueryString } from "@redux/slices/search";

export const useChallengesRepository = <K extends keyof TChallengeRepositories>(
  tag: K,
) => {
  return useRepository<keyof TChallengeRepositories, TChallengeRepositories, K>(
    tag,
  );
};

export const useConfigureChallenges = () => {
  const { id } = useMerchantSidePanelContext();

  const configs = {
    all_challenges: {
      baseUrl: `/merchants/${id}/underwriting-challenges`,
      tag: "all_challenges",
    },
    challenges_categories: {
      baseUrl: `/merchants/${id}/underwriting-challenge-categories`,
      tag: "challenges_categories",
    },
    challenges_stats: {
      baseUrl: `/merchants/${id}/underwriting-challenges/stats`,
      tag: "challenges_stats",
    },
  } satisfies RepositoryConfigs<keyof TChallengeRepositories>;

  const repositories = {
    challenges_categories: createComponentRepository<TChallengeCategory>(),
    challenges_stats: createComponentRepository(),
    all_challenges: createComponentRepository(),
  } satisfies {
    [K in keyof TChallengeRepositories]: IComponentRepository<
      TChallengeRepositories[K]
    >;
  };

  return {
    repositories,
    configs,
  };
};

export const useGetChallenges = () => {
  const { useGetItems: useGetChallengesCategories } = useChallengesRepository(
    "challenges_categories",
  );
  const { useGetItems: useGetChallengesStats } =
    useChallengesRepository("challenges_stats");
  const {
    data: challengesCategoriesData,
    isLoading: areChallengesCategoriesLoading,
  } = useGetChallengesCategories("?sort=sortOrderPriority");
  const { data: challengesStatsData, isLoading: areChallengesStatsLoading } =
    useGetChallengesStats("");

  return {
    data: {
      challengesStatsData,
      challengesCategoriesData,
    },
    isLoading: areChallengesStatsLoading || areChallengesCategoriesLoading,
  };
};

export const useGetSpecificChallengeData = (challengeIdentifier: string) => {
  const { useGetItems: useGetChallengesList } =
    useChallengesRepository("all_challenges");
  const searchText = useAppSelector((state) =>
    selectQueryString(state, "items"),
  );
  const filterParam = challengeIdentifier
    ? `?filter=categoryName:"${challengeIdentifier}"`
    : "";
  const searchParam = searchText ? `&q="${searchText}"` : "";
  const {
    data: challengesList,
    isLoading: isChallengesListLoading,
    error,
  } = useGetChallengesList("");
  //} = useGetChallengesList(`${filterParam}${searchParam}`);  TODO: doesn't work yet, to check after BE

  const { useGetItemsByQueryParams } =
    useChallengesRepository("challenges_stats");
  const { data: categoryStats, isLoading: areStatsLoading } =
    useGetItemsByQueryParams(`?filterName=${challengeIdentifier}`); // TO FILTER BY CHALLENGE CATEGORY

  return {
    data: {
      categoryStats,
      challengesList,
    },
    isLoading: areStatsLoading && isChallengesListLoading,
    isLoadingList: isChallengesListLoading,
  };
};
