import { Stack } from "@mui/system";

const PortalFooterButtons = () => {
  return (
    <Stack
      id="match-report-modal-footer"
      gap="12px"
      alignItems="center"
      direction="row"
    ></Stack>
  );
};

export default PortalFooterButtons;
