import { InputEyeIcon, InputEyeIconNotVisible } from "@assets/icons";
import { RHFInput } from "@common/Input";
import { Text } from "@common/Text";
import { PASSWORD_MIN_CHARS } from "@constants/constants";
import { Box, Divider, InputAdornment, Stack } from "@mui/material";
import { getNewErrorColor } from "@pages/Login/ChangePassword";
import { palette } from "@palette";
import { useGetUser } from "@services/api/onboarding/user";
import { getRelativeTime } from "@utils/date.helpers";
import FlaggedWrapper from "FeatureFlags/FlaggedWrapper";
import { FeatureFlagKeys } from "FeatureFlags/featureFlagKeys";
import React, { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import RequirementBox from "./RequirementBox";
import TrustedDevices from "./TrustedDevices";

type ResetPasswordProps = {
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

const ResetPassword = ({ setDisabled }: ResetPasswordProps) => {
  const { data: userData } = useGetUser();
  const { watch, getFieldState } = useFormContext();
  const values = watch();

  const lastTimePasswordWasUpdated = useMemo(() => {
    const _date = getRelativeTime(
      (userData?.passwordLastChangedAt || 0) * 1000,
    );
    return {
      isExpiring: !!_date.expiresIn,
      message: _date.elapsedTime || _date.expiresIn,
    };
  }, [userData]);

  const isDisabledPassword =
    values.confirm_password !== values.new_password ||
    values.new_password.length < 8 ||
    !/[0-9]/.test(values.new_password) ||
    values.current_password.length < 4;

  useEffect(() => {
    setDisabled(isDisabledPassword);
  }, [isDisabledPassword]);

  const [visibleCurrentPassword, setVisibleCurrentPassword] = useState(false);
  const [visibleNewPassword, setVisibleNewPassword] = useState(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);

  useEffect(() => {
    setDisabled(isDisabledPassword);
  }, [isDisabledPassword]);

  const passwordInputs = [
    {
      name: "current_password",
      label: "Current password",
      placeholder: "Current Password",
      type: !visibleCurrentPassword ? "text" : "password",
      InputProps: {
        endAdornment: (
          <EndAdornment
            isVisible={visibleCurrentPassword}
            setIsVisible={setVisibleCurrentPassword}
          />
        ),
      },
    },
    {
      name: "new_password",
      label: "New Password",
      placeholder: "Type your new password",
      type: !visibleNewPassword ? "text" : "password",
      InputProps: {
        endAdornment: (
          <EndAdornment
            isVisible={visibleNewPassword}
            setIsVisible={setVisibleNewPassword}
          />
        ),
      },
    },
    {
      name: "confirm_password",
      label: "Confirm Password",
      placeholder: "Re-type your new password",
      type: !visibleConfirmPassword ? "text" : "password",
      InputProps: {
        endAdornment: (
          <EndAdornment
            isVisible={visibleConfirmPassword}
            setIsVisible={setVisibleConfirmPassword}
          />
        ),
      },
      error: !!(
        values.confirm_password &&
        values.confirm_password !== values.new_password
      ),
      helper:
        values.confirm_password &&
        values.confirm_password !== values.new_password
          ? " Passwords do not match"
          : "",
    },
  ];

  const lastTimeUpdate = {
    color: lastTimePasswordWasUpdated.isExpiring
      ? palette.filled.red
      : "#326EC5",
    message: !lastTimePasswordWasUpdated.isExpiring
      ? `Last updated ${lastTimePasswordWasUpdated.message}`
      : lastTimePasswordWasUpdated.message,
  };

  return (
    <Box>
      <Text color={palette.neutral[80]} fontWeight="book" fontSize={18} mb={2}>
        Password
      </Text>
      <Text color={lastTimeUpdate.color} mb={1}>
        {lastTimeUpdate.message}
      </Text>
      <Text fontWeight="book" color={palette.neutral[70]}>
        Passwords are the first line of defence against unauthorised access to
        your account. By refreshing your password every 90 days, you’re highly
        reducing the risk of potential threats.
      </Text>
      <Stack spacing={2} mt={3}>
        {passwordInputs.map((props) => (
          <RHFInput
            key={props.name}
            {...props}
            fullWidth
            inputProps={{ maxLength: 254 }}
          />
        ))}

        <Stack spacing={1}>
          {requirementsList.map((item, index) => {
            return (
              <RequirementBox
                key={index}
                requirement={item.text}
                color={
                  getFieldState("new_password").isDirty ||
                  getFieldState("new_password").isTouched ||
                  getFieldState("new_password").invalid ||
                  values.new_password.length > 0
                    ? getNewErrorColor(item.type, values.new_password)
                    : palette.neutral[200]
                }
              />
            );
          })}
        </Stack>
      </Stack>

      <FlaggedWrapper
        ActiveComponent={
          <>
            <Divider sx={{ paddingY: "8px" }} />
            <TrustedDevices />
          </>
        }
        FallbackComponent={<></>}
        name={FeatureFlagKeys.LOGIN_OTP_ENABLED_FLAG}
      />
    </Box>
  );
};

const EndAdornment = ({
  isVisible,
  setIsVisible,
}: {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => (
  <InputAdornment
    position="end"
    sx={{ mr: "2px !important", cursor: "pointer" }}
    onClick={() => setIsVisible((prev) => !prev)}
  >
    {!isVisible ? (
      <InputEyeIcon path_fill={palette.neutral[70]} />
    ) : (
      <InputEyeIconNotVisible path_fill={palette.neutral[70]} />
    )}
  </InputAdornment>
);

const requirementsList = [
  {
    type: "minCharacters",
    text: `${PASSWORD_MIN_CHARS} Characters minimum`,
  },
  {
    type: "lowercase",
    text: "One lowercase character",
  },
  {
    type: "specialCharacter",
    text: "One special character",
  },
  {
    type: "uppercase",
    text: "One uppercase character",
  },
  {
    type: "number",
    text: "One number",
  },
];

export default ResetPassword;
