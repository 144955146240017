import { useMemo, useRef, useState } from "react";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  NEW_ACTION_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import NiceModal from "@ebay/nice-modal-react";
import {
  EDIT_PRIMARY_ACCOUNT_HOLDER,
  GIVE_CONFIRMATION_POP_UP,
  PREVIEW_INVITATION_MODAL,
} from "modals/modal_names";
import { IParsedData } from "@components/Merchants/MerchantPreview/data.types";
import { useMutation, useQueryClient } from "react-query";
import { customInstance } from "@services/api";
import { isFunction } from "lodash";
import GiveText from "@shared/Text/GiveText";
import { ContextualMenuOptionProps } from "@shared/ContextualMenu/ContextualMenu.types";
import { MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS } from "features/Merchants/MerchantSidePanel/constants";
import { ArrowPlacement } from "@shared/Tooltip/GiveTooltip.types";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

type StatusType = "approved" | "declined";

type MissingFieldsType = {
  field: string;
  missing: boolean;
};

const usePAHInfoSection = (id: number, data: IParsedData) => {
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { isMobileView } = useCustomThemeV2();
  const { selectedUser } = useGetCurrentMerchantId();

  const queryClient = useQueryClient();
  const updateOwnerStatus = useMutation((data: any) => {
    return customInstance({
      url: `/merchants/${id}/owner/status`,
      method: "PATCH",
      data,
    });
  });

  const { openConversationHandler } = useConversationsModal();
  const { merchant_underwriting, modify_merchant } = useEnterprisePermissions();
  const hasEditPahPermission = useAccessControl({
    resource: composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.OWNER),
    operation: OPERATIONS.UPDATE,
  });

  const { merchType, userRole } = selectedUser || {};
  const isPreviewDisabled =
    (merchType === "enterprise" || merchType === "provider") &&
    userRole === "member";

  const handleOpenMenu = () => {
    setAnchorEl(menuButtonRef.current);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenPreview = () => {
    NiceModal.show(PREVIEW_INVITATION_MODAL, {
      receiverName: data?.header?.name,
      senderName: data?.merchantInfo?.enterprise,
      senderLogo: data?.merchantInfo?.enterpriseImageUrl,
      isSendingToProvider: false,
    });
  };

  const handleOpenConversation = () => {
    openConversationHandler({
      id: 0,
      name: "Primary Account Holder",
      paths: [],
    });
  };

  const handleEdit = () => {
    handleCloseMenu();
    NiceModal.show(EDIT_PRIMARY_ACCOUNT_HOLDER, {
      id,
      data: data.primaryAccountHolder,
      ownerAccId: data?.ownerId,
    });
  };

  const { menuOptions, missingFields } = useMemo<{
    menuOptions: Omit<ContextualMenuOptionProps, "children">[];
    missingFields: MissingFieldsType[];
  }>(() => {
    const statusName = data.primaryAccountHolder?.owner?.statusName;
    const requiredFiled = [
      {
        field: "First name",
        missing: !data.primaryAccountHolder.firstName,
      },
      {
        field: "Last name",
        missing: !data.primaryAccountHolder.lastName,
      },
      {
        field: "Email",
        missing: !data.primaryAccountHolder?.email,
      },
    ];
    const commonTooltipProperties = {
      tooltipTitle: NEW_ACTION_DENY_MESSAGE,
      tooltipDirection: isMobileView
        ? ArrowPlacement.TOP
        : ArrowPlacement.TOP_END,
    };
    const isEditDisabled = !modify_merchant || !hasEditPahPermission;
    const menuOptions: Omit<ContextualMenuOptionProps, "children">[] = [
      {
        text: "Edit",
        onClick: handleEdit,
        disabled: isEditDisabled,
        disableTooltip: !isEditDisabled,
        ...commonTooltipProperties,
      },
      {
        text: "Approve",
        hidden: statusName === "approved" || !merchant_underwriting,
        onClick: () => handleAction("approved"),
        disabled:
          requiredFiled.some((field) => field.missing) || !hasEditPahPermission,
        disableTooltip: hasEditPahPermission,
        ...commonTooltipProperties,
      },

      {
        text: "Decline",
        hidden: statusName === "declined" || !merchant_underwriting,
        onClick: () => handleAction("declined"),
        disabled: !hasEditPahPermission,
        disableTooltip: hasEditPahPermission,
        ...commonTooltipProperties,
      },
    ];
    const missingFields: MissingFieldsType[] = [
      {
        field: "documents",
        missing: !data.primaryAccountHolder.owner?.IDProofUploaded,
      },
      {
        field: "selfie photo",
        missing: !data.primaryAccountHolder.owner?.selfieUploaded,
      },
    ];
    return { menuOptions, missingFields };
  }, [id, data.primaryAccountHolder]);

  const handleUpdateStatus = (status: StatusType, onSuccess?: () => void) =>
    updateOwnerStatus.mutate(
      { status },
      {
        onSuccess: () => {
          queryClient.setQueryData(
            [MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS.GET, id],
            (data: any) => {
              return {
                ...data,
                merchant: {
                  ...data.merchant,
                  legalEntity: {
                    ...data.merchant.legalEntity,
                    statusName: status,
                  },
                },
              };
            },
          );
          queryClient.refetchQueries([
            MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS.GET,
            id,
          ]);
          isFunction(onSuccess) && onSuccess();
        },
      },
    );
  const isMissingFileds = missingFields.some((field) => field.missing);
  const getModalContent = (status: StatusType) => {
    switch (status) {
      case "approved":
        return {
          title: "Approve Primary Account Holder",
          description: isMissingFileds ? (
            <>
              <GiveText>
                Are you sure you want to approve PAH without attaching:
              </GiveText>
              <ul>
                {missingFields.map((item, index) => (
                  <li key={index}>{item.field}</li>
                ))}
              </ul>
            </>
          ) : (
            "Are you sure you want to approve this PAH?"
          ),
          actions: {
            handleSuccess: {
              onClick: () => handleUpdateStatus("approved"),
            },
          },
        };
      case "declined":
        return {
          title: "Decline Primary Account Holder",
          description: "Are you sure you want to decline this PAH?",
          actions: {
            handleSuccess: {
              onClick: () => handleUpdateStatus("declined"),
            },
          },
        };

      default:
        return {
          title: "",
          description: "",
        };
    }
  };
  const handleAction = (status: StatusType) => {
    const { title, description, actions } = getModalContent(status);

    return NiceModal.show(GIVE_CONFIRMATION_POP_UP, {
      modalType: status,

      title: title,
      description: description,
      actions: actions,
    });
  };

  return {
    handleOpenPreview,
    handleCloseMenu,
    handleOpenConversation,
    handleOpenMenu,
    isPreviewDisabled,
    menuOptions,
    menuButtonRef,
    menuAnchorEl: anchorEl,
  };
};

export default usePAHInfoSection;
