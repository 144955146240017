import { Stack } from "@mui/system";
import { styled } from "@theme/v2/Provider";

const logo =
  "https://s3-alpha-sig.figma.com/img/5c70/f6fe/c056b49e6be4ea30441a37321c5e8fa6?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=CKvfQtmw-mqjj8QyJXhywWAvSo5qDT09rL8h~uoHb~YfPrfDynaR~6WsksaelXBsC6aUQhMi5rEtpnzNtd49TEQivp4vwGGzAYJ2DKQewvIoGiswFR4v6SSepmlieuq2FUxJUFJFovaOWPaOISywJ7S0fXSnyUT62m8sf8aPuO02zsFhAWDI19AUgd0yFOlwszVD-RzfukddNvjPYY1VjqGiv5A3MxW7OSo1sp4RWdJUeI2-4Lkixl8XbFi7xrJYRNTeZvbIqVwGWhh2n6z9ZAP3GUFKy7KsFOHqD4X4Wv~6ZC45PJHY5i3cw0OfEiW0nlRc6JNz2dH5SLmhFsJyVA__";
const ProductHeader = () => {
  return <LogoContainer>{/* <img src={logo} alt="Logo" /> */}</LogoContainer>;
};

export default ProductHeader;

const LogoContainer = styled(Stack)({
  alignItems: "flex-start",
  padding: "32px 0",
  height: "96px",
  "& img": {
    height: "32px",
  },
});
