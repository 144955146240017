import { getJsonValue, isSecondVersion } from "@theme/v2/utils";
import { ThemeVersions } from "@theme/v2/types";

declare module "@mui/material/Link" {
  interface LinkOwnProps {
    hasIcon?: boolean;
    version?: ThemeVersions;
  }

  interface LinkPropsColorOverrides {
    error: true;
  }
}

export const getCustomLinkStyle = (mode: "light" | "dark") => {
  return {
    styleOverrides: {
      root: {
        variants: [
          {
            props: (props: any) => isSecondVersion(props.version),
            style: {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontSize: 14,
              fontWeight: 400,
              lineHeight: "20px",
              gap: "6px",
              color: getJsonValue(`tokens.${mode}.colour.text.primary`),
              textDecoration: "none",
              "& span": {
                position: "relative",
                "&:before": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  bottom: 0,
                  height: "0px",
                  backgroundColor: "currentColor",
                  width: "100%",
                  opacity: 0,
                  transition: "height 0.15s ease-out, opacity 0.15s ease",
                },
              },
              "&:hover span:before": {
                height: "1px",
                opacity: 1,
              },
              "&:active": {
                color: getJsonValue(`tokens.${mode}.colour.text.secondary`),
              },
            },
          },
          {
            props: (props: any) =>
              isSecondVersion(props.version) && props.color === "secondary",
            style: {
              color: getJsonValue(`tokens.${mode}.primitive.blue.100`),
              "&:active": {
                color: getJsonValue(`tokens.${mode}.primitive.blue.50`),
              },
            },
          },
          {
            props: (props: any) => props.color === "error",
            style: {
              color: getJsonValue(`tokens.${mode}.primitive.error.100`),
              "&:active": {
                color: getJsonValue(`tokens.${mode}.primitive.error.50`),
              },
            },
          },
          {
            props: (props: any) =>
              isSecondVersion(props.version) && props.disabled,
            style: {
              color: getJsonValue(`tokens.${mode}.colour.text.tertiary`),
              cursor: "default",
              "&:active": {
                color: getJsonValue(`tokens.${mode}.colour.text.tertiary`),
              },
              "&:hover span:before": {
                height: "0",
                opacity: 0,
              },
            },
          },
          {
            props: (props: any) =>
              props.disabled && isSecondVersion(props.version),
            style: {
              "&:before": {
                content: "unset",
              },
            },
          },
        ],
      },
    },
  };
};
