import CopyButton from "@common/CopyButton";
import { useFormContext } from "react-hook-form";
import { SLUG_MAX_CHARACTER_LENGTH } from "@constants/constants";
import useSlugPrefixFormatter from "@components/Merchants/MerchantPreview/hooks/useSlugPrefixFormatter";
import { HFGiveInput } from "@shared/HFInputs/HFGiveInput/HFGiveInput";
import GiveText from "@shared/Text/GiveText";

type MerchantSlugInputProps = {
  id?: string;
  name: string;
  label?: string;
  parentName?: string;
  disabled?: boolean;
  focusViewColor?: string;
  value?: string;
};

const MerchantSlugInput = ({
  name,
  label,
  parentName,
  disabled = false,
  ...rest
}: MerchantSlugInputProps) => {
  const { watch } = useFormContext();

  const prefix = `https://${
    parentName || watch("parent_slug")
  }.givepayments.com/`;

  const slugPrefix = useSlugPrefixFormatter(prefix);

  return (
    <HFGiveInput
      name={name}
      label={label}
      placeholder="Permalink"
      fullWidth
      maxLength={SLUG_MAX_CHARACTER_LENGTH}
      disabled={disabled}
      leftContent={
        <GiveText variant="bodyS" color="secondary">
          {slugPrefix}
        </GiveText>
      }
      rightContent={
        <CopyButton hidden={!watch(name)} text={prefix + watch(name)} />
      }
      {...rest}
    />
  );
};

export default MerchantSlugInput;
