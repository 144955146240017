import { Box, Stack } from "@mui/system";
import { styled, useAppTheme } from "@theme/v2/Provider";
import GiveText from "@shared/Text/GiveText";
import GiveButton from "@shared/Button/GiveButton";
import { Icon as StatusIcon } from "@shared/Popup/GivePopupIcons";
import React from "react";
import { ArrowRight } from "@phosphor-icons/react";
import { ContentViewTypes } from "../MatchReportModal";

const SUMMARY_TEXT =
  "The Member Alert to Control High-risk Merchants (MATCH) report compiles comprehensive results obtained from the screening of a merchant's business and ownership details.";

interface IComponentProps {
  handleChangeView: (value: ContentViewTypes) => void;
}

const NewReportView = ({ handleChangeView }: IComponentProps) => {
  const theme = useAppTheme();
  const showAddNewReportView = () => {
    handleChangeView(ContentViewTypes.ADD_NEW_REPORT);
  };
  return (
    <Stack gap={3}>
      <StyledAddNewReportStack>
        <GiveText variant="bodyS" color="secondary">
          {SUMMARY_TEXT}
        </GiveText>
        <StyledAddNewReportButton
          label="Add New Report"
          size="small"
          onClick={showAddNewReportView}
        />
      </StyledAddNewReportStack>
      <StyledMatchStatusBoxRoot>
        <StyledMatchStatusStackContainer>
          <StatusIcon type="success-regular" />
          <Stack direction="row" justifyContent="space-between" pt={3}>
            <GiveText variant="h5">Clear</GiveText>
            <ArrowRight
              size={20}
              color={theme.palette.icon?.["icon-primary"]}
            />
          </Stack>
          <StyledLatestCheckStack>
            <GiveText variant="bodyXS" color="secondary">
              Latest check:
            </GiveText>
            <GiveText variant="bodyXS">July 12, 2024, 05:18:34 PM UTC</GiveText>
          </StyledLatestCheckStack>
        </StyledMatchStatusStackContainer>
      </StyledMatchStatusBoxRoot>
    </Stack>
  );
};

const StyledAddNewReportStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: theme.spacing(2.5),
  [theme.breakpoints.down("v2_sm")]: {
    flexDirection: "column",
  },
}));

const StyledAddNewReportButton = styled(GiveButton)(({ theme }) => ({
  height: "fit-content",
  width: "70%",
  [theme.breakpoints.down("v2_sm")]: {
    width: "100%",
  },
}));

const StyledMatchStatusBoxRoot = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette?.surface?.secondary,
  borderRadius: theme.customs?.radius.medium,
}));

const StyledMatchStatusStackContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.5),
  backgroundColor: theme.palette?.surface?.primary,
  borderRadius: theme.customs?.radius.medium,
  cursor: "pointer",
}));

const StyledLatestCheckStack = styled(Stack)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.border?.primary}`,
  marginTop: theme.spacing(1.5),
  paddingTop: theme.spacing(1.5),
  flexDirection: "row",
  justifyContent: "space-between",
}));

export default NewReportView;
