import { useQuery } from "react-query";
import { MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS } from "../constants";
import { customInstance } from "@services/api";
import { useMerchantSidePanelContext } from "../Provider/MerchantSidePanelProvider";

export default function useFetchMerchantRisk() {
  const { data } = useMerchantSidePanelContext();
  const { category } = data?.merchantInfo || {};
  return useQuery(
    [MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS.GET_RISK_CATEGORY, category],
    async () => {
      return customInstance({
        url: `/merchant-risk-statuses?filter=category:%22${category}%22`,
      });
    },
    { enabled: Boolean(category) },
  );
}
