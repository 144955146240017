import AgreementStatusCard from "./components/AgreementStatusCard";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import SnapshotCard from "./components/SnapshotCard";
import BankDisclosure from "./components/BankDisclosure";
import AgreementStatusWrapper from "./wraper/AgreementStatusWrapper";
import RefundPolicy from "./components/RefundPolicy";
import CardTypes from "./components/CardTypes";
import { JoinedProps } from "../agreements.types";
import SignedBySection from "./components/SignedBySection";
import PCISection from "./components/PCISection";
import MerchantApplicationScheduleFee from "./components/MerchantApplicationScheduleFee";
import { Skeleton, SkeletonOwnProps, Stack } from "@mui/material";
import { skeletonDefaultProps } from "../../constants";

const AgreementPanel = ({ setPage }: JoinedProps) => {
  const { data, isLoading } = useMerchantSidePanelContext();

  const openSnapShot = () => {
    setPage({
      mainPage: "Snapshot",
      tab: "merchantApplication",
    });
  };
  return (
    <>
      {isLoading ? (
        <LoadingSkeletons />
      ) : (
        <AgreementStatusWrapper>
          <AgreementStatusCard merchantAgreement={data?.merchantAgreement} />
          <SnapshotCard onClick={openSnapShot} />
          <BankDisclosure />
          <RefundPolicy />
          <CardTypes />
          <MerchantApplicationScheduleFee />
          <PCISection />
          <SignedBySection />
        </AgreementStatusWrapper>
      )}
    </>
  );
};

export default AgreementPanel;

const skeletonsConfig = [
  { height: "46px", width: "100%" },
  { height: "72px", width: "100%" },
  { height: "22px", width: "40%" },
  { height: "144px", width: "100%" },
  { height: "22px", width: "40%" },
  { height: "226px", width: "100%" },
  { height: "22px", width: "50%" },
  { height: "226px", width: "100%" },
];

const LoadingSkeletons = () => (
  <Stack p="20px" gap={2} flex={1}>
    {skeletonsConfig.map((config, index) => (
      <Skeleton key={index} {...config} {...skeletonDefaultProps} />
    ))}
  </Stack>
);
