import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { styled } from "@mui/material";
import { ModalTitle } from "@common/Modal/ModalFactory/atoms";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { TBusinessOwner, TBusinessProfileInfo } from "../data.types";
import { Stack } from "@mui/material";
import BusinessOwnerItem from "@common/BusinessOwners/BusinessOwnerItem";
import { adjustAddressType } from "../components/BusinessOwners/MerchantBusinessOwners";
import useBusinessOwner from "@components/Settings/ManageTeam/BusinessOwners/useBusinessOwner";
import { BusinessOwnershipIcon } from "@assets/icons/RebrandedIcons";
import { useMemo } from "react";
import { PEP_HISTORY_MODAL } from "modals/modal_names";
import { TPEPOwner } from "../PEP/types";

interface IProps {
  ownersList: TBusinessOwner[];
  profileData: TBusinessProfileInfo;
  merchantId: number;
  isApproved: boolean;
  isEnterprise?: boolean;
}

const ManageBuisnessOwnersModal = NiceModal.create(
  ({
    ownersList,
    profileData,
    merchantId,
    isApproved,
    isEnterprise,
  }: IProps) => {
    const modal = useModal();
    const { isMobileView } = useCustomTheme();

    const stakeSum = useMemo(() => {
      return (
        ownersList?.reduce((acc, owner) => {
          const _ownership = owner.ownership ? +owner.ownership : 0;
          return acc + _ownership;
        }, 0) || 0
      );
    }, [ownersList]);

    const isSoleProprietorship =
      profileData.businessType === "individual_sole_proprietorship";

    const hasMaxOwners = isSoleProprietorship && ownersList.length >= 1;

    const handleClose = () => {
      modal.hide();
    };

    const { editOwnerHandler } = useBusinessOwner({
      stakeSum,
      isApprovedLE: isApproved,
      hasMaxOwners,
      legalEntityID: profileData.id,
      isController: profileData.isController,
      showTag: true,
      merchantId,
      isSoleProprietorship,
      isBPLinked: profileData?.isBPLinked,
      businessType: profileData?.businessType,
      onCloseParentModal: handleClose,
    });

    const handleOpenPepHistory = (data: TPEPOwner) => {
      NiceModal.show(PEP_HISTORY_MODAL, {
        selectedOwner: data,
        merchantId,
        legalEntityID: profileData.id,
        onCloseParentModal: handleClose,
        isEnterprise: isEnterprise,
      });
    };

    return (
      <ModalFactory
        variant="dialog"
        renderMobile={isMobileView}
        modalProps={{
          open: modal.visible,
          onClose: handleClose,
          width: 480,
        }}
      >
        <Stack padding={3} gap={3}>
          <ModalTitle
            Icon={<BusinessOwnershipIcon />}
            title="Business Owners"
            onClose={handleClose}
          />
          <FadeUpWrapper delay={25}>
            <StyledContentStack spacing={1} isMobile={isMobileView}>
              {ownersList?.map((owner) => (
                <BusinessOwnerItem
                  key={owner.id}
                  owner={adjustAddressType(owner)}
                  selectBusinessOwner={handleOpenPepHistory}
                  onEdit={(event) =>
                    editOwnerHandler(event, adjustAddressType(owner))
                  }
                  isEnterprise={isEnterprise}
                  withPepStatus
                />
              ))}
            </StyledContentStack>
          </FadeUpWrapper>
        </Stack>
      </ModalFactory>
    );
  },
);

const StyledContentStack = styled(Stack)<{ isMobile: boolean }>(
  ({ isMobile }) => ({
    overflowY: "auto",
    maxHeight: isMobile ? "initial" : 370,
  }),
);

export default ManageBuisnessOwnersModal;
