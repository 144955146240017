import { customInstance } from "@services/api";
import { IComponentRepository } from "./types";

export const createComponentRepository = <T,>(): IComponentRepository<T> => ({
  getAll: async (url: string): Promise<{ total: number; data: Array<T> }> => {
    const response = await customInstance({
      method: "GET",
      url: url,
    });
    return { total: response.total, data: response.data || response };
  },
  getById: (url: string, id: string): Promise<T> => {
    return customInstance({
      method: "GET",
      url: `${url}/${id}`,
    });
  },

  getByQueryParams: (url: string, queryParams: string): Promise<T> => {
    return customInstance({
      method: "GET",
      url: `${url}${queryParams}`
    })
  }
});