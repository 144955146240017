import { RootState } from "@redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EditorState } from "draft-js";
import { findIndex } from "lodash";

export type TicketType = {
  id: string;
  name: string;
  amount: string;
  active: boolean;
  in_stock: string;
  thumbnail: string | File;
  display_tickets: boolean;
  description: {
    enabled: boolean;
    text: EditorState;
  };
};
export interface SweepstakesState {
  names: {
    type: string[];
    conversion: number[];
    amount: (string | number)[];
    purchases: (string | number)[]; // Remove when Invoices slice is created
    transactions: (string | number)[];
    visitors: (string | number)[];
    created: (string | number)[];
    status: string[];
  };
  minibuilder: {
    tickets: {
      globalTicketId: string;
      list: TicketType[];
    };
  };
}

const initialState: SweepstakesState = {
  names: {
    type: [],
    amount: [],
    conversion: [],
    transactions: [],
    purchases: [],
    visitors: [],
    created: [],
    status: [],
  },
  minibuilder: {
    tickets: {
      globalTicketId: "1",
      list: [],
    },
  },
};

type ConversionRangeType = number[];

const sweepstakesSlice = createSlice({
  name: "fundraisers",
  initialState,
  reducers: {
    addConversionRangeFilter: (
      state: SweepstakesState,
      action: PayloadAction<ConversionRangeType>,
    ) => {
      const range = action.payload;
      state.names.conversion = range;
    },
    disableConversionRangeFilter: (state: SweepstakesState) => {
      state.names.conversion = [];
    },
    addTicket: (state: SweepstakesState, action: PayloadAction<TicketType>) => {
      state.minibuilder.tickets.list = [
        ...state.minibuilder.tickets.list,
        action.payload,
      ];
    },
    editTicket: (
      state: SweepstakesState,
      action: PayloadAction<TicketType>,
    ) => {
      const isTicketExist = (newName: string, id: string) => {
        return state.minibuilder.tickets.list.some(
          (item) => item.name === newName && id !== item.id,
        );
      };
      if (isTicketExist(action.payload.name, action.payload.id)) return;
      const updatedTickets = [...state.minibuilder.tickets.list].map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
      state.minibuilder.tickets.list = [...updatedTickets];
    },
    removeTicket: (
      state: SweepstakesState,
      action: PayloadAction<{ id: string }>,
    ) => {
      state.minibuilder.tickets.list = state.minibuilder.tickets.list.filter(
        (item) => item.id !== action.payload.id,
      );
    },
    showTicket: (
      state: SweepstakesState,
      action: PayloadAction<{ id: string }>,
    ) => {
      const index = findIndex(state.minibuilder.tickets.list, {
        id: action.payload.id,
      });

      if (index > -1) {
        state.minibuilder.tickets.list[index] = {
          ...state.minibuilder.tickets.list[index],
          active: !state.minibuilder.tickets.list[index].active,
        };
      }
    },
    setTicketList: (
      state: SweepstakesState,
      action: PayloadAction<TicketType[]>,
    ) => {
      state.minibuilder.tickets.list = action.payload;
    },
    setIncrementGlobalTicketId: (state: SweepstakesState) => {
      state.minibuilder.tickets.globalTicketId = (
        parseInt(state.minibuilder.tickets.globalTicketId) + 1
      ).toString();
    },
  },
});

export const {
  // addFilter,
  // removeFilter,
  addConversionRangeFilter,
  disableConversionRangeFilter,
  addTicket,
  showTicket,
  removeTicket,
  setTicketList,
  editTicket,
  setIncrementGlobalTicketId,
} = sweepstakesSlice.actions;
export const selectFilters = (state: RootState) => state.sweepstakes.names;

export const selectTickets = (state: RootState) =>
  state.sweepstakes.minibuilder.tickets.list;
export const selectGlobalTicketID = (state: RootState) =>
  state.sweepstakes.minibuilder.tickets.globalTicketId;

export default sweepstakesSlice.reducer;
