import ExportFilterButtons from "@common/ExportView/components/ExportFilterButtons";
import { SearchBar } from "@common/SearchBar_V2";
import {
  DownloadReportButton,
  PendingSwitch,
  TableFilters,
} from "@components/ManageMoney/TransactionTable/TransactionTable.atoms";
import { useDownloadReport } from "@components/ManageMoney/TransactionTable/hooks";
import { Grid, Stack } from "@mui/material";
import FilterAtomButton from "@common/Button/FilterAtomButton";
import NiceModal from "@ebay/nice-modal-react";
import { MANAGE_MONEY_FILTER_MODAL } from "modals/modal_names";
import { QKEY_MANAGE_MONEY_TRANSACTIONS_LIST } from "@constants/queryKeys";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  resetFilterByKey,
  selectFiltersObject,
} from "@redux/slices/dynamicFilterSlice";
import { isEmpty, isNull } from "lodash";
import { countTopLevelDifferences } from "@utils/helpers";
import { defaultValuesManageMoney } from "@pages/ManageMoney/constants";

export function MainActionsHeader({
  queryKey,
  filters,
  tableType = "",
  isDisabled,
  filterParams,
}: {
  queryKey: string;
  filters: any;
  tableType?: "manageMoney" | string;
  isDisabled?: boolean;
  filterParams?: string;
}) {
  const tableTitle = "Transactions";
  const { disableDownload, downloadReport, denyDownload } = useDownloadReport({
    filterParams,
  });

  if (!Array.isArray(filters) && filters?.type === "REACT_COMPONENT") {
    return (
      <Grid container alignItems="center">
        {filters.jsx}
        <Stack
          direction="row"
          alignItems="center"
          gap="8px"
          sx={{
            marginLeft: "auto",
          }}
        >
          <SearchBar queryKey={queryKey} />
        </Stack>
      </Grid>
    );
  }
  if (tableType === "manageMoney") {
    return (
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <SearchBar queryKey={queryKey} />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <FilterButton isDisabled={isDisabled} />
          <DownloadReportButton
            disabled={disableDownload}
            denyDownload={denyDownload}
            onClick={downloadReport}
          />
        </Stack>
      </Stack>
    );
  }
  return (
    <Grid container alignItems="center">
      {filters ? <TableFilters filters={filters} /> : <PendingSwitch />}
      <Stack
        direction="row"
        alignItems="center"
        gap="8px"
        sx={{
          marginLeft: "auto",
        }}
      >
        <SearchBar queryKey={queryKey} />
        {tableTitle && ["Merchants", "Merchants list"].includes(tableTitle) && (
          <ExportFilterButtons totalRows={0} />
        )}
        <DownloadReportButton
          disabled={disableDownload}
          denyDownload={denyDownload}
          onClick={downloadReport}
        />
      </Stack>
    </Grid>
  );
}

export const FilterButton = ({ isDisabled }: { isDisabled?: boolean }) => {
  const dispatch = useAppDispatch();
  const filterObject = useAppSelector((state) =>
    selectFiltersObject(state, QKEY_MANAGE_MONEY_TRANSACTIONS_LIST),
  );

  const handleResetFilters = (event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    dispatch(
      resetFilterByKey({
        filterKey: QKEY_MANAGE_MONEY_TRANSACTIONS_LIST,
      }),
    );
  };
  const handleOpenModal = () => NiceModal.show(MANAGE_MONEY_FILTER_MODAL);
  const filtersAmount = isEmpty(filterObject)
    ? null
    : countTopLevelDifferences(filterObject, defaultValuesManageMoney);

  return (
    <FilterAtomButton
      disabled={isDisabled && isNull(filtersAmount)}
      handleResetFilters={handleResetFilters}
      handleOpenModal={handleOpenModal}
      filtersAmount={filtersAmount}
    />
  );
};
