import { Box, BoxProps } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Dropzone, { IDropzoneProps } from "react-dropzone-uploader";

import { ILayoutProps } from "react-dropzone-uploader";
import ImagePlaceholder from "@assets/images/thumbnail-placeholder.png";
import { IFileWithMeta } from "react-dropzone-uploader";
import { StatusValue } from "react-dropzone-uploader";
import { useAppDispatch } from "@redux/hooks";
import { ACCEPTED_IMAGE_FORMATS } from "@constants/constants";
import { isImage } from "@hooks/merchant-api/image-gallery/useUploadImage";
import { showMessage } from "@common/Toast";
import { useIsValidFile } from "@hooks/upload-api/useIsValidFile";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { X } from "@phosphor-icons/react";
import { styled } from "@theme/v2/Provider";
import GiveText from "@shared/Text/GiveText";

type TImageSize = "small" | "medium" | "large" | "thumb" | "original";

export type UploadAvatarProps = BoxProps & {
  file?: any;
  avatarType?: "avatar" | "thumbnail";
  title?: string;
  imageSize?: TImageSize;
  width?: string | number;
  borderRadius?: string | number;
  height?: string | number;
  disabled?: boolean;
  defaultImage?: string;
  uploadStatus?: "pending" | "success" | "error" | "idle";
  customPlaceholder?: string;
  hiddenWhenEmpty?: boolean;
  onResetAvatar?: () => void;
  onChangeStatus?: IDropzoneProps["onChangeStatus"];
  getUploadParams?: IDropzoneProps["getUploadParams"];
  getFilesFromEvent?: IDropzoneProps["getFilesFromEvent"];
  actionToDispatch?: (args: any) => {
    payload: any;
    type: any;
  };
};

const Layout = ({
  input,
  dropzoneProps,
  fileUrl,
}: ILayoutProps & { fileUrl: string }) => {
  return (
    <Box
      {...dropzoneProps}
      style={{
        ...dropzoneProps.style,
        background: `url(${fileUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {input}
    </Box>
  );
};

const GiveUploadAvatar = ({
  file,
  avatarType = "avatar",
  disabled,
  width = "120px",
  height = "120px",
  title = "Avatar",
  imageSize = "small",
  borderRadius = "50%",
  uploadStatus,
  defaultImage,
  onChangeStatus,
  getUploadParams,
  getFilesFromEvent,
  actionToDispatch,
  customPlaceholder,
  hiddenWhenEmpty,
  onResetAvatar,
  ...boxProps
}: UploadAvatarProps) => {
  const ref = useRef<any>(null);
  const defaultPlaceholder = ImagePlaceholder;

  const [fileUrl, setFileUrl] = useState<string>(
    customPlaceholder || defaultPlaceholder,
  );
  const dispatch = useAppDispatch();

  const dispatchAction = useCallback(
    (args: any) => actionToDispatch && dispatch(actionToDispatch(args)),
    [actionToDispatch],
  );
  const { isDropzoneImageFileValid } = useIsValidFile();
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();

  useEffect(() => {
    if (!file) {
      setFileUrl(defaultPlaceholder);
      return;
    }

    const imageUrl =
      typeof file === "string" && file.length > 0
        ? file + "/original"
        : file?.preview;

    if ((defaultImage && !file) || defaultImage) {
      setFileUrl(defaultImage);
    } else if (file && fileUrl === defaultPlaceholder && imageUrl) {
      setFileUrl(imageUrl);
      dispatchAction({ img: imageUrl });
    }
  }, [file]);

  const handleChangeStatus = (
    fileWithMeta: IFileWithMeta,
    status: StatusValue,
    allFiles: IFileWithMeta[],
  ) => {
    const { meta, file } = fileWithMeta;
    if (["preparing", "removed"].includes(status)) return;
    if (
      isFileUploadRefactorEnabled &&
      !isDropzoneImageFileValid(fileWithMeta, status)
    ) {
      return;
    }

    const isImg = isImage(file);

    if (!isImg && !isFileUploadRefactorEnabled) {
      showMessage(
        "Error",
        "Only png, jpeg and jpg extensions are allowed",
        true,
        "",
        false,
      );
      return;
    }

    if (meta.previewUrl) {
      setFileUrl(meta.previewUrl);

      onChangeStatus && onChangeStatus(fileWithMeta, status, allFiles);
      dispatchAction({ img: meta.previewUrl });
    }
  };

  return (
    <Container {...boxProps}>
      <GiveText variant="bodyS">{title}</GiveText>
      <Box
        sx={{
          width: width,
          height: height,
          position: "relative",
        }}
      >
        {fileUrl !== defaultPlaceholder && onResetAvatar && (
          <CloseButton Icon={X} variant="ghost" onClick={onResetAvatar} />
        )}
        <Dropzone
          disabled={disabled}
          ref={ref}
          styles={{
            dropzone: {
              overflow: "hidden",
              minHeight: "auto",
              height: "100%",
              border: 0,
              borderRadius: avatarType === "avatar" ? "50%" : "8px",
              borderColor:
                uploadStatus === "success"
                  ? "green"
                  : uploadStatus === "error"
                  ? "red"
                  : "transparent",
              opacity:
                fileUrl === defaultPlaceholder && hiddenWhenEmpty ? 0 : 1,
            },
          }}
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          multiple={false}
          canCancel={false}
          accept={ACCEPTED_IMAGE_FORMATS}
          getFilesFromEvent={getFilesFromEvent}
          LayoutComponent={(props) => <Layout {...props} fileUrl={fileUrl} />}
          InputComponent={({ accept, onFiles, getFilesFromEvent }) => (
            <Box
              component="label"
              sx={{
                opacity: 0,
                width: "100%",
                height: "100%",
                cursor: "pointer",
              }}
            >
              <input
                disabled={disabled}
                type="file"
                id="upload"
                accept={accept}
                style={{ display: "none" }}
                onChange={async (e) => {
                  const chosenFiles = await getFilesFromEvent(e);
                  onFiles(chosenFiles);
                  (e.target as any).value = null;
                }}
              />
            </Box>
          )}
        />
      </Box>
    </Container>
  );
};

const Container = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
}));

const CloseButton = styled(GiveIconButton)(({ theme }) => ({
  position: "absolute",
  top: "4px",
  right: "4px",
  background: theme.palette.surface?.primary,
  borderRadius: "50%",
  padding: "4px",
  zIndex: 2,
  "&:hover": {
    background: theme.palette.surface?.primary,
  },
}));

export default GiveUploadAvatar;
