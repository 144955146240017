import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import { PEPStatusType } from "@components/Merchants/MerchantPreview/PEP/types";
import { Box, Stack, Grid, Divider } from "@mui/material";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { isEmptyPhone } from "@utils/date.helpers";
import { checkPortals } from "@utils/routing";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import moment from "moment";
import { ReactNode, memo } from "react";
import { IFileWithMeta } from "react-dropzone-uploader";
import GiveCollapsableComponent from "./GiveCollapsableComponent";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme } from "@theme/v2/Provider";
import GiveButton from "@shared/Button/GiveButton";
import { TBusinessOwner } from "@common/BusinessOwners/types";
import { ISectionItem } from "../hooks/useGetSectionItems";
import { GiveSectionItem } from "../GiveSectionItem";
import GivePepStatusChip from "./GivePepStatusChip";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { ArrowRight, PencilSimple } from "@phosphor-icons/react";
import { BOIDDisplay } from "./BOIDDisplay";
import NiceModal from "@ebay/nice-modal-react";
import { PEP_HISTORY_MODAL } from "modals/modal_names";
import { isFunction } from "lodash";

interface Props {
  owner: TBusinessOwner;
  legalEntityID: number;
  onEdit: (event: React.MouseEvent<HTMLElement>, owner: TBusinessOwner) => void;
  withPepStatus?: boolean;
  disabled?: boolean;
  showViewPep?: boolean;
  idFile?: TMerchantDocument | IFileWithMeta;
  merchantId: number;
  isEnterprise?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
}

const GiveBusinessOwnerItem = ({
  owner,
  legalEntityID,
  onEdit,
  withPepStatus = false,
  disabled = false,
  showViewPep = true,
  idFile,
  merchantId,
  isEnterprise = false,
  isFirst,
  isLast,
}: Props) => {
  const handleViewPep = () => {
    NiceModal.show(PEP_HISTORY_MODAL, {
      selectedOwner: owner,
      merchantId,
      legalEntityID: legalEntityID,
      isEnterprise: isEnterprise,
    });
  };

  const handleEdit = (event: React.MouseEvent<HTMLElement>) => {
    if (isFunction(onEdit)) onEdit(event, owner);
  };

  return (
    <GiveCollapsableComponent
      isFirst={isFirst}
      isLast={isLast}
      title={
        <OwnerHeader
          firstName={owner.firstName}
          lastName={owner.lastName}
          ownership={owner.ownership}
          pepStatusName={owner?.pepStatusName}
          withPepStatus={withPepStatus}
          pepStatusSetByUnderwriter={owner.pepStatusSetByUnderwriter}
          manualPepStatus={owner?.manualPepStatus}
        />
      }
      withExpandIcon={true}
    >
      <OwnerBody
        merchantId={merchantId}
        owner={owner}
        onEdit={handleEdit}
        handleViewPep={handleViewPep}
        withPepStatus={withPepStatus}
        disabled={disabled}
        showViewPep={showViewPep}
        idFile={idFile}
        isEnterprise={isEnterprise}
      />
    </GiveCollapsableComponent>
  );
};

type TOwnerHeader = {
  firstName?: string;
  lastName?: string;
  ownership?: string;
  manualPepStatus?: PEPStatusType;
  withPepStatus: boolean;
  pepStatusName: PEPStatusType;
  pepStatusSetByUnderwriter?: boolean;
};

const OwnerHeader = ({
  firstName,
  lastName,
  ownership,
  withPepStatus,
  pepStatusName,
  pepStatusSetByUnderwriter,
  manualPepStatus,
}: TOwnerHeader) => {
  const { isDesktopView } = useCustomTheme();
  const { merchant_underwriting } = useEnterprisePermissions();
  const ownershipStr = ownership || "0";
  const ownerShipPercentage = parseFloat(parseFloat(ownershipStr)?.toFixed(2));
  const { isMerchantPortal } = checkPortals();

  const showStatus =
    withPepStatus &&
    isDesktopView &&
    merchant_underwriting &&
    !isMerchantPortal;

  return (
    <Stack
      direction="row"
      flexGrow={1}
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      gap={2}
    >
      <Stack direction="column" gap="8px" alignItems="left" overflow="hidden">
        <GiveText color="primary">{`${firstName} ${lastName}`}</GiveText>
        <GiveText color="secondary">{ownerShipPercentage}% Ownership</GiveText>
      </Stack>
      {showStatus && (
        <GivePepStatusChip
          ownerPepStatus={pepStatusName}
          manualPepStatus={manualPepStatus}
          pepStatusSetByUnderwriter={pepStatusSetByUnderwriter}
        />
      )}
    </Stack>
  );
};

type DataItem = { title: string; value: string; hidden?: boolean };

interface IOwnerBody {
  owner: TBusinessOwner;
  onEdit: (event: React.MouseEvent<HTMLElement>) => void;
  withPepStatus?: boolean;
  disabled?: boolean;
  showViewPep?: boolean;
  idFile?: TMerchantDocument | IFileWithMeta;
  merchantId: number;
  isEnterprise?: boolean;
  handleViewPep?: () => void;
}

const OwnerBody = ({
  owner,
  onEdit,
  disabled,
  handleViewPep,
  withPepStatus = false,
  showViewPep = true,
  idFile,
  merchantId,
  isEnterprise = false,
}: IOwnerBody) => {
  const { palette } = useAppTheme();
  const { merchant_underwriting } = useEnterprisePermissions();

  const isSSN = owner?.ssn;
  const ownership = !isNaN(parseFloat(owner.ownership))
    ? parseFloat(owner.ownership) % 1 === 0
      ? parseInt(owner.ownership)
      : parseFloat(owner.ownership).toFixed(2)
    : owner.ownership;

  const { countryOfResidence, citizenship } = owner;

  const items: DataItem[] = [
    {
      title: "Full Name",
      value: `${owner.firstName} ${owner.lastName}`,
    },
    {
      title: "Email",
      value: owner.email,
    },
    {
      title: "Ownership",
      value: `${ownership ?? 0}%`,
    },
    {
      title: isSSN ? "SSN" : "EIN",
      value: isSSN
        ? formatSSN("ssn", owner?.ssn?.slice(-4) || "")
        : formatSSN("ein", owner?.ein?.slice(-4) || ""),
    },
    {
      title: "Date of Birth",
      value: owner.dob ? moment(owner.dob).format("MMM DD yyyy") : "",
    },
    {
      title: "Business Owner Phone",
      value: isEmptyPhone(owner?.phone) ? "" : owner?.phone,
    },
    {
      title: "Country of Citizenship",
      value: `${countryOfResidence}`,
    },
    {
      title: "Country of Residence",
      value: `${citizenship}`,
    },
  ];

  const permissionResource = composePermission(
    isEnterprise ? RESOURCE_BASE.ENTERPRISE : RESOURCE_BASE.MERCHANT,
    RESOURCE_BASE.LEGAL_ENTITY,
    RESOURCE_BASE.PRINCIPAL,
    RESOURCE_BASE.PEP,
  );

  const isPEPAllowed = useAccessControl({
    resource: permissionResource,
    operation: OPERATIONS.LIST,
  });

  const showPEP =
    showViewPep && withPepStatus && merchant_underwriting && isPEPAllowed;

  return (
    <Stack spacing={0.5} width={"100%"}>
      {items
        .filter((entry) => !entry.hidden)
        .map((item, index) => (
          <DetailsItem key={index} item={item} highlight={index % 2 !== 0} />
        ))}
      <Stack
        direction="row"
        justifyContent="space-between"
        gap="10px"
        paddingX="16px"
      >
        {!disabled && (
          <CustomButton
            lable="Edit"
            onClick={(event) => onEdit(event)}
            endIcon={<PencilSimple size={14} />}
          />
        )}
        {showPEP && (
          <CustomButton
            lable="View PEP"
            onClick={handleViewPep}
            endIcon={<ArrowRight size={14} />}
          />
        )}
      </Stack>
      {idFile && (
        <Stack padding={2} paddingTop={0}>
          <Divider
            sx={{ backgroundColor: palette?.border?.primary, my: "16px" }}
          />
          <GiveText marginBottom="8px" variant="bodyS">
            Identity Verification
          </GiveText>
          <BOIDDisplay merchantId={merchantId} idFile={idFile} owner={owner} />
        </Stack>
      )}
    </Stack>
  );
};

const CustomButton = ({
  lable,
  onClick,
  endIcon,
}: {
  lable: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  endIcon: ReactNode;
}) => (
  <GiveButton
    sx={{ width: "100%", border: "none" }}
    onClick={onClick}
    label={lable}
    endIcon={endIcon}
    variant="filled"
    color="light"
    size="small"
  />
);
const DetailsItem = ({
  item,
  highlight,
}: {
  item: ISectionItem;
  highlight: boolean;
}) => {
  const { palette } = useAppTheme();

  return (
    <Box padding="16px 16px 0px 32px">
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: highlight ? "inherit" : palette.surface?.secondary,
        }}
      >
        <GiveSectionItem item={item} />
      </Grid>
    </Box>
  );
};

export default memo(GiveBusinessOwnerItem);

const formatSSN = (type: "ssn" | "ein", value: string) => {
  const prefix = type === "ssn" ? "XXX-XX-" : "XX-XXX";
  return prefix + value.toLowerCase().replaceAll("x", "");
};
