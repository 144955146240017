import NiceModal from "@ebay/nice-modal-react";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import GiveText from "@shared/Text/GiveText";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import {
  CheckCircle,
  CreditCard,
  Lock,
  Storefront,
} from "@phosphor-icons/react";
import { Box } from "@mui/material";
import { styled, useAppTheme } from "@theme/v2/Provider";
import { Stack } from "@mui/system";
import GivePayment from "@assets/icons/GivePayment";
import SslPciIcon from "@assets/icons/SslPciIcon";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import GiveDivider from "@shared/GiveDivider/GiveDivider";
import { useGetMerchantById } from "@hooks/enterprise-api/account/useGetMerchants";

const feeItems = [
  {
    Icon: CheckCircle,
    color: "success",
    title: "No Platform Fee:",
    description:
      " GivePayments charges $0.00 (yes, that is zero) platform fee. The only platform that doesn't charge an additional platform fee on top of the credit card fee.",
  },
  {
    Icon: CreditCard,
    color: "default",
    title: "Credit and Debit Card Fees:",
    description:
      "Credit card processing fee is 2.9% the amount plus 29 cents per transaction. Debit card processing fee is 1.5% the amount plus 29 cents per transaction. American Express processing fee is 1.0% the amount plus 35 cents per transaction.",
  },
  {
    Icon: Storefront,
    color: "default",
    title: "Organisation Fee:",
    description:
      "The Organization can add an optional fee to cover their processing expenses. {Provider_name} fee is 0.0% on credit cards, 1.0% on debit cards, and 1.0% on American Express credit card.",
  },
];

//TODO: to be integrated when new checkout page is ready
const CreditCardFeesModal = NiceModal.create(() => {
  const { open, onClose } = useNiceModal();
  const { palette } = useAppTheme();
  const { isMobileView } = useCustomThemeV2();
  const { data: merchantData } = useGetMerchantById();

  return (
    <GiveBaseModal
      open={open}
      title="Credit Card and Platform Fees"
      width="600px"
      height={isMobileView ? "700px" : "450px"}
      onClose={onClose}
      showFooter={false}
      sx={{
        "& .MuiDialogContent-root": {
          padding: "24px !important",
        },
      }}
    >
      <FeesContainer>
        {feeItems.map(({ Icon, color, title, description }, index) => (
          <FeesItem key={index}>
            <IconFrame color={color}>
              <Icon
                size={16}
                color={
                  color === "success"
                    ? palette.primitive?.success?.[50]
                    : palette.icon?.["icon-primary"]
                }
              />
            </IconFrame>
            <Box>
              <GiveText
                variant="bodyS"
                fontWeight={573}
                color="primary"
                component="span"
              >
                {title}{" "}
              </GiveText>
              <GiveText variant="bodyS" color="secondary" component="span">
                {description.replace(
                  "{Provider_name}",
                  merchantData.parentName || "Provider",
                )}
              </GiveText>
            </Box>
          </FeesItem>
        ))}
      </FeesContainer>
      <GiveDivider />
      <FooterContainer>
        <GivePayment />
        <Stack
          gap={"12px"}
          alignItems={isMobileView ? "start" : "end"}
          direction="column"
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Lock size={20} />
            <GiveText variant="bodyS" color="secondary">
              Transaction Secured and Encrypted
            </GiveText>
          </Box>
          <SslPciIcon />
        </Stack>
      </FooterContainer>
    </GiveBaseModal>
  );
});

export default CreditCardFeesModal;

const FeesContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  alignItems: "center",
  alignContent: "center",
  gap: "24px",
}));

const FeesItem = styled(Box)(() => ({
  display: "flex",
  alignItems: "start",
  gap: "16px",
}));

const IconFrame = styled(Box)<{ color?: string }>(({ color, theme }) => ({
  backgroundColor:
    color === "success"
      ? theme.palette.primitive?.success[25]
      : theme.palette.primitive?.transparent?.["darken-5"],
  borderRadius: "50%",
  height: "36px",
  width: "36px",
  minWidth: "36px",
  minHeight: "36px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
}));

const FooterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: "24px",
    alignItems: "start",
  },
}));
