import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import NiceModal from "@ebay/nice-modal-react";
import { Stack } from "@mui/material";
import { Grid } from "@mui/material";
import { Check, X } from "@phosphor-icons/react";
import GiveButton from "@shared/Button/GiveButton";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import GiveText from "@shared/Text/GiveText";
import { styled } from "@theme/v2/Provider";
import { useNavigate } from "react-router-dom";

export const SaveDraftModal = NiceModal.create((props: any) => {
  const { onClose, TransitionProps, SlideProps, open, modal } = useNiceModal();
  const navigate = useNavigate();

  const handleCancel = () => modal.hide();

  const onConfirm = () => {
    handleCancel();
    navigate("/merchant/payment-forms");
  };

  return (
    <ModalFactory
      variant="dialog"
      modalProps={{
        open,
        onClose,
        DrawerProps: {
          SlideProps,
          PaperProps: {
            sx: {
              "&.MuiPaper-root": {
                padding: "20px",
                gap: "16px",
              },
            },
          },
        },
        DialogProps: {
          TransitionProps,
          width: "440px",
          contentContainerSx: {
            padding: "20px",
            gap: "16px",
          },
          PaperProps: {
            "& .MuiPaper-root": {},
          },
          scroll: "paper",
        },
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <div
          style={{
            background: "linear-gradient(223.56deg, #E8F9FC 0%, #E9E8F7 100%)",
            display: "flex",
            padding: "12px",
            width: "48px",
            height: "48px",
            borderRadius: "30px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Color not present in theme.Waiting for UX team answer */}
          <Check color="#2860B2" />
        </div>
        <GiveIconButton
          Icon={X}
          size="small"
          variant="ghost"
          sx={{
            display: "flex",
            gap: 1,
          }}
          onClick={() => handleCancel()}
        />
      </Grid>
      <Stack gap={2}>
        <GiveText fontSize="24px" fontWeight={350}>
          Save as draft?
        </GiveText>
        <GiveText
          color="secondary"
          fontSize="14px"
          fontWeight={400}
          lineHeight="20px"
        >
          Do you want to save this payment form as a draft? If you don’t save
          it, any information added will be lost.
        </GiveText>
      </Stack>
      <Grid container justifyContent="flex-end" alignItems="center">
        <ReButton
          size="large"
          variant="ghost"
          label="Don’t Save"
          onClick={onConfirm}
        />
        <GiveButton size="large" label="Yes, Save" onClick={onConfirm} />
      </Grid>
    </ModalFactory>
  );
});

const ReButton = styled(GiveButton)(({ theme }) => ({
  color: theme.palette.primitive?.error[100],
}));
