import { IDropzoneProps } from "react-dropzone-uploader";
import { useEffect, useRef, useState } from "react";
import { TImportedRow } from "../types";
import useLocalUpload from "@components/UploadFile/hooks/useLocalUpload";
import { parseCsv } from "../utils/parseCsv";
import { isEmpty } from "lodash";

const isCsv = (file: File) => file.type === "text/csv";

const localErrorStates = [
  "rejected_file_type",
  "rejected_max_files",
  "error_file_size",
  "error_validation",
  "error_upload_params",
  "aborted",
  "error_upload",
];
const MIN_UPLOAD_TIME = 1500;

const useCsvImport = () => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { uploadFile, onUploadSuccess, onUploadError } = useLocalUpload();

  const importedRows = useRef<TImportedRow[]>([]);

  useEffect(() => {
    return () => {
      setUploadedFile(null);
      importedRows.current = [];
      setIsLoading(false);
    };
  }, []);

  const onError = (fileName: string) => {
    setUploadedFile(null);
    importedRows.current = [];
    onUploadError(fileName);
    setIsLoading(false);
  };

  // asynchronous validation for upload feedback
  const validateCsv = (file: File) => {
    if (!isCsv(file)) {
      onError(file.name);
      return;
    }

    const parsePromise = new Promise((resolve, reject) => {
      parseCsv(
        file,
        () => reject(),
        (element) => importedRows.current.push(element),
      );

      resolve(undefined);
    });

    const minUploadPromise = new Promise((resolve) => {
      setTimeout(resolve, MIN_UPLOAD_TIME);
    });

    Promise.allSettled([parsePromise, minUploadPromise])
      .then(() => {
        setUploadedFile(file);
        onUploadSuccess(file.name);
        setIsLoading(false);
      })
      .catch(onError);
  };

  const handleChangeStatus: IDropzoneProps["onChangeStatus"] = (
    { file, remove },
    status,
  ) => {
    if (!isEmpty(importedRows.current)) {
      remove();
    }
    setIsLoading(true);
    uploadFile(file);

    if (file.name === uploadedFile?.name) {
      if (remove) remove();
      onError(file.name);
      return;
    }

    if (localErrorStates.includes(status)) {
      if (remove) remove();
      onError(file.name);
    } else if (status === "done") {
      validateCsv(file);
      if (remove) remove();
    }
  };

  const handleReplace = (file: File) => {
    importedRows.current = [];

    if (isLoading) return;
    uploadFile(file);
    setIsLoading(true);
    validateCsv(file);
  };

  return {
    handleChangeStatus,
    handleReplace,
    uploadedFile,
    importedRows: importedRows.current,
  };
};

export default useCsvImport;
