import { getJsonValue } from "@theme/v2/utils";
import { ThemeMode } from "@theme/v2/types";

declare module "@mui/material/Drawer" {
  interface DrawerProps {
    type?: "sidepanel" | "sidemenu";
  }
}

export const getDrawerV2DefaultProps = (mode: ThemeMode)=>({
  componentsProps: {
    backdrop: {
      sx: {
        backgroundColor: getJsonValue(`tokens.${mode}.colour.surface.overlay`),
      },
    },
  },
  PaperProps: {
    sx: {
      transition: "width 195ms ease-in",
      "@media (max-width:768px)": {
        width: "100% !important",
      },
    },
  },
})

export const getCustomDrawerStyle = (mode: ThemeMode) => {
  return {
    styleOverrides: {
      root: {
        variants: [
          {
            props: (props: any) => {
              return props.type === "sidemenu";
            },
            style: () => {
              return {
                "& .MuiDrawer-paper": {
                  width: "116px",
                  padding: "32px",
                  borderRight: `1px solid ${getJsonValue(
                    `tokens.${mode}.colour.border.primary`,
                  )}`,
                  backgroundColor: getJsonValue(
                    `tokens.${mode}.colour.surface.primary`,
                  ),
                },
              };
            },
          },
          {
            props: (props: any) => {
              return (
                props.open &&
                props.variant === "permanent" &&
                props.type === "sidemenu"
              );
            },
            style: ({ theme }: any) => {
              return {
                [theme.breakpoints.between("new_sm", "new_md")]: {
                  ".MuiPaper-root": {
                    width: 320,
                  },
                },
                [theme.breakpoints.up("new_md")]: {
                  ".MuiPaper-root": {
                    width: 264,
                  },
                },
              };
            },
          },
          {
            props: (props: any) => {
              return props.type === "sidepanel";
            },
            style: ({ theme }: any) => {
              return {
                ".MuiDrawer-paper": {
                  display: "flex",
                  backgroundColor: getJsonValue(
                    `tokens.${mode}.colour.surface.secondary`,
                  ),
                  [theme.breakpoints.down("new_sm")]: {
                    height: "96%",
                    borderTopRightRadius: "20px",
                    borderTopLeftRadius: "20px",
                    width: "100%",
                  },
                  overflow: "hidden",
                },
              };
            },
          },
        ],
      },
    },
  };
};
