import { Button } from "@common/Button";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import EditMerchantBaseModal from "../components/EditMerchantBaseModal";
import { TermsOfService } from "@pages/TermsOfService";
import { useUpdateMerchantInfo } from "../hooks/useUpdateMerchantInfo";
import { SIGN_AGREEMENT_MODAL } from "modals/modal_names";
import { Box } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { RHFCheckbox } from "@common/Checkbox";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import ScrollBottomButton from "@components/AcquirerEnterprises/EnterprisePreview/components/ScrollBottomButton";
import { useRef } from "react";
import { useScrollCheck } from "@components/ProfilePage/MerchantAgreementSetup/hooks/useScrollCheck";

type Props = {
  version: string;
  merchantId: number;
  addSignature?: (file: File) => void;
  signatureURL?: string;
};

const MerchantAgreementUpdate = NiceModal.create(
  ({ version, merchantId, addSignature, signatureURL }: Props) => {
    const { handleSubmit } = useUpdateMerchantInfo(merchantId);
    const { open, onClose, TransitionProps } = useNiceModal();
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { CheckboxWithTooltip, handleScroll } = useScrollCheck(false);

    const handleSign = () => {
      if (addSignature) {
        NiceModal.show(SIGN_AGREEMENT_MODAL, { addSignature });
        onClose();
        return;
      }

      handleSubmit(
        "merchant_agreement",
        { msaHadAgreed: true, msaPCICompliant: true, signatureURL },
        onClose,
      );
    };
    const methods = useForm({
      resolver: yupResolver(schema),
      defaultValues: {
        agree: false,
      },
    });
    const {
      formState: { isValid },
    } = methods;

    return (
      <EditMerchantBaseModal
        title={`Merchant Application Agreement${addSignature ? "" : " Update"}`}
        description={
          addSignature
            ? ""
            : "Updates have been made to the merchant agreement. Please read through the revised terms for the latest information"
        }
        open={open}
        handleCancel={onClose}
        TransitionProps={TransitionProps}
        actions={
          <>
            <Button size="medium" background="tertiary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={!isValid}
              size="medium"
              background="primary"
              onClick={handleSign}
            >
              Sign
            </Button>
          </>
        }
        sx={{
          "& .MuiPaper-root": {
            width: "640px !important",
            maxWidth: "640px !important",
          },
          "& .MuiDialogContent-root": {
            padding: 0,
          },
        }}
      >
        <FormProvider {...methods}>
          <Box overflow="hidden" height="100%" width="100%">
            <Box
              sx={{ overflowY: "scroll", overFlowX: "hidden" }}
              maxHeight="374.67px"
              p="24px 24px 0 24px"
              ref={containerRef}
              onScroll={handleScroll}
            >
              <TermsOfService
                merchantAgreementVersion={{
                  version: version,
                  lastUpdated: "June 10,2023",
                }}
              />
              <ScrollBottomButton containerRef={containerRef} />
            </Box>
            <Box py="24px" px="10px" width="100%">
              <CheckboxWithTooltip name="agree" />
            </Box>
          </Box>
        </FormProvider>
      </EditMerchantBaseModal>
    );
  },
);

export default MerchantAgreementUpdate;

const schema = Yup.object({
  agree: Yup.boolean()
    .required("Agree is required")
    .oneOf([true], "You must agree to the terms"),
});
