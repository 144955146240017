import useFindMerchantStatsById from "@hooks/acquirer-api/merchants/stats/useFindMerchantStatsById";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useAppDispatch } from "@redux/hooks";
import { updatePermissions } from "@redux/slices/app";
import { parseAmount } from "@utils/index";
import { useMemo, useRef } from "react";

export function useAvailableBalance() {
  
  const dispatch = useAppDispatch();

  const refValue = useRef(0);
  const { merchantId } = useGetCurrentMerchantId();
  const { data: stats } = useFindMerchantStatsById(merchantId, {
    enabled: !!merchantId,
    retry: 2,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError(err: any) {
      if (err.not_authorized) {
        dispatch(
          updatePermissions({
            merchant_stats: true,
          }),
        );
      }
    },
  });
  const value = useMemo(() => {
    let val = refValue.current;
    if (stats) {
      refValue.current = stats.availableBalance / 100;
      val = stats.availableBalance / 100;
    }
    return parseAmount(val);
  }, [stats]);

  return {
    availableBalance: value
  };
}
