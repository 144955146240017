import { SxProps } from "@mui/material";
import { GiveInput } from "@shared/GiveInputs/GiveInput";
import GiveText from "@shared/Text/GiveText";
import CopyButton from "features/Merchants/MerchantSidePanel/GiveMerchantFile/components/CopyButton";

const PublicInputShare = ({
    url,
    textProps,
  }: {
    url: string;
    textProps?: SxProps;
  }) => {
    return (
      <>
        <GiveText
          sx={{
            fontSize: "18px",
            ...textProps,
          }}
        >
          Public URL
        </GiveText>
        <GiveInput
          value={url}
          fullWidth
          sx={{
            height: "48px",
            "& .MuiInputBase-root": {
              borderRadius: "12px",
              height: "48px",
            },
          }}
          onChange={undefined}
          InputProps={{
            endAdornment: <CopyButton text={url} />,
          }}
        />
      </>
    );
  };

  export default PublicInputShare