import { lazy } from "react";
import { PUBLIC_FORM_PATHS } from "./paths";
import PaymentFormsPublicForm from "@components/PaymentForms/PublicPaymentForm/PaymentFormsPublicForm";
import PayBuilder from "@sections/PayBuilder/PayBuilder";

const EventsPublicForm = lazy(
  () => import("@components/Events/PublicPaymentForm/EventsPublicForm"),
);

const SweepstakesPublicForm = lazy(
  () =>
    import("@components/Sweepstakes/PublicPaymentForm/SweepstakesPublicForm"),
);

const FundraisersPublicForm = lazy(
  () =>
    import("@components/Fundraisers/PublicPaymentForm/FundraisersPublicForm"),
);

const InvoicesPublicForm = lazy(
  () => import("@components/Invoices/PublicPaymentForm/InvoicesPublicForm"),
);

const MembershipsPublicForm = lazy(
  () =>
    import("@components/Memberships/PublicPaymentForm/MembershipsPublicForm"),
);

const ProductPublicForm = lazy(() => import("@containers/ProductPublicForm"));

const publicFormRoutesList = [
  {
    path: PUBLIC_FORM_PATHS.EVENTS,
    element: <EventsPublicForm />,
  },
  {
    path: PUBLIC_FORM_PATHS.SWEEPSTAKES,
    element: <SweepstakesPublicForm />,
  },
  {
    path: PUBLIC_FORM_PATHS.INVOICES,
    element: <InvoicesPublicForm />,
  },
  {
    path: PUBLIC_FORM_PATHS.FUNDRAISERS,
    element: <FundraisersPublicForm />,
  },
  {
    path: PUBLIC_FORM_PATHS.MEMBERSHIPS,
    element: <MembershipsPublicForm />,
  },
  {
    path: PUBLIC_FORM_PATHS.PRODUCT,
    element: <ProductPublicForm />,
  },
  {
    path: PUBLIC_FORM_PATHS.PAYMENT_FORMS,
    element: <PaymentFormsPublicForm />,
  },
  {
    path: PUBLIC_FORM_PATHS.PUBLIC_PRODUCT,

    element: <PayBuilder />,
  },
];

export default publicFormRoutesList;
