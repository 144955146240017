import NiceModal from "@ebay/nice-modal-react";
import { palette } from "@palette";
import { Text } from "@common/Text";
import { UnderwritingPopupBase } from "../../modals/ApproveMerchantPopup";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { Stack } from "@mui/material";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import MultiLineInput from "@common/Input/MultiLineInput";

type Props = {
  handleMarkAsPending: (cb?: () => void, reason?: string) => void;
  name: string;
};

type FormFields = {
  reason: string;
};

const defaultValues = { reason: "" };

const MarkMerchantAsPendingModal = NiceModal.create(
  ({ handleMarkAsPending, name }: Props) => {
    const schema = Yup.object().shape({
      reason: Yup.string().required("Name is required").trim(),
    });

    const methods = useForm<FormFields>({
      resolver: yupResolver(schema),
      defaultValues,
    });
    const { watch } = methods;
    const { reason } = watch();

    const props = {
      modalTitle: "Mark Merchant as Pending",
      submitLabel: "Confirm",
      children: (
        <Stack spacing={2.5} sx={{ overflow: "hidden" }}>
          <Text
            fontWeight="book"
            color={palette.neutral[70]}
            lineHeight="16.8px"
          >
            Are you sure you want to mark{" "}
            <span style={{ color: palette.neutral[100] }}>{name}</span> as
            pending? This action will finalize the approval status and cannot be
            undone.
          </Text>
          <FormProvider {...methods}>
            <FadeUpWrapper delay={100}>
              <MultiLineInput
                name="reason"
                label="Reason"
                placeholder="Reason"
                rows={6}
              />
            </FadeUpWrapper>
          </FormProvider>
        </Stack>
      ),
    };

    return (
      <UnderwritingPopupBase
        handleSubmit={(cb?: () => void) => handleMarkAsPending(cb, reason)}
        primaryButtonBackground={palette.primary.main}
        actionSx={{ fontSize: 18 }}
        {...props}
      />
    );
  },
);

export default MarkMerchantAsPendingModal;
