import { HorizontalMoreIcon } from "@assets/icons/RebrandedIcons";
import { useMobileDrawerActions } from "@components/MobileDrawerActions/hooks/useMobileDrawerActions";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { Grid, Stack, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { palette } from "@palette";
import DisputeCaseSummary from "../components/DisputeCaseSummary";
import useExtraActions from "../hooks/useExtraActions";
import { TDisputeData } from "../data.types";

type Props = {
  data: TDisputeData;
};

const DisputePreviewMobileBody = ({ data }: Props) => {
  const { BottomActions } = useMobileDrawerActions();
  const classes = useStyles();
  const { mobileActions } = useExtraActions(data);

  return (
    <Grid width="100%" className={classes.bodyWrapper}>
      <Stack direction="row" spacing={1} alignItems="center">
        <FadeUpWrapper delay={100}>
          <DisputeCaseSummary isMobileView data={data} />
        </FadeUpWrapper>
      </Stack>
      {mobileActions.filter((x) => !x.hidden).length > 0 && (
        <BottomActions
          sx={{
            "& .MuiPaper-root": {
              padding: 0,
              bottom: "100px",
            },
          }}
          label={
            <HorizontalMoreIcon
              height={32}
              width={32}
              fill={palette.common.white}
            />
          }
          items={mobileActions}
          buttonSx={{
            position: "fixed",
            bottom: "15px",
            left: 0,
            right: 0,
          }}
        />
      )}
    </Grid>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  bodyWrapper: {
    flexGrow: 1,
    overflowY: "auto",
    scrollbarGutter: "stable both-edges",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      paddingBottom: "89px",
    },
  },
}));

export default DisputePreviewMobileBody;
