import { SxProps } from "@mui/material";
import { GiveInput } from "@shared/GiveInputs/GiveInput";
import { useAppTheme } from "@theme/v2/Provider";
import CustomActions from "./components/CustomActions";
import CustomToolbar from "./components/CustomToolbar";
import { CaretDown, CaretLeft, CaretRight } from "@phosphor-icons/react";
import { format, parse } from "date-fns";
import { useForm, Controller } from "react-hook-form";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { CustomPalette } from "@theme/v2/palette.interface";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useState } from "react";

type FormInputs = {
  date: string;
};

type TGiveDatePicker = {
  /** Label for the date picker input */
  label?: string;
  /** Callback function called when the date changes. It can be used to get the selected date */
  onSetDate: (date: any) => void;
  /** Minimum selectable date */
  minDate?: any;
  /** Maximum selectable date */
  maxDate?: any;
  /** If true, disables selection of future dates */
  disableFuture?: boolean;
  /** If true, disables selection of past dates */
  disablePast?: boolean;
  /** Custom styles for the text field */
  textFieldSx?: SxProps;
  /** Custom styles for the dialog component (used in popup variant) */
  customDialogSx?: any;
  /** If true, disables the date picker */
  disabled?: boolean;
  /** Placeholder for the date picker input */
  placeholder?: string;
};

const getDialogSx = (palette: CustomPalette, isMobileView: boolean) => ({
  "& .MuiDialog-paper": {
    backgroundImage: "none",
    boxShadow: "none",
    backgroundColor: palette.surface?.primary,
    borderRadius: "20px",
    padding: "0 12px 12px 0px",
    ...(!isMobileView && {
      width: "472px",
      minHeight: "542px",
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      "& .MuiBox-root": {
        padding: "0px",
      },
    }),
    "& .MuiPickersCalendarHeader-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: isMobileView ? "24px 16px 24px 24px" : "0",
      "& .MuiPickersCalendarHeader-label": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "20px",
        color: palette.text?.primary,
      },
      "& .MuiPickersArrowSwitcher-root": {
        "& .MuiIconButton-root": {
          color: palette.text?.primary,
        },
        "& .MuiPickersArrowSwitcher-spacer": {
          width: "8px",
        },
        "& .Mui-disabled": {
          color: palette.text?.disabled,
        },
      },
    },
    "& .MuiPickersDay-root": {
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: palette.surface?.tertiary,
      },
      "&.Mui-selected": {
        backgroundColor: palette.primitive?.neutral[100],
      },
    },
    "& .MuiPickersDay-today": {
      backgroundColor: palette.surface?.tertiary,
      border: "none",
    },
    "& .MuiYearPicker-root": {
      display: "flex",
      flexDirection: "row",
      overflowY: "scroll",
      rowGap: "12px",
      "&::-webkit-scrollbar": {
        width: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: palette.primitive?.neutral[110],
        borderRadius: "10px",
      },
      "& .PrivatePickersYear-root": {
        flexBasis: "33.33%",
        "& .PrivatePickersYear-yearButton": {
          width: "100%",
          height: "100%",
          fontSize: "12px",
          lineHeight: "14px",
          fontWeight: 400,
          paddingY: "8px",
          borderRadius: "40px",
          color: palette.text?.primary,
        },
        "& .Mui-selected": {
          backgroundColor: palette.primitive?.neutral[100],
          color: palette.primitive?.neutral[0],
        },
      },
    },
    "& .MuiCalendarPicker-root": {
      width: "100%",
      overflow: "hidden",
    },
    ...(!isMobileView && {
      "& .MuiCalendarOrClockPicker-root": {
        gap: "8px",
        "& > div": {
          width: "100%",
        },
      },
      "& .MuiDialogActions-root": {
        padding: "0",
      },
      "& .MuiCalendarPicker-root": {
        width: "100%",
        overflow: "hidden",
        "& .MuiDayPicker-header": {
          justifyContent: "space-around",
        },
      },
      "& .MuiDayPicker-slideTransition": {
        minHeight: "400px",
        overflow: "hidden",
      },
      "& .MuiDayPicker-weekContainer": {
        width: "100%",
        justifyContent: "space-around",
        "& .MuiPickersDay-root": {
          width: "48px",
          height: "48px",
        },
      },
    }),
  },
});

const GiveDatePicker = ({
  label,
  onSetDate,
  minDate,
  maxDate,
  disableFuture,
  disablePast,
  textFieldSx,
  customDialogSx,
  disabled,
  placeholder = "mm/dd/yyyy",
}: TGiveDatePicker) => {
  const { palette } = useAppTheme();
  const { isMobileView } = useCustomThemeV2();
  const { control } = useForm<FormInputs>();
  const [tempValue, setTempValue] = useState<string | null>("");

  const handleClickAccept = (value: any) => {
    setTempValue(value);
    onSetDate(value);
  };

  return (
    <Controller
      name="date"
      control={control}
      render={({ field: { ref, ...rest }, fieldState: { error } }) => (
        <MobileDatePicker
          {...rest}
          label={label}
          onChange={(newValue) => {
            rest.onChange(newValue);
          }}
          value={rest.value}
          inputRef={ref}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
          disableFuture={disableFuture}
          disablePast={disablePast}
          closeOnSelect={false}
          showToolbar
          renderInput={({ inputProps, ...params }) => {
            return (
              <GiveInput
                inputRef={ref}
                variant="outlined"
                error={!!error}
                helperText={error?.message}
                disabled={disabled}
                sx={{
                  ...textFieldSx,
                }}
                inputProps={{
                  ...inputProps,
                  placeholder: placeholder,
                  value: tempValue,
                }}
                {...params}
              />
            );
          }}
          DialogProps={{
            sx: {
              ...getDialogSx(palette, isMobileView),
              ...customDialogSx,
            },
          }}
          components={{
            ActionBar: (props) => (
              <CustomActions
                setValue={handleClickAccept}
                value={rest.value}
                {...props}
              />
            ),
            LeftArrowIcon: () => <CaretLeft size={20} />,
            RightArrowIcon: () => <CaretRight size={20} />,
            SwitchViewIcon: () => <CaretDown size={20} />,
          }}
          ToolbarComponent={() => (
            <CustomToolbar date={rest.value || new Date()} />
          )}
          dayOfWeekFormatter={(weekday) =>
            format(parse(weekday, "EEEE", new Date()), "EEEEEE")
          }
          disableOpenPicker={!!error}
        />
      )}
    />
  );
};

export default GiveDatePicker;
