import React from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
// components
import { useParams } from "react-router-dom";
import { customInstance } from "../../services/api";
import { useMutation } from "react-query";
import { showMessage } from "@common/Toast/ShowToast";
import { useMediaQuery, useTheme } from "@mui/material";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import { PASSWORD_MIN_CHARS } from "@constants/constants";
import { useLogout, useResetApp } from "@hooks/common/useHandleLogout";
import { useNavigate } from "react-router-dom";

type IFormInputs = {
  password: string;
  confirmPassword: string;
  termsConditions: boolean;
};

export const useChangePassword = (isToS?: boolean) => {
  const theme = useTheme();
  const user = useAppSelector(selectUser);
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();

  const { id } = useParams();
  const [alert, setAlert] = React.useState("");

  const { mutateAsync } = useLogout();
  const { handleReset } = useResetApp();

  const schema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .test({
        name: "validator-custom-password",
        test: function (value) {
          return (value || "").length < PASSWORD_MIN_CHARS
            ? this.createError({
                message: `Enter ${
                  PASSWORD_MIN_CHARS - (value || "").length
                } more characters`,
                path: "password",
              })
            : true;
        },
      })
      .matches(/[0-9]/, "Password must contain at least one number")
      .matches(/[a-z]/, "Must contain at least one lowercase character")
      .matches(/[A-Z]/, "Must contain at least one uppercase character")
      .matches(/[!-/:-@[-`{-~]/, "Must contain at least one special character"),
    confirmPassword: Yup.string().required("Confirm password is required"),
    termsConditions: Yup.boolean().when([], {
      is: () => isToS,
      then: Yup.boolean().oneOf(
        [true],
        "Agree to terms and conditions is required",
      ),
    }),
  });

  const defaultValues = {
    password: "",
    confirmPassword: "",
    termsConditions: true,
  };

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues,
  });

  const changePwdMutation = useMutation(
    (data: { password: string; hasAcceptedTC?: boolean }) => {
      return customInstance({
        url: `password-resets/${id}`,
        method: "POST",
        data,
        withCredentials: false,
      });
    },
  );

  const { isLoading, isSuccess } = changePwdMutation;

  const handleLogout = async () => {
    try {
      await mutateAsync();
      handleReset();
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    if (methods.formState.isDirty) {
      setAlert("");
    }
  }, [methods.formState.isDirty]);

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    const customData = {
      password: data.password,
      ...(isToS && {
        hasAcceptedTC: data.termsConditions,
      }),
    };

    changePwdMutation.mutate(customData, {
      onSuccess: async () => {
        showMessage("Success", "Password set successful", isDesktop);
        if (user) {
          handleLogout();
        } else {
          navigate("/login");
        }
      },
      onError: (error: any) => {
        const usedPasswordMessage =
          "New password must not match the previous five.";
        if (error.response?.data?.message === usedPasswordMessage) {
          setAlert(usedPasswordMessage);
          methods.reset(data);
          return;
        }
        showMessage(
          "Error",
          error?.response?.data?.message ||
            "The provided password reset request is invalid and/or expired",
          isDesktop,
        );
      },
    });
  };

  return { methods, onSubmit, isDisabled: isLoading || isSuccess, alert };
};
