import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { Text } from "@common/Text";
import NiceModal from "@ebay/nice-modal-react";
import { Box, Stack } from "@mui/material";
import { Warning } from "@phosphor-icons/react";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { isFunction } from "lodash";

const SendInvitationsBase = ({
  total,
  handleSubmit,
}: {
  total: number;
  handleSubmit: () => void;
}) => {
  const { open, onClose, SlideProps, TransitionProps } = useNiceModal();

  const { isMobileView } = useCustomTheme();
  const onHandleSubmit = () => {
    if (isFunction(handleSubmit)) handleSubmit();
    onClose();
  };
  const handleOnCloseLoading = () => {
    onClose();
  };
  return (
    <ModalFactory
      variant="dialog"
      modalProps={{
        open,
        onClose: handleOnCloseLoading,
        width: "550px",
        SlideProps,
        DialogProps: {
          PaperProps: {
            sx: {
              padding: "24px 24px 16px",
            },
          },
          TransitionProps,
        },
      }}
    >
      <ModalTitle
        title="Send Invitations"
        description={`You are about to send ${total} invitations. Please confirm to continue.`}
        textStyles={{
          title: {
            fontSize: "18px",
            lineHeight: "21.6px",
            letterSpacing: "-0.01em",
          },
        }}
        closeButtonSize={22}
        onClose={handleOnCloseLoading}
      />
      <Box
        p="12px 16px"
        height="100%"
        bgcolor="#FFF2E9"
        width="100%"
        borderRadius="12px"
        mt="20px"
      >
        <Stack mb="9px" alignItems="flex-end" gap="8px" flexDirection="row">
          <Warning size={22} fill="#FF8124" />
          <Text fontWeight="regular" color="#FF8124" fontSize="18px">
            Sending may Take Time
          </Text>
        </Stack>
        <Text color="#C3641D" fontWeight="book" fontSize="14px">
          {warningText}
        </Text>
      </Box>
      <ModalActions
        animationDelay={80}
        containerProps={{
          sx: {
            paddingTop: "20px",
            ...(isMobileView && {
              flexDirection: "column-reverse",
            }),
          },
        }}
        secondaryAction={{
          label: "Cancel",
          onClick: handleOnCloseLoading,

          sx: {
            fontSize: "18px",
            lineHeight: "21.6px",
            padding: "12px 24px",
          },
        }}
        primaryAction={{
          type: "button",
          onClick: onHandleSubmit,
          label: "Yes, Send",

          sx: {
            fontSize: "18px",
            lineHeight: "21.6px",
            padding: "12px 24px",
          },
        }}
      />
    </ModalFactory>
  );
};

const SendInvitationsModal = NiceModal.create(SendInvitationsBase);
export default SendInvitationsModal;

const warningText = `Processing may take some time. You won't be able to perform another
          action until it's complete. We'll notify you once it's done.`;
