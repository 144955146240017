import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { Button } from "@common/Button";
import { styled } from "@mui/material";
import NiceModal from "@ebay/nice-modal-react";
import { SUSPEND_MERCHANT_MODAL } from "modals/modal_names";
import { TStatusReview } from "../../data.types";
import { showMessage } from "@common/Toast";
import { useQueryClient } from "react-query";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { useState } from "react";
import { useUpdateUnderwriting } from "@components/Merchants/MerchantPreview/hooks/useUnderwritingUpdate";

type Props = {
  status?: TStatusReview;
};

const SuspendButton = (props: Props) => {
  const { status } = props;
  const queryClient = useQueryClient();
  const { isUpdateUnderwriterAllowed } = useUnderwriterPermissions();
  const { risk_monitoring } = useEnterprisePermissions();

  const [isLoading, setIsLoading] = useState(false);

  const statusName = status?.statusName || "";
  const { isMobileView } = useCustomTheme();
  const title =
    statusName === "suspended" ? "Resume Account" : "Suspend Account";

  const showButton =
    ["approved", "suspended"].includes(statusName) &&
    !isMobileView &&
    risk_monitoring;

  const { updateUnderwriting } = useUpdateUnderwriting(status?.accID as number);

  const handlcClick = () => {
    if (statusName === "suspended") {
      setIsLoading(true);
      updateUnderwriting.mutate(
        { status: "approved" },
        {
          onSuccess: () =>
            queryClient.invalidateQueries("get-merchant-preview"),
          onError: (err: any) => {
            showMessage("Error", err?.response?.data?.message);
          },
          onSettled: () => {
            setIsLoading(false);
          },
        },
      );
      return;
    }

    NiceModal.show(SUSPEND_MERCHANT_MODAL, {
      handleSuspend: (cb?: () => void) => {
        updateUnderwriting.mutate(
          { status: "suspended" },
          {
            onSuccess: () =>
              queryClient.invalidateQueries("get-merchant-preview"),
            onError: (err: any) => {
              showMessage("Error", err?.response?.data?.message);
            },
            onSettled: () => {
              setIsLoading(false);
              if (cb) {
                cb();
              }
            },
          },
        );
      },
    });
  };

  if (showButton) {
    return (
      <SuspendButtonStyled
        className="suspend-button"
        disabled={!isUpdateUnderwriterAllowed || isLoading}
        background="text"
        onClick={handlcClick}
      >
        {title}
      </SuspendButtonStyled>
    );
  } else return null;
};

const SuspendButtonStyled = styled(Button)(({ theme }) => ({
  padding: "0px 8px",
  height: "fit-content",
  color: theme.palette.neutral[80],
  "&:hover": {
    background: "none",
    textDecoration: "underline",
  },
}));

export default SuspendButton;
