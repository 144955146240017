import React from "react";

export const addGradientToIcon = (
  gradientId: string,
  startColor: string,
  endColor: string,
  Icon: JSX.Element,
) => {
  const color = `url(#${gradientId})`;
  return (
    <>
      <svg width="0" height="0">
        <linearGradient id={gradientId} x1="100%" y1="100%" x2="0%" y2="0%">
          <stop stopColor={startColor} offset="0%" />
          <stop stopColor={endColor} offset="100%" />
        </linearGradient>
      </svg>
      {React.cloneElement(Icon, { color })}
    </>
  );
};

export const convertHexToRGB = (hexValue: string, opacity = 1) => {
  // Remove the hash at the start if it's there
  const hex = hexValue.replace(/^#/, "");

  // Parse the r, g, b values
  let r, g, b;

  // If the hex is 3 characters long, expand it to 6 characters
  if (hex.length === 3) {
    r = parseInt(hex[0] + hex[0], 16);
    g = parseInt(hex[1] + hex[1], 16);
    b = parseInt(hex[2] + hex[2], 16);
  } else {
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  }

  return `rgb(${r}, ${g}, ${b}, ${opacity})`;
};
