import { Stack } from "@mui/material";
import { Box } from "@mui/material";
import { DotsSixVertical, PencilSimple } from "@phosphor-icons/react";
import GiveAvatar from "@shared/Avatar/GiveAvatar";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import GiveText from "@shared/Text/GiveText";
import { styled, useAppTheme } from "@theme/v2/Provider";
import { ProductItemType } from "../types";
import { addSizeToImage } from "@utils/image.helpers";
import ImagePlaceholder from "@assets/images/thumbnail-placeholder.png";
import GiveSwitch from "@shared/Switch/GiveSwitch";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

type Props = {
  item: ProductItemType;
  handleUpdateItem: (item: ProductItemType) => void;
  handleEditItem: () => void;
};

const PaymentFormItem = ({ item, handleUpdateItem, handleEditItem }: Props) => {
  const { palette } = useAppTheme();
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: item?.id });

  const style = {
    zIndex: +isDragging,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const smallThumbnail = addSizeToImage(
    item?.thumbnail || "",
    "small",
    ImagePlaceholder,
  );

  return (
    <Container ref={setNodeRef} style={style}>
      <Stack direction="row" spacing={2.5} alignItems="center">
        <GiveAvatar size="64px" shape="square" imageUrl={smallThumbnail} />
        <Stack spacing={0.5}>
          <GiveText variant="bodyS" fontWeight="500">
            {item?.title}
          </GiveText>
          <GiveText variant="bodyS">${item?.amount}</GiveText>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <GiveIconButton
          Icon={PencilSimple}
          color={palette.icon?.["icon-primary"]}
          size="small"
          variant="ghost"
          className="edit-button"
          onClick={handleEditItem}
        />
        <GiveSwitch
          checked={item?.display || false}
          onChange={() =>
            handleUpdateItem({ ...item, display: !item?.display })
          }
        />
        <GiveIconButton
          Icon={DotsSixVertical}
          color={palette.icon?.["icon-secondary"]}
          size="small"
          variant="ghost"
          style={{ cursor: isDragging ? "grabbing" : "grab" }}
          {...attributes}
          {...listeners}
        />
      </Stack>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.border?.primary}`,
  padding: "16px 0",
  display: "flex",
  justifyContent: "space-between",

  "& .edit-button": {
    visibility: "hidden",
  },
  "&:hover .edit-button": {
    visibility: "visible",
  },
}));

export default PaymentFormItem;
