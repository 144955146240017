import { customInstance } from "@services/api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  MATCHDataType,
  MATCHFormFields,
  ReportFile,
  ReportType,
} from "../types";
import { showMessage } from "@common/Toast";
import { useUploadFiles } from "@hooks/upload-api/uploadHooks";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

const getMATCHReports = (merchantID?: number, reportID?: number) => {
  const url = `/merchants/${merchantID}/match-reports${
    reportID !== undefined ? "/" + reportID : "?sort=-createdAt" //TODO: check sorting if needed
  }`;
  return customInstance({
    url: url,
    method: "GET",
  });
};

export const useGetMATCHReports = (merchantID?: number) => {
  const { data, error, isLoading } = useQuery(
    ["list-match-reports", merchantID || ""],
    async () => {
      const reports = await getMATCHReports(merchantID);
      return reports?.data || [];
    },
    { enabled: Boolean(merchantID) },
  );

  return { error, isLoading, data: data as ReportType[] };
};

export const useGetSingleMATCHReport = (
  merchantID: number,
  reportID?: number,
) => {
  const { data, error, isLoading } = useQuery(
    ["match-report", merchantID, reportID || ""],
    async () => {
      const report = await getMATCHReports(merchantID, reportID);
      return report || [];
    },
    { enabled: Boolean(merchantID) && Boolean(reportID) },
  );

  return { error, isLoading, data: data as ReportType };
};

export const useCreateMATCHReport = ({
  merchantID,
  files,
}: {
  merchantID: number;
  files?: ReportFile[];
}) => {
  const { isDesktopView } = useCustomTheme();
  const { handleUpload: handleUploadEdit } = useUploadFiles();
  const queryClient = useQueryClient();

  const uploadFiles = async (
    merchantID: number,
    reportID: number,
    files: any,
  ) => {
    const uploadRes: string[] | "upload_failed" = await handleUploadEdit({
      list: files,
      attachmentType: "underwriting_match_report",
      merchantId: merchantID,
      resourceID: reportID,
      label: "underwriting match report",
      tag: "underwriting match report",
    });

    if (uploadRes === "upload_failed")
      showMessage("Error", "Evidence upload failed.", isDesktopView);
  };

  const onSuccessCallback = (data: ReportType) => {
    if (data && files) uploadFiles(merchantID, data.ID, files);
  };

  const updateOFACCheck = useMutation(
    ({ data, merchantID }: MATCHDataType) => {
      return customInstance({
        url: `/merchants/${merchantID}/match-reports`,
        method: "POST",
        data,
      });
    },
    {
      onSuccess: (data: ReportType) => {
        onSuccessCallback(data);
      },
      onError: (error: any) => {
        showMessage(
          "Error",
          error?.message || error?.response?.status,
          true,
          "Something went wrong...",
        );
      },
      onSettled: () => {
        queryClient.refetchQueries(["list-match-reports", merchantID]);
        queryClient.invalidateQueries("get-merchant-preview");
      },
    },
  );

  const handleSubmit = ({ data }: { data: MATCHFormFields }) => {
    updateOFACCheck.mutate({ data, merchantID });
  };

  return {
    handleSubmit,
  };
};
