import { Text } from "@common/Text";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { Stack } from "@mui/material";
import { palette } from "@palette";
import EvidenceDocumentList from "./EvidenceDocumentList";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import { TCaseEvidence } from "../../data.types";
import { useMemo } from "react";
import { bytesToSize } from "@utils/index";

type Props = {
  data: TCaseEvidence[];
  caseAction?: string;
  caseNotes?: string;
};

const EvidenceProvidedBody = ({ data, caseAction, caseNotes }: Props) => {
  const documentList = useMemo(() => {
    const filteredDocs = data?.filter((doc) => !!doc?.fileURL);

    return filteredDocs?.map((doc) => {
      const { sizeString } = bytesToSize(doc?.fileSize || 0);

      return {
        fileName: doc?.fileName,
        fileURL: doc?.fileURL,
        fileType: doc?.fileType,
        fileSize: sizeString,
        notes: doc?.notes,
      };
    }) as TMerchantDocument[];
  }, [data]);

  const sectionsList = [
    { title: "Case Action", value: caseAction },
    {
      title: "Case Notes",
      value: caseNotes,
    },
    {
      title: "Documents",
      value: <EvidenceDocumentList documentList={documentList} />,
    },
  ];

  return (
    <FadeUpWrapper delay={50}>
      <Stack px={3} pb={3} spacing={1.5}>
        {sectionsList.map((section) => (
          <Section
            key={section.title}
            title={section.title}
            value={section.value}
          />
        ))}
      </Stack>
    </FadeUpWrapper>
  );
};

type SectionProps = {
  title: string;
  value: string | React.ReactNode;
};

const Section = ({ title, value }: SectionProps) => {
  return (
    <Stack spacing={0.5}>
      <Text color={palette.neutral[70]} fontWeight="light" lineHeight="16.8px">
        {title}
      </Text>
      {typeof value === "string" ? (
        <Text color={palette.neutral[90]} lineHeight="16.8px">
          {value}
        </Text>
      ) : (
        value
      )}
    </Stack>
  );
};

export default EvidenceProvidedBody;
