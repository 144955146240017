import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Stack,
} from "@mui/material";
import { CaretDown, CreditCard, ListChecks } from "@phosphor-icons/react";
import GiveDivider from "@shared/GiveDivider/GiveDivider";
import SectionCardBase from "@shared/SidePanel/components/SectionCard/SectionCardBase";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme } from "@theme/v2/Provider";
import useSnapShot from "features/Merchants/MerchantSidePanel/hooks/useSnapShot";

type FeeDetails = {
  label: string;
  value: string | number | JSX.Element;
  isPhoneNumber?: boolean;
};

type SeeScheduleObj = {
  [key: string]: FeeDetails[];
};

function MerchantApplicationScheduleFee() {
  const {
    transactionFees,
    monthlyAccountFees,
    gateAwayAccessFees,
    chargeBackFees,
    misleneousFees,
    merchantInfo,
    businessProfile,
    primaryAccountHolder,
    businessOwners,
    bankAccounts,
  } = useSnapShot();

  const seeScheduleObj = {
    "Transaction Fees": transactionFees,
    "Monthly Account Fees": monthlyAccountFees,
    "Gateway Access Fees": gateAwayAccessFees,
    "Chargeback Fees": chargeBackFees,
    "Miscellaneous Fees": misleneousFees,
  };

  const merchantApplication = {
    "Merchant Info": merchantInfo,
    "Business Profile": businessProfile,
    "Bank Account": bankAccounts?.flat(),
    "Primary Account Holder": primaryAccountHolder,
    "Business Owner": businessOwners?.flat(),
  };

  return (
    <SectionCardBase>
      <CustomAccordion
        mainTitle="Merchant Application"
        objectSection={merchantApplication}
        Icon={ListChecks}
      />
      <GiveDivider />
      <CustomAccordion
        mainTitle="Fee Schedule"
        objectSection={seeScheduleObj}
        Icon={CreditCard}
      />
    </SectionCardBase>
  );
}

export default MerchantApplicationScheduleFee;

const CustomAccordion = ({
  mainTitle,
  objectSection,
  Icon,
}: {
  mainTitle: string;
  objectSection: SeeScheduleObj;
  Icon?: any;
}) => {
  const { palette } = useAppTheme();
  return (
    <Accordion
      sx={{
        backgroundColor: "transparent", // Remove background color for Accordion
        boxShadow: "none", // Remove box shadow
        "&:before": { display: "none" }, // Remove the default border above Accordion
        padding: 0,
      }}
    >
      <AccordionSummary
        expandIcon={<CaretDown fill={palette.text.secondary} fontSize="20px" />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          padding: 0,
          minHeight: 0,
          "& .MuiAccordionSummary-content": { margin: 0 },
        }}
      >
        <Stack gap="8px" alignItems="center" flexDirection="row">
          <Icon size="24px" fill={palette.icon?.["icon-primary"]} />
          <GiveText fontSize="16px" color="primary">
            {mainTitle}
          </GiveText>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: 0,
        }}
      >
        {Object.keys(objectSection).map((key) => (
          <Stack mt="20px" key={key} spacing={1}>
            <GiveText
              pb="8px"
              pt="20px"
              color="secondary"
              fontSize="14px"
              fontWeight={400}
            >
              {key}
            </GiveText>
            {objectSection[key].map((item, index) => {
              const isEven = index % 2 === 0;
              return (
                <Grid
                  p="12px 16px"
                  bgcolor={
                    isEven
                      ? palette.surface?.secondary
                      : palette.surface?.primary
                  }
                  container
                  key={index}
                >
                  <Grid xs={6} item>
                    <GiveText
                      fontWeight={400}
                      fontSize="14px"
                      color="secondary"
                    >
                      {item.label}
                    </GiveText>
                  </Grid>
                  <Grid xs={6} item>
                    <GiveText
                      fontWeight={400}
                      fontSize="14px"
                      color="primary"
                      sx={{
                        whiteSpace: "normal",
                        overflow: "visible",
                        wordWrap: "break-word",
                      }}
                    >
                      {item.value}
                    </GiveText>
                  </Grid>
                </Grid>
              );
            })}
          </Stack>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
