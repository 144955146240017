import { Box, BoxProps } from "@mui/material";
import { GiveInput } from "@shared/GiveInputs/GiveInput";
import GiveText from "@shared/Text/GiveText";
import { styled } from "@theme/v2/Provider";
import { Dispatch, SetStateAction, useState } from "react";
import { useAppTheme } from "@theme/v2/Provider";
import { dataURLtoFile } from "@utils/assets";
import { selectUser } from "@redux/slices/auth/auth";
import { useAppSelector } from "@redux/hooks";
interface Props {
  setFileToUpload: Dispatch<SetStateAction<File | null>>;
}
function Generate({ setFileToUpload }: Props) {
  const { globalName } = useAppSelector(selectUser);
  const [values, setValues] = useState<{
    textValue: string;
    fontId: null | number;
  }>({
    textValue: `${globalName?.firstName} ${globalName?.lastName}`,
    fontId: null,
  });
  const { palette } = useAppTheme();

  const onHandleSelect = async (fontId: number) => {
    const font = fontList.find((f) => f.id === fontId)?.name;
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (!context) {
      return;
    }
    canvas.width = 400;
    canvas.height = 200;
    context.fillStyle = palette.background.paper;
    context.fillRect(0, 0, canvas.width, canvas.height);
    const text = values.textValue;
    const fontSize = 40;
    const fontColor = palette.text.primary;
    context.fillStyle = fontColor;
    context.font = `${fontSize}px ${font}`;
    const textWidth = context.measureText(text).width;
    const x = (canvas.width - textWidth) / 2;
    const y = canvas.height / 2;
    context.fillText(text, x, y);
    const dataUrl = canvas.toDataURL("image/png");
    const file = await dataURLtoFile(dataUrl, "signature.png");
    setValues((prev) => ({
      ...prev,
      fontId: fontId,
    }));

    setFileToUpload(file);
  };
  return (
    <Box mt="16px">
      <GiveInput
        value={values.textValue}
        onChange={(e) =>
          setValues((p) => ({ ...p, textValue: e.target.value }))
        }
        label="Full Name"
        name="Full"
      />
      <SuggestionsContainer>
        {fontList.map((font) => (
          <Suggestion
            key={font.id}
            sx={{
              fontFamily: font.name,
            }}
            onClick={() => onHandleSelect(font.id)}
            selected={font.id === values.fontId}
            data-testid={`generate-suggestion-${font.name}`}
          >
            <GiveText
              variant="bodyL"
              color="primary"
              sx={{ userSelect: "none", wordBreak: "break-all" }}
            >
              {values.textValue}
            </GiveText>
          </Suggestion>
        ))}
      </SuggestionsContainer>
    </Box>
  );
}

export default Generate;

const fontList = [
  { id: 1, name: "Allison" },
  { id: 2, name: "bedtime-stories" },
  { id: 3, name: "Fuggles" },
  { id: 4, name: "SCRIPTIN" },
];

const SuggestionsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "16px",
  marginTop: "16px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const Suggestion = styled(Box, {
  shouldForwardProp: (prop) => prop !== "selected",
})<BoxProps & { selected: boolean }>(({ selected, theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "12px",
  borderRadius: "6px",

  background: theme.palette.surface?.secondary,
  minHeight: "108px",
  flex: 1,
  cursor: "pointer",
  ...(selected && {
    border: `2px solid ${theme.palette.primitive?.blue[100]}`,
  }),
}));
