import { Box, BoxProps, Stack } from "@mui/material";
import { Plus } from "@phosphor-icons/react";
import GiveText from "@shared/Text/GiveText";
import { styled } from "@theme/v2/Provider";

export type PlaceholderCardProps = {
  icon: JSX.Element;
  title: string;
  description: string;
  disabled?: boolean;
} & BoxProps;

export const PlaceholderCard = ({
  icon,
  title,
  description,
  disabled,
  ...props
}: PlaceholderCardProps) => {
  return (
    <PlaceholderCardSubContainer {...props} disabled={disabled}>
      <Stack direction="row" gap="20px" alignItems="flex-start">
        <IconContainer>{icon}</IconContainer>
        <Stack spacing="4px" maxWidth="440px">
          <GiveText variant="bodyM" color="primary">
            {title}
          </GiveText>
          <GiveText variant="bodyS" color="secondary">
            {description}
          </GiveText>
        </Stack>
      </Stack>
      <StyledAddButton type="button">
        <Plus size={16} />
      </StyledAddButton>
    </PlaceholderCardSubContainer>
  );
};

const IconContainer = styled(Box)(() => ({
  width: 24,
  height: 24,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const PlaceholderCardSubContainer = styled(Box)<
  BoxProps & { disabled?: boolean }
>(({ theme, disabled }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "20px",
  padding: "16px",
  borderRadius: "12px",
  background: theme.palette?.primitive?.transparent?.["darken-5"],
  cursor: "pointer",
  ...(disabled && {
    pointerEvents: "none",
    opacity: 0.5,
  }),
  "&:hover": {
    background: theme.palette?.primitive?.transparent?.["darken-10"],
  },
}));

const StyledAddButton = styled("button")(({ theme }) => ({
  boxShadow: "none",
  border: "none",
  background: theme.palette?.primitive?.transparent?.["darken-10"],
  width: "32px",
  height: "32px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
}));
