import { SxProps } from "@mui/material";
import { TooltipProps as MuiTooltipProps } from "@mui/material";
export enum ArrowPlacement {
  TOP = "top",
  BOTTOM = "bottom",
  LEFT = "left",
  RIGHT = "right",
  TOP_END = "top-end",
  TOP_START = "top-start",
  BOTTOM_END = "bottom-end",
  BOTTOM_START = "bottom-start",
}

export type TooltipColor = "default" | "warning" | "success" | "light";

export type GiveTooltipProps = MuiTooltipProps & {
  title: string | React.ReactNode;
  heading?: string;
  children: React.ReactNode;
  color: TooltipColor;
  iconRight?: React.ReactNode;
  iconLeft?: React.ReactNode;
  leaveTouchDelay?: number;
  isArrow?: boolean;
  arrowPlacement?: ArrowPlacement;
  width?: "compact" | "Max width";
  alignment?: string;
  customTooltipStyles?: SxProps;
};
