import jsonTokens from "./color.tokens.json";
import { ThemeVersions } from "@theme/v2/types";

const getJsonObject = (key: string): any => {
  // Here you retrive nested values
  return key.split(".").reduce((acc: any, part: any) => acc[part], jsonTokens);
};

export const getJsonValue = (key: string): string => {
  const obj = getJsonObject(key);

  return obj?.value;
};

export const isSecondVersion = (value?: ThemeVersions) => {
  return value === "two";
};
