import { useIsValidFile } from "@hooks/upload-api/useIsValidFile";
import { useUploadProgress } from "@redux/slices/uploadProgressSlice";
import {
  axiosUpload,
  confirmAccountFiles,
  createAccountFiles,
  createImage,
  getS3UploadUrl,
  getS3UploadUrl2,
} from "@services/api/upload/uploadEndpoints";
import axios from "axios";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { IFileWithMeta, StatusValue } from "react-dropzone-uploader";
import { QueryKey, useMutation, useQueryClient } from "react-query";
import { getRandomNumber } from "@utils/helpers";

type handleUploadProps = {
  list: { file: File; id: string | number }[];
  attachmentType?: TFileAttachmentType;
};

export enum UPLOAD_STATUSES_ENUM {
  failed = "upload_failed",
}

export type HandlePresignedUploadReturnType =
  | string[]
  | UPLOAD_STATUSES_ENUM.failed;

export const useUploadPresignedDocument = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { setUploadProgress } = useUploadProgress();
  const handleUpload = ({
    list,
    attachmentType = "bank_account",
  }: handleUploadProps) => {
    const customDocumentList: string[] = [];
    setIsLoading(true);
    const isConversation = attachmentType === "conversation_message";
    return new Promise<HandlePresignedUploadReturnType>(function (resolve) {
      if (list.length === 0) return resolve([]);

      Promise.all(
        list.map((document) =>
          getS3UploadUrl({
            attachmentType,
            name: document.file.name,
          }),
        ),
      )
        .then((res) => {
          return Promise.all(
            res.map(
              (
                item: {
                  URL: string;
                  presignedURL: string;
                },
                index,
              ) => {
                setUploadProgress({
                  key: item.presignedURL,
                  data: {
                    fileName: list[index].file.name,
                    size: list[index].file.size,
                  },
                });
                const abortController = new AbortController();
                const signal = abortController.signal;
                setUploadProgress({
                  key: item.presignedURL,
                  data: {
                    abortController,
                  },
                });
                customDocumentList.push(
                  isConversation ? item.presignedURL : item.URL,
                );
                return axios
                  .put(item.presignedURL, list[index].file, {
                    onUploadProgress: (progressEvent) =>
                      setUploadProgress({
                        key: item.presignedURL,
                        data: {
                          progress: progressEvent.total
                            ? Math.floor(
                                (progressEvent.loaded * 100) /
                                  progressEvent.total,
                              )
                            : 0,
                        },
                      }),
                    signal,
                  })
                  .then((r) => {
                    setUploadProgress({
                      key: item.presignedURL,
                      data: {
                        success: true,
                      },
                    });
                  })
                  .catch(() => {
                    setUploadProgress({
                      key: item.presignedURL,
                      data: {
                        failed: true,
                      },
                    });
                  });
              },
            ),
          );
        })
        .then(() => {
          resolve(customDocumentList);
        })
        .catch(() => {
          resolve(UPLOAD_STATUSES_ENUM.failed);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  return { handleUpload, isLoading };
};

export type TFileAttachmentType =
  | "bank_account"
  | "legal_principal"
  | "account_member"
  | "underwriting_profile"
  | "account_owner"
  | "account_owner_selfie"
  | "conversation_message"
  | "underwriting_match_report"
  | "dispute_evidence";

export type UploadProgressParams = {
  fileId: string;
  progress: number;
  identifier?: number | string; // can be id or url
};

export type TUploadFilePayload = {
  attachmentType?: TFileAttachmentType;
  label?: string;
  tag?: string;
  list: { file: File }[];
  merchantId: number;
  resourceID: number;
  refetcherKey?: QueryKey[];
};

export type TUploadedFile = { id: number; isUploaded: true };

export type HandleUploadReturnType =
  | UPLOAD_STATUSES_ENUM.failed
  | TUploadedFile[];

export const useUploadFiles = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { setUploadProgress } = useUploadProgress();

  const handleUpload = async (
    payload: TUploadFilePayload,
    challengeSlugParam?: string,
  ): Promise<any> => {
    if (isEmpty(payload.list)) return [];
    setIsLoading(true);
    const customDocumentList = payload.list.map((item) => {
      return {
        attachmentType: payload.attachmentType,
        fileName: item?.file?.name,
        label: payload.label,
        tag: payload.tag,
        resourceID: payload.resourceID,
        fileSize: item?.file?.size,
      };
    });
    try {
      const res = await createAccountFiles({
        merchantId: payload.merchantId,
        challengeSlugParam,
        customDocumentList,
      });
      if (!res?.total || res?.total < 1) return UPLOAD_STATUSES_ENUM.failed;

      await Promise.all(
        res.data.map(async (document: any, index: any) => {
          setUploadProgress({
            key: document.s3ObjectKey,
            data: {
              fileName: customDocumentList[index].fileName,
              size: customDocumentList[index].fileSize,
              id: document.id,
              accountUpload: true,
              accountID: document.accID,
              refetcherKey: payload.refetcherKey,
            },
          });

          const abortController = new AbortController();
          const signal = abortController.signal;

          setUploadProgress({
            key: document.s3ObjectKey,
            data: {
              abortController,
            },
          });

          const res = await axios.put(
            document.fileURL,
            payload.list[index].file,
            {
              onUploadProgress: (progressEvent) => {
                setUploadProgress({
                  key: document.s3ObjectKey,
                  data: {
                    progress: progressEvent.total
                      ? Math.floor(
                          (progressEvent.loaded * 100) / progressEvent.total,
                        )
                      : 0,
                  },
                });
              },
              signal,
            },
          );
          setUploadProgress({
            key: document.s3ObjectKey,
            data: {
              success: true,
            },
          });

          return res;
        }),
      );
      const confirmedUploadedList: TUploadedFile[] = res.data.map(
        (item: any): TUploadedFile => ({
          id: item.id,
          isUploaded: true,
        }),
      );
      await confirmAccountFiles({
        merchantId: payload.merchantId,
        confirmedUploadedList,
      });

      setIsLoading(false);
      return confirmedUploadedList;
    } catch (e) {
      return UPLOAD_STATUSES_ENUM.failed;
    } finally {
      setIsLoading(false);
    }
  };

  return { handleUpload, isLoading };
};

export const useUploadImageToS3 = () => {
  const { setUploadProgress } = useUploadProgress();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      file,
      formType,
    }: {
      file: File;
      formType: string;
    }) => {
      let s3Response: any;
      let response: any;
      let uploadResponse: any;
      try {
        s3Response = await getS3UploadUrl2({
          formType,
        });

        setUploadProgress({
          key: s3Response.key,
          data: {
            fileName: file.name,
            size: file.size,
          },
        });
        const abortController = new AbortController();
        const signal = abortController.signal;
        setUploadProgress({
          key: s3Response.key,
          data: {
            abortController,
          },
        });

        uploadResponse = await axiosUpload({
          file,
          s3Response,
          setUploadProgress,
          signal,
        });

        const url = `${s3Response.action}/${s3Response.key}`;

        response = await createImage({
          label: "main_pic",
          url,
        });

        setUploadProgress({
          key: s3Response.key,
          data: {
            id: response.id,
          },
        });
        setUploadProgress({
          key: s3Response.key,
          data: {
            success: true,
          },
        });
        return {
          response,
          s3Response,
          uploadResponse,
        };
      } catch (error) {
        setUploadProgress({
          key: s3Response?.key || `${getRandomNumber(100, 1000000)}`,
          data: {
            failed: true,
          },
        });
        return null;
      }
    },
    onSuccess: () => {
      queryClient.refetchQueries("get-all-media-items");
    },
  });
  return mutation;
};

export const useUploadImageToS3WithoutSetting = () => {
  const { setUploadProgress } = useUploadProgress();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      file,
      formType,
    }: {
      file: File;
      formType: string;
    }) => {
      let s3Response: any;
      let url = "";
      try {
        s3Response = await getS3UploadUrl2({
          formType,
        });
        setUploadProgress({
          key: s3Response.key,
          data: {
            fileName: file.name,
            size: file.size,
          },
        });
        const abortController = new AbortController();
        const signal = abortController.signal;
        setUploadProgress({
          key: s3Response.key,
          data: {
            abortController,
          },
        });
        await axiosUpload({
          file,
          s3Response,
          setUploadProgress,
          signal,
        });
        url = `${s3Response.action}/${s3Response.key}`;
        setUploadProgress({
          key: s3Response.key,
          data: {
            success: true,
          },
        });
        return url;
      } catch (error) {
        setUploadProgress({
          key: s3Response?.key || `${getRandomNumber(1000000, 100000000)}`,
          data: {
            failed: true,
          },
        });
        return null;
      }
    },
    onSuccess: () => {
      queryClient.refetchQueries("get-all-media-items");
    },
  });
  return mutation;
};

export const useDropzoneUpload = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: directUpload, isLoading } = useUploadImageToS3();
  const { isValidFile } = useIsValidFile();
  const handleUpload = async (file: File, formType?: string) => {
    try {
      if (
        !isValidFile({
          file,
          allowedTypes: Object.keys(AcceptAllowedImagesTypes),
        })
      ) {
        return;
      }

      const responses = await directUpload({
        file,
        formType: formType || "merchant_image",
      });
      if (responses) {
        queryClient.refetchQueries("get-all-media-items");
      }
      return responses;
    } catch (err) {
      //
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files;
    if (file && file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        handleUpload(file[i]);
      }

      event.target.value = "";
    }
  };

  const handleDragDropChange = (
    metaFile: IFileWithMeta,
    status: StatusValue,
  ) => {
    if (status === "done") handleUpload(metaFile.file);
  };

  return {
    isUploading: isLoading,
    handleImageChange,
    handleDragDropChange,
    handleUpload,
  };
};

export const useUploadSingleImage = () => {
  const { mutateAsync, isLoading: isLoadingS3Request } =
    useUploadImageToS3WithoutSetting();
  const handleUpload = async ({
    file,
    formType = "customer_avatar",
  }: {
    file: File;
    formType?: string;
  }) => {
    return await mutateAsync({ formType, file });
  };

  return { handleUpload, isLoading: isLoadingS3Request };
};

//
export const AcceptAllowedImagesTypes = {
  "image/jpeg": [".jpg", ".jpeg"],
  "image/jpg": [".jpg"],
  "image/png": [".png"],
};
export const AcceptAllowedGeneralDocumentsTypes = {
  "image/jpeg": [".jpg", ".jpeg"],
  "image/jpg": [".jpg"],
  "image/png": [".png"],
  "image/webp": [".webp"],
  "application/pdf": [".pdf"],
};
export const fiveMB = 5 * 1000 * 1000;
