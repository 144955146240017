import { Stack } from "@mui/material";
import { Package } from "@phosphor-icons/react";
import GiveText from "@shared/Text/GiveText";
import CheckoutDetailsSectionItem from "./CheckoutDetailsSectionItem";

const DeliverySection = () => {
  return (
    <Stack gap="12px">
      <Stack direction="row" spacing={2}>
        <Package size={24} />
        <GiveText variant="bodyL">Delivery</GiveText>
      </Stack>
      <CheckoutDetailsSectionItem
        label="Items Will Be Delivered"
        name="delivery"
        secondaryText="Require customer’s shipping address."
      />
    </Stack>
  );
};

export default DeliverySection;
