import React from "react";
import BaseCardComponent from "../BaseCardComponent";
import useSnapShot from "features/Merchants/MerchantSidePanel/hooks/useSnapShot";

function GatewayFees() {
  const { gateAwayAccessFees } = useSnapShot();

  return (
    <BaseCardComponent
      leftTitle="Gateway Access Fees"
      payload={gateAwayAccessFees}
    />
  );
}
export default GatewayFees;
