import React from "react";
import { Button } from "@common/Button";
import { palette } from "@palette";
import { styled } from "@mui/material";
import { FilterFunnelIcon } from "@assets/rebrandIcons";
import XCircle from "@assets/icons/RebrandedIcons/XCircle";

function FilterAtomButton({
  disabled,
  filtersAmount,
  handleResetFilters,
  handleOpenModal,
}: {
  disabled?: boolean;
  filtersAmount: number | null;
  handleResetFilters: (event: React.MouseEvent<HTMLElement>) => void;
  handleOpenModal: () => void;
}) {
  return (
    <StyledButton
      background="secondary"
      disabled={disabled}
      startIcon={
        <FilterFunnelIcon
          fill={filtersAmount ? palette.accent[3] : palette.neutral[60]}
        />
      }
      hasFilters={!!filtersAmount}
      onClick={handleOpenModal}
    >
      Filter {filtersAmount ? `(${filtersAmount})` : ""}
      {!!filtersAmount && (
        <XCircle
          fill={palette.neutral[80]}
          bordered={false}
          onClick={handleResetFilters}
        />
      )}
    </StyledButton>
  );
}

export default FilterAtomButton;

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "hasFilters",
})<{ hasFilters?: boolean }>(({ hasFilters }) => ({
  padding: "0 12px",
  border: hasFilters
    ? `1px solid ${palette.accent[3]}`
    : `1px solid ${palette.neutral[10]}`,
  background: hasFilters ? palette.info.light : palette.background.dimmedWhite,
  color: hasFilters ? palette.accent[3] : palette.neutral[70],
  fontWeight: 350,
  height: "32px",
  lineHeight: "16.8px",
  gap: "4px",
  "&:hover": {
    background: palette.background.dimmedWhite,
  },
}));
