import React, { useEffect, useState } from "react";
import { durations } from "@constants/constants";
import { parseRelativeTimeString } from "@utils/date.helpers";
import { QuantityPaymentCard } from "@components/PaymentForm/PaymentCard";
import { TSubscriptionPeriod } from "@components/PaymentForm/PaymentCard/PaymentCard";
import { getCardColumns } from "@utils/publicForms";
import { CartItemType, selectCart, setCartItem } from "@redux/slices/cart";
import { useAppSelector } from "@redux/hooks";
import { useDispatch } from "react-redux";
import { M_PublicFormType } from "@common/PublicForm/types";
import { renderOptions } from "@common/PublicForm/RadioCardWrapper";
import { useMediaQuery, useTheme } from "@mui/material";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import { useCheckTicketsThreshold } from "@common/PublicForm/hooks/useCheckTicketsThreshold";

type MembershipPaymentCardProps = {
  subscription: any;
  data: M_PublicFormType;
  activeCards: number[];
  setActiveCards: React.Dispatch<React.SetStateAction<number[]>>;
};
const MembershipPaymentCard = ({
  subscription,
  data,
  activeCards,
  setActiveCards,
}: MembershipPaymentCardProps) => {
  const dispatch = useDispatch();
  const { cartItems } = useAppSelector(selectCart);
  const [activeCard, setActiveCard] = React.useState<number | null>(null);
  const [duration, setDuration] = React.useState<string>("One-Time");
  const [subDuration, setSubDuration] = React.useState<string>("Lifetime");
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [formattedDate, setFormattedDate] = useState<any>();
  const { isTicketsThresholdReached } = useCheckTicketsThreshold(cartItems);

  const activePaymentTypes =
    subscription.paymentTypes?.filter((type: any) => type?.checked) || [];

  const noRecurring =
    activePaymentTypes?.length === 1 &&
    activePaymentTypes[0]?.title === "One-Time";

  const addToCartHandler = (item: {
    recurringInterval: string;
    recurringMax: number | null;
  }) => {
    if (!activeCard) return;
    const existingCartItem = cartItems.find(
      (item) => item.productVariantID === +activeCard,
    );

    if (existingCartItem) {
      const newItem: CartItemType = {
        ...existingCartItem,
        recurringInterval: item.recurringInterval,
        recurringMax: item.recurringMax,
      };
      dispatch(
        setCartItem({
          ...newItem,
        }),
      );
    }
  };
  const getRecurringMax = React.useCallback(
    (max?: string) => {
      const recurringMax = max || subDuration;
      if (recurringMax.includes("Every")) return null;
      switch (duration) {
        case "One-Time":
          return null;
        case "Monthly":
        case "Yearly":
          return +recurringMax.substring(0, 2);
        case "Quarterly":
          return +recurringMax.substring(0, 2) / 3;
      }
      return null;
    },
    [duration, subDuration],
  );

  const handleChangeSubDuration = (subDuration: string) => {
    addToCartHandler({
      recurringInterval: duration,
      recurringMax: getRecurringMax(subDuration),
    });
    setSubDuration(subDuration);
  };

  useEffect(() => {
    if (cartItems.length === 0) {
      const defaultPayment = subscription.paymentTypes.find(
        (p: any) => p.isDefault,
      );

      handleChangeDuration(defaultPayment?.title || "One-Time");
    }
  }, [cartItems.length]);

  const handleChange = (id: number) => {
    if (
      isTicketsThresholdReached(
        data.subscriptions.customer_pays_credit.max_subscriptions,
      ) ||
      (activeCard === id && duration === "One-Time")
    )
      return;
    setActiveCard(id);
    const subscription = data?.subscriptions.list.find((s: any) => s.id === id);
    const defaultPayment = subscription.paymentTypes.find(
      (p: any) => p.isDefault,
    );
    dispatch(
      setCartItem({
        name: subscription.name,
        productVariantID: +id,
        price: subscription.amount,
        recurringInterval: defaultPayment?.title || "One-Time",
        recurringMax: getRecurringMax() || null,
        quantity: 1,
      }),
    );

    const selectedItemIds = cartItems.map((item) => item.productVariantID);
    setActiveCards((prev) => {
      const filteredPreviousList = prev.filter((id) =>
        selectedItemIds.includes(id),
      );
      return [...filteredPreviousList, id];
    });
  };

  const handleChangeDuration = (duration: string) => {
    setDuration(duration);
    switch (duration) {
      case "One-Time":
        setSubDuration("Lifetime");
        addToCartHandler({
          recurringInterval: "One-Time",
          recurringMax: null,
        });
        break;
      case "Monthly":
        setSubDuration("3 months");
        addToCartHandler({
          recurringInterval: "Every Month",
          recurringMax: 3, //the default value is 3 in the UI, so it should align with this
        });
        break;
      case "Quarterly":
        setSubDuration("Every Quarter");
        addToCartHandler({
          recurringInterval: "Quarterly",
          recurringMax: null,
        });
        break;
      case "Yearly":
        setSubDuration("Every Year");
        addToCartHandler({
          recurringInterval: "Yearly",
          recurringMax: null,
        });
        break;
    }
  };

  const renderOptionsHelper = (column: string, subscription: any) => {
    const arr =
      column !== "Duration"
        ? subscription.paymentTypes
        : durations[duration ? duration : subscription.paymentTypes[0].title];

    const _type = arr[0].title || arr[0];
    const { formattedDate } = parseRelativeTimeString(subDuration);

    const filteredOptions = arr.filter((type: any) => type.checked !== false);

    return {
      arr: filteredOptions,
      formattedDate,
      _type,
    };
  };

  return (
    <ErrorCatcher errorID="QuantityPaymentCard">
      <QuantityPaymentCard
        key={subscription.id}
        imageURL={subscription.thumbnail}
        title={subscription.name}
        name={subscription.name}
        description={subscription.description.text}
        price={parseFloat(subscription.amount)}
        quantity={subscription.in_stock}
        paymentTypes={subscription.paymentTypes}
        activePaymentType={duration as TSubscriptionPeriod}
        id={subscription.id}
        handleChange={handleChange}
        selected={activeCards.includes(subscription.id)}
        recurringMax={getRecurringMax()}
        setActiveCards={setActiveCards}
        category="membership"
        columns={getCardColumns(duration, subDuration, noRecurring)}
        shouldDisplayLeftTickets={subscription.display}
        maxPurchase={
          Number.isNaN(
            +data.subscriptions.customer_pays_credit.max_subscriptions,
          )
            ? undefined
            : parseInt(
                data.subscriptions.customer_pays_credit.max_subscriptions,
              )
        }
        formattedDate={formattedDate}
        renderOptions={(column: string) =>
          renderOptions({
            column,
            availablePaymentMethods: subscription,
            renderOptionsHelper,
            setFormattedDate,
            isDesktop,
            addToCartHandler,
            handleChangeSubDuration,
            handleChangeDuration,
            duration,
            subDuration,
          })
        }
      />
    </ErrorCatcher>
  );
};

const selectStyles = {
  "& .MuiInputBase-root": {
    border: "none",
    width: "100%",
    minWidth: "100%",
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: "flex-start",
    cursor: "default",
  },
  "& .MuiSelect-select": {
    padding: "0 !important",
    width: "auto",
  },
};
export default MembershipPaymentCard;
