import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useState } from "react";
import { selectQueryString, setSearchQueryByKey } from "@redux/slices/search";

type Props = {
  queryKey?: string;
};

const useTableSearch = ({ queryKey }: Props) => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");

  const searchQuery = useAppSelector((state) =>
    queryKey ? selectQueryString(state, queryKey) : undefined,
  );

  const searchQueryDispatcher = (value: string) => {
    dispatch(
      setSearchQueryByKey({
        queryKey: queryKey || "",
        params: {
          value,
        },
      }),
    );
  };

  const handleSearchChange = (value: string) => {
    setSearch(value);
    searchQueryDispatcher(value);
  };

  return {
    search,
    handleSearchChange,
    searchQuery,
  };
};

export default useTableSearch;
