import { Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { AdvancedSettingsButton } from "./styles";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import useColumnSelection, {
  TBulkSelectAction,
} from "../hooks/useColumnSelection";
import {
  CheckboxContainer,
  CustomCheckbox,
  SettingsSectionTitle,
} from "./AdvancedSettings.atoms";
import { useFormContext } from "react-hook-form";
import { Colum_types, TExportTable } from "../types";

export type TAdvancedSettingsData = {
  columns: Record<string, string[]>;
  translations: Record<string, string>;
  sectionTitles: Record<string, string>;
  visibleColumns: string[];
  monthlyColumns: string[];
  showMonthlyReport: boolean;
  ofacBusinessColumns: string[];
  ofacPersonsColumns: string[];
  initialTimezone: string;
  column_type: Colum_types;
};

const AdvancedSettings = ({
  columns,
  translations,
  sectionTitles,
  visibleColumns,
  monthlyColumns,
  ofacBusinessColumns,
  ofacPersonsColumns,
  showMonthlyReport = false,
  initialTimezone,
  column_type,
}: TAdvancedSettingsData) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const { watch } = useFormContext<TExportTable>();
  const selectedColumns = watch("columnsIncluded");
  const isOfacPersonSelected = selectedColumns === "ofac_persons";
  //its should be a temporary fix
  //we are adding the "Persons Type checkbox only when OFAC persons report is selected" to the advance select
  //bc this column right now is not accepted by BE and for ofac_persons we only sendt the reportType without the columnsIncluded
  const usedColumns = useMemo(() => {
    if (!isOfacPersonSelected && column_type === "merchant") {
      return {
        ...columns,
        ofacInfo: columns?.ofacInfo?.filter((item) => item !== "Persons Type"),
      };
    }
    return columns;
  }, [isOfacPersonSelected, columns]);

  const {
    getCheckboxAttributes,
    updateColumn,
    bulkSelect,
    isSectionAllActive,
    columnsFilter,
    hasLastCheckbox,
    // isOfacPersonSelected,
  } = useColumnSelection({
    initialColumns: usedColumns,
    visibleColumns: visibleColumns,
    monthlyColumns: monthlyColumns,
    ofacBusinessColumns,
    ofacPersonsColumns,
    initialTimezone,
  });
  //logic to get the hidden sections
  const getHideSection = (key: string) => {
    const isMonhlyReportSectionHidden =
      !showMonthlyReport && key === "salesInfo";
    const isOfacSectionHidden = !showMonthlyReport && key === "ofacInfo";
    if (isMonhlyReportSectionHidden || isOfacSectionHidden) return true;
    return false;
  };

  useEffect(() => {
    if (columnsFilter === "custom" && isCollapsed) setIsCollapsed(false);
  }, [columnsFilter]);

  if (isCollapsed) {
    return (
      <FadeUpWrapper delay={200}>
        <AdvancedSettingsButton
          background="tertiary"
          onClick={() => setIsCollapsed(false)}
        >
          Show Advanced Settings
        </AdvancedSettingsButton>
      </FadeUpWrapper>
    );
  }
  const renderCheckbox = (name: string) => {
    const { checked, disabled } = getCheckboxAttributes(name);
    const label = translations[name] || name;

    return (
      <CustomCheckbox
        key={name}
        label={label}
        checked={checked}
        disabled={disabled}
        onChange={(e) => {
          updateColumn(name, e.target.checked);
        }}
      />
    );
  };

  return (
    <Stack direction="column" gap="8px" alignItems="stretch">
      <SettingsSectionTitle showDescription={hasLastCheckbox} />

      {Object.keys(usedColumns).map((key, index) => {
        const isAllActive = isSectionAllActive(key);
        const label = isAllActive ? "Deselect all" : "Select all";
        const bulkAction: TBulkSelectAction = isAllActive
          ? "deselect"
          : "select";
        const showSection = getHideSection(key);
        if (showSection) return;
        return (
          <FadeUpWrapper key={key} delay={40 + index * 20}>
            <CheckboxContainer
              title={sectionTitles[key] || ""}
              action={{
                label,
                onClick: () => {
                  bulkSelect(bulkAction, key);
                },
              }}
            >
              {usedColumns[key]?.map(renderCheckbox)}
            </CheckboxContainer>
          </FadeUpWrapper>
        );
      })}
    </Stack>
  );
};

export default AdvancedSettings;
