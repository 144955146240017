import { PaperPlaneTilt } from "@phosphor-icons/react";
import GiveButton from "@shared/Button/GiveButton";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useResendButtonState } from "@components/Merchants/MerchantPreview/hooks/useResendButtonState";
import { TMerchantInvitation } from "@components/Merchants/MerchantPreview/data.types";
import { useUpdateMerchantInfo } from "@components/Merchants/MerchantPreview/hooks/useUpdateMerchantInfo";
import useResendInvite from "@hooks/merchant-api/manage-team/useResendInvite";
import { useGetCurrentMerchantId } from "@hooks/common";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  NEW_ACTION_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { millisecondsToHHMMSS } from "@utils/index";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { ArrowPlacement } from "@shared/Tooltip/GiveTooltip.types";

interface IComponentProps {
  data?: TMerchantInvitation;
  id: number;
}

const ResendInviteButton = ({ data, id }: IComponentProps) => {
  const { isMobileView } = useCustomThemeV2();
  const { handleSubmit } = useUpdateMerchantInfo(id);
  const { sendInvite } = useResendInvite();
  const {
    isDisableResend,
    setIsDisableResend,
    countdown,
    remainingTimeRef,
    isLongCooldownActive,
    inviteButtonText,
    shouldSendNewEmail,
  } = useResendButtonState({
    cooldownEndsAt: data?.cooldownEndsAt,
    sentEmailCount: data?.sentEmailCount,
    inviteStatus: data?.inviteStatus || "",
  });
  const { selectedUser } = useGetCurrentMerchantId();
  const { merchType, userRole } = selectedUser || {};
  const isMember =
    (merchType === "enterprise" || merchType === "provider") &&
    userRole === "member";

  const isSendInvitationAllowed = useAccessControl({
    resource: composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.INVITE),
    operation: OPERATIONS.CREATE,
  });
  const isDisabledWithPermissions =
    data?.noOwner || !isSendInvitationAllowed || isMember;
  const isResendButtonDisabled = isDisableResend || isDisabledWithPermissions;

  const timeRemainingLabel =
    remainingTimeRef.current > 0
      ? ` in ${millisecondsToHHMMSS(countdown, isLongCooldownActive)}`
      : "";

  const handleResend = () => {
    if (!data?.inviteId || shouldSendNewEmail) {
      handleSubmit("invitation", id);
    } else {
      sendInvite(id, data?.inviteId);
    }
    setIsDisableResend(true);
  };

  return (
    <GiveTooltip
      color="default"
      title={NEW_ACTION_DENY_MESSAGE}
      width="Max width"
      arrowPlacement={ArrowPlacement.TOP}
      disableHoverListener={!isDisabledWithPermissions}
    >
      <GiveButton
        size="small"
        label={inviteButtonText + timeRemainingLabel}
        disabled={isResendButtonDisabled}
        fullWidth={isMobileView}
        sx={{ whiteSpace: "nowrap", border: "none" }}
        onClick={handleResend}
        endIcon={<PaperPlaneTilt />}
      />
    </GiveTooltip>
  );
};

export default ResendInviteButton;
