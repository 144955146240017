import SidePanelHeaderVariant, {
  SidePanelHeaderVariantProps,
} from "@shared/SidePanel/components/SidePanelHeader/SidePanelHeaderVariant";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import {
  Airplay,
  BookmarkSimple,
  FileText,
  PencilSimpleLine,
} from "@phosphor-icons/react";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

const MainHeader = () => {
  const {
    onClose,
    isLoading,
    isFirst = true,
    isLast = true,
    handlers,
    data,
  } = useMerchantSidePanelContext();
  const { isMobileView } = useCustomThemeV2();

  const VariantProps: SidePanelHeaderVariantProps = {
    actions: [
      {
        text: "Agreement",
        hidden: !isMobileView,
        onClick: handlers.handleOpenAgreementSnapshot,
        IconRight: PencilSimpleLine,
      },
      {
        text: "Merchant File",
        hidden: !isMobileView,
        onClick: handlers.handleOpenMerchantFile,
        IconRight: FileText,
      },
      {
        text: "Control Mode",
        hidden: false,
        onClick: handlers.handleControlMode,
        IconRight: Airplay,
      },
      {
        text: `${
          data?.header?.watchlist ? "Remove from" : "Add to"
        } Watchlist`,
        hidden: false,
        onClick: handlers.handleAddToWatchList,
        IconRight: BookmarkSimple,
      },
    ],
    nextPrevState: { isFirst, isLast },
    isControlModeAllowed: true,
    handlers: {
      displayRowHandlers: true,
      handleClose: onClose,
      ...handlers,
    } as any,
    hasUnreadMessages: false,
    hasAlert: true,
    isRiskMonitorAllowed: false,
    withMerchantFile: true,
    withRisk: true,
    withAgreement: true,
    isLoading,
  };

  return (
    <SidePanelHeaderVariant
      variant="merchant_&_provider"
      HeaderProps={VariantProps}
    />
  );
};

export default MainHeader;
