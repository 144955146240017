import NiceModal from "@ebay/nice-modal-react";
import { useHandleLogout } from "@hooks/common/useHandleLogout";
import { useAppSelector } from "@redux/hooks";
import { selectInvitationsDraft } from "@redux/slices/enterprise/merchants";
import { useGetCurrentMerchantId } from "@hooks/common";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";

const LogoutModal = NiceModal.create((): JSX.Element => {
  const { open, onClose, SlideProps } = useNiceModal();
  const { submitLogout, isLoading } = useHandleLogout();
  const { merchantId } = useGetCurrentMerchantId();
  const savedInvitesDraft = useAppSelector((state) =>
    selectInvitationsDraft(state, merchantId),
  );

  return (
    <ModalFactory
      variant="popup"
      modalProps={{
        open,
        onClose,
        PopupProps: {
          TransitionProps: {
            onExited: SlideProps.onExit,
          },
          PaperProps: {
            sx: {
              padding: "24px 24px 16px",
            },
          },
        },
      }}
    >
      <ModalTitle
        title="Log out"
        onClose={onClose}
        closeButtonSize={22}
        description={
          <>
            You can always log back in at any time. If you are a member of other
            merchants you can easily switch accounts from the sidebar.
            {savedInvitesDraft?.entries?.length > 0 && (
              <>
                <br />
                <br />
                Logging out will result in the loss of any unsent merchant
                invitations in your draft.
              </>
            )}
          </>
        }
        textStyles={{
          title: {
            fontSize: "18px",
            lineHeight: "21.6px",
          },
        }}
      />
      <ModalActions
        animationDelay={70}
        containerProps={{
          sx: {
            marginTop: "20px",
          },
        }}
        primaryAction={{
          label: "Log Out",
          type: "button",
          onClick: submitLogout,
          isLoading: isLoading,
        }}
        secondaryAction={{
          label: "Cancel",
          onClick: onClose,
        }}
      />
    </ModalFactory>
  );
});

export default LogoutModal;
