import { useAppTheme } from "@theme/v2/Provider";
import {
  GiveProgressBarType,
  GiveProgressBarVariantType,
} from "./GiveProgressBar.types";

interface GiveProgressBarProps {
  value: number;
  innerValue?: number;
  variant: GiveProgressBarVariantType;
  type: GiveProgressBarType;
}

export default function GiveProgressBar({
  value,
  variant,
  innerValue,
  type,
}: GiveProgressBarProps) {
  const { palette } = useAppTheme();

  const STYLES = {
    completed: {
      backgroundColor:
        type === "default"
          ? palette.primitive?.neutral[40]
          : palette.primitive?.success[50],
    },
    incomplete: {
      ...(type === "default"
        ? { backgroundColor: palette.primitive?.neutral[40] }
        : {
            border: `1px solid ${palette.primitive?.success[50]}`,
          }),
    },
    disabled: {
      backgroundColor: palette.primitive?.transparent["darken-10"],
    },
  };
  return (
    <div
      style={{
        padding: type === "underwriting" ? PADDING[variant] : 0,
        borderRadius: 4,
        backgroundColor:
          type === "underwriting" ? "transparent" : palette.surface?.tertiary,
        width: type === "underwriting" ? `${Math.floor(value)}%` : "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      <div
        style={{
          borderRadius: 4,
          width: type === "default" ? `${Math.floor(value)}%` : "100%",
          height: 8,
          ...STYLES[variant],
        }}
      />
      {innerValue && innerValue !== 0 ? (
        <div
          style={{
            borderRadius: 4,
            width: type === "default" ? `${Math.floor(innerValue)}%` : "100%",
            height: 8,
            position: "absolute",
            backgroundColor:
              variant === "completed"
                ? palette.primitive?.success[50]
                : palette.icon?.["icon-primary"],
          }}
        />
      ) : null}
      {type === "default" && (
        <div
          style={{
            position: "absolute",
            backgroundColor:
              value < 80
                ? palette.primitive?.neutral[40]
                : palette.primitive?.neutral[0],
            width: "4px",
            height: "4px",
            borderRadius: "4px",
            right: "20%",
          }}
        />
      )}
    </div>
  );
}

const PADDING = {
  completed: "0px 2px 0px 0px",
  incomplete: "2px",
  disabled: "0px 0px 0px 2px",
};
