import { memo } from "react";
import GiveChip, { TChipColors } from "@shared/Chip/GiveChip";

export type BusinessProfileStatusType =
  | "pending"
  | "pending_review"
  | "move_back_to_pending"
  | "approved"
  | "declined"
  | "suspended"
  | "ready_for_verification"
  | "declined_by_msp"
  | "pending_review_issue"
  | "In progress"
  | "Delivered"
  | "Undeliverable";

interface Props {
  statusCode?: BusinessProfileStatusType;
}
function GiveBusinessProfileTag({ statusCode }: Props) {
  if (!statusCode || !BusinessProfileStatus[statusCode]) return <></>;
  const { label, color } = BusinessProfileStatus[statusCode];

  return <GiveChip variant="outline" label={label} color={color} />;
}

export default memo(GiveBusinessProfileTag);

type StatusObject = { label: string; color: TChipColors };
export const BusinessProfileStatus: Record<
  BusinessProfileStatusType,
  StatusObject
> = {
  pending_review: {
    label: "Pending Review",
    color: "warning",
  },
  move_back_to_pending: {
    label: "Pending",
    color: "default",
  },
  approved: {
    label: "Approved",
    color: "success",
  },
  declined: {
    label: "Declined",
    color: "error",
  },
  suspended: {
    label: "Suspended",
    color: "warning",
  },
  ready_for_verification: {
    label: "Ready for Verification",
    color: "blue",
  },
  pending: {
    label: "Pending",
    color: "default",
  },
  declined_by_msp: {
    label: "Pending",
    color: "default",
  },
  pending_review_issue: {
    label: "Pending",
    color: "default",
  },
  "In progress": {
    label: "In progress",
    color: "warning",
  },
  Delivered: {
    label: "Delivered",
    color: "warning",
  },
  Undeliverable: {
    label: "Undeliverable",
    color: "error",
  },
};
