import React, { SetStateAction } from "react";
import LoadingComponent from "./LoadingComponent";
import SelfiePreview from "./SelfiePreview";
import { ConfirmationScreen } from "@sections/VerifyAccountHolder_v2/ConfirmationScreen";
import { Box, Stack } from "@mui/material";
import {
  ProfileSetupFormActions,
  ProfileSetupFormTitle,
} from "@components/ProfilePage/form.components";
import SelfieComponent from "@sections/VerifyAccountHolder_v2/components/SelfieComponent";
import { palette } from "@palette";
import { ArrowLeft } from "@assets/icons";

type TDesktopSelfieTab = {
  isUploading: boolean;
  selfieUrl: string | null;
  setSelfieUrl: (value: SetStateAction<string | null>) => void;
  completed: boolean;
  setCompleted: (completed: boolean) => void;
  homepageReset: () => void;
  enterpriseData: any;
  updateProgressBar: (value: number) => void;
  setIsUploading: (value: SetStateAction<boolean>) => void;
  handleBack: () => any;
  dbSelfieURL: string | null;
  isLoggedInPAH?: boolean;
  merchant?: any;
  isSelfieStepActive?: boolean;
};

const DesktopSelfieTab = ({
  isUploading,
  selfieUrl,
  setSelfieUrl,
  completed,
  setCompleted,
  homepageReset,
  enterpriseData,
  updateProgressBar,
  setIsUploading,
  handleBack,
  dbSelfieURL,
  isLoggedInPAH,
  isSelfieStepActive,
}: TDesktopSelfieTab) => {
  const handlePrimaryAction = () => {
    if (completed) {
      homepageReset();
    } else if (selfieUrl) {
      setSelfieUrl(null);
      setCompleted(true);
    } else {
      setSelfieUrl(dbSelfieURL);
    }
  };
  return (
    <>
      {isUploading ? (
        <LoadingComponent />
      ) : (
        <>
          {selfieUrl ? (
            <SelfiePreview
              selfieUrl={selfieUrl}
              onClick={() => {
                setSelfieUrl(null);
                setCompleted(false);
              }}
            />
          ) : (
            <>
              {completed ? (
                <ConfirmationScreen />
              ) : (
                <Stack direction="column" gap={4}>
                  <ProfileSetupFormTitle
                    title="Take a selfie"
                    description="A selfie is needed to confirm your ID"
                  />
                  <SelfieComponent
                    isSelfieStepActive={isSelfieStepActive}
                    isLoggedInPAH={isLoggedInPAH}
                    merchant={enterpriseData}
                    updateProgressBar={updateProgressBar}
                    completed={completed}
                    setCompleted={setCompleted}
                    setSelfieUrl={setSelfieUrl}
                    setIsUploading={setIsUploading}
                  />
                </Stack>
              )}
            </>
          )}
        </>
      )}

      <ProfileSetupFormActions
        secondaryAction={{
          onClick: handleBack,
          children: (
            <Box
              component="span"
              color={palette.black[100]}
              display="inline-flex"
              alignItems="center"
              gap="4px"
            >
              <ArrowLeft height={20} width={20} fill={palette.black[100]} />{" "}
              Back
            </Box>
          ),
        }}
        primaryAction={{
          disabled: !enterpriseData?.owner?.selfieUploaded || isUploading,
          children: completed || selfieUrl ? "Done" : "Next",
          type: "button",
          onClick: handlePrimaryAction,
        }}
      />
    </>
  );
};

export default DesktopSelfieTab;
