import * as Yup from "yup";
import {
  dateOfBirthSchema,
  nonResidentInputsSchema,
  streetAddressValidator,
} from "@utils/validation.helpers";
import { matchIsValidTel } from "mui-tel-input";

const addressSchema = (name: string) =>
  Yup.string().when("useBusinessAddress", {
    is: false,
    then: Yup.string(),
  });

export const getBusinessOwnerSchema = () =>
  Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string().email("Email is not valid"),
    ssn: Yup.string()
      .nullable()
      .notRequired()
      .test(
        "is-empty-or-valid",
        "Please provide a valid SSN number",
        (value) => !value || value.length >= 11,
      ),
    DOB: dateOfBirthSchema(false),
    ownership: Yup.number()
      .min(0, "Ownership must be at least 0%")
      .max(100, "Ownership must be at most 100%"),
    useBusinessAddress: Yup.boolean(),
    country: Yup.string().when("useBusinessAddress", {
      is: false,
      then: Yup.string()
        .required("Country is required")
        .matches(/^[A-Z].+$/, "Country is required"),
    }),
    street: Yup.string().when("useBusinessAddress", {
      is: false,
      then: streetAddressValidator(),
    }),
    contactPhone: Yup.string().when({
      is: (exists: string) => !!exists && exists !== "+1",
      then: (schema) =>
        schema.test(
          "is-valid-number",
          "Please enter a valid phone number",
          function (value) {
            const phoneNumber = value as string;
            return matchIsValidTel(phoneNumber);
          },
        ),
    }),
    city: addressSchema("City"),
    zip: Yup.string().when("useBusinessAddress", {
      is: false,
      then: Yup.string()
        .nullable()
        .notRequired()
        .test(
          "is-empty-or-valid",
          "Invalid ZIP format",
          (value) =>
            !value || Boolean(value.match(/^[0-9]{5}(?:-?[0-9]{4})?$/)),
        ),
    }),
    state: addressSchema("State"),
    ...nonResidentInputsSchema,
    files: Yup.object().shape({
      fileWithMeta: Yup.object().unknown(true),
      status: Yup.string(),
      allFiles: Yup.array().of(Yup.object()),
    }),
  });
