import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import NiceModal from "@ebay/nice-modal-react";
import { Box } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";

import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { useDisputesFilter } from "../hooks/useDisputesFilter";
import FadeUpWrapper from "components/animation/FadeUpWrapper";
import { Stack } from "@mui/material";
import SectionItem from "@common/TableFilters/SectionItem";

const DisputesFilterPanel = NiceModal.create(() => {
  const { open, onClose, TransitionProps, SlideProps } = useNiceModal();
  const { methods, onSubmit, sections, actions } = useDisputesFilter();

  return (
    <ModalFactory
      variant="sidepanel"
      modalProps={{
        open,
        onClose: onClose,
        SlideProps,
        SidePanelProps: {
          width: "500px",
          PaperProps: {
            TransitionProps,
          },
        },
        DrawerProps: {
          PaperProps: {
            TransitionProps,
          },
        },
      }}
    >
      <FormProvider {...methods}>
        <Box
          width="100%"
          height="100%"
          display="flex"
          sx={{
            padding: "16px",
            gap: "24px",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          component="form"
          onSubmit={methods.handleSubmit(onSubmit)}
          data-testid="disputes-filter-panel"
        >
          <ModalTitle
            title="Filter by"
            textStyles={{
              title: {
                fontSize: "18px",
                lineHeight: "21.6px",
                letterSpacing: "-0.18px",
              },
            }}
            closeButtonSize={24}
            onClose={onClose}
          />
          <Box
            flex={1}
            overflow="auto"
            sx={{ overflowX: "hidden" }}
            data-testid="disputes-filter-content"
          >
            <FadeUpWrapper delay={150}>
              <Stack gap="16px">
                {sections.map((item) => (
                  <SectionItem
                    key={item.title}
                    title={item.title}
                    description={item?.description}
                    endElement={item?.endElement}
                    startIcon={item?.startIcon}
                    dataTestId={item?.dataTestId}
                  >
                    {item.children}
                  </SectionItem>
                ))}
              </Stack>
            </FadeUpWrapper>
          </Box>
          <ModalActions animationDelay={250} {...actions} />
        </Box>
      </FormProvider>
    </ModalFactory>
  );
});

export default DisputesFilterPanel;
