import {
  ArrowsLeftRight,
  ArrowUp,
  Code,
  GearSix,
  Storefront,
} from "@phosphor-icons/react";
import { ENTERPRISE_PATHS } from "@routes/paths";

type Props = {
  isTransfersPageEnabled: boolean;
};

const getProviderMenu = ({ isTransfersPageEnabled }: Props) => ({
  menu: [
    {
      label: "Processing",
      value: "/provider/processing",
      Icon: ArrowsLeftRight,
    },
    ...(isTransfersPageEnabled
      ? [
          {
            label: "Transfers",
            value: "/provider/transfers",
            Icon: ArrowUp,
            index: 2,
          },
        ]
      : []),
    {
      label: "Merchants",
      value: "/provider/merchants",
      Icon: Storefront,
    },
  ],
  footer: [
    ...(process.env.REACT_APP_ENVIRONMENT !== "production"
      ? [
          {
            label: "Developer API",
            value: `/provider/${ENTERPRISE_PATHS.DEVELOPER_API}`,
            Icon: Code,
          },
        ]
      : []),
    {
      label: "Settings",
      value: "/provider/settings",
      Icon: GearSix,
    },
  ],
});

export default getProviderMenu;
