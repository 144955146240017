import { useComponentVisibilityOnStatus } from "@components/Merchants/MerchantPreview/sections/StatusReview/hooks/useComponentVisibilityOnStatus";
import { IChipProps } from "@shared/Chip/GiveChip";
import { useAppTheme } from "@theme/v2/Provider";
import { useConfigureChallenges } from "features/Merchants/MerchantSidePanel/WithRepository/Challenges/useChallengesRepository";
import { WithRepositoryProvider } from "features/Merchants/MerchantSidePanel/WithRepository/WithRepositoryContext";
import UnderwritingCardHeader from "./UnderwritingCardHeader";
import UnderwritingChallengeCategories from "./UnderwritingChallengeCategories";
import { CardContainer } from "./UnderwritingCard.style";
import UnderwritingCardBody from "./UnderwritingCardBody";
import { useMerchantSidePanelContext } from "features/Merchants/MerchantSidePanel/Provider/MerchantSidePanelProvider";
import { useState } from "react";
import { selectMerchantStatusName } from "@redux/slices/enterprise/merchants";
import { useAppSelector } from "@redux/hooks";
import { StatusNames } from "@components/Merchants/MerchantList/MerchantStatusFilters";
import { checkPortals } from "@utils/routing";

const UnderwritingCard = ({ isProvider = false }) => {
  const { palette } = useAppTheme();
  const { configs, repositories } = useConfigureChallenges();
  const { data, id } = useMerchantSidePanelContext();
  const { isEnterprisePortal } = checkPortals();
  const statusName = useAppSelector(selectMerchantStatusName);

  const underwritingStatus = getUnderwritingStatus();

  const [isSummaryEnabled, setSummaryEnabled] = useState(
    statusName !== underwritingStatus,
  );

  const {
    underwritingScorePercentage = 0,
    accID,
    underwriterName,
    underwriterEmail,
    underwriterImageURL,
  } = data?.status || {};

  const { status } = useComponentVisibilityOnStatus({
    data: data?.status,
    isRebranding: true,
  });

  const handleManageUnderwriting = () => setSummaryEnabled(false);

  function getUnderwritingStatus() {
    if (isProvider) return StatusNames.enterpriseUnderwriting;
    return isEnterprisePortal
      ? StatusNames.underwritingAsProvider
      : StatusNames.aquirerUnderwriting;
  }

  return (
    <WithRepositoryProvider configs={configs} repositories={repositories}>
      <CardContainer backgroundColor={palette.surface?.primary}>
        <UnderwritingCardHeader
          underwritingScorePercentage={underwritingScorePercentage}
          merchantId={accID}
          status={status as IChipProps}
        />
        {isSummaryEnabled && (
          <UnderwritingCardBody
            underwriterName={underwriterName}
            underwriterEmail={underwriterEmail}
            underwriterImageURL={underwriterImageURL}
            id={id}
            handleManageUnderwriting={handleManageUnderwriting}
          />
        )}
        <UnderwritingChallengeCategories
          allChallengesCompleted={underwritingScorePercentage >= 80}
          merchantId={accID}
          merchantName={data?.merchantInfo?.merchantName}
          show={!isSummaryEnabled}
        />
      </CardContainer>
    </WithRepositoryProvider>
  );
};

export default UnderwritingCard;
