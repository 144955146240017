import { PaymentInformationCard } from "@components/PaymentForm";
import EventDetailsForm from "./EventDetailsForm";
import PublicFormFooter from "@components/PaymentForm/PublicFormFooter";
import bannerImage from "@assets/images/main-canvas-img.png";
import useLoadGoogleScript from "@hooks/google-api/useLoadGoogleScript";
import { useFindEventById } from "@hooks/merchant-api/events";
import { useParams } from "react-router-dom";
import { getCountryNameFromCode } from "@utils/country_dial_codes";
import { getNumberSuffix } from "@utils/index";
import PublicForm from "@common/PublicForm/PublicForm";
import { SE_PublicFormType } from "@common/PublicForm/types";
import { PinIcon, UrlGlobeIcon } from "@assets/icons";
import { LoginExternalLink } from "@components/Login";
import { Text } from "@common/Text";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { NotFoundResourcePage } from "@common/404";
import { useAppDispatch } from "@redux/hooks";
import { setCurrentCampaign } from "@redux/slices/checkout";
import { useEffect, useState } from "react";
import { PublicPeekMode } from "@common/PeekMode/types";
import { MOBILE_PUBLIC_FORM_TICKETS } from "@constants/componentRegistryConstants";
import CheckoutWrapper from "@common/PublicForm/CheckoutWrapper";
import { getPublishedFormDeviceType } from "@pages/NewAdvancedBuilder/utils/helpers";
import moment from "moment";

const EventsPublicForm = ({ previewId, title, isPeekMode }: PublicPeekMode) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const { id } = useParams();
  const { data, isLoading, redirect } = useFindEventById(previewId || id, true);
  const { isLoaded } = useLoadGoogleScript();
  const dispatch = useAppDispatch();
  const { isDesktop } = getPublishedFormDeviceType();
  useEffect(() => {
    if (data) dispatch(setCurrentCampaign({ title: data.general.title }));
  }, [data]);

  if (isLoading) return <LoadingSpinner />;

  if (redirect) {
    return <NotFoundResourcePage />;
  }

  if (!data) {
    return null;
  }

  const currentLocation =
    data.date_and_location.location.location ||
    data.date_and_location.location.address +
      data.date_and_location.location.city +
      data.date_and_location.location.state +
      data.date_and_location.location.zip;

  const locationText = [
    data.date_and_location.location.address,
    data.date_and_location.location.city,
    data.date_and_location.location.state,
    getCountryNameFromCode(data.date_and_location.location.country),
  ].join(", ");

  const Date = ({
    day,
    includeTime,
  }: {
    day: Date | undefined | null;
    includeTime: boolean | undefined;
  }) => {
    if (!day) return null;
    const formattedTime = includeTime ? moment(day).format("hh:mm A") : null;
    return (
      <span style={{ minWidth: "max-content" }}>
        {months[day?.getMonth() ?? -1]} {day?.getDate()}
        {getNumberSuffix(day?.getDate())}
        {", "}
        {day?.getFullYear()} {includeTime && <>{formattedTime}</>}
      </span>
    );
  };

  const authorInfos = {
    name: data.general.creatorName,
    avatar: data.general.creatorImage,
    description: data.general.creatorDescription,
  };

  return (
    <>
      <PublicForm
        type="Event"
        useAsBackground={data.general.featuredImage.useAsBackground || false}
        bannerImage={
          data.general.featuredImage.image
            ? data.general.featuredImage.image + "/original"
            : ""
        }
        formattedProductName={data.general.title}
        campaignTitle={
          data.general.title.charAt(0).toUpperCase() +
          data.general.title.slice(1)
        }
        campaignDescription={data.general.description}
        authorInfos={authorInfos}
        accID={data?.accID}
        productId={id || previewId}
        hasMap={data.date_and_location.location.event_url ? false : true}
        location={{
          currentLocation,
          locationText,
          isLoaded,
          date: {
            eventStartDate: data.date_and_location.date.startDate,
            eventTime: data.date_and_location.date.include_time,
            eventEndDate: data.date_and_location.date.endDate,
            day: data.date_and_location.date.day,
            createDate: (date: Date | null | undefined, time: any) => (
              <Date day={date} includeTime={time} />
            ),
          },
          eventURL: () =>
            data.date_and_location.location.event_url ? (
              <LoginExternalLink
                underline="#4C4A52"
                sx={{ textDecoration: "underline" }}
                href={data.date_and_location.location.event_url}
              >
                {data.date_and_location.location.event_url}
              </LoginExternalLink>
            ) : (
              <Text>
                {data.date_and_location.location.location || locationText}
              </Text>
            ),
          eventIcon: () =>
            data.date_and_location.location.event_url ? (
              <UrlGlobeIcon />
            ) : (
              <PinIcon />
            ),
        }}
        renderSidebar={() => (
          <>
            <EventDetailsForm
              data={data as SE_PublicFormType}
              isLoading={isLoading}
              drawerOpen={{
                ...(!isDesktop && {
                  ID: MOBILE_PUBLIC_FORM_TICKETS,
                  open: drawerOpen,
                  setOpen: setDrawerOpen,
                  renderCheckout: () => (
                    <PaymentInformationCard
                      destinationAccountMerchantName={authorInfos.name}
                      isPeekMode={isPeekMode}
                      setDrawerOpen={setDrawerOpen}
                    />
                  ),
                }),
              }}
            />
            <CheckoutWrapper
              authorInfos={authorInfos}
              isDesktop={isDesktop}
              isPeekMode={isPeekMode}
              setDrawerOpen={setDrawerOpen}
              formOf="event"
            />
          </>
        )}
        isPeekMode={isPeekMode}
        renderFooter={() => <PublicFormFooter isPeekMode={isPeekMode} />}
      />
    </>
  );
};

const bgStyle = {
  minHeight: "100vh",
  paddingBlock: "67px 130px",
  backgroundSize: "cover",
  backgroundPosition: "center",
  zIndex: 0,
  backgroundRepeat: "no-repeat",
  backgroundImage: `url(${bannerImage})`,
  position: "relative",
};

const containerStyle = {
  borderRadius: "8px",
  padding: "8px",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  background: "rgba(255, 255, 255, 0.9)",
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default EventsPublicForm;
