import { showMessage } from "@common/Toast";
import { TPosition } from "@common/Toast/ShowToast";
import { deleteDocumentAPI } from "@components/Settings/Business/Documents/utils";
import NiceModal from "@ebay/nice-modal-react";
import { customInstance } from "@services/api";
import { DELETE_CONFIRMATION_MODAL } from "modals/modal_names";

export const deleteDocument = (
  merchantID: number,
  document: { fileName: string; id: number },
  onEnd?: () => void,
  { isTmpFile = false, hideModal = false } = {},
) => {
  const { fileName, id } = document;
  const deleteHandler = () => deleteDocumentAPI(merchantID, id, onEnd, true);
  if (hideModal) {
    deleteHandler();
  } else {
    NiceModal.show(DELETE_CONFIRMATION_MODAL, {
      variant: "document",
      deleteHandler: isTmpFile ? onEnd : deleteHandler,
      itemName: fileName,
    });
  }
};

export const downloadDocument = async (
  file: {
    fileName: string;
    fileURL: string;
  },
  isDesktop?: boolean,
) => {
  const ToastPosition: TPosition | undefined = !isDesktop
    ? {
        position: "top-left",
        style: { top: "47px", left: "7px" },
      }
    : undefined;
  try {
    const link = document.createElement("a");
    link.href = file.fileURL;
    link.setAttribute("download", file.fileName);
    link.click();
  } catch (error: any) {
    if (error?.name !== "AbortError") {
      showMessage(
        "Warning",
        "Ups something went wrong",
        false,
        "",
        undefined,
        ToastPosition,
      );
    }
  }
};

export const updateFile = (merchantID: number, documentID: number, data: any) =>
  customInstance({
    url: `/accounts/${merchantID}/files/${documentID}`,
    method: "PATCH",
    data,
  });

export const followLink = (
  url: string,
  attributes?: Record<string, string>,
) => {
  const link = document.createElement("a");
  if (attributes) {
    Object.entries(attributes).forEach(([key, value]) =>
      link.setAttribute(key, value),
    );
  }
  link.href = url;
  link.click();
};
