import { ConversationsIconNotFilled } from "@assets/icons";
import NiceModal from "@ebay/nice-modal-react";
import { Box } from "@mui/material";
import { palette } from "@palette";
import {
  BUSINESS_PROFILE_MISSING_FIELDS_MODAL,
  CHANGE_PROFILE_STATUS_MODAL,
  CONFIRMATION_POP_UP,
} from "modals/modal_names";
import { IParsedData } from "../data.types";
import useGetSectionItems from "../hooks/useGetSectionItems";
import { EditButton, EditMerchantSection } from "./atoms";
import { MoreIcon } from "@assets/icons";
import { useMemo, useState } from "react";
import { ActionsMenu } from "@components/ManageMoney/TransactionTable/TransactionInfoModal/ActionsMenu";
import { useUpdateBusinessProfileStatus } from "../hooks/useUpdateBusinessProfileStatus";
import NewTag, {
  BusinessProfileStatusType,
} from "@common/Tag/BusinessProfileTag";
import { isEmpty } from "lodash";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { Stack } from "@mui/material";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { EditBusinessHandler } from "@components/Merchants/CreateMerchantPanel/modals/CreateBusinessProfileModal";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";
import {
  MovePendingTitle,
  StyledIconButton,
  StyledInfoText,
} from "./MerchantPreviewBusinessProfileStyle";
import { useBusinessProfileActions } from "../hooks/useBusinessProfileActions";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  FEATURE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";

type Props = {
  id: number;
  data: IParsedData;
  isAcquirer?: boolean;
  editHandler: EditBusinessHandler;
  isEnterprise?: boolean;
};

const MerchantPreviewBusinessProfile = ({
  id,
  data,
  isAcquirer,
  editHandler,
  isEnterprise,
}: Props) => {
  const { handleUpdateStatus } = useUpdateBusinessProfileStatus({
    merchantId: id,
    data,
  });
  const { businessProfileItems } = useGetSectionItems(data);
  const { message, showToolTip, isHidden, isPending } =
    useBusinessProfileActions(data);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const opened = Boolean(anchorEl);

  const isAllowedUpdate = useAccessControl({
    resource: composePermission(
      isEnterprise ? RESOURCE_BASE.ENTERPRISE : RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.LEGAL_ENTITY,
    ),
    operation: OPERATIONS.UPDATE,
  });

  const handleApprove = () => {
    const mainPrincipal = data?.businessOwnersList?.[0];

    const missingFields = [
      {
        field: "Federal Tax ID / SSN",
        missing: !data?.businessProfile?.taxID && !data?.businessProfile?.ssn,
      },
      {
        field: "Business Legal Name",
        missing: !data?.businessProfile?.legalName,
      },
      { field: "Business Type", missing: !data?.businessProfile?.businessType },
      {
        field: "Date business opened",
        missing: !data?.businessProfile?.businessOpenedAt,
      },
      {
        field: "Business Owner Phone",
        missing: !data?.businessProfile?.contactPhone,
      },
      { field: "Business Address", missing: !data?.businessAddress?.address },
      {
        field: "Business Ownership",
        missing: data?.businessOwnersList?.length === 0,
      },
      {
        field: "First Business Owner first name",
        missing:
          data?.businessOwnersList?.length !== 0 && !mainPrincipal?.firstName,
      },
      {
        field: "First Business Owner last name",
        missing:
          data?.businessOwnersList?.length !== 0 && !mainPrincipal?.lastName,
      },
      {
        field: "First Business Owner Ownership",
        missing:
          data?.businessOwnersList?.length !== 0 && !mainPrincipal?.ownership,
      },
      {
        field: "First Business Owner SSN/EIN",
        missing:
          data?.businessOwnersList?.length !== 0 &&
          !mainPrincipal?.ssn &&
          !mainPrincipal?.ein,
      },
      {
        field: "First Business Owner Date of Birth",
        missing: data?.businessOwnersList?.length !== 0 && !mainPrincipal?.dob,
      },
    ];

    const isMissingFields = missingFields.some((field) => field.missing);

    if (isMissingFields) {
      NiceModal.show(BUSINESS_PROFILE_MISSING_FIELDS_MODAL, {
        fieldsList: missingFields,
      });
      return;
    }
    NiceModal.show(CONFIRMATION_POP_UP, {
      variant: "approve",
      onClick: () => handleUpdateStatus("approved"),
      isEnterprise: isEnterprise,
    });
  };
  const openConfirmationHandler = (status: BusinessProfileStatusType) => {
    NiceModal.show(CHANGE_PROFILE_STATUS_MODAL, {
      status: status,
      id: id,
      merchantName: data.merchantInfo.merchantName,
      data: data,
      isEnterprise: isEnterprise,
    });
  };
  const status = data?.businessProfile.statusName;
  const isOfacConfirmedMatch =
    data?.businessProfile?.ofac?.lastCheckStatusName === "confirmed_match";

  const actions = useMemo(() => {
    return [
      {
        title: "Edit",
        hidden: false,
        disabled:
          isOfacConfirmedMatch || status === "declined" || !isAllowedUpdate,
        tooltipProps: {
          show: !isAllowedUpdate,
          message: FEATURE_DENY_MESSAGE,
        },
        onClick: () => editHandler("business_profile"),
      },
      {
        title: "Approve",
        hidden: isHidden({ actionItem: "approved" }),
        disabled: isPending || !isAllowedUpdate,
        onClick: handleApprove,
        sx: { color: palette.filled.success },
        tooltipProps: {
          show: !isAllowedUpdate,
          message: FEATURE_DENY_MESSAGE,
        },
      },
      {
        title: "Decline",
        hidden: isHidden({ actionItem: "declined" }),
        disabled: isPending || !isAllowedUpdate,
        onClick: () => openConfirmationHandler("declined"),
        sx: { color: palette.filled.red },
        tooltipProps: {
          show: !isAllowedUpdate,
          message: FEATURE_DENY_MESSAGE,
        },
      },
      {
        title: "Suspend",
        hidden: isHidden({ actionItem: "suspended" }),
        onClick: () => openConfirmationHandler("suspended"),
        sx: { color: palette.filled.orange },
        disabled: !isAllowedUpdate,
        tooltipProps: {
          show: !isAllowedUpdate,
          message: FEATURE_DENY_MESSAGE,
        },
      },
      {
        title: <MovePendingTitle />,
        hidden: isHidden({ actionItem: "move_back_to_pending" }),
        onClick: () => openConfirmationHandler("move_back_to_pending"),
        disabled: !isAllowedUpdate,
        tooltipProps: {
          show: !isAllowedUpdate,
          message: FEATURE_DENY_MESSAGE,
        },
      },
    ];
  }, [id, data]);
  const { openConversationHandler } = useConversationsModal();
  const sectionTitle = "Business profile";

  const { isMobileView } = useCustomTheme();

  const onClick = () =>
    openConversationHandler({ id, name: "Business Profile", paths: [] });

  return (
    <EditMerchantSection
      sectionTitle={sectionTitle}
      isAcquirer={!!isAcquirer}
      containerSx={
        isMobileView
          ? { gap: 0, "> .MuiStack-root": { justifyContent: "space-between" } }
          : {}
      }
      SubTitleSection={
        <Stack
          paddingBottom="8px"
          marginBottom={isMobileView ? "16px" : 0}
          direction="column"
          alignItems="flex-start"
          gap={1}
        >
          {isMobileView && <NewTag statusCode={status} />}
          {message && !isEmpty(message.text) && !showToolTip && (
            <StyledInfoText
              color={message.color}
              backgroundColor={message.backgroundColor}
            >
              {message.text}
            </StyledInfoText>
          )}
        </Stack>
      }
      customHeader={
        <Box
          ml={1.5}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          {!isMobileView && (
            <CustomToolTip showToolTip={showToolTip} message={message?.text}>
              <NewTag statusCode={status} />
            </CustomToolTip>
          )}
          <Stack direction="row" alignItems="center" marginLeft="auto">
            {isAcquirer && (
              <EditButton onClick={onClick}>
                <ConversationsIconNotFilled section="business-profile-(legal-entity)" />
              </EditButton>
            )}
            <StyledIconButton
              data-testid="business-profile-more-button"
              onClick={(e) => setAnchorEl(e.currentTarget)}
              size="small"
              sx={{
                ...((opened || isMobileView) && { visibility: "visible" }),
              }}
            >
              <MoreIcon size="24px" />
            </StyledIconButton>
          </Stack>
          <ActionsMenu
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            actions={actions}
            sx={{
              "& .MuiPaper-root": {
                width: 201,
              },
            }}
          />
        </Box>
      }
      items={businessProfileItems}
    />
  );
};

export default MerchantPreviewBusinessProfile;
