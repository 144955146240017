import { Box, ButtonBase } from "@mui/material";
import { Plus } from "@phosphor-icons/react";
import GiveButton from "@shared/Button/GiveButton";
import { CustomTheme } from "@theme/v2/palette.interface";
import { styled, useAppTheme } from "@theme/v2/Provider";
import GiveAvatar from "@shared/Avatar/GiveAvatar";
import GiveTruncateText from "@shared/Text/GiveTruncateText";
import placeholder from "assets/images/profile-placeholder.png";
import { AssignButtonProps } from "./GiveAssignButton.types";
import { useCallback, useState, memo } from "react";
import AssignmentSelect from "componentsV2/Table/components/AssignmentSelect";
import {
  QKEY_LIST_ACQUIRER_MERCHANTS,
  QKEY_LIST_ENTERPRISE_STATS,
  QKEY_LIST_MERCHANTS,
} from "@constants/queryKeys";
import { useQueryClient } from "react-query";
import { useUpdateAssignee } from "@components/Merchants/MerchantPreview/hooks/useGetTeamMembers";
import { MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS } from "features/Merchants/MerchantSidePanel/constants";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";

const GiveAssignButton = ({
  title = "Assign",
  disabled,
  handleClick,
  data,
  onOpening,
  isOnlyName,
}: AssignButtonProps) => {
  const theme = useAppTheme();
  const {
    accID: merchantID,
    underwriterID,
    underwriterName,
    underwriterImageURL,
    underwriterEmail,
  } = data || {};
  const displayName = isOnlyName
    ? underwriterName
    : underwriterName || underwriterEmail;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const queryClient = useQueryClient();
  const { handleChangeAssignee } = useUpdateAssignee(merchantID);
  const { isUpdateUnderwriterAllowed } = useUnderwriterPermissions();
  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event?.stopPropagation();
      setAnchorEl(event.currentTarget);
      onOpening && onOpening("toggle_tooltip", true);
    },
    [],
  );

  const closeMenu = (event?: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    onOpening && onOpening("toggle_tooltip", false);

    event?.stopPropagation();
  };

  const handleChange = (value: any) => {
    const newAssignee = value === "unassigned" ? null : value;
    const onSuccess = () => {
      if (handleClick) handleClick(newAssignee);
      queryClient.refetchQueries(QKEY_LIST_ENTERPRISE_STATS);
      queryClient.refetchQueries(QKEY_LIST_MERCHANTS);
      queryClient.refetchQueries(QKEY_LIST_ACQUIRER_MERCHANTS);
      queryClient.refetchQueries(MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS.GET);
    };
    handleChangeAssignee(underwriterID, newAssignee, onSuccess);
  };

  return (
    <>
      <StyledContainer
        disabled={disabled || !isUpdateUnderwriterAllowed}
        onClick={handleOpenMenu}
      >
        {underwriterName || underwriterEmail ? (
          <>
            <GiveAvatar
              size="24px"
              imageUrl={
                underwriterImageURL
                  ? `${underwriterImageURL}/thumb`
                  : placeholder
              }
            />
            <GiveTruncateText
              variant="bodyS"
              lineClamp={1}
              sx={{ wordBreak: "break-all", marginRight: "4px" }}
            >
              {displayName}
            </GiveTruncateText>
          </>
        ) : (
          <GiveButton
            label={title}
            disabled={disabled || !isUpdateUnderwriterAllowed}
            startIcon={
              <IconFrame>
                <Plus height={16} width={16} />
              </IconFrame>
            }
            sx={buttonStyles({ theme })}
          />
        )}
      </StyledContainer>
      <AssignmentSelect
        anchorEl={anchorEl}
        handleClose={closeMenu}
        handleChange={handleChange}
        merchantId={merchantID}
        underwriterID={data?.underwriterID}
      />
    </>
  );
};
export default memo(GiveAssignButton);

const buttonStyles = ({ theme }: { theme: CustomTheme }) => ({
  padding: 0,
  border: "none",
  color: theme.palette.text.primary,
  backgroundColor: "transparent",
  "&:hover": {
    textDecoration: "underline",
    background: "transparent",
  },
  "&.Mui-disabled": {
    textDecoration: "none",
    color: `${theme.palette.text.disabled} !important`,
  },
  "& .MuiButton-startIcon": {
    marginRight: 0,
    marginLeft: 0,
  },
});

const IconFrame = styled(Box)(({ theme }) => {
  return {
    backgroundColor: theme.palette.surface?.tertiary,
    borderRadius: "50%",
    height: 24,
    width: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "4px",
  };
});

const StyledContainer = styled(ButtonBase)(({ disabled }) => ({
  all: "unset",
  display: "flex",
  gap: "8px",
  cursor: "pointer",
  ...(disabled && {
    cursor: "default",
  }),
}));
