import { CAMPAIGN_PANEL_WIDTH } from "@common/CampaignCard/CampaignDetailsModal/useCampaignModal";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { Box, styled } from "@mui/material";
import { memo } from "react";
import { TStatusReview } from "../data.types";
import Header, { RiskProfileTabs } from "./components/Header";
import RiskProfileActivity from "./components/RiskProfileActivity/RiskProfileActivity";
import RiskProfileTriggers from "./components/RiskProfileTriggers";
import RiskActivitySkeleton from "./components/skeletons/RiskActivitySkeleton";
import Transactions from "./components/Transactions";
import { useRiskProfile } from "./hooks/useRiskProfile";
import { TMerchantRiskProfile } from "./types";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { HiddenComponent } from "@containers/HiddenComponent";

interface Props {
  handleClosePanel: () => void;
  profileId: number;
  merchantId: number;
  merchantStatus?: TStatusReview;
}

function RiskProfilePanel({
  handleClosePanel,
  profileId,
  merchantId,
  merchantStatus,
}: Props) {
  const { data, isLoading, activeTab, setActiveTab } = useRiskProfile({
    profileId,
    merchantId,
  });
  const { isRiskMonitorTransactionsEnabled } = useGetFeatureFlagValues();
  const RiskProfilePage = {
    [RiskProfileTabs.ACTIVITY]: { Component: RiskProfileActivity },
    [RiskProfileTabs.TRIGGERS]: { Component: RiskProfileTriggers },
    [RiskProfileTabs.TRANSACTIONS]: {
      Component: Transactions,
      hidden: !isRiskMonitorTransactionsEnabled,
    },
  };

  const ActivePage = RiskProfilePage[activeTab];

  return (
    <Container
      width={CAMPAIGN_PANEL_WIDTH - 30 + "px"}
      data-testid="merchant-risk-profile-container"
    >
      <FadeUpWrapper delay={50}>
        <Header
          merchantStatus={merchantStatus}
          handleClosePanel={handleClosePanel}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </FadeUpWrapper>
      <ErrorCatcher errorID="risk_monitor">
        {isLoading || profileId === undefined ? (
          <RiskActivitySkeleton />
        ) : (
          <HiddenComponent hidden={ActivePage?.hidden || false}>
            <ActivePage.Component
              data={data as TMerchantRiskProfile}
              profileId={profileId}
              merchantId={merchantId}
            />
          </HiddenComponent>
        )}
      </ErrorCatcher>
    </Container>
  );
}

const Container = styled(Box)(() => ({
  position: "relative",
  background: "rgba(251, 251, 251, 0.75)",
  flex: 1,
  height: "100%",
  borderRadius: "16px",
  boxShadow: "0px 4px 15px 0px #0000000D",
  overflow: "hidden",
  zIndex: 1,
  display: "flex",
  flexDirection: "column",
}));

export default memo(RiskProfilePanel);
