import { Stack, Box } from "@mui/material";
import { usePayBuilderContext } from "../provider/PayBuilderContext";
import { styled } from "@theme/v2/Provider";
import { stepsArray } from "../provider/usePayBuilder";
import GiveText from "@shared/Text/GiveText";

function Stepper() {
  const { activeStepIndex, goToStep, lastStepCompletedIndex } =
    usePayBuilderContext();

  return (
    <Container flexDirection="row">
      {stepsArray.map(({ label, id }, index) => {
        const isActive = index === activeStepIndex;

        return (
          <ItemBox
            onClick={() => goToStep({ index })}
            isActive={isActive}
            key={label}
          >
            <CustomDivider
              isCompleted={index <= lastStepCompletedIndex}
              isActive={isActive}
            />
            <CustomText isActive={isActive}>{label}</CustomText>
          </ItemBox>
        );
      })}

      <GiveText
        fontSize="12px"
        display={{
          xs: "block",
          sm: "none",
        }}
        color="primary"
        fontWeight={400}
      >
        {activeStepIndex + 1}/{stepsArray?.length}
      </GiveText>
    </Container>
  );
}

export default Stepper;

const Container = styled(Stack)(({ theme }) => ({
  width: "100%",
  flexDirection: "row",
  alignItems: "center",
  overflowX: "auto",
}));

const CustomText = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{ isActive?: boolean }>(({ theme, isActive = false }) => ({
  display: "block",
  color: isActive ? theme.palette.text.primary : theme.palette.text.secondary,
  visibility: "visible",
  [theme.breakpoints.down("sm")]: {
    display: "none",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const ItemBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{ isActive?: boolean }>(({ theme, isActive }) => {
  return {
    borderRadius: "8px",
    padding: "10px",
    backgroundColor: isActive
      ? theme.palette.primitive?.transparent["darken-5"]
      : "transparent",
    flexGrow: 1,
    flexBasis: 0,
    cursor: "pointer",
  };
});

const CustomDivider = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{ isActive?: boolean; isCompleted?: boolean }>(
  ({ theme, isActive = false, isCompleted = false }) => ({
    marginBottom: "9px",
    backgroundColor: (function () {
      if (isActive) {
        return theme.palette.primitive?.neutral[90];
      }
      if (isCompleted) {
        return theme.palette.primitive?.neutral[60];
      }
      return theme.palette.primitive?.transparent["darken-25"];
    })(),
    borderRadius: "2px",
    height: "2px",
    position: "relative",
    width: "100%",
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      backgroundColor: isActive
        ? theme.palette.icon?.["icon-primary"]
        : theme.palette.primitive?.transparent["darken-2"],
      height: "100%",
      width: isCompleted ? "100%" : 0,
      borderRadius: "2px ",
    },
  }),
);
