import {
  CREATE_BUSINESS_PROFILE_MODAL,
  CREATE_MERCHANT_AGREEMENT_MODAL,
  EDIT_BANK_ACCOUNT_MODAL,
  EDIT_BUSINESS_OWNER_MODAL,
  EDIT_PRIMARY_ACCOUNT_HOLDER,
  INVITE_PAH_MODAL,
} from "modals/modal_names";
import { useEffect, useMemo } from "react";
import { useQueryClient } from "react-query";
import { isEmpty } from "lodash";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  NEW_ACTION_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { IParsedData } from "@components/Merchants/MerchantPreview/data.types";
import { shouldBeHidden } from "@constants/constants";
import {
  Bank,
  Briefcase,
  Certificate,
  FileText,
  IdentificationBadge,
} from "@phosphor-icons/react";
import { defaultValues } from "@components/Merchants/CreateMerchantPanel/schemas/CreateMerchantSchema";

const useMerchantPlaceholders = (data: IParsedData) => {
  const queryClient = useQueryClient();
  const { modify_merchant, b_profile_linking } = useEnterprisePermissions();

  const totalOwnerships = useMemo(() => {
    return (
      data?.businessOwnersList?.reduce((acc, owner) => {
        const _ownership = owner.ownership ? +owner.ownership : 0;
        return acc + _ownership;
      }, 0) || 0
    );
  }, [data?.businessOwnersList]);

  const isAllowedCreatePAH = useAccessControl({
    resource: composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.OWNER),
    operation: OPERATIONS.CREATE,
  });

  const isAllowedCreateBankAccount = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.BANK_ACCOUNT,
    ),
    operation: OPERATIONS.CREATE,
  });

  const isAllowedCreateBusinessProfile = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.LEGAL_ENTITY,
    ),
    operation: OPERATIONS.CREATE,
  });

  const isAllowedCreateBusinessOwner = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.LEGAL_ENTITY,
      RESOURCE_BASE.PRINCIPAL,
    ),
    operation: OPERATIONS.CREATE,
  });

  const placeholdersList = useMemo(
    () => [
      {
        icon: <IdentificationBadge size={24} />,
        title: "Primary Account Holder",
        description:
          "The Primary Account Holder is the person who has managerial control of the merchant.",
        hidden: Boolean(data?.primaryAccountHolder.email) || !modify_merchant,
        modal: isEmpty(data?.primaryAccountHolder?.owner)
          ? INVITE_PAH_MODAL
          : EDIT_PRIMARY_ACCOUNT_HOLDER,
        merchantId: data?.merchantInfo.merchantID,
        data: data?.primaryAccountHolder,
        tooltipProps: {
          show: !isAllowedCreatePAH,
          message: NEW_ACTION_DENY_MESSAGE,
        },
      },
      {
        icon: <FileText size={24} />,
        title: "Business Profile",
        description:
          "The business profile is the legal entity the merchant is operating under.",
        hidden:
          Boolean(data?.businessProfile.taxID || data?.businessProfile?.ssn) ||
          (!modify_merchant && !b_profile_linking),
        modal: CREATE_BUSINESS_PROFILE_MODAL,
        data: defaultValues.businessProfile,
        merchantId: data?.merchantInfo.merchantID,
        merchantName: data?.merchantInfo.merchantName,
        tooltipProps: {
          show: !isAllowedCreateBusinessProfile,
          message: NEW_ACTION_DENY_MESSAGE,
        },
      },
      {
        icon: <Briefcase size={24} />,
        title: "Business Ownership",
        description:
          "Add any individual who owns 10% or more of this business.",
        hidden: data?.businessOwnersList?.length !== 0 || !modify_merchant,
        noController:
          !data?.businessProfile.taxID && !data?.businessProfile.ssn,
        modal: EDIT_BUSINESS_OWNER_MODAL,
        merchantId: data?.merchantInfo.merchantID,
        legalEntityID: data?.businessProfile.id,
        isIndividualSoleProprietorship:
          data?.businessProfile?.businessType ===
          "individual_sole_proprietorship",
        totalOwnerships,
        businessType: data?.businessProfile?.businessType,
        tooltipProps: {
          show: !isAllowedCreateBusinessOwner,
          message: NEW_ACTION_DENY_MESSAGE,
        },
      },
      {
        icon: <Bank size={24} />,
        title: "Bank Accounts",
        description: "The DDA account of the Merchant.",
        warning: data?.bankAccountList.length === 0,
        hidden: data?.bankAccountList?.length !== 0 || !modify_merchant,
        isDefault: true,
        modal: EDIT_BANK_ACCOUNT_MODAL,
        merchantId: data?.merchantInfo.merchantID,
        parentAccID: data?.merchantInfo.enterpriseID,
        tooltipProps: {
          show: !isAllowedCreateBankAccount,
          message: NEW_ACTION_DENY_MESSAGE,
        },
      },
      {
        icon: <Certificate size={24} />,
        title: "Merchant Agreement",
        description:
          "The merchant agreement outlines the terms and conditions governing the contractual relationship between the merchant and the payment processor.",
        hidden:
          data?.merchantAgreement.msaPCICompliant ||
          shouldBeHidden.merchantAgreement ||
          !modify_merchant,
        warning: !data?.status.approvedAgreement,
        merchantId: data?.merchantInfo.merchantID,
        modal: CREATE_MERCHANT_AGREEMENT_MODAL,
      },
    ],
    [data],
  );

  const isCompletedMerchant = useMemo(
    () =>
      Boolean(data?.primaryAccountHolder.email) &&
      Boolean(data?.businessProfile.taxID || data?.businessProfile?.ssn) &&
      ((data?.businessOwnersList?.length !== 0 &&
        data?.businessProfile.isController) ||
        !data?.businessProfile.isController) &&
      data?.bankAccountList?.length !== 0 &&
      data?.status.approvedAgreement,
    [data],
  );

  useEffect(() => {
    if (isCompletedMerchant) {
      queryClient.refetchQueries("list-all-merchants");
      queryClient.refetchQueries("list-acquirer-merchants");
      queryClient.refetchQueries("list-enterprise-merchants");
    }
  }, [isCompletedMerchant, data?.merchantAgreement.msaPCICompliant]);

  return {
    placeholdersList,
  };
};

export default useMerchantPlaceholders;
