import { Text } from "@common/Text";
import MarkAllAsOkModal, {
  MarkAllAsOkModalProps,
} from "@components/Merchants/MerchantPreview/modals/MarkAllAsOkModal";
import RESOURCE_BASE, {
  ACTION_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import NiceModal from "@ebay/nice-modal-react";
import { ButtonBase, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { Check } from "@phosphor-icons/react";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import { CustomToolTip as PermissionTooltip } from "@common/BusinessOwners/CustomToolTip";

const color = palette.neutral[80];

const MarkAllAsOkButton = ({
  merchantId,
  profileId,
  escalationIds,
}: MarkAllAsOkModalProps) => {
  const handleOpenModal = () => {
    NiceModal.show(MarkAllAsOkModal, { merchantId, profileId, escalationIds });
  };

  const allowedUpdateEscalation = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.RISK_PROFILE_ESCALATION,
    ),
    operation: OPERATIONS.UPDATE,
  });

  return (
    <PermissionTooltip
      showToolTip={!allowedUpdateEscalation}
      message={ACTION_DENY_MESSAGE}
    >
      <ButtonBase
        data-testid="risk-mark-all-as-ok-btn"
        disabled={!allowedUpdateEscalation}
        onClick={handleOpenModal}
      >
        <Stack direction="row" gap={0.5} paddingRight={1} alignItems="center">
          <Check color={color} size={20} />
          <StyledText color={color} lineHeight="16.8px">
            Mark all as OK
          </StyledText>
        </Stack>
      </ButtonBase>
    </PermissionTooltip>
  );
};

const StyledText = styled(Text)({
  "&:hover": {
    textDecoration: "underline",
  },
});

export default MarkAllAsOkButton;
