import MerchantMainInfo from "@components/AcquirerMerchants/MerchantList/MerchantMainInfo";
import {
  MerchantData,
  LastMessageActivityType,
} from "../account/useGetMerchants";
import { capitalizeEachWord, parseAmount } from "@utils/index";
import { TableColumnType } from "@components/common/Table";
import { Box } from "@mui/material";
import { Text } from "@components/common/Text";
import { format, formatDistanceToNow, fromUnixTime } from "date-fns";
import { palette } from "@palette";
import { Stack } from "@mui/material";
import useWatchlist from "./watchlist/useAddMerchantToWatchlist";
import { QKEY_LIST_ACQUIRER_MERCHANTS } from "@constants/queryKeys";
import TextTag from "@components/common/TextTag";
import { selectMerchantStatusName } from "@redux/slices/enterprise/merchants";
import { useMemo } from "react";
import { useAppSelector } from "@redux/hooks";
import { StatusNames } from "@components/Merchants/MerchantList/MerchantStatusFilters";
import UnderwritingLevel, {
  CanManageMoneyIcons,
} from "@components/common/Table/UnderwritingLevel";
import NewAssignmentSelect from "@components/NewAssignmentSelect/NewAssignmentSelect";
import { Tooltip } from "@components/common/Tooltip";
import RiskLevelColItem from "@common/Table/RiskLevelColItem";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import WarningIcon from "@assets/icons/RebrandedIcons/WarningIcon";
import RiskStatusChip from "@common/Tag/RiskStatusChip";
import { TruncateText } from "@common/Text";
import { Image } from "@common/StyledImage/Image";
import placeholder from "assets/images/Placeholder.png";
import { TAG_STATUSES_ENUM } from "@common/TextTag/types";

export const isMerchantInvited = (status?: string): boolean => {
  return (
    status === "sent" ||
    status === "read" ||
    status === "delivered" ||
    status === "invited"
  );
};

export const getMerchantStatus = (
  rowData: MerchantData,
  showInviteStatus?: boolean,
) => {
  const sponsorStatus = rowData?.sponsorStatusDisplayName || "";
  const ownerMembershipStatus = rowData?.ownerMembershipStatus;
  const statusDisplayName = rowData?.statusDisplayName;

  const ownerInviteStatusDisplayName = rowData?.ownerInviteStatusDisplayName;
  // for the "Invited" tab show the specific invitation status
  if (
    showInviteStatus &&
    rowData?.ownerMembershipStatus === "invited" &&
    ownerInviteStatusDisplayName
  ) {
    return ownerInviteStatusDisplayName;
  }

  if (isMerchantInvited(ownerMembershipStatus)) {
    return "Invite sent";
  }

  if (statusDisplayName === "Pre Approved") {
    return "Underwriting";
  }

  if (["Pre-approval", "Auto-approval"].includes(statusDisplayName)) {
    return sponsorStatus;
  }

  return statusDisplayName;
};

export const useGetColumns = ({
  isAcquirerPortal,
}: {
  isAcquirerPortal?: boolean;
}) => {
  const statusName = useAppSelector(selectMerchantStatusName);
  const { risk_monitoring } = useEnterprisePermissions();

  const { addMerchantToWatchlist, removeMerchantFromWatchlist } = useWatchlist(
    false,
    QKEY_LIST_ACQUIRER_MERCHANTS,
  );

  const addToWatchlistHandler = (row?: MerchantData) => {
    if (!row) return;
    addMerchantToWatchlist(row.accID, row.name);
  };

  const underwritingStatus = isAcquirerPortal
    ? StatusNames.aquirerUnderwriting
    : StatusNames.underwritingAsProvider;
  const riskOrApprovedStatus = StatusNames.aquirerRisk;
  const sponsorStatus = StatusNames.aquirerSponsor;

  const isSponsorTab = statusName === sponsorStatus;
  const isRiskTab = statusName === StatusNames.aquirerRisk;

  const processingVolCol = useMemo(() => {
    return {
      key: "totalProcessed,-accID",
      sortable: true,
      title: "Processing Volume",
      headerPosition: "right",
      columnWidth: 0.55,
      renderColumn: (row: MerchantData) => {
        const revenue = parseAmount(row.totalProcessed / 100);
        return (
          <Box flexGrow={1} minWidth="124px">
            <Text color={palette.black[200]} textAlign="end">
              {revenue} USD
            </Text>
          </Box>
        );
      },
    };
  }, []);

  const signupDateCol = useMemo(() => {
    return {
      key: "createdAt,-accID",
      sortable: true,
      title: "Signup Date",
      columnWidth: 0.55,
      renderColumn: (row: MerchantData) => {
        return (
          <Box flexGrow={1} minWidth="90px">
            <Text color={palette.black[200]} textAlign="start">
              {format(row.createdAt * 1000, "MMM dd, yyyy")}
            </Text>
          </Box>
        );
      },
    };
  }, []);
  const InvitationDateCol = useMemo(() => {
    return {
      key: "ownerInviteLastSentAt,-accID",
      sortable: true,
      title: "Invitation Date",
      columnWidth: 0.55,

      renderColumn: (row: MerchantData) => {
        return (
          <Box flexGrow={1} minWidth="90px">
            <Text color={palette.black[200]} textAlign="start">
              {format(row?.ownerInviteLastSentAt * 1000, "MMM dd, yyyy")}
            </Text>
          </Box>
        );
      },
    };
  }, [statusName]);
  const emailCol = useMemo(() => {
    return {
      key: "ownerEmail,-accID",
      sortable: true,
      title: "Email",
      columnWidth: 1,
      renderColumn: (row: MerchantData) => {
        return (
          <Box flexGrow={1} minWidth="90px">
            <Text fontSize="14px" color={palette.black[200]} textAlign="start">
              {row?.ownerEmail}
            </Text>
          </Box>
        );
      },
    };
  }, []);
  const riskStatusCol = useMemo(() => {
    return {
      key: "merchantRiskStatusSortOrder,-accID",
      sortable: true,
      title: "Risk Category",
      columnWidth:
        statusName === sponsorStatus
          ? 0.5
          : statusName === riskOrApprovedStatus
          ? 1
          : 0.6,
      renderColumn: (row: MerchantData) => {
        return (
          <Tooltip
            maxWidth="263px"
            bgColor={palette.neutral[80]}
            titleSx={{
              textAlign: "left",
            }}
            disableHoverListener={
              !row?.categoryCode ||
              !row?.categoryCodeTitle ||
              !row?.merchantRiskStatus
            }
            title={
              <Text color={palette.neutral[5]}>
                {row?.categoryCode}- {row?.categoryCodeTitle}
              </Text>
            }
          >
            <RiskStatusChip riskLevelName={row?.merchantRiskStatus} />
          </Tooltip>
        );
      },
    };
  }, []);

  const statusColV2 = useMemo(() => {
    const isInvitedColumnStatus = statusName === StatusNames?.invited;
    return {
      key: `${
        isInvitedColumnStatus
          ? "ownerInviteStatusDisplayName"
          : "statusSortOrder"
      },-accID`,
      sortable: true,
      title: "Status",
      columnWidth: 0.5,
      renderColumn: (row: MerchantData) => {
        const status = getMerchantStatus(
          row,
          isInvitedColumnStatus,
        ) as TAG_STATUSES_ENUM;
        const statusData = {
          [TAG_STATUSES_ENUM.APPROVED]: {
            name: `${row?.approvedByFirstName} ${row?.approvedByLastName}`,
            updatedAt: Number(row?.approvedAt || 0),
          },
          [TAG_STATUSES_ENUM.SUSPENDED]: {
            name: `${row?.suspendedByFirstName} ${row?.suspendedByLastName}`,
            updatedAt: Number(row?.suspendedAt || 0),
          },
          [TAG_STATUSES_ENUM.DECLINED]: {
            name: `${row?.declinedByFirstName} ${row?.declinedByLastName}`,
            updatedAt: Number(row?.declinedAt || 0),
          },
          [TAG_STATUSES_ENUM.INVITE]: {
            updatedAt: Number(row?.OwnerInviteLastSentAt || 0),
          },
        } as Record<TAG_STATUSES_ENUM, { name?: string; updatedAt: number }>;

        return (
          <TextTag
            maxWidth="135px"
            statusDisplayName={status}
            updatedBy={statusData[status]?.name}
            updatedAt={statusData[status]?.updatedAt}
          />
        );
      },
    };
  }, [statusName]);

  const assignmentCol = useMemo(() => {
    return {
      key: "assignment",
      sortable: false,

      title:
        statusName === riskOrApprovedStatus || statusName === underwritingStatus
          ? "Assignee"
          : "Assignment",
      columnWidth: statusName === riskOrApprovedStatus ? 1 : 0.5,
      columnType: "assignment",
      renderColumn: (row: MerchantData) => (
        <Tooltip
          title={row?.underwriterName || row?.underwriterEmail}
          disableHoverListener={!row.underwriterID}
        >
          <NewAssignmentSelect
            data={row}
            type={statusName === riskOrApprovedStatus ? "risk" : "underwriting"}
          />
        </Tooltip>
      ),
    };
  }, [statusName]);

  const statusNameCol = useMemo(() => {
    return {
      key: "underwritingChallengeScorePercentage,-accID",
      sortable: true,
      title: "Underwriting Score",
      columnWidth: 1,
      columnType: "phase",
      renderColumn: (row: MerchantData) => {
        return (
          <UnderwritingLevel
            statusDisplayName={row?.statusDisplayName}
            score={row?.underwritingChallengeScorePercentage}
            canProcessMoney={row?.canProcessMoney}
            canTransferMoney={row?.canTransferMoney}
            pendingScore={row.underwritingPendingChallengesPercentage}
            pendingChallenges={row?.underwritingPendingChallengesCount}
            hideIcons
            alwaysDisplayProgressBars
          />
        );
      },
    };
  }, []);

  const ageEscalationCol = useMemo(() => {
    return {
      key: "lastEscalationAt,-accID",
      sortable: true,
      title: "Age of escalation",
      columnWidth: 1,
      renderColumn: (row: MerchantData) => {
        const risk = row.riskLevelLabel || "low";

        const parsedDate =
          risk !== "low" && row.lastEscalationAt
            ? formatDistanceToNow(fromUnixTime(row.lastEscalationAt), {
                addSuffix: true,
              })
            : "-";

        return (
          <Box flex={1} minWidth="90px">
            <Text color={palette.black[200]} textAlign="start">
              {parsedDate}
            </Text>
          </Box>
        );
      },
    };
  }, [statusName]);

  const riskLevelCol = useMemo(() => {
    return {
      key: "riskSortOrder,-accID",
      sortable: true,
      title: "Risk Level",
      columnWidth: 0.65,
      renderColumn: (row: MerchantData) => {
        return <RiskLevelColItem row={row} />;
      },
    };
  }, []);

  const mccCol = useMemo(() => {
    return {
      key: "categoryCode,-accID",
      sortable: false,
      title: "MCC",
      columnWidth: 0.6,
      renderColumn: (row: MerchantData) => {
        return (
          <Stack>
            <TruncateText lineClamp={1}>{row.categoryCodeTitle}</TruncateText>
            <Text fontSize={12} fontWeight="book" color={palette.gray[300]}>
              {row.categoryCode}
            </Text>
          </Stack>
        );
      },
    };
  }, []);

  const approvalCol = useMemo(() => {
    return {
      key: "lastInternalApprovalTypeDisplayName,-accID",
      sortable: true,
      title: "Approval",
      columnWidth: 0.5,
      renderColumn: (row: MerchantData) => {
        const statusName =
          row.lastInternalApprovalTypeDisplayName?.replaceAll("-", " ") ?? "";
        const date = row.lastInternalApprovalAt ?? 0;
        return (
          <Stack>
            <Text>{capitalizeEachWord(statusName)}</Text>
            <Text fontSize={12} fontWeight="book" color={palette.gray[300]}>
              {format(date * 1000, "MMM dd, yyyy")}
            </Text>
          </Stack>
        );
      },
    };
  }, []);

  const sponsorStatusCol = useMemo(() => {
    return {
      key: "sponsorStatusName,-accID",
      sortable: true,
      title: "Sponsor Status",
      columnWidth: 0.5,
      renderColumn: (row: MerchantData) => {
        const sponsorStatusDisplayName = row?.sponsorStatusDisplayName || "";
        const status =
          !!sponsorStatusDisplayName &&
          !["Approved", "Declined", "Pending"].includes(
            sponsorStatusDisplayName,
          )
            ? "Ready for Review"
            : sponsorStatusDisplayName;
        return (
          <TextTag maxWidth="135px" statusDisplayName={status} disableTooltip />
        );
      },
    };
  }, []);

  const extraCols = useMemo(() => {
    switch (statusName) {
      case "":
        return [signupDateCol, riskStatusCol, processingVolCol, statusColV2];

      case underwritingStatus:
        return [
          signupDateCol,
          assignmentCol,
          riskStatusCol,
          statusNameCol,
          statusColV2,
        ];
      case riskOrApprovedStatus:
        return [assignmentCol, riskStatusCol, ageEscalationCol, riskLevelCol];
      case sponsorStatus:
        return [mccCol, riskStatusCol, approvalCol, sponsorStatusCol];

      case StatusNames?.invited:
        return [emailCol, InvitationDateCol, statusColV2];
      default:
        return [processingVolCol, signupDateCol, riskStatusCol, statusColV2];
    }
  }, [statusName]);

  const isWarnedMerchant = (row: MerchantData) => {
    if (!row?.isProfileCompleted) return true;
    const isOfacMatch = ["possible_match", "confirmed_match"].includes(
      row?.entityOfac?.lastCheckStatusName,
    );

    const isRiskLevel =
      row?.riskLevelLabel && row?.riskLevelLabel !== "low" && risk_monitoring;

    return isRiskLevel || isOfacMatch;
  };

  const columns: TableColumnType[] = [
    {
      key: "name",
      sortable: true,
      title: isSponsorTab ? "Merchant Name" : "Merchant",
      columnWidth:
        statusName === StatusNames?.invited
          ? 1
          : statusName === riskOrApprovedStatus
          ? 1.7
          : 1.3,
      columnType: "merchant-first",
      renderColumn: (row: MerchantData) => {
        return (
          <Stack
            direction="row"
            spacing={1}
            minWidth={"200px"}
            alignItems="center"
            width="100%"
          >
            <MerchantMainInfo
              row={row}
              removeFromWatchlistHandler={() =>
                removeMerchantFromWatchlist(row?.accID, row?.name)
              }
              addToWatchlistHandler={() => addToWatchlistHandler(row)}
              isAcquirerPortal={isAcquirerPortal}
              badgeTooltipProps={
                row?.hasUnrepliedActivityMessages && row?.lastMessageActivity
                  ? {
                      bgColor: palette.neutral.white,
                      title: (
                        <BadgeTooltipContent data={row?.lastMessageActivity} />
                      ),
                      maxWidth: "235px",
                    }
                  : undefined
              }
            />
            {isRiskTab && (
              <Stack direction="row" spacing={1} data-testid="can-manage-money">
                <CanManageMoneyIcons
                  canProcessMoney={row?.canProcessMoney}
                  canTransferMoney={row?.canTransferMoney}
                />
              </Stack>
            )}
            {isWarnedMerchant(row) && <WarningIcon height={18} width={18} />}
          </Stack>
        );
      },
    },
    ...extraCols,
  ];
  return {
    columns,
    removeMerchantFromWatchlist,
    addToWatchlistHandler,
    isWarnedMerchant,
    isSponsorTab,
    isRiskTab,
  };
};

const BadgeTooltipContent = ({ data }: { data: LastMessageActivityType }) => (
  <Stack direction="row" spacing="12px">
    <Stack color={palette.neutral[80]} width="179px" textAlign="left">
      <Text fontWeight="book" fontSize={12}>
        Notified Merchant
      </Text>
      <Stack direction="row" spacing="4px">
        <Text
          color={palette.neutral[70]}
          fontWeight="book"
          fontSize={12}
          minWidth={46}
        >
          Subject:
        </Text>
        <Text noWrap fontWeight="book" fontSize={12}>
          {data.subject}
        </Text>
      </Stack>
      <Stack direction="row" spacing="4px">
        <Text
          color={palette.neutral[70]}
          fontWeight="book"
          fontSize={12}
          minWidth={46}
        >
          Replied:
        </Text>
        <Text fontWeight="book" fontSize={12}>
          {formatDistanceToNow(fromUnixTime(data.createdAt), {
            addSuffix: true,
          })}
        </Text>
      </Stack>
    </Stack>
    <Image
      height={20}
      width={20}
      sx={{
        borderRadius: "50%",
      }}
      src={data?.authorAvatar ? `${data.authorAvatar}/thumb` : placeholder}
    />
  </Stack>
);
