import { DropdownItem } from "@common/Select";
import { Text } from "@common/Text";
import { Menu } from "@mui/material";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  MERCHANT_PREVIEW_PANEL_MODAL,
  MERCHANT_VIEW_CUSTOMER,
  MERCHANT_VIEW_TRANSACTION_MODAL,
  PROCESSING_CANCEL_TRANSACTION_MODAL,
  PROCESSING_MAKE_RECURRING_MODAL,
  PROCESSING_REFUND_TRANSACTION_MODAL,
  PROCESSING_SEND_RECEIPT_MODAL,
} from "modals/modal_names";
import { TransactionData } from "@components/ManageMoney/TransactionTable/data";
import { getSourceAccount } from "@components/ManageMoney/TransactionTable/transactions.helpers";
import { HiddenComponent } from "containers/HiddenComponent";
import { MobileActionItemProps } from "@components/MobileDrawerActions/hooks/useMobileDrawerActions";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  FEATURE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import { TransactionStatus } from "@components/ManageMoney/TransactionTable/TransactionInfoModal";

export const editMerchantHandler = (id?: number) => {
  NiceModal.show(MERCHANT_PREVIEW_PANEL_MODAL, { id });
};

export const refundHandler = ({ sourceAccount, ids }: any) => {
  NiceModal.show(PROCESSING_REFUND_TRANSACTION_MODAL, {
    sourceAccount,
    ids,
  });
};

export const makeRecurringHandler = (id?: string) => {
  NiceModal.show(PROCESSING_MAKE_RECURRING_MODAL, { id });
};

const cancelHandler = (data: any) => {
  NiceModal.show(PROCESSING_CANCEL_TRANSACTION_MODAL, data);
};

export const sendReceiptHandler = (row?: Record<string, any>) => {
  NiceModal.show(PROCESSING_SEND_RECEIPT_MODAL, {
    transactionID: row?.id,
    emails: [row?.sourceAccount.user.email],
    sourceAccount: getSourceAccount(row as any),
    customerID: row?.sourceAccount.id,
    isChargebackReversal: row?.status === TransactionStatus.CHARGEBACK_REVERSAL,
  });
};

export const viewMerchantTransactionsHandler = (row?: TransactionData) => {
  NiceModal.show(MERCHANT_VIEW_TRANSACTION_MODAL, { data: row });
};

const viewCustomerHandler = (customerId?: any, merchantId?: any) => {
  if (customerId)
    NiceModal.show(MERCHANT_VIEW_CUSTOMER, {
      id: customerId,
      merchantId,
    });
};

export function DMenu({ anchorEl, open, setAnchorEl, selectedRow }: any) {
  const isVoided = selectedRow?.displayStatus === "Voided";
  const isChargeback = selectedRow?.status === "Chargeback";
  const isRefund = selectedRow?.status === "Refunded";
  const isTransfer = selectedRow?.displayType === "Money Transfer";
  const isPendingTransfer =
    isTransfer && selectedRow?.displayStatus === "Requested";

  const isHideRefund =
    selectedRow?.hideRefund ||
    isVoided ||
    selectedRow.isHideRefund ||
    selectedRow?.displayStatus === "Sent" ||
    selectedRow?.displayStatus === "Refunded" ||
    isTransfer ||
    isChargeback;
  const isHideSendReceipt =
    isTransfer || isChargeback || isVoided || selectedRow?.hideSendReceipt;

  const isRefundAllowed = useAccessControl({
    resource: composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.REFUND),
    operation: OPERATIONS.CREATE,
  });
  const isCancelTransferAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.BANK_TRANSFER,
    ),
    operation: OPERATIONS.UPDATE,
  });

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        "& .MuiPaper-root": {
          width: "inherit",
        },
      }}
    >
      <WithHideAnchorItem
        selectedRow={selectedRow}
        onClick={refundHandler}
        title="Refund"
        hidden={isHideRefund}
        anchorEl={anchorEl}
        disabled={!isRefundAllowed}
        tooltipProps={{
          show: !isRefundAllowed,
          message: FEATURE_DENY_MESSAGE,
        }}
        setAnchorEl={setAnchorEl}
        onClickPayload={{
          sourceAccount: selectedRow ? getSourceAccount(selectedRow) : {},
          ids: {
            transactionIDs: [selectedRow?.id],
          },
        }}
      />

      <WithHideAnchorItem
        hidden={isHideSendReceipt}
        selectedRow={selectedRow}
        onClick={() => sendReceiptHandler(selectedRow)}
        title="Send Receipt"
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />

      <WithHideAnchorItem
        selectedRow={selectedRow}
        onClick={editMerchantHandler}
        hidden={!isTransfer}
        title="View Merchant"
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        closeCurrentModal
      />
      <WithHideAnchorItem
        selectedRow={selectedRow}
        onClick={() =>
          viewCustomerHandler(
            selectedRow?.customer?.id,
            !isChargeback && !isRefund
              ? selectedRow?.destinationAccount?.id
              : selectedRow?.sourceAccount?.id,
          )
        }
        hidden={isTransfer}
        title="View customer"
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        closeCurrentModal
      />
      <WithHideAnchorItem
        selectedRow={selectedRow}
        onClick={() =>
          cancelHandler({
            transactionId: selectedRow?.id,
            cancel: true,
            sourceAccount: getSourceAccount(selectedRow),
            customerID: selectedRow?.destinationAccount.id,
          })
        }
        title="Cancel Transfer"
        anchorEl={anchorEl}
        disabled={!isCancelTransferAllowed}
        setAnchorEl={setAnchorEl}
        hidden={!isPendingTransfer}
        tooltipProps={{
          show: !isCancelTransferAllowed,
          message: FEATURE_DENY_MESSAGE,
        }}
      />
    </Menu>
  );
}

export function WithHideAnchorItem({
  anchorEl,
  onClick,
  title,
  selectedRow,
  setAnchorEl,
  onClickPayload,
  hidden,
  disabled = false,
  closeCurrentModal = false,
  tooltipProps,
}: any) {
  const modal = useModal();
  const id =
    selectedRow?.sourceAccount?.type === "user"
      ? selectedRow?.destinationAccount?.id
      : selectedRow?.sourceAccount?.id;

  return (
    <HiddenComponent hidden={Boolean(hidden)}>
      <WithTooltipWrapper
        hasTooltip={!!tooltipProps}
        tooltipProps={tooltipProps}
      >
        <DropdownItem
          disabled={disabled}
          onClick={() => {
            if (anchorEl) setAnchorEl(null);
            if (closeCurrentModal) modal.hide();
            onClick(onClickPayload ?? id);
          }}
        >
          <Text>{title}</Text>
        </DropdownItem>
      </WithTooltipWrapper>
    </HiddenComponent>
  );
}

export const useMobileProcessingActions = () => {
  const modal = useModal();

  const getMobileActions = (data: any) => {
    const isTransfer = data?.displayType === "Money Transfer";
    const isChargeback = data?.status === "Chargeback";
    const isRefund = data?.status === "Refunded";

    const merchantID =
      !isChargeback && !isRefund
        ? data?.destinationAccount?.id
        : data?.sourceAccount?.id;

    const closeCurrentModal = () => modal.hide();

    const mobileActions: MobileActionItemProps[] = [
      {
        label: "View Merchant",
        hidden: !isTransfer,
        onSelect: () => {
          closeCurrentModal();
          editMerchantHandler(merchantID);
        },
      },
    ];

    return mobileActions;
  };

  return { getMobileActions };
};
