import { Components, IconButtonProps } from "@mui/material";
import { palette } from "@palette";
import { isSecondVersion } from "@theme/v2/utils";

export const iconButton: Components["MuiIconButton"] = {
  defaultProps: {
    disableRipple: true,
    disableFocusRipple: true,
    version: "one",
  },

  styleOverrides: {
    root: {
      variants: [
        {
          props: (props: IconButtonProps) =>
            !isSecondVersion(props.version || "one"),
          style: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "44px",
            borderRadius: "6px",
            border: "2px solid #f1f1f1",
            // borderColor: "red",
            // background: "linear-gradient(0deg, #EFF4F8, #EFF4F8),linear-gradient(267.62deg, rgba(214, 227, 243, 0.46) 8.2%, rgba(255, 255, 255, 0.46) 82.68%),linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)",
            // background: "linear-gradient(267.62deg, rgba(214, 227, 243, 0.46) 8.2%, rgba(255, 255, 255, 0.46) 82.68%),linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%),linear-gradient(0deg, #FFFFFF, #FFFFFF)",
            // position: "relative",
            // zIndex: "2",
            backgroundColor: palette.common.white,
            boxShadow:
              "2px 2px 4px rgba(114, 142, 171, 0.1), -2px -2px 20px #FFFFFF, 4px 4px 16px rgba(111, 140, 176, 0.41)",

            "&:hover": {
              backgroundColor: "#EFF4F8",
              boxShadow:
                "2px 2px 4px rgba(114, 142, 171, 0.1), -2px -2px 20px #FFFFFF, 4px 4px 16px rgba(111, 140, 176, 0.41)",
            },
            "&:active": {
              // borderColor: "transparent",
              boxShadow:
                "inset -4px -4px 9px rgba(255, 255, 255, 0.88), inset 4px 4px 14px #C1D5EE",
            },
            "&:focus": {
              // borderColor: "transparent",
              boxShadow:
                "inset -4px -4px 9px rgba(255, 255, 255, 0.88), inset 4px 4px 14px #C1D5EE",
            },
            "&:disabled": {
              boxShadow:
                "inset -4px -4px 9px rgba(255, 255, 255, 0.88), inset 0px 2px 14px rgba(193, 208, 238, 0.5)",
              span: {
                opacity: 0.2,
              },
            },
          },
        },
      ],
    },
  },
};
