import { Grid, Stack } from "@mui/material";
import GiveProgressBar from "./GiveProgressBar";
import { GiveUnderwritingProgressItem } from "./GiveProgressBar.types";
import { CheckCircle, CircleDashed } from "@phosphor-icons/react";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme } from "@theme/v2/Provider";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

interface GiveUnderwritingProgressBarProps {
  completed: number;
  incomplete: number;
  disabled: number;
  width?: string | number;
  showStatus?: boolean;
}

export default function GiveUnderwritingProgressBar({
  completed,
  incomplete,
  disabled,
  width,
  showStatus = true,
}: GiveUnderwritingProgressBarProps) {
  const total = completed + incomplete + disabled;
  const values: Record<string, GiveUnderwritingProgressItem> = {
    completed: {
      value: (100 * completed) / total,
      variant: "completed",
      type: "underwriting",
      hidden: completed < 1,
    },
    incomplete: {
      value: (100 * incomplete) / total,
      variant: "incomplete",
      type: "underwriting",
      hidden: incomplete < 1,
    },
    disabled: {
      value: (100 * disabled) / total,
      variant: "disabled",
      type: "underwriting",
      hidden: disabled < 1,
    },
  };
  return (
    <Stack gap="12px">
      <Grid
        container
        p="0px !important"
        m="0px !important"
        alignItems="center"
        wrap="nowrap"
        width={width}
        flex={1}
      >
        {Object.values(values)
          .filter((item) => !item.hidden)
          .map((item) => (
            <GiveProgressBar
              key={item.variant}
              value={item.value}
              type={item.type}
              variant={item.variant}
            />
          ))}
      </Grid>
      {showStatus && (
        <ProgressStatus
          completed={completed}
          incomplete={incomplete}
          disabled={disabled}
        />
      )}
    </Stack>
  );
}

const ProgressStatus = ({
  completed,
  incomplete,
  disabled,
}: GiveUnderwritingProgressBarProps) => {
  const { palette } = useAppTheme();
  const { isMobileView } = useCustomThemeV2();

  const items = [
    {
      label: "Completed",
      value: completed,
      icon: (
        <CheckCircle
          size={24}
          color={palette.primitive?.success[50]}
          weight="fill"
        />
      ),
      hidden: completed < 1,
    },
    {
      label: "Ready for confirmation",
      value: incomplete,
      icon: <CheckCircle size={24} color={palette.primitive?.success[50]} />,
      hidden: incomplete < 1,
    },
    {
      label: "Open",
      value: disabled,
      icon: <CircleDashed size={24} color={palette.icon?.["icon-secondary"]} />,
      hidden: disabled < 1,
    },
  ];
  return (
    <Stack
      direction={isMobileView ? "column" : "row"}
      alignItems={isMobileView ? "flex-start" : "center"}
      gap={isMobileView ? 1 : 3}
    >
      {items
        .filter((item) => !item.hidden)
        .map(({ icon, label, value }, index) => (
          <Stack key={index} direction="row" alignItems="center" gap="4px">
            {icon}
            <GiveText
              variant="bodyS"
              color="secondary"
            >{`${label} (${value})`}</GiveText>
          </Stack>
        ))}
    </Stack>
  );
};
