import { Text } from "@common/Text";
import { UploadFileNew } from "@components/UploadFile/Rebranded/UploadFileNew";
import { AcceptAllowedGeneralDocumentsTypes } from "@hooks/upload-api/uploadHooks";
import { Box, Stack } from "@mui/material";
import { palette } from "@palette";
import { DocumentItem } from "../components/DocumentItem";
import { RHFInput } from "@common/Input";
import { ReportFile } from "./types";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import { ACTION_DENY_MESSAGE } from "@constants/permissions";
import { useGetUploadFilePermission } from "@hooks/merchant-api/manage-money/useUploadBankAccountDocument";

interface Props {
  viewOnly?: boolean;
  isModal?: boolean;
  onUpload?: (file: File) => void;
  onDelete?: (file: ReportFile) => void;
  files?: any[];
  merchantID: number;
}

export default function ReportFields({
  viewOnly = false,
  onUpload,
  onDelete,
  files,
  merchantID,
  isModal,
}: Props) {
  const isUploadAllowed = useGetUploadFilePermission();
  return (
    <>
      <RHFInput
        name="findings"
        label="Findings"
        multiline
        disabled={viewOnly}
        fullWidth
        sx={{
          "& .MuiInputBase-root": {
            borderWidth: "1px",
            color: palette.neutral[80],
            fontSize: "14px",
            fontWeight: 350,
            height: isModal ? "180px" : "340px",
            alignItems: "flex-start",
            overflowY: "auto",
          },
          "& .MuiInputBase-root.Mui-disabled": {
            backgroundColor: palette.common.white,
            border: `1px solid ${palette.liftedWhite[200]}`,

            "& textarea": {
              WebkitTextFillColor: palette.neutral[80],
            },
          },
          "& .MuiInputLabel-root": {
            fontSize: "14px",
            fontWeight: 350,
          },
        }}
      />
      <Text
        fontSize="14px"
        color={palette.neutral[80]}
        pt={isModal ? 0 : "24px"}
        pb={isModal ? 0 : "12px"}
      >
        Evidence
      </Text>
      {!viewOnly && onUpload && (
        <Box>
          <WithTooltipWrapper
            hasTooltip={!isUploadAllowed}
            tooltipProps={{
              show: !isUploadAllowed,
              message: ACTION_DENY_MESSAGE,
            }}
          >
            <UploadFileNew
              uploadFunction={onUpload}
              maxFiles={5}
              multiple
              disabled={!isUploadAllowed}
              accept={AcceptAllowedGeneralDocumentsTypes}
            />
          </WithTooltipWrapper>
        </Box>
      )}
      {files?.map((document) => (
        <Box key={document.id}>
          <DocumentItem
            merchantID={merchantID}
            {...document}
            list={files}
            tag="MATCH Evidence"
            isUpdatePrevented
            showPreviewIcon
            localOnly
            isDeleteHidden={viewOnly}
            showActionButtonMobile
            localDeleteHandler={() => onDelete?.(document)}
          />
        </Box>
      ))}
      {(!files || files?.length === 0) && viewOnly && (
        <Stack flex={1} justifyContent="center" alignItems="center">
          <Text
            color={palette.neutral[80]}
            fontSize="14px"
            p="24px 16px"
            textAlign="center"
          >
            No evidence document was attached
          </Text>
        </Stack>
      )}
    </>
  );
}
