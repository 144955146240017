import { useMemo } from "react";
import { DynamicReturnType } from "../helpers/refineData";
import { checkPortals } from "@utils/routing";

type TProps = {
  parsedData: DynamicReturnType;
};

const requiredFieldsMap = {
  businessDetails: {
    tabName: "Business details",
    requiredFields: [
      "legalName",
      "taxIDNumber",
      "businessOpenedAt",
      "businessType",
      "ownershipType",
      "phoneNumber",
    ],
  },
  businessAddress: {
    tabName: "Business address",
    requiredFields: ["address", "city", "country", "state", "zip"],
  },
  businessOwner: {
    tabName: "Business owner",
    requiredFields: [
      "firstName",
      "lastName",
      "email",
      "DOB",
      "ssn",
      "ownership",
    ],
  },
  merchantInfo: {
    tabName: "Merchant Info",
    requiredFields: [
      "name",
      "websiteURL",
      "servicePhoneNumber",
      "billingDescriptor",
    ],
  },
};

const checkIncompleteFields = (data: any, fields: string[]) => {
  return fields.some((field) => {
    return (
      !data[field] ||
      data[field]?.trim() === "" ||
      (field === "servicePhoneNumber" && data[field]?.trim() === "+1")
    );
  });
};

const useGenerateWarning = ({ parsedData }: TProps) => {
  const { isEnterprisePortal } = checkPortals();

  const results = useMemo(() => {
    if (isEnterprisePortal) {
      return {
        isIncomplete: false,
        completionStatus: null,
        warningMessage: null,
      };
    }

    const results = Object.entries(requiredFieldsMap).reduce(
      (acc, [key, { tabName, requiredFields }]) => {
        let isIncomplete = false;

        if (key === "businessOwner") {
          // Handle businessOwners separately
          const owners = parsedData.businessOwners;

          if (!owners) {
            isIncomplete = true;
          } else {
            isIncomplete = requiredFields.some((field) =>
              owners.some(
                (owner) =>
                  typeof owner[field as keyof typeof owner] === "string" &&
                  (owner[field as keyof typeof owner] as string).trim() === "",
              ),
            );
          }
        } else {
          // Handle other tabs
          const data = parsedData[key as keyof typeof parsedData];
          isIncomplete =
            !data ||
            (typeof data === "object" &&
              checkIncompleteFields(data, requiredFields));
        }

        acc.completionStatus[key] = { isComplete: !isIncomplete, tabName };
        if (isIncomplete) {
          acc.incompleteTabs.push(tabName);
        }
        return acc;
      },
      { completionStatus: {}, incompleteTabs: [] } as {
        completionStatus: Record<
          string,
          { isComplete: boolean; tabName: string }
        >;
        incompleteTabs: string[];
      },
    );

    return {
      isIncomplete: results.incompleteTabs.length > 0,
      completionStatus: results.completionStatus,
      warningMessage:
        results.incompleteTabs.length > 0
          ? `${results.incompleteTabs.join(", ")}.`
          : null,
    };
  }, [parsedData]);

  return results;
};

export default useGenerateWarning;
