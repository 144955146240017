import React, { cloneElement } from "react";
import { Box, Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { CustomTheme } from "@theme/v2/palette.interface";
import { styled } from "@theme/v2/Provider";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";

type Props = {
  title: string;
  icon: React.ReactElement;
  description?: string;
};

const SectionHeader = ({ title, icon, description }: Props) => {
  const { parsedValues } = usePayBuilderForm();
  return (
    <Stack
      direction="row"
      alignItems={description ? "flex-start" : "center"}
      gap="20px"
    >
      <IconContainer color={parsedValues.accentColor}>
        {cloneElement(icon, {
          color: parsedValues.accentColor,
        })}
      </IconContainer>
      <Stack>
        <GiveText variant="h3" fontWeight={300} color="primary">
          {title}
        </GiveText>
        {description && (
          <GiveText variant="bodyS" color="secondary">
            {description}
          </GiveText>
        )}
      </Stack>
    </Stack>
  );
};

export default SectionHeader;

const IconContainer = styled(Box)<{ color?: string }>(
  ({ theme, color }: { theme: CustomTheme; color?: string }) => ({
    width: 40,
    height: 40,
    padding: "8px",
    borderRadius: "20px",
    backgroundColor: `${color}1A`,
  }),
);
