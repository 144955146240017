import { Box, Divider, Grid, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { Text } from "@common/Text";
import { RiskProfileLevel } from "features/TransactionsRiskProfile/components";
import DisputeAssignmentSelect from "@components/Disputes/DisputesList/components/DisputeAssignmentSelect";
import { Fragment } from "react";
import { Warning } from "@phosphor-icons/react";
import { TDisputeData } from "../data.types";
import { parseAmount } from "@utils/index";
import { format } from "date-fns";
import { DisputesRowType } from "@components/Disputes/RespondToDispute/types";
import NiceModal from "@ebay/nice-modal-react";
import { TRANSACTION_RISK_MODAL } from "modals/modal_names";

const MOBILE_WARNING = `We're sorry, but submitting evidence and responding to 
  disputes is currently only available on the desktop version of our platform.`;

type Props = {
  data: TDisputeData;
  isMobileView?: boolean;
};

const DisputeCaseSummary = ({ isMobileView, data }: Props) => {
  const feePaidByCustomer = !data?.originalTransaction?.passFees;

  const summarySections = {
    firstSection: [
      {
        title: "Merchant",
        value: (
          <Stack>
            <Text
              fontWeight="book"
              color={palette.neutral[90]}
              lineHeight="16.8px"
              sx={{ wordBreak: "break-all" }}
            >
              {data?.merchant?.name}
            </Text>
            <Text
              fontWeight="book"
              color={palette.neutral[70]}
              lineHeight="16.8px"
            >
              {data?.merchant?.accID}
            </Text>
          </Stack>
        ),
      },
      {
        title: "Assignee",
        value: data ? (
          <DisputeAssignmentSelect showIcon data={data as DisputesRowType} />
        ) : (
          <></>
        ),
      },
    ],
    secondSection: [
      { title: "Reason", value: data?.reason },
      {
        title: "Action Required",
        value: "",
      },
      {
        title: "Deadline",
        value: data?.lastCase?.responseDueAt
          ? format(
              new Date(data?.lastCase?.responseDueAt * 1000),
              "MMM. dd, yyyy",
            )
          : "",
      },
    ],
    thirdSection: [
      { title: "Transaction ID", value: data?.originalTransaction?.id },
      {
        title: "Customer",
        value:
          data?.customer?.firstName || data?.customer?.lastName
            ? `${data?.customer?.firstName} ${data?.customer?.lastName}`
            : "",
      },
      { title: "Name on card", value: data?.cardholder?.nameOnCard },
      { title: "Card type", value: data?.cardholder?.cardBrandDiplsayName },
      {
        title: "Card number",
        value: `•••• ${data?.cardholder?.cardNumberLast4}`,
      },
      { title: "Email", value: data?.customer?.email },
    ],
    fourthSection: [
      {
        title: "Amount (USD)",
        value: parseAmount(data?.disputedAmount / 100 || 0),
      },
      {
        title: "Fees (USD)",
        value: parseAmount(data?.originalTransaction?.fees / 100 || 0),
        description: (
          <Text
            fontSize={12}
            fontWeight="book"
            color={palette.neutral[80]}
            lineHeight="14.4px"
          >
            {feePaidByCustomer
              ? "Customer paid fee"
              : "Deducted from merchant’s available balance"}
          </Text>
        ),
      },
    ],
  };

  const textColor = palette.tag.warning.hover;
  const Section = isMobileView ? StackSection : GridSection;

  const handleRiskLevel = async () => {
    const ipProfileID = data?.originalTransaction?.ipProfileID;
    if (!ipProfileID) return;

    NiceModal.show(TRANSACTION_RISK_MODAL, {
      ipProfileID,
    });
  };

  return (
    <Stack spacing="20px">
      {isMobileView && (
        <>
          <WarningContainer>
            <Stack direction="row" spacing="8px" alignItems="center">
              {<Warning size={24} weight="duotone" color={textColor} />}
              <Text
                sx={{
                  fontSize: 18,
                  lineHeight: "21.6px",
                }}
                color={textColor}
              >
                Not Available on Mobile
              </Text>
            </Stack>
            <Stack spacing={2}>
              <Text
                fontSize="14px"
                lineHeight="16.8px"
                color={palette.warning.text}
                fontWeight="book"
                sx={{ wordBreak: "break-word" }}
              >
                {MOBILE_WARNING}
              </Text>
            </Stack>
          </WarningContainer>
          <ContainerDivider />
        </>
      )}
      <StyledContainer>
        <Stack
          direction={isMobileView ? "column" : "row"}
          justifyContent="space-between"
          alignItems={isMobileView ? "flex-start" : "center"}
          spacing={isMobileView ? "8px" : 0}
        >
          <Text
            fontSize={24}
            color={palette.neutral[80]}
            fontWeight="book"
            lineHeight="32.4px"
          >
            Dispute Case Summary
          </Text>
          <RiskProfileLevel
            level={data?.originalTransaction?.ipRiskLevel || 0}
            onClick={handleRiskLevel}
            isInvisibleBadge={true}
          />
        </Stack>
        <ContainerDivider />
        <Section section={summarySections.firstSection} />
        <ContainerDivider />
        <Section section={summarySections.secondSection} />
        <ContainerDivider />
        <Section section={summarySections.thirdSection} />
        <ContainerDivider />
        <Section section={summarySections.fourthSection} />
      </StyledContainer>
    </Stack>
  );
};

type SectionProps = {
  title: string;
  value: string | JSX.Element;
  description?: string | React.ReactNode;
};

const GridSection = ({ section }: { section: SectionProps[] }) => {
  return (
    <Grid container spacing={1}>
      {section.map(({ title, value, description }, i) => (
        <Fragment key={i}>
          <Grid item xs={4}>
            <GridText text={title} />
          </Grid>
          <Grid item xs={8}>
            {typeof value === "string" ? (
              <GridText text={value} isValue />
            ) : (
              value
            )}
            {description}
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
};

const StackSection = ({ section }: { section: SectionProps[] }) => {
  return (
    <Stack spacing={2} direction="column">
      {section.map(({ title, value, description }, i) => (
        <Stack spacing="2px" direction="column" key={i}>
          <GridText text={title} />
          {typeof value === "string" ? (
            <GridText text={value} isValue />
          ) : (
            value
          )}
          {description && description}
        </Stack>
      ))}
    </Stack>
  );
};
const ContainerDivider = () => <Divider color={palette.neutral[20]} />;

const GridText = ({ text, isValue }: { text: string; isValue?: boolean }) => (
  <Text
    color={palette.neutral[isValue ? 80 : 70]}
    fontWeight="book"
    lineHeight="16.8px"
  >
    {text}
  </Text>
);

const StyledContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: palette.neutral[10],
  borderRadius: theme.spacing(1.5),
  gap: theme.spacing(2),
}));

const WarningContainer = styled(Box)(() => ({
  display: "flex",
  padding: " 12px 16px",
  flexDirection: "column",
  gap: "8px",
  borderRadius: "12px",
  background: "#FFF2E9",
}));

export default DisputeCaseSummary;
