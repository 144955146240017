import { Box } from "@mui/material";
import { Stack } from "@mui/material";
import { FunnelSimple, PaperPlaneTilt } from "@phosphor-icons/react";
import GiveButton from "@shared/Button/GiveButton";
import GiveChip from "@shared/Chip/GiveChip";
import GiveUnderwritingProgressBar from "@shared/ProgressBar/GiveUnderwritingProgressBar";
import GiveText from "@shared/Text/GiveText";
import { styled, useAppTheme } from "@theme/v2/Provider";
import { iconMapper } from "./utils";
import GiveSearchBar from "@shared/SearchBar/GiveSearchBar";
import { useState } from "react";
import useTableSearch from "componentsV2/Table/hooks/useTableSearch";
import ContextualMenu from "@shared/ContextualMenu/ContextualMenu";
import { useNotifications } from "./hooks/useNotifications";
import { ContextualMenuOptionProps } from "@shared/ContextualMenu/ContextualMenu.types";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

interface Props {
  name: string;
  identifier: string;
  completed: number;
  incomplete: number;
  disabled: number;
  status: "In Progress" | "Completed";
  isEmptyChallengeList: boolean;
}

export default function ChallengeBodyTop({
  name,
  identifier,
  completed,
  incomplete,
  disabled,
  status,
  isEmptyChallengeList,
}: Props) {
  const { palette } = useAppTheme();
  const { isMobileView } = useCustomThemeV2();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { openNotifyMerchantModal } = useNotifications();
  const { search, searchQuery, handleSearchChange } = useTableSearch({
    queryKey: "items",
  });

  //TODO: add logic
  const menuOptions: Omit<ContextualMenuOptionProps, "children">[] = [
    {
      type: "label",
      text: "Sort by",
    },
    {
      text: "Open",
      onClick: () => null,
      type: "default",
    },
    { text: "Ready for Verification", onClick: () => null, type: "default" },
    { text: "Done", onClick: () => null, type: "default" },
    { text: "Filter by", type: "label" },
    { text: "Merchant Replied", onClick: () => null, type: "default" },
    { text: "Tagged in Conversation", onClick: () => null, type: "default" },
  ];
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const isHideContent =
    identifier === "enhanced_due_diligence" && isEmptyChallengeList;

  return (
    <Stack gap="20px">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        pr="16px"
      >
        <GiveButton
          label="Notify Merchant"
          variant="ghost"
          startIcon={<PaperPlaneTilt size={18} />}
          onClick={openNotifyMerchantModal}
        />
        <GiveChip
          variant="light"
          size="small"
          label={status}
          color={status === "Completed" ? "success" : "default"}
          sx={{ fontSize: "12px" }}
        />
      </Stack>
      <Stack
        gap="20px"
        margin="0px 12px"
        borderBottom={
          isHideContent ? "none" : `1px solid ${palette.border?.primary}`
        }
        pb="20px"
      >
        <IconContainer>{iconMapper(identifier, 24)}</IconContainer>
        <GiveText variant="bodyL">{name} Challenges</GiveText>
        {!isHideContent && (
          <Box width="101%">
            <GiveUnderwritingProgressBar
              completed={completed}
              incomplete={incomplete}
              disabled={disabled}
            />
          </Box>
        )}
      </Stack>
      {!isHideContent && (
        <Stack direction={isMobileView ? "column" : "row"} gap="20px" pl="12px">
          {/* TODO: add logic to handle search */}
          {/* TODO: add logic for filter and sort */}
          <GiveSearchBar handleChange={handleSearchChange} value={search} />
          <GiveButton
            label="Filter & Sort"
            startIcon={<FunnelSimple size={18} />}
            variant={isMobileView ? "filled" : "ghost"}
            color="light"
            size="large"
            sx={{
              width: isMobileView ? "fit-content" : "200px",
              border: "none",
            }}
            onClick={(e) => setAnchorEl(e.target as HTMLElement)}
          />
          <ContextualMenu
            anchorEl={anchorEl}
            color="secondary"
            texture={isMobileView ? "solid" : "blurred"}
            options={menuOptions}
            handleClose={handleCloseMenu}
          />
        </Stack>
      )}
    </Stack>
  );
}

const IconContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primitive?.transparent["darken-5"],
  borderRadius: "50%",
  height: "48px",
  width: "48px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
