import { Button } from "@common/Button";
import { Text } from "@common/Text";
import { Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { Plus } from "@phosphor-icons/react";
import OFACStatus from "../OFAC/components/OFACStatus";
import { ArrowForwardIcon } from "@assets/icons";
import NiceModal from "@ebay/nice-modal-react";
import { MATCH_REPORT_MODAL } from "modals/modal_names";
import { useGetMATCHReports } from "./hooks/useMATCHReports";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { ReportType } from "./types";
import moment from "moment";
import {
  composePermission,
  useAccessControl,
  WithAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  FEATURE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";

interface Props {
  onClickReport: (report: any) => void;
  merchantID: number;
}

export default function DefaultView({ onClickReport, merchantID }: Props) {
  const { data, isLoading } = useGetMATCHReports(merchantID);

  const handleAddNewReport = () => {
    NiceModal.show(MATCH_REPORT_MODAL, { merchantID });
  };

  const isCreateAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.MATCH_REPORT,
    ),
    operation: OPERATIONS.CREATE,
  });

  if (isLoading) return <LoadingSpinner />;
  return (
    <>
      <Text color={palette.neutral[70]} fontWeight={350} fontSize="14px">
        The Member Alert to Control High-risk Merchants (MATCH) report compiles
        comprehensive results obtained from the screening of a merchant&apos;s
        business and ownership details.
      </Text>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Text color={palette.neutral[80]} fontSize="18px" fontWeight={400}>
          All reports
        </Text>
        <StyledButton
          endIcon={<Plus color={palette.neutral[80]} />}
          background="outlined"
          disabled={!isCreateAllowed}
          tooltipProps={{
            show: !isCreateAllowed,
            message: FEATURE_DENY_MESSAGE,
          }}
          onClick={handleAddNewReport}
          data-testid="add-new-report-button"
        >
          Add new report
        </StyledButton>
      </Stack>
      {data && data.length > 0 ? (
        <Stack flex={1} overflow="auto" gap="12px">
          <WithAccessControl
            operation={OPERATIONS.LIST}
            resource={composePermission(
              RESOURCE_BASE.MERCHANT,
              RESOURCE_BASE.MATCH_REPORT,
            )}
          >
            {data.map((item: ReportType, index: number) => (
              <ListItem
                key={item.ID}
                item={item}
                onClick={onClickReport}
                showLatest={index === 0}
                data-testid="report-list-item"
              />
            ))}
          </WithAccessControl>
        </Stack>
      ) : (
        <Stack flex={1} justifyContent="center" alignItems="center">
          <Text color={palette.neutral[80]} fontSize="14px">
            No data to show
          </Text>
        </Stack>
      )}
    </>
  );
}

function ListItem({
  item,
  onClick,
  showLatest,
}: {
  item: ReportType;
  onClick: (report: ReportType) => void;
  showLatest: boolean;
}) {
  return (
    <ItemContainer onClick={() => onClick(item)}>
      <Text color={palette.neutral[70]} fontWeight={350}>
        {showLatest ? "Latest - " : ""}
        {moment
          .unix(item.createdAt)
          .utc()
          .format("MMM D, YYYY, h:mm:ss A [UTC]")}
      </Text>
      <Stack direction="row" alignItems="center" spacing="12px">
        <OFACStatus status={item.statusName} />
        <ArrowForwardIcon stroke={palette.neutral[70]} />
      </Stack>
    </ItemContainer>
  );
}

const StyledButton = styled(Button)(() => ({
  height: "32px",
  fontWeight: 400,
}));

const ItemContainer = styled(Stack)(() => ({
  padding: "16px 12px",
  display: "flex",
  flexDirection: "row",
  backgroundColor: palette.common.white,
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  borderRadius: "8px",
  "&:hover": {
    boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.1)",
  },
}));
