// mui
import Stack from "@mui/material/Stack";
import {
  FilterWithSwitch,
  FilterWithDate,
  FilterWithOption,
  FilterWithAmount,
  ClearFiltersButton,
} from "@common/Filter";
// redux
import { selectFilters } from "@redux/slices/transactions";
import { useAppSelector } from "@redux/hooks";
import {
  addFilter,
  removeFilter,
  // disableFilter,
  addDateFilter,
  disableDateFilter,
  addAmountFilter,
  disableAmountFilter,
  clearFilters,
} from "@redux/slices/transactions";
import { selectQueryFilters } from "@redux/slices/transactions";

export const DonationsFilterBar = () => {
  const filters = useAppSelector(selectFilters);

  return (
    <Stack pt={3} pb={2} direction="row" flexWrap="wrap">
      <FilterWithDate
        title="date"
        options={[
          { value: "in the last", label: "in the last" },
          { value: "is between", label: "is between" },
          { value: "is on or before", label: "is on or before" },
          { value: "is on or after", label: "is on or after" },
        ]}
        filters={filters}
        apply={addDateFilter}
        disable={disableDateFilter}
      />
      {/* <FilterWithAmount
        title="amount"
        options={[
          { value: "is between", label: "is between" },
          {
            value: "is equal to or greater than",
            label: "is equal to or greater than",
          },
          {
            value: "is equal to or less than",
            label: "is equal to or less than",
          },
        ]}
        filters={filters}
        apply={addAmountFilter}
        disable={disableAmountFilter}
      />
      <FilterWithOption
        title="recurrence"
        options={[
          { value: "recurring & one-time", label: "Recurring & One-time" },
          { value: "all recurring", label: "All Recurring" },
          { value: "one-time", label: "One-time" },
          { value: "daily", label: "Daily" },
          { value: "monthly", label: "Monthly" },
          { value: "quarterly", label: "Quarterly" },
          { value: "yearly", label: "Yearly" },
        ]}
        apply={addFilter}
        disable={disableFilter}
      />
      <FilterWithSwitch
        title="source"
        options={{
          Website: false,
          "Mobile App": false,
          API: false,
        }}
        filters={filters}
        apply={addFilter}
        remove={removeFilter}
        disable={disableFilter}
      /> */}

      <ClearFiltersButton clear={clearFilters} selector={selectQueryFilters} />
    </Stack>
  );
};
