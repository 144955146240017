import { useMemo } from "react";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import {
  useDocumentHandlers,
  useDocumentPreview,
} from "@hooks/common/documents";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { downloadDocument } from "@hooks/common/documents/utils";
import { useMerchantSidePanelContext } from "features/Merchants/MerchantSidePanel/Provider/MerchantSidePanelProvider";
import IDUploader from "features/Merchants/MerchantSidePanel/components/PrimaryAccountHolderSection/IDUploader";
import { TBusinessOwner } from "@components/common/BusinessOwners/types";

interface Props {
  owner: TBusinessOwner;
  idFile?: TMerchantDocument | any;
  merchantId: number;
}

export const BOIDDisplay = ({ owner, idFile, merchantId }: Props) => {
  const { id } = useMerchantSidePanelContext();
  const { isDesktopView } = useCustomThemeV2();
  const isLocalFile = idFile && "meta" in idFile;
  const idUrl = useMemo(
    () => (isLocalFile ? idFile.meta?.previewUrl : idFile?.fileURL),
    [isLocalFile, idFile],
  );
  const idName = isLocalFile ? idFile.meta?.name : idFile?.fileName;
  const idFileType = isLocalFile
    ? idFile?.meta?.type.split("/")[1]
    : idFile?.fileType;
  const idFileId = isLocalFile ? idFile?.meta?.id : idFile?.id;
  const isConfirmMatch = owner?.pepStatusName === "confirmed_match";

  const downloadID = async () => {
    if (!idFile) return;
    downloadDocument(
      {
        fileName: idName || "undefined",
        fileURL: idUrl || "",
      },
      isDesktopView,
    );
  };

  const { handlePreview } = useDocumentPreview({
    merchantID: merchantId as number,
    handlers: {
      handleDownload: downloadID,
    },
  });

  const previewDocument = () => {
    // if no file url, skip preview
    if (!idUrl) return;
    const fileToPreview = {
      fileURL: idUrl,
      fileName: idName,
      fileType: idFileType,
      id: idFileId,
      tag: "legal_principal",
    } as TMerchantDocument;

    handlePreview(fileToPreview, [fileToPreview]);
  };

  const { isMobileView } = useCustomThemeV2();
  const { downloadHandler } = useDocumentHandlers(id);

  return (
    <IDUploader
      type={"businessOwnerID"}
      isMobile={isMobileView}
      disabled={false}
      merchantId={merchantId}
      docUrl={idFile?.fileURL || ""}
      downloadHandler={() => downloadHandler(idFile)}
      previewDocument={previewDocument}
      isUploadAllowed={false}
      isDeleteAllowed={isConfirmMatch}
      fileId={idFile?.id}
    />
  );
};
