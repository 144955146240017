import { memo } from "react";
import { styled } from "@theme/v2/Provider";
import { Divider as DividerMui, Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";

type TSideMenuItemProps = {
  type?: "divider" | "default";
  value: string;
  label?: string;
  open: boolean;
  onClick: (v: string) => void;
  Icon?: JSX.Element;
  isSelected?: boolean;
};

const SideMenuItem = ({
  type = "default",
  value,
  label,
  open,
  onClick,
  Icon,
  isSelected,
}: TSideMenuItemProps) => {
  if (type === "divider") {
    return <Divider />;
  }

  return (
    <Container isSelected={isSelected} onClick={() => onClick(value)}>
      {Icon}
      {open && <Text variant="bodyS">{label || value}</Text>}
    </Container>
  );
};

const Container = styled(Stack)<{ isSelected?: boolean }>(
  ({ theme, isSelected }) => ({
    width: "100%",

    padding: "12px",
    borderRadius: "12px",

    display: "flex",
    flexDirection: "row",

    alignItems: "center",
    gap: "12px",

    color: theme.palette.text.secondary,

    "&:hover": {
      background: theme.palette.surface?.secondary,
      cursor: "pointer",
      color: theme.palette.text.primary,
    },
    ...(isSelected && {
      background: theme.palette.gradient["ocean-blue"]?.highlight,
      color: theme.palette.primitive?.blue[100],
      pointerEvents: "none",
    }),
    "& > svg": {
      flexShrink: 0,
    },
  }),
);

const Divider = styled(DividerMui)(({ theme }) => ({
  margin: "4px 0",
  color: theme.palette.border?.primary,
}));

const Text = styled(GiveText)({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  flexGrow: 1,
  color: "inherit",
});

export default memo(SideMenuItem);
