import { MCCType } from "@hooks/acquirer-api/useListCategoriesCodes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface SettingsState {
  category_codes: MCCType[];
}

interface AppState {
  acquirerSettings: SettingsState;
}
const initialState: SettingsState = {
  category_codes: [],
};

const acquirerSettingsSlice = createSlice({
  name: "acquirerSettings",
  initialState,
  reducers: {
    setCategoryCodes: (
      state: SettingsState,
      action: PayloadAction<MCCType[]>,
    ) => {
      const existingCodes = state.category_codes;
      const newCodes = action.payload.filter(
        (code) => !existingCodes.includes(code),
      );
      state.category_codes = [...newCodes];
    },
    setCategoryCode: (state: SettingsState, action: PayloadAction<MCCType>) => {
      state.category_codes = [...state.category_codes, action.payload];
    },
  },
});

export const { setCategoryCodes, setCategoryCode } =
  acquirerSettingsSlice.actions;

export const selectCategoryCodes = (state: AppState): MCCType[] =>
  state.acquirerSettings.category_codes;

export default acquirerSettingsSlice.reducer;
