import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { RootState } from "@redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QueryKey } from "react-query";
import { useMemo } from "react";

type UploadProgress = {
  items: UploadProgressItems;
  hidden: string[];
};

export interface ProgressItem {
  // the actualy identifier is the key of the progressItem in the object
  fileName: string;
  accountID: string;
  index: number; // for UI positioning
  id?: string; // used  for deletion only
  accountUpload?: boolean;
  progress?: number;
  abortController?: AbortController;
  success?: boolean;
  failed?: boolean;
  canceled?: boolean;
  tooLarge?: boolean;
  unsuported?: boolean;
  deleted?: boolean;
  tooManyFiles?: boolean;
  size: number;
  refetcherKey?: QueryKey[];
}

interface UploadProgressItems {
  [key: string]: ProgressItem;
}

interface SetUploadProgressParams {
  key: string;
  data: Partial<ProgressItem>;
}

const initialState: UploadProgress = {
  items: {},
  hidden: [],
};

const uploadProgressSlice = createSlice({
  name: "uploadProgress",
  initialState,
  reducers: {
    setUploadProgress: (
      state: UploadProgress,
      action: PayloadAction<SetUploadProgressParams>,
    ) => {
      const progress = action.payload;
      if (state.hidden.includes(progress.key)) {
        return;
      }
      const isNewItem = !state.items[progress.key];
      // push everything up to make space for the new item
      if (isNewItem) {
        Object.values(state.items).forEach((item) => {
          item.index += 1;
        });
      }
      state.items[progress.key] = {
        //@ts-expect-error: it thinks we are duplicating
        index: 0,
        ...state.items[progress.key],
        ...progress.data,
      };
      // if status is success, we put the item at the top, changing the index
      if (progress.data.success) {
        pushToTop(state, progress.key);
      }

      function pushToTop(state: UploadProgress, action = "") {
        const key = action;
        const item = state.items[key];
        if (!item) {
          return;
        }
        const currentIndex = item.index;
        const maxIndex = Math.max(
          ...Object.values(state.items).map((i) => i.index),
        );
        item.index = maxIndex + 1;
        // now decrement all the other indexes next to the current index
        Object.values(state.items).forEach((i) => {
          if (i.index > currentIndex) {
            i.index -= 1;
          }
        });
      }
    },
    deleteUploadProgress: (
      state: UploadProgress,
      action: PayloadAction<string>, // key
    ) => {
      const currentUIIndex = state.items[action.payload].index;
      if (currentUIIndex === undefined) {
        return;
      }
      delete state.items[action.payload];

      // now the index of the items in which the index is greater than the deleted item's index should be decremented by 1
      Object.values(state.items).forEach((item) => {
        if (item.index > currentUIIndex) {
          item.index -= 1;
        }
      });
    },
    hideUploadProgress: (
      state: UploadProgress,
      action: PayloadAction<string>,
    ) => {
      const currentIndex = state.items[action.payload]?.index;
      if (currentIndex === undefined) {
        return;
      }
      state.hidden.push(action.payload);
      // decrement all the other indexes above the current index
      Object.values(state.items).forEach((i) => {
        if (i.index > currentIndex) {
          i.index -= 1;
        }
      });
      // delete the item from the items
      delete state.items[action.payload];
    },
  },
});

const { setUploadProgress, deleteUploadProgress, hideUploadProgress } =
  uploadProgressSlice.actions;

export const useUploadProgress = () => {
  const dispatch = useAppDispatch();
  return {
    setUploadProgress: (params: SetUploadProgressParams) => {
      dispatch(setUploadProgress(params));
    },
    hideUploadProgress: (key: string) => {
      dispatch(hideUploadProgress(key));
    },
    deleteUploadProgress: (key: string) => {
      dispatch(deleteUploadProgress(key));
    },
  };
};
const selectUploadProgress = (state: RootState) => state.uploadProgress.items;
const selectHiddenKeys = (state: RootState) => state.uploadProgress.hidden;

export const useGetUploadProgress = () => {
  const allItems = useAppSelector(selectUploadProgress);
  const hiddenKeys = useAppSelector(selectHiddenKeys);
  // filter out the hidden items

  const filteredItems = useMemo(() => {
    const items = Object.keys(allItems);
    const remainingKeys = items.filter((key) => !hiddenKeys.includes(key));
    return remainingKeys.reduce((acc, key) => {
      acc[key] = allItems[key];
      return acc;
    }, {} as UploadProgressItems);
  }, [allItems, hiddenKeys]);

  return filteredItems;
};

export default uploadProgressSlice.reducer;
