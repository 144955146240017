import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  ErrorType_bulk_merchant,
  TImportedRow,
  TInviteElement,
  TInvitesMap,
} from "../types";
import NiceModal from "@ebay/nice-modal-react";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { getSelectedKeys, invitesSorter } from "../utils";
import {
  ADD_INVITE_MODAL,
  DELETE_CONFIRMATION_MODAL,
  EDIT_INVITE_MODAL,
  SEND_INVITATIONS_MODAL,
} from "modals/modal_names";
import { BulkInviteActions } from "../components/panel.atoms";
import useSorting from "@hooks/Reducers/useSorting";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  saveInvitationsDraft,
  saveProviderId,
  selectInvitationsDraft,
} from "@redux/slices/enterprise/merchants";
import { useGetCurrentMerchantId } from "@hooks/common";
import { removeSpecialChars } from "@utils/slug";
import { SLUG_MAX_CHARACTER_LENGTH } from "@constants/constants";
import { checkPortals } from "@utils/routing";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { customInstance } from "@services/api";
import { showMessage } from "@common/Toast";
import { isEmpty, isNull, uniqBy } from "lodash";
import { MAXIMUM_NUMBER_OF_MERCHANTS_TO_INVITE } from "../constants";
import {
  QKEY_LIST_ACQUIRER_MERCHANTS,
  QKEY_LIST_ENTERPRISE_MERCHANTS,
  QKEY_LIST_MERCHANT_STATS,
  QKEY_LIST_MERCHANTS,
} from "@constants/queryKeys";
import { generateBillingDescriptor } from "@utils/helpers";
// /accounts/1/background-tasks
type TModifyHandler = (key: string, value: TInviteElement) => void;
type TValuesCount = Record<string, string[]>;

const useBulkInvite = (initialProviderId: number) => {
  const mapRef = useRef<TInvitesMap>(new Map());
  const [loading, setLoading] = useState<"idle" | "isLoading" | "isInviting">(
    "isLoading",
  );
  const isLoading = loading === "isLoading";
  const isInviting = loading === "isInviting";
  const isIdle = loading === "idle";

  const { merchantId } = useGetCurrentMerchantId();
  const savedDraft = useAppSelector((state) =>
    selectInvitationsDraft(state, merchantId),
  );
  const { entries: savedInvitesDraft, providerId: savedProviderId } =
    savedDraft;
  const [providerId, setProviderId] = useState(
    (savedProviderId != 0 ? savedProviderId : initialProviderId) || 0,
  );
  const [invitesList, setInvitesList] = useState(() =>
    Array.from(mapRef.current.entries()),
  );

  const totalSelected = useRef<number>(0);
  const {
    theEntireList,
    allCheckedHaveError,
    hasSelectedItems,
    areAllSelected,
  } = useMemo(() => {
    const theEntireList = Array.from(mapRef.current.values());
    const allCheckedHaveError = theEntireList
      .filter((item: any) => item.checked)
      .every((item: any) => item.errorType !== "");

    const hasSelectedItems = theEntireList?.some((item) => item?.checked);

    const checkedItemsCount = theEntireList?.filter(
      (item) => item?.checked,
    ).length;
    totalSelected.current = checkedItemsCount;
    const areAllSelected = checkedItemsCount === theEntireList.length;
    return {
      theEntireList,
      allCheckedHaveError,
      hasSelectedItems,
      areAllSelected,
    };
  }, [invitesList]);

  const { isEnterprisePortal, isAcquirerPortal } = checkPortals();
  const dispatch = useAppDispatch();
  const { onClose } = useNiceModal();
  const { isMobileView } = useCustomTheme();

  const queryClient = useQueryClient();

  const { attribute, order, toggleSorting } = useSorting({
    tableName: "merchant-invite",
  });

  const { refetch, data, isRefetching } = useQuery(
    ["check-bulk-merchant-invites-background", merchantId],
    async () => {
      setLoading((prev) => {
        if (prev === "isInviting") return "isInviting";
        return "isLoading";
      });
      const task = await fetchTasks(merchantId);

      if (!isEmpty(task) && task?.status === "running") {
        showMessage(
          "Warning",
          "We will notify you when the process is completed",
          true,
          "Sending in progress",
        );
        setLoading("isInviting");
        return { response: [], taskId: null, isInviting: true }; // Exit early if task is still running
      }
      setLoading("isLoading");
      if (task?.readAt) {
        mapRef.current = new Map(); // Reset the map when task is read
        return { response: [], taskId: null, isInviting: false }; // Exit early if task is already read
      }

      const getList =
        task?.id && (await fetchTaskDetails(merchantId, task?.id));

      return { response: getList || [], taskId: task?.id, isInviting: false };
    },
    {
      async onSuccess(res) {
        const data = res?.response;
        const isInviting = res?.isInviting;
        const { Success: successfulMerchants, Failures: failedMerchants } =
          data?.result || {};

        if (!isEmpty(successfulMerchants)) {
          const acceptedMerchantsArray = successfulMerchants?.map(
            (merchant: any) => merchant?.merchantName,
          );
          await deleteEntriesByKeys(acceptedMerchantsArray);
        }
        if (!isEmpty(failedMerchants)) {
          await mapInit(() => {
            failedMerchants?.forEach((payloadObject: any) => {
              const email = payloadObject?.ownerEmail;
              const newEntry: TInviteElement = {
                ...payloadObject,
                checked: false,
                errorType: ErrorType_bulk_merchant.MERCHANT_NAME_EXISTS, //to read from api when ready,
                merchantName: payloadObject?.merchantName,
                pahEmail: email,
              };
              const key = generateKey(newEntry.merchantName);
              if (mapRef.current.has(key)) {
                // Merge with the existing entry in the map
                mapRef.current.set(key, {
                  ...mapRef.current.get(key), // Keep the existing properties
                  ...newEntry, // Update with new data
                });
              } else {
                // If no existing entry, set the new entry in the map
                mapRef.current.set(key, newEntry);
              }
            });
            const selectedItems = theEntireList.filter((item) => item.checked);
            totalSelected.current = selectedItems.length;
          });
        }

        const selectedItems = Array.from(mapRef.current.values()).filter(
          (item) => item.checked,
        );
        totalSelected.current = selectedItems.length;

        setLoading(isInviting ? "isInviting" : "idle");
      },
      onSettled(data, error) {
        updateList();
      },
      onError() {
        setLoading("idle");
        showMessage(
          "Error",
          "An error occurred while fetching background tasks ",
        );
      },
      enabled: isEnterprisePortal || (isAcquirerPortal && Boolean(merchantId)),
    },
  );

  useQuery(
    ["mark-as-read", data?.response, data?.taskId],
    async () => {
      return await customInstance({
        url: `/accounts/${merchantId}/background-tasks/read`,
        method: "PUT",
        data: { taskIDs: [data?.taskId] },
      });
    },
    {
      enabled: !isEmpty(data?.response) && !!data?.taskId,
    },
  );
  const { mutateAsync } = useMutation((data: any) => {
    return customInstance({
      url: "/merchants/bulk-check",
      method: "POST",
      data,
    });
  });

  // count items with the same name to check for duplicates
  // if valuesCount[merchantName].length === 1 the value is unique
  const valuesCount = useMemo(
    () =>
      invitesList.reduce((acc: TValuesCount, [key, value]) => {
        const keyValue = value.merchantName;
        if (!keyValue) return acc;
        if (!acc[keyValue]) {
          acc[keyValue] = [];
        }
        acc[keyValue].push(key);
        return acc;
      }, {}),
    [invitesList],
  );
  const entryIsUnique = (merchantName: string) =>
    !!merchantName && valuesCount[merchantName]?.length === 1;

  const checkIsUnique = useCallback(
    (newName: string, originalName?: string) => {
      if (originalName && newName === originalName) {
        return valuesCount[newName]?.length < 2;
      } else if (originalName) {
        const total = valuesCount[newName];
        return !total || total?.length < 1;
      } else {
        return !valuesCount[newName];
      }
    },
    [valuesCount],
  );
  const showMaximumError = () =>
    showMessage(
      "Warning",
      `A maximum of ${MAXIMUM_NUMBER_OF_MERCHANTS_TO_INVITE} merchants can be invited each time`,
    );
  const updateList = useCallback(() => {
    const list = Array.from(mapRef.current.entries());
    const newList = attribute
      ? list.sort(invitesSorter(attribute, order))
      : list;

    setInvitesList(newList);
  }, [attribute, order]);

  useEffect(() => {
    updateList();
  }, [attribute, order]);

  const mapInit = async (callback: () => void) => {
    setLoading("isLoading");

    // handle initialization asynchronously
    return new Promise((resolve) => {
      callback();
      updateList();
      resolve(undefined);
    }).finally(() => setLoading("idle"));
  };

  // Function to generate a lowercase key based on the merchantName
  const generateKey = (merchantName: string) => merchantName.toLowerCase();

  const deleteEntriesByKeys = async (keys: string[]) => {
    if (!keys.length) return; // Early return if no keys provided
    await mapInit(() => {
      keys.forEach((key) => {
        const lowercaseKey = generateKey(key); // Ensure key is lowercase
        mapRef.current.delete(lowercaseKey); // Delete the entry from the map
      });

      // Update selected items logic if needed
      const selectedItems = theEntireList.filter((item) => item.checked);
      totalSelected.current = selectedItems.length;
    }).then(() => updateList());
  };

  const importedRowsInit = async (arr: TImportedRow[]) => {
    setLoading("isLoading");

    let initialEntries = cleanArray(arr)?.slice(
      0,
      MAXIMUM_NUMBER_OF_MERCHANTS_TO_INVITE,
    );
    if (arr?.length > MAXIMUM_NUMBER_OF_MERCHANTS_TO_INVITE) showMaximumError();
    const withAddedLength = theEntireList?.length + initialEntries?.length;
    if (withAddedLength > MAXIMUM_NUMBER_OF_MERCHANTS_TO_INVITE) {
      initialEntries = initialEntries?.slice(
        0,
        MAXIMUM_NUMBER_OF_MERCHANTS_TO_INVITE - theEntireList?.length,
      );

      showMaximumError();
    }

    if (isEmpty(arr)) {
      setLoading("idle");
      return showMessage(
        "Error",
        "Please use the correct template to upload the list",
      );
    }
    if (theEntireList?.length >= MAXIMUM_NUMBER_OF_MERCHANTS_TO_INVITE) {
      setLoading("idle");
      return;
    }
    const validatedList = await mutateAsync({ merchants: initialEntries });

    await mapInit(() => {
      validatedList?.results?.forEach((entry: any) => {
        const email = entry?.ownerEmail;
        const newEntry: TInviteElement = {
          ...entry,
          checked: entry?.isValid && emailPattern.test(email || ""),
          errorType: !entry?.isValid
            ? ErrorType_bulk_merchant.MERCHANT_NAME_EXISTS
            : !email
            ? ErrorType_bulk_merchant.EMAIL_REQUIRED
            : "",
          merchantName: entry?.name,
          pahEmail: email,
        };

        // Generate a lowercase key for the merchantName
        const key = generateKey(newEntry.merchantName);

        // Check if the map already has this merchantName (case-insensitive match)
        if (mapRef.current.has(key)) {
          // Merge with the existing entry in the map
          mapRef.current.set(key, {
            ...mapRef.current.get(key), // Keep the existing properties
            ...newEntry, // Update with new data
          });
        } else {
          // If no existing entry, set the new entry in the map
          mapRef.current.set(key, newEntry);
        }
      });

      // Handle selected items logic
      const selectedItems = theEntireList.filter((item) => item.checked);
      totalSelected.current = selectedItems.length;
    });
    updateList();
    setLoading("idle");
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (savedInvitesDraft.length > 0) {
      setLoading("isLoading");
      // delay init to handle animations
      timeout = setTimeout(() => {
        mapInit(() => {
          mapRef.current = new Map(savedInvitesDraft);
          totalSelected.current = savedInvitesDraft.reduce(
            (acc, [key, value]) => {
              if (value.checked) return (acc += 1);
              return acc;
            },
            0,
          );
        });
      }, 400);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
      dispatch(
        saveInvitationsDraft({
          merchantId,
          entries: Array.from(mapRef.current.entries()),
        }),
      );
    };
  }, []);

  const setInvite: TModifyHandler = (key, value) => {
    mapRef.current.set(key, value);
    updateList();
  };

  const onAddInvite = () => {
    NiceModal.show(ADD_INVITE_MODAL, {
      onSubmit: async (newEntries: TImportedRow[]) => {
        importedRowsInit(newEntries);
      },
      listItems: theEntireList,
    });
  };

  const confirmRemoval = (cb: VoidFunction, totalItems?: string) => {
    NiceModal.show(DELETE_CONFIRMATION_MODAL, {
      variant: "merchantInvitation",
      itemName: totalItems,
      deleteHandler: cb,
    });
  };

  const editInvite: TModifyHandler = (key, currentValue) => {
    const errorType = (() => {
      if (
        [
          ErrorType_bulk_merchant.MERCHANT_NAME_EXISTS,
          ErrorType_bulk_merchant.MERCHANT_NAME_REQUIRED,
          ErrorType_bulk_merchant.SLUG_EXISTS,
        ].includes(currentValue?.errorType as ErrorType_bulk_merchant) ||
        !currentValue?.merchantName
      ) {
        return "merchantName";
      }

      if (
        [
          ErrorType_bulk_merchant.EMAIL_INVALID,
          ErrorType_bulk_merchant.EMAIL_REQUIRED,
        ].includes(currentValue?.errorType as ErrorType_bulk_merchant) ||
        !currentValue?.pahEmail
      ) {
        return "email";
      }

      return null;
    })();
    NiceModal.show(EDIT_INVITE_MODAL, {
      merchantName: currentValue.merchantName,
      pahEmail: currentValue.pahEmail,
      errorType: errorType,
      checkIsUnique,
      handleDelete: () => confirmRemoval(() => removeInvite(key)),
      handleSubmit: async (newValue: Partial<TInviteElement>) => {
        setLoading("isLoading");
        const oldKey = generateKey(key);

        const currentMap = mapRef.current;
        if (!currentMap.has(oldKey)) return;

        // Retrieve the existing object
        const inviteElement = currentMap.get(oldKey);
        const slug = removeSpecialChars(
          newValue.merchantName || currentValue?.merchantName,
          SLUG_MAX_CHARACTER_LENGTH,
        );
        const validatedList = await mutateAsync({
          merchants: [
            {
              name: newValue?.merchantName || "",
              merchantName: newValue?.merchantName,
              slug,
              email: newValue?.pahEmail,
              owner: {
                email: newValue?.pahEmail,
              },
            },
          ],
        });
        const item = validatedList?.results?.[0];
        if (!item) return;
        const newObject = {
          checked: item?.isValid && emailPattern.test(item?.ownerEmail || ""),
          errorType: !item?.isValid
            ? ErrorType_bulk_merchant.MERCHANT_NAME_EXISTS
            : !item
            ? ErrorType_bulk_merchant.EMAIL_REQUIRED
            : "",
          merchantName: item?.name,
          pahEmail: item?.ownerEmail,
        };
        const newKey = generateKey(item?.name);
        if (oldKey !== newKey) {
          currentMap.delete(key);
        }
        const updatedElement = { ...inviteElement, ...newObject };

        currentMap.set(newKey, updatedElement as any);
        updateList();
        setLoading("idle");
      },
    });
  };

  const toggleSelectItem = useCallback((key: string) => {
    const prevItem = mapRef.current.get(key);

    if (!prevItem) return;
    setInvite(key, { ...prevItem, checked: !prevItem.checked });
  }, []);

  const toggleSelectAll = () => {
    Array.from(mapRef.current.keys()).forEach((key) => {
      const prevItem = mapRef.current.get(key);
      if (prevItem) {
        mapRef.current.set(key, { ...prevItem, checked: !areAllSelected });
      }
    });

    updateList();
  };

  const removeInvite = (key: "all" | string | string[]) => {
    if (key === "all") {
      // remove all
      mapRef.current = new Map();
    } else if (Array.isArray(key)) {
      // remove bulk
      key.forEach((k) => mapRef.current.delete(k));
    } else {
      // remove single item
      mapRef.current.delete(key);
    }
    updateList();
  };

  const deleteSelected = () => {
    if (totalSelected.current === 0) return;

    if (totalSelected.current === mapRef.current.size) {
      removeInvite("all");
    } else if (totalSelected.current === 1) {
      const itemKey = getSelectedKeys(mapRef.current, "single");
      !!itemKey && removeInvite(itemKey);
    } else {
      const itemKeys = getSelectedKeys(mapRef.current, "bulk");
      !!itemKeys && itemKeys.length > 0 && removeInvite(itemKeys);
    }
  };
  const { mutate } = useMutation(
    async (data: any) => {
      const mcc = await customInstance({
        url: `merchants/${data?.parentAccID}`,
      });

      const categoryCodes = mcc?.allowedCategoryCodes?.[0]?.categoryCodes;
      const categoryCodeID = categoryCodes?.id;

      const newList = data?.merchants
        ?.filter(
          (item: any) => item?.checked && item?.merchantName && item?.pahEmail,
        )
        ?.map((merchant: any) => {
          const merchantName_billingDescriptor = merchant.merchantName?.slice(
            0,
            17,
          );
          const randomDigits = Math.floor(
            1000 + Math.random() * 9000,
          ).toString();
          return {
            name: merchant.merchantName,
            parentAccID: data?.parentAccID,
            ...(!isEmpty(categoryCodes) && {
              categoryCodeID,
              billingDescriptor: generateBillingDescriptor(
                `${merchantName_billingDescriptor}${randomDigits}`,
              )?.toUpperCase(),
              billingDescriptorPrefix: mcc?.billingDescriptorPrefix,
            }),

            inviteOwner: true,
            slug: removeSpecialChars(
              merchant.merchantName,
              SLUG_MAX_CHARACTER_LENGTH,
            ),
            owner: { email: merchant?.pahEmail },
          };
        });
      return await customInstance({
        url: "/merchants/bulk-create-async",
        method: "POST",
        data: {
          ...data,
          merchants: newList,
        },
      });
    },
    {
      async onSuccess(data) {
        const acceptedMerchantsArray = data?.success?.map(
          (merchant: any) => merchant?.merchantName,
        );
        const rejectedMerchantsArray = data?.failures;
        if (
          isEmpty(acceptedMerchantsArray) &&
          isEmpty(rejectedMerchantsArray)
        ) {
          dispatch(
            saveInvitationsDraft({
              merchantId,
              entries: [],
            }),
          );

          mapRef.current = new Map();

          return setLoading("isInviting");
        }

        acceptedMerchantsArray &&
          (await deleteEntriesByKeys(acceptedMerchantsArray));

        await mapInit(() => {
          rejectedMerchantsArray?.forEach((payloadObject: any) => {
            const email = payloadObject?.ownerEmail;

            const newEntry: TInviteElement = {
              ...payloadObject,
              checked: false,
              errorType: ErrorType_bulk_merchant.MERCHANT_NAME_EXISTS, //to read from api when ready,
              merchantName: payloadObject?.merchantName,
              pahEmail: email,
            };

            const key = generateKey(newEntry.merchantName);
            if (mapRef.current.has(key)) {
              // Merge with the existing entry in the map
              mapRef.current.set(key, {
                ...mapRef.current.get(key), // Keep the existing properties
                ...newEntry, // Update with new data
              });
            } else {
              // If no existing entry, set the new entry in the map
              mapRef.current.set(key, newEntry);
            }
          });
        });

        queryClient.refetchQueries(QKEY_LIST_MERCHANT_STATS);
        queryClient.refetchQueries(QKEY_LIST_MERCHANTS);
        queryClient.refetchQueries(QKEY_LIST_ENTERPRISE_MERCHANTS);
        queryClient.refetchQueries(QKEY_LIST_ACQUIRER_MERCHANTS);
      },
      onSettled() {
        updateList();
        setLoading((prev) => {
          if (prev === "isInviting") return "isInviting";
          return "idle";
        });
      },
      onError() {
        setLoading("idle");
      },
    },
  );

  const handleSendInvitations = async () => {
    setLoading("isLoading");
    const parentAccID = isEnterprisePortal ? merchantId : providerId;
    mutate({
      parentAccID,
      merchants: theEntireList,
      type: "submerchant",
      signupType: isEnterprisePortal
        ? "enterprise_imported"
        : "acquirer_imported",
    });
  };

  const isProvider = isAcquirerPortal ? !providerId : false;
  const actions: BulkInviteActions[] = [
    {
      label: "Delete Selected",
      onSelect: () =>
        confirmRemoval(
          deleteSelected,
          areAllSelected ? "all" : `${totalSelected.current}`,
        ),
      hidden: !hasSelectedItems,
      disabled: isInviting || isLoading,
      labelProps: {
        sx: {
          color: palette.filled.red,
        },
      },
    },
    {
      label: "Cancel",
      onSelect: onClose,
      hidden: isMobileView,
      disabled: isInviting || isLoading,
    },
    {
      label: "Send Invitations",
      onSelect: () => {
        NiceModal.show(SEND_INVITATIONS_MODAL, {
          total: totalSelected.current,
          handleSubmit: handleSendInvitations,
        });
      },
      hidden: false,
      disabled:
        !hasSelectedItems ||
        isProvider ||
        isInviting ||
        allCheckedHaveError ||
        isLoading,
      tooltipProps: {
        show: !hasSelectedItems || isProvider || allCheckedHaveError,
        message: isProvider
          ? "Provider is missing"
          : allCheckedHaveError && hasSelectedItems
          ? "All merchants are invalid. Please check and edit merchant's data."
          : "Select the merchants to whom you want to send an invitation",
      },
    },
  ];

  const handleSetProviderId = (id: number) => {
    dispatch(
      saveProviderId({
        providerId: id,
        merchantId,
      }),
    );

    setProviderId(id);
  };
  const handleCheckInviteDone = () => {
    setLoading("isInviting");
    refetch();
  };
  return {
    actions,
    data: invitesList,
    areAllSelected,
    isLoading,
    isInviting,
    isIdle,
    importedRowsInit,
    toggleSelectItem,
    toggleSelectAll,
    onAddInvite,
    editInvite,
    entryIsUnique,
    providerId,

    setProviderId: handleSetProviderId,
    isRefetching,
    sorting: {
      attribute,
      order,
      toggleSorting,
    },
    handleCheckInviteDone,
  };
};

export default useBulkInvite;

const cleanArray = (arr: TImportedRow[]) => {
  const initialEntries = uniqBy(arr, "merchantName")?.map((item: any) => {
    const slug = removeSpecialChars(
      item.merchantName,
      SLUG_MAX_CHARACTER_LENGTH,
    );
    return {
      ...item,
      name: item.merchantName,
      merchantName: item.merchantName,
      slug,
      owner: {
        email: item?.pahEmail,
      },
    };
  });
  return initialEntries;
};

const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const fetchTasks = async (providerId: string | number) => {
  const { data: allTasks } = await customInstance({
    url: `/accounts/${providerId}/background-tasks?filter=%3B(name:%22account_bulk_create_task%22)`,
    method: "GET",
  });

  const response = allTasks
    ?.filter((item: any) => item.name === "account_bulk_create_task")
    ?.findLast(
      (item: any) => item?.status === "running" || isNull(item?.readAt),
    );

  return response;
};

const fetchTaskDetails = async (
  providerId: string | number,
  taskId: string,
) => {
  return await customInstance({
    url: `/accounts/${providerId}/background-tasks/${taskId}`,
    method: "GET",
  });
};
