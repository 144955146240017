import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";
import React from "react";

function SendGradientIcon() {
  const { parseLinearGradientColor } = useThemeContext();
  const [col1, col2] = parseLinearGradientColor();
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.624 1.37751C41.2467 1.00051 40.7758 0.730888 40.2597 0.596424C39.7436 0.46196 39.201 0.467513 38.6877 0.61251H38.6596L2.67085 11.5325C2.08502 11.701 1.56441 12.0439 1.17835 12.5157C0.792288 12.9874 0.559094 13.5656 0.509819 14.1732C0.460545 14.7807 0.597528 15.3889 0.902529 15.9167C1.20753 16.4445 1.66607 16.8668 2.2171 17.1275L18.2709 24.7306L25.874 40.7844C26.114 41.2985 26.4961 41.7332 26.9752 42.0372C27.4543 42.3412 28.0103 42.5018 28.5777 42.5C28.664 42.5 28.7502 42.4963 28.8365 42.4888C29.4419 42.4397 30.0179 42.2067 30.4871 41.821C30.9563 41.4352 31.2963 40.9152 31.4615 40.3306L42.374 4.34189C42.374 4.33251 42.374 4.32314 42.374 4.31376C42.5209 3.80182 42.5288 3.26 42.397 2.74397C42.2652 2.22794 41.9984 1.7563 41.624 1.37751ZM28.5946 39.4719L28.5852 39.4981L21.2052 23.9188L30.0627 15.0594C30.3322 14.7758 30.4801 14.3982 30.4751 14.007C30.4701 13.6159 30.3125 13.2422 30.0359 12.9656C29.7593 12.689 29.3856 12.5314 28.9945 12.5264C28.6033 12.5213 28.2257 12.6693 27.9421 12.9388L19.0827 21.7963L3.50148 14.4163H3.52773L39.5015 3.50001L28.5946 39.4719Z"
        fill="url(#paint0_linear_1393_48688)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1393_48688"
          x1="42.4903"
          y1="0.499512"
          x2="1.56747"
          y2="43.5156"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={col1} />
          <stop offset="1" stopColor={col2} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SendGradientIcon;
