import { useInfiniteEnterprises } from "@common/BusinessProfileInputs/EnterprisesSelect";
import { Select } from "@common/Select";
import { SelectOptionProps } from "@common/Select/types";
import { QKEY_LIST_ENTERPRISES } from "@constants/queryKeys";
import { WithFetching } from "@containers/WithFetching";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useAppDispatch } from "@redux/hooks";
import { setSearchQueryByKey } from "@redux/slices/search";
import React, { useMemo, useState } from "react";

type ProviderType = {
  accID: number;
  name: string;
};
type Props = {
  providerId: number;
  setProviderId: (id: number) => void;
  isDisabled: boolean;
};

const ProviderSelect = ({ providerId, setProviderId, isDisabled }: Props) => {
  const [error, setError] = useState("");
  const [currentProvider, setCurrentProvider] = useState<
    SelectOptionProps | undefined
  >(undefined);
  const dispatch = useAppDispatch();
  const { merchantId } = useGetCurrentMerchantId();
  const {
    data,
    handleScroll,
    isLoading,
    error: fetchError,
  } = useInfiniteEnterprises({
    filter: `parentAccID:${merchantId}%3B(statusDisplayName:"Approved")`,
  });

  const options = useMemo(() => {
    if (!data) return [];
    return data.map((provider: ProviderType) => ({
      value: provider.accID,
      label: provider.name,
      disabled: false,
    }));
  }, [data]);

  const handleSearch = (value: string) =>
    dispatch(
      setSearchQueryByKey({
        queryKey: QKEY_LIST_ENTERPRISES,
        params: { value },
      }),
    );

  const selectProviderValue = (id: number) => {
    setProviderId(id);
    const provider = options.find((p) => p.value === id);
    if (!provider) return;

    setCurrentProvider({
      ...provider,
      disabled: true,
    });
    handleSearch("");
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (!newValue) return;
    const id = parseInt(newValue);

    if (Number.isNaN(id)) {
      setError("You need to select a valid provider");
      setCurrentProvider(undefined);
    } else if (!options.some((p) => p.value === id)) {
      setError("Please choose a valid provider");
      setCurrentProvider(undefined);
    } else {
      setError("");
      selectProviderValue(id);
    }
  };

  const formattedOptions = useMemo(() => {
    if (!currentProvider) return options;

    const filteredOptions = options.filter(
      (p) => p.value !== currentProvider.value,
    );
    return [currentProvider, ...filteredOptions];
  }, [currentProvider, options]);

  return (
    <WithFetching
      errorsMessage={"The list could not be retrieved due to an error"}
      isFetching={isLoading && !data}
      isError={!!fetchError}
    >
      <Select
        shouldDisplayIcon
        disabled={isDisabled}
        error={!!error}
        helperText={error}
        withSearchBar
        onScroll={handleScroll}
        label="Provider Name"
        placeholder="Choose merchants' provider"
        options={formattedOptions}
        value={providerId || ""}
        handleSearch={handleSearch}
        onChange={onChange}
        isOpen={(open) => !open && handleSearch("")}
        isCaretIcon
        fullWidth
        SearchInputProps={{
          onKeyDown: (e) => e.stopPropagation(),
        }}
      />
    </WithFetching>
  );
};

export default ProviderSelect;
