import { useGetUploadFilePermission } from "@hooks/merchant-api/manage-money/useUploadBankAccountDocument";
import React, { useCallback, useMemo, useRef } from "react";
import { IFileWithMeta } from "react-dropzone-uploader";
import {
  TFileAttachmentType,
  TUploadFilePayload,
  TUploadedFile,
  UploadProgressParams,
  useUploadFiles,
} from "./hooks/useUploadFiles";
import { FileUploadStatus, SnackbarFile, TSnackbarFiles } from "./types";
import { QueryKey, useQueryClient } from "react-query";
import { customInstance } from "@services/api";
import { useUploadPresignedDocument } from "@hooks/enterprise-api/merchants/useUploadPresignedDocument";
import { isNumber } from "lodash";
import { mime } from "./Rebranded/UploadFile";
import { useUploadCustomerFile } from "./hooks/useUploadCustomerFile";
import { useUploadSignature } from "./hooks/useUploadSignature";

type TPopulateSnackbarReturnedType =
  | string[]
  | undefined
  | "upload_failed"
  | TUploadedFile[];

type TPopulateSnackbarFiles = (
  payload: TSnackbarFiles,
  accept?: string,
  challengeSlugParam?: string,
) => Promise<TPopulateSnackbarReturnedType> | null;

type ContextType = {
  snackbarFiles: SnackbarFile[];
  setSnackbarFiles: React.Dispatch<React.SetStateAction<SnackbarFile[]>>;
  failedFiles: { current: IFileWithMeta[] };
  populateSnackbarFiles: TPopulateSnackbarFiles;
  isLoading: boolean;
  isUploadAllowed: boolean;
  isEveryFileUploaded: boolean;
  clearSnackbarFiles: () => void;
  updateDroppedFilesCount: (payload: number | null) => void;
  onDeleteFile: (payload: {
    identifier: number | string;
    accountToRequest: number;
  }) => void;
  onUploadProgress: ({
    fileId,
    progress,
    identifier,
  }: UploadProgressParams) => void;
};

const REJECTED_FILE_STATUSES = [
  FileUploadStatus.FILE_TOO_LARGE,
  FileUploadStatus.FILE_NOT_SUPPORTED,
];

export const FileUploadContext = React.createContext<ContextType>({
  snackbarFiles: [],
  setSnackbarFiles: () => null,
  failedFiles: { current: [] },
  populateSnackbarFiles: () => null,
  isLoading: false,
  isUploadAllowed: true,
  isEveryFileUploaded: false,
  clearSnackbarFiles: () => null,
  updateDroppedFilesCount: () => null,
  onDeleteFile: () => null,
  onUploadProgress: () => null,
});

// If files are too large or are not accepted types, we give them status accordingly, otherwise we consider them as in progress / ready to be uploaded
const getFileStatus = (
  fileStatus: string,
  type: string,
  supportedFiles: string,
) => {
  let status = "";
  if (fileStatus === "error_file_size") {
    status = FileUploadStatus.FILE_TOO_LARGE;
  } else {
    status = FileUploadStatus.IN_PROGRESS;
  }
  if (!type || !supportedFiles.includes(type))
    status = FileUploadStatus.FILE_NOT_SUPPORTED;
  return status;
};

const defaultSupportedFiles = mime.image + "," + mime.applications;

export const FileUploadProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const queryClient = useQueryClient();
  const [snackbarFiles, setSnackbarFiles] = React.useState<SnackbarFile[]>([]);
  const failedFiles = React.useRef<IFileWithMeta[]>([]);
  const { upload, isLoading } = useUpload();
  const isUploadAllowed = useGetUploadFilePermission();
  const droppedFilesCountRef = useRef<number | null>(null);

  const updateDroppedFilesCount = useCallback(
    (numberOfDroppedFiles: number | null) => {
      droppedFilesCountRef.current = numberOfDroppedFiles;
    },
    [],
  );
  const clearSnackbarFiles = () => {
    setSnackbarFiles([]);
  };

  const isEveryFileUploaded = useMemo(
    () => snackbarFiles.every((item) => item.status !== "Uploading..."),
    [snackbarFiles],
  );

  const onUploadProgress = useCallback(
    ({ fileId, progress, identifier }: UploadProgressParams) => {
      setSnackbarFiles((prevState) => {
        const indexToUpdate = prevState.findIndex((file) => file.id === fileId);
        const newState = [...prevState];
        if (indexToUpdate === -1) return newState;

        newState[indexToUpdate].uploadProgress = progress;
        newState[indexToUpdate].identifier = identifier;
        return newState;
      });
    },
    [],
  );

  const onDeleteFile = useCallback(
    async ({
      identifier,
      accountToRequest,
    }: {
      identifier: string | number;
      accountToRequest: number;
    }) => {
      if (isNumber(identifier)) {
        await customInstance({
          url: `/accounts/${accountToRequest}/files/${identifier}`,
          method: "DELETE",
        });
        let refetcherKey: QueryKey | undefined;
        setSnackbarFiles((prevState) => {
          const newState = prevState.map((file) => {
            const toDelete = file.identifier === identifier;
            if (toDelete) {
              refetcherKey = file.refetcherKeyOnDelete;
            }
            return {
              ...file,
              status: toDelete ? FileUploadStatus.DELETED : file.status,
            };
          });
          if (refetcherKey) {
            queryClient.refetchQueries(refetcherKey);
          }
          return newState;
        });
      }
    },
    [],
  );

  const onUploadFinish = useCallback(
    ({ identifiers }: { identifiers: (number | string)[] }) => {
      setSnackbarFiles((prevState) => {
        const newState = prevState.map((file: SnackbarFile) => {
          const status =
            file.identifier && identifiers.includes(file.identifier)
              ? FileUploadStatus.UPLOADED
              : file.status;
          return {
            ...file,
            status,
          };
        });
        return newState;
      });
    },
    [],
  );

  const onUploadFailed = useCallback(({ fileIds }: { fileIds: string[] }) => {
    setSnackbarFiles((prevState) => {
      const newState = prevState.map((file: SnackbarFile) => ({
        ...file,
        status: fileIds.includes(file.id)
          ? FileUploadStatus.FAILED
          : file.status,
      }));
      return newState;
    });
  }, []);

  const populateSnackbarFiles: TPopulateSnackbarFiles = useCallback(
    async (
      payload: TSnackbarFiles,
      supportedFiles = defaultSupportedFiles,
      challengeSlugParam?: string,
    ) => {
      const {
        allFiles: oldAllFiles,
        isDeleteAllowed = true,
        ...uploadProps
      } = payload;
      const allFiles = [...oldAllFiles];

      const isUnderwritingProfileFiles =
        uploadProps.attachmentType === "underwriting_profile";
      /* The reason for this check is that, when we drop multiple big files, they upload slowly and this function is called multiple times.
                   Also in case of large files during first calls of this function, allFiles variable doesn't contain all the dropped files and for this reason multiple requests are sent.
                  */
      if (
        droppedFilesCountRef.current &&
        droppedFilesCountRef.current !==
          allFiles.length + failedFiles.current.length
      )
        return undefined;

      // We need to include files that are too big along with processed files, so we can display them in stack with the necessary message
      const areAllFilesProcessed = allFiles.every(({ meta }) =>
        ["done", "error_file_size"].includes(meta?.status),
      );

      if (!areAllFilesProcessed) return undefined;

      const files = [...allFiles];

      const snackbarData = files.map(({ meta }) => {
        const { id, name, size, status: fileStatus, type } = meta;
        const status = getFileStatus(fileStatus, type, supportedFiles);

        return {
          id,
          name,
          size,
          status,
          /* Initially all the files have progress of 0, until their upload process is started.
                                 This variable will be updated according to the progress value returned by axios request.
                              */
          uploadProgress: 0,
          // storing account id to use it when item is deleted from snackbar
          accountToRequest: uploadProps.merchantId,
          canBeDeleted: isUnderwritingProfileFiles && isDeleteAllowed,
          refetcherKeyOnDelete: uploadProps.refetcherKeyOnDelete,
        };
      });
      const rejectedSnackbarItems = snackbarData.filter((file) =>
        REJECTED_FILE_STATUSES.includes(file.status as FileUploadStatus),
      );
      setSnackbarFiles((prevState) => {
        if (!isUnderwritingProfileFiles && rejectedSnackbarItems.length > 0)
          return rejectedSnackbarItems;
        // take only the new files from allFiles that's not already present in snackbar list
        const newData = snackbarData.filter(
          (newItem) =>
            !prevState.some((snackbarItem) => snackbarItem.id === newItem.id),
        );
        const newSnackbarData = [...prevState, ...newData];
        return newSnackbarData;
      });

      // // We need to filter files here, to make sure that only valid files are uploaded
      const filesToUpload = files
        .filter(({ meta }) => {
          return (
            meta.status === "done" &&
            meta.type &&
            supportedFiles.includes(meta.type)
          );
        })
        .map((f) => ({
          file: f.file,
          id: f.meta.id,
        }));
      // Remove all files from dropzone in documents drop areas
      if (
        uploadProps.attachmentType === "underwriting_profile" ||
        uploadProps.attachmentType === "conversation_message"
      ) {
        updateDroppedFilesCount(null);
        allFiles.forEach((f: IFileWithMeta) => {
          f.remove && f.remove();
        });
      }
      // return and dont call api there are rejected file types and uploading for bank account/pah
      if (!isUnderwritingProfileFiles && rejectedSnackbarItems.length > 0)
        return "upload_failed";
      // If after filtering array contains items, we proceed with uploading, otherwise we hide snackbar after 3 seconds
      const uploadedFiles =
        (await upload(
          {
            ...uploadProps,
            list: filesToUpload,
            onUploadProgress,
            onUploadFinish: payload.customFinish || onUploadFinish,
            onUploadFailed: payload.customFailed || onUploadFailed,
            isCustomerUpload: payload?.isCustomerUpload,
            isSignatureUpload: payload?.isSignatureUpload,
          },
          challengeSlugParam,
        )) || [];

      return uploadedFiles;
    },
    [],
  );

  return (
    <FileUploadContext.Provider
      value={{
        snackbarFiles,
        setSnackbarFiles,
        failedFiles: failedFiles,
        populateSnackbarFiles,
        isLoading,
        isUploadAllowed,
        clearSnackbarFiles,
        updateDroppedFilesCount,
        onDeleteFile,
        isEveryFileUploaded,
        onUploadProgress,
      }}
    >
      {children}
    </FileUploadContext.Provider>
  );
};

type TUploadArgs = Omit<TUploadFilePayload, "merchantId" | "resourceID"> & {
  merchantId?: number;
  resourceID?: number;
  attachmentType?: TFileAttachmentType;
};

const useUpload = () => {
  const { handleUpload, isLoading } = useUploadFiles();
  const { handlePostImage } = useUploadCustomerFile();
  const { handleUpload: uploadPresigned, isLoading: presignedLoading } =
    useUploadPresignedDocument();
  const { handleUploadSignature } = useUploadSignature();
  const upload = async (args: TUploadArgs, challengeSlugParam?: string) => {
    // when we have a merchant
    if (
      args.list.length > 0 &&
      args.merchantId &&
      args.resourceID &&
      !args?.isCustomerUpload &&
      !args?.isSignatureUpload
    ) {
      const uploadedFiles = await handleUpload(
        {
          ...args,
          merchantId: args.merchantId,
          resourceID: args.resourceID,
        },
        challengeSlugParam,
      );
      return uploadedFiles;
    }
    // when we create merchant / provider
    else if (
      args.list.length > 0 &&
      !args.merchantId &&
      !args?.isCustomerUpload &&
      !args?.isSignatureUpload
    ) {
      const uploadedFiles = await uploadPresigned({
        list: args.list,
        onUploadProgress: args.onUploadProgress,
        onUploadFinish: args.onUploadFinish,
        onUploadFailed: args.onUploadFailed,
        attachmentType: args.attachmentType,
      });

      return uploadedFiles;
    } else if (args?.isCustomerUpload) {
      const uploadedFile = await handlePostImage({
        list: args.list,
        onUploadProgress: args.onUploadProgress,
        onUploadFinish: args.onUploadFinish,
        onUploadFailed: args.onUploadFailed,
      });

      return [uploadedFile];
    } else if (args?.isSignatureUpload) {
      const uploadedSignature = await handleUploadSignature({
        list: args.list,
        onUploadProgress: args.onUploadProgress,
        onUploadFinish: args.onUploadFinish,
        onUploadFailed: args.onUploadFailed,
      });

      return [uploadedSignature];
    } else {
      setTimeout(() => {
        if (args.clearSnackbarFiles) args.clearSnackbarFiles();
      }, 3000);
    }
  };

  return { upload, isLoading: isLoading || presignedLoading };
};

export const useFileUploadContext = () => React.useContext(FileUploadContext);
