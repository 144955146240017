import { IdentificationCard } from "@assets/icons/RebrandedIcons";
import { UserFocus } from "@phosphor-icons/react";

export const IDUploaderMapper = {
  businessOwnerID: {
    icon: (fill?: string) => <IdentificationCard fill={fill} />,
    title: "ID Business Owner",
    attachmentType: "business_owner",
    deleteMessage: "Business Owner ID",
  },
  primaryAccountHolderID: {
    icon: (fill?: string) => <IdentificationCard fill={fill} />,
    title: "ID Primary Account Holder",
    attachmentType: "account_owner",
    deleteMessage: "Primary Account Holder ID",
  },
  primaryAccountHolderSelfie: {
    icon: (fill?: string) => <UserFocus size={20} fill={fill} />,
    title: "Selfie Primary Account Holder",
    attachmentType: "account_owner_selfie",
    deleteMessage: "Primary Account Holder Selfie",
  },
};
