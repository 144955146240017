import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import { customInstance } from "@services/api";
import { useMutation, useQueryClient } from "react-query";
import { objectSlicer } from "features/Minibuilders/Conversations/utils/functions";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import useGetGlobalTopic from "features/Minibuilders/Conversations/hooks/useGetGlobalTopic";
import {
  QKEY_LIST_ACQUIRER_MERCHANTS,
  QKEY_LIST_ENTERPRISE_MERCHANTS,
  QKEY_LIST_ENTERPRISE_STATS,
  QKEY_LIST_ENTERPRISES,
} from "@constants/queryKeys";

type TReadMessage = {
  threadID: number;
};

export const useOpenRiskConversation = ({
  threadID,
  hasUnreadMessages,
  merchantId,
  defaultMessage,
  escalationIds,
  factorId,
}: {
  threadID?: number;
  hasUnreadMessages?: boolean;
  merchantId?: number;
  defaultMessage?: string;
  escalationIds?: number[];
  factorId?: number;
}) => {
  const { readMessage } = useReadRiskMessage({
    threadType: factorId ? "trigger" : "activity",
  });
  const { openConversationHandler, updateThreads } = useConversationsModal();
  const { topic: riskActivityTopic } = useGetGlobalTopic("risk_activity");

  const handleOpenRiskConversation = async () => {
    if (!riskActivityTopic) return;
    const generateConversationObj = {
      topicID: riskActivityTopic.ID,
      name: "Risk Activity",
      paths: [],
      defaultMessage: "",
      ...(escalationIds && { escalationIds: escalationIds }),
      ...(factorId && { factorId: factorId }),
      forceTopicOpen: true,
      openThread: {
        isRepliesOpen: false,
        threadId: undefined,
        commentId: undefined,
        index: undefined,
      },
    };

    // if there is no thread associated with activity, we just open the risk_activity topic
    const openNewEmptyThread = () => {
      openConversationHandler({
        ...generateConversationObj,
        defaultMessage,
      });
    };

    if (threadID && threadID !== 0) {
      const { data: _threads } = await customInstance({
        url: `/merchants/${merchantId}/topics/${riskActivityTopic.ID}/threads?filter=id:${threadID}`,
        method: "GET",
      });

      if (_threads) {
        updateThreads(_threads);

        const userAvatars = objectSlicer(
          _threads[0]?.messages,
          1,
          4,
          "authorAvatarImageURL",
        ) as string[];

        const name = `${_threads[0]?.authorFirstName} ${_threads[0]?.authorLastName}`;
        openConversationHandler({
          ...generateConversationObj,
          paths: [
            {
              avatars: userAvatars ? Array.from(new Set(userAvatars)) : [],
              pathName: name,
            },
          ],
          ...(threadID && {
            openThread: {
              commentId: _threads[0]?.messages[0]?.id,
              index: 0,
              isRepliesOpen: true,
              threadId: _threads[0]?.id,
            },
          }),
        });

        if (hasUnreadMessages && threadID) {
          readMessage({ threadID });
        }
      } else {
        openNewEmptyThread();
      }
    } else {
      openNewEmptyThread();
    }
  };

  return {
    handleOpenRiskConversation,
  };
};

export const useReadRiskMessage = ({
  threadType,
}: {
  threadType: "trigger" | "activity";
}) => {
  const { id: globalMerchantId } = useAppSelector(selectUser);
  const queryClient = useQueryClient();

  const { mutate: readMessage } = useMutation({
    mutationFn: async ({ threadID }: TReadMessage) => {
      await customInstance({
        url: `/merchants/${globalMerchantId}/messages/read`,
        method: "POST",
        data: {
          threadID: threadID,
        },
      });
    },
    onSuccess: () => {
      threadType === "activity" &&
        queryClient.refetchQueries("risk-activities");
      threadType === "trigger" && queryClient.refetchQueries("risk-triggers");
      queryClient.invalidateQueries(QKEY_LIST_ENTERPRISE_MERCHANTS);
      queryClient.invalidateQueries(QKEY_LIST_ACQUIRER_MERCHANTS);
      queryClient.invalidateQueries(QKEY_LIST_ENTERPRISES);
      queryClient.invalidateQueries(QKEY_LIST_ENTERPRISE_STATS);
    },
  });

  return {
    readMessage,
  };
};
