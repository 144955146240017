import { Box, BoxProps, styled } from "@mui/material";
import Header from "../manageUnderwriting/Header";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import DefaultView from "./DefaultView";
import { useState } from "react";
import ReportView from "./ReportView";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { OFAC_MATCH_PANEL_WIDTH } from "../utils";
import { ReportType } from "./types";
import moment from "moment";

interface Props {
  headerData: {
    name: any;
    imageURL: any;
  };
  isAcquirer: boolean;
  toggleSecondModal: () => void;
  merchantID: number;
  isMobile?: boolean;
}

export default function MATCHPanel({
  headerData,
  isAcquirer,
  merchantID,
  toggleSecondModal,
  isMobile = false,
}: Props) {
  const { openConversationHandler } = useConversationsModal();
  const [page, setPage] = useState<"default" | "report">("default");
  const [currentReport, setCurrentReport] = useState<ReportType>();
  const handleClickReport = (report: ReportType) => {
    setPage("report");
    setCurrentReport(report);
  };

  const Sections = {
    default: (
      <DefaultView onClickReport={handleClickReport} merchantID={merchantID} />
    ),
    report: <ReportView merchantID={merchantID} report={currentReport} />,
  };
  return (
    <FadeUpWrapper
      delay={50}
      containerProps={{
        height: "100%",
      }}
      customStyle={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container
        isMobileView={isMobile}
        width={OFAC_MATCH_PANEL_WIDTH}
        data-testid="MATCH-panel-container"
      >
        <Header
          firstPanelShowing
          setDefault={() => setPage("default")}
          toggleSecondModal={toggleSecondModal}
          openConversationHandler={openConversationHandler}
          conversationName="MATCH Check"
          showConversations={isAcquirer}
          page={
            page === "default"
              ? page
              : moment.unix(currentReport?.createdAt || 0).format("MMM D, YYYY")
          }
          headerData={headerData}
          mainText="MATCH"
          showBreadcrumb={page === "report"}
        />
        {Sections[page]}
      </Container>
    </FadeUpWrapper>
  );
}

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobileView",
})<BoxProps & { isMobileView: boolean }>(({ isMobileView }) => ({
  position: "relative",
  background: "rgba(251, 251, 251, 0.75)",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  borderRadius: "16px",
  boxShadow: "0px 4px 15px 0px #0000000D",
  gap: "14px",
  zIndex: 1,
  padding: "16px",
  "&:hover": {
    "& .MuiButtonBase-root.MuiButton-root": {
      visibility: "visible",
    },
  },
  ...(isMobileView && { width: "100%" }),
}));
