import { Stack } from "@mui/material";
import { UserList } from "@phosphor-icons/react";
import GiveText from "@shared/Text/GiveText";
import CheckoutDetailsSectionItem from "./CheckoutDetailsSectionItem";
import { GiveInput } from "@shared/GiveInputs/GiveInput";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { Controller } from "react-hook-form";

const ContactSection = () => {
  const { methods } = usePayBuilderForm();
  const isPhoneNumberRendered = methods.watch().Checkout.phoneNumber.render;

  return (
    <Stack gap="12px">
      <Stack direction="row" spacing={2}>
        <UserList size={24} />
        <GiveText variant="bodyL">Contact</GiveText>
      </Stack>
      <CheckoutDetailsSectionItem label="Email" name="email" isEmail />
      <CheckoutDetailsSectionItem
        name="phoneNumber"
        label="Phone Number"
        showRequiredLabel
        showRequiredSwitch
        optionalInput={
          isPhoneNumberRendered ? (
            <Stack paddingLeft="28px">
              <Controller
                control={methods.control}
                name="Checkout.phoneNumber.optionalMessage"
                render={({ field: { onChange, value } }) => {
                  return (
                    <GiveInput
                      label="Note for customer (Optional)"
                      name="phoneNumber.optionalMessage"
                      value={value}
                      onChange={onChange}
                    />
                  );
                }}
              />
            </Stack>
          ) : (
            <></>
          )
        }
      />
    </Stack>
  );
};

export default ContactSection;
