import { Stack } from "@mui/material";
import GiveAvatar from "@shared/Avatar/GiveAvatar";
import GiveText from "@shared/Text/GiveText";
import { capitalizeFirstLetter } from "@utils/index";

interface Props {
  statusUpdatedAt: string;
  imageURL: string;
  by: string;
  status: string;
}

export default function StatusSection({
  statusUpdatedAt,
  imageURL,
  by,
  status,
}: Props) {
  return (
    <Stack gap="6px">
      <Stack direction="row" justifyContent="space-between">
        <GiveText variant="bodyS">{capitalizeFirstLetter(status)} by</GiveText>
        <GiveText variant="bodyS" color="secondary">
          {statusUpdatedAt}
        </GiveText>
      </Stack>
      <Stack direction="row" gap={1} alignItems="center">
        <GiveAvatar imageUrl={`${imageURL}/thumb`} size="24px" />
        <GiveText variant="bodyS" color="secondary">
          {by}
        </GiveText>
      </Stack>
    </Stack>
  );
}
