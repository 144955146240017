import GiveUploadAvatar, {
  UploadAvatarProps,
} from "@shared/GiveUploadAvatar/GiveUploadAvatar";
import { useFormContext, Controller } from "react-hook-form";

type Props = UploadAvatarProps & {
  name: string;
};

const HFGiveUploadAvatar = ({ name, file, ...other }: Props) => {
  const { control, setValue } = useFormContext();

  const handleReset = () => {
    setValue(name, null, { shouldDirty: true });
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <GiveUploadAvatar
            onResetAvatar={handleReset}
            file={field.value}
            {...other}
          />
        );
      }}
    />
  );
};

export default HFGiveUploadAvatar;
