import useCheckSponsor from "@hooks/common/useCheckSponsor";
import { IChipProps } from "@shared/Chip/GiveChip";
import GivePopup from "@shared/Popup/GivePopup";
import GiveText from "@shared/Text/GiveText";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useAppTheme } from "@theme/v2/Provider";
import useUpdateMerchant from "features/Merchants/MerchantSidePanel/hooks/useUpdateMerchant";
import { useMerchantSidePanelContext } from "features/Merchants/MerchantSidePanel/Provider/MerchantSidePanelProvider";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import { useMemo, useState } from "react";
import ChipMenu from "../../ChipMenu";
import { capitalizeFirstLetter } from "@utils/index";

const colorMap = {
  approved: "success",
  declined: "error",
  suspended: "warning",
};

type PopupStatusTypes = "Suspend" | "Approve" | "Resume" | "";

const AccountStatusDropdown = () => {
  const { isMobileView } = useCustomThemeV2();
  const { data } = useMerchantSidePanelContext();
  const { isUpdateUnderwriterAllowed } = useUnderwriterPermissions();
  const statusData = data.status;
  const status = statusData.statusName as keyof typeof colorMap;
  const [modalStatusName, setModalStatusName] = useState<PopupStatusTypes>("");
  const {
    handleSuspendMutation,
    handleApproveMutation,
    handleResumeMutation,
    isApproveSuspendLoading,
  } = useUpdateMerchant();
  const { hasSponsorAccess } = useCheckSponsor();

  const options = useMemo(() => {
    switch (status) {
      case "approved":
        return [
          {
            text: "Suspend Merchant",
            onClick: () => setModalStatusName("Suspend"),
          },
        ];
      case "declined":
        return [
          {
            text: "Resume Underwriting",
            onClick: () => setModalStatusName("Resume"),
          },
          ...(statusData.underwritingScorePercentage &&
          statusData.underwritingScorePercentage >= 80
            ? [
                {
                  text: "Approve Merchant",
                  onClick: () => setModalStatusName("Approve"),
                },
              ]
            : []),
        ];
      case "suspended":
        return [
          {
            text: "Approve Merchant",
            onClick: () => setModalStatusName("Approve"),
          },
        ];
      default:
        return [];
    }
  }, [status]);

  const handleModalClose = () => setModalStatusName("");

  const handleAcceptAction = () => {
    if (modalStatusName === "Suspend") handleSuspendMutation();
    if (modalStatusName === "Approve") handleApproveMutation();
    if (modalStatusName === "Resume") handleResumeMutation();
    handleModalClose();
  };
  const sponsorStatusDidUpdate =
    statusData?.sponsorStatusName &&
    ["pending", "declined", "approved"].includes(statusData?.sponsorStatusName);

  const canModify = sponsorStatusDidUpdate
    ? hasSponsorAccess
    : isUpdateUnderwriterAllowed;

  return (
    <>
      <ChipMenu
        options={options}
        canOpen={canModify && !isApproveSuspendLoading}
        chipProps={{
          variant: "outline",
          color: colorMap[status] as IChipProps["color"],
        }}
        label={capitalizeFirstLetter(status)}
      />
      <GivePopup
        type={status !== "approved" ? "success" : "destructive"}
        iconType={status !== "approved" ? "success-regular" : "destructive-v2"}
        title={`${modalStatusName} Merchant`}
        description={
          <PopupDescriptionText
            status={modalStatusName}
            name={data?.merchantInfo.merchantName || ""}
          />
        }
        open={!!modalStatusName}
        onClose={handleModalClose}
        isMobile={isMobileView}
        paperSx={isMobileView ? { height: "fit-content !important" } : {}}
        buttons={[
          {
            label: "Cancel",
            onClick: handleModalClose,
            variant: "ghost",
          },
          {
            label: `Yes, ${modalStatusName}`,
            onClick: handleAcceptAction,
            variant: "filled",
          },
        ]}
        sx={{
          "& .MuiDialogTitle-root+.MuiDialogContent-root": {
            paddingTop: "0 !important",
          },
          "& .MuiButton-root": {
            width: "fit-content !important",
          },
          "& .MuiDialogActions-root": {
            flexDirection: "row",
            justifyContent:"flex-end"
          },
        }}
      />
    </>
  );
};

const PopupDescriptionText = ({
  status,
  name,
}: {
  status: PopupStatusTypes;
  name: string;
}) => {
  const { palette } = useAppTheme();

  const otherDescriptions = {
    Suspend: `Suspending the account will temporarily stop the merchant from processing transactions or sending money transfers.`,
    Approve: `This action will finalize the approval process.`,
  };

  return (
    <GiveText variant="bodyS" color="secondary">
      Are you sure you want to {status.toLowerCase()}{" "}
      <span style={{ color: palette.text.primary }}>{name}</span>?{" "}
      {otherDescriptions[status as keyof typeof otherDescriptions]}
    </GiveText>
  );
};

export default AccountStatusDropdown;
