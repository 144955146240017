import React, { useEffect } from "react";
import {
  Box,
  CircularProgress,
  Collapse,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { CountryCode } from "libphonenumber-js";

// form
import { FormProvider } from "react-hook-form";
import { RHFInput, RHFTelInput } from "@common/Input";
import { RHFSelect } from "@common/Select";
import CardNumberInput from "./inputs/CardNumberInput";
import ExpirationInput from "./inputs/ExpirationInput";
import CvvInput from "./inputs/CvvInput";
import ZipCodeInput from "./inputs/ZipCodeInput";
import { getCountryCode, getCountryNames } from "@utils/country_dial_codes";
import ProvinceInput from "./inputs/ProvinceInput";
import { palette } from "@palette";
import { Text } from "@common/Text";
import NameOnCardInput from "./inputs/NameOnCardInput";
import { KeyboardArrowRight, KeyboardArrowUp, LockIcon } from "@assets/icons";
import { useCheckout } from "@hooks/merchant-api/cart";
import { KeyVal } from "@common/KeyVal";
import { CartDataType, useGetCart } from "@hooks/merchant-api/cart/useGetCart";
import { parseAmount } from "@utils/index";
import {
  selectCardType,
  selectIsPaymentRecurring,
} from "@redux/slices/checkout";
import { useAppSelector } from "@redux/hooks";
import { RHFCheckbox } from "@common/Checkbox";
import { useTheme } from "@mui/material";
import { LoginExternalLink } from "@components/Login";
import { useGetProductFeePayment } from "@hooks/merchant-api/cart/useGetProductFeePayment";
import { COMPANY_SIGNATURE, TERMS_OF_SERVICE_URL } from "@constants/constants";
import { useGetCardInfos } from "@hooks/merchant-api/cart/useGetCardInfos";
import useCheckEnvironment from "@hooks/common/useCheckEnvironment";
import { isEmpty } from "lodash";

type Props = {
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  destinationAccountMerchantName?: string;
  closeCheckoutDrawer?(): void;
  isMobileOpen?: boolean;
};

const Payment = ({
  setIsDisabled,
  destinationAccountMerchantName,
  closeCheckoutDrawer,
  isMobileOpen,
}: Props) => {
  const { data: cart, refetch } = useGetCart();
  const { handleCardNumberCheck, cardObject } = useGetCardInfos();
  const { methods, onSubmit, CVVLimit, isLoading, isDeclined } = useCheckout({
    destinationAccountMerchantName,
    closeCheckoutDrawer,
    isDebit: cardObject?.binInfo?.cardType === "DEBIT",
  });
  const { needsTax, optional } = useGetProductFeePayment();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [cardFees, setCardFees] = React.useState<number>(0);
  const [defaultCart, setDefaultCart] = React.useState<any>(cart);

  const shouldAutomaticallyShownOptionalFields = React.useRef(true);

  useEffect(() => {
    if (isMobileOpen) {
      refetch();
    }
  }, [isMobileOpen]);

  const {
    watch,
    setValue,
    setFocus,
    formState: { errors, isValid },
  } = methods;
  const values = watch();

  React.useEffect(() => {
    setIsDisabled(!watch("termsConditions"));
  }, [watch("termsConditions")]);

  const checkoutCardType = useAppSelector(selectCardType);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const isPaymentRecurring = useAppSelector(selectIsPaymentRecurring);

  const cartItems = isEmpty(cart?.items) ? defaultCart?.items : cart?.items;
  const cartFees = cart?.fees || defaultCart?.fees;

  const totalAmount = (cartItems || []).reduce(
    (sum: number, item: CartDataType) => sum + item.amount * item.quantity,
    0,
  );

  React.useEffect(() => {
    const isDisabled =
      totalAmount + (cartFees || 0) === 0 ||
      isDeclined ||
      Object.keys(errors).length !== 0 ||
      !values.termsConditions;

    setIsDisabled(!isValid || isLoading || isDisabled);
  }, [values, isLoading, cart, isDeclined]);

  useEffect(() => {
    if (cart.id) {
      setDefaultCart(cart);
    }
  }, [cart]);

  const fees = React.useMemo(() => {
    const feesValue = cardFees || cartFees;
    return feesValue / 100 || 0;
  }, [cartFees, cardFees]);

  React.useEffect(() => {
    setValue("processingFee", needsTax && !optional);
  }, [needsTax, optional]);

  React.useEffect(() => {
    if (values.expiration.length > 6) setFocus("cvv");
  }, [values.expiration]);

  React.useEffect(() => {
    if (!isOpen && isValid && shouldAutomaticallyShownOptionalFields.current) {
      setIsOpen(true);
      shouldAutomaticallyShownOptionalFields.current = false;
    }
  }, [isOpen, isValid]);

  const { platformBaseUrl } = useCheckEnvironment();

  return (
    <FormProvider {...methods}>
      {isLoading && (
        <Box sx={overlayStyles}>
          <Stack alignItems="center">
            <CircularProgress
              size="57px"
              sx={{
                color: palette.accent[3],
              }}
            />
            <Text
              fontSize="16px"
              color={palette.neutral[100]}
              sx={{ marginTop: 1 }}
            >
              Processing your payment...
            </Text>
          </Stack>
        </Box>
      )}

      <Box
        component="form"
        id="credit-card-payment"
        onSubmit={methods.handleSubmit(onSubmit)}
        data-testid="credit-card-payment"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text fontSize="18px" color="#575353">
            Card information
          </Text>
          <Stack direction="row" gap={1} alignItems="center">
            <LockIcon />
            <Text color="#8F8F8F">Secured by {COMPANY_SIGNATURE}</Text>
          </Stack>
        </Stack>
        <Box sx={paymentCardStyle}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <CardNumberInput
                setCardFees={setCardFees}
                handleCardNumberCheck={handleCardNumberCheck}
                cardObject={cardObject}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <ExpirationInput />
            </Grid>
            <Grid item xs={6} md={3}>
              <CvvInput CVVLimit={CVVLimit} />
            </Grid>
            {isDeclined && (
              <Grid item xs={12}>
                <Text
                  my={0.5}
                  fontWeight="book"
                  color={palette.tag.error.text}
                  lineHeight="16.8px"
                >
                  Your transaction got declined. Try again later
                </Text>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <NameOnCardInput />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFInput
                name="email"
                label="Email"
                fullWidth
                placeholder="yourname@email.com"
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          onClick={() => setIsOpen(!isOpen)}
          sx={{
            cursor: "pointer",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt="16px"
          >
            <Text fontSize="18px" color="#575353">
              Additional details <span style={optionalStyle}>(optional)</span>
            </Text>
            {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowRight />}
          </Stack>
        </Box>
        <Collapse orientation="vertical" in={isOpen}>
          <Grid container spacing={1} sx={{ marginBlock: "8px 32px" }}>
            <Grid item xs={12} md={12}>
              <RHFSelect
                name="country"
                label="Country"
                options={getCountryNames().map((country) => ({
                  value: country,
                  label: country,
                }))}
              />
            </Grid>
            <Grid item xs={12}>
              <RHFInput
                name="address"
                label="Street address"
                fullWidth
                placeholder="Street address"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <RHFInput name="city" label="City" fullWidth placeholder="City" />
            </Grid>
            <Grid item xs={12} md={4}>
              <ProvinceInput isUS={values.country === "United States"} />
            </Grid>
            <Grid item xs={12} md={4}>
              <ZipCodeInput />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFInput
                name="occupation"
                label="Occupation"
                fullWidth
                placeholder="Occupation"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFInput
                name="employer"
                label="Employer"
                fullWidth
                placeholder="Employer"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFTelInput
                label={`Phone Number`}
                name="phone"
                disableDropdown
                defaultCountry={
                  (getCountryCode(values.country) as CountryCode | undefined) ||
                  "US"
                }
                fullWidth
              />
            </Grid>
          </Grid>
        </Collapse>
      </Box>
      <Box sx={containerStyle}>
        {/* SubTotal */}
        <KeyVal
          mobileView={true}
          keyName={
            <Text
              variant="headline"
              fontWeight="medium"
              color={palette.neutral[600]}
            >
              Subtotal
            </Text>
          }
          value={
            <Text
              variant="headline"
              fontWeight="medium"
              color={palette.neutral[600]}
              data-testid="payment-subtotal"
            >
              ${parseAmount(totalAmount)} USD
            </Text>
          }
          sx={{ padding: "0 8px", alignItems: "center" }}
        />

        {/* 2 */}
        {needsTax && (
          <Box
            width="100%"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              mb: 1,
            }}
          >
            <KeyVal
              mobileView={true}
              keyName={
                <Text
                  color={
                    values.processingFee
                      ? palette.neutral.black
                      : palette.gray[100]
                  }
                >
                  {checkoutCardType && checkoutCardType !== "Other"
                    ? `${checkoutCardType} Card`
                    : "Processing"}{" "}
                  Fee
                </Text>
              }
              value={
                <Text
                  color={
                    values.processingFee
                      ? palette.neutral.black
                      : palette.gray[100]
                  }
                  fontWeight="medium"
                  data-testid="payment-fees"
                >
                  &#36;{parseAmount(fees)} USD
                </Text>
              }
              sx={{ padding: "8px 8px" }}
            />
          </Box>
        )}

        <Box
          sx={{
            width: "100%",
            border: "1px solid #DDDCE5",
            margin: "8px 0",
          }}
        />
        <KeyVal
          mobileView={true}
          keyName={
            <Text
              variant="h5"
              fontWeight="medium"
              color="#575353"
              fontSize="18px"
              sx={{ mt: "5px" }}
            >
              Amount to be charged
            </Text>
          }
          value={
            <Text
              variant="h6"
              fontWeight="medium"
              color="#575353"
              fontSize="18px"
              textAlign="end"
              data-testid="payment-amount-to-be-charged"
            >
              &#36;
              {parseAmount(
                totalAmount + (cartFees && values.processingFee ? fees : 0),
              )}{" "}
              USD
            </Text>
          }
          sx={{
            padding: "0 8px",
            alignItems: "baseline",
          }}
        />
      </Box>
      <Stack pt={4} pb={isDesktop ? 4 : 0} gap={2}>
        {optional && (
          <RHFCheckbox
            name="processingFee"
            label="I'm covering the processing fee"
          />
        )}
        <Stack
          direction={isDesktop ? "row" : "column"}
          justifyContent={isDesktop ? "space-between" : "flex-start"}
          alignItems={isDesktop ? "center" : "flex-start"}
        >
          <RHFCheckbox
            name="termsConditions"
            label="I accept Give Terms & Conditions"
            dataTestId="termsConditions-checkbox"
          />
          <LoginExternalLink
            underline={palette.neutral[600]}
            href={`${platformBaseUrl}${TERMS_OF_SERVICE_URL}`}
          >
            <Text color={palette.neutral[600]}>
              Read Give Terms & Conditions
            </Text>
          </LoginExternalLink>
        </Stack>
        {isPaymentRecurring && (
          <Text color="#8F8F8F">
            By confirming your payment, you allow Give to charge your card for
            this payment and future payments in accordance with their terms. You
            can always cancel your subscription.
          </Text>
        )}
      </Stack>
    </FormProvider>
  );
};

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "8px",
  width: "100%",
  background: "#FFF",
  borderRadius: "8px",
  marginTop: "32px",
  boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.05)",
};

const paymentCardStyle = {
  background: "#FAFAFB",
  borderRadius: "8px",
  paddingTop: "8px",
};

const optionalStyle = {
  color: palette.neutral[400],
  fontWeight: 400,
};

const overlayStyles = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "transparent",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 9,
};

export default Payment;
