import { ColumnBaseType, TableColumnTypeCustom } from "@common/Table/helpers";
import { Stack } from "@mui/material";
import { SpringValue, animated } from "react-spring";
import {
  ActionsSkeleton,
  AssigneeSkeleton,
  InOutSkeleton,
  MemberSkeleton,
  MerchantFirstCell,
  PhaseSkeleton,
  ProcessingMerchantSkeleton,
  TagSkeleton,
  TagSkeletonRound,
  TextSkeleton,
} from "./atoms";
import { ElementType } from "react";

type TableRowSkeletonProps = {
  height?: SpringValue<number>;
  columns: TableColumnTypeCustom[];
  rowHeight: number;
  Wrapper?: ElementType;
};

const TableRowSkeleton = ({
  height,
  columns,
  rowHeight,
  Wrapper = animated.tr,
}: TableRowSkeletonProps) => {
  return (
    <Wrapper style={{ height }}>
      {columns?.map((column, index) => {
        if (column?.hideColumn || column.columnType === "empty") return null;
        return (
          <td key={index}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="stretch"
              gap={1}
              height={rowHeight}
              sx={{ paddingInline: "20px 16px" }}
            >
              {TableSkeletons[column.columnType || "text"]}
            </Stack>
          </td>
        );
      })}
    </Wrapper>
  );
};

export const TableSkeletons: Record<ColumnBaseType, JSX.Element | null> = {
  text: <TextSkeleton />,
  "merchant-first": <MerchantFirstCell />,
  assignment: <AssigneeSkeleton />,
  phase: <PhaseSkeleton />,
  "in-out": <InOutSkeleton />,
  "processing-merchant": <ProcessingMerchantSkeleton />,
  status: <TagSkeleton />,
  "status-round": <TagSkeletonRound />,
  member: <MemberSkeleton />,
  actions: <ActionsSkeleton />,
  "merchant-last": <TextSkeleton width="35px" />,
  empty: null,
};

export default TableRowSkeleton;
