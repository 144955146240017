import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { styled } from "@theme/v2/Provider";
import CheckoutFormPreview from "./Checkout/CheckoutFormPreview";
import { BaseMainContainer } from "./components/BaseMainContainer";
import { MobileLeftPanelMainHeader } from "./components/MobileLeftPanelMainHeader";

import { usePayBuilderContext } from "./provider/PayBuilderContext";
import { usePayBuilderForm } from "./provider/PayBuilderFormProvider";

import PreviewWrapper from "./components/PreviewWrapper";
import ProductPreviewView from "./components/ProductPreviewView";

const PreviewStep = [
  () => <ProductPreviewView />,
  () => <ProductPreviewView />,
  () => <ProductPreviewView />,
  () => <CheckoutFormPreview />,
  () => <>launch preview</>,
];

function FormPreview() {
  const { isMobileView } = useCustomThemeV2();
  const { activeStepIndex } = usePayBuilderContext();
  const { methods } = usePayBuilderForm();

  const ActiveView = PreviewStep[activeStepIndex];

  return (
    <Container xs={12} sm={8} item>
      {isMobileView && <MobileLeftPanelMainHeader />}

      <PreviewWrapper background={methods.watch().Style.background}>
        <ActiveView />
      </PreviewWrapper>
    </Container>
  );
}

const Container = styled(BaseMainContainer)(({ theme }) => ({
  width: "100%",
  maxHeight: "100%",
  height: "100vh",
  overflowY: "auto",
  alignItems: "center",
}));

export default FormPreview;
