import { showMessage } from "@common/Toast";
import { customInstance } from "@services/api";
import { deleteTrustedDevices } from "@services/api/2FA/trustedDevices";
import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";

export const useDeleteTrustedDevice = () => {
  const queryClient = useQueryClient();
  const deleteMutation = useMutation(
    deleteTrustedDevices,
    {
      onSuccess: (res) => {
        queryClient.refetchQueries("get-trusted-devices");
      },
      onError: (error: unknown) => {
        const axiosError = error as AxiosError;
        showMessage("Error", axiosError.message, true);
      },
    },
  );

  const handleDelete = (deviceId: number) => {
    deleteMutation.mutate(deviceId);
  };

  return {
    handleDelete,
  };
};
