import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import useDocumentPreviewV2 from "@hooks/common/documents/useDocumentPreviewV2";
import { Box, CircularProgress, Stack } from "@mui/material";
import {
  DotsThree,
  DownloadSimple,
  Eye,
  Trash,
  WarningCircle,
  X,
} from "@phosphor-icons/react";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { GiveTagInput } from "@shared/Inputs/GiveEditableTag";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme } from "@theme/v2/Provider";
import React from "react";

const State = {
  UPLOADED: "uploaded",
  LOADING: "loading",
  FAILED: "failed",
} as const;
const Size = {
  DEFAULT: "default",
  MINI: "mini",
} as const;
interface Props {
  state: (typeof State)[keyof typeof State];
  value: string;
  setValue: (value: string) => void;
  isNotice?: boolean;
  noticeContent?: string;
  errorMessage?: string;
  byMessage?: string;
  dateMessage?: string;
  size?: (typeof Size)[keyof typeof Size];
  isMobile?: boolean;
  fileData?: TMerchantDocument;
  merchantId: number;
  isHideDelete?: boolean;
}
const GiveUploadItem = ({
  errorMessage,
  byMessage,
  dateMessage,
  state,
  size = Size.DEFAULT,
  isMobile,
  isNotice = false,
  noticeContent,
  value,
  setValue,
  fileData,
  merchantId,
  isHideDelete = false,
}: Props) => {
  const { palette } = useAppTheme();
  const [hovered, setHovered] = React.useState(false);
  const { handlePreview } = useDocumentPreviewV2();

  const handlePreviewClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (!fileData?.fileURL) return;
    handlePreview(fileData);
  };

  return (
    <Stack
      gap="12px"
      padding="12px"
      width="100%"
      borderBottom={`1px solid ${palette.border?.primary}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={isMobile && size === Size.DEFAULT ? "flex-start" : "center"}
      >
        <Stack direction="column" gap="8px">
          <Stack direction="row" gap="12px" alignItems="center">
            {state === State.LOADING && (
              <CircularProgress
                size={14}
                sx={{ color: palette.text.primary }}
              />
            )}
            <GiveText
              variant={size === Size.DEFAULT ? "bodyS" : "bodyXS"}
              color="primary"
            >
              {fileData?.fileName}
            </GiveText>
            {Size.DEFAULT === size && state === State.UPLOADED && !isMobile && (
              <GiveTagInput
                value={value}
                setValue={setValue}
                isMobile={isMobile}
              />
            )}
          </Stack>
          <Description
            state={state}
            errorMessage={errorMessage}
            byMessage={byMessage}
            dateMessage={dateMessage}
            size={size}
            isMobile={isMobile}
            value={value}
            setValue={setValue}
          />
        </Stack>
        <Right
          state={state}
          hovered={hovered}
          isMobile={isMobile}
          handlePreviewClick={handlePreviewClick}
          isHideDelete={isHideDelete}
        />
      </Stack>
      {state === State.UPLOADED && isNotice && (
        <Box
          sx={{
            background: palette.surface?.secondary,
            padding: "8px",
            borderRadius: "4px",
            textAlign: "center",
          }}
        >
          <GiveText variant="bodyXS" color="secondary">
            {noticeContent}
          </GiveText>
        </Box>
      )}
    </Stack>
  );
};

export default GiveUploadItem;

const Right = ({
  hovered,
  state,
  isMobile,
  handlePreviewClick,
  isHideDelete = false,
}: {
  state: (typeof State)[keyof typeof State];
  hovered?: boolean;
  isMobile?: boolean;
  handlePreviewClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isHideDelete?: boolean;
}) => {
  const { palette } = useAppTheme();
  if (state === State.LOADING || state === State.FAILED) {
    return <GiveIconButton Icon={X} size="small" />;
  }

  if (isMobile) {
    return <GiveIconButton Icon={DotsThree} size="small" />;
  }
  return hovered ? (
    <Stack direction="row" gap="4px">
      <GiveIconButton Icon={Eye} size="small" onClick={handlePreviewClick} />
      <GiveIconButton Icon={DownloadSimple} size="small" />
      {!isHideDelete && (
        <GiveIconButton
          Icon={Trash}
          size="small"
          color={palette.primitive?.error[50]}
        />
      )}
    </Stack>
  ) : null;
};
const Description = ({
  errorMessage,
  byMessage,
  dateMessage,
  state,
  size,
  isMobile,
  value,
  setValue,
}: {
  errorMessage?: string;
  byMessage?: string;
  dateMessage?: string;
  state: (typeof State)[keyof typeof State];
  size: (typeof Size)[keyof typeof Size];
  isMobile?: boolean;
  value: string;
  setValue: (value: string) => void;
}) => {
  const { palette } = useAppTheme();
  if (state === State.FAILED) {
    return (
      <Stack direction="row" gap="8px" alignItems="center">
        <WarningCircle size={16} color={palette.primitive?.error[50]} />
        <GiveText
          variant={size === Size.DEFAULT ? "bodyXS" : "bodyXXS"}
          color="error"
        >
          {errorMessage}
        </GiveText>
      </Stack>
    );
  }
  if (state === State.LOADING) {
    return null;
  }
  if (isMobile && size === Size.DEFAULT) {
    return (
      <Stack direction="row" gap="8px" alignItems="center">
        <GiveTagInput value={value} setValue={setValue} isMobile={isMobile} />

        <GiveText
          variant={size === Size.DEFAULT ? "bodyXS" : "bodyXXS"}
          color="secondary"
        >
          {dateMessage}
        </GiveText>
      </Stack>
    );
  }

  return (
    <Stack direction="row" gap="8px">
      <GiveText
        variant={size === Size.DEFAULT ? "bodyXS" : "bodyXXS"}
        color="primary"
      >
        {byMessage}
      </GiveText>
      <GiveText
        variant={size === Size.DEFAULT ? "bodyXS" : "bodyXXS"}
        color="secondary"
      >
        {dateMessage}
      </GiveText>
    </Stack>
  );
};
