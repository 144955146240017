import { LinearProgress } from "@mui/material";
import { useAppTheme } from "@theme/v2/Provider";

interface Props {
  progress: number;
}
const SnackbarLinearProgress = ({ progress }: Props) => {
  const p = useAppTheme().palette;
  return (
    <LinearProgress
      variant="determinate"
      value={progress}
      sx={{
        borderRadius: "4px",
        height: "2px",
        backgroundColor: p.text?.secondary,
        ".MuiLinearProgress-bar": {
          backgroundColor: p.surface?.tertiary,
        },
      }}
    />
  );
};

export default SnackbarLinearProgress;
