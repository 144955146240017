import React from "react";
import BaseCardComponent from "../BaseCardComponent";
import useSnapShot from "features/Merchants/MerchantSidePanel/hooks/useSnapShot";

function MonthlyAccountFees() {
  const { monthlyAccountFees } = useSnapShot();
  return (
    <BaseCardComponent
      leftTitle="Monthly Account Fees"
      payload={monthlyAccountFees}
    />
  );
}

export default MonthlyAccountFees;
