import NiceModal from "@ebay/nice-modal-react";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import GiveText from "@shared/Text/GiveText";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { useGetMerchantById } from "@hooks/enterprise-api/account/useGetMerchants";

const RefundPolicyModal = NiceModal.create(() => {
  const { open, onClose } = useNiceModal();
  const { data: merchantData } = useGetMerchantById();

  return (
    <GiveBaseModal
      open={open}
      title="Refund Policy"
      width="600px"
      onClose={onClose}
      showFooter={false}
    >
      <GiveText
        color="secondary"
        variant="bodyS"
        sx={{ whiteSpace: "pre-line" }}
      >
        {merchantData.msaRefundPolicy}
      </GiveText>
    </GiveBaseModal>
  );
});

export default RefundPolicyModal;
