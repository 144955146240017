import { Box, Divider, Stack } from "@mui/material";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import NiceModal from "@ebay/nice-modal-react";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { TDocument } from "./types";
import usePdfPreviewer from "./hooks/usePdfPreviewer";
import useImagePreviewer from "./hooks/useImagePreviewer";
import { styled } from "@theme/v2/Provider";
import { DownloadSimple, Minus, Plus } from "@phosphor-icons/react";
import GiveIconButton from "@shared/IconButton/GiveIconButton";

type Props = {
  file: TDocument;
  handleDownload: (file: TDocument | null) => void;
};

const GiveFilePreviewModal = NiceModal.create(
  ({ file, handleDownload }: Props) => {
    const { name } = file || {};
    const { open, onClose } = useNiceModal();

    const { Previewer, onZoom: onPdfZoom } = usePdfPreviewer({
      file,
      customDocumentWrapperStyles: pdfPreviewStyles,
    });

    const { Previewer: ImagePreviewer, onZoom: onImageZoom } =
      useImagePreviewer({
        file,
        customImageStyle: {
          maxWidth: "100%",
          maxHeight: "100%",
          transformOrigin: "0 0",
        },
      });

    const isPdf = file?.type === "pdf";

    const previewer = isPdf ? Previewer : ImagePreviewer;

    const CustomFooter = (
      <Stack
        direction="row"
        gap="16px"
        alignItems="center"
        justifyContent="center"
      >
        <GiveIconButton
          Icon={Minus}
          onClick={() => (isPdf ? onPdfZoom("out") : onImageZoom("out"))}
          bgColor="solidWhite"
        />
        <GiveIconButton
          Icon={Plus}
          onClick={() => (isPdf ? onPdfZoom("in") : onImageZoom("in"))}
          bgColor="solidWhite"
        />
        <Divider orientation="vertical" flexItem />
        <GiveIconButton
          Icon={DownloadSimple}
          onClick={() => handleDownload(file)}
          bgColor="solidWhite"
        />
      </Stack>
    );

    return (
      <GiveBaseModal
        open={open}
        title={name}
        width="1040px"
        height="100%"
        onClose={onClose}
        closeIconProps={{
          bgColor: "solidWhite",
        }}
        customFooter={CustomFooter}
        customDialogStyle={customDialogStyles}
      >
        <ContentWrapper>{previewer}</ContentWrapper>
      </GiveBaseModal>
    );
  },
);

export default GiveFilePreviewModal;

const ContentWrapper = styled(Box)(() => ({
  width: "100%",
  margin: "0 auto",
  height: "100%",
  minWidth: "20%",
  maxWidth: "100%",
  overflowY: "auto",
  touchAction: "auto",
  userSelect: "none",
  MozUserSelect: "none",
  WebkitUserDrag: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const pdfPreviewStyles = {
  width: "100%",
  height: "100%",
  "& .react-pdf__Document": {
    overflow: "hidden",
  },
  "& .react-pdf__Page__canvas": {
    margin: "0 auto",
  },
  "& .react-pdf__Page:first-of-type": {
    marginTop: "0px",
  },
};

const customDialogStyles = {
  "& .MuiDialog-paper": {
    "& .MuiDialogContent-root": {
      height: "60vh",
      padding: "0px !important",
    },
  },
};
