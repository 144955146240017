import { Grid, Stack } from "@mui/material";
import { RHFInput } from "@common/Input";
import { MAX_TEXT_LENGTH } from "@constants/constants";
import { useFormContext } from "react-hook-form";
import ModalSectionTitle, { ModalSectionTitleProps } from "./atoms";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";

type FundraisersAboutProps = ModalSectionTitleProps & {
  isEdit?: boolean;
  fontSize?: number | string;
  placeHolderText?: string;
  mobileFontSize?: number | string;
};

const FundraisersAbout = ({
  title,
  titleSize = "large",
  isEdit = false,
  fontSize,
  mobileFontSize,
  placeHolderText,
}: FundraisersAboutProps) => {
  const { watch } = useFormContext();
  const values = watch();

  return (
    <Stack direction="column" gap={isEdit ? 2 : 4} flexGrow={1}>
      <FadeUpWrapper delay={100}>
        <ModalSectionTitle
          title={title}
          titleSize={titleSize}
          fontSize={fontSize}
          mobileFontSize={mobileFontSize}
        />
      </FadeUpWrapper>
      <FadeUpWrapper delay={200}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <RHFInput
              name="about.title"
              label="Title"
              placeholder="Enter a suitable title"
              inputProps={{ maxLength: 300 }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <RHFInput
              name="about.description"
              label="Description (recommended)"
              placeholder={placeHolderText}
              multiline
              rows={8}
              fullWidth
              inputProps={{
                maxLength: `${MAX_TEXT_LENGTH}`,
              }}
              helperText={`${
                MAX_TEXT_LENGTH - values?.about?.description?.length
              }`}
            />
          </Grid>
        </Grid>
      </FadeUpWrapper>
    </Stack>
  );
};

export default FundraisersAbout;
