import { Text } from "@common/Text";
import { Menu, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { CaretDown } from "@phosphor-icons/react";
import { MATCH_STATUS, MATCHFormFields, ReportFile, ReportType } from "./types";
import { DropdownItem } from "@common/Select";
import { MouseEventHandler, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment";
import ReportFields from "./ReportFields";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ModalActions } from "@common/Modal/ModalFactory/atoms";
import NiceModal from "@ebay/nice-modal-react";
import { CONFIRMATION_POP_UP } from "modals/modal_names";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import {
  useCreateMATCHReport,
  useGetSingleMATCHReport,
} from "./hooks/useMATCHReports";

interface Props {
  merchantID: number;
  data?: ReportType;
  showLatest?: boolean;
  onClose: () => void;
}

export default function ReportModalContent({
  merchantID,
  showLatest,
  data: defaultData,
  onClose,
}: Props) {
  const { data } = useGetSingleMATCHReport(merchantID, defaultData?.ID);
  const { isMobileView } = useCustomTheme();
  const methods = useForm<MATCHFormFields>({
    resolver: yupResolver(
      Yup.object().shape({
        findings: Yup.string().required("This field is required"),
        status: Yup.string(),
      }),
    ),
    defaultValues: {
      status: defaultData?.statusName || "clear",
      findings: defaultData?.findings || "",
      files: defaultData?.files,
    },
  });

  useEffect(() => {
    if (data)
      methods.reset({
        status: data.statusName,
        findings: data.findings,
        files: data.files,
      });
  }, [data]);

  const { status, files } = methods.watch();

  const { handleSubmit } = useCreateMATCHReport({ merchantID, files });
  const handleChangeStatus = (val: MATCH_STATUS) => {
    methods.setValue("status", val);
  };

  const attemptUpload = (file: File) => {
    const id = Math.random().toString(36).substring(2, 9);
    const lastDotIndex = file.name.lastIndexOf(".") + 1;
    const fileType = file.name.substring(lastDotIndex);
    methods.setValue(
      "files",
      [
        ...(files || []),
        {
          file,
          id,
          updatedAt: moment.now() / 1000, //the DocumentItem component doesn't show the correct value otherwise, because it multiplies it with 1000
          fileName: file.name,
          fileURL: URL.createObjectURL(file),
          fileType: fileType,
        },
      ],
      { shouldDirty: true },
    );
  };

  const handleDelete = (file: ReportFile) => {
    const updated = files?.filter((item) => item.id !== file.id);
    methods.setValue("files", updated);
  };

  const onSubmit = (values: MATCHFormFields) => {
    NiceModal.show(CONFIRMATION_POP_UP, {
      variant: "match_report",
      onClick: () => {
        handleSubmit({ data: values });
        onClose();
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <Stack
        gap="16px"
        pt="16px"
        px="24px"
        flex={1}
        overflow="auto"
        data-testid="report-modal-container"
      >
        <Stack
          direction={isMobileView ? "column" : "row"}
          alignItems={isMobileView ? "start" : "center"}
          gap="6px"
        >
          <Text fontSize="14px" color={palette.neutral[80]}>
            {showLatest ? "Status:" : "Please set the most appropriate status:"}
          </Text>
          <MATCHStatusMenu
            status={status}
            handleChangeStatus={handleChangeStatus}
            disabled={Boolean(showLatest)}
          />
        </Stack>
        <ReportFields
          viewOnly={showLatest}
          isModal
          onUpload={attemptUpload}
          onDelete={handleDelete}
          merchantID={merchantID}
          files={files}
        />
      </Stack>
      {!showLatest && (
        <ModalActions
          containerProps={{
            sx: {
              mt: "20px",
              px: "24px",
              gap: isMobileView ? "2px" : "16px",
            },
          }}
          fullWidth={isMobileView}
          primaryAction={{
            label: "Submit",
            background: "primary",
            type: "submit",
            dataTestId: "report-submit",
            onClick: methods.handleSubmit(onSubmit),
          }}
          secondaryAction={{
            label: "Cancel",
            onClick: onClose,
            dataTestId: "cancel-report",
          }}
        />
      )}
    </FormProvider>
  );
}

function MATCHStatusMenu({
  status,
  handleChangeStatus,
  disabled,
}: {
  status: MATCH_STATUS;
  handleChangeStatus: (status: MATCH_STATUS) => void;
  disabled: boolean;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const mapper = useMemo(
    () => ({
      confirmed_match: {
        color: "#D80D0D",
        backgroundColor: "#F5E0DF",
        label: "Confirmed match",
        optionLabel: "Change to Confirmed Match",
        value: "confirmed_match" as MATCH_STATUS,
      },
      clear: {
        color: "#13A75A",
        backgroundColor: "#E6F3EC",
        label: "Clear",
        optionLabel: "Change to Clear Match",
        value: "clear" as MATCH_STATUS,
      },
    }),
    [],
  );

  const options = useMemo(() => {
    return Object.values(mapper).filter((item) => item.value !== status);
  }, [status]);

  const handleChange = (value: MATCH_STATUS) => {
    handleClose();
    handleChangeStatus(value);
  };

  return (
    <>
      <StatusMenu
        color={mapper[status].color}
        backgroundColor={mapper[status].backgroundColor}
        onClick={handleClick}
        disabled={disabled}
        data-testid="MATCH-Details-Menu"
      >
        <Text>{mapper[status].label}</Text>
        {!disabled && <CaretDown color={mapper[status]?.color} />}
      </StatusMenu>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            width: "max-content",
          },
        }}
      >
        {options.map((option, index) => (
          <DropdownItem
            onClick={() => handleChange(option.value)}
            key={index}
            data-testid="MATCH-Dropdown-Item"
          >
            <Text color={mapper[option.value]?.color}>
              {option.optionLabel}
            </Text>
          </DropdownItem>
        ))}
      </Menu>
    </>
  );
}

const StatusMenu = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "color" && prop !== "backgroundColor",
})<{ backgroundColor: string; color: string; disabled: boolean }>(
  ({ backgroundColor, color, disabled }) => ({
    borderRadius: "16px",
    cursor: "pointer",
    color: color,
    backgroundColor: backgroundColor,
    padding: `2px ${disabled ? "16px" : "12px"} 2px 16px`,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 1,
    ...(disabled && {
      pointerEvents: "none",
    }),
  }),
);
