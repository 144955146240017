import { Box, SxProps } from "@mui/material";
import { Check, Copy } from "@phosphor-icons/react";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { styled } from "@theme/v2/Provider";
import { useState } from "react";

type Props = {
  text: string;
};

const CopyButton = ({ text }: Props) => {
  const [isCopied, setIsCopied] = useState(false);
  const handleCopy = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    navigator?.clipboard?.writeText(text);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <Box>
      <GiveTooltip
        title={isCopied ? "Link copied" : "Copy link"}
        placement="top"
        color="default"
        iconRight={isCopied ? <Check size={18} /> : null}
      >
        <GiveIconButton
          Icon={Copy}
          size="small"
          variant={isCopied ? "filled" : "ghost"}
          onClick={handleCopy}
          sx={{
            pointerEvents: isCopied ? "none" : "auto",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        ></GiveIconButton>
      </GiveTooltip>
    </Box>
  );
};

export default CopyButton;
