import { InputAdornment } from "@mui/material";
import {
  CheckCircle,
  Icon,
  Prohibit,
  WarningCircle,
} from "@phosphor-icons/react";
import GiveSelect from "@shared/GiveInputs/GiveSelect";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useAppTheme } from "@theme/v2/Provider";
import { formatString } from "@utils/helpers";
import { capitalizeFirstLetter } from "@utils/index";
import React, { useMemo } from "react";
import useFetchMerchantRisk from "../../hooks/useFetchMerchantRisk";
import useUpdateMerchant from "../../hooks/useUpdateMerchant";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  ACTION_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { CustomToolTip as PermissionTooltip } from "componentsV2/Table/CustomTooltip";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { GIVE_CONFIRMATION_POP_UP } from "modals/modal_names";

type RiskLevel = "normal" | "high" | "restricted";

const riskLevelConstants: Record<
  RiskLevel,
  { color: string | undefined; Icon: Icon }
> = {
  normal: {
    color: undefined,
    Icon: CheckCircle,
  },
  high: {
    color: undefined,
    Icon: WarningCircle,
  },
  restricted: {
    color: undefined,
    Icon: Prohibit,
  },
};

const MerchantRiskCategoryInput: React.FC = () => {
  const { data } = useMerchantSidePanelContext();
  const { palette } = useAppTheme();
  const { isMobileView } = useCustomThemeV2();
  const { categoryCodeID, category, categoryCodeName, merchantRiskStatus } =
    data?.merchantInfo || {};
  const { remove } = useModal(GIVE_CONFIRMATION_POP_UP);

  const { updateMerchantMutation } = useUpdateMerchant();
  const { mutate, isLoading: isUpdateLoading } = updateMerchantMutation;
  const { data: categoriesData, isLoading } = useFetchMerchantRisk();

  const getRiskLevelColor = (riskLevel: RiskLevel): string | undefined => {
    switch (riskLevel) {
      case "normal":
        return palette?.primitive?.success?.[50];
      case "high":
        return palette?.filled?.orange;
      case "restricted":
        return palette?.primitive?.error?.[50];
      default:
        return undefined;
    }
  };

  const getInputValueColor = (label: string | undefined) => {
    const riskKey = (label?.toLowerCase() as RiskLevel) || "normal";
    return {
      color: getRiskLevelColor(riskKey),
      Icon: riskLevelConstants[riskKey].Icon,
    };
  };

  const options = useMemo(() => {
    return (
      categoriesData?.data?.map((category: any) => ({
        label: capitalizeFirstLetter(category?.name),
        value: category?.name,
        IconLeft: getInputValueColor(category?.name).Icon,
        checkedIconType: "Check",
        isSelected:
          formatString(category?.name) ===
          formatString(merchantRiskStatus || ""),
      })) || []
    );
  }, [categoriesData, merchantRiskStatus]);

  const Icon = getInputValueColor(merchantRiskStatus).Icon;
  const isDisabled = !categoryCodeID || isLoading || isUpdateLoading;

  const allowedEditMerchant = useAccessControl({
    resource: RESOURCE_BASE.MERCHANT,
    operation: OPERATIONS.UPDATE,
  });

  const handleSelectChange = (e: any) => {
    const value = e?.target?.value;
    if (value) {
      NiceModal.show(GIVE_CONFIRMATION_POP_UP, {
        modalType: "change-mcc",
        title: "Change MCC",
        description: (
          <>
            The risk level for MCC{" "}
            <strong>
              {category} - {categoryCodeName}
            </strong>{" "}
            is currently set to{" "}
            <strong>{capitalizeFirstLetter(merchantRiskStatus)}</strong>. Are
            you sure you want to change it to{" "}
            <strong>{capitalizeFirstLetter(value)}</strong>? This change will
            apply only to this merchant.
          </>
        ),
        actions: {
          handleSuccess: {
            onClick: () => {
              mutate({ merchantRiskStatus: `${value}` });
              remove();
            },
          },
          handleCancel: {
            onClick: remove,
          },
        },
      });
    }
  };

  return (
    <PermissionTooltip
      showToolTip={!allowedEditMerchant}
      message={ACTION_DENY_MESSAGE}
      placement="top"
      alignment="end"
    >
      <GiveSelect
        disabled={isDisabled || !allowedEditMerchant}
        label="Merchant Risk Category"
        options={options}
        value={merchantRiskStatus}
        inputValueColor={getInputValueColor(merchantRiskStatus).color}
        useContextualMenu
        onChange={handleSelectChange}
        {...(!isMobileView
          ? {
              contextualMenuProps: {
                color: "transparent",
                texture: "blurred",
                menuWidth: 512,
                anchorOrigin: {
                  vertical: -20,
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
              },
            }
          : {})}
        InputProps={{
          startAdornment:
            isDisabled || !merchantRiskStatus ? null : (
              <InputAdornment sx={{ ml: "16px" }} position="start">
                <Icon
                  size={20}
                  fill={getInputValueColor(merchantRiskStatus).color}
                />
              </InputAdornment>
            ),
        }}
      />
    </PermissionTooltip>
  );
};

export default MerchantRiskCategoryInput;
