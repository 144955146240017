import {
  SLUG_MAX_PREFIX_LENGTH_DESKTOP,
  SLUG_MAX_PREFIX_LENGTH_MOBILE,
} from "@constants/constants";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

const useSlugPrefixFormatter = (prefix: string) => {
  const { isMobileView } = useCustomTheme();
  const maxLength = isMobileView
    ? SLUG_MAX_PREFIX_LENGTH_MOBILE
    : SLUG_MAX_PREFIX_LENGTH_DESKTOP;

  if (prefix?.length <= maxLength) return prefix;

  return `${prefix?.slice(0, maxLength)}...`;
};

export default useSlugPrefixFormatter;
