import { Stack, styled } from "@mui/material";
import TransactionListHeader from "./TransactionListHeader";
import UnfoldListItems from "@components/animation/UnfoldListItems";
import ListEmptyState from "./ListEmptyState";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  selectTimeFilter,
  selectTypeFilter,
  setTimeFilter,
} from "@redux/slices/acquirer/transactionRiskProfile";
import { Button } from "@common/Button";
import { palette } from "@palette";
import ListGroup from "./ListGroup";
import { FormattedListItem } from "features/TransactionsRiskProfile/data.types";
import { WithAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";

interface ListProps {
  list: FormattedListItem[];
  hasMore: boolean;
}

const TransactionsList = ({ list, hasMore }: ListProps) => {
  const timeFilter = useAppSelector(selectTimeFilter);
  const typeFilter = useAppSelector(selectTypeFilter);
  const dispatch = useAppDispatch();

  const showMore = () => dispatch(setTimeFilter("month"));

  const resource = {
    all: RESOURCE_BASE.IPPROFILE_TRANSACTION,
    escalations: RESOURCE_BASE.IPPROFILE_ESCALATION,
  }[typeFilter];

  return (
    <Container>
      <TransactionListHeader />
      <WithAccessControl
        resource={resource}
        operation={OPERATIONS.LIST}
        withPortal
      >
        {list.length === 0 ? (
          <ListEmptyState timeFrame={timeFilter === "day" ? "24h" : "30d"} />
        ) : (
          <>
            <UnfoldListItems
              items={list}
              renderKey={(row) => row.label}
              animationName="waterfall"
              ItemComponent={(row) => <ListGroup currentGroup={row} />}
            />
            {timeFilter === "day" && hasMore && (
              <SeeMoreButton background="tertiary" onClick={showMore}>
                See more
              </SeeMoreButton>
            )}
          </>
        )}
      </WithAccessControl>
    </Container>
  );
};

const Container = styled(Stack)(() => ({
  flexDirection: "column",
  gap: "24px",
  alignItems: "stretch",
  padding: "0 24px 80px",
}));

const SeeMoreButton = styled(Button)(() => ({
  marginInline: "auto",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "16.8px",
  color: palette.gray[300],
}));

export default TransactionsList;
