import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { Stack, styled } from "@mui/material";
import { palette } from "@palette";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import ChipsContainer from "@common/TableFilters/WrapperWithDateRange";
import CustomVolumePopup from "@common/TableFilters/CustomVolumePopup";
import Placeholder from "assets/images/Placeholder.png";
import {
  businessClassificationType,
  defaultValuesProvidersFilter,
  DefaultValuesTypeProviderFilters,
  enterpriseStatusArray,
  getApprovedMerchantsArray,
  getProcessedArray,
  getTransactionsArray,
  QUERYOBJECTKEYS_PROVIDERS_FILTERS,
  UnderwritingScoreValuesPercentage,
} from "./constants";
import { isArray, isEmpty, isEqual } from "lodash";
import Chip from "@common/TableFilters/Chip";
import { StarIcon } from "@assets/icons";
import { Switch_V2 } from "@common/Switch";
import { findByIdAndModify } from "@utils/helpers";
import { FilterObjectTypes } from "types/utils.types";
import {
  getCustomProcessingLabel,
  getProcessingAmount,
  SelectorOption,
} from "@common/TableFilters/utils";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  resetFilterByKey,
  selectFiltersObject,
  setFilterByKey,
} from "@redux/slices/dynamicFilterSlice";
import { QKEY_LIST_ENTERPRISE_STATS } from "@constants/queryKeys";
import { selectMerchantStatusName } from "@redux/slices/enterprise/merchants";
import { StatusNames } from "@components/Merchants/MerchantList/MerchantStatusFilters";
import SectionItem from "@common/TableFilters/SectionItem";
import UpTrendIconV2 from "@assets/icons/UpTrendIconV2";
import {
  StyledAvatar,
  StyledDivider,
} from "@common/TableFilters/hooks/useFilterSections";
import DownTrendIconV2 from "@assets/icons/DownTrendIconV2";
import useUserReducer from "@hooks/Reducers/useUserReducer";
import AssigneesSelector from "@common/TableFilters/AssigneesSelector";
import ListItem from "@common/TableFilters/ListItem";

export default function useProviderFilter() {
  const { open, onClose, SlideProps } = useNiceModal();
  const statusName = useAppSelector(selectMerchantStatusName);
  const {
    img: currUserImg,
    id: currUserId,
    email: currUserEmail,
  } = useUserReducer();
  const isProviderUnderwriting =
    StatusNames?.enterpriseUnderwriting === statusName;

  const filterObject = useAppSelector((state) =>
    selectFiltersObject(state, QKEY_LIST_ENTERPRISE_STATS),
  ) as DefaultValuesTypeProviderFilters;
  const methods = useForm<DefaultValuesTypeProviderFilters>({
    defaultValues: isEmpty(filterObject)
      ? defaultValuesProvidersFilter
      : filterObject,
  });
  const values = methods.watch();
  const dispatch = useAppDispatch();
  const isObjectsEqual = isEqual(
    values,
    isEmpty(filterObject) ? defaultValuesProvidersFilter : filterObject,
  );
  const canClearObj = isEqual(values, defaultValuesProvidersFilter);
  const onSubmit = (data: any) => {
    dispatch(
      setFilterByKey({
        filterKey: QKEY_LIST_ENTERPRISE_STATS,
        params: data,
      }),
    );
    onClose();
  };

  const actions = {
    primaryAction: {
      label: "Apply filters",
      disabled: isObjectsEqual,
      sx: {
        fontSize: "18px",
        lineHeight: "18px",
        padding: "12px 24px",
      },
    },
    secondaryAction: {
      label: "Clear all filters",
      onClick: () => {
        methods.reset(defaultValuesProvidersFilter);
        dispatch(
          resetFilterByKey({
            filterKey: QKEY_LIST_ENTERPRISE_STATS,
          }),
        );
      },
      sx: {
        fontSize: "18px",
        lineHeight: "18px",
        padding: "12px 24px",
        color: palette.filled.red,
      },
      hidden: canClearObj,
    },
  };

  const handleMultiSelect = (
    fieldName: QUERYOBJECTKEYS_PROVIDERS_FILTERS.underwriterEmail,
    value: any,
  ) => {
    const currentValues: any =
      !!values[fieldName] && Array.isArray(values[fieldName])
        ? (values[fieldName] as SelectorOption[])
        : [];

    // replace form value if the value param is an array
    if (Array.isArray(value)) {
      methods.setValue(fieldName, value);
      return;
    }
    if (
      currentValues &&
      currentValues.find((item: any) => item.id === value.id)
    ) {
      methods.setValue(
        fieldName,
        currentValues?.filter((item: any) => item.id !== value.id),
      );
    } else {
      const vals = currentValues ? [...currentValues, value] : [value];
      methods.setValue(fieldName, vals);
    }
  };

  const renderAmountFlow = ({
    key,
    array,
    prefix,
  }: {
    key:
      | QUERYOBJECTKEYS_PROVIDERS_FILTERS.Transactions
      | QUERYOBJECTKEYS_PROVIDERS_FILTERS.Processed
      | QUERYOBJECTKEYS_PROVIDERS_FILTERS.approved_merchants;
    array: {
      label: string;
      value: string;
    }[];
    prefix?: string;
  }) => {
    return (
      <Wrapper>
        {array?.map((option) => {
          const selectedValue = values?.[key];
          const isCustom =
            (selectedValue?.label?.toLowerCase().includes("custom") &&
              option?.label === "Custom") ||
            false;
          const isSelected = selectedValue?.label === option?.label || isCustom;
          const isMoney = key === QUERYOBJECTKEYS_PROVIDERS_FILTERS?.Processed;
          return (
            <Chip
              key={option.value}
              item={
                isCustom
                  ? {
                      label: selectedValue?.label || "Custom",
                      value: option.value,
                    }
                  : option
              }
              selected={isSelected}
              onSelect={() => {
                methods.setValue(key, isSelected ? {} : option);
              }}
              {...(option.value === "custom" && {
                PopupComponent: (e) => (
                  <CustomVolumePopup {...e} isMoney={isMoney} />
                ),
                popupCallback: (e) =>
                  handleSaveProcessingVolume({
                    ...e,
                    key: key,
                    prefix: key,
                    isMoney,
                  }),
              })}
            />
          );
        })}
      </Wrapper>
    );
  };

  const renderChipsArray = ({
    arr,
    key,
  }: {
    arr: any[];
    key:
      | QUERYOBJECTKEYS_PROVIDERS_FILTERS.enterpriseStatus
      | QUERYOBJECTKEYS_PROVIDERS_FILTERS.businessClassification;
  }) => {
    return (
      <Wrapper>
        {arr?.map((option) => {
          const selectedArray = values?.[key] || [];
          if (!isArray(selectedArray)) return;
          const isSelected = selectedArray?.find(
            (item: any) => item.value === option.value,
          );

          return (
            <Chip
              key={option.value}
              item={option}
              selected={!!isSelected}
              allowMultiSelect
              onSelect={() => {
                const newArray = findByIdAndModify(
                  selectedArray as FilterObjectTypes[],
                  option,
                );
                methods.setValue(key, newArray, {
                  shouldDirty: true,
                });
              }}
            />
          );
        })}
      </Wrapper>
    );
  };

  const filterSections = useMemo(() => {
    const watchList = {
      title: "Watchlist only",
      description: "See providers only in the watchlist",
      startIcon: <StarIcon fill={palette.neutral[80]} />,
      endElement: (
        <Switch_V2
          size="small"
          containerProps={{ alignItems: "initial" }}
          name={QUERYOBJECTKEYS_PROVIDERS_FILTERS.watchlist}
          checked={Boolean(values.watchlist)}
          onChange={(e, checked) =>
            methods.setValue(
              QUERYOBJECTKEYS_PROVIDERS_FILTERS.watchlist,
              checked ? checked : "",
            )
          }
        />
      ),
    };
    const enterpriseStatus = {
      title: "Provider Status",
      description: "View providers based on their current status",
      children: renderChipsArray({
        arr: enterpriseStatusArray,
        key: QUERYOBJECTKEYS_PROVIDERS_FILTERS.enterpriseStatus,
      }),
    };
    const businessClassification = {
      title: "Business Classification Type",
      description: "Filter providers by business classification type",
      children: renderChipsArray({
        arr: businessClassificationType,
        key: QUERYOBJECTKEYS_PROVIDERS_FILTERS.businessClassification,
      }),
    };
    const Processed = {
      title: "Processed",
      description: "See providers with a specific processed range",
      children: renderAmountFlow({
        key: QUERYOBJECTKEYS_PROVIDERS_FILTERS.Processed,
        array: getProcessedArray(QUERYOBJECTKEYS_PROVIDERS_FILTERS.Processed),
      }),
    };

    const Transactions = {
      title: "Transactions",
      description: "Total number of transactions",
      children: renderAmountFlow({
        key: QUERYOBJECTKEYS_PROVIDERS_FILTERS.Transactions,
        array: getTransactionsArray(
          QUERYOBJECTKEYS_PROVIDERS_FILTERS.Transactions,
        ),
      }),
    };

    const ApprovedMerchants = {
      title: "Approved Merchants",
      description: "Total number of approved merchants",
      children: renderAmountFlow({
        key: QUERYOBJECTKEYS_PROVIDERS_FILTERS.approved_merchants,
        array: getApprovedMerchantsArray(
          QUERYOBJECTKEYS_PROVIDERS_FILTERS.approved_merchants,
        ),
      }),
    };

    const signupDate = {
      title: "Signup Date",
      description: "See providers that signed up within a specific date range",
      children: (
        <Wrapper>
          <ChipsContainer
            dateName={QUERYOBJECTKEYS_PROVIDERS_FILTERS.createdAt}
            selectedDate={values?.signupDate}
            rangeSaveCallback={(val) =>
              methods.setValue(
                QUERYOBJECTKEYS_PROVIDERS_FILTERS.signupDate,
                val,
                {
                  shouldDirty: true,
                },
              )
            }
          />
        </Wrapper>
      ),
    };

    const underwritingScore = {
      title: "Underwriting Score",
      description: "Specify merchants based on certain underwriting scores",
      children: (
        <Stack direction="column">
          <SectionItem
            containerStyle={{ paddingInline: 0 }}
            startIcon={<UpTrendIconV2 />}
            title="Score exceeds 80%"
            description="See merchants with score of 80% or higher"
            endElement={
              <Switch_V2
                size="small"
                containerProps={{ alignItems: "initial" }}
                checked={
                  values?.[
                    QUERYOBJECTKEYS_PROVIDERS_FILTERS
                      .underwritingChallengeScorePercentage
                  ] === UnderwritingScoreValuesPercentage.overEighty
                }
                onChange={(e, checked) =>
                  methods.setValue(
                    QUERYOBJECTKEYS_PROVIDERS_FILTERS.underwritingChallengeScorePercentage,
                    checked ? UnderwritingScoreValuesPercentage.overEighty : "",
                  )
                }
              />
            }
          />
          <StyledDivider />
          <SectionItem
            startIcon={<DownTrendIconV2 />}
            containerStyle={{ paddingInline: 0 }}
            title="Score below 80%"
            description="See merchants with score of 80% or lower"
            endElement={
              <Switch_V2
                size="small"
                containerProps={{ alignItems: "initial" }}
                checked={
                  values?.[
                    QUERYOBJECTKEYS_PROVIDERS_FILTERS
                      .underwritingChallengeScorePercentage
                  ] === UnderwritingScoreValuesPercentage.belowEighty
                }
                onChange={(e, checked) =>
                  methods.setValue(
                    QUERYOBJECTKEYS_PROVIDERS_FILTERS.underwritingChallengeScorePercentage,
                    checked
                      ? UnderwritingScoreValuesPercentage.belowEighty
                      : "",
                  )
                }
              />
            }
          />
        </Stack>
      ),
    };

    const assignToObj = {
      title: "Assigned to me",
      description: "See merchants that are assigned to you",
      startIcon: <StyledAvatar src={currUserImg || Placeholder} />,
      endElement: (
        <Switch_V2
          size="small"
          containerProps={{ alignItems: "initial" }}
          checked={Boolean(
            values.underwriterEmail?.find(
              (item) => item.value === currUserEmail,
            ),
          )}
          onChange={(e, checked) =>
            handleMultiSelect(
              QUERYOBJECTKEYS_PROVIDERS_FILTERS.underwriterEmail,
              {
                label: currUserEmail,
                value: currUserEmail,
                id: `${currUserId}`,
              },
            )
          }
        />
      ),
      children: (
        <Stack gap={1}>
          <StyledDivider />
          <SectionItem
            containerStyle={{ padding: 0 }}
            title="Other Assignees"
            description="See merchants that are assigned to specific assignees"
            endElement={
              <AssigneesSelector
                id={currUserId}
                selectedValues={values?.underwriterEmail}
                handleMultiSelect={handleMultiSelect as any}
                type="underwriting"
              />
            }
          />
          <Stack spacing="6px" paddingTop={1}>
            {!!values?.underwriterEmail &&
              values.underwriterEmail
                ?.filter((item) => item.value !== currUserEmail)
                .map((item: SelectorOption) => (
                  <ListItem
                    key={item.label}
                    item={item}
                    onDelete={(val) =>
                      handleMultiSelect(
                        QUERYOBJECTKEYS_PROVIDERS_FILTERS.underwriterEmail,
                        val,
                      )
                    }
                  />
                ))}
          </Stack>
        </Stack>
      ),
    };

    return isProviderUnderwriting
      ? [
          watchList,
          underwritingScore,
          businessClassification,
          assignToObj,
          signupDate,
        ]
      : [
          watchList,
          enterpriseStatus,
          businessClassification,
          Processed,
          Transactions,
          ApprovedMerchants,
          signupDate,
        ];
  }, [values, isProviderUnderwriting]);

  const handleSaveProcessingVolume = (param: {
    from: number | undefined;
    to: number | undefined;
    key: string;
    prefix?: string;
    isMoney: boolean;
  }) => {
    const factor = param.isMoney ? 100 : 1;
    const stringValue = getProcessingAmount({
      ...(typeof param.from === "number" && {
        amount: param.from * factor,
      }),
      ...(typeof param.to === "number" && { secondAmount: param.to * factor }),
      modifier: "is between",
      prefix: param?.prefix,
    });

    const customLabel = getCustomProcessingLabel(param?.from, param?.to);
    methods.setValue(param?.key as any, {
      label: customLabel,
      value: stringValue,
    });
  };

  return {
    open,
    onClose,
    SlideProps,
    methods,
    onSubmit,
    actions,
    filterSections,
  };
}

const Wrapper = styled(Stack)(() => ({
  display: "flex",
  flexDirection: "row",
  spacing: "8px",
  flexWrap: "wrap",
  gap: "8px",
}));
