import { Button } from "@mui/material";
import { ButtonProps } from "./GiveButton.types";

const GiveButton = ({ label, variant = "filled", ...rest }: ButtonProps) => {
  return (
    <Button
      disableTouchRipple
      disableRipple
      disableFocusRipple
      variant={variant}
      version="two"
      {...rest}
    >
      {label}
    </Button>
  );
};

export default GiveButton;
