import { Theme, useMediaQuery } from "@mui/material";
import { isMobile } from "@utils/index";

export function useCustomThemeV2() {
  const isMobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("v2_sm"),
  );

  const isTabletView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between("v2_sm", "v2_md"),
  );

  const isDesktopView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("v2_md"),
  );

  const isSmallDesktop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between("v2_md", "v2_lg"),
  );

  return {
    isMobileView,
    isTabletView,
    isDesktopView,
    isMobileDevice: isMobile,
    isSmallDesktop,
  };
}
