import { ExpandRightIcon } from "@assets/builderIcons";
import { NewLogoutIcon } from "@assets/icons";
import { ChevronRight } from "@assets/icons/RebrandedIcons";
import { Button } from "@common/Button";
import { StyledIconButton } from "@common/IconButton";
import { Text, TruncateText } from "@common/Text";
import ProfilePicture from "@components/CustomBreadcrumbsPage/ components/ProfilePicture";
import SwipeableDrawerMobile from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import useUserReducer from "@hooks/Reducers/useUserReducer";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { LOGOUT_MODAL } from "modals/modal_names";
import { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useProfileForms } from "../hooks/useProfileForms";
import TFAModalWrapper from "./ProfileModal2FAWrapper";
import { TProfileModalPage, TProfileTab } from "./types";

type Props = {
  sidebarList: {
    tab: TProfileTab;
    active: boolean;
  }[];
  ProfilePage: TProfileModalPage;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabled: boolean;
  initialTab?: TProfileTab;
};

const ProfileMobileDrawer = ({
  sidebarList,
  ProfilePage,
  setDisabled,
  isDisabled,
  initialTab,
}: Props) => {
  const modal = useModal();
  const { name, email } = useUserReducer();
  const [tab, setTab] = useState<TProfileTab | null>(null);
  const { is2FAEnabled } = useGetFeatureFlagValues();

  useEffect(() => {
    if (initialTab && modal.visible) setTab(initialTab);
  }, [modal.visible]);

  const handleLogout = () => {
    modal.hide();
    NiceModal.show(LOGOUT_MODAL);
  };

  const ActivePage = tab ? ProfilePage[tab]?.component : null;
  const { onSubmit, methods, isLoading, isDirty } = useProfileForms(
    tab as string,
  );

  const trigger2FA = () => {
    NiceModal.show(TFAModalWrapper, {
      email: email,
      isMobileView: true,
      onSuccessFn: methods?.handleSubmit?.(onSubmit),
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (tab === "Security" && is2FAEnabled) {
      return trigger2FA();
    } else {
      methods?.handleSubmit?.(onSubmit, console.log).call();
    }
  };

  useEffect(() => {
    if (methods?.reset) methods?.reset();
  }, [tab]);

  return (
    <SwipeableDrawerMobile
      anchor="bottom"
      onOpen={() => undefined}
      open={modal.visible}
      onClose={() => {
        modal.hide();
        setTab(null);
      }}
      PaperProps={{
        sx: {
          background: palette.neutral[5],
          top: "96px",
          paddingBottom: "60px",
          position: "relative",
          height: "90%",
        },
      }}
    >
      {!tab && (
        <>
          <Stack direction="row" p="8px 0px" spacing={1} mb={2}>
            <ProfilePicture pointerEventsNone />
            <Stack spacing={0.5}>
              <TruncateText
                lineClamp={1}
                color={palette.neutral[80]}
                fontWeight="book"
              >
                {name}
              </TruncateText>
              <TruncateText
                lineClamp={1}
                fontSize={12}
                color={palette.neutral[70]}
                fontWeight="book"
                sx={{
                  wordBreak: "break-all",
                }}
              >
                {email}
              </TruncateText>
            </Stack>
          </Stack>
          {/* <Stack direction="row" spacing={1} alignItems="center" my={3}>
        <DarkModeIcon width={32} height={32} />
        <Text fontWeight="book" color={palette.neutral[90]} flexGrow={1}>
          Dark Mode
        </Text>
        <Switch size="medium" containerProps={{ gap: "0" }} />
      </Stack> */}
          <Stack spacing={1.5}>
            {sidebarList.map((item) => (
              <Tab
                key={item.tab}
                data-testid={item.tab}
                onClick={() => setTab(item.tab)}
              >
                <Text color={palette.neutral[80]} fontWeight="book">
                  {item.tab}
                </Text>
                <ExpandRightIcon height={24} width={24} />
              </Tab>
            ))}
          </Stack>

          <Box
            flexGrow={1}
            display="flex"
            alignItems="flex-end"
            justifyContent="center"
          >
            <LogoutButton
              size="medium"
              onClick={handleLogout}
              startIcon={<NewLogoutIcon fill={palette.neutral[70]} />}
            >
              Log out
            </LogoutButton>
          </Box>
        </>
      )}

      {tab && ActivePage && (
        <FormProvider {...methods}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
            component={tab === "Notifications" ? "div" : "form"}
            onSubmit={tab === "Notifications" ? null : handleSubmit as any}
          >
            <Stack direction="row" gap={1} alignItems="center" mb={2}>
              <StyledIconButton
                onClick={() => setTab(null)}
                sx={{
                  maxWidth: "32px",
                  maxHeight: "32px",
                  borderRadius: "4px",
                  padding: "0 !important",
                }}
              >
                <ChevronRight
                  rotate={180}
                  width={28}
                  height={28}
                  stroke={palette.gray[300]}
                />
              </StyledIconButton>
              <Text
                color={palette.black[100]}
                variant="h6"
                lineHeight="21.6px"
                fontWeight="book"
                letterSpacing="-0.18px"
              >
                {tab}
              </Text>
            </Stack>
            <ActivePage setDisabled={setDisabled} />

            <Box flexGrow={1} pb={4} />
            {(isDirty || (!isDisabled && tab === "Notifications")) && (
              <Stack mt={2} direction="row" pb={8}>
                <Button
                  fullWidth
                  size="medium"
                  background="tertiary"
                  onClick={() => setTab(null)}
                >
                  Cancel
                </Button>
                <Button
                  size="medium"
                  background="primary"
                  type="submit"
                  data-testid="popup-submit-button"
                  fullWidth
                  form={tab === "Notifications" ? "profile-form" : undefined}
                  disabled={isLoading || (tab === "Security" && isDisabled)}
                >
                  Update
                </Button>
              </Stack>
            )}
          </Box>
        </FormProvider>
      )}
    </SwipeableDrawerMobile>
  );
};

const Tab = styled(Box)(() => ({
  padding: "12px 8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "8px",
  background: palette.background.dimmedWhite,
  "&:active": {
    background: palette.neutral[10],
  },
}));

const LogoutButton = styled(Button)(() => ({
  borderRadius: "32px",
  border: `1px solid ${palette.neutral[70]}`,
  background: palette.neutral[10],
  color: palette.neutral[80],
  fontSize: 18,
  fontWeight: "400",
  boxShadow: "none",
  "&:hover": {
    background: palette.neutral[10],
  },
}));

export default ProfileMobileDrawer;
