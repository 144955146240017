import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import NiceModal from "@ebay/nice-modal-react";
import React from "react";
import useManageMoneyFilter from "./useManageMoneyFilter";
import { FormProvider } from "react-hook-form";
import { Box } from "@mui/material";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import ModalContent from "@common/TableFilters/ModalContent";

const ManageMoneyFilterModal = NiceModal.create(() => {
  const {
    onClose,
    SlideProps,

    open,
    methods,
    onSubmit,
    actions,
    filterSections,
  } = useManageMoneyFilter();
  return (
    <ModalFactory
      variant="sidepanel"
      modalProps={{
        open,
        onClose: onClose,
        SlideProps,
        SidePanelProps: {
          width: "500px",
        },
      }}
    >
      <FormProvider {...methods}>
        <Box
          width="100%"
          height="100%"
          display="flex"
          sx={{
            padding: "16px",
            gap: "24px",
            flexDirection: "column",
            justifyContent: "space-between",
            overflow: "hidden",
          }}
          component="form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <ModalTitle
            title="Filter by"
            textStyles={{
              title: {
                fontSize: "18px",
                lineHeight: "21.6px",
                letterSpacing: "-0.18px",
              },
            }}
            closeButtonSize={24}
            onClose={onClose}
          />
          <ModalContent sections={filterSections} />
          <ModalActions animationDelay={250} {...actions} />
        </Box>
      </FormProvider>
    </ModalFactory>
  );
});

export default ManageMoneyFilterModal;
