import { Stack, Box, BoxProps, styled } from "@mui/material";
import BreadcrumbsAppBar, {
  APP_BAR_HEIGHT,
  PageContainer,
} from "./BreadcrumbsAppBar";
import { memo, useCallback, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { MASQUERADE_SNACKBAR_HEIGHT } from "@components/Merchants/Masquerade/MasqueradeSnackbar";
import { isMobileSafari } from "@utils/helpers";
import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";
import { useSelector } from "react-redux";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { rebrandedPages } from "@constants/constants";
export const PAGE_PADDING_TOP = 16;

interface MuiBoxProps extends BoxProps {
  isMasqueradeMode?: boolean;
}

interface StyleMap {
  [key: string]: string;
}

const cls: StyleMap = {
  "/provider/processing": "vlList",
  "/acquirer/processing": "vlList",
  "/acquirer/disputes": "vlList",
  "/merchant/manage-money": "vlList",
  "/merchant/fundraisers/:id": "vlList",
  "/merchant/events/:id": "vlList",
  "/merchant/invoices/:id": "vlList",
  "/merchant/memberships/:id": "vlList",
  "/merchant/sweepstakes/:id": "vlList",
  "/acquirer/transfers": "vlList",
  "/provider/transfers": "vlList",
  "/acquirer/settlements": "vlList",
  "/acquirer/settlements/:id": "vlList",
};

const BreadcrumbsPageContainer = ({ children, ...rest }: MuiBoxProps) => {
  const { isRebrandingEnabled, isRebrandingTableEnabled } =
    useGetFeatureFlagValues();
  const { isMasqueradeMode } = useMasqueradeReducer();
  const { isMobileView } = useCustomTheme();

  const appBarRef = useRef<HTMLDivElement>(null);
  const pageRebranded = rebrandedPages.includes(location.pathname);

  const isNewTableEnabled = isRebrandingEnabled && isRebrandingTableEnabled;

  const newTableApplied = pageRebranded && isNewTableEnabled;

  const enabledHeader = !isRebrandingEnabled;

  const classes = useStyles({
    isMasqueradeMode,
    newTableApplied,
  });

  const handleScroll = useCallback((e: any) => {
    const { scrollTop } = e.currentTarget;
    if (scrollTop > 0) {
      appBarRef.current?.classList.add(classes.appBarActive);
    } else {
      appBarRef.current?.classList.remove(classes.appBarActive);
    }
  }, []);

  const isEnabledVl = useSelector<any, boolean>(
    (state: any) => state.app.enabledVL,
  );

  const path: keyof typeof cls = Object.keys(cls).find((key) => {
    const regex = new RegExp(`^${key.replace(/:[^/]+/g, "[^/]+")}$`);
    return regex.test(location.pathname);
  }) as keyof typeof cls;

  const dynamicStyleKey = cls[path];
  const dynamicStyle = classes[dynamicStyleKey as keyof typeof classes];

  return (
    <RootContainer {...rest}>
      {enabledHeader && ( // TODO: Remove this once when all pages are rebranded
        <BreadcrumbsAppBar
          isMasqueradeMode={isMasqueradeMode}
          appBarRef={appBarRef}
        />
      )}
      <PageContainer
        id="page-container"
        className={`${classes.pageContainer} ${
          isEnabledVl ? dynamicStyle : ""
        }`}
        onScroll={handleScroll}
        mt={isMasqueradeMode ? `${MASQUERADE_SNACKBAR_HEIGHT}px` : 0}
        maxHeight={`calc(100vh - ${
          !isMasqueradeMode
            ? APP_BAR_HEIGHT
            : APP_BAR_HEIGHT + MASQUERADE_SNACKBAR_HEIGHT
        }px )`}
      >
        <Stack
          flexGrow={1}
          pt={!newTableApplied ? (isMobileView ? "64px" : "16px") : 0}
        >
          {children}
        </Stack>
      </PageContainer>
    </RootContainer>
  );
};

export default memo(BreadcrumbsPageContainer);

const RootContainer = styled(Box)(() => ({
  flexGrow: 1,
  overflow: "hidden",
  position: "relative",
  maxHeight: "100vh",
}));

const useStyles = makeStyles(() => ({
  appBarActive: {
    boxShadow: "4px 10px 15px -3px #3131311A",
    background: "#FBFBFBE5",
    backdropFilter: "blur(4px)",
  },
  pageContainer: ({ newTableApplied }: any) => ({
    paddingTop: "16px",
    overflowY: "scroll",
    height: "100%",
    ...(isMobileSafari() && {
      maxHeight: window.innerHeight,
    }),
    "&::-webkit-scrollbar": {
      display: "none",
    },
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    ...(newTableApplied && {
      // TODO: Remove this once we have rebranding completed
      padding: "0 !important",
      width: "100%",
      maxWidth: "100%",
      height: "100%",
      maxHeight: "100%",
    }),
  }),
  vlList: {
    maxWidth: "unset",
    paddingLeft: 0,
    paddingRight: 0,
  },
}));
