import { Button } from "@common/Button";
import { Text } from "@common/Text";
import { Box, keyframes, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { LinkBreak } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";

const ExpiredPasswordReset = ({
  title = "The link is expired",
  message = ` Sorry, the link you followed has expired and the page you are
            looking for is no longer available`,
}) => {
  const navigate = useNavigate();
  return (
    <StyledRoot alignContent="center" justifyContent="center" height="100vh">
      <Stack gap={3} alignItems="center">
        <GradientPurpleBlueWrapper>
          <LinkBreak size={100} fill={"url(#gradient-purple-blue)"} />
        </GradientPurpleBlueWrapper>
        <Stack gap={0.5}>
          <Text variant="h4" color={palette.neutral[100]} textAlign="center">
            {title}
          </Text>
          <Text
            color={palette.neutral[70]}
            variant="smallest"
            textAlign="center"
          >
            {message}
          </Text>
        </Stack>
        <Button size="large" onClick={() => navigate("/login")}>
          Go to homepage
        </Button>
      </Stack>
    </StyledRoot>
  );
};

export default ExpiredPasswordReset;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const StyledRoot = styled(Box)(() => ({
  animation: `${fadeIn} 1s ease-out`,
}));

const GradientPurpleBlueWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        id="gradient-purple-blue"
        x1="81.6354"
        y1="0.866211"
        x2="2.51522"
        y2="84.0536"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#72ECF0" />
        <stop offset="1" stopColor="#528DDF" />
      </linearGradient>
    </defs>
    {children}
  </svg>
);
