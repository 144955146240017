import Skeleton, { DoubledSkeleton } from "@components/animation/Skeleton";
import { Box, Grid, Stack } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

const MerchantPreviewLoadingSkeleton = () => {
  const { isMobileView } = useCustomTheme();

  return (
    <Box
      padding="20px 16px"
      data-testid="merchant-preview-skeleton"
      sx={{
        ...(isMobileView && {
          overflowY: "scroll",
          "::-webkit-scrollbar-track": {
            marginBlock: "16px",
          },
        }),
      }}
    >
      {!isMobileView && (
        <Box
          width="100%"
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
          display="flex"
          mb={3}
        >
          <Skeleton borderRadius="20px" width="258px" height="24px" />
          <Skeleton width="150px" borderRadius="20px" height="24px" />
        </Box>
      )}

      <Stack
        spacing={isMobileView ? 1 : 2}
        direction={isMobileView ? "column" : "row"}
        mb={2}
      >
        <Skeleton borderRadius="4px" width="56px" height="56px" />
        <Stack spacing="13px">
          <Skeleton height="21px" width="70%" />
          <Stack spacing={2} direction="row">
            <DoubledSkeleton
              frequency={3}
              height={isMobileView ? "15px" : "17px"}
              width="143px"
              direction={isMobileView ? "column" : "row"}
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack spacing={1}>
        <Skeleton borderRadius="17.5px" height="12.25px" width="282px" />
        <Skeleton
          borderRadius="17.5px"
          height="12.25px"
          width={isMobileView ? "287px" : "547px"}
        />
      </Stack>

      <Stack direction="row" spacing={3} my={3}>
        {Array(3)
          .fill(0)
          .map((_, index) => (
            <Stack key={index} spacing={2}>
              <DoubledSkeleton
                height="17px"
                width={isMobileView ? "70px" : "95px"}
              />
            </Stack>
          ))}
      </Stack>

      <Stack
        sx={{
          background: palette.neutral.white,
          boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.10)",
          borderRadius: "12px",
          padding: "16px",
        }}
      >
        <Stack spacing={2} mb={2}>
          <Skeleton borderRadius="22.5px" height="15px" width="116px" />
          <Stack spacing={1}>
            {Array(2)
              .fill(0)
              .map((_, index) => (
                <Skeleton key={index} height="12px" width="72px" />
              ))}
          </Stack>
        </Stack>

        <Grid container rowSpacing={2}>
          {Array(2)
            .fill(0)
            .map((_, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <DoubledSkeleton height="12px" width="60px" />
              </Grid>
            ))}
          {Array(2)
            .fill(0)
            .map((_, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <DoubledSkeleton height="12px" width="111px" />
              </Grid>
            ))}
          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <Skeleton height="12px" width="114px" />
              <Stack spacing={1} direction="row" alignItems="center">
                <Skeleton variant="circular" width={24} height={24} />
                <Skeleton height="12px" width="114px" />
              </Stack>
            </Stack>
          </Grid>
          {!isMobileView && (
            <Grid item xs={12} sm={6}>
              <DoubledSkeleton height="12px" width="114px" />
            </Grid>
          )}
        </Grid>
      </Stack>

      <Skeleton
        sx={{ mt: "52px", mb: 3 }}
        borderRadius={22.5}
        height="15.75px"
        width="113px"
      />
      <Box display="flex" justifyContent="space-between">
        <DoubledSkeleton
          frequency={7}
          spacing={3}
          width="132px"
          height="12.25"
          borderRadius={17.5}
        />
        <DoubledSkeleton
          frequency={7}
          spacing={3}
          width="44px"
          height="12.25"
          borderRadius={12.25}
        />
      </Box>
    </Box>
  );
};

export default MerchantPreviewLoadingSkeleton;
