import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
// components
import { ModalTitle } from "@common/Modal/ModalFactory/atoms";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import NiceModal from "@ebay/nice-modal-react";
import { Box } from "@mui/material";
import { ShareProps } from "@sections/Actions/Share/share.types";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useAppTheme } from "@theme/v2/Provider";
import EmbedModalContent from "./EmbedModalContent";

const EmbedModal = NiceModal.create(
  ({ title, productId, type }: ShareProps) => {
    const { onClose, TransitionProps, SlideProps, open, modal } =
      useNiceModal();
    const { breakpoints } = useAppTheme();
    const { isMobileView } = useCustomThemeV2();
    const isDesktop = useMediaQuery(breakpoints.up("sm"));
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    const handleCancel = () => modal.hide();

    return (
      <ModalFactory
        variant="dialog"
        modalProps={{
          open,
          onClose,
          DrawerProps: {
            SlideProps,
            PaperProps: {
              sx: {
                "&.MuiPaper-root": {
                  padding: "unset",
                },
              },
            },
          },
          DialogProps: {
            TransitionProps,
            width: "720px",
            contentContainerSx: {
              // overflowY: "auto",
            },
            PaperProps: {
              "& .MuiPaper-root": {},
            },
            scroll: "paper",
          },
        }}
      >
        <ModalTitle
          title="Share"
          padding="16px"
          onClose={handleCancel}
          textStyles={{ title: { fontSize: 18, lineHeight: "21.6px" } }}
          showDivider
        />
        <Box
          sx={{
            overflowY: "auto",
            padding: isMobileView ? "16px" : "16px 6px 16px 16px",
          }}
        >
          <EmbedModalContent productId={productId} type="event" />
        </Box>
      </ModalFactory>
    );
  },
);

export default EmbedModal;
