import { Box, BoxProps, Divider, Stack, styled } from "@mui/material";
import OFACStatus from "./OFACStatus";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";
import { ArrowForwardIcon } from "@assets/icons";
import { Text, TruncateText } from "@common/Text";
import { palette } from "@palette";
import { OFACCheckStatusType } from "../hooks/useOFAC";
import { ACTION_DENY_MESSAGE } from "@constants/permissions";
import { formatTimestampWithTimezone } from "@utils/index";

interface Props {
  lastCheck?: {
    lastCheckStatusName?: OFACCheckStatusType;
    lastCheckAt?: number;
    nextCheckAt?: number;
    resourceID?: number;
  };
  openDetails: (checkId?: number) => void;
  checkId?: number;
  isEditOfacAllowed?: boolean;
  name?: string;
  ownershipPercentage?: number;
  showDivider?: boolean;
  isNonResident?: boolean;
}

export default function CheckItem({
  lastCheck,
  openDetails,
  checkId,
  isEditOfacAllowed,
  name,
  ownershipPercentage,
  showDivider,
  isNonResident,
}: Props) {
  const cannotOpenDetails =
    !isEditOfacAllowed ||
    !lastCheck?.lastCheckStatusName ||
    (!!name && !checkId);

  return (
    <Box>
      <LatestCheck
        isOwner={!!name}
        hideArrow={!checkId}
        onClick={cannotOpenDetails ? undefined : () => openDetails(checkId)}
        data-testid="OFAC-Status-Card"
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing="8px">
            {isNonResident && <OFACStatus status="non_resident" />}
            <OFACStatus status={lastCheck?.lastCheckStatusName || "clear"} />
          </Stack>

          <CustomToolTip
            message={ACTION_DENY_MESSAGE}
            showToolTip={Boolean(lastCheck) && !isEditOfacAllowed}
          >
            <OfacArrowBtn className="ofac-screening-arrow">
              <ArrowForwardIcon stroke={palette.neutral[70]} />
            </OfacArrowBtn>
          </CustomToolTip>
        </Box>
        <Stack spacing="2px">
          <Stack direction="row" spacing="8px">
            <TruncateText
              lineClamp={1}
              color={palette.neutral[80]}
              fontWeight="book"
              fontSize="14px"
              maxWidth="77%"
            >
              {name || "Latest check"}
            </TruncateText>
            {ownershipPercentage !== undefined && (
              <Text color={palette.neutral[70]} fontSize="14px">
                Ownership {ownershipPercentage}%
              </Text>
            )}
          </Stack>
          <Text color={palette.neutral[70]} fontWeight="book">
            {lastCheck?.lastCheckAt &&
              formatTimestampWithTimezone(lastCheck.lastCheckAt)}
          </Text>
        </Stack>
      </LatestCheck>
      {showDivider && <Divider sx={{ m: "0px 12px", opacity: 0.6 }} />}
    </Box>
  );
}

const LatestCheck = styled(Box, {
  shouldForwardProp: (prop) => prop !== "hideArrow" && prop !== "isOwner",
})<BoxProps & { isOwner: boolean; hideArrow: boolean }>(
  ({ isOwner, hideArrow }) => ({
    display: "flex",
    padding: "16px 12px",
    flexDirection: "column",
    minHeight: "50px",
    gap: "8px",
    borderRadius: "8px",
    ...(!hideArrow && {
      cursor: "pointer",
      "& .ofac-screening-arrow": {
        visibility: "visible",
      },
    }),

    ...(!isOwner && {
      background: palette.common.white,
    }),
  }),
);

const OfacArrowBtn = styled("button")(() => ({
  all: "unset",
  height: "32px",
  width: "32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  visibility: "hidden",
}));
