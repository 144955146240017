import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import ChipsContainer from "@common/TableFilters/WrapperWithDateRange";
import Chip from "@common/TableFilters/Chip";
import { Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { findByIdAndModify, getAmountArray } from "@utils/helpers";
import { isArray, isEmpty, isEqual } from "lodash";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { FilterObjectTypes } from "@customTypes/utils.types";
import CustomVolumePopup from "@common/TableFilters/CustomVolumePopup";
import {
  getCustomProcessingLabel,
  getProcessingAmount,
} from "@common/TableFilters/utils";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  resetFilterByKey,
  selectFiltersObject,
  setFilterByKey,
} from "@redux/slices/dynamicFilterSlice";
import { QKEY_MANAGE_MONEY_TRANSACTIONS_LIST } from "@constants/queryKeys";
import { DefaultValuesTypeManageMoney } from "@pages/ManageMoney/constants";
import {
  defaultValuesManageMoney,
  QUERYOBJECTKEYS_MANAGE_MONEY,
} from "../constants";
import { toUnixDateFormat } from "@utils/date.helpers";

export default function useManageMoneyFilter() {
  const { open, onClose, SlideProps } = useNiceModal();
  const dispatch = useAppDispatch();
  const filterObject = useAppSelector((state) =>
    selectFiltersObject(state, QKEY_MANAGE_MONEY_TRANSACTIONS_LIST),
  );
  const methods = useForm<DefaultValuesTypeManageMoney>({
    defaultValues: isEmpty(filterObject)
      ? defaultValuesManageMoney
      : filterObject,
  });

  const values = methods.watch();
  const isObjectsEqual = isEqual(
    values,
    isEmpty(filterObject) ? defaultValuesManageMoney : filterObject,
  );
  const canClearObj = isEqual(values, defaultValuesManageMoney);

  const onSubmit = (data: any) => {
    if (data.transactionDate.label === "Custom") {
      const { dateName, startDate, endDate } = data.transactionDate;
      const startDateUnix = toUnixDateFormat(startDate);
      const endDateUnix = toUnixDateFormat(endDate);
      data.transactionDate.val = `${dateName}:>=d${startDateUnix})%3B(${dateName}:<=d${endDateUnix}`;
    }

    dispatch(
      setFilterByKey({
        filterKey: QKEY_MANAGE_MONEY_TRANSACTIONS_LIST,
        params: data,
      }),
    );
    onClose();
  };

  const actions = {
    primaryAction: {
      label: "Apply filters",
      disabled: isObjectsEqual,
      sx: {
        fontSize: "18px",
        lineHeight: "18px",
        padding: "12px 24px",
      },
    },
    secondaryAction: {
      label: "Clear all filters",
      onClick: () => {
        methods.reset(defaultValuesManageMoney);
        dispatch(
          resetFilterByKey({
            filterKey: QKEY_MANAGE_MONEY_TRANSACTIONS_LIST,
          }),
        );
      },
      sx: {
        fontSize: "18px",
        lineHeight: "18px",
        padding: "12px 24px",
        color: palette.filled.red,
      },
      hidden: canClearObj,
    },
  };
  const handleSaveProcessingVolume = (param: {
    from: number | undefined;
    to: number | undefined;
    key: string;
    prefix?: string;
  }) => {
    const stringValue = getProcessingAmount({
      ...(typeof param.from === "number" && { amount: param.from * 100 }),
      ...(typeof param.to === "number" && { secondAmount: param.to * 100 }),
      modifier: "is between",
      prefix: param?.prefix,
    });

    const customLabel = getCustomProcessingLabel(param?.from, param?.to);
    methods.setValue(param?.key as any, {
      label: customLabel,
      value: stringValue,
    });
  };
  const renderFlowAmountChip = ({
    key,
  }: {
    key:
      | QUERYOBJECTKEYS_MANAGE_MONEY.inflow_amount
      | QUERYOBJECTKEYS_MANAGE_MONEY.outflow_amount;
  }) => {
    const prefix =
      key === QUERYOBJECTKEYS_MANAGE_MONEY.inflow_amount ? "inflow" : "outflow";
    return (
      <Wrapper>
        {getAmountArray(prefix)?.map((option) => {
          const selectedValue = values?.[key];
          const isCustom =
            (selectedValue?.label?.toLowerCase().includes("custom") &&
              option?.label === "Custom") ||
            false;
          const isSelected = selectedValue?.label === option?.label || isCustom;
          return (
            <Chip
              key={option.value}
              item={
                isCustom
                  ? {
                      label: selectedValue?.label || "Custom",
                      value: option.value,
                    }
                  : option
              }
              selected={isSelected}
              onSelect={() => {
                methods.setValue(key, isSelected ? {} : option);
              }}
              {...(option.value === "custom" && {
                PopupComponent: CustomVolumePopup,
                popupCallback: (e) =>
                  handleSaveProcessingVolume({
                    ...e,
                    key: key,
                    prefix,
                  }),
              })}
            />
          );
        })}
      </Wrapper>
    );
  };
  const renderChipsArray = ({
    arr,
    key,
  }: {
    arr: any[];
    key: QUERYOBJECTKEYS_MANAGE_MONEY;
  }) => {
    return (
      <Wrapper>
        {arr?.map((option) => {
          const selectedArray = values?.[key] || [];
          if (!isArray(selectedArray)) return;
          const isSelected = selectedArray?.find(
            (item: any) => item.value === option.value,
          );

          return (
            <Chip
              key={option.value}
              item={option}
              selected={!!isSelected}
              allowMultiSelect
              onSelect={() => {
                const newArray = findByIdAndModify(
                  selectedArray as FilterObjectTypes[],
                  option,
                );
                methods.setValue(key, newArray, {
                  shouldDirty: true,
                });
              }}
            />
          );
        })}
      </Wrapper>
    );
  };
  const filterSections = useMemo(() => {
    const transactionDate = {
      title: "Transaction Date",
      description:
        "See transactions created within a specific date range. Click on the transaction to see details.",
      children: (
        <Wrapper>
          <ChipsContainer
            dateName={QUERYOBJECTKEYS_MANAGE_MONEY.createdAt}
            selectedDate={values?.transactionDate}
            rangeSaveCallback={(val) =>
              methods.setValue(
                QUERYOBJECTKEYS_MANAGE_MONEY.transactionDate,
                val,
                {
                  shouldDirty: true,
                },
              )
            }
          />
        </Wrapper>
      ),
    };
    const tStatus = {
      title: "Transaction Status",
      description: "",
      children: renderChipsArray({
        arr: transactionStatusStaticArr,
        key: QUERYOBJECTKEYS_MANAGE_MONEY.transaction_status,
      }),
    };
    const inflowObj = {
      title: "Inflow Amount",
      description:
        "View transactions based on the amount of money received within specific range",
      children: renderFlowAmountChip({
        key: QUERYOBJECTKEYS_MANAGE_MONEY.inflow_amount,
      }),
    };

    const outflowOBJ = {
      title: "Outflow Amount",
      description:
        "View transactions based on the amount of money withdrawn within specific range",
      children: renderFlowAmountChip({
        key: QUERYOBJECTKEYS_MANAGE_MONEY.outflow_amount,
      }),
    };
    const paymentMethodObj = {
      title: "Payment Method",
      description: "",
      children: (
        <Wrapper>
          {paymentMethodsStaticArray?.map((option) => {
            const selectedValue =
              values?.[QUERYOBJECTKEYS_MANAGE_MONEY.payment_method];
            const selected = selectedValue?.value === option?.value;
            return (
              <Chip
                key={option.value}
                item={option}
                selected={selectedValue?.value === option?.value}
                onSelect={(val) => {
                  methods.setValue(
                    QUERYOBJECTKEYS_MANAGE_MONEY.payment_method,
                    selected ? {} : option,
                  );
                }}
              />
            );
          })}
        </Wrapper>
      ),
    };
    return [transactionDate, tStatus, inflowObj, outflowOBJ, paymentMethodObj];
  }, [values]);
  return {
    open,
    onClose,

    SlideProps,
    methods,
    onSubmit,
    actions,
    filterSections,
  };
}
const Wrapper = styled(Stack)(() => ({
  display: "flex",
  flexDirection: "row",
  spacing: "8px",
  flexWrap: "wrap",
  gap: "8px",
}));

const transactionStatusStaticArr = [
  {
    value: "Authorized",
    label: "Authorized ",
  },
  {
    value: "Chargeback",
    label: "Chargeback",
  },
  {
    value: "Chargeback Reversal",
    label: "Chargeback Reversal",
  },
  {
    value: "Created",
    label: "Requested",
  },
  {
    value: "Pending",
    label: "Pending",
  },
  {
    value: "Refund Pending",
    label: "Refund Pending",
  },
  {
    value: "Refunded",
    label: "Refunded",
  },
  {
    value: "Sending",
    label: "Sending",
  },
  {
    value: "Sent",
    label: "Sent",
  },
  {
    value: "Settled",
    label: "Settled",
  },
  {
    value: "Voided",
    label: "Voided",
  },
];
const paymentMethodsStaticArray = [
  {
    value: "Credit",
    label: "Credit Card",
  },
  {
    value: "Debit",
    label: "Debit Card",
  },
];
