import BaseCardComponent from "../BaseCardComponent";
import useSnapShot from "features/Merchants/MerchantSidePanel/hooks/useSnapShot";

function TractionFees() {
  const { transactionFees } = useSnapShot();

  return (
    <BaseCardComponent leftTitle="Transaction Fees" payload={transactionFees} />
  );
}

export default TractionFees;
