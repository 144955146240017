import { Box, Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme } from "@theme/v2/Provider";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import ImagesStacker from "features/Minibuilders/Conversations/utils/ImagesStacker";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import ChallengeLayout from "./ChallengeLayout";
import { TChallengeCategory } from "./types";
import { useGetChallenges } from "./useChallengesRepository";
import { iconMapper } from "./utils";
import { ChatsCircle } from "@phosphor-icons/react";
import GiveIconButton from "@shared/IconButton/GiveIconButton";

const ChallengesCategories = () => {
  const { setDoublePanel, setOpenChallenge } = useMerchantSidePanelContext();
  const { data, isLoading } = useGetChallenges();
  const { palette } = useAppTheme();
  const { openConversationHandler } = useConversationsModal();

  if (isLoading) return <></>;

  return (
    <Stack gap={3}>
      <GiveText
        fontSize="14px"
        sx={{
          color: palette.primitive?.neutral[60],
        }}
      >
        Challenge Categories
      </GiveText>
      <Stack gap={3}>
        {data.challengesCategoriesData?.data.map(
          (challenge: TChallengeCategory, id) => {
            const status =
              challenge.totalClosed !== challenge.totalChallenges
                ? "In Progress"
                : "Approved";

            return (
              <ChallengeLayout
                onClick={() => {
                  setOpenChallenge({
                    identifier: challenge.name,
                    displayName: challenge.displayName,
                  });
                  setDoublePanel("challenges");
                }}
                icon={<>{iconMapper(challenge.name)}</>}
                attachConversations={
                  <Stack direction="row" alignItems="center">
                    <ImagesStacker data={[]} />
                    <GiveIconButton
                      Icon={ChatsCircle}
                      variant="ghost"
                      onClick={(e) => {
                        e.stopPropagation();
                        openConversationHandler({
                          id: 0,
                          name: challenge.displayName,
                          paths: [],
                        });
                      }}
                    />
                  </Stack>
                }
                main={
                  <Stack>
                    <GiveText
                      fontSize="14px"
                      sx={{
                        color: palette.primitive?.neutral[90],
                      }}
                    >
                      {challenge.displayName}
                    </GiveText>
                    <GiveText
                      fontSize="14px"
                      sx={{
                        color: palette.primitive?.neutral[60],
                      }}
                    >
                      {challenge.totalClosed + "/" + challenge.totalChallenges}{" "}
                      completed
                    </GiveText>
                  </Stack>
                }
                end={
                  <Box
                    sx={{
                      backgroundColor:
                        status === "Approved"
                          ? palette.primitive?.success[25]
                          : palette.primitive?.neutral[10],
                      padding: "4px 8px",
                      borderRadius: "8px",
                    }}
                  >
                    <GiveText fontSize="12px">{status}</GiveText>
                  </Box>
                }
                key={id}
              />
            );
          },
        )}
      </Stack>
    </Stack>
  );
};

export default ChallengesCategories;
