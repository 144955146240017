import { Box, Stack } from "@mui/material";
import { Plus } from "@phosphor-icons/react";
import GiveText from "@shared/Text/GiveText";
import { styled } from "@theme/v2/Provider";
import { useState } from "react";
import ImageModal from "./ImageModal";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";

function ImageSection() {
  const { methods } = usePayBuilderForm();
  const selectedImage = methods.watch("About.selectedImage");
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const onClose = () => setIsImageModalOpen(false);
  return (
    <>
      <Box>
        <GiveText fontSize="14px" fontWeight={400} color="primary">
          Image (Optional)
        </GiveText>

        <Container
          isImageSelected={Boolean(selectedImage.URL)}
          onClick={() => setIsImageModalOpen(true)}
        >
          {selectedImage.URL ? (
            <Box
              height="100%"
              width="100%"
              component="img"
              src={selectedImage.URL + "/original"}
              sx={{
                objectFit: "cover",
              }}
            />
          ) : (
            <Plus fill="#000000" size="32px" />
          )}
        </Container>
      </Box>
      <ImageModal open={isImageModalOpen} onClose={onClose} />
    </>
  );
}

export default ImageSection;

const Container = styled(Stack)<{ isImageSelected: boolean }>(
  ({ theme, isImageSelected }) => {
    return {
      width: "240px",
      height: "135px",
      backgroundColor: theme.palette.primitive?.transparent["darken-5"],
      marginTop: "12px",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "8px",
      ...(!isImageSelected && {
        border: `1px dashed ${theme.palette.border?.tertiary}`,
      }),

      cursor: "pointer",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    };
  },
);
