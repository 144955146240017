import { pdf } from "@react-pdf/renderer";
import { IParsedData } from "@components/Merchants/MerchantPreview/data.types";
import React from "react";
import { Stack } from "@mui/material";
import { ArrowLineDown } from "@phosphor-icons/react";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme, styled } from "@theme/v2/Provider";
import MerchantAgreementPDF from "@components/Merchants/CreateMerchantPanel/modals/components/MerchantAgreement/pdf";
import { saveAs } from "file-saver";
import { showMessage } from "@common/Toast";

interface IComponentProps {
  snapshotData: any;
  isSnapshotLoading: boolean;
}

const PDFLink = ({ snapshotData, isSnapshotLoading }: IComponentProps) => {
  const { palette } = useAppTheme();

  const showErrorToast = () => {
    showMessage("Error", "An Error has occurred, please Try again later");
  };

  const handleDownloadPdf = async () => {
    if (isSnapshotLoading) return;
    try {
      const blob = await pdf(
        <MerchantAgreementPDF data={snapshotData as IParsedData} />,
      ).toBlob();

      if (blob) saveAs(blob, "merchant_agreement.pdf");
      else showErrorToast();
    } catch (e) {
      showErrorToast();
    }
  };

  return (
    <StyledStack
      isSnapshotLoading={isSnapshotLoading}
      onClick={handleDownloadPdf}
    >
      <ArrowLineDown fill={palette.primitive?.blue[100]} size={25} />
      <GiveText color="link">Download Merchant Application</GiveText>
    </StyledStack>
  );
};

const StyledStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "isSnapshotLoading",
})<{ isSnapshotLoading: boolean }>(({ isSnapshotLoading }) => ({
  flexDirection: "row",
  alignItems: "center",
  opacity: isSnapshotLoading ? 0.5 : 1,
  gap: "8px",
  cursor: isSnapshotLoading ? "default" : "pointer",
}));

export default PDFLink;
