import * as React from "react";
import Box from "@mui/material/Box";
import { useFormContext, Controller } from "react-hook-form";
import GiveSwitch, { SwitchProps } from "../../Switch/GiveSwitch";

export type HFGiveSwitchProps = SwitchProps & {
  label?: React.ReactNode;
  name: string;
};

export default function HFGiveSwitch({ name, ...props }: HFGiveSwitchProps) {
  const { control } = useFormContext();

  return (
    <Box
      sx={{
        verticalAlign: "top",
      }}
    >
      <Controller
        name={name}
        control={control}
        render={({ field: { ref, value, ...rest } }) => (
          <>
            <GiveSwitch
              inputRef={ref}
              {...rest}
              checked={Boolean(value)}
              {...props}
            />
          </>
        )}
      />
    </Box>
  );
}
