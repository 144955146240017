import { customInstance } from "@services/api";
import { useQuery } from "react-query";
import { ExtendedTChallengeStatus } from "../manageUnderwriting/Challenges/ChallengeLayout";
import { TChallengeTypeName } from "../manageUnderwriting/ChallengeTypeChip";

export type TChallenge = {
  id: number;
  merchantAccId: number;
  challengeID: number;
  statusName: string;
  displayName: string;
  categoryUIName: string;
  slug: string;
  name: string;
  points: number;
  canNotifyMerchant: boolean;
  openAt: number;
  openBy: number;
  readyAt: number | null;
  readyBy: number;
  closedAt: number | null;
  needsVerification: boolean;
  closedBy: number;
  createdAt: number;
  updatedAt: number;
  ScorePercentage: number;
  includedFields: Array<string> | null;
  threadID: number;
  totalUsersCommentedInThread: number;
  merchantDisplayTitle: string;
  enterpriseDisplayTitle: string;
  userAvatarsInThread: string[];
  hasUnreadMessages: boolean;
  challengeTypeName: TChallengeTypeName;
  didMerchantReply?: boolean;
  notifyByFirstName?: string;
  notifyByLastName?: string;
  notifyByImageUrl?: string;
  notifiedMessage?: string;
  merchantDisplayName: string;
  enterpriseDisplayName: string;
};

const filterMapper = {
  open: 1,
  ready_for_verification: 2,
  closed: 3,
  create: 0,
};

type TQChallengeParams = {
  merchantId: number;
  challengeFilter?: ExtendedTChallengeStatus;
  isEnterprise?: boolean;
};

// {{URL}}/merchants/:MERCH_ID/underwriting-challenges
// isForMerchant && isForEnterprise
const getChallenges = ({
  merchantId,
  challengeFilter,
  isEnterprise,
}: TQChallengeParams) => {
  const filter = isEnterprise
    ? `isForEnterpise:${isEnterprise}`
    : `isForMerchant:${!isEnterprise}`;

  // If user clicked on filter dropdown
  const didUserFilter = challengeFilter && challengeFilter !== "all";
  const url = `/merchants/${merchantId}/underwriting-challenges`;
  const basicFilters = `filter=${filter}`;
  const customFilters = didUserFilter
    ? `filter=statusName:${filterMapper[challengeFilter]}`
    : basicFilters;

  const sortParam = "&sort=-id";
  const finalQueryString = `?${customFilters}${didUserFilter ? sortParam : ""}`;

  return customInstance({
    url: url + finalQueryString,
    method: "GET",
  });
};

export const useListChallenges = ({
  merchantId,
  challengeFilter,
  isEnterprise,
}: TQChallengeParams) => {
  const { data, isLoading } = useQuery(
    ["get-challenges", merchantId, challengeFilter],
    async () => {
      const data = await getChallenges({
        merchantId,
        challengeFilter,
        isEnterprise,
      });
      return data;
    },

    { retry: false },
  );

  return {
    data: data,
    isLoading,
  };
};
