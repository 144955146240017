import { useGetCurrentMerchantId } from "@hooks/common";
import { useCachedList } from "@hooks/common/useCachedList";
import { ROWS_PER_PAGE, usePagination } from "@hooks/common/usePagination";
import { useAppSelector } from "@redux/hooks";
import { sorting as sortingReducer } from "@redux/slices/fundraisers";
import { selectQueryString } from "@redux/slices/search";
import {
  selectQueryFilters,
  selectTableFilter,
} from "@redux/slices/transactions";
import { customInstance } from "@services/api";
import { ProductParams } from "@services/api/products/queryFactory";
import { setCheckedTransaction } from "@services/api/products/transactions";
import {
  addFiltersToUrl,
  buildMerchantEndpoints,
} from "@services/api/utils.api";
import { encodedQueryFilterMap } from "@services/filtering";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { toUnixDateFormat } from "@utils/date.helpers";
import { useUpdateCachedQuery } from "@utils/react.query";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { UseQueryOptions, UseQueryResult } from "react-query";
import { useSelector } from "react-redux";
import {
  TranTableHiddenColumnUnderExtraLargView,
  TransactionTableHiddenColumnMediumView,
  TransactionTableHiddenColumnSmallMediumView,
} from "../constants";
import { TransactionTableRow } from "./TransactionTable";
import {
  sourceAccountParser,
  sourceMethodParser,
} from "./transactions.helpers";
import { ParsedTransaction, Transaction } from "./transactions.types";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { useFilters } from "@components/VirtualList/hooks";

interface GenericIdentityFn<T> {
  (
    params: T,
    options?: Omit<UseQueryOptions<any, any, any, any>, "queryKey" | "queryFn">,
  ): UseQueryResult<any, any>;
}

export const useTransactionsIOC = <T extends ProductParams>(
  queryKey: string,
  queryGet: GenericIdentityFn<T>,
  queryString?: string,
  options?: any,
  searchQuery?: string,
) => {
  const { isMobileView } = useCustomTheme();
  const queryFilters = useAppSelector(selectQueryFilters);
  const filters = useAppSelector(selectTableFilter);
  const formattedFilter = useFilters(queryKey);
  const sorting = useAppSelector(sortingReducer);

  // we should have a default state for the search query in a generic
  // state not in the funraisers state
  const _searchQuery = useAppSelector((state) =>
    selectQueryString(state, queryKey),
  );
  const loadingRef = useRef<boolean>(false);

  const { allData, invalidateCache } =
    useCachedList<ParsedTransaction>(queryKey);

  const queryFilter = useMemo(
    () => encodedQueryFilterMap(queryFilters),
    [queryFilters],
  );

  const _queryString = queryFilter.transactions || "";

  const { page, setPage } = usePagination(0, "");
  const { merchantId } = useGetCurrentMerchantId();

  const parseData = useCallback(
    (data: any) => parseTransactionData(data, options?.isPurchase),
    [],
  );

  const {
    data: thx,
    isError,
    isLoading,
    isFetching,
  } = queryGet(
    {
      page,
      sorting,
      queryString: _queryString,
      searchQuery: searchQuery ?? _searchQuery,
      filter: options?.filterParams || formattedFilter,
    } as T,
    {
      refetchOnWindowFocus: false,
      select: parseData,
      enabled: Boolean(merchantId),
      onSuccess(_data) {
        setTimeout(() => {
          loadingRef.current = false;
        }, 700);
      },
    },
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  useEffect(() => {
    if (isMobileView) invalidateCache();
    setPage(1);
  }, [
    sorting,
    searchQuery,
    queryString,
    _searchQuery,
    isMobileView,
    filters,
    options?.filterParams,
  ]);

  const usedData = isMobileView
    ? parseData({ data: allData, total: thx?.total ?? 0 }).data
    : thx?.data ?? [];

  return {
    isLoading: isLoading,
    isError,
    page,
    rowsPerPage: ROWS_PER_PAGE,
    currentPageRows: usedData,
    handlePageChange,
    totalRows: thx?.total ?? 0,
    setPage: () => setPage((current) => current + 1),
    allRows: usedData,
    loadingRef,
    invalidateCache,
    isFetching,
    setPageDispatcher: setPage,
  };
};

export const parseTransactionData = (data: any, isPurchase?: boolean) => {
  if (isPurchase) {
    return {
      sourceAccount: { user: {} },
      sourceMethod: { method: {} },
      ...data,
    };
  }

  if (!data.data || !data.data.length) {
    return {
      data: [],
      total: 0,
    };
  }
  const parsedData = data.data.filter(Boolean).map((item: Transaction) => {
    const parsedSourceAccount = sourceAccountParser(item);
    const ParsedSourceMethod = sourceMethodParser(item);

    const hideRefund =
      Boolean(item?.reversalState) ||
      ["Refunded", "Voided"].includes(item?.displayStatus);

    return {
      ...item,
      hideRefund,
      sourceAccount: {
        id: item?.sourceAccount?.id,
        type: item?.sourceAccount?.type,
        user: parsedSourceAccount,
      },
      sourceMethod: {
        id: item?.sourceMethod?.id,
        kind: item?.sourceMethod?.kind,
        type: item?.sourceMethod?.type,
        method: ParsedSourceMethod,
      },
    };
  });

  return {
    data: parsedData,
    total: data?.total,
  };
};

export const useDownloadReport = ({
  filterParams,
}: {
  filterParams?: string;
} = {}) => {
  const { merchantId } = useGetCurrentMerchantId();
  const [disableDownload, setDisableDownload] = useState(false);
  const isFirstTime = useRef(false);
  const timer: MutableRefObject<NodeJS.Timeout | null> = useRef(null);

  useEffect(() => {
    return () => clearTimeout(timer.current as NodeJS.Timeout);
  });

  const downloadReport = async (customUrl?: string) => {
    if (!merchantId) return;
    setDisableDownload(true);

    const shouldUseCustomUrl = customUrl && typeof customUrl === "string";

    const data = await customInstance({
      url: shouldUseCustomUrl
        ? customUrl
        : addFiltersToUrl(
            buildMerchantEndpoints(`/transactions.csv`),
            filterParams,
          ),
      method: "GET",
    });

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    const href = url;
    link.href = href;
    link.setAttribute("download", `transactions.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    if (!isFirstTime.current) {
      setDisableDownload(false);
    } else {
      timer.current = setTimeout(() => {
        setDisableDownload(false);
      }, 3000);
    }

    isFirstTime.current = true;
  };

  const canExportTransactions = useAccessControl({
    resource: RESOURCE_BASE.TRANSACTION,
    operation: OPERATIONS.EXPORT,
    withPortal: true,
  });

  return {
    disableDownload,
    denyDownload: !canExportTransactions,
    downloadReport,
  };
};

export const useProcessingResponsiveTable = () => {
  const { isMediumView, isMediumSmallView, isExtraLargeView } =
    useCustomTheme();

  const reduceColumnProcessingTable = useCallback(
    ({ key }: { key: string }) => {
      const isColumnHiddenMediumView =
        isMediumView && TransactionTableHiddenColumnMediumView.includes(key);
      const isColumnHiddenSmallMediumView =
        isMediumSmallView &&
        TransactionTableHiddenColumnSmallMediumView.includes(key);
      const isColumnHiddenBelowExtraLargeView =
        !isExtraLargeView &&
        TranTableHiddenColumnUnderExtraLargView.includes(key);
      if (
        isColumnHiddenMediumView ||
        isColumnHiddenSmallMediumView ||
        isColumnHiddenBelowExtraLargeView
      )
        return false;
      return true;
    },
    [isMediumView, isMediumSmallView, isExtraLargeView],
  );

  return {
    reduceColumnProcessingTable,
  };
};

export const useManageMoneyResponsiveTable = () => {
  const { isMediumView, isMediumSmallView } = useCustomTheme();

  const reduceColumnManageMoneyTable = useCallback(
    ({ key }: { key: string }) => {
      const isColumnHiddenMediumView =
        isMediumView && TransactionTableHiddenColumnMediumView.includes(key);
      const isColumnHiddenSmallMediumView =
        isMediumSmallView &&
        TransactionTableHiddenColumnSmallMediumView.includes(key);

      if (isColumnHiddenMediumView || isColumnHiddenSmallMediumView)
        return false;
      return true;
    },
    [isMediumSmallView, isMediumView],
  );

  return {
    reduceColumnManageMoneyTable,
  };
};

export const useUpdateTransactionReadStatus = (queryKey: string) => {
  const isEnabledVl = useSelector<any, boolean>(
    (state: any) => state.app.enabledVL,
  );
  const { updateDataWithExceptions } = useUpdateCachedQuery(queryKey);

  const updateReadStatus = (rowData: TransactionTableRow) => {
    const transactionIsRead = rowData.firstCheckedAt !== null;
    if (transactionIsRead) return;

    const isChargeback = rowData.status === "Chargeback";
    const isRefund = rowData.status === "Refunded";
    const transactionId = rowData.id;
    const merchantId =
      !isChargeback && !isRefund
        ? rowData.destinationAccount.id
        : rowData.sourceAccount.id;

    const updateCacheHandler = (oldData: any) => {
      const unixDateNow = toUnixDateFormat(new Date());
      if (isEnabledVl) {
        if (!oldData?.pages || !Array.isArray(oldData.pages)) return oldData;

        const newData = {
          ...oldData,
          pages: oldData?.pages?.map((page: { data: any }) => {
            return {
              ...page,
              data: page?.data?.map((thx: any) => {
                if (thx?.id === transactionId) {
                  return {
                    ...thx,
                    firstCheckedAt: unixDateNow,
                  };
                }
                return thx;
              }),
            };
          }),
        };
        return newData;
      } else {
        if (!oldData?.data || !Array.isArray(oldData.data)) return oldData;

        const data = oldData.data.map((thx: any) => {
          if (thx?.id === transactionId) {
            return {
              ...thx,
              firstCheckedAt: unixDateNow,
            };
          }
          return thx;
        });

        return {
          total: data.length,
          data,
        };
      }
    };

    updateDataWithExceptions(updateCacheHandler, () =>
      setCheckedTransaction(merchantId, transactionId),
    );
  };

  return { updateReadStatus };
};
