import EmailIcon from "@assets/icons/Share/EmailIcon";
import EmbedIcon from "@assets/icons/Share/EmbedIcon";
import FacebookIcon from "@assets/icons/Share/FacebookIcon";
import LinkedinIcon from "@assets/icons/Share/LinkedinIcon";
import ThreadsIcon from "@assets/icons/Share/ThreadsIcon";
import TwitterXIcon from "@assets/icons/Share/TwitterXIcon";
import WhatsappIcon from "@assets/icons/Share/WhatsappIcon";
import { useMemo } from "react";
import {
  EmailShareButton, FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import { EmbedShareButton, ThreadsShareButton } from "./AdditionalButtons";
import LargeVariant from "./LargeVariant";
import type { PublicUrlShareProps, TButtonsMap } from "./PublicShare.types";
import StrictVariant from "./StrictVariant";

const PublicUrlShare = <T extends "large" | "strict">({
  variant,
  anchorEl,
  setAnchorEl,
}: PublicUrlShareProps<T>) => {
  const url = "https://givepayments.com/8uBezlFfYy0"; // get it from props or context
  const ButtonsMap = useMemo(
    () => [
      {
        Component: EmbedShareButton,
        Icon: EmbedIcon,
        id: "embed",
        hidden: variant === "strict",
      },
      {
        Component: EmailShareButton,
        Icon: EmailIcon,
        id: "email",
        hidden: false,
      },
      {
        Component: FacebookShareButton,
        Icon: FacebookIcon,
        id: "facebook",
        hidden: false,
      },
      {
        Component: LinkedinShareButton,
        Icon: LinkedinIcon,
        id: "linkedin",
        hidden: false,
      },
      {
        Component: TwitterShareButton,
        Icon: TwitterXIcon,
        id: "x",
        hidden: false,
      },
      {
        Component: WhatsappShareButton,
        Icon: WhatsappIcon,
        id: "whatsapp",
        hidden: false,
      },
      {
        Component: ThreadsShareButton,
        Icon: ThreadsIcon,
        id: "threads",
        hidden: false,
      },
    ],
    [],
  );

  return (
    <>
      {variant === "large" ? (
        <LargeVariant ButtonsMap={ButtonsMap as TButtonsMap} url={url} />
      ) : (
        <StrictVariant
          ButtonsMap={ButtonsMap as TButtonsMap}
          url={url}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      )}
    </>
  );
};

export default PublicUrlShare;
