import { showMessage } from "@common/Toast";
import { challengeSlugs } from "@constants/challengeSlugs";
import {
  QKEY_LIST_ACQUIRER_MERCHANTS,
  QKEY_LIST_ENTERPRISE_MERCHANTS,
  QKEY_LIST_ENTERPRISE_STATS,
  QKEY_LIST_MERCHANTS,
  QKEY_LIST_MERCHANT_STATS,
} from "@constants/queryKeys";
import { useGetUploadFilePermission } from "@hooks/merchant-api/manage-money/useUploadBankAccountDocument";
import { getMerchantErrorParamName } from "@hooks/onboarding/useSignup";
import { selectUser } from "@redux/slices/auth/auth";
import { customInstance } from "@services/api";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { convertPhoneNumber, toUnixDateFormat } from "@utils/date.helpers";
import { encodeString, formatVariantValue } from "@utils/index";
import { refineLEOwnership } from "@utils/validation.helpers";
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { QKEY_GET_MERCHANT_BY_ID } from "@constants/queryKeys";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { checkPortals } from "@utils/routing";

type SubmitType =
  | "merchant_agreement"
  | "merchant_info"
  | "business_profile"
  | "business_address"
  | "primary_account_holder"
  | "fees"
  | "invitation"
  | "avatar"
  | "categories";

export const useUpdateMerchantInfo = (
  merchantId: number,
  legalEntityID?: number,
  isEdit?: boolean,
) => {
  const queryClient = useQueryClient();
  const { isDesktopView } = useCustomTheme();
  const isUploadAllowed = useGetUploadFilePermission();
  const { isAcquirerEnterprises } = checkPortals();

  const isEditInfoAllowed = useAccessControl({
    resource: isAcquirerEnterprises
      ? RESOURCE_BASE.ENTERPRISE
      : RESOURCE_BASE.MERCHANT,
    operation: OPERATIONS.UPDATE,
  });

  const updateMerchantInfoMutation = useMutation((data: any) => {
    return customInstance({
      url: `/merchants/${merchantId}`,
      method: "PATCH",
      data,
    });
  });

  const auth = useSelector(selectUser);
  const legalEntityMutation = useMutation((data: any) => {
    return customInstance({
      url: legalEntityID
        ? `/merchants/${merchantId}/legal-entities/${legalEntityID}`
        : `/merchants/${merchantId}/legal-entities`,
      method: legalEntityID ? "PATCH" : "POST",
      data,
    });
  });

  const { isLoading, isSuccess } = updateMerchantInfoMutation;

  const handleSubmit = async (
    type: SubmitType,
    data: any,
    onSuccess?: () => void,
    ownerAccId?: number,
  ) => {
    const shouldInvite = data.invite;
    const cachedData: any = queryClient.getQueryData([
      "get-merchant-preview",
      merchantId,
    ]);
    const memberStatus: string =
      cachedData?.merchant?.ownerMembership?.memberStatus || "";
    const isInviteOwner = memberStatus !== "joined";

    let customData = getCustomData(type, data, auth.email, isInviteOwner);
    const filesToUpload = [...(data?.files || [])];

    if (!customData) return;

    let error = null;
    if (
      ["business_profile", "business_address"].includes(type) &&
      !customData.legalEntityID
    ) {
      try {
        const data = await legalEntityMutation.mutateAsync(customData);
        queryClient.setQueriesData(
          ["get-merchant-preview", merchantId],
          (oldData: any) => {
            return {
              ...oldData,
              legalEntity: { ...oldData.legalEntity, ...data },
            };
          },
        );
        if (onSuccess && type === "business_profile" && !customData.address) {
          onSuccess();
        }

        queryClient.invalidateQueries("get-merchant-preview");

        queryClient.invalidateQueries("get-merchant-msp-status");
      } catch (err: any) {
        error = err?.response?.data?.input;
        if (error) showMessage("Error", error[0].message);
        return;
      }
    }

    if (
      type === "business_profile" &&
      customData.address &&
      !customData.legalEntityID
    ) {
      customData = { address: customData.address };
    } else if (
      type === "business_profile" &&
      !customData.address &&
      legalEntityID
    ) {
      return;
    }

    if (type === "merchant_info" && !isEditInfoAllowed) {
      if (onSuccess) onSuccess();
      return;
    }

    updateMerchantInfoMutation.mutate(customData, {
      onSuccess: async (data: any) => {
        queryClient.setQueriesData(
          ["list-acquirer-merchants"],
          (oldData: any) => {
            if (!oldData || !oldData.data) return oldData;

            const index = oldData.data.findIndex(
              (item: any) => item?.accID === data?.accID,
            );
            if (index === -1) return oldData;

            const newData = [...(oldData.data || [])];
            newData[index] = data;

            return { ...oldData, data: newData };
          },
        );

        queryClient.invalidateQueries([QKEY_GET_MERCHANT_BY_ID, merchantId]);
        if (type === "primary_account_holder") {
          if (shouldInvite && !isEdit) {
            customInstance({
              url: `/merchants/${merchantId}`,
              method: "PATCH",
              data: { inviteOwner: true },
            });
          }
          if (filesToUpload && filesToUpload.length > 0 && isUploadAllowed) {
            const customDocumentList = filesToUpload.map((item: any) => ({
              attachmentType: "account_owner",
              resourceId: ownerAccId || data?.owner?.accID,
              fileName: item.name,
              tag: "edit Primary Account Holder upload",
              label: "",
            }));
            await uploadDocuments(
              merchantId,
              customDocumentList,
              filesToUpload,
              undefined,
              challengeSlugs.PRIMARY_5,
            );
          }
        }
        queryClient.setQueriesData(
          ["get-merchant-preview", merchantId],
          (oldData: any) => {
            if (type === "business_profile" && !oldData.merchant?.legalEntity)
              return oldData;
            return {
              ...oldData,
              merchant: {
                ...data,
                ownerIDProofUploaded: filesToUpload.length !== 0,
              },
            };
          },
        );
        if (type === "business_profile" && customData.legalEntityID) {
          queryClient.refetchQueries(["get-merchant-preview", merchantId]);
        }
        if (["merchant_info", "avatar"].includes(type)) {
          queryClient.refetchQueries(QKEY_LIST_MERCHANTS);
          queryClient.refetchQueries(QKEY_LIST_ENTERPRISE_MERCHANTS);
          queryClient.refetchQueries(QKEY_LIST_ACQUIRER_MERCHANTS);
          queryClient.refetchQueries(QKEY_LIST_ENTERPRISE_STATS);
          queryClient.refetchQueries([QKEY_LIST_MERCHANT_STATS, merchantId]);
        }
        if (type === "merchant_agreement") {
          queryClient.refetchQueries([
            "find-merchant-business-details",
            merchantId,
          ]);
        }

        merchantRiskQueries.forEach((query) => {
          queryClient.refetchQueries([query, data?.riskProfileID, merchantId]);
        });

        if (onSuccess) onSuccess();
        updateMerchantInfoMutation.reset();
      },
      onError: (error: any) => {
        if (error?.response?.data.input) {
          if (
            error?.response?.data.input[0].message ==
            'The provided "owner.email" value is already taken.'
          ) {
            showMessage(
              "Error",
              "This email address is already in use, please try again with a another email",
              isDesktopView,
            );
            return;
          }
          showMessage(
            "Error",
            error?.response?.data.input[0].message ||
              `${getMerchantErrorParamName(
                error?.response?.data.input[0].param,
              )} is not valid`,
            isDesktopView,
          );
          return;
        }
        if (error?.response?.data?.message) {
          showMessage("Error", error?.response?.data?.message, isDesktopView);
        }
      },
    });
  };

  return {
    handleSubmit,
    isLoading: isLoading || legalEntityMutation.isLoading,
    isSuccess,
    queryClient,
  };
};

const getCustomData = (
  type: SubmitType,
  data: any,
  accountEmail?: string,
  isInviteOwner?: boolean,
) => {
  const isEmptyPhone = [null, "", "+", "+1"].includes(data?.phoneNumber);
  switch (type) {
    case "merchant_agreement":
      return {
        ...data,
      };
    case "merchant_info":
      return {
        averageTicketAmount: formatVariantValue(data.averageTicketAmount) || 0,
        highTicketAmount: formatVariantValue(data.highTicketAmount) || 0,
        categoryCodeID: data.category,
        name: data.merchantName,
        slug: data.merchantSlug,
        websiteURL: data.websiteURL ? "https://" + data.websiteURL : "",
        billingDescriptor: data.billingDescriptor,
        servicePhoneNumber: convertPhoneNumber(data.servicePhoneNumber),
        description: encodeString(data.description),
        annualCreditCardSalesVolume:
          formatVariantValue(data.estimatedAnnualRevenue) || 0,
        countriesServicedOutside: data.isOutsideUSA
          ? data.countriesOutside
          : "",
        serviceCountriesOutUSCanada: data.isOutsideUSA,
        ...(data.classification && { class: data.classification }),
        ...(data.classification_description && {
          classDescription: data.classification_description,
        }),
        merchantRiskStatus: data.merchantRiskStatus
          ? data.merchantRiskStatus
          : null,
      };
    case "primary_account_holder":
      return {
        ...((isInviteOwner || data?.inviteOwner) && {
          inviteOwner:
            data.assignToMe ||
            data.invite ||
            accountEmail === data.email ||
            data?.inviteOwner,
        }),

        owner: {
          lastName: data.lastName,
          firstName: data.firstName,
          email: data.assignToMe ? accountEmail : data.email,
          phoneNumber: isEmptyPhone
            ? null
            : convertPhoneNumber(data.phoneNumber),
          dateOfBirth: toUnixDateFormat(new Date(data.dob)),
          citizenship: data?.isNotUSResident ? data?.citizenship : "",
          countryOfResidence: data?.isNotResidentInCitizenshipCountry
            ? data?.countryOfResidence
            : "",
        },
      };
    case "business_profile": {
      const address = {
        line1: data.address?.address,
        line2: "",
        city: data.address?.city,
        state: data.address?.state,
        zip: data.address?.zip,
        country: data.address?.country,
      };

      return {
        ...(data.isLinkBusinessProfile &&
          data.linkedBusinessProfile && {
            legalEntityID: data.linkedBusinessProfile,
          }),
        ...(!data.isLinkBusinessProfile &&
          (data.taxID || data.ssn) && {
            type: data.businessType,
            ownershipType: refineLEOwnership(
              data.businessType,
              data.ownershipType,
            ),
            name: data.legalName,
            doingBusinessAs: data.DBA,
            ...(data.tinType === "ein"
              ? { taxIDNumber: data.taxID }
              : { ssn: data.ssn }),
            phoneNumber: data.contactPhone
              ? convertPhoneNumber(data.contactPhone)
              : null,
            ...(data.businessOpenedAt && {
              businessOpenedAt: toUnixDateFormat(
                new Date(data.businessOpenedAt),
              ),
            }),
          }),
        ...(data.address && {
          address,
        }),
      };
    }

    case "business_address": {
      const address = {
        country: data.country,
        city: data.city,
        line1: data.address,
        state: data.state,
        zip: data.zip,
      };

      return {
        address,
      };
    }

    case "fees":
      return {
        amexCreditCardFees: +data.amex_credit_card_fee,
        debitCardFees: +data.debit_card_fee,
        creditCardFees: +data.credit_card_fee,
      };
    case "invitation":
      return {
        inviteOwner: true,
      };
    case "avatar":
      return {
        imageURL: data.avatar,
      };
    case "categories":
      return {
        allowedCategoryCodes: data.categories,
      };
    default:
      return;
  }
};

// TODO: remove this duplicated function, we already have its hook
export const uploadDocuments = async (
  merchantId: number,
  documents: any,
  files: File[],
  onEnd?: (isError: boolean) => void,
  challengeSlugParam?: string,
) => {
  try {
    const res = await customInstance({
      url: `accounts/${merchantId}/files`,
      method: "POST",
      data: { list: documents },
      params: { challenge_slug: challengeSlugParam },
    });

    if (!res?.total || res?.total < 1) return;

    for (const [index, document] of res.data.entries()) {
      await axios.put(document.fileURL, files[index]);
    }

    const confirmedUploadedList = res.data.map((item: any) => ({
      id: item.id,
      isUploaded: true,
    }));

    await customInstance({
      url: `accounts/${merchantId}/files/confirm`,
      method: "POST",
      data: { list: confirmedUploadedList },
    });
    if (onEnd) onEnd(false);
  } catch (error) {
    showMessage(
      "Warning",
      "An error occurred during the document upload process. Try again.",
    );
    if (onEnd) onEnd(true);
  }
};

const merchantRiskQueries = [
  "get-merchant-risk-profile",
  "risk-activities",
  "active-risk-activities",
];
