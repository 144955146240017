import { Box, Drawer, DrawerProps, Fade } from "@mui/material";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { styled } from "@theme/v2/Provider";
import { getDrawerV2DefaultProps } from "@theme/v2/customStyles/customDrawerStyle";
import { useAppTheme } from "@theme/v2/Provider";

const GiveSideMenu = ({
  anchor,
  open,
  onClose,
  children,
  ...rest
}: DrawerProps) => {
  const { isMobileView, isTabletView } = useCustomThemeV2();
  const theme = useAppTheme();
  const variant = isMobileView ? "temporary" : "permanent";

  return (
    <>
      {isTabletView && (
        <Fade in={open}>
          <StyledBackdrop
            onClick={(e) => onClose && onClose(e, "backdropClick")}
          />
        </Fade>
      )}
      <Drawer
        variant={variant}
        open={open}
        elevation={0}
        anchor={anchor}
        onClose={onClose}
        type="sidemenu"
        {...getDrawerV2DefaultProps(theme.palette.mode)}
        {...rest}
      >
        {children}
      </Drawer>
    </>
  );
};

const StyledBackdrop = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 100,
  backgroundColor: theme.palette?.surface?.overlay,
}));

export default GiveSideMenu;
