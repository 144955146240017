import { ROWS_PER_PAGE } from "@hooks/common/usePagination";
import { customInstance } from "@services/api";
import { useQuery } from "react-query";

interface Props {
  page?: number;
  max?: number;
  all?: boolean;
  searchTerm?: string;
}

export const useGetGlobalMerchantCategories = ({
  page = 0,
  max = ROWS_PER_PAGE,
  all = false,
  searchTerm = "",
}: Props) => {
  const getCategories = () => {
    if (searchTerm) {
      return customInstance({
        method: "GET",
        url: `/category-codes?q=${searchTerm}&page=${page}&max=${max}`,
      });
    }
    if (all) {
      return customInstance({
        method: "GET",
        url: "/category-codes?page=0&max=0",
      });
    }
    return customInstance({
      method: "GET",
      url: `/category-codes?page=${page}&max=${max}`,
    });
  };

  return useQuery(["get-all-categories-codes", page, searchTerm], async () => {
    const data = await getCategories();

    return data;
  });
};
