import { Box, CircularProgress, Paper, TextField, styled } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { createFilterOptions } from "@mui/material";
import { useCreateTopic } from "../hooks/useCreateTopic";
import { SearchIcon } from "@assets/rebrandIcons";
import { GlobalTopics } from "../hooks/useConversationsModal";
import { useAppDispatch } from "@redux/hooks";
import { setConversationTopic } from "@redux/slices/conversations";

const filter = createFilterOptions<{ label: string; inputValue?: string }>();

type TopicCategory = { label: string };

const categoryLabels = [
  "Merchant Info",
  "Bank Accounts",
  "Primary Account Holder",
  "Business Profile",
  "Business Address",
  "Business Owners",
  "PEP Check",
  "OFAC Check",
  "Snapshots",
  "Documents",
  "Merchant Fees",
  "Merchant Agreement",
  "Risk Activity",
  "MATCH Check",
];

export const categories: TopicCategory[] = categoryLabels.map((label) => ({
  label,
}));

const ConversationTopicsModal = ({
  handleOpenTopicModal,
  merchantId,
  isLoading,
  isEnterprise,
}: {
  handleOpenTopicModal: () => void;
  merchantId: number;
  isLoading: boolean;
  isEnterprise: boolean;
}) => {
  const [value, setValue] = useState<string>("");
  const { handleSubmit, isSuccess } = useCreateTopic({ merchantId });
  const dispatch = useAppDispatch();

  const roleReplacer = useCallback(
    (arr: Array<{ label: string }>) =>
      arr.map((v) => {
        if (isEnterprise && v.label.includes("Merchant")) {
          v.label = v.label.replace("Merchant", "Provider");
        }
        return v;
      }),
    [isEnterprise],
  );

  useEffect(() => {
    if (isSuccess) {
      handleOpenTopicModal();
    }
  }, [isSuccess]);

  const onChange = (
    e: SyntheticEvent<Element, Event>,
    topic: TopicCategory | null,
  ) => {
    if (!topic) return;
    if (GlobalTopics[topic.label]) {
      dispatch(
        setConversationTopic({
          isOpen: false,
          disableGlobalLauncher: false,
          queryObject: {
            id: undefined,
            name: topic.label,
            paths: [],
            defaultMessage: "",
            challengeId: undefined,
          },
        }),
      );
      handleOpenTopicModal();
      return;
    } else {
      handleSubmit(topic.label);
    }
  };
  return (
    <AutocompleteContainer>
      <Autocomplete
        id="combo-box-demo"
        options={roleReplacer(categories) || []}
        sx={{
          width: "100%",
          boxShadow: "0px 8px 25px 0px rgba(0, 0, 0, 0.15)",
          borderRadius: "8px",
        }}
        open
        onBlur={handleOpenTopicModal}
        PaperComponent={(props) => (
          <OptionsContainer {...props} isTyping={value.length > 0} />
        )}
        getOptionDisabled={(option) => {
          return option.label === "Add Custom topic";
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              label: "Add Custom topic",
            });
            filtered.push({
              inputValue: params.inputValue,
              label: `${params.inputValue}`,
            });
          }
          return filtered;
        }}
        onChange={onChange}
        loading={isLoading}
        renderInput={({ InputProps, ...params }) => (
          <Box sx={{ padding: "8px" }}>
            <TextField
              placeholder="Search..."
              value={value}
              autoFocus
              onChange={(e) => setValue(e.target.value)}
              InputProps={{
                ...InputProps,
                endAdornment: (
                  <>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                  </>
                ),
                startAdornment: <SearchIcon width={24} height={24} />,
              }}
              {...params}
            />
          </Box>
        )}
      />
    </AutocompleteContainer>
  );
};

export default ConversationTopicsModal;

const AutocompleteContainer = styled(Box)(() => ({
  position: "absolute",
  bottom: 0,

  width: "273px",
  marginBottom: "300px",
  zIndex: 99,

  backgroundColor: "#FAFAFA",

  left: "50%",
  transform: `translateX(-50%)`,
  ".Mui-expanded .MuiOutlinedInput-root .MuiAutocomplete-input": {
    margin: 0,
    padding: "initial",
  },
  ".MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root":
    {
      padding: 0,
    },
  ".Mui-expanded.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root": {
    borderRadius: "90px",
    padding: "4px 12px",
    alignItems: "center",
  },
  ".MuiOutlinedInput-root": {
    padding: 0,
    borderRadius: "90px",
  },
}));

const OptionsContainer = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "isTyping",
})<{ isTyping: boolean }>(({ isTyping }) => ({
  transform: "translate(-10px, 0px)",
  width: "273px",
  boxShadow:
    "-4px 0px 10px 0px rgba(0, 0, 0, 0), 4px 0px 10px 0px rgba(0, 0, 0, 0.05), 0px 4px 10px 0px rgba(0, 0, 0, 0.05)",
  borderRadius: "0px 0px 8px 8px",
  "& .MuiAutocomplete-listbox": {
    width: "100%",
    padding: "8px 0px 4px",
    maxHeight: "214px",
    backgroundColor: "#FAFAFA",

    "& .MuiAutocomplete-option": {
      height: "40px",
      padding: "4px 8px",
      ...(isTyping && {
        "&:nth-last-child(2)": {
          fontSize: "12px",
          padding: "12px 12px",
          fontWeight: 350,
          height: "14px",
          lineHeight: "14.4px",
        },
      }),
    },
  },
}));
