import * as React from "react";
import { Box, BoxProps, SxProps } from "@mui/material";
import NumberFormat from "react-number-format";
import { GiveInput } from "./GiveInput";

const MAX_INT_VALUE = Number.MAX_SAFE_INTEGER;
const MIN_INT_VALUE = Number.MIN_SAFE_INTEGER;
const MAX_STOCK = 99999;

export type GiveIntegerInputProps = {
  min?: number;
  max?: number;
  label?: string | React.ReactNode;
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
  helperText?: string | React.ReactNode;
  endIcon?: React.ReactNode;
  allowNegative?: boolean;
  inputRef?: React.Ref<any>;
  sx?: SxProps;
  value?: string | number | null | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  containerProps?: BoxProps;
  inputProps?: Record<any, any>;
};

const GiveIntegerInput = ({
  min = MIN_INT_VALUE,
  max = MAX_INT_VALUE,
  allowNegative = false,
  containerProps,
  ...props
}: GiveIntegerInputProps) => {
  const minValue = min === MIN_INT_VALUE ? (allowNegative ? min : 0) : min;

  const isAllowed = (values: any) => {
    const { formattedValue, floatValue, value } = values;
    if (value > MAX_STOCK) return false;

    if (!floatValue && floatValue !== 0) {
      return formattedValue === "" || formattedValue === "-";
    } else {
      return floatValue >= minValue && floatValue <= max;
    }
  };

  return (
    <Box sx={{ width: "100%", ...containerProps?.sx }} {...containerProps}>
      <NumberFormat
        customInput={GiveInput}
        thousandSeparator={false}
        allowLeadingZeros={false}
        allowNegative={allowNegative}
        decimalScale={0}
        isAllowed={isAllowed}
        fullWidth
        min={minValue}
        {...props}
      />
    </Box>
  );
};

export default GiveIntegerInput