import React from "react";
import { Icon } from "@phosphor-icons/react";
import getAcquirerMenu from "./menus/acquirerMenu";
import getProviderMenu from "./menus/providerMenu";
import getMerchantMenu from "./menus/merchantMenu";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { useUser } from "@hooks/common/useUser";
import { useLocation } from "react-router-dom";
import { useProductPermission } from "features/Permissions/AccessControl/hooks";
import { useGetProductsStatsFactory } from "@services/api/products/queryFactory";

interface MenuListItem {
  label?: string;
  value: string;
  Icon?: Icon;
  CustomIcon?: JSX.Element;
  isForm?: boolean;
}

type TMenuList = {
  menu: MenuListItem[];
  footer: MenuListItem[];
};

type TStatsItem = {
  totalProducts: number;
  productTypeName: string;
};

export function useMenu() {
  const { isAcquirer, isEnterprise, isMerchant } = useUser();
  const { isAddProductAllowed } = useProductPermission();

  const { isTransfersPageEnabled, isDisputesPageEnabled } =
    useGetFeatureFlagValues();
  const location = useLocation();

  const { data: stats } = useGetProductsStatsFactory(undefined, true);

  const menuList: TMenuList = isAcquirer
    ? getAcquirerMenu({ isTransfersPageEnabled, isDisputesPageEnabled })
    : isEnterprise
    ? getProviderMenu({ isTransfersPageEnabled })
    : getMerchantMenu({ isAddProductAllowed });

  const topMenu: MenuListItem[] = React.useMemo(() => {
    const totalStats = stats?.total;
    const statsData = stats?.data;

    if (
      totalStats === 6 &&
      statsData?.every((item: TStatsItem) => item.totalProducts > 0)
    ) {
      return menuList.menu;
    }
    const existing =
      statsData
        ?.filter((item: TStatsItem) => item.totalProducts > 0)
        .map((item: TStatsItem) => `${item.productTypeName}s`) || [];

    return menuList.menu.filter(
      (item: MenuListItem) =>
        !item.isForm || existing.includes(item.label?.toLowerCase()),
    );
  }, [stats?.data, stats?.total, menuList.menu]);

  return {
    topMenu,
    bottomMenu: menuList.footer,
    isMerchant,
    curPath: location.pathname,
  };
}
