import { Text } from "@common/Text";
import { Tooltip } from "@common/Tooltip";
import { Button } from "@common/Button";
import { styled } from "@mui/material";
import { Stack } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { EDIT_DENY_MESSAGE } from "@constants/permissions";

type Props = {
  isStatusNameCanonic: boolean;
  notApprovedList?: string[];
  isReadyToApprove: boolean;
  statusName: string;
  isEnterprise?: boolean;
  handleApprove: () => void;
  handleDecline: () => void;
};

const ApproveDeclineButtons = (props: Props) => {
  const {
    isStatusNameCanonic,
    notApprovedList,
    isReadyToApprove,
    statusName,
    handleApprove,
    handleDecline,
  } = props;
  const { isMobileView } = useCustomTheme();
  const { isUpdateUnderwriterAllowed } = useUnderwriterPermissions();
  const { merchant_underwriting_statuses } = useEnterprisePermissions();
  const approveButtonTitle =
    statusName === "preapproved_mid_issue"
      ? "Retry Approval"
      : "Approve this account";

  const approveButtonDisabled =
    !isReadyToApprove || !isUpdateUnderwriterAllowed;

  if (!isStatusNameCanonic && merchant_underwriting_statuses) {
    return (
      <BodyContainer isMobile={isMobileView}>
        <ApproveButton
          disabled={approveButtonDisabled}
          isPermitted={isUpdateUnderwriterAllowed}
          title={approveButtonTitle}
          notApprovedList={notApprovedList}
          isEnterprise={props.isEnterprise}
          onClick={handleApprove}
          isReadyToApprove={isReadyToApprove}
        />
        <DeclineButton
          disabled={!isUpdateUnderwriterAllowed}
          onClick={() => handleDecline()}
        />
      </BodyContainer>
    );
  } else return null;
};

type ApproveButtonProps = {
  isReadyToApprove: boolean;
  notApprovedList?: string[];
  title: string;
  disabled: boolean;
  isEnterprise?: boolean;
  onClick: () => void;
  isPermitted?: boolean;
};

const ApproveButton = (props: ApproveButtonProps) => {
  const {
    isReadyToApprove,
    notApprovedList,
    title,
    disabled,
    onClick,
    isPermitted,
  } = props;
  const isMainTooltipDisabled = isReadyToApprove || !isPermitted;
  return (
    <Tooltip
      disableHoverListener={isMainTooltipDisabled}
      disableFocusListener={isMainTooltipDisabled}
      disableTouchListener={isMainTooltipDisabled}
      title={
        <TooltipTitle
          notApprovedList={notApprovedList}
          isEnterprise={props.isEnterprise}
        />
      }
    >
      <Button
        size="medium"
        sx={{
          background: palette.filled.success,
          "&:hover": { background: palette.filled.success },
        }}
        disabled={disabled}
        tooltipProps={{
          show: !isPermitted,
          message: EDIT_DENY_MESSAGE,
        }}
        onClick={onClick}
      >
        {title}
      </Button>
    </Tooltip>
  );
};

type DeclineButtonProps = {
  disabled: boolean;
  onClick: () => void;
};
const DeclineButton = (props: DeclineButtonProps) => {
  const { disabled, onClick } = props;
  const { isMobileView } = useCustomTheme();
  return (
    <Button
      background="secondary"
      size="medium"
      disabled={disabled}
      onClick={onClick}
      tooltipProps={{
        show: disabled,
        message: EDIT_DENY_MESSAGE,
      }}
      sx={{ marginBottom: `${isMobileView ? "16px" : "0px"} !important` }}
    >
      Decline
    </Button>
  );
};

type TooltipTitleProps = {
  notApprovedList?: string[];
  isEnterprise?: boolean;
};

const TooltipTitle = (props: TooltipTitleProps) => {
  const { notApprovedList } = props;
  return (
    <Stack>
      <Text
        lineHeight="14px"
        color="#F8F8F6"
        fontWeight="book"
        fontSize="12px"
        textAlign="left"
        mb="10px"
      >
        You cannot approve this {props.isEnterprise ? "provider" : "merchant"}{" "}
        yet. The following information is missing:
      </Text>
      {notApprovedList?.map((text) => (
        <ListStyle key={text}>{text}</ListStyle>
      ))}
    </Stack>
  );
};
/** Styled components */

const ListStyle = styled("li")(() => ({
  textAlign: "left",
  color: "#F8F8F6",
  fontSize: "12px",
  fontWeight: 300,
}));

const BodyContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})((props: { isMobile: boolean }) =>
  props.isMobile
    ? {
        flexDirection: "column-reverse",
        alignItems: "center",
        spacing: 2,
      }
    : {
        flexDirection: "row",
        alignItems: "center",
        spacing: 2,
        gap: "16px",
        paddingTop: "16px",
      },
);

export default ApproveDeclineButtons;
