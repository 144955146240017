import { Box, Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { styled, useAppTheme } from "@theme/v2/Provider";
import React, { useState } from "react";
import { usePayBuilderContext } from "../provider/PayBuilderContext";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import PublicUrlShare from "../LaunchStep/PublicURLShare/PublicURLShare";
import {
  DesktopDeviceIcon,
  MobileDeviceIcon,
  TabletDeviceIcon,
} from "@assets/icons/RebrandedIcons";
import RightSideHeaderPart from "../RightSideHeaderPart";

type ScreenState = {
  isMobileView: boolean;
  isTabletView: boolean;
  isDesktopView: boolean;
  isMobileDevice: boolean;
  isSmallDesktop: boolean;
};

type PreviewWrapperProps = {
  children: React.ReactNode;
  background: string;
};

const PreviewWrapper: React.FC<PreviewWrapperProps> = ({
  children,
  background,
}) => {
  const { isMobileView } = useCustomThemeV2();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { palette } = useAppTheme();
  const {
    screenStates,
    manageScreenActions: { setDeskTopView, setTabletView, setMobileView },
    isLastStep,
  } = usePayBuilderContext();

  const viewActions = [
    {
      Icon: DesktopDeviceIcon,
      onClick: setDeskTopView,
      isActive: screenStates.isDesktopView,
    },
    {
      Icon: TabletDeviceIcon,
      onClick: setTabletView,
      isActive: screenStates.isTabletView,
    },
    {
      Icon: MobileDeviceIcon,
      onClick: setMobileView,
      isActive: screenStates.isMobileDevice,
    },
  ];

  const renderViewActions = () => {
    return viewActions.map(({ Icon, onClick, isActive }, idx) => (
      <IconButton onClick={onClick} key={idx}>
        <Icon
          color={
            isActive
              ? palette.primitive?.blue[100]
              : palette.icon?.["icon-secondary"]
          }
        />
      </IconButton>
    ));
  };

  return (
    <>
      <Stack height="auto" width="100%" p="32px">
        {!isMobileView && (
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            mb="20px"
          >
            <Box>
              <GiveText>Preview</GiveText>
              <GiveText
                mt="4px"
                fontWeight={400}
                fontSize="14px"
                color="secondary"
              >
                As you create your form, this preview will show how it will
                appear to others
              </GiveText>
            </Box>
            <RightSideHeaderPart isLastStep={isLastStep} renderViewActions={renderViewActions} />
          </Stack>
        )}
        <ChildContainer
          screenStates={isMobileView ? undefined : screenStates}
          sx={{
            background: background,
          }}
          mt="20px"
        >
          {children}
        </ChildContainer>
      </Stack>
      <PublicUrlShare
        variant="strict"
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </>
  );
};

export default PreviewWrapper;

const ChildContainer = styled(Box)<{ screenStates?: ScreenState }>(
  ({ theme, screenStates }) => {
    const { isMobileView, isTabletView } = screenStates || {};

    return {
      marginTop: "20px",
      boxShadow: `0px 4px 8px 0px ${theme.customs?.shadows.small}`,
      borderRadius: theme.customs?.radius.medium,
      width: isMobileView ? "390px" : isTabletView ? "768px" : "100%",
      margin: "0 auto",
      height: "auto",
      minHeight: "100vh",
      transition: "width 0.3s ease-in-out",
      paddingTop: isMobileView ? "20px" : undefined,
      display: "flex",
      alignItems: "center",
    };
  },
);

const IconButton = styled("button")(({ theme }) => {
  return {
    all: "unset",
    cursor: "pointer",
    margin: "0 3px",
  };
});
