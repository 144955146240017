import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Divider, Grid, Stack } from "@mui/material";
import { Box } from "@mui/material";
import { Text } from "@common/Text";
import CardType from "../CardType";
import CollapsableComponent from "../../CollapsableComponents";
import { StoreFrontIcon } from "@assets/transactionsRiskProfileIcons";
import { CreditCardIcon2 } from "@assets/icons";
import MerchantExpandableSection from "../MerchantExpandableSection";
import {
  MAX_TEXT_AREA_LENGTH,
  feeScheduleSection,
  merchantApplicationSection,
} from "./merchantAgreement.const";
import { Section } from "@components/Merchants/CreateMerchantPanel/types";
import PreviousTermination from "./PreviousTermination";
import { KeyValue } from "@components/Merchants/MerchantPreview/components/atoms";
import RefundPolicyInput from "./RefundPolicyInput";
import { RHFCheckbox } from "@common/Checkbox";
import { palette } from "@palette";
import { BankDisclosure } from "../BankDisclosure";
import {
  IParsedData,
  TFeeObject,
} from "@components/Merchants/MerchantPreview/data.types";
import { calculateFeePerTransaction } from "@components/Merchants/MerchantPreview/components/MerchantFees";

export type BrandsType = "Visa" | "MasterCard" | "Discover" | "AMEX";
interface MerchantAgreementFormProps {
  isEditForm?: boolean;
  items?: any[];
  isOnboarding?: boolean;
  onSubmit?: () => void;
  data?: Partial<IParsedData>;
  hideDetails?: boolean;
  isSettings?: boolean;
}

export const MerchantAgreementForm = ({
  isOnboarding,
  isEditForm,
  items,
  data,
  hideDetails,
  isSettings,
}: MerchantAgreementFormProps) => {
  const { watch, trigger } = useFormContext();

  const values = watch();

  const cardNames: {
    Visa: { isDefault: boolean };
    MasterCard: { isDefault: boolean };
    Discover: { isDefault: boolean };
    AMEX: { isDefault: boolean };
  } = {
    Visa: { isDefault: true },
    MasterCard: { isDefault: false },
    Discover: { isDefault: false },
    AMEX: { isDefault: false },
  };

  const fees = [
    {
      label: "Credit Card Fee",
      fee: data?.fees?.creditCardFee.value,
      feePerTransaction: calculateFeePerTransaction(
        data?.fees?.creditCardFee as TFeeObject,
      ),
    },
    {
      label: "Debit Card Fee",
      fee: data?.fees?.debitCardFee.value,
      feePerTransaction: calculateFeePerTransaction(
        data?.fees?.debitCardFee as TFeeObject,
      ),
    },
    {
      label: "AMEX Credit Card Fee",
      fee: data?.fees?.amexCreditCardFee.value,
      feePerTransaction: calculateFeePerTransaction(
        data?.fees?.amexCreditCardFee as TFeeObject,
      ),
    },
  ];

  useEffect(() => {
    if (!values.msaPCICompliant) trigger("msaPCICompliant");
  }, [values.msaPCICompliant]);

  return (
    <>
      {isOnboarding && <BankDisclosure />}
      {/* CARD TYPES SECTION */}
      <Box my={3}>
        <Text fontSize={18}>Card Types</Text>
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          gap={1}
          sx={{
            marginTop: "16px",
          }}
        >
          {(Object.keys(cardNames) as BrandsType[]).map((cardName, index) => {
            return <CardType key={index} cardName={cardName} />;
          })}
        </Stack>
      </Box>
      {((!isEditForm && !isSettings) || isOnboarding) && (
        <Box color="#8F8F8F" fontSize={14}>
          <RefundPolicyInput
            name={"msaRefundPolicy"}
            label={`Refund policy`}
            placeholder="Write something about refund policy..."
            rows={6}
            helperText={`${values.msaRefundPolicy?.length}/${MAX_TEXT_AREA_LENGTH}`}
          />
        </Box>
      )}
      {!isOnboarding && (
        <Divider sx={{ my: 1, marginBottom: "16px", marginTop: "16px" }} />
      )}
      {/* EXPANDABLE SECTIONS */}
      {!hideDetails && (
        <>
          <CollapsableComponent
            title="Merchant Application"
            icon={<StoreFrontIcon />}
            sx={{
              ...(isOnboarding && {
                marginTop: "24px",
              }),
            }}
          >
            <SectionContent section={merchantApplicationSection(data)} />
          </CollapsableComponent>
          <CollapsableComponent
            title="Fee Schedule"
            icon={<CreditCardIcon2 />}
            sx={{
              marginTop: "8px",
              ...(isOnboarding && {
                marginBottom: "24px",
              }),
            }}
          >
            <SectionContent section={feeScheduleSection(fees)} />
          </CollapsableComponent>
        </>
      )}
      {!isOnboarding && !hideDetails && (
        <Divider sx={{ my: 1, marginBottom: "16px", marginTop: "16px" }} />
      )}
      <RHFCheckbox
        name="msaPCICompliant"
        label="PCI Compliance"
        labelProps={{
          color: palette.black[100],
          fontSize: 14,
          fontWeight: "book",
        }}
        dataTestId="checkbox-msa-pci-compliant"
        disabled={isEditForm}
      />
      <Text
        color={palette.gray[300]}
        fontSize="14px"
        fontWeight="book"
        ml={4}
        sx={{
          wordBreak: "break-word",
          overflowWrap: "break-word",
        }}
      >
        By checking this checkbox, you confirm that your business adheres to the
        Payment Card Industry Data Security Standard (PCI DSS) requirements and
        is PCI compliant. If you are unsure, complete the Self-Assessment
        Questionnaire A and Attestation of Compliance at
        https://listings.pcisecuritystandards.org/documents/SAQ_A_v3.pdf to
        ensure your business meets the PCI DSS standards.
      </Text>
      <PreviousTermination
        isEditForm={isEditForm}
        merchantId={data?.merchantInfo?.merchantID}
      />
      {isEditForm &&
        values.msaPCICompliant &&
        (!data || (data && data.merchantAgreement?.msaPreviousTermination)) && (
          <Grid container rowSpacing="12px" columnSpacing="8xp" mt={1}>
            {items?.map((item) => {
              const { size, title, ...rest } = item;
              if (!rest.value && rest.value !== 0) return null;
              return (
                <Grid item xs={12} sm={size} key={title} mb={2}>
                  <KeyValue keyName={title} fullWidth {...rest} />
                </Grid>
              );
            })}
          </Grid>
        )}
    </>
  );
};

export const SectionContent = ({ section }: { section: Section[] }) => {
  return (
    <>
      {section.map((section: Section) => {
        const sectionKey = Object.keys(section)[0];
        const fields = section[sectionKey];

        return (
          <MerchantExpandableSection
            key={sectionKey}
            sectionKey={sectionKey}
            fields={fields}
          />
        );
      })}
    </>
  );
};
