import { Box } from "@mui/material";
import GiveUploadArea from "@shared/FileUpload/GiveUploadArea";
import { Dispatch, SetStateAction } from "react";

interface Props {
  setFileToUpload: Dispatch<SetStateAction<File | null>>;
}
function UploadSignature({ setFileToUpload }: Props) {
  const handleUpload = (e: File) => {
    setFileToUpload(e);
  };
  return (
    <Box mt="16px">
      <GiveUploadArea
        uploadFunction={handleUpload}
        disabled={false}
        multiple={false}
        maxFiles={1}
        message="Up to 5MB each (.pdf, .png, .jpg, .jpeg, .webp)"
      />
    </Box>
  );
}

export default UploadSignature;
