import { ModalTitle } from "@common/Modal/ModalFactory/atoms";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import ReportModalContent from "./ReportModalContent";
import { MATCH_REPORT_MODAL } from "modals/modal_names";
import { ReportType } from "./types";
import moment from "moment";

interface Props {
  merchantID: number;
  report?: ReportType;
}

const ReportModal = NiceModal.create(({ merchantID, report }: Props) => {
  const modal = useModal(MATCH_REPORT_MODAL);
  const { isMobileView } = useCustomTheme();

  const handleCancel = () => {
    modal.hide();
  };
  const description = report
    ? `Latest - ${moment
        .unix(report.createdAt)
        .utc()
        .format("MMM D, YYYY, h:mm:ss A [UTC]")}`
    : "Please review each field thoroughly to ensure all information is accurate and current before submitting.";

  const title = report ? "MATCH Report" : "Add New Report";

  return (
    <ModalFactory
      variant="dialog"
      renderMobile={isMobileView}
      modalProps={{
        open: modal.visible,
        onClose: handleCancel,
        width: 570,
        DialogProps: {
          PaperProps: {
            sx: {
              padding: "16px 0px",
            },
          },
          contentContainerSx: { height: "100%" },
        },
        sx: { zIndex: 1201 },
      }}
    >
      <ModalTitle
        title={title}
        onClose={handleCancel}
        description={description}
        textStyles={{ title: { fontSize: "18px" } }}
        padding="0px 24px"
      />
      <ReportModalContent
        merchantID={merchantID}
        showLatest={!!report}
        onClose={handleCancel}
        data={report}
      />
    </ModalFactory>
  );
});

export default ReportModal;
