import GiveChip, { IChipProps } from "@shared/Chip/GiveChip";

const statusMap = {
  ready_for_review: {
    color: "warning",
    label: "Ready for Review",
  },
  pending: {
    color: "default",
    label: "Pending",
  },
};

interface IComponentProps {
  status?: string;
}

const SponsorStatusChip = ({ status = "pending" }: IComponentProps) => {

  const chipData =
    statusMap[status as keyof typeof statusMap] ?? statusMap.pending;
  return (
    <GiveChip
      variant="outline"
      label={chipData.label}
      color={chipData.color as IChipProps["color"]}
    />
  );
};

export default SponsorStatusChip;
