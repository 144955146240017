import React, { useEffect } from "react";
import { Dialog, DialogProps } from "@mui/material";
import { NavigationType, useNavigationType } from "react-router-dom";
import { animated, useTransition } from "react-spring";

export type BaseModalProps = DialogProps & {
  width?: string;
  isPeekMode?: boolean;
  scroll?: DialogProps["scroll"];
  shouldBlur?: boolean;
  testId?: string;
  onAnimationEnd?: () => void;
};

const BaseModal: React.FC<BaseModalProps> = ({
  sx,
  children,
  width = "640px",
  open,
  scroll = "paper",
  shouldBlur = true,
  testId,
  onAnimationEnd,
  isPeekMode,
  ...rest
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const navType: NavigationType = useNavigationType();

  const AnimatedDialog = animated(Dialog);

  const transitions = useTransition(isOpen, {
    from: { transform: "translateY(25px)" },
    enter: { transform: "translateY(0px)" },
    leave: { transform: "translateY(50px)" },
    onDestroyed: () => {
      if (onAnimationEnd && open === false) {
        onAnimationEnd();
      }
    },
  });

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (navType === "POP" && isOpen) {
      setIsOpen(false);
    }
  }, [navType]);

  useEffect(() => {
    const handlePopstate = () => {
      setIsOpen(false);
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {transitions((style, itemVisible) => (
        <AnimatedDialog
          open={!isOpen ? isOpen : itemVisible}
          style={style}
          scroll={scroll}
          BackdropProps={{
            sx: {
              ...(shouldBlur && { backdropFilter: "blur(4px)" }),
              backgroundColor: "rgba(255, 255, 255, 0.15)",
            },
          }}
          PaperProps={{
            style: {
              transformOrigin: "top",
            },
          }}
          sx={{
            overscrollBehavior: "contain",
            "& .MuiDialog-paper": {
              borderRadius: "16px",
              width: width,
              maxWidth: width,
              background: "#F8F8F6",
            },
            ...sx,
          }}
          data-testid={testId}
          {...rest}
        >
          {children}
        </AnimatedDialog>
      ))}
    </>
  );
};

export default BaseModal;
