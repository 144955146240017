import NiceModal from "@ebay/nice-modal-react";
import { palette } from "@palette";
import { Text } from "@common/Text";
import { capitalize } from "lodash";
import { UnderwritingPopupBase } from "@components/Merchants/MerchantPreview/modals/ApproveMerchantPopup";
import { useBulkUpdateSponsorStatus } from "components/AcquirerMerchants/hooks/useBulkUpdateSponsorStatus";
import { Stack, styled } from "@mui/material";
import WarningIcon from "assets/icons/RebrandedIcons/WarningIcon";

type Props = {
  action?: "approve" | "decline";
};

const MerchantBulkActionsModal = NiceModal.create(
  ({ action = "approve" }: Props) => {
    const { bulkUpdateSponsorStatus } = useBulkUpdateSponsorStatus();

    const handleUpdate = async (cb?: () => void) => {
      await bulkUpdateSponsorStatus(action || "approve");
      if (cb) {
        cb();
      }
    };

    const props = {
      modalTitle: `${capitalize(action)} Selected Merchants`,
      submitLabel: capitalize(action),
      children: (
        <Stack>
          <Text
            fontWeight="book"
            color={palette.neutral[70]}
            lineHeight="16.8px"
          >
            {action === "approve"
              ? "Are you sure you want to approve the selected merchants? This action will finalize their approval status and cannot be undone."
              : "Are you sure you want to decline the selected merchants? This action will permanently decline their applications and cannot be undone."}
          </Text>
          <Disclaimer gap="8px">
            <Stack direction="row" gap="8px">
              <WarningIcon />
              <Text fontSize="18px" color={palette.filled.orange}>
                {action === "approve" ? "Approval" : "Decline"} May Take Time
              </Text>
            </Stack>
            <Text color={palette.warning.text}>
              {`Processing may take some time. You won't be able to perform
              another bulk action until it's complete. We'll notify you once
              it's done.`}
            </Text>
          </Disclaimer>
        </Stack>
      ),
    };

    return (
      <UnderwritingPopupBase
        handleSubmit={handleUpdate}
        primaryButtonBackground={
          action === "approve" ? palette.filled.green : palette.filled.red
        }
        width="549px"
        {...props}
      />
    );
  },
);

const Disclaimer = styled(Stack)(() => ({
  backgroundColor: palette.tag.warning.bg,
  padding: "12px 16px",
  borderRadius: "12px",
  marginTop: "16px",
}));

export default MerchantBulkActionsModal;
