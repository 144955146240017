import { useEffect, useRef, useState } from "react";
import { useGetGlobalMerchantCategories } from "./useGlobalMerchantCategories";

export type MCCType = {
  isChecked: boolean;
  id: number;
  issuer: string;
  code: string;
  title: string;
  titleAlt: string;
  description: string;
  examples: string;
  createdAt: number;
  updatedAt: number;
  riskLevelName: string;
};

interface Props {
  all?: boolean;
  searchTerm?: string;
  scrollBoxRef?: React.RefObject<HTMLDivElement>;
}
const useListCategories = ({
  all = false,
  searchTerm = "",
  scrollBoxRef,
}: Props = {}) => {
  const [data, setData] = useState<MCCType[]>([]);
  const [page, setPage] = useState(1);
  const {
    data: pageData,
    isLoading,
    isError,
  } = useGetGlobalMerchantCategories({ page, all, searchTerm });

  const BOTTOM_OFFSET_PX = 4;
  const searchQueryRef = useRef(false);

  useEffect(() => {
    setPage(1);
    searchQueryRef.current = true;

    // Reset scroll position when a new search starts
    if (scrollBoxRef?.current) {
      scrollBoxRef.current.scrollTop = 0;
    }
  }, [searchTerm]);
  useEffect(() => {
    if (pageData?.data) {
      if (searchQueryRef.current) {
        setData(pageData?.data ?? []);
        searchQueryRef.current = false;
      } else {
        if (data.length === 0 && pageData?.data) {
          setData(pageData.data);
        } else if (data.length > 0) {
          setData((prev) => [...prev, ...pageData.data]);
        }
      }
    }
    if (pageData?.data === null && !isLoading) setData([]);
  }, [pageData]);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    if (isLoading) return;
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    const isNearBottom =
      scrollHeight - (scrollTop + clientHeight) <= BOTTOM_OFFSET_PX;

    if (isNearBottom && pageData?.total > data.length) {
      setPage((prev) => prev + 1);
    }
  };

  return {
    data,
    isLoading,
    isError,
    handleScroll,
  };
};

export default useListCategories;
