import { Box, Grid } from "@mui/material";
import GiveCheckbox from "@shared/GiveCheckbox/GiveCheckbox";
import SectionCardBase from "@shared/SidePanel/components/SectionCard/SectionCardBase";
import GiveText from "@shared/Text/GiveText";
import React, { Dispatch, SetStateAction } from "react";
import { StatusInfo } from "../../agreements.types";
import moment from "moment";
import { RenderTextsComponent } from "../../Snapshot/components/BaseCardComponent";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { GiveInput } from "@shared/GiveInputs/GiveInput";
import TerminationForm from "./TerminationForm";

interface Props {
  setRadioButtons?: Dispatch<
    SetStateAction<{
      isPciChecked: boolean;
      isPreviousTerminationChecked: boolean;
    }>
  >;
  radioState?: {
    isPciChecked: boolean;
    isPreviousTerminationChecked: boolean;
  };
  statusInfo?: StatusInfo;
}
function PCISection({ setRadioButtons, radioState, statusInfo }: Props) {
  const {
    status,
    msaPreviousTerminationProcessorName,
    msaPreviousTerminationAt,
    msaPreviousTerminationReason,
    msaPreviousTermination,
  } = statusInfo || {};
  const { isMobileView } = useCustomThemeV2();
  const { isPciChecked, isPreviousTerminationChecked } = radioState || {};
  const isSigned = status === "signed";
  const payload = [
    {
      title: "PCI Compliance",
      subTitle: `By checking this checkbox, you confirm that your business adheres to the Payment Card Industry Data Security Standard (PCI DSS) requirements and is PCI compliant. If you are unsure, complete the Self-Assessment Questionnaire A and Attestation of Compliance to ensure your business meets the PCI DSS standards.`,
      isChecked: isPciChecked,
      isDisabled: isSigned,
      isRequired: true,
      onChange: () => {
        setRadioButtons &&
          setRadioButtons((prev) => ({
            ...prev,
            isPciChecked: !prev?.isPciChecked,
          }));
      },
    },
    {
      title: "Previous Termination Disclosure (Only Check if Applicable)",
      subTitle: `I hereby disclose that my previous merchant account(s) have been terminated or are currently subject to termination proceedings. I understand that providing false information or failing to disclose a prior account termination can result in the rejection of this application or subsequent termination of any account granted based on this application.`,
      isChecked: isPreviousTerminationChecked,
      isDisabled: isSigned,
      onChange: () => {
        setRadioButtons &&
          setRadioButtons((prev) => ({
            ...prev,
            isPreviousTerminationChecked: !prev?.isPreviousTerminationChecked,
          }));
      },
    },
  ];

  const terminationArr = [
    {
      label: "Name of Previous Processor",
      value: msaPreviousTerminationProcessorName,
    },
    {
      label: "Date of Termination",
      value: msaPreviousTerminationAt
        ? moment.unix(msaPreviousTerminationAt as number).format("MM/DD/YYYY")
        : "",
    },
    {
      label: "Reason for Termination",
      value: msaPreviousTerminationReason,
    },
  ];
  const isTerminationShown =
    (isSigned && msaPreviousTermination) ||
    (!isSigned && !!msaPreviousTerminationProcessorName);
  const isTerminationInputs = !isSigned && isPreviousTerminationChecked;
  return (
    <SectionCardBase>
      {payload?.map((data) => (
        <CustomRadioComponent key={data?.title} {...data} />
      ))}
      {isTerminationShown && (
        <Grid container>
          <Grid xs={0.7} item />{" "}
          <Grid xs={11} item>
            {terminationArr?.map(({ label, value }, index, arr) => {
              const gridProps = isMobileView
                ? {
                    xs: 12,
                  }
                : undefined;
              const isNoDivider = arr?.length - 1 === index;
              return (
                <RenderTextsComponent
                  key={label}
                  label={label}
                  value={value}
                  itemGridProps={gridProps}
                  parentGridProps={gridProps}
                  isDivider={!isNoDivider && isMobileView}
                />
              );
            })}
          </Grid>
        </Grid>
      )}
      {isTerminationInputs && (
        <TerminationForm
          msaPreviousTerminationProcessorName={
            msaPreviousTerminationProcessorName
          }
          msaPreviousTerminationAt={msaPreviousTerminationAt}
          msaPreviousTerminationReason={msaPreviousTerminationReason}
        />
      )}
    </SectionCardBase>
  );
}

export default PCISection;

const CustomRadioComponent = ({
  title,
  subTitle,
  isChecked,
  isDisabled,
  onChange,
  isRequired,
}: {
  title?: string;
  subTitle?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  onChange: () => void;
  isRequired?: boolean;
}) => {
  return (
    <Grid my="7px" container>
      <Grid xs={0.7} item>
        <GiveCheckbox
          onChange={onChange}
          checked={isChecked}
          disabled={isDisabled}
        />
      </Grid>
      <Grid xs={11} item pt="2px">
        <Box>
          {title && (
            <GiveText
              fontSize="14px"
              color={isDisabled ? "secondary" : "primary"}
              mb="4px"
            >
              {title}
              {isRequired && (
                <GiveText color="error" component="span">
                  *
                </GiveText>
              )}
            </GiveText>
          )}
          {subTitle && (
            <GiveText variant="bodyXS" color="secondary">
              {subTitle}
            </GiveText>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
