import NiceModal from "@ebay/nice-modal-react";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { PrivacyContent } from "@pages/Privacy/Privacy";
import GiveText from "@shared/Text/GiveText";

//TODO: to be integrated when new checkout page is ready
const PrivacyModal = NiceModal.create(() => {
  const { open, onClose } = useNiceModal();

  return (
    <GiveBaseModal
      open={open}
      title="Privacy Policy"
      width="600px"
      onClose={onClose}
      showFooter={false}
    >
      <GiveText variant="h4" sx={{ paddingBottom: "24px" }}>
        Give Corporation Privacy Policy - United States
      </GiveText>
      <PrivacyContent />
    </GiveBaseModal>
  );
});

export default PrivacyModal;
