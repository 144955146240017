import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
} from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { getGlobalTopic } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { customInstance } from "@services/api";
import { showMessage } from "@common/Toast";
import { TChallengeTypeName } from "@components/Merchants/MerchantPreview/manageUnderwriting/ChallengeTypeChip";
import { getUnderwritingTopicID } from "@components/Merchants/MerchantPreview/manageUnderwriting/Challenges/hooks/useOpenChallengeConversation";
import { useAppTheme, styled } from "@theme/v2/Provider";
import GiveText from "@shared/Text/GiveText";
import { Minus, Plus } from "@phosphor-icons/react";

const ICON_SIZE = 20;

type Props = {
  challengeTypeName: TChallengeTypeName;
  message: React.ReactNode;
  threadId: number;
  merchantID: number;
};

const ChallengeMessage = ({
  message,
  merchantID,
  challengeTypeName,
  threadId,
}: Props) => {
  const [expanded, setExpanded] = useState(false);
  const theme = useAppTheme();

  const { data: topicData, isLoading } = useQuery(
    [`get-message-body-${threadId}`, threadId, merchantID, challengeTypeName],
    async () => {
      const { data } = await getGlobalTopic({ topicName: "underwriting" });
      const underwritingTopicID = getUnderwritingTopicID(
        challengeTypeName,
        data,
      );
      if (!underwritingTopicID) {
        return { message: "" };
      }

      const { data: _threads } = await customInstance({
        url: `/merchants/${merchantID}/topics/${underwritingTopicID}/threads?filter=id:${threadId}`,
        method: "GET",
      });

      if (_threads) {
        return { message: _threads[0]?.messages[0]?.body || "" };
      }
      return { message: "" };
    },
    {
      enabled:
        expanded &&
        !!threadId &&
        !!merchantID &&
        challengeTypeName !== "customer_due_diligence",
      onError(err: any) {
        showMessage(
          // TODO: use rebranding snackbar
          "Error",
          err?.response?.data?.message || "Unable to fetch message body",
        );
      },
    },
  );

  const ExpandIcon = expanded ? (
    <Minus size={ICON_SIZE} fill={theme.palette.text.primary} />
  ) : (
    <Plus size={ICON_SIZE} fill={theme.palette.text.primary} />
  );

  return (
    <StyledAccordion
      onChange={() => setExpanded((curr) => !curr)}
      disableGutters
    >
      <StyledAccordionSummary expandIcon={ExpandIcon}>
        <GiveText color="primary" variant="bodyS">
          How to complete this challenge?
        </GiveText>
      </StyledAccordionSummary>
      <AccordionDetails>
        {isLoading ? (
          <Skeleton
            sx={{
              height: "70px",
            }}
          />
        ) : (
          <GiveText
            sx={{
              wordWrap: "break-word",
              whiteSpace: "normal",
            }}
            color="secondary"
            variant="bodyS"
          >
            {topicData?.message || message}
          </GiveText>
        )}
      </AccordionDetails>
    </StyledAccordion>
  );
};
const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: "none",
  borderRadius: `8px !important`,
  backgroundColor: theme.palette.primitive?.transparent["darken-5"],
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  borderRadius: `8px !important`,
  "&:hover": {
    backgroundColor: theme.palette.neutral[10],
  },
}));

export default ChallengeMessage;
