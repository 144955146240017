import { Box, FormControlLabel, RadioGroup, Stack } from "@mui/material";
import GiveButton from "@shared/Button/GiveButton";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import GiveText from "@shared/Text/GiveText";
import { Controller } from "react-hook-form";
import { SubmitHandler } from "react-hook-form";
import { useUpdateUnderwriting } from "@components/Merchants/MerchantPreview/hooks/useUnderwritingUpdate";
import { useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  QKEY_LIST_ACQUIRER_MERCHANTS,
  QKEY_LIST_MERCHANT_STATS,
} from "@constants/queryKeys";
import NiceModal from "@ebay/nice-modal-react";
import { styled, useAppTheme } from "@theme/v2/Provider";
import GiveRadio from "@shared/Radio/GiveRadio";
import { CustomTheme } from "@theme/v2/palette.interface";
import GiveMotionWrapper from "@shared/Motion/GiveMotionWrapper";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { showMessage } from "@common/Toast";

const approvalTypes = [
  {
    value: "auto-approval",
    title: "Auto-approval",
    subtitle:
      "Recommended for merchants categorized as 'Normal' risk. This allows a quicker and more efficient approval process for low-risk merchants.",
  },
  {
    value: "pre-approval",
    title: "Pre-approval",
    subtitle:
      "Recommended for merchants categorized as 'Restricted' or 'High' risk. This allows additional checks and balances before the final approval is granted.",
  },
];

type Props = {
  merchantId: number;
  defaultStatus?: string;
};

type ApprovalTypeInputs = {
  status: string;
};

const schema = Yup.object().shape({
  status: Yup.string(),
});

const ApproveMerchantModal = NiceModal.create(
  ({ merchantId, defaultStatus = "pre-approval" }: Props) => {
    const { open, onClose } = useNiceModal();
    const theme = useAppTheme();
    const queryClient = useQueryClient();

    const {
      updateUnderwriting: { isLoading, mutate },
    } = useUpdateUnderwriting(merchantId);

    const methods = useForm<ApprovalTypeInputs>({
      mode: "onSubmit",
      reValidateMode: "onChange",
      defaultValues: {
        status: defaultStatus,
      },
      resolver: yupResolver(schema),
    });

    const onConfirm: SubmitHandler<ApprovalTypeInputs> = async (data) => {
      mutate(data, {
        onSuccess: () => {
          queryClient.invalidateQueries("get-merchant-msp-status");
          queryClient.refetchQueries(QKEY_LIST_MERCHANT_STATS);
          queryClient.refetchQueries(QKEY_LIST_ACQUIRER_MERCHANTS);
          queryClient.refetchQueries(["get-merchant-preview", merchantId]);
          onClose();
        },
        onError: () => {
          queryClient.refetchQueries("get-merchant-preview");
          showMessage(
            "Error",
            "Merchant account approval failed. Ensure all required information is accurate and approved",
          );
        },
      });
    };

    const isValid = methods.formState.isValid;

    return (
      <GiveBaseModal
        open={open}
        title="Approve Account"
        width="543px"
        height="57%"
        onClose={onClose}
        buttons={
          <Stack gap="12px" flexDirection="row">
            <GiveButton
              onClick={onClose}
              label="Cancel"
              variant="ghost"
              size="large"
            />
            <GiveButton
              label="Confirm"
              variant="filled"
              size="large"
              disabled={!isValid || isLoading}
              onClick={methods.handleSubmit(onConfirm)}
            />
          </Stack>
        }
        closeIconProps={{
          bgColor: "solidWhite",
        }}
      >
        <Stack sx={{ overflowY: "hidden" }} gap="12px">
          <Controller
            control={methods.control}
            name="status"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box>
                <RadioGroup sx={{ gap: 2 }}>
                  {approvalTypes.map((option, idx) => (
                    <GiveMotionWrapper
                      key={option.title}
                      delay={(idx + 1) * 50}
                    >
                      <StyledBox
                        selected={option.value === value}
                        theme={theme}
                      >
                        <FormControlLabel
                          control={<GiveRadio />}
                          label={
                            <Stack gap="4px">
                              <GiveText
                                sx={{
                                  color: theme.palette.text.primary,
                                }}
                                variant="bodyS"
                              >
                                {option.title}
                              </GiveText>
                              <GiveText
                                sx={{
                                  color: theme.palette.text.secondary,
                                }}
                                variant="bodyS"
                              >
                                {option.subtitle}
                              </GiveText>
                            </Stack>
                          }
                          value={option.value}
                          onChange={onChange}
                          checked={value === option.value}
                          sx={{
                            "&.MuiFormControlLabel-root": {
                              margin: "0px",
                              gap: "12px",
                              alignItems: "flex-start",
                            },
                          }}
                        />
                      </StyledBox>
                    </GiveMotionWrapper>
                  ))}
                </RadioGroup>
                {error && (
                  <GiveText pt="12px" pl="12px" color={"error"}>
                    {error.message}
                  </GiveText>
                )}
              </Box>
            )}
          />
        </Stack>
      </GiveBaseModal>
    );
  },
);

export default ApproveMerchantModal;

const StyledBox = styled(Box)(
  ({ selected, theme }: { selected: boolean; theme: CustomTheme }) => ({
    padding: "16px",
    borderRadius: "8px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: selected
      ? theme.palette.border?.active
      : theme.palette.border?.secondary,
  }),
);
