import { useEffect, useMemo, useRef, useState } from "react";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { queryBuilder } from "@components/VirtualList/api";
import { items } from "@components/VirtualList/components/VirtualizedList";
import { selectQueryString } from "@redux/slices/search";
import { useAppSelector } from "@redux/hooks";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { DISPUTE_PREVIEW_PANEL } from "modals/modal_names";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useIterator } from "@common/Table/hooks";
import { selectSelectedMerchant } from "@redux/slices/merchantFilters";
import { useDisputesQueryString } from "./useDisputesQueryString";
import { useSearchParams } from "react-router-dom";

export const DISPUTES_QUERY_KEY = "get-disputes-list";

export const useDisputesTable = () => {
  const [params] = useSearchParams();

  useEffect(() => {
    const disputeID = params.get("id");
    if (disputeID) {
      NiceModal.show(DISPUTE_PREVIEW_PANEL, {
        id: disputeID,
        isFirst: true,
        isLast: true,
        setSelectedRow: onChangeSelectedRowItem,
        isLoading: isFetchingNextPage,
      });
    }
  }, []);
  const scrollToTop =
    useRef<(index?: number, align?: "center" | "start" | "end") => void>(null);
  const page = useRef(1);
  const sort = useRef("-createdAt");
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const searchQuery = useAppSelector((state) =>
    selectQueryString(state, DISPUTES_QUERY_KEY),
  );

  const { queryString } = useDisputesQueryString();
  const { isDisputeSidePanelEnabled } = useGetFeatureFlagValues();

  const selectedMerchant = useAppSelector((state) =>
    selectSelectedMerchant(state, DISPUTES_QUERY_KEY),
  );

  const selectedMerchantID = selectedMerchant?.accID;

  const { data, isFetchingNextPage, fetchNextPage, hasNextPage, isLoading } =
    useInfiniteQuery(
      [DISPUTES_QUERY_KEY, searchQuery, queryString, selectedMerchantID],
      queryBuilder({
        queryString,
        searchQuery,
        rowsPerPage: 20,
        path: "disputes",
        sorting: sort.current,
        merchantId: selectedMerchantID,
      }),
      {
        getNextPageParam: (lastPage: any) => {
          return lastPage.nextCursor;
        },
      },
    );

  const uiData = useMemo(
    () =>
      data?.pages?.[0].data
        ? data?.pages?.map((row: any) => row?.data).flat() ?? []
        : [],
    [data],
  );

  const modal = useModal(DISPUTE_PREVIEW_PANEL);

  const { selectedRowIdx, onChangeSelectedRowItem, setSelectedRowIdx } =
    useIterator({
      dataLen: uiData.length,
    });

  const handleOnClick = async () => {
    if (isFetchingNextPage || isLoading) return;

    items.clear();
    setLoading(true);
    const data = await queryClient.fetchQuery({
      queryKey: [DISPUTES_QUERY_KEY],
      queryFn: queryBuilder({
        queryString,
        rowsPerPage: 20,
        path: "disputes",
        sorting: sort.current,
      }),
    });

    queryClient.setQueryData([DISPUTES_QUERY_KEY], {
      pageParams: [undefined],
      pages: [data],
    });

    queryClient.removeQueries([DISPUTES_QUERY_KEY]);
    setLoading(false);
  };

  const handleOnSort = (newSort: any) => {
    if (scrollToTop.current) {
      scrollToTop.current();
    }
    sort.current = newSort;
    page.current = 1;

    handleOnClick();
  };

  const onEndReached = async () => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage({
        pageParam: ++page.current,
      }).then(() => {
        if (scrollToTop.current) {
          scrollToTop.current((data?.pages?.length ?? 1) * 20);
        }
      });
    }
  };

  const handleOpenSidePanel = ({ index }: { index: number }) => {
    if (isDisputeSidePanelEnabled) {
      setSelectedRowIdx(index);
    }
  };

  useEffect(() => {
    if (
      (modal.visible || (!modal.visible && selectedRowIdx >= 0)) &&
      uiData[selectedRowIdx]?.id
    ) {
      NiceModal.show(DISPUTE_PREVIEW_PANEL, {
        id: uiData[selectedRowIdx]?.id,
        isFirst: selectedRowIdx === 0,
        isLast: uiData[selectedRowIdx + 1] === undefined && !hasNextPage,
        setSelectedRow: onChangeSelectedRowItem,
        isLoading: isFetchingNextPage,
      });
    }
  }, [selectedRowIdx, modal.visible, isFetchingNextPage]);

  useEffect(() => {
    if (selectedRowIdx === uiData.length && hasNextPage) {
      onEndReached();
    }
  }, [selectedRowIdx, hasNextPage]);

  return {
    handleOnSort,
    uiData,
    scrollToTop,
    onEndReached,
    isFetchingNextPage,
    loading,
    isLoading,
    clickRow: handleOpenSidePanel,
    selectedIndex: selectedRowIdx,
  };
};
