import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { useMerchantDataPreview } from "@components/Merchants/MerchantPreview/hooks/useMerchantDataPreview";
import { useMasquerade, useWatchlist } from "@hooks/enterprise-api/merchants";
import { IButtonComponent } from "@shared/SidePanel/SidePanelAtoms";
import { checkPortals } from "@utils/routing";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { useState } from "react";
import ChangelogButton from "../components/ChangelogButton";
import ConversationsButton from "../components/ConversationsButton";
import { useNotifications } from "../WithRepository/Challenges/hooks/useNotifications";

type TMerchantDoublePanelNew = "merchantFile" | "agreement" | "challenges" | "";

type Props = {
  id: number;
  setSelectedRow?: (newIdx: string | number) => void;
};

export default function useMerchantSidePanel({ id, setSelectedRow }: Props) {
  const { open, onClose } = useNiceModal();
  const { isAcquirerPortal } = checkPortals();
  const { data, merchant, isLoading, isRefetching } = useMerchantDataPreview({
    id,
  });
  const { masqueradeHandler } = useMasquerade();
  const [doublePanel, setDoublePanel] = useState<TMerchantDoublePanelNew>("");
  const { addMerchantToWatchlist, removeMerchantFromWatchlist } =
    useWatchlist();
  const { isOpen, handleOpenConversationsModal } = useConversationsModal();
  const { closeNotifyMerchantModal } = useNotifications();

  const Buttons: IButtonComponent = {
    conversations: (
      <ConversationsButton
        isLoading={isLoading}
        totalUnread={3}
        isOpen={isOpen}
        handleOpenConversationsModal={handleOpenConversationsModal}
        isDoublePanel={!!doublePanel}
      />
    ),
    changelog: (
      <ChangelogButton
        handleOpenChangelogModal={() => void 0}
        isDoublePanel={!!doublePanel}
      />
    ),
  };

  const handleOpenMerchantFile = () => {
    setDoublePanel("merchantFile");
  };

  const handleOpenAgreementSnapshot = () => {
    setDoublePanel("agreement");
  };

  const handleLoadNextMerchant = () => {
    if (setSelectedRow) setSelectedRow("next");
  };

  const handleLoadPrevMerchant = () => {
    if (setSelectedRow) setSelectedRow("prev");
  };

  const renderButton = (e: keyof IButtonComponent) => Buttons[e];

  const handleControlMode = () => {
    masqueradeHandler(
      isAcquirerPortal ? "acquirer" : "provider",
      {
        ...merchant,
        nextRoute: "/merchant/welcome",
      },
      onClose,
    );
  };

  const handleAddToWatchList = () => {
    if (data.header.watchlist) {
      removeMerchantFromWatchlist(id, data.header.name);
    } else {
      addMerchantToWatchlist(id, data.header.name);
    }
  };

  const handleClose = () => {
    onClose();
    setDoublePanel("");
    closeNotifyMerchantModal();
  };

  return {
    data,
    merchant,
    isLoading,
    isRefetching,
    open,
    onClose: handleClose,
    renderButton,
    doublePanel,
    setDoublePanel,
    handlers: {
      handleOpenAgreementSnapshot,
      handleOpenMerchantFile,
      handleNext: handleLoadNextMerchant,
      handlePrevious: handleLoadPrevMerchant,
      handleControlMode,
      handleAddToWatchList,
    },
  };
}
