import NewReportView from "./NewReportView";
import { Dispatch, SetStateAction } from "react";
import { ContentViewTypes } from "../MatchReportModal";
import AddNewReportView from "./AddNewReportView";

interface IComponentProps {
  setContentView: Dispatch<SetStateAction<ContentViewTypes>>;
  contentView: ContentViewTypes;
}

const ModalContentView = ({ setContentView, contentView }: IComponentProps) => {
  const handleChangeView = (view: ContentViewTypes) => setContentView(view);

  if (contentView === ContentViewTypes.ADD_NEW_REPORT) {
    return <AddNewReportView />;
  }

  return <NewReportView handleChangeView={handleChangeView} />;
};

export default ModalContentView;
