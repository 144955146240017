import { Drawer, SwipeableDrawer } from "@mui/material";
import { IGiveSidePanelProps } from "@shared/SidePanel/GiveSidePanel";
import { ReactNode } from "react";

import { useAppTheme } from "@theme/v2/Provider";
import { getDrawerV2DefaultProps } from "@theme/v2/customStyles/customDrawerStyle";

interface PanelWrapperProps {
  isMobileView: boolean;
  isDoublePanelMode: boolean;
  width: number;
  children?: ReactNode;
  drawerProps: Omit<
    IGiveSidePanelProps,
    | "width"
    | "isSecondPanelOpen"
    | "containerProps"
    | "children"
    | "secondPanel"
  >;
}

const PanelWrapper = ({
  children,
  isMobileView,
  isDoublePanelMode,
  width,
  drawerProps,
}: PanelWrapperProps) => {
  const theme = useAppTheme();
  const { componentsProps = {}, PaperProps = {}, ...rest } = drawerProps || {};
  const {
    componentsProps: defaultComponentsProps,
    PaperProps: defaultPaperProps,
  } = getDrawerV2DefaultProps(theme.palette.mode);

  if (isMobileView) {
    return (
      <SwipeableDrawer
        disableDiscovery
        open={drawerProps.open}
        onClose={(e: any) =>
          drawerProps.onClose && drawerProps.onClose(e, "backdropClick")
        }
        onOpen={() => null}
        anchor="bottom"
        transitionDuration={300}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            height: "96%",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: theme.palette.primitive?.neutral[110],
              borderRadius: "10px",
            },
          },
        }}
      >
        {children}
      </SwipeableDrawer>
    );
  }

  return (
    <Drawer
      PaperProps={{
        sx: {
          flexDirection: "row",
          width: isDoublePanelMode ? width * 2 : width,
          ...defaultPaperProps.sx,
        },
        ...PaperProps,
      }}
      type="sidepanel"
      anchor="right"
      componentsProps={{
        ...defaultComponentsProps,
        ...componentsProps,
      }}
      {...rest}
    >
      {children}
    </Drawer>
  );
};
export default PanelWrapper;
