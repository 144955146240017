import { ChipOption } from "@common/TableFilters/Chip";
import { SelectorOption } from "@common/TableFilters/utils";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { RootState } from "@redux/store";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

type QueryKeyType = string; //TODO: they keys that BE accepts as query param when fetching data and using filters

export interface QueryObject {
  [key: QueryKeyType]:
    | Partial<SelectorOption>[]
    | string
    | number
    | boolean
    | any; // we have many types to expect and we can't list each one of them here
}
export interface FilterDate {
  startDate?: Date;
  endDate?: Date;
  val?: string | number | undefined | ChipOption;
  dateName: string;
  label?: string;
}

export type FilterDates = Record<string, FilterDate>;
export type TableFilterState = {
  queryParams?: QueryObject;
  queryString: string;
  customTotalProcessing?: {
    from?: number;
    to?: number;
  };
  filterDates?: FilterDates;
  quickFilter?: string | null;
  queryStringQuickFilter?: string;
};

const initialState: TableFilterState = {
  queryParams: undefined,
  queryString: "",
  filterDates: {},
  quickFilter: null,
  queryStringQuickFilter: "",
};

const tableFiltersSlice = createSlice({
  name: "tableFilters",
  initialState,
  reducers: {
    setFilters: (
      state: TableFilterState,
      action: PayloadAction<TableFilterState>,
    ) => {
      state.queryParams = action.payload.queryParams;
      state.queryString = action.payload.queryString;
    },
    resetFilters: (state: TableFilterState) => {
      state.queryParams = undefined;
      state.queryString = "";
      state.customTotalProcessing = undefined;
      state.filterDates = {};
    },
    setCustomTotalProcessing: (
      state: TableFilterState,
      action: PayloadAction<{ from?: number; to?: number }>,
    ) => {
      state.customTotalProcessing = {
        from: action.payload.from,
        to: action.payload.to,
      };
    },
    setFilterDates: (
      state: TableFilterState,
      action: PayloadAction<FilterDate>,
    ) => {
      const { dateName } = action.payload;
      if (!state.filterDates) {
        state.filterDates = {};
      }
      state.filterDates[dateName] = action.payload;
    },
    setQuickFilter: (
      state: TableFilterState,
      action: PayloadAction<string | null>,
    ) => {
      state.quickFilter = action.payload;
    },

    setQueryStringQuickFilter: (
      state: TableFilterState,
      action: PayloadAction<string>,
    ) => {
      state.queryStringQuickFilter = action.payload;
    },
  },
});

export const {
  setFilters,
  resetFilters,
  setCustomTotalProcessing,
  setFilterDates,
  setQuickFilter,
  setQueryStringQuickFilter,
} = tableFiltersSlice.actions;

export const getSelectedFiltersAmount = (state: RootState) => {
  const { queryParams } = state.tableFilters;
  let filtersAmount = 0;

  // Count queryParams excluding filterDates if queryParams has non-empty values
  if (queryParams) {
    filtersAmount += Object.entries(queryParams).filter(([key, value]) => {
      // Check if the key belongs to any of the key pairs to check separately
      for (const keyPair of keyPairsToCheckSeparately) {
        if (keyPair.includes(key)) {
          return false; // Skip keys that are part of the key pairs
        }
      }
      return _.isArray(value) ? !_.isEmpty(value) : value !== undefined;
    }).length;

    // Handle the special key pairs separately
    keyPairsToCheckSeparately.forEach((keyPair) => {
      // Check if any of the keys in the key pair exist
      if (keyPair.some((key) => key in queryParams)) {
        filtersAmount += 1; // Count as one if any key in the pair exists
      }
    });
  }
  return filtersAmount;
};

export const selectedQueries = (state: RootState) =>
  state.tableFilters.queryParams;

export const selectedQueryString = (state: RootState) =>
  state.tableFilters.queryString;

export const customTotalProcessing = (state: RootState) =>
  state.tableFilters.customTotalProcessing;

export const customFilterDates = (state: RootState) =>
  state.tableFilters.filterDates;

export const selectedQuickFilter = (state: RootState) =>
  state.tableFilters.quickFilter;

export const selectedQueryStringQuickFilter = (state: RootState) =>
  state.tableFilters.queryStringQuickFilter;

export default tableFiltersSlice.reducer;

const keyPairsToCheckSeparately = [
  ["ownerMembershipStatus", "statusDisplayName"],
];

export const useTableFilters = () => {
  const dispatch = useAppDispatch();

  const quickFilterSelector = createSelector(
    selectedQuickFilter,
    (quickFilter) => quickFilter,
  );
  const quickFilter = useAppSelector(quickFilterSelector);

  const queryStringQuickFilterSelector = createSelector(
    selectedQueryStringQuickFilter,
    (queryStringQuickFilter) => queryStringQuickFilter,
  );
  const queryStringQuickFilter = useAppSelector(queryStringQuickFilterSelector);

  const resetTableFilters = (all = false) => {
    dispatch(resetFilters());

    if (all) {
      dispatch(setQuickFilter(null));
      dispatch(setQueryStringQuickFilter(""));
    }

    if (quickFilter) {
      dispatch(
        setQueryStringQuickFilter(
          `(riskLevelLabelDisplayName:"${quickFilter}")`,
        ),
      );
    } else {
      dispatch(setQueryStringQuickFilter(""));
    }
  };

  const setQueryStringWithQuickFilter = (str: string) => {
    dispatch(setQueryStringQuickFilter(str));
  };

  const setQuickFilterValue = (str: string | null) => {
    dispatch(setQuickFilter(str));
  };

  const filtersAmount = useAppSelector(getSelectedFiltersAmount);

  return {
    quickFilter,
    queryStringQuickFilter,
    resetTableFilters,
    setQueryStringWithQuickFilter,
    setQuickFilterValue,
    filtersAmount,
  };
};
