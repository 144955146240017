import { Box, Grid, Stack } from "@mui/material";
import React, { Children, ReactNode } from "react";
import { SidePanelButton, SidePanelName } from "./SidePanelHeaderAtoms";
import { useSidePanelHeaderContext, useStyles } from "./SidePanelHeaderContext";
import { styled, useAppTheme } from "@theme/v2/Provider";
import ProgressBar from "./ProgressBar";
import ContextualMenu from "@shared/ContextualMenu/ContextualMenu";
import GiveText from "@shared/Text/GiveText";
import { TabsDataTypes } from "features/Merchants/MerchantSidePanel/AgreementAndSnapshot/agreements.types";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

interface Props {
  children: ReactNode | ReactNode[];
}

const SidePanelHeader = ({ children }: Props) => {
  const {
    actions,
    anchorEl,
    classes,
    onCloseMenu,
    isLoading = false,
  } = useSidePanelHeaderContext();
  const { isMobileView } = useCustomThemeV2();

  const childrenArr = Children.toArray(children);

  return (
    <Stack position="sticky" top="0px" zIndex={100}>
      <StyledBox>
        <Stack className={`${classes.container}`}>
          <Grid container alignItems="center" gap="8px">
            {childrenArr[0]}
          </Grid>
          <Stack className={`${classes.mainActionsContainer}`}>
            <Stack direction="row" gap="16px">
              {childrenArr[1]}
            </Stack>
          </Stack>
        </Stack>
        <ContextualMenu
          handleClose={onCloseMenu}
          anchorEl={anchorEl}
          color={isMobileView ? "primary" : "tertiary"}
          texture={isMobileView ? "solid" : "blurred"}
          options={actions}
        />
      </StyledBox>
      <ProgressBar isLoading={isLoading} />
    </Stack>
  );
};

export const SidePanelHeaderBase = ({
  children,
  leftItems,
  rightItems,
  tabsData,
}: {
  children?: ReactNode;
  leftItems: ReactNode;
  rightItems: ReactNode;
  tabsData?: TabsDataTypes[];
}) => {
  const classes = useStyles();
  const { palette } = useAppTheme();
  return (
    <Stack position="sticky" top="0px" zIndex={100}>
      <StyledBox>
        <Stack className={`${classes.container}`}>
          <Grid container alignItems="center" gap="8px">
            {leftItems}
          </Grid>
          <Stack className={`${classes.mainActionsContainer}`}>
            <Stack direction="row" gap="16px">
              {rightItems}
            </Stack>
          </Stack>
        </Stack>

        {children}
      </StyledBox>
      {tabsData && (
        <TabContainer>
          {tabsData?.map(({ label, isActive, onClick }) => {
            return (
              <CustomText
                key={label}
                onClick={onClick}
                color={isActive ? "primary" : "secondary"}
                sx={{
                  ...(isActive && {
                    borderBottom: `1px solid ${palette?.border?.active}`,
                  }),
                }}
              >
                {label}
              </CustomText>
            );
          })}
        </TabContainer>
      )}
    </Stack>
  );
};

const TabContainer = styled(Stack)(({ theme }) => ({
  paddingTop: "20px",
  borderBottom: `1px solid ${theme.palette?.border?.primary}`,
  backgroundColor: theme.palette?.surface?.["secondary-transparent"],
  backdropFilter: "blur(25px)",
  flexDirection: "row",
  alignItems: "baseline",
  margin: "0 16px",
  gap: "20px",
  overflowX: "auto",
  whiteSpace: "nowrap",
  scrollbarWidth: "none",
}));

const CustomText = styled(GiveText)(({ theme }) => ({
  paddingBottom: "16px",
  cursor: "pointer",
  whiteSpace: "nowrap",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  paddingBlock: "16px",
  paddingInline: "16px",
  borderBottom: `1px solid ${theme.palette?.border?.primary}`,
  backgroundColor: theme.palette?.surface?.["secondary-transparent"],
  backdropFilter: "blur(25px)",
}));

SidePanelHeader.Button = SidePanelButton;
SidePanelHeader.Name = SidePanelName;

export default SidePanelHeader;
