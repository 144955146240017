import { Stack } from "@mui/material";
import { useCart } from "@sections/PayBuilder/provider/CartContext";
import GiveText from "@shared/Text/GiveText";
import GiveTruncateText from "@shared/Text/GiveTruncateText";
import { styled } from "@theme/v2/Provider";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import NiceModal from "@ebay/nice-modal-react";
import { PUBLIC_PRODUCT_ITEM_MODAL } from "modals/modal_names";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { ProductItemType } from "@sections/PayBuilder/types";
import { Item_Layout } from "@sections/PayBuilder/provider/provider.type";
import { addSizeToImage } from "@utils/image.helpers";
import ImagePlaceholder from "@assets/svgIcons/storefront-placeholder.svg";

const OUT_STOCK_LABEL = "Out of stock";
type Props = {
  product: ProductItemType;
  displayStatus: "preview" | "published";
  itemLayout: Item_Layout;
};

const ProductCard: React.FC<Props> = ({
  product,
  displayStatus,
  itemLayout,
}) => {
  const { id, thumbnail, amount, title, in_stock, description } = product;

  const { isMobileView } = useCustomThemeV2();
  const isCardMode = itemLayout === "card";
  const height = isCardMode ? (isMobileView ? "132px" : "240px") : "80px";

  const { addToCart, getItemInCart } = useCart();
  const cartProduct = getItemInCart(id);
  const quantity = cartProduct?.quantity || 1;
  const existingProduct = !!cartProduct;

  const smallThumbnail = addSizeToImage(
    thumbnail || "",
    "small",
    ImagePlaceholder,
  );

  const handleProductClick = (productId: string) => {
    // if exist in cart add it
    if (existingProduct) {
      addToCart(product);
    } else {
      // else show the modal
      NiceModal.show(PUBLIC_PRODUCT_ITEM_MODAL, { product, addToCart });
    }
  };
  const isOutOfStock = in_stock == null;
  return (
    <StyledContainer
      onClick={() => handleProductClick(id)}
      direction={isCardMode ? "column" : "row-reverse"}
      itemLayout={itemLayout}
      displayStatus={displayStatus}
      existingProduct={existingProduct}
      quantity={quantity}
      isCardMode={isCardMode}
      sx={{
        cursor: "pointer",
        ...(isOutOfStock && {
          pointerEvents: "none",
          "& img": {
            filter: "grayscale(1)",
          },
        }),
      }}
    >
      {Boolean(thumbnail) && (
        <StyledImage
          alt={title}
          src={smallThumbnail}
          isCardMode={isCardMode}
          height={height}
        />
      )}
      <ContentWrapper>
        <GiveText variant="bodyS" color="primary" fontWeight="573">
          {title}
        </GiveText>
        <GiveTruncateText variant="bodyS" color="secondary" lineClamp={1}>
          {description}
        </GiveTruncateText>
        <Stack gap="12px" direction="row">
          <GiveText variant="bodyS" color="primary">
            From ${amount}
          </GiveText>
          {isOutOfStock && (
            <GiveText variant="bodyS" color="error">
              {OUT_STOCK_LABEL}
            </GiveText>
          )}
        </Stack>
      </ContentWrapper>
    </StyledContainer>
  );
};

export default ProductCard;

const StyledImage = styled("img", {
  shouldForwardProp: (prop) => prop !== "isCardMode",
})<{ isCardMode: boolean }>(({ isCardMode }) => ({
  borderRadius: "8px",
  width: isCardMode ? "100%" : "80px",
  objectFit: "cover",
  itemLayout: "block",
  flexShrink: 0,
}));

const StyledContainer = styled(Stack, {
  shouldForwardProp: (prop) =>
    prop !== "itemLayout" &&
    prop != "existingProduct" &&
    prop !== "quantity" &&
    prop !== "displayStatus" &&
    prop !== "isCardMode",
})<{
  itemLayout: string;
  existingProduct: boolean;
  quantity: number;
  displayStatus: string;
  isCardMode: boolean;
}>(
  ({
    itemLayout,
    existingProduct,
    quantity,
    displayStatus,
    isCardMode,
    theme,
  }) => ({
    borderRadius: "12px",
    padding: isCardMode ? "16px 16px 24px 16px" : "8px",
    border: `1px solid ${theme.palette.border?.primary}`,
    width: "100%",
    gap: "16px",
    position: "relative",
    ...(displayStatus === "preview" && {
      pointerEvents: "none",
    }),
    ...(existingProduct &&
      displayStatus === "published" && {
        "&:before": {
          content: `'${quantity}'`,
          position: "absolute",
          width: "22px",
          height: "22px",
          borderRadius: "30px",
          background: "#998A6B",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "14px",
          color: "white",
          top: "-11px",
          right: "-11px",
        },
      }),
  }),
);

const ContentWrapper = styled(Stack)({
  gap: "6px",
  flex: 1,
});
