import { Grid, Stack, styled } from "@mui/material";
import { TruncateText } from "@common/Text";
import {
  formatEIN,
  formatPrimaryAccountHolderAddress,
  formatSSN,
} from "@components/Merchants/MerchantPreview/hooks/useGetSectionItems";
import { Status } from "@components/Settings/Business/Status";
import { formatDateCustomRadio } from "@components/Analytics/SidePanels/CampaignSidePanel/components/TimeLineChartFilters";
import { formatUSPhoneNumber } from "@utils/helpers";
import { businessTypes } from "@common/BusinessProfileInputs/BusinessTypeSelect";
import { palette } from "@palette";
import { capitalizeFirstLetter } from "@common/Table/helpers";

type ProfileModalPreviewInfoProps = {
  selectedProfile: any;
};

function ProfileModalPreviewInfo({
  selectedProfile,
}: ProfileModalPreviewInfoProps) {
  const {
    legalName,
    taxID,
    statusName,
    contactPhone,
    address,
    businessType,
    ownershipType,
    createdAt,
    dba,
    tinType,
    ssn,
  } = selectedProfile || {};

  if (!selectedProfile?.id) return null;

  const keyVals = [
    {
      keyName: "Business Legal Name",
      value: legalName,
    },
    {
      keyName: "Doing Business As",
      value: dba,
    },
    {
      keyName: "Business Creation Date",
      value: formatDateCustomRadio(createdAt),
    },
    {
      keyName: "Business Type",
      value: businessTypes[businessType as keyof typeof businessTypes],
    },
    {
      keyName: tinType === "ssn" ? "SSN" : "EIN",
      value: tinType === "ssn" ? formatSSN(ssn) : formatEIN(taxID),
    },
    {
      keyName: "Business Ownership Type",
      value: capitalizeFirstLetter(ownershipType),
    },

    {
      keyName: "Business Phone Number",
      value: formatUSPhoneNumber(contactPhone),
    },
    {
      keyName: "Business Address",
      value: formatPrimaryAccountHolderAddress({
        ...address,
        line1: address?.address,
      }),
      sm: 12,
      lineClamp: 2,
    },
  ];
  return (
    <Container>
      <Stack alignItems="center" justifyContent="space-between" direction="row">
        <TruncateText lineClamp={1} lineHeight="16.8px">
          {legalName}
        </TruncateText>
        <Status state={statusName || "approved"} />
      </Stack>
      <Grid container>
        {keyVals.map((props, index) => (
          <KeyValue
            key={props.keyName}
            highlight={index % 2 === 0}
            {...props}
          />
        ))}
      </Grid>
    </Container>
  );
}

const Container = styled(Stack)(() => ({
  marginTop: "16px",
  padding: "16px",
  flexDirection: "column",
  alignItems: "stretch",
  gap: "16px",
  borderRadius: "8px",
  backgroundColor: palette.neutral.white,
  boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.10)",
}));

const KeyValue = ({
  keyName,
  value,
  highlight = false,
  ...rest
}: CustomStyleProps & {
  keyName: string;
  value: string;
  highlight?: boolean;
}) => {
  return (
    <Grid
      container
      sx={{
        borderRadius: "4px",
        padding: "4px 8px",
        backgroundColor: highlight ? palette.background.dimmedWhite : "inherit",
      }}
    >
      <TextElement {...rest}>{keyName}</TextElement>
      <TextElement {...rest} isValue>
        {value}
      </TextElement>
    </Grid>
  );
};

type CustomStyleProps = {
  sm?: number;
  lineClamp?: number;
};

const TextElement = ({
  isValue = false,
  children,
  sm = 6,
  lineClamp = 1,
}: CustomStyleProps & {
  isValue?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Grid
      item
      xs={12}
      sm={sm}
      sx={{ marginBottom: sm === 12 && !isValue ? "4px" : 0 }}
    >
      <StyledText lineClamp={lineClamp} isValue={isValue}>
        {children}
      </StyledText>
    </Grid>
  );
};

const StyledText = styled(TruncateText, {
  shouldForwardProp: (prop) => prop !== "isValue",
})<{ isValue: boolean }>(({ isValue }) => ({
  color: isValue ? palette.black[100] : palette.gray[300],
  fontWeight: isValue ? 400 : 350,
  lineHeight: "16.8px",
  minHeight: "16.8px",
}));

export default ProfileModalPreviewInfo;
