import React from "react";

type TProps = { fill: string; size?: number };

const ChartBarBox = ({ fill, size = 20 }: TProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1.25H15C17.0711 1.25 18.75 2.92893 18.75 5V15C18.75 17.0711 17.0711 18.75 15 18.75H5C2.92893 18.75 1.25 17.0711 1.25 15V5C1.25 2.92893 2.92893 1.25 5 1.25ZM0 5C0 2.23858 2.23858 0 5 0H15C17.7614 0 20 2.23858 20 5V15C20 17.7614 17.7614 20 15 20H5C2.23858 20 0 17.7614 0 15V5ZM6.875 11.25C6.875 10.9048 6.59518 10.625 6.25 10.625C5.90482 10.625 5.625 10.9048 5.625 11.25L5.625 16.25C5.625 16.5952 5.90482 16.875 6.25 16.875C6.59518 16.875 6.875 16.5952 6.875 16.25V11.25ZM10 4.375C10.3452 4.375 10.625 4.65482 10.625 5L10.625 16.25C10.625 16.5952 10.3452 16.875 10 16.875C9.65482 16.875 9.375 16.5952 9.375 16.25L9.375 5C9.375 4.65482 9.65482 4.375 10 4.375ZM14.375 8.75C14.375 8.40482 14.0952 8.125 13.75 8.125C13.4048 8.125 13.125 8.40482 13.125 8.75V16.25C13.125 16.5952 13.4048 16.875 13.75 16.875C14.0952 16.875 14.375 16.5952 14.375 16.25V8.75Z"
        fill={fill}
      />
    </svg>
  );
};

export default ChartBarBox;
