import { EnterpriseConfiguration } from "@components/AcquirerEnterprises/CreateEnterprise/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type SettingsStateTheme = {
  logos: {
    smallLogoSrc: string;
    wideLogoSrc: string;
  };
};

export interface SettingsState {
  theme: SettingsStateTheme;
  configuration: EnterpriseConfiguration;
}

interface AppState {
  enterpriseSettings: SettingsState;
}

const defaultConfiguration = {
  modify_merchant: false,
  merchant_underwriting: false,
  merchant_underwriting_statuses: false,
  agreement_signing: false,
  control_mode: false,
  b_profile_linking: false,
  bank_account_linking: false,
  manage_bank_account: false,
  money_transfers: false,
  risk_monitoring: false,
  merchant_triggers: false,
  risk_activity: false,
};

const initialState: SettingsState = {
  theme: {
    logos: {
      smallLogoSrc: "",
      wideLogoSrc: "",
    },
  },
  configuration: defaultConfiguration,
};

const enterpriseSettingsSlice = createSlice({
  name: "enterpriseSettings",
  initialState,
  reducers: {
    updateTheme: (
      state: SettingsState,
      action: PayloadAction<
        Partial<{ smallLogoSrc: string; wideLogoSrc: string }>
      >,
    ) => {
      state.theme.logos = {
        ...state.theme.logos,
        ...action.payload,
      };
    },

    updateEnterpriseConfiguration: (
      state: SettingsState,
      action: PayloadAction<EnterpriseConfiguration>,
    ) => {
      state.configuration = action.payload;
    },

    resetConfigurationToDefault: (state: SettingsState) => {
      state.configuration = defaultConfiguration;
    },
  },
});

export const {
  updateTheme,
  updateEnterpriseConfiguration,
  resetConfigurationToDefault,
} = enterpriseSettingsSlice.actions;

export const selectThemeLogos = (state: AppState) =>
  state.enterpriseSettings.theme.logos;

export const selectEnterpriseConfiguration = (state: AppState) =>
  state.enterpriseSettings.configuration;

export default enterpriseSettingsSlice.reducer;
