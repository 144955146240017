import { TPaymentTypes } from "./types";

export const paymentTypesArray: {
  name: TPaymentTypes;
  description: string;
  BE_label: string;
}[] = [
  {
    name: "One-time",
    description: "A one-time payment that never repeats",
    BE_label: "once",
  },
  {
    name: "Monthly",
    description: "A payment that repeats every month on the same date",
    BE_label: "monthly",
  },
  {
    name: "Quarterly",
    description: "A payment that repeats every 3 months on the same date",
    BE_label: "quarterly",
  },
  {
    name: "Yearly",
    description: "A payment that repeats every year on the same date",
    BE_label: "yearly",
  },
];
