import { BankAccountTagType } from "@common/Tag/BankAccountTag";
import { getRenamedStatus } from "@components/BankAccounts/utils";
import { BankAccount } from "@hooks/merchant-api/manage-money/useListBankAccounts";
import { format } from "date-fns";
import { upperFirst } from "lodash";
import {
  TAccountStatus,
  IParsedData,
  IMerchantBankAccount,
  TBusinessOwner,
  TMerchantDocument,
  TStatusReview,
  TPrimaryAccountHolder,
  TOFACLastCheckType,
  ownerMembershipTypes,
} from "../data.types";
import { toUnixDateFormat } from "@utils/date.helpers";
import { merchantClassificationParser } from "@components/Settings/BusinessDetails/components/MerchantInfoSection/utils/merchantInfoParser";
import { getSubString } from "@utils/index";

export const parsePhoneNumber = (number?: string) => {
  if (!number) return "";
  return number.replace(/[\s+]/g, "");
};

export const merchantDateFormatter = (
  formatter: string,
  date?: number | string,
) => (date ? format(new Date(+date * 1000), formatter) : "");

export const computeAgeOfBusiness = (
  businessOpenedAt?: number | string,
): string => {
  if (
    !businessOpenedAt ||
    !["number", "string"].includes(typeof businessOpenedAt)
  )
    return "";

  const normalizedDate =
    typeof businessOpenedAt === "string"
      ? toUnixDateFormat(new Date(businessOpenedAt))
      : businessOpenedAt;

  const timestampInSeconds = Math.floor(Date.now() / 1000);
  const timeDifferenceInSeconds = timestampInSeconds - normalizedDate;
  const yearsPassed = (
    timeDifferenceInSeconds /
    (60 * 60 * 24 * 365.25)
  ).toFixed(0);

  const years = parseInt(yearsPassed);
  return Number.isNaN(years) || !years ? "" : yearsPassed;
};

const MSPMessageErrorMapper: any = {
  is_required: "is required",
  must_be_approved: "must be approved",
  must_be_public_ownership: "must be public ownership",
};

export const merchantDataParser = (
  data: any,
  accountID?: number,
): IParsedData => {
  const header = {
    name: data?.merchant?.name,
    hasMID: data?.merchant?.hasMID,
    watchlist: data?.merchant?.watchlist,
    imageURL: data?.merchant?.imageURL,
    description: data?.merchant?.description,
    accountStatus: accountStatusParser({ data }),
    businessProfileStatus: accountStatusParser({ data, isLegalEntity: true }),
  };

  const stats = {
    transactions: data?.stats?.transactionsCount,
    balance: data?.stats?.availableBalance / 100 || 0,
    totalRaised:
      (data?.stats?.totalProcessed || data?.stats?.purchasesVolume) / 100 || 0,
    pendingMerchants: data?.stats?.pendingMerchants || 0,
    totalMerchants: data?.stats?.totalMerchants || 0,
    totalGenerated: data?.stats?.totalGenerated / 100 || 0,
    sumGenerated: data?.stats?.sumGenerated / 100 || 0,
    refunds: data?.stats?.refundsCount || 0,
    chargebacks: data?.stats?.chargebacksCount || 0,
  };

  const merchantInfo = merchantInfoParser(data?.merchant);

  const status: TStatusReview = {
    accID: data?.merchant?.accID,
    instant: data?.merchant?.statusDisplayName,
    parentAccID: data?.merchant?.parentAccID,
    underwriterSetByAcquirer: data?.merchant?.underwriterSetByAcquirer,
    receivedTransaction: data?.merchant?.receivedTransaction,
    documentsUploaded: data?.merchant?.documentsUploaded,
    process: data?.merchant?.canProcessMoney,
    transfer: !data?.merchant?.canTransferMoney
      ? false
      : data?.merchant?.transfersEnabled,
    level: data?.merchant?.level,
    verifiedEmail: Boolean(data?.merchant?.owner),
    assignee: data?.merchant?.underwriterID || null,
    hasMID: data?.merchant?.hasMID,
    statusName: data?.merchant?.statusName,
    statusUpdatedByID: data?.merchant?.statusUpdatedByID,
    statusUpdatedAt: merchantDateFormatter(
      "dd LLLL yyyy",
      data?.merchant?.statusUpdatedAt,
    ),
    approvedByName: data?.merchant?.approvedByName,
    approvedBy: data?.merchant?.approvedBy,
    approvedAt: merchantDateFormatter(
      "dd LLLL yyyy",
      data?.merchant?.approvedAt,
    ),
    underwriterImageURL: data?.merchant?.underwriterImageURL,
    underwriterName: data?.merchant?.underwriterName,
    underwriterEmail: data?.merchant?.underwriterEmail,
    approvedAgreement:
      data?.merchant?.msaHadAgreed &&
      data?.merchant?.msaLastAcceptedVersion === data?.aquirerTcVersion,
    isOFACConfirmedMatch:
      data?.entityOfac?.lastCheckStatusName === "confirmed_match" ||
      data?.ownerOfac?.lastCheckStatusName === "confirmed_match" ||
      data?.principals?.some(
        (principal: any) =>
          principal.ofac?.lastCheckStatusName === "confirmed_match",
      ) ||
      false,
    approvedOwner: data?.merchant?.owner?.statusName === "approved",
    allowedToTransfer: data?.merchant?.submerchantAllowedToTransfer || false,
    hasApprovedBankAccount:
      data?.merchant?.hasApprovedBankAccount ||
      data?.merchant?.linkedBankAccount?.status == "approved" ||
      false,
    hasApprovedLegalEntity: data?.merchant?.hasApprovedLegalEntity || false,
    underwritingScorePercentage: data?.merchant?.underwritingScorePercentage,
    statusUpdatedByName: data?.merchant?.statusUpdatedByName,
    approvedByImageUrl: data?.merchant?.approvedByImageUrl,
    statusUpdatedByEmail: data?.merchant?.statusUpdatedByEmail,
    statusUpdatedByImageURL: data?.merchant?.statusUpdatedByImageUrl,
    sponsorStatusName: data?.merchant?.sponsorStatusName,
    sponsorStatusLastUpdatedAt: merchantDateFormatter(
      "dd LLLL yyyy",
      data?.merchant?.sponsorStatusLastUpdatedAt,
    ),
    sponsorStatusLastUpdatedById: data?.merchant?.sponsorStatusLastUpdatedById,
    sponsorStatusLastUpdatedByImageURL:
      data?.merchant?.sponsorStatusLastUpdatedByImageURL,
    sponsorStatusLastUpdatedByEmail:
      data?.merchant?.sponsorStatusLastUpdatedByEmail,
    sponsorStatusReason: getSubString(
      ":",
      "",
      data?.merchant?.sponsorStatusReason,
    ),
    elevatedRiskReason: data?.merchant?.elevatedRiskReason,
    sponsorStatusLastUpdatedByName:
      data?.merchant?.sponsorStatusLastUpdatedByName?.trim(),
    sponsorThreadID: data?.merchant?.sponsorThreadID,
    merchantRiskStatus: data?.merchant?.merchantRiskStatus,
    lastInternalApprovalType: data?.merchant?.lastInternalApprovalType,
  };

  const bankAccountList = merchantBankAccountsParser(data?.bankAccounts);

  const bankAccountSettings = {
    allowBankAccounts: data?.merchant?.allowAddingBankAccounts,
    allowTransfers: data?.merchant?.submerchantAllowedToTransfer,
    linkedAccountID: data?.merchant?.linkedBankAccount?.id || null,
    isLinkAccount: Boolean(data?.merchant?.linkedBankAccount?.id),
  };

  const primaryAccountHolder = primaryAccountHolderParser({
    data: data?.merchant?.owner,
    accountID: accountID,
    ownerIDProofUploaded: data?.documents?.some(
      (doc: TMerchantDocument) => doc?.attTypeName === "account_owner",
    ),
    ofac: data?.merchant?.ownerOfac,
    ownerMembership: data?.merchant?.ownerMembership as ownerMembershipTypes,
  });

  const invitation = {
    inviteSent: Boolean(data?.merchant?.ownerMembership?.inviteLastSentAt),
    emailIsVerified: data?.merchant?.owner?.emailIsVerified,
    inviteId: data?.merchant?.ownerMembership?.inviteId,
    pendingEmail: data?.merchant?.owner?.pendingEmail,
    inviteStatus: data?.merchant?.ownerMembership?.memberStatus,
    noOwner: !data?.merchant?.owner,
    lastSentAt: data?.merchant?.ownerMembership?.inviteLastSentAt,
    sentEmailCount: data?.merchant?.ownerMembership?.inviteNumSent,
    cooldownEndsAt: data?.merchant?.ownerMembership?.cooldownEndsAt,
  };

  const businessProfile = businessProfileParser(
    data?.legalEntity,
    data?.merchant,
  );

  const businessAddress = businessAddressParser(
    data?.legalEntity?.address,
    data?.legalEntity?.id,
  );

  const documents = data?.documents || [];

  const fees = feesParser(data?.merchant);
  const parentFees = feesParser(data?.parent);

  const businessOwnersList = merchantBusinessOwnersParser(data?.principals);

  const categoryCodes = categoryCodesParser(
    data?.merchant?.allowedCategoryCodes,
  );
  const parentCategories = categoryCodesParser(
    data?.parent?.allowedCategoryCodes,
  );

  const merchantAgreement = merchantAgreementParser({
    ...data?.merchant,
    aquirerTcVersion: data?.aquirerTcVersion,
  });

  const riskProfile = {
    level: data?.merchant?.riskLevelLabel || "low",
    profileID: data?.merchant?.riskProfileID,
    riskStatusName: data?.merchant?.mrpRiskStatusName || "routine_monitoring",
  };

  const ownerId = data?.merchant?.owner?.accID;

  const mspStatus = data?.mspStatus?.map(({ Error, Name }: any) => ({
    field: Name?.split(".").join(" "),
    errorMessage: MSPMessageErrorMapper[Error],
  }));

  const msp = {
    canSyncMSPData: data?.merchant?.canSyncMSPData,
    lastMSPSyncAt: data?.merchant?.lastMSPSyncAt,
    displayMSPMessage: !!data?.merchant?.statusDisplayMessage,
  };

  return {
    header,
    stats,
    merchantInfo,
    status,
    primaryAccountHolder,
    bankAccountList,
    businessProfile,
    businessAddress,
    bankAccountSettings,
    documents,
    fees,
    parentFees,
    businessOwnersList,
    invitation,
    categoryCodes,
    parentCategories,
    merchantAgreement,
    riskProfile,
    ownerId,
    mspStatus,
    msp,
  };
};

const accountStatusParser = ({
  data,
  isLegalEntity = false,
}: {
  data: any;
  isLegalEntity?: boolean;
}): TAccountStatus => {
  if (!isLegalEntity) {
    return {
      status: data?.merchant?.statusName,
      statusDisplayName: data?.merchant?.statusDisplayName?.toLowerCase(),
      statusUpdatedAt: data?.merchant?.statusUpdatedAt,
      statusUpdatedByName: data?.merchant?.statusUpdatedByName,
      statusUpdatedByEmail: data?.merchant?.statusUpdatedByEmail,
    };
  }

  const getLegalEntityStatus = (status: string) => {
    switch (status) {
      case "approved":
        return {
          statusUpdatedAt: data?.legalEntity?.approvedAt,
          statusUpdatedByName: data?.legalEntity?.approvedByName,
          statusUpdatedByEmail: data?.legalEntity?.approvedByEmail,
        };
      case "declined":
        return {
          statusUpdatedAt: data?.legalEntity?.declinedAt,
          statusUpdatedByName: data?.legalEntity?.declinedByName,
          statusUpdatedByEmail: data?.legalEntity?.declinedByEmail,
        };
      case "suspended":
        return {
          statusUpdatedAt: data?.legalEntity?.suspendedAt,
          statusUpdatedByName: data?.legalEntity?.suspendedByName,
          statusUpdatedByEmail: data?.legalEntity?.suspendedByEmail,
        };
      case "ready_for_verification":
        return {
          statusUpdatedAt: null,
          statusUpdatedByName: null,
          statusUpdatedByEmail: null,
          readyForVerificationAt: data?.legalEntity?.readyForVerificationAt,
        };
      default:
        return {
          statusUpdatedAt: null,
          statusUpdatedByName: null,
          statusUpdatedByEmail: null,
        };
    }
  };

  return {
    status: data?.legalEntity?.statusName,
    statusDisplayName: data?.legalEntity?.statusDisplayName,
    ...getLegalEntityStatus(data?.legalEntity?.statusName),
  };
};

export const merchantBankAccountsParser = (
  bankAccounts: BankAccount[],
  enterprise?: boolean,
) => {
  return bankAccounts?.map((bankAccount) => ({
    id: +bankAccount.id,
    bankName: bankAccount.bankName,
    status: getRenamedStatus(bankAccount.status) as BankAccountTagType,
    enterprise: enterprise || Boolean(bankAccount.enterprise),
    plaid: bankAccount.createdWithPlaid,
    created: merchantDateFormatter("MM / dd / yyyy", bankAccount.createdAt),
    type: upperFirst(bankAccount.type),
    name: bankAccount.name,
    authPlaidInfo: bankAccount.authPlaidInfo,
    routingNumber: bankAccount.routingNumber,
    accountNumber: `•••• ${bankAccount.numberLast4}`,
    notes: bankAccount.notes,
    isLinked: bankAccount.isLinked,
    approvedByID: bankAccount.approvedByID,
    approvedByName: bankAccount.approvedByName,
    approvedByEmail: bankAccount.approvedByEmail,
    approvedAt: bankAccount.approvedAt,
    declinedByID: bankAccount.declinedByID,
    declinedByName: bankAccount.declinedByName,
    declinedByEmail: bankAccount.declinedByEmail,
    declinedAt: bankAccount.declinedAt,
    createdAt: bankAccount.createdAt,
    updatedAt: bankAccount.updatedAt,
    deletedAt: bankAccount.deletedAt,
  })) as IMerchantBankAccount[];
};

export const merchantBusinessOwnersParser = (principals: any[]) => {
  return principals?.map((principal: any) => ({
    createdAt: principal.createdAt,
    id: principal.id,
    firstName: principal.firstName,
    lastName: principal.lastName,
    pepStatusName: principal?.pepStatusName,
    email: principal.email,
    pepStatusSetByUnderwriter: principal?.pepStatusSetByUnderwriter,
    isNotUSResident: !!principal?.citizenship,
    citizenship: principal?.citizenship || "",
    isNotResidentInCitizenshipCountry: !!principal?.countryOfResidence,
    countryOfResidence: principal?.countryOfResidence || "",
    manualPepStatus: principal.manualPepStatus,
    ownership: principal.ownershipPercentage.toString(),
    tinType: principal?.tinType || ("ssn" as "ein" | "ssn"),
    ofac: {
      lastCheckAt: principal?.lastCheckAt,
      lastCheckStatusName: principal?.lastCheckStatusName,
    },
    ...(principal?.tinType === "ein"
      ? {
          ein: `xxxxx${principal.taxIDNumberLast4}`,
        }
      : {
          ssn: `xxxxx${principal.taxIDNumberLast4}`,
        }),
    dob: new Date(principal.dateOfBirth * 1000),
    phone: `+${principal.phoneNumber}`,
    isBusinessAddress: principal.useEntityGeoAddress,
    ...(!principal.useEntityGeoAddress && {
      address: {
        name: `${principal.firstName} ${principal.lastName}`,
        line1: principal.address?.line1 || "",
        line2: "",
        city: principal.address?.city || "",
        state: principal.address?.state || "",
        zip: principal.address?.zip || "",
        country: principal.address?.country || "US",
      },
    }),
  })) as TBusinessOwner[];
};

export const businessProfileParser = (data: any, merchant?: any) => {
  const yearsPassed = computeAgeOfBusiness(data?.businessOpenedAt);

  return {
    id: data?.id,
    isController: data?.canEdit || false,
    isBPLinked:
      data?.canEdit && typeof data?.canEdit === "boolean"
        ? !data?.canEdit
        : false,
    businessType: data?.type?.name,
    ownershipType: data?.ownershipType,
    legalName: data?.name,
    taxID: data?.tinType === "ein" ? data?.taxIDNumber || "" : "",
    ssn: data?.tinType === "ssn" ? data?.taxIDNumber || "" : "",
    tinType: data?.tinType || "ein",
    dba: data?.doingBusinessAs,
    contactPhone: data?.phoneNumber || "",
    ageOfBusiness:
      data?.businessOpenedAt && Number(yearsPassed) > 0
        ? yearsPassed
        : data?.yearsInBusiness || "",
    businessPurpose: data?.businessPurpose,
    statusName: data?.statusName,
    statusDisplayName: data?.statusDisplayName,
    approvedAt: merchantDateFormatter("MM/dd/yyyy", data?.approvedAt),
    approvedByName: data?.approvedByName,
    approvedByEmail: data?.approvedByEmail,
    declinedAt: merchantDateFormatter("MM/dd/yyyy", data?.declinedAt),
    createdAt: merchantDateFormatter("MM/dd/yyyy HH:mm:ss", data?.createdAt),
    declinedByName: data?.declinedByName,
    declinedByEmail: data?.declinedByEmail,
    readyForVerificationAt: merchantDateFormatter(
      "MM/dd/yyyy HH:mm:ss",
      data?.readyForVerificationAt,
    ),
    businessOpenedAt: merchantDateFormatter(
      "MM/dd/yyyy",
      data?.businessOpenedAt,
    ),
    suspendedByEmail: data?.suspendedByEmail,
    suspendedByName: data?.suspendedByName,
    suspendedAt: merchantDateFormatter("MM/dd/yyyy", data?.suspendedAt),
    statusDisplayMessage: data?.statusDisplayMessage,
    movedPendingByName: data?.movedPendingByName,
    movedPendingAt: merchantDateFormatter("MM/dd/yyyy", data?.movedPendingAt),
    movedPendingByEmail: data?.movedPendingByEmail,
    reason: data?.reason,
    ofac: {
      lastCheckAt: data?.ofac?.lastCheckAt,
      lastCheckID: data?.ofac?.lastCheckID,
      lastCheckStatusName: data?.ofac?.lastCheckStatusName || "clear",
      nextCheckAt: data?.ofac?.nextCheckAt,
    },
  };
};

export const businessAddressParser = (data: any, id?: number) => {
  return {
    id: id?.toString(),
    country: data?.country || "US",
    zip: data?.zip,
    state: data?.state || "",
    city: data?.city,
    address: data?.line1,
  };
};

export const feesParser = (data: any) => {
  return {
    creditCardFee: {
      value: data?.creditCardFees ?? 0,
      baselinePercent: data?.feeConfig?.creditCardFee?.baselinePercent || 0,
      baselineFixed: data?.feeConfig?.creditCardFee?.baselineFixed || 0,
      adjustmentPercent: data?.feeConfig?.creditCardFee?.adjustmentPercent || 0,
      adjustmentFixed: data?.feeConfig?.creditCardFee?.adjustmentFixed || 0,
    },
    debitCardFee: {
      value: data?.debitCardFees ?? 0,
      baselinePercent: data?.feeConfig?.debitCardFee?.baselinePercent || 0,
      baselineFixed: data?.feeConfig?.debitCardFee?.baselineFixed || 0,
      adjustmentPercent: data?.feeConfig?.debitCardFee?.adjustmentPercent || 0,
      adjustmentFixed: data?.feeConfig?.debitCardFee?.adjustmentFixed || 0,
    },
    amexCreditCardFee: {
      value: data?.amexCreditCardFees ?? 0,
      baselinePercent: data?.feeConfig?.amexCreditCardFee?.baselinePercent || 0,
      baselineFixed: data?.feeConfig?.amexCreditCardFee?.baselineFixed || 0,
      adjustmentPercent:
        data?.feeConfig?.amexCreditCardFee?.adjustmentPercent || 0,
      adjustmentFixed: data?.feeConfig?.amexCreditCardFee?.adjustmentFixed || 0,
    },
  };
};

const categoryCodesParser = (data: any[]) => {
  return data?.map((category: any) => ({
    id: category?.categoryCodes?.id,
    code: category?.categoryCodes?.code,
    title: category?.categoryCodes?.title,
  }));
};

export const merchantAgreementParser = (data: any) => {
  const ownerSignedAgreement = data?.msaLastAcceptedBy === data?.owner?.accID;

  let signatureData;
  if (ownerSignedAgreement) {
    signatureData = {
      signatureURL: data?.msaLastAcceptedBySignatureURL,
      signedBy: `${data?.owner?.firstName} ${data?.owner?.lastName}`,
    };
  } else {
    signatureData = {
      signatureURL: data?.msaLastAcceptedBySignatureURL,
      signedBy: `${data?.msaLastAcceptedByFirstName || ""} ${
        data?.msaLastAcceptedByLastName
      }`,
    };
  }

  return {
    msaRefundPolicy: data?.msaRefundPolicy,
    msaPCICompliant: data?.msaPCICompliant,
    msaPreviousTermination: data?.msaPreviousTermination,
    msaPreviousTerminationProcessorName:
      data?.msaPreviousTerminationProcessorName,
    msaPreviousTerminationReason: data?.msaPreviousTerminationReason,
    msaPreviousTerminationAt: data?.msaPreviousTerminationAt,
    msaHadAgreed: data?.msaHadAgreed,
    tcVersion: data?.aquirerTcVersion || data?.msaVersion,
    msaLastAcceptedAt: data?.msaLastAcceptedAt,
    msaLastAcceptedVersion: data?.msaLastAcceptedVersion,
    approvedPAH: data?.owner?.statusName === "approved",
    ...signatureData,
  };
};

export const merchantInfoParser = (data: any) => {
  return {
    averageTicketAmount: String(data?.averageTicketAmount / 100 || 0),
    highTicketAmount: String(data?.highTicketAmount / 100 || 0),
    estimatedAnnualRevenue: String(
      data?.annualCreditCardSalesVolume / 100 || 0,
    ),
    riskLevelLabel: data?.riskLevelLabel,
    serviceCountriesOutUSCanada: data?.serviceCountriesOutUSCanada,
    underwritingScorePercentage: data?.underwritingScorePercentage,
    countriesServicedOutside: data?.countriesServicedOutside,
    createdAt: merchantDateFormatter("MM/dd/yyyy", data?.createdAt),
    merchantName: data?.name,
    merchantID: data?.accID,
    categoryCodeName: data?.categoryCodeTitle,
    category: data?.categoryCode,
    categoryCodeID: data?.categoryCodeID,
    merchantSlug: data?.slug,
    parentSlug: data?.parentSlug,
    websiteURL: data?.websiteURL?.replace("https://", ""),
    billingDescriptor: data?.billingDescriptor,
    servicePhoneNumber: parsePhoneNumber(data?.servicePhoneNumber),
    businessType: data?.legalEntity?.type,
    enterprise: data?.parentName,
    enterpriseImageUrl: data?.parentImageURL,
    enterpriseID: data?.parentAccID,
    description: data?.description,
    principalUploadedIdentityProof: data?.principalUploadedIdentityProof,
    businessPurpose: data?.legalEntity?.businessPurpose, // TODO: to be moved from legalEntity to merchant on BE
    signupTypeDisplayName: data?.signupTypeDisplayName,
    classification: merchantClassificationParser(data),
    classCustomDescription: data?.classCustomDescription,
    billingDescriptorPrefix: data?.billingDescriptorPrefix,
    merchantRiskStatus: data?.merchantRiskStatus,
  };
};

export const primaryAccountHolderParser = ({
  data,
  accountID,
  ownerIDProofUploaded,
  ofac,
  ownerMembership,
}: {
  data: any;
  accountID?: number;
  ownerIDProofUploaded?: boolean;
  ofac?: TOFACLastCheckType;
  ownerMembership?: ownerMembershipTypes;
}): TPrimaryAccountHolder => {
  return {
    id: data?.accID || 0,
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    owner: data?.owner || data,
    statusName: data?.statusName,
    IDProofUploaded: false,
    selfieUploaded: false,
    email: data?.pendingEmail ? data?.pendingEmail : data?.email,
    phoneNumber: data?.phoneNumber || "",
    isNotUSResident: !!data?.citizenship,
    ofac: ofac || {
      lastCheckAt: 0,
      lastCheckID: 0,
      lastCheckStatusName: "clear",
      nextCheckAt: 0,
    },
    citizenship: data?.citizenship || "",
    isNotResidentInCitizenshipCountry: !!data?.countryOfResidence,
    countryOfResidence: data?.countryOfResidence || "",
    isOwner: (data?.owner && accountID === data?.accID) || false,
    dateOfBirth: data?.dateOfBirth || "",
    ssnLast4: data?.ssnLast4 || "",
    ownerIDProofUploaded: ownerIDProofUploaded || false,
    inviteStatusDisplayName: ownerMembership?.inviteStatusDisplayName,
    inviteStatusName: ownerMembership?.inviteStatusName,
    memberStatus: ownerMembership?.memberStatus,
    address: data?.billingAddress || {
      country: "US",
      city: "",
      line1: "",
      state: "",
      zip: "",
    },
  };
};

export const ofacArraySorter = (arr: { ofac: TOFACLastCheckType }[]) => {
  const sortOrder = {
    confirmed_match: 1,
    possible_match: 2,
    manually_cleared: 3,
    clear: 4,
  };

  return [...arr].sort(
    (a, b) =>
      sortOrder[a?.ofac?.lastCheckStatusName] -
      sortOrder[b?.ofac?.lastCheckStatusName],
  );
};
