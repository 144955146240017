import React from "react";

const VisaIcon = ({
  width,
  height,
}: {
  width?: number | string;
  height?: number | string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="39" height="25" rx="3.5" fill="white" />
      <rect x="0.5" y="0.5" width="39" height="25" rx="3.5" stroke="#E6E6E3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5286 17.8331H9.94203L8.00241 10.2157C7.91034 9.86527 7.71487 9.55549 7.42733 9.40949C6.70974 9.04259 5.91901 8.75059 5.0564 8.60332V8.31005H9.22318C9.79826 8.31005 10.2296 8.75059 10.3015 9.26222L11.3078 14.7569L13.8932 8.31005H16.4079L12.5286 17.8331ZM17.8459 17.833H15.4031L17.4146 8.30997H19.8574L17.8459 17.833ZM23.0176 10.9483C23.0894 10.4353 23.5207 10.1421 24.0239 10.1421C24.8147 10.0684 25.676 10.2157 26.3949 10.5813L26.8262 8.53099C26.1073 8.23772 25.3166 8.09045 24.599 8.09045C22.2281 8.09045 20.5029 9.40954 20.5029 11.2403C20.5029 12.633 21.7249 13.3642 22.5875 13.8048C23.5207 14.2441 23.8802 14.5373 23.8083 14.9766C23.8083 15.6355 23.0894 15.9288 22.3719 15.9288C21.5092 15.9288 20.6466 15.7091 19.8572 15.3422L19.4258 17.3939C20.2885 17.7595 21.2217 17.9068 22.0843 17.9068C24.7428 17.9791 26.3949 16.6613 26.3949 14.6833C26.3949 12.1924 23.0176 12.0464 23.0176 10.9483V10.9483ZM34.9436 17.833L33.004 8.30997H30.9206C30.4893 8.30997 30.058 8.60324 29.9142 9.04251L26.3225 17.833H28.8372L29.3392 16.4416H32.4289L32.7165 17.833H34.9436ZM31.2808 10.8746L31.9984 14.4636H29.9869L31.2808 10.8746Z"
        fill="#172B85"
      />
    </svg>
  );
};

export default VisaIcon;
