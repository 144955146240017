import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { NEW_ACTION_DENY_MESSAGE } from "@constants/permissions";
import GiveButton from "@shared/Button/GiveButton";
import { ArrowRight } from "@phosphor-icons/react";
import { useAppTheme } from "@theme/v2/Provider";
import { useMerchantSidePanelContext } from "features/Merchants/MerchantSidePanel/Provider/MerchantSidePanelProvider";
import { setOpeningOptions } from "@redux/slices/merchantPreview";
import { useAppDispatch } from "@redux/hooks";

interface IComponentProps {
  isDisabled: boolean;
}

const RiskMonitorButton = ({ isDisabled }: IComponentProps) => {
  const { palette } = useAppTheme();
  const { setOpenOldPanel } = useMerchantSidePanelContext();
  const dispatch = useAppDispatch();
  const handleManageRiskProfile = () => {
    setOpenOldPanel && setOpenOldPanel(true);
    dispatch(setOpeningOptions({  riskprofile: true }));
  };

  return (
    <GiveTooltip
      disableFocusListener={!isDisabled}
      disableHoverListener={!isDisabled}
      disableTouchListener={!isDisabled}
      color="default"
      title={NEW_ACTION_DENY_MESSAGE}
      alignment="flex-end"
    >
      <GiveButton
        label="Risk Monitor"
        sx={{
          borderRadius: "8px !important",
          border: "none",
        }}
        disabled={isDisabled}
        onClick={handleManageRiskProfile}
        endIcon={
          <ArrowRight size="18px" fill={palette.primitive?.neutral[0]} />
        }
      />
    </GiveTooltip>
  );
};

export default RiskMonitorButton;
