import * as React from "react";
import { styled } from "@mui/material/styles";
import { CloseIcon, SearchIcon } from "@assets/rebrandIcons";
import { palette } from "@theme/colors";
import { StyledIconButton } from "../IconButton";
import {
  Box,
  CircularProgress,
  Input,
  ListItem,
  useAutocomplete,
} from "@mui/material";
import { useTargetRef } from "@hooks/common/useObserveHTMLNode";

const StyledListItem = styled(ListItem)(
  ({ selected }: { selected: boolean }) => ({
    width: "calc( 100% - 8px)",
    cursor: selected ? "default" : "pointer",
    padding: "5px 8px",
    margin: 0,
    "&:last-of-type": {
      marginBottom: "8px",
    },
    "&:first-of-type": {
      marginTop: "8px",
    },
    marginLeft: "4px",
    borderRadius: "4px",
    backgroundColor: "#fff !important",
    "&:hover": {
      backgroundColor: selected ? "#fff !important" : "#ECECE9 !important",
    },
  }),
);

const Root = styled("div")(
  () => `
  color: "rgba(0,0,0,.85)";
  font-size: 14px;
`,
);

const InputWrapper = styled("div")(
  ({ focused }: { focused: boolean }) => `
  width: 300px;
  border: 1px solid #d9d9d9;
  background-color:  ${focused ? "#fff" : "#fbfbfb"};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;


  & input {
    background-color:  ${focused ? "#fff" : "#fbfbfb"};
    color:  rgba(0,0,0,.85);
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
  & .MuiInputBase-root{
    padding: 0;
    border:unset;
  }
  & .MuiInputBase-root.Mui-focused,
  & .MuiInputBase-root:hover {
      border: unset !important;
  }
`,
);

const Listbox = styled("ul")(
  () => `
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  max-height: 360px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: 100%;
  border-radius: 8px;
  overflow-y: auto;
  gap: 0px
`,
);
export type AsyncAutoCompleteProps = {
  options: any[];
  placeholder: string;
  onTextChange(text: string): void;
  renderOption?: (props: any) => React.ReactElement;
  selectedItemId?: number;
  targetRef: ReturnType<typeof useTargetRef>;
  optionsLoading: boolean;
  value?: string;
  onClear?: () => void;
};
type Props = {
  inputRef: React.Ref<any>;
  handleChange(val: any): void;
} & AsyncAutoCompleteProps;

export default function AsyncAutoComplete({
  selectedItemId,
  options,
  placeholder,
  renderOption,
  handleChange,
  onTextChange,
  optionsLoading,
  targetRef,
  onClear,
  value,
}: Props) {
  const {
    getRootProps,
    getListboxProps,
    getOptionProps,
    focused,
    getInputProps,
    setAnchorEl,
  } = useAutocomplete({
    id: "async-autocomplete",
    multiple: false,
    options: options,
    getOptionLabel: (option) => option.name,
  });

  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    onTextChange(searchText);
  }, [searchText]);

  React.useEffect(() => {
    if (value) setSearchText(value);
  }, [value]);

  const searchInputProps = {
    ...getInputProps(),
    value: searchText,
    onChange: (e: any) => {
      setSearchText(e?.target?.value || "");
    },
  };

  React.useEffect(() => {
    (getInputProps().ref as any)?.current.blur();
  }, [selectedItemId]);

  const handleValChange = (newVal: any) => {
    handleChange(newVal);
    setSearchText("");
  };

  return (
    <>
      <Root sx={{ position: "relative", width: "100%" }}>
        <div
          {...getRootProps()}
          style={{ position: "relative", width: "100%" }}
        >
          <InputWrapper
            ref={setAnchorEl}
            focused={focused}
            sx={{
              alignItems: "center",
              borderRadius: "20px",
              width: "100%",
              height: "40px",
            }}
          >
            <Box
              sx={{ paddingLeft: "6px", alignItems: "center", display: "flex" }}
            >
              <SearchIcon width={24} height={24} stroke={palette.gray[200]} />
            </Box>
            <Input
              placeholder={placeholder}
              inputProps={searchInputProps}
              sx={{ flex: 1 }}
              disableUnderline
            />
            <StyledIconButton
              onClick={() => {
                if (optionsLoading) return;
                setSearchText("");
                if (onClear) onClear();
              }}
              sx={{
                maxHeight: "18px",
                maxWidth: "18px",
                marginRight: "12px",
              }}
              data-testid="search-input-clear-button"
            >
              {optionsLoading ? (
                <CircularProgress
                  data-testid="circular_loading_async_autocomplete"
                  sx={{
                    color: "#8F8F8F",
                    opacity: 1,
                  }}
                  size={16}
                />
              ) : (
                <CloseIcon width={22} height={22} />
              )}
            </StyledIconButton>
          </InputWrapper>
        </div>
        <Box
          sx={{
            zIndex: 1500,
            position: "fixed",
            width: "calc( 700px - 64px )",
            "@media (max-width: 780px)": {
              width: "calc( 90% - 64px )",
            },
            "@media (max-width: 600px)": {
              width: "calc( 100% - 88px )",
            },
            "@media (max-width: 480px)": {
              width: "calc( 100% - 88px )",
            },
            marginLeft: "16px",
            marginTop: "8px",
            visibility: focused ? "visible" : "hidden",
          }}
        >
          <Listbox {...getListboxProps()} data-testid="async-list-wrapper">
            {(options as typeof options).map((option, index) => {
              const selected = selectedItemId === option?.id;
              return (
                <StyledListItem
                  {...getOptionProps({ option, index })}
                  key={option?.id}
                  selected={selected}
                  data-testid={`select-business-profile-option-${option?.id}`}
                  onClick={selected ? undefined : () => handleValChange(option)}
                >
                  {renderOption ? (
                    renderOption({
                      ...option,
                      selected: selected,
                    })
                  ) : (
                    <span>{option.name}</span>
                  )}
                  {options.length - 1 === index && (
                    <Box
                      ref={targetRef}
                      sx={{
                        height: "4px",
                        width: "4px",
                      }}
                    />
                  )}
                </StyledListItem>
              );
            })}
          </Listbox>
        </Box>
      </Root>
    </>
  );
}
