import { TableColumnType } from "@common/Table";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { setDynamicSortByKey, updateSorting } from "@redux/slices/fundraisers";
import { selectDateFilter } from "@redux/slices/merchantFilters";
import {
  clearFilters,
  resetStatusFilter,
  selectQueryFilters,
  selectTableFilter,
} from "@redux/slices/transactions";
import { encodedQueryFilterMap } from "@services/filtering";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

const NEGATIVE_FILTER_QUERY_KEYS = [
  "acquirer-processing-transactions",
  "enterprise-processing-transactions",
];

const formatFilterItem = (item: { type: string; value: string }): string => {
  const isNegativeFilter = item.value?.startsWith("!");
  const value = isNegativeFilter ? item.value?.slice(1) : item.value;
  return encodeURIComponent(
    `${item.type}:${isNegativeFilter ? "!" : ""}"${value}"`,
  );
};

export const useFilters = (queryKey: string) => {
  const filters = useAppSelector(selectTableFilter);
  const isNegativeFilterKey = NEGATIVE_FILTER_QUERY_KEYS.includes(queryKey);
  const fFilters = isNegativeFilterKey
    ? filters
    : filters.filter(({ value }) => !value?.startsWith("!"));

  if (fFilters.length === 0) {
    return "";
  }

  const formattedFilter = fFilters.map(formatFilterItem).join(",");
  return `(${formattedFilter})`;
};

export const useSettlementFiltersRepository = () => {
  const dispatch = useDispatch();
  const settlementDate = useAppSelector((state) =>
    selectDateFilter(state, "settlement-date"),
  );
  const queryString = "";
  let filterString = "";

  if (settlementDate) {
    filterString = `?filter=settlementDate:"${settlementDate}"`;
  }

  useEffect(() => {
    return () => {
      dispatch(resetStatusFilter());
      dispatch(clearFilters());
    };
  }, []);

  return { filterString, queryString };
};

const useQuerySearchFilter = () => {
  const queryFilters = useAppSelector(selectQueryFilters);

  const queryFilter = useMemo(
    () => encodedQueryFilterMap(queryFilters),
    [queryFilters],
  );

  return queryFilter.transactions || "";
};

export const useFiltersRepository = (queryKey = "") => {
  const dispatch = useDispatch();

  const queryString = useQuerySearchFilter();
  const formattedFilter = useFilters(queryKey);

  useEffect(() => {
    return () => {
      dispatch(resetStatusFilter());
      dispatch(clearFilters());
    };
  }, []);

  return { queryString, formattedFilter };
};

export const useSorting = (
  setSort: any,
  defaultSortKey = "createdAt",
  sortReduxKey = "",
) => {
  const [sortKey, setSortKey] = useState<any>(null);
  const [order, setOrder] = useState<any>("desc");

  const getNewOrder = (columnKey: any) => {
    let newOrder: any = "asc";

    if (sortKey === null) {
      newOrder = "asc";
    } else {
      if (sortKey !== columnKey) {
        newOrder = "asc";
      } else {
        newOrder = order === "asc" ? "desc" : "asc";
      }
    }

    return newOrder;
  };

  useEffect(() => {
    setSortKey(defaultSortKey);
    setOrder("desc");
  }, []);

  const dispatch = useAppDispatch();

  const onSort =
    (column: TableColumnType) => (_: React.MouseEvent<HTMLElement>) => {
      setSortKey(column.key);
      const newOrder = getNewOrder(column.key);
      const ascOrder = column.key?.replace("-", "");
      const newSorting = newOrder === "asc" ? ascOrder : `-${column.key}`;

      setOrder(newOrder);

      if (sortReduxKey) {
        dispatch(
          setDynamicSortByKey({ key: sortReduxKey, params: newSorting }),
        );
      } else {
        dispatch(updateSorting(newSorting));
      }
      setSort(newSorting);
    };

  return { sortKey, order, onSort };
};
