import { useMemo, useState } from "react";
import { mockStructure } from "../components/products/mockData";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

let lastStepCompleted = "pay_tab_about";

function findIdx(lastStepCompleted: any) {
  const item = stepsArray.find((x) => x.id === lastStepCompleted);
  const index = stepsArray.findIndex((element) => element.id === item?.id);

  return index;
}

export default function usePayBuilder() {
  const screenSizes = useCustomThemeV2();
  const [activeStepIndex, setActiveStepIndex] = useState(() => {
    return Math.max(findIdx(lastStepCompleted), 0);
  });

  const [screenStates, setScreenStates] = useState(screenSizes);
  const [pageStructure, setPageStructure] = useState(mockStructure);
  const isEmptyProducts = pageStructure.children.length == 0;

  const isCompleted = useMemo(
    () => findIdx(lastStepCompleted),
    [lastStepCompleted],
  );

  const goToStep = ({ index, offSet }: { index?: number; offSet?: 1 | -1 }) => {
    setActiveStepIndex((current) => {
      //yuo cannot jump between step if there is a least one step incompleted before
      //isCompleted represents the index of the last step that was completed
      //a step is considered completed if the user pressed Next button
      //also the current step from where the jump was done should be enabled (this case usualy happens if the user jumps back)
      //if the user jumps back to an already completed step and do changes, and press next, do not set it as last step completed.
      if (index !== undefined && index <= isCompleted) return index;
      if (offSet !== undefined) {
        if (offSet === 1 && activeStepIndex >= isCompleted) {
          lastStepCompleted = stepsArray[current + offSet].id;
        }
        return current + offSet;
      }

      return current;
    });
  };

  const manageScreenActions = {
    setDeskTopView: () =>
      setScreenStates({
        isMobileView: false,
        isTabletView: false,
        isDesktopView: true,
        isMobileDevice: false,
        isSmallDesktop: true,
      }),

    setTabletView: () =>
      setScreenStates({
        isMobileView: false,
        isTabletView: true,
        isDesktopView: false,
        isMobileDevice: false,
        isSmallDesktop: false,
      }),
    setMobileView: () =>
      setScreenStates({
        isMobileView: true,
        isTabletView: false,
        isDesktopView: false,
        isMobileDevice: true,
        isSmallDesktop: false,
      }),
  };

  return {
    activeStepIndex,
    activeItem: stepsArray[activeStepIndex],
    goToStep,
    isLastStep: activeStepIndex === stepsArray.length - 1,
    lastStepCompletedIndex: isCompleted,
    isEmptyProducts,
    pageStructure,
    screenStates,
    manageScreenActions,
  };
}

export const stepsArray = [
  { label: "About", id: "pay_tab_about" },
  { label: "Items", id: "pay_tab_items" },
  { label: "Style", id: "pay_tab_style" },
  { label: "Checkout", id: "pay_tab_checkout" },
  { label: "Launch", id: "pay_tab_launch" },
];
