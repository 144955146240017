import { useState } from "react";
import MobileDrawerActions from "../MobileDrawerActions";
import { SxProps } from "@mui/material";
import { IMobileActionItem } from "../MobileActionItem";

export interface MobileActionItemProps {
  onSelect: () => void;
  label: string | React.ReactNode;
  labelProps?: IMobileActionItem;
  closeOnSelect?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  testId?: string;
  tooltipProps?: any;
}
export interface BottomActions {
  label: string | React.ReactNode;
  items: MobileActionItemProps[];
  buttonSx?: SxProps;
  sx?: SxProps;
}

export function useMobileDrawerActions() {
  const [open, setOpen] = useState<boolean>(false);

  const closeDrawer = () => {
    setOpen(false);
  };

  const openDrawer = () => {
    setOpen(true);
  };

  return {
    BottomActions: (props: BottomActions) => (
      <MobileDrawerActions
        isDrawerOpen={open}
        closeDrawer={closeDrawer}
        openDrawer={openDrawer}
        {...props}
      />
    ),
    closeDrawer,
    openDrawer,
    isActionsDrawerOpen: open,
  };
}

export const DrawerBottomActions = (props: BottomActions) => {
  const [open, setOpen] = useState<boolean>(false);

  const closeDrawer = () => {
    setOpen(false);
  };

  const openDrawer = () => {
    setOpen(true);
  };
  return (
    <MobileDrawerActions
      isDrawerOpen={open}
      closeDrawer={closeDrawer}
      openDrawer={openDrawer}
      {...props}
    />
  );
};
