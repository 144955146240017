import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { showMessage } from "@common/Toast";
import { useUpdateUnderwriting } from "@components/Merchants/MerchantPreview/hooks/useUnderwritingUpdate";
import {
  QKEY_LIST_ACQUIRER_MERCHANTS,
  QKEY_LIST_MERCHANT_STATS,
} from "@constants/queryKeys";
import NiceModal from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack } from "@mui/material";
import GiveButton from "@shared/Button/GiveButton";
import { GiveInput } from "@shared/GiveInputs/GiveInput";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import GiveText from "@shared/Text/GiveText";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as Yup from "yup";
type Props = {
  merchantId: number;
  merchantName: string;
};

type ReasonType = {
  reason: string;
};

const schema = Yup.object().shape({
  reason: Yup.string()
    .required("Reason is required")
    .min(1, "Reason cannot be empty"),
});

const DeclineMerchantModal = NiceModal.create(
  ({ merchantId, merchantName }: Props) => {
    const { open, onClose } = useNiceModal();
    const queryClient = useQueryClient();

    const {
      updateUnderwriting: { isLoading, mutate },
    } = useUpdateUnderwriting(merchantId);

    const methods = useForm<ReasonType>({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues: {
        reason: "",
      },
      resolver: yupResolver(schema),
    });

    const onDecline: SubmitHandler<ReasonType> = async (data) => {
      const customData = {
        status: "declined",
        reason: data.reason,
      };
      mutate(customData, {
        onSuccess: () => {
          queryClient.invalidateQueries("get-merchant-msp-status");
          queryClient.refetchQueries(QKEY_LIST_MERCHANT_STATS);
          queryClient.refetchQueries(QKEY_LIST_ACQUIRER_MERCHANTS);
          queryClient.refetchQueries(["get-merchant-preview", merchantId]);
          handleClose();
        },
        onError: () => {
          queryClient.refetchQueries("get-merchant-preview");
          showMessage("Error", "Merchant account can not be declined");
        },
      });
    };

    const handleClose = () => {
      methods.reset();
      onClose();
    };

    const isValid = methods.formState.isValid;

    return (
      <GiveBaseModal
        open={open}
        title="Decline Merchant"
        width="560px"
        height="57%"
        onClose={handleClose}
        buttons={
          <Stack gap="12px" flexDirection="row">
            <GiveButton
              onClick={handleClose}
              label="Cancel"
              variant="ghost"
              size="large"
            />
            <GiveButton
              label="Decline"
              color="destructive"
              variant="filled"
              size="large"
              sx={{
                border: "none",
              }}
              onClick={methods.handleSubmit(onDecline)}
              disabled={!isValid || isLoading}
            />
          </Stack>
        }
      >
        <Box>
          <Controller
            control={methods.control}
            name="reason"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <GiveText
                  mb="24px"
                  color="secondary"
                  fontSize="14px"
                  lineHeight="20px"
                >
                  Are you sure you want to decline{" "}
                  <strong>{merchantName}</strong>? This action will reject
                  merchant’s application.
                </GiveText>

                <GiveInput
                  label="Reason (required)"
                  name="reason"
                  multiline
                  rows={8}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                />
              </>
            )}
          />
        </Box>
      </GiveBaseModal>
    );
  },
);

export default DeclineMerchantModal;
