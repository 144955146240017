import NiceModal from "@ebay/nice-modal-react";
import { FormProvider, useForm } from "react-hook-form";
import MultiLineInput from "@common/Input/MultiLineInput";
import { palette } from "@palette";
import { RHFCheckbox } from "@common/Checkbox";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { isEmpty } from "lodash";
import { useMarkAsFraudActions } from "./hooks/useMarkAsFraudActions";
import { TBlockTransaction } from "@services/api/riskProfile/riskProfileActions";

type TReasonForm = {
  reason: string;
  shouldSendNotification: boolean;
  shouldRefundTransaction: boolean;
};

type TMarkAsFraudModal = {
  shouldRefundDisplayed: boolean;
  transactionId: string;
  customerID: number;
  isFirst?: boolean;
  isLast?: boolean;
  setSelectedRow?: React.Dispatch<any>;
};

const MarkAsFraudModal = NiceModal.create(
  ({
    shouldRefundDisplayed,
    transactionId,
    customerID,
    ...props
  }: TMarkAsFraudModal) => {
    const { open, onClose, TransitionProps } = useNiceModal();

    const defaultValues = {
      reason: "",
      shouldSendNotification: false,
      shouldRefundTransaction: shouldRefundDisplayed ? true : false,
    };

    const methods = useForm<TReasonForm>({ defaultValues });
    const { reason, shouldSendNotification, shouldRefundTransaction } =
      methods.watch();

    const { markAsFraudMutation } = useMarkAsFraudActions(props);
    const onSubmit = () => {
      const payloadData: TBlockTransaction = {
        merchantId: customerID,
        id: transactionId,
        reason: reason,
        canNotifyMerchant: shouldSendNotification,
        skipRefund: shouldRefundTransaction ? false : true,
      };
      markAsFraudMutation.mutate(payloadData);
      onClose();
    };
    return (
      <ModalFactory
        variant="dialog"
        modalProps={{
          open: open,
          onClose,
          DialogProps: {
            paperStyle: { padding: "24px 24px 16px " },
            TransitionProps,
          },
        }}
      >
        <ModalTitle
          title="Mark as Fraud transaction"
          onClose={onClose}
          textStyles={{
            title: {
              fontSize: "18px",
              lineHeight: "21.6px",
              letterSpacing: "-0.18px",
            },
          }}
          containerProps={{ sx: { mb: "16px" } }}
          description="Please provide a reason why this transaction was Marked as fraud"
        />
        <FormProvider {...methods}>
          <FadeUpWrapper delay={100}>
            <MultiLineInput name="reason" placeholder="Reason" rows={8} />
            {shouldRefundDisplayed && (
              <RHFCheckbox
                dataTestId="refund-checkbox"
                name="shouldRefundTransaction"
                label="Refund transaction"
                helperText="If checkbox is checked the transaction will be refunded to the merchant"
                labelProps={{
                  color: palette.neutral[80],
                  fontSize: 14,
                }}
                sx={{ pl: "5px" }}
              />
            )}
            <RHFCheckbox
              dataTestId="notification-checkbox"
              name="shouldSendNotification"
              label="Send notification to the merchant"
              labelProps={{
                color: palette.neutral[80],
                fontSize: 14,
              }}
              sx={{ pl: "5px" }}
            />
          </FadeUpWrapper>
        </FormProvider>
        <ModalActions
          animationDelay={100}
          secondaryAction={{
            label: "Cancel",
            onClick: onClose,
          }}
          primaryAction={{
            type: "button",
            onClick: onSubmit,
            form: "mark-as-fraud",
            background: "primary",
            disabled: isEmpty(reason),
          }}
          containerProps={{ sx: { mt: "20px" } }}
        />
      </ModalFactory>
    );
  },
);

export default MarkAsFraudModal;
