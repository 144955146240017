import { Stack, useMediaQuery, SxProps } from "@mui/material";
import GiveUploadItem from "@shared/FileUpload/GiveUploadItem";
import { useAppTheme } from "@theme/v2/Provider";
import React from "react";

export type GiveUploadItemProps = Omit<
  React.ComponentProps<typeof GiveUploadItem>,
  "isMobile"
>;
export interface UploadStackProps {
  items: GiveUploadItemProps[];
  customStyles?: SxProps;
  isHideDelete?: boolean;
}
const GiveUploadStack = ({
  items,
  customStyles,
  isHideDelete,
}: UploadStackProps) => {
  const { palette, breakpoints } = useAppTheme();
  const isDesktop = useMediaQuery(breakpoints.up("sm"));
  return (
    <Stack
      direction="column"
      border={`1px solid ${palette.border?.primary}`}
      overflow="hidden"
      width={isDesktop ? "600px" : "100%"}
      borderRadius="12px"
      sx={{
        maxWidth: "100%",
        backgroundColor: palette.surface?.primary,
        "& > *:last-child": {
          borderBottom: "none",
        },
        ...customStyles,
      }}
    >
      {items.map((item, index) => (
        <GiveUploadItem
          key={index}
          {...item}
          isMobile={!isDesktop}
          isHideDelete={isHideDelete}
        />
      ))}
    </Stack>
  );
};

export default GiveUploadStack;
