import { TagType } from "@common/Tag";
import { useSelector } from "react-redux";
import { selectUser } from "@redux/slices/auth/auth";
import { toUTCDateFormat } from "@utils/index";
import { IMerchantBankAccount } from "@components/Merchants/MerchantPreview/data.types";

type StatusMessageProps = {
  data: IMerchantBankAccount;
};

export const getRenamedStatus: (status: string) => TagType = (status) => {
  switch (status) {
    case "update_requested":
    case "pending":
      return "upload" as TagType;
    case "pending_review":
      return "pending" as TagType;
    default:
      return status as TagType;
  }
};

export const getMerchantStatusMessage = ({ data }: StatusMessageProps) => {
  switch (data.status) {
    case "upload":
      return "Please upload a bank statement.";
    case "pending":
      return "Bank Account is being reviewed.";
    case "approved":
      return "Bank Account has been approved.";
    case "declined":
      return "We apologize but the Bank Account could not be approved at this time.";
    default:
      return data.status as TagType;
  }
};

export const getAcqEntStatusMessage = ({ data }: StatusMessageProps) => {
  const { declinedByEmail } = data;
  const declinedByName = data.declinedByName?.trim();

  switch (data.status) {
    case "upload":
      return "Bank Statement needs to be uploaded.";
    case "pending":
      return "Bank Statement uploaded and ready for review.";
    case "approved":
      return `Approved ${
        data.approvedAt ? `on ${toUTCDateFormat(data.approvedAt)}` : ""
      } ${data.approvedByName ? `by ${data.approvedByName}` : ""}`;
    case "declined":
      return `Declined ${
        data.declinedAt ? `on ${toUTCDateFormat(data.declinedAt)}` : ""
      } ${
        declinedByName || declinedByEmail
          ? `by ${declinedByName || declinedByEmail}`
          : ""
      }`;
    default:
      return data.status as TagType;
  }
};

export const getStatusMessage = (props: StatusMessageProps) => {
  const auth = useSelector(selectUser);
  switch (auth?.role) {
    case "merchant":
      return getMerchantStatusMessage(props);
    default:
      return getAcqEntStatusMessage(props);
  }
};
