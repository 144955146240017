import { TickIcon } from "@assets/icons";
import { Text } from "@common/Text";
import { LinearProgress, Stack } from "@mui/material";
import { palette } from "@palette";

const KotoLinearProgress = ({
  LinearProgressProps,
  svgProps,
  isActive,
  hasDescription,
  ...rest
}: any) => {
  return (
    <Stack flex={1} justifyContent="flex-start" width="100%">
      {rest.title && (
        <Text
          mb={1}
          textAlign="left"
          color={isActive ? palette.neutral.black : "#8F8F8F"}
        >
          {rest.title}
        </Text>
      )}
      <LinearProgress
        variant="determinate"
        {...rest}
        sx={{
          borderRadius: "90px",
          backgroundColor: "#D1D1D0",
          height: "2px",
          ".MuiLinearProgress-bar": {
            backgroundColor: "neutral.black",
            ...(!isActive && { opacity: ".2" }),
          },
          ...rest.sx,
        }}
        data-testid="stepper-linear-progress"
      />
      {hasDescription && (
        <Stack direction="row" gap={0.5} alignItems="baseline">
          {rest.value === 100 && (
            <TickIcon
              stroke={isActive ? palette.neutral.black : "#8F8F8F"}
              width={svgProps?.width}
              height={svgProps?.height}
            />
          )}
          <Text
            mt={1}
            textAlign="left"
            color={isActive ? palette.neutral.black : "#8F8F8F"}
            {...LinearProgressProps}
          >
            {rest.label}
          </Text>
        </Stack>
      )}
    </Stack>
  );
};

export default KotoLinearProgress;
