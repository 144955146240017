import { CartProvider } from "../provider/CartContext";
import { usePayBuilderForm } from "../provider/PayBuilderFormProvider";
import { Item_Layout } from "../provider/provider.type";
import ProductPageContainer from "./products/ProductPageContainer";

const ProductPreviewView = () => {
  const { methods } = usePayBuilderForm();
  const {
    Style: { itemLayout },
    Items,
    About: { heading, description, assetPosition },
  } = methods.watch();
  const header = { heading, description, assetPosition };

  return (
    <CartProvider>
      <ProductPageContainer
        displayStatus="preview"
        header={header}
        items={Items}
        itemLayout={itemLayout as Item_Layout}
      />
    </CartProvider>
  );
};
export default ProductPreviewView;
