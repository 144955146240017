import NiceModal from "@ebay/nice-modal-react";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import {
  MATCH_REPORT_MODAL,
  NEW_MATCH_REPORT_MODAL,
  OFAC_CHECK_MODAL,
} from "modals/modal_names";
import moment from "moment";
import { useGetMATCHReports } from "@components/Merchants/MerchantPreview/MATCH/hooks/useMATCHReports";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { SyntheticEvent } from "react";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { checkPortals } from "utils/routing";

export type TVariant = "ofac" | "match" | "website_monitoring";

type TValuesObject = {
  title: string;
  description: string;
  descriptionOnDisable: string;
  lastCheck: string;
  onClick: () => void;
  onClickConv: (e: SyntheticEvent) => void;
  permissionDenied?: boolean;
  isConfirmedMatch: boolean;
  isPossibleMatch?: boolean;
};

const useGetSimpleCardSectionData = (variant: TVariant) => {
  const { data } = useMerchantSidePanelContext();

  const { data: matchData } = useGetMATCHReports(data.merchantInfo.merchantID);

  const { openConversationHandler } = useConversationsModal();

  const getFormattedDate = (date?: number) =>
    date ? moment.unix(date).format("MMMM DD, YYYY") : "";

  const { isNewOfacTabsEnabled } = useGetFeatureFlagValues();
  const { isAcquirerEnterprises } = checkPortals();

  const composedOfacPermission = isNewOfacTabsEnabled
    ? composePermission(
        isAcquirerEnterprises
          ? RESOURCE_BASE.ENTERPRISE
          : RESOURCE_BASE.MERCHANT,
        RESOURCE_BASE.OFAC,
      )
    : composePermission(
        isAcquirerEnterprises
          ? RESOURCE_BASE.ENTERPRISE
          : RESOURCE_BASE.MERCHANT,
        RESOURCE_BASE.LEGAL_ENTITY,
        RESOURCE_BASE.OFAC,
      );

  //OFAC logic
  const isViewOfacAllowed = useAccessControl({
    resource: composedOfacPermission,
    operation: OPERATIONS.LIST,
  });
  const handleOpenOFAC = () => {
    NiceModal.show(OFAC_CHECK_MODAL, {
      headerData: data?.header,
      merchantId: data?.merchantInfo.merchantID,
      profileData: data?.businessProfile,
      PAH: data?.primaryAccountHolder,
      businessOwners: data?.businessOwnersList,
    });
  };

  const onClickOfacConv = (e: SyntheticEvent) => {
    e.stopPropagation();
    openConversationHandler({ id: 0, name: "OFAC Check", paths: [] });
  };
  const BPlastOfacCheck = data?.businessProfile?.ofac?.lastCheckAt;
  const PAHlastOfacCheck = data?.primaryAccountHolder?.ofac?.lastCheckAt;
  const BOOfacLastCheckValues = data?.businessOwnersList?.map(
    (owner) => owner.ofac?.lastCheckAt,
  );
  const latestOfacCheck = Math.max(
    ...BOOfacLastCheckValues.filter((value) => value !== undefined),
    BPlastOfacCheck ?? 0,
    PAHlastOfacCheck ?? 0,
  );

  const BPOfacStatus = data?.businessProfile?.ofac?.lastCheckStatusName;
  const PAHOfacStatus = data?.primaryAccountHolder?.ofac?.lastCheckStatusName;
  const BOOfacStatus = data?.businessOwnersList?.map(
    (owner) => owner.ofac?.lastCheckStatusName,
  );
  //we are showing the chip for confirmed and possible match
  const isOfacConfirmedMatch = [
    ...BOOfacStatus,
    PAHOfacStatus,
    BPOfacStatus,
  ]?.includes("confirmed_match");

  const isOfacPossibleMatch = [
    ...BOOfacStatus,
    PAHOfacStatus,
    BPOfacStatus,
  ]?.includes("possible_match");

  //MATCH logic

  const isViewReadMATCHAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.MATCH_REPORT,
    ),
    operation: OPERATIONS.READ,
  });

  const handleOpenMATCH = () => {
    const isNew = false;
    //Todo: Remove if block when new match report is ready to be tested
    if (!isNew) {
      NiceModal.show(MATCH_REPORT_MODAL, {
        merchantID: data.merchantInfo.merchantID,
        report: matchData && matchData.length > 0 ? matchData[0] : undefined,
      });
    } else {
      NiceModal.show(NEW_MATCH_REPORT_MODAL);
    }
  };

  const onClickMatchConv = (e: SyntheticEvent) => {
    e.stopPropagation();
    openConversationHandler({ id: 0, name: "MATCH Check", paths: [] });
  };
  const lastMatchCheck =
    matchData && matchData.length > 0 ? matchData[0].createdAt : undefined;
  const matchDescription = lastMatchCheck ? "Latest Check" : "Add New Report";
  const matchStatus = matchData?.map((data) => data.statusName);
  //for MATCH we dont have possible match status
  const isMatchConfirmedMatch = matchStatus?.includes("confirmed_match");

  const getCardData = (variant: TVariant) => {
    const modalValues: Record<TVariant, TValuesObject> = {
      ofac: {
        title: "OFAC",
        description: "Latest Check",
        descriptionOnDisable:
          "Please create a Business Profile or add a Primary Account Holder with both first and last name provided",
        lastCheck: getFormattedDate(latestOfacCheck),
        onClick: () => handleOpenOFAC(),
        onClickConv: (e) => onClickOfacConv(e),
        permissionDenied: !isViewOfacAllowed,
        isConfirmedMatch: isOfacConfirmedMatch,
        isPossibleMatch: isOfacPossibleMatch,
      },
      match: {
        title: `MATCH`,
        description: matchDescription,
        descriptionOnDisable: `Please create a Business Profile first`,
        lastCheck: getFormattedDate(lastMatchCheck),
        onClick: () => handleOpenMATCH(),
        onClickConv: (e) => onClickMatchConv(e),
        permissionDenied: !isViewReadMATCHAllowed,
        isConfirmedMatch: isMatchConfirmedMatch,
      },
      //will come in a future itteration
      website_monitoring: {
        title: `Website Monitoring`,
        description: "Add New Report",
        descriptionOnDisable: ``,
        lastCheck: getFormattedDate(),
        onClick: () => console.log("open website monitoring"),
        onClickConv: (e) => onClickOfacConv(e),
        isConfirmedMatch: false,
      },
    };
    return modalValues[variant];
  };

  return getCardData(variant);
};

export default useGetSimpleCardSectionData;
