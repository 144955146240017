import React from "react";

type TProps = { fill?: string; size?: number };

const MenuTwo = ({ fill = "#292928", size = 24 }: TProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.77143 7.5C3.34538 7.5 3 7.83579 3 8.25C3 8.66421 3.34538 9 3.77143 9H20.2286C20.6546 9 21 8.66421 21 8.25C21 7.83579 20.6546 7.5 20.2286 7.5H3.77143ZM3.77143 15C3.34538 15 3 15.3358 3 15.75C3 16.1642 3.34538 16.5 3.77143 16.5H20.2286C20.6546 16.5 21 16.1642 21 15.75C21 15.3358 20.6546 15 20.2286 15H3.77143Z"
        fill={fill}
      />
    </svg>
  );
};

export default MenuTwo;
