import { PUBLIC_FORM_PATHS } from "@routes/paths";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { useNavigate } from "react-router-dom";

export const usePaymentFormNavigator = (defaultCb?: any, newCb?: any) => {
  const navigate = useNavigate();
  const { isPayBuilderEnabled } = useGetFeatureFlagValues();

  if (isPayBuilderEnabled) {
    if (newCb) {
      return newCb;
    }
    return () => navigate(`/${PUBLIC_FORM_PATHS.PAY_PRODUCT_BUILDER}`);
  }

  return defaultCb;
};

function dimColor(hex: string, opacity: number) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function getTextColorsBasedOnBackground(hexColor: string) {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  // We are using Realative Luminance formula to calculate the luminance/brightness of the color
  // 0.2126, 0.7152, 0.0722 are the weights that represent human perception sensitivity to each color channel.
  const luminance =
    0.2126 * (r / 255) + 0.7152 * (g / 255) + 0.0722 * (b / 255);

  const titleColor = luminance > 0.5 ? "#000000" : "#FFFFFF";

  const subtitleColor = dimColor(titleColor, 0.6);

  return {
    titleColor,
    subtitleColor,
    luminance,
  };
}
