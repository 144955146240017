import { Button } from "@common/Button";
import { StyledIconButton } from "@common/IconButton";
import Popup from "@common/Popup";
import { Text } from "@common/Text";
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import { followLink } from "@hooks/common/documents/utils";
import { Box, Grid } from "@mui/material";
import { palette } from "@palette";
import { Warning, X } from "@phosphor-icons/react";
import { REDIRECT_EXTERNAL_WEBSITE_MODAL } from "modals/modal_names";

export const LinkModalAlert = ({ link }: { link: string }) => {
  return <Button title={link} />;
};

export const AlertLinkPopUp = NiceModal.create(({ link }: { link: string }) => {
  const modal = useModal();
  return (
    <Popup
      {...muiDialogV5(modal)}
      customContainerStyle={{
        boxShadow: "none",
        border: "none",
      }}
      containerCustomWidth="420px"
      isNotTextChild
      actions={<Actions link={link} hide={modal.hide} />}
    >
      <Header hide={modal.hide} />
      <Body link={link} />
    </Popup>
  );
});

function Actions({
  hide,
  link,
}: {
  link: string;
  hide: () => Promise<unknown>;
}) {
  return (
    <Box display="flex" justifyContent="flex-end" gap={1}>
      <Button
        disableElevation
        disableFocusRipple
        disableRipple
        disableTouchRipple
        variant="text"
        onClick={() => {
          followLink(link, { target: "__blank" });
          hide();
        }}
        sx={{
          background: palette.neutral[10],
          border: `1px solid ${palette.neutral[70]}`,
          padding: "12px",
          width: "120px",
          ":hover": {
            background: palette.neutral[10],
          },
        }}
      >
        <Text color={palette.neutral[80]}>Continue</Text>
      </Button>

      <Button
        onClick={() => hide()}
        disableElevation
        disableFocusRipple
        disableRipple
        disableTouchRipple
        sx={{
          padding: "12px",
          width: "120px",
        }}
      >
        <Text>Stay here</Text>
      </Button>
    </Box>
  );
}

function Header({ hide }: { hide: () => Promise<unknown> }) {
  return (
    <Grid container alignItems="center">
      <Warning weight="duotone" fill="#FF8124" size={24} />
      <Text
        marginLeft={2}
        fontSize="18px"
        fontWeight="book"
        color={palette.neutral[80]}
      >
        Please be careful
      </Text>
      <StyledIconButton
        onClick={() => hide()}
        sx={{
          marginLeft: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <X weight="regular" size={20} color={palette.neutral[80]} />
      </StyledIconButton>
    </Grid>
  );
}

function Body({ link }: { link: string }) {
  return (
    <Grid container flexDirection="column" gap={2}>
      <Text color={palette.neutral[70]} fontWeight="book">
        You are leaving GivePayments and we cannot be held responsible for the
        content of external websites.
      </Text>
      <Text color={palette.neutral[70]} fontWeight="book">
        Remember to{" "}
        {
          <span
            style={{
              textDecoration: "underline",
              color: palette.neutral[80],
            }}
          >
            never enter your password
          </span>
        }{" "}
        unless you are on the real GivePayments website
      </Text>
      <span
        style={{
          textDecoration: "underline",
          color: palette.neutral[80],
          lineBreak: "anywhere"
        }}
      >
        {link}
      </span>
    </Grid>
  );
}

export const triggerExternalWebsitePopUp = (link?: string) => {
  if (link) {
    NiceModal.show(REDIRECT_EXTERNAL_WEBSITE_MODAL, { link });
  }
};
