import {
  businessAddressParser,
  businessProfileParser,
  feesParser,
  merchantAgreementParser,
  merchantBankAccountsParser,
  merchantBusinessOwnersParser,
  merchantInfoParser,
  primaryAccountHolderParser,
} from "@components/Merchants/MerchantPreview/helpers/parsers";
import { customInstance } from "@services/api";
import { useQuery } from "react-query";

const getMerchantSnapshot = (id: number) => {
  return customInstance({
    url: `merchants/${id}/snapshot`,
    method: "GET",
  });
};

type Props = {
  merchantId: number;
  enabled?: boolean;
};

const useAgreementSnapshot = ({ merchantId, enabled = true }: Props) => {
  const { data, isLoading, isFetching } = useQuery(
    ["get_agreement_snapshot", merchantId],
    async () => {
      const snapshot = await getMerchantSnapshot(merchantId);

      return snapshot;
    },
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(merchantId) && enabled,
    },
  );

  const customData = {
    merchantInfo: merchantInfoParser(data?.account),
    businessProfile: businessProfileParser(data?.legal_entity),
    businessAddress: businessAddressParser(data?.legal_entity?.address),
    bankAccountList: merchantBankAccountsParser(
      data?.bankAccount ? [data?.bankAccount] : [],
    ),
    businessOwnersList: merchantBusinessOwnersParser(data?.principals),
    primaryAccountHolder: primaryAccountHolderParser({ data: data?.owner }),
    fees: feesParser({
      feeConfig: data?.feeConfig,
      ...data?.account,
    }),
    merchantAgreement: merchantAgreementParser({
      ...data?.account,
      owner: data?.owner,
    }),
  };

  return { data: customData, isLoading: isLoading || isFetching };
};

export default useAgreementSnapshot;
