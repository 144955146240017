const AmexCardIcon = ({
  width,
  height,
}: {
  width?: number | string;
  height?: number | string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 1H36C37.933 1 39.5 2.567 39.5 4.5V22.5C39.5 24.433 37.933 26 36 26H4C2.067 26 0.5 24.433 0.5 22.5V4.5C0.5 2.567 2.067 1 4 1Z"
        fill="#016FD0"
      />
      <path
        d="M4 1H36C37.933 1 39.5 2.567 39.5 4.5V22.5C39.5 24.433 37.933 26 36 26H4C2.067 26 0.5 24.433 0.5 22.5V4.5C0.5 2.567 2.067 1 4 1Z"
        stroke="#E6E6E3"
      />
      <g clipPath="url(#clip0_5005_78243)">
        <path
          d="M2 2.42847H38V14.9217L36.2181 16.7115L38 18.3045V25.5713H2V13.7941L3.11369 12.9707L2 12.1832V2.42847Z"
          fill="#016FD0"
        />
        <path
          d="M8.97095 21.0858V15.4338H14.9405L15.5809 16.2708L16.2426 15.4338H37.9105V20.696C37.9105 20.696 37.3439 21.0802 36.6885 21.0858H24.6905L23.9684 20.1949V21.0858H21.6021V19.5649C21.6021 19.5649 21.2789 19.7772 20.5801 19.7772H19.7747V21.0858H16.192L15.5524 20.2309L14.903 21.0858H8.97095Z"
          fill="white"
        />
        <path
          d="M2 11.174L3.34615 8.02783H5.67418L6.43814 9.79019V8.02783H9.3321L9.78688 9.30161L10.2278 8.02783H23.2186V8.6682C23.2186 8.6682 23.9016 8.02783 25.0239 8.02783L29.239 8.0426L29.9897 9.78189V8.02783H32.4115L33.0781 9.02691V8.02783H35.5221V13.6798H33.0781L32.4393 12.6775V13.6798H28.8811L28.5233 12.7888H27.5667L27.2147 13.6798H24.8017C23.836 13.6798 23.2186 13.0525 23.2186 13.0525V13.6798H19.5804L18.8583 12.7888V13.6798H5.3293L4.97172 12.7888H4.01821L3.66316 13.6798H2V11.174Z"
          fill="white"
        />
        <path
          d="M3.82275 8.72461L2.00708 12.9566H3.18917L3.52419 12.1092H5.47177L5.80505 12.9566H7.01318L5.19925 8.72461H3.82275ZM4.49451 9.70953L5.08816 11.1904H3.89912L4.49451 9.70953Z"
          fill="#016FD0"
        />
        <path
          d="M7.13794 12.9559V8.72388L8.81776 8.73013L9.7948 11.4587L10.7484 8.72388H12.4148V12.9559H11.3594V9.83757L10.2407 12.9559H9.31516L8.19332 9.83757V12.9559H7.13794Z"
          fill="#016FD0"
        />
        <path
          d="M13.137 12.9559V8.72388H16.5808V9.67052H14.2034V10.3944H16.5253V11.2854H14.2034V12.0371H16.5808V12.9559H13.137Z"
          fill="#016FD0"
        />
        <path
          d="M17.1919 8.72461V12.9566H18.2473V11.4531H18.6916L19.9571 12.9566H21.2468L19.8581 11.3975C20.428 11.3493 21.0159 10.8589 21.0159 10.0976C21.0159 9.20702 20.3186 8.72461 19.5405 8.72461H17.1919ZM18.2473 9.67124H19.4537C19.7431 9.67124 19.9536 9.89818 19.9536 10.1167C19.9536 10.3979 19.6808 10.5622 19.4693 10.5622H18.2473V9.67124Z"
          fill="#016FD0"
        />
        <path
          d="M22.5244 12.9559H21.4468V8.72388H22.5244V12.9559Z"
          fill="#016FD0"
        />
        <path
          d="M25.0794 12.9559H24.8468C23.7214 12.9559 23.0381 12.067 23.0381 10.8573C23.0381 9.61765 23.7137 8.72388 25.135 8.72388H26.3014V9.7262H25.0923C24.5154 9.7262 24.1074 10.1776 24.1074 10.8677C24.1074 11.6873 24.5739 12.0315 25.246 12.0315H25.5238L25.0794 12.9559Z"
          fill="#016FD0"
        />
        <path
          d="M27.376 8.72461L25.5603 12.9566H26.7424L27.0774 12.1092H29.025L29.3583 12.9566H30.5664L28.7525 8.72461H27.376ZM28.0477 9.70953L28.6414 11.1904H27.4523L28.0477 9.70953Z"
          fill="#016FD0"
        />
        <path
          d="M30.6897 12.9559V8.72388H32.0315L33.7447 11.3828V8.72388H34.8001V12.9559H33.5017L31.7451 10.2274V12.9559H30.6897Z"
          fill="#016FD0"
        />
        <path
          d="M9.69312 20.3619V16.1299H13.137V17.0765H10.7596V17.8004H13.0814V18.6914H10.7596V19.4431H13.137V20.3619H9.69312Z"
          fill="#016FD0"
        />
        <path
          d="M26.5681 20.3619V16.1299H30.012V17.0765H27.6346V17.8004H29.9453V18.6914H27.6346V19.4431H30.012V20.3619H26.5681Z"
          fill="#016FD0"
        />
        <path
          d="M13.2706 20.3619L14.9474 18.272L13.2307 16.1299H14.5603L15.5827 17.4541L16.6086 16.1299H17.8862L16.192 18.2459L17.8719 20.3619H16.5425L15.5498 19.0585L14.5812 20.3619H13.2706Z"
          fill="#016FD0"
        />
        <path
          d="M17.9973 16.1306V20.3626H19.0805V19.0262H20.1914C21.1314 19.0262 21.8439 18.5263 21.8439 17.554C21.8439 16.7487 21.2851 16.1306 20.3285 16.1306H17.9973ZM19.0805 17.0877H20.2504C20.5541 17.0877 20.7712 17.2743 20.7712 17.5749C20.7712 17.8574 20.5552 18.0622 20.2469 18.0622H19.0805V17.0877Z"
          fill="#016FD0"
        />
        <path
          d="M22.3022 16.1299V20.3619H23.3576V18.8584H23.802L25.0674 20.3619H26.3571L24.9685 18.8027C25.5384 18.7545 26.1263 18.2642 26.1263 17.5029C26.1263 16.6123 25.429 16.1299 24.6508 16.1299H22.3022ZM23.3576 17.0765H24.564C24.8534 17.0765 25.0639 17.3035 25.0639 17.522C25.0639 17.8032 24.7912 17.9675 24.5796 17.9675H23.3576V17.0765Z"
          fill="#016FD0"
        />
        <path
          d="M30.5007 20.3619V19.4431H32.6128C32.9254 19.4431 33.0607 19.2738 33.0607 19.0881C33.0607 18.9102 32.9258 18.7303 32.6128 18.7303H31.6584C30.8287 18.7303 30.3667 18.2236 30.3667 17.4628C30.3667 16.7843 30.7898 16.1299 32.0227 16.1299H34.0779L33.6335 17.0821H31.856C31.5163 17.0821 31.4117 17.2608 31.4117 17.4315C31.4117 17.6069 31.5409 17.8004 31.8005 17.8004H32.8003C33.7252 17.8004 34.1265 18.3263 34.1265 19.015C34.1265 19.7555 33.6793 20.3619 32.75 20.3619H30.5007Z"
          fill="#016FD0"
        />
        <path
          d="M34.3742 20.3619V19.4431H36.4864C36.7989 19.4431 36.9342 19.2738 36.9342 19.0881C36.9342 18.9102 36.7993 18.7303 36.4864 18.7303H35.5319C34.7023 18.7303 34.2402 18.2236 34.2402 17.4628C34.2402 16.7843 34.6634 16.1299 35.8962 16.1299H37.9514L37.507 17.0821H35.7296C35.3898 17.0821 35.2852 17.2608 35.2852 17.4315C35.2852 17.6069 35.4144 17.8004 35.674 17.8004H36.6739C37.5987 17.8004 38 18.3263 38 19.015C38 19.7555 37.5528 20.3619 36.6235 20.3619H34.3742Z"
          fill="#016FD0"
        />
      </g>
      <defs>
        <clipPath id="clip0_5005_78243">
          <rect
            width="36"
            height="21"
            fill="white"
            transform="translate(2 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AmexCardIcon;
