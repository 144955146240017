import { getMerchantId } from "@utils/helpers";

const buildUrl = (
  url: string,
  parent: string,
  customParam?: string | number | null,
) => {
  if (!customParam) return url;

  return `/${parent}/${customParam}/${url}`;
};

const buildWithMerchantId = (
  url: string,
  parent: string,
  customId?: number | string,
) => {
  const merchant = getMerchantId();
  const id = typeof merchant === "number" ? merchant : merchant.id;
  return buildUrl(url, parent, customId ? customId : id);
};

export const buildAccountsUrl = (url: string) =>
  buildWithMerchantId(url, "accounts");

export const buildMerchantEndpoints = (url: string, id?: number | string) =>
  buildWithMerchantId(url, "merchants", id);

export const addFiltersToUrl = (url: string, filters?: string) => {
  if (!filters) {
    return url; // Return the original URL if no filters are provided
  }

  // Check if the URL already contains a query parameter
  const separator = url.includes("?") ? "&" : "?";

  // Append the filter using the appropriate separator
  return `${url}${separator}filter=${encodeURIComponent(filters)}`;
};
