import NiceModal from "@ebay/nice-modal-react";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import { FeatureFlagKeys } from "FeatureFlags/featureFlagKeys";
import FlaggedWrapper from "FeatureFlags/FlaggedWrapper";
import { useState } from "react";
import LoginOTPModal from "../LoginOTPModal";
import CheckPassword from "./CheckPassword";
import UpdateEmail from "./UpdateEmail";

const NewChangeEmailModal = NiceModal.create(() => {
  const [passwordChecked, setPasswordChecked] = useState<boolean>(false);
  const { email } = useAppSelector(selectUser);

  return passwordChecked ? (
    <UpdateEmail setPasswordChecked={setPasswordChecked} />
  ) : (
    <FlaggedWrapper
      ActiveComponent={
        <LoginOTPModal
          isMobileView={false}
          email={email}
          onSuccessFn={() => setPasswordChecked(true)}
        />
      }
      FallbackComponent={<CheckPassword setChecked={setPasswordChecked} />}
      name={FeatureFlagKeys.LOGIN_OTP_ENABLED_FLAG}
    />
  );
});

export default NewChangeEmailModal;
