import React, { memo, useCallback, useState } from "react";
import { Stack } from "@mui/material";
import { palette } from "@palette";
import { SelectProps } from "@common/Select/Select";
import { TruncateText } from "@common/Text";
import { Button } from "@common/Button";
import { Image } from "@common/StyledImage/Image";
import placeholder from "assets/images/profile-placeholder.png";
import { useQueryClient } from "react-query";
import { DisputesRowType } from "@components/Disputes/RespondToDispute/types";
import { DISPUTES_QUERY_KEY } from "../hooks/useDisputesTable";
import { CaretDown, Plus } from "@phosphor-icons/react";
import useGetTeamMembers from "@components/Merchants/MerchantPreview/hooks/useGetTeamMembers";
import AssignMenu from "@common/AssignMenu/AssignMenu";
import { QKEY_LIST_TEAM_MEMBERS } from "@constants/queryKeys";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import { SxProps } from "@mui/material";
import { useUpdateDisputeAssignee } from "../hooks/useUpdateDisputeAssignee";
import { Tooltip } from "@common/Tooltip";

interface AssignmentSelectProps extends Omit<SelectProps, "options"> {
  data: DisputesRowType;
  showIcon?: boolean;
  assignBtnSx?: SxProps;
}

const DisputeAssignmentSelect = ({
  data,
  showIcon,
  assignBtnSx,
}: AssignmentSelectProps) => {
  const { userAccID } = useAppSelector(selectUser);

  const { id: disputeId, defaultAssignee } = data;

  const { accID, avatarURL, email, firstName, lastName } =
    defaultAssignee || {};

  const assigneeName =
    firstName || lastName ? `${firstName} ${lastName}` : email;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const membersData = useGetTeamMembers({
    memberStatus: "joined",
    queryOptions: {
      enabled: !!anchorEl,
    },
  });

  const queryClient = useQueryClient();

  const { handleChangeAssignee } = useUpdateDisputeAssignee();

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
      event?.stopPropagation();
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleChange = (value: string | null) => {
    const newAssignee = value === "unassigned" ? null : value;
    const onSuccess = () => {
      queryClient.refetchQueries(DISPUTES_QUERY_KEY);
      queryClient.refetchQueries(["dispute-preview", disputeId]);
    };
    handleChangeAssignee(accID, newAssignee, disputeId, onSuccess);
  };

  return (
    <>
      {accID ? (
        <Tooltip
          title={assigneeName}
          titleSx={{ textTransform: "none" }}
          sx={{
            "& .MuiTooltip-tooltip": {
              maxWidth: "350px",
            },
          }}
        >
          <Stack
            gap={1}
            alignItems="center"
            direction="row"
            data-testid="assignee-selection-dropdown"
            onClick={handleOpenMenu}
            mr="auto"
            sx={{
              cursor: "pointer",
            }}
          >
            <Image
              height={24}
              width={24}
              sx={{
                borderRadius: "50%",
              }}
              src={avatarURL ? `${avatarURL}/thumb` : placeholder}
            />
            <Stack direction="row" spacing="4px" alignItems="center">
              <TruncateText
                lineClamp={1}
                color={(theme) => theme.palette.neutral[90]}
                lineHeight="16.8px"
                sx={{ wordBreak: "break-all" }}
                width="100%"
              >
                {assigneeName}
              </TruncateText>
              {showIcon && (
                <CaretDown
                  color={palette.neutral[90]}
                  style={{ flexShrink: 0 }}
                />
              )}
            </Stack>
          </Stack>
        </Tooltip>
      ) : (
        <Button
          background="tertiary"
          data-testid="assignee-selection-dropdown"
          startIcon={
            <Plus width={20} height={20} color={palette.neutral[70]} />
          }
          sx={{
            padding: 0,
            height: "20px",
            color: palette.neutral[70],
            gap: 1,
            fontWeight: 400,
            lineHeight: "16.8px",
            ...assignBtnSx,
          }}
          onClick={handleOpenMenu}
        >
          Assign
        </Button>
      )}
      <AssignMenu
        anchorEl={anchorEl}
        handleClose={() => {
          setAnchorEl(null);
        }}
        statusName={data?.statusDisplayName}
        handleChange={handleChange}
        assigneeId={accID || 0}
        emptyStateTitle="No team members found"
        emptyStateSubtitle="Invite team members"
        serachQueryKey={QKEY_LIST_TEAM_MEMBERS}
        {...membersData}
        currentUserId={userAccID}
        showRole={false}
        searchBarHeight={45}
        popoverHeight={277}
      />
    </>
  );
};

export default memo(DisputeAssignmentSelect);
