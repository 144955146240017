import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Box, Divider, Grid, Stack } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useEffect } from "react";
import { useUpdateMerchantInfo } from "@components/Merchants/MerchantPreview/hooks/useUpdateMerchantInfo";
import { HFGiveInput } from "@shared/HFInputs/HFGiveInput/HFGiveInput";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import GiveButton from "@shared/Button/GiveButton";
import GiveText from "@shared/Text/GiveText";
import HFGiveSwitch from "@shared/HFInputs/HFGiveSwitch/HFGiveSwitch";
import { useAppTheme } from "@theme/v2/Provider";
import useUserReducer from "@hooks/Reducers/useUserReducer";
import { IdentificationBadge } from "@phosphor-icons/react/dist/ssr";

type FormInputs = {
  email: string;
  invite: boolean;
  assignToMe: boolean;
};

type Props = {
  data: FormInputs;
  onClose: (data?: FormInputs) => void;
  merchantId?: number;
  isEdit?: boolean;
  isEnterprise?: boolean;
};

const InvitePAHModal = NiceModal.create(
  ({ data, merchantId, onClose, isEdit, isEnterprise }: Props) => {
    const modal = useModal();
    const open = modal.visible;
    const { handleSubmit, isLoading } = useUpdateMerchantInfo(
      merchantId || 0,
      undefined,
      isEdit,
    );

    const user = useUserReducer();

    const name = [user.globalName.firstName, user.globalName.lastName].join(
      " ",
    );
    const dynamicPlaceholder = [name, user.email].join(" - ");

    const schema = Yup.object({
      email: Yup.string()
        .email("Email not valid")
        .when("assignToMe", {
          is: (value: boolean) => !value,
          then: (schema) => schema.required("Enter account holder email"),
        }),
      invite: Yup.boolean(),
      assignToMe: Yup.boolean(),
    });

    const defaultValues = {
      email: "",
      invite: true,
      assignToMe: false,
    };

    const methods = useForm<FormInputs>({
      mode: "onChange",
      resolver: yupResolver(schema),
      defaultValues,
    });

    const {
      reset,
      formState: { isValid, isDirty },
      watch,
      clearErrors,
    } = methods;
    const values = watch();

    useEffect(() => {
      clearErrors("email");
    }, [data.assignToMe]);

    useEffect(() => {
      reset({
        invite: true,
        email: data.email,
        assignToMe: data.assignToMe,
      });
    }, [data, modal.visible]);

    const handleCancel = () => {
      reset();
      modal.hide();
    };

    const onSubmit: SubmitHandler<FormInputs> = async (data) => {
      if (merchantId) {
        await handleSubmit("primary_account_holder", data);
      }
      if (onClose) onClose(data);
      modal.hide();
    };

    const theme = useAppTheme();

    return (
      <GiveBaseModal
        open={open}
        title="Primary Account Holder"
        width="720px"
        onClose={handleCancel}
        headerLeftContent={<IdentificationBadge />}
        buttons={
          <Stack gap="12px" flexDirection="row">
            <GiveButton
              onClick={handleCancel}
              label="Cancel"
              variant="ghost"
              size="large"
            />
            <GiveButton
              label={"Invite"}
              variant="filled"
              size="large"
              disabled={!isValid || isLoading || (isEdit && !isDirty)}
              type="submit"
              form="edit-primary-account-holder"
              sx={{ border: "none" }}
            />
          </Stack>
        }
        closeIconProps={{
          bgColor: "solidWhite",
        }}
      >
        <GiveText pb="24px" variant="bodyS" color="secondary">
          The Primary Account Holder has full control over the payment account,
          including the ability to add users, manage funds, and update settings.
        </GiveText>
        <FormProvider {...methods}>
          <Box
            component="form"
            id="edit-primary-account-holder"
            onSubmit={methods.handleSubmit(onSubmit)}
            marginTop="8px"
          >
            {!isEnterprise && (
              <>
                <Stack width="100%">
                  <Stack
                    sx={{
                      backgroundColor: theme.palette.surface?.secondary,
                      borderRadius: theme.spacing(1.5),
                      padding: theme.spacing(2),
                    }}
                    direction="row"
                    gap={1}
                  >
                    <HFGiveSwitch
                      name="assignToMe"
                      size="small"
                      disabled={!!values.email}
                    />
                    <Stack>
                      <GiveText variant="bodyS" color="primary">
                        I am the Primary Account Holder
                      </GiveText>
                      <GiveText variant="bodyS" color="secondary">
                        {dynamicPlaceholder} will be assigned as the PAH of this
                        merchant
                      </GiveText>
                    </Stack>
                  </Stack>
                </Stack>
                <Divider sx={{ marginY: 1.5 }}>
                  <GiveText variant="bodyS" color="secondary">
                    OR
                  </GiveText>
                </Divider>
              </>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <HFGiveInput
                  name="email"
                  label="Invite other PAH by email"
                  placeholder=""
                  fullWidth
                  disabled={Boolean(values.assignToMe)}
                />
              </Grid>
            </Grid>
          </Box>
        </FormProvider>
      </GiveBaseModal>
    );
  },
);

export default InvitePAHModal;
