import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Box } from "@mui/material";
import GiveIntegerInput, { GiveIntegerInputProps } from "@shared/GiveInputs/GiveIntegerInput";

type RHFCustomAmountInputProps = GiveIntegerInputProps & {
  name: string,
  width?: string | number,
  helper?: string | React.ReactNode,
};

const HFGiveIntegerInput = ({ 
  name,
  label,
  width,
  helper,
  ...props
}: RHFCustomAmountInputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...rest }, fieldState: { error } }) => {
        return (
          <Box width={width}>
            <GiveIntegerInput
              inputRef={ref}
              label={label}
              error={Boolean(error)}
              helperText={helper ? helper : error?.message as string}
              {...rest}
              {...props}
            />
          </Box>
      )}}
    />
  )
};


export default HFGiveIntegerInput