import { Box, Stack } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useState } from "react";
import { Text } from "@common/Text";
import moment from "moment";
import CustomArrowRight from "@assets/icons/RebrandedIcons/ArrowRight";
import {
  ArrowButton,
  NewStyledDateRange,
  StyledDateRange,
} from "./RangePicker.styles";
import { Button } from "@common/Button";
import Xicon from "@assets/icons/Xicon";

export type TOnSelectionRange = (startDate: Date, endDate: Date) => void;
export type TInitialRange = { startDate?: number; endDate?: number };

type Props = {
  onSelectionChange?: TOnSelectionRange;
  initialRange: TInitialRange;
  isNewStyle?: boolean;
  handleClose?: () => void;
  handleSave?: () => void;
  setCustomDate?: TOnSelectionRange;
};

const RangePicker = ({
  onSelectionChange,
  initialRange,
  isNewStyle,
  handleClose,
  handleSave,
  setCustomDate,
}: Props) => {
  const startDate = initialRange?.startDate
    ? new Date(initialRange?.startDate * 1000)
    : new Date();

  const endDate = initialRange?.endDate
    ? new Date(initialRange?.endDate * 1000)
    : new Date();

  const [isDateSelected, setIsDateSelected] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate,
    endDate,
    key: "selection",
  });
  const { isMobileView } = useCustomTheme();

  const usedRangeDate = setCustomDate
    ? { startDate, endDate, key: "selection" }
    : selectionRange;

  const onChange = (range: any) => {
    if (onSelectionChange)
      onSelectionChange(range.selection.startDate, range.selection.endDate);

    if (setCustomDate)
      setCustomDate(range.selection.startDate, range.selection.endDate);

    setSelectionRange(range.selection);
    setIsDateSelected(
      range.selection.startDate !== null && range.selection.endDate !== null,
    );
  };
  if (isNewStyle) {
    return (
      <Box flexDirection="column" display="flex" py="24px">
        <NewStyledDateRange
          ranges={[usedRangeDate]}
          onChange={onChange}
          showMonthAndYearPickers={false}
          showDateDisplay={false}
          showMonthArrow={false}
          moveRangeOnFirstSelection={false}
          shownDate={undefined}
          editableDateInputs
          weekdayDisplayFormat="eeeeee"
          maxDate={new Date()}
          months={2}
          rangeColors={[palette.liftedWhite[200]]}
          color={palette.black[300]}
          direction={isMobileView ? "vertical" : "horizontal"}
          navigatorRenderer={
            isMobileView
              ? undefined
              : (date: Date, changeShownDate) =>
                  renderNavigation(
                    date,
                    changeShownDate,
                    isNewStyle && !isMobileView,
                    handleClose,
                  )
          }
          scroll={{ enabled: isMobileView ? true : false }}
        />
        <Button
          disabled={!isDateSelected}
          sx={{
            alignSelf: isMobileView ? "center" : "flex-end",
            mr: "14px",
            mt: "24px",
          }}
          onClick={handleSave}
        >
          Apply
        </Button>
      </Box>
    );
  }
  
  return (
    <StyledDateRange
      ranges={[usedRangeDate]}
      onChange={onChange}
      showMonthAndYearPickers={false}
      showDateDisplay={false}
      showMonthArrow={false}
      moveRangeOnFirstSelection={false}
      shownDate={undefined}
      editableDateInputs
      weekdayDisplayFormat="eeeeee"
      maxDate={new Date()}
      months={2}
      rangeColors={[palette.liftedWhite[200]]}
      color={palette.black[300]}
      direction={isMobileView ? "vertical" : "horizontal"}
      navigatorRenderer={
        isMobileView
          ? undefined
          : (date: Date, changeShownDate) =>
              renderNavigation(date, changeShownDate, isNewStyle, handleClose)
      }
      scroll={{ enabled: isMobileView ? true : false }}
    />
  );
};

const renderNavigation = (
  currentFocusedDate: Date,
  changeShownDate: (
    value: string | number | Date,
    mode?: "set" | "setYear" | "setMonth" | "monthOffset" | undefined,
  ) => void,
  isNewStyle?: boolean,
  handleClose?: () => void,
) => {
  const leftMonth = moment(currentFocusedDate).format("MMMM YYYY");
  const rightMonth = moment(currentFocusedDate)
    .add(1, "months")
    .format("MMMM YYYY");

  return (
    <>
      {isNewStyle && (
        <Box
          sx={{
            cursor: "pointer",
          }}
          mb="24px"
          mr="14px"
          alignSelf="flex-end"
          onClick={handleClose}
        >
          <Xicon stroke="#575353" />
        </Box>
      )}

      <Stack
        direction="row"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
        gap="32px"
        sx={{ paddingInline: "0.833em", marginBottom: "16px" }}
      >
        <Stack direction="row" alignItems="center" flexGrow={1}>
          <ArrowButton onClick={() => changeShownDate(-1, "monthOffset")}>
            <CustomArrowRight rotate={-180} stroke={palette.black.main} />
          </ArrowButton>
          <Text
            color={palette.black[200]}
            fontWeight="book"
            lineHeight="16.8px"
            flexGrow={1}
            align="center"
          >
            {leftMonth}
          </Text>
        </Stack>
        <Stack direction="row" alignItems="center" flexGrow={1}>
          <Text
            color={palette.black[200]}
            fontWeight="book"
            lineHeight="16.8px"
            flexGrow={1}
            align="center"
          >
            {rightMonth}
          </Text>
          <ArrowButton onClick={() => changeShownDate(+1, "monthOffset")}>
            <CustomArrowRight stroke={palette.black.main} />
          </ArrowButton>
        </Stack>
      </Stack>
    </>
  );
};

export default RangePicker;
