import { SetStateAction } from "react";
import LoadingComponent from "./LoadingComponent";
import { Box, Grid, Stack } from "@mui/material";
import {
  ProfileSetupFormActions,
  ProfileSetupFormTitle,
} from "@components/ProfilePage/form.components";
import { palette } from "@palette";
import { ArrowLeft } from "@assets/icons";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { CameraFlow } from "@sections/VerifyAccountHolder_v2/components/CameraFlow";
import { Image } from "@common/StyledImage/Image";
import placeholder from "assets/images/Placeholder.png";
import { Text } from "@common/Text";
import { Camera, IdentificationCard, UserFocus } from "@phosphor-icons/react";
import useCameraAction from "../hooks/useCameraAction";

type TMobileSelfieTab = {
  selfieUrl: string | null;
  setSelfieUrl: (value: SetStateAction<string | null>) => void;
  setCompleted: (completed: boolean) => void;
  homepageReset: () => void;
  updateProgressBar: (value: number) => void;
  handleBack: () => any;
  dbSelfieURL: string | null;
  isLoggedInPAH?: boolean;
  merchant?: any;
};

const MobileSelfieTab = ({
  selfieUrl,
  setSelfieUrl,
  setCompleted,
  homepageReset,
  updateProgressBar,
  handleBack,
  dbSelfieURL,
  isLoggedInPAH = true,
  merchant,
}: TMobileSelfieTab) => {
  const { cameraInfo, cameraAction, selfieStates } = useCameraAction({
    setSelfieUrl,
    setCompleted,
    updateProgressBar,
    merchant,
    selfieUrl,
    homepageReset,
    dbSelfieURL,
  });
  const { cameraStatus, webcamRef, isCameraOff, isCameraOn } = cameraInfo || {};
  const { retakePhoto, getPrimaryButtonText, handlePrimaryAction } =
    cameraAction || {};
  const {
    selfie,
    uploadedSelfie,
    isCustomLoading,
    isAllDone,
    gettingSelfie,
    isButtonDisabled,
    isCameraAvailable,
  } = selfieStates || {};

  let image = (
    <SelfieInfo
      condition={!cameraStatus}
      defaultComponent={
        <CameraFlow
          data={selfie}
          webcamRef={webcamRef}
          retakePhoto={retakePhoto}
        />
      }
    />
  );
  if (!isLoggedInPAH) {
    image = (
      <Box width="300px">
        <Image
          width="100%"
          height="100%"
          alt="uploaded-selfie"
          src={uploadedSelfie ? uploadedSelfie.fileURL : placeholder}
        />
      </Box>
    );
  }

  return (
    <>
      {isCustomLoading ? (
        <LoadingComponent />
      ) : (
        <Stack direction="column" gap={4} flex={1}>
          <ProfileSetupFormTitle
            title="Take a selfie"
            description="Upload an identification document such as a driver license, passport, or government issued ID card."
          />
          {isAllDone ? (
            <ConfirmationScreenMobile />
          ) : (
            <Grid
              container
              height={300}
              margin="auto"
              borderRadius="4px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              position="relative"
            >
              {gettingSelfie ? <LoadingSpinner /> : image}
            </Grid>
          )}
        </Stack>
      )}

      <ProfileSetupFormActions
        secondaryAction={{
          disabled: isButtonDisabled,
          onClick: handleBack,
          children: (
            <Box
              component="span"
              color={palette.black[100]}
              display="inline-flex"
              alignItems="center"
              gap="4px"
            >
              <ArrowLeft height={20} width={20} fill={palette.black[100]} />{" "}
              Back
            </Box>
          ),
        }}
        primaryAction={{
          disabled: !isCameraAvailable || isButtonDisabled,
          children: getPrimaryButtonText(),
          type: "button",
          endIcon:
            isCameraOff || isCameraOn ? (
              <Camera size={cameraStatus?.active === undefined ? 15 : 25} />
            ) : null,
          onClick: handlePrimaryAction,
        }}
      />
    </>
  );
};

export default MobileSelfieTab;

const SelfieInfo = ({ condition, defaultComponent }: any) => {
  if (condition) {
    return (
      <Stack
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap="16px"
      >
        <Text
          fontWeight="regular"
          fontSize="18px"
          lineHeight="21.6px"
          textAlign="center"
          color={palette.neutral[80]}
        >
          Submit a selfie for identity confirmation
        </Text>
        <UserFocus size={51} color={palette.accent[3]} />
      </Stack>
    );
  }

  return defaultComponent;
};

const ConfirmationScreenMobile = () => {
  return (
    <Stack
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
    >
      <Stack
        display="flex"
        justifyContent="center"
        alignItems="center"
        paddingBottom={2}
        flexDirection="row"
      >
        <IdentificationCard
          width={33}
          height={33}
          fill={palette.success.main}
        />
        <UserFocus width={33} height={33} fill={palette.success.main} />
      </Stack>
      <Text
        color={palette.filled.success}
        fontSize={18}
        fontWeight="regular"
        lineHeight="120%"
        textAlign="center"
        marginBottom={2}
      >
        ID and Selfie successfully uploaded
      </Text>
      <Text
        fontWeight="regular"
        lineHeight="120%"
        textAlign="center"
        color={palette.neutral[80]}
      >
        We are verifying your identity. This could take some time and you will
        be notified when verified.
      </Text>
    </Stack>
  );
};
