import { CountryFlag } from "@common/CountryFlag";
import { Grid, GridProps, Stack } from "@mui/material";
import GiveDivider from "@shared/GiveDivider/GiveDivider";
import SectionCardBase from "@shared/SidePanel/components/SectionCard/SectionCardBase";
import GiveText from "@shared/Text/GiveText";
import React from "react";
type Payload = {
  label: string;
  isPhoneNumber?: boolean;
  value?: string | number | JSX.Element | null;
  parentGridProps?: GridProps;
  itemGridProps?: GridProps;
  isDivider?: boolean;
};

interface Props {
  payload: Payload[];
  leftTitle?: string;
}
function BaseCardComponent({ payload, leftTitle }: Props) {
  return (
    <SectionCardBase
      sx={{
        marginTop: "40px",
      }}
      leftTitle={leftTitle}
    >
      {payload?.map((item) => {
        return <RenderTextsComponent key={item.value + item.label} {...item} />;
      })}
    </SectionCardBase>
  );
}

export default BaseCardComponent;

export const RenderTextsComponent = ({
  label,
  value,
  isPhoneNumber,
  parentGridProps,
  itemGridProps,
  isDivider,
}: Payload) => {
  return (
    <Grid width="100%" my="8px" container {...parentGridProps}>
      <Grid item xs={6} {...itemGridProps}>
        <GiveText variant="bodyS" color="secondary">
          {label}
        </GiveText>
      </Grid>
      <Grid xs={6} item {...itemGridProps}>
        <Stack alignItems="center" gap={1} flexDirection="row">
          {isPhoneNumber && typeof value === "string" && (
            <CountryFlag height={20} width={20} phoneNumber={value} />
          )}
          <GiveText
            variant="bodyS"
            color="primary"
            sx={{
              whiteSpace: "normal",
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
            }}
          >
            {value}
          </GiveText>
        </Stack>
      </Grid>
      {isDivider && <GiveDivider />}
    </Grid>
  );
};
