import { Box, SxProps } from "@mui/material";
import { ComponentPropsWithoutRef, ReactNode } from "react";
import useMerchantSidePanel from "../hooks/useMerchantSidePanel";
import { useMerchantSidePanelContext } from "../Provider/MerchantSidePanelProvider";

type TMerchantSidePanelData = ReturnType<typeof useMerchantSidePanel>["data"];

export type TMerchantDataFields = {
  riskProfile: TMerchantSidePanelData["riskProfile"];
  statusName: TMerchantSidePanelData["status"]["statusName"];
  merchantId: TMerchantSidePanelData["merchantInfo"]["merchantID"];
  status: TMerchantSidePanelData["status"];
  handleManageRiskProfile: () => void;
  id: number;
};

type ContainerProps<T> = Omit<ComponentPropsWithoutRef<"div">, "children"> & {
  children: (data: T) => ReactNode | ReactNode[];
  sx?: SxProps;
};

// This component will do the heavy lifting
const GiveMerchantSectionsContainer = <T extends TMerchantDataFields>({
  children,
  sx,
  ...divProps
}: ContainerProps<T>) => {
  const { data, id } = useMerchantSidePanelContext();

  const riskSummary = {
    riskProfile: data?.riskProfile,
    statusName: data?.status?.statusName,
    merchantId: data?.merchantInfo?.merchantID,
    status: data?.status,
    handleManageRiskProfile: () => void 0,
  } as T;

  return (
    <Box {...divProps} component="form" sx={{ ...sx }}>
      {children({ ...riskSummary, id })}
    </Box>
  );
};

export default GiveMerchantSectionsContainer;
