import { useGetCurrentMerchantId } from "@hooks/common";
import { useRef, useState, useCallback } from "react";
import { fromUnixTime } from "date-fns";
import useManageApi from "@sections/VerifyAccountHolder_v2/hooks/useManageApi";
import useFindBusinessProfileById from "@hooks/enterprise-api/merchants/useFindBusinessProfileById";
import { personalInfoEnum, PersonalInfoTabDefaultData } from "../types";
import { IFileWithMeta } from "react-dropzone-uploader";
import { useGetIdentificationFiles } from "@sections/VerifyAccountHolder_v2/hooks/useCamera";
import { deleteDocumentsInLoop } from "@sections/VerifyAccountHolder_v2/VerifyAccountHolder";
import { isEmpty } from "lodash";
import { useFileUploadContext } from "@components/UploadFile/FileUploadContext";
import { challengeSlugs } from "@constants/challengeSlugs";
import { mime } from "@components/UploadFile/Rebranded/UploadFile";
import { useQueryClient } from "react-query";
import { useGetMerchantById } from "@hooks/enterprise-api/account/useGetMerchants";
import { getIsOwnerCheckedByDefault } from "@sections/VerifyAccountHolder_v2/form/form.const";
import { useUploadFiles } from "@hooks/upload-api/uploadHooks";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

export default function usePersonalInformation() {
  const stepperRef = useRef<any>(null);
  const queryClient = useQueryClient();
  const handleNext = () => stepperRef.current?.handleNext();
  const handleBack = () => stepperRef.current?.handleBack();

  const { data: files } = useGetIdentificationFiles();
  const { merchantId } = useGetCurrentMerchantId();
  const { onSubmitInformation } = useManageApi(merchantId);
  const { handleUpload } = useUploadFiles();
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { populateSnackbarFiles } = useFileUploadContext();
  const [step, setStep] = useState<personalInfoEnum>(
    personalInfoEnum.PERSONAL_INFORMATION,
  );
  const { data: enterpriseData } = useGetMerchantById({
    merchantId,
  });
  const { data: legalEntity } = useFindBusinessProfileById({
    id: enterpriseData?.legalEntityID,
    merchantId,
  });

  const leType = legalEntity?.type?.name;

  const principal =
    legalEntity?.principals &&
    legalEntity?.principals?.find((user: any) => !!user?.ownerID);
  const isPrincipalSaved = principal && !isEmpty(principal);
  const fullOwnershipReleased = legalEntity?.principals?.reduce(
    (acc: number, v: any) => acc + v.ownershipPercentage,
    0,
  );
  const personalTabDefaultData = generatePersonalInfoTabDefaultData(
    enterpriseData?.owner,
    principal,
    legalEntity?.type?.name,
    fullOwnershipReleased,
  );

  const [personalInfoStatusBar, setPersonalInfoStatusBar] = useState([
    {
      label: personalInfoEnum.PERSONAL_INFORMATION,
      barValue: 0,
    },
    {
      label: personalInfoEnum.UPLOAD_YOUR_ID,
      barValue: 0,
    },
    {
      label: personalInfoEnum.TAKE_A_SELFIE,
      barValue: 0,
    },
  ]);

  const handleUpdateStatusValue = useCallback(
    (label: string) => (value: number) => {
      setPersonalInfoStatusBar((prev) => {
        let oldArr = prev;
        const index = oldArr.findIndex((obj) => obj.label === label);

        if (index !== -1) {
          oldArr = [
            ...oldArr.slice(0, index),
            { ...oldArr[index], barValue: value },
            ...oldArr.slice(index + 1),
          ];
        }
        return oldArr;
      });
    },
    [],
  );
  type dataOld = PersonalInfoTabDefaultData | IFileWithMeta[] | null;
  type dataNew = PersonalInfoTabDefaultData | File | null;
  const onHandleSubmit = async (
    tab: personalInfoEnum[keyof personalInfoEnum],
    data: dataOld | dataNew,
  ) => {
    if (tab === personalInfoEnum.PERSONAL_INFORMATION) {
      const payload = generatePayload(tab, data);

      return onSubmitInformation(
        {
          ...payload,
          legalEntityId: legalEntity?.id,
          principalId: principal?.id,
        },
        () => {
          setStep(personalInfoEnum.UPLOAD_YOUR_ID);
        },
      );
    }
    if (tab === personalInfoEnum.UPLOAD_YOUR_ID) {
      if (isFileUploadRefactorEnabled) {
        const uploadRes = await handleUpload(
          {
            list: [{ file: data as File }],
            merchantId,
            resourceID: merchantId,
            attachmentType: "account_owner",
            label: "",
            tag: "Acquirer document",
            refetcherKey: ["get identification files"],
          },
          challengeSlugs.PRIMARY_5,
        );

        queryClient.invalidateQueries(["get identification files", merchantId]);
        handleUpdateStatusValue(personalInfoEnum.UPLOAD_YOUR_ID)(100);
        return uploadRes;
      } else {
        if (Array.isArray(data)) {
          const fileArr = data.filter(
            (doc: any) => doc?.attTypeName === "account_owner",
          );
          if (!isEmpty(fileArr) && !isEmpty(enterpriseData)) {
            await deleteDocumentsInLoop(fileArr, enterpriseData?.accID);
          }

          const uploadRes = await populateSnackbarFiles(
            {
              fileWithMeta: data[0],
              status: "done",
              allFiles: data,
              attachmentType: "account_owner",
              label: "",
              tag: "Acquirer document",
              merchantId,
              resourceID: merchantId,
            },
            mime.image,
            challengeSlugs.PRIMARY_5,
          );
          if (uploadRes === "upload_failed") return uploadRes;
          queryClient.invalidateQueries([
            "get identification files",
            merchantId,
          ]);
          handleUpdateStatusValue(personalInfoEnum.UPLOAD_YOUR_ID)(100);
          return uploadRes;
        }
      }
    }
  };

  return {
    personalInfoStatusBar,
    step,
    setStep,
    stepperRef,
    isLoaded,
    setIsLoaded,
    handleNext,
    handleBack,
    handleUpdateStatusValue,
    onHandleSubmit,
    personalTabDefaultData,
    files,
    enterpriseData,
    isIndividualSoleProprietorship: leType === "individual_sole_proprietorship",
    leType,
    isPrincipalSaved,
    fullOwnershipReleased,
  };
}

const generatePersonalInfoTabDefaultData = (
  owner: any,
  principal: any,
  leType: string,
  fullOwnershipReleased: number,
) => {
  const address = principal?.address;
  const phoneNumber = owner?.phoneNumber || principal?.phoneNumber;
  return {
    firstName: owner?.firstName || "",
    lastName: owner?.lastName || "",
    DOB: owner?.dateOfBirth === null ? null : fromUnixTime(owner?.dateOfBirth),
    phoneNumber: phoneNumber ? `+${phoneNumber}` : "",
    isOwner: !isEmpty(principal)
      ? true
      : fullOwnershipReleased === 100
      ? false
      : getIsOwnerCheckedByDefault(leType),
    hasManagerialAuthority: owner?.isManagerialAuthority,
    country: address?.country || "US",
    city: address?.city || "",
    street: address?.line1 || "",
    state: address?.state || "",
    zip: address?.zip || "",
    tinType: "ssn",
    ownership:
      leType === "individual_sole_proprietorship"
        ? "100"
        : principal?.ownershipPercentage
        ? `${principal?.ownershipPercentage}`
        : "10",
    ...(principal?.tinType === "ein" && {
      taxID: principal?.taxIDNumberLast4,
    }),
    ...(principal?.tinType === "ssn" && {
      ssn: principal?.taxIDNumberLast4,
    }),
    isNotUSResident: !!owner?.citizenship,
    citizenship: owner?.citizenship || "",
    isNotResidentInCitizenshipCountry: !!owner?.countryOfResidence,
    countryOfResidence: owner?.countryOfResidence || "",
  };
};

const generatePayload = (
  tab: personalInfoEnum[keyof personalInfoEnum],
  data: any,
) => {
  const phoneNumber = data?.phoneNumber?.slice(1).replaceAll(" ", "");
  switch (tab) {
    case personalInfoEnum.PERSONAL_INFORMATION:
      return {
        isOwner: data?.isOwner,
        tinType: data?.tinType,
        base: {
          ...(data?.DOB && { dob: data?.DOB }),
          firstName: data?.firstName,
          isManager: data?.hasManagerialAuthority,
          lastName: data?.lastName,
          ...(phoneNumber?.length > 3 && {
            phoneNumber: phoneNumber,
          }),
          citizenship: data?.isNotUSResident ? data?.citizenship : "",
          countryOfResidence: data?.isNotResidentInCitizenshipCountry
            ? data?.countryOfResidence
            : "",
        },
        ...(data?.isOwner && {
          whenIsOwner: {
            address: {
              country: data?.country,
              city: data?.city,
              line1: data?.street,
              state: data?.state,
              zip: data?.zip,
            },
            ...(data?.ssn &&
              isValidSSNTaxID(data?.ssn) && {
                ssn: data?.ssn,
              }),
            ...(data?.taxID &&
              isValidSSNTaxID(data?.taxID) && {
                ein: data?.taxID,
              }),
            ownership: data?.ownership,
          },
        }),
      };
    case personalInfoEnum.TAKE_A_SELFIE:
      return {};
    case personalInfoEnum.UPLOAD_YOUR_ID:
      return {};
  }
};

const isValidSSNTaxID = (taxID: string) => {
  return taxID?.replace(/\D/g, "")?.length === 9;
};
