import React from "react";
import BaseCardComponent from "../BaseCardComponent";
import useSnapShot from "features/Merchants/MerchantSidePanel/hooks/useSnapShot";

function MerchantInfo() {
  const { merchantInfo } = useSnapShot();
  return <BaseCardComponent leftTitle="Merchant Info" payload={merchantInfo} />;
}

export default MerchantInfo;
