import { DialogActions } from "@mui/material";
import { PickersActionBarProps } from "@mui/x-date-pickers/PickersActionBar";
import GiveButton from "@shared/Button/GiveButton";
import { format } from "date-fns";

type TCustomActionsProps = PickersActionBarProps & {
  setValue: (value: any) => void;
  value: any;
};

const CustomActions = (props: TCustomActionsProps) => {
  const { onAccept, onCancel, className, setValue, value } = props;

  const handleOnAccept = () => {
    const formattedDate = format(value, "MM/dd/yyyy");
    setValue(formattedDate);
    onAccept();
  };

  return (
    <DialogActions className={className}>
      <GiveButton
        variant="ghost"
        size="large"
        label="Cancel"
        onClick={onCancel}
      />
      <GiveButton
        variant="filled"
        size="large"
        label="Ok"
        color="light"
        onClick={handleOnAccept}
      />
    </DialogActions>
  );
};

export default CustomActions;
