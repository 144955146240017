import { Stack } from "@mui/material";
import { GiveProgressTabProps } from "./GiveTab.types";
import SnackbarLinearProgress from "@shared/Snackbar/SnackbarLinearProgress";
import GiveText from "@shared/Text/GiveText";
import { Check, Warning } from "@phosphor-icons/react";
import { useAppTheme } from "@theme/v2/Provider";

export default function GiveProgressTab({
  label,
  value,
  onClick,
  disabled,
  selected,
  hidden,
  progress,
  done,
  warning,
  hideMobileTitle,
}: GiveProgressTabProps) {
  const { palette } = useAppTheme();
  if (hidden) return null;
  return (
    <Stack
      direction="column"
      gap="12px"
      sx={{
        cursor: "pointer",
        flex: 1,
        ...(disabled && { pointerEvents: "none" }),
      }}
      onClick={() => onClick?.(value)}
    >
      <SnackbarLinearProgress progress={!progress || done ? 0 : progress} />
      {!hideMobileTitle && (
        <Stack direction="row" alignItems="center" gap="4px">
          {done && <Check color={palette.text.secondary} size={14} />}
          {warning && (
            <Warning color={palette.primitive?.warning[50]} size={14} />
          )}
          <GiveText
            variant="bodyXS"
            color={selected ? "primary" : "secondary"}
            sx={{ ...(disabled && { color: palette.text.tertiary }) }}
          >
            {label}
          </GiveText>
        </Stack>
      )}
    </Stack>
  );
}
