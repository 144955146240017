import { BreakpointsOptions } from "@mui/material";

export const breakpoints: BreakpointsOptions = {
  values: {
    v2_sm: 0,
    v2_sm_md: 0,
    v2_md: 0,
    v2_lg: 0,
    v2_xl: 0,
    //new breakpoints
    new_xs: 0,
    new_sm: 600,
    new_sm_md: 740,
    new_md: 860,
    new_lg: 1024,
    new_xl: 1400,
    //old breakpoints
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};
