import { styled } from "@mui/material/styles";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { Stack } from "@mui/material";
import { Check } from "@phosphor-icons/react";

export type SelectItemProps = MenuItemProps & {
  helperText?: string;
  error?: boolean;
  warn?: boolean;
  showCheckIcon?: boolean;
  hoverBgColor?: string;
};
export const SelectItem = styled(
  ({
    helperText,
    error,
    warn,
    children,
    showCheckIcon = false,
    ...rest
  }: SelectItemProps) => {
    return (
      <MenuItem {...rest}>
        <Stack justifyContent="space-between" direction="row">
          {children}
          {rest.selected && showCheckIcon && <Check size={20} />}
        </Stack>
        {helperText && (
          <Text
            sx={{
              color: palette.neutral[70],
              fontWeight: 350,
              fontSize: "12px",
              fontFamily: "Give Whyte",
            }}
          >
            {helperText}
          </Text>
        )}
      </MenuItem>
    );
  },
  {
    shouldForwardProp: (prop) =>
      prop !== "error" && prop !== "warn" && prop !== "hoverBgColor",
  },
)(({ theme, error, hidden, warn, hoverBgColor }) => ({
  minHeight: "auto",
  fontSize: "14px",
  padding: "4px 8px",
  borderRadius: "4px",
  border: "1px solid transparent",
  display: hidden ? "none" : "block",

  [theme.breakpoints.down("sm")]: {
    "& .MuiTypography-root ": {
      lineHeight: "30px",
    },
  },

  "& > #select-item": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& > svg": {
      display: "inline",
    },
  },

  "&:not(:last-of-type)": {
    marginBottom: "2px",
  },

  "&:hover": {
    color: "initial",
    background: hoverBgColor ? hoverBgColor : palette.neutral.white,

    ...(error && {
      border: "1px solid transparent",
      background: `${theme.palette.error.light}`,
    }),
    ...(warn && {
      color: `${palette.tag.error.text}`,
      background: `${palette.tag.error.bg}`,
    }),
  },
  "&.Mui-selected": {
    color: "initial",
    background: hoverBgColor ? "initial" : "none !important",

    "&:hover": {
      color: "initial",
      background: hoverBgColor ? hoverBgColor : palette.neutral.white,
    },
  },
}));
