import { Stack } from "@mui/material";
import { ChatsCircle, PaperPlaneTilt } from "@phosphor-icons/react";
import GiveAvatar from "@shared/Avatar/GiveAvatar";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import ItemText from "./components/ItemText";
import TitleLabel from "./components/TitleLable";
import { useNotifications } from "features/Merchants/MerchantSidePanel/WithRepository/Challenges/hooks/useNotifications";

interface Props {
  title: string;
  imageUrl?: string;
  panelType?: "merchantSidePanel" | "other";
}

function SidePanelTitle({ title, imageUrl, panelType }: Props) {
  const { isMobileView } = useCustomThemeV2();
  const { handleOpenConversationsModal } = useConversationsModal();
  const { openNotifyMerchantModal } = useNotifications();

  if (panelType === "merchantSidePanel") {
    const handleClick = () => {
      openNotifyMerchantModal();
    };
    const actionItemList = [
      {
        name: "Notify Merchant",
        Icon: PaperPlaneTilt,
        onClick: handleClick,
      },
      {
        name: "View All Conversations",
        Icon: ChatsCircle,
        onClick: handleOpenConversationsModal,
      },
    ];

    return (
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems={isMobileView ? "start" : "end"}
        maxWidth="100%"
      >
        <Stack flexShrink={1} overflow="hidden">
          <TitleLabel title={title} />
          <Stack
            flexDirection={!isMobileView ? "row" : "column"}
            gap={!isMobileView ? "21px" : "20px"}
            mt="16px"
          >
            {actionItemList.map((item) => {
              const { Icon, name, onClick } = item || {};
              return (
                <Stack
                  alignItems="center"
                  gap="8px"
                  flexDirection="row"
                  key={name}
                  onClick={onClick as any}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <Icon size={15} />
                  <ItemText text={name} />
                </Stack>
              );
            })}
          </Stack>
        </Stack>

        <GiveAvatar
          imageUrl={imageUrl}
          name={title}
          shape="square"
          size="72px"
        />
      </Stack>
    );
  } else return <div> other panel titles created here</div>;
}

export default SidePanelTitle;
