import { Stack } from "@mui/material";
import SectionHeader from "./SectionHeader";
import { HandHeart } from "@phosphor-icons/react";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme } from "@theme/v2/Provider";
import GiveLink from "@shared/Link/GiveLink";
import GiveCheckbox from "@shared/GiveCheckbox/GiveCheckbox";
import { Controller, useFormContext } from "react-hook-form";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import NiceModal from "@ebay/nice-modal-react";
import { CREDIT_CARD_FEES_MODAL } from "modals/modal_names";

const SupportPreview = ({ fees }: { fees: string }) => {
  const { isDesktopView } = useCustomThemeV2();
  const { palette } = useAppTheme();
  const { methods: leftSidepanelMethods } = usePayBuilderForm();
  const methods = useFormContext();
  const { optionalMessage: supportOptionalMessage } =
    leftSidepanelMethods.watch().Checkout.support;

  return (
    <Stack spacing="20px">
      <SectionHeader
        title="Support"
        description={supportOptionalMessage}
        icon={<HandHeart size={24} />}
      />
      <Stack
        padding="16px"
        borderRadius="12px"
        border={`1px solid ${palette.border?.secondary}`}
        gap="20px"
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" spacing={1}>
            <Controller
              control={methods.control}
              name="customerCoversFess"
              render={({ field: { onChange, value } }) => {
                return <GiveCheckbox checked={value} onChange={onChange} />;
              }}
            />

            <Stack spacing={1}>
              <GiveText
                variant="bodyS"
                maxWidth={isDesktopView ? "100%" : "70%"}
              >
                I wish to cover the cost of the credit card fees
              </GiveText>
              <GiveLink
                color="primitive.blue.100"
                onClick={() => NiceModal.show(CREDIT_CARD_FEES_MODAL)}
              >
                Learn More About the Fees
              </GiveLink>
            </Stack>
          </Stack>
          <GiveText variant="bodyS">${fees}</GiveText>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SupportPreview;
