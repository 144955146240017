import SectionCardBase from "@shared/SidePanel/components/SectionCard/SectionCardBase";
import { ISectionItem } from "../hooks/useGetSectionItems";
import { Grid } from "@mui/material";
import GiveDivider from "@shared/GiveDivider/GiveDivider";
import { GiveSectionItem } from "../GiveSectionItem";
import NiceModal from "@ebay/nice-modal-react";
import {
  BUSINESS_PROFILE_MISSING_FIELDS_MODAL,
  CHANGE_PROFILE_STATUS_MODAL,
  CONFIRMATION_POP_UP,
  CREATE_BUSINESS_PROFILE_MODAL,
} from "modals/modal_names";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { IParsedData } from "@components/Merchants/MerchantPreview/data.types";
import { useMemo, useState } from "react";
import ContextualMenu from "@shared/ContextualMenu/ContextualMenu";
import RESOURCE_BASE, {
  EDIT_DENY_BY_OFAC,
  EDIT_DENY_MESSAGE,
  FEATURE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { useBusinessProfileActions } from "@components/Merchants/MerchantPreview/hooks/useBusinessProfileActions";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import { useUpdateBusinessProfileStatus } from "@components/Merchants/MerchantPreview/hooks/useUpdateBusinessProfileStatus";
import { BusinessProfileStatusType } from "@common/Tag/BusinessProfileTag";
import GiveBusinessProfileTag from "./GiveBusinessProfileTag";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { checkPortals } from "@utils/routing";

const BusinessProfileSection = ({
  items,
  data,
  id,
  isEnterprise,
}: {
  items: ISectionItem[];
  data: IParsedData;
  id: number;
  isEnterprise: boolean;
}) => {
  //TODO when we have the rebranded modals to refactor the logic and separet to a new hook
  const { openConversationHandler } = useConversationsModal();

  const { isHidden, isPending } = useBusinessProfileActions(data);

  const { handleUpdateStatus } = useUpdateBusinessProfileStatus({
    merchantId: id,
    data,
  });

  const { merchant_underwriting } = useEnterprisePermissions();
  const { isAcquirerPortal } = checkPortals();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isAllowedUpdate = useAccessControl({
    resource: composePermission(
      isEnterprise ? RESOURCE_BASE.ENTERPRISE : RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.LEGAL_ENTITY,
    ),
    operation: OPERATIONS.UPDATE,
  });

  const editBusinessProfile = () =>
    NiceModal.show(CREATE_BUSINESS_PROFILE_MODAL, {
      merchantId: id,
      data: {
        ...data.businessProfile,
        address: data.businessAddress,
      },
      legalEntityID: data.businessProfile.id,
      isLegalEntityApproved:
        data.header.businessProfileStatus.status?.toLowerCase() === "approved",
      isEdit: true,
      isBPLinked: data.businessProfile?.isBPLinked,
    });

  const handleApprove = () => {
    const mainPrincipal = data?.businessOwnersList?.[0];

    const missingFields = [
      {
        field: "Federal Tax ID / SSN",
        missing: !data?.businessProfile?.taxID && !data?.businessProfile?.ssn,
      },
      {
        field: "Business Legal Name",
        missing: !data?.businessProfile?.legalName,
      },
      { field: "Business Type", missing: !data?.businessProfile?.businessType },
      {
        field: "Date business opened",
        missing: !data?.businessProfile?.businessOpenedAt,
      },
      { field: "Contact Phone", missing: !data?.businessProfile?.contactPhone },
      { field: "Business Address", missing: !data?.businessAddress?.address },
      {
        field: "Business Ownership",
        missing: data?.businessOwnersList?.length === 0,
      },
      {
        field: "First Business Owner first name",
        missing:
          data?.businessOwnersList?.length !== 0 && !mainPrincipal?.firstName,
      },
      {
        field: "First Business Owner last name",
        missing:
          data?.businessOwnersList?.length !== 0 && !mainPrincipal?.lastName,
      },
      {
        field: "First Business Owner Ownership",
        missing:
          data?.businessOwnersList?.length !== 0 && !mainPrincipal?.ownership,
      },
      {
        field: "First Business Owner SSN/EIN",
        missing:
          data?.businessOwnersList?.length !== 0 &&
          !mainPrincipal?.ssn &&
          !mainPrincipal?.ein,
      },
      {
        field: "First Business Owner Date of Birth",
        missing: data?.businessOwnersList?.length !== 0 && !mainPrincipal?.dob,
      },
    ];

    const isMissingFields = missingFields.some((field) => field.missing);

    if (isMissingFields) {
      NiceModal.show(BUSINESS_PROFILE_MISSING_FIELDS_MODAL, {
        fieldsList: missingFields,
      });
      return;
    }
    NiceModal.show(CONFIRMATION_POP_UP, {
      variant: "approve",
      onClick: () => handleUpdateStatus("approved"),
      isEnterprise: isEnterprise,
    });
  };

  const openConfirmationHandler = (status: BusinessProfileStatusType) => {
    NiceModal.show(CHANGE_PROFILE_STATUS_MODAL, {
      status: status,
      id: id,
      merchantName: data.merchantInfo.merchantName,
      data: data,
      isEnterprise: isEnterprise,
    });
  };

  const status = data?.businessProfile.statusName;
  const isOfacConfirmedMatch =
    data?.businessProfile?.ofac?.lastCheckStatusName === "confirmed_match";

  const editDenyMessage = isOfacConfirmedMatch
    ? EDIT_DENY_BY_OFAC
    : EDIT_DENY_MESSAGE;

  const isEditDisabled =
    isOfacConfirmedMatch || status === "declined" || !isAllowedUpdate;
  const threeDotActions = useMemo(() => {
    return [
      {
        text: "Edit",
        hidden: false,
        disabled: isEditDisabled,
        onClick: editBusinessProfile,
        tooltipTitle: editDenyMessage,
        disableTooltip: !isEditDisabled,
      },
      {
        text: "Approve",
        hidden: isHidden({ actionItem: "approved" }),
        disabled: isPending || !isAllowedUpdate,
        onClick: handleApprove,
        tooltipTitle: FEATURE_DENY_MESSAGE,
        disableTooltip: isAllowedUpdate,
      },
      {
        text: "Decline",
        hidden: isHidden({ actionItem: "declined" }),
        disabled: isPending || !isAllowedUpdate,
        onClick: () => openConfirmationHandler("declined"),
        tooltipTitle: FEATURE_DENY_MESSAGE,
        disableTooltip: isAllowedUpdate,
      },
      {
        text: "Suspend",
        hidden: isHidden({ actionItem: "suspended" }),
        onClick: () => openConfirmationHandler("suspended"),
        disabled: !isAllowedUpdate,
        tooltipTitle: FEATURE_DENY_MESSAGE,
        disableTooltip: isAllowedUpdate,
      },
      {
        text: "Move Back to Pending",
        description: "Ask the user to update or add more information",
        hidden: isHidden({ actionItem: "move_back_to_pending" }),
        onClick: () => openConfirmationHandler("move_back_to_pending"),
        disabled: !isAllowedUpdate,
        tooltipTitle: FEATURE_DENY_MESSAGE,
        disableTooltip: isAllowedUpdate,
      },
    ];
  }, [id, data]);

  const actions = {
    handleOpenConversation: (e: React.MouseEvent) => {
      e.stopPropagation();
      openConversationHandler({ id, name: "Business Profile", paths: [] });
    },
    handleEdit: editBusinessProfile,
    handleDotsThree: (e: React.MouseEvent<HTMLElement>) =>
      setAnchorEl(e.currentTarget),
  };
  const disabeledActions = {
    handleOpenConversation: {
      hidden: !merchant_underwriting && !isAcquirerPortal,
    },
    handleEdit: {
      disabled: isEditDisabled,
      tooltipTitle: editDenyMessage,
      disableTooltip: !isEditDisabled,
    },
  };

  return (
    <SectionCardBase
      leftTitle="Business Profile"
      actions={actions}
      disabeledActions={disabeledActions}
      tag={<GiveBusinessProfileTag statusCode={status} />}
    >
      <Grid container spacing={2}>
        {items.map((item, index) => (
          <>
            {item.split && <GiveDivider key={`${index}-${item.title}`} />}
            <GiveSectionItem key={`${item.title}-${index}`} item={item} />
          </>
        ))}
      </Grid>
      <ContextualMenu
        handleClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        color="primary"
        texture="blurred"
        options={threeDotActions}
      />
    </SectionCardBase>
  );
};

export default BusinessProfileSection;
