import { Box, Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { Grid } from "@mui/material";
import { ColorPickerInput } from "@sections/PayBuilder/components/ColorPickerInput";
import { List } from "./components/List";
import { Card } from "./components/Card";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

export function Styling() {
  const { methods } = usePayBuilderForm();
  const { setValue, watch } = methods;
  const { isMobileView } = useCustomThemeV2();

  const { accent, background } = watch().Style;

  return (
    <Box>
      <GiveText mt="40px" fontWeight={271} fontSize="28px" color="primary">
        Style your Form
      </GiveText>

      <Stack gap="24px" mt="40px">
        <Grid container justifyContent="space-between" gap={1}>
          <Stack sx={{ width: "48%" }} gap={1}>
            <GiveText>Background</GiveText>
            <ColorPickerInput
              onSave={(val: string) => {
                setValue("Style.background", val);
              }}
              defaultColor={background}
            />
          </Stack>
          <Stack sx={{ width: "48%" }} gap={1}>
            <GiveText>Accent</GiveText>
            <ColorPickerInput
              onSave={(val: string) => {
                setValue("Style.accent", val);
              }}
              defaultColor={accent}
            />
          </Stack>
        </Grid>
        <Stack gap={1}>
          <GiveText>Item Layout</GiveText>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item sx={{ width: isMobileView ? "100%" : "48%" }}>
              <List />
              <GiveText color="secondary">List</GiveText>
            </Grid>
            <Grid item sx={{ width: isMobileView ? "100%" : "48%" }}>
              <Card />
              <GiveText color="secondary">Card</GiveText>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Box>
  );
}
