export const getCustomFormControlStyle = (palette: any, basePallete: any) => {
  return {
    styleOverrides: {
      root: {
        ".MuiFormHelperText-root": {
          margin: 0,
          textTransform: "none",
          paddingInline: "12px",
          fontSize: "12px",
          marginTop: "4px",
          color: palette.text.tertiary,
          lineHeight: "14px",
          fontFamily: "Give Whyte",
          fontWeight: 400,
          fontStyle: "normal",
          paddingLeft: 0,
          marginLeft: 0,
        },
        ".MuiFormLabel-root": {
          marginBottom: "8px",
          fontSize: "14px",
          color: palette.text.primary,
        },
        ".MuiFormHelperText-root.Mui-error, .MuiFormLabel-root.Mui-error": {
          color: basePallete.primitive.error["100"],
        },
        "& .MuiFormLabel-root.Mui-disabled": {
          color: palette.text.primary,
        },
      },
    },
  };
};
