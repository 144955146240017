import { yupResolver } from "@hookform/resolvers/yup";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { addressFields } from "../consts";

const useCheckoutPreview = () => {
  const { methods: checkoutSidepanelMethods } = usePayBuilderForm();

  const checkoutSidepanelValues = checkoutSidepanelMethods.watch();

  const { render: isPhoneNumberRendered, required: isPhoneNumberRequired } =
    checkoutSidepanelValues.Checkout.phoneNumber;

  const schema = Yup.object().shape({
    email: Yup.string().email().required(),
    ...(isPhoneNumberRendered && {
      phoneNumber: Yup.string().when({
        is: () => {
          return isPhoneNumberRequired;
        },
        then: () => Yup.string().required(),
      }),
    }),
  });

  const methods = useForm({
    defaultValues: {
      email: "",
      phoneNumber: "",
      payment: {
        cardNumber: "",
        expirationDate: "",
        cvv: "",
        nameOnCard: "",
        useDeliveryAdderess: true,
        billingAddress: { ...addressFields },
      },
      deliveryAddress: { ...addressFields, phoneNumber: "" },
      customerCoversFess: false,
      termsConditions: false,
    },
    resolver: yupResolver(schema),
  });

  return {
    methods,
  };
};

export default useCheckoutPreview;
