import * as React from "react";
// @mui
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { Modal } from "@common/Modal";
import { IconButton } from "@common/IconButton";
import { Tabs, TabPanel, ButtonTab } from "@common/Tabs";
import AddToYourWebsite from "./AddToYourWebsite";
import CustomLink from "./CustomLink";
// assets
import {
  CodeIcon,
  FacebookIcon,
  HomeIcon,
  LinkedInIcon,
  PinterestIcon,
  ShareIcon,
  TwitterIcon,
} from "@assets/icons";
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import { ShareProps } from "./share.types";
//TODO: refactor needed to avoid ugly workarounds

const btnStyle = {
  borderRadius: "110.769px",
  boxShadow:
    "5.71429px 5.71429px 11.4286px rgba(114, 142, 171, 0.1), -5.71429px -5.71429px 57.1429px #FFFFFF, 11.4286px 11.4286px 45.7143px rgba(111, 140, 176, 0.41)",
};

const mobileBtnStyle = {
  boxShadow:
    "3.9585px 3.9585px 7.917px rgba(114, 142, 171, 0.1), -3.9585px -3.9585px 39.585px #FFFFFF, 7.917px 7.917px 31.668px rgba(111, 140, 176, 0.41)",
  borderRadius: "80px",
};

const Share = NiceModal.create(({ title, productId, type }: ShareProps) => {
  const modal = useModal();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Modal
      width="736px"
      {...muiDialogV5(modal)}
      title={title}
      onClose={() => modal.hide()}
      transitionDuration={{ appear: 0, enter: 0, exit: 200 }}
      TransitionComponent={Fade}
    >
      <AddToYourWebsite productId={productId} type={type} />
    </Modal>
  );

  // return (
  //   <Modal
  //     width="736px"
  //     {...muiDialogV5(modal)}
  //     title={title}
  //     onClose={() => modal.hide()}
  //     transitionDuration={{ appear: 0, enter: 0, exit: 200 }}
  //     TransitionComponent={Fade}
  //   >
  //     <Box mt={1} mb={2}>
  //       <Tabs
  //         gap={2}
  //         centered
  //         value={value}
  //         variant="fullWidth"
  //         onChange={handleChange}
  //         aria-label="share fundraiser options"
  //         orientation={isDesktop ? "horizontal" : "vertical"}
  //       >
  //         <ButtonTab
  //           icon={<ShareIcon width={32} height={32} />}
  //           iconPosition="start"
  //           label="Share on Social"
  //         />
  //         <ButtonTab
  //           icon={<CodeIcon />}
  //           iconPosition="start"
  //           label="Get a Custom Link"
  //         />
  //         <ButtonTab
  //           icon={<HomeIcon />}
  //           iconPosition="start"
  //           label="Add to your website"
  //         />
  //       </Tabs>
  //     </Box>
  //     <TabPanel index={0} value={value}>
  //       <Stack
  //         gap={2}
  //         height={128}
  //         direction="row"
  //         alignItems="center"
  //         justifyContent="center"
  //       >
  //         <IconButton size="large" sx={isDesktop ? btnStyle : mobileBtnStyle}>
  //           <FacebookIcon
  //             width={isDesktop ? 50 : 34.64}
  //             height={isDesktop ? 50 : 34.64}
  //           />
  //         </IconButton>
  //         <IconButton size="large" sx={isDesktop ? btnStyle : mobileBtnStyle}>
  //           <TwitterIcon
  //             width={isDesktop ? 50 : 34.64}
  //             height={isDesktop ? 50 : 34.64}
  //           />
  //         </IconButton>
  //         <IconButton size="large" sx={isDesktop ? btnStyle : mobileBtnStyle}>
  //           <PinterestIcon
  //             width={isDesktop ? 50 : 34.64}
  //             height={isDesktop ? 50 : 34.64}
  //           />
  //         </IconButton>
  //         <IconButton size="large" sx={isDesktop ? btnStyle : mobileBtnStyle}>
  //           <LinkedInIcon
  //             width={isDesktop ? 50 : 34.64}
  //             height={isDesktop ? 50 : 34.64}
  //           />
  //         </IconButton>
  //       </Stack>
  //     </TabPanel>
  //     <TabPanel index={1} value={value}>
  //       <CustomLink />
  //     </TabPanel>
  //     <TabPanel index={2} value={value}>
  //       <AddToYourWebsite productId={productId} />
  //     </TabPanel>
  //   </Modal>
  // );
});

export default Share;
