import { showMessage } from "@common/Toast";
import useCheckLastUnseen from "@components/VirtualList/hooks/useBlockedTransactions";
import { useGetCurrentMerchantId } from "@hooks/common";
import {
  TBlockTransaction,
  blockTransaction,
} from "@services/api/riskProfile/riskProfileActions";
import { checkPortals } from "@utils/routing";
import { useMutation, useQueryClient } from "react-query";
import { processTransactionData } from "../../TransactionInfoModal/utils";
import NiceModal from "@ebay/nice-modal-react";
import { TRANSACTION_INFO_MODAL } from "modals/modal_names";

type Props = {
  isFirst?: boolean;
  isLast?: boolean;
  setSelectedRow?: React.Dispatch<any>;
};

export const useMarkAsFraudActions = ({
  isFirst,
  isLast,
  setSelectedRow,
}: Props) => {
  const queryClient = useQueryClient();
  const { merchantId } = useGetCurrentMerchantId();
  const { setUnseenTransactions } = useCheckLastUnseen(merchantId);
  const { isAcquirerPortal } = checkPortals();

  const markAsFraudMutation = useMutation(
    (data: TBlockTransaction) => {
      return blockTransaction({
        merchantId: data.merchantId,
        id: data.id,
        reason: data.reason,
        canNotifyMerchant: data.canNotifyMerchant,
        skipRefund: data.skipRefund,
      });
    },
    {
      onSuccess: (transactionData) => {
        if (isAcquirerPortal) setUnseenTransactions();

        showMessage(
          "Success",
          "purchase have been successfully Marked as Fraud",
        );

        const parsedData = transactionData
          ? processTransactionData(transactionData)
          : null;

        if (parsedData?.displayStatus === "Refunded") {
          NiceModal.show(TRANSACTION_INFO_MODAL, {
            data: { ...parsedData, transactionID: parsedData?.id },
            isFirst: setSelectedRow ? isFirst : true,
            isLast: setSelectedRow ? isLast : true,
            setSelectedRow,
          });
        }

        queryClient.refetchQueries("get-transactions-risk-profile");
        queryClient.refetchQueries("get-risk-transactions-list");
        queryClient.refetchQueries("has-more-transactions");
        queryClient.refetchQueries("acquirer-processing-transactions");
        queryClient.refetchQueries([
          "get-transaction-history",
          transactionData?.id,
        ]);
      },
      onError: (error: any) => {
        showMessage(
          "Error",
          error?.message || "Whoops.. an error occured. Please try again",
        );
      },
    },
  );

  return { markAsFraudMutation };
};
