import { customInstance } from "@services/api";
import { buildMerchantEndpoints } from "@services/api/utils.api";

export const prepareBasePath = (
  queryParams: any,
  shouldAddPageParam = true,
  pageParam?: number,
) => {
  const { sorting, path, rowsPerPage, queryString, searchQuery, filter, max } =
    queryParams;

  const sortQuery = sorting ? `sort=${sorting}` : "";
  let basePath = `${path}?${sortQuery}${
    shouldAddPageParam ? `&page=${pageParam}&max=${rowsPerPage}` : ""
  }`;

  if (queryString) {
    basePath += `&filter=${queryString}`;
  }

  if (searchQuery) {
    basePath += `&q="${searchQuery}"`;
  }
  if (filter) {
    basePath += `&filter=${filter}`;
  }

  // if page param is not added we should send total amount of merchants to avoid default limit of 100
  if (max && !shouldAddPageParam) {
    basePath += `&max=${max}`;
  }

  return basePath;
};

export const queryBuilder =
  (queryParams: any) =>
  async ({ pageParam = 1 }) => {
    const basePath = prepareBasePath(queryParams, true, pageParam);
    const pathParamId = queryParams.merchantId;
    const url = pathParamId
      ? buildMerchantEndpoints(basePath, pathParamId)
      : basePath;

    const data = await customInstance({
      url: url,
      method: "GET",
    });

    const numberOfPages = Math.ceil(Number(data.total ?? 0) / 20);

    return {
      data: data.data,
      nextCursor: numberOfPages >= pageParam + 1 ? pageParam + 1 : null,
      total: data.total,
    };
  };

export const queryFunctionBuilder =
  (queryParams: any) =>
  async ({ pageParam = 1 }) => {
    const merchantId = queryParams?.merchantId || null;

    const { sorting, path, rowsPerPage, queryString, searchQuery, filter } =
      queryParams;

    const sortQuery = sorting ? `sort=${sorting}` : "";
    let basePath = `${path}?${sortQuery}&page=${pageParam}&max=${rowsPerPage}`;

    if (queryString) {
      basePath += `&filter=${queryString}`;
    }

    if (searchQuery) {
      basePath += `&q="${searchQuery}"`;
    }
    if (filter) {
      basePath += `&filter=${filter}`;
    }

    const data = await customInstance({
      url: buildMerchantEndpoints(basePath, merchantId),
      method: "GET",
    });

    const numberOfPages = Math.ceil(Number(data.total ?? 0) / 20);

    return {
      data: data.data,
      nextCursor: numberOfPages >= pageParam + 1 ? pageParam + 1 : null,
      total: data.total,
    };
  };
