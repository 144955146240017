import { getJsonValue, isSecondVersion } from "../utils";
import { convertHexToRGB } from "@utils/theme";
import { ThemeVersions } from "@theme/v2/types";

declare module "@mui/material/Button" {
  interface ButtonOwnProps {
    version?: ThemeVersions;
  }

  interface ButtonPropsVariantOverrides {
    filled: true;
    outline: true;
    ghost: true;
  }

  interface ButtonPropsColorOverrides {
    light: true;
    blue: true;
    destructive: true;
  }
}

type ThemeMode = "light" | "dark";


export const getDefaultButtonStyle = [
  {
    props: (props: any) => isSecondVersion(props.version),
    style: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "20px",
      textTransform: "none",
      minWidth: "74px",
    },
  },
];

export const getLargeButtonStyle = [
  {
    props: (props: any) =>
      isSecondVersion(props.version) && props.size === "large",
    style: {
      borderRadius: "40px",
      padding: "11px 20px",
    },
  },
];

export const getSmallButtonStyle = [
  {
    props: (props: any) =>
      isSecondVersion(props.version) && props.size === "small",
    style: {
      borderRadius: "8px",
      padding: "7px 12px",
    },
  },
];

export const getFilledVariants = (mode: ThemeMode, basePalette: any) => [
  {
    props: (props: any) => props.variant === "filled",
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.colour.buttons.default`),
      color: getJsonValue(`tokens.${mode}.colour.text.invert`),
      border:
        "1px solid " + getJsonValue(`tokens.${mode}.colour.buttons.default`),
      "&:hover": {
        backgroundColor: getJsonValue(
          `tokens.${mode}.colour.buttons.default-hover`,
        ),
      },
      "&:active": {
        color: convertHexToRGB(
          getJsonValue(`tokens.${mode}.colour.text.invert`),
          0.7,
        ),
      },
    },
  },
  {
    props: (props: any) =>
      props.variant === "filled" && props.disabled === true,
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.colour.border.tertiary`),
      color: `${convertHexToRGB(
        getJsonValue(`tokens.${mode}.colour.text.invert`),
        0.7,
      )} !important`,
      cursor: "default",
    },
  },

  {
    props: (props: any) =>
      props.variant === "filled" && props.color === "light",
    style: {
      backgroundColor: getJsonValue(
        `tokens.${mode}.primitive.transparent.darken-5`,
      ),
      color: getJsonValue(`tokens.${mode}.colour.text.primary`),

      "&:hover": {
        backgroundColor: getJsonValue(
          `tokens.${mode}.primitive.transparent.darken-10`,
        ),
      },
      "&:active": {
        color: convertHexToRGB(
          getJsonValue(`tokens.${mode}.colour.text.primary`),
          0.7,
        ),
      },
    },
  },
  {
    props: (props: any) =>
      props.variant === "filled" &&
      props.color === "light" &&
      props.disabled === true,
    style: {
      backgroundColor: getJsonValue(
        `tokens.${mode}.primitive.transparent.darken-5`,
      ),
      color: `${convertHexToRGB(
        getJsonValue(`tokens.${mode}.colour.text.primary`),
        0.5,
      )} !important`,
      cursor: "default",
    },
  },
  {
    props: (props: any) => props.variant === "filled" && props.color === "blue",
    style: {
      background: basePalette.gradient["ocean-blue"].default,
      color: getJsonValue(`tokens.${mode}.colour.text.remain-white`),
      "&:hover": {
        background: basePalette.gradient["ocean-blue"].highlight,
      },
      "&:active": {
        color: convertHexToRGB(
          getJsonValue(`tokens.${mode}.colour.text.remain-white`),
          0.7,
        ),
      },
    },
  },
  {
    props: (props: any) =>
      props.variant === "filled" &&
      props.color === "blue" &&
      props.disabled === true,
    style: {
      background: basePalette.gradient["ocean-blue"].disabled,
      color: `${convertHexToRGB(
        getJsonValue(`tokens.${mode}.colour.text.remain-white`),
        0.4,
      )} !important`,
      cursor: "default",
    },
  },
  {
    props: (props: any) =>
      props.variant === "filled" && props.color === "destructive",
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.primitive.error.100`),
      color: getJsonValue(`tokens.${mode}.colour.text.invert`),
      "&:hover": {
        backgroundColor: getJsonValue(`tokens.${mode}.primitive.error.100`),
      },
      "&:active": {
        color: convertHexToRGB(
          getJsonValue(`tokens.${mode}.colour.text.invert`),
          0.7,
        ),
      },
    },
  },
  {
    props: (props: any) =>
      props.variant === "filled" &&
      props.color === "destructive" &&
      props.disabled === true,
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.primitive.error.100`),
      color: getJsonValue(`tokens.${mode}.colour.text.invert`) + " !important",
      cursor: "default",
      opacity: 0.5,
    },
  },
];

export const getOutlinedVariants = (mode: ThemeMode, basePalette: any) => [
  {
    props: (props: any) => props.variant === "outline",
    style: {
      backgroundColor: "transparent",
      color: getJsonValue(`tokens.${mode}.colour.text.primary`),
      border:
        "1.5px solid " + getJsonValue(`tokens.${mode}.colour.buttons.default`),
      "&:hover": {
        backgroundColor: "transparent",
        borderColor: getJsonValue(`tokens.${mode}.primitive.neutral.50`),
      },
      "&:active": {
        borderColor: getJsonValue(`tokens.${mode}.primitive.neutral.40`),
        color: convertHexToRGB(
          getJsonValue(`tokens.${mode}.colour.text.primary`),
          0.7,
        ),
      },
    },
  },
  {
    props: (props: any) =>
      props.variant === "outline" && props.disabled === true,
    style: {
      backgroundColor: "transparent",
      color: `${getJsonValue(`tokens.${mode}.colour.text.primary`)} !important`,
      border:
        "1.5px solid " + getJsonValue(`tokens.${mode}.primitive.neutral.40`),
      cursor: "default",
      opacity: 0.5,
    },
  },
  {
    props: (props: any) =>
      props.variant === "outline" && props.color === "blue",
    style: {
      background: basePalette.gradient["ocean-blue"].border,
      border: "1.5px solid transparent",
      color: getJsonValue(`tokens.${mode}.primitive.blue.100`),
      "&:hover": {
        border:
          "1.5px solid " + getJsonValue(`tokens.${mode}.primitive.blue.100`),
        color: getJsonValue(`tokens.${mode}.primitive.blue.100`),
        opacity: 0.8,
      },
    },
  },
  {
    props: (props: any) =>
      props.variant === "outline" &&
      props.color === "blue" &&
      props.disabled === true,
    style: {
      background: basePalette.gradient["ocean-blue"].border,
      border: "1.5px solid transparent",
      color: getJsonValue(`tokens.${mode}.primitive.blue.100`) + " !important",
      opacity: 0.4,
      cursor: "default",
    },
  },

  {
    props: (props: any) =>
      props.variant === "outline" && props.color === "destructive",
    style: {
      backgroundColor: "transparent",
      color: getJsonValue(`tokens.${mode}.primitive.error.100`),
      border:
        "1.5px solid " + getJsonValue(`tokens.${mode}.primitive.error.50`),
      "&:hover": {
        backgroundColor: "transparent",
        borderColor: getJsonValue(`tokens.${mode}.primitive.error.100`),
      },
      "&:active": {
        borderColor: getJsonValue(`tokens.${mode}.primitive.error.100`),
        color: convertHexToRGB(
          getJsonValue(`tokens.${mode}.primitive.error.100`),
          0.5,
        ),
      },
    },
  },

  {
    props: (props: any) =>
      props.variant === "outline" &&
      props.color === "destructive" &&
      props.disabled === true,
    style: {
      backgroundColor: "transparent",
      color: getJsonValue(`tokens.${mode}.primitive.error.100`) + " !important",
      border:
        "1.5px solid " + getJsonValue(`tokens.${mode}.primitive.error.50`),
      cursor: "default",
      opacity: 0.5,
    },
  },
];

export const getGhostVariants = (mode: ThemeMode) => [
  {
    props: (props: any) => props.variant === "ghost",
    style: {
      backgroundColor: "transparent",
      color: getJsonValue(`tokens.${mode}.colour.text.primary`),
      "&:hover": {
        background: getJsonValue(
          `tokens.${mode}.primitive.transparent.darken-5`,
        ),
      },
      "&:active": {
        color: convertHexToRGB(
          getJsonValue(`tokens.${mode}.primitive.neutral.90`),
          0.7,
        ),
      },
    },
  },

  {
    props: (props: any) => props.variant === "ghost" && props.disabled === true,
    style: {
      backgroundColor: "transparent",
      color: getJsonValue(`tokens.${mode}.colour.text.primary`),
      cursor: "default",
      opacity: 0.5,
    },
  },

  {
    props: (props: any) =>
      props.variant === "ghost" && props.color === "destructive",
    style: {
      background: "transparent",
      color: getJsonValue(`tokens.${mode}.primitive.error.100`),
      "&:hover": {
        background: getJsonValue(`tokens.${mode}.primitive.error.25`),
      },
      "&:active": {
        color: convertHexToRGB(
          getJsonValue(`tokens.${mode}.primitive.error.100`),
          0.7,
        ),
      },
    },
  },

  {
    props: (props: any) =>
      props.variant === "ghost" &&
      props.color === "destructive" &&
      props.disabled === true,
    style: {
      backgroundColor: "transparent",
      color: getJsonValue(`tokens.${mode}.primitive.error.100`) + " !important",
      cursor: "default",
      opacity: 0.5,
    },
  },
];
