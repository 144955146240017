import React from "react";
import SectionCardBase from "@shared/SidePanel/components/SectionCard/SectionCardBase";

import { RenderTextsComponent } from "../BaseCardComponent";
import { isEmpty } from "lodash";
import useSnapShot from "features/Merchants/MerchantSidePanel/hooks/useSnapShot";

function BusinessOwner() {
  const { businessOwners } = useSnapShot(); // Get business owners from useSnapShot
  if (isEmpty(businessOwners)) return <></>;

  return (
    <>
      <SectionCardBase sx={{ marginTop: "40px" }} leftTitle="Business Owners" />
      {businessOwners.map((owner, index) => (
        <RenderBusinessOwner key={index} owner={owner} />
      ))}
    </>
  );
}

export default BusinessOwner;

const RenderBusinessOwner = ({
  owner,
}: {
  owner: {
    label: string;
    value: string | number;
  }[];
}) => {
  return (
    <SectionCardBase>
      {owner.map((detail, idx) => (
        <RenderTextsComponent
          key={`${detail.label}-${detail.value}`}
          {...detail}
        />
      ))}
    </SectionCardBase>
  );
};
