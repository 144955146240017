import { showMessage } from "@common/Toast";
import { NiceModalHandler } from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { customInstance } from "@services/api";
import { buildMerchantEndpoints } from "@services/api/utils.api";
import * as Yup from "yup";
import { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useRef } from "react";

type FormInputs = {
  original: boolean;
  other: boolean;
  email?: string;
  emails: string[];
};

const resendReceiptFormDefaultValues = {
  original: false,
  other: false,
  email: undefined,
  emails: [],
};

function createReceiptInstance({
  transactionID,
  customerID,
  data,
}: {
  transactionID: string;
  customerID?: number;
  data: any;
}) {
  return customInstance({
    url: buildMerchantEndpoints(
      `transactions/${transactionID}/receipt`,
      customerID,
    ),
    method: "POST",
    data,
  });
}

const useSendReceipt = ({
  transactionID,
  customerID,
  isChargebackReversal,
}: {
  transactionID: string;
  customerID?: number;
  isChargebackReversal?: boolean;
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const createReceiptMutation = useMutation((data: any) =>
    createReceiptInstance({ transactionID, customerID, data }),
  );

  const methods = useForm<FormInputs>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: resendReceiptFormDefaultValues,
  });

  const { watch, setValue, getFieldState, setError, reset } = methods;
  const { email, emails, other } = watch();

  const handleChange = (name: "original" | "other", checked: boolean) => {
    setValue(name, checked);
    if (name === "original" && checked) {
      setValue("other", false);
    }
    if (name === "other" && checked) {
      setValue("original", false);
    }
  };

  const resetForm = () => {
    reset({ ...resendReceiptFormDefaultValues });
  };

  const handleAddEmails = (
    e:
      | React.KeyboardEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (!other) {
      setValue("other", true);
    }
    if (e?.key === "Enter" && email && !getFieldState("email").invalid) {
      if (emails.find((em) => em === email)) {
        setError("email", {
          message: "The same email address is already in use",
        });
        return;
      }

      if (inputRef.current) {
        inputRef.current.value = "";
      }
      setValue("emails", [...emails, email]);
      setValue("email", undefined);
    }
  };

  const handleDeleteEmail = (val: string) => {
    const newList = emails.filter((em) => em !== val);
    setValue("emails", newList);
  };

  const handleSubmit = (
    { recipients }: { recipients: string[] | null },
    modal?: NiceModalHandler<Record<string, unknown>>,
  ) => {
    const data = {
      recipients,
      sendToCustomer: Boolean(isChargebackReversal),
    };

    createReceiptMutation.mutate(data, {
      onError: async (error: unknown) => {
        showMessage("Error", "Sending receipt failed, please try again");
      },
      onSuccess: async (res: any) => {
        showMessage("Success", "Receipt sent successfully");
        resetForm();
        if (modal) modal.hide();
      },
    });
  };

  return {
    handleSubmit,
    handleChange,
    handleAddEmails,
    handleDeleteEmail,
    resetForm,
    methods,
    inputRef,
    isLoading: createReceiptMutation.isLoading,
    isError: createReceiptMutation.isError,
  };
};

export { useSendReceipt, createReceiptInstance };

const schema = Yup.object().shape({
  original: Yup.boolean(),
  other: Yup.boolean(),
  email: Yup.string().email("Invalid email address"),
  emails: Yup.array(Yup.string().email(" ")),
});
