import { showMessage } from "@common/Toast";
import { customInstance } from "@services/api";
import { useMutation, useQueryClient } from "react-query";

type Props = {
  disputeId: string;
  markedAsFraud: boolean;
};

export const useDisputeFraud = ({ disputeId, markedAsFraud }: Props) => {
  const queryClient = useQueryClient();

  const updateDisputeMutation = useMutation(() => {
    return customInstance({
      url: `/disputes/${disputeId}`,
      method: "PATCH",
      data: { markAsFraud: !markedAsFraud },
    });
  });

  const handleMarkAsFraud = () => {
    updateDisputeMutation.mutate(undefined, {
      onError: (error: any) => {
        const errorMessage = error?.response?.data?.message;
        showMessage(
          "Error",
          errorMessage ||
            `Unable to ${markedAsFraud ? "unmark" : "mark"} it as fraud`,
        );
      },
      onSuccess: () => {
        queryClient.refetchQueries(["dispute-preview", disputeId]);
      },
    });
  };

  return { handleMarkAsFraud };
};
