import * as Yup from "yup";
import {
  AmountType,
  DateType,
  LocationType,
  TEventModalInputs,
  TPaymentTypes,
} from "./types";

const paymentTypes: TPaymentTypes[] = [
  "one_time",
  "monthly",
  "quarterly",
  "yearly",
];

export const validateURL = (url: string) => {
  if (!url.match(/^https?:\/\//)) {
    return (url = "https://" + url);
  } else return url;
};

export const EventModalSchema = Yup.object().shape({
  about: Yup.object().shape({
    title: Yup.string().trim().required("This field is required"),
    description: Yup.string(),
  }),
  style: Yup.object().shape({
    image: Yup.mixed().nullable(),
    useAsBackground: Yup.boolean(),
  }),
  date_and_location: Yup.object().shape({
    date: Yup.object().shape({
      type: Yup.mixed<DateType>().oneOf(["one day", "period"]),
      day: Yup.mixed().when("type", {
        is: "one day",
        then: Yup.date()
          .typeError("please provide a valid date")
          .nullable()
          .required("A date is required")
          .min(new Date(), "date must be in the future")
          .max(new Date(2099, 11, 31), "date must be before the year 2099")
      }),
      startDate: Yup.mixed().when("type", {
        is: "period",
        then: Yup.date()
          .typeError("please provide a valid date")
          .nullable()
          .required("A starting date is required")
          .min(new Date(), "date must be in the future"),
      }),
      endDate: Yup.mixed().when(["type", "startDate"], {
        is: (type: string, startDate: any) => type === "period" && !!startDate,
        then: Yup.date()
          .typeError("please provide a valid date")
          .nullable()
          .required("An ending date is required")
          .min(Yup.ref("startDate"), "end date must be after start date"),
      }),
    }),
    location: Yup.object().shape({
      type: Yup.mixed<LocationType>().oneOf(["in person", "online"]),
      manual: Yup.boolean(),
      location: Yup.string().when(["type", "manual"], {
        is: (type: string, manual: boolean) => type === "in person" && !manual,
        then: Yup.string().required("Location is required"),
      }),
      country: Yup.string().when(["type", "manual"], {
        is: (type: string, manual: boolean) => type === "in person" && manual,
        then: Yup.string().required("Required"),
      }),
      address: Yup.string().when(["type", "manual"], {
        is: (type: string, manual: boolean) => type === "in person" && manual,
        then: Yup.string().required("Required"),
      }),
      city: Yup.string().when(["type", "manual"], {
        is: (type: string, manual: boolean) => type === "in person" && manual,
        then: Yup.string().required("Required"),
      }),
      state: Yup.string().when(["type", "manual"], {
        is: (type: string, manual: boolean) => type === "in person" && manual,
        then: Yup.string().required("Required"),
      }),
      zip: Yup.string().when(["type", "manual"], {
        is: (type: string, manual: boolean) => type === "in person" && manual,
        then: Yup.string()
          .matches(/^[0-9]{5}(?:-?[0-9]{4})?$/, "Invalid ZIP format")
          .required("Required"),
      }),
      event_url: Yup.string().when("type", {
        is: (type: string) => type === "online",
        then: Yup.string()
          .matches(
            /^(?!(?:https?:\/\/)?(?:www\.)?\w+$)(https?:\/\/)?([\w-]+\.)*[\w-]+\.[\w-]+(\/[\w-]+)*$/,
            "Please enter a valid URL",
          )
          .required("Provide a link to your Virtual Event"),
      }),
    }),
  }),
  payment_set_up: Yup.object().shape({
    payment_types: Yup.object().shape({
      default: Yup.mixed<TPaymentTypes>().oneOf(paymentTypes).required(),
      one_time: Yup.boolean(),
      monthly: Yup.boolean(),
      quarterly: Yup.boolean(),
      yearly: Yup.boolean(),
    }),
    amountsList: Yup.array<AmountType>().min(1, "at least 1"),
  }),
  configuration: Yup.object().shape({
    maxTickets: Yup.string().required("required"),
    customer_pays_fees: Yup.boolean(),
    browse_more: Yup.boolean(),
  }),
});

export const EventModalDefaults = {
  about: {
    title: "",
    description: "",
  },
  style: {
    image: null,
    useAsBackground: false,
  },
  date_and_location: {
    date: {
      type: "one day",
      day: null,
      include_time: false,
      startDate: null,
      endDate: null,
    },
    location: {
      showMap: false,
      type: "in person",
      location: "",
      manual: false,
      country: "US",
      address: "",
      state: "",
      city: "",
      zip: "",
      event_url: "",
    },
  },
  payment_set_up: {
    payment_types: {
      default: "one_time" as TPaymentTypes,
      one_time: true,
      monthly: false,
      quarterly: false,
      yearly: false,
    },
    amountsList: [],
  },
  configuration: {
    maxTickets: "5",
    customer_pays_fees: false,
    browse_more: false,
  },
} as TEventModalInputs;
