import { RHFInput } from "@common/Input";
import { showMessage } from "@common/Toast";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { customInstance } from "@services/api";
import { useGetUser } from "@services/api/onboarding/user";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { useQueryClient, useMutation } from "react-query";
import Popup from "@common/Popup";
import * as Yup from "yup";
import { Text } from "@common/Text";
import { Box, Stack } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { Button } from "@common/Button";
import { selectUser } from "@redux/slices/auth/auth";
import { useAppSelector } from "@redux/hooks";
import MobileWrapper from "./MobileWrapper";

type IFormInputs = {
  oldEmail: string;
  newEmail: string;
};

type Props = {
  setPasswordChecked: React.Dispatch<React.SetStateAction<boolean>>;
};

function UpdateEmail({ setPasswordChecked }: Props) {
  const modal = useModal();
  const queryClient = useQueryClient();
  const { data: userData } = useGetUser();
  const { isMobileView } = useCustomTheme();
  const { email } = useAppSelector(selectUser);
  // const checkIfEmailExists = (email: string) => {
  //   if (!users) return false;
  //   const user = users.data.find((user: any) => user.email === email);
  //   return user ? true : false;
  // };

  const schema = Yup.object({
    newEmail: Yup.string()
      .email("Please enter a valid email")
      .required("Email is required")
      .test(
        "same-email",
        "Email already associated with your account",
        (value) => value?.toLocaleLowerCase() !== email?.toLocaleLowerCase(),
      ),
  });

  const defaultValues = {
    oldEmail: "",
    newEmail: "",
  };

  const methods = useForm<IFormInputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const {
    watch,
    handleSubmit,
    formState: { errors },
    setError,
  } = methods;
  const isValid = !errors.newEmail && watch("newEmail");

  const updateUserData = useMutation((data: any) => {
    return customInstance({
      url: `/users/${userData.accID}`,
      method: "PATCH",
      data,
    });
  });

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    updateUserData.mutate(
      { email: data.newEmail },
      {
        onError: (err: any) => {
          const errMessage = err.response?.data?.input[0]?.message;
          setError("newEmail", { message: errMessage });
          showMessage("Error", errMessage);
        },
        onSuccess: () => {
          showMessage("Info", "Check your email to validate your new email");
          queryClient.invalidateQueries("user");
          modal.hide();
        },
      },
    );
  };
  if (isMobileView)
    return (
      <MobileWrapper
        onCancel={() => setPasswordChecked(false)}
        label="Change Account Email"
      >
        <Box sx={styles.container}>
          <FormProvider {...methods}>
            <FadeUpWrapper delay={100}>
              <RHFInput
                name="newEmail"
                fullWidth
                placeholder="email@example.com"
                type="email"
                label="New Email Address"
                sx={{
                  mt: "8px",
                }}
              />
            </FadeUpWrapper>
          </FormProvider>
        </Box>
        <Box flexGrow={1} />
        <Stack mt={2} direction="row">
          <Button
            onClick={() => {
              setPasswordChecked(false);
              modal.hide();
            }}
            fullWidth
            size="medium"
            background="tertiary"
            disabled={updateUserData.isLoading}
          >
            Cancel
          </Button>
          <Button
            size="medium"
            background="primary"
            type="submit"
            fullWidth
            form="profile-form"
            data-testid="popup-submit-button"
            disabled={!isValid || updateUserData.isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Send
          </Button>
        </Stack>
      </MobileWrapper>
    );
  return (
    <Popup
      {...muiDialogV5(modal)}
      width="470px"
      title="Change Account Email"
      onCancel={() => modal.hide()}
      actionLabel="Send"
      cancelLabel="Cancel"
      displayCloseIcon
      titleSx={{
        fontSize: "20px",
        color: "#575353",
        mb: 1,
      }}
      submitButtonProps={{
        size: "medium",
        disabled: !isValid || updateUserData.isLoading,
        onClick: handleSubmit(onSubmit),
        sx: { padding: "5px 20px" },
      }}
      cancelButtonProps={{
        size: "medium",
      }}
    >
      <Box sx={styles.container}>
        <FormProvider {...methods}>
          <Text sx={styles.infoText}>
            You will receive a verification link on your new email address.
            Please click on that link to confirm the email change.
          </Text>

          <RHFInput
            name="newEmail"
            fullWidth
            placeholder="email@example.com"
            type="email"
            label="New Email Address"
          />
        </FormProvider>
      </Box>
    </Popup>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  infoBox: {
    display: "flex",
    gap: 2,
  },
  infoText: {
    color: "#8F8F8F",
    mb: 2,
  },
  labelText: {
    variant: "body",
    color: palette.neutral[800],
    fontWeight: "medium",
    mb: 1,
  },
};

export default UpdateEmail;
