import NiceModal, { useModal } from "@ebay/nice-modal-react";
import MATCHPanel from "./MATCHPanel";
import { MATCH_MOBILE_VIEW } from "modals/modal_names";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { Box } from "@mui/material";
import { DRAWER_DEFAULT_TOP } from "@common/Modal/ModalFactory/variants/SidePanelVariant";

interface Props {
  headerData: {
    name: any;
    imageURL: any;
  };
  isAcquirer: boolean;
  merchantID: number;
}

const MATCHMobileModal = NiceModal.create(
  ({ headerData, isAcquirer, merchantID }: Props) => {
    const modal = useModal(MATCH_MOBILE_VIEW);
    const { TransitionProps, SlideProps } = useNiceModal();
    return (
      <ModalFactory
        variant="sidepanel"
        modalProps={{
          open: modal.visible,
          onClose: () => modal.hide(),
          SlideProps,
          SidePanelProps: {
            PaperProps: {
              TransitionProps,
            },
          },
          DrawerProps: {
            PaperProps: {
              TransitionProps,
              sx: { height: "100%" },
            },
          },
          sx: {
            height: "100%",
            top: DRAWER_DEFAULT_TOP,
          },
        }}
      >
        <Box position="relative" height="100%">
          <MATCHPanel
            headerData={headerData}
            isAcquirer={isAcquirer}
            toggleSecondModal={() => modal.hide()}
            merchantID={merchantID}
            isMobile
          />
        </Box>
      </ModalFactory>
    );
  },
);

export default MATCHMobileModal;
