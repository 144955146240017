import {
  Bank,
  Briefcase,
  FileSearch,
  ListChecks,
  User,
} from "@phosphor-icons/react";
import { ReactNode } from "react";

export const iconMapper = (name: string, size = 20) => {
  const obj: Record<string, ReactNode> = {
    merchant_onboarding: <ListChecks size={size} />,
    primary_account_holder: <User size={size} />,
    business_profile: <Briefcase size={size} />,
    bank_account: <Bank size={size} />,
    enhanced_due_diligence: <FileSearch size={size} />,
  };

  return obj[name];
};
