import React from "react";
import NiceModal from "@ebay/nice-modal-react";
import { useAppSelector } from "@redux/hooks";
import { selectCart } from "@redux/slices/cart";
import { customInstance } from "@services/api";
import {
  CHECKOUT_MODAL,
  EXPIRED_PAYMENT_FORM_MODAL,
  NOT_ABLE_PROCESS_PAYMENT_FORM_MODAL,
} from "modals/modal_names";
import { useQuery, useQueryClient } from "react-query";
import { useGetCart } from "./useGetCart";
import { useLocation, useParams } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import { addCartItem, deleteCardItem } from "@services/api/checkout/cart";

const getRecurringInterval = (interval?: string) => {
  if (!interval || ["One-Time", "one_time", "once"].includes(interval))
    return "once";
  if (interval === "Every Month") return "monthly";
  return interval.toLocaleLowerCase();
};

const useProduct = () => {
  const { id } = useParams();
  /* We also need to access product id from checkout modal in case transaction is declined, so we can allow user to create another transaction,
     but since in nice modal modals don't have access to useParams we need to retrieve product id from location pathname.
  */
  const productId = id || location.pathname.slice(1);

  return useQuery(
    ["product", productId],
    async () => {
      return await customInstance({
        url: `products/${productId}`,
        method: "GET",
      });
    },
    {
      refetchOnWindowFocus: false,
      /**
       * We use id instead of productId, because we don't need to automatically send this request from
       * checkoutModal where id is undefined. We call it only when transaction is declined.
       */
      enabled: !!id,
    },
  );
};

export const useAddToCart = ({
  destinationAccountMerchantName,
  disableFetchCart,
}: {
  destinationAccountMerchantName: string;
  disableFetchCart?: boolean;
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const queryClient = useQueryClient();

  const { cartItems } = useAppSelector(selectCart);
  const [isLoading, setIsLoading] = React.useState(false);

  const {
    data: cart,
    isLoading: isFetchingCart,
    refetch: refetchCart,
  } = useGetCart(disableFetchCart);
  const {
    data: product,
    isLoading: isFetchingProduct,
    refetch: refetchProduct,
  } = useProduct();

  const { pathname } = useLocation();

  const removeOldCartItems = async () => {
    if (!cart || cart?.items?.length === 0) return;

    for (const item of cart.items) {
      await deleteCardItem(item.id);
    }
  };

  const addToCartHandler = async () => {
    try {
      setIsLoading(true);
      if (!product?.id) return;

      if (!product?.merchCanProcessMoney) {
        NiceModal.show(NOT_ABLE_PROCESS_PAYMENT_FORM_MODAL);
        return;
      }
      if (
        (pathname.includes("events") || pathname.includes("sweepstakes")) &&
        product?.endsAt &&
        new Date(product?.endsAt * 1000) < new Date()
      ) {
        NiceModal.show(EXPIRED_PAYMENT_FORM_MODAL);
        return;
      }

      await queryClient.refetchQueries("get-cart");
      await removeOldCartItems();

      for (const item of cartItems) {
        const customData = {
          ...item,
          price: Math.round(item.price * 100),
          recurringFrequency: item.recurringFrequency || 0,
          recurringInterval: getRecurringInterval(item.recurringInterval),
          recurringMax: item.recurringMax || null,
        };

        await addCartItem(customData);
      }

      queryClient.refetchQueries("get-cart");

      if (isDesktop) {
        NiceModal.show(CHECKOUT_MODAL, {
          destinationAccountMerchantName,
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    addToCartHandler,
    refetchProduct,
    refetchCart,
    isLoading: isLoading || isFetchingCart || isFetchingProduct || !product?.id,
    isDesktop,
  };
};
