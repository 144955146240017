import { AddIcon } from "@assets/icons/RebrandedIcons";
import { Text } from "@common/Text";
import { Box, BoxProps, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { Fragment } from "react";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import NiceModal from "@ebay/nice-modal-react";
import { FormType } from "../../modals/CreateBusinessProfileModal";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";

export type SectionPlaceholderProps = {
  placeholdersList: {
    icon: JSX.Element;
    title: string;
    description: string;
    hidden?: boolean;
    disabled?: boolean;
    modal?: string;
    onClose?: (data: any) => void;
    data?: any;
    businessType?: string;
    merchantId?: number;
    merchantName?: string;
    legalEntityID?: number;
    totalOwnerships?: number;
    noController?: boolean;
    formType?: FormType;
    parentCategories?: any[];
    categories?: any[];
    isDefault?: boolean;
    warning?: boolean;
    isIndividualSoleProprietorship?: boolean;
    isEnterprise?: boolean;
    onClick?: () => void;
    testId?: string;
    parentAccID?: number;
    tooltipProps?: {
      show: boolean;
      message: string;
    };
  }[];
};

const SectionPlaceholders = ({ placeholdersList }: SectionPlaceholderProps) => {
  return (
    <Stack spacing={-3}>
      {placeholdersList.map((placeholder, index) => {
        //to remove invalid properties from the DOM
        const {
          formType,
          merchantId,
          businessType,
          isDefault,
          warning,
          isEnterprise,
          parentCategories,
          isIndividualSoleProprietorship,
          onClick,
          tooltipProps,
          testId,
          merchantName,
          parentAccID,
          ...placeholdeRest
        } = placeholder;

        return (
          <Fragment key={placeholder.title}>
            {!placeholder.hidden && !placeholder.noController && (
              <FadeUpWrapper delay={400 + 50 * (index + 1)}>
                <CustomToolTip
                  showToolTip={tooltipProps?.show || false}
                  message={tooltipProps?.message}
                >
                  <PlaceholderItem
                    {...placeholdeRest}
                    disabled={tooltipProps?.show || placeholdeRest.disabled}
                    data-testid={testId}
                    onClick={() => {
                      if (placeholder.modal && !tooltipProps?.show) {
                        NiceModal.show(placeholder.modal, {
                          data: placeholder.data,
                          onClose: placeholder.onClose,
                          merchantId,
                          id: merchantId,
                          legalEntityID: placeholder.legalEntityID,
                          totalOwnerships: placeholder.totalOwnerships,
                          parentCategories: placeholder.parentCategories,
                          categories: placeholder.categories,
                          isIndividualSoleProprietorship:
                            placeholder.isIndividualSoleProprietorship,
                          isDefault: isDefault || false,
                          formType,
                          isEnterprise,
                          businessType,
                          isMerchant: true,
                          merchantName: merchantName,
                          parentAccID,
                        });
                      } else if (onClick) onClick();
                    }}
                  />
                </CustomToolTip>
              </FadeUpWrapper>
            )}
          </Fragment>
        );
      })}
    </Stack>
  );
};

type PlaceholderItemProps = {
  icon: JSX.Element;
  title: string;
  description: string;
  disabled?: boolean;
} & BoxProps;

const PlaceholderItem = ({
  icon,
  title,
  description,
  disabled,
  ...props
}: PlaceholderItemProps) => {
  return (
    <PlaceholderItemContainer {...props}>
      <PlaceholderItemSubContainer disabled={disabled}>
        <Stack spacing={1}>
          <Stack direction="row" spacing="10px" alignItems="center">
            {icon}
            <Text fontSize={18} color={palette.neutral[80]}>
              {title}
            </Text>
          </Stack>
          <Text fontWeight="book" color={palette.neutral[70]}>
            {description}
          </Text>
        </Stack>
        <StyledAddButton type="button">
          <AddIcon />
        </StyledAddButton>
      </PlaceholderItemSubContainer>
      <PlaceholderBottomGradient />
    </PlaceholderItemContainer>
  );
};

const PlaceholderItemContainer = styled(Box)(() => ({
  padding: "16px 16px 48px 16px",
  borderRadius: "16px",
  border: `1px solid ${palette.neutral[10]}`,
  borderBottom: "none",
  background: palette.background.dimmedWhite,
  position: "relative",
  overflow: "hidden",
}));

const PlaceholderBottomGradient = styled(Box)(() => ({
  height: 50,
  width: "calc(100% + 32px)",
  position: "absolute",
  left: "-16px",
  bottom: 0,
  background:
    "linear-gradient(1deg, #F8F8F6 31.5%, rgba(248, 248, 246, 0.00) 101.87%)",
}));

const PlaceholderItemSubContainer = styled(Box)<
  BoxProps & { disabled?: boolean }
>(({ disabled }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "16px",
  padding: "16px",
  borderRadius: 8,
  background: "#F4F4F4",
  cursor: "pointer",
  "&:hover": {
    background: "#ECECEC",
  },
  ...(disabled && {
    pointerEvents: "none",
    opacity: 0.5,
  }),
}));

const StyledAddButton = styled("button")(() => ({
  boxShadow: "none",
  border: "none",
  background: "#ECECEC",
  width: "32px",
  height: "32px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
}));

export default SectionPlaceholders;
