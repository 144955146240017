import { IconButton, Stack } from "@mui/material";
import NiceModal from "@ebay/nice-modal-react";
import { LOGOUT_MODAL, PROFILE_MODAL } from "modals/modal_names";
import GiveAvatar from "@shared/Avatar/GiveAvatar";
import useUserReducer from "@hooks/Reducers/useUserReducer";
import { styled } from "@theme/v2/Provider";
import ContextualMenu from "@shared/ContextualMenu/ContextualMenu";
import { useMemo, useState } from "react";
import { PencilSimple, SignOut } from "@phosphor-icons/react";
import GiveText from "@shared/Text/GiveText";

const ProfileMenu = () => {
  const { img, globalName, email } = useUserReducer();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const onOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const openProfileModal = () => {
    NiceModal.show(PROFILE_MODAL);
  };

  const openLogoutModal = () => {
    NiceModal.show(LOGOUT_MODAL);
  };

  const actions = useMemo(
    () => [
      {
        text: "Edit Profile",
        IconRight: PencilSimple,
        onClick: openProfileModal,
      },

      {
        text: "Log out",
        IconRight: SignOut,
        onClick: openLogoutModal,
      },
    ],
    [],
  );

  const ProfileDetails = () => (
    <Stack gap="12px" padding="20px 12px" alignItems="center">
      <GiveAvatar imageUrl={img} size="64px" />
      <Stack alignItems="center">
        <GiveText variant="bodyS" color="primary">
          {globalName?.firstName} {globalName?.lastName}
        </GiveText>
        <GiveText variant="bodyXXS" color="secondary">
          {email}
        </GiveText>
      </Stack>
    </Stack>
  );

  return (
    <>
      <AvatarBtn size="large" version="two" onClick={onOpenMenu}>
        <GiveAvatar size="32px" imageUrl={img} />
      </AvatarBtn>

      <ContextualMenu
        Header={<ProfileDetails />}
        menuWidth={240}
        handleClose={onCloseMenu}
        anchorEl={anchorEl}
        color="primary"
        texture="blurred"
        options={actions}
      />
    </>
  );
};

const AvatarBtn = styled(IconButton)(({ theme }) => ({
  border: "4px solid transparent",
  padding: 0,
  borderRadius: "50%",
  "&:hover": {
    border: `4px solid ${theme.palette.border?.primary}`,
  },
}));

export default ProfileMenu;
