import { Box, LinearProgress } from "@mui/material";
import React from "react";
import { styled } from "@theme/v2/Provider";

interface IComponentProps {
  isLoading: boolean;
}

const ProgressBar = ({ isLoading }: IComponentProps) => {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          return oldProgress;
        }
        const diff = Math.random() * 20;
        return Math.min(oldProgress + diff, 100);
      });
    }, 200);

    return () => {
      setProgress(0);
      clearInterval(timer);
    };
  }, [isLoading]);

  if (!isLoading) return <Box height={2} />;

  return <StyledProgress variant="determinate" value={progress} />;
};

const StyledProgress = styled(LinearProgress)(({ theme }) => ({
  height: "2px",
  backgroundColor: "transparent",
  "& .MuiLinearProgress-bar1Determinate": {
    backgroundColor: theme.palette?.primitive?.blue[100],
  },
}));

export default ProgressBar;
