import { IconButton } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { IGiveIconButton } from "./GiveIconButton.type";

const ICON_SIZES = {
  extraSmall: "16px",
  small: "20px",
  medium: "20px",
  large: "24px",
};

const GiveIconButton = ({
  Icon,
  size = "small",
  variant = "filled",
  color,
  bgColor,
  elementRef,
  weight,
  iconText,
  disabled,
  ...rest
}: IGiveIconButton) => {
  const buttonColor = bgColor || "transparent";

  return (
    <IconButton
      size={size}
      variant={variant}
      ref={elementRef}
      data-color={buttonColor}
      version="two"
      disabled={disabled}
      {...rest}
    >
      <Icon
        size={ICON_SIZES[size]}
        color={color || undefined}
        weight={weight}
      />
      {iconText && <GiveText variant="bodyS">{iconText}</GiveText>}
    </IconButton>
  );
};

export default GiveIconButton;
