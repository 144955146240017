import React from "react";
import { Box, Stack, styled, BoxProps, StackProps } from "@mui/material";
import { MASQUERADE_SNACKBAR_HEIGHT } from "@components/Merchants/Masquerade/MasqueradeSnackbar";
import { CustomStyleProps } from "@customTypes/style.types";
import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";

interface StyledLayoutProps extends BoxProps, CustomStyleProps {}

const Layout = styled<React.FC<StyledLayoutProps>>(Box, {
  shouldForwardProp: (prop) => prop !== "isMasqueradeMode",
})(({ theme, isMasqueradeMode, winHeight }) => ({
  display: "flex",
  minHeight: winHeight,
  width: "100%",
  paddingTop: isMasqueradeMode ? MASQUERADE_SNACKBAR_HEIGHT + "px" : "0",

  [theme.breakpoints.up("md")]: {
    paddingTop: isMasqueradeMode ? MASQUERADE_SNACKBAR_HEIGHT + "px" : "0",
  },
}));

export const PortalRootContainer: React.FC<StackProps> = styled((props) => {
  const { isMasqueradeMode } = useMasqueradeReducer();

  return (
    <Stack
      sx={{
        ...(isMasqueradeMode && {
          paddingBottom: "16px",
        }),
        "@media (max-width: 600px)": {
          ...(isMasqueradeMode && {
            paddingTop: "35px",
            paddingBottom: 0,
          }),
        },
      }}
      {...props}
    />
  );
})(({ theme }) => ({
  flexDirection: "column",
  flexGrow: 1,
  [theme.breakpoints.down("sm")]: {
    gap: "40px !important",
    padding: 0,
  },
}));

export default Layout;
