// form
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
// components
import { useMutation, useQueryClient } from "react-query";
import { showMessage } from "@common/Toast/ShowToast";
import { useGetUser } from "@services/api/onboarding/user";
import { customInstance } from "@services/api";
import { PASSWORD_MIN_CHARS } from "@constants/constants";
import { useEffect } from "react";

type IFormInputs = {
  current_password: string;
  new_password: string;
  confirm_password: string;
};

export const useSecurityPassword = () => {
  const queryClient = useQueryClient();
  const { data: userData } = useGetUser();

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues,
  });

  const {
    setError,
    reset,
    formState: { isDirty },
    watch,
  } = methods;
  const values = watch();

  useEffect(() => {
    if (!values.current_password || !values.new_password) return;
    (async () => {
      await methods.trigger("new_password");
    })();
  }, [values.current_password]);

  const changePwdMutation = useMutation((data: any) => {
    return customInstance({
      url: `/users/${userData.accID}`,
      method: "PATCH",
      data,
    });
  });

  const { isLoading } = changePwdMutation;

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    changePwdMutation.mutate(
      {
        newPassword: data.new_password,
        currentPassword: data.current_password,
      },
      {
        onSuccess: () => {
          showMessage("Success", "Password reset successful");
          queryClient.invalidateQueries("user");
          reset();
        },
        onError: (error: any) => {
          const usedPasswordMessage =
            "The new password must not match the previous five.";

          if (error.response?.data?.message === usedPasswordMessage) {
            return setError("new_password", {
              message: usedPasswordMessage,
            });
          } else {
            setError("current_password", {
              message:
                "The provided password does not match the user's current password",
            });
          }
        },
      },
    );
  };

  return { methods, onSubmit, isLoading, userData, isDirty };
};

const defaultValues = {
  current_password: "",
  new_password: "",
  confirm_password: "",
};

const schema = Yup.object().shape({
  current_password: Yup.string()
    .required("Current password is mandatory")
    .min(PASSWORD_MIN_CHARS, "Current password is wrong"),
  new_password: Yup.string()
    .min(
      PASSWORD_MIN_CHARS,
      `Password should have ${PASSWORD_MIN_CHARS} characters minimum`,
    )
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(/[a-z]/, "Must contain at least one lowercase character")
    .matches(/[A-Z]/, "Must contain at least one uppercase character")
    .matches(/[!-/:-@[-`{-~]/, "Must contain at least one special character")
    .test({
      name: "validator-current-new-password",
      test: function (value, context) {
        const currentPassword = context.parent.current_password;
        return value !== currentPassword
          ? true
          : this.createError({
              message: "Current password and new password should not be same",
              path: "new_password",
            });
      },
    }),
  confirm_password: Yup.string()
    .required("Password is required")
    .oneOf([Yup.ref("new_password")], "Passwords do not match"),
});
