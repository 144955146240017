import React, { ReactNode } from "react";
import {
  Box,
  Stack,
  StackProps,
  SxProps,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Modal } from "@common/Modal";
import { DialogProps } from "@common/Modal/Modal";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { Button } from "@common/Button";
import LoadingButton, {
  LoadingButtonProps,
} from "@common/Button/LoadingButton";
import { BtnBGTypes } from "@common/Button/Button_V2";
import { CloseIcon } from "@assets/rebrandIcons";
import { ITextProps } from "@common/Text/Text";
import { ButtonProps, CloseButton } from "@common/Button/Button";
import { IconButton, StyledIconButton } from "@common/IconButton";

type PopupProps = Omit<DialogProps, "onClose"> & {
  title?: string | React.ReactNode | Element;
  text?: string | React.ReactNode;
  onCancel?: () => void;
  onSubmit?: (e?: any) => void;
  isSubmitDisabled?: boolean;
  isLoading?: boolean;
  cancelLabel?: string;
  actionLabel?: string | ReactNode;
  isCancel?: boolean;
  isClose?: boolean;
  cancelType?: BtnBGTypes;
  actions?: React.ReactNode;
  titleSx?: ITextProps["sx"];
  cancelButtonSx?: ITextProps["sx"];
  submitButtonSx?: ITextProps["sx"];
  submitButtonProps?: LoadingButtonProps;
  textContainerProps?: StackProps;
  textProps?: ITextProps;
  titleProps?: ITextProps;
  cancelButtonProps?: ButtonProps;
  displayCloseIcon?: boolean;
  hideSecondaryAction?: boolean;
  hasCustomPadding?: boolean;
  customContent?: React.ReactNode;
  isNotTextChild?: boolean;
  overrideClose?: boolean;
  customContainerStyle?: any;
  CustomCloseIcon?: any;
  isShowTextWhileLoading?: boolean;
  isCloseIconDisable?: boolean;
  HeaderIcon?: any;
  HeaderSecondaryIcon?: any;
  modalHeaderCustomStyles?: any;
  modalActionsCustomStyles?: any;
  HeaderContainerStyles?: any;
  HeaderSecondaryIconSx?: any;
  onCustomCloseIconClick?: () => void;
  containerCustomWidth?: any;
  containerCustomHeight?: any;
  hideActions?: boolean
};

const Popup = ({
  title,
  children,
  isLoading,
  isSubmitDisabled,
  onCancel,
  onSubmit,
  cancelLabel,
  actionLabel,
  actions,
  isCancel = true,
  isClose,
  cancelType = "tertiary",
  titleSx,
  submitButtonProps,
  textContainerProps,
  cancelButtonProps,
  textProps,
  titleProps,
  displayCloseIcon,
  hideSecondaryAction,
  hasCustomPadding = false,
  customContent,
  isNotTextChild,
  customContainerStyle,
  cancelButtonSx,
  submitButtonSx,
  CustomCloseIcon,
  isShowTextWhileLoading,
  isCloseIconDisable = false,
  HeaderIcon,
  HeaderSecondaryIcon,
  HeaderContainerStyles,
  HeaderSecondaryIconSx,
  onCustomCloseIconClick,
  containerCustomWidth,
  containerCustomHeight,
  hideActions,
  ...props
}: PopupProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <StyledModal
      modalType="selection"
      {...props}
      onClose={props.overrideClose ? undefined : onCancel}
      hasCustomPadding={hasCustomPadding}
      onBackdropClick={props.overrideClose ? undefined : onCancel}
      customContainerStyle={customContainerStyle}
      width={containerCustomWidth}
      height={containerCustomHeight}
      hideActions={hideActions}
      actions={
        actions ? (
          actions
        ) : (
          <Box
            display="flex"
            justifyContent="flex-end"
            gap={2}
            width="100%"
            marginTop={0}
          >
            {onCancel && isCancel && !hideSecondaryAction && (
              <Button
                background={cancelType}
                size={isDesktop ? "small" : "medium"}
                onClick={onCancel}
                sx={cancelButtonSx}
                {...cancelButtonProps}
              >
                {cancelLabel || "Cancel"}
              </Button>
            )}
            {actionLabel ? (
              <LoadingButton
                background="primary"
                size={isDesktop ? "small" : "medium"}
                isLoading={isLoading}
                isShowTextWhileLoading={isShowTextWhileLoading}
                disabled={isSubmitDisabled}
                onClick={onSubmit}
                sx={submitButtonSx}
                {...submitButtonProps}
              >
                {actionLabel || "Understood"}
              </LoadingButton>
            ) : null}
          </Box>
        )
      }
    >
      {customContent ? (
        customContent
      ) : (
        <StyledStack {...textContainerProps}>
          {(HeaderIcon || HeaderSecondaryIcon) && (
            <Stack
              direction="row"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              {...HeaderContainerStyles}
            >
              {HeaderIcon && HeaderIcon}

              {HeaderSecondaryIcon && (
                <CloseButton onClick={onCancel} sx={HeaderSecondaryIconSx}>
                  {HeaderSecondaryIcon}
                </CloseButton>
              )}
            </Stack>
          )}
          {(title || displayCloseIcon) && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {title && !isClose && (
                <StyledTitle variant="h5" sx={titleSx} {...titleProps}>
                  {title}
                </StyledTitle>
              )}
              {title && isClose && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                  alignItems="center"
                >
                  <StyledTitle variant="h5" sx={titleSx} {...titleProps}>
                    {title}
                  </StyledTitle>
                  <CloseButton
                    background="tertiary"
                    onClick={onCustomCloseIconClick || onCancel}
                    disabled={isCloseIconDisable}
                  >
                    {CustomCloseIcon || (
                      <CloseIcon
                        height={24}
                        width={24}
                        color={palette.black.main}
                      />
                    )}
                  </CloseButton>
                </Box>
              )}
              {displayCloseIcon && (
                <StyledIconButton
                  onClick={onCancel}
                  sx={{ position: "relative", bottom: "6px" }}
                >
                  <CloseIcon color="#131212" width={27} height={27} />
                </StyledIconButton>
              )}
            </Stack>
          )}
          {isNotTextChild ? (
            children
          ) : (
            <Text
              variant="headline"
              fontWeight="light"
              fontSize={"16px"}
              lineHeight="21.6px"
              color={palette.neutral[80]}
              {...textProps}
            >
              {children}
            </Text>
          )}
        </StyledStack>
      )}
    </StyledModal>
  );
};

const StyledModal = styled(Modal, {
  shouldForwardProp: (prop) =>
    prop !== "hasCustomPadding" && prop !== "customContainerStyle",
})<
  DialogProps & {
    hasCustomPadding: boolean;
    customContainerStyle: any | undefined;
    modalHeaderCustomStyles?: any;
    modalActionsCustomStyles?: any;
    height?: any;
  }
>(
  ({
    theme,
    width,
    height,
    hasCustomPadding,
    customContainerStyle,
    modalHeaderCustomStyles = {},
    modalActionsCustomStyles = {},
  }) => ({
    "& .MuiDialog-paper": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: width || "500px",
      height: height || "auto",
      display: "flex",
      flexDirection: "column",
      borderRadius: 16,
      border: customContainerStyle?.border || "2px solid #FFFFFF",
      background:
        customContainerStyle?.backgroundColor || "rgba(255, 255, 255, 0.7)",
      boxShadow: "0px 60px 180px rgba(0, 0, 0, 0.15)",
      backdropFilter: "blur(20px)",
      overflow: "hidden",
    },
    "& .MuiDialogContent-root": {
      padding: hasCustomPadding ? "16px" : "24px",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      ...modalHeaderCustomStyles,
    },
    "& .MuiDialogActions-root": {
      padding: hasCustomPadding ? "0px 16px 16px" : "24px",
      background: "transparent",
      ...modalActionsCustomStyles,
    },
    [theme.breakpoints.down(600)]: {
      "& .MuiDialog-paper": {
        height: "fit-content",
        maxHeight: "76%",
        width: "90%",
      },
      "& .MuiDialogActions-root .MuiButton-root": {
        width: "fit-content",
      },
    },
  }),
);

const StyledStack = styled(Stack)(({ theme }) => ({
  gap: "8px",
  display: "flex",
  background: "transparent",
  flexDirection: "column",
  marginBottom: "0px",
}));

const StyledTitle = styled(Text)(({ theme }) => ({
  fontWeight: 350,
  fontSize: 32,
  lineHeight: "38.4px",
  color: palette.neutral[80],
  [theme.breakpoints.down("sm")]: {
    fontSize: 24,
    lineHeight: "32.4px",
  },
}));

export default Popup;
