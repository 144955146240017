import { Stack } from "@mui/material";
import { ArrowRight } from "@phosphor-icons/react";
import { StyledGiveButton } from "./UnderwritingCard.style";
import GiveAssignButton from "@shared/AssignButton/GiveAssignButton";
import { CustomToolTip as PermissionTooltip } from "componentsV2/Table/CustomTooltip";
import { ACTION_DENY_MESSAGE } from "@constants/permissions";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";

type TUnderwritingCardBody = {
  underwriterName?: string;
  underwriterEmail?: string;
  underwriterImageURL?: string;
  handleManageUnderwriting?: () => void;
  id: number;
};

const UnderwritingCardBody = ({
  underwriterName,
  underwriterEmail,
  underwriterImageURL,
  handleManageUnderwriting,
  id,
}: TUnderwritingCardBody) => {
  const { isViewUnderwritingAllowed } = useUnderwriterPermissions();
  return (
    <Stack direction="row" justifyContent="space-between">
      <GiveAssignButton
        disabled={false}
        title="Assign"
        data={{
          accID: id,
          underwriterName,
          underwriterImageURL,
          underwriterEmail,
        }}
      />
      {handleManageUnderwriting && (
        <PermissionTooltip
          showToolTip={!isViewUnderwritingAllowed}
          message={ACTION_DENY_MESSAGE}
          placement="top"
          alignment="end"
          fullWidth={true}
        >
          <StyledGiveButton
            variant="filled"
            label="Underwriting"
            endIcon={<ArrowRight />}
            onClick={handleManageUnderwriting}
            disabled={!isViewUnderwritingAllowed}
          />
        </PermissionTooltip>
      )}
    </Stack>
  );
};

export default UnderwritingCardBody;
