import { FilterObjectTypes } from "types/utils.types";
import { TransactionDateType } from "./types";

export enum QUERYOBJECTKEYS_MANAGE_MONEY {
  transactionDate = "transactionDate",
  createdAt = "createdAt",
  transaction_status = "txnDisplayStatus",
  inflow_amount = "inFlow",
  outflow_amount = "outFlow",
  payment_method = "paymentMethod",
}

export const defaultValuesManageMoney = {
  [QUERYOBJECTKEYS_MANAGE_MONEY.transactionDate]: {
    dateName: "createdAt",
    endDate: undefined,
    label: undefined,
    startDate: undefined,
    val: undefined,
  } as TransactionDateType,

  [QUERYOBJECTKEYS_MANAGE_MONEY.transaction_status]:
    [] as Array<FilterObjectTypes>,
  [QUERYOBJECTKEYS_MANAGE_MONEY.createdAt]: "",
  [QUERYOBJECTKEYS_MANAGE_MONEY.payment_method]: {} as FilterObjectTypes,
  [QUERYOBJECTKEYS_MANAGE_MONEY.inflow_amount]: {} as FilterObjectTypes,
  [QUERYOBJECTKEYS_MANAGE_MONEY.outflow_amount]: {} as FilterObjectTypes,
};

export type DefaultValuesTypeManageMoney = typeof defaultValuesManageMoney;
