import * as React from "react";
// mui
import { Divider, styled, useTheme, useMediaQuery } from "@mui/material";
// components
import DrawerListItem from "./DrawerListItem";
import NiceModal from "@ebay/nice-modal-react";
import { CREATE_PRE_BUILD_FORM_MODAL } from "../../modals/modal_names";
import { Text } from "@common/Text";
// redux
import { useAppDispatch } from "@redux/hooks";
import { setMobileDrawerOpen } from "@redux/slices/app";
import { MenuList, MenuListItem } from "types/data.types";
import { palette } from "@palette";
import { useGetProductsStatsFactory } from "@services/api/products/queryFactory";
import PendingMerchantApplication from "./PendingMerchantApplication";
import { checkPortals } from "@utils/routing";
import { MerchantStatus } from "./MerchantStatus/MerchantStatus";
import { DrawerMenu, DrawerFooterMenu } from "./Drawer.styles";
import useDisputeCount from "@components/Disputes/DisputesList/hooks/useDisputeCount";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { merchantMenu, getAcquirerMenu, getEnterpriseMenu } from "routes";
import useMenuItemPermission from "@components/Drawer/hooks/useMenuItemPermission";

export default function DrawerList({ drawerOpen }: { drawerOpen: boolean }) {
  const { isEnterprisePortal, isAcquirerPortal, isMerchantPortal } =
    checkPortals();
  const { isTransfersPageEnabled, isDisputesPageEnabled } =
    useGetFeatureFlagValues();

  const menuList: MenuList = isAcquirerPortal
    ? getAcquirerMenu({ isTransfersPageEnabled, isDisputesPageEnabled })
    : isEnterprisePortal
    ? getEnterpriseMenu({ isTransfersPageEnabled })
    : merchantMenu;


  const menu = menuList.menu;
  const footerMenu = menuList.footer;
  const floor = menuList.floor;

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const { data: stats, isLoading } = useGetProductsStatsFactory(
    undefined,
    true,
  );


  const dynamicMenu = React.useMemo(() => {
    const totalStats = stats?.total;
    const statsData = stats?.data;

    const updatedMenu = menu.reduce((acc: MenuListItem[], item) => {
      const itemName = item.name.toLowerCase();

      if (
        acc.length < 5 ||
        (!isLoading &&
          item.index === 10 &&
          (totalStats < 6 ||
            (totalStats === 6 &&
              !statsData?.every((item: any) => item.totalProducts > 0))))
      ) {
        acc.push(item);
        return acc;
      }

      const type = `${itemName}`;
      const foundIndex = statsData?.findIndex(
        (obj: any) =>
          `${obj.productTypeName}s` === type && obj.totalProducts > 0,
      );

      if (foundIndex !== -1 && !isLoading) {
        acc.push(item);
      }

      return acc;
    }, []);

    return updatedMenu;
  }, [stats?.data, stats?.total, menu]);


  const filteredMenu = useMenuItemPermission(dynamicMenu);

  // We are injecting the dispute count in the diputes menu item
  const modifiedMenu = useDisputeCount(filteredMenu);



  const toggleMobileDrawer = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    item?: MenuListItem,
  ) => {
    if (item?.name === "Pre-Built Forms") {
      NiceModal.show(CREATE_PRE_BUILD_FORM_MODAL);
    }
    if (!isDesktop) dispatch(setMobileDrawerOpen(false));
  };

  const listRef = React.useRef<HTMLUListElement>(null);

  return (
    <>
      <DrawerMenu drawerOpen={drawerOpen} ref={listRef}>
        {modifiedMenu.map((data: MenuListItem, index: number) => {
          return data.name !== "__title__" ? (
            <DrawerListItem
              key={index}
              item={data}
              drawerOpen={drawerOpen}
              onClick={toggleMobileDrawer}
            />
          ) : drawerOpen && data.name !== "__title__" ? (
            <TitleText key={index}>{data.text}</TitleText>
          ) : (
            <Divider
              flexItem
              key={index}
              sx={{
                mt: "7px",
                mb: "16px",
                width: drawerOpen ? "calc(100% - 24px)" : "56px",
                alignSelf: "center",
              }}
            />
          );
        })}
      </DrawerMenu>
      <PendingMerchantApplication drawerOpen={drawerOpen} />
      <DrawerFooterMenu drawerOpen={drawerOpen}>
        {isMerchantPortal && <MerchantStatus listRef={listRef} />}
        {footerMenu?.map((data: MenuListItem, index: number) => (
          <DrawerListItem
            small={index === footerMenu?.length - 1}
            key={index}
            item={data}
            drawerOpen={drawerOpen}
            onClick={(e) => {
              toggleMobileDrawer(e);
            }}
          />
        ))}
        {floor}
      </DrawerFooterMenu>
    </>
  );
}

const TitleText = styled(Text)(() => ({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "16px",
  color: palette.gray[300],
  flexGrow: 0,
  whiteSpace: "nowrap",
  margin: "7px 0px 16px 16px",
}));
