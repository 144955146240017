import React, { createContext, useContext, useState, useCallback } from "react";
import { showMessage } from "@common/Toast";
import { ProductItemType } from "../types";

interface CartItem {
  id: string;
  quantity: number;
  amount: string;
}

interface CartContextProps {
  cartItems: CartItem[];
  addToCart: (item: ProductItemType, addedQuantity?: number) => void;
  removeFromCart: (ProductItemTypeId: string) => void;
  getItemInCart: (productId: string) => CartItem | undefined;
  isCartEmpty: boolean;
  totalAmount: number;
  totalItemsInCart: number;
}

interface CartProviderProps {
  children: React.ReactNode;
}

const CartContext = createContext<CartContextProps | undefined>(undefined);

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  // TODO: Integrate API calls, we might to an optimistic update and handle fallback in case of error
  const isCartEmpty = cartItems.length === 0;

  const addToCart = useCallback(
    async (item: ProductItemType, addedQuantity = 1) => {
      const existingItem = cartItems.find(
        (cartItem) => cartItem.id === item.id,
      );

      const cartItemPayload: CartItem = {
        id: item.id,
        quantity: addedQuantity,
        amount: item.amount,
      };

      if (existingItem) {
        if (
          item.in_stock &&
          existingItem.quantity + addedQuantity <= item.in_stock
        ) {
          // increment the quantity if the item already exists
          setCartItems((prevItems) =>
            prevItems.map((cartItem) =>
              cartItem.id === item.id
                ? { ...cartItem, quantity: cartItem.quantity + addedQuantity }
                : cartItem,
            ),
          );
        } else {
          showMessage("Error", "Item quantity cannot exceed items in stock");
          return;
        }
        // TODO: send a PATCH request to update the cart on the backend
      } else {
        // else add new item to cart
        setCartItems((prevItems) => [...prevItems, cartItemPayload]);
      }

      // TODO: send a POST request to save the updated cart
    },
    [cartItems],
  );

  const removeFromCart = (productId: string) => {
    setCartItems((prevItems) =>
      prevItems.filter((item) => item.id !== productId),
    );
    // Send delete request to the API to remove the item from the cart on the backend
  };

  const getItemInCart = (productId: string) =>
    cartItems.find((item: CartItem) => item.id === productId);

  const totalAmount = cartItems.reduce((total, item) => {
    return total + Number(item.amount) * item.quantity;
  }, 0);

  const totalItemsInCart = cartItems.length;

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        isCartEmpty,
        getItemInCart,
        totalAmount,
        totalItemsInCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("use useCart within CartProvider wrapper");
  }
  return context;
};
