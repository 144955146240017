import { Navigate } from "react-router-dom";
import { lazy } from "react";
import { MERCHANT_PATHS } from "./paths";
import WelcomePage from "@pages/Signup/WelcomePage";
import PaymentFormsPage from "@pages/PaymentForms/PaymentFormsPage";

const DonationList = lazy(() =>
  import("@pages/FundraisersDonationList").then((module) => ({
    default: module.DonationList,
  })),
);

const PaymentFormsList = lazy(() =>
  import("@pages/PaymentFormsList/PaymentFormsDetails").then((module) => ({
    default: module.PaymentFormsDetails,
  })),
);

const PurchasesList = lazy(() =>
  import("@pages/InvoicePurchases").then((module) => ({
    default: module.PurchasesList,
  })),
);
const ManageMoneyPage = lazy(() =>
  import("@pages/ManageMoney").then((module) => ({
    default: module.ManageMoneyPage,
  })),
);
const CustomersPage = lazy(() =>
  import("@pages/Customers").then((module) => ({
    default: module.CustomersPage,
  })),
);
const FundraisersPage = lazy(() =>
  import("@pages/Fundraisers").then((module) => ({
    default: module.FundraisersPage,
  })),
);
const TicketSales = lazy(() =>
  import("@pages/TicketSales/TicketSales").then((module) => ({
    default: module.TicketSales,
  })),
);
const SettingsPage = lazy(() =>
  import("@pages/Settings").then((module) => ({
    default: module.SettingsPage,
  })),
);
const EventsPage = lazy(() =>
  import("@pages/Events").then((module) => ({ default: module.EventsPage })),
);
const InvoicesPage = lazy(() =>
  import("@pages/Invoices").then((module) => ({
    default: module.InvoicesPage,
  })),
);

const SweepstakesReport = lazy(() =>
  import("@pages/SweepstakesReport").then((module) => ({
    default: module.SweepstakesReport,
  })),
);
const MemberListTable = lazy(() =>
  import("@components/Settings").then((module) => ({
    default: module.MemberListTable,
  })),
);

const BusinessDetails = lazy(
  () => import("@components/Settings/BusinessDetails/BusinessDetails"),
);

const MediaLibrary = lazy(() =>
  import("@components/Settings/MediaLibrary").then((module) => ({
    default: module.MediaLibrary,
  })),
);

const Memberships = lazy(() =>
  import("@pages/Memberships").then((module) => ({
    default: module.Memberships,
  })),
);

const MembershipReport = lazy(() =>
  import("@pages/MembershipReport").then((module) => ({
    default: module.MembershipReport,
  })),
);

const SweepstakesPage = lazy(() =>
  import("@pages/Sweepstakes").then((module) => ({
    default: module.SweepstakesPage,
  })),
);
const CustomerInfoDetails = lazy(() =>
  import("@components/Customers/Table/Mobile").then((module) => ({
    default: module.CustomerInfoDetails,
  })),
);

const NewAdvancedBuilder = lazy(() =>
  import("@pages/NewAdvancedBuilder").then((module) => ({
    default: module.NewAdvancedBuilder,
  })),
);

const PaymentDetails = lazy(() =>
  import("@components/ManageMoney/TransactionTable/Mobile").then((module) => ({
    default: module.PaymentDetails,
  })),
);

const MerchantDashBoard = lazy(() =>
  import("@pages/Dashboard").then((module) => ({
    default: module.MerchantDashBoard,
  })),
);

const merchantRoutesList = [
  {
    path: MERCHANT_PATHS.DASHBOARD,
    children: [
      {
        element: <MerchantDashBoard />,
        index: true,
      },
    ],
  },
  {
    path: MERCHANT_PATHS.MANAGE_MONEY,
    children: [
      {
        element: <ManageMoneyPage />,
        index: true,
      },
      {
        path: MERCHANT_PATHS.MANAGE_MONEY_ID,
        element: <PaymentDetails />,
      },
    ],
  },
  {
    path: MERCHANT_PATHS.PAYMENT_FORMS,
    children: [
      {
        element: <PaymentFormsPage />,
        index: true,
      },
      {
        path: MERCHANT_PATHS.PAYMENT_FORMS_ID,
        element: <PaymentFormsList />,
      },
    ],
  },
  { path: MERCHANT_PATHS.WELCOME, element: <WelcomePage /> },
  {
    path: MERCHANT_PATHS.CUSTOMERS,
    children: [
      {
        element: <CustomersPage />,
        index: true,
      },
      {
        path: MERCHANT_PATHS.CUSTOMER_ID,
        element: <CustomerInfoDetails />,
      },
    ],
  },
  {
    path: MERCHANT_PATHS.FUNDRAISERS,
    children: [
      {
        element: <FundraisersPage />,
        index: true,
      },
      {
        path: MERCHANT_PATHS.FUNDRAISERS_ID,
        element: <DonationList />,
      },
    ],
  },
  {
    path: MERCHANT_PATHS.NEW_ADVANCED_BUILDER,
    children: [
      {
        element: <NewAdvancedBuilder />,
        index: true,
      },
    ],
  },
  {
    path: MERCHANT_PATHS.EVENTS,
    children: [
      {
        element: <EventsPage />,
        index: true,
      },
      {
        path: MERCHANT_PATHS.EVENTS_ID,
        element: <TicketSales />,
      },
    ],
  },
  {
    path: MERCHANT_PATHS.SETTINGS,
    element: <SettingsPage />,
    children: [
      {
        element: <Navigate to={MERCHANT_PATHS.BUSINESS} replace />,
        index: true,
      },
      {
        path: MERCHANT_PATHS.BUSINESS,
        element: <BusinessDetails />,
      },
      {
        path: MERCHANT_PATHS.MANAGE_TEAM,
        children: [
          {
            element: <MemberListTable />,
            index: true,
          },
        ],
      },
      {
        path: MERCHANT_PATHS.MEDIA_LIBRARY,
        element: <MediaLibrary />,
      },
    ],
  },
  {
    path: MERCHANT_PATHS.INVOICES,
    children: [
      {
        element: <InvoicesPage />,
        index: true,
      },
      {
        path: MERCHANT_PATHS.INVOICES_ID,
        element: <PurchasesList />,
      },
    ],
  },
  {
    path: MERCHANT_PATHS.SWEEPSTAKES,
    children: [
      {
        path: MERCHANT_PATHS.SWEEPSTAKES_ID,
        element: <SweepstakesReport />,
      },
      {
        element: <SweepstakesPage />,
        index: true,
      },
    ],
  },
  {
    path: MERCHANT_PATHS.MEMBERSHIP,
    children: [
      {
        path: MERCHANT_PATHS.MEMBERSHIP_ID,
        element: <MembershipReport />,
      },
      {
        element: <Memberships />,
        index: true,
      },
    ],
  },
];

export default merchantRoutesList;
