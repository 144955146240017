import { showMessage } from "@common/Toast/ShowToast";
import { useQueryClient } from "react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { NEW_ACCOUNT_MODAL } from "../../../modals/modal_names";
import NiceModal from "@ebay/nice-modal-react";
import { findBankByRoutingNumber } from "@utils/bank_list";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useCreateBankAccountMutation } from "./useCreateBankAccountMutation";
import { useFileUploadContext } from "@components/UploadFile/FileUploadContext";
import { StatusValue } from "react-dropzone-uploader";
import { useRef } from "react";
import { IMerchantBankAccount } from "@components/Merchants/MerchantPreview/data.types";
import { challengeSlugs } from "@constants/challengeSlugs";
import { deleteDocumentAPI } from "@components/Settings/Business/Documents/utils";
import {
  useUploadFiles,
  useUploadPresignedDocument,
} from "@hooks/upload-api/uploadHooks";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { BANK_ACCOUNT_NUMBER_REGEX } from "@constants/constants";
import { getValidBankAccountNumber } from "@utils/bankAccountUtils";

type IFormInputsNew = {
  name: string;
  accountType: string;
  accountNumber: string;
  routingNumber: string;
  files: {
    allFiles: { file: File; id: any }[];
  };
  deletedFiles: number[];
  notes: string;
};
type IFormInputsOld = {
  name: string;
  accountType: string;
  accountNumber: string;
  routingNumber: string;
  files: {
    fileWithMeta: any | null;
    status: StatusValue;
    allFiles: any[];
  };
  deletedFiles: number[];
  notes: string;
};

type useCreateBankAccountProps = {
  submitHandler?: () => void;
  data?: IMerchantBankAccount;
  closeAction: () => false | void;
  isDefault?: boolean;
};

function useCreateBankAccount({
  submitHandler,
  data: bankAccount,
  closeAction,
  isDefault,
}: useCreateBankAccountProps) {
  const { merchantId } = useGetCurrentMerchantId();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery("(min-width: 720px)");
  const auth = useAppSelector(selectUser);
  const isForceError = useRef<boolean>(false);
  const {
    populateSnackbarFiles,
    isUploadAllowed,
    isLoading: uploadLoading,
  } = useFileUploadContext();

  const schema = Yup.object({
    name: Yup.string().trim().required("This field is required"),
    accountNumber: Yup.string().when("name", {
      is: (name: string) => Boolean(bankAccount),
      then: Yup.string(),
      otherwise: Yup.string()
        .matches(
          BANK_ACCOUNT_NUMBER_REGEX,
          "Please enter a valid account number",
        )
        .required("This field is required"),
    }),
    routingNumber: Yup.string()
      .required("This field is required")
      .matches(/^[0-9]{9}$/, "Please enter a valid routing number"),
  });

  const initialBankAccountNumber = bankAccount?.accountNumber
    ? `•••• ${bankAccount?.accountNumber}`
    : "";

  const defaultValues = {
    name: bankAccount?.name || "",
    accountType: bankAccount?.type?.toLowerCase() || "checking",
    accountNumber: initialBankAccountNumber,
    routingNumber: bankAccount?.routingNumber || "",
    notes: bankAccount?.notes || "",
    linkedAccountID: merchantId,
    files: {
      fileWithMeta: null,
      status: "started" as const,
      allFiles: [],
    },
    deletedFiles: [],
  };

  const methods = useForm<IFormInputsOld | IFormInputsNew>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { setError, watch } = methods;
  const values = watch();

  const createBankAccountMutation = useCreateBankAccountMutation(
    bankAccount?.id,
  );

  const { isLoading, isSuccess } = createBankAccountMutation;
  const { handleUpload } = useUploadPresignedDocument();
  const { handleUpload: handleUploadEdit } = useUploadFiles();
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
  const onSubmit: SubmitHandler<IFormInputsOld | IFormInputsNew> = async (
    data,
  ) => {
    const {
      name,
      accountType,
      accountNumber,
      routingNumber,
      notes,
      deletedFiles,
    } = data;

    let uploadRes: string[] | "upload_failed" = [];
    if (!bankAccount && data.files?.allFiles?.length > 0) {
      if (!isFileUploadRefactorEnabled) {
        uploadRes = (await populateSnackbarFiles({
          fileWithMeta:
            (data as IFormInputsOld).files?.fileWithMeta ||
            data.files?.allFiles[0],
          status: (data as IFormInputsOld).files?.status,
          allFiles: (data as IFormInputsOld).files?.allFiles,
        })) as string[] | "upload_failed";
      } else {
        uploadRes = await handleUpload({
          list: data.files?.allFiles,
        });
      }
    }

    if (bankAccount && data.files?.allFiles?.length > 0) {
      if (!isFileUploadRefactorEnabled) {
        uploadRes = (await populateSnackbarFiles(
          {
            fileWithMeta:
              (data as IFormInputsOld).files?.fileWithMeta ||
              data.files?.allFiles[0],
            status: (data as IFormInputsOld).files?.status,
            allFiles: (data as IFormInputsOld).files?.allFiles,
            attachmentType: "bank_account",
            label: "bank account document",
            tag: "bank account document",
            merchantId: merchantId,
            resourceID: +bankAccount?.id,
            onSuccess: () => {
              data.files?.allFiles.forEach((file) => file.remove());
            },
          },
          undefined,
          challengeSlugs.BANK_ACCOUNT_2,
        )) as string[] | "upload_failed";
      } else {
        uploadRes = await handleUploadEdit({
          list: data.files?.allFiles,
          attachmentType: "bank_account",
          merchantId: merchantId,
          resourceID: +bankAccount?.id,
          label: "bank account document",
          tag: "bank account document",
        });
      }
    }

    if (uploadRes === "upload_failed") return;

    const customData = {
      name,
      type: accountType,
      routingNumber,
      notes,
      bankName: findBankByRoutingNumber(routingNumber),
      ...(!bankAccount &&
        Array.isArray(uploadRes) && {
          statements: uploadRes,
        }),
      ...(bankAccount && {
        status:
          bankAccount.status === "upload" && data.files?.allFiles?.length === 0
            ? "pending"
            : "pending_review",
      }),
      ...(!bankAccount && {
        linkedAccountID: merchantId,
        status:
          data.files?.allFiles?.length === 0 ? "pending" : "pending_review",
        isDefault: isDefault || false,
      }),
      ...getValidBankAccountNumber({
        isForceError,
        setError,
        accountNumber,
        defaultAccountNumber: initialBankAccountNumber,
      }),
    };

    !isForceError.current &&
      createBankAccountMutation.mutate(
        bankAccount ? customData : { params: customData },
        {
          onError: (error: any) => {
            const axiosError = error;
            const errorMessage =
              axiosError.response?.statusText ||
              axiosError.response?.data?.message ||
              "Something went wrong.";
            showMessage("Error", errorMessage);
          },
          onSuccess: async (res: any) => {
            if (deletedFiles && deletedFiles?.length > 0) {
              deletedFiles.forEach((fileId) => {
                deleteDocumentAPI(merchantId, fileId, undefined, true);
              });
            }
            if (bankAccount) {
              showMessage("Success", "Bank Account details saved", isDesktop);
            } else {
              localStorage.setItem("hasAccount", "true");
              showMessage(
                "Success",
                "Bank Account has been successfully added",
                isDesktop,
              );
            }

            if (submitHandler) {
              submitHandler();
            }
            NiceModal.hide(NEW_ACCOUNT_MODAL);
            if (closeAction) closeAction();
            queryClient.refetchQueries("list-all-bank-accounts");
            if (bankAccount && !isDesktop && auth.role !== "acquirer") {
              navigate(
                `/${auth.role}/${
                  auth.role === "provider" ? "processing" : "manage-money"
                }`,
              );
            }
          },
        },
      );
  };

  return {
    methods,
    onSubmit,
    defaultValues,
    isDisabled: isLoading || uploadLoading,
    isLoading,
    isSuccess,
    isUploadAllowed,
  };
}

export default useCreateBankAccount;
