import React, { memo, useCallback, useState } from "react";
import { Box, styled } from "@mui/material";
import { palette } from "@palette";
import { SelectProps } from "@common/Select/Select";
import { Button } from "@common/Button";
import { PlusIcon } from "@assets/icons/RebrandedIcons";
import AssignmentMenuItem from "@common/AssignMenu/AssignmentMenuItem";
import Placeholder from "@assets/images/avatar-placeholder.png";
import { Text } from "@common/Text";
import { useUpdateAssignee } from "../../hooks/useGetTeamMembers";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import CustomAssignMenu from "./components/CustomAssignMenu";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { StatusNames } from "@components/Merchants/MerchantList/MerchantStatusFilters";
import { useAppSelector } from "@redux/hooks";
import { selectMerchantStatusName } from "@redux/slices/enterprise/merchants";
import { Tooltip } from "@common/Tooltip";

export type TUnderwritingAssignmentData = {
  accID: number;
  parentAccID: number;
  underwriterID: number;
  underwriterSetByAcquirer: boolean;
  statusName: string;
  underwriterName?: string;
  underwriterImageURL?: string;
  underwriterEmail?: string;
};

interface UnderwritingAssignmentProps extends Omit<SelectProps, "options"> {
  data: TUnderwritingAssignmentData;
  action?: (assignee?: string | null) => void;
  disabled?: boolean;
}

const UnderwritingAssignment = ({
  data,
  action,
  disabled,
}: UnderwritingAssignmentProps) => {
  const { isMobileView } = useCustomTheme();
  const { accID: merchantID, underwriterID, statusName } = data;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { handleChangeAssignee } = useUpdateAssignee(merchantID, {
    updateMerchantPreview: true,
  });

  const selectedTabStatus = useAppSelector(selectMerchantStatusName);
  const handleChange = (value: string | null) => {
    setAnchorEl(null);
    const newAssignee = value === "unassigned" ? null : value;

    const onSuccess = () => {
      if (action) action(newAssignee);
    };
    handleChangeAssignee(underwriterID, newAssignee, onSuccess);
  };

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const { isUpdateUnderwriterAllowed } = useUnderwriterPermissions();
  const isUnassigned = [null, undefined, 0]?.includes(underwriterID);

  return (
    <Box
      sx={{
        ...containerStyle,
        "& .MuiOutlinedInput-root div": {
          fontWeight: 350,
          color: isUnassigned ? palette.gray[100] : palette.neutral[70],
          "&: hover": {
            color: `${palette.neutral[80]} !important`,
          },
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      {isUnassigned ? (
        <Button
          disabled={!isUpdateUnderwriterAllowed}
          background="tertiary"
          sx={{ display: "inline-flex" }}
          size="small"
          startIcon={
            <PlusIcon height={20} width={20} stroke={palette.neutral[80]} />
          }
          onClick={(e) => handleOpenMenu(e)}
          data-testid="underwriting-assign-button"
        >
          <Text
            fontWeight="regular"
            variant="headline"
            color={
              isUpdateUnderwriterAllowed
                ? palette.neutral[80]
                : palette.gray[100]
            }
          >
            Assign
          </Text>
        </Button>
      ) : (
        <Tooltip
          title={data?.underwriterName || data?.underwriterEmail || ""}
          titleSx={{ textTransform: "none" }}
          sx={{
            "& .MuiTooltip-tooltip": {
              maxWidth: "350px",
            },
          }}
        >
          <AssignmentButton
            disabled={!isUpdateUnderwriterAllowed || disabled}
            onClick={(e) => {
              e.preventDefault();
              handleOpenMenu(e);
            }}
            data-testid="assigned-name-email-image"
          >
            <AssignmentMenuItem
              name={data?.underwriterName || data?.underwriterEmail || ""}
              image={
                data?.underwriterImageURL
                  ? `${data?.underwriterImageURL}/thumb`
                  : Placeholder
              }
              customStyle={{
                maxWidth: isMobileView ? "100%" : "250px",
                pointerEvents: "auto",
              }}
            />
          </AssignmentButton>
        </Tooltip>
      )}

      <CustomAssignMenu
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
        handleChange={handleChange}
        statusName={statusName}
        underwriterID={underwriterID}
        merchantId={merchantID}
        type={
          selectedTabStatus === StatusNames.aquirerRisk
            ? "risk"
            : "underwriting"
        }
      />
    </Box>
  );
};

const containerStyle = {
  "& .MuiButtonBase-root": {
    padding: 0,
  },
  "& .MuiInputBase-root": {
    background: `${palette.neutral[5]} !important`,
    borderRadius: "16px !important",
    padding: "6px 24px 6px 16px !important",
    border: "none !important",
    color: palette.neutral[50],
    "&: hover": {
      background: `${palette.neutral[10]} !important`,
    },
  },
};

const AssignmentButton = styled("button")(() => ({
  background: "none",
  color: "inherit",
  border: "none",
  padding: "0",
  font: "inherit",
  cursor: "pointer",
  outline: "inherit",
}));

export default memo(UnderwritingAssignment);
