import NiceModal from "@ebay/nice-modal-react";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import RespondToDisputeModalBody from "@components/Disputes/RespondToDispute/components/RespondToDisputeModalBody";
import { FormProvider } from "react-hook-form";
import { DISPUTE_CONFIRM_SUBMISSION_POPUP } from "modals/modal_names";
import { useRespondToDispute } from "./hooks/useRespondToDispute";

type Props = {
  disputeId: string;
  lastCaseId: string;
  merchantId: number;
  draftId?: string;
  caseAction?: string;
  caseNotes?: string;
};

const RespondToDisputeModal = NiceModal.create((props: Props) => {
  const {
    modal,
    form,
    values,
    handleSaveDraft,
    handleSubmitEvidence,
    handleClose,
    caseActions,
    isLoading,
  } = useRespondToDispute(props);

  const isValid = values.caseAction && values.caseNotes;

  const handleShowConfirmationPopup = () => {
    NiceModal.show(DISPUTE_CONFIRM_SUBMISSION_POPUP, {
      onSubmit: handleSubmitEvidence,
    });
  };

  return (
    <FormProvider {...form}>
      <ModalFactory
        variant="dialog"
        modalProps={{
          open: modal.visible,
          onClose: handleClose,
          width: 648,
          DialogProps: {
            contentContainerSx: {
              overflowY: "auto",
            },
          },
        }}
      >
        <ModalTitle
          title="Respond to Chargeback Dispute"
          description="Please review the details of the dispute and submit the required actions or evidence to contest the chargeback. Ensure all information is accurate and complete to expedite the dispute resolution process"
          padding="24px"
          onClose={handleClose}
          textStyles={{ title: { fontSize: 18, lineHeight: "21.6px" } }}
        />

        <RespondToDisputeModalBody caseActions={caseActions} />
        <ModalActions
          padding="0px 24px 16px"
          primaryAction={{
            label: "Submit Evidence",
            sx: { fontSize: "18px" },
            onClick: handleShowConfirmationPopup,
            disabled: !isValid || isLoading,
          }}
          secondaryAction={{
            label: "Save Draft and Close",
            sx: { fontSize: "18px" },
            onClick: handleSaveDraft,
            disabled: !isValid || isLoading,
          }}
          animationDelay={300}
        />
      </ModalFactory>
    </FormProvider>
  );
});

export default RespondToDisputeModal;
