import { Box } from "@mui/material";
import GiveButton from "@shared/Button/GiveButton";
import GiveChip from "@shared/Chip/GiveChip";
import { styled } from "@theme/v2/Provider";

export const CardContainer = styled(Box)<{
  backgroundColor?: string;
}>(({ backgroundColor }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "20px 24px",
  backgroundColor: backgroundColor,
  borderRadius: "20px",
  maxWidth: "560px",
  gap: "16px",
}));

export const ModifiedGiveChip = styled(GiveChip)({
  fontSize: "12px",
  padding: "4px 8px",
});

export const StyledGiveButton = styled(GiveButton)({
  borderRadius: "8px",
  "&:disabled": {
    border: "none",
  },
});

export const IconContainer = styled(Box)<{ backgroundColor?: string }>(
  ({ backgroundColor }) => ({
    backgroundColor: backgroundColor,
    borderRadius: "40px",
    padding: "8px",
    width: "36px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
);
