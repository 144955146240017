import { getJsonValue } from "../utils";

export const getCustomBaseInputStyle = (
  mode: "light" | "dark",
  basePallete: any,
  palette: any,
) => ({
  styleOverrides: {
    root: {
      backgroundColor: getJsonValue(`tokens.${mode}.colour.surface.primary`),
      padding: "10px",
      borderRadius: "8px",
      border:
        "1px solid " + getJsonValue(`tokens.${mode}.primitive.neutral.20`),
      "&:before": {
        borderBottom: "none",
      },
      "&:after": {
        borderBottom: "none",
      },
      "&:hover:not(.Mui-disabled, .Mui-error, .Mui-focused)": {
        border:
          "1px solid " + getJsonValue(`tokens.${mode}.primitive.neutral.90`),
      },
      "&.Mui-focused:not(.Mui-disabled, .Mui-error)": {
        background: basePallete.gradient["ocean-blue"].border,
        border: "1.5px solid transparent",
      },
      "&:hover:not(.Mui-disabled, .Mui-error)&:before": {
        borderBottom: "none",
      },
      "&.Mui-disabled:before": {
        borderBottomStyle: "none",
      },
      "&.Mui-error": {
        border:
          "1px solid " +
          getJsonValue(`tokens.${mode}.primitive.citrus-peel.100`),
      },
      "&.Mui-focused": {
        border: "solid 1.5px transparent",
        backgroundImage: `linear-gradient(${palette.surface.primary}, ${palette.surface.primary}), ${basePallete.gradient["aqua-horizon"].border}`,
        backgroundOrigin: "border-box",
        backgroundClip: "padding-box, border-box",
      },
    },
  },
});
