import { Box, Slide } from "@mui/material";
import React, { CSSProperties, memo } from "react";
import { animated, useInView, useSpring } from "react-spring";

type TGiveMotionWrapperProps = {
  children: React.ReactElement;
  type?: "fadeInUp" | "slide";
  delay?: number;
  duration?: number;
  direction?: "up" | "down" | "left" | "right";
  reset?: boolean;
  containerProps?: any;
  customStyle?: CSSProperties;
  inView?: boolean;
};

type TFadeInUp = Omit<TGiveMotionWrapperProps, "type">;

const GiveMotionWrapper = ({
  children,
  type = "fadeInUp",
  delay = 0,
  duration = 500,
  direction = "right",
  reset = false,
  containerProps,
  customStyle,
  inView = true,
}: TGiveMotionWrapperProps) => {
  const props = {
    children,
    delay,
    duration,
    reset,
    containerProps,
    customStyle,
  };

  if (type === "fadeInUp") {
    return <FadeInUp {...props} />;
  }

  return (
    <Slide direction={direction} in={inView} timeout={duration}>
      {children}
    </Slide>
  );
};

const FadeInUp = ({
  children,
  delay = 0,
  duration = 500,
  reset = false,
  containerProps,
  customStyle,
}: TFadeInUp) => {
  const [ref, inView] = useInView({ once: true });

  const styles = useSpring({
    opacity: inView ? 1 : 0,
    y: inView ? 0 : 50,
    from: { opacity: 0, y: 50 },
    config: { duration, easing: (t: any) => t * (2 - t) },
    delay: Math.min(delay, 1000),
    reset,
    ...customStyle,
  });

  return (
    <Box ref={ref} width="100%" {...containerProps}>
      <animated.div style={styles}>{children}</animated.div>
    </Box>
  );
};

export default memo(GiveMotionWrapper);
