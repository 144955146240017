import { NewMessageIcon } from "@assets/icons";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { EditMerchantHeaderHandlers } from "@components/Merchants/MerchantPreview/helpers/types";
import { FEATURE_DENY_MESSAGE } from "@constants/permissions";
import { Box, ClassNameMap, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  ArrowDown,
  ArrowUp,
  ChatsTeardrop,
  DotsThreeVertical,
  X,
} from "@phosphor-icons/react";
import { createContext, ReactNode, useContext, useState } from "react";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { Button } from "@common/Button";
import { palette } from "@palette";
import GiveLink from "@shared/Link/GiveLink";
import { ContextualMenuOptionProps } from "@shared/ContextualMenu/ContextualMenu.types";

export interface ISidePanelHeaderProps {
  nextPrevState: {
    isLast: boolean;
    isFirst: boolean;
  };
  sidePanelName?: string;
  actions: ContextualMenuOptionProps[];
  handlers: EditMerchantHeaderHandlers;
  hasAlert: boolean;
  isControlModeAllowed: boolean;
  isRiskMonitorAllowed: boolean;
  hasUnreadMessages: boolean;
  isLoading?: boolean;
}

export interface IButtonComponent {
  prev: JSX.Element;
  next: JSX.Element;
  riskMonitor: JSX.Element;
  controlMode: JSX.Element;
  conversation: JSX.Element;
  merchantFile: JSX.Element;
  agreement: JSX.Element;
  moreActions: JSX.Element;
  closeButton: JSX.Element;
}

interface ISidePanelHeader extends Omit<ISidePanelHeaderProps, "children"> {
  ButtonComponent: IButtonComponent;
  anchorEl: HTMLElement | null;
  onCloseMenu: () => void;
  classes: ClassNameMap<
    | "container"
    | "verticalDivider"
    | "mainActionsContainer"
    | "iconBtn"
    | "iconBtnText"
  >;
}

type TSidePanelHeaderContext = ISidePanelHeader | undefined;
const SidePanelHeaderContext =
  createContext<TSidePanelHeaderContext>(undefined);

export const useSidePanelHeaderContext = () =>
  useContext(SidePanelHeaderContext) as ISidePanelHeader;

export const SidePanelHeaderProvider: React.FC<
  ISidePanelHeaderProps & { children: ReactNode | ReactNode[] }
> = ({
  nextPrevState,
  actions,
  handlers,
  hasAlert,
  isControlModeAllowed,
  isRiskMonitorAllowed,
  children,
  sidePanelName,
  hasUnreadMessages = true,
  isLoading = false,
}) => {
  const {
    handleControlMode,
    handleNext,
    handlePrevious,
    handleClose,
    handleOpenRiskProfile,
    handleOpenMerchantFile,
    handleOpenAgreementSnapshot,
  } = handlers;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();
  const { risk_monitoring, control_mode } = useEnterprisePermissions();

  const onOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const ButtonComponent = {
    prev: (
      <GiveIconButton
        variant="ghost"
        onClick={handlePrevious}
        disabled={nextPrevState.isFirst}
        Icon={ArrowUp}
      />
    ),
    next: (
      <GiveIconButton
        variant="ghost"
        onClick={handleNext}
        disabled={nextPrevState.isLast}
        Icon={ArrowDown}
      />
    ),
    conversation: (
      <Button
        onClick={() => void 0}
        className={`${classes.iconBtn} ${classes.iconBtnText}`}
        sx={{
          cursor: "pointer",
          position: "relative",
        }}
        data-testid="Conversation-Icon"
      >
        <ChatsTeardrop width="17.5px" height="17.5px" />
        {hasUnreadMessages && (
          <Box
            sx={{ position: "absolute", top: "-4px", right: "-2px" }}
            data-testid="New-Message-Icon"
          >
            <NewMessageIcon />
          </Box>
        )}
      </Button>
    ),
    riskMonitor: (
      <>
        {risk_monitoring && isRiskMonitorAllowed && (
          <WithAlertWrapper active={hasAlert}>
            <Button
              className={`${classes.iconBtn} ${classes.iconBtnText}`}
              onClick={handleOpenRiskProfile}
              data-testid="risk-monitor-button"
              sx={{ fontSize: "14px" }}
            >
              Risk Monitor
            </Button>
          </WithAlertWrapper>
        )}
      </>
    ),
    agreement: (
      <GiveLink
        component="button"
        sx={{ whiteSpace: "nowrap" }}
        onClick={handleOpenAgreementSnapshot}
      >
        Agreement
      </GiveLink>
    ),
    merchantFile: (
      <GiveLink
        component="button"
        sx={{ whiteSpace: "nowrap" }}
        onClick={handleOpenMerchantFile}
        data-testid="merchant-file-button"
      >
        Merchant File
      </GiveLink>
    ),
    controlMode: (
      <>
        {control_mode && (
          <Button
            className={`${classes.iconBtn} ${classes.iconBtnText}`}
            onClick={handleControlMode}
            disabled={!isControlModeAllowed}
            data-testid="control-mode-button"
            tooltipProps={{
              show: !isControlModeAllowed,
              message: FEATURE_DENY_MESSAGE,
            }}
            sx={{ fontSize: "14px" }}
          >
            Control mode
          </Button>
        )}
      </>
    ),
    moreActions: (
      <GiveIconButton
        style={{
          marginLeft: "auto",
        }}
        variant="ghost"
        onClick={onOpenMenu}
        data-testid="more-action-button"
        Icon={DotsThreeVertical}
      />
    ),
    closeButton: (
      <GiveIconButton variant="ghost" onClick={handleClose} Icon={X} />
    ),
  };

  return (
    <SidePanelHeaderContext.Provider
      value={{
        ButtonComponent,
        nextPrevState,
        sidePanelName,
        actions,
        handlers,
        classes,
        hasAlert,
        isControlModeAllowed,
        isRiskMonitorAllowed,
        hasUnreadMessages,
        onCloseMenu,
        anchorEl,
        isLoading,
      }}
    >
      {children}
    </SidePanelHeaderContext.Provider>
  );
};

const WithAlertWrapper = ({
  active,
  children,
}: {
  active: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      {active && (
        <Box
          sx={{
            background: "#FF5924",
            borderRadius: "50%",
            width: "8px",
            height: "8px",
          }}
        />
      )}
      {children}
    </Stack>
  );
};

export const useStyles = makeStyles(() => ({
  container: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  verticalDivider: {
    width: 1,
    height: "22px",
    borderLeft: `1px solid ${palette.neutral[30]}`,
  },
  mainActionsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "16px",
  },
  iconBtn: {
    border: "none",
    boxShadow: "none",
    background: "inherit",
    width: "fit-content !important",
    minWidth: "32px",
    padding: "5px 5px",
    height: "32px",
    "&:hover": {
      background: palette.neutral[20],
      boxShadow: "none",
    },
    "&:disabled": {
      background: "transparent",
      boxShadow: "none",
    },
    "&:focus": {
      background: "transparent",
      boxShadow: "none",
    },
    "&:active": {
      background: "transparent",
      boxShadow: "none",
    },
  },
  iconBtnText: {
    textDecoration: "none",
    color: palette.neutral[80],
    fontWeight: 400,
    lineHeight: "16.8px",
    whiteSpace: "nowrap",
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)",
    },
    "&:hover": {
      textDecoration: "underline",
      background: "transparent",
      boxShadow: "none",
    },
  },
}));
