import React from "react";
import { styled } from "@theme/v2/Provider";
import GiveText from "@shared/Text/GiveText";
function ItemText({ text }: { text: string }) {
  return <Text>{text}</Text>;
}

export default ItemText;

const Text = styled(GiveText)(() => {
  return {
    fontSize: "14px",
    fontWeight: "400",
  };
});
