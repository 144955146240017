import { Box, Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";

import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { HFGiveInput } from "@shared/HFInputs/HFGiveInput/HFGiveInput";
import GiveTabs from "@shared/Tabs/GiveTabs";
import { Assertion_position } from "@sections/PayBuilder/provider/provider.type";
import ImageSection from "./ImageSection";
import { styled } from "@theme/v2/Provider";

function About() {
  const { methods } = usePayBuilderForm();
  const { setValue, watch } = methods;

  const assetPosition = watch("About.assetPosition");

  return (
    <Box pb="20px">
      <GiveText mt="40px" fontWeight={271} fontSize="28px" color="primary">
        Introduce your products
      </GiveText>
      <GiveText mt="12px" fontWeight={400} fontSize="14px" color="secondary">
        This introduction is placed at the top of the page
      </GiveText>

      <Stack gap="24px" mt="40px">
        <HFGiveInput name="About.heading" label="Heading" />
        <TextArea
          label="Description (Optional)"
          name="About.description"
          InputProps={{
            inputComponent: "textarea",
          }}
          maxLength={600}
          rows={6}
          displayCounter
        />
        <Box>
          <GiveText mb="8px" fontWeight={400} fontSize="14px" color="primary">
            Asset Position
          </GiveText>
          <GiveTabs
            selected={assetPosition}
            containerSx={{
              width: "100%",
              justifyContent: "space-around",
              padding: "4px",
            }}
            tabSx={{ flex: 1, justifyContent: "center" }}
            items={assetTabs}
            onClick={(e) => {
              setValue("About.assetPosition", e as Assertion_position);
            }}
            type="segmented"
          />
        </Box>
        <ImageSection />
      </Stack>
    </Box>
  );
}

export default About;

const assetTabs = [
  {
    label: "Left",
    value: "left",
  },
  {
    label: "Right",
    value: "right",
  },
];

const TextArea = styled(HFGiveInput)(({ theme }) => {
  return {
    "& .MuiInputBase-root": {
      padding: "0 !important",
    },
    "& .MuiInputBase-input": {
      height: "150px",
    },
    "& textarea": {
      resize: "none",
    },
  };
});
