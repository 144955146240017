import { MerchantAgreementForm } from "@components/Merchants/CreateMerchantPanel/modals/components/MerchantAgreement/Form";
import { Box } from "@mui/material";
import { KotoBackArrow } from "@assets/icons";
import { Button } from "@common/Button";
import { useEffect, useMemo } from "react";
import * as Yup from "yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFormData } from "./MerchantAgreementFormProvider";
import { IParsedData } from "@components/Merchants/MerchantPreview/data.types";
import { MerchantAgreementEnum } from "./types";
import moment from "moment";
import { Disclaimer } from "@components/Merchants/CreateMerchantPanel/modals/components/MerchantAgreement/Disclaimer";
import { yupRequiredOnCondition } from "@components/Merchants/CreateMerchantPanel/hooks/useAddMerchantAgreement";

type FormInputs = {
  msaRefundPolicy: string;
  msaPCICompliant: boolean;
  msaPreviousTermination: boolean;
  msaPreviousTerminationProcessorName: string;
  msaPreviousTerminationReason: string;
  msaPreviousTerminationAt: Date | null;
};

type Props = {
  handleNext: () => void;
  handleBack: () => void;
  merchantData: IParsedData;
  setStatusBar: React.Dispatch<
    React.SetStateAction<
      {
        label: MerchantAgreementEnum;
        barValue: number;
      }[]
    >
  >;
};

const AddMerchantAgreement = ({
  handleNext,
  handleBack,
  merchantData,
  setStatusBar,
}: Props) => {
  const { formData, setFormValues } = useFormData();

  const defaultMsaPCICompliant =
    formData.merchantAgreement.msaPCICompliant &&
    formData.merchantAgreement?.msaLastAcceptedVersion ===
      formData.merchantAgreement?.tcVersion;

  const defaultValues = {
    msaRefundPolicy: formData.merchantAgreement.msaRefundPolicy,
    msaPCICompliant: defaultMsaPCICompliant,
    msaPreviousTermination: formData.merchantAgreement.msaPreviousTermination,
    msaPreviousTerminationProcessorName:
      formData.merchantAgreement.msaPreviousTerminationProcessorName,
    msaPreviousTerminationReason:
      formData.merchantAgreement.msaPreviousTerminationReason,
    msaPreviousTerminationAt:
      formData.merchantAgreement.msaPreviousTerminationAt,
  };

  const msaLastAcceptedAt = merchantData?.merchantAgreement?.msaLastAcceptedAt;

  const schema = Yup.object().shape({
    msaRefundPolicy: Yup.string().required("RefundPolicy is required"),
    msaPCICompliant: Yup.boolean().isTrue(),
    msaPreviousTermination: Yup.boolean(),
    msaPreviousTerminationProcessorName: yupRequiredOnCondition(
      "Previous termination processor name is required",
    ),
    msaPreviousTerminationReason: yupRequiredOnCondition(
      "Reason for Termination is required",
    ),
    msaPreviousTerminationAt: Yup.date().when("msaPreviousTermination", {
      is: true,
      then: Yup.date().required("is required"),
      otherwise: Yup.date().nullable(),
    }),
  });

  const methods = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    watch,
    formState: { isValid },
  } = methods;
  const values = watch();

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    setFormValues("merchantAgreement", data);
    handleNext();
  };

  const status = useMemo(() => {
    if (merchantData?.merchantAgreement) {
      const { msaLastAcceptedVersion, tcVersion, msaHadAgreed } =
        merchantData.merchantAgreement;
      const isCorrectVersion = msaLastAcceptedVersion === tcVersion;
      if (msaHadAgreed && isCorrectVersion) return "signed";
      if ((!msaHadAgreed && isCorrectVersion) || !msaLastAcceptedVersion)
        return "not_signed";

      return "pending";
    }
    return "not_signed";
  }, [merchantData?.merchantAgreement]);

  useEffect(() => {
    setStatusBar((prev) => {
      const updated = prev;
      updated[0] = {
        label: MerchantAgreementEnum.MERCHANT_AGREEMENT,
        barValue: values.msaPCICompliant ? 100 : 0,
      };
      return updated;
    });
  }, [values.msaPCICompliant]);

  return (
    <FormProvider {...methods}>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ flexGrow: 1, paddingBottom: "56px" }}
        component="form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Disclaimer
          status={status}
          accountHolderNameFullName={`${merchantData?.primaryAccountHolder?.firstName} ${merchantData?.primaryAccountHolder?.lastName}`}
          dateTime={
            msaLastAcceptedAt
              ? moment.unix(msaLastAcceptedAt).format("MM-DD-YYYY HH:mm:ss")
              : ""
          }
          lastVersion={`${merchantData?.merchantAgreement?.msaLastAcceptedVersion}`}
          version={`${merchantData?.merchantAgreement?.tcVersion}`}
          merchantId={merchantData?.merchantInfo?.merchantID}
          onCheckUpdates={handleNext}
          signatureURL={merchantData?.merchantAgreement?.signatureURL}
        />
        <MerchantAgreementForm
          isEditForm={status === "signed"}
          isOnboarding
          data={merchantData}
        />
        <Box flexGrow={1} />
        <Box
          mt={3}
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            background="tertiary"
            size="small"
            startIcon={<KotoBackArrow />}
            onClick={handleBack}
          >
            Homepage
          </Button>
          <Button
            disabled={!isValid}
            background="primary"
            size="small"
            type="submit"
          >
            Read and Sign
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default AddMerchantAgreement;
