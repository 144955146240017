import { Text } from "@common/Text";
import NiceModal from "@ebay/nice-modal-react";
import { styled } from "@mui/material";
import { palette } from "@palette";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { BtnBGTypes } from "@common/Button/Button_V2";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";

type Props = {
  handleChange: () => void;
};

const ChangeProviderModal = NiceModal.create(({ handleChange }: Props) => {
  const { open, onClose, TransitionProps } = useNiceModal();

  return (
    <ModalFactory
      variant="popup"
      modalProps={{
        open,
        onClose,
        PopupProps: {
          width: "420px",
          paperStyle: { padding: "24px 24px 16px " },
          TransitionProps,
        },
      }}
    >
      <ModalTitle
        title="Change Provider"
        closeButtonSize={22}
        onClose={onClose}
        textStyles={{
          title: {
            fontSize: "18px",
            fontWeight: 300,
            lineHeight: "21.6px",
          },
        }}
      />
      <FadeUpWrapper delay={100}>
        <StyledText>
          Changing the provider will remove the bank account currently linked to
          this merchant.
        </StyledText>
      </FadeUpWrapper>
      <ModalActions
        containerProps={{ sx: { mt: "20px", gap: "16px" } }}
        primaryAction={{
          label: "Yes, change",
          background: "red" as BtnBGTypes,
          type: "button",
          onClick: () => {
            handleChange();
            onClose()
          },
        }}
        secondaryAction={{
          label: "Discard",
          onClick: onClose,
        }}
      />
    </ModalFactory>
  );
});

const StyledText = styled(Text)(() => ({
  color: palette.neutral[70],
  fontFamily: "Give Whyte",
  fontSize: "14px",
  fontWeight: 350,
  lineHeight: "16.8px",
  padding: 0,
  marginTop: "8px",
  marginBottom: "0px",
  "& span": { color: palette.neutral[80] },
}));

export default ChangeProviderModal;
