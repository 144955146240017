import useRedirect from "@hooks/common/router/useRedirect";
import { getMerchantById } from "@hooks/enterprise-api/account/useGetMerchants";
import { useGetUser } from "@services/api/onboarding/user";
import {
  MERCHANT_PREVIEW_PANEL_MODAL,
  RESOURCE_NOT_FOUND,
} from "modals/modal_names";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useRedirectToMerchantPanel = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { merchantData } = useGetUser();
  const { redirectToModal } = useRedirect();

  useEffect(() => {
    if (merchantData) {
      const path = "/acquirer/merchants";

      const searchParams = new URLSearchParams(location.search);
      const merchantId = searchParams.get("merchantId");
      const section = searchParams.get("section");

      const checkMerchantAccess = async (merchantId: string) => {
        navigate(path);
        try {
          // If merchant is accessible to the current user we open sidepanel, otherwise show resource not found popup
          await getMerchantById(merchantId);
          redirectToModal(MERCHANT_PREVIEW_PANEL_MODAL, {
            id: merchantId,
            initialSection: section,
          });
        } catch (err) {
          redirectToModal(RESOURCE_NOT_FOUND, {
            title:
              "This escalated activity is linked to a merchant you cannot view",
            info: "You don’t have access to view this merchant's escalated activity, as it is not included in your merchant list. Please sign out of the current account and sign in to the appropriate account.",
            styles: {
              titleStyle: {
                fontSize: "18px",
              },
              containerWidth: "648px",
            },
          });
        }
      };

      if (merchantId) {
        checkMerchantAccess(merchantId);
      }
    }
  }, [merchantData, location.search]);
};
