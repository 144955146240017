import * as React from "react";
import { useFormContext, Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import { MuiTelInput, MuiTelInputProps } from "mui-tel-input";
import { Tooltip, TooltipProps } from "@common/Tooltip/Tooltip";
import { styled } from "@mui/material";

export type InputProps = MuiTelInputProps & {
  color?: string;
  error?: boolean;
  disabled?: boolean;
  inputRef?: React.Ref<any>;
  label?: React.ReactNode;
  size?: "small" | "medium";
  helperText?: React.ReactNode;
  tooltipProps?: TooltipProps;
  flagStyles?: any;
  focusViewColor?: string;
};

const TelInput = ({
  label,
  size = "medium",
  color,
  error,
  helperText,
  disabled,
  tooltipProps,
  value,
  flagStyles,
  focusViewColor,
  ...rest
}: InputProps) => {
  const [isFocused, setIsFocused] = React.useState(false);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const caret: number | null = event.target.selectionStart;
    const value: string = event.target.value;

    const succeedingChar: string =
      caret !== null && caret < value.length ? value.charAt(caret) : "";

    const element = event.target as HTMLInputElement;
    window.requestAnimationFrame(() => {
      element.selectionStart =
        succeedingChar === "" ? (caret !== null ? caret + 1 : null) : caret;
      element.selectionEnd = element.selectionStart;
    });
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap="4px">
      <StyledTelInput
        isFocused={isFocused}
        focusViewColor={focusViewColor}
        flagStyles={flagStyles}
        size={size}
        ref={inputRef}
        value={value}
        onInput={handleInput}
        inputProps={{
          maxLength: 12,
          "data-testid": "phone-input",
        }}
        {...rest}
        disabled={disabled}
        helperText={helperText}
        label={label}
        error={error}
        onBlur={(e) => {
          setIsFocused(false);
          if (rest.onBlur) {
            rest.onBlur(e);
          }
        }}
        onFocus={() => setIsFocused(true)}
        InputLabelProps={{
          variant: "filled",
          shrink: true,
          sx: {
            textAlign: "start",
          },
        }}
      />
      {tooltipProps?.title && tooltipProps?.children && (
        <Box
          sx={{
            ...(error && { paddingBottom: "14px" }),
          }}
        >
          <Tooltip {...tooltipProps} />
        </Box>
      )}
    </Box>
  );
};

export default TelInput;

export const RHFTelInput = ({
  name,
  helperText,
  disableFormatting = false,
  formatUSOnly = true,
  ...props
}: InputProps & {
  name: string;
  formatUSOnly?: boolean;
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...rest }, fieldState: { error } }) => {
        const shouldFormat =
          formatUSOnly &&
          !!rest.value &&
          typeof rest.value === "string" &&
          rest.value.substring(0, 2) === "+1";
        //REFACTOR NEEDED: rest of the countries use different formatting, and it messes up validation, we should use our own tel input instead of library
        return (
          <TelInput
            inputRef={ref}
            {...rest}
            error={!!error}
            helperText={helperText || error?.message}
            defaultCountry="US"
            forceCallingCode
            disableFormatting={disableFormatting || !shouldFormat}
            {...props}
          />
        );
      }}
    />
  );
};

interface IStyledProps {
  isFocused: boolean;
  focusViewColor?: string;
  flagStyles?: any;
}

const StyledTelInput = styled(MuiTelInput, {
  shouldForwardProp: (prop) =>
    prop !== "isFocused" && prop !== "focusViewColor" && prop !== "flagStyles",
})<IStyledProps>(({ focusViewColor, isFocused, flagStyles }) => ({
  ...(focusViewColor && {
    "& .MuiInputBase-root": {
      border: `2px solid ${focusViewColor} !important`,
      transition: "border 250ms ease",
    },
  }),
  "& .MuiInputAdornment-positionStart .MuiTypography-root": {
    marginTop: "16px",
    paddingRight: 0,
    paddingLeft: "5px",
    borderRight: "none",
    fontFamily: "Give Whyte",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px",
  },
  "& .Mui-disabled .MuiInputAdornment-positionStart .MuiTypography-root": {
    marginTop: "19px",
    WebkitTextFillColor: "rgba(0, 0, 0, 0.38)",
  },
  "& .MuiTelInput-IconButton": {
    padding: 0,
    height: "auto",
    boxShadow: "none",
    backgroundColor: "inherit",
    border: "none",

    "&:hover": {
      boxShadow: "none",
      background: "none",
    },

    "&:active, &:focus": {
      border: "none",
      boxShadow: "none",
    },
  },

  "& .MuiTelInput-Flag img": {
    width: 24,
    height: 24,
    borderRadius: "50%",
    ...flagStyles,
  },

  "& .MuiButtonBase-root": {
    "& input": {
      "&::placeholder": {
        ...(!isFocused && {
          display: "none",
        }),
      },
    },
  },

  "& .MuiInputLabel-root": {
    left: "30px",
    width: "calc(100% + 22.5px)",
  },
}));
