import { PaletteMode, ThemeProvider, styled as styledd } from "@mui/material";
import React, { ReactNode, useMemo, useState } from "react";
import { CustomTheme } from "./palette.interface";
import { useTheme } from "@emotion/react";
import { CreateMUIStyled } from "@mui/material";
import { ThemeMode } from "@theme/v2/types";
import { useGetFeatureFlagValues } from "../../FeatureFlags/useGetFeatureFlagValues";
import useCombineThemes from "@theme/v2/hooks/useCombineThemes";

export const ColorModeContext = React.createContext({
  toggleColorMode: (mode: "light" | "dark") => {
    return;
  },
});

export const ThemeWrapper = ({
  children,
  defaultMode = "light",
  isFeatureFlagBound = false,
  isNewThemeOnly = false,
  combineThemesIOC,
}: {
  children: ReactNode;
  defaultMode?: ThemeMode;
  isFeatureFlagBound?: boolean;
  isNewThemeOnly?: boolean;
  combineThemesIOC?: any;
}) => {
  const { isRebrandingEnabled } = useGetFeatureFlagValues();
  const [mode, setMode] = useState<PaletteMode>(defaultMode);

  const colorMode = useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) =>
          prevMode === "light" ? "dark" : "light",
        );
      },
    }),
    [],
  );

  const combineThemes = useCombineThemes(mode, isNewThemeOnly);

  if (!isRebrandingEnabled && isFeatureFlagBound) return <>{children}</>;

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={combineThemesIOC ?? combineThemes}>
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export const useAppTheme = (): CustomTheme => {
  const theme = useTheme();

  return theme as CustomTheme;
};

export const styled: CreateMUIStyled<CustomTheme> = styledd;
