import {
  TBusinessOwner,
  TPrimaryAccountHolderAdress,
} from "@components/Merchants/MerchantPreview/data.types";
import { getCountryNameFromCode } from "@utils/country_dial_codes";
import { fromUnixTime } from "date-fns";

export const parseTaxID = (id: string) => {
  if (!id) return id;
  if (id?.length > 2) return id.slice(0, 2) + "-" + id.slice(2);
  return id;
};

export const parsePhoneNumber = (number = "") => {
  if (!number) return "";
  if (number.includes("+")) return number;
  return `+${number}`;
};

export const formatPrimaryAccountHolderAddress = (
  address: TPrimaryAccountHolderAdress,
) => {
  if (!address || !address.line1) return "";
  const { line1, city, state, country, zip } = address;
  return `${zip} ${line1}, ${city}, ${state}, ${getCountryNameFromCode(
    country,
  )}`;
};

export const betterPhoneNumber = (phoneNumber: string): string => {
  // Check if the phoneNumber has a valid length
  const isValidLength = phoneNumber.length === 11;

  if (!isValidLength) {
    return phoneNumber;
  }

  const formatted = `+${phoneNumber.substring(0, 1)} (${phoneNumber.substring(
    1,
    4,
  )}) ${phoneNumber.substring(4, 7)}-${phoneNumber.substring(7)}`;

  return formatted;
};

export const adjustAddressType = (owner: TBusinessOwner) => {
  const { id, dob, businessAddress, address, isBusinessAddress, ownership } =
    owner;

  const customAddress = {
    city: address?.city || businessAddress?.city,
    country: address?.country || businessAddress?.country,
    street: address?.line1 || businessAddress?.line1,
    zip: address?.zip || businessAddress?.zip,
    province: address?.state || businessAddress?.state,
  };
  const { city, country, street, zip, province } = customAddress;

  const dateOfBirth =
    typeof dob === "string"
      ? new Date(dob)
      : typeof dob === "number"
      ? fromUnixTime(dob)
      : dob;
  return {
    ...owner,
    id: parseInt(id),
    dob: dateOfBirth,
    businessAddress: {
      city: city || "",
      country: country || "US",
      street: street || "",
      zip: zip || "",
      province: province || "",
    },
    useBusinessAddress: isBusinessAddress || false,
    ownership: ownership || "0",
  };
};
