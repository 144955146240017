type TImageSize = "small" | "medium" | "large" | "thumb" | "original";

export function addSizeToImage(
  url: string,
  size: TImageSize,
  fallback?: string,
) {
  if (!url && fallback) return fallback;

  if (!url) return "";

  const urlIncludesSize = [
    "/small",
    "/large",
    "/medium",
    "/original",
    "/thumb",
  ].some((substring) => url.includes(substring));

  if (urlIncludesSize) return url;

  return url + "/" + size;
}
