import { Stack } from "@mui/material";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import ProfileMenu from "./UserSection/ProfileMenu";
import NotificationButton from "./UserSection/NotificationsButton";
import useNotifications from "./hooks/useNotifications";

const UserSection = ({
  open,
  isAppBar,
}: {
  open?: boolean;
  isAppBar?: boolean;
}) => {
  const { isMobileView } = useCustomThemeV2();
  const { unreadNotifications } = useNotifications();

  return (
    <Stack
      direction={isAppBar ? "row-reverse" : open ? "row" : "column-reverse"}
      gap={isMobileView ? "20px" : "12px"}
      alignItems="center"
    >
      <ProfileMenu />
      <NotificationButton unreadNotifications={unreadNotifications} />
    </Stack>
  );
};

export default UserSection;
