import { ProfileSetupFormContainer } from "@components/ProfilePage/form.components";
import { Box } from "@mui/material";
import { usePersonalInformationProvider } from "../Provider/PersonalInformationProvider";
import { personalInfoEnum } from "../types";
import { useState, useEffect } from "react";
import DesktopSelfieTab from "./DesktopSelfieTab";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import MobileSelfieTab from "./MobileSelfieTab";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

function TakeSelfieTab() {
  const { isMobileView } = useCustomTheme();
  const [completed, setCompleted] = useState(false);
  const [selfieUrl, setSelfieUrl] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const { isNewOnboardingEnabled } = useGetFeatureFlagValues();
  const {
    handleBack,
    enterpriseData,
    handleUpdateStatusValue,
    homepageReset,
    files,
  } = usePersonalInformationProvider();

  const updateProgressBar = handleUpdateStatusValue(
    personalInfoEnum.TAKE_A_SELFIE,
  );
  const selfieUploaded = enterpriseData?.owner?.selfieUploaded;

  useEffect(() => {
    if (selfieUploaded) {
      updateProgressBar(100);
    } else {
      updateProgressBar(0);
    }
  }, [selfieUploaded]);

  const dbSelfieURL: string | null = files?.data?.reduce(
    (latest: any, current: any) => {
      if (
        current.attTypeName === "account_owner_selfie" &&
        current.updatedAt > latest.updatedAt
      ) {
        return current;
      }
      return latest;
    },
    { updatedAt: 0 },
  )?.fileURL;

  return (
    <Box flexGrow={1} display="flex" height="100%">
      <ProfileSetupFormContainer>
        {isMobileView && isNewOnboardingEnabled ? (
          <MobileSelfieTab
            updateProgressBar={updateProgressBar}
            handleBack={handleBack}
            dbSelfieURL={dbSelfieURL}
            selfieUrl={selfieUrl}
            setSelfieUrl={setSelfieUrl}
            setCompleted={setCompleted}
            homepageReset={homepageReset}
            merchant={enterpriseData}
            isLoggedInPAH
          />
        ) : (
          <DesktopSelfieTab
            enterpriseData={enterpriseData}
            updateProgressBar={updateProgressBar}
            setIsUploading={setIsUploading}
            handleBack={handleBack}
            dbSelfieURL={dbSelfieURL}
            selfieUrl={selfieUrl}
            setSelfieUrl={setSelfieUrl}
            completed={completed}
            setCompleted={setCompleted}
            homepageReset={homepageReset}
            isUploading={isUploading}
            isLoggedInPAH
            isSelfieStepActive
          />
        )}
      </ProfileSetupFormContainer>
    </Box>
  );
}

export default TakeSelfieTab;
