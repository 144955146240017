import { customInstance } from "..";

export const verifyTfaVerificationCode = (data: {
  token: string;
  trust?: boolean;
}) => {
  return customInstance({
    url: `/tfa-verifications/${data.token}`,
    method: "POST",
    data: {
      trust: data.trust ?? false,
    },
  });
};

export const sendTfaVerificationCode = (data: "sms" | "email") => {
  return customInstance({
    url: "/tfa-verifications",
    method: "POST",
    data: {
      method: data,
    },
  });
};
