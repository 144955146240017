import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import MainHeader from "./components/MainHeader/MainHeader";
import PanelLoadingSkeleton from "./components/PanelLoadingSkeleton";
import useGetPanelContent from "./hooks/useGetPanelContent";
import { useMerchantSidePanelContext } from "./Provider/MerchantSidePanelProvider";
import SecondPanelOptions from "./SecondPanelOptions";

import GiveSidePanel from "@shared/SidePanel/GiveSidePanel";
import {
  SidePanelBodyWrapper,
  SidePanelContainerWrapper,
} from "@shared/SidePanel/SidePanelAtoms";
import { useGetGlobalTopics } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import Conversations from "./WithRepository/Challenges/Conversations";

const NewPanel = () => {
  useGetGlobalTopics();
  const {
    open,
    onClose,
    renderButton,
    status,
    doublePanel,
    isLoading,
    merchant,
    data: {
      status: { sponsorStatusName },
    },
  } = useMerchantSidePanelContext();
  const { isMobileView } = useCustomThemeV2();
  const { panelBody, skeletonVariant } = useGetPanelContent(status);

  const sidePanelBodyWrapperSx = {
    padding: "0 16px 0 16px",
    paddingBottom: "16px",
    paddingTop: isMobileView ? "20px" : "40px",
    gap: "32px",
  };

  return (
    <>
      <GiveSidePanel
        open={open}
        onClose={onClose}
        secondPanel={<SecondPanelOptions />}
        isSecondPanelOpen={!!doublePanel}
      >
        <GiveSidePanel.Button renderButton={renderButton} el="conversations" />
        {sponsorStatusName === "approved" && (
          <GiveSidePanel.Button renderButton={renderButton} el="changelog" />
        )}
        <SidePanelContainerWrapper>
          <MainHeader />

          <SidePanelBodyWrapper sx={sidePanelBodyWrapperSx}>
            {isLoading ? (
              <PanelLoadingSkeleton variant={skeletonVariant} />
            ) : (
              panelBody
            )}
          </SidePanelBodyWrapper>
        </SidePanelContainerWrapper>
      </GiveSidePanel>
      <Conversations
        merchant={merchant}
        isLoading={isLoading}
        // handleOpenManageUnderwriting={handlers?.handleOpenManageUnderwriting}
        isLoadingChangelog={isLoading}
        // shouldShowChangelog={isChangelogEnabled && merchant && showChangelog}
      />
    </>
  );
};

export default NewPanel;
