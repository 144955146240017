import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import Popup from "@common/Popup";
import LoginOTP from "@pages/Login/LoginOTP";
import {
  sendTfaVerificationCode,
  verifyTfaVerificationCode,
} from "@services/api/2FA/tfaVerifications";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";

const LoginOTPModal = ({
  isMobileView,
  email,
  onSuccessFn,
  closeOnVerify = false,
}: {
  isMobileView: boolean;
  email: string;
  onSuccessFn: () => void;
  closeOnVerify?: boolean;
}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const { open, onClose, TransitionProps } = useNiceModal();
  const tfaVerificationsMutation = useMutation(sendTfaVerificationCode);
  const tfaVerificationsTokenMutation = useMutation(verifyTfaVerificationCode);

  const resendCode = (data: "sms" | "email") => {
    tfaVerificationsMutation.mutate("email");
  };

  useEffect(() => {
    resendCode("email");
  }, []);

  const handleVerify = (data: {
    token: string;
    email: string;
    onSuccessFn: (data: {
      password: string;
      remember: boolean;
      email: string;
      termsConditions: boolean;
    }) => void;
  }) => {
    tfaVerificationsTokenMutation.mutate(
      { token: data.token },
      {
        onError: (res: any) => {
          setIsError(true);
        },
        onSuccess: async (res: any) => {
          onSuccessFn();
          closeOnVerify && onClose();
        },
      },
    );
  };
  return (
    <Popup
      open={open}
      hideActions
      sx={{
        ".MuiDialogContent-root": {
          padding: 0,
        },
        ".MuiPaper-root": {
          width: "auto",
        },
      }}
      TransitionProps={TransitionProps}
    >
      <LoginOTP
        email={email}
        isMobileView={isMobileView}
        handleVerify={handleVerify}
        isError={isError}
        cancel={() => onClose()}
        stripLoginContainer
        forceUntrust
      />
    </Popup>
  );
};

export default LoginOTPModal;
