import usePayBuilder from "@sections/PayBuilder/provider/usePayBuilder";
import React, { createContext, useContext } from "react";

type PayBuilderContextTypes = ReturnType<typeof usePayBuilder>;

const PayContext = createContext<PayBuilderContextTypes>({} as any);
function PayBuilderContext({ children }: { children: React.ReactNode }) {
  const values = usePayBuilder();
  return <PayContext.Provider value={values}>{children}</PayContext.Provider>;
}

export default PayBuilderContext;
export const usePayBuilderContext = () => useContext(PayContext);
