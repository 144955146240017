import React from "react";

const MasterCardIcon = ({
  width,
  height,
}: {
  width?: number | string;
  height?: number | string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 0.5H36C37.933 0.5 39.5 2.067 39.5 4V22C39.5 23.933 37.933 25.5 36 25.5H4C2.067 25.5 0.5 23.933 0.5 22V4C0.5 2.067 2.067 0.5 4 0.5Z"
        fill="white"
      />
      <path
        d="M4 0.5H36C37.933 0.5 39.5 2.067 39.5 4V22C39.5 23.933 37.933 25.5 36 25.5H4C2.067 25.5 0.5 23.933 0.5 22V4C0.5 2.067 2.067 0.5 4 0.5Z"
        stroke="#E6E6E3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0001 19.6215C18.4626 20.9176 16.4681 21.7 14.2887 21.7C9.42592 21.7 5.48389 17.8049 5.48389 13C5.48389 8.19511 9.42592 4.29999 14.2887 4.29999C16.4681 4.29999 18.4626 5.08242 20.0001 6.37851C21.5377 5.08242 23.5321 4.29999 25.7116 4.29999C30.5743 4.29999 34.5164 8.19511 34.5164 13C34.5164 17.8049 30.5743 21.7 25.7116 21.7C23.5321 21.7 21.5377 20.9176 20.0001 19.6215Z"
        fill="#ED0006"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9995 19.622C21.8929 18.0263 23.0935 15.6517 23.0935 13C23.0935 10.3483 21.8929 7.97368 19.9995 6.37793C21.5369 5.08219 23.5312 4.29999 25.7103 4.29999C30.573 4.29999 34.5151 8.19511 34.5151 13C34.5151 17.8049 30.573 21.7 25.7103 21.7C23.5312 21.7 21.5369 20.9178 19.9995 19.622Z"
        fill="#F9A000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0001 19.6214C21.8931 18.0257 23.0935 15.6513 23.0935 12.9999C23.0935 10.3485 21.8931 7.9741 20.0001 6.37836C18.1071 7.9741 16.9067 10.3485 16.9067 12.9999C16.9067 15.6513 18.1071 18.0257 20.0001 19.6214Z"
        fill="#FF5E00"
      />
    </svg>
  );
};

export default MasterCardIcon;
