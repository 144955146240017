import { PencilSimple } from "@phosphor-icons/react";
import { styled, useAppTheme } from "@theme/v2/Provider";
import { useEffect, useRef, useState } from "react";
import { typography } from "@theme/v2/typography";
import "./GiveEditableTagCSS.css";
import { Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";

export type GiveTagInputProps = {
  value: string;
  setValue:
    | React.Dispatch<React.SetStateAction<string>>
    | ((v: string) => void);
  isMobile?: boolean;
  type?: "editable" | "form-field";
  title?: string;
};

export const GiveTagInput = ({
  value,
  setValue,
  isMobile,
  type = "editable",
  title,
}: GiveTagInputProps) => {
  const { palette } = useAppTheme();
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const labelRef = useRef<HTMLLabelElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const parentElement = labelRef.current as HTMLElement;
    const inputElement = inputRef.current as HTMLElement;
    if (parentElement && inputElement) {
      parentElement.dataset.value = value;
    }
  }, [value]);

  const isEditable = type === "editable";
  return (
    <Label
      className="input-sizer"
      ref={labelRef}
      isFocused={isFocused}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isEditable={isEditable}
    >
      {isEditable && ((!isMobile && isHovered) || isMobile) && !isFocused && (
        <PencilSimple
          width={12}
          height={12}
          fill={palette.icon?.["icon-secondary"]}
          style={{
            marginRight: "10px",
            height: "16px",
            width: "16px",
          }}
        />
      )}
      {isEditable ? (
        <Input
          ref={inputRef}
          type="text"
          size={1}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false);
          }}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          isFocused={isFocused}
        />
      ) : (
        <Stack width="100%" direction="row" gap="8px">
          <GiveText variant="bodyS" color="secondary">
            {title}
          </GiveText>
          <GiveText variant="bodyS">{value}</GiveText>
        </Stack>
      )}
    </Label>
  );
};

const Input = styled("input")<{ isFocused: boolean }>(
  ({ theme, isFocused }) => ({
    color: isFocused
      ? theme.palette.text?.primary
      : theme.palette.text?.secondary,
  }),
);

const Label = styled("label")<{ isFocused: boolean; isEditable: boolean }>(
  ({ isFocused, isEditable, theme }) => ({
    backgroundColor: isFocused
      ? theme.palette.surface?.tertiary
      : theme.palette.surface?.secondary,

    ...typography.bodyXS,
    transition: "background-color 0.2s",

    ...(isEditable
      ? {
          height: "28px",
          borderRadius: "4px",
          paddingLeft: "12px",
          paddingRight: "12px",
          "&:hover": {
            backgroundColor: theme.palette.surface?.tertiary,
          },
        }
      : {
          borderRadius: "12px",
          padding: "15px 16px",
        }),
  }),
);
