import { Stack } from "@mui/material";
import GiveUnderwritingProgressBar from "@shared/ProgressBar/GiveUnderwritingProgressBar";
import GiveText from "@shared/Text/GiveText";
import { ModifiedGiveChip } from "./UnderwritingCard.style";
import { IChipProps } from "@shared/Chip/GiveChip";
import { useMemo } from "react";
import { useGetChallengesStats } from "@components/Merchants/MerchantPreview/manageUnderwriting/Challenges/hooks/useGetChallengesStats";

type TUnderwritingCardHeader = {
  underwritingScorePercentage: number | string;
  status: IChipProps;
  merchantId: number;
};

const UnderwritingCardHeader = ({
  underwritingScorePercentage,
  status,
  merchantId,
}: TUnderwritingCardHeader) => {
  const { label, color } = status || {};

  const { data: challengesStatsData } = useGetChallengesStats({
    merchantId,
  });

  const { completed, incomplete, disabled } = useMemo(
    () => ({
      completed: challengesStatsData?.closedChallengesCount || 0,
      incomplete: challengesStatsData?.readyForVerificationChallengesCount || 0,
      disabled: challengesStatsData?.openChallengesCount || 0,
    }),
    [challengesStatsData],
  );

  return (
    <Stack gap="12px">
      <Stack direction="row" justifyContent="space-between">
        <GiveText
          sx={{
            fontSize: "24px",
            fontWeight: 350,
            lineHeight: "28px",
          }}
        >
          {underwritingScorePercentage}%
        </GiveText>
        <ModifiedGiveChip label={label} color={color} variant="light" />
      </Stack>
      <GiveUnderwritingProgressBar
        completed={completed}
        incomplete={incomplete}
        disabled={disabled}
      />
    </Stack>
  );
};

export default UnderwritingCardHeader;
