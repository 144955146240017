import { Stack } from "@mui/material";
import { Plus } from "@phosphor-icons/react";
import { styled, useAppTheme } from "@theme/v2/Provider";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import Dropzone, { ILayoutProps } from "react-dropzone-uploader";
import { useUploadImage as useUploadImageOld } from "@hooks/merchant-api/image-gallery/useUploadImage";
import { useDropzoneUpload as useUploadImageNew } from "@hooks/upload-api/uploadHooks";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { ACCEPTED_IMAGE_FORMATS } from "@constants/constants";
import { useRef } from "react";

function ImageUploader() {
  const ref = useRef<HTMLInputElement>(null);
  const { palette } = useAppTheme();
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();

  // Access Control
  const isAddImageAllowed = useAccessControl({
    resource: RESOURCE_BASE.MEDIA_ITEM,
    operation: OPERATIONS.CREATE,
    withPortal: true,
  });

  // Upload Handlers based on Feature Flag
  const uploadOld = useUploadImageOld();
  const uploadNew = useUploadImageNew();

  const handleDragDropChange = isFileUploadRefactorEnabled
    ? uploadNew.handleDragDropChange
    : uploadOld.handleDragDropChange;

  const handleImageChange = isFileUploadRefactorEnabled
    ? uploadNew.handleImageChange
    : uploadOld.handleImageChange;

  const isUploading = isFileUploadRefactorEnabled
    ? uploadNew.isUploading
    : uploadOld.isUploading;

  const disabledAddImage = isUploading || !isAddImageAllowed;

  // Handlers
  const openFilePicker = () => ref.current?.click();

  return (
    <Dropzone
      onChangeStatus={handleDragDropChange}
      disabled={disabledAddImage}
      InputComponent={() => (
        <input
          multiple
          type="file"
          accept={ACCEPTED_IMAGE_FORMATS}
          ref={ref}
          disabled={!isAddImageAllowed}
          onChange={handleImageChange}
          hidden
        />
      )}
      LayoutComponent={({ input }: ILayoutProps) => (
        <>
          <ActionButton onClick={openFilePicker}>
            <Stack
              height="48px"
              width="48px"
              borderRadius="50%"
              p="12px"
              justifyContent="center"
              alignItems="center"
              bgcolor={palette.primitive?.transparent["darken-5"]}
            >
              <Plus size="24px" />
            </Stack>
          </ActionButton>
          {input}
        </>
      )}
    />
  );
}

export default ImageUploader;

// Styled Components
const ActionButton = styled(Stack)(({ theme }) => ({
  width: "114px",
  height: "114px",
  cursor: "pointer",
  backgroundColor: theme.palette.primitive?.transparent["darken-5"],
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
  [theme.breakpoints.down("sm")]: {
    width: "calc(50% - 16px)",
  },
}));
