import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { BankAccountType } from "@redux/slices/enterprise/merchants";
import { usePlaidService } from "@hooks/merchant-api/BankAccounts";
import { useMutation } from "react-query";
import { customInstance } from "@services/api";
import { AxiosError } from "axios";
import { showMessage } from "@common/Toast";
import { buildMerchantEndpoints } from "@services/api/utils.api";
import { StatusValue } from "react-dropzone-uploader";
import { useFileUploadContext } from "@components/UploadFile/FileUploadContext";
import { useGetBankFiles } from "../merchant-api/BankAccounts/useGetBankFiles";
import { useRef } from "react";
import { challengeSlugs } from "@constants/challengeSlugs";
import {
  useUploadFiles,
  useUploadPresignedDocument,
} from "@hooks/upload-api/uploadHooks";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { BANK_ACCOUNT_NUMBER_REGEX } from "@constants/constants";
import { getValidBankAccountNumber } from "@utils/bankAccountUtils";

export type TBankFormInputsNew = Omit<BankAccountType, "files"> & {
  files: {
    allFiles: {
      file: File;
      id: any;
    }[];
  };
};
export type TBankFormInputsOld = Omit<BankAccountType, "files"> & {
  files: {
    fileWithMeta: any | null;
    status: StatusValue;
    allFiles: any[];
  };
};
const useAddOnboardingBankAccounts = (
  merchantId: number,
  backLink: () => void,
  firstAccount: any,
) => {
  const { populateSnackbarFiles, isLoading } = useFileUploadContext();
  const {
    open: startPlaidService,
    data: plaidData,
    isCreatingAccount,
  } = usePlaidService();
  const isForceError = useRef<boolean>(false);

  const { data: bankFiles, isLoading: bankFilesLoading } = useGetBankFiles({
    enabled: !!firstAccount,
    bankAccountId: firstAccount?.id,
    merchantId: merchantId,
  });

  const createBankAccountMutation = useMutation((data: any) => {
    return customInstance({
      url: buildMerchantEndpoints("bank-accounts"),
      method: "POST",
      data,
    });
  });
  const updateBankAccountMutation = useMutation((data: any) => {
    return customInstance({
      url: buildMerchantEndpoints(`bank-accounts/${firstAccount?.id}`),
      method: "PATCH",
      data: data?.params,
    });
  });

  const initialAccountNumber = firstAccount?.numberLast4
    ? `•••• ${firstAccount.numberLast4}`
    : "";

  const initialValues = {
    name: firstAccount?.name || "",
    accountType: firstAccount?.type?.toLocaleLowerCase() || "checking",
    routingNumber: firstAccount?.routingNumber || "",
    accountNumber: initialAccountNumber,
    notes: firstAccount?.notes || "",
    statements: [],
    files: {
      fileWithMeta: null,
      status: "started" as const,
      allFiles: [],
    },
  };

  const schema = Yup.object({
    ...(firstAccount?.status != "approved" && {
      name: Yup.string().when([], {
        is: () => Boolean(plaidData),
        then: Yup.string().nullable(),
        otherwise: Yup.string().required("This field is required"),
      }),
      accountNumber: Yup.string().when([], {
        is: () => Boolean(firstAccount?.numberLast4),
        then: Yup.string(),
        otherwise: Yup.string()
          .required("This field is required")
          .matches(
            BANK_ACCOUNT_NUMBER_REGEX,
            "Please enter a valid account number",
          ),
      }),
      routingNumber: Yup.string().when([], {
        is: () => Boolean(plaidData),
        then: Yup.string().nullable(),
        otherwise: Yup.string()
          .required("This field is required")
          .matches(/^[0-9]{9}$/, "Please enter a valid routing number"),
      }),
    }),
    notes: Yup.string(),
  });

  const methods = useForm<TBankFormInputsNew | TBankFormInputsOld>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const { watch, setError } = methods;
  const values = watch();
  const { handleUpload } = useUploadFiles();
  const { handleUpload: uploadPresigned } = useUploadPresignedDocument();
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
  const hasFiles =
    (bankFiles?.total || 0 + values?.files?.allFiles.length || 0) > 0;
  const formDirty = firstAccount ? methods.formState.isDirty : true;

  const onSubmit: SubmitHandler<
    TBankFormInputsNew | TBankFormInputsOld
  > = async (data) => {
    if (firstAccount?.status !== "approved" && !hasFiles) return;
    if (plaidData) {
      if (isFileUploadRefactorEnabled) {
        await handleUpload({
          list: data.files?.allFiles,
          attachmentType: "bank_account",
          merchantId: merchantId,
          resourceID: plaidData.id,
          label: "bank account document",
          tag: "bank account document",
        });
      } else {
        await new Promise((resolve) => {
          resolve(
            populateSnackbarFiles({
              fileWithMeta:
                (data as TBankFormInputsOld).files?.fileWithMeta ||
                data.files?.allFiles[0],
              status: (data as TBankFormInputsOld).files?.status,
              allFiles: (data as TBankFormInputsOld).files?.allFiles,
              attachmentType: "bank_account",
              label: "bank account document",
              tag: "bank account document",
              merchantId: merchantId,
              resourceID: plaidData.id,
            }),
          );
        });
      }

      backLink();
      return;
    }

    let uploadRes: string[] | "upload_failed" = [];
    if (!firstAccount && data.files?.allFiles?.length > 0) {
      if (isFileUploadRefactorEnabled) {
        uploadRes = await uploadPresigned({
          list: data.files?.allFiles,
          attachmentType: "bank_account",
        });
      } else {
        uploadRes = (await populateSnackbarFiles({
          fileWithMeta:
            (data as TBankFormInputsOld).files?.fileWithMeta ||
            data.files?.allFiles[0],
          status: (data as TBankFormInputsOld).files?.status,
          allFiles: (data as TBankFormInputsOld).files?.allFiles,
          onSuccess: () => {
            data?.files?.allFiles.forEach((file) => file.remove());
          },
        })) as string[] | "upload_failed";
      }
    }
    if (firstAccount && data.files?.allFiles?.length > 0) {
      if (isFileUploadRefactorEnabled) {
        await handleUpload(
          {
            list: data.files?.allFiles,
            merchantId: merchantId,
            resourceID: +firstAccount?.id,
            attachmentType: "bank_account",
            label: "bank account document",
            tag: "bank account document",
          },
          challengeSlugs.BANK_ACCOUNT_2,
        );
      } else {
        uploadRes = (await populateSnackbarFiles(
          {
            fileWithMeta:
              (data as TBankFormInputsOld).files?.fileWithMeta ||
              data.files?.allFiles[0],
            status: (data as TBankFormInputsOld).files?.status,
            allFiles: (data as TBankFormInputsOld).files?.allFiles,
            attachmentType: "bank_account",
            label: "bank account document",
            tag: "bank account document",
            merchantId: merchantId,
            resourceID: +firstAccount?.id,
            onSuccess: () => {
              data.files?.allFiles.forEach((file) => file.remove());
            },
          },
          undefined,
          challengeSlugs.BANK_ACCOUNT_2,
        )) as string[] | "upload_failed";
      }
    }

    const customData = {
      routingNumber: data.routingNumber,
      ...(firstAccount?.status != "approved" && {
        name: data.name,
        type: data.accountType,
        ...getValidBankAccountNumber({
          isForceError,
          setError,
          accountNumber: data?.accountNumber,
          defaultAccountNumber: initialAccountNumber,
        }),
      }),
      notes: data.notes,
      status:
        firstAccount?.status === "approved" ? "approved" : "pending_review",
      ...(!firstAccount?.id && {
        isDefault: true,
      }),
      ...(Array.isArray(uploadRes) && {
        statements: uploadRes,
      }),
    };

    !isForceError.current &&
      (firstAccount
        ? updateBankAccountMutation
        : createBankAccountMutation
      ).mutate(
        { params: customData },
        {
          onError: (error: unknown) => {
            const axiosError = error as AxiosError;
            const errorMessage = axiosError.response?.statusText;
            showMessage("Error", errorMessage);
          },
          onSuccess: async (res) => {
            backLink();
          },
        },
      );
  };

  const plaidHandler = async () => {
    if (plaidData) {
      await customInstance({
        url: buildMerchantEndpoints(`bank-accounts/${plaidData.id}`),
        method: "DELETE",
      });

      backLink();
    } else {
      startPlaidService();
    }
  };

  const isValidForm = plaidData
    ? true
    : values.name && values.accountNumber && values.routingNumber;

  return {
    isCreatingAccount,
    methods,
    onSubmit,
    plaidHandler,
    plaidData,
    isDisabled:
      !isValidForm ||
      (firstAccount?.status !== "approved" && !hasFiles) ||
      isLoading ||
      !formDirty ||
      createBankAccountMutation.isLoading,
    bankFiles,
    bankFilesLoading,
  };
};

export default useAddOnboardingBankAccounts;
