import { getJsonValue, isSecondVersion } from "../utils";
import { ThemeMode, ThemeVersions } from "@theme/v2/types";

declare module "@mui/material/Checkbox" {
  interface CheckboxProps {
    version?: ThemeVersions;
  }
}

export const getCheckboxCustomStyle = (mode: ThemeMode) => {
  return {
    styleOverrides: {
      root: {
        variants: [
          {
            props: (props: any) => isSecondVersion(props?.version),
            style: getCustomBaseCheckboxRootStyles(mode),
          },
          ...getLargeCheckboxStyle(),
          ...getSmallCheckboxStyle(),
        ],
      },
    },
  };
};

export const getCustomBaseCheckboxRootStyles = (mode: ThemeMode) => ({
  borderRadius: "4px",
  padding: "0px",
  backgroundColor: getJsonValue(`tokens.${mode}.colour.surface.primary`),
  border: `1.5px solid ${getJsonValue(
    `tokens.${mode}.colour.border.tertiary`,
  )}`,
  transition: "all 0.3s ease",
  "&:hover": {
    border: `1.5px solid ${getJsonValue(
      `tokens.${mode}.colour.text.secondary`,
    )}`,
  },
  svg: {
    fill: "transparent",
  },
  "&.Mui-checked, &.MuiCheckbox-indeterminate": {
    backgroundColor: getJsonValue(`tokens.${mode}.colour.buttons.default`),
    border: "none",
    svg: {
      fill: getJsonValue(`tokens.${mode}.primitive.neutral.0`),
    },
    "&:hover": {
      backgroundColor: getJsonValue(
        `tokens.${mode}.colour.buttons.default-hover`,
      ),
      svg: {
        fill: getJsonValue(`tokens.${mode}.primitive.neutral.0`),
        opacity: 0.7,
      },
    },
    "&.Mui-disabled": {
      opacity: 0.3,
      backgroundColor: getJsonValue(`tokens.${mode}.colour.buttons.default`),
      border: "none",
      svg: {
        fill: getJsonValue(`tokens.${mode}.primitive.neutral.0`),
      },
    },
  },
  "&.Mui-disabled": {
    backgroundColor: getJsonValue(`tokens.${mode}.colour.surface.secondary`),
    border: `1.5px solid ${getJsonValue(
      `tokens.${mode}.colour.border.secondary`,
    )}`,
  },
});

export const getLargeCheckboxStyle = () => [
  {
    props: (props: any) =>
      isSecondVersion(props?.version) && props.size === "medium",
    style: {
      width: "20px",
      height: "20px",
      "& .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
      },
    },
  },
];

export const getSmallCheckboxStyle = () => [
  {
    props: (props: any) =>
      isSecondVersion(props?.version) && props.size === "small",
    style: {
      width: "16px",
      height: "16px",
      "& .MuiSvgIcon-root": {
        width: "16px",
        height: "16px",
      },
    },
  },
];
