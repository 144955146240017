import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import useColumnSelection from "../hooks/useColumnSelection";
import { useFormContext } from "react-hook-form";
import { Colum_types, TExportTable } from "../types";
import { Stack } from "@mui/material";
import { WhiteContainer } from "./styles";
import ColumnsInlcudedSelect from "./ColumnsIncludedSelect";
import { Text } from "@common/Text";
import { palette } from "@palette";

type Props = {
  column_type: Colum_types;
  showMonthlyReport: boolean;
  showOfacReport?: boolean;
  initialColumns: Record<string, string[]>;
  visibleColumns: string[];
  monthlyColumns: string[];
  ofacBusinessColumns: string[];
  ofacPersonsColumns: string[];
  initialTimezone: string;
  EXPORT_FORM_NAME?: string;
};
const ExportColumnsSection = ({
  column_type,
  showMonthlyReport = false,
  showOfacReport = false,
  initialColumns,
  visibleColumns,
  monthlyColumns,
  ofacBusinessColumns,
  ofacPersonsColumns,
  initialTimezone,
  EXPORT_FORM_NAME,
}: Props) => {
  const { getValues } = useFormContext<TExportTable>();
  const selectedColumnIncluded = getValues("columnsIncluded");
  const { columnsActionHandlers } = useColumnSelection({
    initialColumns,
    visibleColumns,
    monthlyColumns,
    ofacBusinessColumns,
    ofacPersonsColumns,
    initialTimezone,
    EXPORT_FORM_NAME,
  });

  const isOnlyMerchant = ["merchant"]?.includes(column_type);
  const isSettlement = column_type === "settlement";
  const columnsIncluded = [
    {
      label: "All columns",
      helperText: "Entire columns including the hidden ones will be exported",
      value: "all",
      onClick: columnsActionHandlers.all,
    },
    {
      label: "Only columns in the view",
      helperText: "Visible columns within the table will be exported",
      value: "visible",
      onClick: columnsActionHandlers.visible,
      hidden: isSettlement,
    },
    {
      label: "Processing Monthly Report",
      value: "processing_monthly",
      onClick: columnsActionHandlers.processing_monthly,
      hidden: !(isOnlyMerchant && showMonthlyReport),
    },
    // it's RPT004, we hide until the implementation
    // {
    //   label: "DDA/Maintenance Monthly Changes Report",
    //   value: "maintenance_monthly",
    //   onClick:columnsIncludedMapper.maintenance_monthly,
    //   hidden: true,
    // },
    {
      label: "OFAC Business Profile Report",
      value: "ofac_business",
      onClick: columnsActionHandlers.ofac_business,
      hidden: !(isOnlyMerchant && showOfacReport),
    },
    {
      label: "OFAC Persons Report",
      value: "ofac_persons",
      onClick: columnsActionHandlers.ofac_persons,
      hidden: !(isOnlyMerchant && showOfacReport),
    },
    {
      label: "Custom",
      helperText: "Selection of specific columns to be exported",
      value: "custom",
      onClick: columnsActionHandlers.custom,
    },
  ];

  return (
    <Stack spacing={1}>
      <FadeUpWrapper delay={150}>
        <Text lineHeight="25px" fontWeight="book" color={palette.neutral[80]}>
          Columns included in the export
        </Text>
        <WhiteContainer>
          <ColumnsInlcudedSelect
            columnsIncluded={columnsIncluded}
            value={selectedColumnIncluded}
          />
        </WhiteContainer>
      </FadeUpWrapper>
    </Stack>
  );
};

export default ExportColumnsSection;
