import { ListItemButton, ListItemButtonProps, styled } from "@mui/material";
import { Text } from "@common/Text";
import { ITextProps } from "@common/Text/Text";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";

export interface IMobileActionItem extends ListItemButtonProps {
  labelTextProps?: ITextProps;
  tooltipProps?: { show: boolean; message: string };
}

const MobileActionItem = styled(
  ({ children, labelTextProps, tooltipProps, ...rest }: IMobileActionItem) => {
    return (
      <WithTooltipWrapper
        hasTooltip={!!tooltipProps}
        tooltipProps={tooltipProps}
      >
        <ListItemButton divider {...rest}>
          <Text textAlign="center" width="100%" {...labelTextProps}>
            {children}
          </Text>
        </ListItemButton>
      </WithTooltipWrapper>
    );
  },
)(({ theme }) => ({
  margin: "auto",
  padding: "12px",
  "&.MuiListItemButton-divider": {
    borderColor: theme.palette.neutral["10"],
  },
}));

export default MobileActionItem;
