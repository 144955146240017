import { Stack } from "@mui/material";
import { styled } from "@theme/v2/Provider";
import { GivePillProps } from "./GiveTab.types";
import GiveText from "@shared/Text/GiveText";

export default function GivePill({
  label,
  value,
  endItem,
  startItem,
  onClick,
  selected,
  hidden,
  disabled,
  variant,
  tabSx,
}: GivePillProps) {
  if (hidden) return null;
  return (
    <Container
      direction="row"
      variant={variant}
      selected={selected}
      disabled={disabled}
      onClick={() => onClick?.(value)}
      sx={tabSx}
    >
      {startItem}
      <GiveText variant="bodyS">{label}</GiveText>
      {endItem}
    </Container>
  );
}

const Container = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "selected" && prop !== "variant",
})<Omit<GivePillProps, "label" | "value">>(
  ({ theme, selected, variant, disabled }) => ({
    gap: "8px",
    alignItems: "center",
    cursor: "pointer",
    ...(variant === "segmented"
      ? {
          padding: "8px 12px",
          borderRadius: "8px",
        }
      : { padding: "8px 16px", borderRadius: "40px" }),
    ...(variant === "gradient" &&
      selected && {
        background: theme.palette.gradient?.["ocean-blue"]?.highlight,
        "& p": {
          color: theme.palette.primitive?.blue[100],
        },
      }),
    ...(variant === "primary" &&
      selected && {
        background: theme.palette.primitive?.neutral[100],
        "& p": {
          color: theme.palette.primitive?.neutral[0],
        },
      }),
    ...(variant === "segmented" &&
      selected && {
        background: theme.palette.text.primary,
        "& p": {
          color: theme.palette.primitive?.neutral[0],
        },
      }),
    ...(!selected && {
      "& p": {
        color:
          variant === "segmented"
            ? theme.palette.text.primary
            : theme.palette.text.secondary,
      },
      "&:hover": {
        ...(variant === "segmented" && {
          background: theme.palette.surface?.tertiary,
        }),
        "& p": {
          color: theme.palette.text.primary,
        },
      },
    }),
    ...(disabled && {
      pointerEvents: "none",
      "& p": {
        color: theme.palette.text.tertiary,
      },
    }),
  }),
);
