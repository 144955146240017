import { Stack, SxProps } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { capitalizeFirstLetter } from "@utils/index";
import { ReactNode } from "react";

const TextWithIcon = ({
  Icon,
  text,
  textSx,
}: {
  Icon: ReactNode;
  text: string;
  textSx?: SxProps;
}) => {
    
  return (
    <Stack direction="row" gap={1} alignItems="center">
      {Icon}
      <GiveText
        sx={{
          fontSize: "14px",
          fontWeight: 400,
          ...textSx,
        }}
      >
        {capitalizeFirstLetter(text)}
      </GiveText>
    </Stack>
  );
};

export default TextWithIcon;
