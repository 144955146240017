const DotIcon = ({ isPublished }: { isPublished?: boolean }) => {
  if (!isPublished) {
    return (
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="4" cy="4" r="4" fill="black" fillOpacity="0.25" />
      </svg>
    );
  } else {
    return (
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="4" cy="4" r="4" fill="#088750" />
      </svg>
    );
  }
};

export default DotIcon;
