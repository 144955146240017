import { NameInput } from "@common/BusinessProfileInputs";
import BusinessTypeSelect from "@common/BusinessProfileInputs/BusinessTypeSelect";
import OwnershipTypeSelect from "@common/BusinessProfileInputs/OwnershipTypeSelect";
import { RHFInput, RHFTelInput } from "@common/Input";
import { Box, Grid, Stack } from "@mui/material";
import {
  ProfileSetupFormContainer,
  ProfileSetupFormActions,
  ProfileSetupFormTitle,
} from "../form.components";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { subDays } from "date-fns";
import CustomTaxSsnInput from "@common/BusinessProfileInputs/CustomTaxSsnInput";
import { BirthDatePicker } from "@common/DatePickers";
import moment from "moment";
import { phoneSchema, ssnSchema } from "@utils/validation.helpers";
import { gridItemsRenderer } from "@utils/rendering/nodesRenderers";
import usePercentageUpdate from "./hooks/usePercentageUpdate";
import { TBusinessStepsCommons } from "./BusinessProfileSetup";
import { DynamicReturnType } from "./helpers/refineData";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  CREATE_DENY_MESSAGE,
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";

interface IProps extends TBusinessStepsCommons {
  canEdit: boolean;
  data: DynamicReturnType["businessDetails"];
  legalEntityId?: any;
}

type TinType = "ssn" | "ein";

type IFormInputs = {
  legalName: string;
  DBA: string;
  tinType: TinType;
  taxIDNumber: string;
  ssn: string;
  businessType: string;
  ownershipType: string;
  phoneNumber: string;
};

const BusinessDetailsStep = ({
  handleBack,
  submitHandler,
  statusBar,
  updateStatusBar,
  canEdit,
  data,
  isSubmitting,
  legalEntityId,
}: IProps) => {
  const isAddLEAllowed = useAccessControl({
    resource: RESOURCE_BASE.LEGAL_ENTITY,
    operation: OPERATIONS.CREATE,
    withPortal: true,
  });

  const isUpdateLEAllowed = useAccessControl({
    resource: RESOURCE_BASE.LEGAL_ENTITY,
    operation: OPERATIONS.UPDATE,
    withPortal: true,
  });

  const hasNoPermissions =
    (legalEntityId && !isUpdateLEAllowed) ||
    (!legalEntityId && !isAddLEAllowed);
  const disableInput = !canEdit || hasNoPermissions;

  const getTooltipMessage = () => {
    if (legalEntityId && !isUpdateLEAllowed) {
      return EDIT_DENY_MESSAGE;
    } else if (!legalEntityId && !isAddLEAllowed) {
      return CREATE_DENY_MESSAGE;
    } else if (!canEdit) {
      return "Only controllers can edit the business profile";
    } else {
      return "";
    }
  };

  const methods = useForm<IFormInputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: data,
  });

  const {
    watch,
    formState: { dirtyFields, isDirty },
  } = methods;

  const values = watch();

  usePercentageUpdate<IFormInputs>(
    values,
    dirtyFields,
    schema,
    updateStatusBar,
  );

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    submitHandler("businessDetails", data, {
      makeApiCall: isDirty,
      dirtyFields: {
        ...dirtyFields,
        businessType: true,
        ownershipType: true,
      },
    });
  };

  const inputs = [
    {
      node: (
        <NameInput
          name="legalName"
          label="Business legal name"
          placeholder="Business legal name"
          isLegalName
          disabled={disableInput}
        />
      ),
    },
    {
      node: (
        <RHFInput
          name="DBA"
          label="Doing Business As (Optional)"
          placeholder="Doing business as..."
          fullWidth
          disabled={disableInput}
        />
      ),
    },
    {
      node: (
        <BirthDatePicker
          name="businessOpenedAt"
          label="Business Creation Date (mm/dd/yyyy)"
          maxDate={subDays(new Date(), 1)}
          minDate={null}
          openPickerOnFocus
          disabled={disableInput}
        />
      ),
    },
    {
      node: (
        <BusinessTypeSelect
          name="businessType"
          label="Business type"
          hasArrowDownIcon
          disabled={disableInput}
        />
      ),
    },
    {
      node: (
        <CustomTaxSsnInput
          taxIdName="taxIDNumber"
          ssnName="ssn"
          businessTypeName="businessType"
          tinType="tinType"
          disabled={disableInput}
        />
      ),
    },
    {
      node: (
        <OwnershipTypeSelect
          name="ownershipType"
          label="Business ownership type"
          hasArrowDownIcon
          disabled={disableInput}
        />
      ),
    },
    {
      node: (
        <RHFTelInput
          name="phoneNumber"
          label="Business Phone Number"
          fullWidth
          disabled={disableInput}
          flagStyles={{
            width: "20px",
            height: "15px",
            borderRadius: 0,
          }}
        />
      ),
    },
  ];

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        flexGrow={1}
        id="business-profile-form"
        display="flex"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <ProfileSetupFormContainer>
          <Stack direction="column" gap={4} height="min-content">
            <ProfileSetupFormTitle title="Fill in your business details" />
            <Grid container rowSpacing="12px">
              {gridItemsRenderer(inputs, {
                show: disableInput,
                message: getTooltipMessage(),
              })}
            </Grid>
          </Stack>

          <ProfileSetupFormActions
            secondaryAction={{
              onClick: handleBack,
            }}
            primaryAction={{
              disabled: statusBar < 100 || isSubmitting,
              form: "business-profile-form",
              children: "Next",
            }}
          />
        </ProfileSetupFormContainer>
      </Box>
    </FormProvider>
  );
};

const schema = Yup.object().shape({
  legalName: Yup.string().required("This field is required"),
  DBA: Yup.string(),
  businessOpenedAt: Yup.date()
    .transform((value) => {
      return value ? moment(value).toDate() : value;
    })
    .typeError("Enter valid date ")
    .required("This field is required")
    .max(new Date(), "Future date not allowed")
    .min(
      moment().subtract(500, "years").format("YYYY-MM-DD"),
      "Date is beyond acceptable range",
    ),
  tinType: Yup.mixed<TinType>().oneOf(["ssn", "ein"]),
  taxIDNumber: Yup.string().when("tinType", {
    is: "ein",
    then: ssnSchema("ein"),
  }),
  ssn: Yup.string().when("tinType", {
    is: "ssn",
    then: ssnSchema("ssn"),
  }),
  businessType: Yup.string().required("This field is required"),
  ownershipType: Yup.string().required("This field is required"),
  phoneNumber: phoneSchema().test(
    "phone-number",
    "This field is required",
    (value) => !!value && value !== "+1",
  ),
});

export default BusinessDetailsStep;
