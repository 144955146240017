import { ModalTitle } from "@common/Modal/ModalFactory/atoms";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import NiceModal from "@ebay/nice-modal-react";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { TInviteElement } from "../types";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { RHFInput } from "@common/Input";
import { COLUMN_NAMES } from "../constants";
import { NameInput } from "@common/BusinessProfileInputs";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { Stack, styled, Box } from "@mui/material";
import { HiddenComponent } from "@containers/HiddenComponent";
import { Button } from "@common/Button";
import { ButtonProps } from "@common/Button/Button";
import { palette } from "@palette";
import { Trash } from "@phosphor-icons/react";
import { useEffect } from "react";

type TInputs = {
  merchantName: string;
  pahEmail: string;
};

type Props = {
  errorType?: "email" | "merchantName";
  merchantName?: string;
  pahEmail?: string;
  checkIsUnique: (
    merchantName: string,
    originalMerchantName?: string,
  ) => boolean;
  handleDelete: VoidFunction;
  handleSubmit: (newValue: Partial<TInviteElement>) => void;
};
const merchantNameError = `Provide unique merchant name`;
const emailError = "Please provide a valid email";

const EditInviteBase = ({
  merchantName,
  pahEmail,
  checkIsUnique,
  handleDelete,
  handleSubmit,
  errorType,
}: Props) => {
  const { open, onClose, SlideProps, TransitionProps } = useNiceModal();
  const { isMobileView } = useCustomTheme();

  const schema = Yup.object().shape({
    merchantName: Yup.string()
      .required(merchantNameError)
      .min(4, "Merchant name must be at least 4 characters long")
      .test("is-unique", merchantNameError, function (value) {
        if (!value) return false;
        const isUnique = checkIsUnique(value, merchantName);
        return isUnique;
      }),
    pahEmail: Yup.string().required(emailError).email(emailError),
  });

  const defaultValues: TInputs = {
    merchantName: merchantName || "",
    pahEmail: pahEmail || "",
  };

  const methods = useForm<TInputs>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: "onChange",
  });

  const {
    trigger,
    formState: { isDirty, isValid, errors },
    setError,
  } = methods;

  useEffect(() => {
    trigger();
  }, []);

  const onSubmit: SubmitHandler<TInputs> = (data) => {
    handleSubmit({ merchantName: data.merchantName, pahEmail: data.pahEmail });
    onClose();
  };

  const onDelete = () => {
    handleDelete();
    onClose();
  };

  useEffect(() => {
    if (!errorType) return;

    const timeoutId = setTimeout(() => {
      const errorField = errorType === "email" ? "pahEmail" : "merchantName";
      const errorMessage =
        errorType === "email" ? emailError : merchantNameError;

      setError(errorField, { message: errorMessage });
    }, 1);

    return () => clearTimeout(timeoutId);
  }, [errorType]);

  const actions: (ButtonProps & { label: string })[] = [
    {
      background: "text",
      label: "Delete",
      hidden: isMobileView,
      onClick: onDelete,
      sx: {
        color: palette.filled.red,
      },
    },
    {
      background: "primary",
      form: "edit-invitation",
      type: "submit",
      disabled: !isDirty || !isValid,
      label: "Save",
    },
    {
      background: "text",
      label: "Cancel",
      hidden: !isMobileView,
      onClick: onClose,
    },
  ];

  return (
    <ModalFactory
      variant="dialog"
      modalProps={{
        open,
        onClose,
        SlideProps,
        DialogProps: {
          PaperProps: {
            sx: {
              padding: "24px 24px 16px",
            },
          },
          TransitionProps,
        },
      }}
    >
      <ModalTitle
        title="Edit Invitation"
        description="Edit email address and merchant's name"
        textStyles={{
          title: {
            fontSize: "18px",
            lineHeight: "21.6px",
            letterSpacing: "-0.01em",
          },
        }}
        closeButtonSize={22}
        onClose={onClose}
      />
      <FormProvider {...methods}>
        <FadeUpWrapper delay={50}>
          <Box
            component="form"
            id="edit-invitation"
            onSubmit={methods.handleSubmit(onSubmit)}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: "16px",
              paddingBlock: "24px",
              ...(isMobileView && {
                flexDirection: "column",
                alignItems: "stretch",
              }),
            }}
          >
            <RHFInput
              name="pahEmail"
              placeholder="example@gmail.com"
              label={COLUMN_NAMES[0]}
              fullWidth
            />
            <NameInput
              name="merchantName"
              placeholder="Merchant name"
              label={COLUMN_NAMES[1]}
              isMerchantName
            />
            {isMobileView && (
              <Button
                background="text"
                onClick={onDelete}
                sx={{
                  alignSelf: "flex-start",
                  color: palette.filled.red,
                  fontWeight: 400,
                  height: "fit-content",
                  lineHeight: "16.8px",
                  padding: "0 4px",
                }}
              >
                <Trash size={16} color={palette.filled.red} /> Delete
              </Button>
            )}
          </Box>
        </FadeUpWrapper>
      </FormProvider>
      <FadeUpWrapper delay={50}>
        <ActionsWrapper>
          {actions.map(
            ({
              background,
              label,
              hidden = false,
              onClick,
              form,
              type,
              disabled,
              sx,
            }) => (
              <HiddenComponent key={label} hidden={hidden}>
                <Button
                  background={background}
                  disabled={disabled}
                  onClick={onClick}
                  type={type}
                  form={form}
                  sx={{
                    fontWeight: 400,
                    height: "fit-content",
                    fontSize: "18px",
                    lineHeight: "21.6px",
                    padding: "12px 24px",
                    ...sx,
                  }}
                >
                  {label}
                </Button>
              </HiddenComponent>
            ),
          )}
        </ActionsWrapper>
      </FadeUpWrapper>
    </ModalFactory>
  );
};

const EditInviteModal = NiceModal.create(EditInviteBase);
export default EditInviteModal;

const ActionsWrapper = styled(Stack)(({ theme }) => ({
  paddingTop: "20px",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "16px",
  },
}));
