import { TickIcon } from "@assets/icons";
import { Text } from "@common/Text";
import { LinearProgress, Stack } from "@mui/material";
import { palette } from "@palette";
import { Warning } from "@phosphor-icons/react";
import { checkPortals } from "@utils/routing";

const ProgressIcon = ({
  value,
  isActive,
  svgProps,
  isIncomplete,
}: {
  value: number;
  isActive: boolean;
  svgProps?: { width?: number; height?: number };
  isIncomplete: boolean;
}) => {
  const { isMerchantPortal } = checkPortals();

  if (isMerchantPortal) {
    if (isIncomplete) {
      return <Warning color={palette.warning.text} size={14} />; // TODO: fix warning icon alignment
    } else {
      return (
        <TickIcon
          stroke={isActive ? palette.neutral.black : palette.neutral[70]}
          width={svgProps?.width}
          height={svgProps?.height}
        />
      );
    }
  } else if (value === 100) {
    return (
      <TickIcon
        stroke={isActive ? palette.neutral.black : palette.neutral[70]}
        width={svgProps?.width}
        height={svgProps?.height}
      />
    );
  }

  return null;
};

const KotoLinearProgress = ({
  LinearProgressProps,
  svgProps,
  isActive,
  hasDescription,
  isIncomplete,
  ...rest
}: any) => {
  return (
    <Stack flex={1} justifyContent="flex-start" width="100%">
      {rest.title && (
        <Text
          mb={1}
          textAlign="left"
          color={isActive ? palette.neutral.black : palette.neutral[70]}
        >
          {rest.title}
        </Text>
      )}
      <LinearProgress
        variant="determinate"
        {...rest}
        sx={{
          borderRadius: "90px",
          backgroundColor: palette.neutral[30],
          height: "2px",
          ".MuiLinearProgress-bar": {
            backgroundColor: "neutral.black",
            ...(!isActive && { opacity: ".2" }),
          },
          ...rest.sx,
        }}
        data-testid="stepper-linear-progress"
      />
      {hasDescription && (
        <Stack direction="row" gap={0.5} alignItems="baseline">
          <ProgressIcon
            value={rest.value}
            isActive={isActive}
            svgProps={svgProps}
            isIncomplete={isIncomplete}
          />
          <Text
            data-testid={`Tab-label-${rest.label}`}
            mt={1}
            textAlign="left"
            color={isActive ? palette.neutral.black : palette.neutral[70]}
            {...LinearProgressProps}
          >
            {rest.label}
          </Text>
        </Stack>
      )}
    </Stack>
  );
};

export default KotoLinearProgress;
