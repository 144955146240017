import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import PreviewSection from "../components/PreviewSection";

const TOOLTIP_MESSAGE = "Please create a Business Profile first";

interface Props {
  disabled: boolean;
  onClick?: () => void;
  opened: boolean;
  isAcquirer: boolean;
}

export default function MATCHPreviewSection({
  disabled,
  onClick,
  opened,
  isAcquirer,
}: Props) {
  const { openConversationHandler } = useConversationsModal();
  const onConvClick = () => {
    openConversationHandler({ id: 0, name: "MATCH Check", paths: [] });
  };
  const isViewReadMATCHAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.MATCH_REPORT,
    ),
    operation: OPERATIONS.READ,
  });
  return (
    <PreviewSection
      isEnabled={isViewReadMATCHAllowed}
      label="MATCH"
      tooltipMessage={TOOLTIP_MESSAGE}
      onClick={onClick}
      disabled={disabled}
      opened={opened}
      isAcquirer={isAcquirer}
      onConversationClick={onConvClick}
    />
  );
}
