import { createContext, ReactNode, useContext, useMemo } from "react";
import {
  IComponentRepository,
  RepositoryConfigs,
  RepositoryDataTypes,
} from "./types";
import { createRepositoryHooks } from "./useWithRepository";

interface RepositoryContextValue<K extends string> {
  getRepositoryHooks: <T>(
    tag: K,
  ) => ReturnType<typeof createRepositoryHooks<T>>;
}

const RepositoryContext = createContext<RepositoryContextValue<any>>(
  {} as RepositoryContextValue<any>,
);

interface Props<K extends string, T extends RepositoryDataTypes> {
  children: ReactNode;
  configs: RepositoryConfigs<K>;
  repositories: {
    [P in K]: IComponentRepository<T[P]>;
  };
}

export const WithRepositoryProvider = <
  K extends string,
  T extends RepositoryDataTypes,
>({
  children,
  configs,
  repositories,
}: Props<K, T>) => {
  const contextValue = useMemo(() => {
    const getRepositoryHooks = <D,>(tag: K) => {
      const config = configs[tag];
      const repository = repositories[tag];

      if (!config || !repository) {
        throw new Error(`No configuration or repository found for tag: ${tag}`);
      }

      return createRepositoryHooks<D>(config, repository);
    };

    return { getRepositoryHooks };
  }, [configs, repositories]);

  return (
    <RepositoryContext.Provider value={contextValue}>
      {children}
    </RepositoryContext.Provider>
  );
};

export const useRepository = <
  K extends string,
  T extends RepositoryDataTypes,
  Tag extends keyof T,
>(
  tag: Tag,
) => {
  const context = useContext(RepositoryContext);
  if (!context) {
    throw new Error(
      "useRepository must be used within the WithRepositoryProvider",
    );
  }
  return context.getRepositoryHooks<T[Tag]>(tag);
};
