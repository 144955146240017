import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useAppDispatch } from "@redux/hooks";
import { TableColumnType, TableData, TableOrder } from "@common/Table/helpers";
import {
  setTableSortingOrder,
  disableTableSortingOrder,
  setTableSortingOrderProperty,
  disableTableSortingOrderProperty,
} from "@redux/slices/app";
import { updateSorting } from "@redux/slices/fundraisers";

type Props = {
  small?: boolean;
  setSorting?: Dispatch<SetStateAction<string>>;
  initialSortingProperty?: string;
  defaultSort?: { key: string; isDesc?: boolean };
};

export const useTableSort = ({
  small,
  setSorting,
  initialSortingProperty = "id",
  defaultSort = { key: "createdAt", isDesc: true },
}: Props) => {
  const dispatch = useAppDispatch();

  const { key: defaultKey, isDesc: isDefaultDesc } = defaultSort || {};

  const [sortKey, setSortKey] = React.useState<null | keyof TableData>(null);

  const [order, setOrder] = React.useState<TableOrder>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof TableData>(
    initialSortingProperty,
  );

  const getNewOrder = (columnKey: keyof TableData) => {
    let newOrder: TableOrder = "asc";
    if (!sortKey || sortKey !== columnKey) {
      newOrder = "asc";

      if (defaultKey === columnKey && isDefaultDesc) {
        newOrder = "desc";
      }
    } else {
      newOrder = order === "asc" ? "desc" : "asc";
    }

    return newOrder;
  };

  useEffect(() => {
    setSortKey("createdAt");
    setOrder("desc");
    setOrderBy("createdAt");
  }, []);

  useEffect(() => {
    if (sortKey !== defaultKey) {
      setSortingData(defaultKey);
    }
  }, [defaultKey, isDefaultDesc]);

  const handleOpenMenu =
    (column: TableColumnType) => (event: React.MouseEvent<HTMLElement>) => {
      setSortingData(column.key);
    };

  const setSortingData = (key: string) => {
    setSortKey(key);
    setOrderBy(key);

    const newOrder = getNewOrder(key);
    setOrder(newOrder);

    const newSorting = (newOrder === "asc" ? key : `-${key}`) as TableOrder;
    if (setSorting) {
      setSorting(newSorting);
      return;
    }
    if (small) {
      dispatch(setTableSortingOrderProperty(key));
      dispatch(setTableSortingOrder(newSorting));
      return;
    }
    dispatch(updateSorting(newSorting));
  };

  React.useEffect(() => {
    return () => {
      dispatch(disableTableSortingOrder());
      dispatch(disableTableSortingOrderProperty());
      dispatch(updateSorting("-createdAt")); //set back to initial value
    };
  }, []);

  return { handleOpenMenu, orderBy, sortKey, order };
};
