import React, { useMemo } from "react";
import { TableColumnType } from "@common/Table";
import { Text } from "@common/Text";
import { TransactionTableTag } from "@common/Tag/TransactionTableTag";
import ShieldIcon from "@assets/icons/RebrandedIcons/ShieldIcon";
import { Divider, Stack, styled } from "@mui/material";
import { ChevronRightIcon } from "@assets/icons";
import { Box } from "@mui/material";
import { Tooltip } from "@common/Tooltip";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import { palette } from "@palette";
import { parseAmount } from "@utils/index";
import { Checks } from "@assets/transactionsRiskProfileIcons";
import moment from "moment";
import { getRiskLevel } from "../components/RiskProfileActivity/RiskProfileActivityHeader";
import { getCardBrandIcon } from "@sections/Checkout/Payment/inputs/CardNumberInput";
type HoverParserReturnType = ReturnType<typeof hoverParser>;
type StatusType = "rejected" | "valid" | "pending";

export default function useGetColumns() {
  const columns: TableColumnType[] = useMemo(() => {
    return [
      {
        key: "date,-accID",
        title: "Date",
        sortable: true,
        leftSort: true,
        columnWidth: 4 / 12,
        renderColumn: (data) => {
          const row = data?.row;

          return (
            <Tooltip
              maxWidth="355px"
              bgColor={palette.neutral.white}
              title={<HoverPopover {...hoverParser(row)} />}
            >
              <Box>
                <ColumnText>
                  {moment.unix(row?.date).format("DD MMM YYYY, HH:mm")}
                </ColumnText>
                <Stack>
                  <TransactionCustomStatus
                    isBlocked={row?.isBlocked || false}
                    isFalsePositive={row?.isFalsePositive || false}
                    type={row?.emailStatus || "pending"}
                  />
                </Stack>
              </Box>
            </Tooltip>
          );
        },
      },
      {
        key: "amount,-accID",
        title: "Amount",
        sortable: false,
        columnWidth: 3 / 12,
        renderColumn: (data) => {
          const row = data?.row;
          return <ColumnText>{parseAmount(row?.amount / 100)}</ColumnText>;
        },
      },
      {
        key: "statusSortOrder,-accID",
        title: "Status",
        sortable: false,
        columnWidth: 3 / 12,
        renderColumn: (data) => {
          const row = data?.row;
          return (
            <TransactionTableTag
              type={row?.txnDisplayStatus?.toLowerCase() || "pending"}
            />
          );
        },
      },
      {
        key: "riskLevel,-accID",
        title: "",
        sortable: false,

        renderColumn: (data) => {
          const row = data?.row;

          const { level, color, background } = getRiskLevel(row?.riskLevel | 0);

          return (
            <Stack gap="10px" alignItems="center" flexDirection="row">
              <Stack gap={0.1} flexDirection="row">
                <ShieldIcon
                  width={18}
                  height={18}
                  fill={background}
                  stroke={color}
                />
                <Text fontWeight="regular" fontSize="18px" color={color}>
                  {level}
                </Text>
              </Stack>
              <ChevronRightIcon
                fill={palette.gray[200]}
                stroke={palette.gray[200]}
              />
            </Stack>
          );
        },
      },
    ];
  }, []);
  return { columns };
}

const TransactionCustomStatus = ({
  type = "rejected",
  isBlocked,
  isFalsePositive,
}: {
  type: StatusType;
  isBlocked?: boolean;
  isFalsePositive?: boolean;
}) => {
  const { color, bg, label } = getColors(type);

  return (
    <Stack flexDirection="row" gap="8px" alignItems="center">
      <Box
        bgcolor={bg}
        alignItems="center"
        justifyContent="center"
        display="flex"
        padding="2px 12px"
        borderRadius="100px"
        width="fit-content"
      >
        <Text color={color}>{label}</Text>
      </Box>
      {(isBlocked || isFalsePositive) && (
        <Stack gap="8px" mt="4px" flexDirection="row" alignItems="center">
          {isFalsePositive ? (
            <Checks />
          ) : isBlocked ? (
            <GppMaybeIcon
              sx={{
                width: "20px",
                color: palette.filled.red,
                fill: palette.tag.error.bg,
                stroke: palette.filled.red,
              }}
            />
          ) : null}

          <Text
            fontSize="12px"
            whiteSpace="nowrap"
            color={
              isFalsePositive ? palette.filled.success : palette.filled.red
            }
          >
            {" "}
            {isFalsePositive ? "False Positive" : "Blocked"}{" "}
          </Text>
        </Stack>
      )}
    </Stack>
  );
};

const RightInflowComponent = ({
  inflow,
  outFlow,
}: {
  inflow?: number;
  outFlow?: number;
}) => {
  const isInFlow = Boolean(inflow);
  const isOutFlow = Boolean(outFlow);
  return (
    <Stack>
      <InflowText
        color={isInFlow ? palette.filled.success : palette.black[100]}
      >
        {isInFlow
          ? `+${parseAmount(Number(inflow) / 100)}`
          : `-${parseAmount(Number(outFlow) / 100)}`}
      </InflowText>
      {isInFlow &&
        isOutFlow && ( //in some cases we have in and out flow if yes we show the outflow value as well
          <InflowText color={palette.gray[300]}>
            {`-${parseAmount(Number(outFlow) / 100)}`}
          </InflowText>
        )}
    </Stack>
  );
};
const HoverPopover = (data: HoverParserReturnType) => {
  const cardHolderInformation =
    "\u2022\u2022\u2022\u2022" +
    data?.cardInfoNumberLast4 +
    " - " +
    data?.cardHolderName;
  const items = [
    {
      titleLeft: "Transaction Status",
      titleRight: (
        <RoutedTransactionTableTag
          type={
            (data?.transactionStatus?.toLocaleLowerCase() || "pending") as any
          }
        />
      ),
      hidden: !data?.transactionStatus,
    },
    {
      titleLeft: "In/ out (USD)",
      rightComponent: (
        <RightInflowComponent
          outFlow={data?.outflow || 0}
          inflow={data?.inflow || 0}
        />
      ),
      hidden: !data?.outflow && !data?.inflow,
    },
    {
      titleLeft: "Card Holder Name",
      leftBody: data?.cardHolderName,
      hidden: !data?.cardHolderName,
    },
    {
      titleLeft: "Card Information",
      leftBody: cardHolderInformation,
      BrandCard: data?.cardInfoBrand
        ? getCardBrandIcon(data?.cardInfoBrand?.toUpperCase())
        : null,

      hidden: !data?.cardInfoNumberLast4,
    },
    {
      titleLeft: "Email",
      leftBody: data?.email,
      hidden: !data?.email,
      rightBody: (
        <TransactionCustomStatus type={data?.emailStatus || "pending"} />
      ),
    },
  ];

  return (
    <ItemsContainer>
      {items?.map((item, idx, arr) => {
        if (item?.hidden) return null;
        return (
          <Box mt="4px" width="100%" key={item.titleLeft}>
            <Stack
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
              width="100%"
            >
              <Text
                fontWeight="book"
                fontSize="12px"
                color={palette.neutral[100]}
              >
                {item?.titleLeft}
              </Text>
              {item?.rightComponent}
              {item?.titleRight && item?.titleRight}
            </Stack>
            <Stack
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
              width="100%"
            >
              <Stack
                justifyContent="space-between"
                alignItems="center"
                flexDirection="row"
                flexShrink={0}
              >
                {item?.BrandCard}

                <WrappedText
                  fontWeight="book"
                  fontSize="12px"
                  color={palette.neutral[70]}
                >
                  {item?.leftBody}
                </WrappedText>
              </Stack>
              {item?.rightBody}
            </Stack>

            {idx !== arr?.filter((item) => !item.hidden)?.length - 1 && (
              <Divider
                sx={{
                  marginY: "8px",
                }}
              />
            )}
          </Box>
        );
      })}
    </ItemsContainer>
  );
};

const ItemsContainer = styled(Box)(() => ({
  width: "355px",
  padding: "0px 16px",
  justifyContent: "space-between",
  display: "flex",
  flexDirection: "column",
}));

const hoverParser = (data: any) => {
  return {
    transactionStatus: (data?.txnDisplayStatus as StatusType) || "",
    cardHolderName: data?.cardInfoCardHolderName || "",
    cardInfoNumberLast4: data?.cardInfoNumberLast4 || "",
    email: data?.email || "",
    emailStatus: ((data?.emailStatus || "pending") as StatusType) || "",
    cardInfoBrand: data?.cardInfoBrand || "",
    outflow: data?.outflow || "",
    inflow: data?.inflow || "",
  };
};

const getColors = (type: StatusType) => {
  switch (type) {
    case "rejected":
      return {
        bg: palette.tag.error.bg,
        color: palette.filled.red,
        label: "Rejected",
      };
    case "valid":
      return {
        color: palette.filled.success,
        bg: palette.filled[100],
        label: "Valid",
      };
    case "pending":
    default:
      return {
        color: palette.neutral[80],
        bg: palette.liftedWhite[100],
        label: "Pending",
      };
  }
};

const ColumnText = styled(Text)(() => ({
  color: palette.neutral[70],
  fontWeight: "regular",
  fontSize: "14px",
}));

const InflowText = styled(Text)(() => ({
  fontWeight: "book",
  fontSize: "12px",
  textAlign: "right",
}));

const RoutedTransactionTableTag = styled(TransactionTableTag)(() => ({
  borderRadius: "100px !important",
  width: "fit-content !important",
  padding: "2px 12px !important",
}));

const WrappedText = styled(Text)({
  wordWrap: "break-word",
  overflowWrap: "break-word",
  whiteSpace: "normal",
  wordBreak: "break-all",
  maxWidth: "300px",
  textAlign: "left",
});
