import { useState } from "react";
import { Badge } from "@mui/material";
import NotificationsCenter from "features/Notifications/NotificationsCenter/NotificationsCenter";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { Bell } from "@phosphor-icons/react";
import { styled } from "@theme/v2/Provider";

const NotificationButton = ({
  unreadNotifications,
}: {
  unreadNotifications: number;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const closeNotificationsCenter = () => setAnchorEl(null);

  const openNotificationsCenter = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (anchorEl) {
      closeNotificationsCenter();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <>
      <BellBadge badgeContent={unreadNotifications} max={9999}>
        <GiveIconButton
          variant="ghost"
          Icon={Bell}
          onClick={openNotificationsCenter}
        />
      </BellBadge>
      <NotificationsCenter
        anchorEl={anchorEl}
        onClose={closeNotificationsCenter}
      />
    </>
  );
};

export default NotificationButton;

const BellBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: theme.palette?.primitive?.blue[50],
    color: theme.palette?.primitive?.neutral[0],
  },
}));
