import React, { memo, useMemo, useState } from "react";
import {
  AssigneesType,
  useGetUnderwriters,
} from "@components/Merchants/MerchantPreview/hooks/useGetTeamMembers";
import ContextualMenu from "@shared/ContextualMenu/ContextualMenu";
import { Plus, X } from "@phosphor-icons/react";
import placeholder from "assets/images/profile-placeholder.png";
import GiveAvatar from "@shared/Avatar/GiveAvatar";

interface AssignmentSelectProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  handleChange: (value: any) => void;
  merchantId: number;
  underwriterID?: number;
  type?: AssigneesType;
}

const AssignmentSelect = ({
  anchorEl,
  handleClose,
  handleChange,
  merchantId,
  underwriterID,
  type,
}: AssignmentSelectProps) => {
  const membersData = useGetUnderwriters({
    merchantId,
    queryOptions: {
      enabled: !!merchantId && !!anchorEl,
    },
    type,
  });
  const membersList =
    (Array.isArray(membersData.data) && membersData.data) || [];

  const [searchValue, setSearchValue] = useState("");

  const { listOptions } = useMemo(() => {
    const fullList = membersList?.map((data) => ({
      label:
        data.user?.firstName && data.user?.lastName
          ? `${data.user.firstName} ${data.user.lastName}`
          : data.user.email,
      icon: data.user?.imageURL ? `${data.user?.imageURL}/thumb` : "",
      value: data.user?.accID,
    }));

    if (!searchValue) return { listOptions: fullList };

    const filtered = fullList.filter(
      (option) =>
        option.label.toLowerCase().includes(searchValue.toLowerCase()) ||
        option.value.toString().includes(searchValue),
    );
    return { listOptions: filtered };
  }, [membersList, searchValue]);
  const searchBarProps = {
    handleChange: (val: string) => setSearchValue(val),
    value: searchValue,
  };
  const clearSearch = () => setSearchValue("");

  return (
    <ContextualMenu
      anchorEl={anchorEl}
      color="primary"
      texture="blurred"
      options={listOptions.map((option) => {
        const isSelected = option?.value === underwriterID;

        return {
          text: option.label,
          Image: <GiveAvatar imageUrl={option?.icon || placeholder} />,
          IconRight: isSelected ? X : Plus,
          isHover: true,
          onClick: () => {
            const newUnderwriter = isSelected ? "unassigned" : option.value;

            handleChange?.(newUnderwriter);
            handleClose();
          },
          isSelected: isSelected,
          canDeselect: isSelected,
        };
      })}
      handleClose={handleClose}
      searchBarProps={searchBarProps}
      menuWidth={280}
      onCloseEmptyState={clearSearch}
      emptySection="empty-search"
      isLoading={membersData.isLoading}
    />
  );
};
export default memo(AssignmentSelect);
