import React from "react";
import { Box, Stack } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import CheckIcon from "@assets/icons/RebrandedIcons/CheckIcon";
import { Tooltip } from "@common/Tooltip";
import { TMerchantHeader } from "../data.types";
import { addSizeToImage } from "@components/UploadAvatar/UploadAvatar";
import { getStatusMessage } from "@components/Settings/Business/Status/utils";
import DeclinedIcon from "@assets/icons/RebrandedIcons/DeclinedIcon";
import { HourGlassIcon } from "@assets/rebrandIcons";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import ImagePlaceholder from "assets/images/Placeholder.png";
import { FormProvider, useForm } from "react-hook-form";
import { useUpdateMerchantInfo } from "../hooks/useUpdateMerchantInfo";
import { isEmpty } from "lodash";
import AvatarUpload from "@components/AvatarUpload/AvatarUpload";
import { useFileUploadContext } from "@components/UploadFile/FileUploadContext";
import { ACCEPTED_IMAGE_FORMATS } from "@constants/constants";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { useUploadSingleImage } from "@hooks/upload-api/uploadHooks";
import MerchantName from "@components/Merchants/MerchantPreview/MerchantName";

type TAvatar = {
  avatar: string | File;
};

type Props = {
  merchantId: number;
  isEnterprise?: boolean;
  dispalyStatusInfo?: boolean;
} & TMerchantHeader;

const MerchantHeader = ({
  name,
  hasMID,
  imageURL,
  accountStatus,
  businessProfileStatus,
  merchantId,
  isEnterprise,
  dispalyStatusInfo = true,
}: Props) => {
  const { isMobileView } = useCustomTheme();
  const { handleSubmit } = useUpdateMerchantInfo(merchantId);
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
  const { handleUpload } = useUploadSingleImage();
  const image = imageURL ? addSizeToImage(imageURL, "thumb") : undefined;
  const { populateSnackbarFiles } = useFileUploadContext();

  const hasUpdatePermissions = useAccessControl({
    resource: RESOURCE_BASE.MERCHANT,
    operation: OPERATIONS.UPDATE,
  });

  const accountTooltip = getStatusMessage({
    status: accountStatus.status,
    updatedAt: accountStatus.statusUpdatedAt,
    updatedByName: accountStatus.statusUpdatedByName,
    updatedByEmail: accountStatus.statusUpdatedByEmail,
  });

  const businessOwnerTooltip = getStatusMessage({
    status: businessProfileStatus.status || "pending",
    updatedAt: businessProfileStatus.statusUpdatedAt,
    updatedByName: businessProfileStatus.statusUpdatedByName,
    updatedByEmail: businessProfileStatus.statusUpdatedByEmail,
    readyForVerificationAt: businessProfileStatus?.readyForVerificationAt,
    isLegalEntityStatus: true,
  });

  const methods = useForm<TAvatar>({
    mode: "onChange",
    defaultValues: { avatar: "" },
  });

  const handleChangeStatus: any = async ({ file }: { file: File }) => {
    if (!file) return;

    if (isFileUploadRefactorEnabled) {
      const img = await handleUpload({ file, formType: "customer_avatar" });
      if (img) handleSubmit("avatar", { avatar: img });
      return;
    }

    const fileToUpload = {
      file,
      meta: {
        status: "done",
        type: file.type,
        id: Math.random().toString(),
        size: file.size,
        name: file.name,
      },
    } as any;
    const img = await populateSnackbarFiles(
      {
        fileWithMeta: fileToUpload,
        status: fileToUpload?.status,
        allFiles: [fileToUpload],
        isCustomerUpload: true,
      },
      ACCEPTED_IMAGE_FORMATS,
    );
    const url = !!img && typeof img?.[0] === "string" ? img[0] : null;
    url && handleSubmit("avatar", { avatar: url });
  };

  return (
    <Stack direction="column" gap={2}>
      <Stack
        direction={isMobileView ? "column" : "row"}
        gap={isMobileView ? 0 : 2}
      >
        <Box width="fit-content">
          <FormProvider {...methods}>
            <AvatarUpload
              subTitle={name}
              size={64}
              defaultImageURL={image}
              placeholder={ImagePlaceholder}
              onUpload={(file) => handleChangeStatus({ file })}
              onDelete={() => handleSubmit("avatar", { avatar: null })}
              canUpload={hasUpdatePermissions}
              canDelete={hasUpdatePermissions}
              disabled={!hasUpdatePermissions}
              tooltipMessage={EDIT_DENY_MESSAGE}
            />
          </FormProvider>
        </Box>
        <Stack direction="column" gap="2px" flexGrow={1}>
          <MerchantName name={name} />
          {dispalyStatusInfo && (
            <Stack
              direction="row"
              flexWrap="wrap"
              gap="12px"
              alignItems="center"
            >
              {!isEnterprise && (
                <StatusMarker
                  status={accountStatus.status}
                  displayStatus={`${accountStatus.statusDisplayName}`}
                  tooltipMessage={accountTooltip}
                />
              )}
              <StatusMarker
                status={businessProfileStatus.status}
                displayStatus="Business Profile"
                tooltipMessage={businessOwnerTooltip}
              />
              {!isEnterprise && (
                <Text
                  color={hasMID ? "#13A75A" : palette.gray[300]}
                  variant="body"
                  lineHeight="16.8px"
                >
                  {hasMID ? "Has MID" : "Doesn't have MID"}
                </Text>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

type StatusMarkerProps = {
  status: string;
  displayStatus: string;
  tooltipMessage?: string;
};

const StatusMarker = ({
  status,
  displayStatus,
  tooltipMessage,
}: StatusMarkerProps) => {
  if (!tooltipMessage)
    return <Status status={status} displayStatus={displayStatus} />;
  const isNoTitle = isEmpty(tooltipMessage);
  return (
    <Tooltip
      title={tooltipMessage}
      titleSx={{ textTransform: "none" }}
      disableHoverListener={isNoTitle}
      disableTouchListener={isNoTitle}
      disableFocusListener={isNoTitle}
      sx={{
        "& .MuiTooltip-tooltip": {
          maxWidth: "350px",
        },
      }}
    >
      <Status status={status} displayStatus={displayStatus} />
    </Tooltip>
  );
};

const Status = ({ status, displayStatus }: StatusMarkerProps) => {
  return (
    <Stack direction="row" gap="2px" alignItems="center">
      <Text
        color={palette.gray[300]}
        variant="body"
        lineHeight="16.8px"
        textTransform="capitalize"
      >
        {displayStatus}
      </Text>
      {getDisplayIcon(status)}
    </Stack>
  );
};

const getDisplayIcon = (status: string) => {
  if (status === "approved" || status?.includes("preapproved")) {
    return <StatusSuccessIcon />;
  } else if (status === "declined") {
    return <DeclinedIcon />;
  } else {
    return <HourGlassIcon height={18} width={18} />;
  }
};

const StatusSuccessIcon = () => (
  <Box
    component="span"
    sx={{
      backgroundColor: "#D6FCDA80",
      width: "18px",
      height: "18px",
      borderRadius: "50%",
    }}
  >
    <CheckIcon height={18} width={18} fill="#13A75A" />
  </Box>
);

export default React.memo(MerchantHeader);
