import {
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from "@mui/material";

export type SwitchProps = MuiSwitchProps;

const GiveSwitch = ({ ...props }: SwitchProps) => {
  return (
    <MuiSwitch
      disableRipple
      disableTouchRipple
      disableFocusRipple
      version="two"
      {...props}
    />
  );
}

export default GiveSwitch;