import SectionCardBase from "@shared/SidePanel/components/SectionCard/SectionCardBase";
import { Grid } from "@mui/material";
import {
  TFeeObject,
  TMerchantFees,
} from "@components/Merchants/MerchantPreview/data.types";
import NiceModal from "@ebay/nice-modal-react";
import { EDIT_MERCHANT_FEES_MODAL } from "modals/modal_names";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import GiveText from "@shared/Text/GiveText";
import { Stack } from "@mui/material";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { checkPortals } from "@utils/routing";

const FeesSection = ({ id, data }: { id: number; data: TMerchantFees }) => {
  const { openConversationHandler } = useConversationsModal();

  const { merchant_underwriting } = useEnterprisePermissions();
  const { isAcquirerPortal } = checkPortals();

  const editHandler = () =>
    NiceModal.show(EDIT_MERCHANT_FEES_MODAL, { id, data });

  const handleFeeConv = (e: React.MouseEvent) => {
    e.stopPropagation();
    openConversationHandler({ id, name: "Merchant Fees", paths: [] });
  };

  const isEditAllowed = useAccessControl({
    resource: RESOURCE_BASE.MERCHANT,
    operation: OPERATIONS.UPDATE,
  });
  const disabeledActions = {
    handleOpenConversation: {
      hidden: !merchant_underwriting && !isAcquirerPortal,
    },
    handleEdit: {
      disabled: !isEditAllowed,
      tooltipTitle: EDIT_DENY_MESSAGE,
      disableTooltip: isEditAllowed,
    },
  };
  const actions = {
    handleOpenConversation: handleFeeConv,
    handleEdit: editHandler,
  };

  const calculateFeePerTransaction = (fee: TFeeObject) => {
    if (!fee) return "";
    const val = (fee?.baselinePercent + fee?.adjustmentPercent) / 100;
    return `${val}% + $${fee?.baselineFixed / 100}`;
  };

  const items = [
    {
      label: "Credit Card Fee",
      fee: data.creditCardFee.value,
      feePerTransaction: calculateFeePerTransaction(data.creditCardFee),
    },
    {
      label: "Debit Card Fee",
      fee: data.debitCardFee.value,
      feePerTransaction: calculateFeePerTransaction(data.debitCardFee),
    },
    {
      label: "AMEX Credit Card Fee",
      fee: data.amexCreditCardFee.value,
      feePerTransaction: calculateFeePerTransaction(data.amexCreditCardFee),
    },
  ];
  return (
    <SectionCardBase
      leftTitle="Fees"
      actions={actions}
      disabeledActions={disabeledActions}
    >
      <Grid container spacing={2}>
        {items.map((item, index) => (
          <FeeItem
            key={index}
            feePerTransaction={item.feePerTransaction}
            fee={item.fee}
            label={item.label}
          />
        ))}
      </Grid>
    </SectionCardBase>
  );
};

type TFeeCardProps = {
  feePerTransaction: string;
  fee: number;
  label: string;
};

const FeeItem = ({ feePerTransaction, fee, label }: TFeeCardProps) => {
  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={6}>
        <GiveText variant="bodyS" color="secondary">
          {label}
        </GiveText>
      </Grid>
      <Grid item xs={6}>
        <Stack>
          <Stack direction="row" gap="4px">
            <GiveText variant="bodyS" color="secondary">
              {fee}
            </GiveText>
            <GiveText variant="bodyS" color="primary">
              BPS
            </GiveText>
          </Stack>
          <GiveText variant="bodyS" color="secondary">
            {feePerTransaction} / fee per transaction
          </GiveText>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default FeesSection;
