import CopyButton from "@common/CopyButton";
import { RHFInput } from "@common/Input";
import { useFormContext } from "react-hook-form";
import { SLUG_MAX_CHARACTER_LENGTH } from "@constants/constants";
import useSlugPrefixFormatter from "@components/Merchants/MerchantPreview/hooks/useSlugPrefixFormatter";

type MerchantSlugInputProps = {
  id?: string;
  name: string;
  label?: string;
  parentName?: string;
  disabled?: boolean;
  focusViewColor?: string;
  value?: string;
};

const MerchantSlugInput = ({
  name,
  label,
  parentName,
  disabled = false,
  ...rest
}: MerchantSlugInputProps) => {
  const { watch } = useFormContext();

  const prefix = `https://${
    parentName || watch("parent_slug")
  }.givepayments.com/`;

  const slugPrefix = useSlugPrefixFormatter(prefix);

  return (
    <RHFInput
      name={name}
      label={label}
      placeholder="Permalink"
      fullWidth
      maxLength={SLUG_MAX_CHARACTER_LENGTH}
      disabled={disabled}
      inputPrefix={slugPrefix}
      endIcon={<CopyButton hidden={!watch(name)} text={prefix + watch(name)} />}
      {...rest}
    />
  );
};

export default MerchantSlugInput;
