import { TDocument } from "@common/FilePreview/types";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import { FILE_PREVIEW_MODAL_V2 } from "modals/modal_names";
import NiceModal from "@ebay/nice-modal-react";
import { useCallback } from "react";
import { downloadDocument } from "./utils";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

type FileType = Pick<
  TMerchantDocument,
  "fileName" | "fileType" | "fileURL" | "id" | "tag"
>;
const useDocumentPreviewV2 = () => {
  const { isDesktopView } = useCustomThemeV2();

  const handleDownload = useCallback((file: TDocument | null) => {
    if (!file) return;
    downloadDocument({ fileName: file.name, fileURL: file.URL }, isDesktopView);
  }, []);

  const handlePreview = (fileData?: FileType) => {
    if (!fileData) return;
    const file = fileFormatter(fileData);
    NiceModal.show(FILE_PREVIEW_MODAL_V2, {
      file: file,
      handleDownload,
    });
  };

  return {
    handlePreview,
  };
};

const fileFormatter = (file: FileType): TDocument => {
  return {
    id: file.id,
    name: file.fileName,
    URL: file.fileURL,
    type: file.fileType,
    tag: file.tag,
  };
};

export default useDocumentPreviewV2;
