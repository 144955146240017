import { CheckMarkIcon } from "@assets/icons";
import { BellRingingIcon, DetectiveIcon } from "@assets/icons/RebrandedIcons";
import { Text } from "@common/Text";
import { Box, BoxProps, Stack, styled, SxProps } from "@mui/material";
import { palette } from "@palette";
import useUpdateRiskProfile from "../../hooks/useUpdateRiskProfile";
import { TRiskStatus } from "../../types";
import { Siren } from "@phosphor-icons/react";

type Props = {
  status: TRiskStatus;
  profileId: number;
  merchantId: number;
};

const RiskActivityStatus = ({ status, profileId, merchantId }: Props) => {
  return (
    <Container isbackground={status !== "routine_monitoring"}>
      <Text color={palette.neutral[80]}>Status</Text>
      {getStatus(status, profileId, merchantId)}
    </Container>
  );
};

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isbackground",
})<BoxProps & { isbackground: boolean }>(({ isbackground }) => ({
  display: "flex",
  alignItems: "center",
  gap: "16px",
  height: "50px",
  marginTop: "32px",
  ...(isbackground && {
    padding: "4px 16px",
    borderRadius: "100px",
    background: palette.neutral[5],
  }),
}));

type RiskStatusProps = {
  icon: React.ReactNode;
  label: string;
  color: string;
  background?: string;
} & BoxProps;

const RiskStatus = ({
  icon,
  label,
  color,
  background,
  ...props
}: RiskStatusProps) => {
  return (
    <StyledRiskStatus background={background} {...props}>
      {icon}
      <Text color={color}>{label}</Text>
    </StyledRiskStatus>
  );
};

const StyledRiskStatus = styled(Box, {
  shouldForwardProp: (prop) => prop !== "background",
})<BoxProps & { background?: string }>(({ background }) => ({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  padding: "4px 16px",
  height: "36px",
  borderRadius: "100px",
  background: background || "inherit",
  whiteSpace: "nowrap",
}));

const getStatus = (
  status: TRiskStatus,
  profileId: number,
  merchantId: number,
) => {
  const { handleChange, isAllowedUpdate } = useUpdateRiskProfile(
    profileId,
    merchantId,
  );

  if (status === "routine_monitoring")
    return (
      <RiskStatus
        color={palette.success.main}
        icon={<CheckMarkIcon fill={palette.success.main} />}
        label="Routine monitoring"
      />
    );

  if (status === "alerted")
    return (
      <Stack direction="row" spacing={1}>
        <RiskStatus
          color={palette.gray[100]}
          icon={<CheckMarkIcon fill={palette.gray[100]} />}
          label="Routine monitoring"
          sx={{ padding: "4px 8px" }}
        />
        <RiskStatus
          color={palette.warning.text}
          icon={<Siren color={palette.warning.text} size={20} weight="bold" />}
          label="Alerted"
          background={palette.tag.warning.bg}
        />
        <RiskStatus
          color={palette.gray[100]}
          icon={<DetectiveIcon color={palette.gray[100]} />}
          label="Active monitoring"
          sx={{
            padding: "4px 8px",
            ...(isAllowedUpdate && { cursor: "pointer" }),
          }}
          onClick={() => handleChange("active_monitoring")}
        />
      </Stack>
    );

  return (
    <Stack direction="row" spacing={1}>
      <RiskStatus
        color={palette.gray[100]}
        icon={<CheckMarkIcon fill={palette.gray[100]} />}
        label="Routine monitoring"
        sx={{ padding: "4px 8px" }}
      />
      <RiskStatus
        color={palette.gray[100]}
        icon={<Siren color={palette.gray[100]} size={20} weight="bold" />}
        label="Alerted"
        sx={{
          padding: "4px 8px",
          ...(isAllowedUpdate && { cursor: "pointer" }),
        }}
        onClick={() => handleChange("alerted")}
      />
      <RiskStatus
        color={palette.error.main}
        icon={<DetectiveIcon color={palette.error.main} />}
        label="Active monitoring"
        background={palette.tag.error.bg}
      />
    </Stack>
  );
};

export default RiskActivityStatus;
