import { WithMissingPermissionModule } from "@common/WithMissingPermissionModule";
import { X } from "@phosphor-icons/react";
import GiveText from "@shared/Text/GiveText";
import {
  SidePanelBodyWrapper,
  SidePanelContainerWrapper,
} from "@shared/SidePanel/SidePanelAtoms";
import { SidePanelHeaderBase } from "@shared/SidePanel/components/SidePanelHeader/SidePanelHeader";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import GiveMerchantFileBody from "./components/GiveMerchantFileBody";
import { useMerchantSidePanelContext } from "../Provider/MerchantSidePanelProvider";
import PanelLoadingSkeleton from "../components/PanelLoadingSkeleton";

const GiveMerchantFile = ({
  handleClosePanel,
}: {
  handleClosePanel?: () => void;
}) => {
  const { isLoading } = useMerchantSidePanelContext();
  return (
    <WithMissingPermissionModule
      message="Merchant file hidden"
      notAuthorized={false}
    >
      <SidePanelContainerWrapper>
        <SidePanelHeaderBase
          leftItems={<GiveText>Merchant</GiveText>}
          rightItems={
            <GiveIconButton
              Icon={X}
              variant="ghost"
              onClick={handleClosePanel}
            />
          }
        />
        <SidePanelBodyWrapper>
          {isLoading ? (
            <PanelLoadingSkeleton variant="merchant_file" />
          ) : (
            <GiveMerchantFileBody />
          )}
        </SidePanelBodyWrapper>
      </SidePanelContainerWrapper>
    </WithMissingPermissionModule>
  );
};

export default GiveMerchantFile;
