import * as React from "react";
import { useFormContext, Controller } from "react-hook-form";
import GiveText from "@shared/Text/GiveText";
import { CheckboxProps } from "@shared/GiveCheckbox/type";
import GiveCheckbox from "@shared/GiveCheckbox/GiveCheckbox";
import { Stack } from "@mui/material";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { Info } from "@phosphor-icons/react";
import { useAppTheme } from "@theme/v2/Provider";

export type EDDModalCheckboxProps = CheckboxProps & {
  name: string;
  label: string;
  disabled?: boolean;
  tooltipText: string;
};

export const EDDModalCheckbox: React.FC<EDDModalCheckboxProps> = ({
  name,
  label,
  disabled,
  tooltipText,
  ...props
}) => {
  const { control } = useFormContext();
  const { palette } = useAppTheme();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, value, onChange, ...rest } }) => {
        return (
          <Stack direction="row" alignItems="center" gap="12px">
            <GiveCheckbox
              size="medium"
              disabled={disabled}
              inputRef={ref}
              value={value}
              onChange={onChange}
              {...rest}
              {...props}
            />
            <GiveText variant="bodyS">{label}</GiveText>

            <GiveTooltip
              color="default"
              width="compact"
              title={
                <GiveText color="default" variant="bodyS">
                  {tooltipText}
                </GiveText>
              }
              alignment=""
              customTooltipStyles={{
                "&.MuiStack-root": {
                  width: "auto",
                },
              }}
            >
              <Info size={18} color={palette.icon?.["icon-secondary"]} />
            </GiveTooltip>
          </Stack>
        );
      }}
    />
  );
};
