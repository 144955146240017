import { CaretRight } from "@assets/icons";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import { HiddenComponent } from "@containers/HiddenComponent";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import React, { useEffect, useMemo, useRef } from "react";
import { TFileNavigation, ZoomHandler } from "../types";
import ArrowButton from "./ArrowButton";
import {
  Backdrop,
  Container,
  ContentWrapper,
  FloatingToolbar,
  Header,
  NavDot,
} from "./FilePreviewLayout.styles";
import { CustomToolbar, SingleActionButton } from "./PreviewToolbar";
import { PreviewSlotsProps, PreviewToolbar } from "./types";
import { Slide } from "@mui/material";
import { useGesture } from "@use-gesture/react";

interface IFilePreviewLayout {
  toolbar: PreviewToolbar;
  previewer: React.ReactNode;
  header: React.ReactNode;
  onBackdropClick?: () => void;
  slots?: PreviewSlotsProps;
  navigation: TFileNavigation;
  showControls: boolean;
  setShowControls?: React.Dispatch<React.SetStateAction<boolean>>;
  count: number;
  index: number;
  onZoom: ZoomHandler;
  toggleControlsPersistence?: () => void;
}

const FilePreviewLayout = ({
  toolbar,
  previewer,
  header,
  onBackdropClick,
  slots,
  navigation,
  showControls,
  count,
  index,
  onZoom,
  toggleControlsPersistence,
}: IFilePreviewLayout) => {
  const { showNext, showPrev, changeFile } = navigation;
  const { isDesktopView } = useCustomTheme();
  const domTargetRef = useRef<HTMLDivElement>(null);

  const onClickAway = () => {
    onBackdropClick?.();
  };

  const goForward = () => changeFile("next");
  const goBack = () => changeFile("prev");

  const dots = useMemo(() => Array.from(Array(count).keys()), [count]);

  const showNavigation = showControls && isDesktopView;
  const isMultipleDocs = count > 1;

  useEffect(() => {
    document.addEventListener("gesturestart", (e) => e.preventDefault());
    document.addEventListener("gesturechange", (e) => e.preventDefault());

    return () => {
      document.removeEventListener("gesturestart", (e) => e.preventDefault());
      document.removeEventListener("gesturechange", (e) => e.preventDefault());
    };
  }, []);

  useGesture(
    {
      onDrag: ({ canceled, pinching, last, cancel, _direction: [dx] }) => {
        if (pinching || canceled) return cancel();
        if (last && dx !== 0) dx > 0 ? goBack() : goForward();
      },
      onPinch: ({ offset: [scale] }) => {
        onZoom(scale);
      },
    },
    {
      target: domTargetRef,
      eventOptions: { passive: false },
      drag: {
        axis: "x",
        swipe: { duration: 250 },
      },
      pinch: {
        pinchOnWheel: false,
      },
    },
  );

  return (
    <ErrorCatcher errorID="file-preview-layout">
      <Container data-testid="preview-action-container" {...slots?.Container}>
        <Slide
          direction="down"
          in={showControls}
          data-testid="file-preview-header"
        >
          <Header>{header}</Header>
        </Slide>

        {showNavigation && isMultipleDocs && (
          <ArrowButton
            disabled={!showPrev}
            onClick={goBack}
            onMouseEnterAndLeave={toggleControlsPersistence}
            data-testid="arrow-button-left"
            sx={{
              left: "100px",
            }}
          >
            <CaretRight rotate={180} />
          </ArrowButton>
        )}
        {showNavigation && isMultipleDocs && (
          <ArrowButton
            disabled={!showNext}
            onClick={goForward}
            onMouseEnterAndLeave={toggleControlsPersistence}
            data-testid="arrow-button-right"
            sx={{
              right: "100px",
            }}
          >
            <CaretRight />
          </ArrowButton>
        )}

        <ErrorCatcher errorID="layout-content">
          <Backdrop data-testid="back-drop" onClick={onClickAway}>
            <ContentWrapper
              ref={domTargetRef}
              {...slots?.ContentWrapper}
              data-testid="previewer-content"
            >
              {previewer}
            </ContentWrapper>
          </Backdrop>
        </ErrorCatcher>

        {showControls && isMultipleDocs && (
          <FloatingToolbar sx={{ bottom: 140, gap: "6px" }}>
            {dots.map((dot) => (
              <NavDot
                key={dot}
                selected={dot === index}
                data-testid="nav-dot"
              />
            ))}
          </FloatingToolbar>
        )}

        {showControls && (
          <FloatingToolbar onClick={(event) => event.stopPropagation()}>
            <HiddenComponent hidden={toolbar.primary.hidden || false}>
              <CustomToolbar>{toolbar.primary.content}</CustomToolbar>
            </HiddenComponent>

            {toolbar.secondary?.map((action) => (
              <HiddenComponent
                key={action.label}
                hidden={
                  action.hidden ||
                  false ||
                  (action.label === "delete" && !isDesktopView)
                }
              >
                <SingleActionButton
                  onClick={action.disabled ? undefined : action.onClick}
                  tooltipProps={action.tooltipProps}
                  data-testid={`${action.label}-action`}
                >
                  {action.content}
                </SingleActionButton>
              </HiddenComponent>
            ))}
          </FloatingToolbar>
        )}
      </Container>
    </ErrorCatcher>
  );
};

export default FilePreviewLayout;
