import BaseCardComponent from "../BaseCardComponent";
import useSnapShot from "features/Merchants/MerchantSidePanel/hooks/useSnapShot";

function PrimaryAccountHolder() {
  const { primaryAccountHolder } = useSnapShot();
  return (
    <BaseCardComponent
      leftTitle="Primary Account Holder"
      payload={primaryAccountHolder}
    />
  );
}

export default PrimaryAccountHolder;
