import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import NiceModal from "@ebay/nice-modal-react";
import { Box, Stack } from "@mui/material";
import { useAppDispatch } from "@redux/hooks";
import {
  setConversationTopic,
  setModalOpenConversation,
} from "@redux/slices/conversations";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import {
  getGlobalTopic,
  useConversationsModal,
} from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import {
  CHALLENGES_GENERAL_MODAL,
  NOTIFY_MERCHANT_V2,
} from "modals/modal_names";
import React, { useState } from "react";
import { IParsedData } from "../../data.types";
import { TChallenge, useListChallenges } from "../../hooks/useListChallenges";
import { useSectionFocusView } from "../../hooks/useSectionFocusView";
import { ChallengeProvider, TActionDelayer } from "./ChallengeContext";
import ChallengeDropdown from "./ChallengeDropdown";
import ChallengeLayout, { ExtendedTChallengeStatus } from "./ChallengeLayout";
import ChallengeLayoutSkeleton from "./ChallengeSkeleton";
import { HeaderNotifyButton } from "./atoms";
import { useChallengeScrollIntoView } from "./hooks/useChallengeScrollIntoView";
import { useGetChallengesStats } from "./hooks/useGetChallengesStats";

interface ChallengesListProps {
  id: number;
  merchantData: IParsedData;
  isAcquirerPortal: boolean;
  isEnterprise: boolean;
}

const ChallengesList: React.FC<ChallengesListProps> = ({
  id,
  merchantData,
  isAcquirerPortal,
  isEnterprise,
}) => {
  const [challengeFilter, setChallengeFilter] =
    useState<ExtendedTChallengeStatus>("all");
  const { data, isLoading } = useListChallenges({
    merchantId: id,
    challengeFilter,
    isEnterprise,
  });
  useChallengeScrollIntoView({data, isLoading});
  const { data: challengesStatsData } = useGetChallengesStats({
    merchantId: id,
  });
  const { isEnabledConversationActivity } = useGetFeatureFlagValues();

  const { focusOnSection } = useSectionFocusView(id, merchantData);
  const { openConversationHandler, updateThreads } = useConversationsModal();
  const { merchant_underwriting } = useEnterprisePermissions();
  const dispatch = useAppDispatch();
  const disableGenericNotify = !merchantData.primaryAccountHolder.email;

  const openNotifyMerchantModal = async () => {
    if (disableGenericNotify) return;
    if (isEnabledConversationActivity && isAcquirerPortal) {
      const { data: topic } = await getGlobalTopic({
        topicName: "underwriting",
      });

      const underwritingTopicID = topic?.find(
        (item: any) => item.Name === "underwriting",
      )?.ID;

      if (!underwritingTopicID) return;

      dispatch(setModalOpenConversation(true));
      dispatch(
        setConversationTopic({
          isOpen: true,
          isOpenedFromSidePanel: false,
          numberOfUnreadMessages: 0,
          queryObject: {
            id: underwritingTopicID,
            name: `Notify ${isEnterprise ? "Providers" : "Merchant"} `,
            tabs: "Activity",
            paths: [
              {
                isConversation: true,
                pathName: `Notify ${isEnterprise ? "Provider" : "Merchant"} `,
                pathID: "new",
                avatars: [],
                hideInputs: ["module"],
                topicName: "general",
              },
            ],
          },
        }),
      );

      return;
    }

    NiceModal.show(NOTIFY_MERCHANT_V2, {
      merchantName: merchantData.merchantInfo.merchantName,
      merchantId: id,
      isEnterprise,
    });
  };

  const totalChallenges = challengesStatsData
    ? challengesStatsData?.closedChallengesCount +
      challengesStatsData?.openChallengesCount +
      challengesStatsData?.readyForVerificationChallengesCount
    : 0;

  const actionsDelayer = ({
    challengeId,
    title,
    primaryActionLabel,
    body,
    challengeTypeName,
  }: TActionDelayer) => {
    return NiceModal.show(CHALLENGES_GENERAL_MODAL, {
      title,
      primaryActionLabel,
      challengeId: challengeId,
      body,
      merchantData,
      challengeTypeName,
    });
  };

  return (
    <Box paddingTop="16px">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <ChallengeDropdown
          totalChallenges={totalChallenges}
          totalCompleted={challengesStatsData?.closedChallengesCount || 0}
          totalOpen={challengesStatsData?.openChallengesCount || 0}
          totalReadyForConfirmation={
            challengesStatsData?.readyForVerificationChallengesCount || 0
          }
          setChallengeFilter={setChallengeFilter}
          challengeFilter={challengeFilter}
        />
        {merchant_underwriting && (
          <HeaderNotifyButton
            onClick={openNotifyMerchantModal}
            disabled={disableGenericNotify}
            isEnterprise={isEnterprise}
            tooltipProps={{
              show: disableGenericNotify,
              message: `The ${
                isEnterprise ? "provider" : "merchant"
              } does not have a Primary Account Holder`,
            }}
          />
        )}
      </Stack>

      {isLoading ? (
        <ChallengeLayoutSkeleton />
      ) : (
        <ChallengeProvider
          actions={{
            openConversationHandler,
            focusOnSection,
            updateThreads,
            merchantData,
            isAcquirerPortal,
            isEnterprise,
            actionsDelayer,
          }}
        >
          {data?.data?.map((challenge: TChallenge) => {
            if (challenge.statusName === "empty") return null;
            
            return (
              <ChallengeLayout
                key={challenge.id}
                message={isEnterprise ? challenge.enterpriseDisplayName : challenge.merchantDisplayName}
                challenge={challenge}
                needsVerification={challenge.needsVerification}
                hasBusinessProfile={
                  typeof merchantData.businessProfile.id === "number" &&
                  merchantData.businessProfile.id !== 0
                }
                merchantId={id}
                challengeFilter={challengeFilter}
              />
            );
          })}
        </ChallengeProvider>
      )}
    </Box>
  );
};

export default ChallengesList;
