import { BaseModal } from "@common/Modal";
import { Text } from "@common/Text";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  SxProps,
  styled,
} from "@mui/material";
import { palette } from "@palette";
import { StyledIconButton } from "@common/IconButton";
import { CloseIcon } from "@assets/rebrandIcons";
import React from "react";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import SwipeableDrawerMobile, {
  SwipeableDrawerMobileProps,
} from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";
import { BaseModalProps } from "@common/Modal/BaseModal";

interface ModalProps {
  title: string;
  handleCancel: () => void;
  open: boolean;
  children: React.ReactNode;
  actions?: React.ReactNode;
  actionsSx?: SxProps;
  hasBackArrow?: boolean;
  icon?: JSX.Element;
  description?: string;
  maxWidth?: number | string;
  mobileDrawerSx?: SxProps;
  DrawerProps?: Omit<
    SwipeableDrawerMobileProps,
    "open" | "onOpen" | "onClose" | "children"
  >;
  onAnimationEnd?: () => void;
}

const EditMerchantBaseModal = ({
  title,
  handleCancel,
  open,
  children,
  actions,
  actionsSx,
  hasBackArrow = false,
  maxWidth,
  icon,
  description,
  sx,
  mobileDrawerSx,
  DrawerProps,
  testId,
  onAnimationEnd,
  ...props
}: ModalProps & Omit<BaseModalProps, "maxWidth">) => {
  const { isDesktopView } = useCustomTheme();
  const content = (
    <>
      <ModalTitleContainer>
        <Stack spacing={1.5} width="100%">
          <Stack direction="row" spacing={1}>
            {icon && icon}
            <Text
              variant="h3"
              color={palette.black[100]}
              fontSize="20px"
              lineHeight="27px"
              fontWeight="book"
              flexGrow={1}
            >
              {title}
            </Text>
            {isDesktopView && (
              <CloseButton
                onClick={handleCancel}
                data-testid="edit-merchant-base-modal-close-btn"
              >
                <CloseIcon width={24} height={24} />
              </CloseButton>
            )}
          </Stack>
          {description && (
            <Text fontWeight="book" color={palette.neutral[70]}>
              {description}
            </Text>
          )}
        </Stack>
      </ModalTitleContainer>
      <ContentContainer data-testid="edit-merchant-base-modal-content">
        {children}
      </ContentContainer>
      {actions && (
        <ModalActionsContainer sx={actionsSx}>{actions}</ModalActionsContainer>
      )}
    </>
  );
  const animationEnd = () => {
    onAnimationEnd && onAnimationEnd();
  };
  if (isDesktopView)
    return (
      <StyledModal
        open={open}
        onClose={handleCancel}
        PaperProps={{
          style: {
            background: "#FBFBFB",
            borderRadius: "12px",
            width: "90%",
            maxWidth: maxWidth || "700px",
          },
          sx: {
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "&::-webkit-scrollbar-track": {
              display: "none",
            },
          },
        }}
        sx={{
          "& .MuiDialogContent-root": {
            overflowX: "hidden",
          },
          ...(sx && sx),
        }}
        {...props}
        onAnimationEnd={animationEnd}
        data-testid={testId}
      >
        {content}
      </StyledModal>
    );
  return (
    <SwipeableDrawerMobile
      anchor="bottom"
      onOpen={() => undefined}
      open={open}
      onClose={handleCancel}
      {...DrawerProps}
      sx={{
        "& .MuiPaper-root": {
          background: palette.neutral[5],
          top: "96px",
        },
        ...(mobileDrawerSx && mobileDrawerSx),
      }}
      data-testid={testId}
    >
      {content}
    </SwipeableDrawerMobile>
  );
};

const StyledModal = styled(BaseModal)(() => ({
  ".MuiDialogActions-root": {
    backgroundColor: "#FBFBFB",
  },

  "@media (max-width: 600px)": {
    "& .MuiDialog-paper": {
      top: "50px !important",
      height: "90%",
      borderRadius: "8px",
      maxHeight: "90%",
      padding: "0 5px",
    },
  },
}));

const CloseButton = styled(StyledIconButton)(() => ({
  maxWidth: "24px",
  maxHeight: "24px",
  borderRadius: "4px",
  padding: "0 !important",
}));

const ModalTitleContainer = styled(DialogTitle)(() => ({
  padding: "16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const ContentContainer = styled(DialogContent)(() => ({
  padding: "16px 16px 24px",
}));

const ModalActionsContainer = styled(DialogActions)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: "24px",
  padding: "12px 16px",
}));

export default EditMerchantBaseModal;
