import styled from "@emotion/styled";
import GiveLogo from "@assets/images/GiveLogo.png";

export const Privacy = () => {
  return (
    <Container>
      <Wrapper>
        <Header>
          <img src={GiveLogo} alt="Give Logo" style={{ height: "100%" }} />
        </Header>
        <main>
          <Title>Give Corporation Privacy Policy - United States</Title>
          <PrivacyContent />
        </main>
        <Footer>©{new Date().getFullYear()} Give Corporation, Inc.</Footer>
      </Wrapper>
    </Container>
  );
};

export const PrivacyContent = () => {
  return (
    <>
      <Paragraph>
        This Privacy policy reflects how Give Corporation (&ldquo;Give
        Corporation&ldquo; or &ldquo;we&ldquo;) provides its payment services
        (the &ldquo;Services&ldquo;) through third party websites
        (&ldquo;Platforms&ldquo;), such as marketplaces, business tools
        providers, and crowdfunding sites. Platforms provide Give
        Corporation&apos;s Services to their end users (&ldquo;Users&ldquo; or
        &ldquo;you&ldquo;) by integrating with Give Corporation&apos;s
        application programming interface (&ldquo;API&ldquo;), which also
        enables the Platform to provide User information to Give Corporation and
        receive information from Give Corporation. This Privacy Policy addresses
        how Give Corporation handles all User information, including User
        information provided by you, User information provided by the Platform,
        and User information obtained by Give Corporation from third parties.
        Give Corporation&apos;s Privacy Policy describes the ways we collect,
        use, and share User information. We may amend this Privacy Policy at any
        time by posting a revised version at this link. The revised version will
        be effective at the time we post it, unless we provide additional notice
        or an opportunity to &ldquo;opt-in&ldquo; because changes are material
        or retroactive.
      </Paragraph>
      <Paragraph>
        By using the Services, you are accepting the practices described in this
        Privacy Policy. By using a Platform that has integrated with Give
        Corporation&apos;s API, you are also accepting the practices described
        in the Platform&apos;s Privacy Policy, which you should review
        carefully. Any information you enter on the Platform&apos;s website or
        application, or on Give Corporation pages, fields, or resources that are
        integrated with the Platform&apos;s website or application, may be
        shared with the owner of the Platform website or application, subject to
        the requirements of the Payment Card Industry Data Security Standard
        (PCI-DSS) and applicable law. Give Corporation is not responsible for
        the content or information practices of Platforms.
      </Paragraph>

      <Heading>Information Collected</Heading>
      <Paragraph>
        Give Corporation collects three types of information: information that
        Users intentionally provide to Give Corporation or the Platform,
        information about Users that third parties provide to Give Corporation
        or the Platform to augment or verify that information, and information
        automatically provided by Users as they interact with the Give
        Corporation or Platform website. Information that can be used to
        identify, contact, or locate a User is &ldquo;personally identifiable
        information.&ldquo; Automatically provided information that uniquely
        identifies a device or browser is not personally identifiable
        information, unless it is linked to a particular User.
      </Paragraph>

      <Heading>User Provided Information</Heading>
      <Paragraph>
        Users either accept payments as &ldquo;Merchants&ldquo; or make payments
        as &ldquo;Payers.&ldquo; A Merchant must provide the Merchant&apos;s
        name, email address, a self-selected password, street address, telephone
        number, tax identification number, merchant category code, date of birth
        (if a natural person), and bank account information. A Merchant may
        provide additional information, such as a link to its website or social
        media account, in order to accelerate account activation and qualify for
        faster disbursement of funds.
      </Paragraph>
      <Paragraph>
        A Payer who is paying with a credit or debit card must provide the
        Payer&apos;s name, card number, expiration date, CVV code, country, and
        zip code. A Payer who is paying by ACH or EFT must provide the
        Payer&apos;s name and bank account information.
      </Paragraph>
      <Paragraph>
        Merchants or Payers may voluntarily provide additional information to
        Give Corporation or the Platform, for example, when seeking customer
        support or in response to surveys or other inquiries.
      </Paragraph>

      <Heading>Third-Party Provided Information</Heading>
      <Paragraph>
        Give Corporation or the Platform may obtain information about Users from
        third parties, such as identity verification services, credit reporting
        services, and social networks such as Facebook or LinkedIn. The Platform
        may provide Give Corporation with additional information, such as the
        User&apos;s transactional history on the Platform.
      </Paragraph>

      <Heading>Automatically Provided Information</Heading>
      <Paragraph>
        Give Corporation or the Platform may automatically record certain
        information about or related to your use of the Services and the
        Platform that is made available through your computer. Three such
        technologies are described below.
      </Paragraph>
      <UList>
        <ListElement>
          Log Files - Give Corporation or the Platform may collect internet
          protocol (IP) address, browser type, device ID, internet service
          provider (ISP), information about your computer and software, links,
          materials you request, your approximate location, referring/exit
          pages, date/time stamp, and other metadata. Platforms may embed
          Javascript code into page loads, which instructs Users&apos; web
          browsers to make web requests back to our servers to collect
          information about User page views and other activities.
        </ListElement>
        <ListElement>
          Cookies - Give Corporation or the Platform collects certain
          information from the User&apos;s browser using small data files called
          &ldquo;cookies.&ldquo; For example, Give Corporation or the Platform
          may use session cookies to help recognize a User who visits multiple
          website pages during the same session, so that the User does not have
          to enter a password to access each page. Session cookies terminate
          once the User closes the browser. By default, Give Corporation or the
          Platform also uses persistent cookies to collect, store, and track
          information. For example, Give Corporation or the Platform uses
          persistent cookies to store the User&apos;s login ID (but not the
          User&apos;s password) to make it easier for the User to login when the
          User returns to the website. We encode our cookies so that only we can
          interpret the information stored in them. You can remove or block
          persistent cookies using the settings in your browser, but this may
          limit your ability to use our Services.
        </ListElement>
        <ListElement>
          Clear GIFs and Web Beacons - Give Corporation or the Platform may
          employ a software technology, called clear gifs or web beacons, that
          helps us better manage content on the Give Corporation or Platform
          website, and in Give Corporation or Platform emails, by informing us
          what content is effective. Clear gifs are tiny graphics with a unique
          identifier, similar in function to cookies, and are used to track the
          online movements of Web users. In contrast to cookies, which are
          stored on a User&apos;s computer hard drive, clear gifs are embedded
          invisibly on Web pages and are about the size of the period at the end
          of this sentence.
        </ListElement>
      </UList>

      <Heading>&ldquo;Do Not Track&ldquo; Disclosure</Heading>
      <Paragraph>
        Give Corporation does not currently respond to web browser &ldquo;Do Not
        Track&ldquo; signals or other mechanisms that provide a method to opt
        out of the collection of personally identifiable information across the
        networks of websites and online services in which we participate. If we
        do so in the future, we will describe how we do so in this Privacy
        policy. Platforms may collect personally identifiable information about
        a User&apos;s online activities from you or from the Give Corporation
        Services. Please refer to the Platform&apos;s Privacy policy for
        information regarding its own &ldquo;Do Not Track&ldquo; policy.
      </Paragraph>
      <Paragraph>
        <strong>The Gist,</strong>
        <br />
        We collect and store certain information about you. This information may
        be provided directly by you, a platform partner, or Give Corporation may
        collect it based on your use of the website.
      </Paragraph>

      <Heading>Use of Information Obtained by Give Corporation</Heading>
      <Paragraph>
        Give Corporation uses User information primarily in order to develop,
        operate, support, maintain, enhance and provide the Services. Give
        Corporation uses User information to process payment transactions; to
        provide receipts and reports; to resolve disputes, collect fees, and
        troubleshoot problems; to customize, measure, and improve the Services;
        and to enforce our agreements with Platforms and our Terms of Service
        and Merchant Agreement with Users.
      </Paragraph>
      <Paragraph>
        Give Corporation also uses User information to detect and prevent fraud
        and other potentially illegal activities. Give Corporation may combine
        User information with information from other sources using proprietary
        algorithms to calculate a measure of the risk that a particular User or
        transaction is fraudulent (&ldquo;Risk Score&ldquo;).
      </Paragraph>
      <Paragraph>
        Give Corporation uses User information for our administrative and
        operational purposes. Give Corporation or the Platform may notify you of
        changes in the Services, or may solicit your feedback, or may conduct
        market research, or may send you promotional materials. To unsubscribe
        from promotional emails, click the &ldquo;unsubscribe&ldquo; link in the
        email.
      </Paragraph>
      <Paragraph>
        <strong>The Gist,</strong>
        <br />
        Give Corporation uses information collected about you in order to meet
        legal obligations and provide a better service.
      </Paragraph>

      <Heading>Sharing of Information with Third Parties</Heading>
      <Paragraph>
        Give Corporation may share User information with third-party service
        providers who help with certain parts of our business operations
        including settlement, security, validation of user credentials, secure
        data storage, marketing, customer service, and other services. Give
        Corporation requires that these service providers use personally
        identifiable User information only in connection with the services they
        perform for Give Corporation. In particular, the Services integrate ACH
        authorization services.
      </Paragraph>
      <Paragraph>
        Give Corporation also may share non-personally identifiable User
        information with third parties that help us better understand how Users
        use our Service or help us detect and prevent fraud and other
        unauthorized or suspicious activity. These third parties may use cookies
        and other technologies to collect non-personally identifiable
        information about Users and combine it with similar information
        collected from others. They may use this information to help Give
        Corporation to better understand our Users, and to help their other
        customers better understand their users.
      </Paragraph>
      <Paragraph>
        A Give Corporation Risk Score is not personally identifiable
        information, and Give Corporation may share it (and other similar
        information generated by Give Corporation) with Platforms or other third
        parties. Give Corporation claims ownership of Risk Scores and similar
        analytical results that we generate using User information, whether
        alone or in combination with other information. Give Corporation may
        use, disclose, or sell Risk Scores in Give Corporation&apos;s sole
        discretion.
      </Paragraph>
      <Paragraph>
        Give Corporation may share User information in the event of a merger,
        acquisition, debt financing, sale of all or a portion of our assets, or
        similar transaction, or in the event of insolvency, bankruptcy or
        receivership in which User information is transferred to one or more
        third parties as one of our business assets. Should such an event occur,
        Give Corporation will endeavor to assure that the acquirer, successor,
        or assignee (as the case may be) follows this Privacy policy with
        respect to User information. If User information could be used contrary
        to this Privacy policy, Users will receive prior notice as well as the
        opportunity to opt out.
      </Paragraph>
      <Paragraph>
        Give Corporation may share User information with law enforcement,
        government officials, or other third parties in the event of a subpoena,
        court order or similar legal procedure, or when Give Corporation
        believes in good faith that the disclosure of User information is
        necessary or advisable to report suspected illegal activity, or to
        protect Give Corporation&apos;s property or legal rights (including, but
        not limited to, enforcement of Give Corporation&apos;s Terms of Service,
        Merchant Agreement, and other agreements) or the property or rights of
        others, or otherwise to help protect the safety or security of the
        Services.
      </Paragraph>
      <Paragraph>
        Except as expressly disclosed in this Privacy policy, Give Corporation
        will not sell or disclose User information to third parties. Give
        Corporation will not sell, rent, share, or trade personally identifiable
        information to third parties (other than the Platform through which Give
        Corporation collected such information) for their promotional purposes.
        Give Corporation may disclose aggregated or other types of
        non-personally identifiable information to third parties for various
        purposes.
      </Paragraph>
      <Paragraph>
        <strong>The Gist,</strong>
        <br />
        We will share personally identifiable information with third parties
        only to best provide Give Corporation&apos;s services and in special
        situations, such as legal investigations and merger. We may also share
        non-identifiable information with third parties that help us prevent
        fraud and analyze website activity.
      </Paragraph>

      <Heading>Security</Heading>
      <Paragraph>
        Give Corporation has implemented physical, technical, and procedural
        safeguards to protect User information from unauthorized access,
        disclosure, alteration, or destruction. Give Corporation uses computer
        safeguards such as firewalls and data encryption, and authorizes access
        to personally identifiable information only for those employees,
        contractors, and agents who require it to fulfill their job
        responsibilities.
      </Paragraph>
      <Paragraph>
        Give Corporation takes additional care to protect User information, such
        as credit card or bank account numbers, if disclosure of the particular
        type of User information could cause direct financial loss. Give
        Corporation encrypts such information and transmits it under Secure
        Socket Layer (SSL).
      </Paragraph>
      <Paragraph>
        <strong>The Gist,</strong>
        <br />
        Give Corporation has taken a number of steps in order to become an
        extremely secure service.
      </Paragraph>

      <Heading>Location</Heading>
      <Paragraph>
        Give Corporation stores and processes User information on dedicated
        servers located in secure data centers that may be located within the
        United States or in other jurisdictions. If you use the Services from
        the European Economic Area or other regions of the world with laws
        governing data collection and use that differ from laws of the United
        States, then you understand and consent to the transfer of your User
        information to the United States or other jurisdictions for the uses
        identified above in accordance with this Privacy policy. You acknowledge
        and agree that the privacy and data security laws in place in the United
        States or other jurisdictions may be different from the privacy and data
        security laws in force in the country in which you reside. By
        voluntarily providing User information, you hereby agree that you are
        consenting to our collection, use, storage, and disclosure of such User
        information in accordance with this Privacy policy. In relation to
        transfers of User information from Give Corporation Payments Ltd. (in
        the UK) to Give Corporation, Inc. (in the United States), Give
        Corporation has put in place measures, including standard model clauses,
        to ensure an adequate level of protection for the rights and freedoms of
        individuals in relation to the processing of their personal data, as
        required by European data protection laws.
      </Paragraph>
      <Paragraph>
        <strong>The Gist,</strong>
        <br />
        It&apos;s easy to change your user information.
      </Paragraph>

      <Heading>Children</Heading>
      <Paragraph>
        The Services are not intended for children. We ask that minors (persons
        under the age of 18) do not submit any personal information to us or use
        the Services.
      </Paragraph>
      <Paragraph>
        <strong>The Gist,</strong>
        <br />
        You may only use Give Corporation if you are 18 years old or older.
      </Paragraph>

      <Heading>Contacting Give Corporation</Heading>
      <Paragraph>
        If you have questions or concerns regarding this policy, you may email
        us at{" "}
        <a href="mailto:legal@givecorporation.com">legal@givecorporation.com</a>
        .
      </Paragraph>
      <Paragraph>You can also write to us at:</Paragraph>
      <Paragraph>
        Give Corporation
        <br />
        Privacy Policy
        <br />
        <AddressSpan>4343 N Scottsdale Road Suite &#35;150</AddressSpan>
        <br />
        <AddressSpan>Scottsdale, AZ 85251</AddressSpan>
        <br />
      </Paragraph>
      <Paragraph>
        <strong>The Gist,</strong>
        <br />
        You can reach us through email or postal mail regarding the Privacy
        policy.
      </Paragraph>
    </>
  );
};

const Container = styled.div`
  background-color: #fff;
  font-family: "Give Whyte", Circular, "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
  color: #292928;
  padding-top: 20px;
  min-height: 100%;
`;
const Wrapper = styled.div`
  min-height: 100%;
  max-width: 650px;
  margin: auto;
`;

const AddressSpan = styled.span`
  color: #1a1a1a;
`;

const Header = styled.header`
  height: 56px;
  text-align: center;
`;

const Footer = styled.footer`
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
  background: #fafafa;
  color: #767f84;
  font-size: 14px;
`;

const Paragraph = styled.p`
  font-size: 14px;
  margin: 0;
  padding: 10px 0 10px 0;
  color: #666665;
  font-weight: 400;
  line-height: 20px;
`;

const Title = styled.h2`
  font-weight: 300;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -0.03em;
  margin-top: 64px;
`;

const Heading = styled.h3`
  font-weight: 400;
  font-size: 20px;
  padding: 20px 0 0 0;
  margin: 0;
`;

const UList = styled.ul`
  margin: 0px 20px 0px 20px;
  padding: 0px;
  padding-left: 20px;
`;

const ListElement = styled.li`
  font-size: 14px;
  margin: 0;
  padding: 5px;
  color: #666665;
`;
