import Papa from "papaparse";
import { MERCHANT_NAME_REGEX } from "@common/BusinessProfileInputs/NameInput";
import * as Yup from "yup";
import { TImportedRow } from "../types";
import { COLUMN_NAMES } from "../constants";

const emailValidator = Yup.string().email();
const getCorrectString = (word?: string | number) =>
  typeof word === "string" ? word.toLocaleLowerCase().trim() : word;
export const parseCsv = (
  file: File,
  onError: VoidFunction,
  onStep: (el: TImportedRow) => void,
) =>
  Papa.parse(file, {
    skipEmptyLines: true,
    header: true,
    dynamicTyping: true,
    transform: (value, field) => {
      const filedName = getCorrectString(field);

      if (filedName === getCorrectString(COLUMN_NAMES[0])) {
        return validateWithExceptions(value, validators[COLUMN_NAMES[0]]);
      } else if (filedName === getCorrectString(COLUMN_NAMES[1])) {
        return validateWithExceptions(value, validators[COLUMN_NAMES[1]]);
      } else {
        return value;
      }
    },
    error: (error) => {
      if (error) onError();
    },
    step: ({ data, errors }) => {
      // Cast data to an object to remove the "unknown" type error
      const normalizedData = Object.keys(data as Record<string, any>).reduce(
        (acc, key) => {
          acc[getCorrectString(key) as string] = (data as Record<string, any>)[
            key
          ];
          return acc;
        },
        {} as Record<string, any>,
      );

      const merchantName =
        normalizedData?.[getCorrectString(COLUMN_NAMES[1]) as string] || "";
      const pahEmail =
        normalizedData?.[getCorrectString(COLUMN_NAMES[0]) as string] || "";

      if (errors.length === 0 && (merchantName || pahEmail)) {
        onStep({ merchantName, pahEmail });
      }
    },
  });

type Validator = (value: string) => void;
type FieldValidators = Record<(typeof COLUMN_NAMES)[number], Validator>;

const validators: FieldValidators = {
  [COLUMN_NAMES[0]]: (value) => {
    const val = value.trim().toLowerCase();
    emailValidator.validateSync(val);
    return val;
  },
  [COLUMN_NAMES[1]]: (value) => {
    return value.trim().replace(MERCHANT_NAME_REGEX, "").replace(/\s+/g, " ");
  },
};

const validateWithExceptions = (value: string, validator: Validator) => {
  try {
    return validator(value);
  } catch (err) {
    return "";
  }
};
