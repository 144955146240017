import { TAG_STATUSES_ENUM } from "@common/TextTag/types";
import { StatusNames } from "@components/Merchants/MerchantList/MerchantStatusFilters";
import { MerchantPreviewModalProps } from "@components/Merchants/MerchantPreview/MerchantPreviewModal";
import { MerchantData } from "@hooks/enterprise-api/account/useGetMerchants";
import { getMerchantStatus } from "@hooks/enterprise-api/merchants/useGetColumns";
import { useAppSelector } from "@redux/hooks";
import { selectMerchantStatusName } from "@redux/slices/enterprise/merchants";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import useMerchantSidePanel from "../hooks/useMerchantSidePanel";


type OpenChallengeType = { identifier: string; displayName: string };

type SidepanelMerchantTypes = ReturnType<typeof useMerchantSidePanel> & {
  id: number;
  isEnterprise: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  status: TAG_STATUSES_ENUM | null;
  tableRowData?: MerchantData;
  isSponsorView: boolean;
  setOpenChallenge: React.Dispatch<
    React.SetStateAction<OpenChallengeType | undefined>
  >;
  openChallenge?: OpenChallengeType;
  setOpenOldPanel?: Dispatch<SetStateAction<boolean>>;
};

const MerchantSidePanelContext = createContext<SidepanelMerchantTypes>(
  {} as any,
);

interface Props extends MerchantPreviewModalProps {
  children: React.ReactNode;
  tableRowData?: MerchantData;
  setOpenOldPanel?: Dispatch<SetStateAction<boolean>>;
}

function MerchantSidePanelProvider({
  children,
  isFirst,
  isLast,
  setSelectedRow,
  // status,
  tableRowData,
  setOpenOldPanel,
  ...rest
}: Props) {
  const [openChallenge, setOpenChallenge] = useState<
    OpenChallengeType | undefined
  >(undefined);
  const status = useMemo(
    () =>
      tableRowData
        ? (getMerchantStatus(tableRowData) as TAG_STATUSES_ENUM)
        : null,
    [tableRowData],
  );

  const statusName = useAppSelector(selectMerchantStatusName);

  // Data from this hook is being used across the entire panel so its better to have it in the context then data specific to a section we can call it there
  const data = useMerchantSidePanel({
    id: rest?.id,
    setSelectedRow,
  });

  const isSponsorView = useMemo(() => {
    const statusDisplayName = data?.merchant?.sponsorStatusDisplayName;
    const sponsorStatusDisplayName =
      statusDisplayName || tableRowData?.sponsorStatusDisplayName || "";
    const status =
      !!sponsorStatusDisplayName &&
      !["Approved", "Declined", "Pending"].includes(sponsorStatusDisplayName)
        ? "Ready for Review"
        : sponsorStatusDisplayName;

    return (
      (statusName === StatusNames.aquirerSponsor || statusName === "") &&
      ["Ready for Review", "Pending"].includes(status)
    );
  }, [statusName, tableRowData, data]);

  return (
    <MerchantSidePanelContext.Provider
      value={{
        ...data,
        id: rest.id,
        setOpenChallenge,
        isEnterprise: false,
        isFirst,
        status,
        isLast,
        isSponsorView,
        openChallenge,
        setOpenOldPanel,
      }}
    >
      {children}
    </MerchantSidePanelContext.Provider>
  );
}

export default MerchantSidePanelProvider;

export const useMerchantSidePanelContext = () =>
  useContext(MerchantSidePanelContext);
