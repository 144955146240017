import NiceModal from "@ebay/nice-modal-react";
import { Text } from "@common/Text";
import { FormProvider } from "react-hook-form";
import { Box, DialogContent, Grid, Stack, styled } from "@mui/material";
import { Button } from "@common/Button";
import { palette } from "@palette";
import { RHFSelect } from "@common/Select";
import { RHFInput, RHFTelInput } from "@common/Input";
import { TBusinessOwner, TMerchantDocument } from "../data.types";
import { useCreateMerchantBusinessOwner } from "../hooks/useCreateMerchantBusinessOwner";
import { NameInput, OwnershipTabsInput } from "@common/BusinessProfileInputs";
import { BirthDatePicker } from "@common/DatePickers";
import { RHFCheckbox } from "@common/Checkbox";
import useCountryCodes from "@hooks/common/useCountryCodes";
import { ProvinceInput, ZipCodeInput } from "@common/AddressInputs";
import EditMerchantBaseModal from "../components/EditMerchantBaseModal";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { BusinessOwnershipIcon } from "@assets/icons/RebrandedIcons";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  DELETE_DENY_MESSAGE,
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { BusinessProfileDisclaimer } from "@sections/VerifyAccountHolder_v2/components/BusinessProfileDisclaimer";
import CustomTaxSsnInput from "@common/BusinessProfileInputs/CustomTaxSsnInput";
import PepStatusChip from "../PEP/components/PepStatusChip";
import { DELETE_CONFIRMATION_MODAL } from "modals/modal_names";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import { RHFStreetAddress } from "@common/RHFStreetAddress";
import { NonResidentInputs } from "@common/NonResidentInput/NonResidentInputs";
import BackButton from "../components/BackButton";
import { isEmpty } from "lodash";
import moment from "moment";
import { buildMerchantEndpoints } from "@services/api/utils.api";
import BusinessOwnerIDUploader from "@common/BusinessOwnerIDUploader/BusinessOwnerIDUploader";
import { businessOwnerDescriptions } from "@components/ProfilePage/BusinessProfileSetup/utils/principal.utils";
import { BusinessUnionTypes } from "@common/BusinessProfileInputs/BusinessTypeSelect";

export interface EditOwnerModalProps {
  merchantId: number;
  totalOwnerships: number;
  data?: TBusinessOwner;
  isApprovedLE?: boolean;
  legalEntityID?: number;
  deleteDisabled?: boolean;
  handleDelete?: () => void;
  onClose?: (data: TBusinessOwner) => void;
  isIndividualSoleProprietorship?: boolean;
  doHandleDelete?: boolean;
  showTag?: boolean;
  permissionResource?: string;
  isBPLinked?: boolean;
  businessType: BusinessUnionTypes;
  onCloseParentModal?: () => void;
  idFile?: TMerchantDocument;
  isMerchant?: boolean;
  primaryAccountHolder?: Partial<TBusinessOwner>;
}

const EditBusinessOwnerModal = NiceModal.create(
  ({
    merchantId,
    legalEntityID,
    data,
    totalOwnerships = 0,
    isMerchant,
    isIndividualSoleProprietorship,
    deleteDisabled = false,
    handleDelete,
    onClose,
    doHandleDelete,
    showTag,
    permissionResource,
    businessType,
    onCloseParentModal,
    idFile,
    isBPLinked,
    primaryAccountHolder,
  }: EditOwnerModalProps) => {
    const { isMobileView } = useCustomTheme();
    const { selectCountriesList } = useCountryCodes();
    const {
      open,
      methods,
      handleCancel,
      onSubmit,
      isLoading,
      handleChangeAddress,
    } = useCreateMerchantBusinessOwner({
      merchantId,
      legalEntityID,
      data,
      totalOwnerships,
      onClose,
      idFile,
      primaryAccountHolder,
      isIndividualSoleProprietorship,
      businessType,
    });

    const permissionProps = {
      resource: permissionResource
        ? permissionResource
        : composePermission(
            RESOURCE_BASE.LEGAL_ENTITY,
            RESOURCE_BASE.PRINCIPAL,
          ),
      withPortal: !permissionResource,
    };

    const isUpdateAllowed = useAccessControl({
      operation: OPERATIONS.UPDATE,
      ...permissionProps,
    });

    const isDeleteAllowed = useAccessControl({
      operation: OPERATIONS.DELETE,
      ...permissionProps,
    });

    const {
      watch,
      formState: { dirtyFields, defaultValues },
    } = methods;
    const values = watch();

    const isPEPMatch = data?.pepStatusName === "confirmed_match";
    const isOFACMatch = data?.ofac?.lastCheckStatusName === "confirmed_match";

    const hasNotEditPermission = Boolean(data?.id) && !isUpdateAllowed;
    const isInputDisabled =
      isBPLinked || hasNotEditPermission || isPEPMatch || isOFACMatch;

    const isTaxExemptOrGovtAgency = [
      "tax_exempt_organization",
      "government_agency",
    ].includes(businessType);

    const ownershipDisabled =
      isIndividualSoleProprietorship || isTaxExemptOrGovtAgency
        ? true
        : isPEPMatch
        ? false
        : hasNotEditPermission;

    const d1 = new Date(values.dob);
    const d2 = new Date(defaultValues?.dob || "");
    const isDirty =
      (!isEmpty(dirtyFields) &&
        (!("dob" in dirtyFields) || Object.keys(dirtyFields).length > 1)) ||
      !(moment(d1).isSame(d2, "day") && !moment(d1).isSame(d2, "minute"));

    const inputList = [
      {
        input: (
          <NameInput
            name="firstName"
            label="First Name"
            placeholder="Enter First Name"
            disabled={isInputDisabled}
          />
        ),
        isInput: true,
      },
      {
        input: (
          <NameInput
            name="lastName"
            label="Last Name"
            placeholder="Enter Last Name"
            disabled={isInputDisabled}
          />
        ),
        isInput: true,
      },
      {
        input: (
          <CustomTaxSsnInput
            ssnName="ssn"
            taxIdName="ein"
            isBusiness
            disabled={isInputDisabled}
          />
        ),
        isInput: true,
      },
      {
        input: (
          <BirthDatePicker
            name="dob"
            placeholder="MM/DD/YYYY"
            fullWidth
            label="Date of Birth"
            disabled={isInputDisabled}
          />
        ),
        isInput: true,
      },
      {
        input: (
          <RHFInput
            name="email"
            label="Email address"
            placeholder="email@example.com"
            fullWidth
            disabled={isInputDisabled}
          />
        ),
        isInput: true,
      },
      {
        input: (
          <RHFTelInput
            name="phone"
            label="Business Owner Phone"
            fullWidth
            disabled={isInputDisabled}
          />
        ),
        isInput: true,
      },
      {
        input: (
          <Text
            fontSize={12}
            fontStyle="italic"
            fontWeight="book"
            color={palette.neutral[70]}
          >
            Phone and Email will not be used for marketing
          </Text>
        ),
      },
      {
        input: (
          <BusinessOwnerIDUploader
            disabled={isInputDisabled}
            merchantId={merchantId}
            idFile={idFile}
          />
        ),
        isInput: true,
      },
      {
        input: (
          <Stack gap={1}>
            <NonResidentInputs
              inputsDisabled={isInputDisabled}
              isBusinessOwner
            />
          </Stack>
        ),
      },
      {
        input: (
          <Box my={2}>
            <OwnershipTabsInput
              name="ownership"
              label="Custom percentage"
              placeholder="0-100"
              leType={businessType}
              isFullyDisabled={ownershipDisabled}
              disableWithoutReset={isInputDisabled}
            />
          </Box>
        ),
      },
      {
        input: (
          <Text
            fontSize="18px"
            fontWeight="regular"
            color={palette.neutral[80]}
          >
            Owner Address
          </Text>
        ),
      },
      {
        input: (
          <RHFCheckbox
            name="isBusinessAddress"
            label="Use Business Address"
            onChange={handleChangeAddress}
            dataTestId="business_owner_use_BusinessAddress_checkbox"
            disabled={isInputDisabled}
          />
        ),
        isInput: true,
      },
      {
        input: (
          <RHFSelect
            name="address.country"
            label="Country"
            options={selectCountriesList}
            disabled
          />
        ),
        isInput: true,
        hidden: values.isBusinessAddress,
      },
      {
        input: (
          <RHFStreetAddress
            name="address.line1"
            label="Street Address"
            placeholder="Enter Street Address..."
            fullWidth
            disabled={isInputDisabled}
          />
        ),
        isInput: true,
        hidden: values.isBusinessAddress,
      },
      {
        input: (
          <RHFInput
            name="address.city"
            label="City"
            placeholder="Enter city..."
            fullWidth
            disabled={isInputDisabled}
          />
        ),
        isInput: true,
        hidden: values.isBusinessAddress,
      },
      {
        input: (
          <ProvinceInput
            name="address.state"
            isUS={values.address?.country === "US"}
            disabled={isInputDisabled}
          />
        ),
        isInput: true,
        hidden: values.isBusinessAddress,
      },
      {
        input: (
          <ZipCodeInput
            name="address.zip"
            label="ZIP"
            placeholder="Enter Zip Code"
            letterSpacing="4px"
            disabled={isInputDisabled}
          />
        ),
        hidden: values.isBusinessAddress,
      },
    ];

    const tooltipProps = {
      show: isInputDisabled,
      message: getTooltipMessage(isPEPMatch, isOFACMatch),
    };

    const handleDeleteBusinessOwner = (ownerID: number | string) => {
      const url = buildMerchantEndpoints(
        `legal-entities/${legalEntityID}/principals/${ownerID}`,
        merchantId,
      );
      NiceModal.show(DELETE_CONFIRMATION_MODAL, {
        variant: "owner",
        itemName: `${data?.firstName} ${data?.lastName}`,
        url: url,
      });
    };
    const onDelete = () => {
      if (handleDelete) handleDelete();
      data?.id && doHandleDelete && handleDeleteBusinessOwner(data?.id);
      handleCancel();
    };

    const handleCloseModal = () => {
      if (onCloseParentModal) {
        onCloseParentModal();
        setTimeout(() => {
          handleCancel();
        }, 1000); //Wait for Parent modal to completely close for better experience
        return;
      }
      handleCancel();
    };

    const ModalTitleIcon = onCloseParentModal ? (
      <BackButton handleGoBack={handleCancel} />
    ) : (
      <BusinessOwnershipIcon />
    );

    return (
      <EditMerchantBaseModal
        title={`${data?.id ? "Edit" : "Add"} Business Owner`}
        icon={ModalTitleIcon}
        open={open}
        handleCancel={handleCloseModal}
        actionsSx={{
          justifyContent: data?.id ? "space-between" : "flex-end",
        }}
        actions={
          <>
            <Box>
              {!deleteDisabled && data?.id && !isPEPMatch && !isOFACMatch && (
                <Button
                  size="medium"
                  data-testid="business-owner-delete-btn"
                  background="tertiary"
                  disabled={!isDeleteAllowed}
                  onClick={onDelete}
                  sx={{
                    padding: "8px 24px",
                    color: palette.filled.red,
                  }}
                  tooltipProps={{
                    show: !isDeleteAllowed,
                    message: DELETE_DENY_MESSAGE,
                  }}
                >
                  Delete
                </Button>
              )}
            </Box>
            <Button
              size="medium"
              background="primary"
              type="submit"
              form="edit-owner-form"
              data-testid="business-owner-submit-btn"
              disabled={
                !isDirty || isLoading || (isInputDisabled && ownershipDisabled)
              }
              sx={{ padding: "8px 24px" }}
            >
              {data?.id ? (isMobileView ? "Save" : "Save Changes") : "Add"}
            </Button>
          </>
        }
        sx={{
          "& .MuiPaper-root": {
            width: "500px !important",
            maxWidth: "500px !important",

            "& .MuiDialogTitle-root+.MuiDialogContent-root": {
              paddingTop: "0px !important",
            },
          },
        }}
      >
        <FormProvider {...methods}>
          <Box
            component="form"
            id="edit-owner-form"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <FadeUpWrapper delay={70}>
              <Text
                data-testid="bo-description-text"
                fontWeight="book"
                color={palette.neutral[70]}
              >
                {businessOwnerDescriptions[businessType]}
              </Text>
            </FadeUpWrapper>

            {isIndividualSoleProprietorship && (
              <FadeUpWrapper delay={100}>
                <BusinessProfileDisclaimer
                  messageType="sole_proprietor"
                  containerStyle={{ marginTop: "16px" }}
                />
              </FadeUpWrapper>
            )}
            {((data?.id && showTag) || isOFACMatch) && (
              <FadeUpWrapper
                delay={100}
                containerProps={{
                  sx: {
                    marginTop: isIndividualSoleProprietorship ? "16px" : "8px",
                  },
                }}
              >
                <Stack direction="row" spacing="8px">
                  {data?.id && showTag && (
                    <PepStatusChip
                      ownerPepStatus={data?.pepStatusName}
                      withPEP
                    />
                  )}
                  {isOFACMatch && (
                    <Text
                      color={palette.filled.red}
                      sx={{
                        padding: "2px 16px",
                        borderRadius: "16px",
                        background: palette.tag.error.bg,
                        width: "fit-content",
                      }}
                    >
                      OFAC Confirmed match
                    </Text>
                  )}
                </Stack>
              </FadeUpWrapper>
            )}

            <Grid
              mt={isIndividualSoleProprietorship ? "20px" : 0}
              container
              spacing={2}
            >
              {inputList.map(({ input, hidden, isInput }, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sx={{ ...(hidden && { display: "none" }) }}
                >
                  <WithTooltipWrapper
                    hasTooltip={!!isInput}
                    tooltipProps={tooltipProps}
                  >
                    <FadeUpWrapper delay={70 * (index + 1)}>
                      {input}
                    </FadeUpWrapper>
                  </WithTooltipWrapper>
                </Grid>
              ))}
            </Grid>
          </Box>
        </FormProvider>
      </EditMerchantBaseModal>
    );
  },
);

const baseMessage = "This edit is blocked by a confirmed match on ";

const getTooltipMessage = (
  isPEPMatch: boolean,
  isOFACMatch: boolean,
): string => {
  if (isPEPMatch && isOFACMatch) {
    return baseMessage + "PEP and OFAC";
  } else if (isPEPMatch) {
    return baseMessage + "PEP";
  } else if (isOFACMatch) {
    return baseMessage + "OFAC";
  } else {
    return EDIT_DENY_MESSAGE;
  }
};

export const ContentContainer = styled(DialogContent)(() => ({
  "& .MuiDialogContent-root": {
    padding: "0 16px 24px",
    paddingTop: "0 !important",
  },
}));

export default EditBusinessOwnerModal;
