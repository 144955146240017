import { Box, BoxProps, Grid, styled } from "@mui/material";
import { TCategoryCode } from "@components/Merchants/MerchantPreview/data.types";
import { palette } from "@palette";
import { Text, TruncateText } from "@common/Text";
import { PlusIcon, TrashBin } from "@assets/icons/RebrandedIcons";
import NoResultsState from "@common/EmptyState/NoResultsState";

type MCCColumnProps = {
  list: TCategoryCode[];
  isAddedCategory: (id: number | string) => boolean;
  search?: string;
  isDisabledCategory?: boolean;
  onAddCategory?: (category: TCategoryCode) => void;
  onRemoveCategory?: (id: number | string) => void;
  side?: string;
};

const EditEnterpriseMCCColumn = ({
  list,
  search,
  onAddCategory,
  onRemoveCategory,
  isAddedCategory,
  isDisabledCategory,
  side,
}: MCCColumnProps) => {
  const showEmptyResult =
    list?.length === 0 && search !== "" && side !== "right";
  return (
    <Grid item spacing={1} xs={12} sm={6}>
      <StyledMCCColumn empty={list?.length === 0}>
        {showEmptyResult ? (
          <NoResultsState
            sx={{
              height: "100%",
              width: "100%",
            }}
            searchQuery={search}
          />
        ) : (
          <>
            {list?.map((prop) => {
              if (!prop) return null;
              const { id, ...category } = prop;
              return (
                <MerchantCategoryItem
                  key={id}
                  added={isAddedCategory(id) && Boolean(onRemoveCategory)}
                  disabled={isDisabledCategory}
                  onClick={() => {
                    if (onAddCategory && !isAddedCategory(id)) {
                      onAddCategory({ ...category, id });
                    }
                    if (
                      onRemoveCategory &&
                      isAddedCategory(id) &&
                      !isDisabledCategory
                    ) {
                      onRemoveCategory(id);
                    }
                  }}
                  {...category}
                />
              );
            })}
          </>
        )}
      </StyledMCCColumn>
    </Grid>
  );
};

const StyledMCCColumn = styled(Box, {
  shouldForwardProp: (prop) => prop !== "empty",
})<{ empty: boolean }>(({ empty }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  borderRadius: "8px",
  border: `1px solid ${palette.neutral[10]}`,
  padding: "12px",
  ...(empty && {
    justifyContent: "center",
  }),
  height: "490px",
  overflowY: "auto",
}));

type MerchantCategoryItemProps = {
  added?: boolean;
  disabled?: boolean;
} & Omit<TCategoryCode, "id"> &
  BoxProps;

const MerchantCategoryItem = ({
  code,
  title,
  added,
  disabled,
  ...props
}: MerchantCategoryItemProps) => {
  return (
    <StyledMerchantCategoryItem disabled={disabled} {...props}>
      <CategoryCode>{code}</CategoryCode>
      <TruncateText
        lineClamp={1}
        sx={{ wordBreak: "break-all" }}
        fontWeight="book"
        color={palette.neutral[80]}
        flexGrow={1}
      >
        {title}
      </TruncateText>
      {!disabled && (
        <Box display="none" className="mcc-trail-icon">
          {added ? (
            <TrashBin height={20} width={20} />
          ) : (
            <PlusIcon height={20} width={20} />
          )}
        </Box>
      )}
    </StyledMerchantCategoryItem>
  );
};

const StyledMerchantCategoryItem = styled(Box)<
  BoxProps & { disabled?: boolean }
>(({ disabled }) => ({
  display: "flex",
  gap: "8px",
  alignItems: "center",
  padding: "4px",
  borderRadius: "4px",
  cursor: "pointer",
  "&:hover": {
    background: palette.neutral[5],
    "& .mcc-trail-icon": {
      display: "block",
    },
  },
}));

const CategoryCode = styled(Text)(() => ({
  padding: "4px",
  borderRadius: "4px",
  background: "#EBEBEB",
  fontSize: 12,
  fontWeight: 350,
  color: palette.neutral[80],
}));

export default EditEnterpriseMCCColumn;
