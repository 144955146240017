import { Box, Stack } from "@mui/material";
import Contact from "./components/Contact";
import { Controller, FormProvider } from "react-hook-form";
import Payment from "./components/Payment";
import GiveButton from "@shared/Button/GiveButton";
import GiveText from "@shared/Text/GiveText";
import { Lock } from "@phosphor-icons/react";
import { Separator } from "@components/ManageMoney/TransactionTable/TransactionInfoModal/Separator";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import GiveCheckbox from "@shared/GiveCheckbox/GiveCheckbox";
import useCheckoutPreview from "./hooks/useCheckoutPreview";
import GiveLink from "@shared/Link/GiveLink";
import { REFUND_POLICY_MODAL } from "modals/modal_names";
import NiceModal from "@ebay/nice-modal-react";
import SupportPreview from "./components/SupportPreview";
import { PRIVACY_POLICY_MODAL } from "modals/modal_names";
import GivePayment from "@assets/icons/GivePayment";
import Delivery from "./components/Delivery";
import { usePayBuilderForm } from "../provider/PayBuilderFormProvider";

const FEES = "10.5";

const CheckoutFormPreview = ({
  isSubmitButtonDisabled = true,
}: {
  isSubmitButtonDisabled?: boolean;
}) => {
  const { isDesktopView } = useCustomThemeV2();
  const { methods } = useCheckoutPreview();
  const { methods: leftSidepanelMethods, parsedValues } = usePayBuilderForm();

  const { render: isSupportEnabled } =
    leftSidepanelMethods.watch().Checkout.support;

  const { render: isDeliveryEnabled } =
    leftSidepanelMethods.watch().Checkout.delivery;

  const handleOpenRefundModal = () => {
    NiceModal.show(REFUND_POLICY_MODAL);
  };

  const openPrivacyModal = () => {
    NiceModal.show(PRIVACY_POLICY_MODAL);
  };

  return (
    <Box
      sx={{
        marginTop: "20px",
        // this should also be dynamic based on the viewport user chooses
        width: isDesktopView ? "717px" : "100%",
        backgroundColor: parsedValues.background,
        padding: isDesktopView ? "40px" : "20px",
        ...(isDesktopView && { borderRadius: "20px" }),
      }}
    >
      <FormProvider {...methods}>
        <Stack gap="48px">
          <Contact />
          {isDeliveryEnabled && <Delivery />}
          <Payment />
          {isSupportEnabled && <SupportPreview fees={FEES} />}
          <Stack direction="row" alignItems="center" spacing={1}>
            <Controller
              control={methods.control}
              name="termsConditions"
              render={({ field: { onChange, value } }) => (
                <GiveCheckbox
                  name="termsConditions"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            {/* TODO: open Terms and conditions */}
            <GiveText>
              I Accept GivePayments&apos;{" "}
              <GiveText sx={{ textDecoration: "underline" }} display="inline">
                Terms & Conditions
              </GiveText>
            </GiveText>
          </Stack>

          <GiveButton
            label="Pay Now"
            variant="filled"
            disabled={isSubmitButtonDisabled}
            sx={{
              borderRadius: "40px",
              padding: "16px",
              backgroundColor: parsedValues.accentColor,
              "&.MuiButtonBase-root:hover": {
                bgcolor: `${parsedValues.accentColor}1A`,
              },
            }}
          />
          <Stack
            direction={isDesktopView ? "row" : "column"}
            justifyContent={isDesktopView ? "space-between" : "center"}
            alignItems="center"
            spacing={isDesktopView ? 0 : 2}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Lock size={16} />
              <GiveText variant="bodyS" color="secondary">
                Transaction Secured and Encrypted
              </GiveText>
            </Stack>
            <GivePayment />
          </Stack>
          <Separator />
          <Stack direction="row" justifyContent="center" spacing={2}>
            <GiveLink onClick={handleOpenRefundModal}>Refund Policy</GiveLink>
            <GiveLink onClick={openPrivacyModal}>Privacy Policy</GiveLink>
          </Stack>
        </Stack>
      </FormProvider>
    </Box>
  );
};

export default CheckoutFormPreview;
