import { useEffect, useMemo, useRef, useState } from "react";
import { parseTransactionData } from "@components/ManageMoney/TransactionTable/hooks";
import { parseRow } from "@components/ManageMoney/TransactionTable/transactions.helpers";
import { toggleVirtualList } from "@redux/slices/app";
import { store } from "@redux/store";
import { useQueryClient } from "react-query";
import {
  VirtualizedList,
  items,
} from "@components/VirtualList/components/VirtualizedList";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { TRANSACTION_INFO_MODAL } from "modals/modal_names";
import { useIterator } from "@common/Table/hooks";
import { MainActionsHeader } from "./MainActionsHeader";
import { useFiltersRepository, useSorting } from "./hooks";
import { queryFunctionBuilder } from "./api";
import { useGetInfiniteTransactions } from "./hooks/queries";
import { useGetColumns } from "./columns";
import { ListWrapper } from "./wrappers/ListWrapper";
import { VirtualListLayoutWrapper } from "./wrappers/VlLayoutWrapper";
import { ListHeader } from "./components/ListHeader";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { MobileWraper } from "./wrappers/MobileWraper";
import { checkPortals } from "@utils/routing";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectQueryString } from "@redux/slices/search";
import { resetStatusFilter } from "@redux/slices/transactions";
import StickyWrapper from "@components/VirtualList/wrappers/StickyWrapper";
import { useGetCurrentMerchantId } from "@hooks/common";
import { isEmpty as isLoadashCheckEmpty, isFunction } from "lodash";
import { TListWrapperSection } from "@containers/types";
import { useLocation } from "react-router-dom";
import { Box, styled } from "@mui/material";
import { useDebouncedCallback } from "use-debounce";
import useCheckLastUnseen from "./hooks/useBlockedTransactions";
import { prefetchOffset } from "@components/Tranfers/hooks/useTransfersTable";

(window as any).enableVirtual = (val: boolean) => {
  store.dispatch(toggleVirtualList(val));
};

const parseData = (data: any, isPurchase?: boolean) =>
  parseTransactionData({ data }, isPurchase);

// Do not mind that is doing to much for now. There is a refactoring incoming on the next Pr
export const VirtualTransactionsTable = ({
  apiBuilder,
  isPurchase,
  queryKey,
  filters,
  overrideColumns,
  queryPath = "transactions",
  rowParser,
  DMenu,
  customScrollParent,
  stickyHeader,
  showActionHeader = true,
  gridProps,
  isBorderBottom,
  handleOnClick: onClick,
  emptyState = "transaction" as TListWrapperSection,
  merchantId: customMid,
  customParser,
  filter: customFilter,
  sortReduxKey,
  sortKeyValue = "-createdAt",
  style,
  tableType,
}: any) => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const { formattedFilter: filter, queryString } =
    useFiltersRepository(queryKey);
  const { isMerchantPortal, isAcquirerPortal } = checkPortals();
  const columns = useGetColumns(isMerchantPortal);
  const { merchantId: activeMerchantID } = useGetCurrentMerchantId();
  const merchantId = customMid || activeMerchantID;
  const page = useRef(1);
  const sort = useRef(sortKeyValue);
  const formattedFilter = customFilter || filter;

  const searchQuery = useAppSelector((state) =>
    selectQueryString(state, queryKey),
  );

  const dispatch = useAppDispatch();
  const { setUnseenTransactions } = useCheckLastUnseen(merchantId);

  useEffect(() => {
    if (isAcquirerPortal) setUnseenTransactions();
    return () => {
      dispatch(resetStatusFilter());
    };
  }, []);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    remove,
  } = useGetInfiniteTransactions(
    {
      queryString,
      rowsPerPage: 20,
      path: queryPath,
      sorting: sort.current,
      searchQuery,
      merchantId,
      filter: formattedFilter,
    },
    {
      queryKey: queryKey,
      enabled: Boolean(merchantId),
    },
  );

  const uiData = useMemo(() => {
    if (isFunction(customParser) && data?.pages[0].data)
      return customParser(data?.pages.map((row: any) => row.data).flat() ?? []);
    return data?.pages[0].data
      ? parseData(
          data?.pages.map((row: any) => row.data).flat() ?? [],
          isPurchase,
        ).data.map(isPurchase ? rowParser : parseRow)
      : [];
  }, [data, customParser]);
  const isDisabled =
    isLoadashCheckEmpty(uiData) && !isLoading && !isFetchingNextPage;
  const { selectedRowIdx, onChangeSelectedRowItem, setSelectedRowIdx } =
    useIterator({
      dataLen: uiData.length,
    });

  useEffect(() => {
    if (location.state && location.state.transactionData) {
      NiceModal.show(TRANSACTION_INFO_MODAL, {
        DMenu,
        data: location.state.transactionData,
        isFirst: true,
        isLast: true,
      });
    }
  }, [location]);

  const onEndReached = async () => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage({
        pageParam: ++page.current,
      }).then(() => {
        if (scrollToTop.current) {
          scrollToTop.current((data?.pages?.length ?? 1) * 20);
        }
      });
    }
  };

  useEffect(() => {
    //we are checking sooner than reaching last page, as fetching can take time, and it is possible to click next on the modal fast
    //by testing manually this seemed like the ideal 'offset'
    if (
      uiData[selectedRowIdx + prefetchOffset] === undefined &&
      hasNextPage &&
      modal.visible
    )
      onEndReached();
  }, [selectedRowIdx, uiData]);

  const [loading, setLoading] = useState(false);

  const handleOnClick = async (queryParams: any) => {
    if (isFetchingNextPage || isLoading || !merchantId) return;

    items.clear();
    setLoading(true);

    const thxKey = `thx-copy-${queryKey}`;
    try {
      await queryClient.cancelQueries({ queryKey: thxKey });
      const data = await queryClient.fetchQuery({
        queryKey: thxKey,
        queryFn: queryFunctionBuilder(queryParams),
      });

      queryClient.setQueryData([queryKey], {
        pageParams: [undefined],
        pages: [data],
      });

      queryClient.removeQueries([thxKey]);
    } catch (e) {
      // maybe show some error ?
    }
    setLoading(false);
  };

  const handleOnSort = (newSort: any) => {
    if (scrollToTop.current) {
      scrollToTop.current();
    }
    sort.current = newSort;
    page.current = 1;

    handleOnClick({
      queryString,
      page: 1,
      sorting: newSort,
      filter: formattedFilter,
      rowsPerPage: 20,
      path: queryPath,
      searchQuery,
      merchantId,
    });
  };

  const { onSort, order, sortKey } = useSorting(
    handleOnSort,
    "createdAt",
    sortReduxKey,
  );

  useEffect(() => {
    if (queryKey && merchantId) {
      handleOnClick({
        page: 1,
        rowsPerPage: 20,
        queryString,
        filter: formattedFilter,
        sorting: sort.current,
        path: queryPath,
        searchQuery: searchQuery,
        merchantId,
      });
    }
  }, [searchQuery, merchantId, formattedFilter, queryString]);

  useEffect(() => {
    return () => {
      remove();
    };
  }, []);

  useEffect(() => {
    if (scrollToTop.current) {
      scrollToTop.current();
    }
    page.current = 1;

    handleOnClick({
      queryString,
      page: 1,
      rowsPerPage: 20,
      filter: formattedFilter,

      sorting: sort.current,
      path: queryPath,
    });
  }, [formattedFilter, queryString]);

  const scrollToTop =
    useRef<(index?: number, align?: "center" | "start" | "end") => void>(null);

  const modal = useModal(TRANSACTION_INFO_MODAL);

  //to prevent extremely fast next clicking that could break the data flow
  const debouncedChangeRowItem = useDebouncedCallback(
    (newIdx: number | string, currentPage?: number, isOldList?: boolean) => {
      onChangeSelectedRowItem(newIdx, currentPage, isOldList);
    },
    400,
  );

  useEffect(() => {
    if (selectedRowIdx > -1) {
      NiceModal.show(TRANSACTION_INFO_MODAL, {
        DMenu,
        data: uiData[selectedRowIdx],
        setSelectedRow: debouncedChangeRowItem,
        isFirst: selectedRowIdx === 0,
        isLast: uiData[selectedRowIdx + 1] === undefined && !hasNextPage,
      });
    }
  }, [selectedRowIdx]);

  const clickRow = ({ index, row }: any) => {
    if (isFunction(onClick)) return onClick({ index, row });
    NiceModal.show(TRANSACTION_INFO_MODAL, {
      data: row,
      isFirst: index === 0,
      isLast: uiData[index + 1] === undefined && !hasNextPage,
      setSelectedRow: onChangeSelectedRowItem,
    });
    setSelectedRowIdx(index);
  };

  const { isDesktopView } = useCustomTheme();
  const isEmpty = uiData.length === 0;

  const loadedHeader =
    (!isEmpty && !isLoading && !loading) || searchQuery || sortKey;

  if (isDesktopView) {
    return (
      <>
        {loadedHeader && (
          <StickyWrapper isSticky={stickyHeader}>
            <VirtualListLayoutWrapper gridProps={gridProps} show>
              {showActionHeader && (
                <MainActionsHeader
                  tableType={tableType}
                  filters={filters}
                  queryKey={queryKey}
                  isDisabled={isDisabled}
                  filterParams={customFilter}
                />
              )}
              <StyledListHeader>
                <ListHeader
                  columns={overrideColumns ?? columns}
                  setSort={onSort}
                  sortKey={sortKey}
                  order={order}
                />
              </StyledListHeader>
            </VirtualListLayoutWrapper>
          </StickyWrapper>
        )}
        <ListWrapper
          dataLen={uiData.length}
          isLoading={isLoading || loading}
          emptyState={emptyState}
          queryKey={queryKey}
        >
          <VirtualizedList
            isBorderBottom={isBorderBottom}
            columns={overrideColumns ?? columns}
            data={uiData}
            isFetchingNextPage={isFetchingNextPage}
            onEndReached={onEndReached}
            scrollToTop={scrollToTop}
            clickRow={clickRow}
            selectedRowIdx={selectedRowIdx}
            customScrollParent={customScrollParent}
            gridProps={gridProps}
            style={style}
          />
        </ListWrapper>
      </>
    );
  }
  return (
    <MobileWraper
      apiBuilder={apiBuilder}
      rowParser={rowParser}
      dataQueryKey={queryKey}
      isPurchase={isPurchase}
      tableType={tableType}
      filters={filters}
      isDisabled={isDisabled}
      filterParams={customFilter}
    />
  );
};

const StyledListHeader = styled(Box)(() => ({
  "& .MuiGrid-root": {
    padding: "8px 2px !important",
  },
}));
