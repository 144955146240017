import { showMessage } from "@common/Toast";
import { useModal } from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createBusinessOwner,
  patchBusinessOwner,
} from "@services/api/business-owners";
import {
  convertPhoneNumber,
  isEmptyPhone,
  toUnixDateFormat,
} from "@utils/date.helpers";
import {
  addressSchema,
  dateOfBirthSchema,
  nonResidentInputsSchema,
  phoneSchema,
  ssnSchema,
} from "@utils/validation.helpers";
import { StatusValue } from "react-dropzone-uploader";
import { useEffect } from "react";
import {
  useUploadFiles,
  useUploadPresignedDocument,
} from "@hooks/upload-api/uploadHooks";
import { deleteDocument } from "@hooks/common/documents/utils";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { uniqueId } from "lodash";
import moment from "moment";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as Yup from "yup";
import { TBusinessOwner, TMerchantDocument } from "../data.types";
import { BusinessUnionTypes } from "@common/BusinessProfileInputs/BusinessTypeSelect";

type TinType = "ssn" | "ein";
type file = {
  fileWithMeta: any;
  status: StatusValue;
  allFiles: any;
};
type FormInputs = TBusinessOwner & {
  tinType: TinType;
  files?: file;
  uploadedfiles?: file;
};

type Props = {
  merchantId: number;
  totalOwnerships: number;
  data?: TBusinessOwner;
  legalEntityID?: number;
  onClose?: (data: TBusinessOwner) => void;
  idFile?: TMerchantDocument;
  primaryAccountHolder?: Partial<TBusinessOwner>;
  isIndividualSoleProprietorship?: boolean;
  businessType: BusinessUnionTypes;
};

export const useCreateMerchantBusinessOwner = ({
  merchantId,
  legalEntityID,
  data: businessOwner,
  totalOwnerships,
  onClose,
  idFile,
  primaryAccountHolder,
  isIndividualSoleProprietorship,
  businessType,
}: Props) => {
  const { isDesktopView } = useCustomTheme();
  const {
    handleUpload: handleUploadPresignedDocument,
    isLoading: loadingPresignedDocument,
  } = useUploadPresignedDocument();
  const { handleUpload, isLoading: uploadLoading } = useUploadFiles();
  const modal = useModal();
  const open = modal.visible;
  const queryClient = useQueryClient();
  const defaultValues = businessOwner
    ? generateDefaultValues(businessOwner)
    : defaultFormValues;

  const methods = useForm<FormInputs>({
    resolver: yupResolver(getSchema({ businessType })),
    defaultValues,
  });

  const {
    reset,
    setValue,
    formState: { dirtyFields },
    watch,
  } = methods;

  const handleCancel = () => modal.remove();

  useEffect(() => {
    if (
      primaryAccountHolder &&
      !businessOwner &&
      isIndividualSoleProprietorship
    ) {
      Object.entries(primaryAccountHolder).forEach(([key, value]) => {
        setValue(
          key as keyof FormInputs,
          value as FormInputs[keyof FormInputs],
          {
            shouldDirty: true,
          },
        );
      });
    }
  }, []);

  const handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue("isBusinessAddress", e.target.checked, { shouldDirty: true });
    setValue("address.country", "US", { shouldDirty: true });
  };

  const { files } = methods.watch();
  const uploadFilesAfter = async (data: any) => {
    let uploadRes;

    if (
      files?.allFiles?.length > 0 &&
      Object.keys(dirtyFields).includes("files")
    ) {
      uploadRes = await handleUpload({
        list: files?.allFiles,
        attachmentType: "legal_principal",
        merchantId: merchantId,
        resourceID: data?.id,
        label: "business owner document",
        tag: "business owner document",
        refetcherKey: ["get-bo-files"],
      });
    }
    if (uploadRes === "upload_failed")
      showMessage("Error", "Document upload failed.", isDesktopView);
  };

  const createBusinessOwnerMutation = useMutation(
    ({ leID, data }: { leID: number; data: any }) => {
      return businessOwner?.id
        ? patchBusinessOwner(leID, businessOwner.id, data, merchantId)
        : createBusinessOwner(leID, data, merchantId);
    },
    {
      onSuccess: async (data) => {
        await uploadFilesAfter(data);
      },
      onError: (error: unknown) => {
        const defaultMessage = "An error occured. Please try again";
        const errorObj = (error as any)?.response?.data;
        const apiError = errorObj?.input?.length
          ? errorObj?.input[0]?.message
          : errorObj?.message;

        showMessage("Error", apiError || defaultMessage);
      },
      onSettled: () => {
        queryClient.refetchQueries(["get-merchant-preview", merchantId]);
        queryClient.invalidateQueries(["get-merchant-msp-status", merchantId]);
        queryClient.invalidateQueries("list-business-owners");
        queryClient.invalidateQueries("pep-checks-history");
        queryClient.invalidateQueries(["list-ofac-checks", merchantId]);
        queryClient.invalidateQueries(["get-bo-files", merchantId]);
        modal.remove();
      },
    },
  );

  const isConfirmMatch = businessOwner?.pepStatusName === "confirmed_match";

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const computedOwnership =
      +(data?.ownership || 0) +
      totalOwnerships -
      +(businessOwner?.ownership || 0);

    if (computedOwnership > 100) {
      showMessage("Info", "Ownership sum cannot exceed 100%");
      return;
    }

    if (data?.uploadedfiles && idFile) {
      deleteDocument(
        merchantId,
        {
          fileName: idFile.fileName,
          id: idFile.id,
        },
        () => {
          queryClient.invalidateQueries(["get-merchant-preview", merchantId]);
          queryClient.invalidateQueries(["get-bo-files", merchantId]);
        },
        {
          hideModal: true,
        },
      );
    }
    // if file doesnt have meta field, that means the file is from BE, so no upload required
    const isLocalFile = !!data?.files?.allFiles[0]?.meta;
    //the flow for adding BO on merchant/provider create
    if (
      onClose &&
      isLocalFile &&
      !businessOwner?.createdAt &&
      data?.files?.allFiles?.length > 0 &&
      data?.files?.allFiles[0]?.meta?.previewUrl !=
        businessOwner?.files?.allFiles[0]?.meta?.previewUrl
    ) {
      const presignedUploadRes = await handleUploadPresignedDocument({
        list: data.files?.allFiles,
        attachmentType: "legal_principal",
      });
      const ownerData = {
        ...data,
        id: businessOwner?.id || uniqueId(),
        ...(!businessOwner?.createdAt &&
          Array.isArray(presignedUploadRes) && {
            idImageUrl: presignedUploadRes[0],
          }),
      };

      onClose(ownerData);
      reset(businessOwner?.id ? ownerData : (defaultValues as any));
      modal.remove();
      if (presignedUploadRes === "upload_failed")
        showMessage("Error", "Document upload failed.", isDesktopView);
      return;
    }
    if (!legalEntityID) return;

    const payloadOnConrfimedMatch = {
      ownershipPercentage: data.ownership ? +data.ownership : null,
    };
    const d1 = new Date(data.dob);
    const d2 = new Date(defaultValues.dob);
    const isDateChanged = !(
      moment(d1).isSame(d2, "day") && !moment(d1).isSame(d2, "minute")
    );
    const defaultPayload = {
      ...(dirtyFields.firstName && {
        firstName: data.firstName,
      }),
      ...(dirtyFields.lastName && {
        lastName: data.lastName,
      }),
      ...(dirtyFields.phone && {
        phoneNumber: data.phone ? convertPhoneNumber(data.phone) : null,
      }),
      ...(dirtyFields.email && {
        email: data.email || null,
      }),
      ...(isDateChanged && {
        dateOfBirth: data.dob ? toUnixDateFormat(new Date(data.dob)) : null,
      }),

      ...((dirtyFields.citizenship || dirtyFields.isNotUSResident) && {
        citizenship: data?.isNotUSResident ? data.citizenship : "",
      }),

      ...((dirtyFields.countryOfResidence ||
        dirtyFields.isNotResidentInCitizenshipCountry) && {
        countryOfResidence: data.isNotResidentInCitizenshipCountry
          ? data.countryOfResidence
          : "",
      }),
      ...(!data.isBusinessAddress &&
        data.address &&
        (dirtyFields.address || dirtyFields.isBusinessAddress) && {
          address: {
            name: data.address.line1,
            line1: data.address.line1,
            line2: "",
            city: data.address.city,
            state: data.address.state,
            zip: data.address.zip,
            country: data.address.country,
          },
        }),
      ...(dirtyFields.isBusinessAddress && {
        useEntityGeoAddress: data.isBusinessAddress,
      }),
      ownershipPercentage: data.ownership ? +data.ownership : null,
    } as any;

    const tinType = watch("tinType");

    if (tinType === "ein") {
      if (dirtyFields.ein) {
        defaultPayload.ein = data.ein;
      }
    } else if (tinType === "ssn") {
      if (dirtyFields.ssn) {
        defaultPayload.taxIDNumber = data.ssn;
      }
    }

    const payload = isConfirmMatch ? payloadOnConrfimedMatch : defaultPayload;

    createBusinessOwnerMutation.mutate({
      leID: legalEntityID,
      data: {
        ...payload,
      },
    });
  };

  return {
    open,
    methods,
    handleCancel,
    onSubmit,
    isLoading:
      createBusinessOwnerMutation.isLoading ||
      uploadLoading ||
      loadingPresignedDocument,
    handleChangeAddress,
  };
};

const defaultFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  ownership: "10",
  tinType: "ssn" as TinType,
  ssn: "",
  ein: "",
  dob: "",
  phone: "",
  isNotUSResident: false,
  citizenship: "",
  isNotResidentInCitizenshipCountry: false,
  countryOfResidence: "",

  isBusinessAddress: false,
  address: {
    country: "US",
    line1: "",
    city: "",
    state: "",
    zip: "",
  },
  files: {
    allFiles: [],
  },
};

const getSchema = ({ businessType }: { businessType: string }) =>
  Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Not valid email").required("Email is required "),
    ownership: Yup.string().test(
      "is-greater-than-0",
      "Ownership must be greater than 0",
      (value) => {
        const isTaxExemptOrGovtAgency = [
          "tax_exempt_organization",
          "government_agency",
        ].includes(businessType);

        return (
          value !== undefined &&
          (Number(value || 0) > 0 || isTaxExemptOrGovtAgency)
        );
      },
    ),
    tinType: Yup.mixed<TinType>().oneOf(["ssn", "ein"]),
    ssn: Yup.string().when("tinType", {
      is: "ssn",
      then: ssnSchema("ssn"),
    }),
    ein: Yup.string().when("tinType", {
      is: "ein",
      then: ssnSchema("ein"),
    }),
    dob: dateOfBirthSchema(),
    phone: phoneSchema(),
    isBusinessAddress: Yup.boolean(),
    address: addressSchema("isBusinessAddress"),
    ...nonResidentInputsSchema,
    files: Yup.object().shape({
      fileWithMeta: Yup.object().unknown(true),
      status: Yup.string(),
      allFiles: Yup.array()
        .of(Yup.object())
        .min(1, "Business Owner ID is required"),
    }),
  });

export const formatOwnersDateUTC = (date: Date, isMobile: boolean) => {
  if (isMobile) {
    return moment(date).format("MMM D, YYYY");
  } else {
    return moment(date).utc().format("MMM D, YYYY, h:mm:ss A [UTC]");
  }
};

const generateDefaultValues = (data?: TBusinessOwner) => {
  return {
    ...data,
    tinType: data?.tinType === "ein" ? "ein" : ("ssn" as TinType),
    ssn: data?.tinType === "ssn" ? data?.ssn : "",
    ein: data?.tinType === "ein" ? data?.ein : "",
    dob: data?.dob ? data?.dob : "",
    phone: isEmptyPhone(data?.phone)
      ? ""
      : `+${convertPhoneNumber(data?.phone || "")}`,
    files: {
      allFiles: [],
    },
  };
};
