import { BankAccountTagType } from "@common/Tag/BankAccountTag";
import GiveChip from "@shared/Chip/GiveChip";
import { memo } from "react";

type TChip = BankAccountTagType | "update_requested";

type Props = {
  accountStatus?: TChip;
};

const GiveBAStatusChip = ({ accountStatus = "pending" }: Props) => {
  const { color, label } = BAStatus[accountStatus];

  return (
    <GiveChip
      variant="light"
      size="small"
      color={color}
      label={label}
      sx={{ marginRight: "5px", maxHeight: "22px" }}
    />
  );
};

type StatusObject = {
  label: string;
  color: "default" | "success" | "warning" | "error";
};

const BAStatus: Record<TChip, StatusObject> = {
  pending: {
    label: "Pending",
    color: "default",
  },
  approved: {
    label: "Approved",
    color: "success",
  },
  declined: {
    label: "Declined",
    color: "error",
  },
  upload: {
    label: "Upload",
    color: "warning",
  },
  update_requested: {
    label: "Update Required",
    color: "warning",
  },
};

export default memo(GiveBAStatusChip);
