import { useMutation, useQueryClient } from "react-query";
import { useMerchantSidePanelContext } from "../Provider/MerchantSidePanelProvider";
import { customInstance } from "@services/api";
import { MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS } from "../constants";
import { useResyncMSP } from "@components/Merchants/MerchantPreview/hooks/useResyncMSP";
import { showMessage } from "@common/Toast";
import { useUpdateUnderwriting } from "@components/Merchants/MerchantPreview/hooks/useUnderwritingUpdate";
import { QKEY_LIST_MERCHANT_STATS } from "@constants/queryKeys";
import { MerchantPayload } from "./hook.types";

export default function useUpdateMerchant() {
  const { id, data } = useMerchantSidePanelContext();
  const queryClient = useQueryClient();

  const handleSuccess = () => {
    queryClient.refetchQueries(MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS.GET);
  };
  const updateMerchantMutation = useMutation(
    (payload: Partial<MerchantPayload>) => {
      return customInstance({
        url: `/merchants/${id}`,
        method: "PATCH",
        data: payload,
      });
    },
    {
      onSuccess() {
        handleSuccess();
      },
    },
  );

  const updateMerchantUnderwriter = useMutation(
    (payload: { underwriterID: number }) => {
      return customInstance({
        url: `/merchants/${id}/underwriting`,
        method: "PATCH",
        data: payload,
      });
    },
    {
      onSuccess() {
        handleSuccess();
      },
    },
  );
  const { isLoading: isReSyncMSPLoading, handleResyncMSP } = useResyncMSP(
    data.status.accID,
  );

  const { updateUnderwriting } = useUpdateUnderwriting(data.status.accID);

  const refreshMerchantData = () => {
    queryClient.invalidateQueries("get-merchant-msp-status");
    queryClient.refetchQueries(["get-merchant-preview", data.status?.accID]);
    queryClient.invalidateQueries(QKEY_LIST_MERCHANT_STATS);
  };

  const handleSuspendMutation = () => {
    updateUnderwriting.mutate(
      { status: "suspended" },
      {
        onSuccess: refreshMerchantData,
        onError: (err: any) => {
          showMessage("Error", err?.response?.data?.message);
        },
      },
    );
  };

  const handleApproveMutation = () => {
    updateUnderwriting.mutate(
      { status: "approved" },
      {
        onSuccess: refreshMerchantData,
        onError: (err: any) => {
          showMessage("Error", err?.response?.data?.message);
        },
      },
    );
  };

  const handleResumeMutation = () => {
    updateUnderwriting.mutate(
      { status: "preapproved_no_tx" },
      {
        onSuccess: refreshMerchantData,
        onError: (err: any) => {
          showMessage("Error", err?.response?.data?.message);
        },
      },
    );
  };

  return {
    updateMerchantMutation,
    isReSyncMSPLoading,
    handleResyncMSP,
    updateMerchantUnderwriter,
    handleSuspendMutation,
    handleApproveMutation,
    handleResumeMutation,
    isApproveSuspendLoading: updateUnderwriting.isLoading,
  };
}
