import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";
import { Button } from "@common/Button";
import { Text } from "@common/Text";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { Box, CircularProgress, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import moment from "moment";
import {
  TBusinessOwner,
  TOFACLastCheckType,
  TPrimaryAccountHolder,
} from "../../data.types";
import { OFACCheckType, useRunOFAC } from "../hooks/useOFAC";
import { useMemo, SetStateAction, Dispatch } from "react";
import OFACTabs from "./OFACTabs";
import CheckItem from "./CheckItem";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { OFACTabType, TabStatuses } from "../helpers";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";

type Props = {
  merchantId: number;
  legalEntityId: number;
  legalName: string;
  lastCheckBP?: TOFACLastCheckType;
  openDetails: (checkId?: number) => void;
  isLoading: boolean;
  check?: OFACCheckType[];
  isRunOfacAllowed: boolean;
  isEditOfacAllowed: boolean;
  isEnterprise: boolean;
  activeTab: OFACTabType;
  setActiveTab: (tab: OFACTabType) => void;
  setCurrentBusinessOwnerIndex: Dispatch<SetStateAction<number>>;
  currentBusinessOwnerIndex: number;
  PAH: TPrimaryAccountHolder;
  businessOwners?: TBusinessOwner[];
  tabStatuses?: TabStatuses;
};

const THIRTY_DAYS_IN_SECONDS = 2626250;

const OFACScreening = ({
  openDetails,
  merchantId,
  legalEntityId,
  legalName,
  lastCheckBP,
  isLoading,
  check,
  isRunOfacAllowed,
  isEditOfacAllowed,
  isEnterprise,
  activeTab,
  setActiveTab,
  setCurrentBusinessOwnerIndex,
  currentBusinessOwnerIndex,
  PAH,
  businessOwners,
  tabStatuses,
}: Props) => {
  const { isNewOfacTabsEnabled } = useGetFeatureFlagValues();
  const { runOFACHandler, isLoading: loadingRun } = useRunOFAC(
    merchantId,
    isEnterprise,
    isNewOfacTabsEnabled ? undefined : legalEntityId,
  );
  const { merchant_underwriting } = useEnterprisePermissions();

  const lastCheckBusinessProfile = useMemo(() => {
    const regularCheck = check && check[0];
    if (
      !regularCheck ||
      regularCheck.updatedAt === lastCheckBP?.lastCheckAt ||
      activeTab !== "Business Profile"
    ) {
      return {
        ...lastCheckBP,
        resourceID: legalEntityId,
        ...(lastCheckBP?.lastCheckAt && {
          nextCheckAt: lastCheckBP.lastCheckAt + THIRTY_DAYS_IN_SECONDS,
        }),
        name: legalName,
      };
    }

    return {
      lastCheckStatusName: regularCheck.statusName,
      lastCheckAt: regularCheck.createdAt,
      nextCheckAt: regularCheck.createdAt + THIRTY_DAYS_IN_SECONDS,
      resourceID: regularCheck.resourceID,
      name: legalName,
    };
  }, [check]);

  const lastCheckPAH = useMemo(() => {
    const regularCheck = check && check[0];
    const name = `${PAH.firstName} ${PAH.lastName}`;
    if (
      !regularCheck ||
      regularCheck.createdAt === PAH?.ofac?.lastCheckAt ||
      activeTab !== "Primary Account Holder"
    ) {
      return {
        ...PAH?.ofac,
        name,
        resourceID: PAH.id,
        nextCheckAt: PAH?.ofac?.lastCheckAt + THIRTY_DAYS_IN_SECONDS,
      };
    }

    return {
      lastCheckStatusName: regularCheck.statusName,
      lastCheckAt: regularCheck.createdAt,
      nextCheckAt: regularCheck.createdAt + THIRTY_DAYS_IN_SECONDS,
      resourceID: regularCheck.resourceID,
      name,
    };
  }, [check]);

  const lastCheckBO = useMemo(() => {
    if (activeTab === "Business Owner") {
      let formattedChecks;
      if (check && check.length > 0) {
        formattedChecks = check?.map((item) => {
          return {
            lastCheckStatusName: item?.statusName,
            lastCheckAt: item.createdAt,
            nextCheckAt: item.createdAt + THIRTY_DAYS_IN_SECONDS,
            lastCheckID: item.ID,
            resourceID: item.resourceID,
            name: item?.resourceFullName,
            ownership: item?.principalOwnershipPercentage,
            isNRA:
              !!item?.resourceCitizenship && item?.resourceCitizenship !== "US",
          };
        });
      } else {
        formattedChecks = businessOwners?.map((item) => {
          return {
            lastCheckStatusName: item?.ofac?.lastCheckStatusName,
            lastCheckAt: item.ofac?.lastCheckAt,
            nextCheckAt: item.ofac?.lastCheckAt + THIRTY_DAYS_IN_SECONDS,
            lastCheckID: undefined,
            resourceID: parseInt(item.id),
            name: `${item.firstName} ${item.lastName}`,
            ownership: item.ownership ? parseInt(item.ownership) : 0,
            isNRA: !!item.citizenship && item.citizenship !== "US",
          };
        });
      }
      return formattedChecks;
    }
  }, [check, activeTab]);

  const lastCheck =
    activeTab === "Business Owner" && lastCheckBO
      ? lastCheckBO[currentBusinessOwnerIndex]
      : activeTab === "Business Profile"
      ? lastCheckBusinessProfile
      : lastCheckPAH;

  const nextCheckMap = {
    clear: (
      <>
        OFAC Check will run automatically on a monthly basis. <br />
        Next check {lastCheck?.nextCheckAt && nextCheck(lastCheck?.nextCheckAt)}
      </>
    ),
    possible_match: "New checks are disabled until match is cleared",
    confirmed_match: (
      <>
        {activeTab} cannot be edited until match is cleared <br />
        <br />
        New checks are disabled until match is cleared
      </>
    ),
    manually_cleared: (
      <>
        OFAC Check will run automatically on a monthly basis. <br />
        Next check {lastCheck?.nextCheckAt && nextCheck(lastCheck?.nextCheckAt)}
      </>
    ),
  };

  const fallbackCheckId =
    activeTab === "Business Profile"
      ? lastCheckBP?.lastCheckID
      : PAH?.ofac?.lastCheckID;
  const checkId = check ? check[0]?.ID : fallbackCheckId;
  const isClearBO =
    lastCheckBO &&
    ["clear", "manually_cleared", ""].includes(
      lastCheckBO[currentBusinessOwnerIndex].lastCheckStatusName,
    );
  const isClearStatus =
    activeTab === "Business Owner"
      ? isClearBO
      : !lastCheck?.lastCheckStatusName ||
        ["clear", "manually_cleared", ""].includes(
          lastCheck?.lastCheckStatusName,
        );
  return (
    <Stack spacing={3} pt={2}>
      <FadeUpWrapper delay={100}>
        <Text fontWeight="book" color={palette.neutral[70]}>
          {`An OFAC check, or Office of Foreign Assets Control check, involves
        screening individuals and entities against the denied party lists
        maintained by the United States Treasury Department's Office of Foreign
        Assets Control.`}
        </Text>
        <OFACTabs
          activeTab={activeTab}
          setActiveTab={(tab) => {
            setActiveTab(tab);
            if (currentBusinessOwnerIndex !== 0)
              setCurrentBusinessOwnerIndex(0);
          }}
          hasBusinessProfile={!!legalEntityId}
          hasPAH={!!PAH?.email}
          hasPAHData={!!PAH?.firstName && !!PAH.lastName}
          tabStatuses={tabStatuses}
          hasBusinessOwner={!!businessOwners && businessOwners.length > 0}
        />
      </FadeUpWrapper>
      <FadeUpWrapper delay={150}>
        <StyledBox>
          {activeTab === "Business Owner" ? (
            <>
              {lastCheckBO && lastCheckBO.length > 1 && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mb="8px"
                >
                  <Text color={palette.neutral[80]} fontSize="12px">
                    {currentBusinessOwnerIndex + 1} of {lastCheckBO?.length}
                  </Text>
                  <Stack direction="row">
                    <IconButton
                      disabled={currentBusinessOwnerIndex === 0}
                      data-testid="OFAC-BO-prev"
                      onClick={() =>
                        setCurrentBusinessOwnerIndex((prev) => prev - 1)
                      }
                    >
                      <CaretLeft color={palette.neutral[70]} />
                    </IconButton>
                    <IconButton
                      disabled={
                        currentBusinessOwnerIndex + 1 === lastCheckBO?.length
                      }
                      data-testid="OFAC-BO-next"
                      onClick={() =>
                        setCurrentBusinessOwnerIndex((prev) => prev + 1)
                      }
                    >
                      <CaretRight color={palette.neutral[70]} />
                    </IconButton>
                  </Stack>
                </Stack>
              )}
              <Stack
                sx={{
                  maxHeight: "250px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {lastCheckBO && (
                  <CheckItem
                    lastCheck={lastCheckBO[currentBusinessOwnerIndex]}
                    openDetails={openDetails}
                    checkId={lastCheckBO[currentBusinessOwnerIndex].lastCheckID}
                    isEditOfacAllowed={isEditOfacAllowed}
                    name={lastCheckBO[currentBusinessOwnerIndex].name}
                    ownershipPercentage={
                      lastCheckBO[currentBusinessOwnerIndex].ownership || 0
                    }
                    isNonResident={lastCheckBO[currentBusinessOwnerIndex].isNRA}
                  />
                )}
              </Stack>
            </>
          ) : (
            <CheckItem
              lastCheck={lastCheck}
              name={lastCheck.name}
              openDetails={openDetails}
              checkId={checkId}
              isEditOfacAllowed={isEditOfacAllowed}
              isNonResident={
                activeTab === "Primary Account Holder"
                  ? PAH?.isNotUSResident
                  : false
              }
            />
          )}
        </StyledBox>
      </FadeUpWrapper>
      <FadeUpWrapper delay={200}>
        <Stack spacing={2} alignItems="center">
          <Text color={palette.neutral[80]} textAlign="center">
            {nextCheckMap[lastCheck?.lastCheckStatusName || "clear"]}
          </Text>
          {!isLoading && merchant_underwriting && isClearStatus && (
            <CustomToolTip
              message="You do not have the right permissions to perform this action"
              showToolTip={!isRunOfacAllowed}
              placement="top"
            >
              <Button
                size="medium"
                sx={{
                  display: "inline-flex",
                  width: "fit-content",
                  padding: "8px 24px",
                }}
                onClick={() => {
                  runOFACHandler(
                    activeTab,
                    activeTab === "Business Owner" && lastCheckBO
                      ? lastCheckBO[currentBusinessOwnerIndex].resourceID
                      : lastCheck.resourceID,
                  );
                }}
                disabled={loadingRun || !isRunOfacAllowed}
                endIcon={
                  loadingRun ? (
                    <CircularProgress
                      size="20px"
                      data-testid="Circular-Progress"
                      sx={{
                        color: palette.neutral.black,
                        opacity: "1 !important",
                      }}
                    />
                  ) : undefined
                }
                data-testid="ofac-check-now-button"
              >
                {loadingRun ? "Checking..." : "Check now"}
              </Button>
            </CustomToolTip>
          )}
        </Stack>
      </FadeUpWrapper>
    </Stack>
  );
};

const nextCheck = (createdAt: number) =>
  moment.unix(createdAt).utc().format("MM/DD/YYYY");

export default OFACScreening;

const IconButton = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "disabled",
})<{ disabled: boolean }>(({ disabled }) => ({
  borderRadius: "50%",
  ...(!disabled && {
    ":hover": {
      backgroundColor: palette.neutral[10],
      cursor: "pointer",
    },
  }),
  ...(disabled && {
    pointerEvents: "none",
    opacity: 0.5,
  }),
  height: "28px",
  width: "28px",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledBox = styled(Box)({
  borderRadius: "8px",
  boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.10)",
});
