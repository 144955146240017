import { Stack } from "@mui/system";
import GiveText from "@shared/Text/GiveText";
import { styled } from "@theme/v2/Provider";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import pl from "@assets/images/widgetImagePlaceholder.png";
import { addSizeToImage } from "@utils/image.helpers";
import ImagePlaceholder from "@assets/images/thumbnail-placeholder.png";

const HeroSection = () => {
  const { isMobileView } = useCustomThemeV2();
  const { parsedValues } = usePayBuilderForm();

  const url = parsedValues.selectedImage?.URL;
  const position = parsedValues.assetPosition;
  const direction = isMobileView
    ? "column"
    : position === "left"
    ? "row"
    : "row-reverse";

  const original = addSizeToImage(url || "", "original", ImagePlaceholder);

  return (
    <StyledStack direction={direction} spacing={3} isMobile={isMobileView}>
      <Stack sx={{ width: isMobileView ? "100%" : "50%" }}>
        {url ? (
          <StyledImage src={original} alt="Hero" />
        ) : (
          <StyledImage src={pl} alt="Hero" />
        )}
      </Stack>
      <TextContainer sx={{ width: isMobileView ? "100%" : "50%" }}>
        <GiveText variant="h2" color="primary">
          {parsedValues.heading || "Heading"}
        </GiveText>
        <GiveText variant="bodyS" color="secondary">
          {parsedValues.description || "Description"}
        </GiveText>
      </TextContainer>
    </StyledStack>
  );
};

export default HeroSection;

const StyledStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  alignItems: "center",
  padding: "64px 0",
  ...(isMobile && {
    paddingTop: 0,
  }),
}));

const StyledImage = styled("img")({
  height: "302px",
  maxHeight: "302px",
  maxWidth: "100%",
  borderRadius: "8px",
  objectFit: "cover",
  width: "100%",
  flex: 1,
});

const TextContainer = styled(Stack)({
  flex: 1,
  gap: "16px",
  width: "100%",
  overflow: "hidden",
  wordWrap: "break-word",
  textOverflow: "ellipsis",
});
