import { Text } from "@common/Text";
import { Stack } from "@mui/material";
import OFACStatus from "../OFAC/components/OFACStatus";
import { FormProvider, useForm } from "react-hook-form";
import { palette } from "@palette";
import { MATCHFormFields, ReportType } from "./types";
import ReportFields from "./ReportFields";
import { useGetSingleMATCHReport } from "./hooks/useMATCHReports";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { useEffect } from "react";

interface Props {
  merchantID: number;
  report?: ReportType;
}

export default function ReportView({ merchantID, report }: Props) {
  const { data, isLoading } = useGetSingleMATCHReport(merchantID, report?.ID);
  const methods = useForm<MATCHFormFields>({
    defaultValues: {
      status: report?.statusName || data?.statusName,
      findings: report?.findings || data?.findings,
      files: report?.files || data?.files,
    },
  });

  useEffect(() => {
    if (data)
      methods.reset({
        status: data.statusName,
        findings: data.findings,
        files: data.files,
      });
  }, [data]);

  const { status, files } = methods.watch();
  if (isLoading) return <LoadingSpinner />;
  return (
    <>
      <Stack direction="row" alignItems="center" gap="8px">
        <Text color={palette.neutral[80]}>Status:</Text>
        <OFACStatus status={status} />
      </Stack>
      <Stack flex={1} overflow="auto">
        <FormProvider {...methods}>
          <ReportFields viewOnly files={files} merchantID={merchantID} />
        </FormProvider>
      </Stack>
    </>
  );
}
