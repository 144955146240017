import { memo, useMemo } from "react";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { Button, Box, Stack, styled } from "@mui/material";
import { TBusinessOwner, TMerchantDocument } from "../../data.types";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { BriefcaseIcon } from "@assets/icons/RebrandedIcons";
import { EditButton } from "../atoms";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  CREATE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { ConversationsIconNotFilled } from "@assets/icons";
import BusinessOwnerItem from "@common/BusinessOwners/BusinessOwnerItem";
import useBusinessOwner from "@components/Settings/ManageTeam/BusinessOwners/useBusinessOwner";
import { fromUnixTime } from "date-fns";
import { TPEPOwner } from "../../PEP/types";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { BusinessUnionTypes } from "@common/BusinessProfileInputs/BusinessTypeSelect";

type Props = {
  data: TBusinessOwner[];
  merchantId?: number;
  legalEntityID?: number;
  isApprovedLE?: boolean;
  businessType: string;
  isIndividualSoleProprietorship?: boolean;
  onCloseModal?: (data?: TBusinessOwner, id?: string) => void;
  selectBusinessOwner?: (data: TPEPOwner) => void;
  withPepStatus?: boolean;
  isController?: boolean;
  isAcquirer?: boolean;
  isEnterprise?: boolean;
  isBPLinked?: boolean;
  documents?: TMerchantDocument[];
  isMerchant?: boolean;
  primaryAccountHolder?: Partial<TBusinessOwner>;
  selectedPEPOwner?: TPEPOwner;
};

const MerchantBusinessOwners = (prop: Props) => {
  const {
    data,
    merchantId,
    legalEntityID,
    isApprovedLE,
    businessType,
    onCloseModal,
    isIndividualSoleProprietorship,
    selectBusinessOwner,
    withPepStatus = false,
    isController,
    isEnterprise = false,
    isBPLinked,
    isAcquirer,
    documents,
    isMerchant,
    primaryAccountHolder,
    selectedPEPOwner = null,
  } = prop;
  const ownersLength = data?.length || 0;
  const createDisabled =
    businessType === "individual_sole_proprietorship" && ownersLength >= 1;

  const totalOwnerships = useMemo(() => {
    return (
      data?.reduce((acc, owner) => {
        const _ownership = owner.ownership ? +owner.ownership : 0;
        return acc + _ownership;
      }, 0) || 0
    );
  }, [data]);
  const { addOwnerHandler, editOwnerHandler } = useBusinessOwner({
    stakeSum: totalOwnerships,
    isApprovedLE,
    hasMaxOwners:
      (isIndividualSoleProprietorship && ownersLength >= 1) || false,
    legalEntityID,
    isController: isController || false,
    showTag: true,
    merchantId,
    onCloseModal,
    isSoleProprietorship: isIndividualSoleProprietorship,
    isBPLinked,
    businessType,
    documents,
    isMerchant,
  });

  const { openConversationHandler } = useConversationsModal();
  const { merchant_underwriting } = useEnterprisePermissions();
  const sectionTitle = "Business owners";
  const onClick = () =>
    openConversationHandler({ id: 0, name: "Business Owners", paths: [] });

  const onClickAddOwner = () => {
    const modalProps = {
      merchantId,
      onClose: onCloseModal,
      isIndividualSoleProprietorship,
      businessType: businessType as BusinessUnionTypes,
      primaryAccountHolder:
        primaryAccountHolder && isIndividualSoleProprietorship
          ? primaryAccountHolder
          : undefined,
    };
    addOwnerHandler({ modalProps });
  };

  return (
    <Stack
      spacing={2}
      sx={{
        "&:hover": {
          "& .MuiButtonBase-root.MuiButton-root": {
            visibility: "visible",
          },
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Text fontSize={18} color={palette.neutral[70]}>
          {sectionTitle}
        </Text>
        <Stack direction="row" alignItems="center" gap={1}>
          {merchant_underwriting && isAcquirer && (
            <EditButton onClick={onClick}>
              <ConversationsIconNotFilled section="business-owner" />
            </EditButton>
          )}

          <AddOwnerButton
            disabled={createDisabled}
            onClick={onClickAddOwner}
            isEnterprise={isEnterprise}
          />
        </Stack>
      </Box>
      <Stack spacing={1}>
        {data?.map((owner) => (
          <BusinessOwnerItem
            idFile={owner.files?.allFiles[0] || getIDFile(owner.id, documents)}
            merchantId={merchantId}
            key={owner.id}
            owner={adjustAddressType(owner)}
            selectBusinessOwner={selectBusinessOwner}
            onEdit={(event) =>
              editOwnerHandler(event, adjustAddressType(owner))
            }
            withPepStatus={withPepStatus}
            isSelectedPEP={selectedPEPOwner?.id === owner.id}
            isEnterprise={isEnterprise}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export const adjustAddressType = (owner: TBusinessOwner) => {
  const { id, dob, businessAddress, address, isBusinessAddress, ownership } =
    owner;

  const customAddress = {
    city: address?.city || businessAddress?.city,
    country: address?.country || businessAddress?.country,
    street: address?.line1 || businessAddress?.line1,
    zip: address?.zip || businessAddress?.zip,
    province: address?.state || businessAddress?.state,
  };
  const { city, country, street, zip, province } = customAddress;

  const dateOfBirth =
    typeof dob === "string"
      ? new Date(dob)
      : typeof dob === "number"
      ? fromUnixTime(dob)
      : dob;
  return {
    ...owner,
    id: parseInt(id),
    dob: dateOfBirth,
    businessAddress: {
      city: city || "",
      country: country || "US",
      street: street || "",
      zip: zip || "",
      province: province || "",
    },
    useBusinessAddress: isBusinessAddress || false,
    ownership: ownership || "0",
  };
};

export const getIDFile = (ownerID: any, documents?: TMerchantDocument[]) => {
  return documents && documents.find((doc) => ownerID === doc.legalPrincipalID);
};

const AddOwnerButton = ({
  disabled,
  isEnterprise,
  ...props
}: {
  disabled: boolean;
  onClick: () => void;
  isEnterprise: boolean;
}) => {
  const { isMobileView } = useCustomTheme();

  const isAddAllowed = useAccessControl({
    resource: composePermission(
      isEnterprise ? RESOURCE_BASE.ENTERPRISE : RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.LEGAL_ENTITY,
      RESOURCE_BASE.PRINCIPAL,
    ),
    operation: OPERATIONS.CREATE,
  });
  const isDisabled = disabled || !isAddAllowed;

  if (isMobileView) {
    <EditButton {...props} disabled={isDisabled}>
      <BriefcaseIcon fill={palette.black[100]} />
    </EditButton>;
  }
  return (
    <CustomToolTip showToolTip={!isAddAllowed} message={CREATE_DENY_MESSAGE}>
      <StyledButton size="small" {...props} disabled={isDisabled}>
        <Text color={palette.black[100]}>Add Business Owner</Text>
      </StyledButton>
    </CustomToolTip>
  );
};

const StyledButton = styled(Button)(() => ({
  borderRadius: "32px",
  border: `1px solid ${palette.neutral[70]}`,
  background: palette.neutral[10],
  boxShadow: "none",
  userSelect: "none",
  "&:hover": {
    background: palette.neutral[10],
  },
}));

export default memo(MerchantBusinessOwners);
