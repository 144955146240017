import React, { useState } from "react";
import { styled } from "@theme/v2/Provider";
import DrawerAccountSelect from "features/Drawer/DrawerAccountSelect";
import { Divider as DividerMui, Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { useMenu } from "./hooks/useMenu";
import SideMenuItem from "./components/SideMenuItem";
import UserSection from "./components/UserSection";
import MerchantAccountSection from "./components/MerchantAccountSection";
import { useNavigate } from "react-router-dom";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import NiceModal from "@ebay/nice-modal-react";
import { CREATE_PRE_BUILD_FORM_MODAL } from "modals/modal_names";

type TParams = {
  open: boolean;
  availableBalance: string;
  handleDrawerClose: () => void;
};

const SideMenu = ({ open, availableBalance, handleDrawerClose }: TParams) => {
  const { isDesktopView } = useCustomThemeV2();
  const navigate = useNavigate();
  const { topMenu, bottomMenu, curPath, isMerchant } = useMenu();

  const [showMerchantAccountSection, setShowMerchantAccountSection] =
    useState(isMerchant);

  const handleClick = (value: string) => {
    !isDesktopView && handleDrawerClose();

    if (value === "new-form") {
      NiceModal.show(CREATE_PRE_BUILD_FORM_MODAL);
    } else navigate(value);
  };

  return (
    <Container>
      <Scroller>
        <Top>
          <DrawerAccountSelect drawerOpen={open} />
          <Menu>
            {open && (
              <>
                <Divider />
                <Stack spacing="4px" marginBottom="20px">
                  <GiveText variant="bodyS" color="primary">
                    {availableBalance}
                  </GiveText>
                  <GiveText variant="bodyXS" color="secondary">
                    Available Balance (USD)
                  </GiveText>
                </Stack>
              </>
            )}

            {topMenu.map((i, ind) => {
              const { Icon, CustomIcon, ...rest } = i;
              return (
                <SideMenuItem
                  key={ind}
                  Icon={Icon ? <Icon size={20} /> : CustomIcon}
                  {...rest}
                  open={open}
                  onClick={handleClick}
                  isSelected={curPath.includes(i.value)}
                />
              );
            })}
          </Menu>
        </Top>
      </Scroller>
      <Bottom>
        {showMerchantAccountSection && (
          <MerchantAccountSection
            open={open}
            onClose={() => setShowMerchantAccountSection(false)}
          />
        )}
        <Menu>
          {bottomMenu.map((i, ind) => {
            const { Icon, CustomIcon, ...rest } = i;
            return (
              <SideMenuItem
                key={ind}
                Icon={Icon ? <Icon size={20} /> : CustomIcon}
                {...rest}
                open={open}
                onClick={handleClick}
                isSelected={curPath.includes(i.value)}
              />
            );
          })}
        </Menu>
        <UserSection open={open} />
      </Bottom>
    </Container>
  );
};

export default React.memo(SideMenu);

const Container = styled(Stack)({
  flex: 1,
  justifyContent: "space-between",
});

const Scroller = styled(Stack)({
  height: "100%",
  overflowY: "auto",
  position: "relative",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  scrollbarWidth: "none",
});

const Top = styled(Stack)({
  flex: 1,
  width: "100%",
  position: "absolute",
  gap: "16px",
});

const Menu = styled(Stack)({
  gap: "4px",
});

const Bottom = styled(Stack)({
  paddingTop: "12px",
  gap: "16px",
});

const Divider = styled(DividerMui)(({ theme }) => ({
  marginBottom: "16px",
  color: theme.palette.border?.primary,
}));
