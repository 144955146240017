import { Box, Stack } from "@mui/material";
import { Check, Trash } from "@phosphor-icons/react";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { styled } from "@theme/v2/Provider";
import { MediaItem } from "@sections/PayBuilder/provider/provider.type";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import GivePopup from "@shared/Popup/GivePopup";
import ImageUploader from "./ImageUploader";
import useManageImageModal from "./useManageImageModal";

function ImageModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  const { isMobileView } = useCustomThemeV2();
  const { methods } = usePayBuilderForm();
  const selectedImage = methods.watch("About.selectedImage");

  const {
    mutateMedia,
    newList,
    openDelete,
    setOpenDelete,
    handleDelete,
    handleCloseDelete,
  } = useManageImageModal({ open });

  const { isLoading: isDeleteLoading } = mutateMedia;

  const handleSelectImage = (item: MediaItem) => {
    methods.setValue("About.selectedImage", item);
    onClose();
  };

  return (
    <>
      <GiveBaseModal
        title="Media Library"
        open={open}
        onClose={onClose}
        showFooter={false}
        height={isMobileView ? undefined : "348px"}
        width={isMobileView ? "100%" : "560px"}
      >
        <Stack gap="16px" flexDirection="row" flexWrap="wrap">
          {newList?.map((item, idx) => {
            const { URL, id } = item;
            const isSelected = Number(id) === Number(selectedImage.id);
            if (!URL) return <ImageUploader key={idx} />;
            return (
              <ImageContainer
                key={URL}
                isSelected={isSelected}
                onClick={() => handleSelectImage(item)}
              >
                <Image alt={URL} src={URL + "/thumb"} />
                <Overlay className="overlay" isSelected={isSelected}>
                  {isSelected ? (
                    <Check fill="#FFFFFF" size="24px" />
                  ) : (
                    <GiveIconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenDelete(item);
                      }}
                      color="#FFFFFF"
                      size="small"
                      Icon={Trash}
                    />
                  )}
                </Overlay>
              </ImageContainer>
            );
          })}
        </Stack>
      </GiveBaseModal>

      <GivePopup
        open={Boolean(openDelete)}
        description={`Are you sure you want to remove ${openDelete?.label}? This will no longer be available in media library.`}
        onClose={() => {
          if (isDeleteLoading) return;
          handleCloseDelete();
        }}
        title="Remove Image"
        type="destructive"
        buttons={[
          {
            label: "Cancel",
            onClick: handleCloseDelete,
            variant: "ghost",
            disabled: isDeleteLoading,
          },
          {
            label: "Yes, Delete",
            onClick: handleDelete,
            variant: "filled",
            color: "destructive",
            disabled: isDeleteLoading,
          },
        ]}
      />
    </>
  );
}

export default ImageModal;

const Image = styled("img")(({ theme }) => ({
  height: "100%",
  width: "100%",
  objectFit: "cover",
  display: "block",
  transition: "opacity 0.3s ease",
  zIndex: 1,
}));

const ImageContainer = styled(Box)<{ isSelected: boolean }>(
  ({ theme, isSelected }) => ({
    position: "relative",
    width: "114px",
    height: "114px",
    overflow: "hidden",
    borderRadius: "8px",
    cursor: "pointer",
    backgroundColor: theme.palette.primitive?.transparent["darken-5"],

    "&:hover .overlay": {
      opacity: 1,
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(50% - 16px)",
    },
  }),
);

const Overlay = styled(Stack)<{ isSelected: boolean }>(
  ({ theme, isSelected }) => ({
    position: "absolute",
    backgroundColor: "#00000066",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    ...(isSelected
      ? { justifyContent: "center", alignItems: "center" }
      : {
          justifyContent: "flex-end",
          alignItems: "flex-end",
          padding: theme.spacing(1),
        }),
    opacity: isSelected ? 1 : 0,
  }),
);
