import { IMerchantBankAccount } from "@components/Merchants/MerchantPreview/data.types";
import { Box, Stack, Grid } from "@mui/material";
import { ReactNode, memo } from "react";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme } from "@theme/v2/Provider";
import GiveButton from "@shared/Button/GiveButton";
import { ISectionItem } from "../hooks/useGetSectionItems";
import { GiveSectionItem } from "../GiveSectionItem";
import { DotsThree, PencilSimple } from "@phosphor-icons/react";
import GiveCollapsableComponent from "../businessOwners/GiveCollapsableComponent";
import { BankAccountTagType } from "@common/Tag/BankAccountTag";
import GiveBAStatusChip from "./GiveBAStatusChip";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { EDIT_DENY_MESSAGE } from "@constants/permissions";

interface Props {
  account: IMerchantBankAccount;
  merchantId: number;
  onEdit?: (event: React.MouseEvent<HTMLElement>) => void;
  onOpenMenu?: (event: React.MouseEvent<HTMLElement>, id: number) => void;
  disabled?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
}

const GiveBankAccountItem = ({
  account,
  onOpenMenu,
  merchantId,
  onEdit,
  isFirst,
  isLast,
}: Props) => {
  return (
    <GiveCollapsableComponent
      isFirst={isFirst}
      isLast={isLast}
      title={
        <BankAccountHeader
          accountName={account?.name}
          status={account?.status}
          isLinked={account?.isLinked}
          isPlaid={account?.plaid}
          onOpenMenu={onOpenMenu}
          accountId={account.id}
        />
      }
      withExpandIcon={true}
    >
      <BankAccountBody account={account} onEdit={onEdit} />
    </GiveCollapsableComponent>
  );
};

type TBankAccountHeader = {
  accountName?: string;
  status?: BankAccountTagType;
  isLinked?: boolean;
  isPlaid?: boolean;
  onOpenMenu?: (event: React.MouseEvent<HTMLElement>, id: number) => void;
  accountId: number;
};

const BankAccountHeader = ({
  accountName,
  status,
  isLinked,
  isPlaid,
  onOpenMenu,
  accountId,
}: TBankAccountHeader) => {
  const headerDescription = isLinked
    ? "Virtual Browser"
    : isPlaid
    ? "Conntected With Plaid"
    : "Manually Uploaded";
  return (
    <Stack
      direction="row"
      flexGrow={1}
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      gap={2}
    >
      <Stack direction="column" gap="8px" alignItems="left" overflow="hidden">
        <GiveText color="primary">{accountName}</GiveText>
        <GiveText color="secondary">{headerDescription}</GiveText>
      </Stack>
      <Stack
        display="flex"
        direction="row"
        gap="8px"
        alignItems="center"
        justifyContent="center"
      >
        {onOpenMenu && (
          <GiveIconButton
            sx={{ margin: "0px !important" }}
            variant="ghost"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              onOpenMenu(e, accountId);
            }}
            Icon={DotsThree}
          />
        )}
        <GiveBAStatusChip accountStatus={status} />
      </Stack>
    </Stack>
  );
};

type DataItem = { title: string; value: string; hidden?: boolean };

type TBankAccountBody = {
  account: IMerchantBankAccount;
  onEdit?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
};
const BankAccountBody = ({ account, onEdit, disabled }: TBankAccountBody) => {
  const items: DataItem[] = [
    {
      title: "Created",
      value: account?.created,
    },
    {
      title: "Account Type",
      value: account?.type,
    },
    {
      title: "Business Name on Account",
      value: account?.name,
    },
    {
      title: "Account Number",
      value: account?.accountNumber,
    },
    {
      title: "Routing Number",
      value: account?.routingNumber,
    },
    {
      title: "Bank Name",
      value: account?.bankName,
    },
  ];

  return (
    <Stack spacing={0.5} width={"100%"}>
      {items
        .filter((entry) => !entry.hidden)
        .map((item, index) => (
          <DetailsItem key={index} item={item} highlight={index % 2 !== 0} />
        ))}
      <Stack
        direction="row"
        justifyContent="space-between"
        gap="10px"
        paddingX="16px"
      >
        <CustomButton
          lable="Edit"
          onClick={onEdit}
          endIcon={<PencilSimple size={14} />}
          disabled={disabled}
        />
      </Stack>
    </Stack>
  );
};

const CustomButton = ({
  lable,
  onClick,
  endIcon,
  disabled = false,
}: {
  lable: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  endIcon: ReactNode;
  disabled?: boolean;
}) => (
  <GiveTooltip
    width="compact"
    alignment="left"
    title={EDIT_DENY_MESSAGE}
    color="default"
    disableHoverListener={!disabled}
  >
    <GiveButton
      sx={{ width: "100%", border: "none" }}
      disabled={disabled}
      onClick={onClick}
      label={lable}
      endIcon={endIcon}
      variant="filled"
      color="light"
      size="small"
    />
  </GiveTooltip>
);
const DetailsItem = ({
  item,
  highlight,
}: {
  item: ISectionItem;
  highlight: boolean;
}) => {
  const { palette } = useAppTheme();

  return (
    <Box padding="16px 16px 0px 32px">
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: highlight ? "inherit" : palette.surface?.secondary,
        }}
      >
        <GiveSectionItem item={item} />
      </Grid>
    </Box>
  );
};

export default memo(GiveBankAccountItem);
