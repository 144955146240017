import { useForm } from "react-hook-form";
import { RespondToDisputeFormType } from "../types";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { customInstance } from "@services/api";
import { useModal } from "@ebay/nice-modal-react";
import { useUploadFiles } from "@hooks/upload-api/uploadHooks";
import { useGetCaseEvidence } from "@components/Disputes/DisputePreview/modals/EvidenceProvidedModal/hooks/useGetCaseEvidence";
import { useEffect } from "react";
import { showMessage } from "@common/Toast";

type Props = {
  disputeId: string;
  lastCaseId: string;
  merchantId: number;
  draftId?: string;
  caseAction?: string;
  caseNotes?: string;
};

const FORM_DEFAULT_VALUES = {
  caseAction: "",
  caseNotes: "",
  caseFiles: [],
};

const getDisputeCaseActions = (disputeId: string, caseId: string) => {
  return customInstance({
    url: `/disputes/${disputeId}/cases/${caseId}/actions`,
    method: "GET",
  });
};

export const useRespondToDispute = ({
  disputeId,
  lastCaseId,
  merchantId,
  draftId,
  caseAction,
  caseNotes,
}: Props) => {
  const queryClient = useQueryClient();
  const modal = useModal();
  const { handleUpload, isLoading: loadingFiles } = useUploadFiles();
  const { data: evidenceData } = useGetCaseEvidence({
    caseId: draftId ?? lastCaseId,
    disputeId,
  });

  const form = useForm<RespondToDisputeFormType>({
    mode: "onChange",
    defaultValues: FORM_DEFAULT_VALUES,
  });
  const values = form.watch();

  const { data: caseActions } = useQuery(
    ["dispute-case-actions", disputeId, lastCaseId],
    async () => {
      const caseActions = await getDisputeCaseActions(disputeId, lastCaseId);

      return caseActions?.data || [];
    },
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(disputeId && lastCaseId),
    },
  );

  const removeDraftFilesMutation = useMutation((data: any) => {
    return customInstance({
      url: `/disputes/${disputeId}/cases/${draftId}/evidence`,
      method: "DELETE",
      data,
    });
  });

  const respondToDisputeMutation = useMutation((data: any) => {
    const url = draftId
      ? `/disputes/${disputeId}/cases/${draftId}/evidence`
      : `/disputes/${disputeId}/evidence`;

    return customInstance({
      url,
      method: draftId ? "PATCH" : "POST",
      data,
    });
  });

  const submitEvidenceMutation = useMutation(({ data, caseId }: any) => {

    return customInstance({
      url: `/disputes/${disputeId}/cases/${caseId}`,
      method: "PATCH",
      data,
    });
  });

  const handleClose = () => modal.remove();

  const handleSaveEvidence = async ({
    onSuccess,
  }: {
    onSuccess?: () => void;
  }) => {
    const uploadedFiles = values.caseFiles
      ?.filter((caseFile) => !caseFile.id)
      ?.map((caseFile) => ({ file: caseFile.file }));

    let caseUploadedFiles = [] as {
      fileID: number | undefined;
      fileName: string;
      notes: string | undefined;
    }[];

    if (uploadedFiles?.length > 0) {
      const filesRes = await handleUpload({
        list: uploadedFiles,
        merchantId: merchantId,
        resourceID: merchantId,
        attachmentType: "dispute_evidence",
        label: "",
      });

      caseUploadedFiles = values.caseFiles
        ?.filter((caseFile) => !caseFile?.id) // remove already existing ones
        ?.map((caseFile, index) => ({
          fileID: filesRes?.[index]?.id,
          fileName: caseFile.file.name,
          fileSize: caseFile.file.size,
          notes: caseFile.note,
        }));
    }

    const caseExistingFiles = values.caseFiles
      ?.filter((caseFile) => caseFile?.id) // if they have id, they exist already
      ?.map((caseFile) => ({
        id: caseFile?.id,
        fileName: caseFile.file.name,
        notes: caseFile.note,
      }));

    const data = {
      action: values.caseAction,
      notes: values.caseNotes,
      files: [...caseUploadedFiles, ...caseExistingFiles],
    };

    if (draftId) {
      const currentFiles = values.caseFiles
        ?.filter((file) => file.id)
        .map((file) => file.id);

      const removedDocs = evidenceData
        ?.filter((doc) => !!doc?.fileURL && !currentFiles.includes(doc?.obj_id))
        ?.map((doc) => doc?.obj_id);

      if (removedDocs?.length > 0) {
        await removeDraftFilesMutation.mutateAsync(
          {
            texts: [],
            files: removedDocs,
          },
          {
            onSuccess: () => {
              queryClient.refetchQueries(["dispute-preview", disputeId]);
            },
          },
        );
      }
    }

    const res = await respondToDisputeMutation.mutateAsync(data, {
      onSuccess: () => {
        queryClient.refetchQueries(["dispute-preview", disputeId]);
        if (onSuccess) onSuccess();
      },
      onError: (err: any) => {
        showMessage(
          "Error",
          err?.response?.data?.input?.[0].message ||
            err?.response?.data?.message,
        );
      },
    });

    return res?.id; // draft id;
  };

  const handleSaveDraft = () => {
    handleSaveEvidence({
      onSuccess: () => handleClose(),
    });
  };

  const handleSubmitEvidence = async () => {
    const data = {
      submit: true,
      notes: values?.caseNotes,
    };

    const caseId = await handleSaveEvidence({});

    submitEvidenceMutation.mutate(
      { data, caseId: caseId ?? draftId },
      {
        onSuccess: () => {
          queryClient.refetchQueries(["dispute-preview", disputeId]);
          handleClose();
        },
        onError: (err: any) => {
          showMessage(
            "Error",
            err?.response?.data?.input?.[0].message ||
              err?.response?.data?.message,
          );
        },
      },
    );
  };

  useEffect(() => {
    if (draftId) {
      const filteredDocs = evidenceData?.filter((doc) => !!doc?.fileURL) || [];

      const documents = filteredDocs?.map((doc) => {
        return {
          file: {
            name: doc?.fileName,
            size: doc?.fileSize || 0,
            type: doc?.fileType,
          },
          fileURL: doc?.fileURL,
          note: doc?.notes,
          id: doc?.obj_id,
        };
      });

      form.reset({
        caseAction,
        caseNotes,
        caseFiles: documents as any[], // to replace any
      });
    }
  }, [evidenceData, draftId]);

  return {
    modal,
    form,
    values,
    handleSaveDraft,
    handleSubmitEvidence,
    handleClose,
    isLoading:
      submitEvidenceMutation.isLoading ||
      respondToDisputeMutation.isLoading ||
      removeDraftFilesMutation.isLoading ||
      loadingFiles,
    caseActions: caseActions || [],
  };
};
