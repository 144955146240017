import Xicon from "@assets/icons/Xicon";
import { Text } from "@common/Text";
import { Box, Breadcrumbs, Link, Stack } from "@mui/material";
import { ReactNode, memo } from "react";
import placeholder from "assets/images/Placeholder.png";
import { TOFACTabs } from "../OFAC/hooks/useSetPage";
import { Replies } from "features/Minibuilders/Conversations/types";
import { EditButton } from "../components/atoms";
import { ConversationsIconNotFilled } from "@assets/icons";
import { OFACTabType } from "../OFAC/helpers";

interface Props {
  firstPanelShowing: boolean;
  toggleSecondModal: () => void;
  setDefault?: () => void;
  children?: ReactNode;
  mainText?: string;
  headerData: {
    name: any;
    imageURL: any;
  };
  page: TOFACTabs | OFACTabType | "default" | "OFAC" | "Snapshot" | string;
  showConversations?: boolean;
  conversationName?: string;
  showBreadcrumb?: boolean;
  openConversationHandler?: ({
    name,
    paths,
    id,
    defaultMessage,
    challengeId,
    openThread,
    forceTopicOpen,
    isOpenedFromSidePanel,
  }: {
    name: string;
    paths: {
      avatars: string[];
      pathName: string;
    }[];
    id?: number;
    defaultMessage?: string;
    challengeId?: number;
    openThread?: Replies;
    forceTopicOpen?: boolean;
    isOpenedFromSidePanel?: boolean;
  }) => void;
}
function Header({
  firstPanelShowing,
  toggleSecondModal,
  headerData,
  setDefault,
  mainText,
  children,
  page,
  showConversations,
  openConversationHandler,
  conversationName,
  showBreadcrumb = false,
}: Props) {
  const isOFACCheck = mainText === "OFAC" && page !== "default";
  const isSnapshots = page === "Snapshot";
  const isDefault = page === "default";

  const getColor = (isActive: boolean, activeColor = "#8F8F8F") =>
    isActive ? "#575353" : activeColor;

  return (
    <Stack
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexDirection="row"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        bgcolor="transparent"
      >
        {!children ? (
          <>
            <Breadcrumbs
              sx={{
                "& a": {
                  cursor: "pointer",
                },
              }}
            >
              {!firstPanelShowing && (
                <Stack
                  display="flex"
                  gap={1}
                  flexDirection="row"
                  alignItems="center"
                >
                  <Box
                    width="24px"
                    height="24px"
                    src={
                      headerData?.imageURL
                        ? headerData.imageURL + "/original"
                        : placeholder
                    }
                    component="img"
                    borderRadius="2px"
                  />
                  <Link onClick={toggleSecondModal} color={getColor(false)}>
                    {headerData?.name}
                  </Link>
                </Stack>
              )}
              <Link
                underline={isOFACCheck || showBreadcrumb ? "hover" : "none"}
                onClick={setDefault}
                color={getColor(isDefault)}
                fontSize={18}
                sx={{
                  fontWeight: 400,
                  "&:hover": {
                    cursor:
                      isOFACCheck || showBreadcrumb ? "pointer" : "default",
                  },
                }}
              >
                {mainText || "ManageUnderwriting"}
              </Link>
              {(isOFACCheck || showBreadcrumb) && (
                <Link
                  fontSize={18}
                  underline="none"
                  sx={{
                    "&:hover": {
                      cursor: "default",
                    },
                  }}
                  color={getColor(true, "#292727")}
                  data-testid="breadcrumb_link"
                >
                  {page}
                </Link>
              )}
              {isSnapshots && (
                <Link underline="hover" color={getColor(true)}>
                  Snapshot
                </Link>
              )}
            </Breadcrumbs>
          </>
        ) : (
          children
        )}
      </Box>
      <Stack gap={1} direction="row" alignItems="center">
        {showConversations && openConversationHandler && (
          <EditButton
            onClick={() =>
              openConversationHandler({
                id: 0,
                name: conversationName || "OFAC Check",
                paths: [],
              })
            }
          >
            <ConversationsIconNotFilled />
          </EditButton>
        )}
        {firstPanelShowing && (
          <button
            data-testid="close-doublepanel-button"
            style={{
              all: "unset",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={toggleSecondModal}
          >
            <Xicon />
          </button>
        )}
      </Stack>
    </Stack>
  );
}

export default memo(Header);

export const BreadCrumbText = ({
  active,
  text,
  isNext,
  onClick,
  isPrev,
  color,
}: {
  active: boolean;
  text: string;
  isNext?: boolean;
  onClick?: () => void;
  isPrev?: boolean;
  color?: string;
}) => {
  const textColor = color ? color : active ? "#575353" : "#8F8F8F";
  return (
    <Text
      color={textColor}
      fontWeight="book"
      fontSize="14px"
      lineHeight="16px"
      onClick={onClick}
      sx={{
        cursor: "pointer",
      }}
      mr={0.5}
    >
      {isPrev && <span> / </span>}
      {text}
      {isNext && <span> /</span>}
    </Text>
  );
};
