import { Text } from "@common/Text";
import { Box, BoxProps, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import React from "react";
import { OFACCheckStatusType, OFACCheckType } from "../hooks/useOFAC";
import OFACStatus from "./OFACStatus";
import { formatTimestampWithTimezone } from "@utils/index";

export default function PreviousCheckItem({
  check,
  isOwner,
}: {
  check: OFACCheckType;
  isOwner?: boolean;
}) {
  return (
    <CheckItem
      key={check.ID}
      date={formatTimestampWithTimezone(check.createdAt)}
      status={check.statusName}
      data-testid="OFAC-Check-item"
      name={isOwner ? check?.resourceFullName : undefined}
    />
  );
}

type CheckItemProps = {
  date: string;
  status: OFACCheckStatusType;
  name?: string;
};

const CheckItem = ({
  date,
  status,
  name,
  ...props
}: CheckItemProps & BoxProps) => {
  return (
    <CheckItemContainer {...props} data-testid="check-item-container">
      {name && (
        <Text
          fontSize={12}
          lineHeight="32px"
          fontWeight="book"
          color={palette.neutral[80]}
        >
          {name}
        </Text>
      )}
      <Text
        fontSize={12}
        lineHeight="32px"
        fontWeight="book"
        color={palette.neutral[70]}
      >
        {date}
      </Text>

      <Stack direction="row" spacing={1} alignItems="center">
        <OFACStatus status={status} />
      </Stack>
    </CheckItemContainer>
  );
};

const CheckItemContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "8px",
  padding: "12px 16px",
  width: "100%",
  pointerEvents: "none",
}));
