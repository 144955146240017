import { Box, Stack } from "@mui/material";
import {
  ProfileSetupFormActions,
  ProfileSetupFormContainer,
  ProfileSetupFormTitle,
} from "../form.components";
import { FormProvider } from "react-hook-form";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { PlusIcon, UserFillIcon } from "@assets/icons";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import AddOwnerInputFields from "./AddOwnerInputFields";
import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { styled } from "@mui/material";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { TBusinessStepsCommons } from "./BusinessProfileSetupNew";
import { DynamicReturnType, OnboardingPrincipal } from "./helpers/refineData";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { showMessage } from "@common/Toast";
import {
  businessOwnerDescriptions,
  businessOwnerIgnoredValues,
} from "./utils/principal.utils";
import { BusinessUnionTypes } from "@common/BusinessProfileInputs/BusinessTypeSelect";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import { getIDFile } from "@components/Merchants/MerchantPreview/components/BusinessOwners/MerchantBusinessOwners";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useQueryClient } from "react-query";
import { IFileWithMeta, StatusValue } from "react-dropzone-uploader";
import { getBusinessOwnerSchema } from "./schemas/businessOwnerSchema";
import { convertPhoneNumber } from "@utils/date.helpers";
import { useUploadFiles } from "@hooks/upload-api/uploadHooks";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  CREATE_DENY_MESSAGE,
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import WarningBanner from "./WarningBanner";
import usePercentageUpdate from "./hooks/usePercentageUpdate";
import { checkPortals } from "@utils/routing";
import { deleteDocument } from "@hooks/common/documents/utils";

type TFormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  ssn: string;
  ownership: string;
  useBusinessAddress: boolean;
  country: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  DOB: string;
  id: string;
  pepStatusName: string;
  isNotUSResident: boolean;
  citizenship: string;
  isNotResidentInCitizenshipCountry: boolean;
  countryOfResidence: string;
  contactPhone?: string;
  files?: {
    fileWithMeta?: any;
    status?: StatusValue;
    allFiles: IFileWithMeta[] | TMerchantDocument[];
  };
};

const isObjectEmpty = (obj: Record<string, any>): boolean => {
  for (const key in obj) {
    if (
      key === "ownership" ||
      key === "useBusinessAddress" ||
      key === "country"
    )
      continue;
    if (key === "files") {
      const areFiles =
        obj[key] && obj[key]?.allFiles && obj[key]?.allFiles?.length > 0;
      return !areFiles;
    }
    const value = obj[key];
    if (value) {
      return false;
    }
  }
  return true;
};

interface IProps extends TBusinessStepsCommons {
  canEdit: boolean;
  data: DynamicReturnType["businessOwners"];
  businessType: BusinessUnionTypes;
  warningMessage: string | null;
  isLegalEntityCreated: boolean;
  isIncomplete: boolean;
  getHelperText: (value: string) => string | undefined;
  isSoleProprietorship: boolean;
  businessOwnerDefaultValues: any;
}

const LEGAL_ENTITY_NOT_CREATED_MESSAGE =
  "Add business details and address first";

const REQUIRED_HELPER_TEXT = "Required for business owner creation";

// This is needed for generating payload for api call
export const ALLOW_EMPTY = {
  firstName: true,
  lastName: true,
  email: true,
  ssn: true,
  DOB: true,
  useBusinessAddress: true,
  country: true,
  street: true,
  city: true,
  zip: true,
  isNotUSResident: true,
  citizenship: true,
  isNotResidentInCitizenshipCountry: true,
  countryOfResidence: true,
};

const BusinessOwner = forwardRef(
  (
    {
      handleBack,
      submitHandler,
      updateStatusBar,
      canEdit,
      data: businessOwners,
      businessType,
      isSubmitting,
      warningMessage,
      isIncomplete,
      isLegalEntityCreated,
      getHelperText,
      isSoleProprietorship,
      businessOwnerDefaultValues,
    }: IProps,
    ref,
  ) => {
    const customValuesRef = useRef<OnboardingPrincipal[]>([]);
    const { isMerchantPortal } = checkPortals();
    const { isMobileView, isDesktopView } = useCustomTheme();
    const { merchantId } = useGetCurrentMerchantId();
    const queryClient = useQueryClient();
    const isAddAllowed = useAccessControl({
      resource: composePermission(
        RESOURCE_BASE.LEGAL_ENTITY,
        RESOURCE_BASE.PRINCIPAL,
      ),
      operation: OPERATIONS.CREATE,
      withPortal: true,
    });

    const isUpdateAllowed = useAccessControl({
      resource: composePermission(
        RESOURCE_BASE.LEGAL_ENTITY,
        RESOURCE_BASE.PRINCIPAL,
      ),
      operation: OPERATIONS.UPDATE,
      withPortal: true,
    });

    const { handleUpload, isLoading: uploading } = useUploadFiles();
    const [isFormOpen, setIsFormOpen] = useState(
      !businessOwners || businessOwners?.length === 0,
    );
    const [selectedOwnersPEP, setSelectedOwnersPEP] = useState("");

    const methods = useForm<TFormInputs>({
      mode: "onChange",
      resolver: yupResolver(getBusinessOwnerSchema()),
      defaultValues: businessOwnerDefaultValues,
    });

    const {
      watch,
      handleSubmit,
      reset,
      formState: { isDirty, dirtyFields },
      getValues,
    } = methods;
    const values = watch();

    const { ssn, email } = values || {};

    const closeForm = () => {
      reset(businessOwnerDefaultValues);
      setIsFormOpen(false);
    };

    const handleOpenWithData = (data: TFormInputs) => {
      setSelectedOwnersPEP(data?.pepStatusName || "");
      setIsFormOpen(true);
      const resetData = {
        ...data,
        DOB: data.DOB === "" ? null : data.DOB,
      } as TFormInputs;
      reset(resetData);
    };

    const customValues: OnboardingPrincipal[] = useMemo(() => {
      if (!businessOwners) {
        return [values];
      } else {
        if (isObjectEmpty(values) || !isDirty) {
          return businessOwners;
        } else {
          const idToCheck = values.id;

          const indexToReplace = customValuesRef.current.findIndex(
            (item) => item.id === idToCheck,
          );

          if (indexToReplace !== -1) {
            // Replace the existing object
            return [
              ...customValuesRef.current.slice(0, indexToReplace),
              { ...customValuesRef.current[indexToReplace], ...values },
              ...customValuesRef.current.slice(indexToReplace + 1),
            ];
          } else {
            // Add new object to the array
            return [...customValuesRef.current, values];
          }
        }
      }
    }, [values, businessOwners]);

    useEffect(() => {
      customValuesRef.current = customValues;
    }, [customValues]);

    useEffect(() => {
      if (!isMerchantPortal) {
        updateStatusBar((businessOwners?.length || 0) > 0 ? 100 : 0);
      }
    }, [businessOwners]);

    usePercentageUpdate<TFormInputs>(
      isMerchantPortal ? customValues : values,
      dirtyFields,
      getBusinessOwnerSchema(),
      updateStatusBar,
      ["id", "pepStatusName"],
      businessOwnerIgnoredValues,
    );

    const uploadFilesAfter = async (
      data: any,
      files: { allFiles: IFileWithMeta[] | TMerchantDocument[] },
    ) => {
      let uploadRes;
      if (files?.allFiles?.length > 0)
        uploadRes = await handleUpload({
          list: files?.allFiles as { file: File }[],
          attachmentType: "legal_principal",
          merchantId: merchantId,
          resourceID: data?.id,
          label: "business owner document",
          tag: "business owner document",
          refetcherKey: ["get-bo-files"],
        });
      if (uploadRes === "upload_failed")
        showMessage("Error", "Document upload failed.", isDesktopView);
    };

    const commonSubmitHandler = async (
      data: TFormInputs,
      forbidRedirect: boolean,
      cb?: any,
      param?: any,
    ) => {
      const ownershipExceeds = checkIfOwnershipExceeds(
        data,
        businessOwners,
        isDirty,
      );

      if (ownershipExceeds) {
        showMessage("Info", "Ownership sum cannot exceed 100%");
        return;
      }

      const { files, ...rest } = data;
      const payload = {
        ...rest,
        countryOfResidence: data.isNotResidentInCitizenshipCountry
          ? data.countryOfResidence
          : "",
        citizenship: data?.isNotUSResident ? data.citizenship : "",
        phoneNumber: data.contactPhone
          ? convertPhoneNumber(data.contactPhone)
          : null,
      };

      submitHandler("businessOwners", payload, {
        makeApiCall:
          isDirty &&
          Object.keys(dirtyFields).length > 0 &&
          !!values.email &&
          !!values.ssn,
        onSuccess: async (data) => {
          if (files) {
            await uploadFilesAfter(data, files);
          }
          queryClient.invalidateQueries(["get-bo-files", merchantId]);
        },
        forbidRedirect,
        allowEmpty: ALLOW_EMPTY,
        handleNext: cb,
        param: param,
      });
    };

    const onSubmit: SubmitHandler<TFormInputs> = async (data) => {
      await commonSubmitHandler(data, false);
    };

    const handleForceSubmit = async (cb?: any, param?: any) => {
      await commonSubmitHandler(getValues(), true, cb, param);
    };

    const backSaveHandler = async () => {
      await commonSubmitHandler(getValues(), true, handleBack);
    };

    const isSubmitDisabled = isSubmitting;
    const isClickable = !isSubmitDisabled && !isFormOpen;

    const hasOwners = businessOwners?.length > 0;
    const canEditInputs =
      canEdit && (hasOwners ? isUpdateAllowed : isAddAllowed);

    const getTooltipMessage = (): string => {
      if (!isLegalEntityCreated) {
        return LEGAL_ENTITY_NOT_CREATED_MESSAGE;
      }
      if (!isUpdateAllowed) {
        return EDIT_DENY_MESSAGE;
      }
      if (!hasOwners && !isAddAllowed) {
        return CREATE_DENY_MESSAGE;
      }
      return "";
    };

    const tooltipProps = {
      show:
        !isUpdateAllowed ||
        (!hasOwners && !isAddAllowed) ||
        !isLegalEntityCreated,
      message: getTooltipMessage(),
    };

    useImperativeHandle(ref, () => ({
      execute: handleForceSubmit,
    }));

    const getHelperTextWithRequired = () => {
      return (value: string) => {
        if (!value) {
          return REQUIRED_HELPER_TEXT;
        }
        return getHelperText(value);
      };
    };

    const idFile = useMemo(() => {
      return getIDFile(values.id, values?.files?.allFiles as any);
    }, [values]);

    const handleDeleteFile = () => {
      if (idFile) {
        deleteDocument(
          merchantId,
          {
            fileName: idFile.fileName,
            id: idFile.id,
          },
          () => {
            reset({ ...values, files: { allFiles: [] } });
            queryClient.invalidateQueries(["get-merchant-preview", merchantId]);
            queryClient.invalidateQueries(["get-bo-files", merchantId]);
          },
        );
      }
    };

    return (
      <FormProvider {...methods}>
        <Box
          component="form"
          flexGrow={1}
          id="business-owner-form"
          display="flex"
          onSubmit={handleSubmit(onSubmit)}
        >
          <ProfileSetupFormContainer>
            <Stack
              direction="column"
              gap={4}
              alignItems="flex-start"
              height="min-content"
            >
              <ProfileSetupFormTitle
                description={businessOwnerDescriptions[businessType]}
                title="Add business owner"
              />
              {isIncomplete && <WarningBanner message={warningMessage} />}
              <Stack direction="column" gap={0.5} width="100%">
                {businessOwners
                  ?.filter((item) => item?.id !== values?.id)
                  .map((data, idx) => (
                    <FadeUpWrapper delay={50 + idx * 50} key={data.id}>
                      <BusinessOwnerItem
                        name={`${data?.firstName || ""} ${
                          data?.lastName || ""
                        }`}
                        ownership={data?.ownership || ""}
                        onClick={() => handleOpenWithData(data)}
                      />
                    </FadeUpWrapper>
                  ))}
              </Stack>
              {!isFormOpen && (
                <TextAddOwner
                  onClick={() => setIsFormOpen(true)}
                  disabled={
                    (isSoleProprietorship && businessOwners?.length >= 1) ||
                    !canEdit ||
                    !isAddAllowed
                  }
                  tooltipProps={{
                    show: !isAddAllowed,
                    message: CREATE_DENY_MESSAGE,
                  }}
                />
              )}
              {isFormOpen && !isMobileView && (
                <AddOwnerInputFields
                  isSoleProprietorship={isSoleProprietorship}
                  selectedOwnersPEP={selectedOwnersPEP}
                  isLoading={isSubmitting || uploading}
                  leType={businessType}
                  canEdit={canEditInputs}
                  idFile={idFile}
                  tooltipProps={tooltipProps}
                  ssnHelperText={getHelperTextWithRequired()(ssn)}
                  emailHelperText={getHelperTextWithRequired()(email)}
                  onDeleteFile={idFile ? handleDeleteFile : undefined}
                />
              )}
            </Stack>
            <ProfileSetupFormActions
              secondaryAction={{
                onClick: backSaveHandler,
              }}
              primaryAction={{
                children: "Next",
                form: "business-owner-form",
                onClick: isClickable ? () => onSubmit(values) : undefined,
                disabled: isSubmitDisabled,
              }}
            />
          </ProfileSetupFormContainer>
        </Box>
        {isMobileView && (
          <ModalFactory
            variant="dialog"
            renderMobile
            modalProps={{
              open: isFormOpen,
              onClose: closeForm,
            }}
          >
            {isFormOpen && (
              <Box
                component="form"
                flexGrow={1}
                id="business-owner-form"
                display="flex"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Stack direction="column" gap={4} maxWidth="100%">
                  <AddOwnerInputFields
                    idFile={idFile}
                    isSoleProprietorship={isSoleProprietorship}
                    leType={businessType}
                    canEdit={canEditInputs}
                    tooltipProps={tooltipProps}
                    onDeleteFile={idFile ? handleDeleteFile : undefined}
                  />
                </Stack>
              </Box>
            )}
          </ModalFactory>
        )}
      </FormProvider>
    );
  },
);

BusinessOwner.displayName = "BusinessOwner";

export default memo(BusinessOwner);

const checkIfOwnershipExceeds = (
  data: TFormInputs,
  businessOwners: DynamicReturnType["businessOwners"],
  isDirty?: boolean,
) => {
  const totalOwnership =
    businessOwners?.reduce((acc, owner) => {
      const _ownership = owner.ownership ? +owner.ownership : 0;
      return acc + _ownership;
    }, 0) || 0;

  const editedOwner = data?.id
    ? businessOwners?.find((owner) => owner?.id === data?.id)
    : null;

  //default value of ownership is 10, which should not be calculated in
  const computedOwnership =
    +(isDirty && data?.ownership ? data?.ownership : 0) +
    totalOwnership -
    +(editedOwner?.ownership || 0);
  return computedOwnership > 100;
};

const TextAddOwner = ({
  onClick,
  disabled,
  tooltipProps,
}: {
  onClick: () => void;
  disabled: boolean;
  tooltipProps: {
    show: boolean;
    message: string;
  };
}) => {
  return (
    <FadeUpWrapper delay={200}>
      <Stack direction="row" justifyContent="center">
        <WithTooltipWrapper
          hasTooltip={!!tooltipProps}
          tooltipProps={tooltipProps}
        >
          <AddOwnerButton
            onClick={disabled ? undefined : onClick}
            sx={{
              ...(disabled && {
                pointerEvents: "none",
                cursor: "default",
              }),
            }}
          >
            <PlusIcon fill="#8F8F8F" />
            <Text fontWeight="regular" fontSize="14px" color="#8F8F8F">
              Add business owner
            </Text>
          </AddOwnerButton>
        </WithTooltipWrapper>
      </Stack>
    </FadeUpWrapper>
  );
};

const BusinessOwnerItem = ({
  name,
  ownership,
  onClick,
}: {
  name: string;
  ownership: number | string;
  onClick: () => void;
}) => {
  return (
    <BusinessOwnerItemWrapper onClick={onClick}>
      <Box gap="8px" display="flex" alignItems="center">
        <UserFillIcon />
        <Text fontWeight="regular" fontSize="14px" color="#8F8F8F">
          {name}
        </Text>
      </Box>
      <Box gap="4px" alignItems="center" display="flex">
        <Text fontWeight="light" fontSize="14px" color="#8F8F8F">
          Ownership
        </Text>
        <Text fontWeight="regular" fontSize="14px" color="#8F8F8F">
          {ownership}%
        </Text>
      </Box>
    </BusinessOwnerItemWrapper>
  );
};

const BusinessOwnerItemWrapper = styled(Box)(() => ({
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  width: "100%",
  boxShadow: "1px 1px 15px 0px #A1AFB41A",
  padding: "16px",
  borderRadius: "8px",
  marginBlock: "5px",
  cursor: "pointer",
  backgroundColor: palette.neutral.white,
}));

const AddOwnerButton = styled(Stack)(() => ({
  cursor: "pointer",
  marginInline: "auto",
  position: "static",
  right: 0,
  left: 0,
  gap: "4px",
  alignItems: "center",
  flexDirection: "row",
}));
