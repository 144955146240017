import React from "react";

const DiscoverCardIcon = ({
  width,
  height,
}: {
  width?: number | string;
  height?: number | string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 0.5H36C37.933 0.5 39.5 2.067 39.5 4V22C39.5 23.933 37.933 25.5 36 25.5H4C2.067 25.5 0.5 23.933 0.5 22V4C0.5 2.067 2.067 0.5 4 0.5Z"
        stroke="#E6E6E3"
      />
      <g clipPath="url(#clip0_5005_78357)">
        <path
          d="M39.3252 0.694824H39.1859L0.689453 0.697367L0.690489 24.974L39.3267 24.9735"
          fill="white"
        />
        <path
          d="M39.0455 0.96991C39.0455 1.23977 39.0467 24.4276 39.0467 24.698C38.7693 24.698 1.24818 24.699 0.969559 24.699C0.969559 24.4286 0.96875 1.2418 0.96875 0.971893C1.24667 0.971893 38.7683 0.96991 39.0455 0.96991Z"
          fill="white"
        />
        <path
          d="M39.0446 14.3333C39.0446 14.3333 28.5652 21.5976 9.37183 24.8508H39.0446V14.3333Z"
          fill="#F58220"
        />
        <path
          d="M20.9236 6.85767C19.3491 6.85767 18.0718 8.06611 18.0718 9.55747C18.0718 11.143 19.2937 12.3283 20.9236 12.3283C22.5129 12.3283 23.7677 11.1274 23.7677 9.58861C23.7677 8.0589 22.5213 6.85767 20.9236 6.85767Z"
          fill="url(#paint0_radial_5005_78357)"
        />
        <path
          d="M4.73341 6.95612H3.2002V12.2098H4.72537C5.5346 12.2098 6.11986 12.0214 6.63322 11.6037C6.93508 11.3572 7.17837 11.0489 7.34601 10.7002C7.51365 10.3516 7.60158 9.97111 7.60362 9.58553C7.60362 8.03446 6.42416 6.95612 4.73341 6.95612ZM5.95266 10.9019C5.62302 11.1942 5.19864 11.3204 4.52415 11.3204H4.24391V7.84577H4.52415C5.19864 7.84577 5.60679 7.96403 5.95266 8.27095C6.13588 8.43751 6.28173 8.63977 6.38089 8.86483C6.48006 9.08989 6.53035 9.3328 6.52856 9.57803C6.53062 9.82578 6.48053 10.0713 6.38142 10.2991C6.28232 10.5269 6.13634 10.7321 5.95266 10.9019Z"
          fill="#001722"
        />
        <path
          d="M9.12685 6.95612H8.08496V12.2098H9.12685V6.95612Z"
          fill="#001722"
        />
        <path
          d="M11.678 8.97142C11.0517 8.74344 10.8674 8.59308 10.8674 8.31055C10.8674 7.97944 11.1955 7.72757 11.6453 7.72757C11.958 7.72757 12.2146 7.85349 12.4881 8.15195L13.0325 7.452C12.6003 7.0727 12.0401 6.86473 11.4605 6.86846C10.5153 6.86846 9.7931 7.51468 9.7931 8.37254C9.7931 9.09793 10.1296 9.46783 11.1082 9.81538C11.3581 9.89185 11.5998 9.99213 11.8298 10.1148C11.9256 10.1707 12.0049 10.2501 12.0598 10.3454C12.1147 10.4406 12.1434 10.5483 12.143 10.6577C12.143 11.0836 11.7979 11.3983 11.3321 11.3983C11.0937 11.402 10.8594 11.3378 10.6573 11.2135C10.4553 11.0891 10.2943 10.9099 10.1937 10.6975L9.521 11.3363C10.0011 12.0286 10.5786 12.3368 11.3732 12.3368C12.4556 12.3368 13.217 11.6268 13.217 10.611C13.217 9.77579 12.8651 9.3974 11.678 8.97142Z"
          fill="#001722"
        />
        <path
          d="M13.5457 9.58556C13.5457 11.1312 14.7809 12.3283 16.369 12.3283C16.8234 12.3289 17.2714 12.2237 17.6765 12.0214V10.8156C17.2589 11.2255 16.89 11.3905 16.4167 11.3905C15.3664 11.3905 14.6202 10.642 14.6202 9.57806C14.6202 8.57019 15.3897 7.77431 16.369 7.77431C16.8654 7.77431 17.2429 7.94761 17.6765 8.36509V7.15924C17.283 6.94762 16.8415 6.83699 16.3929 6.83762C16.0219 6.83302 15.6536 6.9006 15.3094 7.03646C14.9651 7.17232 14.6516 7.37377 14.3869 7.62921C14.1223 7.88465 13.9117 8.18902 13.7673 8.52478C13.6229 8.86055 13.5476 9.22106 13.5457 9.58556Z"
          fill="#001722"
        />
        <path
          d="M26.1277 10.4852L24.7003 6.95612H23.5615L25.8316 12.344H26.3925L28.7024 6.95612H27.572L26.1277 10.4852Z"
          fill="#001722"
        />
        <path
          d="M29.1765 12.2097H32.1351V11.3204H30.2189V9.90138H32.0618V9.01179H30.2189V7.84571H32.1351V6.95612H29.1765V12.2097Z"
          fill="#001722"
        />
        <path
          d="M36.2638 8.50663C36.2638 7.52241 35.5752 6.95612 34.3716 6.95612H32.8237V12.2098H33.8672V10.0983H34.0037L35.4465 12.2098H36.7295L35.0445 9.99676C35.8315 9.83876 36.2638 9.3107 36.2638 8.50663ZM34.1707 9.37424H33.8672V7.78228H34.1874C34.8384 7.78228 35.191 8.05091 35.191 8.56216C35.191 9.08967 34.8384 9.37424 34.1707 9.37424Z"
          fill="#001722"
        />
        <path
          d="M36.9995 7.34735C36.9995 7.25531 36.9368 7.20538 36.8222 7.20538H36.6704V7.66812H36.7819V7.48816L36.9138 7.66812H37.0535L36.897 7.47689C36.9269 7.4707 36.9536 7.45428 36.9724 7.43057C36.9912 7.40685 37.0008 7.37737 36.9995 7.34735ZM36.8029 7.41033H36.7819V7.28998H36.8039C36.8594 7.28998 36.8876 7.30979 36.8876 7.34913C36.8876 7.38976 36.8583 7.41033 36.8029 7.41033Z"
          fill="#231F20"
        />
        <path
          d="M36.8447 7.03122C36.7631 7.03017 36.683 7.05299 36.6146 7.09678C36.5462 7.14057 36.4926 7.20335 36.4606 7.27715C36.4287 7.35094 36.4197 7.43241 36.435 7.51119C36.4503 7.58998 36.489 7.66253 36.5464 7.71961C36.6037 7.77669 36.6771 7.81572 36.7571 7.83175C36.8371 7.84778 36.9201 7.84008 36.9956 7.80962C37.0711 7.77917 37.1357 7.72733 37.1812 7.66072C37.2266 7.5941 37.2509 7.5157 37.2509 7.4355C37.2512 7.32908 37.2087 7.22684 37.1326 7.1511C37.0565 7.07535 36.953 7.03226 36.8447 7.03122ZM36.8442 7.7674C36.7769 7.77006 36.7104 7.75288 36.6531 7.71807C36.5959 7.68327 36.5505 7.63243 36.5229 7.5721C36.4953 7.51177 36.4867 7.4447 36.4982 7.37953C36.5097 7.31435 36.5408 7.25405 36.5875 7.20637C36.6342 7.1587 36.6943 7.12583 36.7601 7.112C36.826 7.09817 36.8945 7.10401 36.9569 7.12877C37.0194 7.15353 37.0729 7.19607 37.1105 7.25092C37.1481 7.30578 37.1682 7.37044 37.1681 7.43659C37.1692 7.52248 37.1358 7.60534 37.0752 7.66724C37.0146 7.72915 36.9316 7.76513 36.8442 7.7674Z"
          fill="#231F20"
        />
        <path
          d="M12.5486 13.5907L13.9439 15.0266V13.6658H14.1433V15.5021L12.7482 14.0693V15.4235H12.5486V13.5907Z"
          fill="#231F20"
        />
        <path
          d="M12.53 13.5907V15.4421H12.7666V14.1146C12.8278 14.1773 14.1614 15.548 14.1614 15.548V13.6472H13.9251V14.9814C13.8639 14.9176 12.53 13.5447 12.53 13.5447V13.5907ZM12.5674 13.6365C12.6292 13.6992 13.9633 15.0729 13.9633 15.0729V13.6833H14.1245V15.4574C14.0627 15.3937 12.7302 14.0231 12.7302 14.0231V15.405H12.5674V13.6365Z"
          fill="#231F20"
        />
        <path
          d="M15.1099 13.6658H16.0369V13.8473H15.3088V14.37H16.0157V14.5516H15.3088V15.2415H16.0369V15.4235H15.1099V13.6658Z"
          fill="#231F20"
        />
        <path
          d="M16.0369 13.6472H15.0908V15.442H16.056V15.223H15.3278V14.5691H16.0343V14.3521H15.3278V13.8668H16.056V13.6472H16.0369ZM16.0185 13.6832V13.8287H15.2899V14.3881H15.9969V14.533H15.2899V15.2595H16.0185V15.405H15.1282V13.6832H16.0185Z"
          fill="#231F20"
        />
        <path
          d="M17.4448 15.4235H17.2452V13.8473H16.8137V13.6658H17.8813V13.8473H17.4448V15.4235Z"
          fill="#231F20"
        />
        <path
          d="M17.8814 13.6472H16.7939V13.8668H17.2265V15.442H17.463V13.8668H17.9V13.6472H17.8814ZM17.8628 13.6832V13.8287H17.4261V15.405H17.2639V13.8287H16.8324V13.6832H17.8628Z"
          fill="#231F20"
        />
        <path
          d="M19.2145 14.9829L19.7937 13.574L20.3725 14.9829L20.8424 13.6657H21.0592L20.3725 15.514L19.7937 14.0981L19.2145 15.514L18.5295 13.6657H18.7451L19.2145 14.9829Z"
          fill="#231F20"
        />
        <path
          d="M19.7771 13.5676C19.7771 13.5676 19.2502 14.8467 19.2156 14.931C19.1853 14.8456 18.7578 13.6472 18.7578 13.6472H18.5024L19.2146 15.5665C19.2146 15.5665 19.7614 14.2287 19.7938 14.148C19.8263 14.2287 20.3731 15.5665 20.3731 15.5665L21.0853 13.6472H20.8295C20.8295 13.6472 20.4018 14.8456 20.3716 14.931C20.3365 14.8467 19.7938 13.5256 19.7938 13.5256L19.7771 13.5676ZM19.2318 14.9896C19.2318 14.9896 19.7614 13.7023 19.7938 13.6241C19.8263 13.7023 20.3736 15.0348 20.3736 15.0348C20.3736 15.0348 20.8468 13.7074 20.8556 13.6832H21.0319C21.0152 13.73 20.4036 15.3782 20.3716 15.4631C20.3376 15.3793 19.7938 14.0498 19.7938 14.0498C19.7938 14.0498 19.2491 15.3793 19.2151 15.4631C19.1843 15.3782 18.5736 13.73 18.5558 13.6832H18.7316C18.74 13.7074 19.2136 15.0348 19.2136 15.0348L19.2318 14.9896Z"
          fill="#231F20"
        />
        <path
          d="M23.636 14.5464C23.637 14.7278 23.5832 14.9055 23.4814 15.0568C23.3796 15.2081 23.2343 15.3264 23.064 15.3965C22.8937 15.4666 22.706 15.4855 22.5248 15.4508C22.3436 15.416 22.1769 15.3291 22.046 15.2012C21.915 15.0733 21.8257 14.9101 21.7893 14.7322C21.7529 14.5544 21.7711 14.3699 21.8415 14.2022C21.9119 14.0345 22.0314 13.8911 22.1849 13.7902C22.3383 13.6893 22.5188 13.6354 22.7035 13.6354C22.8257 13.6349 22.9467 13.6581 23.0598 13.7036C23.1728 13.7492 23.2756 13.8162 23.3622 13.9008C23.4488 13.9854 23.5176 14.086 23.5646 14.1968C23.6116 14.3076 23.6358 14.4264 23.636 14.5464ZM21.9697 14.5443C21.9712 14.6864 22.0155 14.8249 22.097 14.9424C22.1784 15.0599 22.2934 15.151 22.4275 15.2044C22.5616 15.2578 22.7087 15.271 22.8504 15.2424C22.9921 15.2138 23.1221 15.1447 23.2239 15.0437C23.3257 14.9427 23.3948 14.8144 23.4225 14.6749C23.4503 14.5354 23.4354 14.391 23.3797 14.2598C23.3241 14.1286 23.2302 14.0165 23.1099 13.9376C22.9896 13.8587 22.8482 13.8165 22.7035 13.8164C22.5082 13.818 22.3214 13.8954 22.1839 14.0318C22.0464 14.1682 21.9694 14.3524 21.9697 14.5443Z"
          fill="#231F20"
        />
        <path
          d="M21.752 14.5464C21.7509 14.7314 21.8058 14.9127 21.9096 15.0671C22.0135 15.2215 22.1617 15.3421 22.3354 15.4137C22.5091 15.4852 22.7006 15.5045 22.8855 15.4691C23.0704 15.4336 23.2404 15.345 23.374 15.2146C23.5076 15.0841 23.5987 14.9176 23.6359 14.7361C23.673 14.5547 23.6545 14.3665 23.5827 14.1954C23.5109 14.0243 23.389 13.878 23.2324 13.7751C23.0759 13.6721 22.8917 13.6172 22.7034 13.6171C22.5788 13.6167 22.4553 13.6404 22.34 13.6869C22.2248 13.7333 22.12 13.8017 22.0316 13.888C21.9432 13.9743 21.8731 14.0769 21.8251 14.1898C21.7771 14.3028 21.7523 14.424 21.752 14.5464ZM21.7886 14.5464C21.7866 14.3682 21.8385 14.1935 21.9379 14.0444C22.0372 13.8953 22.1793 13.7786 22.3463 13.7091C22.5133 13.6395 22.6975 13.6203 22.8756 13.6539C23.0537 13.6874 23.2177 13.7723 23.3466 13.8975C23.4755 14.0228 23.5637 14.1829 23.5998 14.3576C23.6359 14.5322 23.6184 14.7134 23.5494 14.8782C23.4805 15.043 23.3633 15.1839 23.2126 15.2831C23.062 15.3823 22.8847 15.4353 22.7034 15.4353C22.4623 15.4359 22.2307 15.3428 22.0593 15.1762C21.8879 15.0097 21.7906 14.7832 21.7886 14.5464Z"
          fill="#231F20"
        />
        <path
          d="M21.9509 14.5443C21.9523 14.69 21.9975 14.8321 22.0809 14.9526C22.1643 15.0731 22.2822 15.1667 22.4196 15.2216C22.557 15.2765 22.7079 15.2902 22.8532 15.261C22.9986 15.2318 23.1318 15.161 23.2363 15.0575C23.3408 14.9541 23.4118 14.8225 23.4403 14.6795C23.4688 14.5365 23.4537 14.3884 23.3967 14.2538C23.3398 14.1193 23.2435 14.0043 23.1202 13.9233C22.9968 13.8424 22.8519 13.7991 22.7035 13.7989C22.5032 13.7998 22.3113 13.8788 22.1702 14.0186C22.0291 14.1583 21.9502 14.3474 21.9509 14.5443ZM21.9887 14.5443C21.9902 14.4059 22.0334 14.271 22.1128 14.1566C22.1922 14.0422 22.3043 13.9535 22.4349 13.9015C22.5655 13.8495 22.7088 13.8367 22.8469 13.8646C22.9849 13.8925 23.1114 13.9599 23.2106 14.0583C23.3097 14.1567 23.377 14.2817 23.404 14.4176C23.431 14.5534 23.4165 14.6941 23.3623 14.8219C23.3081 14.9497 23.2166 15.0589 23.0994 15.1358C22.9822 15.2126 22.8445 15.2537 22.7035 15.2538C22.513 15.2527 22.3307 15.1774 22.1967 15.0444C22.0626 14.9113 21.9878 14.7315 21.9887 14.5443Z"
          fill="#231F20"
        />
        <path
          d="M24.6991 15.4235H24.5002V13.6658H24.7588C25.0978 13.6658 25.4129 13.7547 25.4129 14.1506C25.4165 14.2114 25.4078 14.2724 25.3871 14.3299C25.3665 14.3874 25.3344 14.4403 25.2927 14.4854C25.251 14.5306 25.2005 14.5671 25.1443 14.5928C25.088 14.6186 25.0271 14.6331 24.9651 14.6354L25.5342 15.4235H25.2894L24.7495 14.6513H24.6991V15.4235ZM24.6991 14.4791H24.7609C24.9859 14.4791 25.2141 14.4375 25.2141 14.1645C25.2141 13.8761 24.998 13.8473 24.7588 13.8473H24.6991V14.4791Z"
          fill="#231F20"
        />
        <path
          d="M24.5002 13.6472H24.4814V15.442H24.718V14.6698H24.7487C24.7487 14.6832 25.2747 15.4337 25.2747 15.4337L25.5342 15.442H25.5709C25.5709 15.442 25.0246 14.6868 24.9962 14.6462C25.1198 14.6344 25.2339 14.5764 25.315 14.4841C25.3961 14.3918 25.4379 14.2723 25.4316 14.1505C25.4316 13.8112 25.2118 13.6472 24.7587 13.6472H24.5002ZM24.7587 13.6832C25.1921 13.6832 25.3945 13.8318 25.3945 14.1505C25.3978 14.2091 25.3893 14.2677 25.3693 14.323C25.3494 14.3783 25.3184 14.4292 25.2783 14.4726C25.2382 14.5161 25.1897 14.5512 25.1356 14.5761C25.0815 14.6009 25.0229 14.615 24.9633 14.6174L24.9303 14.6205C24.9303 14.6205 25.46 15.3515 25.4987 15.405H25.2899C25.2899 15.3917 24.764 14.641 24.764 14.641L24.699 14.6333H24.6802V15.405H24.5186V13.6832H24.7587Z"
          fill="#231F20"
        />
        <path
          d="M24.6992 13.8287H24.6804V14.4971H24.761C24.9808 14.4971 25.2329 14.4591 25.2329 14.1645C25.2329 13.8545 24.9881 13.8287 24.7589 13.8287H24.6992ZM24.7589 13.8668C24.9886 13.8668 25.1953 13.8883 25.1953 14.1645C25.1953 14.4251 24.9761 14.4601 24.761 14.4601H24.7181V13.8668H24.7589Z"
          fill="#231F20"
        />
        <path
          d="M27.3408 13.6658H27.6088L26.7689 14.4847L27.637 15.4235H27.3618L26.6282 14.6169L26.5783 14.6647V15.4235H26.3789V13.6658H26.5783V14.4184L27.3408 13.6658Z"
          fill="#231F20"
        />
        <path
          d="M27.6087 13.6472H27.3333C27.3333 13.6472 26.655 14.3161 26.5976 14.3747V13.6472H26.3611V15.442H26.5976V14.6678C26.6084 14.6608 26.6182 14.6525 26.6268 14.6431C26.652 14.6698 27.348 15.4358 27.348 15.4358L27.6369 15.442H27.6797C27.6797 15.442 26.8179 14.5099 26.7933 14.4853C26.8194 14.46 27.653 13.6472 27.653 13.6472H27.6087ZM27.563 13.6832C27.5034 13.7423 26.7426 14.4837 26.7426 14.4837C26.7426 14.4837 27.5421 15.3474 27.595 15.405H27.3616C27.3616 15.3957 26.6416 14.6045 26.6416 14.6045L26.629 14.5896L26.5651 14.6523C26.5651 14.6523 26.5599 15.37 26.5599 15.405H26.3982V13.6832H26.5599V14.4632C26.5599 14.4632 27.3375 13.694 27.348 13.6832H27.563Z"
          fill="#231F20"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_5005_78357"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.9197 9.59298) scale(2.81625 2.76684)"
        >
          <stop offset="0.00562" stopColor="#FFEFE1" />
          <stop offset="0.10919" stopColor="#FFEAD8" />
          <stop offset="0.27823" stopColor="#FDDCC0" />
          <stop offset="0.49169" stopColor="#FBC698" />
          <stop offset="0.74115" stopColor="#F8A761" />
          <stop offset="1" stopColor="#F58220" />
        </radialGradient>
        <clipPath id="clip0_5005_78357">
          <rect
            width="38"
            height="24"
            fill="white"
            transform="translate(1 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DiscoverCardIcon;
