import { X, MagnifyingGlass } from "@phosphor-icons/react";
import { styled, useAppTheme } from "@theme/v2/Provider";
import GiveIconButton from "shared/IconButton/GiveIconButton";
import { typography } from "@theme/v2/typography";
import { GiveInput } from "shared/GiveInputs/GiveInput";
import { SxProps } from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import { debounce } from "lodash";

export type GiveSearchBarProps = {
  handleChange: (val: string) => void;
  value: string;
  placeholder?: string;
  iconSize?: number;
  sx?: SxProps;
  searchOnEnter?: boolean;
};

const GiveSearchBar: React.FC<GiveSearchBarProps> = ({
  handleChange,
  value = "",
  placeholder,
  iconSize = 24,
  searchOnEnter = false,
  ...props
}) => {
  const { palette } = useAppTheme();
  const [inputValue, setInputValue] = useState(value);

  const handleDeleteText = () => {
    setInputValue("");
    handleChange("");
  };

  const debouncedChangeHandler = useCallback(
    debounce((val: string) => handleChange(val), 300),
    [handleChange],
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event?.target?.value.replace(/[!()]+/g, "");
    setInputValue(newValue);

    if (searchOnEnter) return;
    debouncedChangeHandler(newValue?.trim());
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (searchOnEnter && ["Enter", "NumpadEnter"].includes(event.code)) {
      handleChange(inputValue);
    }
  };

  const handleOnBlur = () => {
    if (inputValue) return;
    handleChange("");
  };

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value);
    }
  }, [value]);

  const handleInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <StyledInput
      placeholder={placeholder || "Search"}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      onBlur={handleOnBlur}
      onClick={handleInputClick}
      value={inputValue}
      rightContent={
        inputValue && (
          <GiveIconButton
            size="extraSmall"
            variant="filled"
            onClick={handleDeleteText}
            Icon={X}
          />
        )
      }
      leftContent={
        <MagnifyingGlass
          width={iconSize}
          height={iconSize}
          fill={palette.icon?.["icon-secondary"]}
        />
      }
      {...props}
    />
  );
};

const StyledInput = styled(GiveInput)(({ theme }) => ({
  border: "none",
  "& .MuiInputBase-root": {
    borderRadius: "40px",
    padding: "8px 16px",
    height: "40px",
    "& > svg ": {
      flexShrink: "0",
    },
    "&.Mui-focused .MuiInputAdornment-root.MuiInputAdornment-positionStart svg path":
      {
        fill: theme.palette.primitive?.blue["100"],
      },
    "&:hover:not(.Mui-focused) svg path": {
      fill: theme.palette.icon?.["icon-primary"],
    },
    "& input": {
      fontSize: typography.bodyS.fontSize,
      lineHeight: typography.bodyS.lineHeight,
      fontWeight: typography.bodyS.fontWeight,
      color: theme.palette.text.primary,
      padding: 0,
      height: "24px",
      marginLeft: 0,
    },
    "& input::-webkit-input-placeholder": {
      color: theme.palette.text.secondary,
      opacity: 1,
    },
    ":hover:not(.Mui-disabled, .Mui-error):before": {
      borderBottom: "none",
    },
    "& input:placeholder-shown ~ div": {
      display: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      transition: "border-color 0.2s",
      zIndex: 0,
    },
    // This was added to fix the issue when content was not visible when focused
    "&.Mui-focused": {
      zIndex: 0,
      "& input": {
        position: "relative",
        zIndex: 1,
      },
      "& .MuiInputAdornment-root": {
        position: "relative",
        zIndex: 1,
      },
    },
  },
}));

export default GiveSearchBar;
