import { TCaseEvidence } from "@components/Disputes/DisputePreview/data.types";
import { customInstance } from "@services/api";
import { useQuery } from "react-query";

const getDisputeCaseEvidence = (disputeId: string, caseId: string) => {
  return customInstance({
    url: `/disputes/${disputeId}/cases/${caseId}/evidence`,
    method: "GET",
  });
};

type Props = {
  disputeId: string;
  caseId: string;
};

export const useGetCaseEvidence = ({ disputeId, caseId }: Props) => {
  const { data, isLoading } = useQuery(
    ["get-case-evidence", disputeId, caseId],
    async () => {
      const evidence = await getDisputeCaseEvidence(disputeId, caseId);

      return evidence?.data || [];
    },
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(disputeId && caseId),
    },
  );

  return { isLoading, data: data as TCaseEvidence[] };
};
