export const useUndeletableWord = (
  element: HTMLTextAreaElement,
  word: string | undefined,
  isHashtag?: boolean,
) => {
  const autoFocus = Boolean(word);

  const keyDownExtender = (event: any) => {
    const prevLength = element.selectionEnd;
    const prevLengthCondition =
      word &&
      (event.key === "Backspace" || event.key === "Delete") &&
      (isHashtag
        ? prevLength <= word.trim()?.length + 1
        : prevLength <= word.trim()?.length);
    if (prevLengthCondition) {
      event.preventDefault();
    }

    const prevSelectionCondition =
      word &&
      (isHashtag
        ? element.selectionStart < word.length + 1
        : element.selectionStart < word.length);

    if (prevSelectionCondition) {
      if (isHashtag) {
        element.selectionStart = word.length
      } else {
        element.selectionStart = word.length - 1
      }
    }
  };

  return {
    keyDownExtender,
    autoFocus,
  };
};
