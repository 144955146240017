import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import NiceModal from "@ebay/nice-modal-react";
import { Grid, Stack } from "@mui/material";
import GiveButton from "@shared/Button/GiveButton";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import { useState } from "react";
import { Product } from "../components/products/types";
import { styled, useAppTheme } from "@theme/v2/Provider";
import GiveText from "@shared/Text/GiveText";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { showMessage } from "@common/Toast";
import { LinkSimple } from "@phosphor-icons/react";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import PublicUrlShare from "../LaunchStep/PublicURLShare/PublicURLShare";
import QuantityInput from "../components/products/QuantityInput";

const defaultValues = {
  quantity: 1,
};

type Props = {
  product: Product;
  addToCart: (item: Product, addedQuantity?: number) => void;
};

// TODO ADD QUANTITY INPUT COMPONENT, AS IT HAS A SPECIFIC STYLING

const PUBLIC_PRODUCT_ITEM_MODAL = NiceModal.create(
  ({ product, addToCart }: Props) => {
    const { id, thumbnail, amount, title, description, in_stock } = product;
    const [quantity, setQuantity] = useState<number>(defaultValues.quantity);
    const { open, onClose } = useNiceModal();
    const { isMobileView, isDesktopView } = useCustomThemeV2();
    const [helper, setHelper] = useState<string | null>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const { palette } = useAppTheme();

    const reset = () => {
      setHelper(null);
      setQuantity(defaultValues.quantity);
    };

    const onSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      try {
        addToCart(product, quantity);
        showMessage("Success", "Product Successfully added to cart");
      } catch (error) {
        showMessage(
          "Error",
          "Item could not be added to cart, please try again",
        );
        return;
      } finally {
        closeModal();
        reset();
      }
    };

    const handleIncrease = () => {
      if (in_stock && quantity < in_stock) {
        setQuantity(quantity + 1);
        setHelper(null);
      } else {
        setHelper(`Quantity cannot exceed ${in_stock}`);
      }
    };
    const handleDecrease = () => {
      if (quantity > 1) {
        setQuantity(quantity - 1);
        setHelper(null);
      }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setQuantity(Number(value));
    };

    const closeModal = () => {
      reset();
      onClose();
    };

    return (
      <GiveBaseModal
        open={open}
        title={title}
        width={isDesktopView ? "980px" : "100%"}
        height="auto"
        onClose={closeModal}
        showFooter={false}
        headerRightContent={
          <>
            <GiveIconButton
              bgColor="darken"
              onClick={(e) => setAnchorEl(e.currentTarget)}
              Icon={LinkSimple}
            />
            <PublicUrlShare
              variant="strict"
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
            />
          </>
        }
      >
        <form onSubmit={onSubmit}>
          <Grid
            container
            gap="40px"
            sx={{
              gridTemplateColumns: isMobileView
                ? "repeat(1, 1fr)"
                : "repeat(2, 1fr)",
              flexWrap: isMobileView ? "wrap" : "nowrap",
            }}
            onSubmit={onSubmit}
          >
            <ProductImage src={thumbnail} alt={title} isMobile={isMobileView} />
            <ProductDetail>
              <GiveText variant="h3" color="primary">
                {title}
              </GiveText>
              <GiveText variant="bodyS" color="secondary">
                {description}
              </GiveText>
              <GiveText variant="bodyS" color="primary">
                ${amount}
              </GiveText>
              <ProductActions>
                <Stack direction="row" gap="12px">
                  <QuantityInput
                    quantity={quantity}
                    handleChange={handleChange}
                    handleDecrement={handleDecrease}
                    handleIncrement={handleIncrease}
                  />
                  <GiveButton
                    onClick={onSubmit}
                    size="large"
                    label={`Add to cart - $${amount}`}
                    sx={{
                      minWidth: isMobileView ? "230px" : "340px",
                      height: "42px",
                    }}
                  />
                </Stack>
                {helper && <GiveText variant="bodyS">{helper}</GiveText>}
              </ProductActions>
            </ProductDetail>
          </Grid>
        </form>
      </GiveBaseModal>
    );
  },
);

export default PUBLIC_PRODUCT_ITEM_MODAL;

const ProductDetail = styled(Stack)({
  gap: "20px",
});
const ProductActions = styled(Stack)({
  gap: "12px",
  paddingTop: "24px",
  alignItems: "flex-start",
});

const ProductImage = styled("img", {
  shouldForwardProp: (prop) => prop !== "isMobile",
})<{ isMobile: boolean }>(({ isMobile }) => ({
  objectFit: "cover",
  width: isMobile ? "min(350px,100%)" : "400px",
  height: isMobile ? "350px" : "400px",
  borderRadius: "8px",
  flexShrink: 0,
}));

const StyledIcon = styled(GiveIconButton)({
  "&:hover": {
    background: "transparent !important",
  },
});
