import React, { useEffect } from "react";
import { Box, Container } from "@mui/material";
import { styled } from "@theme/v2/Provider";
import { Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import GiveLink from "@shared/Link/GiveLink";
import ProductHeader from "./ProductHeader";
import ProductList from "./ProductList";
import GiveEmptyStateWrapper from "@shared/EmptyState/GiveEmptyStateWrapper";
import ProductHero from "./ProductHero";
import { useCart } from "@sections/PayBuilder/provider/CartContext";
import GiveButton from "@shared/Button/GiveButton";
import NiceModal from "@ebay/nice-modal-react";
import { PUBLIC_PRODUCT_ITEM_MODAL } from "modals/modal_names";
import { ProductItemType } from "@sections/PayBuilder/types";
import { Item_Layout } from "@sections/PayBuilder/provider/provider.type";
import { usePayBuilderContext } from "@sections/PayBuilder/provider/PayBuilderContext";

interface ProductPageContainerProps {
  displayStatus: "published" | "preview";
  children?: React.ReactNode;
  items: ProductItemType[];
  itemLayout: Item_Layout;
  header?: any;
}

const ProductPageContainer: React.FC<ProductPageContainerProps> = ({
  displayStatus,
  items,
  itemLayout,
  header,
}) => {
  const { isCartEmpty, totalAmount, totalItemsInCart, addToCart } = useCart();
  const {
    screenStates: { isMobileView },
  } = usePayBuilderContext();
  useEffect(() => {
    if (displayStatus == "published") {
      const searchParams = new URLSearchParams(location.search);
      const idParam = searchParams.get("sharedProductId");
      if (idParam) {
        const product = items.find((item) => item.id === idParam);
        if (product)
          NiceModal.show(PUBLIC_PRODUCT_ITEM_MODAL, { product, addToCart });
      }
    }
  }, [location.search, displayStatus]);

  const currentYear = new Date().getFullYear();

  const isEmpty = items.length == 0 && displayStatus == "published";
  return (
    <PageContainer displayStatus={displayStatus}>
      {displayStatus === "published" && <ProductHeader />}
      {displayStatus === "published" ? (
        <StyledPlaceholder>
          <GiveText>Here hoes ADB TRee</GiveText>
        </StyledPlaceholder>
      ) : (
        <ProductHero {...header} />
      )}
      <StyledContainer>
        <GiveEmptyStateWrapper isEmpty={isEmpty} section="empty-products">
          <ProductList
            displayStatus={displayStatus}
            items={items}
            itemLayout={itemLayout}
          />
        </GiveEmptyStateWrapper>
      </StyledContainer>

      {displayStatus === "published" && (
        <FooterWrapper
          sx={{
            flexDirection: isMobileView ? "column-reverse" : "row",
          }}
        >
          <GiveText variant="bodyS">
            All rights reserved ©Give Corporation {currentYear}
          </GiveText>
          <Stack direction="row" gap="24px">
            <GiveLink link="#">Privacy Policy</GiveLink>
            <GiveLink link="#">Terms & Conditions</GiveLink>
          </Stack>
        </FooterWrapper>
      )}
      {displayStatus === "published" && !isCartEmpty && (
        <StyledFooter direction="row">
          <Wrapper maxWidth="md">
            <GiveText>
              {`${totalItemsInCart} item${
                totalItemsInCart !== 1 ? "s" : ""
              } - $${totalAmount.toFixed(2)}`}
            </GiveText>
            <Stack direction="row" gap="12px">
              {/* add new colors and styles for Button component in GiveButton */}
              <GiveButton size="large" color="light" label={"View Cart"} />
              <GiveButton size="large" label={"Checkout"} />
            </Stack>
          </Wrapper>
        </StyledFooter>
      )}
    </PageContainer>
  );
};

export default ProductPageContainer;

const PageContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== "displayStatus",
})<{ displayStatus: string }>(({ displayStatus }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  minHeight: "100dvh",
  "& .MuiContainer-root": {
    paddingLeft: displayStatus === "preview" ? "20px" : "40px",
    paddingRight: displayStatus === "preview" ? "20px" : "40px",
    maxWidth: displayStatus === "preview" ? "100%" : "1090px",
    paddingBottom: "24px",
  },
}));

const FooterWrapper = styled(Stack)({
  padding: "24px 0",
  borderTop: "1px solid #CCCCC9",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "48px",
  gap: "24px",
});

const StyledPlaceholder = styled(Box)({
  padding: "40px 0",
});

const StyledContainer = styled(Stack)({
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  "& .empty_text p": {
    maxWidth: "340px",
    textAlign: "center",
  },
});

const Wrapper = styled(Container)({
  maxWidth: "1090px !important",
  display: "flex",
  justifyContent: "space-between",
  padding: "0 !important",
  alignItems: "center",
});

const StyledFooter = styled(Stack)({
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  height: "66px",
  padding: "16px",
  borderTop: "1px solid #E5E5E3",
  backdropFilter: "blur(15px)",
  background: "#FAF8F5B2",
  display: "flex",
  justifyContent: "center",
});
