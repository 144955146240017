import { UseQueryOptions, useQuery } from "react-query";
import { customInstance } from "@services/api";
import { useGetCurrentMerchantId } from "@hooks/common";
import { updatePermissions } from "@redux/slices/app";
import { useAppDispatch } from "@redux/hooks";
import { isDefined } from "@utils/helpers";
import { TRiskLevel } from "@components/Merchants/MerchantPreview/RiskProfile/types";
import { TOFACLastCheckType } from "@components/Merchants/MerchantPreview/data.types";
import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";
import { useGetMerchantAcquirer } from "@services/api/merchants";
import { useMemo } from "react";
import { QKEY_GET_MERCHANT_BY_ID } from "@constants/queryKeys";

export const getMerchantById = (id: number | string) => {
  return customInstance({
    url: `/merchants/${id}`,
    method: "GET",
  });
};

export const useGetMerchantById = (
  custom?: Omit<UseQueryOptions<any, any, any, any>, "queryKey" | "queryFn"> & {
    merchantId?: number | string;
  },
) => {
  const { merchantId, selectedUser: s } = useGetCurrentMerchantId();
  const { isMasqueradeMode } = useMasqueradeReducer();

  const dispatch = useAppDispatch();
  const merchantIdCustom = custom?.merchantId || merchantId;
  const selectedUser: any = s;
  const userRole = selectedUser?.role || selectedUser?.merchType;
  const enabled =
    custom?.enabled !== undefined ? custom?.enabled === true : true;
  const enableAcquirerCall =
    enabled &&
    isDefined(merchantIdCustom) &&
    ((userRole && userRole !== "acquirer") || isMasqueradeMode);

  const { data: acquirerData } = useGetMerchantAcquirer(merchantIdCustom, {
    enabled: enableAcquirerCall,
  });

  const { data, ...rest } = useQuery(
    [QKEY_GET_MERCHANT_BY_ID, merchantIdCustom],
    async () => {
      const merchantPromise = getMerchantById(merchantIdCustom);
      return merchantPromise;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 10000,
      enabled: isDefined(merchantIdCustom),
      onError(err: any) {
        if (err.not_authorized) {
          dispatch(
            updatePermissions({
              get_merchant_by_id: true,
            }),
          );
        }
      },
      ...custom,
    },
  );
  const memoizedData: any & { acquirerTcVersion: string } = useMemo(() => {
    if (!data) return data;
    return {
      ...data,
      aquirerTcVersion: acquirerData?.msaVersion,
    };
  }, [data, acquirerData]);
  return {
    data: memoizedData,
    ...rest,
  };
};

export type Checklist = {
  accID: number;
  checked: boolean;
  checklistItemID: number;
  description?: string;
  level: number;
  name: string;
  notes?: string;
  position: number;
};

export type MerchantData = {
  // THIS MIGHT CHANGE
  totalPayoutAmount: { amount: number; percentage: number };
  // --
  accID: number;
  accTypeID: number;
  parentAccID: null | number;
  type: string;
  ownerEmail?: string;
  ownerMembershipstatus?: string;
  ownerInviteLastSentAt: number;
  name: string;
  slug: string;
  level?: string;
  checklist: Checklist[];
  description: string;
  imageURL: string;
  underwriterImageURL?: string;
  websiteURL: string;
  phoneNumber: string;
  billingDescriptor: string;
  canProcessMoney: boolean;
  hasProcessedMoney: boolean;
  statusDisplayName: string;
  canTransferMoney: boolean;
  allowVolunteers: boolean;
  isController: boolean;
  servicePhoneNumber: string;
  ownershipType: string;
  statusName?: string;
  allowAddingBankAccounts: boolean;
  allowP2P: boolean;
  creditCardFees: number;
  amexCreditCardFees: number;
  debitCardFees: number;
  termsAcceptedAt: null | number;
  termsAcceptedLastAt: null | number;
  underwriterName?: string;
  underwriterID?: number;
  createdAt: number;
  updatedAt: number;
  deletedAt: null | number;
  categoryCode: null | string;
  allowedCategoryCodes: null | string[];
  parentName: string;
  totalProcessed: number;
  assignment?: string;
  underwriterSetByAcquirer: boolean;
  watchlist: boolean;
  hasMID: boolean;
  categoryCodeTitle: string;
  principalUploadedIdentityProof: boolean;
  lastEscalationAt: number;
  lastRiskActivityAt: number;
  lastRiskActivitySlug: string;
  lastRiskActivityDescription: string | undefined;
  lastRiskActivityName?: string;
  isProfileCompleted?: boolean;
  riskProfileID: number;
  riskLevelLabel?: TRiskLevel;
  ownerInviteStatusDisplayName?: string;
  riskStatusDisplayName?:
    | "routine_monitoring"
    | "alerted"
    | "active_monitoring";
  underwritingScorePercentage: number;
  owner: {
    firstName: string;
  };
  underwritingPendingChallengesPercentage: number;
  underwritingPendingChallengesCount: number;
  approvedBy: string;
  approvedByFirstName: string;
  approvedByLastName: string;
  approvedAt: string;
  challengesPending: number;
  hasUnreadMessages: boolean;
  underwriterEmail?: string;
  entityOfac: TOFACLastCheckType;
  signupType: string;
  signupTypeDisplayName: string;
  ownerMembershipStatus?: string;
  OwnerInviteLastSentAt?: number | null;
  underwritingChallengeScorePercentage?: number;
  merchantRiskStatus?: string | null;
  sponsorStatusDisplayName?: string;
  sponsorStatusLastUpdatedAt: number | null;
  lastInternalApprovalAt: number | null;
  lastInternalApprovalTypeDisplayName?: string;
  hasUnrepliedActivityMessages?: boolean;
  lastMessageActivity?: LastMessageActivityType;
  declinedBy: number;
  declinedByFirstName: string;
  declinedByLastName: string;
  declinedAt: number | null;
  suspendedBy: number;
  suspendedByFirstName: string;
  suspendedByLastName: string;
  suspendedAt: number | null;
};

export type LastMessageActivityType = {
  id: number;
  subject: string;
  createdAt: number;
  authorAvatar: string;
  body: string;
};

export type MerchantStatsType = {
  countInvited?: number;
  countApproved: number;
  countInoperative: number;
  countUnderwriting: number;
  countTotal: number;
  countAquirerUnderwriting: number;
  countRisk: number;
  countTotalAquirer: number;
  sponsorCount?: number;
};
