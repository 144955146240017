import React, { useState } from "react";
import { Box, Avatar } from "@mui/material";
import { useAppSelector } from "@redux/hooks";
import { selectMasqueradeMode } from "@redux/slices/app";
import { useAccountSelection } from "@components/Login/useAccountSelectionModal";
import { useGetMerchantById } from "@hooks/enterprise-api/account/useGetMerchants";
import { useGetCurrentMerchantId } from "@hooks/common";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import SwitchAccountsMenu from "./SwitchAccountsMenu";
import ContextualMenuOption from "@shared/ContextualMenu/ContextualMenuOption";
import { styled } from "@theme/v2/Provider";

type DrawerProps = {
  drawerOpen: boolean;
};

const DrawerAccountSelect = ({ drawerOpen }: DrawerProps) => {
  const { name: masqName } = useAppSelector(selectMasqueradeMode);

  const { accountList, chooseAccount } = useAccountSelection();
  const { data: merchantData } = useGetMerchantById();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { merchantId, name, img, isControlMode } = useGetCurrentMerchantId();
  const avatarImage = img || merchantData?.imageURL + "/thumb";
  const merchantName =
    name || accountList.find((el) => el.id === merchantId)?.name || "";

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (accountList.length === 0 || isControlMode) return;
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onPress = (idx: number) => {
    chooseAccount(idx);
    setAnchorEl(null);
  };

  return (
    <Box
      flexShrink={0}
      sx={{
        width: drawerOpen ? "auto" : "fit-content",
        alignSelf: drawerOpen ? "auto" : "center",
        overflow: "hidden",
        ...(accountList.length > 1 && {
          cursor: "pointer",
        }),
      }}
    >
      {drawerOpen ? (
        <ContextualMenuOption
          onClick={handleOpenMenu}
          text={masqName || merchantName}
          IconRight={anchorEl ? CaretUp : CaretDown}
          Image={<StyledAvatar src={avatarImage} />}
        />
      ) : (
        <StyledAvatar src={avatarImage} onClick={handleOpenMenu} />
      )}

      <SwitchAccountsMenu
        accountList={accountList}
        chooseAccount={onPress}
        drawerOpen={drawerOpen}
        anchorEl={anchorEl}
        onCloseMenu={() => setAnchorEl(null)}
      />
    </Box>
  );
};

const StyledAvatar = styled(Avatar)(() => ({
  borderRadius: 8,
  height: 40,
  width: 40,
}));

export default React.memo(DrawerAccountSelect);
