import { Box, Divider, Grid, Stack } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import * as Yup from "yup";
import {
  ProfileSetupFormActions,
  ProfileSetupFormContainer,
  ProfileSetupFormTitle,
} from "../form.components";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { RHFInput, RHFTelInput } from "@common/Input";
import { RHFCustomAmountInput } from "@common/CustomAmountInput";
import { RHFCheckbox } from "@common/Checkbox";
import { matchIsValidTel } from "mui-tel-input";
import { urlSchema } from "@utils/validation.helpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { MerchantSlugInput, NameInput } from "@common/BusinessProfileInputs";
import { removeSpecialChars } from "@utils/slug";
import WebsiteInput from "@common/BusinessProfileInputs/WebsiteInput";
import { generateNameLabelPlaceholder } from "@components/Signup/Forms/SignupOrganizationDetails";
import usePercentageUpdate from "./hooks/usePercentageUpdate";
import { TBusinessStepsCommons } from "./BusinessProfileSetupNew";
import { DynamicReturnType } from "./helpers/refineData";
import placeholder from "@assets/images/avatar-placeholder.png";
import { VisaLogo } from "@assets/logos";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import {
  BillingDescriptorInput,
  StatementPreview,
} from "./BillingDescriptorSection";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { gridItemsRenderer } from "@utils/rendering/nodesRenderers";
import {
  BILLING_DESCRIPTOR_MAX_LENGTH,
  DESCRIPTOR_PREFIX,
  SLUG_MAX_CHARACTER_LENGTH,
} from "@constants/constants";
import { formatBillingDescriptor } from "@components/AcquirerEnterprises/EnterprisePreview/modals/EditEnterpriseInfoModal";
import WarningBanner from "./WarningBanner";

interface IProps extends TBusinessStepsCommons {
  data: DynamicReturnType["merchantInfo"];
  warningMessage: string | null;
  isIncomplete: boolean;
  getHelperText: (value: string) => string | undefined;
}

type FormInputs = {
  name: string;
  slug: string;
  servicePhoneNumber: string;
  websiteURL: string;
  annualCreditCardSalesVolume: string;
  averageTicketAmount: string;
  serviceCountriesOutUSCanada: boolean;
  highTicketAmount: string;
  countriesServicedOutside: string;
  billingDescriptor: string;
};

const ALLOW_EMPTY = {
  websiteURL: true,
};

const getCustomData = (data: FormInputs) => {
  return {
    ...data,
    ...(!data.billingDescriptor && {
      billingDescriptor: data.name.replaceAll(" ", "-"),
    }),
  };
};

const MerchantInfo = forwardRef(
  (
    {
      submitHandler,
      handleBack,
      updateStatusBar,
      data,
      isSubmitting,
      warningMessage,
      isIncomplete,
      getHelperText,
    }: IProps,
    ref,
  ) => {
    const { isMobileView } = useCustomTheme();
    const displayedName = generateNameLabelPlaceholder(data.class.name);
    const avatarImage = data?.imageURL ? `${data.imageURL}/thumb` : placeholder;

    const isUpdateAllowed = useAccessControl({
      resource: RESOURCE_BASE.MERCHANT,
      operation: OPERATIONS.UPDATE,
    });

    const methods = useForm<FormInputs>({
      mode: "onChange",
      resolver: yupResolver(merchantInfoSchema),
      defaultValues: data,
    });

    const {
      watch,
      handleSubmit,
      formState: { dirtyFields, isDirty },
    } = methods;

    const values = watch();

    const { servicePhoneNumber, websiteURL } = values || {};

    usePercentageUpdate<FormInputs>(
      values,
      dirtyFields,
      merchantInfoSchema,
      updateStatusBar,
      [
        "description",
        "imageURL",
        ...(!values?.serviceCountriesOutUSCanada
          ? ["serviceCountriesOutUSCanada", "countriesServicedOutside"]
          : []),
      ],
    );

    useEffect(() => {
      if (!isDirty) return;

      const descriptor = formatBillingDescriptor(
        values.name.slice(0, BILLING_DESCRIPTOR_MAX_LENGTH),
      );
      const parsedSlug = removeSpecialChars(values.name)?.slice(
        0,
        SLUG_MAX_CHARACTER_LENGTH,
      );

      methods.setValue("billingDescriptor", descriptor, { shouldDirty: true });
      methods.setValue("slug", parsedSlug, { shouldDirty: true });
    }, [values.name]);

    const phoneNumberValue =
      !servicePhoneNumber || servicePhoneNumber === "+1"
        ? ""
        : servicePhoneNumber;

    const nodeList: Array<{
      node: JSX.Element;
      sm: number;
      shouldAnimate?: boolean;
      hidden?: boolean;
      hideTooltip?: boolean;
    }> = [
      {
        node: (
          <NameInput
            name="name"
            label={`${displayedName} Name`}
            helper={
              values.name === ""
                ? `The saved merchant name "${data?.name}" is used when the field is blank`
                : ""
            }
            isMerchantName
            disabled={!isUpdateAllowed}
          />
        ),
        sm: 12,
      },
      {
        node: (
          <MerchantSlugInput
            name="slug"
            parentName={data?.parentSlug}
            label="Permalink"
            disabled={!isUpdateAllowed}
          />
        ),
        sm: 12,
      },
      {
        node: (
          <WebsiteInput
            name="websiteURL"
            label={"Website URL"}
            disabled={!isUpdateAllowed}
            helperText={getHelperText(websiteURL)}
          />
        ),
        sm: 12,
      },
      {
        node: (
          <RHFTelInput
            label={`Merchant Phone Number`}
            name="servicePhoneNumber"
            fullWidth
            disabled={!isUpdateAllowed}
            helperText={getHelperText(phoneNumberValue)}
          />
        ),
        sm: 12,
      },
      {
        node: (
          <RHFCustomAmountInput
            name="annualCreditCardSalesVolume"
            label="Estimated Annual Revenue"
            placeholder="0"
            bounded={false}
            initialValue={data?.annualCreditCardSalesVolume}
            disabled={!isUpdateAllowed}
          />
        ),
        sm: 12,
      },
      {
        node: (
          <RHFCustomAmountInput
            name="highTicketAmount"
            label="High Ticket Amount (USD)"
            placeholder="0"
            bounded={false}
            initialValue={data?.highTicketAmount}
            disabled={!isUpdateAllowed}
          />
        ),
        sm: 12,
      },
      {
        node: (
          <RHFCustomAmountInput
            name="averageTicketAmount"
            label="Average Ticket Amount (USD)"
            placeholder="0"
            bounded={false}
            initialValue={data?.averageTicketAmount}
            disabled={!isUpdateAllowed}
          />
        ),
        sm: 12,
      },
      {
        sm: 12,
        node: (
          <RHFCheckbox
            name="serviceCountriesOutUSCanada"
            label="My Business services countries outside of the USA/Canada."
            disabled={!isUpdateAllowed}
          />
        ),
      },
      {
        node: (
          <RHFInput
            name="countriesServicedOutside"
            label="Countries Serviced Outside USA/Canada"
            placeholder="Please enter the countries serviced outside of USA/Canada."
            multiline
            rows={6}
            fullWidth
            disabled={!isUpdateAllowed}
          />
        ),
        sm: 12,
        shouldAnimate: false,
        hidden: !values?.serviceCountriesOutUSCanada,
      },
      {
        node: <Divider />,
        sm: 12,
        hidden: isMobileView,
        hideTooltip: true,
      },
      {
        node: (
          <BillingDescriptorInput
            billingDescriptor={values.billingDescriptor}
            prefix={data?.billingDescriptorPrefix}
            disabled={!isUpdateAllowed}
          />
        ),
        sm: 12,
      },
      {
        node: (
          <StatementPreview
            billingDescriptor={values.billingDescriptor}
            prefix={data?.billingDescriptorPrefix}
            avatarImage={avatarImage}
            issuer={<VisaLogo />}
            date=" 2 May 2024 14:05"
            amount="-12.99 USD"
            isMobileView={isMobileView}
          />
        ),
        sm: 12,
        hideTooltip: true,
      },
    ];

    const onSubmit: SubmitHandler<FormInputs> = (data) => {
      const customData = {
        ...data,
        ...(!data?.serviceCountriesOutUSCanada && {
          countriesServicedOutside: "",
        }),
      };

      const customDirtyFields = {
        ...dirtyFields,
        ...(dirtyFields?.serviceCountriesOutUSCanada && {
          countriesServicedOutside: true,
        }),
      };

      submitHandler("merchantInfo", getCustomData(customData), {
        makeApiCall: isDirty,
        dirtyFields: customDirtyFields,
        forbidRedirect: false,
        allowEmpty: ALLOW_EMPTY,
      });
    };

    const handleForceSubmit = async (cb?: any, param?: any) => {
      await handleSubmit((data) => {
        submitHandler("merchantInfo", getCustomData(data), {
          makeApiCall: isDirty,
          dirtyFields,
          forbidRedirect: true,
          allowEmpty: ALLOW_EMPTY,
          handleNext: cb,
          param: param,
        });
      })();
    };

    const backSaveHandler = async () => {
      await handleForceSubmit(handleBack);
    };

    useImperativeHandle(ref, () => ({
      execute: handleForceSubmit,
    }));

    return (
      <FormProvider {...methods}>
        <Box
          component="form"
          flexGrow={1}
          id="merchant-info-form"
          display="flex"
          onSubmit={handleSubmit(onSubmit)}
        >
          <ProfileSetupFormContainer>
            <Stack direction="column" gap={4} height="min-content">
              <ProfileSetupFormTitle title={`${displayedName} Info`} />
              {isIncomplete && <WarningBanner message={warningMessage} />}
              <Grid container rowSpacing="12px">
                {gridItemsRenderer(nodeList, {
                  show: !isUpdateAllowed,
                  message: EDIT_DENY_MESSAGE,
                })}
              </Grid>
            </Stack>
            <ProfileSetupFormActions
              secondaryAction={{
                onClick: backSaveHandler,
              }}
              primaryAction={{
                disabled: isSubmitting,
                children: "Done",
                onClick: handleSubmit(onSubmit),
              }}
            />
          </ProfileSetupFormContainer>
        </Box>
      </FormProvider>
    );
  },
);

MerchantInfo.displayName = "MerchantInfo";

export default MerchantInfo;

export const billingDescriptorSchema = Yup.string()
  .required("Billing descriptor is required")
  .matches(
    /^.{3,21}$/,
    `Descriptor must match this format '${DESCRIPTOR_PREFIX}*BILLINGDESCRIPTOR' and must be between 3 to ${BILLING_DESCRIPTOR_MAX_LENGTH} characters length`,
  );

export const merchantInfoSchema = Yup.object().shape({
  name: Yup.string(),
  slug: Yup.string(),
  servicePhoneNumber: Yup.string()
    .when({
      is: (exists: string) => Boolean(exists) && exists !== "+1",
      then: (schema) =>
        schema.test(
          "is-valid-number",
          "Please enter a valid phone number",
          function (value) {
            const phoneNumber = value as string;
            const isPhoneValid = matchIsValidTel(phoneNumber);

            if (isPhoneValid) {
              this.createError({
                message: "Plese enter a valid phone Number",
                path: "servicePhoneNumber",
              });
            }
            return matchIsValidTel(phoneNumber);
          },
        ),
    })
    .when({
      is: (exists: string) => {
        return Boolean(exists) && exists === "+1";
      },
      then: (schema) =>
        schema.test(
          "is-empty-number",
          "Please enter a phone number",
          function (value, ctx) {
            const phoneNumber = value as string;

            if (phoneNumber.length === 2) return true;

            return phoneNumber.length > 2 && matchIsValidTel(phoneNumber);
          },
        ),
    }),
  websiteURL: urlSchema({ isRequired: false }),
  annualCreditCardSalesVolume: Yup.string().transform(
    (value, originalValue) => {
      if (originalValue === "" || originalValue === undefined) {
        return "0.0";
      }
      return value;
    },
  ),
  averageTicketAmount: Yup.string().transform((value, originalValue) => {
    if (originalValue === "" || originalValue === undefined) {
      return "0.0";
    }
    return value;
  }),
  serviceCountriesOutUSCanada: Yup.boolean(),
  highTicketAmount: Yup.string().transform((value, originalValue) => {
    if (originalValue === "" || originalValue === undefined) {
      return "0.0";
    }
    return value;
  }),
  countriesServicedOutside: Yup.string(),
  billingDescriptor: Yup.string().test(
    "conditional-billing-descriptor",
    `Descriptor must match this format '${DESCRIPTOR_PREFIX}*BILLINGDESCRIPTOR' and must be between 3 to 24 characters length`,
    function (value) {
      if (!value) return true;
      return /^.{3,24}$/.test(value);
    },
  ),
});
