import { styled } from "@mui/material/styles";
import {
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
  Stack,
  StackProps,
} from "@mui/material";
import React from "react";
import { switchTheme } from "./SwitchTheme";
import { Text } from "@common/Text";
import { ITextProps } from "@common/Text/Text";

export type SwitchProps = MuiSwitchProps & {
  label?: React.ReactNode;
  helperText?: string;
  containerProps?: StackProps;
  sxText?: ITextProps;
};

export const Switch_V2 = styled(
  ({ containerProps, sxText, ...props }: SwitchProps) => {
    const [focused, setFocused] = React.useState(false);

    const onFocus = (e: React.FocusEvent<HTMLButtonElement, Element>) => {
      props.onFocus && props.onFocus(e);
      setFocused(true);
    };

    const onBlur = (e: React.FocusEvent<HTMLButtonElement, Element>) => {
      props.onBlur && props.onBlur(e);
      setFocused(false);
    };

    return (
      <Stack direction="row" alignItems="center" gap={2} {...containerProps}>
        <MuiSwitch onFocus={onFocus} onBlur={onBlur} disableRipple {...props} />
        {props.label && (
          <Text
            color={({ palette }) => palette.neutral[70]}
            height="100%"
            sx={sxText}
          >
            {props.label}
          </Text>
        )}
      </Stack>
    );
  },
)(({ theme, size = "medium" }) => ({
  width: switchTheme.size[size].width,
  height: switchTheme.size[size].height,
  padding: 0,
  overflow: "visible",
  // Default Knob styles
  "& .MuiSwitch-thumb": {
    boxShadow: "0px 2px 4px rgba(16, 24, 40, 0.15)",
    boxSizing: "border-box",
    borderRadius: "15px",
    width: switchTheme.size[size].knob.width,
    height: switchTheme.size[size].knob.height,
    margin: 2,
  },

  // Non switched default track
  "& .MuiSwitch-track": {
    borderRadius: switchTheme.size[size].height / 2,
    backgroundColor: switchTheme.switched.off.background,
    width: switchTheme.size[size].width,
    height: switchTheme.size[size].height,
    opacity: 1,
  },

  "& .MuiSwitch-switchBase": {
    padding: 0,
    transitionDuration: `${switchTheme.switched.transition}ms`,
    "&.Mui-checked": {
      color: switchTheme.switched.knob.color,
      transform: `translateX(calc(${switchTheme.size[size].width}px -  ${switchTheme.size[size].knob.width}px - 4px))`, // 4px is the border that appears on hover + margin
      "& + .MuiSwitch-track": {
        backgroundColor: switchTheme.switched.on.background,
        opacity: 1,
        border: 0,
        "&::after": {
          backgroundColor: switchTheme.switched.on.background,
        },
      },
    },

    // Switch on hover
    "&:hover + .MuiSwitch-track::before": {
      content: '""',
      width: switchTheme.size[size].width,
      height: switchTheme.size[size].height,
      borderRadius: switchTheme.size[size].height / 2,
      backgroundColor: "transparent",
      position: "absolute",
      left: 0,
      opacity: 1,
      transition: theme.transitions.create(["boxShadow"], {
        duration: switchTheme.transition,
      }),
      boxShadow: `0px 0px 0px rgba(2, 2, 2, 0.25), 0px 0px 0px ${switchTheme.hover.borderWidth}px ${switchTheme.hover.borderColor}`,
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      border: `6px solid ${switchTheme.switched.knob.color}`,
    },

    // Disabled default knob
    "&.Mui-disabled .MuiSwitch-thumb": {
      boxShadow: "none",
      opacity: 1,
      backgroundColor: switchTheme.disabled.knob.color,
    },

    // Disabled main track
    "&.Mui-disabled + .MuiSwitch-track": {
      boxShadow: "none",
      backgroundColor: switchTheme.disabled.track.color,
      opacity: 1,

      // Disabled inner track
      "&::after": {
        backgroundColor: switchTheme.disabled.track.color,
      },
    },
  },
}));