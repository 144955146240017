import { TreeNode } from "./types";

export const cartItemList = [
  {
    id: "1",
    thumbnail:
      "https://s3-alpha-sig.figma.com/img/3d05/e76d/002843366e681e73df7da2f3b69fa5c7?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dgkBOyn~bmIzSS7WrXBYlVHNPDD16EXM3s5kcGuyQJEK568G1IS0Un1VErfoATniaMfeGQtI0zXUHXZdhJungpRTRc1nYrHuJPJZnksRy4yDy9lbe2akd~5860YTrh4ns3-s05DhHIEIPiRlS8W9FzoUrWxghi~XSkvi1qUVbTZRrGE-jNI7dyEexi0NFs~Cn89BQgEsN3y5dUEOpyfcBRIShXfFSy8NaWt7lFMbLtJLZ9ExC57B~~PuU8OEniF19O6m3DvEzfV~oZoXyk9oJGRnxgQoBUy0fgMQ1Y2g~tcpHFaI4xXoweffYkVrbaEHO2Rjgz9v01UrI-O-Y88nqA__",
    amount: "12.00",
    title: "Mixed Scone Box",
    quantity: 1,
    description:
      "Our food and drinks are made by hand in our bakeries. We use many ingredients and shared equipment, therefore we cannot guarantee our food and drinks are free from allergens.",
  },
  {
    id: "2",
    thumbnail:
      "https://s3-alpha-sig.figma.com/img/3d05/e76d/002843366e681e73df7da2f3b69fa5c7?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dgkBOyn~bmIzSS7WrXBYlVHNPDD16EXM3s5kcGuyQJEK568G1IS0Un1VErfoATniaMfeGQtI0zXUHXZdhJungpRTRc1nYrHuJPJZnksRy4yDy9lbe2akd~5860YTrh4ns3-s05DhHIEIPiRlS8W9FzoUrWxghi~XSkvi1qUVbTZRrGE-jNI7dyEexi0NFs~Cn89BQgEsN3y5dUEOpyfcBRIShXfFSy8NaWt7lFMbLtJLZ9ExC57B~~PuU8OEniF19O6m3DvEzfV~oZoXyk9oJGRnxgQoBUy0fgMQ1Y2g~tcpHFaI4xXoweffYkVrbaEHO2Rjgz9v01UrI-O-Y88nqA__",
    amount: "12.00",
    title: "Mixed Scone Box",
    quantity: 2,
    description:
      "Our food and drinks are made by hand in our bakeries. We use many ingredients and shared equipment, therefore we cannot guarantee our food and drinks are free from allergens.",
  },
];
const products = [
  {
    id: "1",
    thumbnail:
      "https://s3-alpha-sig.figma.com/img/3d05/e76d/002843366e681e73df7da2f3b69fa5c7?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dgkBOyn~bmIzSS7WrXBYlVHNPDD16EXM3s5kcGuyQJEK568G1IS0Un1VErfoATniaMfeGQtI0zXUHXZdhJungpRTRc1nYrHuJPJZnksRy4yDy9lbe2akd~5860YTrh4ns3-s05DhHIEIPiRlS8W9FzoUrWxghi~XSkvi1qUVbTZRrGE-jNI7dyEexi0NFs~Cn89BQgEsN3y5dUEOpyfcBRIShXfFSy8NaWt7lFMbLtJLZ9ExC57B~~PuU8OEniF19O6m3DvEzfV~oZoXyk9oJGRnxgQoBUy0fgMQ1Y2g~tcpHFaI4xXoweffYkVrbaEHO2Rjgz9v01UrI-O-Y88nqA__",
    amount: "12.00",
    title: "Mixed Scone Box",
    in_stock: 12,
    description:
      "Our food and drinks are made by hand in our bakeries. We use many ingredients and shared equipment, therefore we cannot guarantee our food and drinks are free from allergens.",
  },
  {
    id: "2",
    thumbnail:
      "https://s3-alpha-sig.figma.com/img/3d05/e76d/002843366e681e73df7da2f3b69fa5c7?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dgkBOyn~bmIzSS7WrXBYlVHNPDD16EXM3s5kcGuyQJEK568G1IS0Un1VErfoATniaMfeGQtI0zXUHXZdhJungpRTRc1nYrHuJPJZnksRy4yDy9lbe2akd~5860YTrh4ns3-s05DhHIEIPiRlS8W9FzoUrWxghi~XSkvi1qUVbTZRrGE-jNI7dyEexi0NFs~Cn89BQgEsN3y5dUEOpyfcBRIShXfFSy8NaWt7lFMbLtJLZ9ExC57B~~PuU8OEniF19O6m3DvEzfV~oZoXyk9oJGRnxgQoBUy0fgMQ1Y2g~tcpHFaI4xXoweffYkVrbaEHO2Rjgz9v01UrI-O-Y88nqA__",
    amount: "12.00",
    title: "Mixed Scone Box",
    in_stock: 30,
    description:
      "Our food and drinks are made by hand in our bakeries. We use many ingredients and shared equipment, therefore we cannot guarantee our food and drinks are free from allergens.",
  },
  {
    id: "3",
    thumbnail:
      "https://s3-alpha-sig.figma.com/img/3d05/e76d/002843366e681e73df7da2f3b69fa5c7?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dgkBOyn~bmIzSS7WrXBYlVHNPDD16EXM3s5kcGuyQJEK568G1IS0Un1VErfoATniaMfeGQtI0zXUHXZdhJungpRTRc1nYrHuJPJZnksRy4yDy9lbe2akd~5860YTrh4ns3-s05DhHIEIPiRlS8W9FzoUrWxghi~XSkvi1qUVbTZRrGE-jNI7dyEexi0NFs~Cn89BQgEsN3y5dUEOpyfcBRIShXfFSy8NaWt7lFMbLtJLZ9ExC57B~~PuU8OEniF19O6m3DvEzfV~oZoXyk9oJGRnxgQoBUy0fgMQ1Y2g~tcpHFaI4xXoweffYkVrbaEHO2Rjgz9v01UrI-O-Y88nqA__",
    amount: "12.00",
    title: "Mixed Scone Box",
    in_stock: 1,
    description:
      "Our food and drinks are made by hand in our bakeries. We use many ingredients and shared equipment, therefore we cannot guarantee our food and drinks are free from allergens.",
  },
  {
    id: "4",
    thumbnail:
      "https://s3-alpha-sig.figma.com/img/3d05/e76d/002843366e681e73df7da2f3b69fa5c7?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dgkBOyn~bmIzSS7WrXBYlVHNPDD16EXM3s5kcGuyQJEK568G1IS0Un1VErfoATniaMfeGQtI0zXUHXZdhJungpRTRc1nYrHuJPJZnksRy4yDy9lbe2akd~5860YTrh4ns3-s05DhHIEIPiRlS8W9FzoUrWxghi~XSkvi1qUVbTZRrGE-jNI7dyEexi0NFs~Cn89BQgEsN3y5dUEOpyfcBRIShXfFSy8NaWt7lFMbLtJLZ9ExC57B~~PuU8OEniF19O6m3DvEzfV~oZoXyk9oJGRnxgQoBUy0fgMQ1Y2g~tcpHFaI4xXoweffYkVrbaEHO2Rjgz9v01UrI-O-Y88nqA__",
    amount: "12.00",
    title: "Mixed Scone Box",
    in_stock: null,
    description:
      "Our food and drinks are made by hand in our bakeries. We use many ingredients and shared equipment, therefore we cannot guarantee our food and drinks are free from allergens.",
  },
  {
    id: "5",
    thumbnail:
      "https://s3-alpha-sig.figma.com/img/3d05/e76d/002843366e681e73df7da2f3b69fa5c7?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dgkBOyn~bmIzSS7WrXBYlVHNPDD16EXM3s5kcGuyQJEK568G1IS0Un1VErfoATniaMfeGQtI0zXUHXZdhJungpRTRc1nYrHuJPJZnksRy4yDy9lbe2akd~5860YTrh4ns3-s05DhHIEIPiRlS8W9FzoUrWxghi~XSkvi1qUVbTZRrGE-jNI7dyEexi0NFs~Cn89BQgEsN3y5dUEOpyfcBRIShXfFSy8NaWt7lFMbLtJLZ9ExC57B~~PuU8OEniF19O6m3DvEzfV~oZoXyk9oJGRnxgQoBUy0fgMQ1Y2g~tcpHFaI4xXoweffYkVrbaEHO2Rjgz9v01UrI-O-Y88nqA__",
    amount: "12.00",
    title: "Mixed Scone Box",
    in_stock: 2,
    description:
      "Our food and drinks are made by hand in our bakeries. We use many ingredients and shared equipment, therefore we cannot guarantee our food and drinks are free from allergens.",
  },
  {
    id: "6",
    thumbnail:
      "https://s3-alpha-sig.figma.com/img/3d05/e76d/002843366e681e73df7da2f3b69fa5c7?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dgkBOyn~bmIzSS7WrXBYlVHNPDD16EXM3s5kcGuyQJEK568G1IS0Un1VErfoATniaMfeGQtI0zXUHXZdhJungpRTRc1nYrHuJPJZnksRy4yDy9lbe2akd~5860YTrh4ns3-s05DhHIEIPiRlS8W9FzoUrWxghi~XSkvi1qUVbTZRrGE-jNI7dyEexi0NFs~Cn89BQgEsN3y5dUEOpyfcBRIShXfFSy8NaWt7lFMbLtJLZ9ExC57B~~PuU8OEniF19O6m3DvEzfV~oZoXyk9oJGRnxgQoBUy0fgMQ1Y2g~tcpHFaI4xXoweffYkVrbaEHO2Rjgz9v01UrI-O-Y88nqA__",
    amount: "12.00",
    title: "Mixed Scone Box",
    in_stock: 4,
    description:
      "Our food and drinks are made by hand in our bakeries. We use many ingredients and shared equipment, therefore we cannot guarantee our food and drinks are free from allergens.",
  },
];

export const mockStructure: TreeNode = {
  id: "root",
  content: {
    logo: "https://s3-alpha-sig.figma.com/img/5c70/f6fe/c056b49e6be4ea30441a37321c5e8fa6?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=CKvfQtmw-mqjj8QyJXhywWAvSo5qDT09rL8h~uoHb~YfPrfDynaR~6WsksaelXBsC6aUQhMi5rEtpnzNtd49TEQivp4vwGGzAYJ2DKQewvIoGiswFR4v6SSepmlieuq2FUxJUFJFovaOWPaOISywJ7S0fXSnyUT62m8sf8aPuO02zsFhAWDI19AUgd0yFOlwszVD-RzfukddNvjPYY1VjqGiv5A3MxW7OSo1sp4RWdJUeI2-4Lkixl8XbFi7xrJYRNTeZvbIqVwGWhh2n6z9ZAP3GUFKy7KsFOHqD4X4Wv~6ZC45PJHY5i3cw0OfEiW0nlRc6JNz2dH5SLmhFsJyVA__",

    heading: "Make an Order",
    description:
      "Discover our plant-based pastries and savoury treats. Our bakers make all of our baked goods fresh every day",
    image: {
      url: "https://s3-alpha-sig.figma.com/img/f669/0a42/966c321c797b540ef2a68ef5403480f7?Expires=1732492800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ho9kLdQP-lzycih~xU6L4JSyn2VWHyNfqhecwmFi0Sbu1VhkEeSE7YclEwf37~LfSJtRWER35yZAgWz~vL7HERuwobRo44wfXM02bG9VqZrnl~gQuyAfO~ALljXNmc7nG--8ysNzAJ260diNVYBEWCGjpNItbDCeW~9LeyVo-2js3Dsh4b2iYz~mcbxmyDCJ6Krx7ljbvQLDnUoVmAurLkQf4zd0mxfnk5rUEcZ-d320ak2ffWWuDCYrIW~J~wZpIHCQ7oD5GbJM18FidDP0k~7ZCayf~OUZo4fCdUvG0NKzopN5Pen4~UvrCK9SA8rSJ~fY85mtKf~B3u5G8cDqgA__",
      position: "right",
    },
    displayStatus: "published",
    productDisplayMode: "card",
  },
  children: products,
};
