import { TColumnNames, TColumnTranslations } from "../types";

type TSectionNames = "summary" | "risk" | "customerInfo";
export const RISK_MONITOR_TRANSACTIONS: TColumnNames<TSectionNames> = {
  titles: {
    summary: "Transaction Summary",
    risk: "Risk",
    customerInfo: "Customer Information",
  },
  visible: ["Amount", "Transaction Status", "Risk Level"],
  summary: [
    "Merchant Name",
    "Provider Name",
    "Transaction Id",
    "Transaction Type",
    "Name on Card",
    "Card Type",
    "Card Number Last4",
    "Email",
    "Amount",
    "Fees",
    "Charged",
    "Ending Balance",
    "Transaction Status",
  ],
  risk: [
    "Risk Level",
    "Reason of Escalation",
    "Customer",
    "Name on Card",
    "Card Type",
    "Card Number Last4",
    "Risk Status Transaction",
    "Amount",
  ],
  customerInfo: [
    "Customer First Name",
    "Customer Last Name",
    "Customer Email",
    "Customer Date of Birth",
    "Customer Phone Number",
    "Customer Occupation",
    "Customer Employer",
    "Customer City",
    "Customer State",
    "Customer Zip",
    "Customer Address",
    "Customer Notes",
    "Customer total",
  ],
};

export const RISK_MONITOR_TRANSACTIONS_TRANSLATIONS: TColumnTranslations = {
  "Card Number Last4": "Card Number",
  Blocked: "Blocked Transaction",
  "Customer First Name": "First Name",
  "Customer Last Name": "Last Name",
  "Customer Email": "Email",
  "Customer Date of Birth": "Date of Birth",
  "Customer Phone Number": "Phone Number",
  "Customer Occupation": "Occupation",
  "Customer Employer": "Employer",
  "Customer City": "City",
  "Customer State": "State",
  "Customer Zip": "Zip",
  "Customer Address": "Address",
  "Customer Notes": "Notes",
  "Customer total": "Sum total",
  "Risk Status Transactions": "Risk Status Transactions",
};
