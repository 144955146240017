import { IFileWithMeta } from "react-dropzone-uploader";
import {
  deleteDocument,
  downloadDocument,
} from "@hooks/common/documents/utils";
import { useFormContext } from "react-hook-form";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useDocumentPreview } from "@hooks/common/documents";
import { TMerchantDocument } from "../data.types";
import { useQueryClient } from "react-query";
import { useEffect } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { DELETE_CONFIRMATION_MODAL } from "modals/modal_names";

export const useBusinessOwnerIDUploader = ({
  merchantId,
  idFile,
}: {
  merchantId: number;
  fileId?: number;
  idFile?: TMerchantDocument;
}) => {
  const queryClient = useQueryClient();

  const { isDesktopView } = useCustomTheme();

  const { setValue, watch, trigger } = useFormContext();

  useEffect(() => {
    if (idFile) {
      setValue("files", { allFiles: [idFile] });
    }
  }, []);

  const { files = { allFiles: [] } } = watch();

  const file = files.allFiles[0] || {};

  const isLocalFile = !!file.meta;
  const idUrl = isLocalFile ? file.meta?.previewUrl : file?.fileURL;
  const idName = isLocalFile ? file.meta?.name : file?.fileName;
  const idFileType = isLocalFile
    ? file?.meta?.type.split("/")[1]
    : file?.fileType;
  const idFileId = isLocalFile ? file?.meta?.id : file.id;
  const deleteID = async () => {
    //if file is locally selected
    if (isLocalFile) {
      files?.allFiles?.forEach((file: IFileWithMeta) => file?.remove());
      setValue("files", { allFiles: [] }, { shouldDirty: true });
      return;
    }
    // if file is from BE

    if (idFile) {
      NiceModal.show(DELETE_CONFIRMATION_MODAL, {
        variant: "document",
        deleteHandler: () => {
          setValue("uploadedfiles", watch("files"), { shouldDirty: true });
          setValue("files", { allFiles: [] }, { shouldDirty: true });
        },
        itemName: idFile.fileName || "",
      });
    }
  };

  const downloadFile = async () => {
    if (isLocalFile || idFile) {
      await downloadDocument(
        {
          fileName: idName,
          fileURL: idUrl,
        },
        isDesktopView,
      );
    }
  };

  const { handlePreview } = useDocumentPreview({
    merchantID: merchantId,
    handlers: { handleDownload: downloadFile },
  });

  const previewDocument = () => {
    // if no file url, skip preview
    if (!idUrl) return;
    const fileToPreview = {
      fileURL: idUrl,
      fileName: idName,
      fileType: idFileType,
      id: idFileId,
      tag: "legal_principal",
    } as TMerchantDocument;

    handlePreview(fileToPreview, [fileToPreview]);
  };

  return {
    deleteID,
    downloadFile,
    file,
    previewDocument,
    isLocalFile,
    idUrl,
  };
};
