import { useEffect, useState } from "react";
import { TCreateBusinessProfile } from "../types";
import { defaultValues as merchantDefaultValues } from "../schemas/CreateMerchantSchema";
import { TBusinessOwner } from "@components/Merchants/MerchantPreview/data.types";
import { FormType } from "./CreateBusinessProfileModal";
import { useModal } from "@ebay/nice-modal-react";
import { useUpdateMerchantInfo } from "@components/Merchants/MerchantPreview/hooks/useUpdateMerchantInfo";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ssnSchema, streetAddressValidator } from "@utils/validation.helpers";
import { matchIsValidTel } from "mui-tel-input";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  businessAddressParser,
  businessProfileParser,
  merchantBusinessOwnersParser,
} from "@components/Merchants/MerchantPreview/helpers/parsers";
import { getLegalEntityByID } from "@services/api/businessProfile";
import { format } from "date-fns";
import { getLegalEntityPrincipals } from "../helpers";
import { convertPhoneNumber } from "@utils/date.helpers";
import { useGetCurrentMerchantId } from "@hooks/common";

type FormInputs = TCreateBusinessProfile;
export type TinType = "ssn" | "ein";

const defaultValues = merchantDefaultValues.businessProfile;

type SubmitData = {
  businessProfile: TCreateBusinessProfile;
  businessOwners?: TBusinessOwner[];
};

export interface IUseBusinessProfileModal {
  data: FormInputs;
  onClose?: (data: SubmitData) => void;
  merchantId?: number;
  legalEntityID?: number;
  formType: FormType;
  isLegalEntityApproved?: boolean;
  isEdit?: boolean;
  isBPLinked?: boolean;
  merchantName?: string;
}

const useBusinessProfileModal = ({
  data,
  onClose,
  merchantId,
  legalEntityID,
  formType = FormType.CREATE_MERCHANT,
  isEdit = false,
  isBPLinked,
}: IUseBusinessProfileModal) => {
  const modal = useModal();
  const open = modal.visible;
  const { merchantId: currentMerchantId } = useGetCurrentMerchantId();

  const { handleSubmit, isLoading } = useUpdateMerchantInfo(
    merchantId || 0,
    legalEntityID,
  );

  const merchantType =
    formType === FormType.CREATE_MERCHANT
      ? RESOURCE_BASE.MERCHANT
      : RESOURCE_BASE.ENTERPRISE;
  const isUpdateAllowed = useAccessControl({
    resource: composePermission(merchantType, RESOURCE_BASE.LEGAL_ENTITY),
    operation: OPERATIONS.UPDATE,
  });
  const canEdit =
    !isEdit || (isEdit && isUpdateAllowed) || (isEdit && !merchantId);
  const canEditWithLegalEntity =
    !isEdit ||
    (isEdit && isUpdateAllowed && !isBPLinked) ||
    (isEdit && !merchantId);
  const showAccessDeniedTooltip = isEdit && !isUpdateAllowed && !!merchantId
  
  const schema = Yup.object().shape({
    isLinkBusinessProfile: Yup.boolean(),
    linkedBusinessProfile: Yup.number(),
    businessType: Yup.string(),
    ownershipType: Yup.string(),
    businessOpenedAt: Yup.string()
      .nullable()
      .test("businessOpenedAt", "test", function (value, ctx: any) {
        if (
          ctx.from[0].value.linkedBusinessProfile &&
          ctx.from[0].value.isLinkBusinessProfile
        )
          return true;
        if (!value) {
          return this.createError({
            message: `Date business opened is required`,
            path: "businessOpenedAt",
          });
        }
        return true;
      }),
    legalName: Yup.string().test(
      "legalName",
      "test",
      function (value, ctx: any) {
        if (
          ctx.from[0].value.linkedBusinessProfile &&
          ctx.from[0].value.isLinkBusinessProfile
        )
          return true;
        if (!value) {
          return this.createError({
            message: `Business Legal Name is required`,
            path: "legalName",
          });
        }
        return true;
      },
    ),
    DBA: Yup.string(),
    tinType: Yup.mixed<TinType>().oneOf(["ssn", "ein"]),
    ssn: Yup.string().when("tinType", {
      is: "ssn",
      then: ssnSchema("ssn"),
    }),
    taxID: Yup.string().when("tinType", {
      is: "ein",
      then: Yup.string().test("taxID", "test", function (value, ctx: any) {
        if (
          ctx.from[0].value.linkedBusinessProfile &&
          ctx.from[0].value.isLinkBusinessProfile
        ) {
          return true;
        }
        if (!value) {
          return this.createError({
            message: `Tax ID is required`,
            path: "taxID",
          });
        }
        if (value.replace(/(\s|-)/g, "").length !== 9) {
          return this.createError({
            message: `Invalid format. Format should be 12-3456789`,
            path: "taxID",
          });
        }
        return true;
      }),
    }),
    contactPhone: Yup.string().test(
      "contactPhone",
      "test",
      function (value, ctx: any) {
        if (
          ctx.from[0].value.linkedBusinessProfile &&
          ctx.from[0].value.isLinkBusinessProfile
        )
          return true;
        if (!value) {
          return this.createError({
            message: `Phone number is required`,
            path: "contactPhone",
          });
        }
        if (!matchIsValidTel(value as string)) {
          return this.createError({
            message: `Please enter a valid phone number`,
            path: "contactPhone",
          });
        }
        return true;
      },
    ),
    address: Yup.object().when("isLinkBusinessProfile", {
      is: false,
      then: () =>
        Yup.object().shape({
          country: Yup.string(),
          address: streetAddressValidator(true),
          city: Yup.string()
            .required("All address fields are required")
            .max(20, "City can not contain more than 20 characters"),
          state: Yup.string().required("All address fields are required"),
          zip: Yup.string()
            .required("All address fields are required")
            .matches(/^[0-9]{5}(?:-?[0-9]{4})?$/, "Invalid ZIP format"),
        }),
    }),
  });

  const methods = useForm<FormInputs>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { reset, watch, resetField } = methods;
  const values = watch();

  const [selectedProfile, setSelectedProfile] = useState<any>(null);

  const parsedSelectedProfile = {
    ...businessProfileParser(selectedProfile),
    address: businessAddressParser(
      selectedProfile?.address,
      selectedProfile?.address?.id,
    ),
  };

  const resetToInitialData = () => {
    const parsedPhone = convertPhoneNumber(data?.contactPhone || "");
    reset({
      ...defaultValues,
      ...data,
      contactPhone: `+${parsedPhone}`,
      DBA: data?.DBA || data?.dba,
    });
  };

  useEffect(() => {
    resetToInitialData();
    (async () => {
      if (data?.isLinkBusinessProfile && data.linkedBusinessProfile) {
        const selectedEntity = await getLegalEntityByID(
          data.linkedBusinessProfile,
        );
        setSelectedProfile(selectedEntity);
      } else {
        setSelectedProfile(null);
      }
    })();
  }, [data]);

  const handleClose = () => {
    reset();
    modal.remove();
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (merchantId) {
      handleSubmit(
        "business_profile",
        {
          ...data,
          ...(data.isLinkBusinessProfile &&
            data.linkedBusinessProfile && {
              address: businessAddressParser(selectedProfile?.address),
            }),
        },
        handleClose,
      );
      return;
    }

    let customData = {
      businessProfile: {
        ...data,
        taxID: data?.taxID?.replace("-", ""),
        ssn: data?.ssn?.replace("-", ""),
        businessOpenedAt: data?.businessOpenedAt
          ? format(new Date(data?.businessOpenedAt), "MM/dd/yyyy")
          : "",
        isLinkBusinessProfile: false,
        linkedBusinessProfile: undefined as number | undefined,
      },
      businessOwners: [] as TBusinessOwner[],
    };

    if (data.isLinkBusinessProfile && data.linkedBusinessProfile) {
      let principals = [];
      const merchID = merchantId ? merchantId : currentMerchantId;

      if (merchID) {
        try {
          const legalEntityData = await getLegalEntityPrincipals(
            merchID,
            selectedProfile?.id,
          );
          principals = legalEntityData?.principals || [];
        } catch (error) {
          principals = [];
        }
      }

      const linkedBusinessOwners = merchantBusinessOwnersParser(
        principals,
      )?.map((principal) => ({
        ...principal,
        linked: true,
      }));

      customData = {
        businessProfile: {
          ...businessProfileParser(selectedProfile),
          isController: true,
          businessType: selectedProfile?.type?.name,
          isLinkBusinessProfile: true,
          linkedBusinessProfile: data.linkedBusinessProfile,
          address: businessAddressParser(selectedProfile.address),
        },
        businessOwners: linkedBusinessOwners || [],
      };
    }
    if (onClose) onClose(customData);
    modal.remove();
  };

  useEffect(() => {
    resetField("taxID");
    resetField("ssn");
  }, [values.tinType]);

  return {
    open,
    isLoading,
    canEdit,
    canEditWithLegalEntity,
    handleCancel: resetToInitialData,
    handleClose,
    onSubmit,
    methods,
    parsedSelectedProfile,
    setSelectedProfile,
    showAccessDeniedTooltip
  };
};

export default useBusinessProfileModal;
