import { Button } from "@common/Button";
import { palette } from "@palette";
import { ArrowDownIconV2 } from "@assets/icons/RebrandedIcons";
import { useEffect, useState } from "react";
import { styled } from "@mui/material";
import { Text } from "@common/Text";

interface Props {
  containerRef: React.RefObject<HTMLDivElement>;
}

const ScrollBottomButton = ({ containerRef }: Props) => {
  const [visible, setVisible] = useState(true);

  const toggleVisible = () => {
    const container = containerRef.current;
    if (container) {
      const scrolledHeight = Math.ceil(
        container.scrollTop + container.clientHeight,
      );
      const totalScrollHeight = Math.ceil(container.scrollHeight);
      const tolerance = 2;
      if (scrolledHeight >= totalScrollHeight - tolerance) {
        setVisible(false);
      } else {
        setVisible(true);
      }
    }
  };

  const scrollToBottom = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollTo({
        top: container.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    containerRef.current?.addEventListener("scroll", toggleVisible);
    return () => {
      containerRef.current?.removeEventListener("scroll", toggleVisible);
    };
  }, []);

  return (
    <Button
      size="medium"
      background="primary"
      sx={buttonStyle(visible)}
      onClick={scrollToBottom}
      endIcon={<ArrowDownIconV2 fill={palette.neutral.white} />}
      data-testid="scroll-to-bottom-button"
    >
      <Span>Scroll to bottom</Span>
    </Button>
  );
};

const buttonStyle = (visible: boolean) => ({
  margin: "0 auto",
  bottom: "16px",
  zIndex: 99,
  padding: "4px 12px 4px 16px",
  position: "sticky",
  display: "flex",
  opacity: visible ? 1 : 0,
  visibility: visible ? "visible" : "hidden",
  transition: "opacity 0.3s ease, visibility 0.3s ease",
});
const Span = styled(Text)(() => ({
  color: `${palette.neutral.white} !important`,
  textDecoration: palette.neutral.white,
}));

export default ScrollBottomButton;
