import LoginOTPModal from "@common/Settings/LoginOTPModal";
import NiceModal from "@ebay/nice-modal-react";

const TFAModalWrapper = NiceModal.create(
    ({
      isMobileView,
      email,
      onSuccessFn,
    }: {
      isMobileView: boolean;
      email: string;
      onSuccessFn: () => void;
    }) => {
      return (
        <LoginOTPModal
          email={email}
          isMobileView={isMobileView}
          onSuccessFn={onSuccessFn}
          closeOnVerify
        />
      );
    },
);
  
export default TFAModalWrapper