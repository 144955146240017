import { Stack } from "@mui/material";
import { UploadSimple } from "@phosphor-icons/react";
import { useUploadProgress } from "@redux/slices/uploadProgressSlice";
import GiveButton from "@shared/Button/GiveButton";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme } from "@theme/v2/Provider";
import { getRandomNumber } from "@utils/helpers";
import { Accept, ErrorCode, FileRejection, useDropzone } from "react-dropzone";

interface Props {
  isMobile?: boolean;
  message?: string;
  disabled: boolean;
  uploadFunction: (file: File) => void;
  accept?: Accept;
  maxSizeInBytes?: number;
  maxFiles?: number;
  multiple?: boolean;
}

const GiveUploadArea = ({
  isMobile,
  message,
  disabled = false,
  uploadFunction,
  accept,
  maxSizeInBytes,
  maxFiles,
  multiple,
}: Props) => {
  const { palette } = useAppTheme();

  const { setUploadProgress } = useUploadProgress();

  const showErrorSnackbar = (
    file: FileRejection,
    error: "tooManyFiles" | "tooLarge" | "unsuported",
  ) => {
    setUploadProgress({
      key: `${getRandomNumber(1000000, 100000000)}`,
      data: {
        fileName: file.file.name,
        ...(error !== "tooManyFiles" && { size: file.file.size }),
        [error]: true,
      },
    });
  };

  const onDrop = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    const hasTooManyFilesError = rejectedFiles.some((file) =>
      file.errors.some((error) => error.code === ErrorCode.TooManyFiles),
    );
    if (hasTooManyFilesError) {
      showErrorSnackbar(rejectedFiles[0], "tooManyFiles");
      return;
    }

    rejectedFiles.forEach((file) => {
      if (file.errors[0].code === ErrorCode.FileTooLarge) {
        showErrorSnackbar(file, "tooLarge");
      } else if (file.errors[0].code === ErrorCode.FileInvalidType) {
        showErrorSnackbar(file, "unsuported");
      }
    });
    acceptedFiles.forEach((file) => {
      uploadFunction(file);
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled,
    multiple,
    maxFiles,
    accept,
    // noClick: true,
    maxSize: maxSizeInBytes,
  });

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      borderRadius="20px"
      position="relative"
      display="flex"
      gap={isMobile ? "16px" : "24px"}
      height={isMobile ? undefined : "280px"}
      sx={{
        backgroundColor: isMobile
          ? "transparent"
          : isDragActive
          ? palette.primitive?.transparent["darken-10"]
          : palette.primitive?.transparent["darken-5"],
        transition: "background-color 0.2s",
        cursor: "pointer",
        ...(disabled && {
          cursor: "not-allowed",
          pointerEvents: "none",
          opacity: 0.7,
        }),
        ...(isMobile && {
          marginTop: "16px",
        }),
      }}
      aria-disabled={disabled}
      {...getRootProps({})}
    >
      {isMobile ? (
        <>
          <GiveButton
            variant="outline"
            size="large"
            label="Upload file"
            startIcon={<UploadSimple size={18} color={palette.text.primary} />}
          />
          <GiveText variant="bodyS" color="secondary">
            {message}
          </GiveText>
        </>
      ) : (
        <>
          <UploadSimple size={32} color={palette.icon?.["icon-primary"]} />
          <Stack direction="column" gap="8px" alignItems="center">
            <GiveText variant="bodyL" color="primary">
              Drop files here
            </GiveText>
            <GiveText variant="bodyS" color="secondary">
              {message}
            </GiveText>
          </Stack>
          <GiveButton variant="outline" size="large" label="Select file" />
        </>
      )}
      <input {...getInputProps()} />
    </Stack>
  );
};

export default GiveUploadArea;
