import { TruncateText } from "@common/Text";
import { palette } from "@palette";
import { Tooltip } from "@common/Tooltip";
import React, { useRef, useState } from "react";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

interface IComponentProps {
  name: string;
}

const MerchantName = ({ name }: IComponentProps) => {
  const { isMobileView } = useCustomTheme();
  const nameTextRef = useRef<HTMLHeadElement>(null);
  const [showToolTip, setTooltipShowStatus] = useState(false);

  const displayTooltipOnTextOverflow = () => {
    const textElement = nameTextRef.current;

    if (!textElement) return;

    const isTextOverflowing =
      textElement.scrollHeight - textElement.clientHeight > 10;
    if (isTextOverflowing) setTooltipShowStatus(true);
  };

  const removeTooltipOnExit = () => {
    showToolTip && setTooltipShowStatus(false);
  };

  return (
    <Tooltip
      onMouseEnter={displayTooltipOnTextOverflow}
      onMouseLeave={removeTooltipOnExit}
      disableHoverListener={!showToolTip}
      title={name}
      lineClamp={8}
    >
      <TruncateText
        lineClamp={2}
        color={palette.black[100]}
        variant="h4"
        lineHeight={isMobileView ? "21.6px" : "32.4px"}
        fontWeight="book"
        letterSpacing="-0.24px"
        flexGrow={1}
        fontSize={isMobileView ? "18px" : "32px"}
        textRef={nameTextRef}
        onClick={displayTooltipOnTextOverflow}
      >
        {name}
      </TruncateText>
    </Tooltip>
  );
};

export default MerchantName;
