// acquirer
export const QKEY_LIST_ENTERPRISE_STATS = "list-enterprises-stats";
export const QKEY_LIST_ENTERPRISES = "list-enterprises";
export const QKEY_LIST_ENTERPRISE_MERCHANTS = "list-enterprise-merchants";
export const QKEY_LIST_ACQUIRER_MERCHANTS = "list-acquirer-merchants";
export const QKEY_LIST_RISK_PROFILE_TRANSACTIONS = "get-risk-transactions-list";

// enterprise
export const QKEY_LIST_MERCHANTS = "list-all-merchants";
export const QKEY_MERCHANTS_API_KEYS = "get-merchants-api-keys";
export const QKEY_GET_ENTERPRISEID_BY_ID = "get-enterpriseId-by-id";

// merchant
export const QKEY_LIST_CUSTOMERS = "list-all-customers";
export const QKEY_LIST_FUNDRAISERS = "list-all-fundraisers";
export const QKEY_LIST_PAYMENT_FORMS = "list-all-payment-forms";
export const QKEY_LIST_EVENTS = "list-all-events";
export const QKEY_LIST_INVOICES = "list-all-invoices";
export const QKEY_LIST_MEMBERSHIPS = "list-all-memberships";
export const QKEY_LIST_SWEEPSTAKES = "list-all-sweepstakes";
export const QKEY_LIST_MERCHANT_STATS = "find-merchant-stats-by-id";
export const QKEY_LIST_ALL_BANK_ACCOUNTS = "list-all-bank-accounts";
export const QKEY_GET_MERCHANT_PREVIEW = "get-merchant-preview";
export const QKEY_GET_MERCHANT_MSP_STATUS = "get-merchant-msp-status";

export const QKEY_DONATION_TRANSACTIONS = "get-fundraiser-transactions-by-id";
export const QKEY_RISK_MONITOR_TRANSACTIONS = "get-risk-monitor-transactions";
export const QKEY_MANAGE_MONEY_TRANSACTIONS_LIST = "manage-money-transactions";
export const QKEY_EVENT_TRANSACTIONS = "get-events-transactions-by-id";
export const QKEY_INVOICE_TRANSACTIONS = "get-invoices-transactions-by-id";
export const QKEY_MEMBERSHIP_TRANSACTIONS =
  "get-memberships-transactions-by-id";
export const QKEY_SWEEPSTAKE_TRANSACTIONS =
  "get-sweepstakes-transactions-by-id";
export const QKEY_GET_ALL_MEDIA_ITEM = "get-all-media-items";
export const QKEY_ANALYTICS_CAMPAIGNS = "get-analytics-by-campaign";
export const QKEY_GET_CAMPAGIN_STATS = "get-campaign-stats";
export const QKEY_ANALYTICS_RECURRING_CUSTOMERS =
  "get-analytics-recurring-customers";
export const QKEY_GET_CHANGELOG_CATEGORIES = "get-changelog-categories";
export const QKEY_GET_CHANGELOG_USERS = "get-changelog-users";

// common
export const QKEY_LIST_TEAM_MEMBERS = "get-all-team-members";
export const QKEY_LIST_TEAM_MEMBER_PERMISSIONS =
  "get-team-members-all-permissions";
export const QKEY_USER_PERMISSIONS = "get-user-permissions";
export const QKEY_LIST_UNDERWRITERS = "get-underwriters";

export const QKEY_UNREAD_NOTIFICATIONS = "get-unread-notifications";
export const QKEY_ALL_NOTIFICATIONS = "get-all-notifications";
export const QKEY_ALERTS_LIST = "get-alerts-list";
export const QKEY_GET_TEAM_OWNER_MEMBERS = "get-team-owner-members";
export const QKEY_GET_MERCHANT_BY_ID = "get-merchant-by-id";
export const QKEY_GET_ENTERPRISE_CONFIGURATIONS_BY_ID =
  "get-enterprise-configuration-by-id";

export const QKEY_CHECK_NEW_NOTIFICATIONS = "check-new-notifications";
export const QKEY_LIST_BUSINESS_OWNERS = "list-business-owners";

// QUERY KEYS
export const QKEY_GET_TRANSFER_STATS = "transfer-stats";
export const QKEY_SETTLEMENT = "get-settlement-list";
