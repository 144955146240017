import { useMemo } from "react";
import NiceModal from "@ebay/nice-modal-react";
import { merchantAgreementParser } from "@components/Merchants/MerchantPreview/helpers/parsers";
import { MERCHANT_AGREEMENT_UPDATE_MODAL } from "modals/modal_names";
import useCheckPAH from "@hooks/common/useCheckPAH";

const useAccStatus = ({ merchant }: { merchant: any }) => {
  const { isLoggedInPAH } = useCheckPAH();

  const merchantAgreement = useMemo(
    () => merchantAgreementParser(merchant),
    [merchant],
  );

  const isPending =
    merchantAgreement.msaHadAgreed &&
    merchantAgreement.msaLastAcceptedVersion !== merchantAgreement.tcVersion;

  const handleCheckUpdate = () => {
    NiceModal.show(MERCHANT_AGREEMENT_UPDATE_MODAL, {
      version: merchantAgreement.tcVersion,
      merchantId: merchant?.accID,
      signatureURL: merchant?.msaLastAcceptedBySignatureURL,
    });
  };

  const noUpdate =
    !isPending || !isLoggedInPAH || !merchantAgreement?.approvedPAH;

  return {
    isReviewUpdate: !noUpdate,
    handleCheckUpdate,
  };
};

export default useAccStatus;
