import { Box } from "@mui/material";
import { InputBase, InputAdornment } from "@mui/material";
import { Minus, Plus } from "@phosphor-icons/react";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { styled, useAppTheme } from "@theme/v2/Provider";

interface Props {
  handleDecrement: () => void;
  handleIncrement: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  quantity: number;
}
const QuantityInput = ({
  handleDecrement,
  handleIncrement,
  handleChange,
  quantity,
}: Props) => {
  const { palette } = useAppTheme();
  return (
    <StyledBox>
      <InputBase
        name="quantity"
        type="number"
        value={quantity}
        onChange={handleChange}
        inputProps={{ min: 1 }}
        startAdornment={
          <InputAdornment position="start">
            <StyledIcon
              variant="ghost"
              onClick={handleDecrement}
              Icon={Minus}
            />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <StyledIcon variant="ghost" onClick={handleIncrement} Icon={Plus} />
          </InputAdornment>
        }
      />
    </StyledBox>
  );
};

export default QuantityInput;

const StyledIcon = styled(GiveIconButton)({
  "&:hover": {
    background: "transparent !important",
  },
});

const StyledBox = styled(Box)(({ theme }) => ({
  background: theme.palette.primitive?.transparent["darken-5"],
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  bgcolor: "#f4f4f4",
  borderRadius: "24px",
  padding: "4px 16px",
  width: "140px",
}));
