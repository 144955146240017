import SendGradientIcon from "@assets/icons/RebrandedIcons/SendGradientIcon";
import LoadingButton from "@common/Button/LoadingButton";
import { Text } from "@common/Text";

import { Box, Stack } from "@mui/material";

import React from "react";

function InvitingComponent({
  onClick,
  isFetching,
}: {
  onClick: () => void;

  isFetching?: boolean;
}) {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      height="100%"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <SendGradientIcon />
        <Text fontWeight="light" fontSize="18px" color="#292727" my="24px">
          Sending invitations in progress
        </Text>
        <LoadingButton
          isLoading={isFetching}
          isShowTextWhileLoading
          disabled={isFetching}
          onClick={onClick}
        >
          Check Status
        </LoadingButton>
      </Box>
    </Stack>
  );
}

export default InvitingComponent;
