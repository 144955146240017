import { Box, Stack } from "@mui/material";
import { Fragment } from "react";
import NiceModal from "@ebay/nice-modal-react";
import { FormType } from "@components/Merchants/CreateMerchantPanel/modals/CreateBusinessProfileModal";
import { PlaceholderCard } from "./PlaceholderCard";
import { styled } from "@theme/v2/Provider";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";

export type SectionPlaceholderProps = {
  placeholdersList: {
    icon: JSX.Element;
    title: string;
    description: string;
    hidden?: boolean;
    disabled?: boolean;
    modal?: string;
    onClose?: (data: any) => void;
    data?: any;
    businessType?: string;
    merchantId?: number;
    merchantName?: string;
    legalEntityID?: number;
    totalOwnerships?: number;
    noController?: boolean;
    formType?: FormType;
    parentCategories?: any[];
    categories?: any[];
    isDefault?: boolean;
    warning?: boolean;
    isIndividualSoleProprietorship?: boolean;
    isEnterprise?: boolean;
    onClick?: () => void;
    testId?: string;
    parentAccID?: number;
    tooltipProps?: {
      show: boolean;
      message: string;
    };
  }[];
};

const PlaceholderSection = ({ placeholdersList }: SectionPlaceholderProps) => {
  const visiblePlaceholders = placeholdersList.filter(
    (placeholder) => !placeholder.hidden && !placeholder.noController,
  );

  if (visiblePlaceholders.length === 0) return null;

  return (
    <PlaceholderCardContainer>
      {visiblePlaceholders.map((placeholder, index) => {
        const {
          formType,
          merchantId,
          businessType,
          isDefault,
          warning,
          isEnterprise,
          parentCategories,
          isIndividualSoleProprietorship,
          onClick,
          tooltipProps,
          testId,
          merchantName,
          parentAccID,
          ...placeholdeRest
        } = placeholder;

        const handleClick = () => {
          if (placeholder.modal && !tooltipProps?.show) {
            NiceModal.show(placeholder.modal, {
              data: placeholder.data,
              onClose: placeholder.onClose,
              merchantId,
              id: merchantId,
              legalEntityID: placeholder.legalEntityID,
              totalOwnerships: placeholder.totalOwnerships,
              parentCategories: placeholder.parentCategories,
              categories: placeholder.categories,
              isIndividualSoleProprietorship:
                placeholder.isIndividualSoleProprietorship,
              isDefault: isDefault || false,
              formType,
              isEnterprise,
              businessType,
              isMerchant: true,
              merchantName: merchantName,
              parentAccID,
            });
          } else if (onClick) onClick();
        };

        return (
          <Fragment key={placeholder.title}>
            <GiveTooltip
              width="compact"
              alignment="left"
              title={tooltipProps?.message}
              color="default"
              disableHoverListener={!tooltipProps?.show}
            >
              <PlaceholderCard
                {...placeholdeRest}
                disabled={tooltipProps?.show || placeholdeRest.disabled}
                data-testid={testId}
                onClick={handleClick}
              />
            </GiveTooltip>
          </Fragment>
        );
      })}
    </PlaceholderCardContainer>
  );
};

const PlaceholderCardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  padding: "16px",
  borderRadius: "20px",
  background: theme.palette.surface?.primary,
  overflow: "hidden",
}));
export default PlaceholderSection;
