import { palette } from "@palette";
import { useForm } from "react-hook-form";
import {
  customFilterDates,
  FilterDate,
  resetFilters,
  selectedQueries,
  selectedQueryString,
  setFilterDates,
  setFilters,
} from "@redux/slices/tableFilters";
import { useDispatch } from "react-redux";
import {
  customJoinList,
  FilterSectionItemType,
  FormFields,
  SelectorOption,
} from "@common/TableFilters/utils";
import { useMemo, useState } from "react";
import { FiltersContainer } from "@common/TableFilters/CommonFiltersContainer";
import { Avatar, Divider, Stack, styled } from "@mui/material";
import {
  DisputeFilterFormFields,
  disputesFilterDefaultValues,
  DisputesFilterFormValuesType,
} from "../../utils";
import { useAppSelector } from "@redux/hooks";
import { isEqual } from "lodash";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import WrapperWithDateRange from "@common/TableFilters/WrapperWithDateRange";
import { Switch_V2 } from "@common/Switch";
import useUserReducer from "@hooks/Reducers/useUserReducer";
import ImagePlaceholder from "assets/images/Placeholder.png";
import SectionItem from "@common/TableFilters/SectionItem";
import AssigneesSelector from "@common/TableFilters/AssigneesSelector";
import ListItem from "@common/TableFilters/ListItem";

export const useDisputesFilter = () => {
  const { modal } = useNiceModal();
  const dispatch = useDispatch();
  const contextQueryString = useAppSelector(selectedQueryString);
  const contextQueries = useAppSelector(selectedQueries);
  const contextDate = useAppSelector(customFilterDates);
  const {
    img: currUserImg,
    userAccID: currUserAccId,
    email: currUserEmail,
    globalName,
  } = useUserReducer();
  const [filteredDate, setFilteredDate] = useState<FilterDate | null>(null);

  const methods = useForm<DisputesFilterFormValuesType>({
    defaultValues: { ...disputesFilterDefaultValues, ...contextQueries },
  });

  const values = methods.watch();

  const handleReset = () => {
    dispatch(resetFilters());
    methods.reset(disputesFilterDefaultValues);
  };

  const areFiltersSelected =
    !isEqual(disputesFilterDefaultValues, values) || contextQueryString;

  const clearAllEnabled =
    (areFiltersSelected && !isEqual(disputesFilterDefaultValues, values)) ||
    false;

  const handleMultiSelect = (
    fieldName: FormFields,
    value: SelectorOption | SelectorOption[],
  ) => {
    const field = fieldName as DisputeFilterFormFields;

    const currentValues: SelectorOption[] =
      !!values[field] && Array.isArray(values[field])
        ? (values[field] as SelectorOption[])
        : [];

    // replace form value if the value param is an array
    if (Array.isArray(value)) {
      methods.setValue(field, value);
      return;
    }
    if (currentValues && currentValues.find((item) => item.id === value.id)) {
      methods.setValue(
        field,
        currentValues?.filter((item) => item.id !== value.id),
      );
    } else {
      const vals = currentValues ? [...currentValues, value] : [value];
      methods.setValue(field, vals);
    }
  };

  const onSubmit = (data: DisputesFilterFormValuesType) => {
    let queryString = "";

    if (data.disputeStatus?.length > 0) {
      queryString += "(";
      queryString += customJoinList(
        data.disputeStatus.map((status) => `statusDisplayName:"${status}"`),
        ",",
      );
      queryString += ")%3B";
    }

    if (data.amount?.length > 0) {
      const amount = data?.amount?.[0] as string;
      let amountQs = "";
      switch (amount) {
        case "0 - 1000 USD":
          amountQs = "(disputedAmount:>=0%3BdisputedAmount:<=100000)";
          break;
        case "1001 - 10000 USD":
          amountQs = "(disputedAmount:>=100100%3BdisputedAmount:<=1000000)";
          break;
        case "Custom":
          amountQs = `(${data?.custom?.replace(/amount/g, "disputedAmount")})`;
          break;
      }
      queryString += amountQs + "%3B";
    }

    if (data.createdAt) {
      queryString += data.createdAt + "%3B";
    }

    if (data.underwriterEmail?.length > 0) {
      queryString += "(";
      queryString += customJoinList(
        data.underwriterEmail.map(
          (email) => `defaultAssignee.email:"${email?.value}"`,
        ),
        ",",
      );
      queryString += ")%3B";
    }

    dispatch(
      setFilters({
        queryParams: data,
        queryString,
      }),
    );

    if (filteredDate) dispatch(setFilterDates(filteredDate));

    modal.remove();
  };

  const dateFilter = (title: string, name: FormFields) => ({
    title: title,
    description: "",
    children: (
      <Wrapper>
        <WrapperWithDateRange
          dateName={name}
          selectedDate={contextDate}
          rangeSaveCallback={(val) => setFilteredDate(val)}
        />
      </Wrapper>
    ),
    dataTestId: "date-filter"
  });

  const disputeDateFilter = dateFilter("Date", "createdAt");

  const assignToObj = {
    title: "Assigned to me",
    description: "See merchants that are assigned to you",
    startIcon: <StyledAvatar src={currUserImg || ImagePlaceholder} />,
    endElement: (
      <Switch_V2
        size="small"
        containerProps={{ alignItems: "initial" }}
        checked={Boolean(
          values.underwriterEmail?.find((item) => item.id === currUserAccId),
        )}
        onChange={() => {
          const name =
            globalName?.firstName && globalName?.lastName
              ? `${globalName?.firstName} ${globalName?.lastName}`
              : currUserEmail;

          handleMultiSelect("underwriterEmail", {
            label: name,
            value: currUserEmail,
            id: currUserAccId,
            imageURL: currUserImg?.replace("/thumb", ""),
          });
        }}
      />
    ),
    children: (
      <Stack gap={1}>
        <StyledDivider />
        <SectionItem
          containerStyle={{ padding: 0 }}
          title="Other Assignees"
          description="See merchants that are assigned to specific assignees"
          endElement={
            <AssigneesSelector
              id={currUserAccId}
              selectedValues={values?.underwriterEmail}
              handleMultiSelect={handleMultiSelect}
              type="members"
            />
          }
        />
        <Stack spacing="6px" paddingTop={1}>
          {!!values?.underwriterEmail &&
            values.underwriterEmail?.map((item: SelectorOption) => (
              <ListItem
                key={item.label}
                item={item}
                onDelete={(val) => handleMultiSelect("underwriterEmail", val)}
              />
            ))}
        </Stack>
      </Stack>
    ),
    dataTestId: "assigned-filter"
  };

  const sections = useMemo(() => {
    return [
      disputeDateFilter,
      assignToObj,
      {
        title: "Amount",
        description:
          "View transactions based on the amount of money received within specific range",
        children: (
          <FiltersContainer
            filters={amountOptions}
            fieldName="amount"
            containerSx={{ padding: 0 }}
          />
        ),
        dataTestId: "amount-filter"
      },
      {
        title: "Status",
        startIcon: null,
        description: "",
        children: (
          <FiltersContainer
            filters={statuses}
            fieldName="disputeStatus"
            containerSx={{ padding: 0 }}
            disabledCustom
          />
        ),
        dataTestId: "status-filter"
      },
    ];
  }, [values]);

  const actions = {
    primaryAction: {
      label: "Apply filters",

      disabled: !areFiltersSelected,
      sx: {
        fontSize: "18px",
        lineHeight: "21.6px",
        padding: "12px 24px",
      },
    },
    secondaryAction: {
      label: "Clear all filters",
      onClick: handleReset,
      sx: {
        fontSize: "18px",
        lineHeight: "18px",
        padding: "12px 24px",
        color: palette.filled.red,
      },
      hidden: !clearAllEnabled,
    },
  };

  return {
    actions,
    methods,
    onSubmit,
    sections: sections as FilterSectionItemType[],
  };
};

const Wrapper = styled(Stack)(() => ({
  display: "flex",
  flexDirection: "row",
  spacing: "8px",
  flexWrap: "wrap",
  gap: "8px",
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.neutral[10],
}));

const statuses = [
  "Pending",
  "Action Required",
  "Under Review",
  "Won",
  "Lost",
  "Closed",
  "Escalated",
];

const amountOptions = ["0 - 1000 USD", "1001 - 10000 USD", "Custom"];
