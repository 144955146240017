export const getDefaultValues = (
  leType: string,
  fullOwnershipReleased: number,
) => ({
  isOwner: fullOwnershipReleased ? false : getIsOwnerCheckedByDefault(leType),
  tinType: "",
  base: {
    dob: "",
    firstName: "",
    isManager: false,
    lastName: "",
    phoneNumber: "",
  },
  whenIsOwner: {
    address: {
      country: "US",
      city: "",
      line1: "",
      state: "",
      zip: "",
    },
    ssn: "",
    ein: "",
    tinType: "ssn",
    ownership: ["tax_exempt_organization", "government_agency"].includes(leType)
      ? "0"
      : "",
  },
});

export const getIsOwnerCheckedByDefault = (leType: string) => {
  // ownership check box is checked initially for these business types
  return [
    "tax_exempt_organization",
    "government_agency",
    "individual_sole_proprietorship",
  ].includes(leType);
};

export const getIsOwnerBoxDisabled = (
  leType: string,
  fullOwnershipReleased: number,
) => {
  // ownership checkbox is disabled for these business types
  return (
    fullOwnershipReleased === 100 ||
    [
      "tax_exempt_organization",
      "government_agency",
      "individual_sole_proprietorship",
    ].includes(leType)
  );
};
