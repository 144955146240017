import { Stack, SxProps } from "@mui/material";
import { TStatusReview } from "../../data.types";
import StatusReviewAssignmetComponent from "./components/StatusReviewAssignmetComponent";

type Props = {
  status: TStatusReview;
  handleManageRiskMonitor: () => void;
  customButtonStyle?: SxProps
  disabledRiskMonitor?: boolean
};

const RiskSummaryActions = ({ status, handleManageRiskMonitor, ...props }: Props) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <StatusReviewAssignmetComponent
        isRefactored
        data={status}
        handleManageRiskMonitor={handleManageRiskMonitor}
        {...props}
      />
    </Stack>
  );
};

export default RiskSummaryActions;
