import Dropzone from "react-dropzone-uploader";
import { IDUploaderInput, IDUploaderLayout } from "./IDUploaderComponents";
import { ACCEPTED_IMAGE_FORMATS } from "@constants/constants";
import IDUploaderActions from "./IDUploaderActions";
import { UPLOAD_DENY_MESSAGE } from "@constants/permissions";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import { usePAHUploader } from "@components/Merchants/MerchantPreview/hooks/usePAHUploader";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import { TOwnerFile } from "@components/Merchants/MerchantPreview/components/PrimaryAccountHolder/types";
import { useAppTheme } from "@theme/v2/Provider";
import { UploaderState } from "@components/Merchants/MerchantPreview/components/PrimaryAccountHolder/PAHUploaderMachine";

type Props = {
  type: TOwnerFile;
  merchantId: number;
  docUrl: string;
  isMobile?: boolean;
  disabled?: boolean;
  downloadHandler: (
    documentData?: TMerchantDocument | undefined,
  ) => Promise<void>;
  fileId: number | undefined;
  previewDocument: ({
    tmpUrl,
    type,
    fileName,
  }: {
    tmpUrl?: string | undefined;
    type?: TOwnerFile | undefined;
    fileName?: string;
  }) => void;
  isUploadAllowed: boolean;
  isDeleteAllowed: boolean;
};

export const defaultMaxFileSize = 5000000;

const IDUploader = ({
  type,
  merchantId,
  docUrl,
  downloadHandler,
  isMobile,
  previewDocument,
  fileId,
  isDeleteAllowed,
  isUploadAllowed,
  disabled = false,
}: Props) => {
  // Logic of upload, snackbars and state dispatch
  const {
    fileUrl,
    state,
    fileName,
    handleChangeStatus,
    _deleteDocument,
    isUploadable,
  } = usePAHUploader({ docUrl, merchantId, type, fileId });

  const theme = useAppTheme();

  return (
    <>
      <Dropzone
        disabled={!isUploadable || disabled}
        styles={{
          dropzone: {
            flex: 1,
            overflow: "auto",
            borderRadius: 12,
            justifyContent: "center",
            backgroundColor: theme.palette.primitive?.transparent["darken-5"],
            border: "none",
          },
          dropzoneDisabled: {
            opacity: 1,
          },
        }}
        onChangeStatus={handleChangeStatus}
        multiple={false}
        canCancel={false}
        accept={ACCEPTED_IMAGE_FORMATS}
        LayoutComponent={
          state === UploaderState.ON_UPLOADED
            ? (props) => (
                <IDUploaderLayout
                  {...props}
                  type={type}
                  fileUrl={fileUrl}
                  actions={
                    <IDUploaderActions
                      type={type}
                      downloadHandler={downloadHandler}
                      deleteDocument={_deleteDocument}
                      previewDocument={() =>
                        previewDocument({ tmpUrl: fileUrl, type, fileName })
                      }
                      disabled={{
                        delete: !isDeleteAllowed,
                      }}
                    />
                  }
                />
              )
            : undefined
        }
        PreviewComponent={() => <></>}
        maxSizeBytes={defaultMaxFileSize}
        InputComponent={(props) => (
          <WithTooltipWrapper
            hasTooltip={!isUploadAllowed && !fileUrl}
            tooltipProps={{
              show: !isUploadAllowed,
              message: UPLOAD_DENY_MESSAGE,
            }}
          >
            <IDUploaderInput
              {...props}
              isMobile={isMobile}
              fileName={fileName}
              type={type}
              state={state}
              fileUrl={fileUrl}
              previewDocument={previewDocument}
              isUploadable={isUploadable}
              disabled={disabled}
            />
          </WithTooltipWrapper>
        )}
      />
    </>
  );
};

export default IDUploader;
