import NiceModal, { useModal } from "@ebay/nice-modal-react";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import { ArrowLeft, ChatsCircle } from "@phosphor-icons/react";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import ModalContentView from "./components/ModalContentView";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useMemo, useState } from "react";
import PortalFooterButtons from "./components/PortalFooterButtons";

export enum ContentViewTypes {
  MATCH = "match",
  ADD_NEW_REPORT = "add-new-report",
  REPORT_DETAILS = "report-details",
}

const MatchReportModal = NiceModal.create(() => {
  const [contentView, setContentView] = useState<ContentViewTypes>(
    ContentViewTypes.MATCH,
  );
  const { remove, visible } = useModal();
  const { isMobileView } = useCustomThemeV2();
  const onClose = () => {
    remove();
  };

  const modalTitle = useMemo(() => {
    switch (contentView) {
      case ContentViewTypes.MATCH:
        return "MATCH";
      case ContentViewTypes.ADD_NEW_REPORT:
        return "Add New Report";
      default:
        return "";
    }
  }, [contentView]);

  const handleReturnToBaseView = () => setContentView(ContentViewTypes.MATCH);

  return (
    <GiveBaseModal
      title={modalTitle}
      open={visible}
      onClose={onClose}
      width="560px"
      showFooter={contentView === ContentViewTypes.ADD_NEW_REPORT}
      PaperProps={{
        sx: {
          ...(isMobileView ? { height: "auto !important" } : {}),
        },
      }}
      headerRightContent={
        <GiveIconButton
          variant="ghost"
          onClick={() => null}
          Icon={ChatsCircle}
        />
      }
      headerLeftContent={
        contentView !== ContentViewTypes.MATCH && (
          <GiveIconButton
            variant="ghost"
            onClick={handleReturnToBaseView}
            Icon={ArrowLeft}
          />
        )
      }
      buttons={<PortalFooterButtons />}
    >
      <ModalContentView
        setContentView={setContentView}
        contentView={contentView}
      />
    </GiveBaseModal>
  );
});

export default MatchReportModal;
