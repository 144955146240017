import NotFoundPageState from "@common/EmptyState/NotFoundPageState";
import AppTitle from "@components/AppTitleComponent";
import Base from "@components/Layout/Base";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import NotificationStack from "@components/UploadFile/NotificationStack";
import NotificationStackMobile from "@components/UploadFile/NotificationStackMobile";
import NiceModal from "@ebay/nice-modal-react";
import { useWindowCounter } from "@hooks/common";
import { useLocalMasquerade } from "@hooks/common/masquerade";
import { useScrollToTop } from "@hooks/common/useScrollToTop";
import { useMasquerade } from "@hooks/enterprise-api/merchants";
import useInitialLogin from "@hooks/useInitialLogin";
import { useVersionUpdate } from "@hooks/useVersionUpdate";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectMasqueradeMode, selectModals } from "@redux/slices/app";
import { clearFilters } from "@redux/slices/fundraisers";
import { getEnterpriseRoutes } from "@routes/EnterpriseRouters";
import { MERCHANT_PATHS, PUBLIC_FORM_PATHS } from "@routes/paths";
import { unprotectedRoutes } from "@routes/UnprotectedRoutes";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import FFLoadingStateWrapper from "FeatureFlags/FFLoadingStateWrapper";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import Immutable from "immutable";
import { lazy, Suspense, useEffect } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import { getAcquirerRoutes } from "routes/AcquirerRoutes";
import authRoutes from "routes/AuthRoutes";
import merchantRoutes from "routes/MerchantRoutes";
import PublicRoutes from "routes/PublicRoutes";
import GiveNotificationStack from "./features/FileUpload/GiveNotificationStack";
import PayBuilder from "@sections/PayBuilder/PayBuilder";

const createScript = (srcHref: string, id?: string) => {
  const scr = document.createElement("script");
  scr.setAttribute("src", srcHref);
  if (id) {
    scr.setAttribute("id", id);
  }
  return scr;
};

const MobileSelfie = lazy(() =>
  import("@sections/VerifyAccountHolder_v2/MobileSelfie").then((module) => ({
    default: module.MobileSelfie,
  })),
);

function App() {
  useVersionUpdate();
  useScrollToTop();

  (Immutable.Iterable as any).noLengthWarning = true;

  const dispatch = useAppDispatch();
  const location = useLocation();
  const { isMainWindow } = useWindowCounter();
  const storageMasquerade = useLocalMasquerade();
  const reduxMasquerade = useAppSelector(selectMasqueradeMode);
  const { clearMasquerade, resetMasqueradeMode } = useMasquerade();
  const modals = useSelector(selectModals);
  const {
    isTransfersPageEnabled,
    isDisputesPageEnabled,
    isFileUploadRefactorEnabled,
  } = useGetFeatureFlagValues();
  useInitialLogin();

  useEffect(() => {
    dispatch(clearFilters());
  }, [location.pathname]);

  useEffect(() => {
    const isSpecialLocation =
      location.pathname.match(/^\/[0-9]*$/) || // match payment forms
      location.pathname.startsWith("/privacy") ||
      location.pathname.startsWith("/terms_of_service");

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const isWidget = urlParams.get("widget");
    const hasJustBeenReloaded =
      performance?.navigation?.type == performance?.navigation?.TYPE_RELOAD;
    if (
      !isMainWindow &&
      !isSpecialLocation &&
      !isWidget &&
      !hasJustBeenReloaded
    ) {
      clearMasquerade();
    }
  }, [isMainWindow]);

  useEffect(() => {
    if (storageMasquerade && !storageMasquerade.id && reduxMasquerade.id) {
      const origin = reduxMasquerade.origin;
      const isAForm = location.pathname.match(/\d+/);
      if (isAForm) {
        resetMasqueradeMode(origin);
        return;
      }
      clearMasquerade(origin);
      // navigate(origin);
    }
  }, [storageMasquerade]);

  const queryParams = location?.search;

  useEffect(() => {
    const onReady = () => {
      const plaidScriptID = "plaid_script";
      const recaptchaScriptID = "recaptcha_enterprise_script";
      if (
        process.env.REACT_APP_API_ENDPOINT &&
        !document.getElementById(plaidScriptID)
      ) {
        document.body.appendChild(
          createScript(
            `${
              !process.env.REACT_APP_API_ENDPOINT.endsWith("/")
                ? `${process.env.REACT_APP_API_ENDPOINT}/`
                : process.env.REACT_APP_API_ENDPOINT
            }plaid/cdn/link-initialize.js`,
            plaidScriptID,
          ),
        );
      }
      if (
        process.env.REACT_APP_RECAPTCHA_SITE_KEY &&
        !document.getElementById(recaptchaScriptID)
      ) {
        document.body.appendChild(
          createScript(
            `https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`,
            recaptchaScriptID,
          ),
        );
      }
    };

    if (document.readyState !== "loading") {
      onReady();
    } else {
      document.addEventListener("DOMContentLoaded", onReady);
    }
  }, []);

  return (
    <>
      <NiceModal.Provider modals={modals} dispatch={dispatch}>
        <div>
          <AppTitle />
          {useRoutes([
            {
              path: "/",
              element: (
                <FFLoadingStateWrapper>
                  <PublicRoutes />
                </FFLoadingStateWrapper>
              ),
              children: authRoutes,
            },
            {
              path: "make_selfie",
              children: [
                {
                  element: (
                    <Suspense
                      fallback={
                        <Box
                          width="100%"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          height="100%"
                        >
                          <LoadingSpinner />
                        </Box>
                      }
                    >
                      <MobileSelfie />
                    </Suspense>
                  ),
                  index: true,
                },
              ],
            },
            {
              path: PUBLIC_FORM_PATHS.PAY_PRODUCT_BUILDER,
              children: [
                {
                  element: <PayBuilder />,
                  index: true,
                },
              ],
            },
            {
              path: "/",
              element: (
                <FFLoadingStateWrapper>
                  <Base />
                </FFLoadingStateWrapper>
              ),
              children: [
                {
                  path: "acquirer",
                  children: getAcquirerRoutes({
                    queryParams,
                    isTransfersPageEnabled,
                    isDisputesPageEnabled,
                  }),
                },
                {
                  path: "merchant",
                  children: [
                    ...merchantRoutes,
                    {
                      element: (
                        <Navigate
                          to={`/merchant/${MERCHANT_PATHS.WELCOME}${queryParams}`}
                          replace
                        />
                      ),
                      index: true,
                    },
                  ],
                },
                {
                  path: "provider",
                  children: getEnterpriseRoutes({
                    queryParams,
                    isTransfersPageEnabled,
                  }),
                },
              ],
            },
            ...unprotectedRoutes,
            {
              path: "*",
              element: (
                <FFLoadingStateWrapper>
                  <NotFoundPageState />
                </FFLoadingStateWrapper>
              ),
            },
          ])}
        </div>
      </NiceModal.Provider>
      {isFileUploadRefactorEnabled && (
        <div
          id="root-notifications"
          style={{
            position: "absolute",
            zIndex: 10000, // 1200 is of nice modal, so this is always o top
          }}
        />
      )}
      <Portal />
    </>
  );
}

const Portal = () => {
  const { isMobileView } = useCustomTheme();
  const portalRoot = document.getElementById("root-notifications");
  const { isRebrandingEnabled } = useGetFeatureFlagValues();
  const toRender = isRebrandingEnabled ? (
    <GiveNotificationStack />
  ) : isMobileView ? (
    <NotificationStackMobile />
  ) : (
    <NotificationStack />
  );
  return portalRoot ? createPortal(toRender, portalRoot) : null;
};
export default App;
