import { Stack } from "@mui/material";
import { ArrowLeft } from "@phosphor-icons/react";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import SidePanelHeaderVariant, {
  SidePanelHeaderVariantProps,
} from "@shared/SidePanel/components/SidePanelHeader/SidePanelHeaderVariant";
import GiveText from "@shared/Text/GiveText";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useAppTheme } from "@theme/v2/Provider";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";

export function ChallengeTitle({
  name,
  onBack,
}: {
  name: string;
  onBack: () => void;
}) {
  const { palette } = useAppTheme();
  return (
    <Stack direction="row" alignItems="center" gap="12px">
      <GiveIconButton
        Icon={ArrowLeft}
        onClick={onBack}
        variant="ghost"
        color={palette.icon?.["icon-primary"]}
      />
      <GiveText fontSize="bodyM">{name}</GiveText>
    </Stack>
  );
}

export function ChallengeActions({ onClose }: { onClose: () => void }) {
  const { isMobileView } = useCustomThemeV2();
  const {
    handlers,
    isLast = true,
    isFirst = true,
  } = useMerchantSidePanelContext();
  const VariantProps: SidePanelHeaderVariantProps = {
    actions: [
      {
        text: "Agreement",
        hidden: !isMobileView,
        onClick: handlers.handleOpenAgreementSnapshot,
      },
      {
        text: "Merchant File",
        hidden: !isMobileView,
        onClick: handlers.handleOpenMerchantFile,
      },
    ],
    nextPrevState: { isLast, isFirst },
    isControlModeAllowed: false,
    handlers: {
      displayRowHandlers: false,
      handleClose: onClose,
      ...handlers,
    } as any,
    hasUnreadMessages: false,
    hasAlert: false,
    isRiskMonitorAllowed: false,
    withMerchantFile: true,
    withRisk: false,
    withAgreement: true,
    isLoading: false,
  };
  return (
    <SidePanelHeaderVariant variant="challenge" HeaderProps={VariantProps} />
  );
}
