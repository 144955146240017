import { palette } from "@palette";
// @mui
import Grid from "@mui/material/Grid";
// components
import { Text } from "@common/Text";
import { RHFInput } from "@common/Input";
import { RHFSelect } from "@common/Select";
import OnboardingBankStatement from "./OnboardingBankStatement";
import { Stack } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { BankProviderText } from "@components/BankAccounts";
import {
  TBankFormInputsNew,
  TBankFormInputsOld,
} from "../../../hooks/onboarding/useAddOnboardingBankAccounts";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  CREATE_DENY_MESSAGE,
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { gridItemsRenderer } from "@utils/rendering/nodesRenderers";

const options = [
  {
    value: "checking",
    label: "Checking",
  },
  {
    value: "savings",
    label: "Savings",
  },
];

const CreateOnboardingBankAccount = ({
  firstAccount,
  ...rest
}: {
  firstAccount?: any;
  bankFiles?: any;
  bankFilesLoading?: boolean;
}) => {
  const { watch } = useFormContext<TBankFormInputsNew | TBankFormInputsOld>();
  const values = watch();
  const isApproved = firstAccount?.status === "approved";

  const isLinked = firstAccount?.isLinked;

  const isAddAllowed = useAccessControl({
    resource: RESOURCE_BASE.BANK_ACCOUNT,
    operation: OPERATIONS.CREATE,
    withPortal: true,
  });

  const isEditAllowed = useAccessControl({
    resource: RESOURCE_BASE.BANK_ACCOUNT,
    operation: OPERATIONS.UPDATE,
    withPortal: true,
  });

  const cantAdd = !firstAccount?.id && !isAddAllowed;
  const cantEdit = (firstAccount?.id && !isEditAllowed) || isLinked;
  const isInputDisabled = cantAdd || cantEdit || isApproved;

  const inputs = [
    {
      node: (
        <RHFSelect
          name="accountType"
          placeholder="Select an account"
          label="Account type"
          disabled={isInputDisabled}
          options={options}
        />
      ),
      sm: 4,
    },
    {
      node: (
        <RHFInput
          name="name"
          fullWidth
          placeholder="Business name on account"
          type="text"
          label="Business name on account"
          disabled={isInputDisabled}
        />
      ),
      sm: 8,
    },
    {
      node: (
        <>
          <RHFInput
            name="routingNumber"
            fullWidth
            placeholder="Enter Routing Number"
            type="text"
            label="Routing number"
            inputProps={{ maxLength: 9 }}
            disabled={cantAdd || cantEdit}
          />
          <BankProviderText routingNumber={values.routingNumber} />
        </>
      ),
      sm: 4,
    },
    {
      node: (
        <RHFInput
          name="accountNumber"
          fullWidth
          placeholder="Enter Account Number"
          disabled={isInputDisabled}
          type="text"
          label="Account number"
        />
      ),
      sm: 8,
    },
    {
      node: <OnboardingBankStatement firstAccount={firstAccount} {...rest} />,
      sm: 12,
      hidden: isInputDisabled,
    },
    {
      node: (
        <RHFInput
          name="notes"
          fullWidth
          placeholder="Enter notes about account..."
          type="text"
          label="Notes (optional)"
          multiline
          rows={7}
          disabled={cantAdd || cantEdit}
          inputProps={{
            maxLength: "200",
          }}
          helperText={`${200 - values.notes.length} Characters remaining`}
        />
      ),
      sm: 12,
    },
  ];
  return (
    <Stack direction="column" alignItems="stretch" gap={1}>
      <Text fontSize="18px" fontWeight="book" color={palette.neutral[80]}>
        Enter bank account details
      </Text>
      <Grid container spacing={1.5} direction="row" alignItems="flex-start">
        {gridItemsRenderer(inputs, {
          show: cantAdd || cantEdit,
          message: isLinked
            ? "Only controllers can edit the bank account"
            : !cantEdit
            ? EDIT_DENY_MESSAGE
            : CREATE_DENY_MESSAGE,
        })}
      </Grid>
    </Stack>
  );
};

export default CreateOnboardingBankAccount;
