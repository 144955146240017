import { Collapse, Stack } from "@mui/material";
import { useAppTheme } from "@theme/v2/Provider";
import ChallengesCategories from "features/Merchants/MerchantSidePanel/WithRepository/Challenges/ChallengesCategories";
import ChallengeCategoriesBottom from "./ChallengeCategoriesBottom";

type TChallengeCategoriesProps = {
  allChallengesCompleted: boolean;
  merchantId: number;
  merchantName: string;
  show: boolean;
};

const UnderwritingChallengeCategories = ({
  allChallengesCompleted,
  merchantId,
  merchantName,
  show,
}: TChallengeCategoriesProps) => {
  const { palette } = useAppTheme();

  return (
    <Collapse in={show} timeout={500}>
      <Stack
        borderTop={`1px solid ${palette.surface?.tertiary}`}
        paddingTop={"16px"}
        gap={3}
      >
        <ChallengesCategories />
        <ChallengeCategoriesBottom
          isAllCompleted={allChallengesCompleted}
          merchantId={merchantId}
          merchantName={merchantName}
        />
      </Stack>
    </Collapse>
  );
};

export default UnderwritingChallengeCategories;
