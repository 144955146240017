import SignatureCanvas from "@components/ProfilePage/MerchantAgreementSetup/SignatureCanvas";

import { Box } from "@mui/material";
import { dataURLtoFile } from "@utils/assets";
import React, { Dispatch, SetStateAction } from "react";
import { breakpoints } from "@theme/v2/breakpoints";

interface Props {
  setFileToUpload: Dispatch<SetStateAction<File | null>>;
}

const isMobile = window.innerWidth <= (breakpoints?.values?.v2_sm || 0);
const HORIZONTAL_PADDING_AND_OFFSET = 40;
const CANVAS_MOBILE_WIDTH = window.innerWidth - HORIZONTAL_PADDING_AND_OFFSET;
const CANVAS_DESKTOP_WIDTH = 683;
const CANVAS_HEIGHT = 184;

function Sign({ setFileToUpload }: Props) {
  const handleSetDataUrl = async (e: any) => {
    if (!e) return;

    const file = await dataURLtoFile(e, "signature.png");
    setFileToUpload(file);
  };

  return (
    <Box mt="16px">
      <SignatureCanvas
        setDataURL={handleSetDataUrl}
        height={CANVAS_HEIGHT}
        width={isMobile ? CANVAS_MOBILE_WIDTH : CANVAS_DESKTOP_WIDTH}
      />
    </Box>
  );
}

export default Sign;
