import NiceModal from "@ebay/nice-modal-react";
import { CAMPAIGN_PANEL_WIDTH } from "@common/CampaignCard/CampaignDetailsModal/useCampaignModal";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import DisputePreviewHeader, {
  IDisputePreviewHeaderProps,
} from "@components/Disputes/DisputePreview/components/DisputePreviewHeader";
import DisputePreviewBody from "@components/Disputes/DisputePreview/components/DisputePreviewBody";
import { SidePanel } from "@containers/SidePanel";
import LoadingState from "@components/ManageMoney/TransactionTable/TransactionInfoModal/LoadingState";
import { Box } from "@mui/material";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import DisputePreviewMobilePanel from "./Mobile/DisputePreviewMobilePanel";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import DisputePreviewMobileBody from "./Mobile/DisputePreviewMobileBody";
import { useDisputePreview } from "./hooks/useDisputePreview";

interface IComponentProps extends IDisputePreviewHeaderProps {
  id: string;
}

const DisputePreviewPanel = NiceModal.create(
  ({ isFirst, isLast, setSelectedRow, id }: IComponentProps) => {
    const { modal, onClose, open: isModalVisible, SlideProps } = useNiceModal();
    const { isMobileView } = useCustomTheme();
    const { data, isLoading } = useDisputePreview({ id });

    const handleClose = () => {
      if (setSelectedRow) setSelectedRow(-1);
      onClose();
    };

    if (isMobileView)
      return (
        <DisputePreviewMobilePanel
          onCloseDrawer={handleClose}
          isModalVisble={isModalVisible}
          SlideProps={SlideProps}
        >
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <DisputePreviewMobileBody data={data?.dispute} />
          )}
        </DisputePreviewMobilePanel>
      );

    return (
      <SidePanel
        modal={modal}
        onCloseDrawer={handleClose}
        paperStyle={{ width: CAMPAIGN_PANEL_WIDTH }}
      >
        {isLoading ? (
          //Todo: Design real loading state for panel
          <LoadingState />
        ) : (
          <Box overflow="auto" padding="8px 24px">
            <DisputePreviewHeader
              isFirst={isFirst}
              isLast={isLast}
              setSelectedRow={setSelectedRow}
              data={data?.dispute}
            />
            <DisputePreviewBody data={data} />
          </Box>
        )}
      </SidePanel>
    );
  },
);

export default DisputePreviewPanel;
