import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import NiceModal from "@ebay/nice-modal-react";
import { Grid, Stack } from "@mui/material";
import GiveButton from "@shared/Button/GiveButton";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  OPERATIONS,
  UPLOAD_DENY_MESSAGE,
} from "@constants/permissions";
import { IDropzoneProps } from "react-dropzone-uploader";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import GiveMotionWrapper from "@shared/Motion/GiveMotionWrapper";
import HFGiveCustomAmount from "@shared/HFInputs/HFGiveCustomAmount/HFGiveCustomAmount";
import { HFGiveInput } from "@shared/HFInputs/HFGiveInput/HFGiveInput";
import HFGiveSelect from "@shared/HFInputs/HFGiveSelect/HFGiveSelect";
import HFGiveUploadAvatar from "@shared/HFInputs/HFGiveUploadAvatar/HFGiveUploadAvatar";
import HFGiveIntegerInput from "@shared/HFInputs/HFGiveInput/HFGiveIntegerInput";
import { showMessage } from "@common/Toast";
import { useUploadVariantImage } from "@hooks/merchant-api/fundraisers";
import { uniqueId } from "lodash";
import { useEffect, useState } from "react";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import { PaymentRecurringType, ProductItemType } from "../types";
import { Trash } from "@phosphor-icons/react";

const defaultValues = {
  thumbnail: "",
  amount: "",
  title: "",
  description: null,
  in_stock: null,
  display: false,
  paymentType: "one_time",
};

type FormInputs = {
  thumbnail: string | File;
  amount: string;
  title: string;
  in_stock?: number | null;
  display?: boolean;
  description: string | null;
  paymentType: string;
};

type Props = {
  item?: ProductItemType;
  hasUniqueTitle?: (title: string, id?: string) => boolean;
  onClose?: (item?: ProductItemType) => void;
  handleDelete: () => void;
};

const AddProductItemModal = NiceModal.create(
  ({ item, hasUniqueTitle, onClose, handleDelete }: Props) => {
    const { open, onClose: hideModal } = useNiceModal();
    const { handlePostImage } = useUploadVariantImage();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const isUpdateAllowed = useAccessControl({
      resource: composePermission(
        RESOURCE_BASE.MERCHANT,
        RESOURCE_BASE.PRODUCT,
        RESOURCE_BASE.AMOUNT,
      ),
      operation: OPERATIONS.UPDATE,
    });

    const canEdit = (!!item && isUpdateAllowed) || !item;

    const isAddImageAllowed = useAccessControl({
      resource: RESOURCE_BASE.MEDIA_ITEM,
      operation: OPERATIONS.CREATE,
      withPortal: true,
    });

    const schema = Yup.object().shape({
      title: Yup.string().trim().required("This field is required"),
      amount: Yup.string()
        .required("This field is required")
        .test("Must be above 1 USD", (value) => {
          if (!value) return false;
          const valueToNumber = parseFloat(value);
          if (Number.isNaN(valueToNumber)) return false;
          return valueToNumber >= 1;
        }),
      in_stock: Yup.number().nullable(),
      display: Yup.boolean(),
      thumbnail: Yup.mixed<File | string>(),
      description: Yup.string().nullable(),
    });

    const methods = useForm<FormInputs>({
      mode: "onChange",
      resolver: yupResolver(schema),
      defaultValues,
    });

    const {
      setValue,
      reset,
      formState: { dirtyFields },
    } = methods;

    const handleChangeStatus: IDropzoneProps["onChangeStatus"] = ({ file }) => {
      if (file) {
        setValue(
          "thumbnail",
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
          { shouldDirty: true },
        );
      }
    };

    const onSubmit: SubmitHandler<FormInputs> = async (data) => {
      if (hasUniqueTitle && !hasUniqueTitle(data.title, item?.id)) {
        showMessage("Error", "An element with the same name already exists");
        return;
      }
      setIsLoading(true);
      let url;
      try {
        if (typeof data.thumbnail === "string" || !data.thumbnail) {
          url = data.thumbnail;
        } else {
          url = (await handlePostImage(data.thumbnail)) || null;
        }
      } catch (error) {
        url = null;
      }

      const amount: ProductItemType = {
        id: item ? item.id : uniqueId(),
        title: dirtyFields.title || !item ? data.title : item.title,
        amount: dirtyFields.amount || !item ? data.amount : item.amount,
        description:
          dirtyFields.description || !item
            ? data.description ?? ""
            : item.description,
        thumbnail: dirtyFields.thumbnail || !item ? url : item.thumbnail,
        in_stock: data?.in_stock,
        paymentType: data?.paymentType as PaymentRecurringType,
      };
      setIsLoading(false);
      hideModal();

      if (onClose) onClose(amount);
    };

    useEffect(() => {
      if (item) {
        reset({
          thumbnail: item?.thumbnail || "",
          amount: item.amount,
          in_stock: item.in_stock,
          title: item.title,
          description: item.description,
          paymentType: item.paymentType,
        });
      }

      if (!item) {
        reset(defaultValues);
      }
    }, [item, open]);

    const nodesList = [
      {
        node: (
          <WithTooltipWrapper
            hasTooltip={!isAddImageAllowed}
            tooltipProps={{
              show: !isAddImageAllowed,
              message: UPLOAD_DENY_MESSAGE,
            }}
          >
            <HFGiveUploadAvatar
              avatarType="thumbnail"
              name="thumbnail"
              onChangeStatus={handleChangeStatus}
              disabled={!canEdit || !isAddImageAllowed}
              title="Images"
            />
          </WithTooltipWrapper>
        ),
      },
      {
        node: <HFGiveInput label="Name" name="title" disabled={!canEdit} />,
      },
      {
        node: (
          <HFGiveCustomAmount
            name="amount"
            label="Price"
            currency="usd"
            disabled={!canEdit}
          />
        ),
        xs: 6,
      },
      {
        node: (
          <HFGiveSelect
            label="One Time or Recurring"
            name="paymentType"
            options={recurringOptions}
            useContextualMenu
            contextualMenuProps={{
              color: "tertiary",
              texture: "blurred",
              menuWidth: 278,
            }}
            disabled={!canEdit}
          />
        ),
        xs: 6,
      },
      {
        node: (
          <HFGiveInput
            label="Description (Optional)"
            name="description"
            multiline
            rows={8}
            disabled={!canEdit}
          />
        ),
      },
      {
        node: (
          <HFGiveIntegerInput
            label="Stock (Optional)"
            name="in_stock"
            disabled={!canEdit}
          />
        ),
      },
    ];

    return (
      <GiveBaseModal
        open={open}
        title="Add Item"
        width="640px"
        height="95%"
        onClose={hideModal}
        footerLeftContent={
          item && handleDelete ? (
            <GiveButton
              label="Delete"
              variant="ghost"
              color="destructive"
              size="large"
              startIcon={<Trash size={18} />}
              onClick={handleDelete}
            />
          ) : null
        }
        buttons={
          <Stack gap="12px" flexDirection="row">
            <GiveButton
              onClick={hideModal}
              label="Cancel"
              variant="ghost"
              size="large"
            />
            <GiveButton
              label={item ? "Save Changes" : "Add"}
              variant="filled"
              size="large"
              disabled={isLoading}
              form="create-product-item"
              onClick={methods.handleSubmit(onSubmit)}
            />
          </Stack>
        }
      >
        <FormProvider {...methods}>
          <Grid
            container
            component="form"
            spacing={3}
            id="create-product-item"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            {nodesList.map(({ node, xs }, index) => (
              <Grid item xs={xs || 12} key={index}>
                <GiveMotionWrapper delay={50 * (index + 1)}>
                  {node}
                </GiveMotionWrapper>
              </Grid>
            ))}
          </Grid>
        </FormProvider>
      </GiveBaseModal>
    );
  },
);

const recurringOptions = [
  { value: "one_time", label: "One Time" },
  { value: "monthly", label: "Monthly" },
  { value: "quarterly", label: "Quarterly" },
  { value: "yearly", label: "Yearly" },
];

export default AddProductItemModal;
