import { Stack } from "@mui/material";
import { useAppTheme } from "@theme/v2/Provider";
import ChallengeLayout from "./ChallengeLayout";
import ImagesStacker from "features/Minibuilders/Conversations/utils/ImagesStacker";
import {
  BellSimpleRinging,
  CaretRight,
  ChatsCircle,
  CheckCircle,
  CircleDashed,
  DotsThreeVertical,
} from "@phosphor-icons/react";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { CustomPalette } from "@theme/v2/palette.interface";
import { TChallengeStatus } from "./types";
import GiveText from "@shared/Text/GiveText";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import GiveButton from "@shared/Button/GiveButton";
import { useNotifications } from "./hooks/useNotifications";
import NiceModal from "@ebay/nice-modal-react";
import { BASE_CHALLENGE_MODAL } from "modals/modal_names";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import moment from "moment";

interface Props {
  challenge: any; //TODO
  isMerchantOnboarding?: boolean;
  handleOpenMenu: (e: HTMLElement, challenge: any) => void;
}

export default function ChallengeItem({
  challenge,
  isMerchantOnboarding,
  handleOpenMenu,
}: Props) {
  const { data: merchantData, id: merchantId } = useMerchantSidePanelContext();
  const { openConversationHandler } = useConversationsModal();
  const { openNotifyMerchantModal } = useNotifications();

  const { palette } = useAppTheme();
  const isMerchantNotified = Boolean(challenge.notifiedAt);

  const openChallenge = () => {
    NiceModal.show(BASE_CHALLENGE_MODAL, {
      name: challenge.name,
      title: challenge.merchantDisplayTitle,
      message: challenge.merchantDisplayName,
      threadId: 0,
      challengeTypeName: challenge.challengeTypeName,
      merchantContextData: {
        data: merchantData,
        id: merchantId,
      },
      isPAHChallenge: challenge.categoryName === "primary_account_holder",
      isChallengeDone: challenge.statusName === "closed",
      discussionMessageImages: challenge.userAvatarsInThread,
      discussionTitle: challenge.merchantDisplayTitle,
      discussionCommentsCount: challenge.totalUsersCommentedInThread,
      discussionLastComment: challenge.notifiedAt
        ? moment.unix(challenge.notifiedAt).fromNow()
        : "",
    });
  };

  return (
    <ChallengeLayout
      icon={getStatusIcons(challenge.statusName, palette)}
      applyIconBG={false}
      alwaysShowConversation
      attachConversations={
        <Stack direction="row" alignItems="center">
          <ImagesStacker data={[]} />
          <GiveIconButton
            Icon={ChatsCircle}
            variant="ghost"
            onClick={(e) => {
              e.stopPropagation();
              openConversationHandler({
                id: 0,
                name: challenge.displayName,
                paths: [],
              });
            }}
          />
        </Stack>
      }
      main={
        <Stack>
          <GiveText
            variant="bodyS"
            sx={{
              color: palette.primitive?.neutral[90],
            }}
          >
            {challenge.merchantDisplayTitle}
          </GiveText>
          <Stack direction="row" gap="6px" alignItems="center">
            <GiveText variant="bodyXS" color="secondary" pr="10px">
              {StatusDisplayName[challenge.statusName as TChallengeStatus]}
            </GiveText>
            {isMerchantNotified && (
              <>
                <BellSimpleRinging size={16} color={palette.text.secondary} />
                <GiveText variant="bodyXS" color="secondary">
                  Merchant Notified
                </GiveText>
              </>
            )}
          </Stack>
        </Stack>
      }
      end={
        isMerchantOnboarding ? (
          <GiveButton
            label="Notify"
            size="small"
            color="light"
            variant="filled"
            sx={{ border: "none" }}
            onClick={(e) => {
              e.stopPropagation();
              openNotifyMerchantModal();
            }}
          />
        ) : (
          <Stack direction="row" gap="8px" alignItems="center">
            <GiveIconButton
              Icon={DotsThreeVertical}
              variant="ghost"
              onClick={(e) => {
                e.stopPropagation();
                handleOpenMenu(e.currentTarget, challenge);
              }}
            />
            <GiveIconButton
              Icon={CaretRight}
              variant="ghost"
              size="small"
              onClick={openChallenge}
            />
          </Stack>
        )
      }
      onClick={openChallenge}
    />
  );
}

const getStatusIcons = (status: TChallengeStatus, palette: CustomPalette) => {
  const icons = {
    closed: (
      <CheckCircle
        size={24}
        color={palette.primitive?.success[50]}
        weight="fill"
      />
    ),
    ready_for_verification: (
      <CheckCircle size={24} color={palette.primitive?.success[50]} />
    ),
    open: <CircleDashed size={24} color={palette.icon?.["icon-secondary"]} />,
  };
  return icons[status];
};

const StatusDisplayName = {
  closed: "Completed",
  ready_for_verification: "Ready for Confirmation",
  open: "Open",
};
