import XCircle from "@assets/icons/RebrandedIcons/XCircle";
import { FilterFunnelIcon } from "@assets/rebrandIcons";
import { Button } from "@common/Button";
import NiceModal from "@ebay/nice-modal-react";
import { styled } from "@mui/material";
import { palette } from "@palette";
import { useAppSelector } from "@redux/hooks";
import {
  getSelectedFiltersAmount,
  useTableFilters,
} from "@redux/slices/tableFilters";
import { TABLE_FILTERS_MODAL } from "modals/modal_names";
import { selectMerchantStatusName } from "@redux/slices/enterprise/merchants";
import { StatusNames } from "@components/Merchants/MerchantList/MerchantStatusFilters";
import { MouseEventHandler, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function FilterButton() {
  const filtersAmount = useAppSelector(getSelectedFiltersAmount);
  const statusName = useAppSelector(selectMerchantStatusName);
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const { resetTableFilters } = useTableFilters();

  const currentTab = useMemo(() => {
    switch (statusName) {
      case StatusNames.aquirerUnderwriting:
      case StatusNames.underwritingAsProvider:
        return "underwriting";
      case StatusNames.aquirerRisk:
      case StatusNames.enterpriseApproved:
        return "risk";
      default:
        return "portfolio";
    }
  }, [statusName]);
  const handleOpenModal = () => {
    NiceModal.show(TABLE_FILTERS_MODAL, { tab: currentTab });
    //TODO: send correct props
  };

  const handleResetFilters: MouseEventHandler = (e) => {
    e.stopPropagation();
    resetTableFilters();
  };

  useEffect(() => {
    const isDefaultFilterActive = state && state?.filtersActive;

    //Ensure default filter is only applied once
    if (isDefaultFilterActive) {
      navigate(pathname, { replace: true });
    }

    //Reset filters once component unmounts
    return () => {
      resetTableFilters(true);
    };
  }, [currentTab]);

  return (
    <StyledButton
      background="secondary"
      startIcon={
        <FilterFunnelIcon
          fill={filtersAmount ? palette.accent[3] : palette.neutral[60]}
        />
      }
      hasFilters={!!filtersAmount}
      onClick={handleOpenModal}
    >
      Filter {filtersAmount ? `(${filtersAmount})` : ""}
      {!!filtersAmount && (
        <XCircle
          fill={palette.neutral[80]}
          bordered={false}
          onClick={handleResetFilters}
        />
      )}
    </StyledButton>
  );
}

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "hasFilters",
})<{ hasFilters?: boolean }>(({ hasFilters }) => ({
  padding: "0 12px",
  border: hasFilters
    ? `1px solid ${palette.accent[3]}`
    : `1px solid ${palette.neutral[10]}`,
  background: hasFilters ? palette.info.light : palette.background.dimmedWhite,
  color: hasFilters ? palette.accent[3] : palette.neutral[70],
  fontWeight: 350,
  height: "32px",
  lineHeight: "16.8px",
  gap: "4px",
  "&:hover": {
    background: palette.background.dimmedWhite,
  },
}));
