import Chip, { ChipOption } from "@common/TableFilters/Chip";
import CustomVolumePopup from "@common/TableFilters/CustomVolumePopup";
import ChipsContainer from "@common/TableFilters/WrapperWithDateRange";
import { Switch_V2 as Switch } from "@common/Switch";
import {
  getCustomProcessingLabel,
  getProcessingAmount,
} from "@common/TableFilters/utils";
import { Text } from "@common/Text";
import { Grid, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { isArray, isEmpty, isEqual } from "lodash";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import CheckFillIcon from "@assets/icons/RebrandedIcons/CheckFillIcon";
import SquareExclamationIcon from "@assets/icons/RebrandedIcons/SquareExclamationIcon";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  resetFilterByKey,
  selectFiltersObject,
  setFilterByKey,
} from "@redux/slices/dynamicFilterSlice";
import { QKEY_RISK_MONITOR_TRANSACTIONS } from "@constants/queryKeys";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { FilterObjectTypes } from "@customTypes/utils.types";
import { findByIdAndModify, getAmountArray } from "@utils/helpers";

enum QUERYOBJECTKEYS {
  transactionDate = "transactionDate",
  riskLevel = "riskLevel",
  createdAt = "date",
  transaction_status = "txnDisplayStatus",
  payment_method = "paymentMethod",
  inflow_amount = "inFlow",
  outflow_amount = "outFlow",
  email_status = "emailStatus",
  blocked_transactions = "isBlocked",
  false_positive_transactions = "isFalsePositive",
}

type TransactionDateType = {
  dateName?: string;
  endDate?: Date;
  startDate?: Date;
  label?: string;
  val?: string | number | ChipOption;
  createdAt?: string;
};

export const defaultValuesRiskMonitorTrans = {
  [QUERYOBJECTKEYS.transactionDate]: {
    dateName: "date",
    endDate: undefined,
    label: undefined,
    startDate: undefined,
    val: undefined,
  } as TransactionDateType,
  [QUERYOBJECTKEYS.riskLevel]: [] as Array<FilterObjectTypes>,
  [QUERYOBJECTKEYS.transaction_status]: [] as Array<FilterObjectTypes>,
  [QUERYOBJECTKEYS.createdAt]: "",
  [QUERYOBJECTKEYS.payment_method]: {} as FilterObjectTypes,
  [QUERYOBJECTKEYS.inflow_amount]: {} as FilterObjectTypes,
  [QUERYOBJECTKEYS.outflow_amount]: {} as FilterObjectTypes,
  [QUERYOBJECTKEYS.email_status]: [] as Array<FilterObjectTypes>,
  [QUERYOBJECTKEYS.false_positive_transactions]: false,
  [QUERYOBJECTKEYS.blocked_transactions]: false,
};

export type DefaultValuesType = typeof defaultValuesRiskMonitorTrans;

export default function useTransactionFilter() {
  const dispatch = useAppDispatch();
  const { open, onClose, TransitionProps, SlideProps } = useNiceModal();
  const filterObject = useAppSelector((state) =>
    selectFiltersObject(state, QKEY_RISK_MONITOR_TRANSACTIONS),
  ) as DefaultValuesType;
  const methods = useForm<DefaultValuesType>({
    defaultValues: isEmpty(filterObject)
      ? defaultValuesRiskMonitorTrans
      : filterObject,
  });

  const onSubmit = (data: any) => {
    dispatch(
      setFilterByKey({
        filterKey: QKEY_RISK_MONITOR_TRANSACTIONS,
        params: data,
      }),
    );
    onClose();
  };

  const values = methods.watch();
  const isObjectsEqual = isEqual(
    values,
    isEmpty(filterObject) ? defaultValuesRiskMonitorTrans : filterObject,
  );
  const canClearObj = isEqual(values, defaultValuesRiskMonitorTrans);

  const actions = {
    primaryAction: {
      label: "Apply filters",
      disabled: isObjectsEqual,
      sx: {
        fontSize: "18px",
        lineHeight: "18px",
        padding: "12px 24px",
      },
    },
    secondaryAction: {
      label: "Clear all filters",
      onClick: () => {
        methods.reset(defaultValuesRiskMonitorTrans);
        dispatch(
          resetFilterByKey({
            filterKey: QKEY_RISK_MONITOR_TRANSACTIONS,
          }),
        );
        onClose();
      },
      sx: {
        fontSize: "18px",
        lineHeight: "18px",
        padding: "12px 24px",
        color: palette.filled.red,
      },
      hidden: canClearObj,
    },
  };

  const renderChipsArray = ({
    arr,
    key,
  }: {
    arr: any[];
    key: QUERYOBJECTKEYS;
  }) => {
    return (
      <Wrapper>
        {arr?.map((option) => {
          const selectedArray = values?.[key];
          if (!isArray(selectedArray)) return;
          const isSelected = selectedArray?.find(
            (item: any) => item.value === option.value,
          );

          return (
            <Chip
              key={option.value}
              item={option}
              selected={!!isSelected}
              allowMultiSelect
              onSelect={() => {
                const newArray = findByIdAndModify(
                  selectedArray as FilterObjectTypes[],
                  option,
                );
                methods.setValue(key, newArray, {
                  shouldDirty: true,
                });
              }}
            />
          );
        })}
      </Wrapper>
    );
  };

  const renderFlowAmountChip = ({
    key,
  }: {
    key: QUERYOBJECTKEYS.inflow_amount | QUERYOBJECTKEYS.outflow_amount;
  }) => {
    const prefix = key === QUERYOBJECTKEYS.inflow_amount ? "inflow" : "outflow";
    return (
      <Wrapper>
        {getAmountArray(prefix)?.map((option) => {
          const selectedValue = values?.[key];
          const isCustom =
            (selectedValue?.label?.toLowerCase().includes("custom") &&
              option?.label === "Custom") ||
            false;
          const isSelected = selectedValue?.label === option?.label || isCustom;
          return (
            <Chip
              key={option.value}
              item={
                isCustom
                  ? {
                      label: selectedValue?.label || "Custom",
                      value: option.value,
                    }
                  : option
              }
              selected={isSelected}
              onSelect={() => {
                methods.setValue(key, isSelected ? {} : option);
              }}
              {...(option.value === "custom" && {
                PopupComponent: CustomVolumePopup,
                popupCallback: (e) =>
                  handleSaveProcessingVolume({
                    ...e,
                    key: key,
                    prefix,
                  }),
              })}
            />
          );
        })}
      </Wrapper>
    );
  };
  const handleSaveProcessingVolume = (param: {
    from: number | undefined;
    to: number | undefined;
    key: string;
    prefix?: string;
  }) => {
    const stringValue = getProcessingAmount({
      ...(typeof param.from === "number" && { amount: param.from * 100 }),
      ...(typeof param.to === "number" && { secondAmount: param.to * 100 }),
      modifier: "is between",
      prefix: param?.prefix,
    });

    const customLabel = getCustomProcessingLabel(param?.from, param?.to);
    methods.setValue(param?.key as any, {
      label: customLabel,
      value: stringValue,
    });
  };
  const transactionSections = useMemo(() => {
    const transactionDate = {
      title: "Transaction Date",
      description:
        "See transactions created within a specific date range. Click on the transaction to see details.",
      children: (
        <Wrapper>
          <ChipsContainer
            dateName={QUERYOBJECTKEYS.createdAt}
            selectedDate={values?.transactionDate}
            rangeSaveCallback={(val) =>
              methods.setValue(QUERYOBJECTKEYS.transactionDate, val, {
                shouldDirty: true,
              })
            }
          />
        </Wrapper>
      ),
    };

    const riskLevel = {
      title: "Risk Level",
      description: "View transactions with certain risk level",
      children: renderChipsArray({
        arr: riskLevelArray,
        key: QUERYOBJECTKEYS.riskLevel,
      }),
    };

    const tStatus = {
      title: "Transaction Status",
      description: "",
      children: renderChipsArray({
        arr: transactionStatusStaticArr,
        key: QUERYOBJECTKEYS.transaction_status,
      }),
    };

    const flags = {
      title: "Transaction Flags",
      description: "",
      children: (
        <Wrapper>
          <FlagsComponent
            checked={values?.[QUERYOBJECTKEYS?.blocked_transactions]}
            onChange={(state) =>
              methods.setValue(QUERYOBJECTKEYS?.blocked_transactions, state)
            }
            Icon={SquareExclamationIcon}
            title="Blocked Transactions"
            description="Transactions that have been flagged and stopped due to
                suspicious activity or violation of policies."
            isBorderBottom
          />

          <FlagsComponent
            checked={values?.[QUERYOBJECTKEYS?.false_positive_transactions]}
            onChange={(state) =>
              methods?.setValue(
                QUERYOBJECTKEYS?.false_positive_transactions,
                state,
              )
            }
            Icon={CheckFillIcon}
            title="False Positive Transactions"
            description="A false positive transaction is a legitimate transaction"
          />
        </Wrapper>
      ),
    };

    const paymentMethodObj = {
      title: "Payment Method",
      description: "",
      children: (
        <Wrapper>
          {paymentMethodsStaticArray?.map((option) => {
            const selectedValue = values?.[QUERYOBJECTKEYS.payment_method];
            const selected = selectedValue?.value === option?.value;
            return (
              <Chip
                key={option.value}
                item={option}
                selected={selectedValue?.value === option?.value}
                onSelect={(val) => {
                  methods.setValue(
                    QUERYOBJECTKEYS.payment_method,
                    selected ? {} : option,
                  );
                }}
              />
            );
          })}
        </Wrapper>
      ),
    };

    const emailStatusObj = {
      title: "Email Status",
      description: "",
      children: renderChipsArray({
        arr: emailStatusArray,
        key: QUERYOBJECTKEYS.email_status,
      }),
    };
    const inflowObj = {
      title: "Inflow Amount",
      description:
        "View transactions based on the amount of money received within specific range",
      children: renderFlowAmountChip({ key: QUERYOBJECTKEYS.inflow_amount }),
    };

    const outflowOBJ = {
      title: "Outflow Amount",
      description:
        "View transactions based on the amount of money withdrawn within specific range",
      children: renderFlowAmountChip({ key: QUERYOBJECTKEYS.outflow_amount }),
    };
    return [
      transactionDate,
      riskLevel,
      tStatus,
      flags,
      emailStatusObj,
      inflowObj,
      outflowOBJ,
      paymentMethodObj,
    ];
  }, [values]);
  return {
    methods,
    onSubmit,
    actions,
    transactionSections,
    open,
    onClose,
    TransitionProps,
    SlideProps,
  };
}

const Wrapper = styled(Stack)(() => ({
  display: "flex",
  flexDirection: "row",
  spacing: "8px",
  flexWrap: "wrap",
  gap: "8px",
}));

const transactionStatusStaticArr = [
  {
    value: "Authorized",
    label: "Authorized ",
  },
  {
    value: "Chargeback",
    label: "Chargeback",
  },
  {
    value: "Chargeback Reversal",
    label: "Chargeback Reversal",
  },
  {
    value: "Pending",
    label: "Pending",
  },
  {
    value: "Refunded",
    label: "Refunded",
  },
  {
    value: "Settled",
    label: "Settled",
  },
  {
    value: "Voided",
    label: "Voided",
  },
  {
    value: "Declined",
    label: "Declined",
  },
];

const paymentMethodsStaticArray = [
  {
    value: "CREDIT",
    label: "Credit Card",
  },
  {
    value: "DEBIT",
    label: "Debit Card",
  },
];

const emailStatusArray = [
  {
    value: "valid",
    label: "Valid",
    activeColor: "#108548",
    activeBackgroundColor: "#E6F3EC",
  },
  {
    value: "pending",
    label: "Pending",
    activeColor: "#575353",
    activeBackgroundColor: "#ECECE9",
  },
  {
    value: "rejected",
    label: "Rejected",
    activeColor: "#BB1111",
    activeBackgroundColor: "#F7E6E5",
  },
];

const FlagsComponent = ({
  title,
  description,
  onChange,
  checked,
  isBorderBottom,
  Icon,
}: {
  title: string;
  checked: boolean;
  description: string;
  onChange: (state: boolean) => void;
  isBorderBottom?: boolean;
  Icon: ({
    width,
    fill,
    height,
  }: {
    width?: string | undefined;
    fill?: string | undefined;
    height?: string | undefined;
  }) => JSX.Element;
}) => (
  <Grid
    container
    direction="row"
    alignItems="flex-start"
    style={{
      borderBottom: isBorderBottom ? "1px solid #ECECE9" : undefined,
      paddingBottom: isBorderBottom ? "8px" : undefined,
      paddingTop: isBorderBottom ? undefined : "8px",
      gap: "8px",
    }}
  >
    <Grid item xs={1}>
      <Icon height="20" width="20" />
    </Grid>
    <Grid item xs={8} container direction="column" justifyContent="flex-start">
      <Text fontWeight="book" fontSize="14px" color="#575353">
        {title}
      </Text>
      <Text fontWeight="book" fontSize="14px" color="#8F8F8F">
        {description}
      </Text>
    </Grid>
    <Grid
      item
      xs={2}
      container
      alignItems="flex-start"
      style={{ marginLeft: "auto" }}
    >
      <Switch
        size="small"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
    </Grid>
  </Grid>
);

export const riskLevelArray = [
  {
    value: 0,
    id: "low",
    label: "Low",
  },
  {
    value: 1,
    id: "medium",
    label: "Medium",
  },
  {
    value: 2,
    id: "high",
    label: "High",
  },
  {
    value: 3,
    id: "critical",
    label: "Critical",
  },
  {
    value: 4,
    id: "Suspended",
    label: "Suspended",
  },
];
