import { isSecondVersion } from "@theme/v2/utils";
import { ThemeVersions } from "@theme/v2/types";
import { borderRadius } from "@material-ui/system";

declare module "@mui/material/TextField" {
  interface FilledTextFieldProps {
    version?: ThemeVersions;
  }
  interface OutlinedTextFieldProps {
    version?: ThemeVersions;
  }
  interface StandardTextFieldProps {
    version?: ThemeVersions;
  }
}

export const getTextFieldOverrides = (palette: any, basePallete: any) => ({
  styleOverrides: {
    root: {
      variants: [
        {
          props: (props: any) => isSecondVersion(props.version),
          style: {
            "& .MuiInputBase-root": {
              borderRadius: "12px",
              padding: "14px 16px",
              fontSize: "14px",
              boxShadow: "none",
              backgroundColor: palette.surface.primary,
              border: `1.5px solid ${palette.border.secondary}`,

              "& .MuiInputAdornment-root.MuiInputAdornment-positionStart": {
                marginRight: "4px",
              },

              "& .MuiInputAdornment-root.MuiInputAdornment-positionEnd": {
                display: "flex",
                gap: "4px",
                flexDirection: "row",
                alignItems: "center",
              },

              "& fieldset": {
                border: "none",
              },

              "& input": {
                padding: 0,
                fontFamily: "Give Whyte",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "17px",
                marginTop: "0",

                "&::-ms-reveal": {
                  display: "none",
                },
                "&::-ms-clear": {
                  display: "none",
                },

                "&::placeholder": {
                  fontFamily: "Give Whyte",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "17px",
                  color: palette.text.primary,
                },

                ":-webkit-autofill, :-webkit-autofill:focus": {
                  transition: "background-color 600000s 0s",
                  animationName: "onAutoFillStart",
                },
                ":not(:-webkit-autofill)": {
                  animationName: "onAutoFillCancel",
                },
                "@keyframes onAutoFillStart": {},
                "@keyframes onAutoFillCancel": {},
              },

              "& textarea": {
                fontFamily: "Give Whyte",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "17px",
                color: palette.text.primary,
                marginTop: "0",
              },

              "&:hover": {
                borderColor: palette.border.active,
              },
            },

            "& .MuiInputBase-root.Mui-focused": {
              backgroundColor: palette.surface.primary,
              border: "solid 1.5px transparent",
              backgroundImage: `linear-gradient(${palette.surface.primary}, ${palette.surface.primary}), ${basePallete.gradient["aqua-horizon"].border}`,
              backgroundOrigin: "border-box",
              backgroundClip: "padding-box, border-box",
              color: palette.text.primary,

              "& .MuiInputBase-currencyIcon": {
                color: palette.surface.primary,
              },
            },
            "& .MuiInputBase-root.Mui-error": {
              borderColor: "#D80D0D",
            },

            "& .MuiInputBase-root.Mui-disabled": {
              backgroundColor: palette.surface.secondary,
              borderRadius: "12px",
              color: palette.text.primary,

              "&:hover": {
                border: `1.5px solid ${palette.border.secondary}`,
              },
            },

            "& .MuiInputLabel-root": {
              color: palette.text.primary,
              fontSize: "18px",
              letterSpacing: "-0.01em",
              lineHeight: "22px",
              left: "1px",
            },
            "& .MuiInputLabel-root.Mui-error": {
              color: palette.primitive?.error[100],
            },
            "& .MuiInputLabel-root.MuiInputLabel-shrink": {
              letterSpacing: "0px",
              scale: 0.78,
            },
            "& .MuiInputLabel-root.Mui-focused, .MuiInputLabel-root.Mui-disabled":
              {
                color: palette.text.primary,
              },

            "& .MuiFormHelperText-root": {
              margin: 0,
              textTransform: "none",
              paddingInline: "12px",
              fontSize: "12px",
              marginTop: "4px",
              color: palette.text.primary,
              lineHeight: "14px",
              fontFamily: "Give Whyte",
              fontWeight: 400,
              fontStyle: "normal",
            },
            "& .MuiFormHelperText-root.Mui-error": {
              color: palette.text.primary,
            },
          },
        },
      ],
    },
  },
});
