import { Box, Stack } from "@mui/material";
import { useAppTheme } from "@theme/v2/Provider";
import { PropsWithChildren, ReactNode, useState } from "react";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

interface LayoutProps {
  icon?: ReactNode;
  main?: ReactNode;
  attachConversations: ReactNode;
  end?: ReactNode;
  onClick: () => void;
  applyIconBG?: boolean;
  alwaysShowConversation?: boolean;
}

const ChallengeLayout = ({
  icon,
  main,
  end,
  attachConversations,
  applyIconBG = true,
  alwaysShowConversation = false,
  onClick,
}: PropsWithChildren<LayoutProps>) => {
  const { palette } = useAppTheme();
  const { isDesktopView } = useCustomThemeV2();
  const [isHover, setIsHover] = useState<boolean>(false);
  return (
    <Stack
      direction="row"
      id="challenge-layout1"
      alignItems="center"
      justifyContent="space-between"
      gap={2}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={onClick}
      sx={{
        cursor: "pointer",
      }}
    >
      <Stack alignItems="center" gap={2} direction="row">
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            width: "36px",
            height: "36px",
            borderRadius: "40px",
            backgroundColor: applyIconBG
              ? palette.primitive?.transparent["darken-5"]
              : "transparent",
            padding: applyIconBG ? "8px" : "0px",
          }}
        >
          {icon}
        </Stack>

        <Box>{main}</Box>
      </Stack>
      <Stack direction="row" gap={2} alignItems="center">
        {(isHover || alwaysShowConversation) &&
          isDesktopView &&
          attachConversations}
        {end}
      </Stack>
    </Stack>
  );
};

export default ChallengeLayout;
