import { useCallback } from "react";
import AgreementAndSnapshot from "./AgreementAndSnapshot/AgreementAndSnapshot";
import GiveMerchantFile from "./GiveMerchantFile/GiveMerchantFile";
import { useMerchantSidePanelContext } from "./Provider/MerchantSidePanelProvider";
import { ChallengesSecondPanelWrapper } from "./WithRepository/Challenges/ChallengesSecondPanel";

const SecondPanelOptions = () => {
  const { doublePanel, setDoublePanel, openChallenge } =
    useMerchantSidePanelContext();

  const handleClose = useCallback(() => setDoublePanel(""), []);
  switch (doublePanel) {
    case "merchantFile":
      return <GiveMerchantFile handleClosePanel={handleClose} />;
    case "agreement":
      return <AgreementAndSnapshot />;
    case "challenges":
      return (
        <ChallengesSecondPanelWrapper
          handleClose={handleClose}
          challengeIdentifier={openChallenge?.identifier || ""}
          challengeDisplayName={openChallenge?.displayName || ""}
        />
      );
    default:
      return <></>;
  }
};

export default SecondPanelOptions;
