import { memo } from "react";
// nice-modal
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
//assets
import Popup from "@common/Popup";
import { CustomTheme } from "@theme/v2/palette.interface";
import { CloseIcon } from "@assets/icons";
import { Box } from "@mui/material";
import { Trash } from "@phosphor-icons/react";

type Props = {
  theme: CustomTheme;
  onDelete: () => void;
};

const AdvancedBuilderDeleteCustomColorModal = NiceModal.create(
  ({ theme, onDelete }: Props) => {
    const modal = useModal();

    const handleClose = () => {
      modal.hide();
    };

    const handleDeleteColor = () => {
      handleClose();
      onDelete();
    };

    return (
      <Popup
        {...muiDialogV5(modal)}
        customContainerStyle={{
          backgroundColor: theme.palette.primitive?.neutral[5],
          border: "none",
        }}
        title="Delete Custom Color"
        titleSx={{
          fontSize: "24px",
          color: theme.palette.primitive?.neutral[100],
          lineHeight: "28px",
        }}
        textProps={{
          color: theme.palette.primitive?.neutral[70],
          fontSize: "14px",
          fontWeight: "regular",
          lineHeight: "20px",
          marginTop: "8px",
        }}
        cancelLabel="Cancel"
        cancelButtonProps={{
          size: "medium",
        }}
        cancelButtonSx={{
          color: theme.palette.primitive?.neutral[100],
          fontWeight: "regular",
          padding: "11px 20px",
          "&:hover": {
            backgroundColor: theme.palette.primitive?.transparent["darken-5"],
            textDecoration: "none",
          },
        }}
        onCancel={handleClose}
        actionLabel="Delete"
        submitButtonProps={{
          size: "medium",
        }}
        submitButtonSx={{
          color: theme.palette.primitive?.neutral[0],
          bgcolor: theme.palette.primitive?.error[50],
          fontWeight: "regular",
          padding: "11px 20px",
          "&:hover": {
            backgroundColor: theme.palette.primitive?.error[100],
          },
        }}
        onSubmit={handleDeleteColor}
        HeaderIcon={
          <Box
            sx={{
              borderRadius: "30px",
              width: "48px",
              height: "48px",
              background: theme.palette.gradient["citrus-peel"]?.highlight,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Trash
              size={24}
              color={theme.palette.primitive?.["citrus-peel"]?.[100]}
            />
          </Box>
        }
        HeaderSecondaryIconSx={{
          padding: "6px",
          width: "32px",
          height: "32px",
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: theme.palette.primitive?.transparent["darken-5"],
          },
        }}
        HeaderSecondaryIcon={
          <CloseIcon stroke={theme.palette.primitive?.neutral[90]} />
        }
        HeaderContainerStyles={{
          marginBottom: "20px",
          alignItems: "flex-start",
        }}
      >
        {`Please note that if this color is being used on any widget, it will automatically inherit a default color.`}
      </Popup>
    );
  },
);

export default memo(AdvancedBuilderDeleteCustomColorModal);
