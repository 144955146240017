import { Stack } from "@mui/material";
import { HandHeart } from "@phosphor-icons/react";
import GiveText from "@shared/Text/GiveText";
import CheckoutDetailsSectionItem from "./CheckoutDetailsSectionItem";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { Controller, useFormContext } from "react-hook-form";
import { GiveInput } from "@shared/GiveInputs/GiveInput";

const SupportSection = () => {
  const methods = useFormContext();

  const { methods: leftSidepanelMethods } = usePayBuilderForm();
  const isSupportRendered =
    leftSidepanelMethods.watch().Checkout.support.render;

  return (
    <Stack gap="12px">
      <Stack direction="row" spacing={2}>
        <HandHeart size={24} />
        <GiveText variant="bodyL">Support</GiveText>
      </Stack>
      <CheckoutDetailsSectionItem
        label="Customer Can Choose to Pay Card Fees"
        name="support"
        secondaryText="Applicable only when card details are manually entered. Not applicable for tap-to-pay transactions."
        optionalInput={
          isSupportRendered ? (
            <Stack paddingLeft="28px">
              <Controller
                control={methods.control}
                name="Checkout.support.optionalMessage"
                render={({ field: { onChange, value } }) => {
                  return (
                    <GiveInput
                      label="Note for customer (Optional)"
                      name="support.optionalMessage"
                      value={value}
                      onChange={onChange}
                      maxLength={500}
                      displayCounter
                      multiline
                      rows={6}
                    />
                  );
                }}
              />
            </Stack>
          ) : (
            <></>
          )
        }
      />
    </Stack>
  );
};

export default SupportSection;
