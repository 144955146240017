import GiveMerchantSectionsContainer, {
  TMerchantDataFields,
} from "./GiveMerchantSectionsContainer";
import RiskSummarySection from "./RiskSection/RiskSummarySection";

const GiveMerchantSections = () => {
  return (
    <GiveMerchantSectionsContainer>
      {(props: TMerchantDataFields) => <RiskSummarySection {...props} />}
    </GiveMerchantSectionsContainer>
  );
};

export default GiveMerchantSections;
