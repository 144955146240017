import { Box, Stack, TypographyProps } from "@mui/material";
import { CheckCircle, Warning, XCircle } from "@phosphor-icons/react";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme, styled } from "@theme/v2/Provider";
import { TMerchantAgreementPayload } from "@components/Merchants/CreateMerchantPanel/hooks/useAddMerchantAgreement";
import { StatusInfo } from "../../agreements.types";
import NiceModal from "@ebay/nice-modal-react";
import { NEW_MERCHANT_AGREEMENT_MODAL } from "modals/modal_names";
import { useMerchantSidePanelContext } from "features/Merchants/MerchantSidePanel/Provider/MerchantSidePanelProvider";

interface Props {
  merchantAgreement?: TMerchantAgreementPayload;
  statusInfo?: StatusInfo;
}
const AgreementStatusCard = ({ statusInfo }: Props) => {
  const { data, id } = useMerchantSidePanelContext();
  const { palette } = useAppTheme();
  const { success, error, warning } = palette.primitive || {};
  const { status, msaLastAcceptedVersion, tcVersion, signedOn, signedBy } =
    statusInfo || {};
  const localStatus = status || "notSigned";

  const statusDetails = {
    signed: {
      text: `${signedBy} agreed on ${signedOn} to Version ${msaLastAcceptedVersion}  `,
      Icon: CheckCircle,
      colors: {
        bgColor: success?.[25],
        textColor: success?.[100],
        borderColor: success?.[100],
      },
    },
    notSigned: {
      text: `The agreement has not been signed yet. The agreement can be signed by authorized users or the Primary Account Holder.`,
      Icon: XCircle,
      colors: {
        bgColor: error?.[25],
        textColor: error?.[100],
        borderColor: error?.[100],
      },
    },
    newVersion: {
      text: `${signedBy} agreed on ${signedOn} to version ${msaLastAcceptedVersion} but a new TOS version (${tcVersion}) is available`,
      Icon: Warning,
      colors: {
        bgColor: warning?.[10],
        textColor: warning?.[100],
        borderColor: warning?.[100],
      },
    },
  };

  const { text, Icon, colors } = statusDetails[localStatus];
  const { bgColor, textColor, borderColor } = colors;
  const isNewVersion = localStatus === "newVersion";
  const handleOpenAgreementModal = () =>
    NiceModal.show(NEW_MERCHANT_AGREEMENT_MODAL, { data, merchantId: id });

  return (
    <Container border={`1px solid ${borderColor}`} bgcolor={bgColor}>
      <IconContainer>
        <Icon size="20px" color={textColor} />
      </IconContainer>
      <TextContainer>
        <CustomText variant="bodyS" textColor={textColor}>
          {text}
        </CustomText>
        {isNewVersion && (
          <CheckUpdateText
            onClick={handleOpenAgreementModal}
            variant="bodyS"
            textColor={textColor}
          >
            Check Update
          </CheckUpdateText>
        )}
      </TextContainer>
    </Container>
  );
};

export default AgreementStatusCard;
interface CustomTextProps extends TypographyProps {
  textColor?: string;
}
const CheckUpdateText = styled(GiveText)<CustomTextProps>(({ textColor }) => {
  return {
    color: textColor,
    display: "block",
    textAlign: "left",
    textDecoration: "underline",
    cursor: "pointer",
    marginTop: "14px",
  };
});
const Container = styled(Stack)(({ theme }) => {
  return {
    padding: "12px",
    flexDirection: "row",
    gap: "12px",
    alignItems: "flex-start",
    borderRadius: `${theme.customs?.radius?.extraSmall}px`,
  };
});

const IconContainer = styled(Box)(() => {
  return {
    height: "20px",
    display: "flex",
    alignItems: "center",
    flexShrink: 0,
  };
});

const TextContainer = styled(Box)(() => {
  return {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  };
});

const CustomText = styled(GiveText)<CustomTextProps>(({ textColor }) => {
  return {
    color: textColor,
    display: "block",
    textAlign: "left",
  };
});
