import PEPIcon from "@assets/icons/PEPIcon";
import Xicon from "@assets/icons/Xicon";
import { Text } from "@common/Text";
import { Box, Skeleton, Stack, styled } from "@mui/material";
import { StyledIconButton } from "@common/IconButton";
import { palette } from "@palette";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { BreadCrumbText } from "../../manageUnderwriting/Header";
import { PEPStatusType } from "../types";
import { formatFromUnixWithFormat } from "@utils/index";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  FEATURE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { EditButton } from "../../components/atoms";
import { ConversationsIconNotFilled } from "@assets/icons";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { SyntheticEvent } from "react";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import BackButton from "../../components/BackButton";
import { checkPortals } from "@utils/routing";

interface Props {
  onClose?: () => void;
  isLoading?: boolean;
  breadCrumbDate?: number;
  removeSelectedPEP: () => void;
  status?: PEPStatusType;
  checkPEP: () => void;
  isRunningCheck: boolean;
  showConversations?: boolean;
  goBack?: () => void;
}
function Header({
  onClose,
  isLoading,
  breadCrumbDate,
  removeSelectedPEP,
  status,
  checkPEP,
  isRunningCheck,
  showConversations,
  goBack,
}: Props) {
  const { openConversationHandler } = useConversationsModal();
  const { merchant_underwriting } = useEnterprisePermissions();
  const { isAcquirerPortal } = checkPortals();
  const onConvClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    openConversationHandler({ id: 0, name: "PEP Check", paths: [] });
  };

  const isPageTwo = !!breadCrumbDate;
  const isConfirmedMatch = "confirmed_match" === status;
  const formattedDate = breadCrumbDate
    ? formatFromUnixWithFormat(breadCrumbDate, "DD MMM. YYYY")
    : "";

  const isRunCheckAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.LEGAL_ENTITY,
      RESOURCE_BASE.PRINCIPAL,
      RESOURCE_BASE.PEP,
    ),
    operation: OPERATIONS.CREATE,
  });

  const tooltipProps = {
    showToolTip: !isRunCheckAllowed || isConfirmedMatch,
    message: !isRunCheckAllowed
      ? FEATURE_DENY_MESSAGE
      : "You cannot run a check if the user has already a confirmed match",
  };

  return (
    <>
      <Box alignItems="center" justifyContent="space-between" display="flex">
        <FadeUpWrapper delay={50}>
          <Box gap="8px" alignItems="center" display="flex">
            {goBack && (
              <BackButton
                handleGoBack={isPageTwo ? removeSelectedPEP : goBack}
              />
            )}
            <Text
              sx={{ cursor: "pointer" }}
              fontWeight="regular"
              fontSize="18px"
              color={palette.neutral[80]}
              onClick={removeSelectedPEP}
            >
              Politically Exposed Person
            </Text>
            {isPageTwo && (
              <BreadCrumbText
                color={palette.black[300]}
                text={formattedDate}
                active={false}
                isPrev
              />
            )}
          </Box>
        </FadeUpWrapper>
        <Stack gap={1} direction="row" alignItems="center">
          {showConversations && openConversationHandler && isAcquirerPortal && (
            <EditButton
              onClick={() =>
                openConversationHandler({
                  id: 0,
                  name: "PEP Check",
                  paths: [],
                })
              }
            >
              <ConversationsIconNotFilled />
            </EditButton>
          )}
        </Stack>
        <StyledIconButton
          data-testid="PEP_CLOSE_BUTTON"
          onClick={isPageTwo && !goBack ? removeSelectedPEP : onClose}
        >
          <Xicon />
        </StyledIconButton>
      </Box>
      {!isPageTwo && (
        <>
          <FadeUpWrapper delay={70}>
            <Text
              fontSize="14px"
              color={palette.neutral[70]}
              fontWeight="regular"
            >
              The PEP check identifies politically exposed people by reviewing
              sanction lists to prompt further action.
            </Text>
          </FadeUpWrapper>
          <FadeUpWrapper delay={80}>
            <Box
              alignItems="center"
              justifyContent="space-between"
              display="flex"
              mt="15px"
            >
              <Text
                fontSize="14px"
                color={palette.neutral[80]}
                fontWeight="regular"
              >
                History
              </Text>

              {merchant_underwriting && (
                <Stack spacing={2} direction="row" alignItems="center">
                  {isAcquirerPortal && (
                    <CircularButton onClick={onConvClick}>
                      <ConversationsIconNotFilled />
                    </CircularButton>
                  )}
                  {isLoading ? (
                    <Skeleton
                      sx={{
                        borderRadius: "16px",
                      }}
                      width="92px"
                      variant="rounded"
                      height="18px"
                    />
                  ) : (
                    <CustomToolTip {...tooltipProps}>
                      <CustomButton
                        onClick={checkPEP}
                        disabled={
                          isConfirmedMatch ||
                          isRunningCheck ||
                          !isRunCheckAllowed
                        }
                        data-testid="PEP_check_button"
                      >
                        <PEPIcon
                          fill={
                            isConfirmedMatch
                              ? palette.gray[100]
                              : palette.neutral[100]
                          }
                        />
                        <span>PEP Check</span>
                      </CustomButton>
                    </CustomToolTip>
                  )}
                </Stack>
              )}
            </Box>{" "}
          </FadeUpWrapper>
        </>
      )}
    </>
  );
}

export default Header;

const CustomButton = styled("button")(() => ({
  all: "unset",
  cursor: "pointer",
  color: palette.neutral[100],
  fontWeight: "14px",
  display: "flex",
  alignItems: "center",
  gap: "3px",

  "&:disabled": {
    color: palette.gray[100],
    cursor: "default",
  },
}));

const CircularButton = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0.5,
    padding: "4px",
    borderRadius: "50%",
    cursor: "pointer",
    "&:hover": {
      opacity: 1,
      backgroundColor: theme.palette.neutral[10],
    },
  };
});
