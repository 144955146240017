import { Box, Grid, Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import GiveAssignButton from "@shared/AssignButton/GiveAssignButton";
import SectionCardBase from "../../../../../shared/SidePanel/components/SectionCard/SectionCardBase";
import GiveDivider from "@shared/GiveDivider/GiveDivider";

import { styled } from "@theme/v2/Provider";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import MCCInput from "./MCCInput";
import MerchantRiskCategoryInput from "./MerchantRiskCategoryInput";

interface Props {
  type?: "Underwriter" | "Risk Analyst";
}

function UnderwriterRiskAnalystAssignCard({ type = "Underwriter" }: Props) {
  const { data, id } = useMerchantSidePanelContext();

  const { underwriterName, underwriterImageURL, underwriterEmail, assignee } =
    data?.status || {};

  return (
    <SectionCardBase>
      <Grid container alignItems={assignee ? "flex-start" : "center"}>
        <Grid item xs={6}>
          <GiveText color="secondary" variant="bodyS">
            {type}
          </GiveText>
        </Grid>

        <Grid item xs={assignee ? 12 : 6} sm={6}>
          {assignee ? (
            <Box>
              <Stack direction="row" alignItems="center" gap={1}>
                <GiveAssignButton
                  disabled={false}
                  title="Assign"
                  isOnlyName
                  data={{
                    accID: id,
                    underwriterName,
                    underwriterImageURL,
                    underwriterEmail,
                  }}
                />
              </Stack>
              <Box my="8px">
                <CustomText color="secondary">{underwriterEmail}</CustomText>
              </Box>
              <GiveText sx={{ cursor: "pointer" }} color="link" component="p">
                Message
              </GiveText>
            </Box>
          ) : (
            <GiveAssignButton
              disabled={false}
              title="Assign"
              isOnlyName
              data={{
                accID: id,
                underwriterName,
                underwriterImageURL,
                underwriterEmail,
              }}
            />
          )}
        </Grid>
      </Grid>

      <GiveDivider />

      <Box mt="16px" display="flex" flexDirection="column" gap="16px">
        <MCCInput />
        <MerchantRiskCategoryInput />
      </Box>
    </SectionCardBase>
  );
}

export default UnderwriterRiskAnalystAssignCard;

const CustomText = styled(GiveText)(() => {
  return {
    wordBreak: "break-word",
    whiteSpace: "normal",
    overflowWrap: "break-word",
  };
});
