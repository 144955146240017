import GiveText from "@shared/Text/GiveText";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { styled } from "@theme/v2/Provider";
import { BaseMainContainer } from "./components/BaseMainContainer";
import { LeftPanelActions } from "./components/LeftPanelActions";
import { MobileLeftPanelMainHeader } from "./components/MobileLeftPanelMainHeader";
import { StackRow } from "./components/StackRow";
import Stepper from "./components/Stepper";
import About from "./Forms/About/About";
import PaymentFormItems from "./items/PaymentFormItems";
import LaunchStep from "./LaunchStep/LaunchStep";
import { usePayBuilderContext } from "./provider/PayBuilderContext";
import CheckoutStep from "./Checkout/CheckoutStep";
import { Styling } from "./Forms/Styling/Styling";

const FormStep = [
  () => <About />,
  PaymentFormItems,
  () => <Styling />,
  () => <CheckoutStep />,
  () => <LaunchStep />,
];

function BuilderForm() {
  const { isMobileView } = useCustomThemeV2();
  const { activeItem, activeStepIndex } = usePayBuilderContext();

  const ActiveView = FormStep[activeStepIndex];

  return (
    <Container xs={12} sm={4} item>
      {isMobileView && <MobileLeftPanelMainHeader />}
      <StackRow sx={isMobileView ? { paddingBottom: 0 } : {}}>
        <Stepper />
      </StackRow>
      {isMobileView && (
        <StackRow sx={{ paddingTop: 0, paddingLeft: "22px" }}>
          {<GiveText>{activeItem.label}</GiveText>}
        </StackRow>
      )}
      <StackRow sx={{ overflowY: "auto", flexGrow: 1 }}>
        <ActiveView />
      </StackRow>
      <StackRow
        container
        alignItems="center"
        gap={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",

          borderTop: "1px solid #E5E5E3",
        }}
      >
        <LeftPanelActions />
      </StackRow>
    </Container>
  );
}

export default BuilderForm;

const Container = styled(BaseMainContainer)(({ theme }) => {
  return {
    height: "100dvh",
    backgroundColor: theme.palette.surface?.primary,
    borderRight: `1px solid ${theme.palette.border?.primary} `,
  };
});
