import { useForm } from "react-hook-form";

import {
  Assertion_position,
  MediaItem,
  Item_Layout,
  Product_payload_type,
} from "./provider.type";
import {} from "./provider.type";
import { ProductItemType } from "../types";
import {
  CONTACT_DEFAULT_MESSAGE,
  DEFAULT_RECEIPT_MESSAGE,
  DEFAULT_THANK_YOU_MESSAGE,
  SUPPORT_DEFAULT_MESSAGE,
} from "../Checkout/consts";
import { rgbToHex } from "@mui/material";
import { useMutation } from "react-query";
import { customInstance } from "@services/api";
import { useGetCurrentMerchantId } from "@hooks/common";
import _ from "lodash";

export type FormDataType = typeof initialFormData;

export default function useManagePayFormProvider() {
  const methods = useForm<FormDataType>({
    defaultValues: initialFormData,
  });
  const { merchantId } = useGetCurrentMerchantId();

  const { mutate, isLoading } = useMutation(
    (data: { handleSuccessCB?: () => void }) => {
      const values = methods.watch();

      const productId = values.productId;

      const isPatch = !!productId;

      return customInstance({
        url: isPatch
          ? `/merchants/${merchantId}/products/${productId}`
          : `/merchants/${merchantId}/products`,
        method: isPatch ? "PATCH" : "POST",
        data: generatePayload(values, initialFormData),
      });
    },
    {
      onSuccess(data, variables, context) {
        //NO:Do nto reset anything at this stage
        const newValues = generateFormData(data);
        // methods.reset(newValues);
        variables.handleSuccessCB && variables.handleSuccessCB();
      },
    },
  );
  return {
    methods,
    mutate,
    isLoading,
    parsedValues: {
      accentColor: rgbToHex(methods.watch().Style.accent),
      background: rgbToHex(methods.watch().Style.background),
      itemsLayout: methods.watch().Style.itemLayout,
      heading: methods.watch().About.heading,
      description: methods.watch().About.description,
      selectedImage: methods.watch().About.selectedImage,
      assetPosition: methods.watch().About.assetPosition,
      items: methods.watch().Items,
    },
  };
}

const initialFormData = {
  productId: null as null | number,
  About: {
    heading: "",
    description: "",
    //description: EditorState.createEmpty(),
    assetPosition: "right" as Assertion_position,
    selectedImage: {} as MediaItem,
  },
  Items: [] as ProductItemType[],
  Style: {
    background: "rgb(255,255,255)",
    accent: "rgb(18, 18, 18)",
    itemLayout: "list",
  },
  Checkout: {
    email: {
      render: true,
      required: true,
    },
    phoneNumber: {
      render: false,
      required: false,
      optionalMessage: CONTACT_DEFAULT_MESSAGE,
    },
    delivery: {
      render: false,
      required: false,
    },
    support: {
      render: false,
      required: false,
      optionalMessage: SUPPORT_DEFAULT_MESSAGE,
    },
    thankYouMessage: {
      render: false,
      optionalMessage: DEFAULT_THANK_YOU_MESSAGE,
    },
    receiptMessage: {
      render: false,
      optionalMessage: DEFAULT_RECEIPT_MESSAGE,
    },
  },
  Launch: { heading: "" },
};

const addIfDefined = <T>(
  key: keyof T,
  value: T[keyof T],
  obj: Partial<T>,
  initialValue?: T[keyof T],
) => {
  // Only add if the value is defined and not the same as the initial value
  if (value !== undefined && value !== initialValue) {
    obj[key] = value;
  }
  return obj;
};

const sampleVariant = {
  name: "sad",
  price: 3400,
  description:
    '{"blocks":[{"key":"3p04t","text":"wew","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
  imageURL: "",
  isEnabled: true,
  showAvailableVariants: false,
  inventory: 60,
  bundle: 1,
  id: "2",
  displayOrder: 0,
  recurringIntervals: [
    {
      interval: "once",
    },
  ],
};
const generatePayload = (
  data: Partial<FormDataType>,
  initialFormData: Partial<FormDataType>,
): Partial<Product_payload_type> => {
  const payload: Partial<Product_payload_type> = {
    typeName: "standard",
    variants: [sampleVariant],
  };

  addIfDefined(
    "name",
    data?.About?.heading,
    payload,
    initialFormData?.About?.heading,
  );

  addIfDefined(
    "description",
    data?.About?.description,
    payload,
    initialFormData?.About?.description,
  );

  addIfDefined(
    "imageURL",
    data?.About?.selectedImage?.URL,
    payload,
    initialFormData?.About?.selectedImage?.URL,
  );
  addIfDefined(
    "position",
    data?.About?.assetPosition,
    payload,
    initialFormData?.About?.assetPosition,
  );

  return payload;
};

const generateFormData = (
  data: Partial<Product_payload_type>,
): FormDataType => {
  return {
    productId: data?.id || null,
    About: {
      heading: data?.name || "",
      description: data?.description || "",
      //description: EditorState.createEmpty(),
      assetPosition: "right" as Assertion_position,
      selectedImage: {
        URL: "",
      } as MediaItem,
    },
    Items: [] as ProductItemType[],
    Style: {
      itemLayout: "Card" as Item_Layout,
      background: "rgb(255,255,255)",
      accent: "rgb(18, 18, 18)",
    },
    // TODO: Integrate with API
    Checkout: {
      email: {
        render: true,
        required: true,
      },
      phoneNumber: {
        render: false,
        required: false,
        optionalMessage: CONTACT_DEFAULT_MESSAGE,
      },
      delivery: {
        render: false,
        required: false,
      },
      support: {
        render: false,
        required: false,
        optionalMessage: SUPPORT_DEFAULT_MESSAGE,
      },
      thankYouMessage: {
        render: false,
        optionalMessage: DEFAULT_THANK_YOU_MESSAGE,
      },
      receiptMessage: {
        render: false,
        optionalMessage: DEFAULT_RECEIPT_MESSAGE,
      },
    },
    Launch: { heading: "" },
  };
};
