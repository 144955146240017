import { Stack } from "@mui/material";
import SidePanelHeader from "./SidePanelHeader";
import {
  ISidePanelHeaderProps,
  SidePanelHeaderProvider,
} from "./SidePanelHeaderContext";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

export type SidePanelHeaderVariantProps = ISidePanelHeaderProps & {
  withConversation?: boolean;
  withRisk?: boolean;
  withControlMode?: boolean;
  withMerchantFile?: boolean;
  withAgreement?: boolean;
};

interface IComponentProps {
  variant: "merchant_&_provider" | "challenge" | "custom";
  HeaderProps: SidePanelHeaderVariantProps;
}

const SidePanelHeaderVariant = ({ variant, HeaderProps }: IComponentProps) => {
  const { isMobileView } = useCustomThemeV2();
  return (
    <SidePanelHeaderProvider {...HeaderProps}>
      {variant === "merchant_&_provider" && (
        <SidePanelHeader>
          <Stack
            direction="row"
            gap="4px"
            alignItems="center"
            id="header-left-side"
          >
            {HeaderProps.handlers.displayRowHandlers && (
              <>
                <SidePanelHeader.Button el="prev" />
                <SidePanelHeader.Button el="next" />
              </>
            )}
            {HeaderProps.sidePanelName && <SidePanelHeader.Name />}
          </Stack>
          <Stack
            direction="row"
            gap="16px"
            alignItems="center"
            id="header-right-side"
          >
            {HeaderProps.withConversation && (
              <SidePanelHeader.Button el="conversation" />
            )}
            {HeaderProps.withRisk && (
              <SidePanelHeader.Button el="riskMonitor" />
            )}
            {HeaderProps.withControlMode && (
              <SidePanelHeader.Button el="controlMode" />
            )}
            {HeaderProps.withAgreement && !isMobileView && (
              <SidePanelHeader.Button el="agreement" />
            )}
            {HeaderProps.withMerchantFile && !isMobileView && (
              <SidePanelHeader.Button el="merchantFile" />
            )}
            {HeaderProps.actions.filter((x) => !x.hidden).length && (
              <SidePanelHeader.Button el="moreActions" />
            )}
            <SidePanelHeader.Button el="closeButton" />
          </Stack>
        </SidePanelHeader>
      )}
      {variant === "challenge" && (
        <Stack
          direction="row"
          gap="16px"
          alignItems="center"
          id="header-right-side"
        >
          {HeaderProps.withAgreement && !isMobileView && (
            <SidePanelHeader.Button el="agreement" />
          )}
          {HeaderProps.withMerchantFile && !isMobileView && (
            <SidePanelHeader.Button el="merchantFile" />
          )}
          <SidePanelHeader.Button el="closeButton" />
        </Stack>
      )}
    </SidePanelHeaderProvider>
  );
};

export default SidePanelHeaderVariant;
