import { NewProductBanner } from "@components/Product/NewProductBanner/NewProductBanner";
import { namespaces } from "@localization/resources/i18n.constants";
import {
  CREATE_PAYMENT_FORM_MODAL,
  CREATE_PRE_BUILD_FORM_MODAL,
} from "modals/modal_names";
import { useTranslation } from "react-i18next";

const NewPaymentFormsBanner = ({
  backgroundUrl,
}: {
  backgroundUrl: string;
}) => {
  const { t } = useTranslation(namespaces.pages.paymentForms);

  return (
    <NewProductBanner
      title={t("paymentForms")}
      createModal={CREATE_PRE_BUILD_FORM_MODAL}
      analyticsType="payment-forms"
      background={backgroundUrl}
      createCampaignTitle={t("createPaymentForms")}
    />
  );
};

export default NewPaymentFormsBanner;
