import {
  alpha,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { X } from "@phosphor-icons/react";
import { useAppTheme } from "@theme/v2/Provider";
import React from "react";
import GiveButton from "shared/Button/GiveButton";
import GiveText from "shared/Text/GiveText";
import { Icon } from "./GivePopupIcons";
import { ButtonConfig, Props } from "./GivePopupTypes";

const GivePopup: React.FC<Props> = ({
  type,
  title,
  description,
  open,
  onClose,
  isMobile,
  iconType,
  buttons,
  actionButtons: actions,
  sx,
  paperSx = {},
}) => {
  const { palette } = useAppTheme();

  const defaultButtons: ButtonConfig[] = [
    {
      label: actions?.cancel?.label || "Discard Changes",
      onClick: actions?.cancel?.onClick || onClose,
      variant: "ghost",
    },
    {
      label: actions?.success?.label || "Save",
      onClick: actions?.success?.onClick || onClose,
      variant: "filled",
      color: type === "success" ? "primary" : "destructive",
      disabled: type === "destructive",
    },
  ];

  const actionButtons = buttons || defaultButtons;

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        elevation: 0,
        sx: {
          maxWidth: isMobile ? "100%" : "600px",
          width: isMobile ? "100%" : "440px",
          borderRadius: "20px !important",
          maxHeight: isMobile
            ? "calc(100% - 48px) !important"
            : "calc(100% - 96px) !important",
          ...(isMobile && {
            margin: "48px 0 0 !important",
            borderRadius: "16px 16px 0 0 !important",
          }),
          ...paperSx,
        },
      }}
      sx={{
        background: alpha(palette.surface?.primary || "", 0.4),
        "& .MuiDialogContent-root": {
          border: "unset",
          padding: "0 20px",
        },
        "& .MuiDialogTitle-root, .MuiDialogActions-root": {
          padding: "20px",
        },
        "& .MuiDialog-container": {
          display: "flex",
          alignItems: isMobile ? "flex-end" : "center",
          justifyContent: "center",
        },
        ...sx,
      }}
    >
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Icon type={iconType || type} />
          {!isMobile && (
            <IconButton
              aria-label="close"
              sx={{
                boxShadow: "none",
                border: "none",
                "&:hover": {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                },
              }}
              onClick={onClose}
            >
              <X color={palette.icon?.["icon-primary"]} size={20} />
            </IconButton>
          )}
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <GiveText variant="h4" paddingBottom={"10px"}>
          {title}
        </GiveText>
        <Typography gutterBottom fontSize={"14px"}>
          {description}
        </Typography>
      </DialogContent>
      <DialogActions>
        {actionButtons.map(
          ({ label, onClick, variant, color, disabled, sxButton }, index) => (
            <GiveButton
              key={index}
              onClick={onClick}
              label={label}
              variant={variant}
              color={color}
              size="large"
              disabled={disabled}
              sx={sxButton}
            />
          ),
        )}
      </DialogActions>
    </Dialog>
  );
};

export default GivePopup;
