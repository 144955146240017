import { ProvinceInput, ZipCodeInput } from "@common/AddressInputs";
import { RHFInput } from "@common/Input";
import { RHFSelect } from "@common/Select";
import useCountryCodes from "@hooks/common/useCountryCodes";
import { Box, Grid, Stack } from "@mui/material";
import * as Yup from "yup";
import {
  ProfileSetupFormActions,
  ProfileSetupFormContainer,
  ProfileSetupFormTitle,
} from "../form.components";
import { palette } from "@palette";
import { ArrowDownChevron } from "@assets/icons";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { RHFStreetAddress } from "@common/RHFStreetAddress";
import { gridItemsRenderer } from "@utils/rendering/nodesRenderers";
import { TBusinessStepsCommons } from "./BusinessProfileSetupNew";
import { yupResolver } from "@hookform/resolvers/yup";
import usePercentageUpdate from "./hooks/usePercentageUpdate";
import { DynamicReturnType } from "./helpers/refineData";
import { streetAddressValidator } from "@utils/validation.helpers";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  CREATE_DENY_MESSAGE,
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { checkPortals } from "@utils/routing";
import { forwardRef, useImperativeHandle } from "react";
import WarningBanner from "./WarningBanner";

interface IProps extends TBusinessStepsCommons {
  canEdit: boolean;
  data: DynamicReturnType["businessAddress"];
  legalEntityId?: any;
  warningMessage: string | null;
  isIncomplete: boolean;
  getHelperText: (value: string) => string | undefined;
}

type IFormInputs = {
  country: string;
  address: string;
  city: string;
  state: string;
  zip: string;
};

const ALLOW_EMPTY = {
  address: true,
  city: true,
  state: true,
  zip: true,
};

const BusinessAddressStep = forwardRef(
  (
    {
      handleBack,
      submitHandler,
      updateStatusBar,
      isSubmitting,
      canEdit,
      data,
      legalEntityId,
      warningMessage,
      isIncomplete,
      getHelperText,
    }: IProps,
    ref,
  ) => {
    const { selectCountriesList } = useCountryCodes();
    const { isEnterprisePortal } = checkPortals();

    const isAddLEAllowed = useAccessControl({
      resource: RESOURCE_BASE.LEGAL_ENTITY,
      operation: OPERATIONS.CREATE,
      withPortal: true,
    });

    const isUpdateLEAllowed = useAccessControl({
      resource: RESOURCE_BASE.LEGAL_ENTITY,
      operation: OPERATIONS.UPDATE,
      withPortal: true,
    });

    const isEditMerchantAllowed = useAccessControl({
      resource: isEnterprisePortal
        ? RESOURCE_BASE.ENTERPRISE
        : RESOURCE_BASE.MERCHANT,
      operation: OPERATIONS.UPDATE,
    });

    const hasNoPermissions =
      !isEditMerchantAllowed ||
      (legalEntityId && !isUpdateLEAllowed) ||
      (!legalEntityId && !isAddLEAllowed);
    const disableInput = !canEdit || hasNoPermissions;

    const getTooltipMessage = () => {
      if ((legalEntityId && !isUpdateLEAllowed) || !isEditMerchantAllowed) {
        return EDIT_DENY_MESSAGE;
      } else if (!legalEntityId && !isAddLEAllowed) {
        return CREATE_DENY_MESSAGE;
      } else if (!canEdit) {
        return "Only controllers can edit the business profile";
      } else {
        return "";
      }
    };

    const methods = useForm<IFormInputs>({
      mode: "onChange",
      resolver: yupResolver(schema),
      defaultValues: data,
    });

    const {
      watch,
      formState: { dirtyFields, isDirty },
      handleSubmit,
    } = methods;

    const values = watch();

    const { address, city, state, zip } = values || {};

    usePercentageUpdate<IFormInputs>(
      values,
      dirtyFields,
      schema,
      updateStatusBar,
    );

    const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
      submitHandler("businessAddress", data, {
        makeApiCall: isDirty,
        forbidRedirect: false,
        allowEmpty: ALLOW_EMPTY,
      });
    };

    const handleForceSubmit = async (cb?: any, param?: any) => {
      await handleSubmit((data) => {
        submitHandler("businessAddress", data, {
          makeApiCall: isDirty,
          forbidRedirect: true,
          allowEmpty: ALLOW_EMPTY,
          handleNext: cb,
          param: param,
        });
      })();
    };

    const backSaveHandler = async () => {
      await handleForceSubmit(handleBack);
    };

    const inputs = [
      {
        node: (
          <RHFSelect
            name="country"
            label="Choose country"
            disabled
            options={selectCountriesList}
            SelectProps={{
              IconComponent: () => (
                <ArrowDownChevron
                  width={16}
                  height={9}
                  color={palette.gray[300]}
                />
              ),
            }}
          />
        ),
      },
      {
        node: (
          <RHFStreetAddress
            name="address"
            label="Street address"
            placeholder="Enter Street Address..."
            fullWidth
            disabled={disableInput}
            helperText={getHelperText(address)}
          />
        ),
      },
      {
        node: (
          <RHFInput
            name="city"
            label="City"
            placeholder="Enter city..."
            fullWidth
            disabled={disableInput}
            helperText={getHelperText(city)}
          />
        ),
        md: 4,
      },
      {
        node: (
          <ProvinceInput
            name="state"
            isUS={values.country === "US"}
            disabled={disableInput}
            helperText={getHelperText(state)}
          />
        ),
        md: 4,
      },
      {
        node: (
          <ZipCodeInput
            name="zip"
            label="ZIP"
            placeholder="Enter Zip Code"
            letterSpacing="4px"
            disabled={disableInput}
            helperText={getHelperText(zip)}
          />
        ),
        md: 4,
      },
    ];

    useImperativeHandle(ref, () => ({
      execute: handleForceSubmit,
    }));

    return (
      <FormProvider {...methods}>
        <Box
          component="form"
          flexGrow={1}
          id="business-profile-form"
          display="flex"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <ProfileSetupFormContainer>
            <Stack
              direction="column"
              gap={4}
              alignItems="flex-start"
              height="min-content"
            >
              <ProfileSetupFormTitle title="Add business address" />
              {isIncomplete && <WarningBanner message={warningMessage} />}
              <Grid container spacing="12px">
                {gridItemsRenderer(inputs, {
                  show: disableInput,
                  message: getTooltipMessage(),
                })}
              </Grid>
            </Stack>
            <ProfileSetupFormActions
              secondaryAction={{
                onClick: backSaveHandler,
              }}
              primaryAction={{
                disabled: isSubmitting,
                children: "Next",
              }}
            />
          </ProfileSetupFormContainer>
        </Box>
      </FormProvider>
    );
  },
);

BusinessAddressStep.displayName = "BusinessAddressStep";

export default BusinessAddressStep;

const schema = Yup.object().shape({
  country: Yup.string(),
  address: streetAddressValidator(),
  city: Yup.string(),
  state: Yup.string(),
  zip: Yup.string()
    .nullable()
    .notRequired()
    .test(
      "is-valid-zip",
      "Invalid ZIP format",
      (value) => !value || /^[0-9]{5}(?:-?[0-9]{4})?$/.test(value),
    ),
});
