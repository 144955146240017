import React from "react";
// nice-modal
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
// mui
import {
  DialogContent,
  DialogActions,
  Box,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// components
import { BaseModal, ModalTitle } from "@common/Modal";
// assets
import Payment from "./Payment/Payment";
import { Button } from "@common/Button";
import { Text } from "@common/Text";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import Icon from "@assets/icons/RebrandedIcons/Logo";

const CheckoutModal = NiceModal.create(
  ({
    destinationAccountMerchantName,
  }: {
    destinationAccountMerchantName?: string;
  }) => {
    const modal = useModal();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
    const [isDisabled, setIsDisabled] = React.useState<boolean>(true);

    return (
      <BaseModal
        width="720px"
        {...muiDialogV5(modal)}
        sx={{
          "& .MuiDialogActions-root": {
            "@media (max-width: 600px)": {
              flexDirection: "column",
            },
          },
        }}
      >
        <ModalTitle
          title="Checkout"
          onClose={() => modal.hide()}
          backgroundColor="transparent"
        />
        <DialogContent>
          <ErrorCatcher errorID="Payment">
            <Payment
              setIsDisabled={setIsDisabled}
              destinationAccountMerchantName={destinationAccountMerchantName}
            />
          </ErrorCatcher>
        </DialogContent>
        <DialogActions
          sx={{
            background: "white",
            padding: 0,
          }}
        >
          <Box
            sx={actionsContainerStyle}
            height={isDesktop ? "64px" : "auto"}
            px="16px"
          >
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Text color="#8F8F8F">Powered by</Text>
              <Icon width={210} />
            </Stack>
            <Stack
              direction={isDesktop ? "row" : "column"}
              gap={1}
              alignItems="center"
              width={isDesktop ? "auto" : "100%"}
            >
              <Button
                fullWidth={!isDesktop}
                onClick={() => modal.hide()}
                size="medium"
                background="secondary"
              >
                Cancel
              </Button>
              <Button
                fullWidth={!isDesktop}
                type="submit"
                size="medium"
                background="primary"
                form="credit-card-payment"
                disabled={isDisabled}
              >
                Pay now
              </Button>
            </Stack>
          </Box>
        </DialogActions>
      </BaseModal>
    );
  },
);

const actionsContainerStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "@media (max-width: 900px)": {
    flexDirection: "column-reverse",
    gap: "16px",
  },
};

export default CheckoutModal;
