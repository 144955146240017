import { Text } from "@common/Text";
import { palette } from "@palette";
import { Stack } from "@mui/material";
import { DisputesTableTag } from "@common/Tag/DisputesTableTag";
import { Button } from "@common/Button";
import NiceModal from "@ebay/nice-modal-react";
import { RESPOND_TO_DISPUTE_MODAL } from "modals/modal_names";
import { Tooltip } from "@common/Tooltip";
import { WarningDiamond } from "@phosphor-icons/react";
import { DisputeCaseType, DisputeTagType, TDisputeData } from "../data.types";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import { useMemo } from "react";

type Props = {
  data: {
    dispute: TDisputeData;
    history: DisputeCaseType[];
  };
};

const TitleSection = ({ data }: Props) => {
  const lastCase = data?.history?.[0];
  const draftId = lastCase?.status === "open" ? lastCase?.id : null;

  const handleOpenModal = () => {
    NiceModal.show(RESPOND_TO_DISPUTE_MODAL, {
      disputeId: data?.dispute?.id,
      lastCaseId: data?.dispute?.lastCase?.id,
      merchantId: data?.dispute?.merchant?.accID,
      draftId,
      caseAction: lastCase?.statusAction,
      caseNotes: lastCase?.notes,
    });
  };

  const dueTime = useMemo(() => {
    const responseDueAt = data?.dispute?.lastCase?.responseDueAt;

    if (!responseDueAt) return "";

    const dueDate = fromUnixTime(responseDueAt);

    return formatDistanceToNow(dueDate, { addSuffix: true });
  }, [data?.dispute?.lastCase?.responseDueAt]);

  const hiddenRespondAction = ["closed", "won", "lost"].includes(data?.dispute?.status);
  const disabledRespondAction = data?.dispute?.status === "under review";

  const hiddenDueTime = hiddenRespondAction || disabledRespondAction;

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack gap={1}>
        <Stack direction="row" gap={1} alignItems="center">
          <Text
            fontSize={24}
            fontWeight="book"
            color={palette.neutral[80]}
            lineHeight="32.4px"
          >
            Dispute Case
          </Text>
          {data?.dispute?.markedAsFraud && (
            <Tooltip
              placement="top"
              title="Marked as fraud"
              bgColor={palette.neutral[80]}
              titleSx={{
                fontSize: 12,
                fontWeight: 350,
              }}
              toolTipSx={{
                padding: "4px 16px",
                borderRadius: "8px",
                top: 8,
              }}
            >
              <WarningDiamond
                size={20}
                weight="duotone"
                color={palette.tag.warning.hover}
                data-testid="marked-as-fraud-sign"
              />
            </Tooltip>
          )}
        </Stack>
        <Stack direction="row" gap={1} alignItems="center">
          <DisputesTableTag
            type={
              data?.dispute?.statusDisplayName?.toLowerCase() as DisputeTagType
            }
            sx={{ minWidth: "auto !important" }}
          />
          {!hiddenDueTime && (
            <Text
              fontWeight="book"
              fontSize={12}
              color={palette.neutral[80]}
              lineHeight="14.4px"
            >
              Due {dueTime}
            </Text>
          )}
        </Stack>
      </Stack>
      {!hiddenRespondAction && (
        <Button
          size="medium"
          sx={{ fontWeight: 400, lineHeight: "14px" }}
          onClick={handleOpenModal}
          disabled={disabledRespondAction}
        >
          {draftId ? "View Draft" : "Respond to Dispute"}
        </Button>
      )}
    </Stack>
  );
};

export default TitleSection;
