import { Box, Grid, Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { capitalizeFirstLetter } from "@utils/index";
import PublicInputShare from "./PublicInputShare";
import type { TButtonsMap } from "./PublicShare.types";

const LargeVariant = ({
  ButtonsMap,
  url,
}: {
  ButtonsMap: TButtonsMap;
  url: string;
}) => {

  return (
    <Stack
      gap={2}
      sx={{
        padding: "16px",
        background: "rgba(0, 0, 0, 0.05)",
        borderRadius: "16px",
      }}
    >
      <PublicInputShare url={url} />
      <Grid container spacing={2} padding="16px 0px 16px 0px">
        {ButtonsMap.map((Button, index) => (
          <Grid item key={index} xs={3} sm={3} md={3}>
            <Box textAlign="center" sx={{cursor: "pointer"}}>
              <Button.Component
                id={`${Button.id}-share-button`}
                url={url}
                appId=""
              >
                <Button.Icon />
              </Button.Component>
              <GiveText fontSize="12px" textAlign="center">
                {capitalizeFirstLetter(Button.id)}
              </GiveText>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default LargeVariant;
