import { DownloadIcon } from "@assets/icons";
import { Button } from "@common/Button";
import { Switch_V2 } from "@common/Switch";
import { Text } from "@common/Text";
import { TypedFunction } from "@customTypes/utils.types";
import { Box, BoxProps, Skeleton, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  addStatusFilter,
  clearFilters,
  resetStatusFilter,
  selectTableFilter,
} from "@redux/slices/transactions";
import { getRandomNumber } from "@utils/helpers";
import { FEATURE_DENY_MESSAGE } from "constants/permissions";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";
import { batch } from "react-redux";

export const DownloadReportButton = ({
  disabled,
  denyDownload,
  onClick,
}: {
  disabled: boolean;
  onClick: () => void;
  denyDownload?: boolean;
}) => {
  return (
    <CustomToolTip showToolTip={!!denyDownload} message={FEATURE_DENY_MESSAGE}>
      <Button
        sx={({ palette }) => ({
          paddingRight: 0,
          "&:hover, &:hover path  ": {
            color: palette.neutral["100"],
            stroke: palette.neutral["100"],
          },
        })}
        endIcon={<DownloadIcon />}
        disabled={disabled || denyDownload}
        background="tertiary"
        onClick={onClick}
        data-testid="download-report-button"
      >
        Download report
      </Button>
    </CustomToolTip>
  );
};

export type TransactionTableFilter = {
  label: string;
  onClick: (index: number) => void;
  showAlert?: boolean;
  selected: number;
};

export const TableFilters = ({
  filters,
}: {
  filters: TransactionTableFilter[];
}) => {
  return (
    <Stack direction="row" alignItems="center">
      {filters.map(({ label, onClick, showAlert, selected }, idx) => (
        <TransactionFilterBase
          key={label}
          onClick={() => onClick(idx)}
          selected={selected === idx}
          data-testid={`test-id-${label.toLowerCase().replaceAll(" ", "-")}`}
        >
          {label} {showAlert && <AlertDot data-testid="alert-dot" />}
        </TransactionFilterBase>
      ))}
    </Stack>
  );
};

export const AlertDot = styled((props: BoxProps) => (
  <Box component="span" {...props} />
))(() => ({
  width: "8px",
  height: "8px",
  borderRadius: "100px",
  background: "#6D9CF8",
}));

export const TransactionFilterBase = styled(Text, {
  shouldForwardProp: (prop) => prop !== "selected" && prop !== "color",
})<{ selected: boolean; color?: string }>(({ selected, color }) => ({
  color: selected ? (color ? color : palette.black[100]) : palette.gray[300],
  lineHeight: "16.8px",
  padding: "8px 16px",
  borderRadius: "100px",
  userSelect: "none",
  display: "flex",
  alignItems: "center",
  whiteSpace: "nowrap",
  gap: "4px",
  ...(selected && {
    background: palette.liftedWhite[100],
  }),
  ...(!selected && {
    cursor: "pointer",
  }),
  ...(color && {
    "&:hover": {
      color,
    },
  }),
}));

interface PendingSwitchProps {
  setPage?: TypedFunction<[number]>;
}

export function PendingSwitch({ setPage }: PendingSwitchProps) {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectTableFilter).filter(
    ({ value }) => value !== "!Declined",
  );

  const handleFilterChange = (_: any, checked: boolean) => {
    if (setPage) {
      setPage(0);
    }

    if (!checked) {
      dispatch(resetStatusFilter());
    } else {
      batch(() => {
        dispatch(clearFilters());
        dispatch(addStatusFilter("Pending"));
        dispatch(addStatusFilter("Requested"));
      });
    }
  };

  return (
    <Switch_V2
      onChange={handleFilterChange}
      checked={filters.length > 0}
      size="small"
      label="Pending only"
      sxText={{
        pt: "4px",
      }}
    />
  );
}

export const LoadingSkeleton = () => (
  <Box
    mb="16px"
    display="flex"
    alignItems="center"
    justifyContent="space-between"
  >
    <Box>
      <Skeleton
        sx={{
          borderRadius: "22.5px",
          marginBottom: "8px",
        }}
        height="12.75px"
        width="78px"
        variant="rounded"
      />
      <Skeleton
        sx={{
          borderRadius: "22.5px",
        }}
        height="12.75px"
        width="128px"
        variant="rounded"
      />
    </Box>
    <Skeleton
      sx={{
        borderRadius: "22.5px",
      }}
      height="10.75px"
      width={`${getRandomNumber(50, 128)}px`}
      variant="rounded"
    />
  </Box>
);
