import React from "react";
import { RHFInput } from "@common/Input";
import { useFormContext } from "react-hook-form";

type ZipCodeInputProps = {
  id?: string;
  name: string;
  placeholder?: string;
  label?: string;
  letterSpacing?: string;
  disabled?: boolean;
  focusViewColor?: string;
  helperText?: string;
};

const ZipCodeInput = ({
  name,
  placeholder,
  label,
  letterSpacing = "6px",
  ...props
}: ZipCodeInputProps) => {
  const { setValue, watch } = useFormContext();

  const normalizeInput = (value: string) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d-]/g, "");

    return currentValue;
  };

  React.useEffect(() => {
    setValue(name, normalizeInput(watch(name)));
  }, [watch(name)]);

  return (
    <RHFInput
      name={name}
      label={label}
      fullWidth
      placeholder={placeholder}
      {...props}
      sx={{
        letterSpacing,
        "& .MuiFormHelperText-root": {
          letterSpacing: "0px",
        },
        "& input::placeholder": {
          letterSpacing: letterSpacing || "0px",
        },
      }}
      inputProps={{
        maxLength: "5",
      }}
    />
  );
};

export default ZipCodeInput;
