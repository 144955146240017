import React from "react";
import { palette } from "@palette";
import { makeStyles } from "@mui/styles";
import { Stack, Box } from "@mui/material";
import { styled } from "@mui/styles";
import { TruncateText } from "@common/Text";
import { SortComponent } from "@common/Table/components/SortComponent";
import { calcWidth } from "@common/Campaigns/utils";

export type column = {
  key: string;
  title: string;
  sortable: boolean;
  size?: number;
};

interface Props {
  columns: column[];
  handleOpenMenu: any;
  order: "asc" | "desc";
  sortKey: string;
}

const CardHeader = ({ columns, handleOpenMenu, sortKey, order }: Props) => {
  const classes = useStyles();

  return (
    <Box px="4px" py="8px">
      <StyledRoot>
        {columns.map((column, index) => (
          <Stack
            className={classes.columnContainerStyles}
            style={{ width: calcWidth(column.size) }}
            key={index}
            gap={1}
          >
            <div
              onClick={() => handleOpenMenu(column.key)}
              className={classes.cellWrapper}
              data-testid={`table-${column.key}-sort`}
            >
              <TruncateText lineClamp={2} color={palette.neutral.black}>
                {column.title}
              </TruncateText>
              <div className={classes.sortComponentWrapper}>
                <SortComponent
                  sortedCol={sortKey === column.key}
                  order={order}
                />
              </div>
            </div>
          </Stack>
        ))}
      </StyledRoot>
    </Box>
  );
};

const StyledRoot = styled(Stack)({
  flexDirection: "row",
  fontSize: 14,
  fontWeight: 350,
  lineHeight: "16.8px",
});

const useStyles = makeStyles({
  columnContainerStyles: {
    fontSize: 14,
    fontWeight: 350,
    lineHeight: "16.8px",
    display: "flex",
    flexDirection: "row",
  },
  sortComponentWrapper: {
    paddingLeft: 0,
  },
  cellWrapper: {
    display: "inline-flex",
    justifyContent: "space-between",
    gap: "8px",
    alignItems: "center",
    padding: "4px 8px",
    verticalAlign: "middle",
    userSelect: "none",

    "&:hover": {
      background: "#ECECE9",
      cursor: "pointer",
      borderRadius: "32px",
    },
  },
});

export default CardHeader;
