import { ActionButton } from "./ChallengeActions";

const ViewAction = ({
  viewAction,
  challengeId,
  shouldDisableViewButtonBusinessProfile,
  shouldDisableViewOFAC,
  shouldDisableBusinessProfileOFACMatch,
  isFileAttached,
  slug,
}: {
  viewAction: () => void;
  challengeId: number;
  shouldDisableViewButtonBusinessProfile?: boolean;
  shouldDisableBusinessProfileOFACMatch?: boolean;
  shouldDisableViewOFAC?: boolean;
  isFileAttached?: boolean;
  slug?: string;
}) => {
  const getDisabledTooltipMessage = () => {
    if (shouldDisableViewButtonBusinessProfile) {
      if (slug === "underwriting_match_check")
        return "Completing the business profile is required before adding a MATCH report";
      else
        return "Completing the business profile is required before adding a business owner";
    } else if (shouldDisableBusinessProfileOFACMatch)
      return "This action is blocked by a confirmed match on OFAC";
    else if (shouldDisableViewOFAC)
      return "Completing business profile or primary account holder is required for running OFAC check";
    else return "";
  };

  const message = getDisabledTooltipMessage();

  return (
    <ActionButton
      label="View"
      onClick={viewAction}
      testId={`view-challenge-button-${challengeId}`}
      disabled={
        shouldDisableViewButtonBusinessProfile ||
        shouldDisableViewOFAC ||
        shouldDisableBusinessProfileOFACMatch
      }
      tooltipProps={
        message
          ? {
              message: message,
              show: true,
              maxWidth: "310px",
            }
          : {
              message: "You will be redirected to the relevant information.",
              extraMessage: "\nPlease check the documents if needed.",
              show: !isFileAttached,
              maxWidth: "310px",
            }
      }
      sx={{
        ":disabled": {
          pointerEvents: "auto",
          cursor: "pointer",
          ":hover": { backgroundColor: "#D1D1D0" },
        },
      }}
    />
  );
};

export default ViewAction;
