import { Box, Stack } from "@mui/material";
import {
  ProfileSetupFormActions,
  ProfileSetupFormContainer,
  ProfileSetupFormTitle,
} from "../form.components";
import { FormProvider } from "react-hook-form";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { PlusIcon, UserFillIcon } from "@assets/icons";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import AddOwnerInputFields from "./AddOwnerInputFields";
import { memo, useEffect } from "react";
import { styled } from "@mui/material";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { TBusinessStepsCommons } from "./BusinessProfileSetup";
import { DynamicReturnType } from "./helpers/refineData";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { showMessage } from "@common/Toast";
import { businessOwnerDescriptions } from "./utils/principal.utils";
import { BusinessUnionTypes } from "@common/BusinessProfileInputs/BusinessTypeSelect";
import {
  TBusinessOwner,
  TMerchantDocument,
} from "@components/Merchants/MerchantPreview/data.types";
import { getIDFile } from "@components/Merchants/MerchantPreview/components/BusinessOwners/MerchantBusinessOwners";
import { useGetBOFiles } from "@hooks/merchant-api/business-owner/useGetBOFiles";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useQueryClient } from "react-query";
import { IFileWithMeta, StatusValue } from "react-dropzone-uploader";
import { useFileUploadContext } from "@components/UploadFile/FileUploadContext";
import { getBusinessOwnerSchema } from "./schemas/businessOwnerSchema";
import { convertPhoneNumber } from "@utils/date.helpers";
import {
  useUploadFiles,
  useUploadPresignedDocument,
} from "@hooks/upload-api/uploadHooks";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  CREATE_DENY_MESSAGE,
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

type TFormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  ssn: string;
  ownership: string;
  useBusinessAddress: boolean;
  country: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  DOB: string;
  id: string;
  pepStatusName: string;
  isNotUSResident: boolean;
  citizenship: string;
  isNotResidentInCitizenshipCountry: boolean;
  countryOfResidence: string;
  contactPhone?: string;
  files?: {
    fileWithMeta?: any;
    status?: StatusValue;
    allFiles: IFileWithMeta[] | TMerchantDocument[];
  };
};

interface IProps extends TBusinessStepsCommons {
  canEdit: boolean;
  data: DynamicReturnType["businessOwners"];
  businessType: BusinessUnionTypes;
  primaryAccountHolder?: Partial<TBusinessOwner>;
}

function BusinessOwner({
  handleBack,
  submitHandler,
  statusBar,
  updateStatusBar,
  canEdit,
  data: businessOwners,
  businessType,
  isSubmitting,
  primaryAccountHolder,
}: IProps) {
  const { isMobileView } = useCustomTheme();
  const { merchantId } = useGetCurrentMerchantId();
  const queryClient = useQueryClient();
  const { populateSnackbarFiles, isLoading: uploading } =
    useFileUploadContext();
  const isAddAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.LEGAL_ENTITY,
      RESOURCE_BASE.PRINCIPAL,
    ),
    operation: OPERATIONS.CREATE,
    withPortal: true,
  });

  const isUpdateAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.LEGAL_ENTITY,
      RESOURCE_BASE.PRINCIPAL,
    ),
    operation: OPERATIONS.UPDATE,
    withPortal: true,
  });

  const { handleUpload } = useUploadFiles();
  const { handleUpload: handleUploadPresigned } = useUploadPresignedDocument();
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
  const [isFormOpen, setIsFormOpen] = useState(
    !businessOwners || businessOwners?.length === 0,
  );
  const [selectedOwnersPEP, setSelectedOwnersPEP] = useState("");

  const isSoleProprietorship =
    businessType === "individual_sole_proprietorship";

  const { data: documents = {} } = useGetBOFiles({ enabled: true });
  const defaultValues = getDefaultValues({
    isSoleProprietorship: isSoleProprietorship,
    primaryAccountHolder: primaryAccountHolder,
  }) as any;

  const methods = useForm<TFormInputs>({
    mode: "onChange",
    resolver: yupResolver(getBusinessOwnerSchema()),
    defaultValues,
  });

  const {
    watch,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;
  const values = watch();

  const closeForm = () => {
    reset(defaultValues);
    setIsFormOpen(false);
  };

  const handleOpenWithData = (data: TFormInputs) => {
    setSelectedOwnersPEP(data?.pepStatusName || "");
    setIsFormOpen(true);
    const idFile = getIDFile(data?.id, documents.data);
    const allFiles = idFile ? [idFile] : [];
    const dataToReset = {
      ...data,
      files: { allFiles: allFiles },
    };
    reset(dataToReset);
  };

  useEffect(() => {
    updateStatusBar((businessOwners?.length || 0) > 0 ? 100 : 0);
  }, [businessOwners]);

  const onSubmit: SubmitHandler<TFormInputs> = async (data) => {
    const ownershipExceeds = checkIfOwnershipExceeds(
      data,
      businessOwners,
      isDirty,
    );

    if (ownershipExceeds) {
      showMessage("Info", "Ownership sum cannot exceed 100%");
      return;
    }

    let uploadRes: string[] | "upload_failed" = [];
    // if file doesnt have meta field, that means the file is from BE, so no upload required
    const isLocalFile = "meta" in (data.files?.allFiles[0] || {});
    if (isLocalFile && !values.id && data?.files?.allFiles) {
      if (isFileUploadRefactorEnabled) {
        uploadRes = await handleUploadPresigned({
          list: data.files?.allFiles.map((file: any) => ({
            file: file.file,
            id: file.meta.id,
          })),
          attachmentType: "legal_principal",
        });
      } else {
        // upload when BO creation
        uploadRes = (await populateSnackbarFiles({
          fileWithMeta: data.files?.fileWithMeta || data.files?.allFiles[0],
          status: data.files?.status || "done",
          allFiles: data.files?.allFiles as IFileWithMeta[],

          attachmentType: "legal_principal",
        })) as string[] | "upload_failed";
      }
    }
    if (isLocalFile && values.id && data?.files?.allFiles) {
      if (isFileUploadRefactorEnabled) {
        const filesToUpload = (data.files?.allFiles as IFileWithMeta[]).map(
          (file) => ({
            file: file.file,
          }),
        );

        uploadRes = await handleUpload({
          list: filesToUpload,
          merchantId,
          resourceID: +values.id,
          attachmentType: "legal_principal",
          label: "business owner document",
          tag: "business owner document",
        });
      } else {
        uploadRes = (await populateSnackbarFiles(
          {
            fileWithMeta: data.files?.fileWithMeta || data.files?.allFiles[0],
            status: data.files?.status || "done",
            allFiles: data.files?.allFiles as IFileWithMeta[],
            attachmentType: "legal_principal",
            label: "business owner document",
            tag: "business owner document",
            merchantId: merchantId,
            resourceID: +values.id,
          },
          undefined,
        )) as string[] | "upload_failed";
      }
    }

    if (uploadRes === "upload_failed") return;
    const { files, contactPhone, ...rest } = data;
    const payload = {
      ...rest,
      ...(!values.id &&
        Array.isArray(uploadRes) && {
          idImageUrl: uploadRes[0],
        }),
      countryOfResidence: data.isNotResidentInCitizenshipCountry
        ? data.countryOfResidence
        : "",
      citizenship: data?.isNotUSResident ? data.citizenship : "",
      phoneNumber: data.contactPhone
        ? convertPhoneNumber(data.contactPhone)
        : null,
    };

    submitHandler("businessOwners", payload, {
      makeApiCall: isDirty,
      onSuccess: () => {
        reset(getDefaultValues({ isSoleProprietorship: false }));
        setIsFormOpen(false);
        updateStatusBar(100);
        queryClient.invalidateQueries(["get-bo-files", merchantId]);
      },
    });
  };

  const isSubmitDisabled = isSubmitting || statusBar < 100;
  const isClickable = !isSubmitDisabled && !isFormOpen;

  const hasOwners = businessOwners?.length > 0;
  const canEditInputs = canEdit && (hasOwners ? isUpdateAllowed : isAddAllowed);
  const tooltipProps = {
    show: !isUpdateAllowed || (!hasOwners && !isAddAllowed),
    message: !isUpdateAllowed ? EDIT_DENY_MESSAGE : CREATE_DENY_MESSAGE,
  };

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        flexGrow={1}
        id="business-owner-form"
        display="flex"
        onSubmit={handleSubmit(onSubmit)}
      >
        <ProfileSetupFormContainer>
          <Stack
            direction="column"
            gap={4}
            alignItems="flex-start"
            height="min-content"
          >
            <ProfileSetupFormTitle
              description={businessOwnerDescriptions[businessType]}
              title="Add business owner"
            />
            <Stack direction="column" gap={0.5} width="100%">
              {businessOwners
                ?.filter((item) => item?.id !== values?.id)
                .map((data, idx) => (
                  <FadeUpWrapper delay={50 + idx * 50} key={data.id}>
                    <BusinessOwnerItem
                      name={`${data?.firstName || ""} ${data?.lastName || ""}`}
                      ownership={data?.ownership || ""}
                      onClick={() => handleOpenWithData(data)}
                    />
                  </FadeUpWrapper>
                ))}
            </Stack>
            {!isFormOpen && (
              <TextAddOwner
                onClick={() => setIsFormOpen(true)}
                disabled={
                  (isSoleProprietorship && businessOwners?.length >= 1) ||
                  !canEdit ||
                  !isAddAllowed
                }
                tooltipProps={{
                  show: !isAddAllowed,
                  message: CREATE_DENY_MESSAGE,
                }}
              />
            )}
            {isFormOpen && !isMobileView && (
              <AddOwnerInputFields
                isSoleProprietorship={isSoleProprietorship}
                selectedOwnersPEP={selectedOwnersPEP}
                isLoading={isSubmitting || uploading}
                leType={businessType}
                canEdit={canEditInputs}
                idFile={getIDFile(values.id, documents.data)}
                tooltipProps={tooltipProps}
              />
            )}
          </Stack>
          <ProfileSetupFormActions
            secondaryAction={{
              onClick: handleBack,
            }}
            primaryAction={{
              children: "Next",
              form: "business-owner-form",
              onClick: isClickable ? () => onSubmit(values) : undefined,
              disabled: isSubmitDisabled,
            }}
          />
        </ProfileSetupFormContainer>
      </Box>
      {isMobileView && (
        <ModalFactory
          variant="dialog"
          renderMobile
          modalProps={{
            open: isFormOpen,
            onClose: closeForm,
          }}
        >
          {isFormOpen && (
            <Box
              component="form"
              flexGrow={1}
              id="business-owner-form"
              display="flex"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Stack direction="column" gap={4} maxWidth="100%">
                <AddOwnerInputFields
                  idFile={getIDFile(values.id, documents.data)}
                  isSoleProprietorship={isSoleProprietorship}
                  leType={businessType}
                  canEdit={canEditInputs}
                  tooltipProps={tooltipProps}
                />
              </Stack>
            </Box>
          )}
        </ModalFactory>
      )}
    </FormProvider>
  );
}

export default memo(BusinessOwner);

const checkIfOwnershipExceeds = (
  data: TFormInputs,
  businessOwners: DynamicReturnType["businessOwners"],
  isDirty?: boolean,
) => {
  const totalOwnership =
    businessOwners?.reduce((acc, owner) => {
      const _ownership = owner.ownership ? +owner.ownership : 0;
      return acc + _ownership;
    }, 0) || 0;

  const editedOwner = data?.id
    ? businessOwners?.find((owner) => owner?.id === data?.id)
    : null;

  //default value of ownership is 10, which should not be calculated in
  const computedOwnership =
    +(isDirty && data?.ownership ? data?.ownership : 0) +
    totalOwnership -
    +(editedOwner?.ownership || 0);
  return computedOwnership > 100;
};

const TextAddOwner = ({
  onClick,
  disabled,
  tooltipProps,
}: {
  onClick: () => void;
  disabled: boolean;
  tooltipProps: {
    show: boolean;
    message: string;
  };
}) => {
  return (
    <FadeUpWrapper delay={200}>
      <Stack direction="row" justifyContent="center">
        <WithTooltipWrapper
          hasTooltip={!!tooltipProps}
          tooltipProps={tooltipProps}
        >
          <AddOwnerButton
            onClick={disabled ? undefined : onClick}
            sx={{
              ...(disabled && {
                pointerEvents: "none",
                cursor: "default",
              }),
            }}
          >
            <PlusIcon fill="#8F8F8F" />
            <Text fontWeight="regular" fontSize="14px" color="#8F8F8F">
              Add business owner
            </Text>
          </AddOwnerButton>
        </WithTooltipWrapper>
      </Stack>
    </FadeUpWrapper>
  );
};

const BusinessOwnerItem = ({
  name,
  ownership,
  onClick,
}: {
  name: string;
  ownership: number | string;
  onClick: () => void;
}) => {
  return (
    <BusinessOwnerItemWrapper onClick={onClick}>
      <Box gap="8px" display="flex" alignItems="center">
        <UserFillIcon />
        <Text fontWeight="regular" fontSize="14px" color="#8F8F8F">
          {name}
        </Text>
      </Box>
      <Box gap="4px" alignItems="center" display="flex">
        <Text fontWeight="light" fontSize="14px" color="#8F8F8F">
          Ownership
        </Text>
        <Text fontWeight="regular" fontSize="14px" color="#8F8F8F">
          {ownership}%
        </Text>
      </Box>
    </BusinessOwnerItemWrapper>
  );
};
const getDefaultValues = ({
  isSoleProprietorship = false,
  primaryAccountHolder,
}: {
  isSoleProprietorship: boolean;
  primaryAccountHolder?: Partial<TBusinessOwner>;
}): TFormInputs => {
  return {
    firstName:
      isSoleProprietorship && primaryAccountHolder?.firstName
        ? primaryAccountHolder?.firstName
        : "",
    lastName:
      isSoleProprietorship && primaryAccountHolder?.lastName
        ? primaryAccountHolder?.lastName
        : "",
    email:
      isSoleProprietorship && primaryAccountHolder?.email
        ? primaryAccountHolder?.email
        : "",
    ssn: "",
    DOB:
      isSoleProprietorship && primaryAccountHolder?.dob
        ? (primaryAccountHolder?.dob as string)
        : "",
    contactPhone:
      isSoleProprietorship && primaryAccountHolder?.phone
        ? primaryAccountHolder?.phone
        : "",
    isNotUSResident: false,
    citizenship:
      isSoleProprietorship && primaryAccountHolder?.citizenship
        ? primaryAccountHolder?.citizenship
        : "",
    isNotResidentInCitizenshipCountry: false,
    countryOfResidence:
      isSoleProprietorship && primaryAccountHolder?.countryOfResidence
        ? primaryAccountHolder?.countryOfResidence
        : "",
    ownership: "10",
    useBusinessAddress: true,
    country: "US",
    street:
      isSoleProprietorship && primaryAccountHolder?.address?.name
        ? primaryAccountHolder?.address?.name
        : "",
    city:
      isSoleProprietorship && primaryAccountHolder?.address?.city
        ? primaryAccountHolder?.address?.city
        : "",
    state:
      isSoleProprietorship && primaryAccountHolder?.address?.state
        ? primaryAccountHolder?.address?.state
        : "",
    zip:
      isSoleProprietorship && primaryAccountHolder?.address?.zip
        ? primaryAccountHolder?.address?.zip
        : "",
    id: "",
    pepStatusName: "",
    files: {
      allFiles: [] as IFileWithMeta[] | TMerchantDocument[],
    },
  };
};

const BusinessOwnerItemWrapper = styled(Box)(() => ({
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  width: "100%",
  boxShadow: "1px 1px 15px 0px #A1AFB41A",
  padding: "16px",
  borderRadius: "8px",
  marginBlock: "5px",
  cursor: "pointer",
  backgroundColor: palette.neutral.white,
}));

const AddOwnerButton = styled(Stack)(() => ({
  cursor: "pointer",
  marginInline: "auto",
  position: "static",
  right: 0,
  left: 0,
  gap: "4px",
  alignItems: "center",
  flexDirection: "row",
}));
