import {
  NotificationType,
  TConversationParams,
  TParsedNotification,
} from "../types";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import { formatFromUnixWithFormat } from "@utils/index";
import moment from "moment";

type TNotificationsGroups = "mandatory" | "generic";
type TNotificationsList = TParsedNotification[];
export type TGroupedList = Record<TNotificationsGroups, TNotificationsList>;

export const groupData = (list: any): TGroupedList => {
  if (!list || !Array.isArray(list))
    return {
      mandatory: [],
      generic: [],
    };
  const mandatoryList: TNotificationsList = [];
  const genericList: TNotificationsList = [];

  list.forEach((notification) => {
    const parsedNotification = parser(notification);

    if (parsedNotification.isMandatory) {
      const currentNotification = addLabel(
        parsedNotification,
        "mandatory",
        mandatoryList.length === 0,
      );
      mandatoryList.push(currentNotification);
    } else {
      const currentNotification = addLabel(
        parsedNotification,
        "generic",
        genericList.length === 0,
      );
      genericList.push(currentNotification);
    }
  });

  return {
    mandatory: mandatoryList,
    generic: genericList,
  };
};

const normalizeType = (type?: string): NotificationType => {
  if (!type) return "generic";

  switch (type) {
    case "underwriting":
      return "underwriting";
    case "conversation":
      return "conversation";
    default:
      return "generic";
  }
};

const parser = (notification: any): TParsedNotification => {
  return {
    accID: notification?.accID,
    id: notification?.id || 0,
    title: notification?.displayTitle || "",
    description: notification?.displayMessage || "",
    isMandatory: notification?.alertType === "mandatory" || false,
    firstCheckedAt: notification?.readAt || null,
    type: normalizeType(notification?.alertCategory),
    createdAt: notification?.createdAt || 0,
    challengeSlug: notification?.challenge?.slug || "",
    alertName: notification?.alertName || "",
    transactionID: notification?.transaction?.id || "",
    targetMerchantId: notification?.merchant?.accID || 0,
    threadId: notification?.challenge?.threadID || null,
    disputeId: notification?.disputeID || "",
    ...(notification?.conversationMessage && {
      conversationParams: getConversationParams(
        notification?.conversationMessage,
      ),
    }),
  };
};

const getConversationParams = (params?: any): TConversationParams => {
  return {
    targetMerchantId: params?.subjectAccID || 0,
    targetMerchantType: params?.subjectAccType || "",
    topicId: params?.topicID || 1,
    threadId: params?.threadID || 0,
    messageId: params?.id || 0,
  };
};

export const getNotificationDate = (unixTime: number) => {
  const last24HoursMark = moment(new Date()).subtract(24, "hours").unix();
  if (unixTime > last24HoursMark) {
    return formatDistanceToNow(fromUnixTime(unixTime), {
      addSuffix: true,
    });
  }
  return formatFromUnixWithFormat(unixTime, "MMMM. D YYYY - h:mm A");
};

const addLabel = (
  alert: TParsedNotification,
  type: TNotificationsGroups,
  addLabel: boolean,
) => {
  if (addLabel) {
    return {
      ...alert,
      label: labels[type],
    };
  }
  return alert;
};

const labels: Record<TNotificationsGroups, string> = {
  mandatory: "Mandatory notifications",
  generic: "Other notifications",
};
