export const timezones = [
  {
    name: "africa/abidjan",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "africa/accra",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "africa/addis_ababa",
    abbreviation: "eat",
    utcOffset: "+03:00",
  },
  {
    name: "africa/algiers",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "africa/asmara",
    abbreviation: "eat",
    utcOffset: "+03:00",
  },
  {
    name: "africa/asmera",
    abbreviation: "eat",
    utcOffset: "+03:00",
  },
  {
    name: "africa/bamako",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "africa/bangui",
    abbreviation: "wat",
    utcOffset: "+01:00",
  },
  {
    name: "africa/banjul",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "africa/bissau",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "africa/blantyre",
    abbreviation: "cat",
    utcOffset: "+02:00",
  },
  {
    name: "africa/brazzaville",
    abbreviation: "wat",
    utcOffset: "+01:00",
  },
  {
    name: "africa/bujumbura",
    abbreviation: "cat",
    utcOffset: "+02:00",
  },
  {
    name: "africa/cairo",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "africa/casablanca",
    abbreviation: "+01",
    utcOffset: "+01:00",
  },
  {
    name: "africa/ceuta",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "africa/conakry",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "africa/dakar",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "africa/dar_es_salaam",
    abbreviation: "eat",
    utcOffset: "+03:00",
  },
  {
    name: "africa/djibouti",
    abbreviation: "eat",
    utcOffset: "+03:00",
  },
  {
    name: "africa/douala",
    abbreviation: "wat",
    utcOffset: "+01:00",
  },
  {
    name: "africa/el_aaiun",
    abbreviation: "+01",
    utcOffset: "+01:00",
  },
  {
    name: "africa/freetown",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "africa/gaborone",
    abbreviation: "cat",
    utcOffset: "+02:00",
  },
  {
    name: "africa/harare",
    abbreviation: "cat",
    utcOffset: "+02:00",
  },
  {
    name: "africa/johannesburg",
    abbreviation: "sast",
    utcOffset: "+02:00",
  },
  {
    name: "africa/juba",
    abbreviation: "cat",
    utcOffset: "+02:00",
  },
  {
    name: "africa/kampala",
    abbreviation: "eat",
    utcOffset: "+03:00",
  },
  {
    name: "africa/khartoum",
    abbreviation: "cat",
    utcOffset: "+02:00",
  },
  {
    name: "africa/kigali",
    abbreviation: "cat",
    utcOffset: "+02:00",
  },
  {
    name: "africa/kinshasa",
    abbreviation: "wat",
    utcOffset: "+01:00",
  },
  {
    name: "africa/lagos",
    abbreviation: "wat",
    utcOffset: "+01:00",
  },
  {
    name: "africa/libreville",
    abbreviation: "wat",
    utcOffset: "+01:00",
  },
  {
    name: "africa/lome",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "africa/luanda",
    abbreviation: "wat",
    utcOffset: "+01:00",
  },
  {
    name: "africa/lubumbashi",
    abbreviation: "cat",
    utcOffset: "+02:00",
  },
  {
    name: "africa/lusaka",
    abbreviation: "cat",
    utcOffset: "+02:00",
  },
  {
    name: "africa/malabo",
    abbreviation: "wat",
    utcOffset: "+01:00",
  },
  {
    name: "africa/maputo",
    abbreviation: "cat",
    utcOffset: "+02:00",
  },
  {
    name: "africa/maseru",
    abbreviation: "sast",
    utcOffset: "+02:00",
  },
  {
    name: "africa/mbabane",
    abbreviation: "sast",
    utcOffset: "+02:00",
  },
  {
    name: "africa/mogadishu",
    abbreviation: "eat",
    utcOffset: "+03:00",
  },
  {
    name: "africa/monrovia",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "africa/nairobi",
    abbreviation: "eat",
    utcOffset: "+03:00",
  },
  {
    name: "africa/ndjamena",
    abbreviation: "wat",
    utcOffset: "+01:00",
  },
  {
    name: "africa/niamey",
    abbreviation: "wat",
    utcOffset: "+01:00",
  },
  {
    name: "africa/nouakchott",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "africa/ouagadougou",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "africa/porto-novo",
    abbreviation: "wat",
    utcOffset: "+01:00",
  },
  {
    name: "africa/sao_tome",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "africa/timbuktu",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "africa/tripoli",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "africa/tunis",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "africa/windhoek",
    abbreviation: "cat",
    utcOffset: "+02:00",
  },
  {
    name: "america/adak",
    abbreviation: "hst",
    utcOffset: "\u221210:00",
  },
  {
    name: "america/anchorage",
    abbreviation: "akst",
    utcOffset: "\u221209:00",
  },
  {
    name: "america/anguilla",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/antigua",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/araguaina",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/argentina/buenos_aires",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/argentina/catamarca",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/argentina/comodrivadavia",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/argentina/cordoba",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/argentina/jujuy",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/argentina/la_rioja",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/argentina/mendoza",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/argentina/rio_gallegos",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/argentina/salta",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/argentina/san_juan",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/argentina/san_luis",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/argentina/tucuman",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/argentina/ushuaia",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/aruba",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/asuncion",
    abbreviation: "-04",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/atikokan",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/atka",
    abbreviation: "hst",
    utcOffset: "\u221210:00",
  },
  {
    name: "america/bahia",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/bahia_banderas",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/barbados",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/belem",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/belize",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/blanc-sablon",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/boa_vista",
    abbreviation: "-04",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/bogota",
    abbreviation: "-05",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/boise",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "america/buenos_aires",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/cambridge_bay",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "america/campo_grande",
    abbreviation: "-04",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/cancun",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/caracas",
    abbreviation: "-04",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/catamarca",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/cayenne",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/cayman",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/chicago",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/chihuahua",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/ciudad_juarez",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "america/coral_harbour",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/cordoba",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/costa_rica",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/creston",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "america/cuiaba",
    abbreviation: "-04",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/curacao",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/danmarkshavn",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "america/dawson",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "america/dawson_creek",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "america/denver",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "america/detroit",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/dominica",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/edmonton",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "america/eirunepe",
    abbreviation: "-05",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/el_salvador",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/ensenada",
    abbreviation: "pst",
    utcOffset: "\u221208:00",
  },
  {
    name: "america/fort_nelson",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "america/fort_wayne",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/fortaleza",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/glace_bay",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/godthab",
    abbreviation: "-02",
    utcOffset: "\u221202:00",
  },
  {
    name: "america/goose_bay",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/grand_turk",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/grenada",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/guadeloupe",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/guatemala",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/guayaquil",
    abbreviation: "-05",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/guyana",
    abbreviation: "-04",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/halifax",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/havana",
    abbreviation: "cst",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/hermosillo",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "america/indiana/indianapolis",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/indiana/knox",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/indiana/marengo",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/indiana/petersburg",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/indiana/tell_city",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/indiana/vevay",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/indiana/vincennes",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/indiana/winamac",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/indianapolis",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/inuvik",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "america/iqaluit",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/jamaica",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/jujuy",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/juneau",
    abbreviation: "akst",
    utcOffset: "\u221209:00",
  },
  {
    name: "america/kentucky/louisville",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/kentucky/monticello",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/knox_in",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/kralendijk",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/la_paz",
    abbreviation: "-04",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/lima",
    abbreviation: "-05",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/los_angeles",
    abbreviation: "pst",
    utcOffset: "\u221208:00",
  },
  {
    name: "america/louisville",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/lower_princes",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/maceio",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/managua",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/manaus",
    abbreviation: "-04",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/marigot",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/martinique",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/matamoros",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/mazatlan",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "america/mendoza",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/menominee",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/merida",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/metlakatla",
    abbreviation: "akst",
    utcOffset: "\u221209:00",
  },
  {
    name: "america/mexico_city",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/miquelon",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/moncton",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/monterrey",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/montevideo",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/montreal",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/montserrat",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/nassau",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/new_york",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/nipigon",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/nome",
    abbreviation: "akst",
    utcOffset: "\u221209:00",
  },
  {
    name: "america/noronha",
    abbreviation: "-02",
    utcOffset: "\u221202:00",
  },
  {
    name: "america/north_dakota/beulah",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/north_dakota/center",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/north_dakota/new_salem",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/nuuk",
    abbreviation: "-02",
    utcOffset: "\u221202:00",
  },
  {
    name: "america/ojinaga",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/panama",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/pangnirtung",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/paramaribo",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/phoenix",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "america/port-au-prince",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/port_of_spain",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/porto_acre",
    abbreviation: "-05",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/porto_velho",
    abbreviation: "-04",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/puerto_rico",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/punta_arenas",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/rainy_river",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/rankin_inlet",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/recife",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/regina",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/resolute",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/rio_branco",
    abbreviation: "-05",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/rosario",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/santa_isabel",
    abbreviation: "pst",
    utcOffset: "\u221208:00",
  },
  {
    name: "america/santarem",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/santiago",
    abbreviation: "-04",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/santo_domingo",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/sao_paulo",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "america/scoresbysund",
    abbreviation: "-01",
    utcOffset: "\u221201:00",
  },
  {
    name: "america/shiprock",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "america/sitka",
    abbreviation: "akst",
    utcOffset: "\u221209:00",
  },
  {
    name: "asia/seoul",
    abbreviation: "kst",
    utcOffset: "+09:00",
  },
  {
    name: "america/st_barthelemy",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/st_johns",
    abbreviation: "nst",
    utcOffset: "\u221203:30",
  },
  {
    name: "america/st_kitts",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/st_lucia",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/st_thomas",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/st_vincent",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/swift_current",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/tegucigalpa",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/thule",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/thunder_bay",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/tijuana",
    abbreviation: "pst",
    utcOffset: "\u221208:00",
  },
  {
    name: "america/toronto",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "america/tortola",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/vancouver",
    abbreviation: "pst",
    utcOffset: "\u221208:00",
  },
  {
    name: "america/virgin",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "america/whitehorse",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "america/winnipeg",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "america/yakutat",
    abbreviation: "akst",
    utcOffset: "\u221209:00",
  },
  {
    name: "america/yellowknife",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "antarctica/casey",
    abbreviation: "+11",
    utcOffset: "+11:00",
  },
  {
    name: "antarctica/davis",
    abbreviation: "+07",
    utcOffset: "+07:00",
  },
  {
    name: "antarctica/dumontdurville",
    abbreviation: "+10",
    utcOffset: "+10:00",
  },
  {
    name: "antarctica/macquarie",
    abbreviation: "aest",
    utcOffset: "+10:00",
  },
  {
    name: "antarctica/mawson",
    abbreviation: "+05",
    utcOffset: "+05:00",
  },
  {
    name: "antarctica/mcmurdo",
    abbreviation: "nzst",
    utcOffset: "+12:00",
  },
  {
    name: "antarctica/palmer",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "antarctica/rothera",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "antarctica/south_pole",
    abbreviation: "nzst",
    utcOffset: "+12:00",
  },
  {
    name: "antarctica/syowa",
    abbreviation: "+03",
    utcOffset: "+03:00",
  },
  {
    name: "antarctica/troll",
    abbreviation: "+00",
    utcOffset: "+00:00",
  },
  {
    name: "antarctica/vostok",
    abbreviation: "+06",
    utcOffset: "+06:00",
  },
  {
    name: "arctic/longyearbyen",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "asia/aden",
    abbreviation: "+03",
    utcOffset: "+03:00",
  },
  {
    name: "asia/almaty",
    abbreviation: "+06",
    utcOffset: "+06:00",
  },
  {
    name: "asia/amman",
    abbreviation: "+03",
    utcOffset: "+03:00",
  },
  {
    name: "asia/anadyr",
    abbreviation: "+12",
    utcOffset: "+12:00",
  },
  {
    name: "asia/aqtau",
    abbreviation: "+05",
    utcOffset: "+05:00",
  },
  {
    name: "asia/aqtobe",
    abbreviation: "+05",
    utcOffset: "+05:00",
  },
  {
    name: "asia/ashgabat",
    abbreviation: "+05",
    utcOffset: "+05:00",
  },
  {
    name: "asia/ashkhabad",
    abbreviation: "+05",
    utcOffset: "+05:00",
  },
  {
    name: "asia/atyrau",
    abbreviation: "+05",
    utcOffset: "+05:00",
  },
  {
    name: "asia/baghdad",
    abbreviation: "+03",
    utcOffset: "+03:00",
  },
  {
    name: "asia/bahrain",
    abbreviation: "+03",
    utcOffset: "+03:00",
  },
  {
    name: "asia/baku",
    abbreviation: "+04",
    utcOffset: "+04:00",
  },
  {
    name: "asia/bangkok",
    abbreviation: "+07",
    utcOffset: "+07:00",
  },
  {
    name: "asia/barnaul",
    abbreviation: "+07",
    utcOffset: "+07:00",
  },
  {
    name: "asia/beirut",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "asia/bishkek",
    abbreviation: "+06",
    utcOffset: "+06:00",
  },
  {
    name: "asia/brunei",
    abbreviation: "+08",
    utcOffset: "+08:00",
  },
  {
    name: "asia/calcutta",
    abbreviation: "ist",
    utcOffset: "+05:30",
  },
  {
    name: "asia/chita",
    abbreviation: "+09",
    utcOffset: "+09:00",
  },
  {
    name: "asia/choibalsan",
    abbreviation: "+08",
    utcOffset: "+08:00",
  },
  {
    name: "asia/chongqing",
    abbreviation: "cst",
    utcOffset: "+08:00",
  },
  {
    name: "asia/chungking",
    abbreviation: "cst",
    utcOffset: "+08:00",
  },
  {
    name: "asia/colombo",
    abbreviation: "+0530",
    utcOffset: "+05:30",
  },
  {
    name: "asia/dacca",
    abbreviation: "+06",
    utcOffset: "+06:00",
  },
  {
    name: "asia/damascus",
    abbreviation: "+03",
    utcOffset: "+03:00",
  },
  {
    name: "asia/dhaka",
    abbreviation: "+06",
    utcOffset: "+06:00",
  },
  {
    name: "asia/dili",
    abbreviation: "+09",
    utcOffset: "+09:00",
  },
  {
    name: "asia/dubai",
    abbreviation: "+04",
    utcOffset: "+04:00",
  },
  {
    name: "asia/dushanbe",
    abbreviation: "+05",
    utcOffset: "+05:00",
  },
  {
    name: "asia/famagusta",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "asia/gaza",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "asia/harbin",
    abbreviation: "cst",
    utcOffset: "+08:00",
  },
  {
    name: "asia/hebron",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "asia/ho_chi_minh",
    abbreviation: "+07",
    utcOffset: "+07:00",
  },
  {
    name: "asia/hong_kong",
    abbreviation: "hkt",
    utcOffset: "+08:00",
  },
  {
    name: "asia/hovd",
    abbreviation: "+07",
    utcOffset: "+07:00",
  },
  {
    name: "asia/irkutsk",
    abbreviation: "+08",
    utcOffset: "+08:00",
  },
  {
    name: "asia/istanbul",
    abbreviation: "+03",
    utcOffset: "+03:00",
  },
  {
    name: "asia/jakarta",
    abbreviation: "wib",
    utcOffset: "+07:00",
  },
  {
    name: "asia/jayapura",
    abbreviation: "wit",
    utcOffset: "+09:00",
  },
  {
    name: "asia/jerusalem",
    abbreviation: "ist",
    utcOffset: "+02:00",
  },
  {
    name: "asia/kabul",
    abbreviation: "+0430",
    utcOffset: "+04:30",
  },
  {
    name: "asia/kamchatka",
    abbreviation: "+12",
    utcOffset: "+12:00",
  },
  {
    name: "asia/karachi",
    abbreviation: "pkt",
    utcOffset: "+05:00",
  },
  {
    name: "asia/kashgar",
    abbreviation: "+06",
    utcOffset: "+06:00",
  },
  {
    name: "asia/kathmandu",
    abbreviation: "+0545",
    utcOffset: "+05:45",
  },
  {
    name: "asia/katmandu",
    abbreviation: "+0545",
    utcOffset: "+05:45",
  },
  {
    name: "asia/khandyga",
    abbreviation: "+09",
    utcOffset: "+09:00",
  },
  {
    name: "asia/kolkata",
    abbreviation: "ist",
    utcOffset: "+05:30",
  },
  {
    name: "asia/krasnoyarsk",
    abbreviation: "+07",
    utcOffset: "+07:00",
  },
  {
    name: "asia/kuala_lumpur",
    abbreviation: "+08",
    utcOffset: "+08:00",
  },
  {
    name: "asia/kuching",
    abbreviation: "+08",
    utcOffset: "+08:00",
  },
  {
    name: "asia/kuwait",
    abbreviation: "+03",
    utcOffset: "+03:00",
  },
  {
    name: "asia/macao",
    abbreviation: "cst",
    utcOffset: "+08:00",
  },
  {
    name: "asia/macau",
    abbreviation: "cst",
    utcOffset: "+08:00",
  },
  {
    name: "asia/magadan",
    abbreviation: "+11",
    utcOffset: "+11:00",
  },
  {
    name: "asia/makassar",
    abbreviation: "wita",
    utcOffset: "+08:00",
  },
  {
    name: "asia/manila",
    abbreviation: "pst",
    utcOffset: "+08:00",
  },
  {
    name: "asia/muscat",
    abbreviation: "+04",
    utcOffset: "+04:00",
  },
  {
    name: "asia/nicosia",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "asia/novokuznetsk",
    abbreviation: "+07",
    utcOffset: "+07:00",
  },
  {
    name: "asia/novosibirsk",
    abbreviation: "+07",
    utcOffset: "+07:00",
  },
  {
    name: "asia/omsk",
    abbreviation: "+06",
    utcOffset: "+06:00",
  },
  {
    name: "asia/oral",
    abbreviation: "+05",
    utcOffset: "+05:00",
  },
  {
    name: "asia/phnom_penh",
    abbreviation: "+07",
    utcOffset: "+07:00",
  },
  {
    name: "asia/pontianak",
    abbreviation: "wib",
    utcOffset: "+07:00",
  },
  {
    name: "asia/pyongyang",
    abbreviation: "kst",
    utcOffset: "+09:00",
  },
  {
    name: "asia/qatar",
    abbreviation: "+03",
    utcOffset: "+03:00",
  },
  {
    name: "asia/qostanay",
    abbreviation: "+06",
    utcOffset: "+06:00",
  },
  {
    name: "asia/qyzylorda",
    abbreviation: "+05",
    utcOffset: "+05:00",
  },
  {
    name: "asia/rangoon",
    abbreviation: "+0630",
    utcOffset: "+06:30",
  },
  {
    name: "asia/riyadh",
    abbreviation: "+03",
    utcOffset: "+03:00",
  },
  {
    name: "asia/saigon",
    abbreviation: "+07",
    utcOffset: "+07:00",
  },
  {
    name: "asia/sakhalin",
    abbreviation: "+11",
    utcOffset: "+11:00",
  },
  {
    name: "asia/samarkand",
    abbreviation: "+05",
    utcOffset: "+05:00",
  },
  {
    name: "asia/shanghai",
    abbreviation: "cst",
    utcOffset: "+08:00",
  },
  {
    name: "asia/singapore",
    abbreviation: "+08",
    utcOffset: "+08:00",
  },
  {
    name: "asia/srednekolymsk",
    abbreviation: "+11",
    utcOffset: "+11:00",
  },
  {
    name: "asia/taipei",
    abbreviation: "cst",
    utcOffset: "+08:00",
  },
  {
    name: "asia/tashkent",
    abbreviation: "+05",
    utcOffset: "+05:00",
  },
  {
    name: "asia/tbilisi",
    abbreviation: "+04",
    utcOffset: "+04:00",
  },
  {
    name: "asia/tehran",
    abbreviation: "+0330",
    utcOffset: "+03:30",
  },
  {
    name: "asia/tel_aviv",
    abbreviation: "ist",
    utcOffset: "+02:00",
  },
  {
    name: "asia/thimbu",
    abbreviation: "+06",
    utcOffset: "+06:00",
  },
  {
    name: "asia/thimphu",
    abbreviation: "+06",
    utcOffset: "+06:00",
  },
  {
    name: "asia/tokyo",
    abbreviation: "jst",
    utcOffset: "+09:00",
  },
  {
    name: "asia/tomsk",
    abbreviation: "+07",
    utcOffset: "+07:00",
  },
  {
    name: "asia/ujung_pandang",
    abbreviation: "wita",
    utcOffset: "+08:00",
  },
  {
    name: "asia/ulaanbaatar",
    abbreviation: "+08",
    utcOffset: "+08:00",
  },
  {
    name: "asia/ulan_bator",
    abbreviation: "+08",
    utcOffset: "+08:00",
  },
  {
    name: "asia/urumqi",
    abbreviation: "+06",
    utcOffset: "+06:00",
  },
  {
    name: "asia/ust-nera",
    abbreviation: "+10",
    utcOffset: "+10:00",
  },
  {
    name: "asia/vientiane",
    abbreviation: "+07",
    utcOffset: "+07:00",
  },
  {
    name: "asia/vladivostok",
    abbreviation: "+10",
    utcOffset: "+10:00",
  },
  {
    name: "asia/yakutsk",
    abbreviation: "+09",
    utcOffset: "+09:00",
  },
  {
    name: "asia/yangon",
    abbreviation: "+0630",
    utcOffset: "+06:30",
  },
  {
    name: "asia/yekaterinburg",
    abbreviation: "+05",
    utcOffset: "+05:00",
  },
  {
    name: "asia/yerevan",
    abbreviation: "+04",
    utcOffset: "+04:00",
  },
  {
    name: "atlantic/azores",
    abbreviation: "-01",
    utcOffset: "\u221201:00",
  },
  {
    name: "atlantic/bermuda",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "atlantic/canary",
    abbreviation: "wet",
    utcOffset: "+00:00",
  },
  {
    name: "atlantic/cape_verde",
    abbreviation: "-01",
    utcOffset: "\u221201:00",
  },
  {
    name: "atlantic/faeroe",
    abbreviation: "wet",
    utcOffset: "+00:00",
  },
  {
    name: "atlantic/faroe",
    abbreviation: "wet",
    utcOffset: "+00:00",
  },
  {
    name: "atlantic/jan_mayen",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "atlantic/madeira",
    abbreviation: "wet",
    utcOffset: "+00:00",
  },
  {
    name: "atlantic/reykjavik",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "atlantic/south_georgia",
    abbreviation: "-02",
    utcOffset: "\u221202:00",
  },
  {
    name: "atlantic/st_helena",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "atlantic/stanley",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "australia/act",
    abbreviation: "aest",
    utcOffset: "+10:00",
  },
  {
    name: "australia/adelaide",
    abbreviation: "acst",
    utcOffset: "+09:30",
  },
  {
    name: "australia/brisbane",
    abbreviation: "aest",
    utcOffset: "+10:00",
  },
  {
    name: "australia/broken_hill",
    abbreviation: "acst",
    utcOffset: "+09:30",
  },
  {
    name: "australia/canberra",
    abbreviation: "aest",
    utcOffset: "+10:00",
  },
  {
    name: "australia/currie",
    abbreviation: "aest",
    utcOffset: "+10:00",
  },
  {
    name: "australia/darwin",
    abbreviation: "acst",
    utcOffset: "+09:30",
  },
  {
    name: "australia/eucla",
    abbreviation: "+0845",
    utcOffset: "+08:45",
  },
  {
    name: "australia/hobart",
    abbreviation: "aest",
    utcOffset: "+10:00",
  },
  {
    name: "australia/lhi",
    abbreviation: "+1030",
    utcOffset: "+10:30",
  },
  {
    name: "australia/lindeman",
    abbreviation: "aest",
    utcOffset: "+10:00",
  },
  {
    name: "australia/lord_howe",
    abbreviation: "+1030",
    utcOffset: "+10:30",
  },
  {
    name: "australia/melbourne",
    abbreviation: "aest",
    utcOffset: "+10:00",
  },
  {
    name: "australia/north",
    abbreviation: "acst",
    utcOffset: "+09:30",
  },
  {
    name: "australia/nsw",
    abbreviation: "aest",
    utcOffset: "+10:00",
  },
  {
    name: "australia/perth",
    abbreviation: "awst",
    utcOffset: "+08:00",
  },
  {
    name: "australia/queensland",
    abbreviation: "aest",
    utcOffset: "+10:00",
  },
  {
    name: "australia/south",
    abbreviation: "acst",
    utcOffset: "+09:30",
  },
  {
    name: "australia/sydney",
    abbreviation: "aest",
    utcOffset: "+10:00",
  },
  {
    name: "australia/tasmania",
    abbreviation: "aest",
    utcOffset: "+10:00",
  },
  {
    name: "australia/victoria",
    abbreviation: "aest",
    utcOffset: "+10:00",
  },
  {
    name: "australia/west",
    abbreviation: "awst",
    utcOffset: "+08:00",
  },
  {
    name: "australia/yancowinna",
    abbreviation: "acst",
    utcOffset: "+09:30",
  },
  {
    name: "brazil/acre",
    abbreviation: "-05",
    utcOffset: "\u221205:00",
  },
  {
    name: "brazil/denoronha",
    abbreviation: "-02",
    utcOffset: "\u221202:00",
  },
  {
    name: "brazil/east",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "brazil/west",
    abbreviation: "-04",
    utcOffset: "\u221204:00",
  },
  {
    name: "canada/atlantic",
    abbreviation: "ast",
    utcOffset: "\u221204:00",
  },
  {
    name: "canada/central",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "canada/eastern",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "canada/mountain",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "canada/newfoundland",
    abbreviation: "nst",
    utcOffset: "\u221203:30",
  },
  {
    name: "canada/pacific",
    abbreviation: "pst",
    utcOffset: "\u221208:00",
  },
  {
    name: "canada/saskatchewan",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "canada/yukon",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "cet",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "chile/continental",
    abbreviation: "-04",
    utcOffset: "\u221204:00",
  },
  {
    name: "chile/easterisland",
    abbreviation: "-06",
    utcOffset: "\u221206:00",
  },
  {
    name: "cst6cdt",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "cuba",
    abbreviation: "cst",
    utcOffset: "\u221205:00",
  },
  {
    name: "eet",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "egypt",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "eire",
    abbreviation: "ist",
    utcOffset: "+01:00",
  },
  {
    name: "est",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "est5edt",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "etc/gmt",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "etc/gmt+0",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "etc/gmt+1",
    abbreviation: "-01",
    utcOffset: "\u221201:00",
  },
  {
    name: "etc/gmt+10",
    abbreviation: "-10",
    utcOffset: "\u221210:00",
  },
  {
    name: "etc/gmt+11",
    abbreviation: "-11",
    utcOffset: "\u221211:00",
  },
  {
    name: "etc/gmt+12",
    abbreviation: "-12",
    utcOffset: "\u221212:00",
  },
  {
    name: "etc/gmt+2",
    abbreviation: "-02",
    utcOffset: "\u221202:00",
  },
  {
    name: "etc/gmt+3",
    abbreviation: "-03",
    utcOffset: "\u221203:00",
  },
  {
    name: "etc/gmt+4",
    abbreviation: "-04",
    utcOffset: "\u221204:00",
  },
  {
    name: "etc/gmt+5",
    abbreviation: "-05",
    utcOffset: "\u221205:00",
  },
  {
    name: "etc/gmt+6",
    abbreviation: "-06",
    utcOffset: "\u221206:00",
  },
  {
    name: "etc/gmt+7",
    abbreviation: "-07",
    utcOffset: "\u221207:00",
  },
  {
    name: "etc/gmt+8",
    abbreviation: "-08",
    utcOffset: "\u221208:00",
  },
  {
    name: "etc/gmt+9",
    abbreviation: "-09",
    utcOffset: "\u221209:00",
  },
  {
    name: "etc/gmt-0",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "etc/gmt-1",
    abbreviation: "+01",
    utcOffset: "+01:00",
  },
  {
    name: "etc/gmt-10",
    abbreviation: "+10",
    utcOffset: "+10:00",
  },
  {
    name: "etc/gmt-11",
    abbreviation: "+11",
    utcOffset: "+11:00",
  },
  {
    name: "etc/gmt-12",
    abbreviation: "+12",
    utcOffset: "+12:00",
  },
  {
    name: "etc/gmt-13",
    abbreviation: "+13",
    utcOffset: "+13:00",
  },
  {
    name: "etc/gmt-14",
    abbreviation: "+14",
    utcOffset: "+14:00",
  },
  {
    name: "etc/gmt-2",
    abbreviation: "+02",
    utcOffset: "+02:00",
  },
  {
    name: "etc/gmt-3",
    abbreviation: "+03",
    utcOffset: "+03:00",
  },
  {
    name: "etc/gmt-4",
    abbreviation: "+04",
    utcOffset: "+04:00",
  },
  {
    name: "etc/gmt-5",
    abbreviation: "+05",
    utcOffset: "+05:00",
  },
  {
    name: "etc/gmt-6",
    abbreviation: "+06",
    utcOffset: "+06:00",
  },
  {
    name: "etc/gmt-7",
    abbreviation: "+07",
    utcOffset: "+07:00",
  },
  {
    name: "etc/gmt-8",
    abbreviation: "+08",
    utcOffset: "+08:00",
  },
  {
    name: "etc/gmt-9",
    abbreviation: "+09",
    utcOffset: "+09:00",
  },
  {
    name: "etc/gmt0",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "etc/greenwich",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "etc/uct",
    abbreviation: "utc",
    utcOffset: "+00:00",
  },
  {
    name: "etc/universal",
    abbreviation: "utc",
    utcOffset: "+00:00",
  },
  {
    name: "etc/utc",
    abbreviation: "utc",
    utcOffset: "+00:00",
  },
  {
    name: "etc/zulu",
    abbreviation: "utc",
    utcOffset: "+00:00",
  },
  {
    name: "europe/amsterdam",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/andorra",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/astrakhan",
    abbreviation: "+04",
    utcOffset: "+04:00",
  },
  {
    name: "europe/athens",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "europe/belfast",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "europe/belgrade",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/berlin",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/bratislava",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/brussels",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/bucharest",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "europe/budapest",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/busingen",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/chisinau",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "europe/copenhagen",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/dublin",
    abbreviation: "ist",
    utcOffset: "+01:00",
  },
  {
    name: "europe/gibraltar",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/guernsey",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "europe/helsinki",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "europe/isle_of_man",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "europe/istanbul",
    abbreviation: "+03",
    utcOffset: "+03:00",
  },
  {
    name: "europe/jersey",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "europe/kaliningrad",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "europe/kiev",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "europe/kirov",
    abbreviation: "msk",
    utcOffset: "+03:00",
  },
  {
    name: "europe/kyiv",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "europe/lisbon",
    abbreviation: "wet",
    utcOffset: "+00:00",
  },
  {
    name: "europe/ljubljana",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/london",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "europe/luxembourg",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/madrid",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/malta",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/mariehamn",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "europe/minsk",
    abbreviation: "+03",
    utcOffset: "+03:00",
  },
  {
    name: "europe/monaco",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/moscow",
    abbreviation: "msk",
    utcOffset: "+03:00",
  },
  {
    name: "europe/nicosia",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "europe/oslo",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/paris",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/podgorica",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/prague",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/riga",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "europe/rome",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/samara",
    abbreviation: "+04",
    utcOffset: "+04:00",
  },
  {
    name: "europe/san_marino",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/sarajevo",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/saratov",
    abbreviation: "+04",
    utcOffset: "+04:00",
  },
  {
    name: "europe/simferopol",
    abbreviation: "msk",
    utcOffset: "+03:00",
  },
  {
    name: "europe/skopje",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/sofia",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "europe/stockholm",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/tallinn",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "europe/tirane",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/tiraspol",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "europe/ulyanovsk",
    abbreviation: "+04",
    utcOffset: "+04:00",
  },
  {
    name: "europe/uzhgorod",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "europe/vaduz",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/vatican",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/vienna",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/vilnius",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "europe/volgograd",
    abbreviation: "msk",
    utcOffset: "+03:00",
  },
  {
    name: "europe/warsaw",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/zagreb",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "europe/zaporozhye",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "europe/zurich",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "gb",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "gb-eire",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "gmt",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "gmt+0",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "gmt-0",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "gmt0",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "greenwich",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "hongkong",
    abbreviation: "hkt",
    utcOffset: "+08:00",
  },
  {
    name: "hst",
    abbreviation: "hst",
    utcOffset: "\u221210:00",
  },
  {
    name: "iceland",
    abbreviation: "gmt",
    utcOffset: "+00:00",
  },
  {
    name: "indian/antananarivo",
    abbreviation: "eat",
    utcOffset: "+03:00",
  },
  {
    name: "indian/chagos",
    abbreviation: "+06",
    utcOffset: "+06:00",
  },
  {
    name: "indian/christmas",
    abbreviation: "+07",
    utcOffset: "+07:00",
  },
  {
    name: "indian/cocos",
    abbreviation: "+0630",
    utcOffset: "+06:30",
  },
  {
    name: "indian/comoro",
    abbreviation: "eat",
    utcOffset: "+03:00",
  },
  {
    name: "indian/kerguelen",
    abbreviation: "+05",
    utcOffset: "+05:00",
  },
  {
    name: "indian/mahe",
    abbreviation: "+04",
    utcOffset: "+04:00",
  },
  {
    name: "indian/maldives",
    abbreviation: "+05",
    utcOffset: "+05:00",
  },
  {
    name: "indian/mauritius",
    abbreviation: "+04",
    utcOffset: "+04:00",
  },
  {
    name: "indian/mayotte",
    abbreviation: "eat",
    utcOffset: "+03:00",
  },
  {
    name: "indian/reunion",
    abbreviation: "+04",
    utcOffset: "+04:00",
  },
  {
    name: "iran",
    abbreviation: "+0330",
    utcOffset: "+03:30",
  },
  {
    name: "israel",
    abbreviation: "ist",
    utcOffset: "+02:00",
  },
  {
    name: "jamaica",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "japan",
    abbreviation: "jst",
    utcOffset: "+09:00",
  },
  {
    name: "kwajalein",
    abbreviation: "+12",
    utcOffset: "+12:00",
  },
  {
    name: "libya",
    abbreviation: "eet",
    utcOffset: "+02:00",
  },
  {
    name: "met",
    abbreviation: "met",
    utcOffset: "+01:00",
  },
  {
    name: "mexico/bajanorte",
    abbreviation: "pst",
    utcOffset: "\u221208:00",
  },
  {
    name: "mexico/bajasur",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "mexico/general",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "mst",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "mst7mdt",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "navajo",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "nz",
    abbreviation: "nzst",
    utcOffset: "+12:00",
  },
  {
    name: "nz-chat",
    abbreviation: "+1245",
    utcOffset: "+12:45",
  },
  {
    name: "pacific/apia",
    abbreviation: "+13",
    utcOffset: "+13:00",
  },
  {
    name: "pacific/auckland",
    abbreviation: "nzst",
    utcOffset: "+12:00",
  },
  {
    name: "pacific/bougainville",
    abbreviation: "+11",
    utcOffset: "+11:00",
  },
  {
    name: "pacific/chatham",
    abbreviation: "+1245",
    utcOffset: "+12:45",
  },
  {
    name: "pacific/chuuk",
    abbreviation: "+10",
    utcOffset: "+10:00",
  },
  {
    name: "pacific/easter",
    abbreviation: "-06",
    utcOffset: "\u221206:00",
  },
  {
    name: "pacific/efate",
    abbreviation: "+11",
    utcOffset: "+11:00",
  },
  {
    name: "pacific/enderbury",
    abbreviation: "+13",
    utcOffset: "+13:00",
  },
  {
    name: "pacific/fakaofo",
    abbreviation: "+13",
    utcOffset: "+13:00",
  },
  {
    name: "pacific/fiji",
    abbreviation: "+12",
    utcOffset: "+12:00",
  },
  {
    name: "pacific/funafuti",
    abbreviation: "+12",
    utcOffset: "+12:00",
  },
  {
    name: "pacific/galapagos",
    abbreviation: "-06",
    utcOffset: "\u221206:00",
  },
  {
    name: "pacific/gambier",
    abbreviation: "-09",
    utcOffset: "\u221209:00",
  },
  {
    name: "pacific/guadalcanal",
    abbreviation: "+11",
    utcOffset: "+11:00",
  },
  {
    name: "pacific/guam",
    abbreviation: "chst",
    utcOffset: "+10:00",
  },
  {
    name: "pacific/honolulu",
    abbreviation: "hst",
    utcOffset: "\u221210:00",
  },
  {
    name: "pacific/johnston",
    abbreviation: "hst",
    utcOffset: "\u221210:00",
  },
  {
    name: "pacific/kanton",
    abbreviation: "+13",
    utcOffset: "+13:00",
  },
  {
    name: "pacific/kiritimati",
    abbreviation: "+14",
    utcOffset: "+14:00",
  },
  {
    name: "pacific/kosrae",
    abbreviation: "+11",
    utcOffset: "+11:00",
  },
  {
    name: "pacific/kwajalein",
    abbreviation: "+12",
    utcOffset: "+12:00",
  },
  {
    name: "pacific/majuro",
    abbreviation: "+12",
    utcOffset: "+12:00",
  },
  {
    name: "pacific/marquesas",
    abbreviation: "-0930",
    utcOffset: "\u221209:30",
  },
  {
    name: "pacific/midway",
    abbreviation: "sst",
    utcOffset: "\u221211:00",
  },
  {
    name: "pacific/nauru",
    abbreviation: "+12",
    utcOffset: "+12:00",
  },
  {
    name: "pacific/niue",
    abbreviation: "-11",
    utcOffset: "\u221211:00",
  },
  {
    name: "pacific/norfolk",
    abbreviation: "+11",
    utcOffset: "+11:00",
  },
  {
    name: "pacific/noumea",
    abbreviation: "+11",
    utcOffset: "+11:00",
  },
  {
    name: "pacific/pago_pago",
    abbreviation: "sst",
    utcOffset: "\u221211:00",
  },
  {
    name: "pacific/palau",
    abbreviation: "+09",
    utcOffset: "+09:00",
  },
  {
    name: "pacific/pitcairn",
    abbreviation: "-08",
    utcOffset: "\u221208:00",
  },
  {
    name: "pacific/pohnpei",
    abbreviation: "+11",
    utcOffset: "+11:00",
  },
  {
    name: "pacific/ponape",
    abbreviation: "+11",
    utcOffset: "+11:00",
  },
  {
    name: "pacific/port_moresby",
    abbreviation: "+10",
    utcOffset: "+10:00",
  },
  {
    name: "pacific/rarotonga",
    abbreviation: "-10",
    utcOffset: "\u221210:00",
  },
  {
    name: "pacific/saipan",
    abbreviation: "chst",
    utcOffset: "+10:00",
  },
  {
    name: "pacific/samoa",
    abbreviation: "sst",
    utcOffset: "\u221211:00",
  },
  {
    name: "pacific/tahiti",
    abbreviation: "-10",
    utcOffset: "\u221210:00",
  },
  {
    name: "pacific/tarawa",
    abbreviation: "+12",
    utcOffset: "+12:00",
  },
  {
    name: "pacific/tongatapu",
    abbreviation: "+13",
    utcOffset: "+13:00",
  },
  {
    name: "pacific/truk",
    abbreviation: "+10",
    utcOffset: "+10:00",
  },
  {
    name: "pacific/wake",
    abbreviation: "+12",
    utcOffset: "+12:00",
  },
  {
    name: "pacific/wallis",
    abbreviation: "+12",
    utcOffset: "+12:00",
  },
  {
    name: "pacific/yap",
    abbreviation: "+10",
    utcOffset: "+10:00",
  },
  {
    name: "poland",
    abbreviation: "cet",
    utcOffset: "+01:00",
  },
  {
    name: "portugal",
    abbreviation: "wet",
    utcOffset: "+00:00",
  },
  {
    name: "prc",
    abbreviation: "cst",
    utcOffset: "+08:00",
  },
  {
    name: "pst8pdt",
    abbreviation: "pst",
    utcOffset: "\u221208:00",
  },
  {
    name: "roc",
    abbreviation: "cst",
    utcOffset: "+08:00",
  },
  {
    name: "rok",
    abbreviation: "kst",
    utcOffset: "+09:00",
  },
  {
    name: "singapore",
    abbreviation: "+08",
    utcOffset: "+08:00",
  },
  {
    name: "turkey",
    abbreviation: "+03",
    utcOffset: "+03:00",
  },
  {
    name: "uct",
    abbreviation: "utc",
    utcOffset: "+00:00",
  },
  {
    name: "universal",
    abbreviation: "utc",
    utcOffset: "+00:00",
  },
  {
    name: "us/alaska",
    abbreviation: "akst",
    utcOffset: "\u221209:00",
  },
  {
    name: "us/aleutian",
    abbreviation: "hst",
    utcOffset: "\u221210:00",
  },
  {
    name: "us/arizona",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "us/central",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "us/east-indiana",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "us/eastern",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "us/hawaii",
    abbreviation: "hst",
    utcOffset: "\u221210:00",
  },
  {
    name: "us/indiana-starke",
    abbreviation: "cst",
    utcOffset: "\u221206:00",
  },
  {
    name: "us/michigan",
    abbreviation: "est",
    utcOffset: "\u221205:00",
  },
  {
    name: "us/mountain",
    abbreviation: "mst",
    utcOffset: "\u221207:00",
  },
  {
    name: "us/pacific",
    abbreviation: "pst",
    utcOffset: "\u221208:00",
  },
  {
    name: "us/samoa",
    abbreviation: "sst",
    utcOffset: "\u221211:00",
  },
  {
    name: "utc",
    abbreviation: "utc",
    utcOffset: "+00:00",
  },
  {
    name: "w-su",
    abbreviation: "msk",
    utcOffset: "+03:00",
  },
  {
    name: "wet",
    abbreviation: "wet",
    utcOffset: "+00:00",
  },
  {
    name: "zulu",
    abbreviation: "utc",
    utcOffset: "+00:00",
  },
];

import moment from "moment-timezone";
type TTimeZone =
  | { name: string; abbreviation: string; utcOffset: string }
  | undefined;
  
export const getSessionTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getFullTimeZonesMomentAll = () => {
  return moment.tz
    .names()
    .map((x) => ({ timezone: x, gmt: moment.tz(x).format("Z") }));
};

export const getTimeZoneUtcOffSet = (name: string) => {
  const timezone: TTimeZone = timezones.find((item) =>
    item.name.toLowerCase().includes(name.toLowerCase()),
  );
  return timezone?.utcOffset;
};
