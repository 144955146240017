import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import { useDisputeFraud } from "../hooks/useDisputeFraud";

type TProps = {
  markedAsFraud: boolean;
  disputeId: string;
};

const congif = {
  mark_as_fraud: {
    title: "Mark as fraud",
    description: (
      <>
        Are you sure you want to mark this transaction as fraud?
        <br />
        The risk level will rise to 3. You can reverse this action later if
        needed.
      </>
    ),
    action: "Mark as Fraud",
    modalWidth: 494,
  },
  unmark_as_fraud: {
    title: "Unmark as fraud",
    description: `Are you sure you want to unmark this case as fraud? 
    This will lower the risk level back to its previous state.`,
    modalWidth: 454,
    action: "Unmark as Fraud",
  },
};

const DisputeFraud = NiceModal.create(
  ({ markedAsFraud, disputeId }: TProps) => {
    const modal = useModal();
    const { handleMarkAsFraud } = useDisputeFraud({ disputeId, markedAsFraud });

    const handleCancel = () => modal.hide();

    const handleSave = () => {
      handleMarkAsFraud();
      handleCancel();
    };

    const settings = markedAsFraud
      ? congif.unmark_as_fraud
      : congif.mark_as_fraud;

    return (
      <ModalFactory
        variant="dialog"
        modalProps={{
          open: modal.visible,
          onClose: handleCancel,
          width: settings.modalWidth,
        }}
      >
        <ModalTitle
          title={settings.title}
          description={settings.description}
          padding="24px 24px 0"
          onClose={handleCancel}
          textStyles={{
            title: {
              fontSize: 18,
            },
            description: {
              fontWeight: 350,
            },
          }}
        />

        <ModalActions
          animationDelay={100}
          padding="20px 24px 16px"
          secondaryAction={{
            label: "Cancel",
            onClick: handleCancel,
            sx: { fontSize: 18 },
          }}
          primaryAction={{
            type: "button",
            label: settings.action,
            onClick: handleSave,
            background: "primary",
            isLoading: false,
            sx: { fontSize: 18, padding: "12px 24px" },
          }}
        />
      </ModalFactory>
    );
  },
);

export default DisputeFraud;
