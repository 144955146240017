import { BANK_ACCOUNT_NUMBER_REGEX } from "@constants/constants";
import { UseFormSetError } from "react-hook-form";

type ValidateBANumberFunctionParam = {
  accountNumber: string | undefined;
  defaultAccountNumber: string | undefined;
  setError?: UseFormSetError<any>;
  isForceError?: React.MutableRefObject<boolean>;
};

export const getValidBankAccountNumber = ({
  defaultAccountNumber,
  accountNumber,
  setError,
  isForceError,
}: ValidateBANumberFunctionParam): { number?: string } | undefined => {
  const isSameBA = accountNumber === defaultAccountNumber;

  if (isSameBA) return;

  if (!isSameBA && !accountNumber?.match(BANK_ACCOUNT_NUMBER_REGEX)) {
    if (isForceError) isForceError.current = true;
    setError &&
      setError("accountNumber", {
        message: "Please enter a valid account number",
      });
    return;
  }

  if (isForceError) isForceError.current = false;
  return { number: accountNumber };
};
