import { Stack } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useDocumentHandlers } from "@hooks/common/documents";
import { PAH_UPLOADER_PREVIEW_MODAL } from "modals/modal_names";
import NiceModal from "@ebay/nice-modal-react";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { urlToBase64 } from "@utils/assets";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { TOwnerFile } from "@components/Merchants/MerchantPreview/components/PrimaryAccountHolder/types";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import IDUploader from "./IDUploader";
import { TIDPreviewModalProps } from "./types";
import { IDVerificationLoader } from "./IDVerificationLoader";
import { IDUploaderMapper } from "./IDUploaderMapper";

type TOwnerFileItems = Array<{
  type: TOwnerFile;
  file: TMerchantDocument | undefined;
}>;

function IdentityVerification() {
  const {
    isEnterprise,
    id,
    isRefetching,
    data: { documents: data },
  } = useMerchantSidePanelContext();

  const [areDownloading, setAreDownloading] = useState(false);

  const { isMobileView } = useCustomTheme();
  const PAH_ID = data?.find((doc) => doc?.attTypeName === "account_owner");
  const PAH_SELFIE = data?.find(
    (doc) => doc?.attTypeName === "account_owner_selfie",
  );

  const resource = composePermission(
    isEnterprise ? RESOURCE_BASE.ENTERPRISE : RESOURCE_BASE.MERCHANT,
    RESOURCE_BASE.FILES,
  );

  const isUploadAllowed = useAccessControl({
    resource,
    operation: OPERATIONS.CREATE,
  });

  const isDeleteAllowed = useAccessControl({
    resource,
    operation: OPERATIONS.DELETE,
  });

  // =========
  // THIS SECTION CAN BE REVERTED BACK AFTER THE BE FIXES PAH ISSUES ABOUT FILES CONSISTENCIES

  const [files, setFiles] = useState<TOwnerFileItems>([]);

  const createMapper = useCallback(async (): Promise<TOwnerFileItems> => {
    const results = (
      await Promise.allSettled([
        urlToBase64(PAH_ID?.fileURL, PAH_ID?.fileName, PAH_ID?.fileType),
        urlToBase64(
          PAH_SELFIE?.fileURL,
          PAH_SELFIE?.fileName,
          PAH_SELFIE?.fileType,
        ),
      ])
    ).map((p) => p?.status === "fulfilled" && p?.value);
    const idFile = results[0] as string | undefined;
    const selfieFile = results[1] as string | undefined;
    return [
      {
        type: "primaryAccountHolderID",
        file: PAH_ID && idFile ? { ...PAH_ID, fileURL: idFile } : undefined,
      },
      {
        type: "primaryAccountHolderSelfie",
        file:
          PAH_SELFIE && selfieFile
            ? { ...PAH_SELFIE, fileURL: selfieFile }
            : undefined,
      },
    ];
  }, [PAH_ID?.fileURL, PAH_SELFIE?.fileURL]);

  useEffect(() => {
    (async () => {
      setAreDownloading(true);
      const _files = await createMapper();
      setFiles(_files);
      setAreDownloading(false);
    })();
    return () => setFiles([]);
  }, [data]);

  //=======

  const { downloadHandler } = useDocumentHandlers(id);

  const previewDocument = ({
    tmpUrl = "",
    type = "" as TOwnerFile,
    fileName = "",
  } = {}) => {
    const filesData = files.map(({ type: _type, file }) => {
      return {
        fileName:
          file?.fileName ||
          (type && fileName && type === _type ? fileName : ""),
        src:
          file?.fileURL ||
          (type && tmpUrl && type === _type ? tmpUrl : undefined) ||
          "",
        type: type,
        icon: IDUploaderMapper[_type].icon,
        title: _type === "primaryAccountHolderID" ? "ID" : "Selfie",
      };
    });

    NiceModal.show(PAH_UPLOADER_PREVIEW_MODAL, {
      filesData,
    } as TIDPreviewModalProps);
  };

  return (
    <Stack gap={1}>
      <Stack gap={1}>
        {areDownloading || isRefetching ? (
          <IDVerificationLoader />
        ) : (
          files.map(({ type, file }, index) => {
            return (
              <IDUploader
                key={index}
                type={type}
                isMobile={isMobileView}
                disabled={!isUploadAllowed}
                merchantId={id}
                docUrl={file?.fileURL || ""}
                downloadHandler={() => downloadHandler(file)}
                previewDocument={previewDocument}
                isUploadAllowed={isUploadAllowed}
                isDeleteAllowed={isDeleteAllowed}
                fileId={file?.id}
              />
            );
          })
        )}
      </Stack>
    </Stack>
  );
}

export default memo(IdentityVerification);
