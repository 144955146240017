import { WithFetching } from "@containers/WithFetching";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import GiveSelect, { GiveSelectProps } from "@shared/GiveInputs/GiveSelect";

const HFGiveSelect: React.FC<
  GiveSelectProps & {
    name: string;
    isFetchindData?: boolean;
    isError?: boolean;
    errorsMessage?: string;
    focusViewColor?: string;
  }
> = ({
  name,
  helperText,
  isFetchindData = false,
  isError = false,
  errorsMessage,
  ...props
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...rest }, fieldState: { error } }) => (
        <WithFetching
          errorsMessage={errorsMessage}
          isFetching={isFetchindData}
          isError={isError}
        >
          <GiveSelect
            inputRef={ref}
            {...rest}
            error={!!error}
            helperText={helperText || error?.message}
            {...props}
          />
        </WithFetching>
      )}
    />
  );
};

export default HFGiveSelect;
