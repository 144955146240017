import {
  sendTfaVerificationCode,
  verifyTfaVerificationCode,
} from "@services/api/2FA/tfaVerifications";
import { useRef, useState } from "react";
import { useMutation } from "react-query";

type IFormInputs = {
  otp_input: string;
  trust_device: boolean;
};

export function maskEmail(email: string): string {
  const [username, domain] = email.split("@");

  if (username.length <= 2) {
    return `${username.charAt(0)}*${"@" + domain}`;
  }

  const maskedUsername = `${username.charAt(0)}${"*".repeat(
    12,
  )}${username.charAt(username.length - 1)}`;
  return `${maskedUsername}@${domain}`;
}

export const useLoginOTP = () => {
  const loginRef = useRef<{
    password: string;
    remember: boolean;
    email: string;
    termsConditions: boolean;
  }>({
    password: "",
    remember: false,
    email: "",
    termsConditions: false,
  });
  const [isError, setIsError] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const tfaVerificationsMutation = useMutation(sendTfaVerificationCode);

  const tfaVerificationsTokenMutation = useMutation(verifyTfaVerificationCode);

  const resendCode = (data: "sms" | "email") => {
    tfaVerificationsMutation.mutate("email");
  };

  const handleVerify = (data: {
    token: string;
    trust: boolean;
    email: string;
    onSuccessFn: (data: {
      password: string;
      remember: boolean;
      email: string;
      termsConditions: boolean;
    }) => void;
  }) => {
    setIsLoading(true);
    tfaVerificationsTokenMutation.mutate(
      { token: data.token, trust: data.trust },
      {
        onError: (res: any) => {
          setIsError(true);
        },
        onSuccess: async (res: any) => {
          data.onSuccessFn({
            email: loginRef.current.email,
            password: loginRef.current.password,
            remember: loginRef.current.remember,
            termsConditions: loginRef.current.termsConditions,
          });
        },
        onSettled: () => {
          setIsLoading(false);
        }
      },
    );
  };
  return {
    isError,
    isLoading,
    resendCode,
    loginRef,
    handleVerify,
    setIsError,
  };
};
