import { customInstance } from "@services/api";
import { useMutation } from "react-query";

type SponsorStatusPayload = { sponsorStatus: string; reason?: string };

export const useSponsorUpdate = (merchantId: number) => {
  const updateSponsorStatus = useMutation((payload: SponsorStatusPayload) => {
    return customInstance({
      url: `/merchants/${merchantId}/sponsor-status`,
      method: "PATCH",
      data: payload,
    });
  });

  return { updateSponsorStatus };
};
