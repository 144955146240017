import { Stack } from "@mui/material";

import GiveText from "@shared/Text/GiveText";
import { useAppTheme } from "@theme/v2/Provider";
import { FC } from "react";
import PublicUrlShare from "./PublicURLShare/PublicURLShare";



const LaunchStep: FC<any> = () => {
  const { palette } = useAppTheme();
  return (
    <Stack gap={2}>
      <Stack gap={1.5}>
        <GiveText
          fontSize="28px"
          sx={{ fontWeight: 271, color: palette.primitive?.neutral[90] }}
        >
          Launch your products
        </GiveText>
        <GiveText
          fontSize="14px"
          sx={{
            fontWeight: 400,
            color: palette.primitive?.neutral[60],
          }}
        >
          Your Product form is ready to go! You can share this via the link
          below or embed this directly into your own page.
        </GiveText>
      </Stack>

      <PublicUrlShare
        variant="large"
      />
    </Stack>
  );
};

export default LaunchStep;
