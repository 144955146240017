import { useState, useContext, createContext } from "react";
import BuilderForm from "../BuilderForm";
import FormPreview from "../FormPreview";
import { makeStyles } from "@mui/styles";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

type SubComponentType = React.FC & {
  displayName?: string;
};

type MobileLayoutContextTypes = {
  activeView: "builder" | "preview";
  toggleView: () => void;
};

const ViewSwitcherContext = createContext<MobileLayoutContextTypes>({} as any);

export const ResponsiveViewSwitcher = ({ children }: any) => {
  const [activeView, setActiveView] = useState<"builder" | "preview">(
    "builder",
  );

  const toggleView = () => {
    setActiveView(activeView === "builder" ? "preview" : "builder");
  };

  return (
    <ViewSwitcherContext.Provider value={{ activeView, toggleView }}>
      {children}
    </ViewSwitcherContext.Provider>
  );
};

const BuilderFormSwitched: SubComponentType = () => {
  const { activeView } = useMobileLayoutHandler();
  const classes = useStyles();
  const { isMobileView } = useCustomThemeV2();

  if (!isMobileView) return <BuilderForm />;

  return (
    <div
      className={`${classes["view-section"]} ${
        activeView === "builder" ? classes.active : classes.inactive
      }`}
      style={{
        flex: 1,
        transition: "transform 0.3s ease-in-out",
      }}
    >
      <BuilderForm />
    </div>
  );
};
BuilderFormSwitched.displayName = "ResponsiveViewSwitcher.BuilderForm";
ResponsiveViewSwitcher.BuilderForm = BuilderFormSwitched;

// FormPreview component
const FormPreviewSwitched: SubComponentType = () => {
  const { activeView } = useMobileLayoutHandler();
  const classes = useStyles();
  const { isMobileView } = useCustomThemeV2();

  if (!isMobileView) return <FormPreview />;

  return (
    <div
      className={`${classes["view-section"]} ${
        activeView === "preview" ? classes.active : classes.inactive
      }`}
      style={{
        flex: 1,
        transition: "transform 0.3s ease-in-out",
      }}
    >
      <FormPreview />
    </div>
  );
};
FormPreviewSwitched.displayName = "ResponsiveViewSwitcher.FormPreview";
ResponsiveViewSwitcher.FormPreview = FormPreviewSwitched;

export const useMobileLayoutHandler = () => useContext(ViewSwitcherContext);

const useStyles = makeStyles({
  "view-section": {
    position: "absolute",
    width: "100%",
    height: "",
    top: 0,
    left: 0,
    opacity: 0,
    pointerEvents: "none",
  },

  active: {
    opacity: 1,
    pointerEvents: "auto",
    width: "100%",
  },

  inactive: {
    opacity: 0,
    width: 0,
  },
});
