import { RHFCheckbox } from "@common/Checkbox";
import { Text } from "@common/Text";
import { Tooltip } from "@common/Tooltip";
import { Box, BoxProps } from "@mui/material";
import { palette } from "@palette";
import { useState } from "react";

export const useScrollCheck = (isSigned: boolean) => {
  const [enabledAgreementCheckbox, setEnabledAgreementCheckbox] =
    useState(false);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    const scrolledHeight = Math.ceil(scrollTop + clientHeight);
    const totalScrollHeight = Math.ceil(scrollHeight);
    const tolerance = 2;
    if (
      scrolledHeight >= totalScrollHeight - tolerance &&
      !enabledAgreementCheckbox &&
      !isSigned
    ) {
      setEnabledAgreementCheckbox(true);
    }
  };

  const CheckboxWithTooltip = ({
    name,
    containerProps,
  }: {
    name: string;
    containerProps?: BoxProps;
  }) => {
    return (
      <Box {...containerProps}>
        <Tooltip
          title="Please read the terms of service agreement to enable this "
          maxWidth="410px"
          placement="top-start"
          disableHoverListener={isSigned || enabledAgreementCheckbox}
          parentStyle={{
            justifyContent: "start",
            display: "initial",
          }}
        >
          <RHFCheckbox
            name={name}
            dataTestId="agreement-confirmation-checkbox"
            label="Agreement Confirmation"
            helperText={<HelperText />}
            labelProps={{
              color: enabledAgreementCheckbox
                ? palette.neutral[80]
                : palette.neutral[70],
              fontSize: 14,
              fontWeight: "book",
              marginTop: "10px",
            }}
            disabled={!enabledAgreementCheckbox}
            formSx={{ alignItems: "start" }}
          />
        </Tooltip>
      </Box>
    );
  };
  return { handleScroll, CheckboxWithTooltip };
};

const HelperText = () => (
  <Text
    color={palette.gray[300]}
    fontSize="14px"
    fontWeight="book"
    mt="2px"
    onMouseMove={(e) => e.stopPropagation()}
    onMouseOver={(e) => e.stopPropagation()}
  >
    By checking this box, I acknowledge that I have read, understand, and agree
    to the terms and conditions of the Service Agreement, and confirm that our
    business adheres to all applicable Federal, State, and Local laws, as well
    as Card Brand rules and regulations.
  </Text>
);
