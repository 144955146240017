import { NameInput } from "@common/BusinessProfileInputs";
import BusinessTypeSelect from "@common/BusinessProfileInputs/BusinessTypeSelect";
import OwnershipTypeSelect from "@common/BusinessProfileInputs/OwnershipTypeSelect";
import { RHFInput, RHFTelInput } from "@common/Input";
import { Box, Grid, Stack } from "@mui/material";
import {
  ProfileSetupFormContainer,
  ProfileSetupFormActions,
  ProfileSetupFormTitle,
} from "../form.components";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { subDays } from "date-fns";
import CustomTaxSsnInput from "@common/BusinessProfileInputs/CustomTaxSsnInput";
import { BirthDatePicker } from "@common/DatePickers";
import moment from "moment";
import { phoneSchema, ssnSchema } from "@utils/validation.helpers";
import { gridItemsRenderer } from "@utils/rendering/nodesRenderers";
import usePercentageUpdate from "./hooks/usePercentageUpdate";
import { TBusinessStepsCommons } from "./BusinessProfileSetupNew";
import { DynamicReturnType } from "./helpers/refineData";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  CREATE_DENY_MESSAGE,
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import WarningBanner from "./WarningBanner";
import { useGetCurrentMerchantId } from "@hooks/common";
import { checkPortals } from "@utils/routing";

interface IProps extends TBusinessStepsCommons {
  canEdit: boolean;
  data: DynamicReturnType["businessDetails"];
  legalEntityId?: any;
  warningMessage: string | null;
  isIncomplete: boolean;
  getHelperText: (value: string) => string | undefined;
}

type TinType = "ssn" | "ein";

type IFormInputs = {
  legalName: string;
  DBA: string;
  tinType: TinType;
  taxIDNumber: string;
  ssn: string;
  businessType: string;
  ownershipType: string;
  phoneNumber: string;
  businessOpenedAt: string;
};

const ALLOW_EMPTY = {
  legalName: true,
  DBA: true,
  businessOpenedAt: true,
  taxIDNumber: true,
  phoneNumber: true,
};

const BusinessDetailsStep = forwardRef(
  (
    {
      handleBack,
      submitHandler,
      updateStatusBar,
      canEdit,
      data,
      isSubmitting,
      legalEntityId,
      warningMessage,
      isIncomplete,
      getHelperText,
    }: IProps,
    ref,
  ) => {
    const { name } = useGetCurrentMerchantId();
    const { isMerchantPortal } = checkPortals();

    const isAddLEAllowed = useAccessControl({
      resource: RESOURCE_BASE.LEGAL_ENTITY,
      operation: OPERATIONS.CREATE,
      withPortal: true,
    });

    const isUpdateLEAllowed = useAccessControl({
      resource: RESOURCE_BASE.LEGAL_ENTITY,
      operation: OPERATIONS.UPDATE,
      withPortal: true,
    });

    const hasNoPermissions =
      (legalEntityId && !isUpdateLEAllowed) ||
      (!legalEntityId && !isAddLEAllowed);
    const disableInput = !canEdit || hasNoPermissions;

    const getTooltipMessage = () => {
      if (legalEntityId && !isUpdateLEAllowed) {
        return EDIT_DENY_MESSAGE;
      } else if (!legalEntityId && !isAddLEAllowed) {
        return CREATE_DENY_MESSAGE;
      } else if (!canEdit) {
        return "Only controllers can edit the business profile";
      } else {
        return "";
      }
    };

    const methods = useForm<IFormInputs>({
      mode: "onChange",
      resolver: yupResolver(schema),
      defaultValues: data,
    });

    const {
      watch,
      formState: { dirtyFields, isDirty },
      handleSubmit,
      setValue,
    } = methods;

    const values = watch();

    const { businessOpenedAt, taxIDNumber, phoneNumber } = values || {};

    const phoneNumberValue =
      !phoneNumber || phoneNumber === "+1" ? "" : phoneNumber;

    usePercentageUpdate<IFormInputs>(
      values,
      dirtyFields,
      schema,
      updateStatusBar,
      ["id", values?.tinType === "ein" ? "ssn" : "ein"],
    );

    const onSubmit: SubmitHandler<IFormInputs> = (data) => {
      submitHandler("businessDetails", data, {
        makeApiCall: isDirty,
        dirtyFields: {
          ...dirtyFields,
          businessType: true,
          ownershipType: true,
        },
        forbidRedirect: false,
        allowEmpty: ALLOW_EMPTY,
      });
    };

    const handleForceSubmit = async (cb?: any, param?: any) => {
      await handleSubmit((data) => {
        submitHandler("businessDetails", data, {
          makeApiCall: isDirty,
          dirtyFields: {
            ...dirtyFields,
            businessType: true,
            ownershipType: true,
          },
          forbidRedirect: true,
          allowEmpty: ALLOW_EMPTY,
          handleNext: cb,
          param: param,
        });
      })();
    };

    const backSaveHandler = async () => {
      await handleForceSubmit(handleBack);
    };

    const inputs = [
      {
        node: (
          <NameInput
            name="legalName"
            label="Business legal name"
            placeholder="Business legal name"
            isLegalName
            disabled={disableInput}
          />
        ),
      },
      {
        node: (
          <RHFInput
            name="DBA"
            label="Doing Business As (Optional)"
            placeholder="Doing business as..."
            fullWidth
            disabled={disableInput}
          />
        ),
      },
      {
        node: (
          <BirthDatePicker
            name="businessOpenedAt"
            label="Business Creation Date (mm/dd/yyyy)"
            maxDate={subDays(new Date(), 1)}
            minDate={null}
            openPickerOnFocus
            disabled={disableInput}
            helper={getHelperText(businessOpenedAt)}
          />
        ),
      },
      {
        node: (
          <BusinessTypeSelect
            name="businessType"
            label="Business type"
            hasArrowDownIcon
            disabled={disableInput}
          />
        ),
      },
      {
        node: (
          <CustomTaxSsnInput
            taxIdName="taxIDNumber"
            ssnName="ssn"
            businessTypeName="businessType"
            tinType="tinType"
            disabled={disableInput}
            helperText={getHelperText(taxIDNumber)}
          />
        ),
      },
      {
        node: (
          <OwnershipTypeSelect
            name="ownershipType"
            label="Business ownership type"
            hasArrowDownIcon
            disabled={disableInput}
          />
        ),
      },
      {
        node: (
          <RHFTelInput
            name="phoneNumber"
            label="Business Phone Number"
            fullWidth
            disabled={disableInput}
            flagStyles={{
              width: "20px",
              height: "15px",
              borderRadius: 0,
            }}
            helperText={getHelperText(phoneNumberValue)}
          />
        ),
      },
    ];

    useImperativeHandle(ref, () => ({
      execute: handleForceSubmit,
    }));

    useEffect(() => {
      if (!isMerchantPortal) return;
      !data?.legalName &&
        name &&
        setValue("legalName", name, { shouldDirty: true });
    }, [data]);

    return (
      <FormProvider {...methods}>
        <Box
          component="form"
          flexGrow={1}
          id="business-profile-form"
          display="flex"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <ProfileSetupFormContainer>
            <Stack direction="column" gap={4} height="min-content">
              <ProfileSetupFormTitle title="Fill in your business details" />
              {isIncomplete && <WarningBanner message={warningMessage} />}
              <Grid container rowSpacing="12px">
                {gridItemsRenderer(inputs, {
                  show: disableInput,
                  message: getTooltipMessage(),
                })}
              </Grid>
            </Stack>

            <ProfileSetupFormActions
              secondaryAction={{
                onClick: backSaveHandler,
              }}
              primaryAction={{
                disabled: isSubmitting,
                form: "business-profile-form",
                children: "Next",
              }}
            />
          </ProfileSetupFormContainer>
        </Box>
      </FormProvider>
    );
  },
);

const schema = Yup.object().shape({
  legalName: Yup.string(),
  DBA: Yup.string(),
  businessOpenedAt: Yup.date()
    .nullable()
    .notRequired()
    .transform((value, originalValue) => {
      return !originalValue ? null : moment(value).toDate();
    })
    .typeError("Enter valid date")
    .max(new Date(), "Future date not allowed")
    .min(
      moment().subtract(500, "years").format("YYYY-MM-DD"),
      "Date is beyond acceptable range",
    ),
  tinType: Yup.mixed<TinType>().oneOf(["ssn", "ein"]),
  taxIDNumber: Yup.string().when("tinType", {
    is: "ein",
    then: ssnSchema("ein", false),
  }),
  ssn: Yup.string().when("tinType", {
    is: "ssn",
    then: ssnSchema("ssn", false),
  }),
  businessType: Yup.string(),
  ownershipType: Yup.string(),
  phoneNumber: phoneSchema(),
});

BusinessDetailsStep.displayName = "BusinessDetailsStep";

export default BusinessDetailsStep;
