import { Select } from "@common/Select";
import { palette } from "@palette";
import { useFormContext } from "react-hook-form";

type Props = {
  columnsIncluded: any;
  value: string;
};

const ColumnsInlcudedSelect = ({ columnsIncluded, value }: Props) => {
  const { setValue } = useFormContext();
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue("columnsIncluded", value, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };
  return (
    <Select
      label="Choose Type of Export"
      options={columnsIncluded}
      value={value || ""}
      onChange={onChange}
      isOpen={(open) => !open}
      isCaretIcon
      fullWidth
      selectItemProps={{
        showCheckIcon: true,
        hoverBgColor: palette.neutral[10],
      }}
      sxMenu={{
        maxHeight: "350px",
      }}
      styleMenu={{ backgroundColor: palette.neutral.white }}
    />
  );
};

export default ColumnsInlcudedSelect;
