import React, { memo } from "react";
import { HFGiveInput } from "@shared/HFInputs/HFGiveInput/HFGiveInput";

type NameInputProps = {
  id?: string;
  name: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  isLegalName?: boolean;
  isMerchantName?: boolean;
  autoComplete?: boolean;
  maxLength?: string;
  focusViewColor?: string;
  value?: string;
  helper?: string;
};

const NameInput = ({
  name,
  label,
  placeholder,
  disabled,
  isLegalName,
  isMerchantName,
  autoComplete,
  maxLength,
  ...rest
}: NameInputProps) => {
  return (
    <HFGiveInput
      name={name}
      label={label}
      disabled={disabled}
      fullWidth
      placeholder={placeholder}
      autoComplete={autoComplete ? "" : "null"}
      inputProps={{
        maxLength,
      }}
      handleNormalizeInput={(e) =>
        normalizeInput(e, isLegalName, isMerchantName)
      }
      {...rest}
    />
  );
};

export default memo(NameInput);

const DEFAULT_REGEX = /[~`!@#$%^*&()_={}[\]:;,.<>+?-]+|\d+|^\s+$/g;
const LEGAL_NAME_REGEX = /[~`!@#$%^*&()_={}[\]:;<>+?-]+|^\s+$/g;
export const MERCHANT_NAME_REGEX = /[^a-zA-Z0-9,.' ]/g;

const normalizeInput = (
  value: string,
  isLegalName?: boolean,
  isMerchantName?: boolean,
) => {
  if (!value) return value;
  const charsRegex = isLegalName
    ? LEGAL_NAME_REGEX
    : isMerchantName
    ? MERCHANT_NAME_REGEX
    : DEFAULT_REGEX;

  const currentValue = value.replace(charsRegex, "").replace(/\s+/g, " ");

  return currentValue;
};
