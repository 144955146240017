import {
  MerchantPreviewModalBody,
  MerchantPreviewModalProps,
} from "@components/Merchants/MerchantPreview/MerchantPreviewModal";
import NiceModal from "@ebay/nice-modal-react";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import NewPanel from "./NewPanel";
import MerchantSidePanelProvider from "./Provider/MerchantSidePanelProvider";
import { useAppSelector } from "@redux/hooks";
import { selectMerchantStatusName } from "@redux/slices/enterprise/merchants";
import { StatusNames } from "@components/Merchants/MerchantList/MerchantStatusFilters";
import { useState } from "react";

const NewMerchantSidePanel = NiceModal.create(
  (props: MerchantPreviewModalProps) => {
    const [openOldPanel, setOpenOldPanel] = useState<boolean>(false);

    const statusName = useAppSelector(selectMerchantStatusName);
    const isRiskTab = statusName === StatusNames.aquirerRisk;
    const { isRebrandingEnabled, isSidePanelRebrandingEnabled } =
      useGetFeatureFlagValues();

    return isRebrandingEnabled &&
      isSidePanelRebrandingEnabled &&
      !isRiskTab &&
      !openOldPanel ? (
      <MerchantSidePanelProvider {...props} setOpenOldPanel={setOpenOldPanel}>
        <NewPanel />
      </MerchantSidePanelProvider>
    ) : (
      <OldPanel {...props} />
    );
  },
);

const OldPanel = (props: MerchantPreviewModalProps) => {
  return <MerchantPreviewModalBody {...props} />;
};

export default NewMerchantSidePanel;
