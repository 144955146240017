import { Stack } from "@mui/material";
import { RHFSelect } from "@common/Select";
import { palette } from "@palette";
import { CaretDown } from "@phosphor-icons/react";
import { RHFInput } from "@common/Input";
import { UploadFile } from "@components/UploadFile";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import UploadedFiles from "@components/Disputes/RespondToDispute/components/UploadedFiles";
import { IDropzoneProps } from "react-dropzone-uploader";
import { useFileUploadContext } from "@components/UploadFile/FileUploadContext";
import { FileUploadStatus } from "@components/UploadFile/types";
import { useFormContext } from "react-hook-form";

export const TEST_CASE_ACTION_LISTS = [
  { value: "Submit Transaction Details", label: "Submit Transaction Details" },
  { value: "Destroy Evidence", label: "Destroy Evidence" },
  { value: "Sue Merchant", label: "Sue Merchant" },
];

type CaseActionType = {
  code: string;
  displayName: string;
  name: string;
  status: string;
};

type Props = {
  caseActions: CaseActionType[];
};

const RespondToDisputeModalBody = ({ caseActions }: Props) => {
  const { getValues, setValue } = useFormContext();
  const { setSnackbarFiles } = useFileUploadContext();

  const caseActionOptions = caseActions?.map((action) => ({
    value: action?.name,
    label: action?.displayName,
  }));

  const handleFileSelect: IDropzoneProps["onChangeStatus"] = (
    iFile,
    status,
  ) => {
    const currentFiles = getValues().caseFiles;
    if (["error_file_size", "rejected_file_type"].includes(status)) {
      const { id, name, size } = iFile.meta;
      setSnackbarFiles([
        {
          id,
          name,
          size,
          status:
            status === "error_file_size"
              ? FileUploadStatus.FILE_TOO_LARGE
              : FileUploadStatus.FILE_NOT_SUPPORTED,
          uploadProgress: 0,
          canBeDeleted: true,
        },
      ]);
      return;
    }

    if (status === "preparing") return;

    setValue("caseFiles", [{ file: iFile.file }, ...currentFiles]);
  };

  return (
    <FadeUpWrapper delay={50}>
      <Stack px={2} pb={3} gap={2}>
        <RHFSelect
          label="Case Action"
          placeholder="Case Action"
          options={caseActionOptions}
          name="caseAction"
          sx={{
            "& .MuiInputBase-root": fieldSx,
          }}
          DropIcon={
            <CaretDown width={24} height={24} color={palette.neutral[70]} />
          }
        />
        <RHFInput
          label="Case Notes"
          fullWidth
          rows={6}
          multiline
          name="caseNotes"
          InputProps={{
            sx: fieldSx,
          }}
        />
        <UploadFile
          backgroundColor="white"
          onChangeStatus={handleFileSelect}
          width="100%"
          customText="1200 x 1200 (1:1) recommended, up to 5MB each."
          accept="image/png, image/jpeg, application/pdf"
        />
        <UploadedFiles />
      </Stack>
    </FadeUpWrapper>
  );
};

const fieldSx = { borderWidth: "1px !important" };

export default RespondToDisputeModalBody;
