import { Text } from "@common/Text";
import { useMemo } from "react";
import {
  useSidePanelHeaderContext,
  type IButtonComponent,
} from "./SidePanelHeaderContext";
import { capitalizeFirstLetter } from "@utils/index";

export const SidePanelName = () => {
  const { sidePanelName = "" } = useSidePanelHeaderContext();
  const computedSidePanelName = useMemo(() => {
    return sidePanelName
      .toLowerCase()
      .split(" ")
      .map(capitalizeFirstLetter)
      .join(" ");
  }, [sidePanelName]);
  return <Text fontSize="16px">{computedSidePanelName}</Text>;
};

export const SidePanelButton = ({ el }: { el: keyof IButtonComponent }) => {
  const { ButtonComponent } = useSidePanelHeaderContext();
  return ButtonComponent[el];
};
