import { useEffect, useState, useRef, useMemo } from "react";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import ProfileSetupHome from "./ProfileSetupHome/ProfileSetupHome";
import { useGetCurrentMerchantId } from "@hooks/common";
import profileSetupImage from "@assets/images/profile_setup.png";
import BankAccountSetup from "./BankAccountSetup/BankAccountSetup";
import MerchantAgreementSetup from "./MerchantAgreementSetup/MerchantAgreementSetup";
import { MerchantType } from "@customTypes/merchant.preview.types";
import { HomepageCardStatus } from "./ProfileSetupHome/HomepageCard";
import { useQueryClient } from "react-query";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import PersonalInformationProvider from "./PersonalInformation/Provider/PersonalInformationProvider";
import EnterpriseAgreement from "./EnterpriseAgreement/EnterpriseAgreement";
import { checkPortals } from "@utils/routing";
import { THomepageOption } from "./types";
import BusinessProfileSetupNew from "./BusinessProfileSetupNew/BusinessProfileSetupNew";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import BusinessProfileSetup from "./BusinessProfileSetup/BusinessProfileSetup";
import { refineData } from "./BusinessProfileSetupNew/helpers/refineData";
import useGenerateWarning from "./BusinessProfileSetupNew/hooks/useGenerateWarning";
import { useGetBOFiles } from "@hooks/merchant-api/business-owner/useGetBOFiles";

type Props = {
  backToWelcomePage: () => void;
  matchTaxID?: boolean;
  hasPrincipal: boolean;
  legalEntity?: any;
  merchant: MerchantType["merchant"];
  bankAccountStatus: HomepageCardStatus;
  bankAccountsList: any;
  businessDetailsStatus: HomepageCardStatus;
  msaStatus: HomepageCardStatus;
  pahStatus: HomepageCardStatus;
};

const ProfilePageContainer = ({
  backToWelcomePage,
  legalEntity,
  bankAccountsList,
  merchant,
  ...props
}: Props) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const { merchantId } = useGetCurrentMerchantId();
  const queryClient = useQueryClient();
  const { isEnterprisePortal } = checkPortals();
  const { isNewOnboardingEnabled } = useGetFeatureFlagValues();

  const [isAnimation, setIsAnimation] = useState(false);
  const [currentSection, setCurrentSection] = useState<THomepageOption | "">(
    "",
  );
  const imageWrapperRef = useRef<HTMLDivElement | null>(null);
  const { data: documents = {} } = useGetBOFiles({ enabled: true });

  const chooseSection = (section: THomepageOption) => {
    if (isAnimation) return;
    setIsAnimation(true);
    setTimeout(() => {
      setCurrentSection(section);
      setIsAnimation(false);
    }, 1000);
  };

  const homepageReset = () => {
    setCurrentSection("");
    queryClient.removeQueries("get-bank-files");
    queryClient.refetchQueries("list-all-bank-accounts");
    if (imageWrapperRef.current) {
      imageWrapperRef.current.style.transition = "transform 1.1s ease-in";
    }
  };

  useEffect(() => {
    if (isAnimation) {
      setTimeout(() => {
        if (imageWrapperRef.current)
          imageWrapperRef.current.style.transition = "none";
      }, 1000);
    }
  }, [isAnimation]);

  const firstAccount =
    bankAccountsList?.length > 0 ? bankAccountsList[0] : null;

  const parsedData = useMemo(
    () => refineData({ legalEntity, merchant, documents }),
    [legalEntity, merchant, documents],
  );

  const { isIncomplete, warningMessage, completionStatus } = useGenerateWarning(
    { parsedData },
  );

  const pages = {
    business_details:
      isNewOnboardingEnabled && !isEnterprisePortal ? (
        <BusinessProfileSetupNew
          backLink={homepageReset}
          data={{
            legalEntity,
            merchant,
          }}
          parsedData={parsedData}
          warningMessage={warningMessage}
          isIncomplete={isIncomplete}
          completionStatus={completionStatus}
        />
      ) : (
        <BusinessProfileSetup
          backLink={homepageReset}
          data={{
            legalEntity,
            merchant,
          }}
        />
      ),
    bank_account: (
      <BankAccountSetup
        backLink={homepageReset}
        merchantId={merchantId}
        firstAccount={firstAccount}
      />
    ),
    merchant_agreement: isEnterprisePortal ? (
      <EnterpriseAgreement backLink={homepageReset} data={merchant} />
    ) : (
      <MerchantAgreementSetup backLink={homepageReset} />
    ),

    enterprise_verify_identity: (
      <PersonalInformationProvider homepageReset={homepageReset}>
        <PersonalInformation />
      </PersonalInformationProvider>
    ),
  };
  return (
    <Stack direction="row" gap={5} justifyContent="center" height="inherit">
      {!currentSection && (
        <ProfileSetupHome
          chooseSection={chooseSection}
          isAnimation={isAnimation}
          backToWelcomePage={backToWelcomePage}
          taxID={legalEntity?.taxIDNumber}
          isIncomplete={isIncomplete}
          {...props}
        />
      )}
      {isDesktop && (
        <Box
          alignSelf="stretch"
          ref={imageWrapperRef}
          sx={{
            borderRadius: "16px",
            position: "sticky",
            transition: "transform 1.1s ease-in",
            transform: `translateX(${isAnimation ? "-130%" : 0})`,
            height: "inherit",
            maxWidth: "100%",
          }}
        >
          <img
            src={profileSetupImage}
            alt="Profile Setup"
            height="100%"
            style={{ objectFit: "cover" }}
          />
        </Box>
      )}
      {currentSection && pages[currentSection]}
    </Stack>
  );
};

export default ProfilePageContainer;
