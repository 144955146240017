import { SkeletonOwnProps } from "@mui/material";

export const MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS = {
  GET: "get-merchant-preview",
  GET_RISK_CATEGORY: "get-merchant-risk-category",
};

export const BANK_DETAILS = [
  {
    name: "RS2 Software INC",
    addr1: "4643 S Ulster St Ste 1285 Denver,",
    addr2: "CO, 80237-3090 United States",
  },
  {
    name: "Chesapeake Bank",
    addr1: "97 N. Main Street",
    addr2: "Kilmarnock, VA 22482",
  },
];

export const LastUpdatedOn_merchant_agreement = "June 10,2023";
export const skeletonDefaultProps: SkeletonOwnProps = {
  sx: { borderRadius: "16px" },
  variant: "rectangular",
};
