import { useAppSelector } from "@redux/hooks";
import { selectDateFilter } from "@redux/slices/merchantFilters";
import { selectedQueryString } from "@redux/slices/tableFilters";
import { addDays, getUnixTime } from "date-fns";
import { useMemo } from "react";

export const useDisputesQueryString = () => {
  const selectedDate = useAppSelector((state) =>
    selectDateFilter(state, "dispute-date"),
  );
  const contextQueryString = useAppSelector(selectedQueryString);

  const queryString = useMemo(() => {
    let query = "";

    if (selectedDate) {
      const unixDate = selectedDate ? getUnixTime(new Date(selectedDate)) : 0;
      const maxDate = selectedDate
        ? getUnixTime(addDays(new Date(selectedDate), 1))
        : 0;

      query = `(createdAt:>d${unixDate}%3BcreatedAt:<=d${maxDate})%3B`;
    }

    if (contextQueryString) query += contextQueryString;

    return query;
  }, [selectedDate, contextQueryString]);

  return { queryString };
};
