import React from "react";
import { Typography, TypographyProps } from "@mui/material";

export type FontWeight =
  | "light"
  | "regular"
  | "medium"
  | "semibold"
  | "bold"
  | "book";

export interface ITextProps extends TypographyProps {
  /**
   * Font weight of the text
   */
  textRef?: React.RefObject<any>;
  fontWeight?: FontWeight | number;
  href?: string;
  gradient?: string;
}

export const Text: React.FC<ITextProps> = ({
  fontWeight,
  variant,
  children,
  href,
  gradient,
  textRef,
  ...rest
}) => {
  //defaults to regular weight if no fontWeight passed
  const fontWeightPassed = !!fontWeight;
  if (!fontWeight) {
    fontWeight = "regular";
  }

  // if button or link variant and no fontWeight passed, defaults to medium
  const makeMedium =
    !fontWeightPassed && (variant === "button" || variant === "buttonSmall");
  if (makeMedium) {
    fontWeight = "medium";
  }

  const weight =
    typeof fontWeight === "number" ? fontWeight : fontWeightsMap[fontWeight];

  const linkProps =
    variant === "link"
      ? {
          component: "a",
          href: href,
        }
      : {};

  const textGradient = gradient
    ? {
        background: gradient,
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
      }
    : {};

  return (
    <Typography
      ref={textRef}
      {...linkProps}
      fontWeight={weight}
      variant={variant}
      {...rest}
      sx={{
        ...textGradient,
        ...rest.sx,
      }}
    >
      {children}
    </Typography>
  );
};

const fontWeightsMap = {
  light: 300,
  book: 350,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};
