import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { RadioFormSection } from "./atoms";
import { Colum_types } from "../types";

type Props = {
  isFilter: boolean;
  totalRows: number;
  filteredRows: number;
  column_type: Colum_types;
};

const ExportTypeSection = ({
  isFilter,
  totalRows,
  column_type,
  filteredRows,
}: Props) => {
  const getFilteredTextHelper = () => {
    if (
      (column_type === "merchant" || column_type === "settlement") &&
      isFilter
    ) {
      return `${filteredRows} row${
        filteredRows > 1 ? "s" : ""
      } of data will be exported`;
    }
    return "Please apply a filter first to use this feature";
  };

  const types = [
    {
      title: "All data",
      helper: `${totalRows} row${
        totalRows > 1 ? "s" : ""
      } of data will be exported`,
      value: "all",
    },
    {
      title: "Only filtered data",
      helper: getFilteredTextHelper(),
      value: "filtered",
      disabled: !isFilter,
    },
  ];

  return (
    <FadeUpWrapper delay={100}>
      <RadioFormSection title="Export type" name="exportType" radios={types} />
    </FadeUpWrapper>
  );
};

export default ExportTypeSection;
