type IconProps = {
    height?: number;
    width?: number;
    stroke?: string;
  };
  
  const LinkedinIcon = ({
    width = 49,
    height = 48,
    stroke = "#A9AFBD",
  }: IconProps) => {
    return (
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="48" height="48" rx="24" fill="#0B66C2" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.5833 14.25H16.4167C15.2205 14.25 14.25 15.2197 14.25 16.4167V31.5833C14.25 32.7795 15.2197 33.75 16.4167 33.75H31.5833C32.7795 33.75 33.75 32.7803 33.75 31.5833V16.4167C33.75 15.2205 32.7803 14.25 31.5833 14.25ZM20.1458 31.0417H17.238V21.6525H20.1458V31.0417ZM18.678 20.4233C17.7278 20.4233 16.9583 19.6477 16.9583 18.6907C16.9583 17.7337 17.7278 16.9583 18.678 16.9583C19.6283 16.9583 20.397 17.7337 20.397 18.6907C20.397 19.6477 19.6275 20.4233 18.678 20.4233ZM31.0417 31.0417H28.1483V26.1135C28.1483 24.762 27.6345 24.0068 26.565 24.0068C25.4017 24.0068 24.7935 24.7928 24.7935 26.1135V31.0417H22.005V21.6525H24.7935V22.917C24.7935 22.917 25.632 21.3653 27.6248 21.3653C29.6175 21.3653 31.0417 22.581 31.0417 25.0965V31.0417Z"
          fill="white"
        />
      </svg>
    );
  };
  
  export default LinkedinIcon;
  