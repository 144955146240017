import { palette } from "@palette";
import {
  useMediaQuery,
  useTheme,
  Stack,
  styled,
  Box,
  BoxProps,
} from "@mui/material";
import { Bank, HandCoins } from "@phosphor-icons/react";
import { Tooltip } from "@common/Tooltip";
import { useMemo } from "react";
import { useAppDispatch } from "@redux/hooks";
import { setOpeningOptions } from "@redux/slices/merchantPreview";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";

const DISPLAY_BREAKPOINT = 1240;

type UnderwritingLevelProps = {
  score?: number;
  statusDisplayName: string;
  canTransferMoney: boolean;
  canProcessMoney: boolean;
  pendingScore: number;
  pendingChallenges: number;
  onClick?: (e: any) => void;
  alwaysDisplayProgressBars?: boolean;
  hideIcons?: boolean;
};

const UnderwritingLevel = ({
  score,
  statusDisplayName,
  canProcessMoney,
  canTransferMoney,
  pendingScore,
  pendingChallenges,
  alwaysDisplayProgressBars = false,
  hideIcons = false,
  onClick,
}: UnderwritingLevelProps) => {
  const theme = useTheme();
  const isDisplayed = useMediaQuery(theme.breakpoints.up(DISPLAY_BREAKPOINT));
  statusDisplayName === "Pre Approved" ? "Underwriting" : statusDisplayName;

  const hoverProgressBarMessage = `Score: ${score}% (${pendingChallenges} Pending Challenges)`;

  return (
    <Stack
      minWidth="100px"
      direction="row"
      flexGrow={1}
      alignItems="center"
      gap={4}
      onClick={onClick}
    >
      {(isDisplayed || alwaysDisplayProgressBars) && (
        <Tooltip
          title={hoverProgressBarMessage}
          fullWidth
          maxWidth="270px"
          data-testid="Tooltip-Progressbar"
        >
          <Progress score={score || 0} pendingScore={pendingScore} />
        </Tooltip>
      )}
      {!hideIcons && (
        <Stack direction="row" spacing={1}>
          <CanManageMoneyIcons
            canProcessMoney={canProcessMoney}
            canTransferMoney={canTransferMoney}
          />
        </Stack>
      )}
    </Stack>
  );
};

type CanManageMoneyIconsProps = {
  canProcessMoney: boolean;
  canTransferMoney: boolean;
};
export const CanManageMoneyIcons = ({
  canProcessMoney,
  canTransferMoney,
}: CanManageMoneyIconsProps) => (
  <>
    <StatusTooltip icon={HandCoins} type="process" value={canProcessMoney} />
    <StatusTooltip icon={Bank} type="transfer" value={canTransferMoney} />
  </>
);

export const StatusTooltip = ({
  icon: Icon,
  type,
  value,
}: {
  type: "process" | "transfer";
  value: boolean;
  icon: any;
}) => {
  const hoverColor = value ? palette.filled.success : palette.neutral[60];

  const tooltip =
    type === "process"
      ? `Can${value ? "" : "'t"} accept payments`
      : `Can${value ? "" : "'t"} send money`;

  return (
    <Tooltip title={tooltip} placement="top" data-testid="Tooltip-Status">
      <Box display="flex" sx={{ "&:hover *": { fill: hoverColor } }}>
        <Icon
          weight="duotone"
          size={18}
          color={value ? palette.filled.success : palette.neutral[30]}
        />
      </Box>
    </Tooltip>
  );
};

const Progress = ({
  score,
  pendingScore,
}: {
  score: number;
  pendingScore: number;
}) => {
  const dispatch = useAppDispatch();
  const { merchant_underwriting } = useEnterprisePermissions();
  const { isViewUnderwritingAllowed } = useUnderwriterPermissions();
  const colors = useMemo(() => {
    if (!score || score < 80) {
      return {
        background: palette.neutral[10],
        active: palette.neutral[100],
        circle: palette.neutral[70],
        pending: palette.gray[100],
      };
    }

    return {
      background: "#E9E9E9",
      active: palette.filled.success,
      circle: palette.tag.success.bg,
      pending: "transparent",
    };
  }, [score]);

  const isUnderwritingDisabled =
    !merchant_underwriting || !isViewUnderwritingAllowed;

  return (
    <ProgressBar
      onClick={() => {
        dispatch(setOpeningOptions({ underwriter: true }));
      }}
      data-testid="underwriting-progress-bar"
      bg={colors.background}
      disabled={isUnderwritingDisabled}
    >
      <ProgressBar
        bg={colors.active}
        width={`${score || 0}%`}
        sx={{ zIndex: 2 }}
      />
      <ProgressBar
        bg={colors.pending}
        width={`${score + pendingScore || 0}%`}
        sx={{ position: "absolute", left: 0, top: 0, zIndex: 1 }}
      />
      <ProgressCircle bg={colors.circle} sx={{ zIndex: 3 }} />
    </ProgressBar>
  );
};

const ProgressBar = styled(Box)<BoxProps & { bg: string; disabled?: boolean }>(
  ({ bg, disabled }) => ({
    height: "8px",
    borderRadius: "32px",
    flexGrow: 1,
    background: bg,
    position: "relative",
    overflow: "hidden",
    ...(disabled && { pointerEvents: "none" }),
  }),
);

const ProgressCircle = styled(Box)<BoxProps & { bg: string }>(({ bg }) => ({
  width: "4px",
  height: "4px",
  borderRadius: "50%",
  background: bg,
  position: "absolute",
  right: "20%",
  top: "2px",
}));

export default UnderwritingLevel;
