import { Button } from "@common/Button";
import { RHFInput } from "@common/Input";
import { RHFSelect } from "@common/Select";
import { Text } from "@common/Text";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { Box, Grid, Stack, styled } from "@mui/material";
import { ArrowRight } from "@phosphor-icons/react";
import { FormProvider } from "react-hook-form";
import { RHFCheckbox } from "@common/Checkbox";
import MessageSection from "./MessageSection";
import CreateTopicButton from "./Sections/CreateTopicButton";
import { Thread } from "./types";
import { isEmpty } from "lodash";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { challengesWithCustomRejectedIcon } from "@components/Merchants/MerchantPreview/manageUnderwriting/Challenges/ChallengeLayout";
import {
  ExtendedTChallengeStatus,
  IconStatusMapper,
} from "@components/Merchants/MerchantPreview/manageUnderwriting/Challenges/ChallengeHeader";
import useMarkAsSolved from "../hooks/useMarkAsSolved";
import useUnderwritingThreads from "../hooks/useUnderwritingThreads";
import useGetThreadMessages from "../hooks/useGetThreadMessages";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  isEnterprise?: boolean;
  merchantID: number;
  thread: Thread | undefined;
  topicData: any;
  merchantName?: string;
  handleOpenManageUnderwriting?: () => void;
};

function UnderwritingThreads({
  isEnterprise = false,
  merchantID,
  thread,
  topicData,
  merchantName,
  handleOpenManageUnderwriting,
}: Props) {
  const isRiskMonitor = thread?.topicName === "risk_monitor";
  const isThreadSelected = !isEmpty(thread);

  const isThreadClosed = [
    thread?.challenge?.statusName,
    thread?.statusName,
  ].includes("closed");

  const isReadyForVerification = isRiskMonitor
    ? thread?.statusName === "open" && thread?.didSubjectAccReply === true
    : thread?.challenge?.statusName === "ready_for_verification";

  const isThreadClosedRiskMonitor = isThreadClosed && isRiskMonitor;

  const { messages, isLoading, isMessageResponse, refetch } =
    useGetThreadMessages({
      merchantID,
    });

  const { disableSubmit, isRejection, methods, handleSubmit, hideInput } =
    useUnderwritingThreads({
      isThreadSelected,
      refetch,
      merchantName,
      isThreadClosed,
      isRiskMonitor,
      merchantID,
    });

  const values = methods.watch();

  const nodeList = [
    {
      node: (
        <RHFSelect
          label="Choose Module"
          name="module"
          options={getModules(topicData)}
        />
      ),
      hidden: hideInput.module,
    },
    {
      node: (
        <RHFInput
          name="subject"
          fullWidth
          placeholder="Enter Subject"
          type="text"
          label="Subject"
        />
      ),
      hidden: hideInput.subject,
    },
    {
      node: (
        <RHFInput
          name="message"
          label="Message"
          placeholder="Message"
          multiline
          rows={6}
          fullWidth
        />
      ),
      hidden: hideInput.message,
    },
  ];

  const { isMobileView } = useCustomTheme();
  const challengeId = thread?.challenge?.id || 0;

  const { mutate: mutateMarkSolved } = useMarkAsSolved({
    ownerAccID: thread?.ownerAccID || 0,
    threadId: thread?.id || 0,
    merchantId: merchantID,
    challengeId,
    isRiskMonitor,
  });
  const showNoticeCard = (isRiskMonitor && isThreadClosed) || !isRiskMonitor;

  if (isLoading) {
    return (
      <Stack
        height="100%"
        justifyContent="space-between"
        flexDirection="column"
        component="form"
        overflow="hidden"
      >
        <LoadingSpinner />
      </Stack>
    );
  }
  return (
    <FormProvider {...methods}>
      <Stack
        height="100%"
        justifyContent="space-between"
        flexDirection="column"
        component="form"
        overflow="hidden"
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        <Box
          style={{
            overflowY: "auto",
            maxHeight: isMobileView ? "100%" : "92%",
            overflowX: "hidden",
          }}
        >
          {showNoticeCard && (
            <FadeUpWrapper delay={50}>
              <NoticeCard
                thread={thread}
                isEnterprise={isEnterprise}
                isRejection={isRejection}
                isThreadClosedRiskMonitor={isThreadClosedRiskMonitor}
                handleOpenManageUnderwriting={handleOpenManageUnderwriting}
              />
            </FadeUpWrapper>
          )}

          {isRejection && (
            <Box p="16px">
              <Text fontWeight="book" fontSize="14px" color="#575353">
                Default Reason
              </Text>
              <Text mt="8px" fontWeight="book" fontSize="14px" color="#8F8F8F">
                The default reason can be provided to the merchant, in addition
                to the specific message you have inserted
              </Text>
              <Box mt="12px" borderRadius="8px" p="12px 16px" bgcolor="#ECECE9">
                <Text fontWeight="book" color="#575353" fontSize="14px">
                  {messages?.[0]?.body}
                </Text>
                <Text
                  mt="16px"
                  fontWeight="book"
                  color="#575353"
                  fontSize="14px"
                >
                  {values?.message}
                </Text>
              </Box>
            </Box>
          )}

          <Box p="16px">
            <Grid container spacing={2}>
              {nodeList.map(({ node, hidden }, index) => {
                if (hidden) return;
                return (
                  <Grid key={index} item xs={12}>
                    <FadeUpWrapper delay={100 + 50 * (index + 1)}>
                      {node}
                    </FadeUpWrapper>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          {isMessageResponse && (
            <>
              {messages?.map((message, idx) => (
                <FadeUpWrapper key={message?.id} delay={100 + 50 * (idx + 1)}>
                  <MessageSection
                    isEnterprise={isEnterprise}
                    merchantID={merchantID}
                    message={message}
                    showDivider={!(idx === 0 && isThreadClosedRiskMonitor)}
                  />
                </FadeUpWrapper>
              ))}
            </>
          )}
        </Box>
        {thread?.didSubjectAccReply &&
          isReadyForVerification &&
          !isRejection && (
            <CreateTopicButton
              handleOpenTopicModal={() => {
                mutateMarkSolved();
              }}
              sx={{
                bottom: 40,
              }}
              buttonText="Mark as solved"
            />
          )}
        {!isThreadClosedRiskMonitor && (
          <Stack
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            p="8px 16px"
            boxShadow="0px -2px 10px 0px rgba(76, 76, 76, 0.1)"
            bgcolor="#ffffff"
          >
            <RHFCheckbox
              dataTestId="internal_note_check_box"
              name="isInternalNote"
              label="Internal Note"
            />
            <CustomButton
              disabled={disableSubmit}
              background="tertiary"
              variant="text"
              type="submit"
              data-testid="post-conversation-button"
            >
              Post
            </CustomButton>
          </Stack>
        )}
      </Stack>
    </FormProvider>
  );
}

export default UnderwritingThreads;

type NoticeCardProps = {
  thread: Thread | undefined;
  handleOpenManageUnderwriting?: () => void;
  isEnterprise: boolean;
  isRejection?: boolean;
  isThreadClosedRiskMonitor?: boolean;
};

const NoticeCard = ({
  thread,
  handleOpenManageUnderwriting,
  isEnterprise,
  isRejection = false,
  isThreadClosedRiskMonitor,
}: NoticeCardProps) => {
  const isThreadSelected = !isEmpty(thread) && !isRejection;

  const location = useLocation();
  const navigate = useNavigate();

  const color = !isThreadSelected ? "#FFF2E9" : "#E6EAF2";
  const challengeStatus = thread?.challenge?.statusName;

  const text = isThreadClosedRiskMonitor
    ? "This topic is closed. Please start a new topic to notify the merchant."
    : isThreadSelected
    ? thread?.title
    : `Please note: You are messaging the  ${
        isEnterprise ? "Provider" : "merchant"
      } `;

  const iconStatus = !isThreadSelected
    ? "create"
    : challengesWithCustomRejectedIcon.includes(
        thread?.challenge?.slug as any,
      ) && challengeStatus === "open"
    ? "rejected"
    : challengeStatus !== "rejected"
    ? challengeStatus
    : null;

  const navigateAndFocusEDD = () => {
    if (!handleOpenManageUnderwriting) return;
    handleOpenManageUnderwriting();
    navigate({
      ...location,
      search: `?focused-challenge-id=${thread?.challenge.id}`,
    });
  };

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      bgcolor={color}
      justifyContent="space-between"
    >
      <Stack
        gap="8px"
        alignItems="center"
        flexDirection="row"
        width="100%"
        p="8px 16px"
      >
        {iconStatus
          ? IconStatusMapper[iconStatus as ExtendedTChallengeStatus]
          : null}

        <Text color="#575353" fontWeight="regular" fontSize="14px">
          {text}
        </Text>
      </Stack>
      {isThreadSelected && !isThreadClosedRiskMonitor && (
        <Stack
          sx={{
            cursor: "pointer",
          }}
          pr="25px"
          gap="3px"
          alignItems="center"
          flexDirection="row"
          onClick={navigateAndFocusEDD}
        >
          <Text fontWeight="regular" color="#575353" fontSize="14px">
            Open
          </Text>
          <Box sx={{ display: "inline-block", transform: "rotate(-45deg)" }}>
            <ArrowRight color="#575353" size={25} />
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

const getModules = (data: any) => {
  return data?.map((item: any) => ({
    label: item?.displayName,
    value: item?.id,
  }));
};

const CustomButton = styled(Button)(() => ({
  color: "#9EC8FF",
}));
