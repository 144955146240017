import { useCallback, useState } from "react";

export const useMentions = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  const setMentionedUser = (user: string) => {
    setValue((comment: any) => (comment ? `${comment} ${user}` : `${user}`));
  };

  const onChange = useCallback(
    (_: any, newValue: any) => {
      setValue(newValue);
    },
    [setValue],
  );
  const onAdd = (...args: any) => {
    setValue((v: string) => `${v}`);
  };

  return {
    sourceValue: value,
    onSourceChange: onChange,
    onSourceAdd: onAdd,
    setMentionedUser,
  };
};
