import { Badge, styled } from "@mui/material";
import { getRiskColor } from "../utils";
import { Text } from "@common/Text";
import { RiskProfileShield } from "@assets/rebrandIcons";
import { Stack } from "@mui/material";

type Props = {
  level: number;
  onClick: () => void;
  isInvisibleBadge?: boolean;
};

const RiskProfileLevel = ({ level, onClick, isInvisibleBadge }: Props) => {
  const { color, border, badge, badgeAnimation } = getRiskColor(level);
  const animation = "pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1)";

  return (
    <Badge
      variant="dot"
      invisible={isInvisibleBadge ?? level < 2}
      sx={{
        "& .MuiBadge-badge": {
          backgroundColor: badge,
          MozAnimation: animation,
          msAnimation: animation,
          animation,
          badgeAnimation,
        },
      }}
    >
      <Container
        border={border}
        onClick={onClick}
        data-testid="risk-level-button"
      >
        <StyledText color={color}>Risk Level</StyledText>
        <StyledText color={color} isNumber>
          <RiskProfileShield stroke={color} /> {level}
        </StyledText>
      </Container>
    </Badge>
  );
};

const Container = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "border",
})<{ border?: string }>(({ border }) => ({
  flexDirection: "row",
  alignItems: "center",
  gap: "4px",
  borderRadius: "4px",
  padding: "4px 8px",
  userSelect: "none",
  cursor: "pointer",
  ...(border && {
    border: `1px solid ${border}`,
  }),
}));

const StyledText = styled(Text, {
  shouldForwardProp: (prop) => prop !== "isNumber",
})<{
  isNumber?: boolean;
}>(({ isNumber = false }) => ({
  fontSize: isNumber ? "18px" : "14px",
  fontWeight: 400,
  lineHeight: isNumber ? "21.6px" : "16.8px",
  ...(isNumber && {
    letterSpacing: "-0.18px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  }),
}));

export default RiskProfileLevel;
