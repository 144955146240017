import React, { useEffect } from "react";
import { Checkbox } from "../Checkbox";
import { useBulkMarkMerchants } from "@components/AcquirerMerchants/hooks/useBulkMarkMerchants";
import { useAppDispatch } from "@redux/hooks";
import { resetMarkedMerchants } from "@redux/slices/enterprise/merchants";
import useCheckSponsor from "@hooks/common/useCheckSponsor";
import { CustomToolTip } from "../BusinessOwners/CustomToolTip";
import { ACTION_DENY_MESSAGE } from "@constants/permissions";
import { Box } from "@mui/material";
import { useGetBackgroundTaskStatus } from "@components/AcquirerMerchants/hooks/useGetBackgroundTaskStatus";
const MarkAllCheckBox = () => {
  const { isMarkAllChecked, toggleBulkMark, stats } = useBulkMarkMerchants();
  const dispatch = useAppDispatch();
  const { hasSponsorAccess } = useCheckSponsor();
  useGetBackgroundTaskStatus({ enabled: true });

  useEffect(() => {
    // reset marked merchants on page exit
    return () => {
      dispatch(resetMarkedMerchants());
    };
  }, []);

  return (
    <CustomToolTip
      message={ACTION_DENY_MESSAGE}
      showToolTip={!hasSponsorAccess}
    >
      <Box onClick={(e) => e.stopPropagation()}>
        <Checkbox
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            toggleBulkMark && toggleBulkMark(e.target.checked);
          }}
          disabled={
            !hasSponsorAccess || stats?.merchantSponsorReadyForReviewCount === 0
          }
          aria-disabled={false}
          checked={isMarkAllChecked}
          data-testid="mark-all-checkbox"
        />
      </Box>
    </CustomToolTip>
  );
};
export default React.memo(MarkAllCheckBox);
