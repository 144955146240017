import React, { createContext, useContext } from "react";
import useManagePayFormProvider from "./useManagePayFormProvider";
import { FormProvider } from "react-hook-form";

type PayBuilderContextTypes = ReturnType<typeof useManagePayFormProvider>;

const FormContext = createContext<PayBuilderContextTypes>({} as any);
function PayBuilderFormProvider({ children }: { children: React.ReactNode }) {
  const values = useManagePayFormProvider();
  const { methods } = values;
  return (
    <FormContext.Provider value={values}>
      <FormProvider {...methods}>{children}</FormProvider>
    </FormContext.Provider>
  );
}

export default PayBuilderFormProvider;

export const usePayBuilderForm = () => useContext(FormContext);
