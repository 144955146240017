import { formatTimestamp } from "@components/Merchants/MerchantPreview/components/DocumentItem";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import { useGetUploadFilePermission } from "@hooks/merchant-api/manage-money/useUploadBankAccountDocument";
import {
  AcceptAllowedGeneralDocumentsTypes,
  fiveMB,
  useUploadFiles,
} from "@hooks/upload-api/uploadHooks";
import { Stack, SxProps } from "@mui/material";
import GiveUploadArea from "@shared/FileUpload/GiveUploadArea";
import GiveUploadStack, {
  GiveUploadItemProps,
} from "@shared/FileUpload/GiveUploadStack";
import GiveText from "@shared/Text/GiveText";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { checkPortals } from "@utils/routing";
import { useQueryClient } from "react-query";

const DocumentsSection = ({
  id,
  data,
  isEnterprise = false,
  title,
  customUploadStackStyles,
  isHideDelete = false,
}: {
  id: number;
  data: TMerchantDocument[];
  isEnterprise?: boolean;
  title?: string;
  customUploadStackStyles?: SxProps;
  isHideDelete?: boolean;
}) => {
  const queryClient = useQueryClient();
  const { isAcquirerPortal, isEnterprisePortal } = checkPortals();
  const { isMobileView } = useCustomThemeV2();
  const isUploadAllowed = useGetUploadFilePermission(isEnterprise);
  const tag = `${
    isAcquirerPortal ? "Acquirer" : isEnterprisePortal ? "Provider" : "Merchant"
  } upload`;
  const { handleUpload } = useUploadFiles();

  const attemptUpload = async (file: File) => {
    await handleUpload({
      list: [{ file }],
      merchantId: id,
      resourceID: id,
      attachmentType: "underwriting_profile",
      label: "",
      tag,
      refetcherKey: ["get-merchant-preview"],
    });
    queryClient.invalidateQueries("get-merchant-msp-status");
    queryClient.refetchQueries("get-merchant-preview");
  };

  const parsedData = parseDocumentsList(data, id);
  return (
    <Stack gap="20px">
      {title && <GiveText>{title}</GiveText>}
      <GiveUploadArea
        message="Up to 5MB each (.pdf, .png, .jpg, .jpeg, .webp)"
        disabled={!isUploadAllowed}
        uploadFunction={attemptUpload}
        accept={AcceptAllowedGeneralDocumentsTypes}
        maxFiles={5}
        multiple
        isMobile={isMobileView}
        maxSizeInBytes={fiveMB}
      />
      {parsedData?.length > 0 && (
        <GiveUploadStack
          items={parsedData}
          customStyles={customUploadStackStyles}
          isHideDelete={isHideDelete}
        />
      )}
    </Stack>
  );
};

const parseDocumentsList = (documents: TMerchantDocument[], id: number) => {
  const parsedData: GiveUploadItemProps[] = documents?.map((document) => {
    return {
      merchantId: id,
      state: "uploaded",
      value: document?.tag || document?.attTypeName,
      setValue: () => null,
      byMessage: `by ${document?.userFullName}`,
      dateMessage: formatTimestamp(document?.updatedAt * 1000),
      fileData: document,
    };
  });
  return parsedData;
};
export default DocumentsSection;
