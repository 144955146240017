import { Stack, SxProps } from "@mui/material";
import { styled } from "@theme/v2/Provider";

export interface IButtonComponent {
  conversations: JSX.Element;
  changelog: JSX.Element;
}

export type TRenderButton = (el: keyof IButtonComponent) => JSX.Element;
export const SidePanelButton = ({
  renderButton,
  el,
}: {
  renderButton: TRenderButton;
  el: keyof IButtonComponent;
}) => {
  return renderButton(el);
};

export const SidePanelContainerWrapper = styled(Stack)(({ theme }) => ({
  width: "100%",
  height: "100%",
  alignItems: "stretch",
  flexDirection: "column",
  justifyContent: "flex-start",
  scrollBehavior: "smooth",

  [theme.breakpoints.up("v2_sm")]: {
    overflowX: "hidden",
  },
}));

export const SidePanelBodyWrapper = ({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: SxProps;
}) => {
  return (
    <Stack
      sx={{ overflowY: "auto", scrollbarGutter: "stable both-edges", ...sx }}
    >
      {children}
    </Stack>
  );
};
