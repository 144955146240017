import {
  BellRinging,
  Check,
  PencilSimple,
  Prohibit,
  Trash,
} from "@phosphor-icons/react";
import ContextualMenu from "@shared/ContextualMenu/ContextualMenu";
import { ContextualMenuOptionProps } from "@shared/ContextualMenu/ContextualMenu.types";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { useNotifications } from "./hooks/useNotifications";

interface Props {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  isEDD?: boolean;
  currentChallenge?: any;
}

export default function ChallengeItemMenu({
  anchorEl,
  handleClose,
  isEDD,
  currentChallenge,
}: Props) {
  const { openNotifyMerchantModal } = useNotifications();
  const { isMobileView } = useCustomThemeV2();

  const menuOptions: Omit<ContextualMenuOptionProps, "children">[] = [
    {
      text: "Notify Merchant",
      onClick: () => openNotifyMerchantModal(),
      IconRight: BellRinging,
    },
    {
      text: "Edit",
      onClick: () => null, //TODO: add logic to open modal
      IconRight: PencilSimple,
      hidden: !isEDD,
    },
    { text: "Done", onClick: () => null, IconRight: Check },
    {
      text: "Reject",
      onClick: () => null, //TODO: add logic
      IconRight: Prohibit,
      type: "destructive",
    },
    {
      text: "Delete",
      onClick: () => null, //TODO: add logic
      IconRight: Trash,
      type: "destructive",
      hidden: !isEDD,
    },
  ];

  return (
    <ContextualMenu
      anchorEl={anchorEl}
      color="secondary"
      texture={isMobileView ? "solid" : "blurred"}
      options={menuOptions}
      handleClose={handleClose}
    />
  );
}
