import ArrowUp from "@assets/icons/RebrandedIcons/ArrowUp";
import DeleteIcon from "@assets/icons/RebrandedIcons/DeleteIcon";
import { Text } from "@common/Text";
import { ACCEPTED_IMAGE_FORMATS } from "@constants/constants";
import { Box, Button, styled } from "@mui/material";
import { memo, useRef, useState, useEffect } from "react";
import Dropzone, {
  IFileWithMeta,
  ILayoutProps,
  StatusValue,
} from "react-dropzone-uploader";
import { useThemeContext } from "../Provider/CustomThemeProvider";
import { ImageType } from "../hooks/useThemeManager";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  selectThemeLogos,
  updateTheme,
} from "@redux/slices/enterprise/enterpriseSettings";
import NiceModal from "@ebay/nice-modal-react";
import { DELETE_CONFIRMATION_MODAL } from "modals/modal_names";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { useIsValidFile } from "@hooks/upload-api/useIsValidFile";

interface Props {
  textLabel?: string;
  type: ImageType;
  disabled?: boolean;
}

type ImageData = {
  defaultImage: string;
  imageQueryType: string;
  imageFromAPI?: string | null;
};

function CustomDropZone({ textLabel, type, disabled = false }: Props) {
  const imgRef = useRef<any>();
  const { smallLogoSrc = "", wideLogoSrc = "" } =
    useAppSelector(selectThemeLogos);
  const { images, handleUpdateImage, handleDeleteImage } = useThemeContext();

  const imageData: Record<ImageType, ImageData> = {
    landscape: {
      defaultImage: wideLogoSrc,
      imageQueryType: "wideLogoSrc",
      imageFromAPI: images?.landscape,
    },
    square: {
      defaultImage: smallLogoSrc,
      imageQueryType: "smallLogoSrc",
      imageFromAPI: images?.square,
    },
  };

  const { defaultImage, imageQueryType, imageFromAPI } = imageData[type];
  const [imageURL, setImageURL] = useState<string>(defaultImage);

  const dispatch = useAppDispatch();
  const { isValidFile, isDropzoneImageFileValid } = useIsValidFile();
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();

  useEffect(() => {
    const imageUrl =
      typeof imageFromAPI === "string" && (imageFromAPI as string).length > 0
        ? imageFromAPI + "/original"
        : "";

    if (defaultImage && !imageFromAPI) {
      setImageURL(defaultImage);
    } else if (imageFromAPI && imageURL === "") {
      setImageURL(imageUrl);
    }
  }, [images]);

  const handleDragDropChange = (
    metaFile: IFileWithMeta,
    status: StatusValue,
  ) => {
    const { meta } = metaFile;
    if (
      isFileUploadRefactorEnabled &&
      !isDropzoneImageFileValid(metaFile, status)
    ) {
      return;
    }

    if (status === "done") {
      handleUpdateImage({ type, file: metaFile.file });
    }

    if (meta.previewUrl) {
      setImageURL(meta.previewUrl);
      dispatch(
        updateTheme({
          [imageQueryType]: meta.previewUrl,
        }),
      );
    }
  };

  const handleClick = () => {
    if (imgRef.current) {
      imgRef.current.click();
    }
  };

  const handleDelete = ({ type }: { type: ImageType }) => {
    NiceModal.show(DELETE_CONFIRMATION_MODAL, {
      variant: "image",
      deleteHandler: () => {
        handleDeleteImage({ type });
        setImageURL("");
        dispatch(
          updateTheme({
            [imageQueryType]: "",
          }),
        );
      },
    });
  };

  return (
    <Dropzone
      styles={{
        dropzone: {
          overflow: "hidden",
          width: "100%",
          height: "100%",
          border: 0,
        },
      }}
      onChangeStatus={handleDragDropChange}
      disabled={disabled}
      accept={ACCEPTED_IMAGE_FORMATS}
      InputComponent={({ getFilesFromEvent, accept, onFiles }) => (
        <input
          multiple
          type="file"
          accept={ACCEPTED_IMAGE_FORMATS}
          ref={imgRef}
          onChange={async (e) => {
            const chosenFiles = await getFilesFromEvent(e);
            onFiles(chosenFiles);
            (e.target as any).value = null;
          }}
          hidden
          disabled={disabled}
        />
      )}
      LayoutComponent={({ input, dropzoneProps }: ILayoutProps) => (
        <DropzoneArea {...dropzoneProps}>
          <ImageUpload
            imageSelected={!!imageFromAPI}
            onClick={handleClick}
            disabled={disabled}
          >
            {input}

            <Box
              sx={{
                ...((imageFromAPI || imageURL) && {
                  width: "100%",
                  height: "100%",
                }),
              }}
            >
              {imageFromAPI || imageURL ? (
                <>
                  <Box
                    {...dropzoneProps}
                    style={{
                      ...dropzoneProps.style,
                      width: "100%",
                      ...(imageURL && {
                        background: `url(${imageURL})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }),
                    }}
                  />
                  <DeleteButton
                    className="delete-logo-button"
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      handleDelete({ type });
                    }}
                    sx={{
                      display: { xs: "block", sm: "none" },
                    }}
                  >
                    <DeleteIcon />
                  </DeleteButton>
                </>
              ) : (
                <>
                  <ArrowUp />
                  <Text color="#8F8F8F" fontSize="11.93px" fontWeight="book">
                    {textLabel}
                  </Text>
                </>
              )}
            </Box>
          </ImageUpload>
        </DropzoneArea>
      )}
    />
  );
}

export default memo(CustomDropZone);

const DropzoneArea = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  border: "none",
  overflow: "hidden",
}));

const DeleteButton = styled(Box)(() => ({
  cursor: "pointer",
  position: "absolute",
  top: 2,
  right: 4,
  background: "#fff",
  padding: "2px 4px",
  borderRadius: "2px",
}));

const ImageUpload = styled(Button, {
  shouldForwardProp: (prop) => prop !== "imageSelected",
})<{ imageSelected: boolean }>(({ theme, imageSelected, disabled }) => ({
  width: "100%",
  height: 115,
  padding: 0,
  position: "relative",
  background: "inherit",
  boxShadow: "none",
  borderRadius: "4.5px",
  overflow: "hidden",
  cursor: "pointer",
  border: imageSelected ? "2px solid #D1D1D0" : `1px dashed #D1D1D0`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover .delete-logo-button": {
    ...(!disabled && {
      display: "block",
    }),
  },

  [theme.breakpoints.down("sm")]: {
    borderWidth: "2.5pxc",
  },
}));
