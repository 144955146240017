// nice-modal
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
// mui
import {
  Box,
  useTheme,
  useMediaQuery,
  Stack,
  styled,
  Divider,
} from "@mui/material";
// components
import { Text } from "@common/Text";
import { palette } from "@palette";
import { useAppSelector } from "@redux/hooks";
import ThankYouIcon from "@assets/images/rafiki.svg";
import Modal from "@common/Modal/Modal";
import { Button } from "@common/Button";
import { selectThankYouSettings } from "@redux/slices/checkout";
import { Check } from "@phosphor-icons/react";
import { DESCRIPTOR_PREFIX } from "@constants/constants";

type ModalProps = {
  name: string;
  email: string;
  amount: number | string;
  cardType: string;
  destinationAccountMerchantName?: string;
};

const ThankYouModal = NiceModal.create(
  ({ email, amount, cardType, destinationAccountMerchantName }: ModalProps) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
    const modal = useModal();
    const ThankYouSettings = useAppSelector(selectThankYouSettings);

    const handleClose = () => {
      modal.hide();
      if (ThankYouSettings.redirectUsers.redirectURL !== "") {
        window.location.href = ThankYouSettings.redirectUsers.redirectURL;
      }
    };

    return (
      <StyledModal
        {...muiDialogV5(modal)}
        width={isDesktop ? "648px" : "100%"}
        titleComponent={
          <IconBox>
            <Check size={43} color={palette.background.dimmedWhite} />
          </IconBox>
        }
        sx={modalStyles}
        onClose={handleClose}
        actions={<Button onClick={handleClose}>Close</Button>}
        data-testid="thank-you-modal"
      >
        <Stack gap="24px" alignItems="center" paddingTop="10px">
          <Text
            fontSize={28}
            sx={{
              color: palette.neutral[80],
              lineHeight: "initial",
            }}
          >
            {isDesktop ? "Payment Successful" : "Success"}
          </Text>
          <StyledText data-testid="thank-you-modal-reciept">
            A receipt has been sent to{" "}
            <span
              style={{
                color: palette.neutral[80],
                textDecoration: "underline",
              }}
            >
              {email}
            </span>
          </StyledText>
          <StyledText color={palette.filled.grey}>
            The charge will show up on your bank statement as:
            <br />
            {DESCRIPTOR_PREFIX}
            <span style={{ color: palette.neutral[80] }}>
              <sup style={{ lineHeight: 0, verticalAlign: "baseline" }}>*</sup>
              {destinationAccountMerchantName}
            </span>
          </StyledText>
          <Divider
            sx={{
              border: "none",
              height: "1px",
              width: "100%",
              borderRadius: "10px",
              backgroundColor: "#E1E1DE",
            }}
          />
          <Stack
            direction="row"
            gap="10px"
            data-testid="thank-you-modal-charged-amount"
            alignItems="center"
            justifyContent="center"
          >
            <Text
              fontSize="34px"
              lineHeight="34px"
              variant="h2"
              style={{ color: palette.filled.success }}
            >
              {(+amount).toFixed(2)}
            </Text>
            <Text fontSize="18px" color={palette.filled.grey}>
              USD
            </Text>
          </Stack>
        </Stack>
        {ThankYouSettings.displayIllustration && (
          <img src={ThankYouIcon} alt="Thank you" />
        )}
      </StyledModal>
    );
  },
);

const StyledModal = styled(Modal)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "780px",
    padding: "0",
    borderRadius: "16px",
    background: "linear-gradient(223.56deg, #72ECF0 0%, #528DDF 100%)",
    position: "absolute",
    height: "auto",
    backdropFilter: "blur(40px)",
    boxShadow: "0px 60px 180px 0px #00000026",
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: "-1",
      left: "0",
      top: "0",
      background:
        "linear-gradient(359deg, #FFFFFF 52%, rgba(255, 255, 255, 0) 169.02%)",
    },
  },

  [theme.breakpoints.down(950)]: {
    "& .MuiDialog-paper": {},
  },
  [theme.breakpoints.down(600)]: {
    "& .MuiDialog-paper": {
      margin: "0",
    },
    "& .MuiDialogActions-root": {
      flexDirection: "column",
    },
  },
  [theme.breakpoints.up("sm")]: {
    "& .MuiDialog-paper": {
      height: "auto",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiDialog-paper": {
      width: " calc( 100% - 20px ) ",
      height: "auto",
      bottom: "30px",
      top: "auto",
    },
    "& .MuiDialogActions-root": {
      flexDirection: "column",
    },
  },
}));

const modalStyles = {
  "& .MuiDialog-paper": {},
  "& .MuiDialogContent-root": {
    background: "#ffffff",
    borderRadius: "12px",
    margin: "10px 24px 40px 24px",
    boxShadow: "10px 60px 180px 0px #0000001A",
    backdropFilter: "blur(40px)",
    padding: "40px 32px 24px 32px",
  },
  "& .MuiDialogActions-root": {
    justifyContent: "flex-end",
    background: "transparent",
    padding: "20px 24px 16px 24px",
  },
  "& .MuiDialogTitle-root": {
    background: "transparent",

    "& > button": {
      background: palette.neutral.white,
    },
  },
};
const StyledText = styled(Text)(({ theme }) => ({
  color: palette.filled.grey,
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "16.8px",
  textAlign: "center",
}));

export const IconBox = ({
  children,
}: {
  background?: string;
  children: React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        background: "linear-gradient(223.56deg, #72ECF0 0%, #528DDF 100%)",
        borderRadius: "50%",
        height: "57px",
        width: "57px",
        flex: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: "61px",
        boxShadow: "0px 10px 10px 0px #0000000D",
        zIndex: "1",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      {children}
    </Box>
  );
};
export default ThankYouModal;
