import { styled } from "@theme/v2/Provider";
import GiveText from "./GiveText";
import { TextProps } from "./giveText.types";

export type TruncateTextProps = {
  lineClamp: number;
  link?: string;
  onHoverShowAll?: boolean;
} & TextProps;

const GiveTruncateText = styled(GiveText, {
  shouldForwardProp: (prop) =>
    prop !== "lineClamp" && prop !== "link" && prop !== "onHoverShowAll",
})(({ lineClamp, link, onHoverShowAll }: TruncateTextProps) => ({
  display: "-webkit-box",
  lineClamp,
  WebkitLineClamp: lineClamp,
  WebkitBoxOrient: "vertical",
  overflowWrap: "break-word",
  textOverflow: "ellipsis",
  overflow: "hidden",
  wordBreak: "break-word",
  ...(link && {
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  }),
  ...(onHoverShowAll && {
    "&:hover": {
      display: "block",
      overflow: "visible",
      whiteSpace: "normal",
    },
  }),
}));

export default GiveTruncateText;
