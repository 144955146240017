import { Stack } from "@mui/system";
import Portal from "@mui/material/Portal";
import GiveButton from "@shared/Button/GiveButton";
import React from "react";
import GiveText from "@shared/Text/GiveText";
import ReportStatusSelector, { MatchStatusEnum } from "./ReportStatusSelector";
import { FormProvider, useForm } from "react-hook-form";
import { HFGiveInput } from "@shared/HFInputs/HFGiveInput/HFGiveInput";
import { styled } from "@theme/v2/Provider";
import { useModal } from "@ebay/nice-modal-react";

export type ReportFormFields = {
  findings: string;
  evidence: File[];
  status: MatchStatusEnum;
};

const REPORT_FORM_DEFAULT_VALUES = {
  findings: "",
  evidence: [],
  status: MatchStatusEnum.Clear,
};

const AddNewReportView = () => {
  const methods = useForm<ReportFormFields>({
    defaultValues: REPORT_FORM_DEFAULT_VALUES,
  });
  const { remove } = useModal();

  return (
    <Stack gap={2.5}>
      <GiveText variant="bodyS" color="secondary">
        Please review each field thoroughly to ensure all information is
        accurate and current before submitting.
      </GiveText>
      <FormProvider {...methods}>
        <StyledStack>
          <ReportStatusSelector />
          <Stack gap={1}>
            <GiveText variant="bodyS">Findings</GiveText>
            <HFGiveInput
              name="findings"
              multiline
              rows={11}
              placeholder="Enter your findings..."
            />
          </Stack>
        </StyledStack>
      </FormProvider>
      <Portal
        container={() => document.getElementById("match-report-modal-footer")}
      >
        <GiveButton label="Cancel" size="large" variant="ghost" onClick={remove} />
        <GiveButton label="Submit" size="large" variant="filled" />
      </Portal>
    </Stack>
  );
};

const StyledStack = styled(Stack)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.border?.primary}`,
  paddingBlock: theme.spacing(2.5),
  gap: theme.spacing(2.5),
}));
export default AddNewReportView;
