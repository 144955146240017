import { Text } from "@common/Text";
import { Box, BoxProps, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { ArrowForwardIcon, ConversationsIconNotFilled } from "@assets/icons";
import { LockIconV2 as LockIcon } from "@assets/icons/RebrandedIcons";
import { Button } from "@common/Button";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import { CircularButton } from "../components/atoms";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { SyntheticEvent } from "react";
import { FEATURE_DENY_MESSAGE } from "@constants/permissions";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

interface Props {
  disabled: boolean;
  label: string;
  onClick?: () => void;
  opened: boolean;
  isAcquirer: boolean;
  isEnabled: boolean;
  tooltipMessage: string;
  onConversationClick: () => void;
  status?: React.ReactElement;
}

export default function PreviewSection({
  disabled,
  onClick,
  opened,
  isAcquirer,
  isEnabled,
  label,
  tooltipMessage,
  onConversationClick,
  status,
}: Props) {
  const { isMobileView } = useCustomTheme();
  const { merchant_underwriting } = useEnterprisePermissions();
  const onConvClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    onConversationClick();
  };

  return (
    <WithTooltipWrapper
      hasTooltip={disabled || !isEnabled}
      tooltipProps={{
        message: !isEnabled ? FEATURE_DENY_MESSAGE : tooltipMessage,
        show: disabled || !isEnabled,
        placement: "top",
      }}
      maxWidth="240px"
    >
      <Container
        opened={opened}
        isMobileView={isMobileView}
        disabled={disabled || !isEnabled}
        onClick={!disabled && isEnabled ? onClick : undefined}
        data-testid={`${label}-container`}
      >
        <Text fontSize={18} color={palette.neutral[80]}>
          {label}
        </Text>
        {disabled || !isEnabled ? (
          <LockIcon />
        ) : (
          <Stack direction="row" alignItems="center" spacing={1}>
            {!!status && status}
            {merchant_underwriting && isAcquirer && (
              <CircularButton className="circular-button" onClick={onConvClick}>
                <ConversationsIconNotFilled section="ofac" />
              </CircularButton>
            )}
            <Button
              background="tertiary"
              endIcon={<ArrowForwardIcon />}
              sx={{ padding: "4px 8px", height: "28px", width: "76px" }}
            >
              Open
            </Button>
          </Stack>
        )}
      </Container>
    </WithTooltipWrapper>
  );
}

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "opened",
})<BoxProps & { disabled: boolean; opened: boolean; isMobileView?: boolean }>(
  ({ opened, disabled, isMobileView }) => ({
    padding: "16px",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: palette.background.dimmedWhite,
    boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.10)",
    cursor: "pointer",
    ...(isMobileView && {
      "& .circular-button": {
        display: "flex",
      },
    }),
    ...(!disabled &&
      !opened && {
        "&:hover": {
          boxShadow: "0px 3px 15px 0px rgba(2, 2, 2, 0.15)",
          "& .circular-button": {
            display: "flex",
          },
        },
      }),
    ...(opened && {
      boxShadow: "none",
      opacity: 0.5,
      pointerEvents: "none",
      cursor: "default",
    }),
  }),
);
