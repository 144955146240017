import { Popover, Stack } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import PublicInputShare from "./PublicInputShare";
import type { TButtonsMap } from "./PublicShare.types";

const StrictVariant = ({
  ButtonsMap,
  anchorEl,
  setAnchorEl,
  url,
}: {
  ButtonsMap: TButtonsMap;
  anchorEl: HTMLElement | null;
  url: string;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
}) => {
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Popover
      id="anchorEl"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: "20px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          width: "456px",
          padding: "16px",
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Stack>
        <Stack direction="row" alignItems="center" gap={2}>
          <PublicInputShare
            url={url}
            textProps={{
              fontSize: "14px",
              minWidth: "72px",
            }}
          />
        </Stack>
        <Stack
          direction="row"
          flexWrap="wrap"
          pt="16px"
          justifyContent="space-between"
        >
          {ButtonsMap.map((Button, index) => {
            return (
              Button.hidden === false && (
                <Button.Component
                  id={`${Button.id}-share-button`}
                  url={url}
                  key={index}
                  appId=""
                >
                  <Button.Icon />
                </Button.Component>
              )
            );
          })}
        </Stack>
      </Stack>
    </Popover>
  );
};

export default StrictVariant;
