import { useState } from "react";
import { Stack } from "@mui/material";
import { ArrowLeft, X } from "@phosphor-icons/react";

import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { SidePanelHeaderBase } from "@shared/SidePanel/components/SidePanelHeader/SidePanelHeader";
import GiveText from "@shared/Text/GiveText";
import {
  SidePanelBodyWrapper,
  SidePanelContainerWrapper,
} from "@shared/SidePanel/SidePanelAtoms";
import AgreementPanel from "./Agreement/AgreementPanel";
import SnapshotPanel from "./Snapshot/Snapshot";
import { useMerchantSidePanelContext } from "../Provider/MerchantSidePanelProvider";
import { JoinedProps, StateType, tabs, MainPages } from "./agreements.types";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";

const sections = (props: JoinedProps) => ({
  Agreement: <AgreementPanel {...props} />,
  Snapshot: <SnapshotPanel {...props} />,
});

const defaultValues: StateType = {
  mainPage: "Agreement" as MainPages,
  tab: "merchantApplication" as tabs,
};

const AgreementAndSnapshot: React.FC = () => {
  const [page, setPage] = useState<StateType>(defaultValues);
  const { mainPage, tab } = page;
  const { isMobileView } = useCustomThemeV2();
  const { setDoublePanel } = useMerchantSidePanelContext();

  const handleClose = () => setDoublePanel("");
  const resetPage = () => setPage(defaultValues);

  const props = { setPage, page };
  const isNextPage = mainPage !== "Agreement";

  const tabsData = [
    {
      label: "Merchant Application",
      onClick: () =>
        setPage((prev) => ({ ...prev, tab: "merchantApplication" })),
      isActive: tab === "merchantApplication",
    },
    {
      label: "Fees Schedule",
      onClick: () => setPage((prev) => ({ ...prev, tab: "fees" })),
      isActive: tab === "fees",
    },
    {
      label: "Refund Policy",
      onClick: () => setPage((prev) => ({ ...prev, tab: "refund" })),
      isActive: tab === "refund",
    },
  ];

  return (
    <SidePanelContainerWrapper>
      <SidePanelHeaderBase
        leftItems={
          <Stack
            sx={{
              cursor: "pointer",
            }}
            gap="12px"
            alignItems="center"
            flexDirection="row"
            onClick={resetPage}
          >
            {isNextPage && <ArrowLeft size="20px" />}
            <GiveText color={isNextPage ? "secondary" : "primary"}>
              Agreement {isNextPage && !isMobileView && " /"}
              {isNextPage && (
                <GiveText color="primary" component="span">
                  {" "}
                  Snapshot
                </GiveText>
              )}
            </GiveText>
          </Stack>
        }
        rightItems={
          <GiveIconButton
            Icon={X}
            variant="ghost"
            onClick={isNextPage ? resetPage : handleClose}
          />
        }
        {...(isNextPage && { tabsData })}
      />
      <SidePanelBodyWrapper>{sections(props)[mainPage]}</SidePanelBodyWrapper>
    </SidePanelContainerWrapper>
  );
};

export default AgreementAndSnapshot;
