import { CloseIcon } from "@assets/icons";
import { StyledIconButton } from "@common/IconButton";
import { Text, TruncateText } from "@common/Text";
import { FontWeight, ITextProps } from "@common/Text/Text";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { Divider, Stack, StackProps, styled } from "@mui/material";
import { palette } from "@palette";
import { ReactElement } from "react";

type TextStyles = {
  title?: ITextProps;
  description?: ITextProps;
};

type TitleProps = {
  Icon?: JSX.Element;
  title?: string;
  onClose?: () => void;
  description?: string | ReactElement;
  containerProps?: StackProps;
  textStyles?: TextStyles;
  padding?: string;
  closeButtonSize?: number;
  animationDelay?: number;
  showDivider?: boolean;
};

export const ModalTitle = ({
  Icon,
  title,
  onClose,
  description,
  containerProps,
  textStyles,
  padding,
  closeButtonSize = 24,
  animationDelay = 20,
  showDivider = false,
}: TitleProps) => {
  const titleStyle = getStyle("title", textStyles?.title);
  const descriptionStyle = getStyle("description", textStyles?.description);

  return (
    <FadeUpWrapper delay={animationDelay}>
      <ModalTitleContainer
        {...containerProps}
        sx={{ ...containerProps?.sx, padding }}
      >
        <Stack direction="row" spacing={1}>
          {Icon && Icon}
          <TruncateText
            variant="h3"
            {...titleStyle}
            minHeight={titleStyle.lineHeight}
            sx={{ userSelect: "none" }}
            flexGrow={1}
            lineClamp={2}
          >
            {title}
          </TruncateText>
          {onClose && (
            <CloseButton
              data-testid="close-button"
              onClick={onClose}
              closeButtonSize={closeButtonSize}
            >
              <CloseIcon
                width={closeButtonSize - 2}
                height={closeButtonSize - 2}
                stroke={palette.black[100]}
              />
            </CloseButton>
          )}
        </Stack>
        {description && <Text {...descriptionStyle}>{description}</Text>}
      </ModalTitleContainer>
      {showDivider && (
        <Divider
          sx={{
            marginBottom: "16px",
          }}
        />
      )}
    </FadeUpWrapper>
  );
};

const defaultTextProps = {
  title: {
    fontSize: "20px",
    lineHeight: "27px",
    fontWeight: "book" as FontWeight,
    color: palette.black[100],
  },
  description: {
    fontSize: "14px",
    lineHeight: "16.8px",
    fontWeight: "book" as FontWeight,
    color: palette.gray[300],
  },
};

const getStyle = (key: "title" | "description", customStyles?: ITextProps) => {
  return {
    ...defaultTextProps[key],
    ...customStyles,
  };
};

const ModalTitleContainer = styled(Stack)(() => ({
  flexDirection: "column",
  alignItems: "stretch",
  gap: "8px",
  background: "inherit",
}));

const CloseButton = styled(StyledIconButton, {
  shouldForwardProp: (prop) => prop !== "closeButtonSize",
})<{ closeButtonSize: number }>(({ closeButtonSize }) => ({
  maxWidth: `${closeButtonSize}px`,
  maxHeight: `${closeButtonSize}px`,
  borderRadius: "4px",
  padding: "0 !important",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
