import * as React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { capitalizeEachWord } from "@utils/index";
import { GiveInput, InputProps } from "@shared/GiveInputs/GiveInput";
import GiveText from "@shared/Text/GiveText";

type NormalizeInputFunction = (VAL: string) => string;
export type HFGiveInputProps = InputProps & {
  name: string;
  helper?: string | JSX.Element | any;
  shouldCapitalizeEachWord?: boolean;
  focusViewColor?: string;
  handleNormalizeInput?: NormalizeInputFunction;
  maxLength?: number;
  displayCounter?: boolean;
};

export const HFGiveInput: React.FC<HFGiveInputProps> = ({
  name,
  helper,
  shouldCapitalizeEachWord,
  handleNormalizeInput,
  maxLength,
  displayCounter,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, value, ...rest }, fieldState: { error } }) => {
        return (
          <>
            <GiveInput
              disabled
              inputRef={ref}
              {...rest}
              displayCounter={displayCounter}
              error={!!error}
              maxLength={maxLength}
              value={
                shouldCapitalizeEachWord ? capitalizeEachWord(value) : value
              }
              helperText={helper || error?.message}
              onChange={(e) => {
                const value = e.target.value;
                const newValue = maxLength ? value?.slice(0, maxLength) : value;

                rest?.onChange({
                  ...e,
                  target: {
                    ...e.target,
                    value: handleNormalizeInput
                      ? handleNormalizeInput(newValue)
                      : newValue,
                  },
                });
              }}
              {...props}
            />
          </>
        );
      }}
    />
  );
};
