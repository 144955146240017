import { makeStyles } from "@mui/styles";
import { roundToNearestTenth } from "@utils/index";
import { useGetProductsStatsFactory } from "@services/api/products/queryFactory";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { productTypeIDs } from "@services/api/analytics/campaigns";
import { statsParser, mergeStats } from "./utils";
import { TProductTypeSection } from "./NewProductBanner";
import NiceModal from "@ebay/nice-modal-react";

interface IUseNewProductBanner {
  analyticsType?: TProductTypeSection;
  analitycsModal: string;
  hasEmptyData: boolean;
}

const useNewProductBanner = ({
  analitycsModal,
  analyticsType,
  hasEmptyData,
}: IUseNewProductBanner) => {
  const classes = useStyles();

  const location = useLocation();
  const currentPage = location.pathname.split("/")[2] as TProductTypeSection;
  const { t } = useTranslation(`page.${currentPage}`);
  const isPaymentForm = currentPage === "payment-forms";
  const { data, isLoading } = useGetProductsStatsFactory(
    productTypeIDs[currentPage as keyof typeof productTypeIDs],
    isPaymentForm,
  );

  const parsedData = useMemo(() => {
    const isArray = Array.isArray(data?.data);
    if (!isArray || !data?.data?.length) return statsParser(data);

    const parsedArray = data?.data?.map((d: any) => statsParser(d));
    return mergeStats(parsedArray);
  }, [data]);

  const onOpenAnalytics = analyticsType
    ? () =>
        NiceModal.show(analitycsModal, {
          isEmpty: hasEmptyData || parsedData.totalPurchases === 0,
        })
    : undefined;

  const { parseStats, mainStat } = useMemo(() => {
    const {
      totalProducts,
      visitors,
      conversionRate,
      averagePurchases,
      sumGenerated,
    } = parsedData;
    const parseStats = !translations[currentPage]
      ? []
      : [
          {
            title: t(translations[currentPage][0]),
            value: totalProducts,
          },
          { title: t(translations[currentPage][1]), value: visitors },
          {
            title: t(translations[currentPage][2]),
            value: isPaymentForm
              ? roundToNearestTenth(conversionRate / data?.total)
              : roundToNearestTenth(conversionRate),
            percent: true,
          },
          {
            isAmount: true,
            title: t(translations[currentPage][3]),
            value:
              (isPaymentForm
                ? averagePurchases / data?.total
                : averagePurchases) / 100,
          },
        ];
    const mainStat = {
      isAmount: true,
      title: translations[currentPage]
        ? t(translations[currentPage][4])
            ?.replace(/([A-Z])/g, " $1")
            .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
        : "",
      value: `${sumGenerated / 100}`,
    };
    return { parseStats, mainStat };
  }, [parsedData, currentPage, t]);

  return {
    isLoading,
    classes,
    parseStats,
    mainStat,
    onOpenAnalytics,
  };
};

const useStyles = makeStyles({
  videoStyles: {
    opacity: 0.2,
    objectPosition: "center center",
  },
});

const translations: Record<TProductTypeSection, string[]> = {
  fundraisers: [
    "fundraisers",
    "visitors",
    "conversion",
    "average",
    "fundraisersTotal",
  ],
  "payment-forms": [
    "Forms",
    "Visitors",
    "Conversion",
    "Average",
    "PaymentFormTotal",
  ],
  events: ["events", "visitors", "conversion", "average", "eventsTotal"],
  invoices: ["Invoices", "Visitors", "Conversion", "Average", "Invoices Total"],
  memberships: [
    "Memberships",
    "Visitors",
    "Conversion",
    "Average",
    "Memberships Total",
  ],
  sweepstakes: [
    "sweepstakes",
    "visitors",
    "conversion",
    "average",
    "sweepstakesTotal",
  ],
  customers: [],
  "manage-money": [],
};

export default useNewProductBanner;
