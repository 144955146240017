import { ClockCounterwiseIcon } from "@assets/icons/RebrandedIcons";
import { StyledIconButton } from "@common/IconButton";
import { SIDE_PANEL_BUTTON_OFFSET } from "@constants/constants";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { NEW_BASE_PANEL_WIDTH } from "@shared/SidePanel/GiveSidePanel";

type Props = {
  isLoading?: boolean;
  handleOpenChangelogModal: (open?: boolean | undefined) => void;
  isOpen?: boolean;
  isOpenConversations?: boolean;
  isDoublePanel?: boolean;
};

const ChangelogButton = ({
  isLoading = false,
  handleOpenChangelogModal,
  isOpen = false,
  isOpenConversations,
  isDoublePanel = false,
}: Props) => {
  const { isMobileView } = useCustomTheme();
  const styles = useStyles({
    isOpen: Boolean(isOpen || isOpenConversations),
    isMobileView,
    isDoublePanel,
  });

  return (
    <StyledIconButton
      id="changelogButton"
      className={styles.buttonStyles}
      onClick={() => handleOpenChangelogModal(!isMobileView ? true : !isOpen)}
      disabled={isLoading}
      sx={{
        position: "relative",
        borderRadius: "50%",
      }}
      data-testid="changelog-button"
    >
      <Box className={styles.containerStyles}>
        <ClockCounterwiseIcon width="30px" height="30px" />
      </Box>
    </StyledIconButton>
  );
};

export default ChangelogButton;

const useStyles = makeStyles((theme: Theme) => ({
  buttonStyles: ({
    isMobileView,
    isOpen,
    isDoublePanel,
  }: {
    isMobileView: boolean;
    isOpen: boolean;
    isDoublePanel: boolean;
  }) => {
    return {
      position: isMobileView ? (isOpen ? "relative" : "fixed") : "fixed",
      bottom: isMobileView ? (isOpen ? "initial" : "90px") : "90px",
      left: isMobileView
        ? isOpen
          ? "0px"
          : "initial"
        : `calc(100% - ${
            (isDoublePanel ? NEW_BASE_PANEL_WIDTH * 2 : NEW_BASE_PANEL_WIDTH) +
            SIDE_PANEL_BUTTON_OFFSET
          }px)`,
      right: isMobileView ? "20px" : "70px",
      ...(isMobileView && { top: isOpen ? "0px" : "initial" }),
      boxShadow: isMobileView
        ? "0px 2.625px 13.125px 0px rgba(2, 2, 2, 0.15)"
        : "initial",
      backgroundColor: theme.palette.neutral.white,
      zIndex: 3,
    };
  },

  containerStyles: {
    borderRadius: "50%",
    padding: "10px 10px 4px 10px",
    backgroundColor: theme.palette.neutral.white,
  },
}));
