import React from "react";
// nice-modal
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
// mui
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { Text } from "@common/Text";
import { Modal, ModalTitle } from "@common/Modal";
import { DropdownItem } from "@common/Select";
import { BankAccountTable } from "./Table/BankAccountTable";
// assets
import { PlaidIcon } from "@assets/icons";
// localization
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
import { NEW_ACCOUNT_MODAL } from "modals/modal_names";
import { usePlaidService } from "@hooks/merchant-api/BankAccounts";
import { palette } from "@palette";
import { Button_V2 as Button } from "@common/Button/Button";
//hooks
import useListBankAccounts from "hooks/merchant-api/manage-money/useListBankAccounts";
import { useAppSelector } from "@redux/hooks";
import { WithMissingPermissionModule } from "@common/WithMissingPermissionModule";
import {
  FEATURE_DENY_MESSAGE,
  CREATE_DENY_MESSAGE,
} from "@constants/permissions";
import MobileBankAccountsList from "./MobileBankAccountsList";
import { BankIcon } from "@assets/icons/RebrandedIcons";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import { Box } from "@mui/material";

const BankAccounts = NiceModal.create(() => {
  const theme = useTheme();
  const modal = useModal();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const MenuOpen = Boolean(anchorEl);
  const { open: startPlaidService, isEnableLinkPlaid } = usePlaidService();
  const { t } = useTranslation(namespaces.pages.manageMoney);

  const {
    allRows,
    setPage,
    loadingRef,
    handlePageChange,
    totalRows,
    page,
    isLoading,
    isAllowedAddAccounts,
  } = useListBankAccounts();

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenNewBankAccount = () => {
    setAnchorEl(null);
    NiceModal.show(NEW_ACCOUNT_MODAL, { isDefault: totalRows === 0 });
  };

  const handleAddWithPlaid = () => {
    startPlaidService();
    if (anchorEl) setAnchorEl(null);
  };

  const hasNotBankAccountsListPermission = useAppSelector(
    (state) => state.app.permissions?.bank_accounts_list,
  );

  const mobileActions = [
    {
      onClick: handleAddWithPlaid,
      disabled: !isEnableLinkPlaid,
      label: {
        title: "Add bank account with Plaid",
        description: t("modals.AverageApproval1", { ns: namespaces.common }),
        Icon: <PlaidIcon height={24} width={24} fill={palette.gray[300]} />,
      },
    },
    {
      onClick: handleOpenNewBankAccount,
      label: {
        title: "Manuallly add bank account",
        description: t("modals.AverageApproval3", { ns: namespaces.common }),
        Icon: <BankIcon fill={palette.gray[300]} />,
      },
    },
  ];

  const desktopAtions: MenuItemProps[] = [
    {
      onClick: handleAddWithPlaid,
      disabled: !isEnableLinkPlaid,
      tooltipProps: {
        show: !isEnableLinkPlaid,
        message: FEATURE_DENY_MESSAGE,
      },
      title: "Add bank account with Plaid",
      description: t("modals.AverageApproval1", { ns: namespaces.common }),
      Icon: <PlaidIcon height={24} width={24} fill={palette.gray[300]} />,
    },
    {
      onClick: handleOpenNewBankAccount,
      title: "Manually add bank account",
      description: t("modals.AverageApproval3", { ns: namespaces.common }),
    },
  ];

  if (!isDesktop) {
    return (
      <MobileBankAccountsList
        page={page}
        totalRows={totalRows}
        setPage={setPage}
        loadingRef={loadingRef}
        rows={allRows}
        mobileActions={mobileActions}
        isLoading={isLoading}
      />
    );
  }

  return (
    <Modal
      width="720px"
      maxWidth="xl"
      {...muiDialogV5(modal)}
      headerComponent={
        <ModalTitle
          backgroundColor={palette.liftedWhite.main}
          title="Bank accounts"
          onClose={() => modal.hide()}
        />
      }
      onClose={() => modal.hide()}
      sx={modalStyles}
      actions={
        <Stack p={0.5} ml={"auto"} justifyContent={"flex-end"}>
          <Button
            size="medium"
            variant="primary"
            onClick={handleOpenMenu}
            disabled={!isAllowedAddAccounts}
            data-testid="add-bank-account-button"
            tooltipProps={{
              show: !isAllowedAddAccounts,
              message: CREATE_DENY_MESSAGE,
            }}
          >
            Add bank account
          </Button>
        </Stack>
      }
    >
      <Menu
        open={MenuOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {desktopAtions.map((item) => (
          <MenuItem key={item.title} {...item} />
        ))}
      </Menu>
      <WithMissingPermissionModule
        message="Bank accounts hidden"
        notAuthorized={hasNotBankAccountsListPermission}
        wrapperProps={{
          marginTop: 0,
          padding: "24px",
        }}
      >
        <BankAccountTable
          rows={allRows}
          page={page}
          isLoading={isLoading}
          totalRows={totalRows}
          loadingRef={loadingRef}
          setPage={setPage}
          handlePageChange={handlePageChange}
        />
      </WithMissingPermissionModule>
    </Modal>
  );
});

type MenuItemProps = {
  onClick: () => void;
  disabled?: boolean;
  title: string;
  description: string;
  Icon?: JSX.Element;
  tooltipProps?: {
    show: boolean;
    message: string;
  };
};

const MenuItem = ({
  onClick,
  disabled = false,
  title,
  description,
  Icon,
  tooltipProps,
}: MenuItemProps) => {
  const { isDesktopView } = useCustomTheme();
  return (
    <WithTooltipWrapper hasTooltip={!!tooltipProps} tooltipProps={tooltipProps}>
      <DropdownItem
        disabled={disabled}
        onClick={disabled ? undefined : onClick}
      >
        <Box>
          {Icon ? (
            <Stack mb={0.5} direction="row" alignItems="center" spacing={1}>
              <Text variant={isDesktopView ? "body" : "h5"}>{title}</Text>
              {Icon}
            </Stack>
          ) : (
            <Text variant={isDesktopView ? "body" : "h5"} mb={0.5}>
              {title}
            </Text>
          )}

          <Text
            variant={isDesktopView ? "caption" : "body"}
            color={palette.neutral[600]}
          >
            {description}
          </Text>
        </Box>
      </DropdownItem>
    </WithTooltipWrapper>
  );
};

const modalStyles = {
  "& .MuiDialog-paper": {
    width: "780px",
    borderRadius: "16px",
    boxShadow: "0px 60px 180px rgba(0, 0, 0, 0.15)",
    backdropFilter: "blur(20px)",
    background: palette.liftedWhite.main,
  },
  "& .MuiDialogTitle-root": {
    background: palette.liftedWhite.main,
    padding: "12px 16px 8px",
    "& > button": {
      background: palette.liftedWhite.main,
    },
  },
};

export default BankAccounts;
