import NiceModal from "@ebay/nice-modal-react";
import { EDIT_ENTERPRISE_MCC_MODAL } from "modals/modal_names";
import { TCategoryCode } from "@components/Merchants/MerchantPreview/data.types";
import { MerchantSectionContainer } from "@components/Merchants/MerchantPreview/components/atoms";
import { Stack, styled } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { ICreateEnterpriseSchema } from "@components/AcquirerEnterprises/CreateEnterprise/types";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import { useRef, useState } from "react";

type Props = {
  id: number;
  data: TCategoryCode[];
  parentCategories: TCategoryCode[];
  handleCloseMCC?: (data: ICreateEnterpriseSchema["categories"]) => void;
  isEnterpriseSidePane?: boolean;
};
const maxHeightList = 400;
const CategoryItemHeight = 36;
const MCCSection = ({
  id,
  data,
  parentCategories,
  handleCloseMCC,
  isEnterpriseSidePane,
}: Props) => {
  const editHandler = () => {
    NiceModal.show(EDIT_ENTERPRISE_MCC_MODAL, {
      id,
      categories: data,
      parentCategories,
      onClose: handleCloseMCC,
    });
  };
  const getHeight: () => number = () => {
    const height =
      typeof data?.length === "number" ? data?.length * CategoryItemHeight : 0;
    if (height > maxHeightList) {
      return maxHeightList;
    }
    return height;
  };
  const scroller = useRef<VirtuosoHandle>(null);
  const [isAtBottom, setIsAtBottom] = useState(false);
  return (
    <MerchantSectionContainer
      sectionTitle="Merchant Category Codes (MCC)"
      editHandler={editHandler}
      containerSx={{
        borderBottom: "none",
        position: "relative",
      }}
      isEnterpriseSidePane={isEnterpriseSidePane}
    >
      <Virtuoso
        ref={scroller}
        style={{
          height: getHeight(),
          width: "100%",
        }}
        onScroll={(e: any) => {
          const { scrollTop, clientHeight, scrollHeight } = e.target;
          const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10; // 10px buffer
          setIsAtBottom(isAtBottom);
        }}
        data={data}
        itemContent={(_, category) => {
          return <MerchantCategoryItem key={category.id} {...category} />;
        }}
      />
      {data?.length > 11 && (
        <div
          style={{
            background:
              "linear-gradient(0.51deg, #FBFBFB 31.5%, rgba(248, 248, 246, 0) 101.87%)",
            height: 78,
            width: "100%",
            bottom: 0,
            position: "absolute",
            opacity: isAtBottom ? 0 : 1,
            transition: "opacity 0.5s",
          }}
        />
      )}
    </MerchantSectionContainer>
  );
};

const MerchantCategoryItem = ({ code, title }: TCategoryCode) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      p="4px"
      data-testid={`mcc-added-${code}`}
    >
      <CategoryCode>{code}</CategoryCode>
      <Text fontWeight="book" color={palette.neutral[80]}>
        {title}
      </Text>
    </Stack>
  );
};

const CategoryCode = styled(Text)(() => ({
  padding: "4px",
  borderRadius: "4px",
  background: "#EBEBEB",
  fontSize: 12,
  fontWeight: 350,
  color: palette.neutral[80],
}));

export default MCCSection;
