import { SelectorOption } from "@common/TableFilters/utils";
import { Amount } from "components/Tranfers/utils";

export const disputesFilterDefaultValues = {
  underwriterEmail: [],
  disputeStatus: [],
  amount: [],
  custom: "",
  createdAt: "",
};

export type DisputesFilterFormValuesType = {
  underwriterEmail: SelectorOption[];
  disputeStatus: string[];
  amount: Array<Amount>;
  custom: string;
  createdAt: string;
};

export type DisputeFilterFormFields =
  | "underwriterEmail"
  | "disputeStatus"
  | "amount"
  | "custom"
  | "createdAt";
