import { PEPStatusType } from "@components/Merchants/MerchantPreview/PEP/types";
import GiveChip from "@shared/Chip/GiveChip";
import { memo, useMemo } from "react";

type Props = {
  ownerPepStatus?: PEPStatusType;
  EndIcon?: JSX.Element;
  pepStatusSetByUnderwriter?: boolean;
  manualPepStatus?: PEPStatusType;
  isSidePanel?: boolean;
  withPEP?: boolean;
};

const GivePepStatusChip = ({
  ownerPepStatus = "pending",
  manualPepStatus,
  pepStatusSetByUnderwriter,
  withPEP,
}: Props) => {
  const status = useMemo(() => {
    if (
      (ownerPepStatus === "clear" || manualPepStatus === "clear") &&
      pepStatusSetByUnderwriter
    )
      return "clear_manually";

    const statusManually =
      ownerPepStatus === "clear" || manualPepStatus === "clear"
        ? "clear_manually"
        : "confirmed_match";

    return manualPepStatus ? statusManually : ownerPepStatus;
  }, [ownerPepStatus, manualPepStatus, pepStatusSetByUnderwriter]);

  const { color, label } = PEPStatus[status];

  return (
    <GiveChip
      variant="light"
      size="small"
      color={color}
      label={withPEP ? `PEP ${label}` : label}
      sx={{ marginRight: "5px", maxHeight: "22px" }}
    />
  );
};

type StatusObject = {
  label: string;
  color: "default" | "success" | "warning" | "blue" | "error";
};
type ExtendedPEPStatusType = PEPStatusType | "disabled_pending";

const PEPStatus: Record<ExtendedPEPStatusType, StatusObject> = {
  pending: {
    label: "Pending",
    color: "default",
  },
  possible_match: {
    label: "Possible Match",
    color: "warning",
  },
  clear: {
    label: "Clear",
    color: "success",
  },
  clear_manually: {
    label: "Manually Cleared",
    color: "success",
  },
  confirmed_match: {
    label: "Confirmed Match",
    color: "error",
  },
  disabled_pending: {
    label: "Pending",
    color: "default",
  },
};

export default memo(GivePepStatusChip);
