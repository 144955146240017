import StoreFrontIcon from "@assets/icons/RebrandedIcons/StoreFrontIcon";
import {
  CategoriesSelect,
  EnterprisesSelect,
  NameInput,
} from "@common/BusinessProfileInputs";
import { RHFInput, RHFTelInput } from "@common/Input";
import { Text } from "@common/Text";
import { Box, Grid, Stack, styled, TextField } from "@mui/material";
import { palette } from "@palette";
import { checkPortals } from "@utils/routing";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import WebsiteInput from "@common/BusinessProfileInputs/WebsiteInput";
import { RHFCheckbox } from "@common/Checkbox";
import { RHFCustomAmountInput } from "@common/CustomAmountInput";
import { RHFSelect } from "@common/Select";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import CreateFormAvatarUpload from "@components/AvatarUpload/CreateFormAvatarUpload";
import {
  TClassificationsAPIResponse,
  useBusinessClassification,
} from "@components/Signup/Forms/hooks/useBusinessClassification";
import {
  DESCRIPTOR_PREFIX,
  SLUG_MAX_CHARACTER_LENGTH,
} from "@constants/constants";
import { generateBillingDescriptor } from "@utils/helpers";
import { getStringWidth, removeSpecialChars } from "@utils/slug";
import { debounce } from "lodash";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

type Props = {
  modify_merchant: boolean;
};

const CreateMerchantGeneral = ({ modify_merchant }: Props) => {
  const { isAcquirerPortal } = checkPortals();
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const values = watch();

  const phoneHelper =
    (errors?.merchantInfo as any)?.servicePhoneNumber?.message || "";

  const handleChangeBillingDescriptor = useCallback((descriptor: string) => {
    setValue(
      "merchantInfo.billingDescriptor",
      generateBillingDescriptor(descriptor),
      {
        shouldDirty: true,
      },
    );
  }, []);

  const handleChangeSlug = useCallback((slug: string) => {
    const updatedSlug = removeSpecialChars(slug, SLUG_MAX_CHARACTER_LENGTH);
    setValue("merchantInfo.merchantSlug", updatedSlug, { shouldDirty: true });
  }, []);

  const handleUpdate = useCallback(
    debounce((merchantName: string) => {
      handleChangeBillingDescriptor(merchantName);
      handleChangeSlug(merchantName);
    }, 500),
    [],
  );

  useEffect(() => {
    handleUpdate(values.merchantInfo.merchantName);

    // Clean up function
    return () => {
      handleUpdate.cancel();
    };
  }, [values.merchantInfo.merchantName]);

  const classificationsParser = (res: TClassificationsAPIResponse) =>
    res?.data?.map(({ name, displayName }) => ({
      value: name,
      label: `${displayName}`,
    }));
  const { isMobileView } = useCustomTheme();
  const { data: classificationOptions } = useBusinessClassification(
    "accept_payments",
    classificationsParser,
  );

  const withNoPaddingLeft = !isMobileView && {
    "&.MuiGrid-item ": {
      paddingLeft: "0px !important",
    },
  };
  const nodeList = [
    {
      node: (
        <NameInput
          label="Merchant name"
          name="merchantInfo.merchantName"
          placeholder="Merchant name"
          isMerchantName
        />
      ),
      sm: 12,
    },

    {
      node: (
        <Text
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          color={palette.neutral[70]}
          fontWeight="book"
        >
          Billing Descriptor
        </Text>
      ),
      sm: 3,
    },
    {
      node: (
        <EditableTextContainer ml={0} pl={0}>
          {DESCRIPTOR_PREFIX}*
          <EditableText
            isUpperCase
            text={values.merchantInfo.billingDescriptor}
            onChange={(e) => handleChangeBillingDescriptor(e.target.value)}
            onSubmit={() => {
              if (
                !values.merchantInfo.billingDescriptor ||
                values.merchantInfo.billingDescriptor?.length < 3
              ) {
                handleChangeBillingDescriptor(values.merchantInfo.merchantName);
                return;
              }
            }}
          />
        </EditableTextContainer>
      ),
      sm: 9,
      sx: withNoPaddingLeft,
    },
    {
      node: (
        <Text color={palette.neutral[70]} fontWeight="book">
          Permalink
        </Text>
      ),
      sm: 2,
    },
    {
      node: (
        <EditableTextContainer>
          https://{values.merchantInfo.enterpriseSlug || "provider-slug"}
          .givepayments.com/
          <EditableText
            text={values.merchantInfo.merchantSlug}
            onChange={(e) => handleChangeSlug(e.target.value)}
            onSubmit={() => {
              if (!values.merchantInfo.merchantSlug) {
                handleChangeSlug(values.merchantInfo.merchantName);
                return;
              }
            }}
          />
        </EditableTextContainer>
      ),
      sm: 10,
      sx: withNoPaddingLeft,
    },
    {
      node: (
        <EnterprisesSelect
          name="merchantInfo.enterpriseID"
          slug="merchantInfo.enterpriseSlug"
          phone="merchantInfo.enterprisePhone"
          category="merchantInfo.category"
          isConfirmModal={Boolean(values.linkedBankAccountID)}
        />
      ),
      sm: 12,
      hidden: !isAcquirerPortal,
    },
    ...(modify_merchant
      ? [
          {
            node: (
              <RHFSelect
                label="Classification"
                name="merchantInfo.classification"
                options={classificationOptions}
              />
            ),
            sm: 12,
          },
        ]
      : []),
    {
      node: (
        <CategoriesSelect
          label="Merchant Category Code (MCC)"
          name="merchantInfo.category"
          placeholder="Merchant Category Code (MCC)"
          providerId={values.merchantInfo.enterpriseID}
          isAcquirer={isAcquirerPortal}
        />
      ),
      sm: 12,
      hidden: !modify_merchant,
    },
    {
      node: (
        <RHFTelInput
          label="Merchant Phone Number"
          name="merchantInfo.servicePhoneNumber"
          helperText={
            phoneHelper || "If empty, Provider’s number will be used instead "
          }
          fullWidth
        />
      ),
      sm: 12,
      hidden: !modify_merchant,
    },
    {
      node: <WebsiteInput name="merchantInfo.websiteURL" label="Website URL" />,

      sm: 12,
      hidden: !modify_merchant,
    },
    {
      node: (
        <RHFCustomAmountInput
          name="merchantInfo.estimatedAnnualRevenue"
          label="Estimated Annual Revenue"
          placeholder="ex. 900"
          bounded={false}
          currency="usd"
        />
      ),
      sm: 12,
      hidden: !modify_merchant,
    },
    {
      node: (
        <RHFCustomAmountInput
          name="merchantInfo.averageTicketAmount"
          label="Average Ticket Amount"
          placeholder="ex. 900"
          bounded={false}
          currency="usd"
        />
      ),

      sm: 12,
      hidden: !modify_merchant,
    },
    {
      node: (
        <RHFCustomAmountInput
          name="merchantInfo.highTicketAmount"
          label="High Ticket Amount"
          placeholder="ex. 900"
          bounded={false}
          currency="usd"
        />
      ),

      sm: 12,
      hidden: !modify_merchant,
    },
    {
      node: (
        <RHFInput
          name="merchantInfo.description"
          label="Purpose or Mission of Merchant"
          placeholder="Purpose or Mission of Merchant"
          multiline
          rows={6}
          fullWidth
          inputProps={{
            maxLength: "600",
          }}
          helperText={600 - values.merchantInfo.description?.length}
        />
      ),
      sm: 12,
      hidden: !modify_merchant,
    },
    {
      sm: 12,
      node: (
        <RHFCheckbox
          name="merchantInfo.serviceCountriesOutUSCanada"
          label="My Business services countries outside of the USA/Canada."
        />
      ),
      hidden: !modify_merchant,
    },
    {
      node: (
        <RHFInput
          name="merchantInfo.countriesServicedOutside"
          label="Countries Serviced Outside USA/Canada"
          placeholder="Please enter the countries serviced outside of USA/Canada."
          multiline
          rows={6}
          fullWidth
          sx={{
            display: values.merchantInfo.serviceCountriesOutUSCanada
              ? "initial"
              : "none",
          }}
        />
      ),
      sm: 12,
      shouldAnimate: false,
      hidden: !modify_merchant,
    },
  ];

  return (
    <Container>
      <FadeUpWrapper delay={50}>
        <Stack direction="row" spacing={1} alignItems="center">
          <StoreFrontIcon />
          <Text color={palette.neutral[80]} fontSize={18}>
            General
          </Text>
        </Stack>
      </FadeUpWrapper>
      {!modify_merchant && (
        <FadeUpWrapper delay={50}>
          <Text fontWeight="book" color={palette.neutral[70]}>
            Create a new merchant by entering the necessary details below. Once
            created, an email invitation will be sent to the provided email
            address to complete the signup process
          </Text>
        </FadeUpWrapper>
      )}

      <FadeUpWrapper delay={100}>
        <CreateFormAvatarUpload fieldName="merchantInfo.thumbnail" />
      </FadeUpWrapper>

      <Grid container spacing={2}>
        {nodeList.map(({ node, hidden, sm, sx = {} }, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={sm || 6}
            sx={{ ...(hidden && { display: "none" }), ...sx }}
          >
            <FadeUpWrapper delay={100 + 50 * (index + 1)}>{node}</FadeUpWrapper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

const Container = styled(Box)(() => ({
  display: "flex",
  padding: "24px 16px",
  flexDirection: "column",
  gap: "24px",
  borderRadius: "16px",
  border: `1px solid ${palette.neutral[10]}`,
  background: palette.background.dimmedWhite,
  boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.05)",

  "& .dzu-dropzone": {
    borderRadius: "8px !important",
  },
}));

const EditableTextContainer = styled(Text)(() => ({
  display: "flex",
  alignItems: "center",
  color: palette.neutral[70],
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "100%",
}));

type EditableTextProps = {
  text: string | React.ReactNode;
  isUpperCase?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onSubmit?: () => void;
};

const FONT = "15px Give Whyte";

const EditableText = ({
  text,
  isUpperCase = false,
  onChange,
  onSubmit,
}: EditableTextProps) => {
  const inputWidth = useMemo(() => {
    if (text) {
      const stringText = text.toString();
      const textValue = isUpperCase ? stringText.toUpperCase() : stringText;
      return getStringWidth(textValue, FONT);
    }

    return 0;
  }, [text]);

  const onBlur = () => {
    if (onSubmit) onSubmit();
  };

  return (
    <Input
      value={text}
      onChange={onChange}
      onBlur={onBlur}
      {...(isUpperCase && {
        inputProps: { style: { textTransform: "uppercase" } },
      })}
      sx={{
        width: inputWidth,
      }}
    />
  );
};

const Input = styled(TextField)(() => ({
  "& .MuiInputBase-root": {
    border: "none",
    padding: 0,

    "& input": {
      color: palette.neutral[70],
      fontSize: "14px",
      fontWeight: 400,
      margin: 0,
      "&:hover": {
        color: palette.neutral[80],
      },
      "&:focus-within": {
        color: palette.black[100],
      },
    },
    "&.Mui-focused": {
      backgroundColor: "rgba(21, 134, 255, 0.1)",
    },
  },
  "& .MuiInputBase-root.Mui-disabled": {
    boxShadow: "none",
    border: "none",

    "& input": {
      color: palette.black[100],
      fontSize: "12px",
      lineHeight: "14.4px",
      fontWeight: 350,
      height: "15px",
      margin: 0,
      backgroundColor: "rgba(21, 134, 255, 0.1)",
      WebkitTextFillColor: palette.black[100],
    },
  },
}));

export default CreateMerchantGeneral;
