type IconProps = {
  height?: number;
  width?: number;
  stroke?: string;
};

const EmailIcon = ({
  width = 49,
  height = 48,
  stroke = "#A9AFBD",
}: IconProps) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill="black" fillOpacity="0.1" />
      <path
        d="M33 16.5H15C14.8011 16.5 14.6103 16.579 14.4697 16.7197C14.329 16.8603 14.25 17.0511 14.25 17.25V30C14.25 30.3978 14.408 30.7794 14.6893 31.0607C14.9706 31.342 15.3522 31.5 15.75 31.5H32.25C32.6478 31.5 33.0294 31.342 33.3107 31.0607C33.592 30.7794 33.75 30.3978 33.75 30V17.25C33.75 17.0511 33.671 16.8603 33.5303 16.7197C33.3897 16.579 33.1989 16.5 33 16.5ZM31.0716 18L24 24.4828L16.9284 18H31.0716ZM32.25 30H15.75V18.9553L23.4928 26.0531C23.6312 26.1801 23.8122 26.2506 24 26.2506C24.1878 26.2506 24.3688 26.1801 24.5072 26.0531L32.25 18.9553V30Z"
        fill="#292928"
      />
    </svg>
  );
};

export default EmailIcon;
