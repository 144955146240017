import { AxiosError } from "axios";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { IDropzoneProps } from "react-dropzone-uploader";
// form
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
// modal
import NiceModal from "@ebay/nice-modal-react";
import { MERCHANT_ADD_CUSTOMER } from "modals/modal_names";
// mui
import { useTheme, useMediaQuery } from "@mui/material";
// components
import { showMessage } from "@common/Toast/ShowToast";
// api
import { customInstance } from "@services/api";
import { useUploadCustomerImage } from "./useUploadCustomerImage";
// utils
import { getUnixTime, subYears } from "date-fns";
import { matchIsValidTel } from "mui-tel-input";
import { convertPhoneNumber, toUTCStartOfDay } from "@utils/date.helpers";
import {
  MINIMUM_REQUIRED_AGE,
  MAXIMUM_REQUIRED_AGE,
} from "@constants/constants";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useCreateCustomerMutation } from "./useCreateCustomerMutation";

export type CustomerData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dateOfBirth: number | Date | null;
  address: null | {
    country: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
    line1: string | null;
  };
  occupation: string;
  employer: string;
  notes: string;
  avatarURL: string | File;
};

const isAnyTruthy = (...values: string[]) => values.some(Boolean);

export const useCreateCustomer = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const queryClient = useQueryClient();
  const { handlePostImage } = useUploadCustomerImage();
  const { merchantId } = useGetCurrentMerchantId();

  const schema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Email is required")
      .test({
        message: () => "Email already registered",
        test: async (value) => {
          if (value) {
            try {
              const response = await customInstance({
                url: `/merchants/${merchantId}/customers/email-check`,
                method: "POST",
                data: {
                  email: value,
                },
              });

              if (response.isAvailable) {
                return true;
              } else {
                return false;
              }
            } catch (error) {
              console.log(error);
            }
          }
          return false;
        },
      }),
    phone: Yup.string()
      .nullable()
      .when({
        is: (exists: string) => Boolean(exists !== "+1" && dirtyFields.phone),
        then: (schema) =>
          schema.test({
            name: "is-valid-number",
            message: "Phone number already exists",
            test: async function (value) {
              if (!value || value.length === 0) return true;
              const isValid = matchIsValidTel(value as string);

              if (isValid) {
                try {
                  const response = await customInstance({
                    url: `/merchants/${merchantId}/customers/phonenumber-check`,
                    method: "POST",
                    data: {
                      phoneNumber: convertPhoneNumber(value),
                    },
                  });
                  if (response.isAvailable) {
                    return true;
                  } else {
                    return false;
                  }
                } catch (error) {
                  console.log(error);
                }
              }
              return this.createError({ message: "Insert valid phone number" });
            },
          }),
      }),
    dateOfBirth: Yup.date()
      .required("Date of birth is required")
      .typeError("Please provide a valid date")
      .min(subYears(new Date(), MAXIMUM_REQUIRED_AGE), "Must be 100 or less")
      .max(subYears(new Date(), MINIMUM_REQUIRED_AGE), `Must be 18 or older`),
    address: Yup.object().shape({
      state: Yup.string().nullable(),
      city: Yup.string().nullable(),
      line1: Yup.string().nullable(),
      zip: Yup.string()
        .nullable()
        .when({
          is: (exists: string) => !!exists,
          then: Yup.string().matches(
            /^[0-9]{5}(?:-?[0-9]{4})?$/,
            "Invalid ZIP format",
          ),
        }),
    }),
    occupation: Yup.string().matches(
      /^[a-zA-Z\s]*$/,
      "Please enter a valid occupation",
    ),
    employer: Yup.string().matches(
      /^[a-zA-Z\s]*$/,
      "Please enter a valid employer",
    ),
  });

  const defaultValues: CustomerData = {
    lastName: "",
    firstName: "",
    email: "",
    phone: "",
    address: null,
    dateOfBirth: null,
    notes: "",
    employer: "",
    occupation: "",
    avatarURL: "",
  };

  const methods = useForm<CustomerData>({
    resolver: yupResolver(schema),
    defaultValues,
    reValidateMode: "onChange",
  });

  const createCustomerMutation = useCreateCustomerMutation(merchantId);

  const { isLoading, isSuccess } = createCustomerMutation;
  const {
    setValue,
    formState: { dirtyFields },
  } = methods;

  const handleChangeStatus: IDropzoneProps["onChangeStatus"] = ({ file }) => {
    if (file) {
      setValue(
        "avatarURL",
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
    }
  };

  const onSubmit: SubmitHandler<CustomerData> = async (data) => {
    let url = "";
    if (typeof data.avatarURL === "string") {
      url = data.avatarURL;
    } else {
      url = await handlePostImage(data.avatarURL);
    }

    const customData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: convertPhoneNumber(data.phone),
      dateOfBirth: !data.dateOfBirth
        ? null
        : typeof data.dateOfBirth === "number"
        ? data.dateOfBirth
        : getUnixTime(toUTCStartOfDay(data.dateOfBirth)),
      occupation: data.occupation,
      employer: data.employer,
      notes: data.notes,
      avatarURL: url ? url : null,
      address:
        data.address && Object.values(data.address).some(Boolean)
          ? {
              ...data.address,
              country: "US",
            }
          : null,
    };

    createCustomerMutation.mutate([customData], {
      onError: (error: unknown) => {
        const axiosError = error as AxiosError;
        const errorMessage = axiosError.response?.data;
        showMessage("Error", `${errorMessage}`);
      },
      onSuccess: async (res: any) => {
        if (res.created !== null) {
          NiceModal.hide(MERCHANT_ADD_CUSTOMER);
          showMessage("Success", "", true, "Customer added");
          queryClient.refetchQueries("list-all-customers");
          queryClient.refetchQueries("get-customers-stats");
        }

        if (res.failed !== null) {
          for (const failed of res.failed) {
            if (failed.error.Code === "invalid_input") {
              const inputErrors = failed.error.Input;

              for (const err of inputErrors) {
                showMessage("Error", `${err.message}`, isDesktop, "", 6000);
              }
            } else {
              showMessage("Error", `${failed.error.Code}`);
            }
          }
        }
      },
    });
  };

  return {
    methods,
    onSubmit,
    isLoading: isLoading,
    isDisabled: isLoading || isSuccess,
    handleChangeStatus,
  };
};
