import KotoStepper from "@common/SignUp/KotoStepper";
import { Box, Stack } from "@mui/material";
import { useCallback, useMemo, useRef, useState } from "react";
import {
  ProfileSetupFormActions,
  ProfileSetupFormContainer,
} from "../form.components";
import { HiddenComponent } from "@containers/HiddenComponent";
import { MerchantType } from "@customTypes/merchant.preview.types";
import SignTOS from "./SignTOS";
import { FormProvider } from "react-hook-form";
import useEnterpriseAgreement from "./useEnterpriseAgreement";
import {
  merchantInfoParser,
  primaryAccountHolderParser,
  merchantAgreementParser,
} from "@components/Merchants/MerchantPreview/helpers/parsers";

type Props = {
  backLink: () => void;
  data?: MerchantType["merchant"];
};

const EnterpriseAgreement = ({ backLink, data }: Props) => {
  const [step, setStep] = useState(StepsEnum.AGREEMENT);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const stepperRef = useRef<any>(null);

  const [statusBar, setStatusBar] = useState([
    {
      label: StepsEnum.AGREEMENT,
      barValue: 0,
    },
  ]);

  const merchantData = useMemo(
    () => ({
      merchantInfo: merchantInfoParser(data),
      primaryAccountHolder: primaryAccountHolderParser({ data: data?.owner }),
      merchantAgreement: merchantAgreementParser(data),
      status: {
        statusName: "pending",
      },
    }),
    [data],
  );

  const handleUpdateStatusValue = useCallback(
    (label: string) => (value: number) => {
      setStatusBar((prev) =>
        prev.map((s) => {
          if (s.label !== label) return s;
          return {
            ...s,
            barValue: value,
          };
        }),
      );
    },
    [],
  );

  const { methods, onSubmit, isDisabled } = useEnterpriseAgreement(
    handleUpdateStatusValue(StepsEnum.AGREEMENT),
    merchantData,
    backLink,
  );

  const { hasAgreed } = methods.watch();

  const content = [
    {
      node: (
        <FormProvider {...methods}>
          <SignTOS data={merchantData} signDisabled={!hasAgreed} />
        </FormProvider>
      ),
      visible: step === StepsEnum.AGREEMENT && isLoaded,
    },
  ];

  return (
    <Stack
      direction="column"
      justifyContent="stretch"
      height="inherit"
      width="100%"
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <KotoStepper
          sx={{ flexGrow: 1 }}
          setStep={setStep}
          defaultStep={StepsEnum.AGREEMENT}
          customSteps={statusBar}
          ref={stepperRef}
          setIsLoaded={setIsLoaded}
        />
      </Stack>
      <Box
        component="form"
        flexGrow={1}
        id="enterprise-agreement-form"
        display="flex"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <ProfileSetupFormContainer>
          <Stack direction="column" gap={4} height="min-content">
            {content.map(({ node, visible }, index) => (
              <HiddenComponent key={index} hidden={!visible}>
                {node}
              </HiddenComponent>
            ))}
          </Stack>
          <ProfileSetupFormActions
            secondaryAction={{
              onClick: backLink,
            }}
            primaryAction={{
              disabled: isDisabled,
              children: "Done",
              form: "enterprise-agreement-form",
            }}
          />
        </ProfileSetupFormContainer>
      </Box>
    </Stack>
  );
};

export default EnterpriseAgreement;

enum StepsEnum {
  AGREEMENT = "Agreement",
}
