import { Container } from "../StatusReview/components/StatusReviewAtoms";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { Box, Stack, styled } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import SponsorApprovalTag, {
  SponsorApprovalStatus,
} from "./SponsorApprovalTag";
import { Button } from "@common/Button";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { EditButton } from "../../components/atoms";
import { ConversationsIconNotFilled } from "@assets/icons";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { MSP, TStatusReview } from "../../data.types";
import { useStatusReview } from "../../hooks/useStatusReview";
import { FormProvider } from "react-hook-form";
import AbleToComponent from "../StatusReview/components/AbleToComponent";
import { useResyncMSP } from "../../hooks/useResyncMSP";
import NiceModal from "@ebay/nice-modal-react";
import useCheckSponsor from "@hooks/common/useCheckSponsor";
import { MERCHANT_INITIAL_APPROVAL_MODAL } from "modals/modal_names";
import { MerchantActionsHistory } from "../StatusReview/components/ActionsHistory";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import { checkPortals } from "@utils/routing";

type Props = {
  data: TStatusReview;
  name: string;
  mspStatus: MSP;
};

const SponsorApproval = ({ data, name, mspStatus }: Props) => {
  const { isMobileView } = useCustomTheme();
  const { merchant_underwriting } = useEnterprisePermissions();
  const { openConversationHandler } = useConversationsModal();
  const {
    methods,
    handleSponsorApprove,
    handleSponsorDecline,
    handleMarkAsPending,
    handleChangeProcessing,
    handleChangeTransfer,
    isMerchantApproved,
  } = useStatusReview({ data, name });
  const { isLoading, handleResyncMSP } = useResyncMSP(data.accID);
  const { isUpdateUnderwriterAllowed } = useUnderwriterPermissions();
  const { isAcquirerPortal } = checkPortals();

  const { watch } = methods;
  const values = watch();

  const status = data?.statusName;
  const sponsorStatusName = data?.sponsorStatusName;
  const { hasSponsorAccess } = useCheckSponsor();

  const isAccountOverview =
    sponsorStatusName && ["approved", "declined"].includes(sponsorStatusName);

  const showReason =
    sponsorStatusName && ["pending", "declined"].includes(sponsorStatusName);

  const sponsorStatusDidUpdate =
    sponsorStatusName &&
    ["pending", "declined", "approved"].includes(sponsorStatusName);

  const accountStatus = isAccountOverview
    ? sponsorStatusName
    : status === "preapproved_mid_issue"
    ? data?.lastInternalApprovalType
    : status;

  /** if sponsor already acted on the status then only sponsor is able to change it
   * if sponsor hasnt acted on the status then only non-sponsor users with update underwriting permission is able to change it
   */
  const isRevertAllowed = sponsorStatusDidUpdate
    ? hasSponsorAccess // sponsor action
    : isUpdateUnderwriterAllowed; // underwriting action;

  const handleRevertSponsorStatus = () => {
    if (sponsorStatusName === "approved") handleSponsorDecline();
    else if (sponsorStatusName === "declined") handleSponsorApprove();
  };

  const handleRevertUnderwritingStatus = () => {
    NiceModal.show(MERCHANT_INITIAL_APPROVAL_MODAL, {
      merchantId: data.accID,
      defaultStatus: data?.statusName,
    });
  };

  const handleRevertStatus = () => {
    if (!isRevertAllowed) return;
    if (sponsorStatusDidUpdate) {
      handleRevertSponsorStatus();
    } else {
      handleRevertUnderwritingStatus();
    }
  };

  const handleOpenConversation = () =>
    openConversationHandler({
      id: 0,
      name: "Sponsor",
      paths: [],
    });

  return (
    <FormProvider {...methods}>
      <Container
        component="form"
        isMobile={isMobileView}
        sx={{
          gap: isMobileView ? 0 : "24px",
          padding: "16px",
          borderRadius: "12px",
          "&:hover": {
            "& .MuiButtonBase-root.MuiButton-root": {
              visibility: "visible",
            },
          },
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Stack direction="row" spacing={1} alignItems="center">
            <Text fontSize={18} color={palette.neutral[90]}>
              {isAccountOverview
                ? "Account Status Overview"
                : "Sponsor Approval"}
            </Text>
            {!isAccountOverview && sponsorStatusName && (
              <SponsorApprovalTag
                tag={sponsorStatusName as SponsorApprovalStatus}
              />
            )}
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            {merchant_underwriting && isAcquirerPortal && (
              <EditButton onClick={handleOpenConversation}>
                <ConversationsIconNotFilled section="sponsor" />
              </EditButton>
            )}
            <SponsorApprovalTag
              tag={accountStatus as SponsorApprovalStatus}
              isDropdown={isRevertAllowed}
              onClick={handleRevertStatus}
            />
          </Stack>
        </Box>
        {showReason && (
          <ReasonBox>
            <Text color={palette.neutral[80]}>Reason</Text>
            <Text color={palette.neutral[80]} fontWeight="book">
              {data?.sponsorStatusReason}
            </Text>
          </ReasonBox>
        )}
        {!isAccountOverview && hasSponsorAccess && isAcquirerPortal && (
          <Box display="flex" justifyContent="space-between">
            <Box>
              {sponsorStatusName !== "pending" && (
                <Button
                  background="tertiary"
                  sx={{
                    padding: 0,
                  }}
                  onClick={handleMarkAsPending}
                >
                  Mark as Pending
                </Button>
              )}
            </Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <DeclineButton
                background="secondary"
                onClick={handleSponsorDecline}
              >
                Decline
              </DeclineButton>
              <ApproveButton onClick={handleSponsorApprove}>
                Approve
              </ApproveButton>
            </Stack>
          </Box>
        )}
        {isAccountOverview && <MerchantActionsHistory data={data} />}
        {sponsorStatusName === "approved" && (
          <AbleToComponent
            handleChangeProcessing={handleChangeProcessing}
            handleChangeTransfer={handleChangeTransfer}
            processValue={values.process}
            transferValue={values.transfer}
            isApproved={isMerchantApproved}
            handleResyncMSP={handleResyncMSP}
            isSuspended={false}
            isMerchantApproved={isMerchantApproved}
            allowTransfers={data.allowedToTransfer}
            isLoading={isLoading}
            lastMSPSyncAt={mspStatus.lastMSPSyncAt}
            canSyncMSPData={mspStatus.canSyncMSPData}
          />
        )}
      </Container>
    </FormProvider>
  );
};

const ApproveButton = styled(Button)(() => ({
  padding: "8px 24px",
  height: "32px",
  fontWeight: 400,
  color: palette.neutral.white,
  background: palette.filled.success,
  "&:hover": {
    color: palette.neutral.white,
    background: palette.filled.success,
  },
}));

const DeclineButton = styled(Button)(() => ({
  padding: "8px 24px",
  height: "32px",
  fontWeight: 400,
  border: `1px solid ${palette.neutral[80]}`,
}));

const ReasonBox = styled(Box)(() => ({
  padding: "12px 16px",
  borderRadius: "16px",
  border: `1px solid ${palette.neutral[20]}`,
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  background: palette.primary.background,
}));

export default SponsorApproval;
