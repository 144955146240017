import NiceModal from "@ebay/nice-modal-react";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { SAVE_MINI_BUILDER_DRAFT } from "modals/modal_names";
import { X } from "@phosphor-icons/react";

export const SaveAndCloseBtn = () => {
  return (
    <GiveIconButton
      Icon={X}
      size="large"
      variant="ghost"
      iconText="Save and Close"
      onClick={() => {
        NiceModal.show(SAVE_MINI_BUILDER_DRAFT);
      }}
      sx={{
        display: "flex",
        gap: 1,
      }}
    />
  );
};
