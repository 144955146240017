import { Box } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { format } from "date-fns";

type TCustomToolbarProps = {
  date: any;
};

const CustomToolbar = ({ date }: TCustomToolbarProps) => {
  return (
    <Box width="100%" padding="24px" paddingBottom="0">
      <GiveText
        variant="h3"
        sx={{
          fontWeight: 300,
        }}
      >
        {format(date, "EEE, MMM d")}
      </GiveText>
    </Box>
  );
};

export default CustomToolbar;
