import { Text } from "@common/Text";
import { Box } from "@mui/material";
import { palette } from "@palette";

type TProps = {
  message: string | null;
};

const WarningBanner = ({ message }: TProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: palette.tag.warning.bg,
        padding: "12px 16px",
        borderRadius: "12px",
      }}
    >
      <Text
        sx={{
          color: palette.warning.text,
          fontSize: "14px",
          fontWeight: 350,
          lineHeight: "16.8px",
        }}
      >
        {`Required information: ${message}`}
      </Text>
    </Box>
  );
};

export default WarningBanner;
