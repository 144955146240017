import * as React from "react";
import { palette } from "@palette";
// @mui
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
// components
import { ExpandedRow } from "./ExpandedRow";
import { Text } from "@common/Text";
import { Tag } from "@common/Tag";
import { DropdownItem } from "@common/Select";
import { Table_V2 as Table, TableColumnType } from "@common/Table";
// data
// assets
import { MoreIcon } from "@assets/icons";
// localization
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
import NiceModal from "@ebay/nice-modal-react";
import {
  DELETE_CONFIRMATION_MODAL,
  NEW_ACCOUNT_MODAL,
  UPLOAD_STATEMENT_MODAL,
} from "modals/modal_names";
import { ActionsComponent } from "@common/Table/components/ActionsComponent";
import { ROWS_PER_PAGE } from "@hooks/common/usePagination";
import { HiddenComponent } from "containers/HiddenComponent";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";
import RESOURCE_BASE, {
  DELETE_DENY_MESSAGE,
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { IMerchantBankAccount } from "@components/Merchants/MerchantPreview/data.types";
import { buildMerchantEndpoints } from "@services/api/utils.api";
import { useAccessControl } from "features/Permissions/AccessControl";
import { Tooltip } from "@common/Tooltip";

export type BankAccountTableProps = {
  rows: IMerchantBankAccount[];
  page: number;
  setPage?: () => void;
  sorting?: string;
  totalRows: number;
  loadingRef: React.MutableRefObject<boolean>;
  isLoading?: boolean;
  handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
};

type ModifiedDropdownItemProps = {
  onClick: () => void;
  isBankAccountLinked: boolean | undefined;
  children: React.ReactNode;
  isAllowedEdit?: boolean;
};

const ModifiedDropDownItem = ({
  onClick,
  isBankAccountLinked = false,
  children,
  isAllowedEdit,
}: ModifiedDropdownItemProps) => {
  if (!isBankAccountLinked) {
    return (
      <CustomToolTip message={EDIT_DENY_MESSAGE} showToolTip={!isAllowedEdit}>
        <DropdownItem disabled={!isAllowedEdit} onClick={onClick}>
          {children}
        </DropdownItem>
      </CustomToolTip>
    );
  }

  return (
    <Tooltip
      maxWidth="263px"
      bgColor={palette.neutral[80]}
      titleSx={{
        textAlign: "left",
      }}
      title={
        <Text color={palette.neutral[5]}>
          {isAllowedEdit
            ? "You cannot upload statements for this bank account because it islinked to a Provider account."
            : EDIT_DENY_MESSAGE}
        </Text>
      }
      sx={{
        justifyContent: "flex-start",
      }}
    >
      <DropdownItem disabled>{children}</DropdownItem>
    </Tooltip>
  );
};

export const BankAccountTable = (props: BankAccountTableProps) => {
  const { rows, page, totalRows, isLoading, handlePageChange } = props;
  const { t } = useTranslation(namespaces.common);

  const isDeleteAllowed = useAccessControl({
    resource: RESOURCE_BASE.BANK_ACCOUNT,
    operation: OPERATIONS.DELETE,
    withPortal: true,
  });

  const isEditAllowed = useAccessControl({
    resource: RESOURCE_BASE.BANK_ACCOUNT,
    operation: OPERATIONS.UPDATE,
    withPortal: true,
  });

  const [selectedBankAccount, setSelectedBankAccount] =
    React.useState<IMerchantBankAccount | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    data: IMerchantBankAccount,
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedBankAccount(data);
  };

  const handleCloseMenu = () => {
    if (anchorEl) setAnchorEl(null);
  };
  const deleteBankAccountHandler = (row: IMerchantBankAccount | null) => {
    const url = buildMerchantEndpoints(`bank-accounts/${row?.id}`);
    NiceModal.show(DELETE_CONFIRMATION_MODAL, {
      variant: "bank_account",
      itemName: row?.name,
      url: url,
    });
    setAnchorEl(null);
  };

  const editBankAccountHandler = () => {
    NiceModal.show(NEW_ACCOUNT_MODAL, {
      data: selectedBankAccount,
    });
    handleCloseMenu();
  };

  const uploadStatementBankAccountHandler = () => {
    NiceModal.show(UPLOAD_STATEMENT_MODAL, { data: selectedBankAccount });
    handleCloseMenu();
  };

  const columns: TableColumnType[] = [
    {
      key: "name",
      sortable: true,
      columnWidth: 2,
      title: t("transactionTable.Account", { ns: namespaces.common }),
      hasExpandButton: true,
      renderColumn: (row: IMerchantBankAccount) => (
        <Box>
          <Text sx={bankAccountNameStyle}>{row.name}</Text>
          <Text color={palette.neutral[800]}>{row.bankName}</Text>
        </Box>
      ),
    },
    {
      key: "statusDisplayName",
      sortable: true,
      columnWidth: 2,
      title: t("transactionTable.Status", { ns: namespaces.common }),
      renderColumn: (row: IMerchantBankAccount) => (
        <Tag shouldShowIcon type={row.status} />
      ),
    },
    {
      key: "actions",
      renderColumn: (row: IMerchantBankAccount) => {
        const isDisabled = row.isLinked && row.status !== "upload";
        return (
          <Box sx={{ marginLeft: "auto" }}>
            <ActionsComponent
              title="More"
              disabled={isDisabled}
              onClick={(e) => handleOpenMenu(e, row)}
              icon={() => <MoreIcon size="20px" />}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <Box sx={bankAccountTableWrapper}>
      <Table
        expandable
        isLoading={isLoading}
        data={rows}
        rowRenderKey={(row) => row.id}
        columns={columns}
        renderExpandedRow={ExpandedRow}
        withPagination={totalRows > ROWS_PER_PAGE}
        page={page}
        handlePageChange={handlePageChange}
        totalRecords={totalRows}
        emptyState="bankAccount"
        noTrailing
      />

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPaper-root": {
            width: 159,
          },
        }}
      >
        <HiddenComponent hidden={selectedBankAccount?.isLinked === true}>
          <CustomToolTip
            showToolTip={!isEditAllowed}
            message={EDIT_DENY_MESSAGE}
          >
            <DropdownItem
              onClick={editBankAccountHandler}
              disabled={!isEditAllowed}
              data-testid="bank-account-table-edit-button"
            >
              <Text>{t("buttons.Edit", { ns: namespaces.common })}</Text>
            </DropdownItem>
          </CustomToolTip>
        </HiddenComponent>
        {selectedBankAccount?.status === "upload" && (
          <ModifiedDropDownItem
            isAllowedEdit={isEditAllowed}
            isBankAccountLinked={selectedBankAccount?.isLinked}
            onClick={uploadStatementBankAccountHandler}
          >
            <Text>
              {t("buttons.UploadStatement", { ns: namespaces.common })}
            </Text>
          </ModifiedDropDownItem>
        )}
        <HiddenComponent hidden={selectedBankAccount?.isLinked === true}>
          <CustomToolTip
            showToolTip={!isDeleteAllowed}
            message={DELETE_DENY_MESSAGE}
          >
            <DropdownItem
              disabled={!isDeleteAllowed}
              onClick={() => deleteBankAccountHandler(selectedBankAccount)}
              data-testid="bank-account-table-delete-button"
            >
              <Text color={palette.error.main}>Delete</Text>
            </DropdownItem>
          </CustomToolTip>
        </HiddenComponent>
      </Menu>
    </Box>
  );
};

export const bankAccountTableWrapper = {
  marginTop: "16px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  overflowX: "hidden",
  "& .MuiTableContainer-root": {
    margin: 0,
    padding: 0,
    backgroundColor: palette.liftedWhite.main,
  },
  "& .MuiTableRow-root": {
    backgroundColor: "transparent",
  },
  "& .MuiTableCell-root": {
    border: "none",
  },
  "& .MuiButtonBase-root": {
    backgroundColor: "transparent",
  },
  "& .tableHeader-status": {
    paddingRight: 0,
    display: "flex",
    justifyContent: "end",
  },
  "& .tableBody-status": {
    paddingRight: 0,
    display: "flex",
    justifyContent: "end",
  },
  "& .tableBody-actions": {
    paddingRight: 0,
    width: "12px",
  },
  "& .table-container": {
    padding: 0,
  },
};

export const bankAccountEmptyStyle = {
  height: 214,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const bankAccountNameStyle = {
  fontWeight: "500",
  color: palette.neutral[800],
  width: "250px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
