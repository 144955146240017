import NiceModal from "@ebay/nice-modal-react";
import { RISK_MONITOR_FILTERS_MODAL } from "modals/modal_names";
import { defaultValuesRiskMonitorTrans } from "./modal/useTransactionFilter";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { QKEY_RISK_MONITOR_TRANSACTIONS } from "@constants/queryKeys";
import {
  resetFilterByKey,
  selectFiltersObject,
} from "@redux/slices/dynamicFilterSlice";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { countTopLevelDifferences } from "@utils/helpers";
import FilterAtomButton from "@common/Button/FilterAtomButton";

function FilterBtn() {
  const queryClient = useQueryClient();
  const handleOpenModal = () => NiceModal.show(RISK_MONITOR_FILTERS_MODAL);
  const dispatch = useAppDispatch();
  const filterObject = useAppSelector((state) =>
    selectFiltersObject(state, QKEY_RISK_MONITOR_TRANSACTIONS),
  );

  const [cachedData, setCachedData] = useState<any>(null);

  useEffect(() => {
    const data = queryClient.getQueryData(QKEY_RISK_MONITOR_TRANSACTIONS);
    setCachedData(data);

    const unsubscribe = queryClient.getQueryCache().subscribe((event: any) => {
      if (event.query.queryKey === QKEY_RISK_MONITOR_TRANSACTIONS) {
        setCachedData(event.query.state.data);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [queryClient]);
  const handleResetFilters = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    dispatch(
      resetFilterByKey({
        filterKey: QKEY_RISK_MONITOR_TRANSACTIONS,
      }),
    );
  };
  const filtersAmount = isEmpty(filterObject)
    ? null
    : countTopLevelDifferences(filterObject, defaultValuesRiskMonitorTrans);

  return (
    <FilterAtomButton
      filtersAmount={filtersAmount}
      disabled={isEmpty(cachedData)}
      handleResetFilters={handleResetFilters}
      handleOpenModal={handleOpenModal}
    />
  );
}

export default FilterBtn;
