import { ChevronRight } from "@assets/icons/RebrandedIcons";
import { StyledIconButton } from "@common/IconButton";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import { capitalizeFirstLetter } from "@common/Table/helpers";
import { Text } from "@common/Text";
import SwipeableDrawerMobile from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { TToolbarAction } from "../layout/types";
import { TDocument } from "../types";

type DrawerProps = {
  open: boolean;
  handleClose: () => void;
  actions: TToolbarAction[];
  merchantID: number;
  file: TDocument;
  merchantType: "acquirer" | "enterprise" | "merchant";
};

const DocumentMobileDrawer = ({
  handleClose,
  open,
  actions,
  file,
}: DrawerProps) => {
  const { name } = file;
  const extensionRegex = /\.[^/.]+$/;
  const extension = name.match(extensionRegex);
  const fileName = extension ? name.replace(extension[0], "") : name;
  return (
    <SwipeableDrawerMobile
      anchor="bottom"
      onOpen={() => undefined}
      open={open}
      onClose={handleClose}
      sx={{ zIndex: 2000 }}
      PaperProps={{
        sx: {
          background: palette.neutral[5],
          top: "50%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "32px 16px",
        },
      }}
    >
      <>
        <Text
          color={palette.black[100]}
          variant="h6"
          lineHeight="21.6px"
          fontWeight="book"
          letterSpacing="-0.18px"
          marginTop="16px"
          marginBottom="24px"
          sx={{ wordBreak: "break-all" }}
          data-testid="preview-drawer-filename"
        >
          {fileName}
          {extension}
        </Text>
        {actions?.map((action: TToolbarAction) => {
          const { label, disabled, onClick, hidden } = action;
          if (hidden) return;
          return (
            <WithTooltipWrapper
              key={label}
              hasTooltip={!!action.tooltipProps}
              tooltipProps={action.tooltipProps}
              fullWidth
            >
              <MobileAction
                isDelete={label === "delete"}
                onClick={disabled ? undefined : onClick}
                data-testid={`preview-drawer-${label.replace(/\s+/, "-")}`}
              >
                {capitalizeFirstLetter(label)}
              </MobileAction>
            </WithTooltipWrapper>
          );
        })}
      </>
    </SwipeableDrawerMobile>
  );
};

const MobileAction = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isDelete",
})<{ isDelete?: boolean }>(({ isDelete }) => ({
  padding: "4px 8px",
  borderRadius: "4px",
  color: isDelete ? palette.filled.red : palette.black[100],
  fontSize: "14px",
  fontWeight: 350,
  lineHeight: "32px",
  width: "100%",
  marginBottom: "12px",
  backgroundColor: palette.neutral["white"],
  "&:active": {
    backgroundColor: palette.liftedWhite[100],
  },
}));

interface IMobileHeader {
  handleBack: () => void;
  label: string;
}

export const MobileHeader = ({ handleBack, label }: IMobileHeader) => {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <StyledIconButton
        data-testid="edit-back-button"
        onClick={handleBack}
        sx={{
          maxWidth: "32px",
          maxHeight: "32px",
          borderRadius: "4px",
          padding: "0 !important",
        }}
      >
        <ChevronRight
          rotate={180}
          width={28}
          height={28}
          stroke={palette.gray[300]}
        />
      </StyledIconButton>
      <Text
        color={palette.black[100]}
        variant="h6"
        lineHeight="21.6px"
        fontWeight="book"
        letterSpacing="-0.18px"
      >
        {label}
      </Text>
    </Stack>
  );
};

export default DocumentMobileDrawer;
