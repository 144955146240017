import { Stack } from "@mui/material";
import GiveButton from "@shared/Button/GiveButton";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import { ReasonType } from "../components/types";

type ChallengeButtonsProps = {
  isChallengeDone: boolean;
  hasRejectButton: boolean;
  methods: UseFormReturn<ReasonType>;
  handleClose: () => void;
};

const ChallengeButtons = ({
  isChallengeDone,
  hasRejectButton,
  methods,
  handleClose,
}: ChallengeButtonsProps) => {
  // TODO: Add logic
  const onDone: SubmitHandler<ReasonType> = async (data) => {
    handleClose();
  };

  // TODO: Add logic
  const onReject = () => {
    handleClose();
  };

  return (
    <Stack gap="12px" flexDirection="row">
      {isChallengeDone ? (
        <GiveButton
          label="Close"
          variant="filled"
          size="large"
          onClick={handleClose}
        />
      ) : (
        <>
          {hasRejectButton && (
            <GiveButton
              onClick={onReject}
              label="Reject"
              variant="ghost"
              size="large"
              color="destructive"
            />
          )}
          <GiveButton
            label="Done"
            variant="filled"
            size="large"
            onClick={methods.handleSubmit(onDone)}
          />
        </>
      )}
    </Stack>
  );
};

export default ChallengeButtons;
