import { customInstance } from "@services/api";
import { useState } from "react";

const getCardNumber = ({ cardNumber }: { cardNumber: string }) => {
  return customInstance({
    method: "POST",
    url: "/cart/payment-method",
    data: {
      number: cardNumber.replace(/\s/g, ""),
    },
  });
};

export const useGetCardInfos = () => {
  const [cardObject, setCardObject] = useState<Record<any, any>>();

  const handleCardNumberCheck = async (cardNumber: string) => {
    try {
      const cardObj = await getCardNumber({ cardNumber });

      setCardObject(cardObj);
    } catch (err) {
      //
    }
  };
  return { handleCardNumberCheck, cardObject };
};
