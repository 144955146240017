import { CaretRight } from "@assets/icons";
import GearSixIcon from "@assets/rebrandIcons/GearSixIcon";
import { Button } from "@common/Button";
import { ButtonProps } from "@common/Button/Button";
import { StyledIconButton } from "@common/IconButton";
import { Text } from "@common/Text";
import { Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

type TCommonProps = {
  filters: TFilter[];
  showMarkAllAsRead: boolean;
  markAllAsRead: VoidFunction;
  handleConfigurationClick: VoidFunction;
};

export const DesktopHeader = ({
  filters,
  showMarkAllAsRead,
  markAllAsRead,
  handleConfigurationClick,
}: TCommonProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      padding="0 16px"
      borderBottom={`1px solid ${palette.liftedWhite[200]}`}
    >
      <SubsetOne>
        {filters.map((f) => (
          <FilterElement key={f.label} {...f} />
        ))}
      </SubsetOne>
      <SubsetOne gap={1}>
        {showMarkAllAsRead && <MarkAllAsRead onClick={markAllAsRead} />}
        <ConfigurationButton onClick={handleConfigurationClick} />
      </SubsetOne>
    </Stack>
  );
};

export const MobileHeader = ({
  filters,
  onClose,
  showMarkAllAsRead,
  markAllAsRead,
  handleConfigurationClick,
}: TCommonProps & {
  onClose: () => void;
}) => {
  return (
    <Stack
      direction="column"
      alignItems="stretch"
      padding="0 16px 16px"
      gap={2}
    >
      <SubsetTwo>
        <BackButton data-testid="back-button-mobile-screen" onClick={onClose} />
        {showMarkAllAsRead && <MarkAllAsRead onClick={markAllAsRead} />}
      </SubsetTwo>
      <SubsetTwo>
        <SubsetOne gap={1}>
          {filters.map((f) => (
            <FilterElement key={f.label} {...f} />
          ))}
        </SubsetOne>
        <ConfigurationButton onClick={handleConfigurationClick} />
      </SubsetTwo>
    </Stack>
  );
};

const SubsetOne = styled(Stack)(() => ({
  flexDirection: "row",
  alignItems: "center",
}));

const SubsetTwo = styled(Stack)(() => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}));

const BackButton = (props: ButtonProps) => (
  <BackButtonBase background="tertiary" {...props}>
    <CaretRight rotate={180} fill={palette.gray[300]} /> Notifications
  </BackButtonBase>
);

const MarkAllAsRead = (props: ButtonProps) => (
  <BlueButtonBase background="tertiary" {...props}>
    Mark all as read
  </BlueButtonBase>
);

const ConfigurationButton = ({ onClick }: { onClick: VoidFunction }) => {
  const { isMobileView } = useCustomTheme();
  const iconSize = isMobileView ? 24 : 20;
  return (
    <IconButtonBase
      onClick={onClick}
      data-testid="notification-configuration-btn"
    >
      <GearSixIcon
        width={iconSize}
        height={iconSize}
        stroke={palette.gray[300]}
        path_fill={palette.gray[300]}
        fill={palette.background.dimmedWhite}
      />
    </IconButtonBase>
  );
};

const BackButtonBase = styled(Button)(() => ({
  fontSize: "14px",
  fontWeight: 350,
  lineHeight: "16.8px",
  color: palette.black[100],
  padding: 0,
}));

const BlueButtonBase = styled(Button)(({ theme }) => ({
  fontSize: "10px",
  fontWeight: 350,
  lineHeight: "12px",
  color: palette.accent[3],
  padding: "8px 0",
  height: "fit-content",

  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
    lineHeight: "16.8px",
  },
}));

const IconButtonBase = styled(StyledIconButton)(() => ({
  width: "fit-content !important",
  height: "fit-content !important",
  padding: "0 !important",
  "&:hover path": {
    opacity: 1,
    fill: palette.background.dimmedWhite,
    path_fill: palette.neutral[80],
    stroke: palette.neutral[80],
  },
}));

type TFilterBadge = {
  display: boolean;
  text: string;
  variant: string;
};

type TFilter = {
  label: string;
  onClick: () => void;
  selected: boolean;
  badge: TFilterBadge | null;
};

const FilterElement = ({ label, selected, onClick, badge }: TFilter) => {
  return (
    <FilterButtonBase
      background="tertiary"
      selected={selected}
      onClick={onClick}
    >
      {label}
      {badge?.display && (
        <Badge
          data-testid={`badge-text-counter-${badge?.text}`}
          bgVariant={badge?.variant}
        >
          {badge?.text}
        </Badge>
      )}
    </FilterButtonBase>
  );
};

export const FilterButtonBase = styled(Button, {
  shouldForwardProp: (prop) => prop !== "selected",
})<{ selected: boolean }>(({ theme, selected }) => ({
  fontSize: "14px",
  fontWeight: 350,
  lineHeight: "16.8px",
  color: selected ? palette.black[100] : palette.gray[300],
  padding: "8px 12px",
  display: "flex",
  flexDirection: "row",
  gap: "4px",
  textDecoration: "none !important",
  height: "fit-content",
  transition: "all 50ms ease",
  cursor: selected ? "default" : "pointer",
  userSelect: "none",

  [theme.breakpoints.down("sm")]: {
    color: selected ? palette.neutral.white : palette.black[200],
    boxShadow: "0px 5px 10px 0px #0000000D",
    borderRadius: "24px",
    backgroundColor: `${
      selected ? palette.black[300] : palette.neutral.white
    } !important`,
  },

  [theme.breakpoints.up("sm")]: {
    border: "2px solid transparent",
    borderRadius: 0,
    borderBottom: `2px solid ${selected ? palette.black[300] : "transparent"}`,
  },
}));

const badgeBackground: Record<string, string> = {
  orange: palette.accent[4],
  default: palette.accent[3],
};

const getBackground = (variant?: string) => {
  if (!variant) return badgeBackground.default;
  return badgeBackground[variant] || badgeBackground.default;
};

const Badge = styled(Text, {
  shouldForwardProp: (prop) => prop !== "bgVariant",
})<{ bgVariant?: string }>(({ bgVariant }) => ({
  fontSize: "11px",
  fontWeight: 400,
  lineHeight: "13.2px",
  color: palette.neutral.white,
  borderRadius: "100px",
  backgroundColor: getBackground(bgVariant),
  width: "min-content",
  padding: "1px 4px",
}));
