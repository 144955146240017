import React from "react";
import { Box, Grid } from "@mui/material";
import { styled } from "@theme/v2/Provider";

type Statuses = {
  pah: boolean;
  business_details: boolean;
  bank_account: boolean;
  merchant_agreement: boolean;
};

export const Pills = ({ statuses }: { statuses: Statuses }) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      height="14px"
      gap="6px"
    >
      <Pill status={statuses.pah} />
      <Pill status={statuses.business_details} />
      <Pill status={statuses.bank_account} />
      <Pill status={statuses.merchant_agreement} />
    </Grid>
  );
};

const Pill = styled(Box)<{ status: boolean }>(({ status, theme }) => ({
  flex: 1,
  height: "3px",
  borderRadius: "2px",
  background: theme.palette.icon?.["icon-primary"],
  opacity: status ? 1 : 0.3,
}));
