import NiceModal from "@ebay/nice-modal-react";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import { Stack } from "@mui/material";
import GiveButton from "@shared/Button/GiveButton";
import { styled } from "@theme/v2/Provider";
import ChallengeMessage from "./components/ChallengeMessage";
import { TChallengeTypeName } from "../types";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { ReasonType } from "./components/types";
import ChallengeDocuments from "features/Merchants/MerchantSidePanel/GiveMerchantFile/components/DocumentsSection";
import MerchantSidePanelProvider, {
  useMerchantSidePanelContext,
} from "features/Merchants/MerchantSidePanel/Provider/MerchantSidePanelProvider";
import ChallengeButtons from "./components/ChallengeButtons";
import IdentityVerification from "features/Merchants/MerchantSidePanel/components/PrimaryAccountHolderSection/IdentityVerification";
import ChallengeDiscussion from "./components/ChallengeDiscussion";
import { HFGiveInput } from "@shared/HFInputs/HFGiveInput/HFGiveInput";

// TODO: refactor this to hook to get data
type Props = {
  title: string;
  hasRejectButton?: boolean;
  message: string;
  threadId: number;
  challengeTypeName: TChallengeTypeName;
  merchantContextData: any;
  isChallengeDone: boolean;
  discussionTitle?: string;
  discussionMessageImages?: string[];
  discussionCommentsCount?: number;
  discussionLastComment?: string;
  isPAHChallenge?: boolean;
  name: string;
};

const schema = Yup.object().shape({
  reason: Yup.string().min(1, ""),
});

const BaseChallengeModal = NiceModal.create(
  ({
    title,
    hasRejectButton = true,
    message,
    threadId,
    challengeTypeName,
    merchantContextData,
    isChallengeDone,
    discussionTitle,
    discussionMessageImages,
    discussionCommentsCount,
    discussionLastComment,
    isPAHChallenge,
    name,
  }: Props) => {
    // TODO: Refactor this wrapping with context provider
    return (
      <MerchantSidePanelProvider {...merchantContextData}>
        <ModalContent
          title={title}
          hasRejectButton={hasRejectButton}
          message={message}
          threadId={threadId}
          challengeTypeName={challengeTypeName}
          isChallengeDone={isChallengeDone}
          discussionTitle={discussionTitle}
          discussionMessageImages={discussionMessageImages}
          discussionCommentsCount={discussionCommentsCount}
          discussionLastComment={discussionLastComment}
          isPAHChallenge={isPAHChallenge}
          name={name}
        />
      </MerchantSidePanelProvider>
    );
  },
);

export default BaseChallengeModal;

const ModalContent = ({
  title,
  hasRejectButton = true,
  message,
  threadId,
  challengeTypeName,
  isChallengeDone,
  discussionTitle,
  discussionMessageImages,
  discussionCommentsCount,
  discussionLastComment,
  isPAHChallenge,
  name,
}: Omit<Props, "merchantContextData">) => {
  const { open, onClose } = useNiceModal();
  const { data, id } = useMerchantSidePanelContext();

  const idDocuments =
    data?.documents?.filter((item) => {
      return challengeKeys[name]?.includes(item?.attTypeName);
    }) || [];

  const methods = useForm<ReasonType>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      reason: "",
    },
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    methods.reset();
    onClose();
  };

  return (
    <FormProvider {...methods}>
      <GiveBaseModal
        open={open}
        title={title}
        width="724px"
        height="100%"
        onClose={handleClose}
        buttons={
          <ChallengeButtons
            isChallengeDone={isChallengeDone}
            hasRejectButton={hasRejectButton}
            handleClose={handleClose}
            methods={methods}
          />
        }
        closeIconProps={{
          bgColor: "solidWhite",
        }}
      >
        <Stack gap="16px">
          <ChallengeMessage
            message={message}
            threadId={threadId}
            challengeTypeName={challengeTypeName}
            merchantID={id}
          />
          {isChallengeDone && (
            <ChallengeDiscussion
              title={discussionTitle || ""}
              messageImages={discussionMessageImages || []}
              commentsCount={discussionCommentsCount || 0}
              lastComment={discussionLastComment || ""}
              onClick={() => void 0} // TODO: Add onClick
            />
          )}
          <HFGiveInput
            label="Reason"
            name="reason"
            placeholder="Enter your reason"
            multiline
            rows={8}
          />
          <ChallengeDocuments
            id={id}
            data={idDocuments}
            isEnterprise={false}
            customUploadStackStyles={{
              width: "100%",
            }}
            isHideDelete={true}
          />
          {isPAHChallenge && <IdentityVerification />}
        </Stack>
      </GiveBaseModal>
    </FormProvider>
  );
};

const challengeKeys: { [key: string]: string[] } = {
  bank_account_verification: ["bank_account"],
  primary_account_holder_id_verification: [
    "account_owner_selfie",
    "account_owner",
  ],
};
