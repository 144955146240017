import { Text } from "@common/Text";
import { Divider, Stack } from "@mui/material";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { TMerchantDocument } from "../data.types";
import { useDocumentHandlers } from "@hooks/common/documents";
import { PAH_UPLOADER_PREVIEW_MODAL } from "modals/modal_names";
import { TPreviewModalProps } from "./PrimaryAccountHolder/PAHUploaderPreviewModal";
import NiceModal from "@ebay/nice-modal-react";
import { PAHMapper } from "./PrimaryAccountHolder/PAHMapper";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { urlToBase64 } from "@utils/assets";
import { TOwnerFile } from "./PrimaryAccountHolder/types";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { FileUploadProvider } from "@components/UploadFile/FileUploadContext";
import PAHUploader from "@components/Merchants/MerchantPreview/components/PrimaryAccountHolder/PAHUploader";

type TOwnerFileItems = Array<{
  type: TOwnerFile;
  file: TMerchantDocument | undefined;
}>;

function IdentityVerification({
  id,
  data,
  isEnterprise = false,
}: {
  id: number;
  data: TMerchantDocument[];
  isEnterprise?: boolean;
}) {
  const { isMobileView } = useCustomTheme();
  const PAH_ID = data?.find((doc) => doc?.attTypeName === "account_owner");
  const PAH_SELFIE = data?.find(
    (doc) => doc?.attTypeName === "account_owner_selfie",
  );

  const resource = composePermission(
    isEnterprise ? RESOURCE_BASE.ENTERPRISE : RESOURCE_BASE.MERCHANT,
    RESOURCE_BASE.FILES,
  );

  const isUploadAllowed = useAccessControl({
    resource,
    operation: OPERATIONS.CREATE,
  });

  const isDeleteAllowed = useAccessControl({
    resource,
    operation: OPERATIONS.DELETE,
  });

  // =========
  // THIS SECTION CAN BE REVERTED BACK AFTER THE BE FIXES PAH ISSUES ABOUT FILES CONSISTENCIES

  const [files, setFiles] = useState<TOwnerFileItems>([]);
  const createMapper = useCallback(async (): Promise<TOwnerFileItems> => {
    const imgs = await Promise.allSettled([
      urlToBase64(PAH_ID?.fileURL, PAH_ID?.fileName, PAH_ID?.fileType),
      urlToBase64(
        PAH_SELFIE?.fileURL,
        PAH_SELFIE?.fileName,
        PAH_SELFIE?.fileType,
      ),
    ]);

    const results = imgs.map((p) => p?.status === "fulfilled" && p?.value);

    const idFile = results[0] as string | undefined;
    const selfieFile = results[1] as string | undefined;
    return [
      {
        type: "primaryAccountHolderID",
        file: PAH_ID && idFile ? { ...PAH_ID, fileURL: idFile } : undefined,
      },
      {
        type: "primaryAccountHolderSelfie",
        file:
          PAH_SELFIE && selfieFile
            ? { ...PAH_SELFIE, fileURL: selfieFile }
            : undefined,
      },
    ];
  }, [PAH_ID?.fileURL, PAH_SELFIE?.fileURL]);
  async function setTheFiles() {
    const _files = await createMapper();
    setFiles(_files);
  }
  useEffect(() => {
    setTheFiles();
  }, [data]);

  //=======

  const { downloadHandler } = useDocumentHandlers(id);

  // moment.unix(PAH_SELFIE?.createdAt).format("MMM D, YYYY")
  const previewDocument = ({
    tmpUrl = "",
    type = "" as TOwnerFile,
    fileName = "",
  } = {}) => {
    const filesData = files.map(({ type: _type, file }) => {
      return {
        fileName:
          file?.fileName ||
          (type && fileName && type === _type ? fileName : ""),
        src:
          file?.fileURL ||
          (type && tmpUrl && type === _type ? tmpUrl : undefined) ||
          "",
        type: type,
        icon: PAHMapper[_type].icon,
        title: _type === "primaryAccountHolderID" ? "ID" : "Selfie",
      };
    });

    NiceModal.show(PAH_UPLOADER_PREVIEW_MODAL, {
      filesData,
    } as TPreviewModalProps);
  };
  return (
    <Stack gap={1}>
      <Text textAlign="left" fontWeight="book" fontSize="14px" color="#8F8F8F">
        Identity Verification
      </Text>
      <Stack
        height={isMobileView ? "244px" : "118px"}
        direction={isMobileView ? "column" : "row"}
        gap={1}
      >
        {files.map(({ type, file }, index) => {
          return (
            <FileUploadProvider key={index}>
              <PAHUploader
                key={index}
                type={type}
                isMobile={isMobileView}
                disabled={!isUploadAllowed}
                merchantId={id}
                docUrl={file?.fileURL || ""}
                downloadHandler={() => downloadHandler(file)}
                previewDocument={previewDocument}
                isUploadAllowed={isUploadAllowed}
                isDeleteAllowed={isDeleteAllowed}
                fileId={file?.id}
              />
            </FileUploadProvider>
          );
        })}
      </Stack>
      <Divider sx={{ mt: "16px" }} />
    </Stack>
  );
}

export default memo(IdentityVerification);
