import React from "react";
import { styled } from "@theme/v2/Provider";
import { Radio as MuiRadio, RadioProps as MuiRadioProps } from "@mui/material";

export type RadioProps = MuiRadioProps;

const GiveRadio: React.FC<RadioProps> = ({ ...rest }) => {
  return (
    <MuiRadio
      size="small"
      disableRipple
      checkedIcon={<BpCheckedIcon className="RadioIcon BPchecked" />}
      icon={<BpIcon className="RadioIcon" />}
      version="two"
      {...rest}
    />
  );
};

const BpIcon = styled("span")({
  position: "relative",
  borderRadius: "50%",
  transition: "background-color 0.2s",
  backgroundColor: "transparent",
});

const BpCheckedIcon = styled(BpIcon)({
  "&:before": {
    transition: "background-color 0.2s",
    position: "absolute",
    content: '""',
    top: "50%",
    left: "50%",
    borderRadius: "50%",
    transform: "translate(-50%, -50%)",
  },
});

export default GiveRadio;
