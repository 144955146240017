import Avatar from "@mui/material/Avatar";
import placeholderImage from "@assets/images/avatar-placeholder.png";
import { GiveAvatarProps, squareToBorderRadiusMap } from "./GiveAvatar.types";

type Props = GiveAvatarProps;

const GiveAvatar = ({
  size = "28px",
  imageUrl,
  shape = "rounded",
  name,
  sx,
}: Props) => {
  return (
    <Avatar
      sx={{
        width: size,
        height: size,
        borderRadius:
          shape === "square" ? squareToBorderRadiusMap[size] : "50%",
        ...sx,
      }}
      src={imageUrl || placeholderImage}
      alt={name}
    ></Avatar>
  );
};

export default GiveAvatar;
