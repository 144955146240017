import { Checkbox as MuiCheckbox } from "@mui/material";
import { Check, Minus } from "@phosphor-icons/react";
import { CheckboxProps } from "./type";

const GiveCheckbox = ({
  size = "small",
  icon = null,
  checkedIcon = <Check fill={"transparent"} />,
  indeterminateIcon = <Minus fill={"transparent"} />,
  ...rest
}: CheckboxProps) => {
  return (
    <MuiCheckbox
      disableRipple
      disableTouchRipple
      disableFocusRipple
      checkedIcon={checkedIcon}
      indeterminateIcon={indeterminateIcon}
      size={size}
      version="two"
      {...(icon && { icon })}
      {...rest}
    />
  );
};

export default GiveCheckbox;
