import {
  DeviceType,
  isPreviewMode,
  lastItemSelected,
  selectedItem,
} from "../signals";
import {
  composeWidget,
  createBaseWidget,
  widgetsBuilderFromNode,
} from "../utils/builders";
import { Droppable } from "./DnD/Droppable";
import { WithSelectedNode } from "../containers/WithSelectedNode";
import { OverlayComponent } from "./DnD/components/OverlayComponent";
import { onDragOverSignal } from "./DnD/helpers";
import { builder } from "../utils";
import { ROOT_NODE_ID } from "../consts/values";
import { widgets } from "../consts/widgets";
import { applyBindingsFactory, subs } from "../consts/widgets/widgetFactory";
import {
  getDeviceTypeProperties,
  saveCompoundNodeDeviceTypeState,
  syncNodeAttributes,
} from "../NodeDeviceTypePropertiesMap";
import {
  getPublishedNodeAttributes,
  getDeviceTypeAttribute,
  getDeviceTypePropertyName,
  getDeviceSpecificKey,
} from "../utils/helpers";
import {
  encodedBlueprintId,
  encodedBlueprintIdType,
  IMAGE_GALLERY_IMAGE_BLUEPRINT,
} from "../consts/widgets/helpers";
import { triggerExternalWebsitePopUp } from "@common/LinkModalAlert/LinkModalAlert";

function extractCssProperties(
  widgetType: string,
  Tag: any,
  style: any,
  metaAttributes: any,
  content: string,
) {
  switch (widgetType) {
    case "single_image":
      return widgetsBuilderFromNode[widgetType as "single_image"](
        style,
        metaAttributes,
      )?.cssProperties;
    case "button":
      return widgetsBuilderFromNode[widgetType as "button"](
        style,
        metaAttributes,
        content,
        "",
      ).cssProperties;

    case "divider":
      return widgetsBuilderFromNode[widgetType as "divider"](
        style,
        metaAttributes,
      ).cssProperties;
    case "heading":
      return widgetsBuilderFromNode[widgetType as "heading"](
        Tag,
        style,
        metaAttributes,
        content,
      ).cssProperties;
    default:
      return {};
  }
}

const PublishedNodeElement = ({
  Tag,
  tabletTag,
  mobileTag,
  content,
  tabletContent,
  mobileContent,
  style,
  tabletStyle,
  mobileStyle,
  nodeId,
  children,
  widgetType,
  href,
  tabletHref,
  mobileHref,
  metaAttributes,
  tabletMetaAttributes,
  mobileMetaAttributes,
}: any) => {
  const deviceTypeContent = getPublishedNodeAttributes(
    content,
    tabletContent,
    mobileContent,
  );
  const DeviceTypeTag =
    getPublishedNodeAttributes(Tag, tabletTag, mobileTag) || Tag;

  const deviceTypeMetaAttribute = getPublishedNodeAttributes(
    metaAttributes,
    tabletMetaAttributes,
    mobileMetaAttributes,
  );

  let htmlAttrs: any = {};

  switch (widgetType) {
    case "button": {
      const link = getPublishedNodeAttributes(href, tabletHref, mobileHref);
      htmlAttrs = {
        href: link,
        target: "_blank",
        onClick: (e: any) => {
          e.preventDefault();
          triggerExternalWebsitePopUp(link);
        },
      };
      break;
    }

    case "root":
      htmlAttrs = {
        id: "layout_root_node",
      };
      break;
    default:
      break;
  }

  return (
    <DeviceTypeTag
      style={{
        margin: 0,
        ...getPublishedNodeAttributes(style, tabletStyle, mobileStyle),
        ...(deviceTypeMetaAttribute?.["data-hidden"] === "true" && {
          display: "none",
        }),
        ...(widgetType !== "single_image" &&
          widgetType !== "button" && { width: "100%", paddingLeft: "3px" }),
        ...(widgetType === "heading" && {
          paddingBottom: "0.1em",
        }),
      }}
      id={nodeId}
      {...htmlAttrs}
    >
      {deviceTypeContent || children}
    </DeviceTypeTag>
  );
};

function getWidgetStyle(
  widgetType: any,
  selectedItem: any,
  style: any,
  nodeId: any,
) {
  const baseStyles = { margin: 0, ...style };

  if (widgetType === "button") {
    return {
      ...baseStyles,
      cursor: "pointer",
    };
  } else if (
    widgetType === "single_image" &&
    style?.backgroundSize !== "contain"
  ) {
    // For image when selected item becomes null, it can't remember the width. Hence directly style width is used.
    return { ...baseStyles, width: style?.width || "100%" };
  } else {
    return {
      ...baseStyles,

      width: "100%",
    };
  }
}

const Base = ({
  Tag,
  tabletTag,
  mobileTag,
  content,
  tabletContent,
  mobileContent,
  style,
  tabletStyle,
  mobileStyle,
  nodeId,
  children,
  parentID,
  widgetType,
  href,
  metaAttributes,
  tabletMetaAttributes,
  mobileMetaAttributes,
  head,
  src,
}: any) => {
  const baseWidget = createBaseWidget({
    nodeId,
    widgetType,
    Tag,
    tabletTag,
    mobileTag,
  });

  let htmlAttrs: any = {};

  let props: any = {};

  const deviceTypeStyleAttribute = getDeviceTypeAttribute(
    style,
    tabletStyle,
    mobileStyle,
  );

  const deviceTypeMetaAttribute = getDeviceTypeAttribute(
    metaAttributes,
    tabletMetaAttributes,
    mobileMetaAttributes,
  );

  const deviceTypeContent = getDeviceTypeAttribute(
    content,
    tabletContent,
    mobileContent,
  );

  const deviceTypeTag = getDeviceTypeAttribute(Tag, tabletTag, mobileTag);

  switch (widgetType) {
    case "heading":
      props = widgetsBuilderFromNode[widgetType as "heading"](
        deviceTypeTag,
        deviceTypeStyleAttribute,
        deviceTypeMetaAttribute,
        deviceTypeContent,
      );
      break;
    case "button":
      props = widgetsBuilderFromNode[widgetType as "button"](
        deviceTypeStyleAttribute,
        deviceTypeMetaAttribute,
        deviceTypeContent,
        href,
      );
      // htmlAttrs = { href };
      break;
    case "divider":
      props = widgetsBuilderFromNode[widgetType as "divider"](
        deviceTypeStyleAttribute,
        deviceTypeMetaAttribute,
      );
      break;
    case "single_image":
      props = widgetsBuilderFromNode[widgetType as "single_image"](
        deviceTypeStyleAttribute,
        deviceTypeMetaAttribute,
      );
      break;
    case "root":
      htmlAttrs = {
        id: "layout_root_node",
      };
      break;
    case "image_card":
      htmlAttrs = {
        src,
      };
      break;
    default:
      break;
  }

  const DeviceTypeElementTag = getDeviceTypeAttribute(
    Tag,
    tabletTag,
    mobileTag,
  );

  return (
    <>
      <WithSelectedNode
        isSelected={
          (selectedItem.value?.id !== undefined &&
            selectedItem.value?.id === nodeId) ||
          selectedItem.value?.subSection?.id === nodeId
        }
        hideToolbar={selectedItem.value?.subSection?.id === nodeId}
      >
        <Droppable
          id={`node-${nodeId}`}
          data={{
            nodeId,
            parentID,
            head,
          }}
        >
          <DeviceTypeElementTag
            onClick={(e: any) => {
              e.stopPropagation();

              if (nodeId === ROOT_NODE_ID) {
                selectedItem.value = null;
                lastItemSelected.value = null;
              }
              if (isPreviewMode.value) return;
              if (
                selectedItem.value?.id !== undefined &&
                selectedItem.value?.id === nodeId
              )
                return;
              if (
                selectedItem.value?.id !== undefined &&
                selectedItem.value?.id === head
              )
                return;
              if (parentID !== null && widgetType !== "root") {
                if (parentID !== ROOT_NODE_ID) {
                  const w = builder.findNodeByKey(head ?? parentID);
                  if (!w) return;

                  const fl = flattenNodeList(w);

                  const widget: any = widgets[w.widgetType as "image_text"];

                  //parse style values to state values and then e cn pass it to selectedItem
                  const { ss, binders } = calculateStateBasedOnBindings(
                    fl,
                    widget,
                    w,
                  );

                  selectedItem.value = {
                    id: w.key,
                    widgetType: widget.id,
                    icon: widget.icon,
                    tabs: widget.tabs,
                    title: widget.title,
                    state: {
                      ...ss.selectedData.state,
                      cssProperties: ss.selectedData.cssProperties,
                    },
                  };

                  subs.subscribe("new", binders);
                } else {
                  // when we update typography global styles or global colors, we need to sync node's properties in cache so sidepanel is not outdated
                  const cssProperties = extractCssProperties(
                    widgetType,
                    Tag,
                    style,
                    metaAttributes,
                    content,
                  );
                  const tabletCssProperties = extractCssProperties(
                    widgetType,
                    tabletTag,
                    tabletStyle,
                    tabletMetaAttributes,
                    tabletContent,
                  );
                  const mobileCssProperties = extractCssProperties(
                    widgetType,
                    mobileTag,
                    mobileStyle,
                    mobileMetaAttributes,
                    mobileContent,
                  );

                  syncNodeAttributes(nodeId, {
                    cssProperties,
                    tabletCssProperties: tabletCssProperties,
                    mobileCssProperties: mobileCssProperties,
                    metaAttributes,
                    tabletMetaAttributes,
                    mobileMetaAttributes,
                  });

                  selectedItem.value = {
                    ...composeWidget(baseWidget, props),
                    ...getDeviceTypeProperties(nodeId),
                  };
                }
              }
            }}
            style={{
              ...getWidgetStyle(
                widgetType,
                selectedItem,
                deviceTypeStyleAttribute,
                nodeId,
              ),
              opacity:
                deviceTypeMetaAttribute?.["data-hidden"] === "true"
                  ? 0.25
                  : deviceTypeStyleAttribute?.opacity || 1,
              // In preview mode hidden elements should not be visible
              ...(isPreviewMode.value &&
                deviceTypeMetaAttribute?.["data-hidden"] === "true" && {
                  display: "none",
                }),
              ...(widgetType === "root" && {
                paddingBottom: "40px",
              }),
              ...(widgetType === "heading" && {
                paddingBottom: "0.1em",
              }),
            }}
            id={nodeId}
            {...htmlAttrs}
          >
            {getDeviceTypeAttribute(content, tabletContent, mobileContent) ||
              children}
          </DeviceTypeElementTag>
        </Droppable>
      </WithSelectedNode>
      {onDragOverSignal.value === nodeId ? <OverlayComponent /> : null}
    </>
  );
};

export const Node = ({ node, isPublished }: any) => {
  if (!node) return null;

  const Tag = node.tag;

  const NodeItem =
    isPublished || isPreviewMode.value ? PublishedNodeElement : Base;

  if (!node.childrens?.length) {
    return (
      <NodeItem
        key={node.key}
        nodeId={node.key}
        Tag={Tag}
        tabletTag={node.tabletTag}
        mobileTag={node.mobileTag}
        content={node.content}
        tabletContent={node.tabletContent}
        mobileContent={node.mobileContent}
        style={node.style}
        tabletStyle={node.tabletStyle}
        mobileStyle={node.mobileStyle}
        widgetType={node.widgetType}
        parentID={node.parentID}
        metaAttributes={node.metaAttributes ?? {}}
        tabletMetaAttributes={node.tabletMetaAttributes ?? {}}
        mobileMetaAttributes={node.mobileMetaAttributes ?? {}}
        href={node?.href}
        tabletHref={node?.tabletHref}
        mobileHref={node?.mobileHref}
        src={node.src}
        alt={node.alt}
        head={node.head}
      />
    );
  }

  return (
    <NodeItem
      widgetType={node.widgetType}
      nodeId={node.key}
      key={node.key}
      Tag={Tag}
      parentID={node.parentID}
      style={node.style}
      tabletStyle={node?.tabletStyle}
      mobileStyle={node?.mobileStyle}
      metaAttributes={node.metaAttributes ?? {}}
      tabletMetaAttributes={node?.tabletMetaAttributes ?? {}}
      mobileMetaAttributes={node?.mobileMetaAttributes ?? {}}
      head={node.head}
      href={node?.href}
      tabletHref={node?.tabletHref}
      mobileHref={node?.mobileHref}
    >
      {node.childrens?.map((node: any) => (
        <Node key={node.key} node={node} isPublished={isPublished} />
      ))}
    </NodeItem>
  );
};

export const flattenNodeList = (tree: any): any[] => {
  const flattenedList: any[] = [];

  const traverse = (node: any) => {
    flattenedList.push(node);

    if (node?.childrens && node?.childrens?.length > 0) {
      node.childrens.forEach(traverse);
    }
  };

  traverse(tree);
  return flattenedList.filter((item) => item.key !== 1);
};

function startB(selectedData: any) {
  function setValuesFromBinding(binding: any, lastPath: string, value: any) {
    const [targetPath, _] = binding;
    const targetPathParts = targetPath.split(".");
    let current = selectedData;

    // Traverse the path to the target property
    targetPathParts.forEach((part: any, i: number) => {
      if (i === targetPathParts.length - 1) {
        // Check if the current part matches the lastPath
        if (part === lastPath) {
          // Set the value at the last part of the path
          current[part] = value;
        } else {
          console.error(`Path mismatch: expected ${lastPath}, found ${part}`);
        }
      } else {
        current[part] = current[part] || {};
        current = current[part];
      }
    });
  }

  return { selectedData, setValuesFromBinding };
}

export const calculateStateBasedOnBindings = (
  flattenedList: any,
  widget: any,
  w: any,
) => {
  const binders: any[] = [];
  const ss = startB({});

  flattenedList.forEach((x: any) => {
    const decodedBlueprintId =
      encodedBlueprintId[x.blueprintID as encodedBlueprintIdType];
    const blueprintFactory: any = (widget as any).blueprints[
      decodedBlueprintId
    ];

    const cssPropsFactory = (widget as any).props?.[decodedBlueprintId];
    if (cssPropsFactory) {
      const deviceTypes = [
        DeviceType.DESKTOP,
        DeviceType.TABLET,
        DeviceType.MOBILE,
      ];
      deviceTypes.forEach((item: any) => {
        const props = cssPropsFactory(
          x,
          builder.findNodeByKey(x.parentID),
          item,
        );
        if (props) {
          x[getDeviceSpecificKey("cssProperties", item)] = { ...props };
        }
      });
    }

    if (blueprintFactory) {
      const isImageGalleryImageItem =
        encodedBlueprintId?.[x.blueprintID as encodedBlueprintIdType] ===
        IMAGE_GALLERY_IMAGE_BLUEPRINT;
      const blueprint = blueprintFactory(
        x.cssProperties,
        // TODO: this was done for image gallery, check for more scalable solution
        isImageGalleryImageItem
          ? x?.[getDeviceTypePropertyName("metaAttributes")]?.[
              "data-imageTitle"
            ]
          : undefined,
      );
      if (blueprint.bindings) {
        for (const [sourcePath, targetPath] of Object.entries(
          blueprint.bindings,
        ) as any) {
          if (sourcePath.startsWith("$derived")) {
            continue;
          }

          const sourceValue = sourcePath
            .split(".")
            .reduce((acc: any, key: any) => {
              const deviceTypeKey = getDeviceTypePropertyName(key);
              // if object contains key, it means it's node object, so we need to read device type style/content etc
              const keyToUse = acc?.key ? deviceTypeKey : key;
              return acc && acc[keyToUse];
            }, x);

          const lastTargetPathField = targetPath.split(".").pop();
          /*
            Also metaAttributes subscribe to state.
            Bu we should skip them while parsing from style values to the state.
            MetaAtributes is only used to store those values that are not style values.
             
          */
          if (!sourcePath.startsWith("metaAttributes")) {
            if (!ss.selectedData.state?.[lastTargetPathField]) {
              ss.setValuesFromBinding(
                [targetPath, sourcePath],
                lastTargetPathField,
                widgetsBuilderFromNode[
                  w.widgetType as "image_card" | "image_text"
                ](
                  sourceValue,
                  lastTargetPathField,
                  x?.[getDeviceTypePropertyName("metaAttributes")] ||
                    x.metaAttributes,
                ),
              );
            }
          }
        }
        binders.push([
          x.key,
          applyBindingsFactory(x, blueprint.bindings, blueprint.stateParser),
        ]);
      }
    }
  });

  saveCompoundNodeDeviceTypeState(w.key, ss.selectedData.state);

  return {
    binders,
    ss,
  };
};
