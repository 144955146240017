import { Box, Stack } from "@mui/material";
import GiveButton from "@shared/Button/GiveButton";
import GiveBaseModal from "@shared/modals/GiveBaseModal";
import NiceModal from "@ebay/nice-modal-react";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { IParsedData } from "../../../../components/Merchants/MerchantPreview/data.types";
import { TermsOfService } from "@pages/TermsOfService";
import { styled } from "@theme/v2/Provider";
import useHandleScroll from "../hooks/useHandleScroll";
import { TMerchantAgreementPayload } from "@components/Merchants/CreateMerchantPanel/hooks/useAddMerchantAgreement";
import { useMutation, useQueryClient } from "react-query";
import { customInstance } from "@services/api";
import { isEmpty } from "lodash";
import {
  LastUpdatedOn_merchant_agreement,
  MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS,
} from "../constants";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import GiveDivider from "@shared/GiveDivider/GiveDivider";
import GiveCheckbox from "@shared/GiveCheckbox/GiveCheckbox";
import GiveText from "@shared/Text/GiveText";

const MerchantAgreementModal = NiceModal.create(
  ({
    data,
    merchantId,
  }: {
    data?: IParsedData;
    merchantId: string | number;
  }) => {
    const queryClient = useQueryClient();
    const { isMobileView } = useCustomThemeV2();
    const { open, onClose } = useNiceModal();
    const defaultHasAgreed =
      data?.merchantAgreement.tcVersion ===
        data?.merchantAgreement.msaLastAcceptedVersion &&
      !!data?.merchantAgreement?.msaHadAgreed;

    const {
      scrollButton,
      handleScroll,
      containerRef,
      enabledAgreementCheckbox,
      viewRef,
      setEnabledAgreementCheckbox,
    } = useHandleScroll({
      defaultHasAgreed,
    });

    const getDefaultValuesFromData = (signData?: TMerchantAgreementPayload) => {
      return {
        msaHadAgreed: true,
        msaPCICompliant: true,
        signatureURL: signData?.signatureURL,
      };
    };

    const updateMerchantInfoMutation = useMutation({
      mutationFn: () => {
        return customInstance({
          url: `/merchants/${merchantId}`,
          method: "PATCH",
          data: isEmpty(data?.merchantAgreement)
            ? merchantAgreementDefaultValues
            : getDefaultValuesFromData(data?.merchantAgreement),
        });
      },
      onSuccess: () => {
        queryClient.refetchQueries(MERCHANT_SIDE_PANEL_PREVIEW_API_KEYS.GET);
        onClose();
      },
    });
    return (
      <GiveBaseModal
        open={open}
        title="Merchant Application Agreement Update"
        width="720px"
        height={isMobileView ? "90%" : "57%"}
        onClose={onClose}
        sx={{
          "& .MuiDialogContent-root": {
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "&::-webkit-scrollbar-track": {
              display: "none",
            },
          },
        }}
        buttons={
          <Stack gap="12px" flexDirection="row">
            <GiveButton
              onClick={onClose}
              label="Cancel"
              variant="ghost"
              size="large"
            />
            <GiveButton
              label="Agree"
              variant="filled"
              size="large"
              sx={{ border: "none" }}
              disabled={
                !enabledAgreementCheckbox ||
                updateMerchantInfoMutation?.isLoading
              }
              onClick={() => merchantId && updateMerchantInfoMutation.mutate()}
            />
          </Stack>
        }
        closeIconProps={{
          bgColor: "solidWhite",
        }}
      >
        <TOSWrapper onScroll={handleScroll} ref={containerRef}>
          <TermsOfService
            merchantAgreementVersion={{
              version: data?.merchantAgreement?.tcVersion || "",
              lastUpdated: LastUpdatedOn_merchant_agreement,
            }}
          />

          {scrollButton}
          <Box ref={viewRef} px="16px">
            <GiveDivider />
            <Stack
              mt="20px"
              gap="12px"
              flexDirection="row"
              alignItems="flex-start"
            >
              <Box>
                <GiveCheckbox
                  checked={enabledAgreementCheckbox}
                  onChange={() => setEnabledAgreementCheckbox((prev) => !prev)}
                />
              </Box>
              <Box>
                <GiveText fontWeight={400} color="primary" variant="bodyS">
                  Agreement Confirmation
                </GiveText>
                <GiveText
                  fontWeight={400}
                  mt="4px"
                  color="secondary"
                  variant="bodyXS"
                >
                  By checking this box, I acknowledge that I have read,
                  understand, and agree to the terms and conditions of the
                  Service Agreement, and confirm that our business adheres to
                  all applicable Federal, State, and Local laws, as well as Card
                  Brand rules and regulations.
                </GiveText>
              </Box>
            </Stack>
          </Box>
        </TOSWrapper>
      </GiveBaseModal>
    );
  },
);

export default MerchantAgreementModal;

const TOSWrapper = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  width: "100%",
  height: "618px",
  borderRadius: "16px",
  border: "none",
  backgroundColor: "white",
  overflowY: "scroll",
  position: "relative",
  "*": {
    color: theme.palette.text.primary,
  },
  "& .MuiCardMedia-root": {
    backgroundColor: "white",
    height: "618px",
    overflowY: "scroll",
  },
}));

const merchantAgreementDefaultValues = {
  msaRefundPolicy: "30 Days of Purchase",
  msaPCICompliant: true,
  msaPreviousTermination: false,
  msaPreviousTerminationProcessorName: "",
  msaPreviousTerminationReason: "",
  msaPreviousTerminationAt: null,
  msaHadAgreed: false,
};
