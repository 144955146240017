import { ThemeVersions } from "@theme/v2/types";
import { isSecondVersion } from "@theme/v2/utils";

declare module "@mui/material/TableCell" {
  interface TableCellProps {
    version?: ThemeVersions;
  }
}

export const customTableCellStyle = {
  styleOverrides: {
    root: {
      variants: [
        {
          props: (props: any) => isSecondVersion(props?.version),
          style: {
            borderBottom: "none",
            padding: "16px",
          },
        },
      ],
    },
  },
};
