import { TAG_STATUSES_ENUM } from "@common/TextTag/types";
import InviteSentPanelContent from "../components/InviteSentPanelContent/InviteSentPanelContent";
import SidePanelBody from "../components/SidePanelBody";
import { useCallback } from "react";
import { IPanelLoadingSkeletonProps } from "../components/PanelLoadingSkeleton";

const useGetPanelContent = (status?: TAG_STATUSES_ENUM | null) => {
  const getPanelContent = useCallback(() => {
    let panelBody;
    let skeletonVariant: IPanelLoadingSkeletonProps["variant"];

    switch (status) {
      case TAG_STATUSES_ENUM.INVITE:
        panelBody = <InviteSentPanelContent />;
        skeletonVariant = "invite_sent";
        break;
      default:
        panelBody = <SidePanelBody />;
        skeletonVariant = "default";
        break;
    }

    return { panelBody, skeletonVariant };
  }, [status]);

  return getPanelContent();
};

export default useGetPanelContent;
