import { CloseIcon } from "@assets/icons";
import Popup from "@common/Popup";
import { Text } from "@common/Text";
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import { styled } from "@mui/material";
import { Stack } from "@mui/material";
import { palette } from "@palette";
import { StyledIconButton } from "@common/IconButton";
import { isFunction } from "lodash";

interface ModalProps {
  name: string;
  onAccept?: () => void;
}

const PepConfirmMatchModal = NiceModal.create(
  ({ name, onAccept }: ModalProps) => {
    const modal = useModal();

    const handleCancel = () => {
      modal.hide();
    };
    const onClickConfirm = () => {
      modal.hide();
      isFunction(onAccept) && onAccept();
    };

    return (
      <Popup
        {...muiDialogV5(modal)}
        onCancel={handleCancel}
        onSubmit={onClickConfirm}
        actionLabel="Confirm match "
        width="415px"
        cancelButtonProps={{
          size: "large",
        }}
        submitButtonProps={{
          size: "large",
          background: "red",
        }}
        sx={{
          "& .MuiDialogContent-root": {
            padding: "16px 16px 8px 16px",
          },
          "& .MuiDialogActions-root": {
            padding: "0px 16px 16px 16px",
          },
        }}
      >
        <Stack
          justifyContent="space-between"
          flexDirection="row"
          display="flex"
          alignItems="center"
          width="100%"
        >
          <StyledTitle>Confirmed Match</StyledTitle>
          <StyledIconButton onClick={handleCancel}>
            <CloseIcon width={20} height={20} stroke={palette.neutral[80]} />
          </StyledIconButton>
        </Stack>
        <StyledText>
          Are you sure you want to confirm the match? Please note that once
          confirmed, <span style={{ color: palette.neutral[100] }}>{name}</span>{" "}
          will no longer be able to edit any data related to his business
          profile. However, the ownership percentage will remain editable. This
          action is irreversible.
        </StyledText>
      </Popup>
    );
  },
);

const StyledTitle = styled(Text)(() => ({
  color: palette.neutral[80],
  fontFamily: "Give Whyte",
  fontSize: "18px",
  fontWeight: 350,
  lineHeight: "21.6px",
  letterSpacing: "-0.18px",
}));

const StyledText = styled(Text)(() => ({
  color: palette.neutral[70],
  fontFamily: "Give Whyte",
  fontSize: "14px",
  fontWeight: 350,
  lineHeight: "16.8px",
  padding: 0,
  marginTop: "8px",
  marginBottom: "0px",
}));

export default PepConfirmMatchModal;
