import { Stack } from "@mui/material";
import { CloudArrowUp, PencilSimple, Signature } from "@phosphor-icons/react";
import { Modals_types } from "./modal.types";
import GiveText from "@shared/Text/GiveText";
import { Box } from "@mui/material";
import { styled } from "@theme/v2/Provider";
import { Dispatch, SetStateAction } from "react";

interface Props {
  setModalOpen: Dispatch<SetStateAction<Modals_types>>;
}
function DefaultMode({ setModalOpen }: Props) {
  return (
    <Stack
      flexDirection={{
        xs: "column",
        sm: "row",
      }}
      gap="16px"
      mt="12px"
    >
      {signatureOptions.map(({ Icon, label, description, modal }) => {
        return (
          <CardContainer onClick={() => setModalOpen(modal)} key={label}>
            <Stack
              flexDirection={{
                xs: "row",
                sm: "column",
              }}
              gap="12px"
            >
              <IconContainer>
                <Icon size="25px" />
              </IconContainer>

              <Box>
                <GiveText
                  variant="bodyS"
                  textAlign={{
                    xs: "left",
                    sm: "center",
                  }}
                  fontWeight={400}
                  color="primary"
                >
                  {label}
                </GiveText>
                <GiveText
                  variant="bodyXS"
                  fontWeight={400}
                  color="secondary"
                  textAlign={{
                    xs: "left",
                    sm: "center",
                  }}
                  mt="8px"
                >
                  {description}
                </GiveText>
              </Box>
            </Stack>
          </CardContainer>
        );
      })}
    </Stack>
  );
}

export default DefaultMode;

const signatureOptions = [
  {
    Icon: CloudArrowUp,
    label: "Upload Signature",
    description:
      "Please upload a clear and legible image of your signature for verification purposes.",
    modal: "upload" as Modals_types,
  },
  {
    Icon: Signature,
    label: "Sign",
    description:
      "Use the paint tool on your trackpad or mouse to digitally replicate your signature for submission.",
    modal: "sign" as Modals_types,
  },
  {
    Icon: PencilSimple,
    label: "Generate",
    description:
      "Generate a digital signature by entering your name and utilizing the automated signature creation feature",
    modal: "generate" as Modals_types,
  },
];

const CardContainer = styled(Stack)(({ theme }) => ({
  width: "240px",
  border: `1.5px solid ${theme.palette.border?.primary}`,
  borderRadius: "8px",
  padding: "63px 16px",
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "16px",
  },
}));

const IconContainer = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  height: "25px",
  width: "25px",
  [theme.breakpoints.down("sm")]: {
    margin: 0, // remove centering on mobile
  },
}));
