import { Box, styled } from "@mui/material";
import { CustomTheme } from "@theme/v2/palette.interface";

export interface PanelContainerProps {
  isSinglePanelOnly?: boolean;
  isMobileView: boolean;
  containerWidth: number;
}

export const FirstPanelContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "isMobileView" && prop !== "containerWidth",
})<PanelContainerProps>(
  ({ isMobileView, containerWidth }: PanelContainerProps) => ({
    flex: 1,
    ...(!isMobileView && { minWidth: containerWidth }),
  }),
);

export const SecondPanelContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "isSinglePanelOnly" &&
    prop !== "isMobileView" &&
    prop !== "containerWidth",
})<PanelContainerProps>(
  ({
    theme,
    isSinglePanelOnly,
    isMobileView,
    containerWidth,
  }: {
    theme: CustomTheme;
  } & PanelContainerProps) => ({
    height: "100%",
    width: isMobileView ? "100%" : containerWidth,
    backgroundColor: theme.palette.surface?.secondary,
    ...(!isSinglePanelOnly && {
      borderLeft: `1px solid ${theme.palette.border?.primary}`,
      boxShadow: theme.customs?.shadows.left,
    }),
  }),
);
