/* eslint-disable @typescript-eslint/ban-ts-comment */
// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";

import { Button } from "@common/Button";

import { FormControlLabel } from "@common/FormControlLabel";
import { Input, RHFInput } from "@common/Input";
import { TabPanel, Tabs } from "@common/Tabs";
import { Text } from "@common/Text";
import { ColorValue, createColor } from "mui-color";

import { Checkbox } from "@common/Checkbox";
import ColorPicker from "@common/ColorPicker/ColorPicker";
import FadeInWrapper from "@components/animation/FadeInWrapper";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useCopyClipboard } from "@hooks/common/useCopyClipboard";
import { Stack } from "@mui/material";
import { Copy } from "@phosphor-icons/react";
import { campaignType, WidgetType } from "@sections/Actions/Share/share.types";
import GiveButton from "@shared/Button/GiveButton";
import GiveText from "@shared/Text/GiveText";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useAppTheme } from "@theme/v2/Provider";
import { Color } from "mui-color";
import { useCallback, useMemo, useState } from "react";

const boxStyle = {
  mt: 1,
  height: 72,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#F5F5F3",
  borderRadius: "12px",
  padding: "16px",
};

const inputBox = {
  marginTop: 2,
  position: "relative",
  backgroundColor: "#F5F5F3",

  "& > .MuiButton-root": {
    position: "absolute",
    borderRadius: "6px",
    bottom: "8px",
    right: "8px",

    "@media (max-width: 600px)": {
      left: 0,
      right: 0,
      width: "95%",
      margin: "0 auto",
    },
  },
};

const inputStyle = {
  "& .MuiInputBase-root": {
    padding: "4px 0px 4px 16px",
    "&.Mui-disabled": {
      backgroundColor: "#F5F5F3 !important",
      padding: "20px",
      borderRadius: "12px",
    },
    "& textarea": {
      fontSize: "14px",

      "&::-webkit-scrollbar": {
        width: 3,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "inherit",
      },
      "&::-webkit-scrollbar-thumb": {
        border: "none",
      },
    },
  },
};

const btnCopied = {
  background: "#EFF4F8",
  boxShadow:
    "inset -4px -4px 9px rgba(255, 255, 255, 0.88), inset 4px 4px 14px #C1D5EE",
};

function getCurrentDomain() {
  const { protocol, hostname } = window.location;

  // Remove "www." prefix if present
  const domain = hostname.replace("www.", "");

  const fullDomain = `${protocol}//${domain}`;
  return fullDomain;
}
function minify_html(inputHTML: any) {
  const minifiedHTML = inputHTML
    .replace(/\s+/g, " ")
    .replace(/<!--[^-]*(?:-[^-]+)*-->/g, "")
    .replace(/> </g, "><");
  const formattedHTML = minifiedHTML.replace(/></g, ">\n<");
  return formattedHTML.trim();
}

const makeWidget = ({
  buttonName,
  domainName,
  productId,
  buttonColor,
  buttonBackground,
  autoPayment,
}: any) => {
  const iFrameText = `<iframe src='${domainName}/${productId}?public=true&noFocus=true' frameBorder='no' scrolling='auto' height='800px' width='100%'></iframe>`;
  const widgetText = `
  <button
    type="button"
    data-id="pay-now"
    class="givepayments-btn"
    data-productId="${productId}"
    data-domainName="${domainName}"
    style="
      color:${
        buttonColor === " " || typeof buttonColor === "object"
          ? "transparent"
          : buttonColor
      };
      background:${
        typeof buttonBackground === "object" ? "transparent" : buttonBackground
      };
      border-radius: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 20px;
      border: 0;
      cursor: pointer;
    "
  >
    ${buttonName}
  </button>
  <script type='text/javascript' src='https://cdn.givepayments.com/ui/static/js/script.minify.js'></script> 
  <script type='text/javascript'>
    if(${autoPayment}) {
      document.addEventListener('DOMContentLoaded',GIVEPAYMENTS.init([{auto:'${productId}',domainName:'${domainName}'}]));
    }

    const btn = document.querySelector('[data-id="pay-now"]');

    btn.addEventListener("click", () => {
      GIVEPAYMENTS.init([{ auto: "${productId}", domainName: "${domainName}" }]);
    });

    btn.addEventListener('touchstart', () => {
      GIVEPAYMENTS.load([{ auto: "${productId}", domainName: "${domainName}" }]);
    });
  </script> 
  `;

  return { iFrameText, widgetText };
};
const EmbedModalContent = ({
  productId,
  type,
}: {
  productId: string;
  type: campaignType;
}) => {
  const theme = useTheme();
  const { palette } = useAppTheme();
  const { isMobileView } = useCustomTheme();
  const { copyToClipboard } = useCopyClipboard();

  const [tabValue, setTabValue] = useState(0);
  const [isCopied, setIsCopied] = useState(false);
  const [isIFrameCopied, setIsIFrameCopied] = useState(false);
  const [textColor, setTextColor] = useState<ColorValue>(
    createColor("#FAF8F5"),
  );

  const [autoPopUp, setAutoPopUp] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState<ColorValue>(
    createColor("#998A6B"),
  );

  const schema = Yup.object({
    text: Yup.string().required("This field is required"),
    // textColor: Yup.string()
    //   .required("This field is required")
    //   .test("is-hex-code", "", (value: any) => hex.test(value)),
    // backgroundColor: Yup.string()
    //   .required("This field is required")
    //   .test("is-hex-code", "", (value: any) => hex.test(value)),
  });

  const defaultValues = {
    text: "Donate Now",
    textColor: "#FAF8F5",
    backgroundColor: "#998A6B",
    autopop: false,
  };

  const methods = useForm<WidgetType>({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { watch } = methods;
  const values = watch();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onSubmit: SubmitHandler<WidgetType> = (formValues) => {
    console.log("Values: ", formValues);
  };

  const { iFrameText, widgetText } = makeWidget({
    buttonName: values.text,
    domainName: getCurrentDomain(),
    autoPayment: autoPopUp,
    productId,
    buttonColor: (textColor as Color).raw // hex is stripping trasparency
      ? `${(textColor as Color).raw}`
      : textColor,
    buttonBackground: (backgroundColor as Color).raw
      ? `${(backgroundColor as Color).raw}`
      : backgroundColor,
  });

  const changeTextColor = useCallback((color: string) => {
    if (!color) {
      setTextColor(" "); //this is a workaround, because the library does not allow the setting of undefined or empty string
    } else setTextColor(createColor(color));
  }, []);

  const changeBackgroundColor = useCallback((color: string) => {
    setBackgroundColor(createColor(color));
  }, []);

  const gridItem = useMemo(() => {
    const arr = [
      {
        component: <RHFInput name="text" type="text" fullWidth label="Text" />,
        xs: 12,
      },
      {
        xs: 6,
        component: (
          <ColorPicker
            fullWidth
            // value={textColor}
            label="Text color"
            defaultValue={defaultValues.textColor}
            onChange={changeTextColor}
          />
        ),
      },
      {
        component: (
          <ColorPicker
            fullWidth
            // value={backgroundColor}
            label="Background color"
            defaultValue={defaultValues.backgroundColor}
            onChange={changeBackgroundColor}
            // onChange={(color: ColorValue) => setBackgroundColor(color)}
          />
        ),
        xs: 6,
      },
      {
        component: (
          <>
            <GiveText fontSize="16px">Button Preview</GiveText>
            <Box sx={boxStyle}>
              <Button
                onTouchStart={() => {
                  const scr = document.createElement("script");
                  scr.setAttribute("type", "text/javascript");
                  scr.setAttribute(
                    "src",
                    "https://cdn.givepayments.com/ui/static/js/script.minify.js",
                  );

                  document.body.prepend(scr);

                  scr.onload = function () {
                    try {
                      GIVEPAYMENTS.load([
                        {
                          auto: productId,
                          domainName: getCurrentDomain(),
                        },
                      ]);
                    } catch (err) {
                      console.log(err);
                    }
                  };
                }}
                onClick={() => {
                  const scr = document.createElement("script");
                  scr.setAttribute("type", "text/javascript");
                  scr.setAttribute(
                    "src",
                    "https://cdn.givepayments.com/ui/static/js/script.minify.js",
                  );

                  document.body.prepend(scr);

                  scr.onload = function () {
                    try {
                      GIVEPAYMENTS.init([
                        {
                          auto: productId,
                          domainName: getCurrentDomain(),
                        },
                      ]);
                    } catch (err) {
                      console.log(err);
                    }
                  };
                }}
                sx={{
                  borderRadius: "40px",
                  boxShadow: "none",
                  height: "40px",
                  padding: "8px 24px",
                  ...(textColor && {
                    // @ts-ignore
                    color: textColor.hex
                      ? // @ts-ignore
                        `#${textColor.hex}`
                      : textColor.toString(),
                  }),
                  ...(backgroundColor && {
                    // @ts-ignore
                    backgroundColor: backgroundColor.hex
                      ? // @ts-ignore
                        `#${backgroundColor.hex}`
                      : backgroundColor.toString(),
                  }),
                }}
              >
                {values.text}
              </Button>
            </Box>
          </>
        ),
        xs: 12,
        mt: 1,
      },
      {
        xs: 12,
        component: (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  label={
                    <>
                      <GiveText fontSize="14px">Autopopup Form</GiveText>{" "}
                    </>
                  }
                  name="autopop"
                />
              }
              label=""
              value={autoPopUp}
              sx={{
                "& .MuiCheckbox-root": {
                  height: "20px",
                },
              }}
              onChange={() => setAutoPopUp((current) => !current)}
            />
            <GiveText
              ml={"30px"}
              fontSize="14px"
              sx={{
                color: palette.primitive?.neutral[60],
              }}
            >
              {` When your website is visited, your ${type} will automatically
                  pop above your website.`}
            </GiveText>
          </>
        ),
      },
    ];
    return arr;
  }, [backgroundColor, textColor, values.text, boxStyle, autoPopUp]);

  return (
    <>
      <Box mb={2}>
        <FadeUpWrapper delay={100}>
          <Tabs
            size="large"
            value={tabValue}
            variant="fullWidth"
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              borderRadius: "12px",
              padding: "4px",
              border: `solid 1px ${palette.primitive?.neutral[20]}`,
              fontSize: "14px !important",
              fontWeight: 400,
              "& .MuiButtonBase-root": {
                backgroundColor: "transparent",
                fontSize: "14px",
                fontWeight: 400,
              },
              "& .MuiButtonBase-root.Mui-selected": {
                boxShadow: "none",
                backgroundColor: palette.primitive?.neutral[90],
                color: palette.primitive?.neutral[0],
                borderRadius: "8px",
              },
            }}
          >
            <Tab label="Embed Popup" />
            <Tab label="Embed iFrame" />
          </Tabs>
        </FadeUpWrapper>
      </Box>
      <TabPanel index={0} value={tabValue} sx={{ pb: 0 }}>
        <FadeUpWrapper delay={150}>
          <GiveText fontSize="16px">Popup Widget Settings</GiveText>
        </FadeUpWrapper>
        <FadeUpWrapper delay={200}>
          <Text color={theme.palette.neutral[600]}>
            {`Customize the display of the button that will pop your ${type}.`}
          </Text>
        </FadeUpWrapper>

        <FormProvider {...methods}>
          <Grid
            mt={1}
            mb={2}
            container
            rowSpacing={2}
            columnSpacing={1}
            component="form"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            {gridItem.map((c, idx) => (
              <Grid item key={idx} mt={c.mt} xs={c.xs}>
                <FadeUpWrapper delay={200 + idx * 50}>
                  {c.component}
                </FadeUpWrapper>
              </Grid>
            ))}
          </Grid>
        </FormProvider>
        <Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack gap={0.5}>
              <FadeUpWrapper delay={300}>
                <GiveText fontSize="16px">Popup Widget Code</GiveText>
              </FadeUpWrapper>

              <FadeUpWrapper delay={350}>
                <Text color={palette.primitive?.neutral[60]}>
                  Copy and paste this code anywhere in your website&apos;s HTML
                  to pop the widget.
                </Text>
              </FadeUpWrapper>
            </Stack>

            <GiveButton
              label="Copy"
              sx={{
                width: "fit-content",
                height: "34px",
                borderRadius: "8px",
                backgroundColor: palette.primitive?.transparent["darken-10"],
                border: "none",
                color: palette.primitive?.neutral[90],
                "&:hover": {
                  color: palette.primitive?.neutral[0],
                },
              }}
              onClick={() => copyToClipboard(minify_html(widgetText))}
              startIcon={<Copy stroke={palette.primitive?.neutral[90]} />}
            />
          </Stack>
          <FadeInWrapper delay={400}>
            <Box sx={inputBox}>
              <Input
                fullWidth
                type="text"
                value={minify_html(widgetText)}
                multiline
                rows={isMobileView ? 12 : 9}
                disabled
                sx={inputStyle}
              />
            </Box>
          </FadeInWrapper>
        </Box>
      </TabPanel>
      {/** ====================== PANEL 2 ====================== */}
      <TabPanel index={1} value={tabValue} sx={{ pt: 1, pb: 0 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack gap={0.5}>
            <FadeUpWrapper delay={100}>
              <GiveText fontSize="16px">Embed iFrame</GiveText>
            </FadeUpWrapper>

            <FadeUpWrapper delay={150}>
              <GiveText
                sx={{ color: theme.palette.neutral[600], fontSize: "14px" }}
              >
                {`Copy and paste this code anywhere in your website's HTML where
            you want the ${type} to show.`}
              </GiveText>
            </FadeUpWrapper>
          </Stack>

          <GiveButton
            label="Copy"
            sx={{
              width: "fit-content",
              height: "34px",
              borderRadius: "8px",
              backgroundColor: palette.primitive?.transparent["darken-10"],
              border: "none",
              color: palette.primitive?.neutral[90],
              "&:hover": {
                color: palette.primitive?.neutral[0],
              },
            }}
            onClick={() => copyToClipboard(minify_html(iFrameText))}
            startIcon={<Copy stroke={palette.primitive?.neutral[90]} />}
          />
        </Stack>

        <FadeInWrapper delay={250}>
          <Box
            sx={{
              ...inputBox,
              maxWidth: "100%",
              maxHeight: "100%",
              overflow: "hidden",
            }}
          >
            <Input
              fullWidth
              type="text"
              value={iFrameText}
              multiline
              rows={isMobileView ? 8 : 4}
              disabled
              sx={inputStyle}
            />
          </Box>
        </FadeInWrapper>
      </TabPanel>
    </>
  );
};

export default EmbedModalContent;
