import { Text } from "@common/Text";
import { Box, Stack, SxProps, styled } from "@mui/material";
import { palette } from "@palette";
import { X } from "@phosphor-icons/react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import CustomPopover from "./CustomPopover";
import { getSubString } from "@utils/index";
import { CloseButton } from "./atoms";

type Props = {
  filters: Array<string>;
  title?: string;
  containerSx?: SxProps;
  subTitle?: string;
  fieldName: string;
  limits?: { min?: number; max?: number };
  disabledCustom?: boolean;
};

export const FiltersContainer = ({
  filters,
  title,
  containerSx,
  subTitle,
  fieldName,
  limits,
  disabledCustom,
}: Props) => {
  return (
    <Container direction="column" sx={containerSx} gap={2}>
      {(title || subTitle) && (
        <Box>
          {title && <StyledText>{title}</StyledText>}
          {subTitle && (
            <StyledText sx={{ color: "#8F8F8F" }}>{subTitle}</StyledText>
          )}
        </Box>
      )}
      <Stack direction="row" gap={1} flexWrap={"wrap"}>
        {filters.map((filter) => {
          return (
            <>
              <Chip
                name={filter}
                fieldName={fieldName}
                limits={limits}
                disabledCustom={disabledCustom}
              />
            </>
          );
        })}
      </Stack>
    </Container>
  );
};

type ChipProps = {
  name: string;
  attachMapFn?: (v: string) => string;
  fieldName: string;
  limits?: { min?: number; max?: number };
  disabledCustom?: boolean;
};

const Chip = ({ name, fieldName, limits, disabledCustom }: ChipProps) => {
  const { watch, setValue } = useFormContext();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const computedName = name.charAt(0).toUpperCase() + name.slice(1);
  const selectedFilters = watch(fieldName) as string[];
  const isSelected = selectedFilters?.includes(name);
  const isCustomChip = name.includes("Custom");
  const customChip = {
    min: getSubString(
      ">=",
      watch("custom")?.includes("%") ? "%" : "",
      watch("custom"),
    )?.slice(1),
    max: getSubString("<=", "", watch("custom"))?.slice(1),
  };

  const handleUpdateFilter = (name: string) => {
    let updatedFilters = selectedFilters;
    if (isSelected) {
      updatedFilters = updatedFilters?.filter((filter) => filter !== name);
    } else if (!isSelected && fieldName === "amount") {
      updatedFilters = [name];
    } else {
      updatedFilters = [...(updatedFilters || []), name];
    }

    setValue(fieldName, updatedFilters, {
      shouldDirty: true,
    });
  };

  const resetCustom = () => {
    if (disabledCustom) return;
    setValue("custom", "");
  };

  return (
    <>
      <Stack
        direction="row"
        onClick={(e) => {
          if (isSelected) return;
          if (isCustomChip) {
            setAnchorEl(e.currentTarget);
          } else {
            handleUpdateFilter(name);
            resetCustom();
          }
        }}
        gap={0.5}
        alignItems="center"
        sx={{
          padding: "2px 8px",
          backgroundColor: isSelected ? palette.info.light : "#ECECE9",
          borderRadius: "100px",
          marginTop: "2px",
          marginLeft: 0,
          cursor: "default",
          ...(!isSelected && {
            cursor: "pointer",
          }),
        }}
      >
        <StyledText
          fontSize="12px"
          sx={{ lineHeight: "14.4px" }}
          isSelected={isSelected}
        >
          {computedName}
          {isCustomChip &&
            (customChip?.min || customChip?.max) &&
            `: ${parseInt(customChip?.min || "0") / 100} - ${
              customChip?.max
                ? parseInt(customChip?.max || "0") / 100
                : "infinite"
            } USD`}
        </StyledText>
        {isSelected && (
          <CloseButton
            closeButtonSize={18}
            onClick={(e) => {
              e.stopPropagation();
              if (isCustomChip) resetCustom();
              handleUpdateFilter(name);
            }}
          >
            <X
              style={{
                ...(isSelected && {
                  color: palette.filled.blue,
                }),
              }}
            />
          </CloseButton>
        )}
      </Stack>
      {anchorEl && isCustomChip && (
        <CustomPopover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          setAnchorEl={setAnchorEl}
          handleUpdateFilter={
            isSelected ? undefined : () => handleUpdateFilter("Custom")
          }
          setCustomValues={(amount: string) => setValue("custom", amount)}
          limits={limits}
        />
      )}
    </>
  );
};

const Container = styled(Stack)(() => ({
  gap: "8px",
  padding: "16px",
  backgroundColor: palette.neutral.white,
  borderRadius: "8px",
}));

const StyledText = styled(Text, {
  shouldForwardProp: (prop) => prop !== "fontSize" && prop !== "isSelected",
})<{ fontSize?: string; isSelected?: boolean }>(({ fontSize, isSelected }) => ({
  fontSize: fontSize || "14px",
  lineHeight: "16.8px",
  color: isSelected ? palette.filled.blue : palette.neutral[80],
  fontWeight: 400,
}));
