import { Stack } from "@mui/material";
import { GiveLineTabProps } from "./GiveTab.types";
import GiveText from "@shared/Text/GiveText";
import { useAppTheme } from "@theme/v2/Provider";

export default function GiveLineTab({
  label,
  info,
  value,
  endItem,
  startItem,
  onClick,
  selected,
  hidden,
  disabled,
  tabSx,
}: GiveLineTabProps) {
  const { palette } = useAppTheme();
  if (hidden) return null;
  return (
    <Stack
      sx={{
        cursor: "pointer",
        ...(disabled && { cursor: "default", pointerEvents: "none" }),
        ...(selected
          ? { borderBottom: `2px solid ${palette.border?.active}` }
          : {
              "&:hover": {
                borderBottom: `2px solid ${palette.border?.secondary}`,
              },
            }),
        gap: "8px",
        paddingBottom: "16px",
        ...tabSx,
      }}
      onClick={() => onClick?.(value)}
    >
      <Stack
        direction="row"
        sx={{
          gap: "4px",
          alignItems: "center",
          "& p": {
            color: palette.text.secondary,
          },
          "&:hover": {
            "& p": { color: palette.text.primary },
          },
          ...(selected && {
            "& p": { color: palette.text.primary },
          }),
          ...(disabled && {
            "& p": {
              color: palette.text.tertiary,
            },
          }),
        }}
      >
        {startItem}
        <GiveText variant="bodyS" sx={{ whiteSpace: "nowrap" }}>
          {label}
        </GiveText>
        {endItem}
      </Stack>
      {info && (
        <GiveText variant="bodyXS" color="secondary">
          {info}
        </GiveText>
      )}
    </Stack>
  );
}
