import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { format } from "date-fns";
import { useEffect, useMemo, useRef } from "react";
import { useModal } from "@ebay/nice-modal-react";
import {
  TExportHandlerArgs,
  useDownloadExportedTable,
} from "./useDownloadExportedTable";
import useQueryString from "@hooks/useQueryString";
import {
  Colum_types,
  ColumnExport,
  DateRange,
  ExportType,
  TExportTable,
} from "../types";
import { getSessionTimezone, getTimeZoneUtcOffSet } from "@utils/timezones";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import { convertToTimezone, ensureArray, getMonthRange } from "../utils";
import { MERCHANT_COLUMNS } from "../columns/merchant";
import { RISK_MONITOR_TRANSACTIONS } from "../columns/riskMonitorTransactions";
import { SETTLEMENT_COLUMNS } from "../columns/settlement";

type Props = {
  isFilter: boolean;
  allColumns: Record<string, string[]>;
  visibleColumns: string[];
  monthlyColumns: string[];
  EXPORT_FORM_NAME?: string;
  closeModal(): void;
  column_type: Colum_types;
  merchantId?: number;
  sortReduxKey?: string;
  searchKey?: string;
  formattedFilterParams?: string;
  showMonthlyReport?: boolean;
  ofacBusinessColumns: string[];
};

export const SUPPORTED_EXTENSIONS_EXPORT = [
  // { label: "PDF (.pdf)", value: "pdf" },
  { label: "CSV (.csv)", value: "csv" },
];

export const useExportTable = ({
  isFilter,
  allColumns,
  visibleColumns,
  monthlyColumns,
  EXPORT_FORM_NAME,
  closeModal,
  column_type = "merchant",
  merchantId,
  sortReduxKey,
  searchKey,
  formattedFilterParams,
  showMonthlyReport,
  ofacBusinessColumns,
}: Props) => {
  const modal = useModal();
  const userData = useAppSelector(selectUser);
  const initialTimezone = userData.timezone || getSessionTimezone();
  //this is always the default value in custon date range section
  const { startDate, endDate } = getMonthRange("current");
  const defaultValues: TExportTable = {
    fileName: generateFileName(EXPORT_FORM_NAME),
    fileType: "csv",
    exportType: isFilter ? "filtered" : "all",
    dateRange: "current_month",
    customDateRange: {
      timezone: initialTimezone,
      startDate: startDate,
      endDate: endDate,
    },
    columnsIncluded: column_type === "settlement" ? "all" : "visible",
    selectedColumns:
      column_type === "settlement"
        ? Object.values(allColumns).flat()
        : visibleColumns,
  };

  const methods = useForm<TExportTable>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { getValues } = methods;
  const { exportType = "all", selectedColumns = [] } = useWatch({
    control: methods.control,
  });
  const applyFilters = isFilter && exportType !== "all";
  const filterQuery = useQueryString({
    isMerchant: true,
    applyAdvancedQuery: applyFilters,
  });

  // have to send ownerMembershipStatus in /submerchants endpoint but ownerMembershipStatus for /submerchants.csv endpoint
  const updatedFilterQuery = filterQuery?.includes("ownerMembershipStatus")
    ? filterQuery.replaceAll("ownerMembershipStatus", "ownerMembershipStatus")
    : filterQuery;

  const salesInfo = (MERCHANT_COLUMNS as { salesInfo: string[] }).salesInfo;
  const salesInfoColumns = ensureArray(salesInfo);
  const ofacInfo = (MERCHANT_COLUMNS as { ofacInfo: string[] }).ofacInfo;
  const ofacInfoColumns = ensureArray(ofacInfo);

  // if includes  salesInfo, OFAC columns we have to call the new endpoint => `/submerchants-monthly-processing.csv`
  //on this end point is applied the permission
  const includesRestrictedColumns: boolean = useMemo(() => {
    const isSalesColumnsIncluded = selectedColumns.some((col) =>
      salesInfoColumns.includes(col),
    );
    const isOfacInfoColumnsIncluded = selectedColumns.some((col) =>
      ofacInfoColumns.includes(col),
    );
    return isSalesColumnsIncluded || isOfacInfoColumnsIncluded;
  }, [selectedColumns]);

  const { handleExport, isLoading } = useDownloadExportedTable({
    withFilters: applyFilters,
    filterQuery: updatedFilterQuery,
    closeModal,
    column_type,
    merchantId,
    sortReduxKey,
    searchKey,
    formattedFilterParams,
    includesRestrictedColumns,
    showMonthlyReport,
  });

  const onSubmit: SubmitHandler<TExportTable> = async (data) => {
    //convert the date by timeZone, if its the same as selected returns the same value
    const convertedStartDate = convertToTimezone(
      data.customDateRange.startDate,
      data.customDateRange.timezone,
    );
    const convertedEndtDate = convertToTimezone(
      data.customDateRange.endDate,
      data.customDateRange.timezone,
    );
    const timeZoneOffSet = getTimeZoneUtcOffSet(data.customDateRange.timezone);
    let payload = null;
    const hasSelectedDate =
      !!data.customDateRange.startDate && !!data.customDateRange.endDate;
    switch (data.columnsIncluded) {
      case "custom":
        payload = {
          includeAllColumns: false,
          columns: data.selectedColumns,
          fileNamePrefix: "GIVE_MERCHANTS_",
          reportType: "merchant_report",
          ...(column_type === "settlement" &&
            hasSelectedDate && {
              dateRange: {
                startDate: data.customDateRange.startDate,
                endDate: data.customDateRange.endDate,
              },
            }),
        };
        break;
      case "visible":
        payload = {
          includeAllColumns: false,
          columns: visibleColumns,
          fileNamePrefix: "GIVE_MERCHANTS_",
          reportType: "only_in_view",
        };
        break;
      case "processing_monthly":
        payload = {
          includeAllColumns: false,
          columns: monthlyColumns,
          fileNamePrefix: "GIVE_MONTHLY_MERCHANT_PROCESSING_",
          dateRange: {
            startDate: convertedStartDate,
            endDate: convertedEndtDate,
          },
          utcOffSet: timeZoneOffSet,
          reportType: "processing_monthly_report",
        };
        break;
      case "ofac_business":
        payload = {
          includeAllColumns: false,
          columns: ofacBusinessColumns,
          fileNamePrefix: "GIVE_OFAC_BUSINESS_",
          dateRange: {
            startDate: convertedStartDate,
            endDate: convertedEndtDate,
          },
          utcOffSet: timeZoneOffSet,
          reportType: "ofac_legal_entity_report",
        };
        break;
      case "ofac_persons":
        payload = {
          includeAllColumns: false,
          fileNamePrefix: "GIVE_OFAC_PERSONS_",
          dateRange: {
            startDate: convertedStartDate,
            endDate: convertedEndtDate,
          },
          utcOffSet: timeZoneOffSet,
          reportType: "ofac_persons_report",
        };
        break;
      // case "maintenance_monthly": // TODO add when implemented
      case "all":
      default:
        payload = {
          includeAllColumns: true,
          fileNamePrefix: "GIVE_MERCHANTS_",
          reportType: "merchant_report",
          ...(column_type === "settlement" &&
            hasSelectedDate && {
              dateRange: {
                startDate: data.customDateRange.startDate,
                endDate: data.customDateRange.endDate,
              },
            }),
        };
        break;
    }

    handleExport({ data, payload } as TExportHandlerArgs);
  };

  useEffect(() => {
    if (modal.visible) {
      methods.reset(defaultValues);
    }
  }, [modal.visible]);

  const selectedColumnsIncluded = getValues("columnsIncluded");
  const columnsWithDateRange = [
    "processing_monthly",
    "ofac_business",
    "ofac_persons",
  ];
  const showDateRangeSection = columnsWithDateRange.includes(
    selectedColumnsIncluded,
  );

  return {
    methods,
    onSubmit,
    isLoading,
    initialTimezone,
    showDateRangeSection,
  };
};

const schema = Yup.object({
  fileName: Yup.string().required("Required"),
  fileType: Yup.string().required("Required"),
  exportType: Yup.mixed<ExportType>(),
  dateRange: Yup.mixed<DateRange>(),
  customDateRange: Yup.object({
    startDate: Yup.number(),
    endDate: Yup.number(),
  }),
  columnsIncluded: Yup.mixed<ColumnExport>(),
  selectedColumns: Yup.array(Yup.string()),
});

export const getDefaultSelectedColumns = (
  columns: Record<string, string[]>,
) => {
  let newArray: string[] = [];
  Object.values(columns).forEach((array) => {
    newArray = [...newArray, ...array];
  });
  return newArray;
};

export const generateFileName = (name = "GIVE_MERCHANTS_") =>
  name + format(new Date(), "yyyyMMdd_HHmmss");
