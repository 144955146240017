import { Divider } from "@mui/material";
import { useAppTheme } from "@theme/v2/Provider";
interface Props {
  direction?: "horizontal" | "vertical";
  bgType?: "primary" | "secondary";
}
function GiveDivider({ direction = "horizontal", bgType = "primary" }: Props) {
  const { palette } = useAppTheme();

  const bgColor = {
    primary: palette?.border?.primary,
    secondary: palette?.border?.secondary,
  };

  return (
    <Divider
      sx={{
        bgcolor: bgColor[bgType],
        my: "16px",
        width: direction === "horizontal" ? "100%" : "1px",
        height: direction === "vertical" ? "100%" : "1px",
      }}
    />
  );
}

export default GiveDivider;
