import React from "react";
import BaseCardComponent from "../BaseCardComponent";
import useSnapShot from "features/Merchants/MerchantSidePanel/hooks/useSnapShot";

function ChargeBackFees() {
  const { chargeBackFees } = useSnapShot();
  return (
    <BaseCardComponent leftTitle="Chargeback Fees" payload={chargeBackFees} />
  );
}

export default ChargeBackFees;
