import { useAppDispatch } from "@redux/hooks";
import {
  resetConversationTopic,
  setConversationTopic,
  setModalOpenConversation,
} from "@redux/slices/conversations";
import { getGlobalTopic } from "features/Minibuilders/Conversations/hooks/useConversationsModal";

export function useNotifications() {
  const dispatch = useAppDispatch();

  const closeNotifyMerchantModal = () => {
    dispatch(setModalOpenConversation(false));
    dispatch(resetConversationTopic());
  };

  const openNotifyMerchantModal = async () => {
    const { data: topic } = await getGlobalTopic({
      topicName: "underwriting",
    });

    const underwritingTopicID = topic?.find(
      (item: any) => item.Name === "underwriting",
    )?.ID;

    if (!underwritingTopicID) return;

    dispatch(setModalOpenConversation(true));
    dispatch(
      setConversationTopic({
        isOpen: true,
        isOpenedFromSidePanel: false,
        numberOfUnreadMessages: 0,
        queryObject: {
          id: underwritingTopicID,
          name: `Notify Merchant`, //later update for provider too
          tabs: "Activity",
          paths: [
            {
              isConversation: true,
              pathName: `Notify Merchant`,
              pathID: "new",
              avatars: [],
              hideInputs: ["module"],
              topicName: "general",
            },
          ],
        },
      }),
    );
  };

  return { openNotifyMerchantModal, closeNotifyMerchantModal };
}
