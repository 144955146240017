import { useCallback, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, FormProvider, useForm } from "react-hook-form";
import { NameInput } from "@common/BusinessProfileInputs";
import { BirthDatePicker } from "@common/DatePickers";
import { RHFTelInput } from "@common/Input";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { RHFCheckbox } from "@common/Checkbox";
import { OwnerInformations } from "./components/AdressInformations";
import { palette } from "@palette";
import { OwnerShip } from "./components/OwnerShip";
import { schema } from "./form/schema";
import { FormInputs } from "./form/form.type";
import {
  getDefaultValues,
  getIsOwnerBoxDisabled,
  getIsOwnerCheckedByDefault,
} from "./form/form.const";
import { InformationProps } from "./types";
import { BusinessProfileDisclaimer } from "./components/BusinessProfileDisclaimer";
import { CustomStyleTooltip } from "./components/CustomStyleTooltip";
import { isEmpty } from "lodash";
import { merchantDateFormatter } from "@components/Merchants/MerchantPreview/helpers/parsers";
import CustomTaxSsnInput from "@components/common/BusinessProfileInputs/CustomTaxSsnInput";
import { Text } from "../../components/common/Text";
import { styled } from "@mui/material";
import useCheckPAH from "@hooks/common/useCheckPAH";
import { NonResidentInputs } from "@common/NonResidentInput/NonResidentInputs";

/*
 Hide the modal is is in control mode
 The modal appears only if the account holder was already invited, confirmed and then logged in
 owner attr shoudl be send anymore
*/

export function Information({
  checkValidity,
  updateProgressBar,
  data,
  onSubmit,
  leType,
  leApproved,
  fullOwnershipReleased,
  hasLE,
  hasOwner,
  owner,
  isController,
  principal,
}: InformationProps) {
  const methods = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues:
      (data as FormInputs) ?? getDefaultValues(leType, fullOwnershipReleased),
    mode: "onChange",
  });

  const isOwnerVal = methods.watch("isOwner");
  const isPrincipalSaved = principal && !isEmpty(principal);
  useEffect(() => {
    if (!isEmpty(data)) return methods.reset(data);
    if (!isEmpty(owner) && isEmpty(data))
      methods.reset(
        generateOwnerData(owner, principal, leType, fullOwnershipReleased),
      );
  }, [owner, data, principal]);

  const isOwnerCheckDisabled =
    getIsOwnerBoxDisabled(leType, fullOwnershipReleased) ||
    leApproved ||
    !isController ||
    isPrincipalSaved;
  const { isLoggedInPAH } = useCheckPAH();

  const isTaxExemptOrGovtAgency = [
    "tax_exempt_organization",
    "government_agency",
  ].includes(leType);

  const inputList = [
    {
      input: (
        <NameInput
          name="base.firstName"
          label="First name"
          placeholder="First name"
          disabled={!isLoggedInPAH}
        />
      ),
    },
    {
      input: (
        <NameInput
          name="base.lastName"
          label="Last name"
          placeholder="Last name"
          disabled={!isLoggedInPAH}
        />
      ),
    },
    {
      input: (
        <BirthDatePicker
          name="base.dob"
          placeholder="MM/DD/YYYY"
          label="Date of Birth"
          onBlur={() => {
            methods.trigger("base.dob");
          }}
          disabled={!isLoggedInPAH}
        />
      ),
    },
    {
      input: (
        <RHFTelInput
          onBlur={() => {
            methods.trigger("base.phoneNumber");
          }}
          name="base.phoneNumber"
          label="Phone Number"
          fullWidth
          disabled={!isLoggedInPAH}
        />
      ),
    },
    {
      input: (
        <NonResidentInputs namePrefix="base" inputsDisabled={!isLoggedInPAH} />
      ),
    },
    {
      input: (
        <RHFCheckbox
          dataTestId="i_have_managerial_authority_checkbox"
          name="base.isManager"
          label="I have managerial authority of the business."
          disabled={!isLoggedInPAH}
        />
      ),
    },
    {
      input: (
        <CustomStyleTooltip
          disableHoverListener={!isOwnerCheckDisabled}
          disableFocusListener={!isOwnerCheckDisabled}
          disableTouchListener={!isOwnerCheckDisabled}
          title="This business has already reached the maximum number of owners allowed."
          placement="top-start"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -14],
                  },
                },
              ],
            },
          }}
        >
          <span>
            <RHFCheckbox
              disabled={isOwnerCheckDisabled}
              disabledStyles={{
                stroke: palette.neutral[40],
                "stroke-width": 1.6,
              }}
              name="isOwner"
              dataTestId="owner_checkbox"
              label={
                ["tax_exempt_organization", "government_agency"].includes(
                  leType,
                )
                  ? "I’m also a controller of the organization"
                  : "I’m also an owner of this business"
              }
              labelProps={{
                ...(isOwnerCheckDisabled && {
                  color: palette.neutral[70],
                }),
              }}
            />
          </span>
        </CustomStyleTooltip>
      ),
      disabled: !hasOwner || !hasLE || !isLoggedInPAH, //both valeus are required(Owner and LE)
    },
  ];

  const {
    formState: { isValid },
  } = methods;

  useEffect(() => {
    checkValidity(isValid);
  }, [isValid]);

  const base = methods.getValues("base");
  const ownership = methods.getValues("whenIsOwner");

  const onUpdateProgressBar = useCallback(() => {
    const { address, ...rest } = ownership;

    type _base = keyof typeof base;
    type _address = keyof typeof address;
    type _rest = keyof typeof rest;

    const a = (Object.keys(base) as _base[]).filter((k) => base[k]).length;
    const b = (Object.keys(address) as _address[]).filter(
      (k) => address[k],
    ).length;
    const c = (Object.keys(rest) as _rest[]).filter((k) => rest[k]).length;

    let total = Object.keys(base).length;
    let currentProgress = a - 1; //1 step for api to be successfull

    if (methods.getValues("isOwner")) {
      //substract 2 from current (isOwner and remove one between EIN or SSN)
      //substract 3 from total (is current with 1 substracted from Conuntry which is predefined and constant)
      total += Object.keys(address).length;
      total += Object.keys(rest).length;

      currentProgress += b;
      currentProgress += c;

      total -= 3;
      currentProgress -= 2;
    }

    if (methods.watch("base.phoneNumber") === "+1") {
      //ODDDD behaviour of the input to keep the prefix on the form state
      currentProgress -= 1;
    }

    updateProgressBar((currentProgress * 100) / total);
  }, [base, ownership]);

  return (
    <FormProvider {...methods}>
      <Form
        id="confirm-primary-account-holder"
        onSubmit={() => {
          methods.handleSubmit(onSubmit)();
        }}
        onBlur={onUpdateProgressBar}
        onChange={(e) => {
          //Because on autocompletion of input fields, the values do not get updated, we check on on blur and onchange for checkkoxes
          if (
            (e.target as any).getAttribute("name") === "base.isManager" ||
            (e.target as any).getAttribute("name") === "isOwner"
          ) {
            onUpdateProgressBar();
          }
        }}
      >
        <Box>
          <Grid container rowSpacing={1} columnSpacing={2}>
            {inputList
              .filter((x) => !x.disabled)
              .map(({ input }, index) => (
                <Grid key={index} item xs={12}>
                  <FadeUpWrapper delay={100 * (index + 1)}>
                    {input}
                  </FadeUpWrapper>
                </Grid>
              ))}
          </Grid>
          {isOwnerVal && (
            <Grid paddingTop={2} gap={3} borderRadius="16px" container>
              {leType === "individual_sole_proprietorship" && (
                <BusinessProfileDisclaimer
                  messageType="sole_proprietor"
                  containerStyle={{ marginTop: "16px" }}
                />
              )}
              {isPrincipalSaved && (
                <BusinessProfileDisclaimer messageType="owner" />
              )}
              <Grid item xs={12}>
                <Text paddingBottom={2} fontSize={18} fontWeight="book">
                  Tax ID
                </Text>
                <StyledTaxInput>
                  <CustomTaxSsnInput
                    ssnName="whenIsOwner.ssn"
                    taxIdName="whenIsOwner.ein"
                    tinType="whenIsOwner.tinType"
                    isBusiness={false}
                    disabled={isPrincipalSaved || !isLoggedInPAH}
                  />
                </StyledTaxInput>
              </Grid>
              <OwnerInformations disabled={isPrincipalSaved} />
              <OwnerShip
                isIndividualSoleProprietorship={
                  leType === "individual_sole_proprietorship"
                }
                isFullyDisabled={
                  isPrincipalSaved || !isLoggedInPAH || isTaxExemptOrGovtAgency
                }
                leType={leType}
              />
            </Grid>
          )}
          {!isController && (
            <BusinessProfileDisclaimer messageType="controller" />
          )}
        </Box>
      </Form>
    </FormProvider>
  );
}
const generateOwnerData = (
  data: any,
  principal: any,
  leType: string,
  fullOwnershipReleased: number,
): FormInputs => {
  const user = data;
  const address = principal?.address;

  return {
    isOwner: !isEmpty(principal)
      ? true
      : fullOwnershipReleased === 100
      ? false
      : getIsOwnerCheckedByDefault(leType),
    base: {
      dob: merchantDateFormatter("MM/dd/yyyy", user?.dateOfBirth) || null,
      firstName: user?.firstName,
      isManager: data?.isManagerialAuthority,
      lastName: user?.lastName,
      phoneNumber: user?.phoneNumber ? `+${data.phoneNumber}` : null,
      isNotUSResident: !!user?.citizenship,
      citizenship: user?.citizenship || "",
      isNotResidentInCitizenshipCountry: !!user?.countryOfResidence,
      countryOfResidence: user?.countryOfResidence || "",
    } as FormInputs["base"],
    whenIsOwner: {
      tinType: principal?.tinType ?? "ssn",
      address: {
        city: address?.city || "",
        country: address?.country || "US",
        line1: address?.line1,
        state: address?.state,
        zip: address?.zip,
      },
      ein:
        principal?.tinType === "ein"
          ? `xxxxx${principal?.taxIDNumberLast4}`
          : "",
      ownership:
        leType === "individual_sole_proprietorship"
          ? "100"
          : ["tax_exempt_organization", "government_agency"].includes(leType)
          ? "0"
          : `${principal?.ownershipPercentage}`,
      ssn:
        principal?.tinType === "ssn"
          ? `xxxxx${principal?.taxIDNumberLast4}`
          : "",
    },
  };
};

const StyledTaxInput = styled(Box)(() => ({
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: `${palette.liftedWhite[100]} !important`,
    border: `1px solid ${palette.liftedWhite[200]}`,
    "& p": {
      WebkitTextFillColor: `${palette.black[100]} !important`,
    },
  },
  "& .taxid-input .MuiInputBase-root.Mui-disabled": {
    backgroundColor: `${palette.liftedWhite[200]} !important`,
    boxShadow: "none",
    border: "none",

    "& input": {
      marginTop: "18px",
      WebkitTextFillColor: palette.black[100],
      color: palette.black[100],
    },
  },
}));
