import GiveButton from "@shared/Button/GiveButton";
import { usePayBuilderContext } from "../provider/PayBuilderContext";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import { usePayBuilderForm } from "../provider/PayBuilderFormProvider";
import { SaveAndCloseBtn } from "./SaveAndCloseBtn";

export const LeftPanelActions = () => {
  const { isMobileView } = useCustomThemeV2();
  const { activeStepIndex, isLastStep, goToStep } = usePayBuilderContext();
  const { mutate } = usePayBuilderForm();
  return (
    <>
      {!isMobileView && <SaveAndCloseBtn />}
      {activeStepIndex !== 0 && (
        <GiveButton
          size="large"
          sx={{
            marginLeft: isMobileView ? 0 : "auto",
          }}
          variant="ghost"
          label="Back"
          onClick={() => goToStep({ offSet: -1 })}
        />
      )}
      {isLastStep ? (
        <GiveButton
          size="large"
          label="Publish"
          onClick={() => console.log("Published")}
          sx={{
            marginLeft: isMobileView ? "auto" : 0,
          }}
        />
      ) : (
        <GiveButton
          size="large"
          label="Next"
          onClick={() =>
            mutate({ handleSuccessCB: () => goToStep({ offSet: 1 }) })
          }
          sx={{
            marginLeft: isMobileView ? "auto" : 0,
          }}
        />
      )}
    </>
  );
};
