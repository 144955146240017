import * as React from "react";
import { useFormContext, Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import { MuiTelInput, MuiTelInputProps } from "mui-tel-input";
import { Tooltip, TooltipProps } from "@common/Tooltip/Tooltip";
import { FormLabel } from "@mui/material";
import { styled, useAppTheme } from "@theme/v2/Provider";

export type TelInputProps = MuiTelInputProps & {
  color?: string;
  error?: boolean;
  disabled?: boolean;
  inputRef?: React.Ref<any>;
  label?: React.ReactNode;
  size?: "small" | "medium";
  helperText?: React.ReactNode;
  tooltipProps?: TooltipProps;
  flagStyles?: any;
  focusViewColor?: string;
};

const TelInput = ({
  label,
  size = "medium",
  error,
  helperText,
  disabled,
  tooltipProps,
  value,
  ...rest
}: TelInputProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const caret: number | null = event.target.selectionStart;
    const value: string = event.target.value;

    const succeedingChar: string =
      caret !== null && caret < value.length ? value.charAt(caret) : "";

    const element = event.target as HTMLInputElement;
    window.requestAnimationFrame(() => {
      element.selectionStart =
        succeedingChar === "" ? (caret !== null ? caret + 1 : null) : caret;
      element.selectionEnd = element.selectionStart;
    });
  };
  const { palette } = useAppTheme();
  return (
    <Box display="flex" flexDirection="column" gap="8px">
      {label && (
        <FormLabel
          error={error}
          disabled={rest.disableDropdown}
          sx={{
            ...(error && { color: palette.primitive?.error }),
            color: palette.text.primary,
            fontSize: 14,
          }}
        >
          {label}
        </FormLabel>
      )}
      <StyledTelInput
        size={size}
        ref={inputRef}
        value={value}
        onInput={handleInput}
        inputProps={{
          maxLength: 12,
          "data-testid": "phone-input",
        }}
        {...rest}
        disabled={disabled}
        helperText={helperText}
        error={error}
        version="two"
        InputLabelProps={{
          variant: "outlined",
          sx: {
            textAlign: "start",
          },
        }}
      />
      {tooltipProps?.title && tooltipProps?.children && (
        <Box
          sx={{
            ...(error && { paddingBottom: "14px" }),
          }}
        >
          <Tooltip {...tooltipProps} />
        </Box>
      )}
    </Box>
  );
};

export default TelInput;

export const HFGiveTelephone = ({
  name,
  helperText,
  disableFormatting = false,
  formatUSOnly = true,
  ...props
}: TelInputProps & {
  name: string;
  formatUSOnly?: boolean;
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...rest }, fieldState: { error } }) => {
        const shouldFormat =
          formatUSOnly &&
          !!rest.value &&
          typeof rest.value === "string" &&
          rest.value.substring(0, 2) === "+1";
        //REFACTOR NEEDED: rest of the countries use different formatting, and it messes up validation, we should use our own tel input instead of library
        return (
          <TelInput
            inputRef={ref}
            {...rest}
            error={!!error}
            helperText={helperText || error?.message}
            defaultCountry="US"
            forceCallingCode
            disableFormatting={disableFormatting || !shouldFormat}
            {...props}
          />
        );
      }}
    />
  );
};

const StyledTelInput = styled(MuiTelInput)(() => ({
  "& .MuiTypography-root": {
    marginTop: "0 !important",
  },
  "& .MuiInputAdornment-positionStart .MuiTypography-root": {
    marginTop: "16px",
    borderRight: "none",
    fontFamily: "Give Whyte",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px",
  },
  "& .Mui-disabled .MuiInputAdornment-positionStart .MuiTypography-root": {
    WebkitTextFillColor: "rgba(0, 0, 0, 0.38)",
  },
  "& .MuiTelInput-IconButton": {
    height: "auto",
    boxShadow: "none",
    backgroundColor: "inherit",
    border: "none",

    "&:hover": {
      boxShadow: "none",
      background: "none",
    },

    "&:active, &:focus": {
      border: "none",
      boxShadow: "none",
    },
  },

  "& .MuiTelInput-Flag img": {
    width: 24,
    height: 24,
    borderRadius: "50%",
  },

  "& .MuiInputLabel-root": {
    left: "30px",
    width: "calc(100% + 22.5px)",
  },
}));
