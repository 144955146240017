import { Box, BoxProps, styled } from "@mui/material";
import { ITextProps, Text } from "@common/Text/Text";
import { palette } from "@palette";
import { Tooltip } from "@common/Tooltip";
import moment from "moment";
import { TAG_STATUSES_ENUM } from "@common/TextTag/types";

interface TextTagProps extends BoxProps {
  textProps?: ITextProps;
  disableTooltip?: boolean;
  statusDisplayName: string;
  updatedAt?: number | null;
  updatedBy?: string;
}

const TextTag = styled<React.FC<TextTagProps>>(function ({
  textProps,
  statusDisplayName,
  updatedAt = null,
  updatedBy = "",
  disableTooltip,
  ...rest
}) {
  const statusTag = colorsMap[statusDisplayName];
  const { color, backgroundColor } =
    statusTag || colorsMap[TAG_STATUSES_ENUM.APPROVED];
  const label = statusTag ? statusDisplayName : TAG_STATUSES_ENUM.APPROVED;

  const isInviteStatus = statusDisplayName === TAG_STATUSES_ENUM.INVITE;

  const isTooltipEnabled = isInviteStatus
    ? !!updatedAt
    : !!updatedAt && !!updatedBy?.trim();

  const tooltipContent = isInviteStatus
    ? "Invitation sent"
    : `${statusDisplayName} by: ${updatedBy?.trim()}`;

  return (
    <Box {...rest} width="100%">
      <Tooltip
        title={`${tooltipContent} ${dateParser(updatedAt)}`}
        placement="top"
        disableHoverListener={!isTooltipEnabled || disableTooltip}
      >
        <StyledText
          sx={{
            paddingY: "4px",
            color,
            backgroundColor,
            width: "100%",
          }}
          {...textProps}
        >
          {label}
        </StyledText>
      </Tooltip>
    </Box>
  );
})(() => ({
  minWidth: "94px",
}));

export default TextTag;

const dateParser = (date?: number | null) =>
  date ? ` on ${moment.unix(date).format("LL")}` : "";

const colorsMap: Record<string, { color: string; backgroundColor: string }> = {
  [TAG_STATUSES_ENUM.APPROVED]: {
    color: palette.filled.success,
    backgroundColor: palette.tag.success.bg,
  },
  [TAG_STATUSES_ENUM.UNDERWRITING]: {
    color: palette.info.main,
    backgroundColor: palette.info.light,
  },
  [TAG_STATUSES_ENUM.SUSPENDED]: {
    color: palette.tag.warning.hover,
    backgroundColor: palette.tag.warning.bg,
  },
  [TAG_STATUSES_ENUM.DECLINED]: {
    color: palette.tag.error.hover,
    backgroundColor: palette.tag.error.bg,
  },
  [TAG_STATUSES_ENUM.INVITE]: {
    color: palette.neutral[80],
    backgroundColor: palette.neutral[10],
  },
  [TAG_STATUSES_ENUM.PENDING]: {
    color: palette.neutral[80],
    backgroundColor: palette.neutral[10],
  },
  [TAG_STATUSES_ENUM.READY_FOR_REVIEW]: {
    color: palette.tag.warning.hover,
    backgroundColor: palette.tag.warning.bg,
  },
  [TAG_STATUSES_ENUM.IN_PROGRESS]: {
    color: palette.filled?.orange,
    backgroundColor: palette.tag.warning.bg,
  },
  [TAG_STATUSES_ENUM.DELIVERED]: {
    color: palette.neutral[80],
    backgroundColor: palette.neutral[10],
  },
  [TAG_STATUSES_ENUM.UNDELIVERABLE]: {
    color: palette.error.hover,
    backgroundColor: palette.tag.error.bg,
  },
};

const StyledText = styled(Text)(() => ({
  textAlign: "center",
  userSelect: "none",
  padding: "2px 16px",
  fontSize: "12px",
  lineHeight: "14.4px",
  borderRadius: "50px",
}));
