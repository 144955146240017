import React from "react";
import { styled } from "@theme/v2/Provider";
import GiveText from "@shared/Text/GiveText";

function TitleLabel({ title }: { title: string }) {
  return <Title>{title}</Title>;
}

export default TitleLabel;

const Title = styled(GiveText)(() => {
  return {
    fontSize: "28px",
    fontWeight: 357,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "34px",
  };
});
