import { ProductType } from "@components/CreateCampaign/types";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

export default function useGenerateTypeList() {
  const { isPayBuilderEnabled } = useGetFeatureFlagValues();

  const typeList: { name: ProductType; description: string }[] = [
    ...(isPayBuilderEnabled
      ? [
          {
            name: "Product" as ProductType,
            description: "Create a listing to sell one or more items",
          },
        ]
      : [
          {
            name: "Standard" as ProductType,
            description: "Use the Payment Forms for ease and security.",
          },
        ]),

    {
      name: "Event",
      description: "Start accepting donations instantly and safely.",
    },
    {
      name: "Fundraiser",
      description: "Start accepting donations instantly and safely.",
    },
    {
      name: "Invoice",
      description: "Organizing your invoices has never been easier.",
    },
    {
      name: "Membership",
      description: "From recurring donations to automated memberships.",
    },
    {
      name: "Sweepstake",
      description: "Set up, sell tickets and select winners, with total ease.",
    },
  ];
  return { typeList };
}
