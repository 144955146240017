import { Grid } from "@mui/material";
import ProductCard from "./ProductCard";
import { useMemo } from "react";
import { usePayBuilderForm } from "@sections/PayBuilder/provider/PayBuilderFormProvider";
import { ProductItemType } from "@sections/PayBuilder/types";
import { Item_Layout } from "@sections/PayBuilder/provider/provider.type";
import { usePayBuilderContext } from "@sections/PayBuilder/provider/PayBuilderContext";

interface ProductsProps {
  items: ProductItemType[];
  displayStatus: "preview" | "published";
  itemLayout: Item_Layout;
}

const ProductList = ({
  displayStatus,
  items: Items,
  itemLayout,
}: ProductsProps) => {
  const { methods } = usePayBuilderForm();

  const {
    screenStates: { isMobileView },
  } = usePayBuilderContext();
  const cardWidth = isMobileView
    ? "repeat(auto-fill, minmax(171px, 1fr))"
    : "repeat(auto-fill, minmax(260px, 1fr))";
  const listWidth = isMobileView ? "repeat(1, 1fr)" : "repeat(2, 1fr)";

  const sortedProducts = useMemo(() => {
    return [...Items]?.sort((a, b) => {
      if (a.in_stock && !b.in_stock) return -1;
      if (!a.in_stock && b.in_stock) return 1;
      return 0;
    });
  }, [Items]);
  return (
    <Grid
      container
      sx={{
        display: "grid",
        gap: "16px",
        gridTemplateColumns: itemLayout === "list" ? listWidth : cardWidth,
      }}
    >
      {sortedProducts?.map((product) => (
        <ProductCard
          key={product.id}
          product={product}
          displayStatus={displayStatus}
          itemLayout={itemLayout}
        />
      ))}
    </Grid>
  );
};

export default ProductList;
