import { Box, styled, SxProps, Theme } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

type FormInputs = {
  amexCheckbox: boolean;
  expressCheckbox: boolean;
};

type TTosWrapper = {
  children: React.ReactNode;
  customStyles?: SxProps<Theme>;
};

export const TosWrapper = ({ children, customStyles }: TTosWrapper) => {
  const methods = useForm<FormInputs>({
    mode: "onChange",
    defaultValues: {
      amexCheckbox: true,
      expressCheckbox: true,
    },
  });

  // TODO: handle api when needed, for now checkboxes are implemented in FE

  return (
    <Wrapper customStyles={customStyles}>
      <Box component="form">
        <FormProvider {...methods}>{children}</FormProvider>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled("div")(({ customStyles }: { customStyles: any }) => ({
  backgroundColor: "#fff",
  fontFamily:
    'Circular, "Helvetica Neue", Helvetica, Arial, sans-serif !important',
  color: "#253655",
  padding: "20px",
  minHeight: "100%",
  borderRadius: "20px",
  textAlign: "justify",
  ...customStyles,
}));
