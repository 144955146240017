import { showMessage } from "@common/Toast";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { Box, Divider, Stack, styled } from "@mui/material";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useInView } from "react-intersection-observer";
import { Document, Page, PageProps } from "react-pdf";
import { OnError, OnRenderSuccess } from "react-pdf/dist/cjs/shared/types";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { DotsMenuButton } from "../components/DotsMenuButton";
import PaginationBar from "../components/PdfPreviewer/PaginationBar";
import SearchButton from "../components/PdfPreviewer/SearchButton";
import SearchSubHeader from "../components/PdfPreviewer/SearchSubHeader";
import { ZoomControls } from "../layout/Toolbar.atoms";
import { PreviewToolbar } from "../layout/types";
import { TRenderer } from "../types";
import usePages from "./usePages";
import useTextSearch from "./useTextSearch";
import useZoom from "./useZoom";

type TSetVisibilityHandler = (page: number, isIntersecting: boolean) => void;

// padding top + padding bottom
const PAGE_PADDING = 40;
// 44px for header and 44ps for footer
const LAYOUT_OFFSET_HEIGHT = 88;

const usePdfPreviewer: TRenderer = ({
  file,
  onClose,
  handleOpenDrawer,
  showControls,
  toggleControlsPersistence,
  customDocumentWrapperStyles,
}) => {
  const [highlights, setHighlights] = useState<NodeListOf<HTMLElement> | null>(
    null,
  );
  const [isSearchPanelFocused, setIsSearchPanelFocused] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const {
    numPages,
    pageNumber,
    setPageNumber,
    onDocumentLoadSuccess,
    setPagesRendered,
    pagesRenderedPlusOne,
    resetPages,
  } = usePages();

  const { isMobileView, isDesktopView } = useCustomTheme();
  const { zoom, onZoom, resetZoom, disabled } = useZoom({
    // setting defaut to 0.56 makes the width or height half size, wich leaves room
    // for other pages, and since we want to show one page per view, we try to make it fit
    // almost all device width
    defaultValue: 1,
    minZoom: 1,
    maxZoomEqualsDeviceSize: true,
    isPdf: true,
    step: 0.1,
  });

  const { isSearchActive, toggleSearch, textRenderer, searchText, onSearch } =
    useTextSearch();

  const onRenderSuccess: OnRenderSuccess = () => {
    setPagesRendered((prev) => prev + 1);
  };

  const selectHighlights = useCallback(() => {
    if (!searchText) return setHighlights(null);
    if (searchText && wrapperRef.current) {
      const pickHighlights = wrapperRef.current.querySelectorAll("mark");
      setHighlights(pickHighlights);
    }
  }, [searchText]);

  const onChangePage = (page: number) => {
    setPageNumber(page);
    if (!wrapperRef.current) return;

    const pageElement = wrapperRef.current.querySelector(
      `div[data-page-number='${page}']`,
    );
    if (pageElement) pageElement.scrollIntoView();
  };

  useEffect(() => {
    resetPages();
    resetZoom();
    toggleSearch(false);
  }, [file]);

  const setPageVisibility: TSetVisibilityHandler = useCallback(
    (page, isIntersecting) => {
      if (isIntersecting) setPageNumber(page);
    },
    [],
  );

  const toolbar: PreviewToolbar = {
    primary: {
      content: (
        <Stack direction="row" gap="8px" alignItems="center">
          <PaginationBar
            page={pageNumber}
            onChange={onChangePage}
            numPages={numPages}
            onFocus={setIsSearchPanelFocused}
          />
          {isDesktopView && (
            <>
              <Divider orientation="vertical" flexItem />
              <ZoomControls
                onZoom={onZoom}
                disabled={disabled}
                onMouseEnterAndLeave={toggleControlsPersistence}
              />
            </>
          )}
        </Stack>
      ),
    },
  };

  const onLoadError: OnError = () => {
    showMessage("Error", "An error occurred while rendering the pdf");
    if (onClose) onClose();
  };

  const handleClickOnDocument: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };

  return {
    toolbar,
    isSearchPanelFocused,
    onZoom,
    headerActions: (
      <>
        <SearchButton isSearch={isSearchActive} toggleSearch={toggleSearch} />
        {!isDesktopView ? <DotsMenuButton onClick={handleOpenDrawer} /> : <></>}
      </>
    ),
    Previewer: (
      <>
        <DocumentWrapper
          ref={wrapperRef}
          onClick={handleClickOnDocument}
          data-testid="document-wrapper"
          sx={customDocumentWrapperStyles}
        >
          <Document
            file={file.URL}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<LoadingSpinner sx={{ height: "50vh" }} />}
            onLoadError={onLoadError}
          >
            {Array.from(
              { length: pagesRenderedPlusOne },
              (el: unknown, index: number) => {
                const isCurrentlyRendering = pagesRenderedPlusOne === index + 1;
                const isLastPage = numPages === index + 1;
                const renderOnSuccess =
                  isCurrentlyRendering && !isLastPage
                    ? onRenderSuccess
                    : undefined;
                const page = index + 1;
                return (
                  <PageWithObserver
                    key={`page_${page}`}
                    scale={zoom}
                    setPageVisibility={setPageVisibility}
                    onRenderSuccess={renderOnSuccess}
                    pageNumber={page}
                    customTextRenderer={textRenderer}
                    renderAnnotationLayer={false}
                    onRenderTextLayerSuccess={selectHighlights}
                    isMobileView={isMobileView}
                  />
                );
              },
            )}
          </Document>
        </DocumentWrapper>
        {isSearchActive && (
          <SearchSubHeader
            onClose={() => toggleSearch(false)}
            onSearch={onSearch}
            highlights={highlights}
            showControls={showControls || isSearchPanelFocused}
            onFocus={setIsSearchPanelFocused}
          />
        )}
      </>
    ),
  };
};

type TPageWithObserver = PageProps & {
  pageNumber: number;
  setPageVisibility: TSetVisibilityHandler;
  isMobileView: boolean;
};

const getThreshold = (scale = 1) => {
  if (scale > 1.4) {
    return 0.2;
  } else if (scale > 1) {
    return 0.4;
  } else if (scale < 0.8) {
    return 0.95;
  } else {
    return 0.8;
  }
};

const PageWithObserver = ({
  pageNumber,
  setPageVisibility,
  isMobileView,
  ...rest
}: TPageWithObserver) => {
  const { ref } = useInView({
    threshold: getThreshold(rest?.scale),
    onChange: (inView) => setPageVisibility(pageNumber, inView),
  });

  // to show one page per view, we set the height of vh - header & footer height
  // on mobile when width is set, height is ignored
  const pageHeight = window.innerHeight - LAYOUT_OFFSET_HEIGHT;
  const pageWidth = isMobileView ? window.innerWidth - PAGE_PADDING : undefined;

  return (
    <Page
      height={pageHeight}
      width={pageWidth}
      canvasRef={ref}
      pageNumber={pageNumber}
      {...rest}
    />
  );
};

const DocumentWrapper = styled(Box)(({ theme }) => ({
  "& .react-pdf__Document": {
    display: "flex",
    gap: "16px",
    flexDirection: "column",
    overflow: "auto",

    "& mark": {
      opacity: "0.5",
      background: "transparent",
      color: "transparent",
      whiteSpace: "pre",
    },
  },
  "& .textLayer": {
    overflow: "visible",
  },
  "& .react-pdf__Page:first-of-type": {
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "8px",
    },
  },
}));

export default usePdfPreviewer;
