import { Stack } from "@mui/material";
import { GiveTabProps } from "./GiveTab.types";
import GivePill from "./GivePill";
import { useAppTheme } from "@theme/v2/Provider";
import GiveLineTab from "./GiveLineTab";
import { useCustomThemeV2 } from "@theme/hooks/useCustomThemeV2";
import GiveProgressTab from "./GiveProgressTab";
import GiveSelect from "@shared/GiveInputs/GiveSelect";
import GiveText from "@shared/Text/GiveText";

export default function GiveTabs({
  items,
  onClick,
  type = "pill",
  variant,
  selected,
  containerSx,
  tabSx,
  hideMobileTitle,
}: GiveTabProps) {
  const { palette } = useAppTheme();
  const { isMobileView } = useCustomThemeV2();

  if (type === "pill")
    return (
      <Stack direction="row">
        {items.map((item, index) => (
          <GivePill
            key={index}
            variant={variant !== "underline" ? variant : undefined}
            {...item}
            selected={item.value === selected}
            onClick={onClick}
            tabSx={tabSx}
          />
        ))}
      </Stack>
    );
  if (type === "line")
    return (
      <Stack
        direction="row"
        justifyContent="flex-start"
        sx={{
          width: "100%",
          gap: "20px",
          ...(variant === "underline" && {
            borderBottom: `1px solid ${palette.border?.primary}`,
          }),
          ...containerSx,
        }}
      >
        {items.map((item, index) => (
          <GiveLineTab
            key={index}
            {...item}
            selected={item.value === selected}
            onClick={item?.onClickItem || onClick}
            tabSx={tabSx}
          />
        ))}
      </Stack>
    );
  if (type === "segmented")
    if (items.length < 4 || !isMobileView) {
      return (
        <Stack
          direction="row"
          gap="2px"
          sx={{
            border: `1px solid ${palette.border?.secondary}`,
            borderRadius: "12px",
            padding: "4px",
            width: "fit-content",
            ...containerSx,
          }}
        >
          {items.map((item, index) => (
            <GivePill
              key={index}
              variant="segmented"
              {...item}
              selected={item.value === selected}
              onClick={onClick}
              tabSx={tabSx}
            />
          ))}
        </Stack>
      );
    } else
      return (
        <GiveSelect
          options={items}
          value={selected}
          onChange={(item) => onClick?.(item.target.value as string)}
        />
      );
  if (type === "stepper")
    return (
      <>
        <Stack direction="row" gap="12px" sx={containerSx}>
          {items.map((item, index) => (
            <GiveProgressTab
              key={index}
              {...item}
              selected={item.value === selected}
              onClick={onClick}
              hideMobileTitle={hideMobileTitle && isMobileView}
              progress={
                hideMobileTitle && isMobileView && item.value === selected
                  ? 100
                  : item.progress
              }
            />
          ))}
        </Stack>
        {hideMobileTitle && isMobileView && (
          <GiveText variant="bodyXS" color="primary" mt="12px">
            {items.find((item) => item.value === selected)?.label}
          </GiveText>
        )}
      </>
    );
  else return <></>;
}
