import { Text } from "@common/Text";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";
import { checkPortals } from "@utils/routing";
import { Button } from "@common/Button";
import { Stack, styled, Box } from "@mui/material";
import { palette } from "@palette";
import { CheckMarkIcon } from "@assets/icons";
import { APP_BAR_HEIGHT } from "@components/CustomBreadcrumbsPage/BreadcrumbsAppBar";
import { MASQUERADE_SNACKBAR_HEIGHT } from "@components/Merchants/Masquerade/MasqueradeSnackbar";
import Skeleton from "@components/animation/Skeleton";
import { useProductPermission } from "features/Permissions/AccessControl/hooks";
import { ACTION_DENY_MESSAGE } from "@constants/permissions";
import { SpinnerGap } from "@phosphor-icons/react";

type CompleteProfileButtonProps = {
  isCompletedProfile: boolean;
  isUnderReviewProfile: boolean;
  isProfileDataLoading: boolean;
  onClick: () => void;
};

export const CompleteProfileButton = ({
  isCompletedProfile,
  isProfileDataLoading,
  onClick,
  isUnderReviewProfile,
}: CompleteProfileButtonProps) => {
  if (isProfileDataLoading) {
    return (
      <Skeleton
        width="220px"
        height="36px"
        data-testid="complete-profile-skeleton"
      />
    );
  }
  if (!isCompletedProfile && !isUnderReviewProfile) {
    return (
      <Button
        sx={{ width: "fit-content" }}
        size="medium"
        disabled={isProfileDataLoading}
        onClick={onClick}
      >
        Complete your profile
      </Button>
    );
  }
  return (
    <Stack
      height={"36px"}
      onClick={onClick}
      direction="row"
      spacing={1}
      alignItems="center"
    >
      <Text
        sx={{
          cursor: "pointer",
        }}
        fontSize={16}
        color={isUnderReviewProfile ? palette.info.main : palette.success.main}
      >
        {isUnderReviewProfile ? "Under Verification" : "Profile Completed!"}
      </Text>
      {isUnderReviewProfile ? (
        <SpinnerGap fill={palette.info.main} />
      ) : (
        <CheckMarkIcon />
      )}
    </Stack>
  );
};

export const CreatePaymentFormButton = ({
  isFirstCampaignComplete,
  onClick,
}: {
  isFirstCampaignComplete: boolean;
  onClick: () => void;
}) => {
  const { isAddProductAllowed } = useProductPermission();

  if (!isFirstCampaignComplete) {
    return (
      <Button
        onClick={onClick}
        background="tertiary"
        sx={{ color: palette.neutral[70] }}
        disabled={!isAddProductAllowed}
        tooltipProps={{
          message: ACTION_DENY_MESSAGE,
          show: !isAddProductAllowed,
        }}
      >
        Start creating your first payment form
      </Button>
    );
  }
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Text fontSize={16} color={palette.success.main}>
        Payment form created!
      </Text>
      <CheckMarkIcon />
    </Stack>
  );
};

const WelcomePageTitle = () => {
  const { gradient } = useThemeContext();
  const { isMerchantPortal } = checkPortals();
  const { masqFirstName } = useGetCurrentMerchantId();
  const {
    globalName: { firstName },
  } = useAppSelector(selectUser);
  return (
    <>
      <Text sx={{ ...mainTypographyStyle(gradient) }} align="center">
        Welcome, {masqFirstName ? masqFirstName : firstName}.<br />
        Let&apos;s get you started.
      </Text>
      {isMerchantPortal && (
        <Text sx={{ ...subTypographyStyle }} align="center">
          To kickstart your first campaign, click the button below.
          <br />
          From creation to launch and everything in between, we&apos;ll help you
          get up and running.
        </Text>
      )}
    </>
  );
};

export default WelcomePageTitle;

const mainTypographyStyle = (gradient: string) => ({
  background: gradient,
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",

  fontSize: "70px",
  fontStyle: "normal",
  fontWeight: 300,
  lineHeight: "110%",
  "@media (max-width: 600px)": {
    fontSize: "32px",
  },
});

const subTypographyStyle = {
  lineHeight: "135%",
  fontSize: "18px",
};

const CONTAINER_PADDINGTOP = 24;

const getPagePixelsOffset = (isMasqueradeMode: boolean) => {
  const appBarOffset = APP_BAR_HEIGHT + CONTAINER_PADDINGTOP * 2;

  if (!isMasqueradeMode) return appBarOffset;
  return appBarOffset + MASQUERADE_SNACKBAR_HEIGHT;
};

export const WelcomePageContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMasqueradeMode",
})<{ isMasqueradeMode: boolean }>(({ isMasqueradeMode }) => ({
  display: "flex",
  height: `calc(100vh - ${getPagePixelsOffset(isMasqueradeMode)}px)`,
  paddingBlock: "8px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "stretch",
  position: "relative",
  transition: "transform 0.5s ease-in-out",

  "@keyframes outAnimation": {
    "0%": {
      transform: "translateX(0%)",
      visibility: " hidden",
    },
    "100%": {
      transform: "translateX(-120%)",
      visibility: "visible",
    },
  },

  "@keyframes inAnimation": {
    "0%": {
      transform: "translateX(100%)",
      visibility: " hidden",
    },
    "100%": {
      transform: "translateX(0%)",
      visibility: "visible",
    },
  },
}));
