import React from "react";
import UnfoldListItems from "@components/animation/UnfoldListItems";
import { TableSkeletons } from "@common/Skeleton/TableRowSkeleton";
import { animated } from "react-spring";
import ListItemSkeleton from "@common/Skeleton/ListItemSkeleton";
import ListWrapperWithStates from "@containers/ListWrapperWithStates";
import { TListWrapperSection } from "@containers/types";
import NoResultsState from "@common/EmptyState/NoResultsState";
import { columns } from "../columns";
import { VirtualListLayoutWrapper } from "./VlLayoutWrapper";
import { useAppSelector } from "@redux/hooks";
import { selectQueryString } from "@redux/slices/search";

const skeletonItems = Array.from({ length: 20 }, (_, i) => ({
  id: `skeleton-${i}`,
}));

type Props = {
  dataLen: number;
  children: any;
  isLoading: boolean;
  queryKey: string;
  emptyState?: TListWrapperSection;
  show?: boolean;
  customColumns?: any[];
};

export function ListWrapper({
  dataLen,
  children,
  isLoading,
  queryKey,
  emptyState,
  show = true,
  customColumns,
}: Props) {
  const searchQuery = useAppSelector((state) =>
    queryKey ? selectQueryString(state, queryKey) : undefined,
  );

  if (isLoading) {
    return (
      <VirtualListLayoutWrapper show={show}>
        <UnfoldListItems
          items={skeletonItems}
          renderKey={(row) => row.id}
          Wrapper={animated.div}
          ItemComponent={(row, idx, height) => (
            <ListItemSkeleton
              key={`skeleton-item-${idx}`}
              listSkeletons={TableSkeletons}
              height={56 as any}
              rowHeight={40}
              columns={(customColumns as any) || (columns as any)}
            />
          )}
        />
      </VirtualListLayoutWrapper>
    );
  }

  if (searchQuery && emptyState && !isLoading && !dataLen) {
    return (
      <ListWrapperWithStates
        section={emptyState}
        isEmpty={Boolean(emptyState) && !searchQuery}
        action={null}
      >
        <NoResultsState searchQuery={searchQuery} />
      </ListWrapperWithStates>
    );
  }

  if (!dataLen) {
    return (
      <ListWrapperWithStates
        section={emptyState}
        action={null}
        isEmpty={dataLen === 0}
      />
    );
  }

  return children;
}
