import { FeatureFlagKeys } from "FeatureFlags/featureFlagKeys";
import FlaggedWrapper from "FeatureFlags/FlaggedWrapper";
import LoginNew from "./LoginNew";
import LoginOld from "./LoginOld";

const Login = () => {
  return (
    <FlaggedWrapper
      ActiveComponent={<LoginNew />}
      FallbackComponent={<LoginOld />}
      name={FeatureFlagKeys.LOGIN_OTP_ENABLED_FLAG}
    />
  );
};

export default Login;
