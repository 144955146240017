import GiveText from "@shared/Text/GiveText";
import {
  IMerchantBankAccount,
  TMerchantBankAccountSettings,
} from "@components/Merchants/MerchantPreview/data.types";
import { Stack } from "@mui/material";
import GiveBankAccountItem from "../bankAccounts/GiveBankAccountItem";
import { useBankAccount } from "../hooks/useBankAccount";
import ContextualMenu from "@shared/ContextualMenu/ContextualMenu";
import { ChatsCircle, GearSix, Plus } from "@phosphor-icons/react";
import GiveIconButton from "@shared/IconButton/GiveIconButton";
import { IconButtonProps } from "@mui/material";
import { Icon } from "@phosphor-icons/react";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import {
  CREATE_DENY_MESSAGE,
  NEW_ACTION_DENY_MESSAGE,
} from "@constants/permissions";
import GiveTooltip from "@shared/Tooltip/GiveTooltip";
import { checkPortals } from "@utils/routing";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";

export interface HeaderAction {
  Icon: Icon;
  onClick: () => void;
  hidden: boolean;
  iconText?: string;
  size?: IconButtonProps["size"];
  disabled?: boolean;
  tooltipTitle?: string;
  disableTooltip?: boolean;
}

const BankAccountSection = ({
  bankAccounts,
  merchantId,
  parentAccID,
  bankAccountSettings,
  onCloseModal,
}: {
  bankAccounts: IMerchantBankAccount[];
  merchantId: number;
  parentAccID?: number;
  bankAccountSettings?: TMerchantBankAccountSettings;
  onCloseModal?: (data?: IMerchantBankAccount, id?: number) => void;
}) => {
  const {
    anchorEl,
    onOpenMenu,
    onCloseMenu,
    actions,
    handleEdit,
    manageBankAccountsHandler,
    handleAddBankAccount,
    isAddBankAccountAllowed,
    isUpdateAllowed,
  } = useBankAccount(
    bankAccounts,
    merchantId,
    parentAccID,
    bankAccountSettings,
    onCloseModal,
  );
  const { isAcquirerPortal } = checkPortals();
  const {
    bank_account_linking,
    manage_bank_account,
    money_transfers,
    merchant_underwriting,
    modify_merchant,
  } = useEnterprisePermissions();

  const hasBankPermissions =
    bank_account_linking || manage_bank_account || money_transfers;

  const { openConversationHandler } = useConversationsModal();
  const handleConv = () =>
    openConversationHandler({
      id: 0,
      name: "Bank Accounts",
      paths: [],
    });

  const headerActions: HeaderAction[] = [
    {
      Icon: Plus,
      onClick: handleAddBankAccount,
      hidden: !modify_merchant,
      iconText: "Add",
      size: "extraSmall",
      disabled: !isAddBankAccountAllowed,
      tooltipTitle: CREATE_DENY_MESSAGE,
      disableTooltip: isAddBankAccountAllowed,
    },
    {
      Icon: ChatsCircle,
      onClick: handleConv,
      hidden: !merchant_underwriting || !isAcquirerPortal,
      disableTooltip: true,
    },
    {
      Icon: GearSix,
      onClick: manageBankAccountsHandler,
      hidden: !merchantId,
      disabled: !hasBankPermissions,
      tooltipTitle: NEW_ACTION_DENY_MESSAGE,
      disableTooltip: hasBankPermissions,
    },
  ];
  const bankAccountsLength = bankAccounts.length;
  return (
    <Stack>
      <Stack
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        width="100%"
        marginBottom="20px"
      >
        <GiveText>Bank Accounts</GiveText>
        <Stack gap="10px" flexDirection="row" alignItems="center">
          {headerActions?.map(
            (
              {
                Icon,
                onClick,
                hidden,
                iconText,
                size,
                disabled,
                tooltipTitle,
                disableTooltip,
              },
              idx,
            ) => {
              if (hidden) return null;
              return (
                <GiveTooltip
                  width="compact"
                  alignment="left"
                  title={tooltipTitle}
                  color="default"
                  disableHoverListener={disableTooltip}
                  key={idx}
                >
                  <GiveIconButton
                    sx={{
                      cursor: "pointer",
                    }}
                    variant="ghost"
                    onClick={onClick}
                    Icon={Icon}
                    iconText={iconText}
                    size={size}
                    disabled={disabled}
                  />
                </GiveTooltip>
              );
            },
          )}
        </Stack>
      </Stack>
      {bankAccounts?.map((account, idx) => (
        <GiveBankAccountItem
          key={account.id}
          merchantId={merchantId}
          account={account}
          onOpenMenu={onOpenMenu}
          disabled={isUpdateAllowed}
          onEdit={handleEdit}
          isFirst={idx === 0}
          isLast={idx === bankAccountsLength - 1}
        />
      ))}
      <ContextualMenu
        handleClose={onCloseMenu}
        anchorEl={anchorEl}
        color="secondary"
        texture="blurred"
        options={actions}
      />
    </Stack>
  );
};

export default BankAccountSection;
