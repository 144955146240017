import React, { ChangeEvent, useState, useMemo, CSSProperties } from "react";
import { DoubleDropDownIcon, SearchEmptyStateIcon } from "@assets/icons";
import { EmptyState } from "@common/EmptyState";
import {
  Input,
  InputAdornment,
  InputProps,
  ListSubheader,
  MenuProps,
  useTheme,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { palette } from "@palette";
import { CaretDown, CaretUp, MagnifyingGlass } from "@phosphor-icons/react";
import { SelectItem, SelectItemProps } from "./SelectItem";
import { SelectOptionProps } from "./types";
import useSearchbar from "./useSearchbar";
import { TruncateText } from "@common/Text";

export type SelectProps = TextFieldProps & {
  options: SelectOptionProps[] | undefined;
  label?: string | React.ReactNode;
  width?: string | number;
  DropIcon?: JSX.Element;
  withSearchBar?: boolean;
  shouldDisplayIcon?: boolean;
  customOptionItem?: React.FC<{ option: SelectOptionProps }>;
  selectItemProps?: Omit<SelectItemProps, "value">;
  sxMenu?: MenuProps["sx"];
  handleDeselect?: () => void;
  onOpen?: () => void;
  MenuProps?: Partial<MenuProps>;
  noWrapper?: boolean;
  focusViewColor?: string;
  isOptionNonClickable?: boolean;
  isOpen?: (open: boolean) => void;
  isCaretIcon?: boolean;
  handleSearch?: (newValue: string) => void;
  SearchInputProps?: Partial<InputProps>;
  styleMenu?: CSSProperties;
  disabled?: boolean;
};

export default function Select({
  options = [],
  label,
  value,
  DropIcon,
  fullWidth,
  withSearchBar,
  shouldDisplayIcon = true,
  customOptionItem: CustomOptionItem,
  selectItemProps,
  sxMenu,
  handleDeselect,
  onOpen,
  MenuProps,
  noWrapper = false,
  focusViewColor,
  onScroll,
  isOptionNonClickable,
  isOpen,
  isCaretIcon,
  handleSearch,
  SearchInputProps,
  styleMenu,
  ...props
}: SelectProps) {
  const { sx, SelectProps, ...rest } = props;
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const {
    query,
    options: searchOptions,
    onSearch,
    resetOptions,
  } = useSearchbar({ initialOptions: options, customSearch: handleSearch });

  const filteredOptions =
    !withSearchBar || handleSearch ? options : searchOptions;

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onSearch(e.target.value);
  };

  const showCustomIcon = !SelectProps?.IconComponent;
  const CarretIcon = open ? CaretUp : CaretDown;
  const CustomIconComponent = isCaretIcon ? (
    <CarretIcon size={28} fill="#8F8F8F" />
  ) : DropIcon ? (
    DropIcon
  ) : (
    <DoubleDropDownIcon />
  );

  const handleOpenMenu = () => {
    if (props?.disabled || isOptionNonClickable) return;
    if (isOpen) isOpen(true);
    if (onOpen) {
      onOpen();
    } else {
      setOpen(true);
    }
  };

  const closeMenu = () => {
    resetOptions();
    setOpen(false);
    if (isOpen) {
      isOpen(false);
    }
  };

  const isEmptyList = useMemo(() => {
    return filteredOptions.filter((o) => !o?.hidden).length === 0;
  }, [filteredOptions]);

  const CustomTextFiled = (
    <TextField
      data-testid={`${label}-select-input`}
      select
      value={value || ""}
      variant="outlined"
      label={label}
      sx={{
        "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
          padding: theme.spacing(0.1),
          background: "inherit",
          ...(label && { paddingTop: "15px" }),
        },
        "& .MuiFilledInput-root": {
          "&:hover": {
            backgroundColor: "inherit",
          },
        },

        ...sx,
      }}
      fullWidth={fullWidth}
      SelectProps={{
        open,
        onClose: closeMenu,
        onOpen: handleOpenMenu,
        ...SelectProps,
        ...(showCustomIcon && {
          IconComponent: () =>
            shouldDisplayIcon ? (
              <InputAdornment
                position="end"
                onClick={handleOpenMenu}
                sx={{
                  cursor: props?.disabled ? "default" : "pointer",
                  pointerEvents: props?.disabled ? "none" : "initial",
                }}
              >
                {CustomIconComponent}
              </InputAdornment>
            ) : null,
        }),
        sx: {
          ...(focusViewColor && {
            "& .MuiInputBase-root": {
              border: `2px solid ${focusViewColor} !important`,
              transition: "border 250ms ease",
            },
          }),
          ...sx,
          width: value === "unassigned" ? "fit-content" : "auto",
          ".MuiSelect-outlined .MuiStack-root": {
            cursor:
              (filteredOptions?.length || 0) > 1
                ? "pointer !important"
                : "default !important",
          },
        },
        MenuProps: {
          PaperProps: {
            sx: {
              maxHeight: 250,
              maxWidth: 250,
              ...sxMenu,
            },
            style: { paddingTop: 0, ...styleMenu },
            onScroll: onScroll,
          },
          sx: {
            ...(shouldDisplayIcon === false && {
              display: "none",
            }),
          },
          ...MenuProps,
        },
      }}
      {...rest}
    >
      {withSearchBar ? (
        <SearchInput onChange={onChange} {...SearchInputProps} />
      ) : null}
      {withSearchBar && query && isEmptyList ? (
        <EmptyState
          Icon={<SearchEmptyStateIcon width={48} height={48} />}
          title={{
            main: `No results for "${query}"`,
          }}
          customSize={{
            main: {
              fontSize: "14px",
              lineHeight: "16.8px",
            },
            secondary: {},
          }}
          sx={{
            paddingBlock: "48px",
          }}
        />
      ) : (
        renderOptions(filteredOptions, CustomOptionItem, {
          selectItemProps,
          value,
          handleDeselect: () => {
            setOpen(false);
            if (handleDeselect) handleDeselect();
          },
        })
      )}
    </TextField>
  );
  return noWrapper ? (
    CustomTextFiled
  ) : (
    <Stack width={fullWidth ? "100%" : "auto"}>{CustomTextFiled}</Stack>
  );
}

type Params = {
  selectItemProps?: Omit<SelectItemProps, "value">;
  value: unknown;
  handleDeselect: VoidFunction;
};

const renderOptions = (
  options?: SelectOptionProps[],
  CustomOptionItem?: React.FC<{ option: SelectOptionProps }>,
  params?: Params,
) => {
  if (!options?.length) return null;
  return options?.map((option) => (
    <SelectItem
      data-testid={`${option.value}-item`}
      key={option.value}
      value={option.value}
      helperText={option.helperText}
      disabled={option.disabled}
      hidden={option.hidden}
      sx={(theme) => ({
        "& div": {
          color: theme.palette.neutral[800],
        },
      })}
      onClick={() => {
        if (option?.onClick) {
          option.onClick();
        }
      }}
      {...params?.selectItemProps}
    >
      <Stack
        gap={1}
        direction="row"
        alignItems="center"
        color={palette.black[100]}
        fontWeight={400}
        fontSize="14px"
      >
        {CustomOptionItem ? (
          <CustomOptionItem
            option={{
              ...option,
              selected: params?.value === option.value,
              handleDeselect: params?.handleDeselect,
            }}
          />
        ) : (
          <>
            {option.startIcon}
            <TruncateText lineClamp={1} onHoverShowAll>
              {option.label}
            </TruncateText>
            {option.endIcon}
          </>
        )}
      </Stack>
    </SelectItem>
  ));
};

function SearchInput({ onChange, onClick, ...props }: InputProps) {
  return (
    <ListSubheader style={{ padding: 0, lineHeight: "inherit" }}>
      <Input
        {...props}
        startAdornment={
          <InputAdornment
            sx={{
              pl: "13px",
            }}
            position="start"
          >
            <MagnifyingGlass fill="#8F8F8F" size={20} />
          </InputAdornment>
        }
        placeholder="Search"
        onChange={onChange}
        onClick={(e) => undefined} // disable close menu when clicking
        fullWidth
        sx={{
          lineHeight: "inherit",
          height: "44px",
          "& .MuiInputBase-input:placeholder-shown": {
            color: "#8F8F8F",
            fontSize: "14px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      />
    </ListSubheader>
  );
}
