import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { customInstance } from "@services/api";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import ExpiredPasswordReset from "./ExpiredPasswordReset";
import { showMessage } from "@common/Toast";

function InviteMembers() {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  // Extract the values of the query parameters
  const accountId = queryParams.get("accountID");
  const inviteId = queryParams.get("inviteID");
  const token = queryParams.get("token");
  const email = queryParams.get("email");
  const allDataAvailable = !!accountId && !!inviteId && !!token && !!email;
  const [{ tokenExpired, title, message }, setData] = useState({
    tokenExpired: false,
    title: "Missing Parameters",
    message: `Sorry, some parameters required to invite ${
      email || "the user"
    } are missing`,
  });

  useQuery(
    ["invite_user", accountId, inviteId, token, email],
    async () => {
      return customInstance({
        url: `/accounts/${accountId}/invites/${inviteId}/accept?token=${token}&email=${email}`,
      });
    },
    {
      onSuccess(data) {
        showMessage("Success", `${email} successfully invited `);
      },
      onError(err: any) {
        const errMessage =
          err.response?.data?.message ||
          `an error occurred while trying to invite ${email || "user"}`;
        setData({
          title: "Error Inviting user",
          message: errMessage,
          tokenExpired: true,
        });

        showMessage("Error", errMessage);
      },
      enabled: allDataAvailable,
    },
  );
  if (tokenExpired || !allDataAvailable)
    return (
      <ExpiredPasswordReset
        {...((!allDataAvailable || title || message) && {
          title,
          message,
        })}
      />
    );
  return (
    <div>
      <LoadingSpinner />
    </div>
  );
}

export default InviteMembers;
