import { Button } from "@common/Button";
import EditMerchantBaseModal from "@components/Merchants/MerchantPreview/components/EditMerchantBaseModal";
import { UploadFile } from "@components/UploadFile";
import NiceModal from "@ebay/nice-modal-react";
import { Box, Stack } from "@mui/material";
import { IDropzoneProps } from "react-dropzone-uploader";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ChevronRight } from "@assets/icons/RebrandedIcons";
import { palette } from "@palette";
import { SIGN_AGREEMENT_MODAL } from "modals/modal_names";
import { useEffect } from "react";
import { UploadFileDocumentPreviewItem } from "@components/UploadFile/Rebranded/UploadFileDocumentPreviewItem";
import { useAsyncAction } from "@hooks/customReactCore";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { FileUploadStatus } from "@components/UploadFile/types";
import { useFileUploadContext } from "@components/UploadFile/FileUploadContext";
import { UploadFileNew } from "@components/UploadFile/Rebranded/UploadFileNew";
import { AcceptAllowedImagesTypes } from "@hooks/upload-api/uploadHooks";
import FlaggedWrapper from "FeatureFlags/FlaggedWrapper";
import { FeatureFlagKeys } from "FeatureFlags/featureFlagKeys";

type FormInputsNew = {
  file: File | null;
};

type FormInputsOld = {
  file: any | null;
};
const defaultValues = { file: null };

type Props = {
  addSignature: (file: File) => Promise<void>;
};

const UploadSignatureModal = NiceModal.create(({ addSignature }: Props) => {
  const { open, onClose } = useNiceModal();
  const [isLoading, triggerAction] = useAsyncAction();
  const { setSnackbarFiles } = useFileUploadContext();
  const methods = useForm<FormInputsNew | FormInputsOld>({
    defaultValues,
  });

  const { watch, setValue, reset } = methods;
  const values = watch();
  const handleChangeStatus: IDropzoneProps["onChangeStatus"] = (
    fileWithMeta,
    status,
  ) => {
    if (status === "rejected_file_type") {
      const { id, name, size } = fileWithMeta.meta;
      setSnackbarFiles([
        {
          id,
          name,
          size,
          status: FileUploadStatus.FILE_NOT_SUPPORTED,
          uploadProgress: 0,
          canBeDeleted: true,
        },
      ]);
      return;
    }
    if (status === "done" || status === "error_file_size") {
      if (values.file) return;
      setValue("file", fileWithMeta, { shouldDirty: true });
    }
  };

  const handleUpload = (file: File) => {
    setValue("file", file, { shouldDirty: true });
  };

  const handleBack = () => {
    NiceModal.show(SIGN_AGREEMENT_MODAL, { addSignature });
    onClose();
  };

  const deleteFileHandler = () => {
    setValue("file", null, { shouldDirty: true });
  };

  const onSubmit: SubmitHandler<FormInputsNew | FormInputsOld> = async (
    data,
  ) => {
    if (!data.file) return;
    triggerAction(async () => {
      await addSignature(data?.file as File);
      onClose();
    });
  };
  useEffect(() => {
    if (open) reset(defaultValues);
  }, [open]);

  return (
    <EditMerchantBaseModal
      title="Upload Signature"
      description="Submit your signature in either JPG or PNG format for seamless and secure document authentication"
      open={open}
      width="730px"
      handleCancel={onClose}
      icon={
        <Box
          height="24px"
          sx={{ transform: "rotate(180deg)", cursor: "pointer" }}
          onClick={handleBack}
        >
          <ChevronRight height={24} width={24} stroke={palette.neutral[80]} />
        </Box>
      }
      sx={{
        "& .MuiPaper-root": {
          width: "730px !important",
          maxWidth: "730px !important",
        },
        "& .MuiDialog-paper": {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
      actions={
        <>
          <Button size="medium" background="tertiary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="medium"
            background="primary"
            type="submit"
            form="upload-signature-form"
            disabled={!values.file || isLoading}
            sx={{ padding: "8px 24px" }}
            data-testid="sign-button"
          >
            Sign
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <Stack
          spacing={1}
          component="form"
          id="upload-signature-form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <FlaggedWrapper
            ActiveComponent={() => (
              <UploadFileNew
                uploadFunction={handleUpload}
                accept={AcceptAllowedImagesTypes}
                maxFiles={1}
                supportedFormatText=".png, .jpg"
              />
            )}
            FallbackComponent={() => (
              <UploadFile
                maxFiles={1}
                backgroundColor="white"
                onChangeStatus={handleChangeStatus}
                supportedFormatText=".png, .jpg"
                accept="image/png, image/jpeg"
                width="100%"
              />
            )}
            name={FeatureFlagKeys.FILE_UPLOAD_TECH_REFACTOR_KEY}
          />
          {values.file && (
            <FlaggedWrapper
              ActiveComponent={() => (
                <UploadFileDocumentPreviewItem
                  fileName={values.file?.name}
                  id={0}
                  deleteDocument={deleteFileHandler}
                  fileURL={URL.createObjectURL(values.file)}
                />
              )}
              FallbackComponent={() => (
                <UploadFileDocumentPreviewItem
                  fileName={values.file?.meta?.name}
                  id={values.file?.meta?.id}
                  deleteDocument={deleteFileHandler}
                  fileURL={values.file?.meta?.previewUrl}
                />
              )}
              name={FeatureFlagKeys.FILE_UPLOAD_TECH_REFACTOR_KEY}
            />
          )}
        </Stack>
      </FormProvider>
    </EditMerchantBaseModal>
  );
});

export default UploadSignatureModal;
