import { Box, IconButton } from "@mui/material";
import { palette } from "@palette";
import { Trash } from "@phosphor-icons/react";
import Webcam from "react-webcam";

export const CameraFlow = ({
  data,
  webcamRef,
  retakePhoto,
}: {
  data: string | null;
  webcamRef: any;
  retakePhoto?: () => void;
}) => {
  return (
    <>
      {data ? (
        <Box>
          <img
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "8px",
            }}
            src={data}
          />
          {retakePhoto && (
            <IconButton
              sx={{
                position: "absolute",
                bottom: "8px",
                right: "8px",
                bgcolor: "#FFFFFF99",
                border: "none",
                boxShadow: "none",
                borderRadius: "50%",
              }}
              onClick={retakePhoto}
            >
              <Trash size={25} color={palette.filled.red} />
            </IconButton>
          )}
        </Box>
      ) : (
        <WebcamCapture webcamRef={webcamRef} />
      )}
    </>
  );
};

export const WebcamCapture = ({ webcamRef, videoStyle }: any) => {
  return (
    <Webcam
      ref={webcamRef}
      autoFocus
      audio={false}
      videoConstraints={{
        width: 3000,
        height: 3000,
        facingMode: "user",
      }}
      screenshotQuality={1}
      screenshotFormat="image/jpeg"
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        width: "800%",
        height: "800%",
        objectFit: "cover",
        borderRadius: 8,
        ...videoStyle,
        transform: `scale(0.125)`,
        transformOrigin: "top left",
      }}
    />
  );
};
