import { Stack } from "@mui/material";
import GiveText from "@shared/Text/GiveText";
import { StyledGiveButton } from "./UnderwritingCard.style";
import NiceModal from "@ebay/nice-modal-react";
import {
  APPROVE_MERCHANT_MODAL_REBRAND,
  DECLINE_MERCHANT_MODAL_REBRAND,
} from "modals/modal_names";

type TChallengeCategoriesBottom = {
  isAllCompleted: boolean;
  merchantId: number;
  merchantName: string;
};

const ChallengeCategoriesBottom = ({
  isAllCompleted,
  merchantId,
  merchantName,
}: TChallengeCategoriesBottom) => {
  const handleApprove = () => {
    NiceModal.show(APPROVE_MERCHANT_MODAL_REBRAND, {
      merchantId,
    });
  };

  const handleDecline = () => {
    NiceModal.show(DECLINE_MERCHANT_MODAL_REBRAND, {
      merchantId,
      merchantName,
    });
  };

  return (
    <>
      <GiveText variant="bodyS" color="primary">
        All of the above criteria must be met before approving this account.
      </GiveText>
      <Stack direction="row" gap={1.5}>
        <StyledGiveButton
          disabled={!isAllCompleted}
          variant="filled"
          label="Approve this Account"
          onClick={handleApprove}
        />
        <StyledGiveButton
          variant="outline"
          label="Decline"
          color="destructive"
          onClick={handleDecline}
        />
      </Stack>
    </>
  );
};

export default ChallengeCategoriesBottom;
