import * as React from "react";
// @mui
import DialogTitle, {
  DialogTitleProps as MuiDialogTitleProps,
} from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import { SxProps, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/material";
// components
import { Text } from "@common/Text";
import { Tooltip } from "@common/Tooltip";
import { IconButton } from "@common/IconButton";
// icon
import { palette } from "@palette";
import { styled } from "@mui/material";
import Progress from "./Progress";
import KotoLinearProgress from "@common/SignUp/LinearProgress";
import { BoxProps } from "@mui/material";
import { rest } from "lodash";
import { X } from "@phosphor-icons/react";

type DialogTitleProps = MuiDialogTitleProps & {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  titleIcon?: JSX.Element;
  titleComponent?: React.ReactNode;
  sortIconButton?: React.ReactNode;
  backgroundColor?: string;
  shouldShowProgressBar?: boolean;
  progressValues?: Record<string, number>;
  modalType?: "default" | "builder" | "popUp" | "selection";
  onClose: () => void;
  shouldCamelCase?: boolean;
  headerContainerProps?: BoxProps;
  titleSx?: SxProps;
};

const ModalTitle = ({
  title,
  subTitle,
  titleIcon,
  onClose,
  sortIconButton,
  titleComponent,
  backgroundColor,
  shouldShowProgressBar,
  shouldCamelCase = true,
  modalType = "default",
  progressValues,
  headerContainerProps,
  ...props
}: DialogTitleProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const total = React.useMemo(
    () =>
      Object.values(progressValues || {}).reduce((acc, val) => acc + val, 0),
    [progressValues],
  );

  const calculateGlobalPercentage = React.useCallback(() => {
    const maxTotal = Object.keys(progressValues || {}).length * 100;
    return total !== 0 ? Math.round((total / maxTotal) * 100) : 0;
  }, [progressValues]);

  const StyledDialogTitle = {
    p: 2,
    pb: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "stretch",
    backgroundColor: backgroundColor || palette.neutral.white,

    ...(!isDesktop && {
      p: "12px 16px",

      "& > div > svg": {
        width: 24,
        height: 22,
      },
    }),
  };

  return (
    <DialogTitle sx={StyledDialogTitle} {...props}>
      <Box
        display="flex"
        alignItems="center"
        flexGrow={1}
        {...headerContainerProps}
      >
        <Stack gap={1} alignItems="center" direction="row">
          {titleIcon}

          {titleComponent ? (
            <>{titleComponent}</>
          ) : (
            <Box width="100%">
              <Text
                fontSize={32}
                lineHeight="38.4px"
                variant="h5"
                fontWeight="book"
                textTransform={shouldCamelCase ? "capitalize" : "none"}
                color={palette.neutral[70]}
                sx={props.titleSx}
              >
                {title}
              </Text>
              <Text
                fontWeight="book"
                textTransform="capitalize"
                variant="headline"
                color={palette.neutral[80]}
              >
                {subTitle}
              </Text>
            </Box>
          )}
        </Stack>
      </Box>

      {sortIconButton && (
        <Stack direction="row" spacing={1}>
          {sortIconButton}
          <Tooltip title="Close">
            <StyledIconButton aria-label="close" onClick={onClose} size="small">
              <X width={32} height={32} />
            </StyledIconButton>
          </Tooltip>
        </Stack>
      )}
      {!sortIconButton && (
        <Stack direction="row" spacing={4} alignItems="center">
          {shouldShowProgressBar && (
            <Tooltip
              title={<Progress title={title} progressValues={progressValues} />}
              bgColor={palette.neutral.white}
            >
              <Box
                sx={{
                  maxWidth: "150px",
                  cursor: "pointer",
                }}
              >
                <Text fontSize={12}>Create Merchant Progress</Text>
                <Stack
                  direction="row"
                  sx={{ cursor: "pointer" }}
                  alignItems="center"
                  gap={1}
                >
                  <Text
                    fontSize={12}
                    sx={{
                      color: "neutral.black",
                    }}
                  >
                    {calculateGlobalPercentage()}%
                  </Text>
                  <KotoLinearProgress
                    isActive
                    hasDescription={false}
                    value={calculateGlobalPercentage()}
                    sx={{
                      width: "100%",
                      height: "5px",
                      marginRight: 0,
                    }}
                  />
                </Stack>
              </Box>
            </Tooltip>
          )}
          <Tooltip title="Close">
            <StyledIconButton aria-label="close" onClick={onClose} size="small">
              <X width={32} height={32} />
            </StyledIconButton>
          </Tooltip>
        </Stack>
      )}
    </DialogTitle>
  );
};

const StyledIconButton = styled(IconButton)(() => ({
  boxShadow: "none",
  border: "none",
  background: "none",
}));

export default ModalTitle;
