import React, { memo, useRef } from "react";
import { Grid } from "@mui/material";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useCalculatePercentage } from "@common/SignUp/useCalculatePercentage";
import { useFormData } from "../CreateCampaignFormProvider";
import BottomButtons from "../components/BottomButtons";
import { isEqual } from "lodash";
import PaymentTypeCard from "../components/PaymentTypeCard";
import { ProductType } from "../types";
import useGenerateTypeList from "@hooks/useGenerateTypeList";

type Props = {
  handleNext: () => void;
  handleUpdateStatusValue: (value: number) => void;
  campaignBarStatusBar: number;
  backToWelcomePage: () => void;
};

type IFormInputs = {
  type: ProductType;
};

const CreateCampaignType = ({
  handleNext,
  handleUpdateStatusValue,
  backToWelcomePage,
  campaignBarStatusBar,
}: Props) => {
  const { calculatePercentage } = useCalculatePercentage({ isEdit: false });
  const { formData, setFormValues, initialPaymentSetup, resetFormValues } =
    useFormData();
  const valueRef = useRef<any>(null);

  const { typeList } = useGenerateTypeList();
  const schema = Yup.object().shape({
    type: Yup.mixed<ProductType>().required(),
  });

  const defaultValues = formData.campaignType;

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    watch,
    setValue,
    formState: { dirtyFields },
  } = methods;

  const values = watch();

  React.useEffect(() => {
    if (
      Object.keys(dirtyFields).length > 0 &&
      !isEqual(values, valueRef.current)
    ) {
      valueRef.current = values;
      calculatePercentage({
        action: handleUpdateStatusValue,
        dirtyFields,
        defaultValues,
        schema,
        values,
      });
    }
  }, [values, dirtyFields]);

  const handleSelectType = (type: ProductType) => {
    if (type === "Product") return;
    resetFormValues();
    setValue("type", type, { shouldDirty: true });
  };

  const goNext = () => {
    setFormValues("campaignType", { type: values.type });
    ["Fundraiser", "Invoice", "Standard"].includes(values?.type) &&
      setFormValues("paymentSetup", initialPaymentSetup as any);
    handleNext();
  };

  return (
    <>
      <Grid container spacing={1.2}>
        {typeList.map((type) => (
          <PaymentTypeCard
            key={type.name}
            handleSelectType={handleSelectType}
            selected={values.type === type.name}
            type={type}
          />
        ))}
      </Grid>
      <BottomButtons
        goNext={goNext}
        goBack={backToWelcomePage}
        disabled={!values.type || campaignBarStatusBar < 100}
        isStep1
      />
    </>
  );
};

export default memo(CreateCampaignType);
