import { DownloadIcon } from "@assets/icons";
import EyeIcon from "@assets/icons/InputEyeIcon";
import { EditIcon, TrashBin } from "@assets/icons/RebrandedIcons";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";
import { isValidExtension } from "@common/FilePreview/utils/fileList.utils";
import { StyledIconButton } from "@common/IconButton";
import { Text, TruncateText } from "@common/Text";
import { showMessage } from "@common/Toast";
import { DELETE_DENY_MESSAGE } from "@constants/permissions";
import { useDocumentHandlers } from "@hooks/common/documents";
import {
  Box,
  ClickAwayListener,
  Stack,
  StackProps,
  TextField,
  styled,
} from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { checkPortals } from "@utils/routing";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { QueryKey, useQueryClient } from "react-query";
import { TMerchantDocument } from "../data.types";
import { Tag } from "./BusinessOwners/BusinessOwnerUploadList";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";

type TDocumentItemProps = TMerchantDocument & {
  merchantID: number;
  list: TMerchantDocument[];
  refetcher?: QueryKey;
  hiddenActionProps?: any;
  showPreviewIcon?: boolean;
  showDetails?: boolean;
  ItemContainerProps?: StackProps;
  localDeleteHandler?: () => void;
  showActionButtonMobile?: boolean;
  isEnterprise?: boolean;
  isDeleteHidden?: boolean;
  isUpdatePrevented?: boolean;
  localOnly?: boolean;
};

export const DocumentItem = ({
  merchantID,
  list,
  refetcher,
  hiddenActionProps,
  showPreviewIcon,
  showDetails = true,
  ItemContainerProps,
  localDeleteHandler,
  showActionButtonMobile = false,
  isEnterprise = false,
  isDeleteHidden = false,
  isUpdatePrevented = false,
  localOnly = false,
  ...props
}: TDocumentItemProps) => {
  const { isAcquirerPortal } = checkPortals();
  const {
    downloadHandler,
    deleteHandler,
    editHandler,
    previewHandler,
    isDeleteAllowed,
    isUpdateAllowed,
  } = useDocumentHandlers(
    merchantID,
    list,
    isEnterprise,
    undefined,
    localDeleteHandler,
    localOnly,
    isDeleteHidden,
  );
  const { fileName, tag, updatedAt, userFullName, fileType, userEmail } = props;
  const {
    email: loggedInEmail,
    globalName: { firstName, lastName },
  } = useAppSelector(selectUser);
  // adding fallback user name
  const loggedInPlaceholder =
    `${firstName} ${lastName}`.trim() || loggedInEmail;
  const user = userFullName?.trim() || userEmail || loggedInPlaceholder;

  const lastDotIndex = fileName.lastIndexOf(".");
  const name = fileName.substring(0, lastDotIndex);
  const queryClient = useQueryClient();
  const { isDesktopView, isMobileView } = useCustomTheme();
  const showActionMobile = showActionButtonMobile && isMobileView;
  const [isFocused, setIsFocused] = useState<boolean>(showActionMobile);

  const refetchQuery = () => {
    refetcher && queryClient.refetchQueries(refetcher);
    queryClient.refetchQueries("get-merchant-preview");
    queryClient.refetchQueries("get identification files");
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (!clickable) return;
    previewHandler(props);
  };

  const actions = [
    {
      label: "preview",
      children: <EyeIcon path_fill={palette.neutral[70]} />,
      onClick: handleClick,
      hidden: !showPreviewIcon,
    },
    {
      label: "download",
      children: (
        <DownloadIcon width={18} height={18} stroke={palette.neutral[70]} />
      ),
      onClick: (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        downloadHandler(props);
      },
    },
    {
      label: "delete",
      children: <TrashBin width={18} height={18} fill={palette.neutral[70]} />,
      disabled: !isDeleteAllowed,
      onClick: (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        localDeleteHandler
          ? localDeleteHandler()
          : deleteHandler(props, refetchQuery);
      },
      tooltipProps: {
        show: !isDeleteAllowed,
        message: DELETE_DENY_MESSAGE,
      },
      sx: {
        "&:hover path": {
          fill: palette.error.hover,
        },
      },
      hidden: !isAcquirerPortal || isDeleteHidden,
      ...hiddenActionProps,
    },
  ];

  const handleSubmitTab = (newValue: string) => {
    editHandler(merchantID, props.id, { tag: newValue });
  };

  const clickable =
    !!props?.fileType && !!props?.fileURL && isValidExtension(props?.fileType);

  return (
    <ClickAwayListener
      onClickAway={() => (showActionMobile ? undefined : setIsFocused(false))}
    >
      <ItemContainer
        isFocused={isFocused}
        clickable={clickable}
        {...(!showPreviewIcon && {
          onClick: handleClick,
        })}
        {...ItemContainerProps}
        data-testid="uploaded-document-item"
      >
        <Stack direction="row" data-testid={`uploaded-document-item-${name}`}>
          <TruncateText
            lineClamp={1}
            color={palette.black[100]}
            variant="body"
            lineHeight="16.8px"
            fontWeight="book"
          >
            {name}
          </TruncateText>
          <Text
            color={palette.black[100]}
            variant="body"
            lineHeight="16.8px"
            fontWeight="book"
          >
            .{fileType}
          </Text>
        </Stack>
        {showDetails && (
          <Stack direction="row" gap={1} alignItems="center" height="22px">
            {tag &&
              (isDesktopView && isUpdateAllowed && !isUpdatePrevented ? (
                <TagInput
                  onSubmit={handleSubmitTab}
                  tag={tag}
                  setIsFocused={setIsFocused}
                  isFocused={isFocused}
                />
              ) : (
                <Tag data-testid={`${fileName}-tag`}>{tag}</Tag>
              ))}
            {user && (
              <Text
                color={palette.neutral[80]}
                variant="caption"
                lineHeight="14.4px"
                fontWeight="book"
              >
                by {user}
              </Text>
            )}
            <Text
              color={palette.gray[300]}
              variant="caption"
              lineHeight="14.4px"
              fontWeight="book"
            >
              {formatTimestamp(updatedAt * 1000)}
            </Text>
          </Stack>
        )}
        {(isDesktopView || showActionMobile) && (
          <ActionsContainer
            className="document-item-actions"
            data-testid="document-item-actions"
          >
            {actions.map((action) => {
              const { label, tooltipProps, hidden, ...rest } = action;
              if (hidden) return;
              if (!tooltipProps) return <ActionButton key={label} {...rest} />;

              return (
                <CustomToolTip
                  key={label}
                  showToolTip={tooltipProps?.show}
                  message={tooltipProps?.message}
                >
                  <ActionButton key={label} {...rest} />
                </CustomToolTip>
              );
            })}
          </ActionsContainer>
        )}
      </ItemContainer>
    </ClickAwayListener>
  );
};

const TagInput = ({
  tag,
  onSubmit,
  setIsFocused,
  isFocused,
}: {
  tag: string;
  onSubmit: (newValue: string) => void;
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
  isFocused: boolean;
}) => {
  const [value, setValue] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setValue(tag);
  }, []);

  const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsFocused(true);
  };

  useEffect(() => {
    if (isFocused && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isFocused]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const submitInput = () => {
    if (!value?.trim()) {
      showMessage("Error", "The uploaded file name can't be empty");
      setValue(tag);
    } else onSubmit(value);
    setIsFocused(false);
  };
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    submitInput();
  };

  return (
    <Stack
      direction="row"
      gap="2px"
      alignItems="center"
      padding="4px"
      maxWidth="400px"
      borderRadius="4px"
      sx={{ backgroundColor: palette.gray[40] }}
    >
      <ActionButton
        onClick={handleClickEdit}
        className="document-item-actions"
        sx={{
          display: "none",
          maxWidth: "16px",
          maxHeight: "16px",
          ...(isFocused && { display: "none !important" }),
        }}
      >
        <EditIcon width={16} height={16} fill={palette.black[100]} />
      </ActionButton>
      <Box
        component="form"
        onSubmit={handleSubmit}
        height="15px"
        sx={{ cursor: "pointer" }}
      >
        <TagBaseInput
          value={value}
          onChange={handleInputChange}
          inputRef={inputRef}
          onBlur={submitInput}
          inputProps={{
            size: value.length,
            onClick: handleClickEdit,
          }}
          sx={{
            "& .MuiInputBase-root": {
              maxWidth: "unset",
            },
          }}
        />
      </Box>
    </Stack>
  );
};

const TagBaseInput = styled(TextField)(() => ({
  "& .MuiInputBase-root": {
    border: "none",
    padding: 0,
    maxWidth: "124px",

    "& input": {
      color: palette.black[100],
      fontSize: "12px",
      lineHeight: "14.4px",
      fontWeight: 350,
      height: "15px",
      margin: 0,
      backgroundColor: palette.gray[40],
      cursor: "pointer",
    },
  },
  "& .MuiInputBase-root.Mui-disabled": {
    boxShadow: "none",
    border: "none",

    "& input": {
      color: palette.black[100],
      fontSize: "12px",
      lineHeight: "14.4px",
      fontWeight: 350,
      height: "15px",
      margin: 0,
      backgroundColor: palette.gray[40],
      WebkitTextFillColor: palette.black[100],
      cursor: "pointer",
    },
  },
}));

const ActionButton = styled(StyledIconButton)(() => ({
  maxWidth: "18px",
  maxHeight: "18px",
  backgroundColor: "inherit",
  borderRadius: "4px",
  padding: "0 !important",
}));

const ActionsContainer = styled(Stack)(() => ({
  display: "none",
  position: "absolute",
  flexDirection: "row",
  alignItems: "center",
  padding: "4px 8px",
  gap: "12px",
  top: "8px",
  right: "4px",
  borderRadius: "4px",
  backgroundColor: palette.neutral.white,
  boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
}));

const ItemContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "isFocused" && prop !== "clickable",
})<{ isFocused: boolean; clickable: boolean }>(({ isFocused, clickable }) => ({
  padding: "8px 4px",
  flexDirection: "column",
  gap: "8px",
  borderRadius: "4px",
  position: "relative",
  maxWidth: "100%",
  overflow: "hidden",

  ":hover": {
    backgroundColor: palette.neutral.white,
    "& .document-item-actions": {
      display: "flex",
    },
  },

  cursor: clickable ? "pointer" : "default",
  ...(isFocused && {
    backgroundColor: palette.neutral.white,
    "& .document-item-actions": {
      display: "flex",
    },
  }),
}));

export function formatTimestamp(timestamp: number) {
  const date = new Date(timestamp);
  const formattedDate = format(date, "MMM d, yyyy");
  return formattedDate;
}
