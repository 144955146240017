export type NotificationType = "underwriting" | "conversation" | "generic";

export type TConversationParams = {
  targetMerchantId: number;
  targetMerchantType: string;
  topicId: number;
  threadId: number;
  messageId: number;
};

export enum ALERT_NAMES_ENUM {
  Bulk_merchant_create = "bulk_merchant_creation",
  TRANSFER = "transfer",
  NEW_MEMBER = "new_member",
  NEW_BANK = "new_bank_account",
  OFAC_MATCH = "ofac_match",
  MID_ISSUE = "mid_issue",
  NEGATIVE_BALANCE = "negative_balance",
  MERCHANT_OWNER_UNDERWRITING_APPROVAL = "merchant_owner_underwriting_approval",
  CONVO_MENTION = "convo_mention_alert",
  NEW_DISPUTE = "new_dispute",
  DISPUTE_STATS_CHANGED = "dispute_status_changed",
  UNDERWRITING_EDD_CHALLENGE = "underwriting_edd_challenge",
  UNDERWRITING_CHALLHENGE_REJECTION = "underwriting_challenge_rejection",
  CHARGE_BACK_REVERSAL = "chargeback_reversed",
  CHARGEBACK = "chargeback",
  REFUND = "refund",
  MERCHANT_READY_FOR_APPROVAL = "merchants_ready_for_approval",
  PROVIDER_READY_FOR_APPROVAL = "enterprises_ready_for_approval",
  UNDEWRWRITER_ASSIGNMENT = "underwriter_assignement",
  NEW_MERCHANT = "new_merchant",
  NEW_PROVIDER = "new_enterprise",
  MERCHANT_UNDERWRITING_APPROVAL = "merchant_underwriting_approval",
}

export type TParsedNotification = {
  id: number;
  title: string;
  description: string;
  createdAt: number;
  type: NotificationType;
  isMandatory: boolean;
  firstCheckedAt: number | null;
  challengeSlug: string;
  alertName: ALERT_NAMES_ENUM;
  label?: string;
  transactionID: string;
  targetMerchantId: number;
  conversationParams?: TConversationParams;
  threadId: number;
  disputeId: string;
};

type TAlign = "center" | "start" | "end";
export type TScrollToTop = (index: number, align?: TAlign) => void;

export type TNotificationsFilter = "unread" | "all";
