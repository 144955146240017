import { TDocument } from "@common/FilePreview/types";
import { styled } from "@mui/material";
import { DetailedHTMLProps, ImgHTMLAttributes, useEffect } from "react";
import { useImage } from "react-image";
import FallbackImg from "@assets/images/image-unavailable.png";

const FALLBACK_IMG_WIDTH = 231;
const FALLBACK_IMG_HEIGHT = 186;

interface ICustomImage extends ImageProps {
  file: TDocument;
  onError: (error: any) => void;
  onLoad?: (e: any, customSize?: { width: number; height: number }) => void;
  isLoading?: boolean;
  customStyle?: React.CSSProperties;
}

const CustomImage = ({
  file,
  onError,
  onLoad,
  isLoading,
  customStyle,
  ...props
}: ICustomImage) => {
  const { src, error } = useImage({
    srcList: [file.URL, FallbackImg],
  });

  useEffect(() => {
    if (error) onError(error);
  }, [error]);

  useEffect(() => {
    if (src !== file.URL && isLoading && onLoad) {
      onLoad(null, { width: FALLBACK_IMG_WIDTH, height: FALLBACK_IMG_HEIGHT });
    }
  }, [src, file, isLoading]);

  return (
    <StyledImage
      src={src}
      alt={file.name}
      isLoading={isLoading}
      onLoad={onLoad}
      style={customStyle}
      {...props}
    />
  );
};

type ImageProps = {
  zoom: number;
  maxed?: boolean;
  isLoading?: boolean;
} & DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

const StyledImage = styled("img", {
  shouldForwardProp: (prop) =>
    prop !== "zoom" && prop !== "maxed" && prop !== "isLoading",
})<ImageProps>(({ theme, zoom, maxed = false, isLoading }) => ({
  objectFit: "contain",
  transform: `scale(${zoom})`,
  transformOrigin: "center",
  transition: "transform 0.3s ease-in-out",
  userSelect: "none",

  [theme.breakpoints.up("sm")]: {
    ...(maxed && {
      ...(zoom > 1 && { width: "100%" }),
      height: "100%",
    }),
    margin: "auto",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },

  ...(isLoading && {
    display: "none",
  }),
}));

export default CustomImage;
