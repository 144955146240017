import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import { Stack, styled, StackProps } from "@mui/material";
import { palette } from "@palette";

export const CustomToolbar = styled(Stack)(({ theme }) => ({
  gap: "12px",
  flexDirection: "row",
  alignItems: "center",
  boxShadow: "0px 3px 15px 0px rgba(2, 2, 2, 0.15)",
  padding: "0 16px",
  borderRadius: "100px",
  backgroundColor: palette.liftedWhite[100],

  [theme.breakpoints.down("sm")]: {
    background: palette.black[100],
    backdropFilter: "blur(2px)",
  },
}));

const CircleButton = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "disabled",
})<{ disabled?: boolean }>(({ disabled, theme }) => ({
  cursor: disabled ? "default" : "pointer",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  padding: "10px",
  width: "48px",
  height: "48px",
  borderRadius: "100px",
  backgroundColor: palette.liftedWhite[100],
  boxShadow: "0px 3px 15px 0px rgba(2, 2, 2, 0.15)",
  userSelect: "none",
  opacity: disabled ? 0.3 : 1,
  [theme.breakpoints.down("sm")]: {
    backgroundColor: palette.black[100],
    boxShadow: "0px 3.5px 17.5px 0px rgba(2, 2, 2, 0.15)",
    width: "56px",
    height: "56px",
    padding: 0,
    position: "absolute",
    right: "24px",
  },
}));

export const SingleActionButton = ({
  tooltipProps,
  disabled = false,
  onClick,
  ...props
}: StackProps & {
  tooltipProps?: { show: boolean; message: string };
  disabled?: boolean;
}) => {
  const handleClick = (e: any) => {
    if (disabled || !onClick) return;
    else onClick(e);
  };

  return (
    <WithTooltipWrapper hasTooltip={!!tooltipProps} tooltipProps={tooltipProps}>
      <CircleButton {...props} onClick={handleClick} disabled={disabled} />
    </WithTooltipWrapper>
  );
};
