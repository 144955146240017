import SwipeableDrawerMobile, {
  SwipeableDrawerMobileProps,
} from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";
import { palette } from "@palette";
import { SxProps, Popover, PopoverProps } from "@mui/material";
import ErrorCatcher from "@common/Error/ErrorCatcher";

export type DialogVariantProps = {
  open: boolean;
  anchorEl: HTMLButtonElement | SVGSVGElement | null;
  onClose: () => void;
  sx?: SxProps;
  children: React.ReactNode;
  renderMobile?: boolean;
  PopoverProps?: Omit<PopoverProps, "open">;
  DrawerProps?: Omit<
    SwipeableDrawerMobileProps,
    "onOpen" | "onClose" | "open"
  > & {
    dataTestId?: string;
  };
  PopoverSx?: SxProps;
};

const DRAWER_DEFAULT_TOP = "96px";

const CustomPopover = ({
  open,
  anchorEl,
  onClose,
  children,
  sx,
  renderMobile = false,
  PopoverProps = {},
  DrawerProps = {},
  PopoverSx,
}: DialogVariantProps) => {
  const commonProps = {
    open,
    sx,
  };

  const { dataTestId, ...drawerProps } = DrawerProps;

  if (renderMobile) {
    return (
      <SwipeableDrawerMobile
        anchor="bottom"
        onOpen={() => undefined}
        {...commonProps}
        {...drawerProps}
        data-testid={dataTestId}
        onClose={() => !!onClose && onClose()}
        PaperProps={{
          ...drawerProps?.PaperProps,
          sx: {
            background: palette.neutral[5],
            position: "relative",
            maxHeight: `calc(100vh - ${
              (sx as any)?.top || DRAWER_DEFAULT_TOP
            })`,
            ...drawerProps?.PaperProps?.sx,
          },
        }}
        sx={{
          top: "auto",
          ...sx,
        }}
      >
        <ErrorCatcher errorID="swipeable-drawer">{children}</ErrorCatcher>
      </SwipeableDrawerMobile>
    );
  }

  return (
    <Popover
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      {...PopoverProps}
      slotProps={{
        ...PopoverProps?.slotProps,
        paper: {
          ...PopoverProps?.slotProps?.paper,
          sx: {
            padding: "12px 16px",
            borderRadius: "8px",
            background: palette.neutral.white,
            boxShadow: "0px 3px 15px 0px rgba(2, 2, 2, 0.15)",
            ...PopoverSx,
          },
        },
      }}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      sx={sx}
    >
      <ErrorCatcher errorID="popover">{children}</ErrorCatcher>
    </Popover>
  );
};

export default CustomPopover;
