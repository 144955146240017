import { useGetCurrentMerchantId } from "@hooks/common";
import {
  useCamera,
  useGetIdentificationFiles,
  useGetUploadedSelfie,
} from "@sections/VerifyAccountHolder_v2/hooks/useCamera";
import { useUploadFiles } from "@hooks/upload-api/uploadHooks";
import { SetStateAction, useEffect, useState } from "react";
import { useGetUploadProgress } from "@redux/slices/uploadProgressSlice";
import { showMessage } from "@common/Toast";
import { QKEY_GET_MERCHANT_BY_ID } from "@constants/queryKeys";
import { useQueryClient } from "react-query";
import { base64ToFile } from "@utils/helpers";
import { isEmpty } from "lodash";
import { deleteDocumentsInLoop } from "@components/Settings/Business/Documents/utils";
import { challengeSlugs } from "@constants/challengeSlugs";

type TUseCameraAction = {
  setSelfieUrl: (value: SetStateAction<string | null>) => void;
  setCompleted: (completed: boolean) => void;
  updateProgressBar: (value: number) => void;
  merchant?: any;
  selfieUrl: string | null;
  homepageReset: () => void;
  dbSelfieURL: string | null;
};

const useCameraAction = ({
  setSelfieUrl,
  setCompleted,
  updateProgressBar,
  merchant,
  selfieUrl,
  homepageReset,
  dbSelfieURL,
}: TUseCameraAction) => {
  const queryClient = useQueryClient();
  const { data: files, refetch } = useGetIdentificationFiles();
  const { merchantId } = useGetCurrentMerchantId();
  const { uploadedSelfie, isLoading: gettingSelfie } = useGetUploadedSelfie();
  const { handleUpload } = useUploadFiles();
  const {
    cameraStatus,
    isCameraAvailable,
    webcamRef,
    checkCamera,
    setCamera,
    destroyCamera,
  } = useCamera();

  const [selfie, setSelfie] = useState<string | null>(null);
  const [isCustomLoading, setIsCustomLoading] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  const progress = useGetUploadProgress();
  const progressKey = Object.keys(progress)[0];
  const { canceled, deleted } = progress[progressKey] || {};

  const isCameraOff = selfie === null && cameraStatus?.active === undefined;
  const isCameraOn = selfie === null;
  const isImageCaptured = selfie && !selfieUrl && !cameraStatus?.active;
  const isAllDone = selfieUrl && !isCustomLoading;

  useEffect(() => {
    checkCamera();
    return () => {
      destroyCamera({ cameraStatus });
    };
  }, []);

  // Handling upload cancel and delete
  useEffect(() => {
    if (canceled) {
      cancelUpload();
    } else if (deleted) {
      deleteUpload();
    }
  }, [canceled, deleted]);

  const retakePhoto = () => {
    setSelfie(null);
  };

  const cancelUpload = () => {
    if (isCustomLoading && isButtonDisabled) {
      setIsCustomLoading(false);
      setIsButtonDisabled(false);
    }
  };

  const deleteUpload = () => {
    if (!selfie) return;
    showMessage(
      "Info",
      "Please capture selfie again", // TODO: add the correct message
      true,
      "Image is deleted successfully", // TODO: add the correct message
    );
    setSelfie(null);
    setSelfieUrl(null);
    setIsCustomLoading(false);
    handleRefetch();

    queryClient.refetchQueries([QKEY_GET_MERCHANT_BY_ID, merchantId]);
  };

  const showPendingMsg = () => {
    showMessage(
      "Info",
      "Please upload again", // TODO: add the correct message
      true,
      "Image upload is canceled", // TODO: add the correct message
    );
    setIsButtonDisabled(false);
  };

  const handleRefetch = () => {
    refetch().then((res) => {
      const latestSelfieData = res?.data?.data?.reduce(
        (latest: any, current: any) => {
          if (
            current.attTypeName === "account_owner_selfie" &&
            current.updatedAt > latest.updatedAt
          ) {
            return current;
          }
          return latest;
        },
        { updatedAt: 0 },
      );
      const latestSelfieURL = latestSelfieData?.fileURL;

      if (!latestSelfieData?.isUploaded) return showPendingMsg();

      if (setSelfieUrl) {
        setSelfieUrl(latestSelfieURL);
        setIsCustomLoading(false);
        setIsButtonDisabled(false);
      } else {
        setCompleted && setCompleted(true);
        setIsCustomLoading(false);
        setIsButtonDisabled(false);
      }
      queryClient.invalidateQueries(QKEY_GET_MERCHANT_BY_ID);
    });
  };

  const onSnap = () => {
    if (cameraStatus) {
      if (webcamRef.current) {
        const imageSrc = webcamRef.current.getScreenshot();

        setSelfie(imageSrc);
        updateProgressBar(100);

        destroyCamera({ cameraStatus });
      }
    } else {
      setCamera();
    }
  };

  const onSubmitSelfie = async () => {
    if (!selfie) return;
    setIsCustomLoading(true);
    setIsButtonDisabled(true);
    if (cameraStatus) {
      destroyCamera({ cameraStatus });
    }
    const selfieFile = base64ToFile(selfie);
    const fileArr = files?.data?.filter(
      (doc: any) => doc?.attTypeName === "account_owner_selfie",
    );

    const hideDeleteMessage = true;
    !isEmpty(fileArr) &&
      !isEmpty(merchant) &&
      (await deleteDocumentsInLoop(
        fileArr,
        merchant?.accID || merchantId,
        hideDeleteMessage,
      ));

    await handleUpload(
      {
        list: [{ file: selfieFile }],
        merchantId: merchant?.accID || merchantId,
        resourceID: merchant?.accID || merchantId,
        attachmentType: "account_owner_selfie",
        label: "",
        tag: `merchant upload`,
      },
      challengeSlugs.PRIMARY_5,
    );

    handleRefetch();

    queryClient.refetchQueries([QKEY_GET_MERCHANT_BY_ID, merchantId]);
  };

  const getPrimaryButtonText = () => {
    if (isCameraOff) {
      return "Open camera";
    } else if (isCameraOn) {
      return "";
    } else if (isImageCaptured) {
      return "Submit";
    } else if (isAllDone) {
      return "Done";
    }
  };

  const handlePrimaryAction = () => {
    if (isCameraOff || isCameraOn) {
      onSnap();
    } else if (isImageCaptured) {
      onSubmitSelfie();
    } else if (isAllDone) {
      setSelfieUrl(null);
      homepageReset();
    } else {
      setSelfieUrl(dbSelfieURL);
    }
  };

  return {
    cameraInfo: {
      cameraStatus,
      webcamRef,
      isCameraOff,
      isCameraOn,
    },
    cameraAction: {
      retakePhoto,
      getPrimaryButtonText,
      handlePrimaryAction,
    },
    selfieStates: {
      selfie,
      uploadedSelfie,
      isCustomLoading,
      isAllDone,
      gettingSelfie,
      isButtonDisabled,
      isCameraAvailable,
    },
  };
};

export default useCameraAction;
