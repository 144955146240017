export const SUPPORT_DEFAULT_MESSAGE =
  "Your support makes a difference! Adding a little extra helps us keep delivering the best service possible.";
export const CONTACT_DEFAULT_MESSAGE =
  "In case there is an issue with delivery, we can contact you via phone";
export const DEFAULT_THANK_YOU_MESSAGE = `We want to extend our heartfelt thanks for shopping with us! Your support means a lot, and we’re delighted to have you as a customer.\n
If you have any questions about your order, please don’t hesitate to reach out. We hope to serve you again soon!`;
export const DEFAULT_RECEIPT_MESSAGE =
  "Thank you for your purchase! We are pleased to confirm that we have received your order Your items are being processed and will be shipped shortly.";

export const addressFields = {
  country: "United States",
  firstName: "",
  lastName: "",
  adress: "",
  apartment: "",
  city: "",
  province: "",
  zipCode: "",
};
