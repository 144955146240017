import useSnapShot from "features/Merchants/MerchantSidePanel/hooks/useSnapShot";
import BaseCardComponent from "../BaseCardComponent";

function MiscellaneousFees() {
  const { misleneousFees } = useSnapShot();

  return (
    <BaseCardComponent
      leftTitle="Miscellaneous Fees"
      payload={misleneousFees}
    />
  );
}

export default MiscellaneousFees;
