import { Stack } from "@mui/material";
import { styled } from "@theme/v2/Provider";
import { lowerFirst } from "lodash";
import ChallengeBodyTop from "./ChallengeBodyTop";
import { Plus } from "@phosphor-icons/react";
import { useState } from "react";
import GiveButton from "@shared/Button/GiveButton";
import NiceModal from "@ebay/nice-modal-react";
import { EDD_CHALLENGE_MODAL } from "modals/modal_names";
import ChallengeItemMenu from "./ChallengeItemMenu";
import ChallengeItem from "./ChallengeItem";

export default function ChallengeBody({
  data,
  name,
  identifier,
}: {
  name: string;
  identifier: string;
  data: {
    categoryStats: any; //TODO: fix types
    challengesList:
      | {
          total: number;
          data: any[];
        }
      | undefined;
  };
}) {
  const [currentChallenge, setCurrentChallenge] = useState(undefined);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const trimmedName = name.replaceAll(" ", "");
  const statAccessorName = `${lowerFirst(trimmedName)}ChallengesCount`;
  const closedStatAccessorName = `closed${trimmedName}ChallengesCount`;
  const isMerchantOnboarding = identifier === "merchant_onboarding";
  const isEDD = identifier === "enhanced_due_diligence";
  const status =
    data?.categoryStats[statAccessorName] ===
    data?.categoryStats[closedStatAccessorName]
      ? "Completed"
      : "In Progress";
  const challengeList = data?.challengesList?.data;

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setCurrentChallenge(undefined);
  };

  const handleOpenMenu = (e: HTMLElement, challenge: any) => {
    setCurrentChallenge(challenge);
    setAnchorEl(e);
  };

  return (
    <Container>
      {/* TODO: update after API integration for single catgeory stats */}
      <ChallengeBodyTop
        identifier={identifier}
        name={name}
        completed={data.categoryStats[closedStatAccessorName]}
        incomplete={data.categoryStats[statAccessorName]}
        disabled={data.categoryStats[statAccessorName]}
        status={status}
        isEmptyChallengeList={!challengeList || challengeList?.length === 0}
      />
      {challengeList?.map((challenge, index) => {
        return (
          <ChallengeItem
            challenge={challenge}
            key={index}
            isMerchantOnboarding={isMerchantOnboarding}
            handleOpenMenu={handleOpenMenu}
          />
        );
      })}
      {isEDD && <AddEDDChallengeButton />}
      <ChallengeItemMenu
        handleClose={handleCloseMenu}
        anchorEl={anchorEl}
        currentChallenge={currentChallenge}
        isEDD={isEDD}
      />
    </Container>
  );
}

const Container = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.surface?.primary,
  borderRadius: theme.customs?.radius.medium,
  margin: "0px 20px 40px 20px",
  padding: "20px 12px 12px 12px",
  gap: "20px",
  flex: 1,
  marginTop: "16px",
}));

const AddEDDChallengeButton = () => {
  return (
    <GiveButton
      label="Add New Challenge"
      startIcon={<Plus size={20} />}
      fullWidth
      color="light"
      variant="ghost"
      sx={{ justifyContent: "flex-start", borderRadius: "8px" }}
      size="large"
      onClick={() =>
        NiceModal.show(EDD_CHALLENGE_MODAL, {
          type: "create",
        })
      }
    />
  );
};
