import { Skeleton, Stack } from "@mui/material";

export const IDVerificationLoader = () => {
  return (
    <Stack gap={1}>
      <Skeleton variant="rounded" height={155} sx={{ borderRadius: "8px" }} />
      <Skeleton variant="rounded" height={155} sx={{ borderRadius: "8px" }} />
    </Stack>
  );
};
