import React, { useState } from "react";
// utils
import { matchIsValidTel } from "mui-tel-input";
// form
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
// services
import { customInstance } from "@services/api";
import { useMutation, useQueryClient } from "react-query";
import { useGetUser } from "@services/api/onboarding/user";

// localization
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
// redux
import { updatePartialUser } from "@redux/slices/auth/auth";
// components
import { showMessage } from "@common/Toast/ShowToast";
// utils
import { fromUnixTime, getUnixTime, subYears } from "date-fns";
import { getSessionTimezone } from "@utils/timezones";
import { convertPhoneNumber } from "@utils/date.helpers";
import {
  MINIMUM_REQUIRED_AGE,
  MAXIMUM_REQUIRED_AGE,
  ACCEPTED_IMAGE_FORMATS,
} from "@constants/constants";
import { useDispatch } from "react-redux";
import { useFileUploadContext } from "@components/UploadFile/FileUploadContext";
import { addSizeToImage } from "@components/UploadAvatar/UploadAvatar";
import { useChangeProfileAvatar } from "@hooks/upload-api/useChangeProfileAvatar";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

type IFormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  pendingEmail: string;
  phone: string;
  address: string;
  date: number | Date | null;
  occupation: string;
  language: string;
  employer: string;
  currency: string;
  timezone: string;
};

export const useAccount = () => {
  const dispatch = useDispatch();
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
  const {
    handleChangeStatus: handleChangeStatusNew,
    updateUserImage: updateUserImageNew,
  } = useChangeProfileAvatar();
  const updateUserImageOld = (url: string | null) => {
    const imageURL = { imageURL: url };

    updateUserData.mutate(imageURL, {
      onSuccess: (res: any) => {
        const user = queryClient.getQueryData("user");
        if (user) {
          queryClient.setQueryData(
            "user",
            Object.assign(user as object, imageURL),
          );
        }

        dispatch(
          updatePartialUser({
            img: addSizeToImage(url || "", "thumb"),
          }),
        );
      },
    });
  };
  const handleChangeStatusOld: any = async ({ file }: { file: File }) => {
    if (file) {
      const fileToUpload = {
        file,
        meta: {
          status: "done",
          type: file?.type,
          id: Math.random().toString(),
          size: file?.size,
          name: file?.name,
        },
      } as any;
      const img: any = await populateSnackbarFiles(
        {
          fileWithMeta: fileToUpload,
          status: fileToUpload?.status,
          allFiles: [fileToUpload],
          isCustomerUpload: true,
        },
        ACCEPTED_IMAGE_FORMATS,
      );
      const url = !!img && typeof img?.[0] === "string" ? img[0] : null;
      updateUserImage(url);
    }
  };

  const handleChangeStatus = isFileUploadRefactorEnabled
    ? handleChangeStatusNew
    : handleChangeStatusOld;
  const updateUserImage = isFileUploadRefactorEnabled
    ? updateUserImageNew
    : updateUserImageOld;
  const schema = Yup.object().shape({
    firstName: Yup.string().required("This field is required"),
    lastName: Yup.string().required("This field is required"),
    email: Yup.string()
      .email("Please enter a valid email")
      .required("This field is required")
      .matches(
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        "Please enter a valid email",
      ),
    phone: Yup.string().when({
      is: (exists: string) =>
        Boolean(exists && exists !== "+1" && dirtyFields.phone),
      then: (schema) =>
        schema.test(
          "is-valid-number",
          "Please enter a valid phone number",
          function (value) {
            const phoneNumber = value as string;
            return matchIsValidTel(phoneNumber);
          },
        ),
    }),
    address: Yup.string(),
    date: Yup.date()
      .typeError("Please enter a valid date")
      .nullable()
      .min(subYears(new Date(), MAXIMUM_REQUIRED_AGE), "Must be 100 or less")
      .max(subYears(new Date(), MINIMUM_REQUIRED_AGE), `Must be 18 or older`),

    state: Yup.string().matches(/^[a-zA-Z\s]*$/, "Please enter a valid state"),
    occupation: Yup.string().matches(
      /^[a-zA-Z\s]*$/,
      "Please enter a valid occupation",
    ),
    employer: Yup.string().matches(
      /^[a-zA-Z\s]*$/,
      "Please enter a valid employer",
    ),
  });

  const { data: userData } = useGetUser();
  const queryClient = useQueryClient();
  const { i18n } = useTranslation(namespaces.pages.settings);

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });
  const {
    control,
    reset,
    formState: { dirtyFields, isDirty },
  } = methods;

  const updateUserData = useMutation((data: any) => {
    return customInstance({
      url: `/users/${userData.accID}`,
      method: "PATCH",
      data,
    });
  });

  React.useEffect(() => {
    if (userData) {
      reset({
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        phone: `+${userData.phoneNumber || 1}`,
        date:
          userData.dateOfBirth === null
            ? null
            : fromUnixTime(userData.dateOfBirth),
        employer: userData.employer,
        occupation: userData.occupation,
        language: userData.language || "eng",
        timezone: userData.timezone || getSessionTimezone(),
        currency: userData.currency || "usd",
      });
    }
  }, [userData, userData?.pendingEmail]);

  const { populateSnackbarFiles } = useFileUploadContext();

  const removeImage = () => updateUserImage(null);

  const onSubmit: SubmitHandler<IFormInputs> = (updatedData) => {
    changeLanguage(updatedData.language);
    let dateOfBirth: number | null;

    if (!updatedData.date) {
      dateOfBirth = null;
    } else {
      dateOfBirth = getUnixTime(updatedData.date);
    }

    updateUserData.mutate(
      {
        firstName: updatedData.firstName,
        lastName: updatedData.lastName,
        ...(userData?.email !== updatedData.email && {
          email: updatedData?.email,
        }),
        phoneNumber:
          updatedData.phone && updatedData.phone.length > 0
            ? convertPhoneNumber(updatedData.phone)
            : null,
        dateOfBirth,
        occupation: updatedData.occupation,
        employer: updatedData.employer,
        language: updatedData.language.toLowerCase(),
        timezone: updatedData.timezone.toLowerCase(),
        currency: updatedData.currency,
      },
      {
        onError: () => {
          showMessage("Error", "Invalid Inputs, please verify you data");
        },
        onSuccess: () => {
          dispatch(
            updatePartialUser({
              globalName: {
                firstName: updatedData.firstName,
                lastName: updatedData.lastName,
                phoneNumber: updatedData.phone,
              },
              timezone: updatedData.timezone,
            }),
          );
          reset(updatedData);
          queryClient.invalidateQueries("user");
          showMessage("Success", "Info has been edited successfully");
        },
      },
    );
  };

  return {
    methods,
    onSubmit,
    handleChangeStatus,
    control,
    defaultValues: initialValues,
    isLoading: updateUserData.isLoading,
    isDirty,
    removeImage,
  };
};

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  pendingEmail: "",
  phone: "",
  address: "",
  date: undefined,
  employer: "",
  occupation: "",
  currency: "usd",
  language: "eng",
  timezone: "",
};
