import { isSecondVersion } from "@theme/v2/utils";
import { TypographyProps } from "@mui/material";
import { typography } from "../../typography";

export const getCustomTypographyStyle = () => {
  const oldTypographyStyles = typography as any;
  return {
    root: {
      variants: [
        {
          props: (props: TypographyProps) => {
            return !isSecondVersion(props.version);
          },
          style: (props: TypographyProps) => {
            const variant = props?.variant;
            if (!variant) return {};
            return oldTypographyStyles[variant] || {};
          },
        },
      ],
    },
  };
};
