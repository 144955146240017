// nice-modal
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
// mui
import { Box, Stack, styled } from "@mui/material";
// components
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  selectMasquerade,
  setMasqueradeSkipModal,
} from "@redux/slices/enterprise/merchants";
import { Checkbox } from "@common/Checkbox";
import Popup from "@common/Popup";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { Button } from "@common/Button";
import { DialogProps } from "@common/Modal/Modal";

type MasqueradeModalProps = {
  name: string;
};

const MasqueradeModal = NiceModal.create(({ name }: MasqueradeModalProps) => {
  const modal = useModal();
  const dispatch = useAppDispatch();

  const { skipModal } = useAppSelector(selectMasquerade);

  const handleClose = () => {
    modal.hide();
  };

  const switchSkip = () => {
    dispatch(setMasqueradeSkipModal(!skipModal));
  };

  const understoodHandler = () => {
    handleClose();
  };

  return (
    <StyledModal
      {...muiDialogV5(modal)}
      width="480px"
      onCancel={understoodHandler}
    >
      <Stack spacing={1}>
        <Text fontSize={20} fontWeight="book" color={palette.neutral[80]}>
          Control Mode
        </Text>
        <Stack spacing={4}>
          <Text fontSize={16} fontWeight="light" color={palette.neutral[80]}>
            You are controlling{" "}
            <span style={{ fontWeight: 500, overflowWrap: "break-word" }}>
              {name}
            </span>
            . You can see all their content and perform actions on their behalf.
          </Text>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Checkbox
              label="Do not show this again"
              checked={skipModal}
              onChange={switchSkip}
            />
            <Button
              onClick={understoodHandler}
              size="medium"
              sx={{ padding: "8px 24px" }}
            >
              Continue
            </Button>
          </Box>
        </Stack>
      </Stack>
    </StyledModal>
  );
});

const StyledModal = styled(Popup)<DialogProps>(() => ({
  "& .MuiDialogContent-root": {
    padding: "16px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  "& .MuiDialogActions-root": {
    display: "none",
  },
}));

export default MasqueradeModal;
