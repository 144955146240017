import { showMessage } from "@common/Toast";
import { customInstance } from "@services/api";
import { useMutation, useQueryClient } from "react-query";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  selectConversationTopic,
  setConversationTopic,
} from "@redux/slices/conversations";
import { selectSelectedAccount } from "@redux/slices/auth/accounts";

const markAsSolved = async ({
  merchantId,
  challengeId,
}: {
  merchantId: number;
  challengeId: number;
}) => {
  return customInstance({
    url: `/merchants/${merchantId}/underwriting-challenges/${challengeId}/status`,
    method: "PATCH",
    data: {
      status: "closed",
      type: "enhanced_due_diligence",
    },
  });
};

const closeThread = async ({
  ownerAccID,
  threadId,
}: {
  ownerAccID: number;
  threadId: number;
}) => {
  return customInstance({
    url: `/merchants/${ownerAccID}/threads/${threadId}/status`,
    method: "PATCH",
    data: {
      statusName: "closed",
    },
  });
};

const useMarkAsSolved = ({
  merchantId,
  ownerAccID,
  isRiskMonitor,
  threadId,
  challengeId,
}: {
  ownerAccID: number;
  threadId: number;
  merchantId: number;
  challengeId: number;
  isRiskMonitor: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { queryObject } = useAppSelector(selectConversationTopic);
  const queryClient = useQueryClient();

  const onSuccess = () => {
    const paths = queryObject?.paths;
    const pathIndex = paths?.findIndex((item) => item.isConversation);
    const newPaths = [...paths];

    if (pathIndex !== -1) {
      newPaths[pathIndex] = {
        ...newPaths[pathIndex],
        hideInputs: ["module", "subject", "message"],
      };
    } else {
      newPaths.push({
        hideInputs: ["module", "subject", "message"],
        isConversation: true,
        pathName: "",
        avatars: [],
      });
    }
    dispatch(
      setConversationTopic({
        queryObject: {
          ...queryObject,
          paths,
        },
      }),
    );
    queryClient.invalidateQueries("fetch-activity-list");
    queryClient.refetchQueries("get-challenges");
  };

  const markAsSolvedMutation = useMutation(
    () => {
      if (isRiskMonitor) {
        return closeThread({ ownerAccID, threadId });
      } else {
        return Promise.all([
          markAsSolved({ merchantId, challengeId }),
          closeThread({ ownerAccID, threadId }).catch((e) => {
            console.log("The thread wasn't closed", e);
          }),
        ]);
      }
    },
    {
      onError: (err: unknown) => {
        const axiosError = err as any;
        const errorMessage = axiosError?.response?.data?.message;
        showMessage("Error", errorMessage || "Unable to change assignee");
      },
      onSuccess: () => {
        onSuccess();
      },
    },
  );
  return markAsSolvedMutation;
};

export const useCloseThread = ({ threadId }: { threadId?: number }) => {
  const selectedUser = useAppSelector(selectSelectedAccount);
  const ownerAccID = selectedUser?.id || 0;

  const closeThreadMutation = useMutation(
    () => {
      if (threadId === 0 || threadId === undefined) {
        throw new Error(
          "Cannot close thread: threadId is required and must not be 0.",
        );
      }
      return closeThread({ ownerAccID, threadId });
    },
    {
      onError: () => {
        showMessage("Error", "Unable to close topic");
      },
    },
  );

  return closeThreadMutation;
};

export default useMarkAsSolved;
