import ResetPassword from "./ResetPassword";
type Props = {
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};
const SecurityTab = ({ setDisabled }: Props) => {
  return (
    <>
      <ResetPassword setDisabled={setDisabled} />
    </>
  );
};

export default SecurityTab;
