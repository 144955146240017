import { TickIcon } from "@assets/icons";
import { Text } from "@common/Text";
import { Box, BoxProps, Grid } from "@mui/material";
import { Stack, styled } from "@mui/system";
import { palette } from "@palette";
import { memo } from "react";
import { ProductType, TPaymentTypes } from "../types";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

export type CardType = {
  name: ProductType | TPaymentTypes;
  description: string;
};

interface Props {
  selected: boolean;
  handleSelectType: (name: any) => void;
  type: CardType;
}

function PaymentTypeCard({ type, selected, handleSelectType }: Props) {
  const { isPayBuilderEnabled } = useGetFeatureFlagValues();
  const isProductDisabled = type.name === "Product" && !isPayBuilderEnabled;
  return (
    <Grid item xs={12} sm={6} onClick={() => handleSelectType(type.name)}>
      <TypeCard disabled={isProductDisabled} selected={selected}>
        <Box sx={iconStyle} className="check-icon">
          <TickIcon height={12} width={12} stroke="#fff" />
        </Box>
        <Stack direction="row" spacing={1}>
          <Text fontSize={18} fontWeight="book" color={palette.neutral[70]}>
            {type.name === "Standard" ? "Standard Payment Form" : type.name}
          </Text>

          {isProductDisabled && <Text sx={disabledTagStyle}>Coming soon</Text>}
        </Stack>
        <Text color={palette.neutral[80]}>{type.description}</Text>
      </TypeCard>
    </Grid>
  );
}

export default memo(PaymentTypeCard);

const TypeCard = styled(Box)<
  BoxProps & { selected?: boolean; disabled?: boolean }
>(({ selected, disabled }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  textAlign: "start",
  gap: "16px",
  padding: "24px",
  borderRadius: "12px",
  border: `1px solid ${palette.neutral[10]}`,
  background: "#FBFBFB",
  cursor: "pointer",
  userSelect: "none",
  position: "relative",
  "& .check-icon": {
    visibility: "hidden",
  },
  "&:hover": {
    background: palette.neutral[10],
    "& *": {
      color: palette.neutral[80],
    },
  },

  ...(selected && {
    background: "#fff",
    boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.10)",
    "& .check-icon": {
      visibility: "visible",
    },
    "& *": {
      color: palette.neutral[80],
    },
    "&:hover": {
      background: "#fff",
    },
  }),

  ...(disabled && {
    cursor: "default",
    "& *": {
      color: palette.gray[100],
    },
    "&:hover": {
      "& *": {
        color: palette.gray[100],
      },
    },
  }),
}));

const iconStyle = {
  height: 20,
  width: 20,
  borderRadius: "50%",
  backgroundColor: palette.black.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: 10,
  right: 10,
};

export const disabledTagStyle = {
  color: `${palette.filled.blue} !important`,
  backgroundColor: "#E6EAF2",
  borderRadius: "4px",
  padding: "0 8px",
  fontWeight: "400",
};
