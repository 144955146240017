import GiveButton from "@shared/Button/GiveButton";
import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";

export default function useHandleScroll({
  defaultHasAgreed,
}: {
  defaultHasAgreed?: boolean;
}) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [enabledAgreementCheckbox, setEnabledAgreementCheckbox] =
    useState(false);
  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    const scrolledHeight = Math.ceil(scrollTop + clientHeight);
    const totalScrollHeight = Math.ceil(scrollHeight);
    const tolerance = 2;
    if (
      scrolledHeight >= totalScrollHeight - tolerance &&
      !enabledAgreementCheckbox &&
      !defaultHasAgreed
    ) {
      setEnabledAgreementCheckbox(true);
    }
  };

  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  const scrollToBottom = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollTo({
        top: container.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const scrollButton = (
    <GiveButton
      sx={buttonStyle(!inView)}
      onClick={scrollToBottom}
      label="Scroll to bottom"
      size="large"
      variant="filled"
    />
  );
  return {
    scrollButton,
    handleScroll,
    containerRef,
    viewRef: ref,
    enabledAgreementCheckbox,
    setEnabledAgreementCheckbox,
  };
}

const buttonStyle = (visible: boolean) => ({
  margin: "0 auto",
  bottom: "16px",
  zIndex: 99,
  padding: "4px 12px 4px 16px",
  position: "sticky",
  display: "flex",
  opacity: visible ? 1 : 0,
  visibility: visible ? "visible" : "hidden",
  transition: "opacity 0.3s ease, visibility 0.3s ease",
});
