import { palette } from "@palette";
import qs from "query-string";
// mui
import { Box, Grid, styled } from "@mui/material";
// form
import { FormProvider } from "react-hook-form";
// components
import { Text } from "@common/Text";
import { Button } from "@common/Button";
import { RHFInput } from "@common/Input";
import LoginContainer from "./LoginContainer";
import RequirementBox from "./RequirementBox";
import { useChangePassword } from "hooks/auth-api";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { PASSWORD_MIN_CHARS } from "@constants/constants";
import { InfoIcon } from "@assets/icons";
import { RHFCheckbox } from "@common/Checkbox";
import NiceModal from "@ebay/nice-modal-react";
import { TERMS_OF_SERVICE_MODAL } from "modals/modal_names";
import { useEffect } from "react";
import { showMessage } from "@common/Toast";
import { isValidEmail } from "@utils/helpers";
import { useVerifyPasswordResetToken } from "@hooks/auth-api/useVerifyPasswordResetToken";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import ExpiredPasswordReset from "@pages/Login/ExpiredPasswordReset";

type Props = {
  isToS?: boolean;
};

const ChangePassword = ({ isToS = false }: Props) => {
  const [searchParams] = useSearchParams();
  const { search } = useLocation();
  const { id } = useParams();

  const { methods, onSubmit, isDisabled, alert } = useChangePassword(isToS);
  const { getFieldState, watch, formState, setValue } = methods;
  const values = watch();
  const { isLoading, isError } = useVerifyPasswordResetToken({
    token: id as string,
  });
  const isDisabledPassword =
    !formState.isValid || values.confirmPassword !== values.password;

  const openTermsConditions = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.preventDefault();
    NiceModal.show(TERMS_OF_SERVICE_MODAL, {
      agree: () =>
        setValue("termsConditions", true, {
          shouldDirty: true,
          shouldValidate: true,
        }),
    });
  };

  useEffect(() => {
    const params = qs.parse(search);
    if (isToS && isValidEmail(params?.email as string)) {
      showMessage("Success", "Your account has been successfully validated!");
    }
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ExpiredPasswordReset />;
  }

  return (
    <LoginContainer hideFooterLogo>
      <Box>
        <FormProvider {...methods}>
          <StyledForm
            component="form"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <Text
              color={palette.black[100]}
              variant="headline"
              textAlign="center"
            >
              Set password for <b>{searchParams.get("email")}</b>
            </Text>
            {alert && (
              <AlertBox>
                <InfoIcon
                  height={24}
                  width={24}
                  path_fill={palette.info.main}
                />
                <Text
                  color={palette.info.main}
                  fontSize={14}
                  variant="headline"
                >
                  {alert}
                </Text>
              </AlertBox>
            )}
            <RHFInput
              name="password"
              fullWidth
              placeholder="Enter your new password"
              inputProps={{ maxLength: 254 }}
              label={"Password"}
            />
            <Grid container>
              {requirementsList.map((item, index) => {
                return (
                  <Grid item xs={12} sm={6} key={index}>
                    <RequirementBox
                      requirement={item.text}
                      color={
                        getFieldState("password").isDirty ||
                        getFieldState("password").isTouched ||
                        getFieldState("password").invalid ||
                        values.password.length > 0
                          ? getErrorColor(item.type, values.password)
                          : palette.neutral[200]
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
            <RHFInput
              name="confirmPassword"
              fullWidth
              error={
                !!(
                  values.confirmPassword &&
                  values.confirmPassword !== values.password
                )
              }
              placeholder="Enter your new password again"
              label="Re-type password"
              inputProps={{ maxLength: 254 }}
              helper={
                values.confirmPassword &&
                values.confirmPassword !== values.password
                  ? "Passwords do not match"
                  : ""
              }
            />
            {isToS && (
              <RHFCheckbox
                name="termsConditions"
                label={
                  <>
                    I agree to{" "}
                    <Box
                      sx={{
                        display: "inline-block",
                        textDecoration: "underline",
                      }}
                      onClick={openTermsConditions}
                    >
                      <Text color={palette.black[100]}>
                        Give Corporation terms of service
                      </Text>
                    </Box>
                  </>
                }
              />
            )}
            <Button
              type="submit"
              variant="secondary"
              disabled={isDisabled || isDisabledPassword}
              sx={{
                "@media (min-width: 600px)": {
                  alignSelf: "center",
                },
              }}
            >
              Continue
            </Button>
          </StyledForm>
        </FormProvider>
      </Box>
    </LoginContainer>
  );
};

const requirementsList = [
  {
    type: "minCharacters",
    text: `${PASSWORD_MIN_CHARS} Characters minimum`,
  },
  {
    type: "number",
    text: "One number",
  },
  {
    type: "lowercase",
    text: "One lowercase character",
  },
  {
    type: "specialCharacter",
    text: "One special character",
  },
  {
    type: "uppercase",
    text: "One uppercase character",
  },
];

export const getErrorColor = (type: string, value: string) => {
  switch (type) {
    case "minCharacters":
      return value.length >= PASSWORD_MIN_CHARS
        ? palette.filled.green
        : palette.filled.red;
    case "number":
      return /[0-9]/.test(value) ? palette.filled.green : palette.filled.red;
    case "uppercase":
      return /[A-Z]/.test(value) ? palette.filled.green : palette.filled.red;
    case "lowercase":
      return /[a-z]/.test(value) ? palette.filled.green : palette.filled.red;
    case "specialCharacter":
      return /[!-/:-@[-`{-~]/.test(value)
        ? palette.filled.green
        : palette.filled.red;
    default:
      return palette.filled.red;
  }
};
export const getNewErrorColor = (type: string, value: string) => {
  switch (type) {
    case "minCharacters":
      return value.length >= PASSWORD_MIN_CHARS
        ? palette.filled.green
        : palette.filled.red;
    case "number":
      return /[0-9]/.test(value) ? palette.filled.green : palette.filled.red;
    case "uppercase":
      return /[A-Z]/.test(value) ? palette.filled.green : palette.filled.red;
    case "lowercase":
      return /[a-z]/.test(value) ? palette.filled.green : palette.filled.red;
    case "specialCharacter":
      return /[!-/:-@[-`{-~]/.test(value)
        ? palette.filled.green
        : palette.filled.red;
    default:
      return palette.filled.red;
  }
};

const StyledForm = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  alignItems: "stretch",
}));

const AlertBox = styled(Box)({
  display: "flex",
  gap: "16px",
  alignItems: "end",
  padding: "10px 16px",
  borderRadius: "8px",
  backgroundColor: palette.info.light,
});

export default ChangePassword;
