import {
  PaperIcon,
  TeamMembersIcon,
  EnterpriseIcon,
} from "@assets/emptyStatesIcons";
import { NotebookIcon } from "@assets/icons/RebrandedIcons";
import StoreFrontIcon from "@assets/icons/RebrandedIcons/StoreFrontIcon";
import {
  CustomerPeopleIcon,
  DisputesIcon,
  JobsIcon,
  TransfersIcon,
  SettlementIcon,
  MerchantsIcon,
} from "@assets/rebrandIcons";
import { EmptyState } from "@common/EmptyState";
import {
  IEmptyStateProps,
  TEmptyStateAction,
} from "@common/EmptyState/EmptyState";
import { useCreateMerchantActions } from "@components/AcquirerMerchants/hooks/useCreateMerchantActions";
import RESOURCE_BASE, {
  CREATE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import NiceModal from "@ebay/nice-modal-react";
import { Stack, SxProps } from "@mui/material";
import { useAccessControl } from "features/Permissions/AccessControl";
import { CREATE_ENTERPRISE_PANEL_MODAL } from "modals/modal_names";
import React from "react";
import { TListWrapperSection } from "./types";
import { DeveloperIcon } from "@assets/icons";

interface ListWrapperWithStatesProps {
  children?: React.ReactNode;
  isEmpty?: boolean;
  section?: TListWrapperSection;
  sx?: SxProps;
  action?: TEmptyStateAction | null;
}

const ListWrapperWithStates = ({
  isEmpty = false,
  children,
  section,
  sx,
  action,
}: ListWrapperWithStatesProps) => {
  const { data } = useEmptyState();
  const currentData = section ? data[section] : null;

  if (isEmpty && section && currentData) {
    return (
      <EmptyState
        {...currentData}
        action={
          action ? action : action === null ? undefined : currentData?.action
        }
      />
    );
  }

  return (
    <Stack sx={sx} flexGrow={1} mb={section === "purchase" ? 3 : 0}>
      {children}
    </Stack>
  );
};

const useEmptyState = () => {
  const {
    handleClickCreateMerchant,
    isLoading,
    isFetching,
    isCreateMerchantAllowed,
  } = useCreateMerchantActions();
  const isCreatingMerchant = isLoading || isFetching;

  const isCreateEnterpriseAllowed = useAccessControl({
    resource: RESOURCE_BASE.ENTERPRISE,
    operation: OPERATIONS.CREATE,
  });

  const data: Record<TListWrapperSection, IEmptyStateProps> = {
    "merchant-list": {
      Icon: <MerchantsIcon width={100} height={100} gradient />,
      title: {
        main: "You do not have any merchants",
        secondary: "Let's create your first merchant",
      },
      action: {
        label: "Create Merchant",
        onClick: handleClickCreateMerchant,
        disabled: isCreatingMerchant || !isCreateMerchantAllowed,
      },
    },
    merchant: {
      Icon: <StoreFrontIcon width={100} height={100} />,
      title: {
        main: "No merchants",
      },
    },
    customer: {
      Icon: <CustomerPeopleIcon width={100} height={100} gradient />,
      title: {
        main: "You do not have any customers",
      },
    },
    riskMonitorTransactions: {
      Icon: <JobsIcon width={100} height={100} gradient />,
      title: {
        main: "You do not have any transactions",
      },
    },
    transaction: {
      Icon: <JobsIcon width={100} height={100} gradient />,
      title: {
        main: "You do not have any transactions",
      },
      height: "100%",
    },
    bankAccount: {
      Icon: <JobsIcon width={100} height={100} gradient />,
      title: {
        main: "You do not have any bank account",
      },
      height: "100%",
    },
    transfers: {
      Icon: <TransfersIcon width={100} height={100} gradient />,
      title: {
        main: "You do not have any transfers",
      },
      height: "100%",
    },
    settlement: {
      Icon: <SettlementIcon width={100} height={100} gradient />,
      title: {
        main: "You do not have any settlement",
      },
      height: "100%",
    },
    disputes: {
      Icon: <DisputesIcon width={100} height={100} gradient />,
      title: {
        main: "You do not have any disputes",
      },
      height: "100%",
    },
    enterprise: {
      Icon: <EnterpriseIcon width={100} height={100} gradient />,
      title: {
        main: "You do not have any providers",
        secondary: "Let's create your first provider",
      },
      action: {
        label: "Create Provider",
        onClick: () => NiceModal.show(CREATE_ENTERPRISE_PANEL_MODAL),
        disabled: !isCreateEnterpriseAllowed,
        tooltipProps: {
          show: !isCreateEnterpriseAllowed,
          message: CREATE_DENY_MESSAGE,
        },
      },
    },
    purchase: {
      Icon: <PaperIcon />,
      title: {
        main: `You do not have purchases`,
      },
    },
    team: {
      Icon: <TeamMembersIcon />,
      title: {
        main: "You do not have any team members",
        secondary: "Invite people to your team.",
      },
    },
    permissions: {
      Icon: <NotebookIcon />,
      title: {
        main: "No permissions have been allocated to the user at this time",
        secondary: "Begin by adding the first permission",
      },
      sx: {
        padding: "50px",
        flexGrow: 1,
      },
    },
    "developer-api": {
      Icon: <DeveloperIcon gradient width={100} height={100} />,
      title: {
        main: "You do not have any API keys",
      },
    },
  };

  return { data };
};

export default ListWrapperWithStates;
