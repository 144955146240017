import { TOFACLastCheckType } from "../../data.types";
import OFACStatus from "./OFACStatus";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";

import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { checkPortals } from "@utils/routing";
import { OFACCheckStatusType } from "../hooks/useOFAC";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { OFACTabType } from "../helpers";
import PreviewSection from "../../components/PreviewSection";

type Props = {
  id: number;
  disabled: boolean;
  onOpen: (open?: boolean, tab?: OFACTabType) => void;
  opened: boolean;
  lastCheckBO?: TOFACLastCheckType;
  lastCheckBP?: TOFACLastCheckType;
  lastCheckPAH?: TOFACLastCheckType;
  isAcquirer?: boolean;
};

const OFACPreviewSection = ({
  disabled,
  onOpen,
  opened,
  lastCheckBO,
  lastCheckBP,
  lastCheckPAH,
  isAcquirer,
}: Props) => {
  const { isNewOfacTabsEnabled } = useGetFeatureFlagValues();
  const { openConversationHandler } = useConversationsModal();
  const { isMobileView } = useCustomTheme();
  const { isAcquirerEnterprises } = checkPortals();
  const onConvClick = () => {
    openConversationHandler({ id: 0, name: "OFAC Check", paths: [] });
  };

  const composed = isNewOfacTabsEnabled
    ? composePermission(
        isAcquirerEnterprises
          ? RESOURCE_BASE.ENTERPRISE
          : RESOURCE_BASE.MERCHANT,
        RESOURCE_BASE.OFAC,
      )
    : composePermission(
        isAcquirerEnterprises
          ? RESOURCE_BASE.ENTERPRISE
          : RESOURCE_BASE.MERCHANT,
        RESOURCE_BASE.LEGAL_ENTITY,
        RESOURCE_BASE.OFAC,
      );

  const isViewReadOfacAllowed = useAccessControl({
    resource: composed,
    operation: OPERATIONS.LIST,
  });

  const parsedMatchesArray: {
    type: OFACTabType;
    match?: OFACCheckStatusType;
  }[] = [
    { type: "Business Profile", match: lastCheckBP?.lastCheckStatusName },
    ...(isNewOfacTabsEnabled
      ? [
          {
            type: "Primary Account Holder" as OFACTabType,
            match: lastCheckPAH?.lastCheckStatusName,
          },
          {
            type: "Business Owner" as OFACTabType,
            match: lastCheckBO?.lastCheckStatusName,
          },
        ]
      : []),
  ];

  const confirmedMatch = parsedMatchesArray.find(
    (item) => item.match === "confirmed_match",
  );
  const possibleMatch = parsedMatchesArray.find(
    (item) => item.match === "possible_match",
  );
  const matchToShow = confirmedMatch || possibleMatch;

  const handleOpen = () => {
    onOpen(true, matchToShow?.type);
  };

  const tooltipMessage = isMobileView
    ? "Please switch to desktop view to access the OFAC section"
    : "Please create a Business Profile or add a Primary Account Holder with both first and last name provided";

  return (
    <PreviewSection
      isAcquirer={Boolean(isAcquirer)}
      isEnabled={isViewReadOfacAllowed}
      onClick={handleOpen}
      onConversationClick={onConvClick}
      label="OFAC"
      tooltipMessage={tooltipMessage}
      disabled={disabled}
      opened={opened}
      status={matchToShow?.match && <OFACStatus status={matchToShow.match} />}
    />
  );
};

export default OFACPreviewSection;
