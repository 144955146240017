import {
  ArrowsCounterClockwise,
  ArrowsLeftRight,
  ArrowUp,
  Bank,
  Briefcase,
  GearSix,
  Storefront,
} from "@phosphor-icons/react";

type Props = {
  isTransfersPageEnabled: boolean;
  isDisputesPageEnabled: boolean;
};

const getAcquirerMenu = ({
  isTransfersPageEnabled,
  isDisputesPageEnabled,
}: Props) => ({
  menu: [
    {
      label: "Processing",
      value: "/acquirer/processing",
      Icon: ArrowsLeftRight,
    },

    {
      label: "Settlement",
      value: "/acquirer/settlements",
      Icon: Bank,
    },
    ...(isDisputesPageEnabled
      ? [
          {
            label: "Disputes",
            value: "/acquirer/disputes",
            Icon: ArrowsCounterClockwise,
          },
        ]
      : []),
    ...(isTransfersPageEnabled
      ? [
          {
            label: "Transfers",
            value: "/acquirer/transfers",
            Icon: ArrowUp,
          },
        ]
      : []),
    {
      value: "",
      type: "divider",
    },
    {
      label: "Providers",
      value: "/acquirer/providers",
      Icon: Briefcase,
    },
    {
      label: "Merchants",
      value: "/acquirer/merchants",
      Icon: Storefront,
    },
  ],
  footer: [
    {
      label: "Settings",
      value: "/acquirer/settings",
      Icon: GearSix,
    },
  ],
});

export default getAcquirerMenu;
