import { Box, Skeleton, Stack } from "@mui/material";
import { JoinedProps } from "../agreements.types";
import MerchantInfo from "./components/merchantApplication/MerchantInfo";
import BusinessProfile from "./components/merchantApplication/BusinessProfile";
import BankAccount from "./components/merchantApplication/BankAccount";
import PrimaryAccountHolder from "./components/merchantApplication/PrimaryAccountHolder";
import BusinessOwner from "./components/merchantApplication/BusinessOwner";
import TractionFees from "./components/FreeSchedule/TractionFees";
import GiveText from "@shared/Text/GiveText";
import { useMerchantSidePanelContext } from "../../Provider/MerchantSidePanelProvider";
import MonthlyAccountFees from "./components/FreeSchedule/MonthlyAccountFees";
import GatewayFees from "./components/FreeSchedule/GatewayFees";
import ChargeBackFees from "./components/FreeSchedule/ChargeBackFees";
import MiscellaneousFees from "./components/FreeSchedule/MiscellaneousFees";
import { skeletonDefaultProps } from "../../constants";

function SnapshotPanel({ page: { tab } }: JoinedProps) {
  const { data, isLoading } = useMerchantSidePanelContext();
  return (
    <Box px="16px" pb="20px">
      {isLoading ? (
        <LoadingSkeletons />
      ) : (
        <>
          {tab === "merchantApplication" && (
            <>
              <MerchantInfo />
              <BusinessProfile />
              <BankAccount />
              <PrimaryAccountHolder />
              <BusinessOwner />
            </>
          )}
          {tab === "fees" && (
            <>
              <TractionFees />
              <MonthlyAccountFees />
              <GatewayFees />
              <ChargeBackFees />
              <MiscellaneousFees />
            </>
          )}
          {tab === "refund" && (
            <GiveText mt="10px" variant="bodyS" color="primary">
              {data?.merchantAgreement?.msaRefundPolicy}
            </GiveText>
          )}{" "}
        </>
      )}
    </Box>
  );
}

export default SnapshotPanel;

const skeletonsConfig = [
  { height: "22px", width: "45%" },
  { height: "368px", width: "100%" },
  { height: "22px", width: "40%" },
  { height: "144px", width: "100%" },
  { height: "22px", width: "40%" },
  { height: "226px", width: "100%" },
  { height: "22px", width: "50%" },
  { height: "226px", width: "100%" },
];

const LoadingSkeletons = () => (
  <Stack gap={2} flex={1}>
    {skeletonsConfig.map((config, index) => (
      <Skeleton key={index} {...config} {...skeletonDefaultProps} />
    ))}
  </Stack>
);
