import { TooltipProps } from "@mui/material";
import { ThemeMode } from "../types";
import { getJsonValue, isSecondVersion } from "../utils";
import { ThemeVersions } from "@theme/v2/types";

declare module "@mui/material/Tooltip" {
  interface TooltipProps {
    version?: ThemeVersions;
  }

  interface TooltipPropsColorOverrides {
    default: true;
    success: true;
    warning: true;
    light: true;
  }
}

export const getCustomTooltipStyles = (mode: ThemeMode) => {
  return {
    styleOverrides: {
      tooltip: {
        variants: [
          {
            props: (props: any) => isSecondVersion(props?.version),
            style: {
              borderRadius: "8px",
              maxWidth: "320px",
              padding: "8px 16px",
            },
          },
          ...getDefaultTooltipStyles(mode),
          ...getSuccessTooltipStyles(mode),
          ...getWarningTooltipStyles(mode),
          ...getLightTooltipStyles(),
        ],
      },
    },
  };
};

export const getDefaultTooltipStyles = (mode: ThemeMode) => [
  {
    props: (props: TooltipProps) => props.color === "default",
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.colour.surface.invert`),
      color: getJsonValue(`tokens.${mode}.colour.text.primary`),
      "& .MuiTooltip-arrow": {
        color: getJsonValue(`tokens.${mode}.colour.text.primary`),
      },
    },
  },
];

export const getSuccessTooltipStyles = (mode: ThemeMode) => [
  {
    props: (props: TooltipProps) => props.color === "success",
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.primitive.success.25`),
      border: `1px solid ${getJsonValue(
        `tokens.${mode}.primitive.success.50`,
      )}`,
      "& .MuiTooltip-arrow": {
        color: getJsonValue(`tokens.${mode}.primitive.success.100`),
      },
    },
  },
];

export const getWarningTooltipStyles = (mode: ThemeMode) => [
  {
    props: (props: TooltipProps) => props.color === "warning",
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.primitive.warning.10`),
      border: `1px solid ${getJsonValue(
        `tokens.${mode}.primitive.warning.50`,
      )}`,
      "& .MuiTooltip-arrow": {
        color: getJsonValue(`tokens.${mode}.primitive.warning.100`),
      },
    },
  },
];

export const getLightTooltipStyles = () => [
  {
    props: (props: TooltipProps) => props.color === "light",
    style: {
      backgroundColor: getJsonValue(`tokens.dark.colour.surface.invert`),
      color: getJsonValue(`tokens.dark.colour.text.primary`),
      "& .MuiTooltip-arrow": {
        color: getJsonValue(`tokens.dark.colour.text.primary`),
      },
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.25)",
    },
  },
];
